import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { GridRow, Column, MoneyField } from '../../common';

const FECSection19a = ({ q19aColA, q19aColB }) => {
  return (
    <Fragment>
      <GridRow>
        <Column sm={4} classNames="sub-question">
          <p>(a) Of Loans Made or Guaranteed by the Candidate</p>
        </Column>
        <Column sm={4} classNames="entry-row">
          <MoneyField
            disabled
            value={q19aColA || 0}
          />
        </Column>
        <Column sm={4} classNames="entry-row">
          <MoneyField
            disabled
            value={q19aColB || 0}
          />
        </Column>
      </GridRow>
    </Fragment>
  );
};

FECSection19a.propTypes = {
  q19aColA: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  q19aColB: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default FECSection19a;
