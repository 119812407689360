export const types = {
  CLEAR_DEPOSITS: 'CLEAR_DEPOSITS',
  CLEAR_SINGLE_DEPOSIT_FOR_TICKET: 'CLEAR_SINGLE_DEPOSIT_FOR_TICKET',
  CLEAR_SINGLE_DEPOSIT: 'CLEAR_SINGLE_DEPOSIT',
  CREATE_DEPOSIT_SUCCESS: 'CREATE_DEPOSIT_SUCCESS',
  CREATE_DEPOSIT: 'CREATE_DEPOSIT',
  DELETE_DEPOSIT_SUCCESS: 'DELETE_DEPOSIT_SUCCESS',
  DELETE_DEPOSIT: 'DELETE_DEPOSIT',
  GET_ALL_UNDEPOSITED_SUCCESS: 'GET_ALL_UNDEPOSITED_SUCCESS',
  GET_ALL_UNDEPOSITED: 'GET_ALL_UNDEPOSITED',
  GET_DEPOSIT_LIST_PROCESSING: 'GET_DEPOSIT_LIST_PROCESSING',
  GET_DEPOSIT_LIST_SUCCESS: 'GET_DEPOSIT_LIST_SUCCESS',
  GET_DEPOSIT_LIST: 'GET_DEPOSIT_LIST',
  GET_DEPOSIT_TICKET_INFO_ERROR: 'GET_DEPOSIT_TICKET_INFO_ERROR',
  GET_DEPOSIT_TICKET_INFO_SUCCESS: 'GET_DEPOSIT_TICKET_INFO_SUCCESS',
  GET_DEPOSIT_TICKET_INFO: 'GET_DEPOSIT_TICKET_INFO',
  GET_SINGLE_DEPOSIT_FOR_TICKET_SUCCESS: 'GET_SINGLE_DEPOSIT_FOR_TICKET_SUCCESS',
  GET_SINGLE_DEPOSIT_SUCCESS: 'GET_SINGLE_DEPOSIT_SUCCESS',
  GET_SINGLE_DEPOSIT: 'GET_SINGLE_DEPOSIT',
  GET_UNDEPOSITED_TOTAL_SUCCESS: 'GET_UNDEPOSITED_TOTAL_SUCCESS',
  GET_UNDEPOSITED_TOTAL: 'GET_UNDEPOSITED_TOTAL',
  UPDATE_DEPOSIT_SUCCESS: 'UPDATE_DEPOSIT_SUCCESS',
  UPDATE_DEPOSIT: 'UPDATE_DEPOSIT',
  UPLOAD_DEPOSIT_SPREADSHEET_FAILURE: 'UPLOAD_DEPOSIT_SPREADSHEET_FAILURE',
  UPLOAD_DEPOSIT_SPREADSHEET_PROCESSING: 'UPLOAD_DEPOSIT_SPREADSHEET_PROCESSING',
  UPLOAD_DEPOSIT_SPREADSHEET_SUCCESS: 'UPLOAD_DEPOSIT_SPREADSHEET_SUCCESS',
  UPLOAD_DEPOSIT_SPREADSHEET: 'UPLOAD_DEPOSIT_SPREADSHEET',
  UPLOAD_DEPOSIT_SPREADSHEET_NOT_STARTED: 'UPLOAD_DEPOSIT_SPREADSHEET_NOT_STARTED',
};

export const actions = {
  getDepositList() {
    return { type: types.GET_DEPOSIT_LIST };
  },

  getSingleDeposit(id) {
    return { type: types.GET_SINGLE_DEPOSIT, data: { id } };
  },

  clearSingleDeposit() {
    return { type: types.CLEAR_SINGLE_DEPOSIT };
  },

  createDeposit(payload) {
    return { type: types.CREATE_DEPOSIT, data: { payload } };
  },

  updateDeposit(id, payload) {
    return { type: types.UPDATE_DEPOSIT, data: { id, payload } };
  },

  deleteDeposit(id) {
    return { type: types.DELETE_DEPOSIT, data: { id } };
  },

  clearDeposits() {
    return { type: types.CLEAR_DEPOSITS };
  },

  uploadDepositSpreadsheet(spreadsheet) {
    return { type: types.UPLOAD_DEPOSIT_SPREADSHEET, data: { spreadsheet } };
  },
};
