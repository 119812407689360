import {
  CheckboxVisibility,
  DetailsList,
  Dropdown,
  SelectionMode,
  Text,
} from 'office-ui-fabric-react';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { CaretDown } from '../../icons';
import './index.css';
import { itemsPerPageOptions } from './utils';

const PaginatedList = ({ columns, items, total, onChangePage }) => {
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [activePage, setActivePage] = useState(0);
  const pageCount = Math.ceil(total / itemsPerPage);

  useEffect(() => {
    onChangePage(activePage, itemsPerPage);
  }, []);

  const handlePageClick = event => {
    onChangePage(event.selected, itemsPerPage);
    setActivePage(event.selected);
  };

  const handleItemsPerPageDropdownChange = (event, option) => {
    onChangePage(0, option.key);
    setItemsPerPage(option.key);
    setActivePage(0);
  };

  const handleRenderCaretDown = () => <CaretDown />;

  return (
    <div className="PaginatedList">
      <DetailsList
        items={items}
        columns={columns}
        compact={false}
        selectionMode={SelectionMode.none}
        checkboxVisibility={CheckboxVisibility.none}
      />
      <div className="paginated-list-pagination-container">
        <div className="paginated-list-dropdown-container">
          <Text className="paginated-list-dropdown-label">Items per page:</Text>
          <Dropdown
            onRenderCaretDown={handleRenderCaretDown}
            selectedKey={itemsPerPage}
            options={itemsPerPageOptions}
            onChange={handleItemsPerPageDropdownChange}
          />
        </div>
        <ReactPaginate
          forcePage={activePage}
          marginPagesDisplayed={2}
          breakLabel="..."
          nextLabel="Next"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel="Previous"
          containerClassName={'pagination'}
          previousLinkClassName={'pagination__link'}
          nextLinkClassName={'pagination__link'}
          disabledClassName={'pagination__link--disabled'}
          activeClassName={'pagination__link--active'}
          renderOnZeroPageCount={null}
        />
      </div>
    </div>
  );
};

PaginatedList.propTypes = {
  columns: PropTypes.array.isRequired,
  items: PropTypes.array,
  total: PropTypes.number,
  onChangePage: PropTypes.func.isRequired,
};

PaginatedList.defaultProps = {
  items: [],
  total: 0,
};

export default PaginatedList;
