import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogType,
  DialogFooter,
} from 'office-ui-fabric-react/lib/Dialog';
import {
  PrimaryButton,
} from 'office-ui-fabric-react/lib/Button';
import './SubmitReportDialog.css';

const FileSuccessDialog = ({
  dialogHidden,
  onCancel,
}) => (
  <Dialog
    hidden={dialogHidden}
    onDismiss={onCancel}
    dialogContentProps={{
      type: DialogType.normal,
      title: 'Filing is Successful',
    }}
    modalProps={{
      isBlocking: true,
    }}
  >
    <p style={{ display: 'block', height: 42 }}>
      Your filing has been accepted by the FEC.
    </p>
    <DialogFooter>
      <PrimaryButton text="Close" onClick={onCancel} />
    </DialogFooter>
  </Dialog>
);

FileSuccessDialog.propTypes = {
  dialogHidden: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
};

FileSuccessDialog.defaultProps = {
  dialogHidden: true,
};

export default FileSuccessDialog;
