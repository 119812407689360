import { emailRegex } from '../../helpers/constants';

export const validateCCInfo = state => {
  const { cardholderName, cardholderEmail, zipCode } = state.ccInfo;

  state.ccInfo.formValid = true;

  if (cardholderName.length === 0) {
    state.ccInfo.cardholderNameError = 'Cardholder name is required';
    state.ccInfo.formValid = false;
  } else {
    state.ccInfo.cardholderNameError = '';
  }

  if (cardholderEmail.length === 0) {
    state.ccInfo.cardholderEmailError = 'Cardholder email is required';
    state.ccInfo.formValid = false;
  } else if (!emailRegex.test(cardholderEmail)) {
    state.ccInfo.cardholderEmailError = 'Cardholder email is invalid';
    state.ccInfo.formValid = false;
  } else {
    state.ccInfo.cardholderEmailError = '';
  }

  if (zipCode.length === 0) {
    state.ccInfo.zipCodeError = 'Zip code is required';
    state.ccInfo.formValid = false;
  } else {
    state.ccInfo.zipCodeError = '';
  }

  return state;
};
