const types = {
  GET_ELECTION_CYCLES: 'GET_ELECTION_CYCLES',
  GET_ELECTION_CYCLES_SUCCESS: 'GET_ELECTION_CYCLES_SUCCESS',
  GET_ELECTION_CYCLES_FAILURE: 'GET_ELECTION_CYCLES_FAILURE',
};

const actions = {
  getElectionCycles() {
    return { type: types.GET_ELECTION_CYCLES };
  },
};

export { types, actions };
