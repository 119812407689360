import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { CommitteeTypePicker } from '../../Pickers';

export const CommitteeForm = ({
  handleChange,
  businessName = '',
  committeeAffiliation = 0,
  contactName = '',
  committeeFecId = '',
  contactType = '',
}) => (
  <Fragment>
    <TextField
      label={contactType === 'PTY' ? 'Organization Name' : 'Committee Name'}
      value={businessName}
      onChange={handleChange('businessName')}
    />
    {['Committee', 'COM', 'PAC'].includes(contactType) && (
    <CommitteeTypePicker
      selectedKey={committeeAffiliation}
      onChange={handleChange('committeeAffiliation')}
    />
      )}
    <TextField
      label="Contact Name"
      value={contactName}
      onChange={handleChange('contactName')}
    />
    {['PAC', 'PTY'].includes(contactType) && (
    <TextField
      label="FEC ID"
      value={committeeFecId}
      onChange={handleChange('committeeFecId')}
    />
      )}
  </Fragment>
  );

CommitteeForm.propTypes = {
  handleChange: PropTypes.func.isRequired,
  businessName: PropTypes.string,
  committeeAffiliation: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  contactName: PropTypes.string,
  contactType: PropTypes.string,
  committeeFecId: PropTypes.string,
};
