import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { GridRow, Column, MoneyField } from '../../common';
import SectionLabel from './SectionLabel';

const FECSection10 = ({ q10ColA }) => {
  return (
    <Fragment>
      <GridRow
        style={{
          borderTop: '1px solid #919191',
        }}
      >

        <Column sm={4}>
          <SectionLabel
            label="10."
            labelLines={[
              (<span>Debts and Obligations Owed <b>BY</b></span>),
              'the Committee (Itemize all on',
              'Schedule C and/or Schedule D)',
            ]}
          />
        </Column>
        <Column sm={4} classNames="entry-row">
          <MoneyField
            disabled
            value={q10ColA || 0}
          />
        </Column>
      </GridRow>
    </Fragment>
  );
};

FECSection10.propTypes = {
  q10ColA: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default FECSection10;
