import { ImportProgressStep } from '../../../helpers/constants';

export const steps = [
  {
    index: 0,
    label: ImportProgressStep.DataType,
  },
  {
    index: 1,
    label: ImportProgressStep.Upload,
  },
  {
    index: 2,
    label: ImportProgressStep.FileFormat,
  },
  {
    index: 3,
    label: ImportProgressStep.Questions,
  },
  {
    index: 4,
    label: ImportProgressStep.ColumnMapping,
  },
];
