import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'office-ui-fabric-react/lib/Dropdown';
import {
  DefaultButton,
  PrimaryButton,
} from 'office-ui-fabric-react/lib/Button';
import { Label } from 'office-ui-fabric-react/lib/Label';
import { Grid, GridRow, Column } from '../common';
import { YearPicker, DatePicker, FiledReportPicker } from '../Pickers';
import { formatNonStandardDate, sortByField } from '../../helpers/util';
import { sortDirections, federalReportTypeMap } from '../../helpers/constants';
import { CaretDown } from '../icons';

export const CoverPage = ({
  actions,
  isExpanded,
  reportType,
  reportDate,
  electionYear,
  campaign,
  electionDate,
  electionDateError,
  reportingName,
  reportingPeriod,
  reportingPeriodError,
  reportingPeriods,
  reportYear,
  reportYearError,
  isTBDReport,
  startDate,
  startDateError,
  endDate,
  endDateError,
  isFirstFilingForCurrentOffice,
  previousBalanceSource,
  previousReportId,
  previousReportIdError,
  reportId,
  session,
}) => {
  const reportPeriod =
    reportingPeriods.find(rp => rp.id === reportingPeriod.key) || {};
  const campaignElectionDate = campaign.electionDates.find(
    ed => ed.electionDate === electionDate.key,
  );
  const selectedElectionDate = campaignElectionDate
    ? campaignElectionDate.electionDate
    : 0;

  return (
    <div className="cover-page depth-1" name="coverpage">
      <header onClick={actions.toggleExpand}>
        <h3>Cover Page</h3>
      </header>
      <div className={`content${isExpanded ? '-expanded' : '-collapsed'}`}>
        <Grid>
          <GridRow>
            <Column lg={3}>
              <Label>Report Type</Label>
              <p className="label-content">
                {session.isFederal()
                  ? federalReportTypeMap[reportType]
                  : reportType}
              </p>
            </Column>
            <Column lg={5}>
              <Label>Report Date</Label>
              <p className="label-content">{reportDate}</p>
            </Column>
            {!isTBDReport && (
              <Column lg={3}>
                <Label>Election Year</Label>
                <p className="label-content">{electionYear}</p>
              </Column>
            )}
            {isTBDReport && (
              <Column lg={3}>
                <Label>Reporting Period</Label>
                <p className="label-content">{`${reportPeriod.group} - ${reportPeriod.desc}`}</p>
              </Column>
            )}
          </GridRow>
          {!isTBDReport && (
            <GridRow>
              <Column lg={4}>
                <Dropdown
                  onRenderCaretDown={() => <CaretDown />}
                  label="Election Date"
                  required
                  errorMessage={electionDateError}
                  selectedKey={selectedElectionDate}
                  onChange={actions.handleChangeElectionDate('electionDate')}
                  options={[
                    { key: 0, text: 'Select' },
                    ...campaign.electionDates
                      .sort(sortByField('electionDate', sortDirections.ASC))
                      .map(ed => {
                        return {
                          key: ed.electionDate,
                          text: `${formatNonStandardDate(
                            ed.electionDate,
                            'YYYY-MM-DD',
                          )} - ${ed.electionCycle}`,
                        };
                      }),
                  ]}
                />
              </Column>
              <Column lg={4}>
                {reportingName &&
                <Dropdown
                  onRenderCaretDown={() => <CaretDown />}
                  label="Reporting Name"
                  selectedKey={-1}
                  errorMessage={reportingPeriodError}
                  required
                  onChange={actions.handleChange('reportingPeriod')}
                  options={[
                    { key: -1, text: reportingName },
                  ]}
                />
                }
                {!reportingName &&
                <Dropdown
                  onRenderCaretDown={() => <CaretDown />}
                  label="Reporting Period"
                  selectedKey={reportingPeriod.key || reportingPeriod}
                  errorMessage={reportingPeriodError}
                  required
                  onChange={actions.handleChange('reportingPeriod')}
                  options={[
                    { key: 0, text: 'Select' },
                    ...reportingPeriods.map(rp => ({
                      key: rp.id,
                      text: `${rp.group} - ${rp.desc}`,
                    })),
                  ]}
                />
                }
              </Column>
              <Column lg={4}>
                <YearPicker
                  onChange={actions.handleChange('reportYear')}
                  required
                  errorMessage={reportYearError}
                  label="Report Covering Year"
                  value={reportYear.key || reportYear}
                />
              </Column>
            </GridRow>
          )}
          <GridRow>
            <Column lg={6}>
              <DatePicker
                label="Report Start Date"
                value={startDate}
                errorMessage={startDateError}
                onChange={actions.handleChangeDate('startDate')}
              />
            </Column>
            <Column lg={6}>
              <DatePicker
                label="Report End Date"
                value={endDate}
                errorMessage={endDateError}
                onChange={actions.handleChangeDate('endDate')}
              />
            </Column>
          </GridRow>
          {(!isFirstFilingForCurrentOffice && !isTBDReport) &&
          <GridRow>
            <Column lg={6}>
              <FiledReportPicker
                label="Previous Report Balance Source"
                selectedKey={previousReportId || previousBalanceSource || '0'}
                onChange={actions.handleChange('previousReportId')}
                errorMessage={previousReportIdError}
                reportId={reportId}
                required
              />
            </Column>
          </GridRow>
          }
          <GridRow>
            <Column classNames="section-actions">
              {!isTBDReport && (
                <DefaultButton
                  text="Update Report"
                  onClick={actions.updateReport}
                  style={{ marginRight: 16 }}
                />
              )}
              <PrimaryButton text="Continue" onClick={actions.nextSection} />
            </Column>
          </GridRow>
        </Grid>
      </div>
    </div>
  );
};

CoverPage.propTypes = {
  isExpanded: PropTypes.bool,
  reportType: PropTypes.string,
  reportDate: PropTypes.string,
  electionYear: PropTypes.string,
  campaign: PropTypes.object.isRequired,
  electionDate: PropTypes.object,
  electionDateError: PropTypes.string,
  reportingName: PropTypes.string,
  reportingPeriod: PropTypes.object,
  reportingPeriodError: PropTypes.string,
  reportingPeriods: PropTypes.array.isRequired,
  reportYear: PropTypes.object,
  reportYearError: PropTypes.string,
  isTBDReport: PropTypes.bool,
  actions: PropTypes.object.isRequired,
  startDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  startDateError: PropTypes.string,
  endDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  endDateError: PropTypes.string,
  previousBalanceSource: PropTypes.string,
  previousReportId: PropTypes.string,
  previousReportIdError: PropTypes.string,
  reportId: PropTypes.string.isRequired,
  isFirstFilingForCurrentOffice: PropTypes.bool,
  session: PropTypes.object.isRequired,
};

CoverPage.defaultProps = {
  isExpanded: true,
  reportType: '',
  reportDate: '',
  electionYear: '',
  electionDate: { key: 0 },
  electionDateError: '',
  reportingName: '',
  reportingPeriod: { key: -1 },
  reportingPeriodError: '',
  reportYear: { key: '0' },
  reportYearError: '',
  isTBDReport: false,
  startDate: null,
  startDateError: '',
  previousBalanceSource: '',
  previousReportId: '',
  previousReportIdError: '',
  isFirstFilingForCurrentOffice: false,
  endDate: null,
  endDateError: '',
};

export default CoverPage;
