import { types } from '../actions/reportActions';
import { statuses } from '../helpers/constants';
import { createReducer } from './createReducer';

export const initialState = {
  upcomingReports: [],
  upcomingReportsError: null,
  draftReports: [],
  draftReportsError: null,
  draftReportsStatus: statuses.NOT_STARTED,
  filedReports: [],
  filedReportsError: null,
  filedReportsStatus: statuses.NOT_STARTED,
};

const actionMap = {
  [types.GET_DRAFT_REPORTS_PROCESSING]: state => {
    return {
      ...state,
      draftReportsStatus: statuses.PROCESSING,
    };
  },
  [types.GET_FILED_REPORTS_PROCESSING]: state => {
    return {
      ...state,
      filedReportsStatus: statuses.PROCESSING,
    };
  },
  [types.GET_DRAFT_REPORTS_SUCCESS]: (state, action) => {
    return {
      ...state,
      draftReportsStatus: statuses.SUCCESS,
      draftReports: action.reports,
      draftReportsError: null,
    };
  },
  [types.GET_FILED_REPORTS_SUCCESS]: (state, action) => {
    return {
      ...state,
      filedReportsStatus: statuses.SUCCESS,
      filedReports: action.reports,
      filedReportsError: null,
    };
  },
  [types.GET_DRAFT_REPORTS_FAILURE]: (state, action) => {
    return {
      ...state,
      draftReportsStatus: statuses.ERROR,
      draftReportsError: action.error,
    };
  },
  [types.GET_FILED_REPORTS_FAILURE]: (state, action) => {
    return {
      ...state,
      filedReportsStatus: statuses.ERROR,
      filedReportsError: action.error,
    };
  },
  [types.GET_DRAFT_REPORTS_RESET]: state => {
    return {
      ...state,
      draftReportsStatus: statuses.NOT_STARTED,
    };
  },
  [types.GET_FILED_REPORTS_RESET]: state => {
    return {
      ...state,
      filedReportsStatus: statuses.NOT_STARTED,
    };
  },
  [types.CREATE_DISCLOSURE_REPORT_PROCESSING]: state => {
    return {
      ...state,
      draftReportStatus: statuses.PROCESSING,
    };
  },
  [types.CREATE_DISCLOSURE_REPORT_SUCCESS]: (state, action) => {
    return {
      ...state,
      draftReports: [...state.draftReports, action.newReport],
      draftReportStatus: statuses.NOT_STARTED,
    };
  },
  [types.CLEAR_REPORTS]: () => {
    return { ...initialState };
  },
};

export const reportsReducer = createReducer(initialState, actionMap);
