import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { Separator } from 'office-ui-fabric-react/lib/Separator';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { getLabel } from '../../helpers/labelHelper';
import AggregateRow from './AggregateRow';
import './AggregatesGraph.css';

const AggregatesGraph = ({
  aggregates,
  session,
  electionYear = 0,
  electionCycle = 0,
  amount = 0,
}) => {
  const [items, setItems] = useState(aggregates);

  useEffect(() => {
    setItems(aggregates);
  }, [aggregates, electionYear, electionCycle, amount]);

  return (
    <>
      <div className="AggregatesGraph">
        <span className="aggregates-header">
          <Icon
            iconName="FunnelDollar"
            styles={{
              root: {
                fontSize: '14px',
                backgroundColor: 'var(--primary-color))',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: 36,
                height: 36,
                color: '#fff',
                borderRadius: '50%',
              },
            }}
          />
          <h2>{`${getLabel('Contribution', session)} Aggregates`}</h2>
        </span>
        <div className="aggregate-list">
          {items.map((item, index) => (
            <AggregateRow
              key={index}
              aggregate={item}
              electionYear={electionYear}
              electionCycle={electionCycle}
              amount={amount}
            />
          ))}
        </div>
      </div>
      <Separator />
    </>
  );
};

AggregatesGraph.propTypes = {
  aggregates: PropTypes.array,
  session: PropTypes.object,
  electionYear: PropTypes.number,
  electionCycle: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default AggregatesGraph;
