import React, { Fragment, useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  DefaultButton,
  PrimaryButton,
} from 'office-ui-fabric-react/lib/Button';
import Loading from '../../components/Loading';
import { actions as budgetCategoryActions } from '../../actions/budgetCategoryActions';
import { actions as expenditureActions } from '../../actions/expenditureActions';
import { actions as messagingActions } from '../../actions/messagingActions';
import { actions as validationActions } from '../../actions/validationActions';
import ExpenditureForm from '../../components/ExpenditureForm';
import { scrollToTop, calculateEndRecipientTotals, itemDateBeforeLastFiling } from '../../helpers/util';
import { getFRCampaignType } from '../../helpers/campaignHelper';
import { EndRecipientDialog, ConfirmContinueUpdateDialog, EndRecipientBulkLoadDialog } from '../../components/Dialogs';
import { recipientSave, recipientUpdate } from '../../services/apiCalls';
import {
  statuses,
} from '../../helpers/constants';
import { getRecipientTypes } from '../../helpers/contactHelper';
import { getQueryParams } from '../../helpers/urlHelper';
import { useContactSearch } from '../../hooks/useContactSearch';
import {
  validate,
  createPayload,
} from '../../components/ExpenditureForm/ExpenditureFormValidations';
import { serializeRecipient } from '../../helpers/endRecipientHelper';
import {
  getLabel,
  convertItemUpdateErrorsToMessageList,
  itemUpdateWarning,
} from '../../helpers/labelHelper';
import {
  addExpenditureReducer,
  initialState,
  actions,
} from './addExpenditureReducer';

export const AddExpenditure = ({
  budgetCategory,
  budgetCategoryActions,
  campaign,
  expenditureActions,
  expenditures,
  history,
  location,
  session,
  validationActions,
  validations,
}) => {
  const [state, dispatch] = useReducer(addExpenditureReducer, initialState);
  const { lastFiledReportEndDate } = campaign;

  useEffect(() => {
    if (state.electionYear === 0 && campaign) {
      const electionYear = campaign.isNonCandidateCommittee
        ? new Date().getFullYear()
        : campaign.nextElectionYear * 1 || 0;
      dispatch({
        type: actions.SET_INITIAL_CAMPAIGN,
        data: {
          electionYear,
          isReportable: campaign.isNonCandidateCommittee,
          isRecipientSave: false,
          recipientSaveExpenditureId: null,
        },
      });
    }
  }, [
    campaign.isNonCandidateCommittee,
    campaign.nextElectionYear,
    state.electionYear,
  ]);

  useEffect(() => {
    if (budgetCategory.getBudgetCategoryStatus === statuses.SUCCESS) {
      dispatch({
        type: actions.HANDLE_CHANGE,
        data: {
          fieldName: 'budgetCategories',
          value: budgetCategory.budgetCategoryOptions,
        },
      });
    }
  }, [budgetCategory.getBudgetCategoryStatus]);

  const parseQueryParams = () => {
    const params = getQueryParams(location.search);
    if (Object.keys(params).length) {
      dispatch({
        type: actions.SET_QUERY_PARAMS,
        data: {
          expenditureType: params.type || 0,
          reportIdRedirect: params.reportId,
          election: params.electionCycle,
          fec: Boolean(params.fec),
          frCampaignType: getFRCampaignType(campaign),
        },
      });
    }
  };

  useEffect(() => {
    scrollToTop();
    parseQueryParams();
    budgetCategoryActions.setBudgetCategory();
    return () => {
      validationActions.clearCheckNumberValidations();
      expenditureActions.resetSave();
      dispatch({ type: 'RESET_STATE' });
    };
  }, []);

  const clearFieldsForNew = () => {
    dispatch({ type: actions.CLEAR_FIELDS_FOR_NEW });
  };

  useEffect(() => {
    if (
      expenditures.saveStatus === statuses.SUCCESS &&
      !state.isRecipientSave
    ) {
      clearFieldsForNew();
      scrollToTop();
    }
  }, [expenditures.saveStatus, state.recipientSave]);

  const clearRecipientInfo = () => {
    dispatch({ type: actions.CLEAR_RECIPIENT_INFO });
  };

  const onItemSelected = contact => {
    dispatch({ type: actions.ON_CONTACT_SELECTED, data: { contact } });
    if (state.checkNumber) {
      validationActions.validateExpenditureCheckNumber(
        state.selectedContact?._id || contact._id,
        state.checkNumber,
      );
    }
    return null;
  };

  const onConduitSelected = selectedConduitContact => {
    dispatch({ type: actions.ON_CONDUIT_SELECTED, data: { selectedConduitContact } });
    return selectedConduitContact;
  };

  const onRemoveConduit = removeFn => {
    dispatch({ type: actions.RESET_SELECTED_CONDUIT_INFO });
    removeFn();
  };

  const onSupportOpposeSelected = selectedSupportOpposeContact => {
    dispatch({ type: actions.ON_SUPPORT_OPPOSE_SELECTED, data: { selectedSupportOpposeContact } });
    return selectedSupportOpposeContact;
  };

  const onRemoveSupportOppose = removeFn => {
    dispatch({ type: actions.RESET_SELECTED_SUPPORT_OPPOSE_INFO });
    removeFn();
  };

  const handleContributionRefundChange = (e, value) => {
    dispatch({
      type: actions.HANDLE_REFUND_CONTRIBUTION_CHANGE,
      data: {
        refundContributionId: value.key !== undefined ? value.key : value,
      },
    });
  };

  const handleLoanChange = (e, value) => {
    dispatch({
      type: actions.HANDLE_LOAN_CHANGE,
      data: {
        loanId: value.key !== undefined ? value.key : value,
      },
    });
  };

  const [onResolveSuggestions, onRenderSuggestionsItem] = useContactSearch(
    state.election,
    state.electionYear,
    null,
    'Expenditure',
  );

  const clearSelectedContact = () => {
    dispatch({
      type: actions.CLEAR_RECIPIENT_INFO,
    });
  };

  const resetContactFields = () => {
    dispatch({
      type: actions.RESET_SELECTED_CONTACT_INFO,
    });
  };

  const handleCoupledChange = (fieldName1, fieldName2) => (e, value) => {
    dispatch({
      type: actions.HANDLE_COUPLED_CHANGE,
      data: {
        fieldName1,
        fieldName2,
        value,
      },
    });
  };

  const handleChange = fieldName => (e, value) => {
    dispatch({
      type: actions.HANDLE_CHANGE,
      data: {
        fieldName,
        value: value.key !== undefined ? value.key : value,
      },
    });
    if (fieldName === 'expenditureType' && value.key === 'Credit Card') {
      clearRecipientInfo();
    }

    if (fieldName === 'expenditureType' && value?.key === 'Credit Card') {
      dispatch({ type: actions.FORCE_BUSINESS_RECIPIENT_TYPE });
    }

    if (fieldName === 'contactType') {
      dispatch({
        type: actions.RESET_RECIPIENT_INFO,
      });
    }
  };

  const handleChangeDate = fieldName => date => {
    dispatch({
      type: actions.HANDLE_CHANGE,
      data: {
        fieldName,
        value: date,
      },
    });
  };

  const isOtherElection = election => {
    const result =
      Boolean(election) &&
      session.isFederal() &&
      !['Primary', 'General', 'Convention', 'Runoff', 'Recount'].includes(
        election,
      );
    return result;
  };

  const handleFederalElectionChange = (e, value) => {
    dispatch({
      type: actions.HANDLE_FEDERAL_ELECTION_CHANGE,
      data: {
        election: value.key !== undefined ? value.key : value,
        otherElectionType:
          value.key && isOtherElection(value.key)
            ? state.otherElectionType
            : '',
      },
    });
  };

  const handleContributionFederalElectionChange = (e, value) => {
    dispatch({
      type: actions.HANDLE_CONTRIBUTION_FEDERAL_ELECTION_CHANGE,
      data: {
        contributionElection: value.key !== undefined ? value.key : value,
        contributionOtherElectionType:
          value.key && isOtherElection(value.key)
            ? state.otherElectionType
            : '',
      },
    });
  };

  const validateCheckNumber = () => {
    const { checkNumber, selectedContact } = state;
    if (checkNumber && selectedContact?._id) {
      validationActions.validateExpenditureCheckNumber(
        selectedContact._id,
        checkNumber,
      );
    }
  };

  const handleChangeDatePaid = datePaid => {
    dispatch({ type: actions.HANDLE_CHANGE_DATE_PAID, data: { datePaid } });
  };

  const closeAddRecipientDialog = () => {
    dispatch({ type: actions.CLOSE_END_RECIPIENT_DIALOG });
  };

  const closeBulkAddRecipientDialog = () => {
    dispatch({ type: actions.CLOSE_BULK_END_RECIPIENT_DIALOG });
  };

  const selectRecipient = recipientId => {
    dispatch({
      type: actions.SELECT_RECIPIENT,
      data: {
        recipientId,
      },
    });
  };

  const addRecipient = async newRecipient => {
    const errors = validate(state, session);
    dispatch({
      type: actions.FORM_ERRORS,
      data: {
        errors,
      },
    });
    if (Object.values(errors).some(e => e.length > 0)) {
      scrollToTop();
      return messagingActions.setErrorToast(
        'There are form errors, please correct any errors to continue',
      );
    }

    const payload = createPayload(state);
    if (!payload.endRecipients) {
      payload.endRecipients = [];
    }
    payload.endRecipients = [
      ...payload.endRecipients,
      serializeRecipient({ ...newRecipient, isDirty: true }),
    ];

    const {
      data: { data: expenditure },
    } = await recipientUpdate(payload);

    dispatch({
      type: actions.RECIPIENT_ADDED,
      data: {
        expenditure,
      },
    });
  };

  const editRecipient = updatedRecipient => {
    dispatch({
      type: actions.EDIT_RECIPIENT,
      data: {
        updatedRecipient,
      },
    });
  };

  const deleteRecipient = recipientId => {
    dispatch({
      type: actions.DELETE_RECIPIENT,
      data: {
        recipientId,
      },
    });
  };

  const save = (addNew = false) => {
    const payload = createPayload(state);
    if (state.isRecipientSave) {
      expenditureActions.update(payload, addNew, state.reportIdRedirect);
    } else if (state.fec) {
      expenditureActions.saveFec(payload, addNew, state.reportIdRedirect);
    } else {
      expenditureActions.save(payload, addNew, state.reportIdRedirect);
    }

    if (addNew) {
      validationActions.clearCheckNumberValidations();
      clearFieldsForNew();
      scrollToTop();
    }
  };

  const onSaveClick = (addNew = false) => {
    const errors = validate(state, session);
    dispatch({ type: actions.FORM_ERRORS, data: { errors } });

    if (Object.values(errors).some(e => e.length > 0)) {
      scrollToTop();
      return messagingActions.setErrorToast(
        'There are form errors, please correct any errors to continue',
      );
    }

    const isBadItemDate = itemDateBeforeLastFiling(state.datePaid, lastFiledReportEndDate);
    const continueEditMessageList = convertItemUpdateErrorsToMessageList({
      itemName: 'expenditure',
      isBadItemDate,
    });

    if (continueEditMessageList.length > 0) {
      return dispatch({
        type: actions.SHOW_CONTINUE_UPDATE,
        data: {
          addNew,
          continueEditMessageList,
        },
      });
    }

    save(addNew);
  };

  const onRecipientSave = async () => {
    const errors = validate(state, session);
    dispatch({
      type: actions.FORM_ERRORS,
      data: {
        errors,
      },
    });
    if (Object.values(errors).some(e => e.length > 0)) {
      scrollToTop();
      messagingActions.setErrorToast(
        'There are form errors, please correct any errors to continue',
      );
    } else {
      const payload = createPayload(state);
      if (!state.recipientSaveExpenditureId) {
        const {
          data: {
            data: { _id },
          },
        } = await recipientSave(payload);
        dispatch({ type: actions.ON_RECIPIENT_SAVE, data: { _id } });
      } else {
        const {
          data: { data: expenditure },
        } = await recipientUpdate(payload);
        dispatch({
          type: actions.ON_RECIPIENT_SAVE,
          data: {
            _id: expenditure._id,
          },
        });
      }
    }
  };

  const onRecipientSaveBulk = async () => {
    const errors = validate(state, session);
    dispatch({ type: actions.FORM_ERRORS, data: { errors } });
    if (Object.values(errors).some(e => e.length > 0)) {
      messagingActions.setErrorToast(
        'There are form errors, please correct any errors to continue',
      );
    } else {
      const payload = createPayload(state);
      if (!state.recipientSaveExpenditureId) {
        const {
          data: {
            data: { _id },
          },
        } = await recipientSave(payload);
        dispatch({ type: actions.ON_BULK_RECIPIENT_SAVE, data: { _id } });
      } else {
        const {
          data: { data: expenditure },
        } = await recipientUpdate(payload);
        dispatch({
          type: actions.ON_BULK_RECIPIENT_SAVE,
          data: {
            _id: expenditure._id,
          },
        });
      }
    }
  };

  const cancel = () => {
    if (state.reportIdRedirect) {
      if (state.fec) {
        history.push(
          `/filer/editFECReport/${state.reportIdRedirect}?section=itemizedDisbursements`,
        );
      } else {
        history.push(
          `/filer/editReport/${state.reportIdRedirect}?section=expenditures`,
        );
      }
    } else {
      history.push('/filer/expenditures');
    }
  };

  const deleteExpenditure = () => {
    const { recipientSaveExpenditureId: _id } = state;
    if (_id) {
      expenditureActions.delete(_id, state.reportIdRedirect);
    }
  };

  if (expenditures.saveStatus === statuses.PROCESSING) {
    return <Loading />;
  }

  if (expenditures.saveStatus === statuses.NOT_STARTED) {
    const formActions = {
      openAddRecipientDialog: onRecipientSave,
      openBulkAddRecipientDialog: onRecipientSaveBulk,
      handleChange,
      handleChangeDate,
      handleCoupledChange,
      handleFederalElectionChange,
      handleContributionFederalElectionChange,
      handleChangeDisbursementCategory: handleChange,
      handleContributionRefundChange,
      handleLoanChange,
      editRecipient: selectRecipient,
      deleteRecipient,
      onBlurCheckNumber: validateCheckNumber,
      onResolveSuggestions,
      onRenderSuggestionsItem,
      paymentInfoActions: {
        handlePaymentInfoChange: handleChange,
        handleChangeDate: handleChangeDatePaid,
      },
      contactActions: {
        clearSelectedContact,
        onItemSelected,
        resetContactFields,
      },
      supportOpposeActions: {
        onSupportOpposeSelected,
        onRemoveSupportOppose,
      },
      conduitActions: {
        onConduitSelected,
        onRemoveConduit,
      },
    };

    const addRecipientActions = {
      closeDialog: closeAddRecipientDialog,
      closeBulkDialog: closeBulkAddRecipientDialog,
      addRecipient,
      editRecipient,
    };

    const totalRecipientAmount = calculateEndRecipientTotals(
      state.endRecipients,
    );

    const cancelContinueUpdate = () => {
      dispatch({
        type: actions.HIDE_CONTINUE_UPDATE,
      });
    };

    const { isRecipientSave } = state;

    return (
      <Fragment>
        <div className="ExpenditureForm depth-1">
          <h3>{`Add ${getLabel('Expenditure', session)}`}</h3>
          <ExpenditureForm
            campaign={campaign}
            {...state}
            selectedConduit={state.conduit}
            selectedSupportOppose={state.supportOppose}
            totalRecipientAmount={totalRecipientAmount}
            actions={formActions}
            validations={validations}
            session={session}
            messagingActions={messagingActions}
          />
          <div className="expenditure-actions-wrapper">
            <div className="actions">
              {isRecipientSave && (
                <DefaultButton
                  text="Delete"
                  onClick={deleteExpenditure}
                  className="delete-btn expenditure-btn"
                  style={{
                    backgroundColor: '#a80000',
                    color: '#fff',
                  }}
                />
              )}
              {!isRecipientSave && (
                <DefaultButton
                  text="Cancel"
                  onClick={cancel}
                  className="cancel-btn expenditure-btn"
                />
              )}
              <PrimaryButton
                text="Save"
                onClick={() => onSaveClick(false)}
                className="save-btn expenditure-btn"
              />
              <PrimaryButton
                text="Save, Add New"
                onClick={() => onSaveClick(true)}
                className="save-add-new-btn expenditure-btn"
                iconProps={{
                  iconName: 'Plus',
                }}
              />
            </div>
          </div>
        </div>
        <EndRecipientDialog
          election={state.election}
          electionYear={state.electionYear}
          recipient={state.selectedRecipient}
          totalAmount={state.amount}
          budgetCategories={state.budgetCategories}
          actions={addRecipientActions}
          dialogHidden={state.addRecipientDialogHidden}
          sourceTypes={getRecipientTypes(session)}
          session={session}
          recipientAmountTotal={totalRecipientAmount}
        />
        <EndRecipientBulkLoadDialog
          election={state.election}
          electionYear={state.electionYear}
          recipient={state.selectedRecipient}
          totalAmount={state.amount}
          budgetCategories={state.budgetCategories}
          actions={addRecipientActions}
          dialogHidden={state.bulkRecipientDialogHidden}
          sourceTypes={getRecipientTypes(session)}
          session={session}
          recipientAmountTotal={totalRecipientAmount}
        />
        <ConfirmContinueUpdateDialog
          dialogHidden={state.confirmContinueUpdateHidden}
          cancel={cancelContinueUpdate}
          confirm={() => save(state.addNew)}
          messageList={state.continueEditMessageList}
          instruction={itemUpdateWarning}
        />
      </Fragment>
    );
  }

  return null;
};

AddExpenditure.propTypes = {
  budgetCategory: PropTypes.object.isRequired,
  budgetCategoryActions: PropTypes.object.isRequired,
  campaign: PropTypes.object.isRequired,
  expenditureActions: PropTypes.object.isRequired,
  expenditures: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object,
  messagingActions: PropTypes.object.isRequired,
  session: PropTypes.object.isRequired,
  validationActions: PropTypes.object.isRequired,
  validations: PropTypes.object.isRequired,
};

function mapStateToProps({ currentCampaign, expenditures, validations, user, budgetCategory }) {
  return {
    budgetCategory,
    campaign: currentCampaign.campaign,
    expenditures,
    validations,
    session: user.session,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    budgetCategoryActions: bindActionCreators(budgetCategoryActions, dispatch),
    expenditureActions: bindActionCreators(expenditureActions, dispatch),
    messagingActions: bindActionCreators(messagingActions, dispatch),
    validationActions: bindActionCreators(validationActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddExpenditure),
);
