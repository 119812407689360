import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { BusinessTypePicker } from '../../Pickers';

export const BusinessForm = ({
  handleChange,
  businessName,
  businessType,
  contactName,
  type,
  taxEin,
}) => (
  <Fragment>
    <TextField
      label={type === 'ORG' ? 'Organization Name' : 'Business Name'}
      value={businessName}
      onChange={handleChange('businessName')}
    />
    <BusinessTypePicker
      label={type === 'ORG' ? 'Organization Type' : 'Business Type'}
      selectedKey={businessType}
      onChange={handleChange('businessType')}
    />
    <TextField
      label="Contact Name"
      value={contactName}
      onChange={handleChange('contactName')}
    />
    <TextField
      label="Tax Ein"
      value={taxEin}
      onChange={handleChange('taxEin')}
    />
  </Fragment>
);

BusinessForm.propTypes = {
  handleChange: PropTypes.func.isRequired,
  businessName: PropTypes.string,
  businessType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  contactName: PropTypes.string,
  taxEin: PropTypes.string,
  type: PropTypes.oneOf(['ORG', 'Business']),
};

BusinessForm.defaultProps = {
  businessName: '',
  businessType: 0,
  contactName: '',
  taxEin: '',
};

export default BusinessForm;
