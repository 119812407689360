import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'office-ui-fabric-react/lib/Link';
import {
  ActionButton,
  DefaultButton,
  PrimaryButton,
} from 'office-ui-fabric-react/lib/Button';
import { Grid, GridRow, Column } from '../common';
import { EmptyView } from '../EmptyView';
import { ScheduleEList } from '../ScheduleEList';

const ScheduleELines = ({
  sectionName,
  state,
  toggleExpandSection,
  session,
  addNewDisbursement,
  editDisbursement,
  items,
}) => {
  const renderList = () =>
    items.length > 0 ? (
      <ScheduleEList
        editItem={editDisbursement}
        expenditures={{
          ...items,
          expenditures: [...items],
        }}
        session={session}
        isHidden={state.expandableSections[sectionName] === false}
      />
    ) : (
      <EmptyView
        message="There are no Schedule E entries"
      />
    );

  return (
    <div
      className="ScheduleELines fec-report-section depth-1"
      name={sectionName}
    >
      <h3>
        SCHEDULE E{' '}
        <small>
          {' '}
          (
          <Link onClick={() => toggleExpandSection(sectionName, false)}>
            {state.expandableSections[sectionName] === true
              ? 'Collapse'
              : 'Expand'}
          </Link>
          )
        </small>
      </h3>
      <div
        className={`fec-ScheduleELines${
          state.expandableSections[sectionName] === true
            ? '-expanded'
            : '-collapsed'
        }`}
      >
        <Grid>
          <GridRow>
            <Column classNames="ms-textAlignRight">
              <ActionButton
                text="Add New Disbursement"
                iconProps={{
                  iconName: 'PlusCircle',
                  styles: {
                    root: {
                      fontSize: '1.3em',
                    },
                  },
                }}
                onClick={addNewDisbursement}
              />
            </Column>
          </GridRow>
          {renderList()}
        </Grid>
        <div className="section-actions">
          <DefaultButton
            text="Previous Section"
            style={{ marginRight: 16 }}
            onClick={() => toggleExpandSection('coverpage', false)}
          />
          <PrimaryButton
            text="Save & Continue"
            onClick={() => toggleExpandSection('itemizedLoans', true)}
          />
        </div>
      </div>
    </div>
  );
};

ScheduleELines.propTypes = {
  sectionName: PropTypes.string.isRequired,
  state: PropTypes.object.isRequired,
  toggleExpandSection: PropTypes.func.isRequired,
  session: PropTypes.object.isRequired,
  addNewDisbursement: PropTypes.func.isRequired,
  editDisbursement: PropTypes.func.isRequired,
  items: PropTypes.array,
};

ScheduleELines.defaultProps = {
  items: [],
};

export default ScheduleELines;
