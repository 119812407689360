import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { adminUserReducer as adminUsers } from './adminUserReducer';
import { aggregatesReducer as aggregates } from './aggregatesReducer';
import { alertReducer as alerts } from './alertReducer';
import { anedotReducer as anedot } from './anedotReducer';
import { budgetCategoryReducer as budgetCategory } from './budgetCategoryReducer';
import { contactsReducer as contacts } from './contactsReducer';
import { contactsMergeReducer as contactsMerge } from './contactsMergeReducer';
import { contactTransactionsReducer as contactTransactions } from './contactTransactionsReducer';
import { contributionReducer as contributions } from './contributionsReducer';
import { contributionSummaryReducer as contributionsSummary } from './contributionsSummaryReducer';
import { createCampaignReducer as createCampaign } from './createCampaignReducer';
import { currentCampaignReducer as currentCampaign } from './currentCampaignReducer';
import { currentReportReducer as currentReport } from './currentReportReducer';
import { debtsReducer as debts } from './debtsReducer';
import { dataImportReducer as dataImports } from './dataImportReducer';
import { depositReducer as deposits } from './depositReducer';
import { electionCycleReducer as electionCycles } from './electionCycleReducer';
import { expendituresReducer as expenditures } from './expendituresReducer';
import { federalReportReducer as federalReports } from './federalReportReducer';
import { importReducer as importdata } from './importReducer';
import { ledgerReducer as ledger } from './ledgerReducer';
import { listReducer as lists } from './listReducer';
import { loansReducer as loans } from './loansReducer';
import { longRunningProcessReducer as longRunningProcess } from './longRunningProcessReducer';
import { messagingReducer as messages } from './messagingReducer';
import { paymentReducer as payment } from './paymentReducer';
import { reconciliationReducer as reconciliations } from './reconciliationReducer';
import { reconciliationSortsReducer as reconciliationSorts } from './reconciliationSortsReducer';
import { reportingPeriodsReducer as reportingPeriods } from './reportingPeriodsReducer';
import { reportsReducer as reports } from './reportsReducer';
import { searchValueReducer as searchValues } from './searchValueReducer';
import { userReducer as user } from './userReducer';
import { validationReducer as validations } from './validationReducer';
import { verifyUserReducer as verifyUser } from './verifyUserReducer';
import { importListReducer as importList } from './importListReducer';
import { importWizardReducer as importWizard } from './importWizardReducer';
import { importErrorsReducer as importErrors } from './importErrorsReducer';
import { integrationSettingReducer as integrationSettings } from './integrationSettingReducer';

export default history =>
  combineReducers({
    router: connectRouter(history),
    adminUsers,
    aggregates,
    alerts,
    anedot,
    budgetCategory,
    contacts,
    contactsMerge,
    contactTransactions,
    contributions,
    contributionsSummary,
    createCampaign,
    currentCampaign,
    currentReport,
    dataImports,
    importList,
    importWizard,
    importErrors,
    debts,
    deposits,
    electionCycles,
    expenditures,
    federalReports,
    importdata,
    integrationSettings,
    ledger,
    lists,
    loans,
    longRunningProcess,
    messages,
    payment,
    reconciliations,
    reconciliationSorts,
    reportingPeriods,
    reports,
    searchValues,
    user,
    validations,
    verifyUser,
  });
