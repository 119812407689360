import React from 'react';
import { getContributions } from '../services/apiCalls';
import { formatDate, formatCurrency } from '../helpers/util';

export function useContributionSearch() {
  const searchContacts = async filterText => {
    try {
      const params = [
        `?search=${encodeURIComponent(filterText)}&limit=100`,
      ]
        .filter(Boolean)
        .join('');

      const { data } = await getContributions(params);
      return data;
    } catch (e) {
      return [];
    }
  };

  const onResolveSuggestions = async filterText => {
    if (filterText && filterText.length > 1) {
      return searchContacts(filterText);
    }
    return [];
  };

  const renderItem = (contribution, deleteIcon) => {
    const colStyle = { flex: '1 1 0', padding: '6px 8px 0 0', overflow: 'hidden' };
    const rowStyle = {
      height: '32px',
      width: '900px',
      overflow: 'hidden',
      display: 'flex',
      justifyContent: 'left',
      verticalAlign: 'middle',
      paddingLeft: '6px',
    };
    return (
      <div key={contribution._id} style={rowStyle}>
        <div style={colStyle}>Name: {contribution.displayName}</div>
        <div style={colStyle}>Amount: {formatCurrency(contribution.amount)}</div>
        <div style={colStyle}>Received: {formatDate(contribution.receivedDate)}</div>
        <div style={colStyle}>Entered/Updated: {formatDate(contribution.updatedAt)}</div>
        {deleteIcon}
      </div>
    );
  };

  const onRenderSuggestionsItem = contribution => {
    return renderItem(contribution, null);
  };

  return [onResolveSuggestions, onRenderSuggestionsItem, renderItem];
}
