import { sortDirectionMap, formatISODate } from '../../helpers/util';

export const buildParams = (state, isForm6Form24Report) => {
  // debugger;
  const params = [
    `?limit=100&skip=${state.skip * 100}`,
    state.filterText.length ? `&search=${state.filterText}` : '',
    state.startDate ? `&startDate=${formatISODate(state.startDate)}` : '',
    state.endDate ? `&endDate=${formatISODate(state.endDate)}` : '',
    state.sortBy ? `&sortBy=${state.sortBy}` : '',
    isForm6Form24Report ? '&isForm6Form24Report=true' : '',
    state._id ? `&reportId=${state._id}` : '',
    sortDirectionMap[state.sortDir]
      ? `&sortDir=${sortDirectionMap[state.sortDir]}`
      : '',
  ]
    .filter(Boolean)
    .join('');

  return params;
};
