import React from 'react';
import PropTypes from 'prop-types';
import { Pivot, PivotItem } from 'office-ui-fabric-react/lib/Pivot';
import { ActionButton, DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { TextField } from 'office-ui-fabric-react/lib/TextField';

export const FamilyForm = ({
  handleChange,
  handleChangeNewChild,
  handleChangeChild,
  handleDeleteChild,
  addNewChildStart,
  addNewChildFinish,
  cancelAddChild,
  spouseFirstName,
  spouseMiddleName,
  spouseLastName,
  childrenArray,
  addChild,
  newChild,
}) => (
  <Pivot>
    <PivotItem headerText="Spouse">
      <TextField
        label="Spouse First Name"
        value={spouseFirstName}
        onChange={handleChange('spouseFirstName')}
      />
      <TextField
        label="Spouse Middle Name"
        value={spouseMiddleName}
        onChange={handleChange('spouseMiddleName')}
      />
      <TextField
        label="Spouse Last Name"
        value={spouseLastName}
        onChange={handleChange('spouseLastName')}
      />
    </PivotItem>
    <PivotItem headerText="Children">
      {(childrenArray || []).map(c => (
        <span
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <TextField
            key={c._id}
            label="Name"
            value={c.name}
            onChange={handleChangeChild(c._id)}
            styles={{
              root: {
                width: '95%',
              },
            }}
          />
          <Icon
            iconName="UserTimes"
            onClick={handleDeleteChild(c._id)}
            styles={{
              root: {
                marginRight: 6,
                marginTop: 6,
                marginLeft: 6,
                cursor: 'pointer',
              },
            }}
          />
        </span>
      ))}
      <ActionButton
        disabled={addChild}
        iconProps={{ iconName: 'PlusCircle' }}
        onClick={addNewChildStart}
      >
        Add Child
      </ActionButton>
      {addChild && (
        <div className="additional-field">
          <TextField
            label="Child Name"
            value={newChild}
            onChange={handleChangeNewChild}
          />
          <Stack horizontal alignContent="end">
            <DefaultButton
              text="Cancel"
              onClick={cancelAddChild}
              style={{ marginRight: 16 }}
            />
            <DefaultButton text="Save New Child" onClick={addNewChildFinish} />
          </Stack>
        </div>
      )}
    </PivotItem>
  </Pivot>
);

FamilyForm.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleChangeNewChild: PropTypes.func.isRequired,
  handleChangeChild: PropTypes.func.isRequired,
  handleDeleteChild: PropTypes.func.isRequired,
  addNewChildStart: PropTypes.func.isRequired,
  addNewChildFinish: PropTypes.func.isRequired,
  cancelAddChild: PropTypes.func.isRequired,
  spouseFirstName: PropTypes.string,
  spouseMiddleName: PropTypes.string,
  spouseLastName: PropTypes.string,
  childrenArray: PropTypes.array,
  addChild: PropTypes.bool,
  newChild: PropTypes.string,
};

FamilyForm.defaultProps = {
  spouseFirstName: '',
  spouseMiddleName: '',
  spouseLastName: '',
  childrenArray: [],
  addChild: false,
};
