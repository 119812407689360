import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'office-ui-fabric-react/lib/Link';
import { Label } from 'office-ui-fabric-react/lib/Label';
import { DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { Grid, GridRow, Column, MoneyField } from '../../components/common';
import { DatePicker } from '../../components/Pickers';
import './EditBalanceDetails.css';

const FECEditBalanceDetails = ({
  actions,
  campaign,
  balanceDetailsExpanded = false,
  session,
}) => (
  <div className="EditBalanceDetails depth-1" name="balance-details">
    <h3>
      Beginning Balance information{' '}
      <small>
        {' '}
        (
        <Link onClick={actions.toggleExpandBalanceDetails}>
          {balanceDetailsExpanded ? 'Collapse' : 'Expand'}
        </Link>
        )
      </small>
    </h3>
    <div
      className={`edit-balance-details-content${
        balanceDetailsExpanded ? ' expanded' : ''
      }`}
    >
      <Grid>
        <GridRow>
          <Column sm={9} lg={6}>
            <MoneyField
              label="Starting Bank Balance"
              value={campaign.openingCashAmount}
              onChange={actions.handleTextChange('openingCashAmount')}
            />
          </Column>
          <Column sm={3} lg={6}>
            <DatePicker
              label="As of Date"
              value={campaign.openingCashAmountDate || new Date(2016, 0, 1)}
              onChange={actions.handleDateChange('openingCashAmountDate')}
            />
          </Column>
        </GridRow>
        <GridRow style={{ borderTop: '1px solid #919191' }}>
          <Column md={6}>
            <MoneyField
              label="Total Contributions (other than loans)"
              onChange={actions.handleChangeFECSummaryText(
                'colB_6a_TotalContributionsNoLoans',
              )}
              value={campaign.fecSummary.colB_6a_TotalContributionsNoLoans}
            />
          </Column>
          <Column md={6}>
            <MoneyField
              label="Total Contribution Refunds"
              value={campaign.fecSummary.colB_6b_TotalContributionRefunds}
              onChange={actions.handleChangeFECSummaryText(
                'colB_6b_TotalContributionRefunds',
              )}
            />
          </Column>
        </GridRow>
        <GridRow style={{ borderTop: '1px solid #919191' }}>
          <Column md={6}>
            <MoneyField
              label="Total Operating Expenditures"
              value={campaign.fecSummary.colB_7a_TotalOperatingExpenditures}
              onChange={actions.handleChangeFECSummaryText(
                'colB_7a_TotalOperatingExpenditures',
              )}
            />
          </Column>
          <Column md={6}>
            <MoneyField
              label="Total Offsets to Operating Expenditures"
              value={
                campaign.fecSummary.colB_7b_TotalOffsetsToOperatingExpenditures
              }
              onChange={actions.handleChangeFECSummaryText(
                'colB_7b_TotalOffsetsToOperatingExpenditures',
              )}
            />
          </Column>
        </GridRow>
        <GridRow style={{ borderTop: '1px solid #919191' }}>
          <Column>
            <Label>Contributions (other than loans) from:</Label>
          </Column>
        </GridRow>
        <GridRow>
          <Column md={6}>
            <MoneyField
              label="Individuals/Persons Other Than Political Committees, Itemized"
              value={campaign.fecSummary.colB_11ai_IndividualsItemized}
              onChange={actions.handleChangeFECSummaryText(
                'colB_11ai_IndividualsItemized',
              )}
            />
          </Column>
          <Column md={6}>
            <MoneyField
              label="Individuals/Persons Other Than Political Committees, Unitemized"
              value={campaign.fecSummary.colB_11aii_IndividualsUnitemized}
              onChange={actions.handleChangeFECSummaryText(
                'colB_11aii_IndividualsUnitemized',
              )}
            />
          </Column>
        </GridRow>
        <GridRow>
          <Column md={4}>
            <MoneyField
              label="Political Party Committees"
              value={campaign.fecSummary.colB_11b_PoliticalPartyCommittees}
              onChange={actions.handleChangeFECSummaryText(
                'colB_11b_PoliticalPartyCommittees',
              )}
            />
          </Column>
          <Column md={4}>
            <MoneyField
              label="Other Political Committees (such as PACs)"
              value={campaign.fecSummary.colB_11c_OtherPoliticalCommittees}
              onChange={actions.handleChangeFECSummaryText(
                'colB_11c_OtherPoliticalCommittees',
              )}
            />
          </Column>
          <Column md={4}>
            <MoneyField
              label="The Candidate"
              value={campaign.fecSummary.colB_11d_TheCandidate}
              onChange={actions.handleChangeFECSummaryText(
                'colB_11d_TheCandidate',
              )}
            />
          </Column>
        </GridRow>
        <GridRow style={{ borderTop: '1px solid #919191' }}>
          <Column md={6}>
            <Label>
              Transfer <strong>from</strong> Other Authorized Committees
            </Label>
            <MoneyField
              value={
                campaign.fecSummary
                  .colB_12_TransfersFromOtherAuthorizedCommittees
              }
              onChange={actions.handleChangeFECSummaryText(
                'colB_12_TransfersFromOtherAuthorizedCommittees',
              )}
            />
          </Column>
          <Column md={6}>
            <Label>
              Transfer <strong>to</strong> Other Authorized Committees
            </Label>
            <MoneyField
              value={
                campaign.fecSummary.colB_18_TransfersToOtherAuthorizedCommittees
              }
              onChange={actions.handleChangeFECSummaryText(
                'colB_18_TransfersToOtherAuthorizedCommittees',
              )}
            />
          </Column>
        </GridRow>
        <GridRow style={{ borderTop: '1px solid #919191' }}>
          <Column md={6}>
            <MoneyField
              label="Loans Made or Guaranteed by the Candidate"
              value={
                campaign.fecSummary.colB_13a_LoansMadeOrGuarnByTheCandidate
              }
              onChange={actions.handleChangeFECSummaryText(
                'colB_13a_LoansMadeOrGuarnByTheCandidate',
              )}
            />
          </Column>
          <Column md={6}>
            <MoneyField
              label="All Other Loans"
              value={campaign.fecSummary.colB_13b_AllOtherLoans}
              onChange={actions.handleChangeFECSummaryText(
                'colB_13b_AllOtherLoans',
              )}
            />
          </Column>
        </GridRow>
        <GridRow style={{ borderTop: '1px solid #919191' }}>
          <Column md={6}>
            <MoneyField
              label="Other Receipts"
              value={campaign.fecSummary.colB_15_OtherReceipts}
              onChange={actions.handleChangeFECSummaryText(
                'colB_15_OtherReceipts',
              )}
            />
          </Column>
          <Column md={6}>
            <MoneyField
              label="Other Disbursements"
              value={campaign.fecSummary.colB_21_OtherDisbursements}
              onChange={actions.handleChangeFECSummaryText(
                'colB_21_OtherDisbursements',
              )}
            />
          </Column>
        </GridRow>
        <GridRow style={{ borderTop: '1px solid #919191' }}>
          <Column>
            <Label>Loan Repayments</Label>
          </Column>
        </GridRow>
        <GridRow>
          <Column md={6}>
            <MoneyField
              label="Of Loans Made or Guaranteed by the Candidate"
              value={campaign.fecSummary.colB_19a_LoanRepaymentByCandidate}
              onChange={actions.handleChangeFECSummaryText(
                'colB_19a_LoanRepaymentByCandidate',
              )}
            />
          </Column>
          <Column md={6}>
            <MoneyField
              label="Of All Other Loans"
              value={campaign.fecSummary.colB_19b_LoanRepayments_AllOtherLoans}
              onChange={actions.handleChangeFECSummaryText(
                'colB_19b_LoanRepayments_AllOtherLoans',
              )}
            />
          </Column>
        </GridRow>
        <GridRow style={{ borderTop: '1px solid #919191' }}>
          <Column>
            <Label>Refunds of Contributions to</Label>
          </Column>
        </GridRow>
        <GridRow>
          <Column md={4}>
            <MoneyField
              label="Individuals/Persons Other Than Political Committees"
              value={
                campaign.fecSummary.colB_20a_Refund_IndividualsOtherThanPolCmtes
              }
              onChange={actions.handleChangeFECSummaryText(
                'colB_20a_Refund_IndividualsOtherThanPolCmtes',
              )}
            />
          </Column>
          <Column md={4}>
            <MoneyField
              label="Political Party Committees"
              value={
                campaign.fecSummary.colB_20b_Refund_PoliticalPartyCommittees
              }
              onChange={actions.handleChangeFECSummaryText(
                'colB_20b_Refund_PoliticalPartyCommittees',
              )}
            />
          </Column>
          <Column md={4}>
            <MoneyField
              label="Other Political Parties (such as PACs)"
              value={
                campaign.fecSummary.colB_20c_Refund_OtherPoliticalCommittees
              }
              onChange={actions.handleChangeFECSummaryText(
                'colB_20c_Refund_OtherPoliticalCommittees',
              )}
            />
          </Column>
        </GridRow>
      </Grid>
    </div>
    {balanceDetailsExpanded && (
      <div className="ms-textAlignRight">
        <DefaultButton
          style={{ marginRight: 8 }}
          text="Back"
          onClick={() =>
            actions.previousStep(
              campaign.campaignCommitteeRegistered ||
                campaign.isNonCandidateCommittee ||
                session.isFederal()
                ? 4
                : 2,
            )
          }
        />
        <DefaultButton
          text="Continue"
          onClick={() => actions.nextStep(session.isFederal() ? 7 : 6)}
        />
      </div>
    )}
  </div>
);

FECEditBalanceDetails.propTypes = {
  actions: PropTypes.object.isRequired,
  campaign: PropTypes.object.isRequired,
  balanceDetailsExpanded: PropTypes.bool,
  session: PropTypes.object.isRequired,
};

export default FECEditBalanceDetails;
