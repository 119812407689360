import { types } from '../actions/reportingPeriodActions';
import { createReducer } from './createReducer';

export const initialState = {
  periods: [],
};

const actionMap = {
  [types.GET_REPORTING_PERIODS_SUCCESS]: (state, action) => {
    return {
      ...state,
      periods: action.reportingPeriods,
    };
  },
};

export const reportingPeriodsReducer = createReducer(initialState, actionMap);
