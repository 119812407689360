import axios from 'axios';
import React, {
  Fragment,
  useEffect,
  useReducer,
} from 'react';
import {
  CheckboxVisibility,
  DetailsList,
  DetailsListLayoutMode,
  Icon,
  SelectionMode,
} from 'office-ui-fabric-react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { BackButton } from '../../../components/common';
import {
  getUserSession,
  getFiledReports,
} from '../../../selectors';

import './index.css';
import { types as reportActions } from '../../../actions/reportActions';
import { reportingPeriods } from '../../../helpers/reportingPeriods';
import { formatDate, formatUtcDate, formatCurrency as fc } from '../../../helpers/util';
import { getReportTypeByReportCode } from '../../../helpers/reportHelper';
import { auditReducer, initialState, actions } from '../auditReducer';

const CashOnHand = ({ history }) => {
  const reduxDispatch = useDispatch();
  const [state, localDispatch] = useReducer(auditReducer, initialState);
  const filedReports = useSelector(getFiledReports);
  const session = useSelector(getUserSession);

  useEffect(() => {
    reduxDispatch({
      type: reportActions.GET_FILED_REPORTS,
      data: { reportType: '' },
    });
  }, []);

  const mapFiledReports = () => {
    return filedReports.map(fr => ({
      ...fr,
      key: fr._id,
    }));
  };

  const getReportTypeStatus = (item) => {
    if (item.reportType === 'Amended') {
      return `${item.reportType} (${item.amendmentNumber}) - ${item.reportStatus}`;
    }
    return `${item.reportType} - ${item.reportStatus}`;
  };

  const getSummaryForReportFromRecords = async (id) => {
    const { data } = await axios.get(`/api/filer/audit/getReportSummary/${id}`);
    localDispatch({
      type: actions.SET_CALCULATED_TOTALS,
      data: {
        reportId: id,
        reportData: data,
      },
    });
  };

  const getDiff = (report, calced) => {
    if ((!report && report !== 0) || (!calced && calced !== 0)) {
      return '';
    }
    return fc(parseFloat(report) - parseFloat(calced));
  };

  const createFiledColumns = () => [
    {
      key: 'report-type',
      name: 'Report Type - Status',
      minWidth: 75,
      maxWidth: 200,
      onRender: item => {
        const rp = reportingPeriods.find(rp => rp.id === item.reportingPeriod);
        const classNames = [
          `report-type-${item.reportType.toLowerCase()}`,
          item.reportingPeriod === '18' ? 'final' : '',
        ]
          .filter(Boolean)
          .join(' ');
        return (
          <>
            <span
              className={classNames}
            >{`${item.reportType} - ${item.reportStatus}`}</span>
            {rp !== undefined && <p>{`${rp.group} - ${rp.desc}`}</p>}
          </>
        );
      },
    },
    {
      key: 'report-date',
      name: 'Report Date',
      maxWidth: 120,
      onRender: item => <span>{formatDate(item.reportDate)}</span>,
    },
    {
      key: 'election-date',
      name: 'Election Date',
      maxWidth: 120,
      onRender: item => <span>{formatUtcDate(item.electionDate)}</span>,
    },
    {
      key: 'contributions',
      name: 'Contributions',
      minWidth: 130,
      maxWidth: 140,
      onRender: item => {
        return (
          <div>
            <span>{fc(item?.summary?.contributions?.q5?.amount)}</span>
            {state.calculatedTotals?.[item._id]?.contributionAmount &&
            <span> / {fc(state.calculatedTotals?.[item._id]?.contributionAmount)}</span>
            }
          </div>
        );
      },
    },
    {
      key: 'expenditures',
      name: 'Expenditures',
      minWidth: 130,
      maxWidth: 140,
      onRender: item => {
        return (
          <div>
            <span>{fc(item?.summary?.expenditures?.q11?.amount)}</span>
            {state.calculatedTotals?.[item._id]?.expenditureAmount &&
            <span> / {fc(state.calculatedTotals?.[item._id]?.expenditureAmount)}</span>
            }
          </div>
        );
      },
    },
    {
      key: 'cash-on-hand',
      name: 'COH At Close',
      minWidth: 150,
      maxWidth: 160,
      onRender: item => {
        return (
          <div>
            <span>{fc(item?.summary?.total?.q15?.amount)}</span>
            {state.calculatedTotals?.[item._id]?.cashOnHand &&
            <span> / {fc(state.calculatedTotals?.[item._id]?.cashOnHand)}</span>
            }
          </div>
        );
      },
    },
    {
      key: 'actions',
      name: 'Get',
      minWidth: 50,
      maxWidth: 50,
      onRender: item => {
        return (
          <div className="filed-report-actions">
            <Icon
              iconName="Search"
              styles={{
                root: {
                  color: '#107c10',
                  fontSize: 24,
                  marginRight: 8,
                  cursor: 'pointer',
                },
              }}
              onClick={() => getSummaryForReportFromRecords(item._id)}
            />
          </div>
        );
      },
    },
    {
      key: 'contributions',
      name: 'Cont Diff',
      minWidth: 50,
      maxWidth: 60,
      onRender: item => {
        return (
          <div>
            {getDiff(item?.summary?.contributions?.q5?.amount, state.calculatedTotals?.[item._id]?.contributionAmount)}
          </div>
        );
      },
    },
    {
      key: 'expenditures',
      name: 'Exp Diff',
      minWidth: 50,
      maxWidth: 60,
      onRender: item => {
        return (
          <div>
            {getDiff(item?.summary?.expenditures?.q11?.amount, state.calculatedTotals?.[item._id]?.expenditureAmount)}
          </div>
        );
      },
    },
    {
      key: 'cash-on-hand',
      name: 'COH Diff',
      minWidth: 50,
      maxWidth: 60,
      onRender: item => {
        return (
          <div>
            {getDiff(item?.summary?.total?.q15?.amount, state.calculatedTotals?.[item._id]?.cashOnHand)}
          </div>
        );
      },
    },
  ];

  const createFiledColumnsFec = () => [
    {
      key: 'report-type',
      name: 'Report Type - Status',
      minWidth: 130,
      maxWidth: 150,
      onRender: item => {
        const rp = reportingPeriods.find(rp => rp.id === item.reportingPeriod);
        const classNames = [
          `report-type-${item.reportType.toLowerCase()}`,
          item.reportingPeriod === '18' ? 'final' : '',
        ]
          .filter(Boolean)
          .join(' ');
        return (
          <>
            <span
              className={classNames}
            >{getReportTypeStatus(item)}</span>
            {rp !== undefined && <p>{`${rp.group} - ${rp.desc}`}</p>}
          </>
        );
      },
    },
    {
      key: 'report-year',
      name: 'Year',
      minWidth: 55,
      maxWidth: 55,
      onRender: item => <span>{item.electionYear}</span>,
    },
    {
      key: 'report-title',
      name: 'Report Title',
      minWidth: 205,
      maxWidth: 220,
      onRender: item => <span>{getReportTypeByReportCode(item.reportCode)}</span>,
    },
    {
      key: 'report-filed',
      name: 'Date Filed',
      minWidth: 75,
      maxWidth: 90,
      onRender: item => <span>{formatDate(item.updatedAt)}</span>,
    },
    {
      key: 'filing-id',
      name: 'Filing ID',
      minWidth: 90,
      maxWidth: 100,
      onRender: item => <span>{item.filingId}</span>,
    },
    {
      key: 'contributions',
      name: 'Contributions (11e)',
      minWidth: 130,
      maxWidth: 140,
      onRender: item => {
        return (
          <div>
            <span>{fc(item?.fecSummary?.colA_11e_TotalContributions)}</span>
            {state.calculatedTotals?.[item._id]?.contributionAmount &&
            <span> / {fc(state.calculatedTotals?.[item._id]?.contributionAmount)}</span>
            }
          </div>
        );
      },
    },
    {
      key: 'expenditures',
      name: 'Expenditures (17)',
      minWidth: 130,
      maxWidth: 140,
      onRender: item => {
        return (
          <div>
            <span>{fc(item?.fecSummary?.colA_17_OperatingExpenditures)}</span>
            {state.calculatedTotals?.[item._id]?.expenditureAmount &&
            <span> / {fc(state.calculatedTotals?.[item._id]?.expenditureAmount)}</span>
            }
          </div>
        );
      },
    },
    {
      key: 'cash-on-hand',
      name: 'COH At Close',
      minWidth: 150,
      maxWidth: 160,
      onRender: item => {
        return (
          <div>
            <span>{fc(item?.fecSummary?.colA_27_CashOnHandAtClosePeriod)}</span>
            {state.calculatedTotals?.[item._id]?.cashOnHand &&
            <span> / {fc(state.calculatedTotals?.[item._id]?.cashOnHand)}</span>
            }
          </div>
        );
      },
    },
    {
      key: 'actions',
      name: 'Get',
      minWidth: 50,
      maxWidth: 50,
      onRender: item => {
        return (
          <div className="filed-report-actions">
            <Icon
              iconName="Search"
              styles={{
                root: {
                  color: '#107c10',
                  fontSize: 24,
                  marginRight: 8,
                  cursor: 'pointer',
                },
              }}
              onClick={() => getSummaryForReportFromRecords(item._id)}
            />
          </div>
        );
      },
    },
    {
      key: 'contributions',
      name: 'Cont Diff',
      minWidth: 50,
      maxWidth: 60,
      onRender: item => {
        return (
          <div>
            {getDiff(item?.fecSummary?.colA_11e_TotalContributions, state.calculatedTotals?.[item._id]?.contributionAmount)}
          </div>
        );
      },
    },
    {
      key: 'expenditures',
      name: 'Exp Diff',
      minWidth: 50,
      maxWidth: 60,
      onRender: item => {
        return (
          <div>
            {getDiff(item?.fecSummary?.colA_17_OperatingExpenditures, state.calculatedTotals?.[item._id]?.expenditureAmount)}
          </div>
        );
      },
    },
    {
      key: 'cash-on-hand',
      name: 'COH Diff',
      minWidth: 50,
      maxWidth: 60,
      onRender: item => {
        return (
          <div>
            {getDiff(item?.fecSummary?.colA_27_CashOnHandAtClosePeriod, state.calculatedTotals?.[item._id]?.cashOnHand)}
          </div>
        );
      },
    },
  ];

  return (
    <Fragment>
      <BackButton history={history} message="Cancel" />
      <div className="CashOnHand">
        <h2>Report vs Records Audit</h2>
        <div className="filed-reports depth-1">
          <h3>Filed Reports</h3>
          {filedReports.length === 0 && (
            <div className="no-filed-reports">
              <Icon
                iconName="FileExport"
                style={{ fontSize: 60, color: '#99999999' }}
              />
              <p>No reports filed for this campaign.</p>
            </div>
          )}
          {filedReports.length > 0 && (
            <DetailsList
              items={mapFiledReports()}
              columns={session.isFederal() ? createFiledColumnsFec() : createFiledColumns()}
              checkboxVisibility={CheckboxVisibility.none}
              compact={false}
              selectionMode={SelectionMode.none}
              layoutMode={DetailsListLayoutMode.justified}
            />
          )}
        </div>
      </div>
    </Fragment>
  );
};

CashOnHand.propTypes = {
  history: PropTypes.object.isRequired,
};

export default CashOnHand;
