import React from 'react';
import PropTypes from 'prop-types';
import {
  DatePicker as MSDatePicker,
  DayOfWeek,
} from 'office-ui-fabric-react/lib/DatePicker';
import './DatePicker.scss';

const DatePickerStrings = {
  months: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  shortMonths: [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ],

  days: [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ],

  shortDays: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],

  goToToday: 'Go to Today',
  prevMonthAriaLabel: 'Go to previous month',
  nextMonthAriaLabel: 'Go to next month',
  prevYearAriaLabel: 'Go to previous year',
  nextYearAriaLabel: 'Go to next year',
};

const formatDate = date => {
  if (!date) {
    date = new Date();
  }
  if (date) {
    return `${
      DatePickerStrings.shortMonths[date.getMonth()]
    } ${date.getDate()}, ${date.getFullYear()}`;
  }
};

const DatePicker = ({
  value = null,
  label = '',
  required = false,
  className = '',
  errorMessage = '',
  placeholder = '',
  onChange = () => {},
  onBlur = () => {},
  underlined = false,
  disabled = false,
}) => {
  const onSelectDate = date => {
    onChange(date);
  };

  return (
    <div className="FR-DatePicker">
      <MSDatePicker
        label={label}
        isRequired={required}
        firstDayOfWeek={DayOfWeek.Sunday}
        strings={DatePickerStrings}
        placeholder={placeholder}
        formatDate={formatDate}
        disabled={disabled}
        allowTextInput
        calendarProps={{
          navigationIcon: 'Times',
          leftNavigation: 'ArrowLeft',
          rightNavigation: 'ArrowRight',
        }}
        className={className}
        value={value}
        onSelectDate={onSelectDate}
        underlined={underlined}
        onBlur={onBlur}
      />
      {errorMessage && <label className="error-message">{errorMessage}</label>}
    </div>
  );
};

DatePicker.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.number,
  ]),
  label: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  className: PropTypes.string,
  errorMessage: PropTypes.string,
  placeholder: PropTypes.string,
  underlined: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default DatePicker;
