import React from 'react';
import PropTypes from 'prop-types';
import { TooltipHost } from 'office-ui-fabric-react';
import { HelpIcon } from '.';

const Tip = ({
  content,
}) => {
  return (
    <div style={{ display: 'inline-block', width: '32px' }}>
      <TooltipHost
        content={content}
        closeDelay={750}
      >
        <HelpIcon />
      </TooltipHost>
    </div>
  );
};

Tip.propTypes = {
  content: PropTypes.string,
};

Tip.defaultProps = {
  content: '',
};

export default Tip;
