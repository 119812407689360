import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  DefaultButton,
  PrimaryButton,
  ActionButton,
} from 'office-ui-fabric-react/lib/Button';
import { Grid, GridRow, Column, DataLabel } from '../common';
import { formatDateTime } from '../../helpers/util';

import './ContactDetails.css';

const ContactDetails = ({
  contact,
  isTarget,
  setAsTargetContact,
  cancelMerge,
  warnBeforeProceeding,
}) => {
  const classNames = [
    'contact-details-wrapper',
    isTarget ? 'target-contact' : 'source-contact',
  ]
    .filter(Boolean)
    .join(' ');

  return (
    <Grid>
      <GridRow>
        <Column classNames="ContactDetails">
          <div className={classNames}>
            {!isTarget && (
              <ActionButton
                onClick={() => setAsTargetContact(contact._id)}
                iconProps={{
                  iconName: 'UserCheck',
                }}
              >
                Set as Primary
              </ActionButton>
            )}
            {isTarget && <h4>Primary Contact</h4>}
            <DataLabel
              className="contact-label"
              size="large"
              label="Contact Type"
              value={contact.contactType}
            />
            {(contact.firstName || contact.middleName || contact.lastName) && (
              <DataLabel
                className="contact-label"
                size="large"
                label="Contact Name"
                value={`${contact.firstName}${
                  contact.middleName ? ' ' + contact.middleName : ''
                } ${contact.lastName}`}
              />
            )}
            {contact.businessName && (
              <DataLabel
                className="contact-label"
                size="large"
                label="Contact Name"
                value={contact.businessName}
              />
            )}
            {contact.contactName && (
              <DataLabel
                className="contact-label"
                size="large"
                label="Contact Name"
                value={contact.contactName}
              />
            )}
            {contact.businessType && (
              <DataLabel
                className="contact-label"
                size="large"
                label="Business Type"
                value={contact.businessType}
              />
            )}
            {contact.occupation && (
              <DataLabel
                className="contact-label"
                size="large"
                label="Occupation"
                value={contact.occupation}
              />
            )}
            {contact.employer && (
              <DataLabel
                className="contact-label"
                size="large"
                label="Employer"
                value={contact.employer}
              />
            )}
            {contact.email && (
              <DataLabel
                className="contact-label"
                size="large"
                label="Email Address"
                value={contact.email}
              />
            )}
            {contact.address && (
              <Fragment>
                {contact.address.addressLine1 && (
                  <DataLabel
                    className="contact-label"
                    size="large"
                    label="Address 1"
                    value={contact.address.addressLine1}
                  />
                )}
                {contact.address.addressLine2 && (
                  <DataLabel
                    className="contact-label"
                    size="large"
                    label="Address 2"
                    value={contact.address.addressLine2}
                  />
                )}
                {contact.address.city && (
                  <DataLabel
                    className="contact-label"
                    size="large"
                    label="City"
                    value={contact.address.city}
                  />
                )}
                {contact.address.state && contact.address.state !== '0' && (
                  <DataLabel
                    className="contact-label"
                    size="large"
                    label="State"
                    value={contact.address.state}
                  />
                )}
                {contact.address.zipCode && (
                  <DataLabel
                    className="contact-label"
                    size="large"
                    label="Zip Code"
                    value={contact.address.zipCode}
                  />
                )}
              </Fragment>
            )}
            {contact.updatedAt && (
              <DataLabel
                className="contact-label"
                size="large"
                label="Updated At"
                value={formatDateTime(contact.updatedAt)}
              />
            )}
          </div>
          {isTarget && (
            <div className="merge-actions">
              <DefaultButton
                text="Cancel"
                onClick={cancelMerge}
                className="cancel-btn"
              />
              <PrimaryButton
                text="Proceed with Consolidation"
                onClick={warnBeforeProceeding}
              />
            </div>
          )}
        </Column>
      </GridRow>
    </Grid>
  );
};

ContactDetails.propTypes = {
  contact: PropTypes.object,
  setAsTargetContact: PropTypes.func,
  isTarget: PropTypes.bool,
  cancelMerge: PropTypes.func,
  warnBeforeProceeding: PropTypes.func,
};

ContactDetails.defaultProps = {
  contact: {},
  isTarget: false,
};

export default ContactDetails;
