export const itemsPerPageOptions = [
  {
    key: 10,
    text: '10',
  },
  {
    key: 25,
    text: '25',
  },
  {
    key: 50,
    text: '50',
  },
  {
    key: 100,
    text: '100',
  },
];
