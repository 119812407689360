import React, { useReducer, useEffect, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
  DefaultButton,
  PrimaryButton,
} from 'office-ui-fabric-react/lib/Button';
import { actions as currentReportActions } from '../../actions/currentReportActions';
import { actions as messagingActions } from '../../actions/messagingActions';
import { actions as reportActions } from '../../actions/reportActions';
import { actions as debtActions } from '../../actions/debtActions';
import { scrollToComponent, itemDateBeforeLastFiling } from '../../helpers/util';
import { getQueryParams } from '../../helpers/urlHelper';
import Loading from '../../components/Loading';
import {
  convertItemUpdateErrorsToMessageList,
  itemUpdateWarning,
} from '../../helpers/labelHelper';
import {
  FECNonCandidateBeginningBalances,
  FECCoverPage,
  Form6CoverPage,
  FECSummaryPage,
  FECNonCandidateSummaryPage,
  FECDetailedSummaryReceiptsPage,
  FECNonCandidateDetailedSummaryReceiptsPage,
  FECDetailedSummaryDisbursementsPage,
  FECNonCandidateDetailedSummaryDisbursementsPage,
  F3ZSection,
  DebtsSection,
  ItemizedReceipts,
  ItemizedDisbursements,
  ItemizedLoans,
  ScheduleELines,
} from '../../components/FECReportSections';
import { fecReportReducer, initialState, actions } from './fecReportReducer';
import { validate } from './fecReportValidations';
import { createFecReportPayload } from './fecReportHelper';
import { isForm6Form24Report as determineIfForm6_24 } from '../../helpers/reportHelper';
import { getFRCampaignType } from '../../helpers/campaignHelper';
import { isFederalJfc } from '../../helpers/committeeHelper';
import {
  SubmitReportDialog,
  FileSuccessDialog,
  ConfirmContinueUpdateDialog,
} from '../../components/Dialogs';
import { statuses, frCampaignTypes } from '../../helpers/constants';
import './FecEdit.css';
import BeginningBalanceSourceDialog from '../../components/Dialogs/BeginningBalanceSourceDialog';
import {
  getContributionsList,
} from '../../selectors';
import { buildParams } from './helper';
import { types as contributionActions } from '../../actions/contributionActions';

const FECEdit = ({
  session,
  campaign,
  currentReport,
  currentReportActions,
  reportActions,
  debtActions,
  match,
  debts,
  history,
  location,
}) => {
  const contributions = useSelector(getContributionsList);
  const reduxDispatch = useDispatch();
  const [state, dispatch] = useReducer(fecReportReducer, initialState);
  const isForm6Form24Report = determineIfForm6_24(state.reportType);
  const { lastFiledReportEndDate } = campaign;
  const frCampaignType = getFRCampaignType(campaign);
  const isJFC = isFederalJfc(campaign?.committee);

  useEffect(() => {
    const { id } = match.params;
    if (id) {
      currentReportActions.getReport(id);
      const params = getQueryParams(location.search);
      if (params.section && params.section !== state.expandedSection) {
        dispatch({
          type: actions.TOGGLE_EXPAND_SECTION,
          data: {
            sectionName: params.section,
          },
        });
      }
    }
    return () => {
      currentReportActions.resetReport();
    };
  }, []);

  useEffect(() => {
    if (currentReport.report && !state.reportType && !state.reportCode) {
      dispatch({
        type: actions.SET_REPORT,
        data: {
          report: currentReport.report,
          frCampaignType,
        },
      });
    }

    if (state.reportType && state.reportCode && !state.previousBalanceSource) {
      dispatch({ type: actions.PRESENT_SET_PREVIOUS_BALANCE_SOURCE });
    }
    if (state.reportType && state.reportCode && state.previousBalanceSource) {
      dispatch({ type: actions.CLOSE_SET_PREVIOUS_BALANCE_SOURCE });
    }
  }, [currentReport.report, state.reportType, state.reportCode, state.previousBalanceSource, currentReport.getCurrentReportStatus]);

  useEffect(() => {
    // This is a return from a balance source update, refresh the state
    if (currentReport.getCurrentReportStatus === statuses.SUCCESS) {
      dispatch({
        type: actions.SET_REPORT,
        data: {
          report: currentReport.report,
          frCampaignType,
        },
      });
    }
    // Provides spinny on update calls
    if (currentReport.getCurrentReportStatus === statuses.PROCESSING) {
      dispatch({
        type: actions.CLEAR_REPORT_TYPE,
      });
    }
  }, [currentReport.getCurrentReportStatus]);

  useEffect(() => {
    // The job status can be successful but report a failed job
    if (currentReport.federalReportJobStatus === statuses.SUCCESS) {
      const { failReason } = currentReport.federalReportJobResult.data;

      if (failReason) {
        return dispatch({
          type: actions.SET_FEC_FILING_WARNINGS,
          data: { fileWithFecWarning: failReason },
        });
      }
      dispatch({ type: actions.CLOSE_SUBMIT_REPORT_DIALOG });
      dispatch({ type: actions.PRESENT_FILING_SUCCESS_DIALOG });
    }
    if (currentReport.federalReportJobStatus === statuses.ERROR) {
      const defaultMsg =
        'Error with filing, please consult tech support before attempting to file again.';
      const errorMsg = currentReport.federalReportJobError || defaultMsg;
      return dispatch({
        type: actions.SET_FEC_FILING_WARNINGS,
        data: {
          fileWithFecWarning: errorMsg,
        },
      });
    }
  }, [
    currentReport.federalReportJobResult,
    currentReport.federalReportJobStatus,
  ]);

  useEffect(() => {
    // On click opens up new window while report is requested to avoid popup through an untrusted event

    // Either have the report, or we don't
    if (
      currentReport.requestFECWebServiceDraftReportStatus === statuses.SUCCESS
    ) {
      if (!currentReport.requestFECWebServiceDraftResult.isFailed) {
        const reportId = currentReport.requestFECWebServiceDraftReportId;
        state.fecWebServiceFileWindow.location.href = `${window.location.origin}/api/filer/reports/${reportId}/fec/draftPdf`;
      } else {
        state.fecWebServiceFileWindow.location.href = `${window.location.origin}/api/filer/message/issue`;
      }
    }
    if (
      currentReport.requestFECWebServiceDraftReportStatus === statuses.ERROR
    ) {
      state.fecWebServiceFileWindow.location.href = `${window.location.origin}/api/filer/message/issue`;
    }
  }, [
    currentReport.requestFECWebServiceDraftReportStatus,
    currentReport.requestFECWebServiceDraftResult,
    currentReport.requestFECWebServiceDraftReportId,
  ]);

  useEffect(() => {
    if (state.startDate) {
      const params = buildParams(state, isForm6Form24Report);
      reduxDispatch({
        type: contributionActions.GET_CONTRIBUTIONS,
        data: { params },
      });
    }
  }, [state.startDate, currentReport.report, state.filterText, state.sortBy, state.sortDir, isForm6Form24Report]);

  useEffect(() => {
    if (state.skip > 0) {
      const params = buildParams(state, isForm6Form24Report);
      reduxDispatch({
        type: contributionActions.GET_CONTRIBUTIONS_NEXT_PAGE,
        data: { params },
      });
    }
  }, [state.skip]);

  useLayoutEffect(() => {
    scrollToComponent(`[name="${state.expandedSection}"]`);
  }, [state.expandedSection]);

  const showErrorToast = message => {
    messagingActions.setErrorToast(message);
  };

  const handleChange = fieldName => (e, value) => {
    dispatch({
      type: actions.HANDLE_CHANGE,
      data: {
        fieldName,
        value: value.key !== undefined ? value.key : value,
      },
    });
  };

  const updateReport = () => {
    const errors = validate(state);
    dispatch({
      type: actions.FORM_ERRORS,
      data: {
        errors,
      },
    });
    if (Object.keys(errors).length > 0) {
      showErrorToast('There are form errors, please correct them to continue');
    } else {
      const payload = createFecReportPayload(
        match.params.id,
        currentReport,
        state,
      );
      currentReportActions.updateFederalReport(payload);
    }
  };

  useEffect(() => {
    if (state.f3ZUpdated > 0) {
      updateReport();
    }
  }, [state.f3ZUpdated]);

  const addF3ZData = (f3Z) => {
    dispatch({
      type: actions.ADD_F3Z_RECORD,
      data: f3Z,
    });
  };

  const updateF3ZData = (f3Z) => {
    dispatch({
      type: actions.UPDATE_F3Z_RECORD,
      data: f3Z,
    });
  };

  const deleteF3ZData = (f3Z) => {
    dispatch({
      type: actions.DELETE_F3Z_RECORD,
      data: f3Z,
    });
  };

  const handlePreviousBalanceChange = fieldName => (e, value) => {
    dispatch({
      type: actions.HANDLE_PREVIOUS_BALANCE_CHANGE,
      data: {
        fieldName,
        value: value.key !== undefined ? value.key : value,
      },
    });
  };

  const handleDateChange = fieldName => date => {
    dispatch({
      type: actions.HANDLE_CHANGE,
      data: {
        fieldName,
        value: new Date(date),
      },
    });
  };

  const addNewReceipt = () => {
    history.push(`/filer/addContribution?reportId=${match.params.id}&fec=true`);
  };

  const editReceipt = id => {
    history.push(
      `/filer/editContribution/${id}?reportId=${match.params.id}&fec=true`,
    );
  };

  const editItem = id => {
    const item = currentReport.contributions.find(c => c._id === id);
    const { isItemFiled, reconciliationId, depositId, receivedDate } = (item || {});
    const isBadItemDate = itemDateBeforeLastFiling(receivedDate, lastFiledReportEndDate);
    const continueEditMessageList = convertItemUpdateErrorsToMessageList({
      itemName: 'receipt',
      isItemFiled,
      reconciliationId,
      depositId,
      isBadItemDate,
    });

    if (continueEditMessageList.length > 0) {
      return dispatch({
        type: actions.SHOW_CONTINUE_UPDATE,
        data: {
          continueEditMessageList,
          confirmContinueCallback: () => editReceipt(id),
        },
      });
    }

    editReceipt(id);
  };

  const addNewDisbursement = () => {
    history.push(`/filer/addExpenditure?reportId=${match.params.id}&fec=true`);
  };

  const editDisbursement = id => {
    history.push(
      `/filer/editExpenditure/${id}?reportId=${match.params.id}&fec=true`,
    );
  };

  const onEditDisbursementClick = id => {
    const item = currentReport.expenditures.find(c => c._id === id);
    const { isItemFiled, reconciliationId, depositId, expenditureDate } = (item || {});
    const isBadItemDate = itemDateBeforeLastFiling(expenditureDate, lastFiledReportEndDate);
    const continueEditMessageList = convertItemUpdateErrorsToMessageList({
      itemName: 'disbursement',
      isItemFiled,
      reconciliationId,
      depositId,
      isBadItemDate,
    });

    if (continueEditMessageList.length > 0) {
      return dispatch({
        type: actions.SHOW_CONTINUE_UPDATE,
        data: {
          editItemId: id,
          continueEditMessageList,
          confirmContinueCallback: () => editDisbursement(id),
        },
      });
    }

    editDisbursement(id);
  };

  const addNewLoan = () => {
    history.push(`/filer/addLoan?reportId=${match.params.id}&fec=true`);
  };

  const editLoan = id => {
    history.push(`/filer/editLoan/${id}?reportId=${match.params.id}&fec=true`);
  };

  const toggleExpandSection = (sectionName, update = false) => {
    dispatch({
      type: actions.TOGGLE_EXPAND_SECTION,
      data: {
        sectionName,
      },
    });
    if (update) {
      updateReport();
    }
  };

  const fileReport = () => {
    dispatch({ type: actions.IS_SUBMITTING_FILE });
    currentReportActions.submitFederalReport(
      match.params.id,
      state.submitFileWithWarnings,
    );
  };

  const getFileFromFEC = async reportId => {
    const blankPagePath = `${window.location.origin}/api/filer/message/wait`;
    const newWindow = window.open(blankPagePath, '_blank');
    dispatch({ type: actions.SET_FEC_WEB_SERVICE_PDF_WINDOW, data: newWindow });
    currentReportActions.requestFECWebServiceDraftReport(reportId);
  };

  const get48HourFileFromFEC = async (reportId) => {
    const blankPagePath = `${window.location.origin}/api/filer/message/wait`;
    const newWindow = window.open(blankPagePath, '_blank');
    dispatch({ type: actions.SET_FEC_WEB_SERVICE_PDF_WINDOW, data: newWindow });
    currentReportActions.requestFECWebServiceDraftReport(reportId);
  };

  const handleSubmitWithWarningsClick = () => {
    dispatch({ type: actions.TOGGLE_SUBMIT_WITH_WARNINGS });
  };

  const closeSuccessDialog = () => {
    dispatch({ type: actions.CLOSE_FILING_SUCCESS_DIALOG });
    if (isForm6Form24Report) {
      return history.push('/filer/48HourReports');
    }
    return history.push('/filer/reports');
  };

  const cancelContinueUpdate = () => {
    dispatch({
      type: actions.HIDE_CONTINUE_UPDATE,
    });
  };

  const nextPage = () => {
    dispatch({
      type: actions.HANDLE_CHANGE,
      data: {
        fieldName: 'skip',
        value: state.skip + 1,
      },
    });
  };

  const setSort = ({ sortBy, sortDir }) => {
    dispatch({
      type: actions.SET_SORT,
      data: {
        sortBy,
        sortDir,
      },
    });
  };

  const handleFilterChange = (evt, filterText) => {
    dispatch({
      type: actions.SET_FILTER,
      data: {
        filterText,
      },
    });
  };

  const renderFederalCandidateSections = () => {
    return (
      <>
        <FECCoverPage
          campaign={campaign}
          currentReport={currentReport}
          handleChange={handleChange}
          handleDateChange={handleDateChange}
          toggleExpandSection={toggleExpandSection}
          state={state}
          sectionName="coverpage"
          nextSectionName="summary"
        />
        <FECSummaryPage
          campaign={campaign}
          currentReport={currentReport}
          handleChange={handleChange}
          handleChangeDate={handleDateChange}
          toggleExpandSection={toggleExpandSection}
          state={state}
          sectionName="summary"
        />
        <FECDetailedSummaryReceiptsPage
          campaign={campaign}
          currentReport={currentReport}
          handleChangeDate={handleDateChange}
          toggleExpandSection={toggleExpandSection}
          state={state}
          sectionName="summaryDetailedReceipts"
        />
        <FECDetailedSummaryDisbursementsPage
          currentReport={currentReport}
          toggleExpandSection={toggleExpandSection}
          state={state}
          sectionName="summaryDetailedDisbursements"
        />
        <ItemizedReceipts
          sectionName="itemizedReceipts"
          state={state}
          toggleExpandSection={toggleExpandSection}
          addNewReceipt={addNewReceipt}
          actions={{ editItem, nextPage, setSort, handleFilterChange }}
          session={session}
          sortField={state.sortBy || 'updatedAt'}
          sortDirection={state.sortDir || 'desc'}
          filterText={state.filterText}
          items={contributions}
        />
        <ItemizedDisbursements
          sectionName="itemizedDisbursements"
          state={state}
          toggleExpandSection={toggleExpandSection}
          addNewDisbursement={addNewDisbursement}
          editDisbursement={onEditDisbursementClick}
          session={session}
          items={currentReport.expenditures}
        />
        <ItemizedLoans
          sectionName="itemizedLoans"
          state={state}
          toggleExpandSection={toggleExpandSection}
          addNewLoan={addNewLoan}
          editLoan={editLoan}
          items={currentReport.loans}
          session={session}
        />
        <DebtsSection
          session={session}
          debts={debts.list}
          debtActions={debtActions}
          reportId={match.params.id}
          toggleExpandSection={toggleExpandSection}
          state={state}
          sectionName="debts"
        />
        <F3ZSection
          toggleExpandSection={toggleExpandSection}
          updateReport={updateReport}
          sectionName="f3Z"
          state={state}
          addF3ZData={addF3ZData}
          updateF3ZData={updateF3ZData}
          deleteF3ZData={deleteF3ZData}
        />
      </>
    );
  };

  const show = false;
  const renderFederalNonCandidateSections = () => {
    return (
      <>
        <FECCoverPage
          campaign={campaign}
          currentReport={currentReport}
          handleChange={handleChange}
          handleDateChange={handleDateChange}
          toggleExpandSection={toggleExpandSection}
          state={state}
          sectionName="coverpage"
          nextSectionName="beginningBalances"
        />
        <FECNonCandidateBeginningBalances
          handleChange={handlePreviousBalanceChange}
          toggleExpandSection={toggleExpandSection}
          state={state}
          sectionName="beginningBalances"
        />
        {show &&
        <>
          <FECNonCandidateSummaryPage
            campaign={campaign}
            currentReport={currentReport}
            handleChange={handleChange}
            handleChangeDate={handleDateChange}
            toggleExpandSection={toggleExpandSection}
            state={state}
            sectionName="summary"
          />
          <FECNonCandidateDetailedSummaryReceiptsPage
            campaign={campaign}
            currentReport={currentReport}
            handleChangeDate={handleDateChange}
            toggleExpandSection={toggleExpandSection}
            state={state}
            sectionName="summaryDetailedReceipts"
          />
          <FECNonCandidateDetailedSummaryDisbursementsPage
            currentReport={currentReport}
            toggleExpandSection={toggleExpandSection}
            state={state}
            sectionName="summaryDetailedDisbursements"
          />
        </>
        }
        <ItemizedReceipts
          sectionName="itemizedReceipts"
          state={state}
          toggleExpandSection={toggleExpandSection}
          addNewReceipt={addNewReceipt}
          actions={{ editItem, nextPage, setSort, handleFilterChange }}
          session={session}
          sortField={state.sortBy || 'updatedAt'}
          sortDirection={state.sortDir || 'desc'}
          filterText={state.filterText}
          items={contributions}
        />
        <ItemizedDisbursements
          sectionName="itemizedDisbursements"
          state={state}
          toggleExpandSection={toggleExpandSection}
          addNewDisbursement={addNewDisbursement}
          editDisbursement={onEditDisbursementClick}
          session={session}
          items={currentReport.expenditures}
        />
        <ItemizedLoans
          sectionName="itemizedLoans"
          state={state}
          toggleExpandSection={toggleExpandSection}
          addNewLoan={addNewLoan}
          editLoan={editLoan}
          items={currentReport.loans}
          session={session}
        />
        <DebtsSection
          session={session}
          debts={debts.list}
          debtActions={debtActions}
          reportId={match.params.id}
          toggleExpandSection={toggleExpandSection}
          state={state}
          sectionName="debts"
        />
        <F3ZSection
          toggleExpandSection={toggleExpandSection}
          updateReport={updateReport}
          sectionName="f3Z"
          state={state}
          addF3ZData={addF3ZData}
          updateF3ZData={updateF3ZData}
          deleteF3ZData={deleteF3ZData}
        />
      </>
    );
  };

  const renderFederalCandidate48HourSections = () => {
    return (
      <>
        <Form6CoverPage
          campaign={campaign}
          currentReport={currentReport}
          handleChange={handleChange}
          handleDateChange={handleDateChange}
          toggleExpandSection={toggleExpandSection}
          state={state}
          sectionName="coverpage"
        />
        <ItemizedReceipts
          sectionName="itemizedReceipts"
          state={state}
          toggleExpandSection={toggleExpandSection}
          addNewReceipt={addNewReceipt}
          actions={{ editItem, nextPage, setSort, handleFilterChange }}
          session={session}
          sortField={state.sortBy || 'updatedAt'}
          sortDirection={state.sortDir || 'desc'}
          filterText={state.filterText}
          items={contributions}
        />
        <ItemizedLoans
          sectionName="itemizedLoans"
          state={state}
          toggleExpandSection={toggleExpandSection}
          addNewLoan={addNewLoan}
          editLoan={editLoan}
          items={currentReport.loans}
          session={session}
        />
      </>
    );
  };

  const renderFederalNonCandidate48HourSections = () => {
    return (
      <>
        <Form6CoverPage
          campaign={campaign}
          currentReport={currentReport}
          handleChange={handleChange}
          handleDateChange={handleDateChange}
          toggleExpandSection={toggleExpandSection}
          state={state}
          sectionName="coverpage"
        />
        <ScheduleELines
          sectionName="scheduleELines"
          state={state}
          toggleExpandSection={toggleExpandSection}
          addNewDisbursement={addNewDisbursement}
          editDisbursement={onEditDisbursementClick}
          session={session}
          items={currentReport.expenditures}
        />
      </>
    );
  };

  const renderButtons = () => {
    return (
      <div className="actions">
        <DefaultButton
          text="Back to Reports"
          iconProps={{ iconName: 'ArrowAltCircleLeft' }}
          onClick={() => history.push('/filer/reports')}
          style={{ marginRight: 16 }}
        />
        <DefaultButton
          text="Generate Detail Report"
          iconProps={{ iconName: 'FilePdf' }}
          onClick={() =>
            window.open(`/api/filer/reports/${match.params.id}/fec/pdf`)
          }
          style={{ marginRight: 16 }}
        />
        <DefaultButton
          text="Generate Working Report"
          iconProps={{ iconName: 'FilePdf' }}
          onClick={() => getFileFromFEC(match.params.id)}
          style={{ marginRight: 16 }}
        />
        <PrimaryButton
          text="Verify with FEC"
          onClick={() =>
            history.push(`/filer/editFECReport/verify/${match.params.id}`)
          }
          style={{ marginRight: 16 }}
          iconProps={{
            iconName: 'CheckDouble',
          }}
          disabled={false}
        />
        <PrimaryButton
          text="File with FEC"
          iconProps={{ iconName: 'Upload' }}
          style={{ marginRight: 32 }}
          onClick={() =>
            dispatch({ type: actions.OPEN_SUBMIT_REPORT_DIALOG })
          }
        />
        <PrimaryButton
          text="Delete Report"
          onClick={() =>
            reportActions.deleteDisclosureReport(match.params.id, false)
          }
          styles={{
            root: {
              backgroundColor: '#a80000',
            },
            rootHovered: {
              backgroundColor: '#840000',
            },
            rootPressed: {
              backgroundColor: '#840000',
            },
          }}
        />
      </div>
    );
  };

  const render48HourButtons = () => {
    return (
      <div className="actions">
        <DefaultButton
          text="Back to Reports"
          iconProps={{ iconName: 'ArrowAltCircleLeft' }}
          onClick={() => history.push('/filer/48HourReports')}
          style={{ marginRight: 16 }}
        />
        <DefaultButton
          text="Generate Working Report"
          iconProps={{ iconName: 'FilePdf' }}
          onClick={() => get48HourFileFromFEC(match.params.id)}
          style={{ marginRight: 16 }}
        />
        <PrimaryButton
          text="Verify with FEC"
          onClick={() =>
            history.push(`/filer/editFECReport/verify/${match.params.id}`)
          }
          style={{ marginRight: 16 }}
          iconProps={{
            iconName: 'CheckDouble',
          }}
          disabled={false}
        />
        <PrimaryButton
          text="File with FEC"
          iconProps={{ iconName: 'Upload' }}
          style={{ marginRight: 32 }}
          onClick={() =>
            dispatch({ type: actions.OPEN_SUBMIT_REPORT_DIALOG })
          }
        />
        <PrimaryButton
          text="Delete Report"
          onClick={() =>
            reportActions.delete48HourNoticeReport(match.params.id)
          }
          styles={{
            root: {
              backgroundColor: '#a80000',
            },
            rootHovered: {
              backgroundColor: '#840000',
            },
            rootPressed: {
              backgroundColor: '#840000',
            },
          }}
        />
      </div>
    );
  };

  if (!state.reportType) {
    return <Loading />;
  }

  return (
    <div className="FECReportEdit">
      {(!isForm6Form24Report && frCampaignType === frCampaignTypes.FederalCandidate) &&
      renderFederalCandidateSections()
      }

      {(!isForm6Form24Report && frCampaignType === frCampaignTypes.FederalNonCandidate) &&
      renderFederalNonCandidateSections()
      }

      {(isForm6Form24Report && frCampaignType === frCampaignTypes.FederalCandidate) &&
      renderFederalCandidate48HourSections()
      }

      {(isForm6Form24Report && frCampaignType === frCampaignTypes.FederalNonCandidate && isJFC) &&
        renderFederalCandidate48HourSections()
      }

      {(isForm6Form24Report && frCampaignType === frCampaignTypes.FederalNonCandidate && !isJFC) &&
      renderFederalNonCandidate48HourSections()
      }

      <div className="edit-report-actions">
        {!isForm6Form24Report && renderButtons()}
        {isForm6Form24Report && render48HourButtons()}
      </div>

      <SubmitReportDialog
        dialogHidden={state.submitReportDialogHidden}
        onCancel={() => dispatch({ type: actions.CLOSE_SUBMIT_REPORT_DIALOG })}
        onConfirm={fileReport}
        onConfirmButtonText="Yes, File With FEC"
        session={session}
        isSubmittingFile={state.isSubmittingFile}
        fecFilingWarning={state.fileWithFecWarning}
        isFecSubmit={true}
        submitWithWarnings={state.submitFileWithWarnings}
        handleSubmitWithWarningsClick={handleSubmitWithWarningsClick}
      />
      <FileSuccessDialog
        dialogHidden={state.fileSuccessDialogHidden}
        onCancel={closeSuccessDialog}
      />
      <BeginningBalanceSourceDialog
        dialogHidden={state.previousBalanceSourceDialogHidden}
        onConfirm={updateReport}
        reportId={match.params.id}
        handleChange={handleChange}
        selectedKey={state.previousReportId}
      />
      <ConfirmContinueUpdateDialog
        dialogHidden={state.confirmContinueUpdateHidden}
        cancel={cancelContinueUpdate}
        confirm={state.confirmContinueCallback}
        messageList={state.continueEditMessageList}
        instruction={itemUpdateWarning}
      />
    </div>
  );
};

FECEdit.propTypes = {
  campaign: PropTypes.object.isRequired,
  currentReport: PropTypes.object.isRequired,
  currentReportActions: PropTypes.object.isRequired,
  messagingActions: PropTypes.object.isRequired,
  reportActions: PropTypes.object.isRequired,
  debtActions: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  debts: PropTypes.object.isRequired,
  session: PropTypes.object.isRequired,
};

const mapStateToProps = ({ currentCampaign, currentReport, debts, user }) => ({
  campaign: currentCampaign.campaign,
  currentReport,
  debts,
  session: user.session,
});

const mapDispatchToProps = dispatch => ({
  currentReportActions: bindActionCreators(currentReportActions, dispatch),
  messagingActions: bindActionCreators(messagingActions, dispatch),
  reportActions: bindActionCreators(reportActions, dispatch),
  debtActions: bindActionCreators(debtActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(FECEdit);
