import { getLabel } from '../../helpers/labelHelper';
import { isInvalidDate, isInvalidNumber } from '../../helpers/util';
import { emailRegex, nonIndividualErrorTypeMap } from '../../helpers/constants';

export const validate = ({ session, state }) => {
  const errors = {
    electionYearError: '',
    electionError: '',
    emailError: '',
    otherElectionTypeError: '',
    paymentTypeError: '',
    loanDateError: '',
    firstNameError: '',
    lastNameError: '',
    businessNameError: '',
    interestRateError: '',
    dueDateError: '',
    outstandingBalanceError: '',
    cumulativePaymentToDateError: '',
    isPersonalFundsOfCandidateError: '',
    isSecuredError: '',
    originalLoanDateError: '',
    drawAmountError: '',
    otherPartiesSecondarilyLiableError: '',
    collateralPledgedDescriptionError: '',
    collateralPledgedEstimatedValueError: '',
    lenderPerfectedSecurityError: '',
    areFutureContributionsOrInterestIncomePledgedError: '',
    futureContributionsOrInterestEstimatedValueError: '',
    depositoryAccountLocationError: '',
    depositoryAccountAddressLine1Error: '',
    depositoryAccountCityError: '',
    depositoryAccountStateError: '',
    depositoryAccountZipCodeError: '',
    selectedContactError: '',
    fecIdError: '',
    missingDepositoryAccountError: '',
  };

  if (state.electionYear === 0) {
    errors.electionYearError = 'Election year is required';
  }

  if (state.election === 0) {
    errors.electionError = 'Election is required';
  } else if (
    ['Other'].includes(state.election) &&
    state.otherElectionType.length === 0 &&
    session.isFederal()
  ) {
    errors.otherElectionTypeError = 'Other election is required';
  }

  if (state.paymentType === 0) {
    errors.paymentTypeError = 'Loan type is required';
  }

  if (state.email && !emailRegex.test(state.email)) {
    errors.emailError = 'Please use a valid email address';
  }

  if (state.amount && isInvalidNumber(state.amount)) {
    errors.amountError = 'Invalid value, include only digits and decimal point';
  }

  if (!state.loanDate) {
    errors.loanDateError = `${getLabel('Loan date', session)} is required`;
  } else if (isInvalidDate(state.loanDate)) {
    errors.loanDateError = 'Invalid date';
  }

  if (['IND', 'Individual', 'CAN'].includes(state.contactType)) {
    if (state.firstName.length === 0) {
      errors.firstNameError = 'First name is required';
    }

    if (state.lastName.length === 0) {
      errors.lastNameError = 'Last name is required';
    }
  } else if (
    Object.keys(nonIndividualErrorTypeMap).includes(state.contactType)
  ) {
    if (state.businessName.length === 0) {
      errors.businessNameError = `${
        nonIndividualErrorTypeMap[state.contactType]
      } is required`;
    }
  }

  if (state.selectedContact === null) {
    if (state.contactType === 0) {
      errors.selectedContactError =
        'Please choose a contact or create a new one';
    }
  }

  if (session.isFederal()) {
    if (['IND', 'Individual', 'CAN'].includes(state.contactType)) {
      if (state.interestRate.length === 0) {
        errors.interestRateError =
          'Interest rate is required (if none, enter 0)';
      } else if (isInvalidNumber(state.interestRate)) {
        errors.interestRateError = 'Invalid interest rate value';
      }

      if (!state.dueDate) {
        errors.dueDateError = 'Due date is required';
      } else if (isInvalidDate(state.dueDate)) {
        errors.dueDateError = 'Invalid date';
      }

      if (state.isSecured === null) {
        errors.isSecuredError = 'Please choose an option';
      }

      if (state.cumulativePaymentToDate.length === 0) {
        errors.cumulativePaymentToDateError =
          'Cumulative payment to date is required, (if none, enter 0)';
      } else if (isInvalidNumber(state.cumulativePaymentToDate)) {
        errors.cumulativePaymentToDateError =
          'Invalid value, include only digits and decimal point';
      }

      if (state.outstandingBalance.length === 0) {
        errors.outstandingBalanceError =
          'Outstanding balance is required, (if none, enter 0)';
      } else if (isInvalidNumber(state.outstandingBalance)) {
        errors.outstandingBalanceError =
          'Invalid value, include only digits and decimal point';
      }

      if (
        !state.isPersonalFundsOfCandidate &&
        state.responsibleParties.filter(r => !r.removed).length === 0
      ) {
        errors.isPersonalFundsOfCandidateError =
          'If not personal funds of the candidate, endorsers or guarantors are required';
      }
    }

    if (
      [
        'Business',
        'ORG',
        'COM',
        'CCM',
        'PAC',
        'PTY',
        'Candidate Committee',
      ].includes(state.contactType)
    ) {
      if (state.interestRate.length === 0) {
        errors.interestRateError =
          'Interest rate is required (if none, enter 0)';
      } else if (isInvalidNumber(state.interestRate)) {
        errors.interestRateError =
          'Invalid value, include only digits and decimal point';
      }

      if (!state.dueDate) {
        errors.dueDateError = 'Due date is required';
      } else if (isInvalidDate(state.dueDate)) {
        errors.dueDateError = 'Invalid date';
      }

      if (state.isLoanRestructured && !state.originalLoanDate) {
        errors.originalLoanDateError = 'Original loan date is required';
      } else if (
        state.isLoanRestructured &&
        isInvalidDate(state.originalLoanDate)
      ) {
        errors.originalLoanDateError = 'Invalid date';
      }

      if (state.isLineOfCredit) {
        if (state.drawAmount.length === 0) {
          errors.drawAmountError =
            'Draw amount is required, (if none, enter 0)';
        } else if (isInvalidNumber(state.drawAmount)) {
          errors.drawAmountError =
            'Invalid value, include only digits and decimal point';
        }

        if (state.outstandingBalance.length === 0) {
          errors.outstandingBalanceError =
            'Outstanding balance is required, (if none, enter 0)';
        } else if (isInvalidNumber(state.outstandingBalance)) {
          errors.outstandingBalanceError =
            'Invalid value, include only digits and decimal point';
        }
      }

      if (state.otherPartiesSecondarilyLiable === null) {
        errors.otherPartiesSecondarilyLiableError = 'Please select an option';
      } else if (
        state.otherPartiesSecondarilyLiable === true &&
        state.responsibleParties.length === 0
      ) {
        errors.otherPartiesNotListedError =
          'If other parties are secondarily liable, endorsers or guarantors are required';
        errors.otherPartiesSecondarilyLiableError = '';
      }

      if (state.isCollateralPledged) {
        if (state.collateralPledgedDescription.length === 0) {
          errors.collateralPledgedDescriptionError =
            'Description required for pledged collateral';
        }

        if (state.collateralPledgedEstimatedValue.length === 0) {
          errors.collateralPledgedEstimatedValueError =
            'Estimated value required for pledged collateral';
        } else if (isInvalidNumber(state.collateralPledgedEstimatedValue)) {
          errors.collateralPledgedEstimatedValueError =
            'Invalid value, include only digits and decimal point';
        }

        if (state.lenderPerfectedSecurity === null) {
          errors.lenderPerfectedSecurityError = 'Please select an option';
        }
      }

      if (state.areFutureContributionsOrInterestIncomePledged) {
        if (state.futureContributionsOrInterestDescription.length === 0) {
          errors.futureContributionsOrInterestDescriptionError =
            'Description is required';
        }

        if (state.futureContributionsOrInterestEstimatedValue.length === 0) {
          errors.futureContributionsOrInterestEstimatedValueError =
            'Estimated value is required, (if none, enter 0)';
        } else if (
          isInvalidNumber(state.futureContributionsOrInterestEstimatedValue)
        ) {
          errors.futureContributionsOrInterestEstimatedValueError =
            'Invalid value, include only digits and decimal point';
        }

        if (!state.depositoryAccount.establishedDate) {
          errors.establishedDateError = 'Established date is required';
        } else if (isInvalidDate(state.depositoryAccount.establishedDate)) {
          errors.establishedDateError = 'Invalid date';
        }

        if (
          !state.depositoryAccount._id ||
          (state.depositoryAccount._id && !state.depositoryAccount.location)
        ) {
          errors.missingDepositoryAccountError =
            'Please choose an existing depository account or create a new one if future income is pledged as collateral';
        }
      }

      if (
        state.treasurerDateSigned &&
        isInvalidDate(state.treasurerDateSigned)
      ) {
        errors.treasurerDateSignedError = 'Invalid date';
      }

      if (
        state.authorizedRepresentative.dateSigned &&
        isInvalidDate(state.authorizedRepresentative.dateSigned)
      ) {
        errors.authorizedRepDateSignedError = 'Invalid date';
      }
    }
  }

  return errors;
};

export const validateDepositoryAccount = account => {
  const { location, addressLine1, city, state, zipCode } = account;
  const errors = {
    depositoryAccountLocationError: '',
    depositoryAccountAddressLine1Error: '',
    depositoryAccountCityError: '',
    depositoryAccountStateError: '',
    depositoryAccountZipCodeError: '',
  };

  if (!location) {
    errors.depositoryAccountLocationError =
      'Depository account location is required';
  }

  if (!addressLine1) {
    errors.depositoryAccountAddressLine1Error = 'Address line 1 is required';
  }

  if (!city) {
    errors.depositoryAccountCityError = 'City is required';
  }

  if (!state) {
    errors.depositoryAccountStateError = 'State is required';
  }

  if (!zipCode) {
    errors.depositoryAccountZipCodeError = 'Zip code is required';
  }

  return errors;
};
