import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'office-ui-fabric-react/lib/Link';
import {
  DefaultButton,
  PrimaryButton,
} from 'office-ui-fabric-react/lib/Button';
import { Checkbox } from 'office-ui-fabric-react/lib/Checkbox';
import { Grid, GridRow, Column, LabelGroup } from '../common';
import { DatePicker, FiledReportPicker } from '../Pickers';
import {
  FECSection6a,
  FECSection6b,
  FECSection6c,
  FECSection6d,
  FECSection7,
  FECSection8,
  FECSection9,
  FECSection10,
} from './NonCandidateSections';

const FECNonCandidateSummary = ({
  campaign,
  currentReport,
  handleChange,
  handleChangeDate,
  toggleExpandSection,
  state,
  sectionName,
}) => {
  return (
    <div
      className="FECSummaryPage fec-report-section depth-1"
      name={sectionName}
    >
      <h3>
        SUMMARY PAGE OF RECEIPTS AND DISBURSEMENTS{' '}
        <small>
          {' '}
          (
          <Link onClick={() => toggleExpandSection(sectionName, false)}>
            {state.expandableSections[sectionName] === true
              ? 'Collapse'
              : 'Expand'}
          </Link>
          )
        </small>
      </h3>
      <div
        className={`edit-fec-summary-page${
          state.expandableSections[sectionName] === true
            ? '-expanded'
            : '-collapsed'
        }`}
      >
        <Grid>
          <GridRow>
            <Column>
              <LabelGroup
                label="Committee Name"
                value={campaign.candidateOrCommitteeFullName || ''}
              />
            </Column>
          </GridRow>
          <GridRow>
            <Column lg={6}>
              <DatePicker
                label="Report Covering the Period From"
                value={state.startDate}
                required
                errorMessage={state.errors.startDateError}
                onChange={handleChangeDate('startDate')}
              />
            </Column>
            <Column lg={6}>
              <DatePicker
                label="To"
                value={state.endDate}
                required
                errorMessage={state.errors.endDateError}
                onChange={handleChangeDate('endDate')}
              />
            </Column>
          </GridRow>
          <GridRow>
            <Column lg={6}>
              <FiledReportPicker
                label="Previous Report Balance Source"
                selectedKey={state.previousReportId || state.previousBalanceSource || '0'}
                onChange={handleChange('previousReportId')}
                errorMessage={state.errors.previousReportIdError}
                reportId={currentReport?.report?._id}
                required
              />
            </Column>
          </GridRow>
          <GridRow style={{ marginTop: 16, borderTop: '3px solid #919191' }}>
            <Column sm={4} classNames="ms-smPush4 ms-textAlignCenter">
              <p style={{ borderBottom: '1px solid #919191' }}>
                <strong>COLUMN A</strong>
                <br />
                <strong>This Period</strong>
              </p>
            </Column>
            <Column sm={4} classNames="ms-smPush4 ms-textAlignCenter">
              <p style={{ borderBottom: '1px solid #919191' }}>
                <strong>COLUMN B</strong>
                <br />
                <strong>Calendar Year-to-Date</strong>
              </p>
            </Column>
          </GridRow>
          <FECSection6a
            colB_Year={state.fecPacSummary.colB_Year_ForAbove}
            colB_6a={state.fecPacSummary.colB_6a_CashOnHandJan1_19}
          />
          <FECSection6b
            cashOnHandAtBeginning={state.fecPacSummary.colA_6b_CashOnHandBeginning}
          />
          <FECSection6c
            q6bColA={state.q6bColA}
            q6bColB={state.q6bColB}
          />
          <FECSection6d
            q6bColA={state.q6bColA}
            q6bColB={state.q6bColB}
          />
          <FECSection7
            q7aColA={state.q7aColA}
            q7aColB={state.q7aColB}
          />
          <FECSection8
            q8ColA={state.q8ColA}
            q8ColB={state.q8ColB}
          />
          <FECSection9 q9ColA={state.q9ColA} />
          <FECSection10 q10ColA={state.q10ColA} />
          <GridRow style={{
            borderTop: '1px solid #919191',
            borderBottom: '1px solid #919191',
          }}
          >
            <Column sm={4} style={{ padding: '16px 8px' }}>
              <Checkbox
                label="This committee has qualified as a multicandidate committee. (see FEC FORM 1M)"
                checked={state.isMultiCandidate}
                disabled={true}
              />
            </Column>
          </GridRow>
          <GridRow>
            <Column classNames="ms-textAlignCenter">
              <h4>For further information contact:</h4>
              <span>Federal Election Commission</span>
              <br />
              <address>
                <span>1050 First Street, N.E.</span>
                <br />
                <span>Washington, DC 20463</span>
              </address>
              <br />
              <span>{'Toll Free: 800-424-9530'}</span>
              <br />
              <span>{'Local: 202-694-1100'}</span>
            </Column>
          </GridRow>
        </Grid>
        <div className="section-actions">
          <DefaultButton
            text="Previous Section"
            style={{ marginRight: 16 }}
            onClick={() => toggleExpandSection('coverpage', false)}
          />
          <PrimaryButton
            text="Save & Continue"
            onClick={() => toggleExpandSection('summaryDetailedReceipts', true)}
          />
        </div>
      </div>
    </div>
  );
};

FECNonCandidateSummary.propTypes = {
  campaign: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleChangeDate: PropTypes.func.isRequired,
  toggleExpandSection: PropTypes.func.isRequired,
  summaryPageExpanded: PropTypes.bool,
  state: PropTypes.object.isRequired,
  sectionName: PropTypes.string.isRequired,
  currentReport: PropTypes.object,
};

FECNonCandidateSummary.defaultProps = {
  currentReport: {},
};

export default FECNonCandidateSummary;
