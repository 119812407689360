export const buildCampaignDescription = campaign => {
  if (campaign._id === '66ff7a988c16d9ec2bd573ac') {
    return 'Bird, Brenna (State: Attorney General)';
  }
  if (
    !campaign.candidate ||
    !campaign.candidate.firstName ||
    !campaign.candidate.lastName ||
    /team mayra/i.test(campaign.campaignName) // Kludge to allow candidate name association with Team Mayra for 48hr
  ) {
    return campaign.campaignName;
  }
  if (campaign.officeType === 'Federal') {
    return `${campaign.candidate.lastName}, ${campaign.candidate.firstName} (${
      campaign.officeType
    }: ${campaign.officeName.replace(', undefined', '')})`;
  }
  return `${campaign.candidate.lastName}, ${campaign.candidate.firstName} (${
    campaign.officeType
  }: ${campaign.officeState || ''} ${campaign.officeName.replace(
    ', undefined',
    '',
  )})`;
};

export const transformCampaignsForSelection = item => {
  const key = item._id;
  const text = buildCampaignDescription(item);
  return {
    key,
    text,
  };
};
