import moment from 'moment';
import { formatAddress } from '../../helpers/util';

const CONTACT_KEYS_FOR_META = [
  'displayName',
  'occupation',
  'employer',
  'contactType',
  'email',
  'phone1',
  'formattedAddress',
];

export const fillInEmptyAttribute = (attrName, metaRecord, list) => {
  return list.reduce((a, m) => {
    if (!a[attrName]?.value || m[attrName]?.isPinned) {
      a[attrName] = m[attrName];
    }
    return a;
  }, { ...metaRecord });
};

export const fillInEmptyAttributes = (metaRecord, list) => {
  // Don't accidentally drill into working list calculating the composite
  let meta = JSON.parse(JSON.stringify(metaRecord));
  const mergeContactList = JSON.parse(JSON.stringify(list));

  for (let i = 0; i < CONTACT_KEYS_FOR_META.length; i += 1) {
    const key = CONTACT_KEYS_FOR_META[i];
    meta = fillInEmptyAttribute(key, meta, mergeContactList);
  }
  const metaKeys = Object.keys(meta);
  for (let i = 0; i < metaKeys.length; i += 1) {
    const k = metaKeys[i];
    if (!CONTACT_KEYS_FOR_META.includes(k)) {
      delete meta[k];
    }
  }
  return meta;
};

export const flattenContact = (c) => {
  const {
    _id,
    firstName,
    middleName,
    lastName,
    salutation,
    suffix,
    businessName,
    displayName,
    occupation,
    employer,
    address,
    contactType,
    email,
    phone1,
    updatedAt,
  } = c;

  const {
    addressLine1,
    addressLine2,
    city,
    state,
    zipCode,
    county,
  } = (address || {});

  return {
    _id,
    firstName,
    middleName,
    lastName,
    displayName,
    salutation,
    suffix,
    businessName,
    occupation,
    employer,
    contactType,
    email,
    phone1,
    updatedAt,
    addressLine1,
    addressLine2,
    city,
    state,
    zipCode,
    county,
  };
};

export const decorateContactForUI = (c) => {
  const {
    _id,
    firstName,
    middleName,
    lastName,
    displayName,
    salutation,
    suffix,
    businessName,
    occupation,
    employer,
    contactType,
    email,
    phone1,
    updatedAt,
    addressLine1,
    addressLine2,
    city,
    state,
    zipCode,
    county,
  } = c;
  const mockContactForAddress = {
    address: {
      addressLine1,
      addressLine2,
      city,
      state,
      zipCode,
    },
  };
  const formattedAddress = formatAddress(mockContactForAddress);

  return {
    _id,
    isPartOfMerge: true,
    firstName: { value: firstName, id: _id },
    middleName: { value: middleName, id: _id },
    lastName: { value: lastName, id: _id },
    displayName: { value: displayName, id: _id },
    salutation: { value: salutation, id: _id },
    suffix: { value: suffix, id: _id },
    businessName: { value: businessName, id: _id },
    occupation: { value: occupation, id: _id },
    employer: { value: employer, id: _id },
    contactType: { value: contactType, id: _id },
    email: { value: email, id: _id },
    phone1: { value: phone1, id: _id },
    updatedAt: { value: updatedAt, id: _id },
    addressLine1: { value: addressLine1, id: _id },
    addressLine2: { value: addressLine2, id: _id },
    city: { value: city, id: _id },
    state: { value: state, id: _id },
    zipCode: { value: zipCode, id: _id },
    county: { value: county, id: _id },
    formattedAddress: { value: formattedAddress, id: _id },
  };
};

export const getContactFromList = (list, id) => {
  return list.find(c => c._id === id);
};

export const getListExcludingContact = (list, id) => {
  return list.filter(c => c._id !== id);
};

export const sortDecoratedObjects = (list) => {
  return list.sort((a, b) => {
    return moment(a.updatedAt.value).isSameOrBefore(moment(b.updatedAt.value)) ? 1 : -1;
  });
};

export const getFlatDecoratedListFromContactToMerge = (contactToMerge) => {
  const topLevelRec = flattenContact(contactToMerge);
  let allFlatContacts = [topLevelRec, ...contactToMerge.matches];
  allFlatContacts = allFlatContacts
    .map(c => decorateContactForUI(c));
  return sortDecoratedObjects(allFlatContacts);
};

export const recordsInMerge = (records) => {
  return records.filter(r => r.isPartOfMerge);
};

export const recordsNotInMerge = (records) => {
  return records.filter(r => !r.isPartOfMerge);
};

export const createUpdateData = (metaRecord, list) => {
  const mergeContactList = sortDecoratedObjects(recordsInMerge(list));
  // sorted newest to oldest, grab oldest rec for target
  const targetContactOffset = mergeContactList.length - 1;
  const primaryContactId = mergeContactList[targetContactOffset]._id;
  // grab the rest of the ids to identify contacts that need merged
  const contactIds = mergeContactList.slice(0, targetContactOffset).map(r => r._id);

  const {
    addressLine1,
    addressLine2,
    city,
    state,
    zipCode,
    county,
  } = getContactFromList(mergeContactList, metaRecord?.formattedAddress?.id);

  const {
    firstName,
    middleName,
    lastName,
    salutation,
    suffix,
    businessName,
  } = getContactFromList(mergeContactList, metaRecord?.displayName?.id);

  const {
    occupation,
    employer,
    email,
    phone1,
  } = metaRecord;

  const primaryContact = {
    address: {
      addressLine1: addressLine1.value,
      addressLine2: addressLine2.value,
      city: city.value,
      state: state.value,
      zipCode: zipCode.value,
      county: county.value,
    },
    firstName: firstName.value,
    middleName: middleName.value,
    lastName: lastName.value,
    salutation: salutation.value,
    suffix: suffix.value,
    businessName: businessName.value,
    occupation: occupation.value,
    employer: employer.value,
    email: email.value,
    phone1: phone1.value,
  };

  return {
    url: `/api/filer/contacts/${primaryContactId}/consolidate`,
    primaryContactId,
    payload: {
      primaryContact,
      contactIds,
    },
  };
};
