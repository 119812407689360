import React, { useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { types as searchValueActions } from '../../reducers/searchValueReducer';
import { types as userActions } from '../../actions/userActions';
import { scrollToTop } from '../../helpers/util';
import { statuses } from '../../helpers/constants';
import Loading from '../../components/Loading';
import DashboardCampaignInfo from './DashboardCampaignInfo';
import DashboardCampaignFinances from './DashboardCampaignFinances';
import DashboardActions from './DashboardActions';
import AccountInactive from './AccountInactive';
import GenericMessageDialog from '../../components/Dialogs/GenericMessageDialog';

import {
  getUser,
  getUserSession,
  getCurrentCampaign,
} from '../../selectors';
import './MainDashboard.css';

export const MainDashboard = ({ history }) => {
  const reduxDispatch = useDispatch();

  const session = useSelector(getUserSession);
  const user = useSelector(getUser);
  const currentCampaign = useSelector(getCurrentCampaign);
  const officeType = currentCampaign && currentCampaign.campaign && currentCampaign.campaign.officeType || '';
  const campaignId = currentCampaign && currentCampaign.campaign && currentCampaign.campaign.id;

  useEffect(() => {
    reduxDispatch({ type: searchValueActions.CLEAR_SEARCH });
  }, []);

  useEffect(() => {
    if (session) {
      scrollToTop();
    }
  }, [session]);

  if (
    currentCampaign.campaign &&
    currentCampaign.campaign.status === 'Inactive'
  ) {
    return <AccountInactive history={history} />;
  }

  const clearMessage = () => {
    reduxDispatch({
      type: userActions.USER_LOGIN_CLEAR_MESSAGE,
      data: { campaignId },
    });
  };

  const GAFilerMessage = (
    <div>
      <p>FrontRunner is working with the State of Georgia as it implements its integration of the FrontRunner application and Georgia’s new e-filing platform.</p>
      <p>We appreciate your patience as both tech teams work through the complexities of these types of integrations to ensure accuracy.</p>
      <p>Once your data is final and you are ready to file, please contact FrontRunner through the Intercom chat application when you are logged in or send an email to support@frontrunner.app as we will be assisting in your filing to ensure the transmittal is accurate.</p>
      <p>We look forward to a more seamless process for later reports, and are working diligently with the State Agency to ensure compliance. There are some early delays as everything is smoothed out but be assured you will be timely with your report when it is handed off for us to transmit.</p>
    </div>
  );
  const GAMessageHidden = user.hasSeenMessageList.includes(campaignId);
  return (
    <Fragment>
      <div className="MainDashboard">
        {!currentCampaign.campaign &&
          session &&
          !session.isSysAdminRole() &&
          currentCampaign.allCampaigns.length === 1 && <Loading />}
        {currentCampaign.getCurrentCampaignStatus === statuses.PROCESSING &&
          <Loading />
        }
        {currentCampaign?.campaign?.status === 'Active' && (
          <div className="dashboard-content">
            <DashboardCampaignInfo
              user={user}
              history={history}
              currentCampaign={currentCampaign}
            />

            <DashboardCampaignFinances
              user={user}
              history={history}
              currentCampaign={currentCampaign}
            />

            <DashboardActions user={user} history={history} />
          </div>
        )}
        {officeType !== 'Federal' &&
        <GenericMessageDialog
          dialogHidden={GAMessageHidden || !currentCampaign.campaign}
          onCancel={clearMessage}
          titleText={'Filing Instruction'}
          message={GAFilerMessage}
        />
        }
      </div>
    </Fragment>
  );
};

MainDashboard.displayName = 'MainDashboard';

MainDashboard.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(MainDashboard);
