import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { Dropdown } from 'office-ui-fabric-react/lib/Dropdown';
import { PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import { sortByField, scrollToTop } from '../../helpers/util';
import { Grid, GridRow, Column } from '../../components/common';
import { YearPicker, ReportingFrequencyPicker } from '../../components/Pickers';
import { CaretDown } from '../../components/icons';
import { signupOfficeTypes } from '../../helpers/constants';
import './OfficeDetails.css';

export const OfficeDetails = ({
  office,
  isNonCandidateCommittee,
  offices,
  actions,
}) => {
  useEffect(() => {
    scrollToTop();
  }, []);

  const {
    candidateFirstName,
    candidateFirstNameError,
    candidateMiddleName,
    candidateLastName,
    candidateLastNameError,
    officeType,
    officeTypeError,
    officeState,
    officeStateError,
    officeCategory,
    officeCategoryError,
    office: selectedOffice,
    officeError,
    suboffice,
    subofficeError,
    nextElectionYear,
    nextElectionYearError,
    reportingFrequency = '',
    reportingFrequencyError = '',
  } = office;

  const officeStates =
    officeType !== 0 ? offices[officeType].states || null : null;

  const officeCategories =
    officeType === signupOfficeTypes.LOCAL &&
    officeStates &&
    offices[officeType].states[officeState]
      ? offices[officeType].states[officeState].offices || null
      : null;

  const federalOffices =
    officeType === signupOfficeTypes.FEDERAL &&
    officeState &&
    offices[officeType].states[officeState]
      ? offices[officeType].states[officeState].offices || null
      : null;

  const stateOffices =
    officeType === signupOfficeTypes.STATE &&
    officeState &&
    offices[officeType].states[officeState]
      ? offices[officeType].states[officeState].offices || null
      : null;

  const localOffices =
    officeType === signupOfficeTypes.LOCAL && officeCategory
      ? offices[officeType].states[officeState].offices[officeCategory]
          .suboffices || null
      : null;

  const stateSubOffices =
    officeType === signupOfficeTypes.STATE && stateOffices && selectedOffice
      ? offices[officeType].states[officeState].offices[selectedOffice]
          .suboffices || null
      : null;

  const federalSubOffices =
    officeType === signupOfficeTypes.FEDERAL && federalOffices && selectedOffice
      ? offices[officeType].states[officeState].offices[selectedOffice]
          ?.suboffices || null
      : null;

  return (
    <Grid className="OfficeDetails">
      {!isNonCandidateCommittee && (
        <GridRow>
          <Column>
            <TextField
              label="Candidate First Name"
              value={candidateFirstName}
              errorMessage={candidateFirstNameError}
              onChange={actions.handleOfficeTextChange('candidateFirstName')}
              required
            />
          </Column>
          <Column>
            <TextField
              label="Candidate Middle Name"
              value={candidateMiddleName}
              onChange={actions.handleOfficeTextChange('candidateMiddleName')}
            />
          </Column>
          <Column>
            <TextField
              label="Candidate Last Name"
              value={candidateLastName}
              errorMessage={candidateLastNameError}
              onChange={actions.handleOfficeTextChange('candidateLastName')}
              required
            />
          </Column>
        </GridRow>
      )}
      <GridRow>
        <Column lg={8}>
          <Dropdown
            onRenderCaretDown={() => <CaretDown />}
            label="Office Type"
            onChange={actions.handleOfficeTypeChange}
            selectedKey={officeType}
            dropdownWidth={250}
            errorMessage={officeTypeError}
            placeholder="Select"
            options={[
              ...Object.values(offices).map(office => ({
                key: office.id,
                text: office.name,
              })),
            ]}
            required
          />
        </Column>
        <Column lg={4}>
          <Dropdown
            onRenderCaretDown={() => <CaretDown />}
            label="Office State"
            required
            selectedKey={officeState}
            onChange={actions.handleOfficeStateChange}
            errorMessage={officeStateError}
            dropdownWidth={250}
            disabled={!officeStates}
            placeholder="Select"
            options={
              !officeStates
                ? []
                : [
                    ...Object.values(officeStates)
                      .sort(sortByField('name'))
                      .map((st) => {
                        const [value, name] = Object.values(st);
                        return {
                          key: value,
                          text: name,
                        };
                      }),
                  ]
            }
          />
        </Column>
        {officeType === signupOfficeTypes.LOCAL && (
          <Column lg={6}>
            <Dropdown
              onRenderCaretDown={() => <CaretDown />}
              label="Office Category"
              required
              selectedKey={officeCategory}
              onChange={actions.handleOfficeCategoryChange}
              errorMessage={officeCategoryError}
              dropdownWidth={250}
              disabled={!officeCategories}
              placeholder="Select"
              options={
                !officeCategories
                  ? []
                  : [
                      ...Object.values(officeCategories)
                        .sort(sortByField('name'))
                        .map(oc => {
                          const [id, name] = Object.values(oc);
                          return {
                            key: id,
                            text: name,
                          };
                        }),
                    ]
              }
            />
          </Column>
        )}
        {officeType === signupOfficeTypes.FEDERAL && (
          <Column lg={6}>
            <Dropdown
              onRenderCaretDown={() => <CaretDown />}
              selectedKey={selectedOffice}
              label="Office"
              onChange={actions.handleOfficeChange}
              required
              errorMessage={officeError}
              placeholder="Select"
              disabled={!federalOffices}
              options={
                !federalOffices
                  ? []
                  : [
                      ...Object.values(federalOffices)
                        .slice()
                        .sort(sortByField('name'))
                        .map(fo => {
                          const [id, name] = Object.values(fo);
                          return {
                            key: id,
                            text: name,
                          };
                        }),
                    ]
              }
            />
          </Column>
        )}
        {officeType === signupOfficeTypes.STATE && (
          <Column lg={6}>
            <Dropdown
              onRenderCaretDown={() => <CaretDown />}
              selectedKey={selectedOffice}
              label="Office"
              onChange={actions.handleOfficeChange}
              required
              errorMessage={officeError}
              dropdownWidth={250}
              placeholder="Select"
              disabled={!stateOffices}
              options={
                !stateOffices
                  ? []
                  : [
                      ...Object.values(stateOffices)
                        .slice()
                        .sort(sortByField('name'))
                        .map(so => {
                          const [id, name] = Object.values(so);
                          return {
                            key: id,
                            text: name,
                          };
                        }),
                    ]
              }
            />
          </Column>
        )}
        {officeType === signupOfficeTypes.LOCAL && (
          <Column lg={6}>
            <Dropdown
              onRenderCaretDown={() => <CaretDown />}
              selectedKey={selectedOffice}
              label="Office"
              required
              errorMessage={officeError}
              onChange={actions.handleOfficeChange}
              dropdownWidth={250}
              placeholder="Select"
              disabled={!localOffices}
              options={
                !localOffices
                  ? []
                  : [
                      ...Object.values(localOffices)
                        .sort(sortByField('name'))
                        .map(lo => {
                          const [id, name] = Object.values(lo);
                          return {
                            key: id,
                            text: name,
                          };
                        }),
                    ]
              }
            />
          </Column>
        )}
        {officeType === signupOfficeTypes.FEDERAL && selectedOffice === 2 && (
          <Column lg={6}>
            <Dropdown
              onRenderCaretDown={() => <CaretDown />}
              required
              label="Sub Office"
              onChange={actions.handleStateSubOfficeChange}
              selectedKey={suboffice}
              errorMessage={subofficeError}
              placeholder="Select"
              disabled={!federalSubOffices}
              options={
                !federalSubOffices
                  ? []
                  : [
                      ...Object.values(federalSubOffices).map(fso => {
                        const [id, name] = Object.values(fso);
                        return {
                          key: id,
                          text: name,
                        };
                      }),
                    ]
              }
            />
          </Column>
        )}
        {officeType === signupOfficeTypes.STATE && (
          <Column lg={6}>
            <Dropdown
              onRenderCaretDown={() => <CaretDown />}
              required
              label="Sub Office"
              onChange={actions.handleStateSubOfficeChange}
              selectedKey={suboffice}
              dropdownWidth={250}
              errorMessage={subofficeError}
              placeholder="Select"
              disabled={!stateSubOffices}
              options={
                !stateSubOffices
                  ? []
                  : [
                      ...Object.values(stateSubOffices).map(sso => {
                        const [id, name] = Object.values(sso);
                        return {
                          key: id,
                          text: name,
                        };
                      }),
                    ]
              }
            />
          </Column>
        )}
        {officeType === signupOfficeTypes.LOCAL && (
          <Column lg={6}>
            <TextField
              label="Sub Office"
              required
              value={suboffice}
              disabled={selectedOffice === 0}
              onChange={actions.handleOfficeTextChange('suboffice')}
              errorMessage={subofficeError}
            />
          </Column>
        )}
      </GridRow>
      {officeType !== 0 && (
        <GridRow>
          <Column lg={6}>
            <YearPicker
              required
              label="Next Election Year"
              value={nextElectionYear}
              errorMessage={nextElectionYearError}
              onChange={actions.handleNextElectionYearChange}
            />
          </Column>
          {officeType === signupOfficeTypes.FEDERAL && (
            <Column lg={6}>
              <ReportingFrequencyPicker
                handleChange={actions.handleChangeReportingFrequency}
                value={reportingFrequency}
                required
                errorMessage={reportingFrequencyError}
              />
            </Column>
          )}
        </GridRow>
      )}
      <GridRow className="actions">
        <Column classNames="ms-textAlignRight">
          <PrimaryButton text="Next" onClick={actions.handleNextStep} />
        </Column>
      </GridRow>
    </Grid>
  );
};

OfficeDetails.propTypes = {
  isNonCandidateCommittee: PropTypes.bool,
  office: PropTypes.shape({
    candidateFirstName: PropTypes.string,
    candidateFirstNameError: PropTypes.string,
    candidateMiddleName: PropTypes.string,
    candidateLastName: PropTypes.string,
    candidateLastNameError: PropTypes.string,
    candidateEmailAddress: PropTypes.string,
    candidateEmailAddressError: PropTypes.string,
    officeType: PropTypes.number,
    officeTypeError: PropTypes.string,
    officeState: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    officeStateError: PropTypes.string,
    officeCategory: PropTypes.number,
    officeCategoryError: PropTypes.string,
    office: PropTypes.number,
    officeError: PropTypes.string,
    suboffice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    subofficeError: PropTypes.string,
    nextElectionYear: PropTypes.number,
    nextElectionYearError: PropTypes.string,
    reportingFrequency: PropTypes.string,
    reportingFrequencyError: PropTypes.string,
  }).isRequired,
  offices: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

OfficeDetails.defaultProps = {
  isNonCandidateCommittee: false,
};

export default OfficeDetails;
