import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'office-ui-fabric-react/lib/Dropdown';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { CaretDown } from '../icons';
import { gaPaymentCodes, GA_PAYMENT_CODE_OTHER } from '../../helpers/constants';

const GAPaymentCodePicker = ({
  pickerValue = 0,
  otherValue = '',
  className = '',
  errorMessage = '',
  required = false,
  style = {},
  label = 'Payment Code',
  placeholder = 'Select Payment Code',
  onPickerChange,
  onOtherChange,
  disabled = false,
}) => {
  return (
    <>
      <Dropdown
        onRenderCaretDown={() => <CaretDown />}
        selectedKey={pickerValue}
        onChange={onPickerChange}
        className={className}
        placeholder={placeholder}
        style={style}
        errorMessage={errorMessage}
        required={required}
        label={label}
        disabled={disabled}
        options={[
          { key: 0, text: 'Select' },
          ...gaPaymentCodes,
        ]}
      />
      {pickerValue === GA_PAYMENT_CODE_OTHER &&
      <TextField
        label="Payment Code Other Description"
        value={otherValue}
        onChange={onOtherChange}
        required={required}
      />
      }
    </>
  );
};

GAPaymentCodePicker.propTypes = {
  pickerValue: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.object,
  ]),
  otherValue: '',
  onPickerChange: PropTypes.func.isRequired,
  onOtherChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  errorMessage: PropTypes.string,
  required: PropTypes.bool,
  style: PropTypes.object,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
};

export default GAPaymentCodePicker;
