export const types = {
  CREATE_FEDERAL_REPORT: 'CREATE_FEDERAL_REPORT',
  CREATE_FEDERAL_REPORT_PROCESSING: 'CREATE_FEDERAL_REPORT_PROCESSING',
  CREATE_FEDERAL_REPORT_SUCCESS: 'CREATE_FEDERAL_REPORT_SUCCESS',
  CREATE_FEDERAL_REPORT_FAILURE: 'CREATE_FEDERAL_REPORT_FAILURE',
  RESET_FEDERAL_REPORT_STATE: 'RESET_FEDERAL_REPORT_STATE',
};

export const actions = {
  createReport(reportDetails) {
    return { type: types.CREATE_FEDERAL_REPORT, data: { reportDetails } };
  },
};
