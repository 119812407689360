import axios from 'axios';
import { replace } from 'connected-react-router';
import { call, put, takeLatest } from 'redux-saga/effects';
import { types } from '../actions/importListActions';
import { errorToast, getServerSideErrorMessage, infoToast } from '../helpers/util';
import { importStatusWatchWorker } from './importPollingSaga';

const url = '/api/filer/contributions/import';

export function* handleGetImportList(action) {
  try {
    yield put({ type: types.GET_IMPORT_LIST_PROCESSING });
    const { data } = yield call(axios.get, url, { params: action.data }, {
      withCredentials: true,
    });

    yield put({
      type: types.GET_IMPORT_LIST_SUCCESS,
      data,
    });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put(errorToast(error));
    yield put({ type: types.GET_IMPORT_LIST_FAILURE, error });
  }
}

export function* getImportList() {
  yield takeLatest(types.GET_IMPORT_LIST, handleGetImportList);
}

export function* handleFinalizeImport(action) {
  try {
    yield put({ type: types.FINALIZE_IMPORT_PROCESSING, data: action.data });
    yield call(axios.put, `${url}/${action.data.fileId}/finalize`, {
      withCredentials: true,
    });

    if (action.data.redirectToImportList) {
      yield put(replace('/filer/dev/importList'));
    }
    yield put({ type: types.FINALIZE_IMPORT_SUCCESS, data: action.data });
    yield put(infoToast('File import is finalizing'));
    yield call(importStatusWatchWorker, action.data.fileId);
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put(errorToast(error));
    yield put({ type: types.FINALIZE_IMPORT_FAILURE, error });
  }
}

export function* finalizeImport() {
  yield takeLatest(types.FINALIZE_IMPORT, handleFinalizeImport);
}
