import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'office-ui-fabric-react/lib/Link';
import { DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { Grid, GridRow, Column, MoneyField } from '../../components/common';
import { DatePicker } from '../../components/Pickers';
import './EditBalanceDetails.css';

const FECEditBalanceDetailsFederalPac = ({
  actions,
  campaign,
  balanceDetailsExpanded = false,
  session,
}) => (
  <div className="EditBalanceDetails depth-1" name="balance-details">
    <h3>
      Beginning Balance information{' '}
      <small>
        {' '}
        (
        <Link onClick={actions.toggleExpandBalanceDetails}>
          {balanceDetailsExpanded ? 'Collapse' : 'Expand'}
        </Link>
        )
      </small>
    </h3>
    <div
      className={`edit-balance-details-content${
        balanceDetailsExpanded ? ' expanded' : ''
      }`}
    >
      <Grid>
        <GridRow>
          <Column sm={9} lg={6}>
            <MoneyField
              label="Starting Bank Balance"
              value={campaign.openingCashAmount}
              onChange={actions.handleTextChange('openingCashAmount')}
            />
          </Column>
          <Column sm={3} lg={6}>
            <DatePicker
              label="As of Date"
              value={campaign.openingCashAmountDate || new Date(2016, 0, 1)}
              onChange={actions.handleDateChange('openingCashAmountDate')}
            />
          </Column>
        </GridRow>
      </Grid>
    </div>
    {balanceDetailsExpanded && (
      <div className="ms-textAlignRight">
        <DefaultButton
          style={{ marginRight: 8 }}
          text="Back"
          onClick={() =>
            actions.previousStep(
              campaign.campaignCommitteeRegistered ||
                campaign.isNonCandidateCommittee ||
                session.isFederal()
                ? 4
                : 2,
            )
          }
        />
        <DefaultButton
          text="Continue"
          onClick={() => actions.nextStep(session.isFederal() ? 7 : 6)}
        />
      </div>
    )}
  </div>
);

FECEditBalanceDetailsFederalPac.propTypes = {
  actions: PropTypes.object.isRequired,
  campaign: PropTypes.object.isRequired,
  balanceDetailsExpanded: PropTypes.bool,
  session: PropTypes.object.isRequired,
};

export default FECEditBalanceDetailsFederalPac;
