import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { GridRow, Column, MoneyField } from '../../common';

const FECSection20c = ({ q20cColA, q20cColB }) => {
  return (
    <Fragment>
      <GridRow>
        <Column sm={4} classNames="sub-question">
          <p>
            (c) Other Political Committees
            <br />
            {'(such as PACs)'}
          </p>
        </Column>
        <Column sm={4} classNames="entry-row">
          <MoneyField
            disabled
            value={q20cColA || 0}
          />
        </Column>
        <Column sm={4} classNames="entry-row">
          <MoneyField
            disabled
            value={q20cColB || 0}
          />
        </Column>
      </GridRow>
    </Fragment>
  );
};

FECSection20c.propTypes = {
  q20cColA: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  q20cColB: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default FECSection20c;
