import { createReducer } from '../../../reducers/createReducer';

export const initialState = {
  paymentReferenceId: '',
  purpose: '',
  amount: '',
  electionYear: 0,
  electionCycle: 0,
  otherElectionType: '',
  disbursementCategory: 0,
  datePaid: '',
  selectedContact: null,
  usingNewContact: false,
  contactType: 0,
  contactId: null,
  salutation: 'none',
  firstName: '',
  middleName: '',
  lastName: '',
  occupation: '',
  employer: '',
  businessName: '',
  businessType: '',
  contactName: '',
  committeeAffiliation: 0,
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: 0,
  zipCode: '',
  county: '',
  committeeFecId: '',
  phone1: '',
  phone2: '',
  email: '',
  errors: {
    amount: '',
    electionYear: '',
    electionCycle: '',
    otherElectionType: '',
    disbursementCategory: '',
    datePaid: '',
    email: '',
    selectedContact: '',
    contactType: '',
    firstName: '',
    lastName: '',
    businessName: '',
    committeeFecId: '',
  },
};

const clearSelectedContact = () => ({
  selectedContact: null,
  contactType: 0,
  contactId: null,
  salutation: 'none',
  firstName: '',
  middleName: '',
  lastName: '',
  occupation: '',
  employer: '',
  businessName: '',
  businessType: '',
  contactName: '',
  committeeAffiliation: 0,
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: 0,
  zipCode: '',
  county: '',
  committeeFecId: '',
  phone1: '',
  phone2: '',
  email: '',
});

export const actions = {
  HANDLE_CHANGE: 'HANDLE_CHANGE',
  SET_FORM_ERRORS: 'SET_FORM_ERRORS',
  RESET_FORM: 'RESET_FORM',
  SET_DEFAULT_ELECTION_YEAR: 'SET_DEFAULT_ELECTION_YEAR',
  CLEAR_SELECTED_CONTACT: 'CLEAR_SELECTED_CONTACT',
  ON_CONTACT_SELECTED: 'ON_CONTACT_SELECTED',
  RESET_SELECTED_CONTACT_INFO: 'RESET_SELECTED_CONTACT_INFO',
};

export const actionMap = {
  [actions.HANDLE_CHANGE]: (state, { data: { fieldName, value } }) => ({
    ...state,
    [fieldName]: value,
  }),
  [actions.SET_FORM_ERRORS]: (state, { data: { errors } }) => ({
    ...state,
    errors: {
      ...state.errors,
      ...errors,
    },
  }),
  [actions.RESET_FORM]: state => ({
    ...initialState,
    electionYear: state.electionYear,
    electionCycle: state.electionCycle,
  }),
  [actions.SET_DEFAULT_ELECTION_YEAR]: (
    state,
    { data: { nextElectionYear } },
  ) => ({
    ...state,
    electionYear: nextElectionYear,
  }),
  [actions.CLEAR_SELECTED_CONTACT]: state => ({
    ...state,
    ...clearSelectedContact(),
  }),
  [actions.ON_CONTACT_SELECTED]: (state, { data: { contact } }) => {
    return {
      ...state,
      selectedContact: contact,
      contactId: contact._id || null,
      contactType: contact.contactType,
      salutation: contact.salutation || 'none',
      firstName: contact.firstName || '',
      middleName: contact.middleName || '',
      lastName: contact.lastName || '',
      employer: contact.employer || '',
      occupation: contact.occupation || '',
      businessName: contact.businessName || '',
      businessType: contact.businessType || 0,
      contactName: contact.contactName || '',
      committeeAffiliation: contact.committeeAffiliation || 0,
      addressLine1: contact.address?.addressLine1 || '',
      addressLine2: contact.address?.addressLine2 || '',
      city: contact.address?.city || '',
      state: contact.address?.state || 0,
      zipCode: contact.address?.zipCode || '',
      county: contact.address?.county || '',
      phone1: contact.phone1 || '',
      phone2: contact.phone2 || '',
      email: contact.email || '',
      committeeFecId: contact.committeeFecId || '',
    };
  },
  [actions.RESET_SELECTED_CONTACT_INFO]: state => {
    if (state.selectedContact) {
      return {
        ...state,
        usingNewContact: false,
        contactType: state.selectedContact.contactType || 0,
        contactId: state.selectedContact._id || undefined,
        salutation: state.selectedContact.salutation || 'none',
        firstName: state.selectedContact.firstName || '',
        middleName: state.selectedContact.middleName || '',
        lastName: state.selectedContact.lastName || '',
        employer: state.selectedContact.employer || '',
        occupation: state.selectedContact.occupation || '',
        addressLine1: state.selectedContact.address?.addressLine1 || '',
        addressLine2: state.selectedContact.address?.addressLine2 || '',
        city: state.selectedContact.address?.city || '',
        state: state.selectedContact.address?.state || 0,
        zipCode: state.selectedContact.address?.zipCode || '',
        county: state.selectedContact.address?.county || '',
        phone1: state.selectedContact.phone1 || '',
        phone2: state.selectedContact.phone2 || '',
        email: state.selectedContact.email || '',
        businessName: state.selectedContact.businessName || '',
        businessType: state.selectedContact.businessType || 0,
        contactName: state.selectedContact.contactName || '',
        committeeAffiliation: state.selectedContact.committeeAffiliation || 0,
        committeeFecId: state.selectedContact.committeeFecId || '',
      };
    }

    return {
      ...state,
      selectedContact: null,
      usingNewContact: false,
      contactId: undefined,
      contactType: 0,
      salutation: 'none',
      firstName: '',
      middleName: '',
      lastName: '',
      employer: '',
      occupation: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: 0,
      zipCode: '',
      county: '',
      phone1: '',
      phone2: '',
      email: '',
      businessName: '',
      businessType: 0,
      contactName: '',
      committeeAffiliation: 0,
      committeeFecId: '',
    };
  },
};

export const BankFeePanelReducer = createReducer(initialState, actionMap);
