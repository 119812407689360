import React, { useRef, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import {
  PrimaryButton,
  DefaultButton,
} from 'office-ui-fabric-react/lib/Button';
import { Grid, GridRow, Column } from '../../components/common';

export const Verification = ({
  verificationCode,
  verificationCodeError,
  actions,
}) => {
  const focusRef = useRef();

  useEffect(() => {
    focusRef.current.focus();
  }, []);

  return (
    <Fragment>
      <form noValidate onSubmit={actions.verifyCode}>
        <Grid>
          <GridRow>
            <Column classNames="ms-textAlignCenter">
              <p>
                {`Your account has been created and an email has been sent to you to with a verification code.
                Please enter the verification code in the box below and click the 'Verify Account' button.
                Once your account has been verified you will be asked to create a password and then be allowed to login.`}
              </p>
            </Column>
            <Column sm={6} classNames="ms-smPush3">
              <TextField
                label="Verification Code"
                required
                onChange={actions.handleVerificationChange}
                value={verificationCode}
                errorMessage={verificationCodeError}
                maxLength={6}
                componentRef={focusRef}
              />
            </Column>
          </GridRow>
        </Grid>
      </form>
      <GridRow>
        <Column classNames="ms-textAlignCenter">
          <PrimaryButton
            text="Verify"
            type="submit"
            onClick={actions.verifyCode}
          />
        </Column>
      </GridRow>
      <GridRow>
        <Column classNames="ms-textAlignCenter">
          <p>
            {
              "Didn't receive the email? Try checking your spam folder or click Resend Email to get a new verification code."
            }
          </p>
        </Column>
      </GridRow>
      <GridRow>
        <Column classNames="ms-textAlignCenter">
          <DefaultButton text="Resend Email" onClick={actions.resendEmail} />
        </Column>
      </GridRow>
    </Fragment>
  );
};

Verification.propTypes = {
  verificationCode: PropTypes.string,
  verificationCodeError: PropTypes.string,
  actions: PropTypes.object.isRequired,
};

Verification.defaultProps = {
  verificationCode: '',
  verificationCodeError: '',
};

export default Verification;
