import { isInvalidDate } from '../../helpers/util';

export const validate = state => {
  const errors = {};

  if (state.reportType === '') {
    errors.reportTypeError = 'Report type is required';
  }

  if (state.reportDate === '') {
    errors.reportDateError = 'Report date is required';
  } else if (isInvalidDate(state.reportDate)) {
    errors.reportDateError = 'Invalid date';
  }

  if (!state.startDate) {
    errors.startDateError = 'Report start date is required';
  } else if (state.startDate && isInvalidDate(state.startDate)) {
    errors.startDateError = 'Invalid date';
  }

  if (!state.endDate) {
    errors.endDateError = 'Report end date is required';
  } else if (state.endDate && isInvalidDate(state.endDate)) {
    errors.endDateError = 'Invalid date';
  }

  if (
    state.endDate &&
    state.startDate &&
    !isInvalidDate(state.endDate) &&
    !isInvalidDate(state.startDate) &&
    new Date(state.startDate).getTime() > new Date(state.endDate).getTime()
  ) {
    errors.startDateError = 'Start date cannot be after end date';
    errors.endDateError = 'End date cannot be before start date';
  }

  if (state.previousReportId === '0') {
    errors.previousReportIdError = 'Previous balance source is required';
  }

  return errors;
};
