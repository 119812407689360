import React, { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  DefaultButton,
  MessageBar,
  MessageBarType,
  Panel,
  PanelType,
  PrimaryButton,
  Separator,
  TextField,
} from 'office-ui-fabric-react';
import { Grid, GridRow, Column, MoneyField } from '../../common';
import { getLabel } from '../../../helpers/labelHelper';
import {
  YearPicker,
  ElectionCyclePicker,
  FECElectionCyclePicker,
  DisbursementCategoryPicker,
  DatePicker,
  ContactPicker,
} from '../../Pickers';
import {
  BankFeePanelReducer,
  initialState,
  actions,
} from './BankFeePanelReducer';
import { validate, createPayload } from './BankFeePanelValidations';
import { getNextElectionYear } from '../../../selectors';
import { disbursementCategories } from '../../../helpers/constants';
import { useContactSearch } from '../../../hooks/useContactSearch';
import { getRecipientTypes } from '../../../helpers/contactHelper';
import './BankFeePanel.scss';

const BankFeePanel = ({
  isOpen,
  onDismiss,
  onSave,
  session,
  periodEndDate = '',
}) => {
  const nextElectionYear = useSelector(getNextElectionYear);
  const [state, localDispatch] = useReducer(BankFeePanelReducer, initialState);

  useEffect(() => {
    localDispatch({
      type: actions.SET_DEFAULT_ELECTION_YEAR,
      data: {
        nextElectionYear: +nextElectionYear,
      },
    });
  }, [nextElectionYear]);

  useEffect(() => {
    if (!state.datePaid) {
      localDispatch({
        type: actions.HANDLE_CHANGE,
        data: {
          fieldName: 'datePaid',
          value: periodEndDate,
        },
      });
    }
  }, [periodEndDate]);

  const clearSelectedContact = () => {
    localDispatch({
      type: actions.CLEAR_SELECTED_CONTACT,
    });
  };

  const [onResolveSuggestions, onRenderSuggestionsItem] = useContactSearch(
    state.electionCycle,
    state.electionYear,
    null,
    'Expenditure',
  );

  const onItemSelected = contact => {
    localDispatch({ type: actions.ON_CONTACT_SELECTED, data: { contact } });
    return null;
  };

  const resetContactFields = () => {
    localDispatch({
      type: actions.RESET_SELECTED_CONTACT_INFO,
    });
  };

  const onCreateNewContact = () => {
    localDispatch({
      type: actions.HANDLE_CHANGE,
      data: {
        fieldName: 'usingNewContact',
        value: true,
      },
    });
  };

  const onChange = fieldName => (e, option) => {
    const value = option.key !== undefined ? option.key : option;
    localDispatch({
      type: actions.HANDLE_CHANGE,
      data: {
        fieldName,
        value,
      },
    });
  };

  const onChangeDatePaid = date => {
    localDispatch({
      type: actions.HANDLE_CHANGE,
      data: {
        fieldName: 'datePaid',
        value: date,
      },
    });
  };

  const close = () => {
    localDispatch({
      type: actions.RESET_FORM,
    });
    onDismiss();
  };

  const save = () => {
    const errors = validate(state, session);

    localDispatch({
      type: actions.SET_FORM_ERRORS,
      data: { errors },
    });

    if (!Object.values(errors).some(e => e.length > 0)) {
      const payload = createPayload(state);
      onSave(payload);
      close();
    }
  };

  const onRenderFooterContent = () => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <DefaultButton text="Cancel" onClick={close} />
        <PrimaryButton
          text="Save Fee"
          onClick={save}
          styles={{ root: { marginLeft: 16 } }}
        />
      </div>
    );
  };

  const isSpecialOrOtherElection =
    session.isFederal() && ['Special', 'Other'].includes(state.electionCycle);

  const disbursementType = disbursementCategories.find(
    dc => dc.key === state.disbursementCategory,
  );

  return (
    <Panel
      isOpen={isOpen}
      onDismiss={close}
      headerText="Add Fee"
      isFooterAtBottom
      onRenderFooterContent={onRenderFooterContent}
      type={PanelType.large}
    >
      <div className="BankFeePanel">
        <Grid>
          <GridRow>
            <Column lg={4}>
              <YearPicker
                label="Election Year"
                value={state.electionYear}
                onChange={onChange('electionYear')}
                required
                errorMessage={state.errors.electionYear}
              />
            </Column>
            <Column lg={4}>
              {session.isFederal() ? (
                <FECElectionCyclePicker
                  value={state.electionCycle}
                  onChange={onChange('electionCycle')}
                  required
                  errorMessage={state.errors.electionCycle}
                />
              ) : (
                <ElectionCyclePicker
                  value={state.electionCycle}
                  label="Election"
                  onChange={onChange('electionCycle')}
                  required
                  errorMessage={state.errors.electionCycle}
                />
              )}
            </Column>
            {isSpecialOrOtherElection && (
              <Column lg={4}>
                <TextField
                  label="Other Election Type"
                  required
                  errorMessage={state.errors.otherElectionType}
                  value={state.otherElectionType}
                  onChange={onChange('otherElectionType')}
                />
              </Column>
            )}
          </GridRow>
          {session.isFederal() && (
            <>
              <Separator />
              <GridRow>
                <Column lg={5}>
                  <DisbursementCategoryPicker
                    disbursementCategory={state.disbursementCategory}
                    errorMessage={state.errors.disbursementCategory}
                    handleChange={onChange('disbursementCategory')}
                  />
                </Column>
                {disbursementType && disbursementType.included.length > 0 && (
                  <Column lg={7}>
                    <p
                      style={{ margin: '8px 0 0' }}
                    >{`Valid Expenses for ${disbursementType.text}:`}</p>
                    <ul>
                      {disbursementType.included.map(type => (
                        <li key={type} style={{ fontWeight: 400 }}>
                          {type}
                        </li>
                      ))}
                    </ul>
                  </Column>
                )}
              </GridRow>
            </>
          )}
          <Separator />
          <GridRow>
            <Column lg={4}>
              <MoneyField
                value={state.amount}
                onChange={onChange('amount')}
                label="Amount"
                required
                errorMessage={state.errors.amount}
              />
            </Column>
            <Column lg={4}>
              <DatePicker
                label="Date Paid"
                value={state.datePaid}
                onChange={onChangeDatePaid}
                required
                errorMessage={state.errors.datePaid}
              />
            </Column>
          </GridRow>
          <GridRow>
            <Column lg={4}>
              <TextField
                label="Reference Id"
                value={state.paymentReferenceId}
                onChange={onChange('paymentReferenceId')}
              />
            </Column>
            <Column lg={4}>
              <TextField
                label="Purpose"
                value={state.purpose}
                onChange={onChange('purpose')}
              />
            </Column>
          </GridRow>
          <Separator />
          <GridRow>
            <Column lg={9}>
              {state.errors.selectedContact && (
                <MessageBar messageBarType={MessageBarType.error}>
                  {state.errors.selectedContact}
                </MessageBar>
              )}
              <ContactPicker
                session={session}
                selectedContact={state.selectedContact}
                clearSelectedContact={clearSelectedContact}
                onResolveSuggestions={onResolveSuggestions}
                onRenderSuggestionsItem={onRenderSuggestionsItem}
                onItemSelected={onItemSelected}
                onChangeContactField={onChange}
                resetContactFields={resetContactFields}
                onCreateNewContact={onCreateNewContact}
                cancelAddNew={resetContactFields}
                contactType={{
                  label: 'Contact Type',
                  field: 'contactType',
                  required: true,
                  show: true,
                  errorMessage: state.errors.contactType,
                  value: state.contactType,
                  options: getRecipientTypes(session),
                }}
                committeeFecId={{
                  label: 'FEC ID',
                  field: 'committeeFecId',
                  value: state.committeeFecId,
                  required: false,
                  show: true,
                }}
                salutation={{
                  label: 'Salutation',
                  field: 'salutation',
                  value: state.salutation,
                  required: false,
                  show: true,
                }}
                relationshipToCandidate={{
                  label: 'Relationship To Candidate',
                  field: 'relationshipToCandidate',
                  value: state.relationshipToCandidate,
                  required: false,
                  show: true,
                }}
                firstName={{
                  label: 'First Name',
                  field: 'firstName',
                  value: state.firstName,
                  required: true,
                  show: true,
                  errorMessage: state.errors.firstName,
                }}
                middleName={{
                  label: 'Middle Name',
                  field: 'middleName',
                  value: state.middleName,
                  required: false,
                  show: true,
                }}
                lastName={{
                  label: 'Last Name',
                  field: 'lastName',
                  value: state.lastName,
                  required: true,
                  show: true,
                  errorMessage: state.errors.lastName,
                }}
                occupation={{
                  label: 'Occupation',
                  field: 'occupation',
                  value: state.occupation,
                  required: false,
                  show: true,
                }}
                employer={{
                  label: 'Employer',
                  field: 'occupation',
                  value: state.occupation,
                  required: false,
                  show: true,
                }}
                address1={{
                  label: getLabel('Address Line 1', session),
                  field: 'addressLine1',
                  value: state.addressLine1,
                  required: false,
                  show: true,
                }}
                address2={{
                  label: getLabel('Address Line 2', session),
                  field: 'addressLine2',
                  value: state.addressLine2,
                  required: false,
                  show: true,
                }}
                city={{
                  label: 'City',
                  field: 'city',
                  value: state.city,
                  required: false,
                  show: true,
                }}
                state={{
                  label: 'State',
                  field: 'state',
                  value: state.state,
                  required: false,
                  show: true,
                }}
                zipCode={{
                  label: 'Zip Code',
                  field: 'zipCode',
                  value: state.zipCode,
                  required: false,
                  show: true,
                }}
                county={{
                  label: 'County',
                  field: 'county',
                  value: state.county,
                  required: false,
                  show: true,
                }}
                phone1={{
                  label: 'Phone 1',
                  field: 'phone1',
                  value: state.phone1,
                  show: true,
                }}
                phone2={{
                  label: 'Phone 2',
                  field: 'phone2',
                  value: state.phone2,
                  show: true,
                }}
                email={{
                  label: 'Email Address',
                  field: 'email',
                  value: state.email,
                  show: true,
                  errorMessage: state.errors.email,
                }}
                businessName={{
                  label: 'Business Name',
                  field: 'businessName',
                  value: state.businessName,
                  required: true,
                  show: true,
                  errorMessage: state.errors.businessName,
                }}
                businessType={{
                  label: 'Business Type',
                  field: 'businessType',
                  value: state.businessType,
                  show: true,
                }}
                contactName={{
                  label: 'Contact Name',
                  field: 'contactName',
                  value: state.contactName,
                  show: true,
                }}
                committeeAffiliation={{
                  label: 'Committee Type',
                  field: 'committeeAffiliation',
                  value: state.committeeAffiliation,
                  show: true,
                }}
              />
            </Column>
          </GridRow>
        </Grid>
      </div>
    </Panel>
  );
};

BankFeePanel.propTypes = {
  isOpen: PropTypes.bool,
  onDismiss: PropTypes.func,
  onSave: PropTypes.func.isRequired,
  session: PropTypes.object.isRequired,
  periodEndDate: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.number,
  ]),
};

export default BankFeePanel;
