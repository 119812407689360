import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { GridRow, Column, MoneyField } from '../../common';

const FECSection11b = ({ q11bColA, q11bColB }) => {
  return (
    <Fragment>
      <GridRow>
        <Column sm={4}>
          <p className="sub-question">(b) Political Party Committees</p>
        </Column>
        <Column sm={4} classNames="entry-row">
          <MoneyField
            disabled
            value={q11bColA || 0}
          />
        </Column>
        <Column sm={4} classNames="entry-row">
          <MoneyField
            disabled
            value={q11bColB || 0}
          />
        </Column>
      </GridRow>
    </Fragment>
  );
};

FECSection11b.propTypes = {
  q11bColA: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  q11bColB: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default FECSection11b;
