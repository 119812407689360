import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { GridRow, Column, TextField, MoneyField } from '../../common';

const FECSection6a = ({ colB_Year, colB_6a }) => {
  return (
    <Fragment>
      <GridRow>
        <Column sm={2}>
          <p>
            6.<span style={{ marginLeft: '14px' }}>(a) Cash on Hand</span>
            <br />
            <span style={{ marginLeft: '72px' }}>January 1,</span>
          </p>
        </Column>
        <Column sm={2}>
          <TextField
            disabled
            value={colB_Year}
          />
        </Column>
        <Column sm={4}>&nbsp;</Column>
        <Column sm={4}>
          <MoneyField
            disabled
            value={colB_6a}
          />
        </Column>

      </GridRow>
    </Fragment>
  );
};

FECSection6a.propTypes = {
  colB_Year: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  colB_6a: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default FECSection6a;
