import { types } from '../actions/federalReportActions';
import { createReducer } from './createReducer';
import { statuses } from '../helpers/constants';

export const initialState = {
  createReportStatus: statuses.NOT_STARTED,
  createReportError: null,
};

const actionMap = {
  [types.CREATE_FEDERAL_REPORT_PROCESSING]: state => {
    return {
      ...state,
      createReportStatus: statuses.PROCESSING,
      createReportError: null,
    };
  },

  [types.CREATE_FEDERAL_REPORT_SUCCESS]: state => {
    return {
      ...state,
      createReportStatus: statuses.SUCCESS,
      createReportError: null,
    };
  },

  [types.CREATE_FEDERAL_REPORT_FAILURE]: (state, action) => {
    return {
      ...state,
      createReportStatus: statuses.ERROR,
      createReportError: action.error,
    };
  },

  [types.RESET_FEDERAL_REPORT_STATE]: () => {
    return { ...initialState };
  },
};

export const federalReportReducer = createReducer(initialState, actionMap);
