import React from 'react';
import PropTypes from 'prop-types';
import { DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { Link } from 'office-ui-fabric-react/lib/Link';
import { StatePicker } from '../../components/Pickers';
import {
  Grid,
  GridRow,
  Column,
  EmailField,
  PhoneNumberField,
  ZipCodeField,
} from '../../components/common';
import { getLabel } from '../../helpers/labelHelper';
import './EditChairpersonDetails.css';

const EditChairpersonDetails = ({
  campaign: { chairPerson: chairperson = {} },
  formSection,
  chairpersonDetailsExpanded,
  actions,
  session,
}) => (
  <div className="EditChairpersonDetails depth-1" name="chairperson-details">
    <h3>
      {`${getLabel('Chairperson', session)} Information `}
      <small>
        {' '}
        (
        <Link onClick={actions.toggleExpandChairpersonDetails}>
          {chairpersonDetailsExpanded ? 'Collapse' : 'Expand'}
        </Link>
        )
      </small>
    </h3>
    <div
      className={`edit-chairperson-details-content${
        chairpersonDetailsExpanded ? ' expanded' : ''
      }`}
    >
      <Grid>
        <GridRow>
          <Column lg={4}>
            <TextField
              label={`${getLabel('Chairperson', session)} First Name`}
              value={chairperson.firstName}
              onChange={actions.handleTextChange('firstName')}
            />
          </Column>
          <Column lg={4}>
            <TextField
              label={`${getLabel('Chairperson', session)} Middle Name`}
              value={chairperson.middleName}
              onChange={actions.handleTextChange('middleName')}
            />
          </Column>
          <Column lg={4}>
            <TextField
              label={`${getLabel('Chairperson', session)} Last Name`}
              value={chairperson.lastName}
              onChange={actions.handleTextChange('lastName')}
            />
          </Column>
        </GridRow>
        <GridRow>
          <Column lg={6}>
            <TextField
              value={chairperson.addressLine1}
              label={`${getLabel('Chairperson', session)} Mailing Address 1`}
              onChange={actions.handleTextChange('addressLine1')}
            />
          </Column>
          <Column lg={6}>
            <TextField
              value={chairperson.addressLine2}
              label={`${getLabel('Chairperson', session)} Address 2`}
              onChange={actions.handleTextChange('addressLine2')}
            />
          </Column>
        </GridRow>
        <GridRow>
          <Column lg={4}>
            <TextField
              label={`${getLabel('Chairperson', session)} City`}
              value={chairperson.city}
              onChange={actions.handleTextChange('city')}
            />
          </Column>
          <Column lg={4}>
            <StatePicker
              fieldLabel={`${getLabel('Chairperson', session)} State`}
              selectedKey={chairperson.state}
              onChange={actions.handleStateChange}
              session={session}
            />
          </Column>
          <Column lg={4}>
            <ZipCodeField
              label={`${getLabel('Chairperson', session)} Zip Code`}
              value={chairperson.zipCode}
              onChange={actions.handleTextChange('zipCode')}
            />
          </Column>
        </GridRow>
        <GridRow>
          <Column lg={session.isFederal() ? 4 : 6}>
            <PhoneNumberField
              label={`${getLabel('Chairperson', session)} Telephone Number`}
              value={chairperson.telephoneNumber}
              onChange={actions.handleTextChange('telephoneNumber')}
            />
          </Column>
          <Column lg={session.isFederal() ? 4 : 6}>
            <EmailField
              label={`${getLabel('Chairperson', session)} Email`}
              value={chairperson.emailAddress}
              onChange={actions.handleTextChange('emailAddress')}
              setFormInvalid={actions.setFormInvalid}
              formSection={formSection}
            />
          </Column>
          {session.isFederal() && (
            <Column lg={4}>
              <TextField
                label={`${getLabel('Chairperson', session)} Title or Position`}
                value={chairperson.titleOrPosition}
                onChange={actions.handleTextChange('titleOrPosition')}
              />
            </Column>
          )}
        </GridRow>
      </Grid>
    </div>
    {chairpersonDetailsExpanded && (
      <div className="ms-textAlignRight">
        <DefaultButton
          style={{ marginRight: 8 }}
          text="Back"
          onClick={() => actions.previousStep(2)}
        />
        <DefaultButton text="Continue" onClick={() => actions.nextStep(4)} />
      </div>
    )}
  </div>
);

EditChairpersonDetails.propTypes = {
  campaign: PropTypes.object,
  chairpersonDetailsExpanded: PropTypes.bool,
  actions: PropTypes.object.isRequired,
  formSection: PropTypes.number,
  session: PropTypes.object.isRequired,
};

EditChairpersonDetails.defaultProps = {
  campaign: {},
  chairpersonDetailsExpanded: false,
  formSection: null,
};

export default EditChairpersonDetails;
