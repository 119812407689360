import { createReducer } from '../../reducers/createReducer';

export const initialState = {
  filterCalloutVisible: false,
  filters: [],
  columns: [],
  filterText: '',
};

export const actions = {
  RESET: 'RESET',
  OPEN_FILTER_CALLOUT: 'OPEN_FILTER_CALLOUT',
  CLOSE_FILTER_CALLOUT: 'CLOSE_FILTER_CALLOUT',
  ADD_FILTER: 'ADD_FILTER',
  REMOVE_FILTER: 'REMOVE_FILTER',
  ADD_COLUMN: 'ADD_COLUMN',
  REMOVE_COLUMN: 'REMOVE_COLUMN',
  HANDLE_CHANGE: 'HANDLE_CHANGE',
  CLEAR_COLUMNS: 'CLEAR_COLUMNS',
};

export const actionMap = {
  [actions.RESET]: state => ({
    ...state,
    filters: [],
  }),
  [actions.OPEN_FILTER_CALLOUT]: state => ({
    ...state,
    filterCalloutVisible: true,
  }),
  [actions.CLOSE_FILTER_CALLOUT]: state => ({
    ...state,
    filterCalloutVisible: false,
  }),
  [actions.ADD_FILTER]: (state, { data: { filter, id } }) => ({
    ...state,
    filters: [...state.filters, { ...filter, id }],
    filterCalloutVisible: false,
  }),
  [actions.REMOVE_FILTER]: (state, { data: { id } }) => ({
    ...state,
    filters: state.filters.filter(f => f.id !== id),
  }),
  [actions.ADD_COLUMN]: (state, { data: { key } }) => ({
    ...state,
    columns: [...state.columns, key],
  }),
  [actions.REMOVE_COLUMN]: (state, { data: { key } }) => ({
    ...state,
    columns: state.columns.filter(col => col !== key),
  }),
  [actions.HANDLE_CHANGE]: (state, { data: { fieldName, value } }) => ({
    ...state,
    [fieldName]: value,
  }),
  [actions.CLEAR_COLUMNS]: state => ({
    ...state,
    columns: [],
  }),
};

export const listsReducer = createReducer(initialState, actionMap);
