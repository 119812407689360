export const validate = state => {
  const errors = {};

  if (state.cardholderName.length === 0) {
    errors.cardholderNameError = 'Cardholder name is required';
  }

  if (state.cardholderEmail.length === 0) {
    errors.cardholderEmailError = 'Cardholder email is required';
  }

  return errors;
};
