import { types } from '../actions/loanActions';
import { statuses } from '../helpers/constants';
import { createReducer } from './createReducer';

export const initialState = {
  loans: [],
  getLoansError: null,
  getLoansStatus: statuses.NOT_STARTED,
  saveStatus: statuses.NOT_STARTED,
};

const actionMap = {
  [types.GET_LOANS_PROCESSING]: state => {
    return {
      ...state,
      getLoansStatus: statuses.PROCESSING,
    };
  },
  [types.GET_LOANS_SUCCESS]: (state, action) => {
    return {
      ...state,
      loans: action.loans,
      getLoansStatus: statuses.SUCCESS,
    };
  },
  [types.GET_LOANS_FAILURE]: (state, action) => {
    return {
      ...state,
      getLoansError: action.error,
      getLoansStatus: statuses.ERROR,
    };
  },
  [types.SAVE_LOAN_PROCESSING]: state => {
    return {
      ...state,
      saveStatus: statuses.PROCESSING,
    };
  },
  [types.SAVE_LOAN_RESET]: state => {
    return {
      ...state,
      saveStatus: statuses.NOT_STARTED,
    };
  },
  [types.SAVE_LOAN_FAILURE]: state => {
    return {
      ...state,
      saveStatus: statuses.ERROR,
    };
  },
  [types.SAVE_LOAN_SUCCESS]: state => {
    return {
      ...state,
      saveStatus: statuses.SUCCESS,
    };
  },
};

export const loansReducer = createReducer(initialState, actionMap);
