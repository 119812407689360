import React from 'react';
import PropTypes from 'prop-types';
import ContactRow from '../Contacts/ContactRow';

const ExistingContactsList = ({
  contribution,
  selectedExistingContact,
  toggleSelectExistingContact,
}) => {
  return (
    <div className="existing-list">
      {contribution.possibleContacts.map(pc => {
        return (
          <ContactRow
            key={`${pc.uuid}`}
            item={pc}
            showDetails={false}
            toggleSelectContact={toggleSelectExistingContact}
            selectedContacts={[
              selectedExistingContact
                ? selectedExistingContact.uuid
                : undefined,
            ]}
            itemIdField="uuid"
          />
        );
      })}
    </div>
  );
};

ExistingContactsList.propTypes = {
  contribution: PropTypes.object.isRequired,
  selectedExistingContact: PropTypes.object,
  toggleSelectExistingContact: PropTypes.func.isRequired,
};

ExistingContactsList.defaultProps = {
  selectedExistingContact: null,
};

export default ExistingContactsList;
