import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { GridRow, Column, MoneyField } from '../../common';

const FECSection14 = ({ q14ColA, q14ColB }) => {
  return (
    <Fragment>
      <GridRow style={{ borderTop: '1px solid #919191', paddingBottom: 6 }}>
        <Column sm={4}>
          <p>
            14. OFFSET TO OPERATING EXPENDITURES <br />
            (Refunds, Rebates, etc.)
          </p>
        </Column>
        <Column sm={4} classNames="entry-row">
          <MoneyField
            disabled
            value={q14ColA || 0}
          />
        </Column>
        <Column sm={4} classNames="entry-row">
          <MoneyField value={q14ColB || 0} disabled />
        </Column>
      </GridRow>
    </Fragment>
  );
};

FECSection14.propTypes = {
  q14ColA: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  q14ColB: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default FECSection14;
