import React from 'react';
import { IconButton } from 'office-ui-fabric-react/lib/Button';
import { getAddress } from '../services/apiCalls';

export function useAddressSearch(removeSelectedItem) {
  const searchAddress = async filterText => {
    try {
      const { data } = await getAddress(filterText);
      return data.results;
    } catch (e) {
      return [];
    }
  };

  const onResolveAddressSuggestions = async filterText => {
    if (filterText && filterText.length > 1) {
      return searchAddress(filterText);
    }
    return [];
  };

  const renderAddressItem = (entry) => {
    const colStyle = { flex: '1 1 0', padding: '6px 8px 0 0', overflow: 'hidden' };
    const rowStyle = {
      height: '32px',
      width: '900px',
      overflow: 'hidden',
      display: 'flex',
      justifyContent: 'left',
      verticalAlign: 'middle',
      paddingLeft: '6px',
    };
    return (
      <div key={entry.id} style={rowStyle}>
        <div style={colStyle}>{entry.item}</div>
        <IconButton
          iconProps={{ iconName: 'Times' }}
          onClick={removeSelectedItem}
        />
      </div>
    );
  };

  const onRenderAddressSuggestionsItem = item => {
    return (
      <div>{item.formatted}</div>
    );
  };

  return [onResolveAddressSuggestions, onRenderAddressSuggestionsItem, renderAddressItem];
}
