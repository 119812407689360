import { takeEvery, takeLatest, put, call } from 'redux-saga/effects';
import axios from 'axios';
import { push } from 'connected-react-router';
import { types } from '../actions/userActions';
import { types as messagingTypes } from '../actions/messagingActions';
import { types as currentCampaignTypes } from '../actions/currentCampaignActions';
import {
  getServerSideErrorMessage,
  reloadPage,
  successToast,
} from '../helpers/util';
import { toastTypes, CampaignUserRoles } from '../helpers/constants';

export function* handleGetCurrentUser() {
  try {
    const {
      data: { session, username },
    } = yield call(axios.get, '/api/session', { withCredentials: true });
    const {
      data: { isNonCandidateCommittee },
    } = yield call(axios.get, '/api/user/isCommitteeUser', {
      withCredentials: true,
    });
    yield put({
      type: types.CHECK_IS_NON_CANDIDATE_COMMITTEE_SUCCESS,
      isNonCandidateCommittee,
    });
    if (session && session.role === CampaignUserRoles.SysAdmin) {
      yield put({
        type: types.USER_LOGIN_SUCCESS,
        session: { ...session, username },
      });
      yield put(push('/filer'));
    } else if (session && !session.campaignId) {
      yield put({
        type: types.USER_LOGIN_CREATE_CAMPAIGN,
        session: { ...session, username },
      });
    } else {
      yield put({
        type: types.USER_LOGIN_SUCCESS,
        session: { ...session, username },
      });
    }
    const { data: campaigns } = yield call(axios.get, '/api/filer/campaigns', {
      withCredentials: true,
    });
    yield put({
      type: currentCampaignTypes.GET_ALL_CAMPAIGNS_SUCCESS,
      data: { campaigns },
    });
  } catch (error) {
    yield put({ type: types.USER_LOGIN_FAILURE, error: null });
  }
}

export function* getCurrentUser() {
  yield takeEvery(types.GET_CURRENT_USER, handleGetCurrentUser);
}

export function* handleLogin(action) {
  try {
    const {
      data: { username, password },
    } = action;
    const payload = {
      emailAddress: username,
      password,
    };
    const {
      data: { session, username: user_name },
    } = yield call(axios.post, '/api/signIn', payload);
    if (session && session.role === CampaignUserRoles.SysAdmin) {
      yield put({
        type: types.USER_LOGIN_SUCCESS,
        session: { ...session, username: user_name },
      });
      yield put(push('/filer'));
    } else if (session && !session.campaignId) {
      yield put({
        type: types.USER_LOGIN_CREATE_CAMPAIGN,
        session: { ...session, username: user_name },
      });
    } else {
      yield put({
        type: types.USER_LOGIN_SUCCESS,
        session: { ...session, username: user_name },
      });
    }
    const { data: campaigns } = yield call(axios.get, '/api/filer/campaigns');
    yield put({
      type: currentCampaignTypes.GET_ALL_CAMPAIGNS_SUCCESS,
      data: { campaigns },
    });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put({ type: types.USER_LOGIN_FAILURE, error });
  }
}

export function* login() {
  yield takeEvery(types.USER_LOGIN, handleLogin);
}

export function* handleForgotPasswordRequest(action) {
  try {
    yield put({ type: types.FORGOT_PASSWORD_REQUEST_PROCESSING });
    const { emailAddress } = action;
    yield call(axios.post, '/api/forgotPassword/react', { emailAddress });
    yield put({ type: types.FORGOT_PASSWORD_SUCCESS });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put({ type: types.FORGOT_PASSWORD_FAILURE, error });
  }
}

export function* forgotPasswordRequest() {
  yield takeEvery(types.FORGOT_PASSWORD_REQUEST, handleForgotPasswordRequest);
}

export function* handleResetPassword(action) {
  try {
    yield put({ type: types.RESET_PASSWORD_PROCESSING });
    const {
      data: { newPassword: password, confirmPassword, passwordResetToken },
    } = action;
    yield call(axios.post, '/api/resetPassword', {
      password,
      confirmPassword,
      passwordResetToken,
    });
    yield put({ type: types.RESET_PASSWORD_SUCCESS });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put({ type: types.RESET_PASSWORD_FAILURE, error });
  }
}

export function* resetPasswordRequest() {
  yield takeEvery(types.RESET_PASSWORD_REQUEST, handleResetPassword);
}

export function* handleEmailIsUnique(action) {
  try {
    const { emailAddress } = action;
    const {
      data: { isUnique, redirectToken = null },
    } = yield call(axios.get, `/api/signup/${emailAddress}`);
    if (isUnique === true) {
      yield put({ type: types.SIGNUP_EMAIL_IS_UNIQUE });
    } else if (isUnique === false) {
      yield put({ type: types.SIGNUP_EMAIL_NOT_UNIQUE, redirectToken });
    }
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put({ type: types.CHECK_EMAIL_IS_UNIQUE_ERROR, error });
  }
}

export function* checkEmailAddressIsUnique() {
  yield takeLatest(types.CHECK_EMAIL_IS_UNIQUE, handleEmailIsUnique);
}

export function* handleRegisterNewAccount(action) {
  try {
    const { payload } = action;
    const {
      data: { emailSent, emailToken },
    } = yield call(axios.post, '/api/signup/react', payload);

    if (emailSent && emailToken) {
      yield put({
        type: types.REGISTER_NEW_ACCOUNT_SUCCESS,
        registrationToken: emailToken,
      });
    }
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put({ type: types.REGISTER_NEW_ACCOUNT_FAILURE, error });
  }
}

export function* registerNewAccount() {
  yield takeEvery(types.REGISTER_NEW_ACCOUNT, handleRegisterNewAccount);
}

export function* handleGetInfoFromToken(action) {
  try {
    const { registrationToken } = action;
    const {
      data: { user = null },
    } = yield call(axios.get, `/api/signup/token/${registrationToken}`);
    if (user) {
      yield put({
        type: types.USER_REGISTERED,
        data: { user, registrationToken },
      });
    } else {
      yield put({ type: types.USER_NOT_REGISTERED });
    }
  } catch (e) {
    yield put({ type: types.USER_NOT_REGISTERED });
  }
}

export function* getInfoFromToken() {
  yield takeEvery(types.GET_INFO_FROM_TOKEN, handleGetInfoFromToken);
}

export function* handleVerifyAccount(action) {
  try {
    const {
      data: { verificationCode, registrationToken: emailToken, emailAddress },
    } = action;
    const {
      data: { verified, message = '' },
    } = yield call(axios.post, '/api/signup/verify', {
      verificationCode,
      emailToken,
      emailAddress,
    });

    if (verified) {
      yield put({ type: types.USER_VERIFIED, registrationToken: emailToken });
    } else {
      yield put({
        type: types.USER_NOT_VERIFIED,
        data: { registrationToken: emailToken, error: message },
      });
    }
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put({ type: types.USER_VERIFICATION_ERROR, error });
  }
}

export function* verifyCode() {
  yield takeEvery(types.VERIFY_NEW_ACCOUNT, handleVerifyAccount);
}

export function* handleResendVerificationEmail(action) {
  try {
    const { registrationToken } = action;
    const {
      data: { newToken },
    } = yield call(axios.post, '/api/signup/resend/react', {
      token: registrationToken,
    });
    yield put({
      type: messagingTypes.SET_TOAST,
      data: {
        toastType: toastTypes.SUCCESS,
        message: 'Verification email resent, check your inbox',
      },
    });
    yield put(push(`/signup/${newToken}`));
    yield put({ type: types.VERIFICATION_EMAIL_RESENT, newToken });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put({ type: types.VERIFICATION_EMAIL_NOT_SENT, error });
  }
}

export function* resendVerificationEmail() {
  yield takeEvery(
    types.RESEND_VERIFICATION_EMAIL,
    handleResendVerificationEmail,
  );
}

export function* handleCompleteSignup(action) {
  try {
    const {
      payload: { emailAddress, password, confirmPassword },
    } = action;
    yield call(axios.post, '/api/signup/login', {
      emailAddress,
      password,
      confirmPassword,
    });
    yield put({ type: types.CLEAR_SIGNUP_ERRORS });
    yield put(push('/'));
    yield put(
      successToast(
        'Signup successful! Login with the credentials you just created',
      ),
    );
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put({ type: types.COMPLETE_SIGNUP_FAILURE, error });
  }
}

export function* completeSignup() {
  yield takeEvery(types.COMPLETE_SIGNUP, handleCompleteSignup);
}

export function* handleValidatePromoCode(action) {
  try {
    const { promoCode } = action;
    const {
      data: { description = '' },
    } = yield call(axios.get, `/api/account/setup/promo/${promoCode}`);
    yield put({
      type: types.PROMO_CODE_VALID,
      data: { description, promoCode },
    });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put({ type: types.PROMO_CODE_INVALID, error });
  }
}

export function* validatePromoCode() {
  yield takeLatest(types.VALIDATE_PROMO_CODE, handleValidatePromoCode);
}

export function* handleGetPromoCode() {
  try {
    const {
      data: { description, promoCode },
    } = yield call(axios.get, '/api/account/setup/promo', {
      withCredentials: true,
    });
    yield put({
      type: types.GET_PROMO_CODE_SUCCESS,
      data: { promoCode, description },
    });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put({ type: types.GET_PROMO_CODE_FAILURE, error });
  }
}

export function* getPromoCode() {
  yield takeLatest(types.GET_PROMO_CODE, handleGetPromoCode);
}

export function* handleLogout() {
  yield call(axios.get, '/api/signOut', { withCredentials: true });
  yield put({ type: types.LOGOUT_SUCCESS });
  yield put(push('/'));
  reloadPage();
}

export function* logout() {
  yield takeLatest(types.LOGOUT, handleLogout);
}

export function* handleCheckIsNonCandidateCommittee() {
  try {
    const {
      data: { isNonCandidateCommittee },
    } = yield call(axios.get, '/api/user/isCommitteeUser', {
      withCredentials: true,
    });
    yield put({
      type: types.CHECK_IS_NON_CANDIDATE_COMMITTEE_SUCCESS,
      isNonCandidateCommittee,
    });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put({ type: types.CHECK_IS_NON_CANDIDATE_COMMITTEE_FAILURE, error });
  }
}

export function* checkIsNonCandidateCommittee() {
  yield takeLatest(
    types.CHECK_IS_NON_CANDIDATE_COMMITTEE,
    handleCheckIsNonCandidateCommittee,
  );
}

export function* handleChangePassword(action) {
  try {
    const { oldPass, newPass } = action.data;
    yield call(
      axios.put,
      '/api/changePassword',
      { oldPassword: oldPass, password: newPass },
      { withCredentials: true },
    );
    yield put({
      type: messagingTypes.SET_TOAST,
      data: {
        message: 'Password changed successfully!',
        toastType: toastTypes.SUCCESS,
      },
    });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put({
      type: messagingTypes.SET_TOAST,
      data: {
        message: error,
        toastType: toastTypes.ERROR,
      },
    });
  }
}

export function* changePassword() {
  yield takeLatest(types.CHANGE_PASSWORD, handleChangePassword);
}
