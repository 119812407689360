import React, { useEffect, useReducer, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
  TextField,
  Checkbox,
  Spinner,
  SpinnerSize,
  DetailsList,
  CheckboxVisibility,
  PrimaryButton,
  SearchBox,
  Separator,
  ScrollablePane,
  ScrollbarVisibility,
  Sticky,
  StickyPositionType,
  TooltipHost,
} from 'office-ui-fabric-react';
import GenericSpinnerMessageDialog from '../../../components/Dialogs/GenericSpinnerMessageDialog';
import { sortDirections, statuses } from '../../../helpers/constants';
import { types as depositActions } from '../../../actions/depositActions';
import { BackButton, Grid, GridRow, Column, HelpIcon } from '../../../components/common';
import { DatePicker } from '../../../components/Pickers';
import { formatCurrency, sortByField, formatDate } from '../../../helpers/util';
import {
  addEditDepositReducer,
  initialState,
  actions,
} from './addEditDepositReducer';
import { validate, createPayload } from './addEditDepositValidations';
import {
  getSingleDeposit,
  getUndepositedItems,
  getDepositUploadStatus,
  getUploadResult,
} from '../../../selectors';
import './AddEditDeposit.css';

let allUndeposited = [];

const AddEditDeposit = ({ history, match }) => {
  const [state, localDispatch] = useReducer(
    addEditDepositReducer,
    initialState,
  );
  const reduxDispatch = useDispatch();
  const undeposited = useSelector(getUndepositedItems);
  const singleDeposit = useSelector(getSingleDeposit);
  const uploadStatus = useSelector(getDepositUploadStatus);
  const uploadResult = useSelector(getUploadResult);

  const filePicker = useRef(null);

  useEffect(() => {
    if (!match.params.id) {
      reduxDispatch({
        type: depositActions.GET_ALL_UNDEPOSITED,
      });
    } else {
      reduxDispatch({
        type: depositActions.GET_SINGLE_DEPOSIT,
        data: {
          id: match.params.id,
        },
      });
    }

    return () => {
      reduxDispatch({
        type: depositActions.CLEAR_SINGLE_DEPOSIT,
      });
    };
  }, []);

  useEffect(() => {
    allUndeposited = undeposited;
    localDispatch({
      type: actions.SET_DEPOSIT_FOR_EDIT,
      data: {
        deposit: singleDeposit,
        undeposited,
      },
    });
  }, [singleDeposit]);

  const saveDeposit = () => {
    const errors = validate(state);

    localDispatch({
      type: actions.SET_ERRORS,
      data: {
        errors,
      },
    });

    if (!Object.values(errors).some(e => e.length)) {
      const payload = createPayload(state);
      if (state._id && singleDeposit) {
        reduxDispatch({
          type: depositActions.UPDATE_DEPOSIT,
          data: {
            id: state._id,
            payload,
          },
        });
      } else {
        reduxDispatch({
          type: depositActions.CREATE_DEPOSIT,
          data: {
            payload,
          },
        });
      }
    }
  };

  const getMessageList = (uploadResult) => {
    if (uploadResult.messageList && uploadResult.messageList.length > 0) {
      return uploadResult.messageList;
    }
    return [];
  };

  useEffect(() => {
    if (uploadStatus === statuses.PROCESSING) {
      localDispatch({
        type: actions.SHOW_UPLOAD_DIALOG,
      });
    }

    if (uploadStatus === statuses.ERROR) {
      localDispatch({
        type: actions.SET_UPLOAD_DIALOG_MESSAGE,
        data: { uploadDialogMessage: ['There was an issue processing the uploaded file'] },
      });
    }

    if (uploadStatus === statuses.SUCCESS) {
      localDispatch({
        type: actions.SET_UPLOAD_DIALOG_MESSAGE,
        data: { uploadDialogMessage: getMessageList(uploadResult) },
      });

      reduxDispatch({
        type: depositActions.GET_SINGLE_DEPOSIT,
        data: {
          id: match.params.id,
        },
      });
    }
  }, [uploadStatus, uploadResult]);

  useEffect(() => {
    if (!match.params.id) {
      allUndeposited = undeposited;
      localDispatch({
        type: actions.SET_UNDEPOSITED,
        data: { undeposited },
      });
    }
  }, [undeposited]);

  const handleChange = fieldName => (e, value) => {
    localDispatch({
      type: actions.HANDLE_CHANGE,
      data: {
        fieldName,
        value: value.key !== undefined ? value.key : value,
      },
    });
  };

  const mapItems = () => {
    const { sortField, sortDirection, filterText = '' } = state;

    let items = [
      ...allUndeposited,
      ...state.contributions,
      ...state.expenditures,
      ...state.loans,
    ];

    if (!items.length) {
      return [];
    }

    const searchText = filterText.toLowerCase();

    if (state.toggleShowCCOnly) {
      items = items.filter(
        i =>
          i.paymentType === 'Credit Card' ||
          i.isContributionProcessingFee === true,
      );
    }

    if (state.toggleShowSelectedOnly) {
      const idsOfSelected = state.selections.map(r => r._id);
      items = items.filter(i => idsOfSelected.includes(i._id));
    }

    items = items
      .filter(i => {
        const matchesOnName = i.displayName
          .toLowerCase()
          .includes(searchText);
        const matchesOnCheckNumber =
          i.checkNumber &&
          (typeof i.checkNumber === 'string'
            ? i.checkNumber.includes(searchText)
            : i.checkNumber.toString().includes(searchText));

        const matchesOnAmount = i.amount.toString().includes(searchText);
        const matchesOnPaymentReferenceId = (i.paymentReferenceId || '').toString().toLowerCase().includes(searchText);
        const matchesOnTag = (i.tags || []).join(', ').toLowerCase().includes(searchText);
        return matchesOnName || matchesOnCheckNumber || matchesOnAmount || matchesOnTag || matchesOnPaymentReferenceId;
      })
      .sort(sortByField(sortField, sortDirection));

    return items;
  };

  const toggleSelectAll = () => {
    localDispatch({
      type: actions.TOGGLE_SELECT_ALL,
      data: {
        currentOptions: mapItems(),
      },
    });
  };

  const onSelectionChanged = _id => {
    let item = allUndeposited.find(c => c._id === _id);
    if (!item) {
      item = state.contributions.find(c => c._id === _id);
    }

    if (!item) {
      item = state.expenditures.find(e => e._id === _id);
    }

    if (!item) {
      item = state.loans.find(l => l._id === _id);
    }

    if (!item) {
      return;
    }

    if (state.selections.find(selection => selection._id === item._id)) {
      localDispatch({
        type: actions.REMOVE_SELECTION,
        data: {
          item,
        },
      });
    } else {
      localDispatch({
        type: actions.ADD_SELECTION,
        data: {
          item,
        },
      });
    }
  };

  const handleChangeDate = date => {
    localDispatch({
      type: actions.HANDLE_CHANGE,
      data: {
        fieldName: 'date',
        value: date,
      },
    });
  };

  const toggleShowCCOnly = () => {
    localDispatch({
      type: actions.TOGGLE_SHOW_CC_ONLY,
    });
  };

  const toggleSelectedOnly = () => {
    localDispatch({
      type: actions.TOGGLE_SHOW_SELECTED_ONLY,
    });
  };

  const renderCCToggle = () => (
    <Checkbox
      label="Show Credit Card Transactions Only"
      className="cc-filter"
      checked={state.toggleShowCCOnly}
      onChange={toggleShowCCOnly}
    />
  );

  const renderSelectedOnlyToggle = () => (
    <Checkbox
      label="Show Selected Only"
      className="cc-filter"
      checked={state.toggleShowSelectedOnly}
      onChange={toggleSelectedOnly}
    />
  );

  const onRenderItemColumn = (item, index, column) => {
    const { selections } = state;

    if (column.key === 'selection') {
      const isSelected =
        selections.find(selection => selection._id === item._id) !== undefined;

      return (
        <Checkbox
          checked={isSelected}
          onChange={() => onSelectionChanged(item._id)}
        />
      );
    }

    return null;
  };

  const onRenderDetailsHeader = (headerProps, defaultRender) => {
    return (
      <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced>
        <div>
          <div className="search-actions ms-hiddenMdDown">
            <SearchBox
              className="search-box"
              value={state.filterText}
              onChange={handleChange('filterText')}
              clearButtonProps={{
                iconProps: {
                  iconName: 'Times',
                },
              }}
              placeholder="Search"
              styles={{
                root: {
                  marginTop: 29,
                },
              }}
            />
            {renderCCToggle()}
            {renderSelectedOnlyToggle()}
          </div>
          <div className="search-actions-sm ms-hiddenLgUp">
            <Grid>
              <GridRow>
                <Column md={4}>
                  <TextField
                    label="Deposit Name"
                    className="deposit-field"
                    required
                    value={state.name}
                    errorMessage={state.errors.nameError}
                    onChange={handleChange('name')}
                  />
                </Column>
                <Column md={4}>
                  <DatePicker
                    className="deposit-field"
                    label="Deposit Date"
                    required
                    value={state.date}
                    onChange={handleChangeDate}
                    errorMessage={state.errors.dateError}
                  />
                </Column>
                <Column sm={9} md={4}>
                  {renderCCToggle()}
                </Column>
                <Column sm={3} md={12}>
                  <PrimaryButton
                    text="Save"
                    onClick={saveDeposit}
                    styles={{
                      root: {
                        marginTop: 30,
                      },
                    }}
                  />
                </Column>
              </GridRow>
            </Grid>
          </div>
          <Separator />
          <div className="select-all-checkbox">
            <Checkbox onChange={toggleSelectAll} checked={state.allSelected} />
          </div>
          {defaultRender({ ...headerProps })}
        </div>
      </Sticky>
    );
  };

  const onColumnClick = (e, { fieldName }) => {
    let sortField = fieldName;
    let sortDirection;

    if (state.sortField === fieldName) {
      if (state.sortDirection === sortDirections.ASC) {
        sortDirection = sortDirections.DESC;
      } else if (state.sortDirection === sortDirections.DESC) {
        sortDirection = sortDirections.NONE;
      } else {
        sortDirection = sortDirections.ASC;
      }
    } else {
      sortField = fieldName;
      sortDirection = sortDirections.ASC;
    }

    localDispatch({
      type: actions.SET_SORT,
      data: {
        sortField,
        sortDirection,
      },
    });
  };

  const createColumns = useMemo(() => {
    const { sortField, sortDirection } = state;

    return [
      {
        key: 'selection',
        fieldName: 'selection',
        name: '',
        minWidth: 40,
        maxWidth: 40,
      },
      {
        key: 'name',
        fieldName: 'displayName',
        name: 'Name',
        isMultiline: true,
        minWidth: 200,
        maxWidth: 300,
        isSorted:
          sortField === 'displayName' &&
          sortDirection !== sortDirections.NONE,
        isSortedDescending: sortDirection === sortDirections.DESC,
        onColumnClick,
        onRender: item => <span>{item.displayName}</span>,
      },
      {
        key: 'paymentType',
        fieldName: 'paymentType',
        name: 'Payment Type',
        maxWidth: 200,
        isSorted:
          sortField === 'paymentType' && sortDirection !== sortDirections.NONE,
        isSortedDescending: sortDirection === sortDirections.DESC,
        onColumnClick,
        onRender: item => (
          <span>{`${item.paymentType}${
            item.checkNumber ? ' - ' + item.checkNumber : ''
          }`}</span>
        ),
      },
      {
        key: 'amount',
        fieldName: 'amount',
        name: 'Amount',
        maxWidth: 75,
        isSorted:
          sortField === 'amount' && sortDirection !== sortDirections.NONE,
        isSortedDescending: sortDirection === sortDirections.DESC,
        onColumnClick,
        onRender: item => (
          <div style={{ width: '100%', textAlign: 'right' }}>
            {formatCurrency(item.amount)}
          </div>
        ),
      },
      {
        key: 'receivedDate',
        fieldName: 'receivedDate',
        name: 'Received Date',
        maxWidth: 200,
        isSorted:
          sortField === 'receivedDate' && sortDirection !== sortDirections.NONE,
        isSortedDescending: sortDirection === sortDirections.DESC,
        onColumnClick,
        onRender: item => (
          <span>{formatDate(item.receivedDate, 'MM/DD/YYYY')}</span>
        ),
      },
      {
        key: 'paymentReferenceId',
        fieldName: 'paymentReferenceId',
        name: 'Reference ID',
        minWidth: 200,
        maxWidth: 220,
        isSorted:
          sortField === 'paymentReferenceId' && sortDirection !== sortDirections.NONE,
        isSortedDescending: sortDirection === sortDirections.DESC,
        onColumnClick,
        onRender: item => (
          <span>{item.paymentReferenceId}</span>
        ),
      },
      {
        key: 'tags',
        fieldName: 'tags',
        name: 'Tags',
        maxWidth: 100,
        isSorted:
          sortField === 'tags' && sortDirection !== sortDirections.NONE,
        isSortedDescending: sortDirection === sortDirections.DESC,
        onColumnClick,
        onRender: item => (
          <span>{(item.tags || []).join(', ')}</span>
        ),
      },
    ];
  });

  const getSelectionTotal = () =>
    state.selections.reduce((acc, selection) => {
      acc += selection.amount || 0;
      return acc;
    }, 0);

  const selectionTotal = getSelectionTotal();

  const hasFormErrors = Object.values(state.errors).some(c => c.length);

  const onChangeHandler = (event) => {
    const chosenFile = event.target.files[0];
    const formData = new FormData();
    formData.append('file', chosenFile);
    formData.set('name', chosenFile.name);
    formData.set('size', chosenFile.size);
    formData.set('lastModified', chosenFile.lastModified);
    formData.set('type', chosenFile.type);
    formData.set('depositId', match.params.id);
    localDispatch({
      type: actions.SET_SPREADSHEET_FILE,
      data: {
        spreadsheet: formData,
      },
    });
  };

  const onClickHandler = async () => {
    reduxDispatch({
      type: depositActions.UPLOAD_DEPOSIT_SPREADSHEET,
      data: state.spreadsheet,
    });
  };

  const closeUploadDialog = () => {
    localDispatch({
      type: actions.CLOSE_UPLOAD_DIALOG,
    });
  };

  return (
    <>
      <BackButton
        history={history}
        url="/filer/deposits"
        message="Cancel"
        isFixed
        pageTitle={`${match.params.id ? 'Edit' : 'Create'} Deposit`}
      />
      <div className="AddEditDeposit">
        <div className="options-panel ms-hiddenMdDown">
          <TextField
            label="Deposit Name"
            className="deposit-field"
            required
            value={state.name}
            errorMessage={state.errors.nameError}
            onChange={handleChange('name')}
          />
          <DatePicker
            className="deposit-field"
            label="Deposit Date"
            required
            value={state.date}
            onChange={handleChangeDate}
            errorMessage={state.errors.dateError}
          />
          <Separator />
          <label className="selection-details">
            {'Deposit Total: '}
            <strong>{`${formatCurrency(selectionTotal)}`}</strong>
          </label>
          {match.params.id &&
          <div className="deposit-actions">
            <h3>Upload spreadsheet
              <TooltipHost
                content="This file will match transactions to this deposit. File format: CSV (comma-separated values); no headers; first column contains the Payment Reference Id; second column has the Amount. (For an Anedot transaction downloaded file these are UID and Gross Amount.)"
                closeDelay={750}
              >
                <HelpIcon />
              </TooltipHost>
            </h3>
            <input
              className="file-input"
              ref={filePicker}
              type="file"
              onChange={onChangeHandler}
              label="Select Spreadsheet File"
            />
            <PrimaryButton
              style={{ marginTop: '12px' }}
              type="button"
              onClick={onClickHandler}
              disabled={!filePicker?.current?.value || state.isUploadingFile}
            >
              Upload Contributions
            </PrimaryButton>
          </div>
          }
          {!match.params.id &&
          <div className="deposit-actions">
            To upload contributions, create and save this deposit and select &apos;Edit&apos; from deposit in list.
          </div>
          }
          <Separator />
          <div className="deposit-actions">
            <PrimaryButton text="Save" onClick={saveDeposit} />
          </div>
        </div>
        <div className="deposits-list">
          <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
            {(match.params.id && singleDeposit && undeposited) ||
            (!match.params.id && undeposited) ? (
              <div
                className={`deposit-list-wrapper${
                  hasFormErrors ? ' formErrors' : ''
                }`}
              >
                <DetailsList
                  onRenderItemColumn={onRenderItemColumn}
                  onRenderDetailsHeader={onRenderDetailsHeader}
                  items={mapItems()}
                  columns={createColumns}
                  selectionPreservedOnEmptyClick
                  compact={false}
                  setKey="transactions"
                  checkboxVisibility={CheckboxVisibility.hidden}
                />
              </div>
            ) : (
              <div className="section-loading">
                <Spinner
                  label="Loading..."
                  ariaLive="assertive"
                  size={SpinnerSize.large}
                  labelPosition="left"
                />
              </div>
            )}
          </ScrollablePane>
        </div>
        <GenericSpinnerMessageDialog
          dialogHidden={state.uploadDialogHidden}
          onCancel={closeUploadDialog}
          titleText="Deposit File Upload"
          isProcessing={uploadStatus === statuses.PROCESSING}
          processingMessage="Applying file. This may require several minutes depending upon the size of the file."
          finishedProcessingMessageList={state.uploadDialogMessage}
          finishedProcessingInstruction="Please click 'Save' to associate the uploaded contributions to this deposit."
        />
      </div>
    </>
  );
};

AddEditDeposit.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default AddEditDeposit;
