import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { formatCurrency } from '../../helpers/util';

const AggregateRow = ({
  aggregate,
  electionCycle,
  electionYear,
  amount = 0,
}) => {
  const amountLabel = useMemo(() => {
    if (
      electionYear.toString() === aggregate.electionYear.toString() &&
      electionCycle === aggregate.electionCycle
    ) {
      const newTotal =
        Math.round(
          (parseFloat(aggregate.total) + parseFloat(amount || 0)) * 100,
        ) / 100;
      return `${formatCurrency(aggregate.total)} + ${formatCurrency(
        amount || 0,
      )} = ${formatCurrency(newTotal)}`;
    }
    return formatCurrency(aggregate.total);
  }, [electionCycle, electionYear, amount, aggregate]);

  return (
    <>
      <div className="AggregateRow">
        <h4>{`${aggregate.electionYear} ${aggregate.electionCycle}`}</h4>
        <span style={{ color: 'var(--primary-color)', marginLeft: 16 }}>
          {amountLabel}
        </span>
      </div>
    </>
  );
};

AggregateRow.propTypes = {
  aggregate: PropTypes.object,
  electionYear: PropTypes.number,
  electionCycle: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default AggregateRow;
