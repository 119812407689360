export const types = {
  CHANGE_IMPORT_WIZARD_STEP: 'CHANGE_IMPORT_WIZARD_STEP',
  CLEAR_IMPORT_WIZARD_DATA: 'CLEAR_IMPORT_WIZARD_DATA',
  GET_EDITED_IMPORT_DATA: 'GET_EDITED_IMPORT_DATA',
  GET_EDITED_IMPORT_DATA_PROCESSING: 'GET_EDITED_IMPORT_DATA_PROCESSING',
  GET_EDITED_IMPORT_DATA_SUCCESS: 'GET_EDITED_IMPORT_DATA_SUCCESS',
  GET_EDITED_IMPORT_DATA_FAILURE: 'GET_EDITED_IMPORT_DATA_FAILURE',
  SAVE_IMPORT_DATA_TYPE: 'SAVE_IMPORT_DATA_TYPE',
  UPLOAD_IMPORT_FILE: 'UPLOAD_IMPORT_FILE',
  UPLOAD_IMPORT_FILE_PROCESSING: 'UPLOAD_IMPORT_FILE_PROCESSING',
  UPLOAD_IMPORT_FILE_SUCCESS: 'UPLOAD_IMPORT_FILE_SUCCESS',
  UPLOAD_IMPORT_FILE_FAILURE: 'UPLOAD_IMPORT_FILE_FAILURE',
  SAVE_IMPORT_FILE_FORMAT: 'SAVE_IMPORT_FILE_FORMAT',
  SAVE_IMPORT_CONFIG: 'SAVE_IMPORT_CONFIG',
  SAVE_IMPORT_CONFIG_PROCESSING: 'SAVE_IMPORT_CONFIG_PROCESSING',
  SAVE_IMPORT_CONFIG_SUCCESS: 'SAVE_IMPORT_CONFIG_SUCCESS',
  SAVE_IMPORT_CONFIG_FAILURE: 'SAVE_IMPORT_CONFIG_FAILURE',
  GET_UPLOAD_RESULT: 'GET_UPLOAD_RESULT',
  GET_UPLOAD_RESULT_PROCESSING: 'GET_UPLOAD_RESULT_PROCESSING',
  GET_UPLOAD_RESULT_SUCCESS: 'GET_UPLOAD_RESULT_SUCCESS',
  GET_UPLOAD_RESULT_FAILURE: 'GET_UPLOAD_RESULT_FAILURE',
  MAP_IMPORT_COLUMNS: 'MAP_IMPORT_COLUMNS',
  MAP_IMPORT_COLUMNS_PROCESSING: 'MAP_IMPORT_COLUMNS_PROCESSING',
  MAP_IMPORT_COLUMNS_SUCCESS: 'MAP_IMPORT_COLUMNS_SUCCESS',
  MAP_IMPORT_COLUMNS_FAILURE: 'MAP_IMPORT_COLUMNS_FAILURE',
};

export const actions = {
  changeImportWizardStep(step) {
    return { type: types.CHANGE_IMPORT_WIZARD_STEP, data: { step } };
  },
  clearImportWizardData() {
    return { type: types.CLEAR_IMPORT_WIZARD_DATA };
  },
  getEditedImportData(fileId, dataType) {
    return { type: types.GET_EDITED_IMPORT_DATA, data: { fileId, dataType } };
  },
  saveImportDataType(dataType) {
    return { type: types.SAVE_IMPORT_DATA_TYPE, data: { dataType } };
  },
  uploadImportFile(file) {
    return { type: types.UPLOAD_IMPORT_FILE, data: { file } };
  },
  saveImportFileFormat(fileFormat) {
    return { type: types.SAVE_IMPORT_FILE_FORMAT, data: { fileFormat } };
  },
  saveImportConfig(fileId, config) {
    return { type: types.SAVE_IMPORT_CONFIG, data: { fileId, config } };
  },
  getUploadResult(fileId) {
    return { type: types.GET_UPLOAD_RESULT, data: { fileId } };
  },
  mapImportColumns(fileId, columns) {
    return { type: types.MAP_IMPORT_COLUMNS, data: { fileId, columns } };
  },
};
