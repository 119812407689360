import React, { Fragment, useState, useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect, useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ActionButton, DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { List } from 'office-ui-fabric-react/lib/List';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import { Checkbox, Dropdown } from 'office-ui-fabric-react';
import { useIntersect } from '../../hooks/useIntersect';
import { actions as contactActions, types as contactTypes } from '../../actions/contactActions';
import { BackButton, Grid, GridRow, Column } from '../../components/common';
import { statuses } from '../../helpers/constants';
import { scrollToTop } from '../../helpers/util';
import {
  ContactDetailsPanel,
  MergeContactsPanel,
} from '../../components/Panels';
import ContactRow from './ContactRow';
import './Contacts.css';
import ScrollObserver from '../../components/ScrollObserver';
import { getNextPageStatusContacts } from '../../selectors';
import { cleansObject } from '../../helpers/payloadHelper';
import { isBetaUser, isSysAdmin } from '../../helpers/sessionHelper';
import { CaretDown } from '../../components/icons';

const initialState = {
  filterText: '',
  showDetailsPanel: false,
  showMergePanel: false,
  selectedContacts: [],
  selectedContact: {},
  contactsToMerge: [],
  pageIndex: 0,
  refreshTimes: 0,

  isSystemAdministrator: false,
  isBetaUserEnabled: false,
  isAdvancedSearch: false,
  searchType: 'individual',
  isFuzzyMatch: false,
  firstNameFilterText: '',
  middleNameFilterText: '',
  lastNameFilterText: '',
  businessNameFilterText: '',
  phoneFilterText: '',
  emailFilterText: '',
  addressFilterText: '',
  cityFilterText: '',
  stateFilterText: '',
  zipFilterText: '',
};

export const Contacts = ({ contacts, contactActions, history, session }) => {
  const reduxDispatch = useDispatch();
  const [state, setState] = useState({ ...initialState });
  const getNextPageStatus = useSelector(getNextPageStatusContacts);

  const [scrollRef, entry] = useIntersect({
    rootMargin: '80px',
    threshold: 0.3,
  });

  state.isSystemAdministrator = isSysAdmin(session);
  state.isBetaUserEnabled = isBetaUser(session);

  useEffect(() => {
    let params = `?pageIndex=${state.pageIndex}&pageSize=100`;
    params = state.filterText ? `${params}&fullSearch=${state.filterText}` : params;
    params = state.isAdvancedSearch ? `${params}&isAdvancedSearch=${state.isAdvancedSearch}` : params;
    params = state.isFuzzyMatch ? `${params}&isFuzzyMatch=${state.isFuzzyMatch}` : params;
    params = state.firstNameFilterText ? `${params}&firstName=${state.firstNameFilterText}` : params;
    params = state.lastNameFilterText ? `${params}&lastName=${state.lastNameFilterText}` : params;
    params = state.businessNameFilterText ? `${params}&businessName=${state.businessNameFilterText}` : params;
    params = state.phoneFilterText ? `${params}&phone=${state.phoneFilterText}` : params;
    params = state.emailFilterText ? `${params}&email=${state.emailFilterText}` : params;
    params = state.addressFilterText ? `${params}&address=${state.addressFilterText}` : params;
    params = state.cityFilterText ? `${params}&city=${state.cityFilterText}` : params;
    params = state.stateFilterText ? `${params}&state=${state.stateFilterText}` : params;
    params = state.zipFilterText ? `${params}&zip=${state.zipFilterText}` : params;

    if (state.pageIndex === 0) {
      scrollToTop();
      reduxDispatch({
        type: contactTypes.GET_CONTACTS,
        data: { params },
      });
    } else {
      reduxDispatch({
        type: contactTypes.GET_CONTACTS_NEXT_PAGE,
        data: { params },
      });
    }
  }, [state.pageIndex, state.filterText, state.isAdvancedSearch, state.isFuzzyMatch, state.firstNameFilterText, state.lastNameFilterText, state.businessNameFilterText,
    state.phoneFilterText, state.emailFilterText, state.addressFilterText, state.cityFilterText, state.stateFilterText, state.zipFilterText, state.refreshTimes]);

  useEffect(() => {
    if (entry.isIntersecting) {
      const newPageIndex = state.pageIndex + 1;
      setState({
        ...state,
        pageIndex: newPageIndex,
      });
    }
  }, [entry]);

  useEffect(() => {
    setState({
      ...state,
      pageIndex: 0,
    });
  }, []);

  const openDetailsPanel = id => {
    return () => {
      let contact = {};
      if (id) {
        contact = contacts.contacts.find(c => c._id === id);
      }
      setState({
        ...state,
        showDetailsPanel: true,
        selectedContact: contact,
      });
    };
  };

  const closeDetailsPanel = () => {
    setState({
      ...state,
      showDetailsPanel: false,
      selectedContact: {},
    });
  };

  const onTextFilterChange = fieldName => {
    return (e, value) => {
      setState({
        ...state,
        pageIndex: 0,
        [fieldName]: value,
      });
    };
  };

  const onSearchTypeChange = () => {
    return (e, value) => {
      setState({
        ...state,
        firstNameFilterText: '',
        middleNameFilterText: '',
        lastNameFilterText: '',
        businessNameFilterText: '',
        pageIndex: 0,
        searchType: value.key,
      });
    };
  };

  const toggleSelectContact = id => {
    return (e, isChecked) => {
      const selectedContacts = isChecked
        ? [...state.selectedContacts, id]
        : state.selectedContacts.filter(c => c !== id);
      setState({
        ...state,
        selectedContacts,
      });
    };
  };

  const createPayload = contact => {
    const payload = {
      committeeFecId: contact.committeeFecId || null,
      contactType: contact.type,
      businessName: contact.businessName || null,
      businessType: contact.businessType || null,
      committeeAffiliation: contact.committeeAffiliation || null,
      contactName: contact.contactName || null,
      taxEin: contact.taxEin || null,
      externalDonorId: contact.externalDonorId || null,
      salutation: contact.salutation === 'none' ? null : contact.salutation,
      relationshipToCandidate: contact.relationshipToCandidate || null,
      firstName: contact.firstName || null,
      middleName: contact.middleName || null,
      lastName: contact.lastName || null,
      suffix: contact.suffix || null,
      nickName: contact.nickName || null,
      occupation: contact.occupation || null,
      employer: contact.employer || null,
      specific: contact.specific || null,
      commonSource: contact.commonSource || null,
      candidateOffice: contact.candidateOffice || null,
      candidateState: contact.candidateState || null,
      candidateDistrict: contact.candidateDistrict || null,
      candidateFirstName: contact.candidateFirstName || null,
      candidateMiddleName: contact.candidateMiddleName || null,
      candidateLastName: contact.candidateLastName || null,
      candidateFecId: contact.candidateFecId || null,
      taxId: contact.taxId || '',
      trackFor1099: contact.trackFor1099 || false,
      isDeceased: contact.isDeceased || null,
      isRemoved: contact.isRemoved || null,
      isCandidate: contact.type === 'Individual' ? contact.isCandidate : false,
      trustee: contact.trustee || null,
      trustor: contact.trustor || null,
      isLivingOrRevokableTrust: contact.isLivingOrRevokableTrust || null,
      address: {
        addressLine1: contact.addressLine1 || null,
        addressLine2: contact.addressLine2 || null,
        city: contact.city || null,
        state: contact.state || null,
        zipCode: contact.zipCode || null,
        county: contact.county || null,
      },
      additionalAddresses: (contact.additionalAddresses || []).map(
        ({ addressLine1, addressLine2, city, state, zipCode, county }) => ({
          addressLine1: addressLine1 || null,
          addressLine2: addressLine2 || null,
          city: city || null,
          state: state || null,
          zipCode: zipCode || null,
          county: county || null,
        }),
      ),
      email: contact.email || null,
      additionalEmailAddresses: (contact.additionalEmailAddresses || []).map(
        ({ value }) => value.toLowerCase(),
      ),
      phone1: contact.phone1 || null,
      phone2: contact.phone2 || null,
      additionalTelephoneNumbers: (contact.additionalTelephoneNumbers || []).map(
        ({ value }) => ({
          number: value,
        }),
      ),
      tags: contact.tags,
      sourceDate: contact.sourceDate || null,
      family:
        contact.type === 'Individual'
          ? {
            spouse: {
              firstName: contact.spouseFirstName || null,
              middleName: contact.spouseMiddleName || null,
              lastName: contact.spouseLastName || null,
            },
            children: contact.children
              ? (contact.children || []).map(({ name }) => ({
                name,
              }))
              : null,
          }
          : {},
      notes: (contact.notes || []).map(({ value }) => ({
        text: value,
      })),
      purpose: contact.purpose,
    };

    const cleanPayload = cleansObject(payload);
    // Make sure these aren't cleaned so they can be properly reset
    cleanPayload.taxId = payload.taxId || '';
    cleanPayload.trackFor1099 = payload.trackFor1099 || false;
    cleanPayload.isDeceased = payload.isDeceased || false;
    cleanPayload.isRemoved = payload.isRemoved || false;
    cleanPayload.isCandidate = payload.isCandidate || false;
    return cleanPayload;
  };

  const saveContact = contact => {
    setState((state) => {
      return {
        ...initialState,
        filterText: state.filterText,
        showDetailsPanel: false,
        refreshTimes: state.refreshTimes + 1,
      };
    });

    const payload = createPayload(contact);

    contactActions.saveContact(payload);
  };

  const addContribution = (id) => {
    history.push(`/filer/addContribution?contactId=${id}`);
  };

  const isMissingFromList = (contactId, originalMembersReturned) => {
    return originalMembersReturned.filter(om => om._id === contactId).length === 0;
  };

  const updateContact = async contact => {
    setState((state) => {
      return {
        ...initialState,
        filterText: state.filterText,
        showDetailsPanel: false,
        refreshTimes: state.refreshTimes + 1,
      };
    });

    for (let i = 0; i < contact.householdMembers.length; i += 1) {
      const hm = contact.householdMembers[i];
      if (isMissingFromList(hm._id, contact.originalHouseholdMembers)) {
        // eslint-disable-next-line no-await-in-loop
        await axios.post('/api/filer/contactLinks', {
          contactId1: contact._id,
          contactId2: hm._id,
        });
      }
    }

    for (let i = 0; i < contact.originalHouseholdMembers.length; i += 1) {
      const ohm = contact.originalHouseholdMembers[i];
      if (isMissingFromList(ohm._id, contact.householdMembers)) {
        // eslint-disable-next-line no-await-in-loop
        await axios.delete(`/api/filer/contactLinks?contactIds=${contact._id}&contactIds=${ohm._id}`);
      }
    }

    const payload = createPayload(contact);

    contactActions.updateContact(contact._id, payload);
  };

  const deleteContact = id => {
    setState((state) => {
      return {
        ...initialState,
        selectedContact: {},
        filterText: state.filterText,
        showDetailsPanel: false,
        refreshTimes: state.refreshTimes + 1,
      };
    });

    contactActions.deleteContact(id);
  };

  const mergeContacts = () => {
    const contactsToMerge = contacts.contacts.reduce((acc, contact) => {
      if (state.selectedContacts.includes(contact._id)) {
        acc.push(contact);
      }
      return acc;
    }, []);
    setState({
      ...state,
      showMergePanel: true,
      contactsToMerge,
    });
  };

  const closeMergePanel = () => {
    setState({
      ...state,
      showMergePanel: false,
      contactsToMerge: [],
      selectedContacts: [],
    });
  };

  const saveMergeContacts = (targetContactId, contactIds) => {
    let params = `?pageIndex=0&pageSize=${(state.pageIndex + 1) * 100}`;
    params = state.filterText ? `${params}&fullSearch=${state.filterText}` : params;

    setState({
      ...state,
      showMergePanel: false,
      contactsToMerge: [],
      selectedContacts: [],
    });

    contactActions.mergeContacts(targetContactId, contactIds, params);
  };

  const consolidateContacts = (targetContact, contactIds) => {
    let params = `?pageIndex=0&pageSize=${(state.pageIndex + 1) * 100}`;
    params = state.filterText ? `${params}&fullSearch=${state.filterText}` : params;

    setState({
      ...state,
      showMergePanel: false,
      contactsToMerge: [],
      selectedContacts: [],
    });

    // contactActions.consolidateContacts(targetContact, contactIds, params);
    contactActions.consolidateContacts(targetContact, contactIds, params);
  };

  const toContact = contact => ({
    ...contact,
    key: contact._id,
    isSelected: false,
  });

  const onRenderCell = item => {
    const { selectedContacts } = state;
    const classNames = [
      'contact-row',
      selectedContacts.includes(item._id) ? 'selected' : '',
    ]
      .filter(Boolean)
      .join(' ');

    return (
      <ContactRow
        key={item._id}
        classNames={classNames}
        item={item}
        toggleSelectContact={toggleSelectContact}
        selectedContacts={selectedContacts}
        openDetailsPanel={openDetailsPanel}
      />
    );
  };

  const showSearch = () => {
    return (
      <GridRow>
        <Column md={12}>
          <TextField
            label="Search Contacts by Name"
            onChange={onTextFilterChange('filterText')}
          />
        </Column>
      </GridRow>
    );
  };

  const showAdvancedSearch = () => {
    return (
      <>
        <GridRow>
          <Column md={2}>
            <Dropdown
              onChange={onSearchTypeChange()}
              options={[
                { key: 'individual', text: 'Individual' },
                { key: 'organization', text: 'Organization' },
              ]}
              selectedKey={state.searchType}
              onRenderCaretDown={() => <CaretDown />}
            />
          </Column>
          {state.searchType === 'individual' &&
          <>
            <Column md={3}>
              <TextField
                onChange={onTextFilterChange('firstNameFilterText')}
                placeholder="First Name"
              />
            </Column>
            <Column md={2}>
              <TextField
                onChange={onTextFilterChange('middleNameFilterText')}
                placeholder="Middle Name"
              />
            </Column>
            <Column md={3}>
              <TextField
                onChange={onTextFilterChange('lastNameFilterText')}
                placeholder="Last Name"
              />
            </Column>
            <Column md={2}>
              <TextField
                onChange={onTextFilterChange('emailFilterText')}
                placeholder="Email"
              />
            </Column>
          </>
          }
          {state.searchType === 'organization' &&
          <>
            <Column md={3}>
              <TextField
                onChange={onTextFilterChange('businessNameFilterText')}
                placeholder="Organization Name"
              />
            </Column>
            <Column md={2}>
              <TextField
                onChange={onTextFilterChange('emailFilterText')}
                placeholder="Email"
              />
            </Column>
          </>
          }
        </GridRow>
        <GridRow style={{ paddingTop: '16px' }}>
          <Column md={2}>
            <TextField
              onChange={onTextFilterChange('phoneFilterText')}
              placeholder="Phone"
            />
          </Column>
          <Column md={3}>
            <TextField
              onChange={onTextFilterChange('addressFilterText')}
              placeholder="Address"
            />
          </Column>
          <Column md={3}>
            <TextField
              onChange={onTextFilterChange('cityFilterText')}
              placeholder="City"
            />
          </Column>
          <Column md={2}>
            <TextField
              onChange={onTextFilterChange('stateFilterText')}
              placeholder="State"
            />
          </Column>
          <Column md={1}>
            <TextField
              onChange={onTextFilterChange('zipFilterText')}
              placeholder="Zip"
            />
          </Column>
          <Column md={1}>
            <Checkbox
              label="Fuzzy"
              checked={state.isFuzzyMatch}
              onChange={onTextFilterChange('isFuzzyMatch')}
            />
          </Column>
        </GridRow>
      </>
    );
  };

  const hasSearchEntries = state.filterText !== '' || state.isAdvancedSearch;

  return (
    <Fragment>
      <BackButton history={history} isFixed={true} />
      <div className="Contacts depth-1">
        <div className="fixed-search">
          <header>
            <h3>Contacts</h3>
            <span>
              <ActionButton
                iconProps={{ iconName: 'PlusCircle' }}
                onClick={openDetailsPanel(null)}
              >
                Add New Contact
              </ActionButton>
            </span>
          </header>
          {(contacts.contacts.length > 0 || hasSearchEntries) && (
            <Grid>
              <Column md={9}>
                {!state.isAdvancedSearch && showSearch()}
                {state.isAdvancedSearch && showAdvancedSearch()}
              </Column>
              <Column md={3} style={{ marginTop: state.isAdvancedSearch ? '' : '28px' }}>
                <Checkbox
                  label="Advanced Search"
                  checked={state.isAdvancedSearch}
                  onChange={onTextFilterChange('isAdvancedSearch')}
                />
              </Column>
            </Grid>
          )}
        </div>
        <div className="contacts-content" style={{ paddingTop: state.isAdvancedSearch ? '170px' : '151px' }}>
          <Grid>
            {contacts.contacts.length > 0 && (
              <GridRow>
                <Column>
                  <div className="contacts-table">
                    <List
                      items={contacts.contacts.map(toContact)}
                      onRenderCell={onRenderCell}
                    />
                    <ScrollObserver
                      getNextPageStatus={getNextPageStatus}
                      scrollRef={scrollRef}
                    />
                  </div>
                </Column>
              </GridRow>
            )}
            {contacts.contacts.length === 0 &&
              contacts.getContactsStatus === statuses.PROCESSING && (
                <GridRow>
                  <Column
                    classNames="ms-textAlignCenter"
                    style={{ padding: 48 }}
                  >
                    <Spinner size={SpinnerSize.large} />
                  </Column>
                </GridRow>
              )}
            {contacts.contacts.length === 0 &&
              contacts.getContactsStatus === statuses.SUCCESS &&
              state.filterText === '' && (
                <GridRow>
                  <Column>
                    <div className="empty-contacts-view">
                      <Icon
                        iconName="UserCircle"
                        styles={{ root: { fontSize: 72, color: '#71afe5' } }}
                      />
                      <div>
                        <p style={{ fontSize: 20 }}>
                          {
                            'There aren\'t any contacts, click "Add New Contact" to get started.'
                          }
                        </p>
                      </div>
                    </div>
                  </Column>
                </GridRow>
              )}
          </Grid>
        </div>
      </div>
      <ContactDetailsPanel
        contact={state.selectedContact}
        showPanel={state.showDetailsPanel}
        closePanel={closeDetailsPanel}
        saveContact={saveContact}
        addContribution={addContribution}
        updateContact={updateContact}
        deleteContact={deleteContact}
        session={session}
      />
      {state.selectedContacts.length > 1 && (
        <div
          className={`selected-contact-actions${
            state.selectedContacts.length === 0 ? ' hide' : ' show'
          }`}
        >
          <div
            className={`actions-inner${
              state.selectedContacts.length === 0 ? ' hide' : ' show'
            }`}
          >
            <h3>{`${state.selectedContacts.length} ${
              state.selectedContacts.length === 2 ? 'contact' : 'contacts'
            } selected: `}</h3>
            <span>
              <DefaultButton
                text="Cancel"
                onClick={() => setState({ ...state, selectedContacts: [] })}
                style={{ marginRight: 16 }}
              />
              {state.selectedContacts.length === 2 && (
                <DefaultButton
                  text={
                    session.isSysAdminRole()
                      ? 'Admin Consolidate Selected Contacts'
                      : 'Consolidate Selected Contacts'
                  }
                  onClick={mergeContacts}
                  iconProps={{ iconName: 'Cloudsmith' }}
                />
              )}
            </span>
          </div>
        </div>
      )}
      {state.showMergePanel && (
        <MergeContactsPanel
          showPanel={state.showMergePanel}
          closePanel={closeMergePanel}
          saveMerge={saveMergeContacts}
          consolidateContacts={consolidateContacts}
          contactsToMerge={state.contactsToMerge}
          session={session}
        />
      )}
    </Fragment>
  );
};

Contacts.propTypes = {
  history: PropTypes.object.isRequired,
  contacts: PropTypes.object.isRequired,
  contactActions: PropTypes.object.isRequired,
  session: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    contacts: state.contacts,
    session: state.user.session,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    contactActions: bindActionCreators(contactActions, dispatch),
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Contacts),
);
