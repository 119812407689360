import React from 'react';
import { registerIcons } from '@uifabric/styling';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAddressCard,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faBalanceScale,
  faBolt,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faChalkboardTeacher,
  faChartBar,
  faChartPie,
  faCheck,
  faCheckDouble,
  faCheckSquare,
  faChevronUp,
  faCircle,
  faCity,
  faCreditCard,
  faEnvelope,
  faExchangeAlt,
  faExclamationCircle,
  faExclamationTriangle,
  faFile,
  faFileAlt,
  faFileCsv,
  faFileExport,
  faFileImport,
  faFileInvoiceDollar,
  faFilter,
  faFlagUsa,
  faFunnelDollar,
  faHistory,
  faInfo,
  faInfoCircle,
  faList,
  faListAlt,
  faListUl,
  faLock,
  faMinusCircle,
  faMoneyBillAlt,
  faMoneyBillWaveAlt,
  faMoneyCheckAlt,
  faPencilAlt,
  faPlus,
  faPlusCircle,
  faPrint,
  faProjectDiagram,
  faQuestion,
  faReceipt,
  faSearch,
  faSearchDollar,
  faSignOutAlt,
  faSitemap,
  faSort,
  faSortDown,
  faSortUp,
  faStop,
  faThumbsUp,
  faTimes,
  faUndo,
  faUniversity,
  faUpload,
  faUser,
  faUserCheck,
  faUserCircle,
  faUserEdit,
  faUserFriends,
  faUserMinus,
  faUsers,
  faUserSlash,
  faUserTie,
  faUserTimes,
  faWrench,
} from '@fortawesome/free-solid-svg-icons';
import { faCloudsmith } from '@fortawesome/free-brands-svg-icons';
import {
  faAddressCard as faAddressCardAlt,
  faArrowAltCircleLeft,
  faBuilding,
  faCalendarAlt,
  faCheckCircle,
  faCreditCard as faCreditCardAlt,
  faEye,
  faEyeSlash,
  faFilePdf,
  faFrown,
  faSquare,
  faTrashAlt,
  faUser as faUserReg,
} from '@fortawesome/free-regular-svg-icons';

export default function initializeIcons() {
  library.add(
    faAddressCard,
    faArrowLeft,
    faArrowRight,
    faArrowUp,
    faBalanceScale,
    faBolt,
    faBuilding,
    faCalendarAlt,
    faCaretDown,
    faCaretLeft,
    faCaretRight,
    faChalkboardTeacher,
    faChartBar,
    faChartPie,
    faCheck,
    faCheckCircle,
    faCheckDouble,
    faCheckSquare,
    faChevronUp,
    faCircle,
    faCity,
    faCloudsmith,
    faCreditCard,
    faCreditCardAlt,
    faEnvelope,
    faExchangeAlt,
    faExclamationCircle,
    faExclamationTriangle,
    faFile,
    faFileAlt,
    faFileCsv,
    faFileExport,
    faFileImport,
    faFileInvoiceDollar,
    faFilePdf,
    faFilter,
    faFlagUsa,
    faFrown,
    faFunnelDollar,
    faHistory,
    faInfo,
    faInfoCircle,
    faList,
    faListAlt,
    faListUl,
    faLock,
    faMinusCircle,
    faMoneyBillAlt,
    faMoneyBillWaveAlt,
    faMoneyCheckAlt,
    faPencilAlt,
    faPlus,
    faPlusCircle,
    faPrint,
    faProjectDiagram,
    faQuestion,
    faReceipt,
    faSearch,
    faSearchDollar,
    faSignOutAlt,
    faSort,
    faSortDown,
    faSortUp,
    faSquare,
    faStop,
    faTimes,
    faUniversity,
    faUpload,
    faUser,
    faUserCheck,
    faUserCircle,
    faUserEdit,
    faUserFriends,
    faUserMinus,
    faUserReg,
    faUsers,
    faUserSlash,
    faUserTie,
    faUserTimes,
    faThumbsUp,
  );

  registerIcons({
    icons: {
      AddressCard: <FontAwesomeIcon icon={faAddressCard} />,
      AddressCardAlt: <FontAwesomeIcon icon={faAddressCardAlt} />,
      ArrowAltCircleLeft: <FontAwesomeIcon icon={faArrowAltCircleLeft} />,
      ArrowLeft: <FontAwesomeIcon icon={faArrowLeft} />,
      ArrowRight: <FontAwesomeIcon icon={faArrowRight} />,
      ArrowUp: <FontAwesomeIcon icon={faArrowUp} />,
      BalanceScale: <FontAwesomeIcon icon={faBalanceScale} />,
      Bolt: <FontAwesomeIcon icon={faBolt} />,
      Building: <FontAwesomeIcon icon={faBuilding} />,
      CalendarAlt: <FontAwesomeIcon icon={faCalendarAlt} />,
      CaretDown: <FontAwesomeIcon icon={faCaretDown} />,
      CaretLeft: <FontAwesomeIcon icon={faCaretLeft} />,
      CaretRight: <FontAwesomeIcon icon={faCaretRight} />,
      ChalkboardTeacher: <FontAwesomeIcon icon={faChalkboardTeacher} />,
      ChartBar: <FontAwesomeIcon icon={faChartBar} />,
      ChartPie: <FontAwesomeIcon icon={faChartPie} />,
      Check: <FontAwesomeIcon icon={faCheck} />,
      CheckCircle: <FontAwesomeIcon icon={faCheckCircle} />,
      CheckDouble: <FontAwesomeIcon icon={faCheckDouble} />,
      CheckSquare: <FontAwesomeIcon icon={faCheckSquare} />,
      ChevronUp: <FontAwesomeIcon icon={faChevronUp} />,
      Circle: <FontAwesomeIcon icon={faCircle} />,
      City: <FontAwesomeIcon icon={faCity} />,
      Cloudsmith: <FontAwesomeIcon icon={faCloudsmith} />,
      CreditCard: <FontAwesomeIcon icon={faCreditCard} />,
      CreditCardAlt: <FontAwesomeIcon icon={faCreditCardAlt} />,
      Envelope: <FontAwesomeIcon icon={faEnvelope} />,
      ExchangeAlt: <FontAwesomeIcon icon={faExchangeAlt} />,
      ExclamationCircle: <FontAwesomeIcon icon={faExclamationCircle} />,
      ExclamationTriangle: <FontAwesomeIcon icon={faExclamationTriangle} />,
      Eye: <FontAwesomeIcon icon={faEye} />,
      EyeSlash: <FontAwesomeIcon icon={faEyeSlash} />,
      File: <FontAwesomeIcon icon={faFile} />,
      FileAlt: <FontAwesomeIcon icon={faFileAlt} />,
      FileCSV: <FontAwesomeIcon icon={faFileCsv} />,
      FileExport: <FontAwesomeIcon icon={faFileExport} />,
      FileImport: <FontAwesomeIcon icon={faFileImport} />,
      FileInvoiceDollar: <FontAwesomeIcon icon={faFileInvoiceDollar} />,
      FilePdf: <FontAwesomeIcon icon={faFilePdf} />,
      Filter: <FontAwesomeIcon icon={faFilter} />,
      FlagUsa: <FontAwesomeIcon icon={faFlagUsa} />,
      Frown: <FontAwesomeIcon icon={faFrown} />,
      FunnelDollar: <FontAwesomeIcon icon={faFunnelDollar} />,
      Help: <FontAwesomeIcon icon={faQuestion} />,
      History: <FontAwesomeIcon icon={faHistory} />,
      Info: <FontAwesomeIcon icon={faInfo} />,
      InfoCircle: <FontAwesomeIcon icon={faInfoCircle} />,
      List: <FontAwesomeIcon icon={faList} />,
      ListAlt: <FontAwesomeIcon icon={faListAlt} />,
      ListUl: <FontAwesomeIcon icon={faListUl} />,
      Lock: <FontAwesomeIcon icon={faLock} />,
      MinusCircle: <FontAwesomeIcon icon={faMinusCircle} />,
      MoneyBillAlt: <FontAwesomeIcon icon={faMoneyBillAlt} />,
      MoneyCheckAlt: <FontAwesomeIcon icon={faMoneyCheckAlt} />,
      Organization: <FontAwesomeIcon icon={faSitemap} />,
      PencilAlt: <FontAwesomeIcon icon={faPencilAlt} />,
      Plus: <FontAwesomeIcon icon={faPlus} />,
      PlusCircle: <FontAwesomeIcon icon={faPlusCircle} />,
      Print: <FontAwesomeIcon icon={faPrint} />,
      ProjectDiagram: <FontAwesomeIcon icon={faProjectDiagram} />,
      Receipt: <FontAwesomeIcon icon={faReceipt} />,
      Search: <FontAwesomeIcon icon={faSearch} />,
      SearchDollar: <FontAwesomeIcon icon={faSearchDollar} />,
      SignOutAlt: <FontAwesomeIcon icon={faSignOutAlt} />,
      Sort: <FontAwesomeIcon icon={faSort} />,
      SortDown: <FontAwesomeIcon icon={faSortDown} />,
      SortUp: <FontAwesomeIcon icon={faSortUp} />,
      Stop: <FontAwesomeIcon icon={faStop} />,
      Times: <FontAwesomeIcon icon={faTimes} />,
      TrashAlt: <FontAwesomeIcon icon={faTrashAlt} />,
      UnCheckSquare: <FontAwesomeIcon icon={faSquare} />,
      Undo: <FontAwesomeIcon icon={faUndo} />,
      University: <FontAwesomeIcon icon={faUniversity} />,
      Upload: <FontAwesomeIcon icon={faUpload} />,
      User: <FontAwesomeIcon icon={faUser} />,
      UserAlt: <FontAwesomeIcon icon={faUserReg} />,
      UserCheck: <FontAwesomeIcon icon={faUserCheck} />,
      UserCircle: <FontAwesomeIcon icon={faUserCircle} />,
      UserEdit: <FontAwesomeIcon icon={faUserEdit} />,
      UserFriends: <FontAwesomeIcon icon={faUserFriends} />,
      UserMinus: <FontAwesomeIcon icon={faUserMinus} />,
      Users: <FontAwesomeIcon icon={faUsers} />,
      UserSlash: <FontAwesomeIcon icon={faUserSlash} />,
      UserTie: <FontAwesomeIcon icon={faUserTie} />,
      UserTimes: <FontAwesomeIcon icon={faUserTimes} />,
      WavyBill: <FontAwesomeIcon icon={faMoneyBillWaveAlt} />,
      ThumbsUp: <FontAwesomeIcon icon={faThumbsUp} />,
      Fix: <FontAwesomeIcon icon={faWrench} />,
    },
  });
}
