import { takeLatest, put, call } from 'redux-saga/effects';
import axios from 'axios';
import { types } from '../actions/budgetCategoryActions';

export function* handleSetBudgetCategory() {
  try {
    const { data: options } = yield call(
      axios.get,
      '/api/filer/campaignCategory/budgetCategory',
      { withCredentials: true },
    );
    yield put({
      type: types.SET_BUDGET_CATEGORY_SUCCESS,
      data: options,
    });
  } catch (e) {
    yield put({ type: types.SET_BUDGET_CATEGORY_FAILURE });
  }
}

export function* setBudgetCategory() {
  yield takeLatest(
    types.SET_BUDGET_CATEGORY,
    handleSetBudgetCategory,
  );
}
