import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { FederalOfficePicker } from '../../Pickers/FederalOfficePicker';
import { StatePicker } from '../../Pickers';

export const CandidateCampaignCommitteeForm = ({
  handleChange,
  committeeFecId,
  committeeName,
  contactName,
  candidateOffice = '',
  candidateState = 0,
  candidateDistrict = '',
  candidateFecId = '',
  candidateFirstName = '',
  candidateMiddleName = '',
  candidateLastName = '',
}) => (
  <Fragment>
    <TextField
      label="Committee Name"
      value={committeeName}
      onChange={handleChange('businessName')}
    />
    <TextField
      label="Contact Name"
      value={contactName}
      onChange={handleChange('contactName')}
    />
    <TextField label="FEC ID" value={committeeFecId} onChange={handleChange('committeeFecId')} />
    <TextField
      label="Candidate First Name"
      value={candidateFirstName}
      onChange={handleChange('candidateFirstName')}
    />
    <TextField
      label="Candidate Middle Name"
      value={candidateMiddleName}
      onChange={handleChange('candidateMiddleName')}
    />
    <TextField
      label="Candidate Last Name"
      value={candidateLastName}
      onChange={handleChange('candidateLastName')}
    />
    <TextField
      label="Candidate FEC ID"
      value={candidateFecId}
      onChange={handleChange('candidateFecId')}
    />
    <FederalOfficePicker
      selectedKey={candidateOffice}
      label="Candidate Office"
      onChange={handleChange('candidateOffice')}
    />
    <StatePicker
      selectedKey={candidateState}
      fieldLabel="Candidate State"
      onChange={handleChange('candidateState')}
    />
    <TextField
      label="Candidate District"
      value={candidateDistrict}
      onChange={handleChange('candidateDistrict')}
    />
  </Fragment>
  );

CandidateCampaignCommitteeForm.propTypes = {
  handleChange: PropTypes.func.isRequired,
  committeeFecId: PropTypes.string,
  committeeName: PropTypes.string,
  contactName: PropTypes.string,
  candidateOffice: PropTypes.string,
  candidateState: PropTypes.number,
  candidateDistrict: PropTypes.string,
  candidateFecId: PropTypes.string,
  candidateFirstName: PropTypes.string,
  candidateMiddleName: PropTypes.string,
  candidateLastName: PropTypes.string,
};
