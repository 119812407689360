import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { GridRow, Column, MoneyField } from '../../common';

const FECSection7a = ({ q7aColA, q7aColB }) => {
  return (
    <Fragment>
      <GridRow>
        <Column md={4} classNames="sub-question">
          <p>
            (a) Total Operating Expenditures
            <br />
            {'(from Line 17)'}
          </p>
        </Column>
        <Column md={4} classNames="entry-row">
          <MoneyField
            disabled
            value={q7aColA}
          />
        </Column>

        <Column md={4} classNames="entry-row">
          <MoneyField disabled value={q7aColB} />
        </Column>
      </GridRow>
    </Fragment>
  );
};

FECSection7a.propTypes = {
  q7aColA: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  q7aColB: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default FECSection7a;
