import { isInvalidDate, isInvalidNumber } from '../../helpers/util';

export const validate = (formState, isFederal) => {
  const errors = {};
  if (!formState.amount) {
    errors.amountError = 'Amount is required';
  }

  if (formState.amount && isInvalidNumber(formState.amount)) {
    errors.amountError =
      'Invalid value, include only digits and decimal point';
  }

  if (!formState.datePaid) {
    errors.datePaidError = 'Date paid is required';
  } else if (isInvalidDate(formState.datePaid)) {
    errors.datePaidError = 'Invalid date';
  }

  if (formState.contactType === 0) {
    errors.recipientTypeError = 'Recipient type is required';
  }

  if (isFederal && formState.disbursementCategory === 0) {
    errors.disbursementCategoryError = 'Disbursement category is required';
  }

  if (formState.contactType === 'Individual') {
    if (formState.firstName.length === 0) {
      errors.firstNameErrorMessage = 'First name is required';
    }

    if (formState.lastName.length === 0) {
      errors.lastNameErrorMessage = 'Last name is required';
    }
  } else if (formState.contactType === 'Business') {
    if (formState.businessName.length === 0) {
      errors.businessNameError = 'Business name is required';
    }
  } else if (formState.contactType === 'Common Source') {
    if (formState.businessName.length === 0) {
      errors.commonSourceNameError = 'Common source name is required';
    }
  } else if (formState.contactType === 'Committee') {
    if (formState.businessName.length === 0) {
      errors.committeeNameError = 'Committee name is required';
    }
  }

  return errors;
};
