import { types } from '../actions/debtActions';
import { createReducer } from './createReducer';
import { statuses } from '../helpers/constants';

export const initialState = {
  list: [],
  getDebtsStatus: statuses.NOT_STARTED,
  getDebtsError: null,
  saveDebtStatus: statuses.NOT_STARTED,
  saveDebtError: null,
  updateDebtStatus: statuses.NOT_STARTED,
  updateDebtError: null,
  deleteDebtStatus: statuses.NOT_STARTED,
  deleteDebtError: null,
};

const actionMap = {
  [types.GET_DEBTS_PROCESSING]: state => ({
    ...state,
    getDebtsStatus: statuses.PROCESSING,
    getDebtsError: null,
  }),

  [types.GET_DEBTS_SUCCESS]: (state, action) => ({
    ...state,
    getDebtsStatus: statuses.SUCCESS,
    getDebtsError: null,
    list: action.data.debts,
  }),

  [types.GET_DEBTS_FAILURE]: (state, action) => ({
    ...state,
    getDebtsStatus: statuses.ERROR,
    getDebtsError: action.error,
  }),

  [types.CLEAR_DEBTS]: () => ({ ...initialState }),

  [types.SAVE_DEBT_PROCESSING]: state => ({
    ...state,
    saveDebtStatus: statuses.PROCESSING,
    saveDebtError: null,
  }),

  [types.SAVE_DEBT_SUCCESS]: (state, action) => ({
    ...state,
    list: [...state.list, action.data.debt],
    saveDebtStatus: statuses.SUCCESS,
    saveDebtError: null,
  }),

  [types.SAVE_DEBT_FAILURE]: (state, action) => ({
    ...state,
    saveDebtStatus: statuses.ERROR,
    saveDebtError: action.error,
  }),

  [types.UPDATE_DEBT_PROCESSING]: state => ({
    ...state,
    updateDebtStatus: statuses.PROCESSING,
    updateDebtError: null,
  }),

  [types.UPDATE_DEBT_SUCCESS]: (state, action) => ({
    ...state,
    list: state.list.map(debt => {
      if (action.data.debtId === debt._id) {
        debt = {
          ...debt,
          address: {
            ...action.data.payload.address,
          },
          contactType: action.data.payload.contactType,
          firstName: action.data.payload.firstName,
          incurredAmount: action.data.payload.incurredAmount,
          lastName: action.data.payload.lastName,
          middleName: action.data.payload.middleName,
          businessName: action.data.payload.businessName,
          paymentAmount: action.data.payload.paymentAmount,
          periodBeginningBalance: action.data.payload.periodBeginningBalance,
          periodEndingBalance: action.data.payload.periodEndingBalance,
          prefix: action.data.payload.prefix,
          purpose: action.data.payload.purpose,
          suffix: action.data.payload.suffix,
        };
      }
      return debt;
    }),
    updateDebtStatus: statuses.SUCCESS,
    updateDebtError: null,
  }),

  [types.UPDATE_DEBT_FAILURE]: (state, action) => ({
    ...state,
    updateDebtStatus: statuses.ERROR,
    updateDebtError: action.error,
  }),

  [types.DELETE_DEBT_PROCESSING]: state => ({
    ...state,
    deleteDebtStatus: statuses.PROCESSING,
    deleteDebtError: null,
  }),

  [types.DELETE_DEBT_SUCCESS]: (state, action) => ({
    ...state,
    list: state.list.filter(d => d._id !== action.data.debtId),
    deleteDebtStatus: statuses.SUCCESS,
    deleteDebtError: null,
  }),

  [types.DELETE_DEBT_FAILURE]: (state, action) => ({
    ...state,
    deleteDebtStatus: statuses.ERROR,
    deleteDebtError: action.error,
  }),
};

export const debtsReducer = createReducer(initialState, actionMap);
