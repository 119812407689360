import {
  printFullNameLastNameFirst,
  printFullNameFirstNameFirst,
} from '../helpers/util';

export default class Candidate {
  constructor(dto = {}) {
    this.fecIdNumber = dto.fecIdNumber || '';
    this.firstName = dto.firstName;
    this.middleName = dto.middleName;
    this.lastName = dto.lastName;
    this.emailAddress = dto.emailAddress;
    this.telephoneNumber = dto.telephoneNumber;
    this.addressLine1 = dto.addressLine1;
    this.addressLine2 = dto.addressLine2;
    this.city = dto.city;
    this.state = dto.state;
    this.zipCode = dto.zipCode;
    this.filerId = dto.filerId;
    this.filerPIN = dto.filerPIN;
    this.filerEmailAddress = dto.filerEmailAddress;
  }

  displayName() {
    return printFullNameLastNameFirst(this);
  }

  properName() {
    return printFullNameFirstNameFirst(this);
  }
}
