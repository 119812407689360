export const types = {
  GET_USERS_FOR_CAMPAIGN: 'GET_USERS_FOR_CAMPAIGN',
  GET_USERS_FOR_CAMPAIGN_PROCESSING: 'GET_USERS_FOR_CAMPAIGN_PROCESSING',
  GET_USERS_FOR_CAMPAIGN_SUCCESS: 'GET_USERS_FOR_CAMPAIGN_SUCCESS',
  CLEAR_CURRENT_USER_LIST: 'CLEAR_CURRENT_USER_LIST',
  ADD_USER: 'ADD_USER',
  ADD_USER_SUCCESS: 'ADD_USER_SUCCESS',
  EDIT_USER: 'EDIT_USER',
  GET_USER_FOR_EDIT: 'GET_USER_FOR_EDIT',
  GET_USER_FOR_EDIT_SUCCESS: 'GET_USER_FOR_EDIT_SUCCESS',
  RESEND_INVITE: 'RESEND_INVITE',
  RESEND_INVITE_SUCCESS: 'RESEND_INVITE_SUCCESS',
  TOGGLE_USER_ACTIVE: 'TOGGLE_USER_ACTIVE',
};

export const actions = {
  getUsersForCampaign() {
    return { type: types.GET_USERS_FOR_CAMPAIGN };
  },

  clearCurrentUserList() {
    return { type: types.CLEAR_CURRENT_USER_LIST };
  },

  addUser(user) {
    return { type: types.ADD_USER, data: { user } };
  },

  editUser(_id, user) {
    return { type: types.EDIT_USER, data: { _id, user } };
  },

  getUserForEdit(_id) {
    return { type: types.GET_USER_FOR_EDIT, data: { _id } };
  },

  resendInvite(_id) {
    return { type: types.RESEND_INVITE, data: { _id } };
  },

  toggleUserActive(_id, isActive) {
    return { type: types.TOGGLE_USER_ACTIVE, data: { _id, isActive } };
  },
};
