import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Image } from 'office-ui-fabric-react/lib/Image';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import {
  DefaultButton,
  PrimaryButton,
} from 'office-ui-fabric-react/lib/Button';
import { Toggle } from 'office-ui-fabric-react/lib/Toggle';
import { Link } from 'office-ui-fabric-react/lib/Link';
import { Grid, GridRow, Column } from '../../components/common';
import { Toast } from '../../components/Toast';
import FRLogo from '../../assets/img/HRFrontRunner.png';
import { validate } from './VerifyInviteValidations';
import { actions as verifyUserActions } from '../../actions/verifyUserActions';
import { TermsAndConditionsDialog } from '../../components/Dialogs';
import './VerifyInvite.css';

const initialState = {
  newPassword: '',
  confirmPassword: '',
  termsAccepted: false,
  termsAndConditionsDialogHidden: true,
};

const VerifyInvite = ({
  verifyUserActions,
  match,
  messages,
  verifyUser,
  history,
}) => {
  const [state, setState] = useState({ ...initialState });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const { verificationToken: token } = match.params;
    verifyUserActions.verifyToken(token);
  }, []);

  const onChange = fieldName => (e, value) => {
    setState({
      ...state,
      [fieldName]: value,
    });
  };

  const toggleAcceptTandC = (e, termsAccepted) => {
    setState(() => ({
      ...state,
      termsAccepted,
    }));
  };

  const acceptTermsFromDialog = () => {
    setState({
      ...state,
      termsAccepted: true,
      termsAndConditionsDialogHidden: true,
    });
  };

  const openTandCDialog = () => {
    setState({ ...state, termsAndConditionsDialogHidden: false });
  };

  const closeTandCDialog = () => {
    setState({ ...state, termsAndConditionsDialogHidden: true });
  };

  const onVerifyUser = () => {
    const errors = validate(state);
    if (Object.keys(errors).length) {
      setErrors(errors);
    } else {
      setErrors({});
      const payload = {
        password: state.newPassword,
        termsOfServiceAccepted: state.termsAccepted,
      };
      verifyUserActions.verifyUser(verifyUser.token, payload);
    }
  };

  return (
    <div className="VerifyInvite-wrapper">
      <div className="VerifyInvite depth-1">
        <Grid>
          <GridRow>
            <Column md={6} classNames="ms-mdPush3 ms-lgPush3 ms-xlPush3 logo">
              <Image src={FRLogo} alt="FrontRunner" height={150} />
            </Column>
          </GridRow>
          {verifyUser.verified && (
            <Fragment>
              <GridRow>
                <Column>
                  <p className="ms-textAlignCenter">
                    {
                      "Congratulations, you've confirmed your email address. Please enter a password to begin:"
                    }
                  </p>
                </Column>
              </GridRow>
              <GridRow>
                <Column>
                  <TextField
                    type="password"
                    label="New Password"
                    value={state.newPassword}
                    onChange={onChange('newPassword')}
                    errorMessage={errors.newPasswordError}
                  />
                </Column>
              </GridRow>
              <GridRow>
                <Column>
                  <TextField
                    type="password"
                    label="Confirm New Password"
                    value={state.confirmPassword}
                    errorMessage={errors.confirmPasswordError}
                    onChange={onChange('confirmPassword')}
                  />
                </Column>
              </GridRow>
              <GridRow>
                <Column md={6}>
                  <Toggle
                    checked={state.termsAccepted}
                    label="I Accept the Terms and Conditions"
                    onText="Yes"
                    offText="No"
                    onChange={toggleAcceptTandC}
                  />
                </Column>
                <Column md={6} classNames="ms-textAlignRight">
                  <DefaultButton text="View Terms" onClick={openTandCDialog} />
                </Column>
              </GridRow>
              <GridRow>
                <Column classNames="ms-textAlignCenter verify-invite-actions">
                  <PrimaryButton
                    text="Continue"
                    onClick={onVerifyUser}
                    disabled={!state.termsAccepted}
                  />
                </Column>
              </GridRow>
            </Fragment>
          )}
          {!verifyUser.verified && (
            <GridRow>
              <Column>
                <p className="ms-textAlignCenter">
                  {
                    "Sorry, we couldn't find that invite. Contact the person that invited you and ask him/her to resend the invite."
                  }
                </p>
              </Column>
            </GridRow>
          )}
          <GridRow>
            <Column classNames="ms-textAlignCenter">
              <Link
                onClick={() => {
                  history.push('/');
                }}
              >
                Return to Login Screen
              </Link>
            </Column>
          </GridRow>
        </Grid>
      </div>
      <TermsAndConditionsDialog
        acceptTermsFromDialog={acceptTermsFromDialog}
        termsAndConditionsDialogHidden={state.termsAndConditionsDialogHidden}
        closeDialog={closeTandCDialog}
      />
      {messages.message && (
        <Toast
          message={messages.message}
          toastType={messages.toastType}
          position={messages.position}
        />
      )}
    </div>
  );
};

VerifyInvite.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  messages: PropTypes.object.isRequired,
  verifyUserActions: PropTypes.object.isRequired,
  verifyUser: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    messages: state.messages,
    verifyUser: state.verifyUser,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    verifyUserActions: bindActionCreators(verifyUserActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VerifyInvite);
