export const types = {
  GET_INTEGRATION_SETTINGS: 'GET_INTEGRATION_SETTINGS',
  GET_INTEGRATION_SETTINGS_PROCESSING: 'GET_INTEGRATION_SETTINGS_PROCESSING',
  GET_INTEGRATION_SETTINGS_SUCCESS: 'GET_INTEGRATION_SETTINGS_SUCCESS',
  GET_INTEGRATION_SETTINGS_FAILURE: 'GET_INTEGRATION_SETTINGS_FAILURE',
  CREATE_INTEGRATION_SETTING: 'CREATE_INTEGRATION_SETTING',
  CREATE_INTEGRATION_SETTING_PROCESSING: 'CREATE_INTEGRATION_SETTING_PROCESSING',
  CREATE_INTEGRATION_SETTING_SUCCESS: 'CREATE_INTEGRATION_SETTING_SUCCESS',
  CREATE_INTEGRATION_SETTING_FAILURE: 'CREATE_INTEGRATION_SETTING_FAILURE',
  UPDATE_INTEGRATION_SETTING: 'UPDATE_INTEGRATION_SETTING',
  UPDATE_INTEGRATION_SETTING_PROCESSING: 'UPDATE_INTEGRATION_SETTING_PROCESSING',
  UPDATE_INTEGRATION_SETTING_SUCCESS: 'UPDATE_INTEGRATION_SETTING_SUCCESS',
  UPDATE_INTEGRATION_SETTING_FAILURE: 'UPDATE_INTEGRATION_SETTING_FAILURE',
};
