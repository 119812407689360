import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  IconButton,
  ActionButton,
  DefaultButton,
  PrimaryButton,
} from 'office-ui-fabric-react/lib/Button';
import { Link } from 'office-ui-fabric-react/lib/Link';
import {
  DetailsList,
  SelectionMode,
  CheckboxVisibility,
} from 'office-ui-fabric-react/lib/DetailsList';
import { Grid, GridRow, Column } from '../common';
import { formatCurrency } from '../../helpers/util';
import { EmptyView } from '../EmptyView';
import { DebtDialog, ConfirmDeleteFromReportDialog } from '../Dialogs';

const DebtsSection = ({
  debts,
  debtActions,
  toggleExpandSection,
  reportId,
  state,
  sectionName,
  session,
}) => {
  const [debtDialogHidden, setDebtDialogHidden] = useState(true);
  const [debtToEdit, setDebtToEdit] = useState(null);
  const [confirmDeleteHidden, setConfirmDeleteHidden] = useState(true);
  const [debtToDelete, setDebtToDelete] = useState(null);

  useEffect(() => {
    debtActions.getDebts(reportId);
    return () => {
      debtActions.clearDebts();
    };
  }, []);

  const openDebtDialog = () => {
    setDebtDialogHidden(false);
  };

  const closeDebtDialog = () => {
    setDebtToEdit(null);
    setDebtDialogHidden(true);
  };

  const addDebt = debt => {
    const payload = {
      contactId: debt.contactId,
      contactType: debt.contactType,
      businessName: debt.businessName || undefined,
      prefix: debt.prefix !== 'none' ? debt.prefix : undefined,
      firstName: debt.firstName || undefined,
      middleName: debt.middleName || undefined,
      lastName: debt.lastName || undefined,
      suffix: debt.suffix || undefined,
      address: {
        addressLine1: debt.addressLine1 || undefined,
        addressLine2: debt.addressLine2 || undefined,
        city: debt.city || undefined,
        state: debt.state || undefined,
        zipCode: debt.zipCode || undefined,
      },
      purpose: debt.purpose || undefined,
      periodBeginningBalance: debt.periodBeginningBalance,
      incurredAmount: debt.incurredAmount,
      paymentAmount: debt.paymentAmount,
      periodEndingBalance: debt.periodEndingBalance,
    };

    debtActions.saveDebt(reportId, payload);
  };

  const updateDebt = debt => {
    const payload = {
      contactId: debt.contactId,
      contactType: debt.contactType,
      businessName: debt.businessName || undefined,
      prefix: debt.prefix !== 'none' ? debt.prefix : undefined,
      firstName: debt.firstName || undefined,
      middleName: debt.middleName || undefined,
      lastName: debt.lastName || undefined,
      suffix: debt.suffix || undefined,
      address: {
        addressLine1: debt.addressLine1 || undefined,
        addressLine2: debt.addressLine2 || undefined,
        city: debt.city || undefined,
        state: debt.state || undefined,
        zipCode: debt.zipCode || undefined,
      },
      purpose: debt.purpose || undefined,
      periodBeginningBalance: debt.periodBeginningBalance,
      incurredAmount: debt.incurredAmount,
      paymentAmount: debt.paymentAmount,
      periodEndingBalance: debt.periodEndingBalance,
    };

    debtActions.updateDebt(reportId, debtToEdit._id, payload);
    setDebtToEdit(null);
  };

  // eslint-disable-next-line
  const setDebtForEdit = debt => {
    setDebtToEdit(debt);
    setDebtDialogHidden(false);
  };

  const deleteDebt = debt => {
    if (!debt.isItemFiled) {
      debtActions.deleteDebt(reportId, debt._id);
      setDebtToDelete(null);
    } else {
      setDebtToDelete(debt);
      setConfirmDeleteHidden(false);
    }
  };

  const cancelDelete = () => {
    setDebtToDelete(null);
    setConfirmDeleteHidden(true);
  };

  const confirmDelete = () => {
    debtActions.deleteDebt(reportId, debtToDelete._id);
    setDebtToDelete(null);
    setConfirmDeleteHidden(true);
  };

  const createColumns = () => [
    {
      key: 'name',
      fieldName: 'name',
      name: 'Name',
      minWidth: 150,
      maxWidth: 180,
      isResizable: true,
      onRender: item => {
        return ['ORG', 'Business'].includes(item.contactType)
          ? item.businessName
          : `${item.prefix ? item.prefix + ' ' : ''}${item.firstName} ${
              item.middleName ? item.middleName + ' ' : ''
            }${item.lastName}${item.suffix ? ' ' + item.suffix : ''}`;
      },
    },
    {
      key: 'address',
      fieldName: 'address',
      name: 'Address',
      minWidth: 75,
      maxWidth: 145,
      isResizable: true,
      onRender: item =>
        item.address ? (
          <div>
            <p style={{ marginBottom: 2, marginTop: 0 }}>
              {item.address.addressLine1 || ''}
            </p>
            {item.address.addressLine2 && (
              <p style={{ marginBottom: 2, marginTop: 0 }}>
                {item.address.addressLine2}
              </p>
            )}
            <p style={{ marginBottom: 2, marginTop: 0 }}>{`${
              item.address.city
            }${item.address.state ? ', ' + item.address.state : ''}${
              item.address.zipCode ? ' ' + item.address.zipCode : ''
            }`}</p>
          </div>
        ) : (
          ''
        ),
    },
    {
      key: 'periodBeginningBalance',
      fieldName: 'periodBeginningBalance',
      name: 'Beginning Balance',
      minWidth: 100,
      maxWidth: 115,
      isResizable: true,
      onRender: item => formatCurrency(item.periodBeginningBalance),
    },
    {
      key: 'incurredAmount',
      fieldName: 'incurredAmount',
      name: 'Incurred Amount',
      minWidth: 75,
      maxWidth: 115,
      isResizable: true,
      onRender: item => formatCurrency(item.incurredAmount),
    },
    {
      key: 'paymentAmount',
      fieldName: 'paymentAmount',
      name: 'Payment Amount',
      minWidth: 75,
      maxWidth: 115,
      isResizable: true,
      onRender: item => formatCurrency(item.paymentAmount),
    },
    {
      key: 'periodEndingBalance',
      fieldName: 'periodEndingBalance',
      name: 'Ending Balance',
      minWidth: 100,
      maxWidth: 115,
      isResizable: true,
      onRender: item => formatCurrency(item.periodEndingBalance),
    },
    {
      key: 'actions',
      fieldName: '',
      name: '',
      minWidth: 150,
      maxWidth: 150,
      isResizable: false,
      onRender: item => (
        <span
          className="ms-textAlignRight"
          style={{ display: 'inline-block', width: '100%' }}
        >
          <IconButton
            iconProps={{
              iconName: 'PencilAlt',
            }}
            styles={{
              root: {
                marginRight: 32,
              },
            }}
            onClick={() => setDebtForEdit(item)}
          />
          <IconButton
            styles={{
              root: {
                marginRight: 16,
              },
            }}
            iconProps={{
              iconName: 'TrashAlt',
            }}
            onClick={() => deleteDebt(item)}
          />
        </span>
      ),
    },
  ];

  return (
    <Fragment>
      <div className="FECDebts fec-report-section depth-1" name={sectionName}>
        <h3>
          DEBTS{' '}
          <small>
            {' '}
            (
            <Link onClick={() => toggleExpandSection(sectionName, false)}>
              {state.expandableSections[sectionName] === true
                ? 'Collapse'
                : 'Expand'}
            </Link>
            )
          </small>
        </h3>
        <div
          className={`content${
            state.expandableSections[sectionName] === true
              ? '-expanded'
              : '-collapsed'
          }`}
        >
          <Grid>
            <GridRow>
              <Column classNames="ms-textAlignRight">
                <ActionButton
                  iconProps={{
                    iconName: 'PlusCircle',
                    styles: {
                      root: {
                        fontSize: '1.3em',
                      },
                    },
                  }}
                  onClick={openDebtDialog}
                >
                  Add New Debt
                </ActionButton>
              </Column>
            </GridRow>
            {debts && debts.length === 0 && (
              <EmptyView message="There are no debts this reporting period." />
            )}
            {debts && debts.length !== 0 && (
              <DetailsList
                checkboxVisibility={CheckboxVisibility.hidden}
                selectionMode={SelectionMode.none}
                columns={createColumns()}
                items={debts}
              />
            )}
          </Grid>
          <div className="section-actions">
            <DefaultButton
              text="Previous Section"
              style={{ marginRight: 16 }}
              onClick={() => toggleExpandSection('itemizedLoans', false)}
            />
            <PrimaryButton
              text="Save & Continue"
              onClick={() => toggleExpandSection('f3Z', true)}
            />
          </div>
        </div>
      </div>
      <DebtDialog
        session={session}
        dialogHidden={debtDialogHidden}
        closeDialog={closeDebtDialog}
        debtToEdit={debtToEdit}
        addDebt={addDebt}
        updateDebt={updateDebt}
        electionYear={state.electionYear}
      />
      <ConfirmDeleteFromReportDialog
        dialogHidden={confirmDeleteHidden}
        cancel={cancelDelete}
        confirm={confirmDelete}
        itemType="Debt"
      />
    </Fragment>
  );
};

DebtsSection.propTypes = {
  debts: PropTypes.array.isRequired,
  debtActions: PropTypes.object.isRequired,
  toggleExpandSection: PropTypes.func.isRequired,
  reportId: PropTypes.string.isRequired,
  state: PropTypes.object.isRequired,
  sectionName: PropTypes.string.isRequired,
  session: PropTypes.object.isRequired,
};

export default DebtsSection;
