import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { injectStripe } from 'react-stripe-elements';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  DefaultButton,
  PrimaryButton,
} from 'office-ui-fabric-react/lib/Button';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import {
  Dialog,
  DialogFooter,
  DialogType,
} from 'office-ui-fabric-react/lib/Dialog';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { Image } from 'office-ui-fabric-react/lib/Image';
// eslint-disable-next-line import/no-named-as-default
import CreditCardForm from '../../components/CreditCardForm';
import Loading from '../../components/Loading';
import { statuses } from '../../helpers/constants';
import { actions as paymentActions } from '../../actions/paymentActions';
import { scrollToTop } from '../../helpers/util';
import { validate } from './CreditCardFormValidations';
import FRLogo from '../../assets/img/HRFrontRunner.png';
import './EditBillingInfo.css';

export const EditBillingInfo = ({
  history,
  stripe,
  paymentActions,
  payment,
}) => {
  const [ccInfo, setCCInfo] = useState({
    cardholderName: '',
    cardholderEmail: '',
    coupon: '',
  });
  const [hideCancelAccountDialog, setHideCancelAccountDialog] = useState(true);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    scrollToTop();
    paymentActions.getCCDetails();
  }, []);

  useEffect(() => {
    setCCInfo({
      ...ccInfo,
      cardholderName: payment.cardholderName,
      cardholderEmail: payment.cardholderEmail,
    });
  }, [payment]);

  const handleTextChange = fieldName => (e, value) => {
    setCCInfo({
      ...ccInfo,
      [fieldName]: value,
    });
  };

  const openCancelAccountDialog = () => {
    setHideCancelAccountDialog(false);
  };

  const closeCancelAccountDialog = () => {
    setHideCancelAccountDialog(true);
  };

  const cancelAccount = () => {
    setHideCancelAccountDialog(true);
    paymentActions.cancelAccount();
  };

  const saveCCDetails = () => {
    const errors = validate(ccInfo);
    if (Object.keys(errors).length) {
      setErrors(errors);
    } else {
      const payload = {
        plan: payment.planId,
        cardholderName: ccInfo.cardholderName,
        cardholderEmail: ccInfo.cardholderEmail,
        stripe,
        coupon: ccInfo.coupon,
      };
      paymentActions.saveCCDetails(payload);
    }
  };

  const cancel = () => {
    paymentActions.resetStatuses();
    history.push('/filer');
  };

  if (!payment.planId || payment.cancelAccountStatus === statuses.PROCESSING) {
    return <Loading />;
  }

  return (
    <Fragment>
      <div className="EditBillingInfo depth-1">
        <div className="logo ms-textAlignCenter">
          <Image src={FRLogo} width={125} />
          <h3>Update Payment Information</h3>
        </div>
        <CreditCardForm
          ccInfo={{
            ...ccInfo,
            coupon: payment.coupon || ccInfo.coupon,
            ...errors,
          }}
          planPrice={payment.planPrice}
          productName={payment.planDescription}
          getPlanPriceStatus={payment.planPriceStatus}
          stripeError={
            payment.stripeError ||
            payment.submitError ||
            payment.cancelAccountError
          }
          status={payment.stripeStatus}
          formSection="cardholderEmailError"
          actions={{ handleTextChange }}
          showCoupon
        />
        <div className="update-billing-info-actions">
          {payment.submitStatus !== statuses.PROCESSING && (
            <Fragment>
              <DefaultButton
                className="cancel-btn"
                onClick={cancel}
                text="Cancel"
              />
              <PrimaryButton text="Save" onClick={saveCCDetails} />
            </Fragment>
          )}
          {payment.submitStatus === statuses.PROCESSING && (
            <Spinner size={SpinnerSize.large} />
          )}
        </div>
      </div>

      {payment.accountStatus.toLowerCase() !== 'inactive' && (
        <div className="CancelAccount depth-1">
          <div className="cancel-header">
            <Icon
              iconName="Frown"
              styles={{
                root: {
                  fontSize: 20,
                  color: '#999',
                  marginRight: 8,
                },
              }}
            />
            <h3>Cancel FrontRunner Account</h3>
          </div>
          <p>
            I no longer wish to take advantage of the services offered by
            FrontRunner and wish to cancel my account.
          </p>
          <div className="edit-billing-info-actions">
            <DefaultButton
              text="I WISH TO CANCEL MY ACCOUNT"
              onClick={openCancelAccountDialog}
            />
          </div>
          <Dialog
            hidden={hideCancelAccountDialog}
            dialogContentProps={{
              type: DialogType.normal,
              title: 'Confirm Account Cancellation',
              subText:
                'Are you absolutely sure you wish to cancel your FrontRunner account? This will take affect immediately.',
            }}
          >
            <DialogFooter>
              <DefaultButton text="No" onClick={closeCancelAccountDialog} />
              <DefaultButton text="Yes, cancel it" onClick={cancelAccount} />
            </DialogFooter>
          </Dialog>
        </div>
      )}
    </Fragment>
  );
};

EditBillingInfo.propTypes = {
  history: PropTypes.object.isRequired,
  stripe: PropTypes.object.isRequired,
  paymentActions: PropTypes.object,
  payment: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    payment: state.payment,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    paymentActions: bindActionCreators(paymentActions, dispatch),
  };
}

export default injectStripe(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps,
    )(EditBillingInfo),
  ),
);
