import React, { useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  CheckboxVisibility,
  Checkbox,
  DefaultButton,
  DetailsList,
  DetailsRow,
  SearchBox,
  SelectionMode,
  Spinner,
  SpinnerSize,
} from 'office-ui-fabric-react';
import {
  TooltipHost,
} from 'office-ui-fabric-react/lib/Tooltip';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { types as messagingTypes } from '../../actions/messagingActions';
import { sortDirections, statuses, toastTypes } from '../../helpers/constants';
import { useIntersect } from '../../hooks/useIntersect';
import { ContributionsPanel } from '../Panels';
import { EmptyView } from '../EmptyView';
import { formatCurrency, formatDate, formatDateTime, getServerSideErrorMessage } from '../../helpers/util';
import { getLabel } from '../../helpers/labelHelper';
import ScrollObserver from '../ScrollObserver';
import { getNextPageStatusContributions } from '../../selectors';
import './index.scss';
import { DatePicker } from '../Pickers';

export const ObservableContributionsList = ({
  history,
  editItem,
  contributions,
  session,
  isHidden,
  filterText = '',
  startDate,
  endDate,
  checkForNoOccupationOrEmployer,
  actions = { handleChange: () => {}, setSort: () => {}, nextPage: () => {} },
  getContributionsStatus = statuses.NOT_STARTED,
  sortField = 'updatedAt',
  sortDirection = sortDirections.DESC,
}) => {
  const reduxDispatch = useDispatch();
  const getNextPageStatus = useSelector(getNextPageStatusContributions);

  if (isHidden) {
    return null;
  }

  const [scrollRef, entry] = useIntersect({
    rootMargin: '80px',
    threshold: 0.3,
  });

  useEffect(() => {
    if (entry.isIntersecting) {
      actions.nextPage();
    }
  }, [entry]);

  const [state, setState] = useState({
    showPanel: false,
    panelItem: null,
    errors: [],
    selectedKey: 0,
  });

  const onColumnClick = (e, column) => {
    const field = column.fieldName;
    let direction;
    if (sortField === column.fieldName) {
      if (sortDirection === sortDirections.ASC) {
        direction = sortDirections.DESC;
      } else if (sortDirection === sortDirections.DESC) {
        direction = sortDirections.NONE;
      } else {
        direction = sortDirections.ASC;
      }
    } else {
      direction = sortDirections.ASC;
    }
    actions.setSort({ sortField: field, sortDirection: direction });
  };

  useEffect(() => {
    setState({
      ...state,
      showPanel: state.panelItem !== null,
    });
  }, [state.panelItem]);

  const showPanel = async item => {
    try {
      const { data } = await axios.get(`/api/filer/contributions/${item._id}`, {
        withCredentials: true,
      });

      if (data.length) {
        const [contribution] = data;
        setState({
          ...state,
          panelItem: contribution,
        });
      }
    } catch (e) {
      const error = getServerSideErrorMessage(e);
      reduxDispatch({
        type: messagingTypes.SET_TOAST,
        data: {
          message: error,
          toastType: toastTypes.ERROR,
        },
      });
    }
  };

  const hidePanel = () => {
    setState({
      ...state,
      panelItem: null,
    });
  };

  const rowIconStyle = {
    root: {
      color: 'rgb(168, 168, 168)',
      fontSize: 16,
      marginRight: 8,
    },
  };

  const getIsFiled = () => {
    return (
      <TooltipHost
        content="Filed"
        closeDelay={750}
      >
        <Icon
          iconName="ListAlt"
          styles={rowIconStyle}
        />
      </TooltipHost>);
  };

  const getIsDeposited = (message) => {
    return (
      <TooltipHost
        content={message}
        closeDelay={750}
      >
        <Icon
          iconName="FileInvoiceDollar"
          styles={rowIconStyle}
        />
      </TooltipHost>);
  };

  const getDepositedMessage = (item) => {
    if (!item) {
      return '';
    }
    const message = (
      <div>
        <div>{item.name}</div>
        <div>Amount: {formatCurrency(item.amount)}</div>
        <div>Deposit Date: {formatDate(item.depositDate)}</div>
        <div>By Check Count: {item.checksTenderedCount}</div>
        <div>
          <a
            href="#"
            onClick={() =>
              history.push(`/filer/editDeposit/${item._id}`)
            }
          >
            Open Deposit
          </a>
        </div>
      </div>
    );
    return getIsDeposited(message);
  };

  const getIsReconciled = () => {
    return (
      <TooltipHost
        content="Reconciled"
        closeDelay={750}
      >
        <Icon
          iconName="BalanceScale"
          styles={rowIconStyle}
        />
      </TooltipHost>);
  };

  const columns = useMemo(() => [
    {
      key: 'contributor-name',
      name: 'Contributor Name',
      fieldName: 'displayName',
      data: 'string',
      minWidth: 140,
      isResizable: true,
      isSorted:
        sortField === 'sortName' && sortDirection !== sortDirections.NONE,
      isSortedDescending: sortDirection === sortDirections.DESC,
      onColumnClick,
      onRender: item => <span>{item.displayName}</span>,
    },
    {
      key: 'amount',
      name: 'Amount',
      fieldName: 'amount',
      data: 'number',
      minWidth: 70,
      isResizable: true,
      isSorted: sortField === 'amount' && sortDirection !== sortDirections.NONE,
      isSortedDescending: sortDirection === sortDirections.DESC,
      onColumnClick,
      onRender: item => (
        <span style={{ textAlign: 'right', display: 'block' }}>
          {formatCurrency(item.amount)}
        </span>
      ),
    },
    {
      key: 'checkNumber',
      name: 'Check Number',
      fieldName: 'checkNumber',
      data: 'string',
      minWidth: 70,
      isResizable: true,
      isSorted:
        sortField === 'checkNumber' && sortDirection !== sortDirections.NONE,
      isSortedDescending: sortDirection === sortDirections.DESC,
      onColumnClick,
      onRender: item => <span>{item.checkNumber}</span>,
    },
    {
      key: 'sourceReference',
      name: 'Source Reference',
      fieldName: 'sourceReference',
      isResizable: true,
      minWidth: 75,
      isSorted:
        sortField === 'sourceReference' &&
        sortDirection !== sortDirections.NONE,
      isSortedDescending: sortDirection === sortDirections.DESC,
      onColumnClick,
      onRender: item => <span>{item.sourceReference}</span>,
    },
    {
      key: 'referenceId',
      name: 'Reference Id',
      fieldName: 'paymentReferenceId',
      isResizable: true,
      minWidth: 100,
      isSorted:
        sortField === 'paymentReferenceId' &&
        sortDirection !== sortDirections.NONE,
      isSortedDescending: sortDirection === sortDirections.DESC,
      onColumnClick,
      onRender: item => <span>{item.paymentReferenceId}</span>,
    },
    {
      key: 'sourceCode',
      name: 'Source Code',
      fieldName: 'sourceCode',
      isResizable: true,
      minWidth: 100,
      isSorted:
        sortField === 'sourceCode' &&
        sortDirection !== sortDirections.NONE,
      isSortedDescending: sortDirection === sortDirections.DESC,
      onColumnClick,
      onRender: item => <span>{item.sourceCode}</span>,
    },
    {
      key: 'date-received',
      name: 'Date Received',
      fieldName: 'receivedDate',
      minWidth: 100,
      isResizable: true,
      isSorted:
        sortField === 'receivedDate' && sortDirection !== sortDirections.NONE,
      isSortedDescending: sortDirection === sortDirections.DESC,
      onColumnClick,
      onRender: item => <span>{formatDate(item.receivedDate)}</span>,
    },
    {
      key: 'date-entered-updated',
      name: 'Date Entered/Updated',
      fieldName: 'updatedAt',
      data: 'string',
      isResizable: true,
      minWidth: 150,
      isSorted:
        sortField === 'updatedAt' && sortDirection !== sortDirections.NONE,
      isSortedDescending: sortDirection === sortDirections.DESC,
      onColumnClick,
      onRender: item => <span>{formatDateTime(item.updatedAt)}</span>,
    },
    {
      key: 'isItemFiled',
      // iconName: 'ListAlt',
      // iconClassName: 'contribution-header-icon',
      fieldName: 'isItemFiled',
      name: 'FIL',
      isResizable: true,
      isSorted:
        sortField === 'isItemFiled' && sortDirection !== sortDirections.NONE,
      isSortedDescending: sortDirection === sortDirections.DESC,
      onColumnClick,
      onRender: item => item.isItemFiled ? getIsFiled() : null,
      minWidth: 30,
      maxWidth: 30,
    },
    {
      key: 'deposited',
      name: 'DEP',
      // iconName: 'FileInvoiceDollar',
      // iconClassName: 'contribution-header-icon',
      fieldName: 'depositId',
      isResizable: true,
      isSorted:
        sortField === 'depositId' && sortDirection !== sortDirections.NONE,
      isSortedDescending: sortDirection === sortDirections.DESC,
      onColumnClick,
      onRender: item => item.depositId ? getDepositedMessage(item.deposit) : null,
      minWidth: 30,
      maxWidth: 30,
    },
    {
      key: 'reconciled',
      name: 'REC',
      // iconName: 'BalanceScale',
      // iconClassName: 'contribution-header-icon',
      fieldName: 'reconciliationId',
      isResizable: true,
      isSorted:
        sortField === 'reconciliationId' && sortDirection !== sortDirections.NONE,
      isSortedDescending: sortDirection === sortDirections.DESC,
      onColumnClick,
      onRender: item => item.reconciliationId ? getIsReconciled() : null,
      minWidth: 30,
      maxWidth: 30,
    },
  ]);

  const onRenderRow = rowProps => (
    <div onClick={() => showPanel(rowProps.item)}>
      <DetailsRow {...rowProps} />
    </div>
  );

  return (
    <div className="ObservableContributionsList">
      <div className="search-line">
        <div>
          <SearchBox
            placeholder={`Search ${getLabel('Contributions', session)}`}
            value={filterText}
            onChange={actions.handleFilterChange}
            clearButtonProps={{
              iconProps: {
                iconName: 'Times',
              },
            }}
          />
        </div>
        <div style={{ marginLeft: '50px', display: 'flex', marginRight: '12px' }}>
          <div className="date-label">Start Date</div>
          <DatePicker
            onChange={actions.changeDate('startDate')}
            value={startDate}
          />
          <div className="date-label">End Date</div>
          <DatePicker
            onChange={actions.changeDate('endDate')}
            value={endDate}
          />
        </div>
        <TooltipHost
          content="Clear start and end dates."
        >
          <DefaultButton
            text="Clear Dates"
            onClick={actions.clearDates}
          />
        </TooltipHost>
        <div className="no-employee-or-occupation">
          <Checkbox
            label="No Employee/Occupation"
            checked={checkForNoOccupationOrEmployer}
            onChange={actions.setCheckForNoOccupationOrEmployer}
          />
        </div>
      </div>
      {getContributionsStatus === statuses.PROCESSING && (
        <div style={{ padding: '60px 0' }}>
          <Spinner size={SpinnerSize.large} />
        </div>
      )}
      {getContributionsStatus === statuses.SUCCESS && contributions.length > 0 && (
        <>
          <DetailsList
            items={contributions}
            columns={columns}
            compact={false}
            selectionMode={SelectionMode.none}
            checkboxVisibility={CheckboxVisibility.none}
            onRenderRow={onRenderRow}
          />
          <ScrollObserver
            getNextPageStatus={getNextPageStatus}
            scrollRef={scrollRef}
          />
        </>
      )}
      {getContributionsStatus === statuses.SUCCESS &&
        contributions.length === 0 && (
          <EmptyView
            message={`No ${getLabel('contributions', session)} found`}
          />
        )}
      <ContributionsPanel
        item={state.panelItem}
        showPanel={state.showPanel}
        closePanel={hidePanel}
        editItem={editItem}
        session={session}
      />
    </div>
  );
};

ObservableContributionsList.propTypes = {
  history: PropTypes.object.isRequired,
  editItem: PropTypes.func.isRequired,
  contributions: PropTypes.array,
  filterFn: PropTypes.func,
  session: PropTypes.object.isRequired,
  isHidden: PropTypes.bool,
  filterText: PropTypes.string,
  startDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  endDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  checkForNoOccupationOrEmployer: PropTypes.bool,
  actions: PropTypes.object,
  getContributionsStatus: PropTypes.string,
  sortField: PropTypes.string,
  sortDirection: PropTypes.oneOf(Object.values(sortDirections)),
};

ObservableContributionsList.defaultProps = {
  contributions: {
    contributions: [],
  },
  filterFn: null,
  isHidden: false,
};

export default ObservableContributionsList;
