import { frCampaignTypes } from './constants';

export const getFRCampaignType = campaign => {
  const {
    FederalCandidate,
    FederalNonCandidate,
    StateLocalCandidate,
    StateLocalNonCandidate,
  } = frCampaignTypes;

  if (campaign.officeType === 'Federal') {
    return campaign.isNonCandidateCommittee ? FederalNonCandidate : FederalCandidate;
  }
  // State/Local non candidates don't have officeType set in mongo, just check for isNonCandidateCommittee
  return campaign.isNonCandidateCommittee ? StateLocalNonCandidate : StateLocalCandidate;
};

export const isPresidentialCampaign = campaign => campaign.office === 'P';

export const canEditTransactionIdNumber = campaign => {
  return campaign.canEditTransactionIdNumber === true;
};
