import * as R from 'ramda';
import { types } from '../actions/userActions';
import { statuses } from '../helpers/constants';
import Session from '../models/Session';
import { createReducer } from './createReducer';

export const initialState = {
  forgotPasswordStatus: statuses.NOT_STARTED,
  forgotPasswordError: '',
  passwordResetStatus: statuses.NOT_STARTED,
  passwordResetError: '',
  error: '',
  signupEmailIsUnique: null,
  registered: false,
  verified: false,
  registrationToken: null,
  registrationError: null,
  verificationError: null,
  passwordError: null,
  promoCodeDescription: null,
  promoCodeError: null,
  isAuthenticated: false,
  session: null,
  isNonCandidateCommittee: false,
  hasSeenMessageList: [],
};

const actionMap = {
  [types.USER_LOGIN_SUCCESS]: (state, action) => {
    const newState = {
      ...state,
      session: new Session(action.session),
      isAuthenticated: true,
      error: '',
    };
    return newState;
  },
  [types.USER_SELECT_CAMPAIGN_SUCCESS]: (state, action) => {
    return {
      ...state,
      session: action.data.session,
    };
  },
  [types.USER_LOGIN_CREATE_CAMPAIGN]: (state, action) => {
    return {
      ...state,
      session: new Session(action.session),
      isAuthenticated: true,
      error: '',
    };
  },
  [types.USER_LOGIN_CLEAR_MESSAGE]: (state, action) => {
    return {
      ...state,
      hasSeenMessageList: [...state.hasSeenMessageList, action.data.campaignId],
    };
  },
  [types.USER_LOGIN_FAILURE]: (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  },
  [types.FORGOT_PASSWORD_REQUEST_PROCESSING]: state => {
    return {
      ...state,
      forgotPasswordStatus: statuses.PROCESSING,
      forgotPasswordError: '',
    };
  },
  [types.FORGOT_PASSWORD_SUCCESS]: state => {
    return {
      ...state,
      forgotPasswordStatus: statuses.SUCCESS,
      forgotPasswordError: '',
    };
  },
  [types.FORGOT_PASSWORD_FAILURE]: (state, action) => {
    const forgotPasswordError = R.pathOr(
      'an error occurred',
      ['error', 'response', 'data', 'message'],
      action,
    );
    return {
      ...state,
      forgotPasswordStatus: statuses.ERROR,
      forgotPasswordError,
    };
  },
  [types.CLEAR_FORGOT_PASSWORD_REQUEST_STATUS]: state => {
    return {
      ...state,
      forgotPasswordStatus: statuses.NOT_STARTED,
      forgotPasswordError: '',
    };
  },
  [types.CLEAR_RESET_PASSWORD_STATUS]: state => {
    return {
      ...state,
      passwordResetStatus: statuses.NOT_STARTED,
      passwordResetError: '',
    };
  },
  [types.RESET_PASSWORD_SUCCESS]: state => {
    return {
      ...state,
      passwordResetStatus: statuses.SUCCESS,
      passwordResetError: '',
    };
  },
  [types.RESET_PASSWORD_FAILURE]: (state, action) => {
    const passwordResetError = R.pathOr(
      'an error occurred',
      ['error', 'response', 'data', 'message'],
      action,
    );
    return {
      ...state,
      passwordResetStatus: statuses.ERROR,
      passwordResetError,
    };
  },
  [types.RESET_PASSWORD_PROCESSING]: state => {
    return {
      ...state,
      passwordResetStatus: statuses.PROCESSING,
      passwordResetError: '',
    };
  },
  [types.SIGNUP_EMAIL_IS_UNIQUE]: state => {
    return {
      ...state,
      signupEmailIsUnique: true,
    };
  },
  [types.SIGNUP_EMAIL_NOT_UNIQUE]: (state, action) => {
    return {
      ...state,
      signupEmailIsUnique: false,
      registrationToken: action.redirectToken,
    };
  },
  [types.CHECK_EMAIL_IS_UNIQUE_ERROR]: (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  },
  [types.RESET_EMAIL_CHECK]: state => {
    return {
      ...state,
      signupEmailIsUnique: null,
    };
  },
  [types.REGISTER_NEW_ACCOUNT_SUCCESS]: (state, action) => {
    return {
      ...state,
      registrationToken: action.registrationToken,
      registered: true,
    };
  },
  [types.REGISTER_NEW_ACCOUNT_FAILURE]: (state, action) => {
    return {
      ...state,
      registered: false,
      registrationError: action.error,
    };
  },
  [types.USER_REGISTERED]: (state, action) => {
    return {
      ...state,
      registered: true,
      registrationToken: action.data.registrationToken,
      verified: Boolean(action.data.user.emailVerifiedAt),
      registeredEmailAddress: action.data.user.emailAddress,
      registeredReferralCode: action.data.user.referralCode,
      registeredPromoCode: action.data.user.promoCode,
      registeredCampaign: action.data.user.campaignName,
    };
  },
  [types.USER_NOT_REGISTERED]: state => {
    return {
      ...state,
      registered: false,
      registrationToken: null,
    };
  },
  [types.USER_VERIFIED]: (state, action) => {
    return {
      ...state,
      registered: true,
      verified: true,
      registrationToken: action.registrationToken,
    };
  },
  [types.USER_NOT_VERIFIED]: (state, action) => {
    return {
      ...state,
      verified: false,
      registrationToken: action.data.registrationToken,
      verificationError: action.data.error,
    };
  },
  [types.USER_VERIFICATION_ERROR]: (state, action) => {
    return {
      ...state,
      verified: false,
      verificationError: action.error,
    };
  },
  [types.VERIFICATION_EMAIL_RESENT]: (state, action) => {
    return {
      ...state,
      registrationToken: action.newToken,
    };
  },
  [types.VERIFICATION_EMAIL_NOT_SENT]: (state, action) => {
    return {
      ...state,
      verificationError: action.error,
    };
  },
  [types.CLEAR_SIGNUP_ERRORS]: state => {
    return {
      ...state,
      passwordError: null,
    };
  },
  [types.COMPLETE_SIGNUP_FAILURE]: (state, action) => {
    return {
      ...state,
      passwordError: action.error,
    };
  },
  [types.PROMO_CODE_VALID]: (state, action) => {
    return {
      ...state,
      promoCodeDescription: `SUCCESS! Promo Code: ${action.data.description}`,
      registeredPromoCode: action.data.promoCode,
    };
  },
  [types.PROMO_CODE_INVALID]: (state, action) => {
    return {
      ...state,
      promoCodeDescription: `ERROR - ${action.error}`,
    };
  },
  [types.LOGOUT_SUCCESS]: () => {
    return { ...initialState };
  },
  [types.CLEAR_PROMO_CODE]: state => {
    return {
      ...state,
      registeredPromoCode: undefined,
      promoCodeDescription: '',
    };
  },
  [types.GET_PROMO_CODE_SUCCESS]: (state, action) => {
    return {
      ...state,
      registeredPromoCode: action.data.promoCode,
      promoCodeDescription: action.data.description,
    };
  },
  [types.GET_PROMO_CODE_FAILURE]: (state, action) => {
    return {
      ...state,
      registeredPromoCode: null,
      promoCodeDescription: null,
      promoCodeError: action.error || null,
    };
  },
  [types.CHECK_IS_NON_CANDIDATE_COMMITTEE_SUCCESS]: (state, action) => {
    return {
      ...state,
      isNonCandidateCommittee: action.isNonCandidateCommittee,
    };
  },
  [types.CHECK_IS_NON_CANDIDATE_COMMITTEE_ERROR]: (state, action) => {
    return {
      ...state,
      isNonCandidateCommittee: false,
      nonCandidateCommitteeError: action.error,
    };
  },
};

export const userReducer = createReducer(initialState, actionMap, false);
