const hasValue = val =>
  val && typeof val === 'object'
    ? Object.keys(val).length > 0
    : val !== null && val !== undefined && val !== 0 && val !== '';

const mergeAddresses = (acc, contact) =>
  [
    ...(acc.additionalAddresses || []),
    contact.address,
    ...(contact.additionalAddresses || []),
  ].filter(Boolean);

const mergePhones = (acc, contact) =>
  [
    ...(acc.additionalTelephoneNumbers || []),
    contact.phone1 && { number: contact.phone1 },
    contact.phone2 && { number: contact.phone2 },
    ...(contact.additionalTelephoneNumbers || []),
  ].filter(Boolean);

const mergeTags = (acc, contact) =>
  [...(acc.tags || []), ...(contact.tags || [])].filter(Boolean);

const mergeEmails = (acc, contact) =>
  [
    ...(acc.additionalEmailAddresses || []),
    contact.email,
    ...(contact.additionalEmailAddresses || []),
  ].filter(Boolean);

const mergeNotes = (acc, contact) =>
  [...(acc.notes || []), ...(contact.notes || [])].filter(Boolean);

const mergeChildren = (acc, contact) =>
  [...(acc.children || []), ...(contact.children || [])].filter(Boolean);

export const mergeFields = (targetContact, contacts) =>
  contacts.reduce(
    (acc, contact) => {
      Object.getOwnPropertyNames(contact).forEach(key => {
        if (key === 'address' && hasValue(contact[key])) {
          acc.additionalAddresses = mergeAddresses(acc, contact);
        } else if (
          (key === 'phone1' || key === 'phone2') &&
          hasValue(contact[key])
        ) {
          acc.additionalTelephoneNumbers = mergePhones(acc, contact);
        } else if (key === 'email' && hasValue(contact[key])) {
          acc.additionalEmailAddresses = mergeEmails(acc, contact);
        } else if (key === 'tags' && hasValue(contact[key])) {
          acc.tags = mergeTags(acc, contact);
        } else if (key === 'notes' && hasValue(contact[key])) {
          acc.notes = mergeNotes(acc, contact);
        } else if (key === 'children' && hasValue(contact[key])) {
          acc.children = mergeChildren(acc, contact);
        } else if (!hasValue(acc[key]) && hasValue(contact[key])) {
          acc[key] = contact[key];
        }
      });
      return acc;
    },
    { ...targetContact },
  );
