import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { GridRow, Column, MoneyField } from '../../common';

const FECSection6c = ({ q6cColA, q6cColB }) => {
  return (
    <Fragment>
      <GridRow>
        <Column md={4} classNames="sub-question">
          <p>
            (c) Total Receipts (from Line 19)
          </p>
        </Column>
        <Column md={4} classNames="entry-row">
          <MoneyField
            disabled
            value={q6cColA || 0}
          />
        </Column>
        <Column md={4} classNames="entry-row">
          <MoneyField disabled value={q6cColB || 0} />
        </Column>
      </GridRow>
    </Fragment>
  );
};

FECSection6c.propTypes = {
  q6cColA: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  q6cColB: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default FECSection6c;
