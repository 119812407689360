import React, { useEffect, useReducer, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import uuid from 'uuid/v4';
import { DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Loading from '../../components/Loading';
import { actions as currentCampaignActions } from '../../actions/currentCampaignActions';
import { actions as messagingActions } from '../../actions/messagingActions';
import {
  formatDateOnly,
  scrollToTop,
  scrollToComponent,
  getServerSideErrorMessage,
} from '../../helpers/util';
import { getFRCampaignType } from '../../helpers/campaignHelper';
import { frCampaignTypes } from '../../helpers/constants';
import * as API from '../../services/apiCalls';
import EditFilerDetails from './EditFilerDetails';
import EditCandidateDetails from './EditCandidateDetails';
import EditCommitteeDetails from './EditCommitteeDetails';
import EditChairpersonDetails from './EditChairpersonDetails';
import EditTreasurerDetails from './EditTreasurerDetails';
import EditBalanceDetails from './EditBalanceDetails';
import FECEditBalanceDetailsFederalPac from './FECEditBalanceDetailsFederalPac';
import FECEditBalanceDetails from './FECEditBalanceDetails';
import EditInitialIndebtednessDetails from './EditInitialIndebtednessDetails';
import EditBanksSection from './EditBanksSection';
import EditOrganizationsSection from './EditOrganizationsSection';
import DesignatedAgentModal from './DesignatedAgentModal';
import { BackButton } from '../../components/common';
import { cleanUpMoneyEntry } from '../../helpers/currencyHelper';
import {
  EditCampaignReducer,
  initialState,
  actions,
} from './EditCampaignReducer';
import './EditCampaign.css';

const stepMap = {
  0: {
    name: 'filer-details',
    sectionExpanded: 'filerDetailsExpanded',
  },
  1: {
    name: 'candidate-details',
    sectionExpanded: 'candidateDetailsExpanded',
  },
  2: {
    name: 'committee-details',
    sectionExpanded: 'committeeDetailsExpanded',
  },
  3: {
    name: 'chairperson-details',
    sectionExpanded: 'chairpersonDetailsExpanded',
  },
  4: {
    name: 'treasurer-details',
    sectionExpanded: 'treasurerDetailsExpanded',
  },
  5: {
    name: 'balance-details',
    sectionExpanded: 'balanceDetailsExpanded',
  },
  6: {
    name: 'indebtedness-details',
    sectionExpanded: 'indebtednessDetailsExpanded',
  },
  7: {
    name: 'banks-details',
    sectionExpanded: 'banksDetailsExpanded',
  },
  8: {
    name: 'orgs-details',
    sectionExpanded: 'organizationsDetailsExpanded',
  },
};

export const EditCampaign = ({
  history,
  currentCampaignActions,
  messagingActions,
  session,
}) => {
  const [state, dispatch] = useReducer(EditCampaignReducer, initialState);

  const getCampaignForEdit = async () => {
    const campaign = await API.getCampaignForEdit();
    dispatch({
      type: actions.SET_CAMPAIGN_DETAILS,
      data: { campaign },
    });
  };

  useEffect(() => {
    scrollToTop();
    getCampaignForEdit();
  }, []);

  const setFormInvalid = (isInvalid, formSection) => {
    dispatch({
      type: actions.SET_FORM_INVALID,
      data: {
        formSection,
        isInvalid,
      },
    });
  };

  const handleTextChange = fieldName => (e, value) => {
    dispatch({
      type: actions.HANDLE_CHANGE,
      data: {
        fieldName,
        value: value.key !== undefined ? value.key : value,
      },
    });
  };

  const handlePartyAffiliationChange = (e, option) => {
    dispatch({
      type: actions.HANDLE_CHANGE,
      data: {
        fieldName: 'partyAffiliation',
        value: option.key !== undefined ? option.key : option,
      },
    });
  };

  const handleDateChange = fieldName => date => {
    dispatch({
      type: actions.HANDLE_CHANGE,
      data: {
        fieldName,
        value: date,
      },
    });
  };

  const toggleExpandFilerDetails = () => {
    dispatch({ type: actions.TOGGLE_EXPAND_FILER_DETAILS });
  };

  const toggleIncumbent = (e, isIncumbent) => {
    dispatch({
      type: actions.HANDLE_CHANGE,
      data: {
        fieldName: 'isIncumbent',
        value: isIncumbent,
      },
    });
  };

  const toggleExpandCandidateDetails = () => {
    dispatch({ type: actions.TOGGLE_EXPAND_CANDIDATE_DETAILS });
  };

  const toggleCommitteeRegistered = (e, campaignCommitteeRegistered) => {
    dispatch({
      type: actions.TOGGLE_COMMITTEE_REGISTERED,
      data: {
        campaignCommitteeRegistered,
      },
    });
  };

  // TODO: Deprecate? According to Rick, shouldn't change this without intervention from us
  // Not an issue before PACs
  const handleFecCommitteeTypeChange = (e, option) => {
    const value = option.key !== undefined ? option.key : option;
    dispatch({
      type: actions.HANDLE_CHANGE,
      data: {
        fieldName: 'fecCommitteeType',
        value,
      },
    });
    const isPac = ['PacSeparateSegregatedFund', 'PacSupportOpposeMoreThanOne'].includes(value);
    dispatch({
      type: actions.HANDLE_CHANGE,
      data: {
        fieldName: 'isNonCandidateCommittee',
        value: isPac,
      },
    });
  };

  const handleFecPACOrganizationOptions = (e, option) => {
    const value = option.key !== undefined ? option.key : option;
    dispatch({
      type: actions.HANDLE_FEDERAL_PAC_CHANGE,
      data: {
        fieldName: 'organizationType',
        value,
      },
    });
  };

  const handleFecPACOrganizationCheckboxOption = (e, value, fieldName) => {
    dispatch({
      type: actions.HANDLE_FEDERAL_PAC_CHANGE,
      data: {
        fieldName,
        value,
      },
    });
  };

  const handleCandidateTextChange = fieldName => (e, value) => {
    dispatch({
      type: actions.HANDLE_CHANGE,
      data: {
        section: 'candidate',
        fieldName,
        value,
      },
    });
  };

  const handleCandidateStateChange = (e, option) => {
    dispatch({
      type: actions.HANDLE_CHANGE,
      data: {
        section: 'candidate',
        fieldName: 'state',
        value: option.key !== undefined ? option.key : option,
      },
    });
  };

  const toggleExpandCommitteeDetails = () => {
    dispatch({ type: actions.TOGGLE_EXPAND_COMMITTEE_DETAILS });
  };

  const handleCommitteeTextChange = fieldName => (e, value) => {
    dispatch({
      type: actions.HANDLE_CHANGE,
      data: {
        section: 'committee',
        fieldName,
        value: value.key !== undefined ? value.key : value,
      },
    });

    if (
      (!state.formValid || state.committeeNameErrorMessage !== '') &&
      fieldName === 'name' &&
      state.campaign.committee.name
    ) {
      dispatch({ type: actions.SET_FORM_VALID });
    }
  };

  const handleImportDefaultsChange = fieldName => (e, value) => {
    dispatch({
      type: actions.HANDLE_DEFAULT_CHANGE,
      data: {
        fieldName,
        value: value.key !== undefined ? value.key : value,
      },
    });
  };

  const handleChangeRegistrationDate = date => {
    dispatch({
      type: actions.HANDLE_CHANGE,
      data: {
        section: 'committee',
        fieldName: 'registrationDate',
        value: date,
      },
    });
  };

  const handleCommitteeStateChange = (e, option) => {
    dispatch({
      type: actions.HANDLE_CHANGE,
      data: {
        section: 'committee',
        fieldName: 'state',
        value: option.key !== undefined ? option.key : option,
      },
    });
  };

  const toggleExpandChairpersonDetails = () => {
    dispatch({
      type: actions.TOGGLE_EXPAND_CHAIRPERSON_DETAILS,
    });
  };

  const handleChairpersonTextChange = fieldName => (e, value) => {
    dispatch({
      type: actions.HANDLE_CHANGE,
      data: {
        section: 'chairPerson',
        fieldName,
        value: value.key !== undefined ? value.key : value,
      },
    });
  };

  const handleChairpersonStateChange = (e, option) => {
    dispatch({
      type: actions.HANDLE_CHANGE,
      data: {
        section: 'chairPerson',
        fieldName: 'state',
        value: option.key !== undefined ? option.key : option,
      },
    });
  };

  const toggleExpandTreasurerDetails = () => {
    dispatch({ type: actions.TOGGLE_EXPAND_TREASURER_DETAILS });
  };

  const handleTreasurerTextChange = fieldName => (e, value) => {
    dispatch({
      type: actions.HANDLE_CHANGE,
      data: {
        section: 'treasurer',
        fieldName,
        value: value.key !== undefined ? value.key : value,
      },
    });
  };

  const handleTreasurerStateChange = (e, option) => {
    dispatch({
      type: actions.HANDLE_CHANGE,
      data: {
        section: 'treasurer',
        fieldName: 'state',
        value: option.key !== undefined ? option.key : option,
      },
    });
  };

  const addDesignatedAgent = () => {
    dispatch({
      type: actions.SET_DESIGNATED_AGENT_MODAL_VISIBILITY,
      data: { isHidden: false },
    });
  };

  const removeDesignatedAgent = () => {
    dispatch({
      type: actions.SET_DESIGNATED_AGENT,
      data: {
        designatedAgent: null,
      },
    });
  };

  const closeDesignatedAgentModal = () => {
    dispatch({
      type: actions.SET_DESIGNATED_AGENT_MODAL_VISIBILITY,
      data: { isHidden: true },
    });
  };

  const addDesignatedAgentToCampaign = agent => {
    dispatch({
      type: actions.SET_DESIGNATED_AGENT,
      data: {
        designatedAgent: { ...agent },
      },
    });
  };

  const toggleExpandBalanceDetails = () => {
    dispatch({ type: actions.TOGGLE_EXPAND_BALANCE_DETAILS });
  };

  const handleAffidavitLimitChange = (e, option) => {
    dispatch({
      type: actions.HANDLE_CHANGE,
      data: {
        fieldName: 'affidavitLimit',
        value: option.key !== undefined ? option.key : option,
      },
    });
  };

  const toggleExpandIndebtednessDetails = () => {
    dispatch({
      type: actions.TOGGLE_EXPAND_INDEBTEDNESS,
    });
  };

  const toggleHasIndebtedness = (e, hasIndebtedness) => {
    dispatch({
      type: actions.HANDLE_CHANGE,
      data: {
        fieldName: 'hasIndebtedness',
        value: hasIndebtedness,
      },
    });
  };

  const addDebt = newDebt => {
    dispatch({
      type: actions.ADD_DEBT,
      data: {
        newDebt,
        _id: uuid(),
      },
    });
  };

  const removeDebt = id => {
    dispatch({
      type: actions.REMOVE_DEBT,
      data: {
        id,
      },
    });
  };

  const toggleExpandBankDetails = () => {
    dispatch({
      type: actions.TOGGLE_EXPAND_BANK_DETAILS,
    });
  };

  const addBankToCampaign = newBank => {
    dispatch({
      type: actions.ADD_BANK,
      data: {
        newBank,
        _id: uuid(),
      },
    });
  };

  const editBank = updatedBank => {
    dispatch({
      type: actions.EDIT_BANK,
      data: {
        updatedBank,
      },
    });
  };

  const removeBank = id => {
    dispatch({
      type: actions.REMOVE_BANK,
      data: {
        id,
      },
    });
  };

  const toggleExpandOrganizationsDetails = () => {
    dispatch({ type: actions.TOGGLE_EXPAND_ORGANIZATION_DETAILS });
  };

  const addOrgToCampaign = newOrg => {
    dispatch({
      type: actions.ADD_ORGANIZATION,
      data: {
        id: uuid(),
        newOrg,
      },
    });
  };

  const editOrg = updatedOrg => {
    dispatch({
      type: actions.EDIT_ORGANIZATION,
      data: {
        updatedOrg,
      },
    });
  };

  const removeOrg = id => {
    dispatch({
      type: actions.REMOVE_ORG,
      data: {
        id,
      },
    });
  };

  const handleChangeFECSummaryText = fieldName => (e, value) => {
    dispatch({
      type: actions.HANDLE_CHANGE,
      data: {
        section: 'fecSummary',
        fieldName,
        value: value.key !== undefined ? value.key : value,
      },
    });
  };

  useEffect(() => {
    scrollToComponent(`[name="${stepMap[state.currentStep].name}"]`);
  }, [state.currentStep]);

  const nextStep = nextStep => {
    dispatch({
      type: actions.UPDATE_STEP,
      data: {
        step: nextStep,
        currentSectionExpanded: stepMap[state.currentStep].sectionExpanded,
        newSectionExpanded: stepMap[nextStep].sectionExpanded,
      },
    });
  };

  const previousStep = previousStep => {
    dispatch({
      type: actions.UPDATE_STEP,
      data: {
        step: previousStep,
        currentSectionExpanded: stepMap[state.currentStep].sectionExpanded,
        newSectionExpanded: stepMap[previousStep].sectionExpanded,
      },
    });
  };

  const saveChanges = async () => {
    try {
      const { campaign } = state;
      const campaignType = getFRCampaignType(campaign);
      const {
        FederalCandidate,
        FederalNonCandidate,
        StateLocalCandidate,
        StateLocalNonCandidate,
      } = frCampaignTypes;

      const payload = {
        nextElectionDate: campaign.nextElectionDate || undefined,
        nextElectionYear: campaign.nextElectionYear,
        filerId: campaign.filerId,
        filerEmail: campaign.filerEmail,
        filerPassword: campaign.filerPassword,
        description: campaign.description,
        managedBy: campaign.managedBy,
        timeZoneName: campaign.timeZoneName,
        isIncumbent: campaign.isIncumbent,
        incumbentName: campaign.incumbentName,
        officeType: campaign.officeType,
        campaignCommitteeRegistered:
          campaign.campaignCommitteeRegistered ||
          campaign.isNonCandidateCommittee,
        openingCashAmount: cleanUpMoneyEntry(campaign.openingCashAmount),
        openingCashAmountDate: formatDateOnly(campaign.openingCashAmountDate),
        endingCashOnHandAmount: cleanUpMoneyEntry(campaign.endingCashOnHandAmount),
        endingMonetaryContributionsAmount:
          cleanUpMoneyEntry(campaign.endingMonetaryContributionsAmount),
        previousElectionCycleCarryForwardAmount: cleanUpMoneyEntry(campaign.previousElectionCycleCarryForwardAmount),
        endingMonetaryExpendituresAmount:
          cleanUpMoneyEntry(campaign.endingMonetaryExpendituresAmount),
        endingInKindExpendituresAmount: cleanUpMoneyEntry(campaign.endingInKindExpendituresAmount),
        endingInKindContributionsAmount:
          cleanUpMoneyEntry(campaign.endingInKindContributionsAmount),
        affidavitLimit: campaign.affidavitLimit || -1,
        settings: {
          importDefaults: {
            electionCycle: campaign?.settings?.importDefaults?.electionCycle ? campaign.settings.importDefaults.electionCycle.toString() : undefined,
            electionYear: campaign?.settings?.importDefaults?.electionYear ? campaign.settings.importDefaults.electionYear.toString() : undefined,
          },
        },
        candidate: campaign.candidate,
        reportingFrequency: campaign.reportingFrequency,
        partyAffiliation: campaign.partyAffiliation,
        debts: campaign.debts.map(d => ({
          electionCycle: d.electionCycle,
          electionYear: d.electionYear,
          periodClosingDebtAmount: cleanUpMoneyEntry(d.periodClosingDebtAmount) || 0,
          periodOpeningDebtAmount: cleanUpMoneyEntry(d.periodOpeningDebtAmount) || 0,
          removed: d.removed || false,
          isNew: d.isNew || false,
          _id: d._id,
        })),
        committee: { // needed for NC State level bank updates
          banks: campaign.banks.map(bank => {
            delete bank._id;
            if (bank.isNew) {
              delete bank.isNew;
            }
            return bank;
          }),
        },
      };

      if (
        campaign.campaignCommitteeRegistered ||
        campaign.isNonCandidateCommittee ||
        session.isFederal()
      ) {
        if (campaignType !== FederalNonCandidate) {
          payload.fecSummary = {
            colB_6a_TotalContributionsNoLoans:
              cleanUpMoneyEntry(campaign.fecSummary.colB_6a_TotalContributionsNoLoans),
            colB_6b_TotalContributionRefunds:
              cleanUpMoneyEntry(campaign.fecSummary.colB_6b_TotalContributionRefunds),
            colB_7a_TotalOperatingExpenditures:
              cleanUpMoneyEntry(campaign.fecSummary.colB_7a_TotalOperatingExpenditures),
            colB_7b_TotalOffsetsToOperatingExpenditures:
              cleanUpMoneyEntry(campaign.fecSummary.colB_7b_TotalOffsetsToOperatingExpenditures),
            colB_11ai_IndividualsItemized:
              cleanUpMoneyEntry(campaign.fecSummary.colB_11ai_IndividualsItemized),
            colB_11aii_IndividualsUnitemized:
              cleanUpMoneyEntry(campaign.fecSummary.colB_11aii_IndividualsUnitemized),
            colB_11b_PoliticalPartyCommittees:
              cleanUpMoneyEntry(campaign.fecSummary.colB_11b_PoliticalPartyCommittees),
            colB_11c_OtherPoliticalCommittees:
              cleanUpMoneyEntry(campaign.fecSummary.colB_11c_OtherPoliticalCommittees),
            colB_11d_TheCandidate: cleanUpMoneyEntry(campaign.fecSummary.colB_11d_TheCandidate),
            colB_12_TransfersFromOtherAuthorizedCommittees:
              cleanUpMoneyEntry(campaign.fecSummary.colB_12_TransfersFromOtherAuthorizedCommittees),
            colB_13a_LoansMadeOrGuarnByTheCandidate:
              cleanUpMoneyEntry(campaign.fecSummary.colB_13a_LoansMadeOrGuarnByTheCandidate),
            colB_13b_AllOtherLoans: cleanUpMoneyEntry(campaign.fecSummary.colB_13b_AllOtherLoans),
            colB_14_OffsetsToOperatingExpenditures:
              cleanUpMoneyEntry(campaign.fecSummary.colB_7b_TotalOffsetsToOperatingExpenditures),
            colB_15_OtherReceipts: cleanUpMoneyEntry(campaign.fecSummary.colB_15_OtherReceipts),
            colB_17_OperatingExpenditures:
              cleanUpMoneyEntry(campaign.fecSummary.colB_7a_TotalOperatingExpenditures),
            colB_18_TransfersToOtherAuthorizedCommittees:
              cleanUpMoneyEntry(campaign.fecSummary.colB_18_TransfersToOtherAuthorizedCommittees),
            colB_19a_LoanRepaymentByCandidate:
              cleanUpMoneyEntry(campaign.fecSummary.colB_19a_LoanRepaymentByCandidate),
            colB_19b_LoanRepayments_AllOtherLoans:
              cleanUpMoneyEntry(campaign.fecSummary.colB_19b_LoanRepayments_AllOtherLoans),
            colB_20a_Refund_IndividualsOtherThanPolCmtes:
              cleanUpMoneyEntry(campaign.fecSummary.colB_20a_Refund_IndividualsOtherThanPolCmtes),
            colB_20b_Refund_PoliticalPartyCommittees:
              cleanUpMoneyEntry(campaign.fecSummary.colB_20b_Refund_PoliticalPartyCommittees),
            colB_20c_Refund_OtherPoliticalCommittees:
              cleanUpMoneyEntry(campaign.fecSummary.colB_20c_Refund_OtherPoliticalCommittees),
            colB_21_OtherDisbursements:
              cleanUpMoneyEntry(campaign.fecSummary.colB_21_OtherDisbursements),
          };
        }

        payload.committee = {
          ...campaign.committee,
          ...payload.committee, // retain bank information from currently defined payload
          electionDate: campaign.committee.electionDate
            ? formatDateOnly(campaign.committee.electionDate)
            : undefined,
          registrationDate: campaign.committee.registrationDate
            ? formatDateOnly(campaign.committee.registrationDate)
            : undefined,
          state: campaign.committee.state || undefined,
          chairPerson: {
            ...campaign.chairPerson,
            state: campaign.chairPerson.state || undefined,
          },
          treasurer: {
            ...campaign.treasurer,
            state: campaign.treasurer.state || undefined,
          },
          designatedAgent: campaign.designatedAgent
            ? { ...campaign.designatedAgent }
            : undefined,
          organizations: campaign.organizations.map(org => {
            delete org._id;
            if (org.isNew) {
              delete org.isNew;
            }
            return org;
          }),
        };
      }

      if (campaignType === FederalCandidate || campaignType === StateLocalCandidate) {
        await API.saveEditCampaign(payload);
      }

      if (campaignType === FederalNonCandidate) {
        await API.saveFederalNCCCampaign(payload);
      }

      if (campaignType === StateLocalNonCandidate) {
        await API.saveEditNCCCampaign(payload);
      }

      currentCampaignActions.getCurrentCampaign();
      history.push('/filer');
      messagingActions.setSuccessToast(
        'Campaign information updated successfully',
      );
    } catch (e) {
      const error = getServerSideErrorMessage(e);
      messagingActions.setErrorToast(error);
    }
  };

  const {
    campaign,
    filerEmailError,
    incumbentNameError,
    filerDetailsExpanded,
    candidateDetailsExpanded,
    committeeDetailsExpanded,
    chairpersonDetailsExpanded,
    treasurerDetailsExpanded,
    balanceDetailsExpanded,
    indebtednessDetailsExpanded,
    committeeNameErrorMessage,
    designatedAgentModalHidden,
    banksDetailsExpanded,
    organizationsDetailsExpanded,
  } = state;

  const editOfficeActions = {
    handleDateChange,
    handleTextChange,
    handleCommitteeTextChange,
    handlePartyAffiliationChange,
    toggleIncumbent,
    toggleExpandFilerDetails,
    setFormInvalid,
    handleFecCommitteeTypeChange,
    handleFecPACOrganizationOptions,
    handleFecPACOrganizationCheckboxOption,
    handleImportDefaultsChange,
    nextStep,
  };

  const editCandidateActions = {
    handleTextChange: handleCandidateTextChange,
    handleStateChange: handleCandidateStateChange,
    toggleExpandCandidateDetails,
    setFormInvalid,
    nextStep,
    previousStep,
  };

  const editCommitteeActions = {
    handleTextChange: handleCommitteeTextChange,
    handleStateChange: handleCommitteeStateChange,
    handleChangeRegistrationDate,
    toggleExpandCommitteeDetails,
    toggleCommitteeRegistered,
    setFormInvalid,
    nextStep,
    previousStep,
  };

  const editChairpersonActions = {
    handleTextChange: handleChairpersonTextChange,
    handleStateChange: handleChairpersonStateChange,
    toggleExpandChairpersonDetails,
    setFormInvalid,
    nextStep,
    previousStep,
  };

  const editTreasurerActions = {
    handleTextChange: handleTreasurerTextChange,
    handleStateChange: handleTreasurerStateChange,
    toggleExpandTreasurerDetails,
    addDesignatedAgent,
    removeDesignatedAgent,
    setFormInvalid,
    nextStep,
    previousStep,
  };

  const editBalancesActions = {
    handleTextChange,
    handleChangeFECSummaryText,
    handleDateChange,
    toggleExpandBalanceDetails,
    handleAffidavitLimitChange,
    nextStep,
    previousStep,
  };

  const editInitialIndebtednessActions = {
    handleTextChange: () => {},
    addDebt,
    removeDebt,
    toggleExpandIndebtednessDetails,
    toggleHasIndebtedness,
    setFormInvalid,
    previousStep,
    nextStep,
  };

  const editBanksActions = {
    nextStep,
    previousStep,
    toggleExpandBankDetails,
    onSave: addBankToCampaign,
    removeBank,
    editBank,
  };

  const editOrgsActions = {
    previousStep,
    toggleExpandOrganizationsDetails,
    onSave: addOrgToCampaign,
    removeOrg,
    editOrg,
  };

  const designatedAgentModalActions = {
    onDismiss: closeDesignatedAgentModal,
    onSave: addDesignatedAgentToCampaign,
  };

  const getFederalCandidateEditPanels = (campaignType) => {
    return (
      <Fragment>
        <EditFilerDetails
          campaign={campaign}
          campaignType={campaignType}
          filerEmailError={filerEmailError}
          incumbentNameError={incumbentNameError}
          filerDetailsExpanded={filerDetailsExpanded}
          actions={editOfficeActions}
          formSection={0}
          session={session}
        />
        <EditCandidateDetails
          campaign={campaign}
          candidateDetailsExpanded={candidateDetailsExpanded}
          actions={editCandidateActions}
          formSection={1}
          session={session}
        />
        <EditCommitteeDetails
          campaign={campaign}
          committeeDetailsExpanded={committeeDetailsExpanded}
          committeeNameErrorMessage={committeeNameErrorMessage}
          actions={editCommitteeActions}
          formSection={2}
          session={session}
        />
        <EditChairpersonDetails
          campaign={campaign}
          chairpersonDetailsExpanded={chairpersonDetailsExpanded}
          actions={editChairpersonActions}
          formSection={3}
          session={session}
        />
        <EditTreasurerDetails
          campaign={campaign}
          treasurerDetailsExpanded={treasurerDetailsExpanded}
          actions={editTreasurerActions}
          formSection={4}
          session={session}
        />
        <FECEditBalanceDetails
          campaign={campaign}
          balanceDetailsExpanded={balanceDetailsExpanded}
          actions={editBalancesActions}
          formSection={5}
          session={session}
        />
        <EditBanksSection
          actions={editBanksActions}
          banksDetailsExpanded={banksDetailsExpanded}
          campaign={campaign}
          campaignType={campaignType}
          formSection={7}
          session={session}
        />
      </Fragment>
    );
  };

  const getStateLocalCandidateEditPanels = (campaignType) => {
    return (
      <Fragment>
        <EditFilerDetails
          campaign={campaign}
          campaignType={campaignType}
          filerEmailError={filerEmailError}
          incumbentNameError={incumbentNameError}
          filerDetailsExpanded={filerDetailsExpanded}
          actions={editOfficeActions}
          formSection={0}
          session={session}
        />
        <EditCandidateDetails
          campaign={campaign}
          candidateDetailsExpanded={candidateDetailsExpanded}
          actions={editCandidateActions}
          formSection={1}
          session={session}
        />
        <EditCommitteeDetails
          campaign={campaign}
          committeeDetailsExpanded={committeeDetailsExpanded}
          committeeNameErrorMessage={committeeNameErrorMessage}
          actions={editCommitteeActions}
          formSection={2}
          session={session}
        />
        {(campaign.campaignCommitteeRegistered &&
          <Fragment>
            <EditChairpersonDetails
              campaign={campaign}
              chairpersonDetailsExpanded={chairpersonDetailsExpanded}
              actions={editChairpersonActions}
              formSection={3}
              session={session}
            />
            <EditTreasurerDetails
              campaign={campaign}
              treasurerDetailsExpanded={treasurerDetailsExpanded}
              actions={editTreasurerActions}
              formSection={4}
              session={session}
            />
          </Fragment>
        )}
        <EditBalanceDetails
          campaign={campaign}
          balanceDetailsExpanded={balanceDetailsExpanded}
          actions={editBalancesActions}
          formSection={5}
          session={session}
        />
        <EditInitialIndebtednessDetails
          campaign={campaign}
          indebtednessDetailsExpanded={indebtednessDetailsExpanded}
          actions={editInitialIndebtednessActions}
          formSection={6}
        />
        {(campaign.officeState && campaign.officeState === 'North Carolina') &&
        <EditBanksSection
          actions={editBanksActions}
          banksDetailsExpanded={banksDetailsExpanded}
          campaign={campaign}
          campaignType={campaignType}
          formSection={7}
          session={session}
        />
        }
      </Fragment>
    );
  };

  const getStateLocalNonCandidateEditPanels = (campaignType) => {
    return (
      <Fragment>
        <EditFilerDetails
          campaign={campaign}
          campaignType={campaignType}
          filerEmailError={filerEmailError}
          incumbentNameError={incumbentNameError}
          filerDetailsExpanded={filerDetailsExpanded}
          actions={editOfficeActions}
          formSection={0}
          session={session}
        />
        <EditCommitteeDetails
          campaign={campaign}
          committeeDetailsExpanded={committeeDetailsExpanded}
          committeeNameErrorMessage={committeeNameErrorMessage}
          actions={editCommitteeActions}
          formSection={2}
          session={session}
        />
        <EditChairpersonDetails
          campaign={campaign}
          chairpersonDetailsExpanded={chairpersonDetailsExpanded}
          actions={editChairpersonActions}
          formSection={3}
          session={session}
        />
        <EditTreasurerDetails
          campaign={campaign}
          treasurerDetailsExpanded={treasurerDetailsExpanded}
          actions={editTreasurerActions}
          formSection={4}
          session={session}
        />
        <EditBalanceDetails
          campaign={campaign}
          balanceDetailsExpanded={balanceDetailsExpanded}
          actions={editBalancesActions}
          formSection={5}
          session={session}
        />
        <EditInitialIndebtednessDetails
          campaign={campaign}
          indebtednessDetailsExpanded={indebtednessDetailsExpanded}
          actions={editInitialIndebtednessActions}
          formSection={6}
        />
      </Fragment>
    );
  };

  const getFederalNonCandidateEditPanels = (campaignType) => {
    return (
      <Fragment>
        <EditFilerDetails
          campaign={campaign}
          campaignType={campaignType}
          filerEmailError={filerEmailError}
          incumbentNameError={incumbentNameError}
          filerDetailsExpanded={filerDetailsExpanded}
          actions={editOfficeActions}
          formSection={0}
          session={session}
        />
        <EditCommitteeDetails
          campaign={campaign}
          committeeDetailsExpanded={committeeDetailsExpanded}
          committeeNameErrorMessage={committeeNameErrorMessage}
          actions={editCommitteeActions}
          formSection={2}
          session={session}
        />
        <EditChairpersonDetails
          campaign={campaign}
          chairpersonDetailsExpanded={chairpersonDetailsExpanded}
          actions={editChairpersonActions}
          formSection={3}
          session={session}
        />
        <EditTreasurerDetails
          campaign={campaign}
          treasurerDetailsExpanded={treasurerDetailsExpanded}
          actions={editTreasurerActions}
          formSection={4}
          session={session}
        />
        <FECEditBalanceDetailsFederalPac
          campaign={campaign}
          balanceDetailsExpanded={balanceDetailsExpanded}
          actions={editBalancesActions}
          formSection={5}
          session={session}
        />
        <EditBanksSection
          actions={editBanksActions}
          banksDetailsExpanded={banksDetailsExpanded}
          campaign={campaign}
          campaignType={campaignType}
          formSection={7}
          session={session}
        />
        <EditOrganizationsSection
          actions={editOrgsActions}
          organizationsDetailsExpanded={organizationsDetailsExpanded}
          campaign={campaign}
          formSection={8}
          session={session}
        />
      </Fragment>
    );
  };

  const campaignType = state.campaign && getFRCampaignType(state.campaign);

  return (
    <Fragment>
      <BackButton history={history} />
      <div className="EditCampaign">
        <div className="container">
          {!campaign && <Loading />}
          {(campaign && campaignType === frCampaignTypes.FederalCandidate) && getFederalCandidateEditPanels(campaignType) }
          {(campaign && campaignType === frCampaignTypes.FederalNonCandidate) && getFederalNonCandidateEditPanels(campaignType) }
          {(campaign && campaignType === frCampaignTypes.StateLocalCandidate) && getStateLocalCandidateEditPanels(campaignType) }
          {(campaign && campaignType === frCampaignTypes.StateLocalNonCandidate) && getStateLocalNonCandidateEditPanels(campaignType) }
        </div>
        <div className="action-footer">
          <DefaultButton
            text="Cancel"
            onClick={() => history.push('/filer')}
            className="cancel-btn"
          />
          <DefaultButton primary text="Save Changes" onClick={saveChanges} />
        </div>
      </div>
      {campaign && (
        <DesignatedAgentModal
          isHidden={designatedAgentModalHidden}
          actions={designatedAgentModalActions}
          designatedAgent={campaign.designatedAgent}
          session={session}
        />
      )}
    </Fragment>
  );
};

EditCampaign.propTypes = {
  history: PropTypes.object.isRequired,
  currentCampaignActions: PropTypes.object.isRequired,
  messagingActions: PropTypes.object.isRequired,
  session: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    session: state.user.session,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    currentCampaignActions: bindActionCreators(
      currentCampaignActions,
      dispatch,
    ),
    messagingActions: bindActionCreators(messagingActions, dispatch),
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(EditCampaign),
);
