import { takeLatest, put, call } from 'redux-saga/effects';
import axios from 'axios';
import { types } from '../actions/dataImportActions';
import { getServerSideErrorMessage } from '../helpers/util';

export function* handleGetDataImportList() {
  try {
    yield put({ type: types.GET_DATA_IMPORT_LIST_PROCESSING });
    const { data: dataImportList } = yield call(
      axios.get,
      '/api/filer/dataImport',
      { withCredentials: true },
    );
    yield put({ type: types.GET_DATA_IMPORT_LIST_SUCCESS, data: { dataImportList } });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put({ type: types.GET_DATA_IMPORT_LIST_FAILURE, error });
  }
}

export function* getDataImportList() {
  yield takeLatest(types.GET_DATA_IMPORT_LIST, handleGetDataImportList);
}

export function* handleGetDataImportDetail(action) {
  try {
    const { dataImportId } = action.data;
    yield put({ type: types.GET_DATA_IMPORT_DETAIL_PROCESSING });
    const { data: dataImport } = yield call(
      axios.get,
      `/api/filer/dataImport/${dataImportId}`,
      { withCredentials: true },
    );
    yield put({ type: types.GET_DATA_IMPORT_DETAIL_SUCCESS, data: { dataImport } });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put({ type: types.GET_DATA_IMPORT_DETAIL_FAILURE, error });
  }
}

export function* getDataImportDetail() {
  yield takeLatest(types.GET_DATA_IMPORT_DETAIL, handleGetDataImportDetail);
}

export function* handleDeleteDataImport(action) {
  try {
    const { dataImportId } = action.data;
    yield put({ type: types.DELETE_DATA_IMPORT_PROCESSING });
    yield call(
      axios.delete,
      `/api/filer/dataImport/${dataImportId}`,
      { withCredentials: true },
    );
    yield put({ type: types.DELETE_DATA_IMPORT_SUCCESS, data: { dataImportId } });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put({ type: types.DELETE_DATA_IMPORT_FAILURE, error });
  }
}

export function* deleteDataImport() {
  yield takeLatest(types.DELETE_DATA_IMPORT, handleDeleteDataImport);
}
