import React from 'react';
import PropTypes from 'prop-types';
import { Persona } from 'office-ui-fabric-react/lib/Persona';

const address = item => {
  if (!item.address) {
    return null;
  }

  if (item.address.addressLine2) {
    return `${item.address.addressLine1 || ''} ${item.address.addressLine2 ||
      ''}, ${item.address.city || ''}, ${item.address.state || ''} ${item
      .address.zipCode || ''}`;
  }

  return `${item.address.addressLine1 || ''}, ${item.address.city || ''}, ${item
    .address.state || ''} ${item.address.zipCode || ''}`;
};

const IndividualPersona = ({ persona }) => {
  const name =
    persona.typeAheadName ||
    `${persona.lastName}, ${persona.firstName}${
      persona.middleName ? ' ' + persona.middleName : ''
    }`;

  const addr = address(persona);

  return <Persona text={name} secondaryText={addr} coinSize={48} />;
};

IndividualPersona.propTypes = {
  persona: PropTypes.object.isRequired,
};

export default IndividualPersona;
