import { Icon } from 'office-ui-fabric-react';
import PropTypes from 'prop-types';
import React from 'react';

const HelpIcon = ({ marginLeft = 8 }) => (
  <Icon
    iconName="Help"
    styles={{
      root: {
        marginLeft,
        fontSize: '10pt',
        backgroundColor: '#244b8d',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 24,
        height: 24,
        color: '#fff',
        borderRadius: '50%',
      },
    }}
  />
);

export default HelpIcon;

HelpIcon.propTypes = {
  marginLeft: PropTypes.number,
};
