import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { GridRow, Column, MoneyField } from '../../common';

const FECSection20a = ({ q20aColA, q20aColB }) => {
  return (
    <Fragment>
      <GridRow>
        <Column sm={4} classNames="sub-question">
          <p>(a) Individuals/Persons Other Than Political Committees</p>
        </Column>
        <Column sm={4} classNames="entry-row">
          <MoneyField
            disabled
            value={q20aColA || 0}
          />
        </Column>
        <Column sm={4} classNames="entry-row">
          <MoneyField
            disabled
            value={q20aColB || 0}
          />
        </Column>
      </GridRow>
    </Fragment>
  );
};

FECSection20a.propTypes = {
  q20aColA: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  q20aColB: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default FECSection20a;
