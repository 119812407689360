import React from 'react';
import PropTypes from 'prop-types';
import {
  ActionButton,
  DefaultButton,
  PrimaryButton,
} from 'office-ui-fabric-react/lib/Button';
import { Grid, GridRow, Column } from '../common';
import { EmptyView } from '../EmptyView';
import ExpendituresList from '../ExpendituresList';
import { formatCurrency } from '../../helpers/util';
import { getLabel } from '../../helpers/labelHelper';

export const ExpendituresSection = ({
  items,
  actions,
  isExpanded,
  totalAmount,
  totalInKind,
  session,
}) => (
  <div className="expenditures depth-1" name="expenditures">
    <header onClick={actions.toggleExpand}>
      <h3>{`${getLabel('Expenditures', session)}`}</h3>
      <label>{`Amount: ${formatCurrency(
        totalAmount,
      )} | In-Kind: ${formatCurrency(totalInKind)}`}</label>
    </header>
    <div className={`content${isExpanded ? '-expanded' : '-collapsed'}`}>
      <Grid>
        <GridRow>
          <Column classNames="ms-textAlignRight">
            <ActionButton
              iconProps={{
                iconName: 'PlusCircle',
                styles: {
                  root: {
                    fontSize: '1.3em',
                  },
                },
              }}
              onClick={actions.addNew}
            >
              {`Add New ${getLabel('Expenditure', session)}`}
            </ActionButton>
          </Column>
        </GridRow>
        {items.length > 0 && (
          <ExpendituresList
            editItem={actions.editItem}
            expenditures={{
              ...items,
              expenditures: [...items],
            }}
            session={session}
          />
        )}
        {items.length === 0 && (
          <EmptyView
            message={`There are no ${getLabel(
              'expenditures',
              session,
            )} for this reporting period.`}
          />
        )}
      </Grid>
      <div className="section-actions">
        <DefaultButton
          text="Back"
          style={{ marginRight: 16 }}
          onClick={actions.previousSection}
        />
        <PrimaryButton text="Continue" onClick={actions.nextSection} />
      </div>
    </div>
  </div>
);

ExpendituresSection.propTypes = {
  actions: PropTypes.object.isRequired,
  items: PropTypes.array,
  isExpanded: PropTypes.bool,
  totalAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  totalInKind: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  session: PropTypes.object.isRequired,
};

ExpendituresSection.defaultProps = {
  items: [],
  isExpanded: false,
  totalAmount: 0,
  totalInKind: 0,
};

export default ExpendituresSection;
