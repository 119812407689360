import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { GridRow, Column, MoneyField } from '../../common';

const FECSection13a = ({ q13aColA, q13aColB }) => {
  return (
    <Fragment>
      <GridRow style={{ borderTop: '1px solid #919191' }}>
        <Column sm={4}>
          <p>13. LOANS</p>
        </Column>
      </GridRow>
      <GridRow>
        <Column sm={4} classNames="sub-question">
          <p>(a) Made or Guaranteed by the Candidate</p>
        </Column>
        <Column sm={4} classNames="entry-row">
          <MoneyField
            disabled
            value={q13aColA || 0}
          />
        </Column>
        <Column sm={4} classNames="entry-row">
          <MoneyField
            disabled
            value={q13aColB || 0}
          />
        </Column>
      </GridRow>
    </Fragment>
  );
};

FECSection13a.propTypes = {
  q13aColA: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  q13aColB: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default FECSection13a;
