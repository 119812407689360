import React, { useState, useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { types as messagingActions } from '../../actions/messagingActions';
import { types as loanActions } from '../../actions/loanActions';
import { types as validationActions } from '../../actions/validationActions';
import { scrollToTop, getServerSideErrorMessage } from '../../helpers/util';
import { statuses, toastTypes } from '../../helpers/constants';
import { LoanForm } from '../../components/LoanForm/LoanForm';
import { getQueryParams } from '../../helpers/urlHelper';
import { getLabel } from '../../helpers/labelHelper';
import { ConfirmDeleteFromReportDialog } from '../../components/Dialogs';
import { editLoanReducer, actions, initialState } from './editLoanReducer';

export const EditLoan = ({ location, history, match }) => {
  const [state, localDispatch] = useReducer(editLoanReducer, initialState);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);
  const reduxDispatch = useDispatch();
  const campaign = useSelector(state => state.currentCampaign.campaign);
  const validations = useSelector(state => state.validations);
  const session = useSelector(state => state.user.session);
  const messages = useSelector(state => state.messages);

  const retrieveLoan = async () => {
    const { id } = match.params;
    if (id) {
      try {
        const { data: loan } = await axios.get(`/api/filer/loans/${id}`, {
          withCredentials: true,
        });

        if (loan) {
          const params = getQueryParams(location.search);
          localDispatch({
            type: actions.GET_LOAN_FOR_EDIT_SUCCESS,
            data: { loan, params },
          });
        } else {
          localDispatch({
            type: actions.GET_LOAN_FOR_EDIT_FAILURE,
          });
          reduxDispatch({
            type: messagingActions.SET_TOAST,
            data: {
              toastType: toastTypes.ERROR,
              message: 'An error occurred, please try again',
            },
          });
        }
      } catch (e) {
        localDispatch({
          type: actions.GET_LOAN_FOR_EDIT_FAILURE,
        });
        const error = getServerSideErrorMessage(e);
        reduxDispatch({
          type: messagingActions.SET_TOAST,
          data: {
            toastType: toastTypes.ERROR,
            message: error,
          },
        });
      }
    }
  };

  useEffect(() => {
    scrollToTop();
    retrieveLoan();
    return () => {
      reduxDispatch({
        type: validationActions.CLEAR_CHECK_NUMBER_VALIDATION,
      });
    };
  }, []);

  const onCancel = () => {
    if (state.reportIdRedirect) {
      if (state.fec) {
        history.push(
          `/filer/editFECReport/${state.reportIdRedirect}?section=itemizedLoans`,
        );
      } else {
        history.push(
          `/filer/editReport/${state.reportIdRedirect}?section=loans`,
        );
      }
    } else if (state.redirect === 'ledger') {
      history.push('/filer/ledger');
    } else {
      history.push('/filer/loans');
    }
  };

  const deleteLoan = id => {
    if (id) {
      if (state.fec) {
        reduxDispatch({
          type: loanActions.DELETE_FEC_LOAN,
          data: {
            id,
            reportId: state.reportIdRedirect,
            redirect: state.redirect,
          },
        });
      } else {
        reduxDispatch({
          type: loanActions.DELETE_LOAN,
          data: {
            id,
            reportId: state.reportIdRedirect,
            redirect: state.redirect,
          },
        });
      }
    }
  };

  const confirmDeleteLoan = () => {
    localDispatch({
      type: actions.SHOW_CONFIRM_DELETE_MODAL,
    });
  };

  const cancelDeleteLoan = () => {
    localDispatch({
      type: actions.HIDE_CONFIRM_DELETE_MODAL,
    });
  };

  const onConfirmDelete = () => {
    const { id } = match.params;
    if (session.isFederal() && state.loan.isItemFiled) {
      confirmDeleteLoan();
    } else {
      deleteLoan(id);
    }
  };

  const onSave = (payload, addNew = false, callbacks = () => {}) => {
    payload._id = match.params.id;
    if (state.fec) {
      reduxDispatch({
        type: loanActions.UPDATE_FEC_LOAN,
        data: {
          payload,
          addNew,
          reportId: state.reportIdRedirect,
          redirect: state.redirect,
          verifyReport: state.verifyReport,
        },
      });
    } else {
      reduxDispatch({
        type: loanActions.UPDATE_LOAN,
        data: {
          payload,
          addNew,
          reportId: state.reportIdRedirect,
          redirect: state.redirect,
          verifyReport: state.verifyReport,
        },
      });
    }

    if (addNew) {
      callbacks();
    }
  };

  if (state.status === statuses.SUCCESS) {
    return (
      <div className="LoanForm depth-1">
        <h3>Edit Loan</h3>
        <LoanForm
          campaign={campaign}
          session={session}
          onSave={onSave}
          onCancel={onCancel}
          onDelete={onConfirmDelete}
          messagingActions={messagingActions}
          validations={validations}
          validationActions={validationActions}
          verifyReport={state.verifyReport}
          loan={state.loan}
          saveButtonDisabled={saveButtonDisabled}
          setSaveButtonDisabled={setSaveButtonDisabled}
          messages={messages}
        />
        <ConfirmDeleteFromReportDialog
          dialogHidden={state.confirmDeleteHidden}
          cancel={cancelDeleteLoan}
          confirm={() => deleteLoan(match.params.id)}
          itemType={getLabel('Loan', session)}
        />
      </div>
    );
  }

  return null;
};

EditLoan.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withRouter(EditLoan);
