export const types = {
  GET_ALL_CONTACTS: 'GET_ALL_CONTACTS',
  GET_ALL_CONTACTS_SUCCESS: 'GET_ALL_CONTACTS_SUCCESS',
  GET_CONTACTS: 'GET_CONTACTS',
  GET_CONTACTS_PROCESSING: 'GET_CONTACTS_PROCESSING',
  GET_CONTACTS_SUCCESS: 'GET_CONTACTS_SUCCESS',
  GET_CONTACTS_NEXT_PAGE: 'GET_CONTACTS_NEXT_PAGE',
  GET_CONTACTS_NEXT_PAGE_PROCESSING: 'GET_CONTACTS_NEXT_PAGE_PROCESSING',
  GET_CONTACTS_NEXT_PAGE_SUCCESS: 'GET_CONTACTS_NEXT_PAGE_SUCCESS',
  ADD_CONTACT: 'ADD_CONTACT',
  ADD_CONTACT_SUCCESS: 'ADD_CONTACT_SUCCESS',
  UPDATE_CONTACT: 'UPDATE_CONTACT',
  UPDATE_CONTACT_SUCCESS: 'UPDATE_CONTACT_SUCCESS',
  MERGE_CONTACTS: 'MERGE_CONTACTS',
  MERGE_CONTACTS_SUCCESS: 'MERGE_CONTACTS_SUCCESS',
  DELETE_CONTACT: 'DELETE_CONTACT',
  DELETE_CONTACT_SUCCESS: 'DELETE_CONTACT_SUCCESS',
  CLEAR_CONTACTS: 'CLEAR_CONTACTS',
  CONTACT_ACTION_PROCESSING: 'CONTACT_ACTION_PROCESSING',
  CONSOLIDATE_CONTACTS: 'CONSOLIDATE_CONTACTS',
  CONSOLIDATE_CONTACTS_SUCCESS: 'CONSOLIDATE_CONTACTS_SUCCESS',
};

export const actions = {
  getAllContacts() {
    return { type: types.GET_ALL_CONTACTS };
  },

  getContacts() {
    return { type: types.GET_CONTACTS };
  },

  getContactsNextPage() {
    return { type: types.GET_CONTACTS_NEXT_PAGE };
  },

  saveContact(contact) {
    return { type: types.ADD_CONTACT, data: { contact } };
  },

  updateContact(_id, contact) {
    return { type: types.UPDATE_CONTACT, data: { _id, contact } };
  },

  deleteContact(id) {
    return { type: types.DELETE_CONTACT, id };
  },

  mergeContacts(targetContactId, contactIds, params = '') {
    return {
      type: types.MERGE_CONTACTS,
      data: { targetContactId, contactIds, params },
    };
  },

  consolidateContacts(targetContact, contactIds, params = '') {
    return {
      type: types.CONSOLIDATE_CONTACTS,
      data: { targetContact, contactIds, params },
    };
  },
};
