import { types } from '../actions/contactActions';
import { statuses } from '../helpers/constants';
import { createReducer } from './createReducer';

export const initialState = {
  contacts: [],
  status: statuses.NOT_STARTED,
  getNextPageStatus: statuses.PROCESSING,
  getContactsStatus: statuses.NOT_STARTED,
};

const actionMap = {
  [types.CONTACT_ACTION_PROCESSING]: state => {
    return {
      ...state,
      status: statuses.PROCESSING,
    };
  },
  [types.GET_ALL_CONTACTS_SUCCESS]: (state, action) => {
    return {
      ...state,
      contacts: action.contacts,
      status: statuses.SUCCESS,
    };
  },
  [types.GET_CONTACTS_PROCESSING]: state => {
    return {
      ...state,
      getContactsStatus: statuses.PROCESSING,
    };
  },
  [types.GET_CONTACTS_SUCCESS]: (state, action) => {
    return {
      ...state,
      contacts: action.contacts,
      getContactsStatus: statuses.SUCCESS,
    };
  },
  [types.GET_CONTACTS_NEXT_PAGE_PROCESSING]: state => ({
    ...state,
    getNextPageStatus: statuses.PROCESSING,
  }),
  [types.GET_CONTACTS_NEXT_PAGE_SUCCESS]: (
    state,
    action,
  ) => ({
    ...state,
    contacts: [...state.contacts, ...action.contacts],
    getNextPageStatus: statuses.NOT_STARTED,
  }),
  [types.ADD_CONTACT_SUCCESS]: (state, action) => {
    return {
      ...state,
      contacts: [...state.contacts, action.contact],
      status: statuses.NOT_STARTED,
    };
  },
  [types.UPDATE_CONTACT_SUCCESS]: (state, action) => {
    return {
      ...state,
      contacts: state.contacts.map(c => {
        if (c._id === action.data._id) {
          c = { ...c, ...action.data.contact };
        }
        return c;
      }),
      status: statuses.NOT_STARTED,
    };
  },
  [types.DELETE_CONTACT_SUCCESS]: (state, action) => {
    return {
      ...state,
      contacts: state.contacts.filter(c => c._id !== action._id),
      status: statuses.NOT_STARTED,
    };
  },
  [types.CLEAR_CONTACTS]: state => {
    return {
      ...state,
      contacts: [],
      status: statuses.NOT_STARTED,
    };
  },
};

export const contactsReducer = createReducer(initialState, actionMap);
