import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { SalutationPicker, RelationshipToCandidatePicker } from '../../Pickers';

export const IndividualForm = ({
  handleChange,
  salutation,
  firstName,
  middleName,
  lastName,
  suffix,
  nickName,
  employer,
  occupation,
  relationshipToCandidate,
}) => (
  <Fragment>
    <SalutationPicker
      onChange={handleChange('salutation')}
      selectedKey={salutation}
    />
    <TextField
      label="First Name"
      value={firstName}
      onChange={handleChange('firstName')}
    />
    <TextField
      label="Middle Name"
      value={middleName}
      onChange={handleChange('middleName')}
    />
    <TextField
      label="Last Name"
      value={lastName}
      onChange={handleChange('lastName')}
    />
    <TextField
      label="Suffix"
      value={suffix}
      onChange={handleChange('suffix')}
    />
    <TextField
      label="Nickname"
      value={nickName}
      onChange={handleChange('nickName')}
    />
    <TextField
      label="Occupation"
      value={occupation}
      onChange={handleChange('occupation')}
    />
    <TextField
      label="Employer"
      value={employer}
      onChange={handleChange('employer')}
    />
    <RelationshipToCandidatePicker
      onChange={handleChange('relationshipToCandidate')}
      selectedKey={relationshipToCandidate}
    />
  </Fragment>
);

IndividualForm.propTypes = {
  handleChange: PropTypes.func.isRequired,
  salutation: PropTypes.string,
  firstName: PropTypes.string,
  middleName: PropTypes.string,
  lastName: PropTypes.string,
  suffix: PropTypes.string,
  nickName: PropTypes.string,
  occupation: PropTypes.string,
  employer: PropTypes.string,
};

IndividualForm.defaultProps = {
  firstName: '',
  middleName: '',
  lastName: '',
  suffix: '',
  nickName: '',
  occupation: '',
  employer: '',
};
