import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { GridRow, Column } from '../../common';
import { FederalOfficePicker } from '../FederalOfficePicker';
import { StatePicker } from '../index';

const CandidateCommitteeEntity = ({
  committeeFecId = '',
  name = '',
  contactName = '',
  onChange,
  contactType = '',
  candidateOffice,
  candidateState,
  candidateDistrict,
  candidateFecId,
  candidateFirstName,
  candidateMiddleName,
  candidateLastName,
}) => {
  const isFECEntity = ['CCM', 'PTY'].includes(contactType);
  return (
    <Fragment>
      <GridRow>
        <Column md={isFECEntity ? 4 : 6}>
          <TextField
            label={name.label}
            value={name.value}
            required={name.required}
            errorMessage={name.errorMessage}
            onChange={onChange(name.field)}
          />
        </Column>
        <Column md={isFECEntity ? 4 : 6}>
          <TextField
            label={contactName.label}
            value={contactName.value}
            required={contactName.required}
            errorMessage={contactName.errorMessage}
            onChange={onChange(contactName.field)}
          />
        </Column>
        {isFECEntity && (
          <Column md={4}>
            <TextField
              label={committeeFecId.label}
              value={committeeFecId.value}
              required={committeeFecId.required}
              errorMessage={committeeFecId.errorMessage}
              onChange={onChange(committeeFecId.field)}
            />
          </Column>
        )}
      </GridRow>
      {(candidateOffice.show && contactType === 'CCM') && // if office is needed, so are the others
        <Fragment>
          <GridRow>
            <Column md={4}>
              <TextField
                label={candidateFirstName.label}
                value={candidateFirstName.value}
                onChange={onChange(candidateFirstName.field)}
                errorMessage={candidateFirstName.errorMessage}
                required={candidateFirstName.required}
              />
            </Column>
            <Column md={4}>
              <TextField
                label={candidateMiddleName.label}
                value={candidateMiddleName.value}
                onChange={onChange(candidateMiddleName.field)}
                errorMessage={candidateMiddleName.errorMessage}
                required={candidateMiddleName.required}
              />
            </Column>
            <Column md={4}>
              <TextField
                label={candidateLastName.label}
                value={candidateLastName.value}
                onChange={onChange(candidateLastName.field)}
                errorMessage={candidateLastName.errorMessage}
                required={candidateLastName.required}
              />
            </Column>
          </GridRow>
          <GridRow>
            <Column md={3}>
              <FederalOfficePicker
                selectedKey={candidateOffice.value}
                label={candidateOffice.label}
                onChange={onChange(candidateOffice.field)}
                errorMessage={candidateOffice.errorMessage}
                required={candidateOffice.required}
              />
            </Column>
            <Column md={3}>
              <StatePicker
                selectedKey={candidateState.value}
                fieldLabel={candidateState.label}
                onChange={onChange(candidateState.field)}
                errorMessage={candidateState.errorMessage}
                required={candidateState.required}
              />
            </Column>
            <Column md={3}>
              <TextField
                label={candidateDistrict.label}
                value={candidateDistrict.value}
                onChange={onChange(candidateDistrict.field)}
                errorMessage={candidateDistrict.errorMessage}
                required={candidateDistrict.required}
              />
            </Column>
            <Column md={3}>
              <TextField
                label={candidateFecId.label}
                value={candidateFecId.value}
                onChange={onChange(candidateFecId.field)}
                errorMessage={candidateFecId.errorMessage}
                required={candidateFecId.required}
              />
            </Column>
          </GridRow>
        </Fragment>
      }

    </Fragment>
  );
};

CandidateCommitteeEntity.propTypes = {
  committeeFecId: PropTypes.object,
  name: PropTypes.object,
  contactName: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  contactType: PropTypes.string,
  candidateOffice: PropTypes.object,
  candidateState: PropTypes.object,
  candidateDistrict: PropTypes.object,
  candidateFecId: PropTypes.object,
  candidateFirstName: PropTypes.object,
  candidateMiddleName: PropTypes.object,
  candidateLastName: PropTypes.object,
};

export default CandidateCommitteeEntity;
