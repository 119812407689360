import { IconButton, TooltipHost } from 'office-ui-fabric-react';
import React from 'react';
import Badge from '../../../components/Badge';
import { ImportStatus } from '../../../helpers/constants';
import { formatDate, formatInteger } from '../../../helpers/util';

const statusColor = {
  [ImportStatus.Created]: 'var(--success-color)',
  [ImportStatus.Pending]: 'var(--warning-color)',
  [ImportStatus.Processed]: 'var(--success-color)',
  [ImportStatus.Error]: 'var(--error-color)',
  [ImportStatus.Completed]: 'var(--primary-color)',
  [ImportStatus.Failed]: 'var(--error-color)',
};

const statusLabel = {
  [ImportStatus.Pending]: 'Processing',
};

export const getColumns = ({
  finalizeImport,
  finalizeImporFileId,
  reviewErrors,
  editMapping,
}) => [
  {
    key: 'name',
    name: 'File Name',
    fieldName: 'fileName',
    data: 'string',
    minWidth: 165,
    maxWidth: 200,
    isResizable: true,
    onRender: item => <span>{item.fileName}</span>,
  },
  {
    key: 'fileSize',
    name: 'File Size',
    fieldName: 'fileSize',
    data: 'number',
    minWidth: 100,
    maxWidth: 100,
    isResizable: true,
    onRender: item => <span>{formatInteger(item.fileSize)} B</span>,
  },
  {
    key: 'createdBy',
    name: 'Created By',
    minWidth: 165,
    maxWidth: 200,
    isResizable: true,
    onRender: item => (
      <span>
        {Boolean(item.createdBy) &&
          `${item.createdBy.firstName} ${item.createdBy.lastName}`}
      </span>
    ),
  },
  {
    key: 'createdAt',
    name: 'Created At',
    fieldName: 'createdAt',
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
    onRender: item => <span>{formatDate(item.createdAt)}</span>,
  },
  {
    key: 'updatedAt',
    name: 'Updated At',
    fieldName: 'updatedAt',
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
    onRender: item => <span>{formatDate(item.updatedAt)}</span>,
  },
  {
    key: 'import_status',
    name: 'Status',
    fieldName: 'status',
    data: 'string',
    minWidth: 165,
    maxWidth: 200,
    isResizable: true,
    onRender: item => (
      <Badge text={statusLabel[item.status] || item.status} color={statusColor[item.status]} />
    ),
  },
  {
    key: 'actions',
    name: 'Actions',
    fieldName: 'actions',
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
    onRender: item => (
      <div className="import-list-actions-cell">
        {item.status !== ImportStatus.Completed &&
          item.status !== ImportStatus.Failed &&
          item.status !== ImportStatus.Pending && (
            <TooltipHost content="Edit mapping">
              <IconButton
                style={{ height: 16, width: 16 }}
                iconProps={{ iconName: 'PencilAlt' }}
                onClick={() => editMapping(item.fileId)}
              />
            </TooltipHost>
          )}
        {item.status === ImportStatus.Processed && (
          <TooltipHost content="Finalize import">
            <IconButton
              style={{ height: 16, width: 16 }}
              iconProps={{ iconName: 'ThumbsUp' }}
              onClick={() => finalizeImport(item.fileId)}
              disabled={finalizeImporFileId === item.fileId}
            />
          </TooltipHost>
        )}
        {item.status === ImportStatus.Error && (
          <TooltipHost content="Review errors">
            <IconButton
              style={{ height: 16, width: 16 }}
              iconProps={{ iconName: 'Fix' }}
              onClick={() => reviewErrors(item.fileId)}
            />
          </TooltipHost>
        )}
      </div>
    ),
  },
];
