export const types = {
  GET_LIST: 'GET_LIST',
  GET_LIST_OLD: 'GET_LIST_OLD',
  GET_LIST_PROCESSING: 'GET_LIST_PROCESSING',
  GET_LIST_SUCCESS: 'GET_LIST_SUCCESS',
  GET_CSV: 'GET_CSV',
  GET_CSV_PROCESSING: 'GET_CSV_PROCESSING',
  GET_CSV_SUCCESS: 'GET_CSV_SUCCESS',
  GET_CSV_FAILURE: 'GET_CSV_FAILURE',
  GET_CONTRIBUTION_LIMIT_CSV: 'GET_CONTRIBUTION_LIMIT_CSV',
  GET_CONTRIBUTION_LIMIT_CSV_PROCESSING: 'GET_CONTRIBUTION_LIMIT_CSV_PROCESSING',
  GET_CONTRIBUTION_LIMIT_CSV_SUCCESS: 'GET_CONTRIBUTION_LIMIT_CSV_SUCCESS',
  GET_CONTRIBUTION_LIMIT_CSV_FAILURE: 'GET_CONTRIBUTION_LIMIT_CSV_FAILURE',
  CLEAR_LISTS: 'CLEAR_LISTS',
  GET_LIST_UNRECONCILED: 'GET_LIST_UNRECONCILED',
};
