export const validate = state => {
  const errors = {};

  if (state.newPassword.length === 0) {
    errors.newPasswordError = 'New password is required';
  }

  if (state.confirmPassword.length === 0) {
    errors.confirmPasswordError = 'Confirm new password is required';
  } else if (state.newPassword !== state.confirmPassword) {
    errors.confirmPasswordError = 'Passwords do not match';
  }

  return errors;
};
