import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogType,
  DialogFooter,
} from 'office-ui-fabric-react/lib/Dialog';
import {
  DefaultButton,
  PrimaryButton,
} from 'office-ui-fabric-react/lib/Button';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { StatePicker } from '../../components/Pickers';
import {
  Grid,
  GridRow,
  Column,
  PhoneNumberField,
  EmailField,
  ZipCodeField,
} from '../../components/common';

const startState = {
  firstName: '',
  middleName: '',
  lastName: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: 0,
  zipCode: '',
  telephoneNumber: '',
  emailAddress: '',
  titleOrPosition: '',
};

const DesignatedAgentModal = ({
  isHidden,
  actions,
  designatedAgent,
  session,
}) => {
  const [state, setState] = useState({ ...startState });

  useEffect(() => {
    setState({
      ...designatedAgent,
    });
  }, [designatedAgent]);

  const isEdit = designatedAgent !== null;

  const handleChange = fieldName => (e, value) => {
    setState({
      ...state,
      [fieldName]: value.key !== undefined ? value.key : value,
    });
  };

  const onDismiss = () => {
    setState({ ...startState });
    actions.onDismiss();
  };

  const onSave = () => {
    actions.onSave({ ...state });
    actions.onDismiss();
  };

  return (
    <Dialog
      hidden={isHidden}
      onDismiss={onDismiss}
      dialogContentProps={{
        dialogType: DialogType.normal,
        title: `${
          isEdit ? 'Edit' : 'Add'
        } Designated Agent / Assistant Treasurer`,
      }}
      minWidth={'50%'}
    >
      <Grid>
        <GridRow>
          <Column md={4}>
            <TextField
              label="First Name"
              value={state.firstName}
              onChange={handleChange('firstName')}
              required
            />
          </Column>
          <Column md={4}>
            <TextField
              label="Middle Name"
              value={state.middleName}
              onChange={handleChange('middleName')}
            />
          </Column>
          <Column md={4}>
            <TextField
              label="Last Name"
              value={state.lastName}
              onChange={handleChange('lastName')}
              required
            />
          </Column>
        </GridRow>
        <GridRow>
          <Column md={6}>
            <TextField
              label="Mailing Address 1"
              value={state.addressLine1}
              onChange={handleChange('addressLine1')}
              required
            />
          </Column>
          <Column md={6}>
            <TextField
              label="Mailing Address 2"
              value={state.addressLine2}
              onChange={handleChange('addressLine2')}
            />
          </Column>
        </GridRow>
        <GridRow>
          <Column md={4}>
            <TextField
              label="City"
              value={state.city}
              onChange={handleChange('city')}
              required
            />
          </Column>
          <Column md={4}>
            <StatePicker
              required
              onChange={handleChange('state')}
              selectedKey={state.state}
              placeholder="Select"
              session={session}
            />
          </Column>
          <Column md={4}>
            <ZipCodeField
              value={state.zipCode}
              onChange={handleChange('zipCode')}
              required
            />
          </Column>
        </GridRow>
        <GridRow>
          <Column md={4}>
            <PhoneNumberField
              label="Telephone Number"
              value={state.telephoneNumber}
              onChange={handleChange('telephoneNumber')}
            />
          </Column>
          <Column md={4}>
            <EmailField
              value={state.emailAddress}
              onChange={handleChange('emailAddress')}
            />
          </Column>
          <Column md={4}>
            <TextField
              label="Title or Position"
              value={state.titleOrPosition}
              onChange={handleChange('titleOrPosition')}
            />
          </Column>
        </GridRow>
      </Grid>
      <DialogFooter>
        <DefaultButton
          text="Cancel"
          onClick={onDismiss}
          style={{
            marginRight: 16,
          }}
        />
        <PrimaryButton text={isEdit ? 'Save' : 'Add'} onClick={onSave} />
      </DialogFooter>
    </Dialog>
  );
};

DesignatedAgentModal.propTypes = {
  actions: PropTypes.object.isRequired,
  isHidden: PropTypes.bool.isRequired,
  designatedAgent: PropTypes.object,
  session: PropTypes.object.isRequired,
};

export default DesignatedAgentModal;
