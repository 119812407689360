import { types } from '../actions/anedotActions';
import { statuses } from '../helpers/constants';
import { createReducer } from './createReducer';

export const initialState = {
  currentIndex: 0,
  unprocessed: [],
  nextUnprocessed: null,
  getUnprocessedStatus: statuses.NOT_STARTED,
  getUnprocessedError: null,
  updateStatus: statuses.NOT_STARTED,
  updateError: null,
  aggregateAmount: [],
  aggregateAmountError: null,
  unprocessedRecord: null,
  getUnprocessedRecordStatus: statuses.NOT_STARTED,
  getUnprocessedRecordError: null,
};

const actionMap = {
  [types.GET_UNPROCESSED_PROCESSING]: state => {
    return {
      ...state,
      getUnprocessedStatus: statuses.PROCESSING,
      aggregateAmount: [],
    };
  },
  [types.GET_UNPROCESSED_SUCCESS]: (state, { data: { unprocessed } }) => {
    return {
      ...state,
      getUnprocessedStatus: statuses.SUCCESS,
      getUnprocessedError: null,
      nextUnprocessed: unprocessed[state.currentIndex],
      unprocessed,
      updateStatus: statuses.NOT_STARTED,
    };
  },
  [types.GET_UNPROCESSED_FAILURE]: (state, action) => {
    return {
      ...state,
      getUnprocessedError: action.error,
      getUnprocessedStatus: statuses.ERROR,
      nextUnprocessed: null,
      unprocessed: [],
      currentIndex: 0,
      updateStatus: statuses.NOT_STARTED,
    };
  },
  [types.GET_RECORD_PROCESSING]: state => {
    return {
      ...state,
      getUnprocessedRecordStatus: statuses.PROCESSING,
    };
  },
  [types.GET_RECORD_SUCCESS]: (state, { data: { unprocessedRecord } }) => {
    return {
      ...state,
      getUnprocessedRecordStatus: statuses.SUCCESS,
      getUnprocessedRecordError: null,
      unprocessedRecord,
      updateStatus: statuses.NOT_STARTED,
    };
  },
  [types.GET_RECORD_FAILURE]: (state, action) => {
    return {
      ...state,
      getUnprocessedRecordError: action.error,
      getUnprocessedRecordStatus: statuses.ERROR,
      unprocessedRecord: null,
      updateStatus: statuses.NOT_STARTED,
    };
  },
  [types.UPDATE_ANEDOT_CONTRIBUTION_PROCESSING]: state => {
    return {
      ...state,
      updateStatus: statuses.PROCESSING,
    };
  },
  [types.UPDATE_ANEDOT_CONTRIBUTION_SUCCESS]: (
    state,
    { data: { anedotId } },
  ) => {
    const unprocessed = state.unprocessed.filter(e => e !== anedotId);
    if (unprocessed.length === 0) {
      return {
        ...state,
        currentIndex: 0,
        nextUnprocessed: null,
        unprocessed,
        aggregateAmount: [],
      };
    }

    // if on last record in list start at beginning
    if (!unprocessed[state.currentIndex]) {
      return {
        ...state,
        updateStatus: statuses.SUCCESS,
        unprocessed,
        nextUnprocessed: unprocessed[0],
        currentIndex: 0,
        aggregateAmount: [],
      };
    }
    return {
      ...state,
      updateStatus: statuses.SUCCESS,
      unprocessed,
      nextUnprocessed: unprocessed[state.currentIndex],
      aggregateAmount: [],
    };
  },
  [types.UPDATE_ANEDOT_CONTRIBUTION_FAILURE]: (state, action) => {
    return {
      ...state,
      updateStatus: statuses.ERROR,
      updateError: action.error,
    };
  },
  [types.CLEAR_UPDATE_STATUS]: state => {
    return {
      ...state,
      updateStatus: statuses.NOT_STARTED,
      updateError: null,
    };
  },

  [types.CLEAR_UNPROCESSED]: state => {
    return {
      ...state,
      nextUnprocessed: null,
      unprocessed: [],
      currentIndex: 0,
      getUnprocessedStatus: statuses.NOT_STARTED,
      getUnprocessedError: null,
      aggregateAmount: [],
    };
  },
  [types.GET_NEXT_UNPROCESSED]: state => {
    if (state.unprocessed[state.currentIndex]) {
      const newIndex = state.currentIndex + 1;
      return {
        ...state,
        currentIndex: newIndex,
        nextUnprocessed: state.unprocessed[newIndex],
      };
    }

    return state;
  },
  [types.GET_PREVIOUS_UNPROCESSED]: state => {
    if (state.currentIndex > 0) {
      const newIndex = state.currentIndex - 1;
      return {
        ...state,
        currentIndex: newIndex,
        nextUnprocessed: state.unprocessed[newIndex],
      };
    }

    return state;
  },
  [types.GET_AGGREGATE_AMOUNT_SUCCESS]: (
    state,
    { data: { amount, _id, electionCycle, electionYear } },
  ) => {
    const newAggregates = [
      ...state.aggregateAmount,
      {
        _id,
        electionCycle,
        electionYear,
        amount,
        total: amount.replace(/[^0-9.]+/g, ''),
      },
    ];
    return {
      ...state,
      aggregateAmount: newAggregates,
    };
  },
};

export const anedotReducer = createReducer(initialState, actionMap);
