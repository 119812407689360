import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { GridRow, Column, MoneyField } from '../../common';

const FECSection13b = ({ q13bColA, q13bColB }) => {
  return (
    <Fragment>
      <GridRow>
        <Column sm={4} classNames="sub-question">
          <p>(b) All Other Loans</p>
        </Column>
        <Column sm={4} classNames="entry-row">
          <MoneyField
            disabled
            value={q13bColA || 0}
          />
        </Column>
        <Column sm={4} classNames="entry-row">
          <MoneyField
            disabled
            value={q13bColB || 0}
          />
        </Column>
      </GridRow>
    </Fragment>
  );
};

FECSection13b.propTypes = {
  q13bColA: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  q13bColB: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default FECSection13b;
