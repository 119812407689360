import { types } from '../actions/currentCampaignActions';
import { createReducer } from './createReducer';
import { statuses } from '../helpers/constants';

export const initialState = {
  campaign: null,
  campaignFinances: null,
  getCurrentCampaignError: null,
  getCurrentCampaignFinanceError: null,
  getCurrentCampaignStatus: statuses.NOT_STARTED,
  getCurrentCampaignFinanceStatus: statuses.NOT_STARTED,
  allCampaigns: [],
};

const actionMap = {
  [types.GET_CURRENT_CAMPAIGN_PROCESSING]: (state) => {
    return {
      ...state,
      campaign: null,
      getCurrentCampaignError: null,
      getCurrentCampaignStatus: statuses.PROCESSING,
    };
  },
  [types.GET_CURRENT_CAMPAIGN_SUCCESS]: (state, action) => {
    return {
      ...state,
      campaign: action.campaign,
      getCurrentCampaignError: null,
      getCurrentCampaignStatus: statuses.SUCCESS,
    };
  },
  [types.GET_CURRENT_CAMPAIGN_FINANCE_PROCESSING]: (state) => {
    return {
      ...state,
      campaignFinances: null,
      getCurrentCampaignFinanceStatus: statuses.PROCESSING,
    };
  },
  [types.RESET_CURRENT_CAMPAIGN_FINANCE]: (state) => {
    return {
      ...state,
      campaignFinances: null,
      getCurrentCampaignFinanceStatus: statuses.NOT_STARTED,
    };
  },
  [types.GET_CURRENT_CAMPAIGN_FINANCE_SUCCESS]: (state, action) => {
    return {
      ...state,
      campaignFinances: action.campaignFinances,
      getCurrentCampaignFinanceStatus: statuses.SUCCESS,
    };
  },
  [types.GET_CURRENT_CAMPAIGN_FINANCE_FAILURE]: (state, action) => {
    return {
      ...state,
      getCurrentCampaignFinanceError: action.error,
      getCurrentCampaignFinanceStatus: statuses.ERROR,
    };
  },
  [types.GET_CURRENT_CAMPAIGN_FAILURE]: (state, action) => {
    return {
      ...state,
      getCurrentCampaignError: action.error,
      getCurrentCampaignStatus: statuses.ERROR,
    };
  },
  [types.GET_ALL_CAMPAIGNS_SUCCESS]: (state, action) => {
    return {
      ...state,
      allCampaigns: action.data.campaigns || [],
    };
  },
};

export const currentCampaignReducer = createReducer(
  initialState,
  actionMap,
  false,
);
