export const types = {
  GET_DRAFT_REPORTS: 'GET_DRAFT_REPORTS',
  GET_DRAFT_REPORTS_SUCCESS: 'GET_DRAFT_REPORTS_SUCCESS',
  GET_DRAFT_REPORTS_FAILURE: 'GET_DRAFT_REPORTS_FAILURE',
  GET_DRAFT_REPORTS_PROCESSING: 'GET_DRAFT_REPORTS_PROCESSING',
  GET_DRAFT_REPORTS_RESET: 'GET_DRAFT_REPORTS_RESET',
  GET_FILED_REPORTS: 'GET_FILED_REPORTS',
  GET_FILED_REPORTS_SUCCESS: 'GET_FILED_REPORTS_SUCCESS',
  GET_FILED_REPORTS_FAILURE: 'GET_FILED_REPORTS_FAILURE',
  GET_FILED_REPORTS_PROCESSING: 'GET_FILED_REPORTS_PROCESSING',
  GET_FILED_REPORTS_RESET: 'GET_DRAFT_REPORTS_RESET',
  CREATE_DISCLOSURE_REPORT: 'CREATE_DISCLOSURE_REPORT',
  CREATE_DISCLOSURE_REPORT_SUCCESS: 'CREATE_DISCLOSURE_REPORT_SUCCESS',
  CREATE_DISCLOSURE_REPORT_PROCESSING: 'CREATE_DISCLOSURE_REPORT_PROCESSING',
  CREATE_DISCLOSURE_REPORT_RESET: 'CREATE_DISCLOSURE_REPORT_RESET',
  CREATE_TBD_REPORT: 'CREATE_TBD_REPORT',
  CREATE_TBD_REPORT_SUCCESS: 'CREATE_TBD_REPORT_SUCCESS',
  CREATE_TBD_REPORT_PROCESSING: 'CREATE_TBD_REPORT_PROCESSING',
  CREATE_TBD_REPORT_RESET: 'CREATE_TBD_REPORT_RESET',
  CREATE_48HOUR_NOTICE_REPORT: 'CREATE_48HOUR_NOTICE_REPORT',
  CREATE_48HOUR_NOTICE_REPORT_SUCCESS: 'CREATE_48HOUR_NOTICE_REPORT_SUCCESS',
  CREATE_48HOUR_NOTICE_REPORT_PROCESSING: 'CREATE_48HOUR_NOTICE_REPORT_PROCESSING',
  CREATE_48HOUR_NOTICE_REPORT_RESET: 'CREATE_48HOUR_NOTICE_REPORT_RESET',
  DELETE_DISCLOSURE_REPORT: 'DELETE_DISCLOSURE_REPORT',
  DELETE_48HOUR_NOTICE_REPORT: 'DELETE_48HOUR_NOTICE_REPORT',
  CLEAR_REPORTS: 'CLEAR_REPORTS',
};

export const actions = {
  getDraftReports(reportType = '') {
    return { type: types.GET_DRAFT_REPORTS, data: { reportType } };
  },

  getFiledReports(reportType = '') {
    return { type: types.GET_FILED_REPORTS, data: { reportType } };
  },

  createDisclosureReport(payload) {
    return { type: types.CREATE_DISCLOSURE_REPORT, payload };
  },

  createTBDReport(payload) {
    const tbdPayload = {
      electionDate: new Date(payload.electionDate),
      reportDate: payload.reportDate,
      reportType: payload.reportType,
      reportingPeriod: payload.reportingPeriod,
      startDate: payload.startDate,
      endDate: payload.endDate,
    };
    return { type: types.CREATE_TBD_REPORT, payload: tbdPayload };
  },

  create48HourNoticeReport(payload) {
    return { type: types.CREATE_48HOUR_NOTICE_REPORT, payload };
  },

  deleteDisclosureReport(id, isTBDReport = false) {
    return { type: types.DELETE_DISCLOSURE_REPORT, data: { id, isTBDReport } };
  },

  delete48HourNoticeReport(id) {
    return { type: types.DELETE_48HOUR_NOTICE_REPORT, data: { id } };
  },

  clearReports() {
    return { type: types.CLEAR_REPORTS };
  },
};
