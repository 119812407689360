import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { Checkbox } from 'office-ui-fabric-react/lib/Checkbox';
import { DefaultButton, ActionButton } from 'office-ui-fabric-react/lib/Button';
import {
  DetailsList,
  ConstrainMode,
  SelectionMode,
  CheckboxVisibility,
} from 'office-ui-fabric-react/lib/DetailsList';
import { actions as ledgerActions } from '../../actions/ledgerActions';
import { BackButton, Grid, GridRow } from '../../components/common';
import { formatDate, formatCurrency, sortByField } from '../../helpers/util';
import { sortDirections } from '../../helpers/constants';
import Loading from '../../components/Loading';
import { FilterLedgerPanel } from '../../components/Panels';
import {
  applyFilter,
  byMinDate,
  byMaxDate,
  onColumnClick,
} from '../../helpers/listsHelper';
import { getLabel } from '../../helpers/labelHelper';
import './Ledger.css';

const filterAmounts = (minAmount, maxAmount) => item => {
  if (minAmount !== 0 && maxAmount === 0) {
    return item.credit >= minAmount || item.debit >= minAmount;
  }

  if (maxAmount !== 0 && minAmount === 0) {
    return item.credit <= maxAmount || item.debit <= maxAmount;
  }

  if (minAmount !== 0 && maxAmount !== 0) {
    return (
      (item.credit >= minAmount && item.credit <= maxAmount) ||
      (item.debit >= minAmount && item.debit <= maxAmount)
    );
  }

  return true;
};

export class Ledger extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    ledger: PropTypes.object.isRequired,
    ledgerActions: PropTypes.object.isRequired,
    electionCycles: PropTypes.array.isRequired,
    session: PropTypes.object.isRequired,
  };

  constructor(...args) {
    super(...args);

    this.state = {
      filterText: '',
      sortField: 'date',
      sortDirection: sortDirections.ASC,
      showFiltersPanel: false,
      electionCycleFilters: [],
      transactionTypeFilters: [],
      entityTypeFilters: [],
      paymentTypeFilters: [],
      startDate: '',
      endDate: '',
      startAmount: 0,
      endAmount: 0,
    };

    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.mapItems = this.mapItems.bind(this);
    this.createColumsn = this.createColumns.bind(this);
    this.editContribution = this.editContribution.bind(this);
    this.editExpenditure = this.editExpenditure.bind(this);
    this.editLoan = this.editLoan.bind(this);
    this.onColumnClick = this.onColumnClick.bind(this);
    this.showFiltersPanel = this.showFiltersPanel.bind(this);
    this.hideFiltersPanel = this.hideFiltersPanel.bind(this);
    this.onChangeMultiSelect = this.onChangeMultiSelect.bind(this);
    this.resetFilters = this.resetFilters.bind(this);
    this.onChangeDate = this.onChangeDate.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  resetFilters() {
    this.setState({
      electionCycleFilters: [],
      transactionTypeFilters: [],
      entityTypeFilters: [],
      paymentTypeFilters: [],
      startDate: '',
      endDate: '',
      startAmount: 0,
      endAmount: 0,
    });
  }

  componentDidMount() {
    this.props.ledgerActions.getLedger();
  }

  componentWillUnmount() {
    this.props.ledgerActions.clearLedger();
  }

  handleSearchChange(e, filterText) {
    this.setState({
      filterText,
    });
  }

  showFiltersPanel() {
    this.setState({
      showFiltersPanel: true,
    });
  }

  hideFiltersPanel() {
    this.setState({
      showFiltersPanel: false,
    });
  }

  editContribution(id) {
    this.props.history.push(`/filer/editContribution/${id}?redirect=ledger`);
  }

  editExpenditure(id) {
    this.props.history.push(`/filer/editExpenditure/${id}?redirect=ledger`);
  }

  editLoan(id) {
    this.props.history.push(`/filer/editLoan/${id}?redirect=ledger`);
  }

  mapItems() {
    const {
      sortField,
      sortDirection,
      filterText,
      electionCycleFilters,
      transactionTypeFilters,
      entityTypeFilters,
      paymentTypeFilters,
      startDate,
      endDate,
      startAmount,
      endAmount,
    } = this.state;

    const minAmount = parseFloat(startAmount, 10);
    const maxAmount = parseFloat(endAmount, 10);

    const { entries = [] } = this.props.ledger;
    return entries
      .filter(
        e =>
          e.displayName.toLowerCase().includes(filterText.toLowerCase()) ||
          e.transactionType.toLowerCase().includes(filterText.toLowerCase()) ||
          e.debit.toString().includes(filterText.toLowerCase()) ||
          e.credit.toString().includes(filterText.toLowerCase()) ||
          e.balance.toString().includes(filterText.toLowerCase()),
      )
      .filter(applyFilter(electionCycleFilters, 'electionCycle'))
      .filter(applyFilter(transactionTypeFilters, 'transactionType'))
      .filter(applyFilter(entityTypeFilters, 'entityType'))
      .filter(applyFilter(paymentTypeFilters, 'paymentType'))
      .filter(byMinDate(startDate, 'transactionDate'))
      .filter(byMaxDate(endDate, 'transactionDate'))
      .filter(filterAmounts(minAmount, maxAmount))
      .map(e => ({
        ...e,
        key: e._id,
      }))
      .sort(sortByField(sortField, sortDirection));
  }

  onColumnClick(e, column) {
    this.setState(onColumnClick(column.fieldName));
  }

  createColumns() {
    const { session } = this.props;
    const { sortField, sortDirection } = this.state;

    return [
      {
        key: 'date',
        fieldName: 'transactionDate',
        name: 'Date',
        maxWidth: 100,
        onRender: item => formatDate(item.transactionDate),
        onColumnClick: this.onColumnClick,
        isSorted:
          sortField === 'transactionDate' &&
          sortDirection !== sortDirections.NONE,
        isSortedDescending: sortDirection === sortDirections.DESC,
      },
      {
        key: 'type',
        fieldName: 'transactionType',
        name: 'Type',
        maxWidth: 100,
        onRender: item => getLabel(item.transactionType, session),
        onColumnClick: this.onColumnClick,
        isSorted:
          sortField === 'transactionType' &&
          sortDirection !== sortDirections.NONE,
        isSortedDescending: sortDirection === sortDirections.DESC,
      },
      {
        key: 'description',
        fieldName: 'displayName',
        name: 'Description',
        onRender: item => {
          if (item.transactionType === 'Contribution') {
            return (
              <span
                onClick={() => this.editContribution(item._id)}
                style={{
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  color: '#71afe5',
                }}
              >
                {item.displayName}
              </span>
            );
          }

          if (item.transactionType === 'Expenditure') {
            return (
              <span
                onClick={() => this.editExpenditure(item._id)}
                style={{
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  color: '#71afe5',
                }}
              >
                {item.displayName}
              </span>
            );
          }

          if (item.transactionType === 'Loan') {
            return (
              <span
                onClick={() => this.editLoan(item._id)}
                style={{
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  color: '#71afe5',
                }}
              >
                {item.displayName}
              </span>
            );
          }

          return item.displayName;
        },
        onColumnClick: this.onColumnClick,
        isSorted:
          sortField === 'displayName' && sortDirection !== sortDirections.NONE,
        isSortedDescending: sortDirection === sortDirections.DESC,
      },
      {
        key: 'checkNumber',
        fieldName: 'checkNumberSort',
        name: 'Check Number',
        onRender: item => item.checkNumber,
        onColumnClick: this.onColumnClick,
        isSorted:
          sortField === 'checkNumberSort' &&
          sortDirection !== sortDirections.NONE,
        isSortedDescending: sortDirection === sortDirections.DESC,
      },
      {
        key: 'paymentReferenceId',
        fieldName: 'paymentReferenceId',
        name: 'Reference Number',
        onRender: item => item.paymentReferenceId,
        onColumnClick: this.onColumnClick,
        isSorted:
          sortField === 'paymentReferenceId' &&
          sortDirection !== sortDirections.NONE,
        isSortedDescending: sortDirection === sortDirections.DESC,
      },
      {
        key: 'credit',
        fieldName: 'credit',
        name: 'Credit',
        onRender: item => (
          <div style={{ textAlign: 'right' }}>
            {item.credit ? formatCurrency(item.credit) : '--'}
          </div>
        ),
        onColumnClick: this.onColumnClick,
        isSorted:
          sortField === 'credit' && sortDirection !== sortDirections.NONE,
        isSortedDescending: sortDirection === sortDirections.DESC,
      },
      {
        key: 'debit',
        fieldName: 'debit',
        name: 'Debit',
        onRender: item => (
          <div style={{ textAlign: 'right' }}>
            {item.debit ? formatCurrency(item.debit) : '--'}
          </div>
        ),
        onColumnClick: this.onColumnClick,
        isSorted:
          sortField === 'debit' && sortDirection !== sortDirections.NONE,
        isSortedDescending: sortDirection === sortDirections.DESC,
      },
      {
        key: 'balance',
        fieldName: 'balance',
        name: 'Balance',
        onRender: item => (
          <div style={{ textAlign: 'right' }}>
            {formatCurrency(item.balance)}
          </div>
        ),
        onColumnClick: this.onColumnClick,
        isSorted:
          sortField === 'balance' && sortDirection !== sortDirections.NONE,
        isSortedDescending: sortDirection === sortDirections.DESC,
      },
      {
        key: 'reconciled',
        fieldName: 'reconciled',
        name: 'Reconciled',
        onRender: item =>
          item.transactionId &&
          item.transactionType &&
          item.transactionType !== 'Loan' ? (
            <Checkbox
              checked={item.reconciled}
              onChange={() => this.toggleReconcilation(item)}
            />
          ) : (
            ''
          ),
        onColumnClick: this.onColumnClick,
        isSorted:
          sortField === 'reconciled' && sortDirection !== sortDirections.NONE,
        isSortedDescending: sortDirection === sortDirections.DESC,
      },
    ];
  }

  toggleReconcilation = item => {
    this.props.ledgerActions.reconcile(
      item.transactionId,
      item.transactionType,
      !item.reconciled,
    );
  };

  onChangeMultiSelect(fieldName) {
    return (e, item) => {
      this.setState(state => {
        let selectedItems = [...state[fieldName]];
        if (item.selected) {
          selectedItems = [...selectedItems, item.key];
        } else {
          selectedItems = selectedItems.filter(i => i !== item.key);
        }
        state[fieldName] = selectedItems;
        return state;
      });
    };
  }

  onChangeDate(fieldName) {
    return date => {
      this.setState({
        [fieldName]: date,
      });
    };
  }

  onChange(fieldName) {
    return (e, value) => {
      this.setState({
        [fieldName]: value,
      });
    };
  }

  render() {
    const { ledger, electionCycles, session } = this.props;
    const {
      filterText,
      showFiltersPanel,
      electionCycleFilters,
      transactionTypeFilters,
      entityTypeFilters,
      paymentTypeFilters,
      startDate,
      endDate,
      startAmount,
      endAmount,
    } = this.state;

    if (!ledger.id) {
      return <Loading />;
    }

    return (
      <Fragment>
        <BackButton history={this.props.history} />
        <div className="Ledger depth-1">
          <header>
            <h3>Transactions</h3>
            <div>
              {(electionCycleFilters.length > 0 ||
                transactionTypeFilters.length > 0 ||
                entityTypeFilters.length > 0 ||
                paymentTypeFilters.length > 0 ||
                startDate !== '' ||
                endDate !== '' ||
                startAmount !== 0 ||
                endAmount !== 0) && (
                <ActionButton
                  text="Reset"
                  onClick={this.resetFilters}
                  iconProps={{ iconName: 'Filter' }}
                  style={{ marginRight: 16 }}
                />
              )}
              <DefaultButton
                text="Filter"
                iconProps={{ iconName: 'Filter' }}
                onClick={this.showFiltersPanel}
              />
            </div>
          </header>
          <Grid>
            <GridRow>
              <div className="ms-Grid-col ms-sm12">
                <TextField
                  placeholder="Search Transactions"
                  onChange={this.handleSearchChange}
                  value={filterText}
                />
              </div>
            </GridRow>
            <GridRow>
              <div className="ms-Grid-col ms-sm12 ms-textAlignRight">
                <h3>
                  <span
                    style={{ fontWeight: 400 }}
                  >{`Opening Balance as of ${formatDate(
                    ledger.openingCashAmountDate,
                  )}: `}</span>
                  <strong>{`${formatCurrency(
                    ledger.openingCashAmount,
                  )}`}</strong>
                </h3>
              </div>
            </GridRow>
            <GridRow>
              <div className="ms-Grid-col ms-sm12">
                <DetailsList
                  items={this.mapItems()}
                  columns={this.createColumns()}
                  checkboxVisibility={CheckboxVisibility.none}
                  compact={false}
                  selectionMode={SelectionMode.none}
                  constrainMode={ConstrainMode.horizontalConstrained}
                />
              </div>
            </GridRow>
            <GridRow>
              <div className="ms-Grid-col ms-sm12 ms-textAlignRight">
                <h3>
                  <span style={{ fontWeight: 400 }}>{'Closing Balance: '}</span>
                  <strong>{` ${formatCurrency(ledger.balance)}`}</strong>
                </h3>
              </div>
            </GridRow>
          </Grid>
        </div>
        <FilterLedgerPanel
          showPanel={showFiltersPanel}
          closePanel={this.hideFiltersPanel}
          electionCycles={electionCycles}
          electionCycleFilters={electionCycleFilters}
          transactionTypeFilters={transactionTypeFilters}
          entityTypeFilters={entityTypeFilters}
          paymentTypeFilters={paymentTypeFilters}
          startDate={startDate}
          endDate={endDate}
          startAmount={startAmount}
          endAmount={endAmount}
          actions={{
            onChangeMultiSelect: this.onChangeMultiSelect,
            onChangeDate: this.onChangeDate,
            onChange: this.onChange,
            resetFilters: this.resetFilters,
          }}
          session={session}
        />
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    ledger: state.ledger,
    electionCycles: state.electionCycles.cycles,
    session: state.user.session,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ledgerActions: bindActionCreators(ledgerActions, dispatch),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Ledger));
