import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ActionButton } from 'office-ui-fabric-react/lib/Button';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import {
  DetailsList,
  DetailsRow,
  CheckboxVisibility,
  SelectionMode,
  DetailsListLayoutMode,
} from 'office-ui-fabric-react/lib/DetailsList';
import { DefaultButton, Stack } from 'office-ui-fabric-react';
import { actions as reportActions } from '../../actions/reportActions';
import { formatUtcDate } from '../../helpers/util';
import {
  getReportTypeByItemForm24,
  getReportTypeByItemForm6,
  // getReportTypeByItemForm24,
}
  from '../../helpers/reportHelper';
import { frCampaignTypes } from '../../helpers/constants';
import { getFRCampaignType } from '../../helpers/campaignHelper';
import { AddFederalReportDialog } from '../../components/Dialogs';
import { BackButton } from '../../components/common';
import './ViewAll.css';
import { actions as currentReportActions } from '../../actions/currentReportActions';
import ReportViewPicker from '../../components/Pickers/ReportViewPicker';

export class Form6Form24ViewAllReports extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    campaign: PropTypes.object.isRequired,
    reports: PropTypes.object.isRequired,
    reportActions: PropTypes.object.isRequired,
    currentReportActions: PropTypes.object.isRequired,
    session: PropTypes.object.isRequired,
  };

  state = {
    createReportDialogHidden: true,
    frCampaignType: null,
    is48Hour: false,
  };

  componentDidMount() {
    this.props.reportActions.getDraftReports('48HourNotice');
    this.props.reportActions.getFiledReports('48HourNotice');
    this.setState({ frCampaignType: getFRCampaignType(this.props.campaign) });
  }

  componentWillUnmount() {
    this.props.reportActions.clearReports();
  }

  openCreateReportDialog = (is48Hour) => {
    this.setState({
      is48Hour,
      createReportDialogHidden: false,
    });
  };

  closeCreateReportDialog = () => {
    this.setState({
      createReportDialogHidden: true,
    });
  };

  create48HourNoticeReport = reportDetails => {
    const { reportDate, electionDate, electionCycle, startDate, endDate, reportType } = reportDetails;
    const payload = {
      reportType,
      reportDate,
      electionDate,
      electionCycle,
      startDate,
      endDate,
    };
    this.closeCreateReportDialog();
    this.props.reportActions.create48HourNoticeReport(payload);
  };

  createColumnsDraft = () => {
    const getReportType = this.state.frCampaignType === frCampaignTypes.FederalCandidate
      ? getReportTypeByItemForm6 : getReportTypeByItemForm24;
    return [
        {
          key: 'report',
          name: 'Report',
          minWidth: 400,
          maxWidth: 420,
          onRender: item => getReportType(item),
        },
        {
          key: 'report-start-date',
          name: 'Start Date',
          minWidth: 100,
          maxWidth: 100,
          onRender: item => <span>{formatUtcDate(item.startDate)}</span>,
        },
        {
          key: 'report-end-date',
          name: 'End Date',
          minWidth: 100,
          maxWidth: 100,
          onRender: item => <span>{formatUtcDate(item.endDate)}</span>,
        },
      ];
  };

  getVersion = (item) => {
    const currentOrPastMsg = item.isCurrentVersion ? 'Current Version' : 'Past Version';
    const currentOrPastIcon = item.isCurrentVersion ? <Icon styles={{ root: { color: '#36bdbb' } }} iconName="CheckCircle" /> : <Icon styles={{ root: { color: '#112e51' } }} iconName="history" />;
    return (
      <Stack horizontal childrenGap={8}>
        <div style={{ paddingRight: 4 }}>{currentOrPastIcon}</div>
        <Stack childrenGap={4}>
          <div>{currentOrPastMsg}</div>
          <div>{item.filingId}</div>
        </Stack>
      </Stack>
    );
  };

  createFiledColumns = () => {
    const getReportType = this.state.frCampaignType === frCampaignTypes.FederalCandidate
      ? getReportTypeByItemForm6 : getReportTypeByItemForm24;
    return [
        {
          key: 'report',
          name: 'Report',
          minWidth: 400,
          maxWidth: 420,
          onRender: item => getReportType(item),
        },
        {
          key: 'view',
          name: '',
          minWidth: 125,
          maxWidth: 140,
          onRender: item => {
            const filedPdfLocation = `/api/filer/reports/${item._id}/pdfFiled`;
            const fecResponseLocation = `/api/filer/reports/${item._id}/filed`;
            const fecFileLocation = `/api/filer/reports/${item._id}/fec/fecFile`;
            return (
              <div className="filed-report-actions">
                <ReportViewPicker
                  filedPdfLocation={filedPdfLocation}
                  fecResponseLocation={fecResponseLocation}
                  fecFileLocation={fecFileLocation}
                />
              </div>
            );
          },
        },
        {
          key: 'version',
          name: 'Version',
          minWidth: 220,
          maxWidth: 220,
          onRender: item => this.getVersion(item),
        },
        {
          key: 'report-start-date',
          name: 'Start Date',
          minWidth: 100,
          maxWidth: 100,
          onRender: item => <span>{formatUtcDate(item.startDate)}</span>,
        },
        {
          key: 'report-end-date',
          name: 'End Date',
          minWidth: 100,
          maxWidth: 100,
          onRender: item => <span>{formatUtcDate(item.endDate)}</span>,
        },
        {
          key: 'report-date-filed',
          name: 'Date Filed',
          minWidth: 100,
          maxWidth: 100,
          onRender: item => <span>{formatUtcDate(item.reportFiledAt)}</span>,
        },
        {
          key: 'actions',
          name: '',
          minWidth: 125,
          maxWidth: 140,
          onRender: item => {
            return (
              <div className="filed-report-actions">
                { item.isAmendable &&
                <DefaultButton
                  onClick={!item.isAmendable ? () => {} : () => this.props.currentReportActions.amendReportFederal(item._id)}
                  iconProps={{
                    iconName: 'PencilAlt',
                    styles: {
                      root: {
                        color: '#107c10',
                        marginRight: 8,
                      },
                    },
                  }}
                  text="Amend"
                  disabled={!item.isAmendable}
                />
                }
              </div>
            );
          },
        },
      ];
  };

  mapDraftReports = () => {
    const {
      reports: { draftReports },
    } = this.props;
    return draftReports.map(dr => ({
      ...dr,
      key: dr._id,
    }));
  };

  mapFiledReports = () => {
    const {
      reports: { filedReports },
    } = this.props;
    return filedReports.map(fr => ({
      ...fr,
      key: fr._id,
    }));
  };

  onRenderRow = props => {
    return (
      <div onClick={() => this.onHandleRowSelection(props.item._id)}>
        <DetailsRow {...props} />
      </div>
    );
  };

  onHandleRowSelection = id => {
    this.props.history.push(`/filer/editFECReport/${id}?reportType=48HourNotice`);
  };

  render() {
    const {
      props: { reports, campaign },
      state: { createReportDialogHidden, is48Hour },
    } = this;

    return (
      <Fragment>
        <BackButton history={this.props.history} />
        <div className="ViewAllReports">
          <div className="working-reports depth-1">
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <h3>Working Reports</h3>
              {this.state.frCampaignType === frCampaignTypes.FederalCandidate &&
              <ActionButton
                iconProps={{ iconName: 'Plus' }}
                onClick={() => this.openCreateReportDialog(true)}
                text="Create New 48 Hour Notice Report"
              />
              }
              {this.state.frCampaignType === frCampaignTypes.FederalNonCandidate &&
              <ActionButton
                iconProps={{ iconName: 'PlusCircle' }}
                menuIconProps={{
                  iconName: 'CaretDown',
                }}
                menuProps={{
                  items: [
                    {
                      key: 'report-24',
                      text: '24-Hour Report',
                      onClick: () => {
                        this.openCreateReportDialog(false);
                      },
                      iconProps: {
                        iconName: 'Plus',
                      },
                    },
                    {
                      key: 'report-48',
                      text: '48-Hour Report',
                      onClick: () => {
                        this.openCreateReportDialog(true);
                      },
                      iconProps: {
                        iconName: 'Plus',
                      },
                    },
                  ],
                }}
                text="Create New Report"
              />
              }
            </div>
            {reports.draftReports.length === 0 && (
              <div className="no-filed-reports">
                <Icon
                  iconName="FileImport"
                  style={{ fontSize: 60, color: '#99999999' }}
                />
                <p>
                  No 48 hour notice reports created or in progress for this
                  campaign.
                </p>
              </div>
            )}
            {reports.draftReports.length > 0 && (
              <DetailsList
                items={this.mapDraftReports()}
                columns={this.createColumnsDraft()}
                checkboxVisibility={CheckboxVisibility.none}
                compact={false}
                selectionMode={SelectionMode.none}
                layoutMode={DetailsListLayoutMode.justified}
                onRenderRow={this.onRenderRow}
              />
            )}
          </div>
          <div className="filed-reports depth-1">
            <h3 style={{ marginBottom: 16 }}>Filed Reports</h3>
            {reports.filedReports.length === 0 && (
              <div className="no-filed-reports">
                <Icon
                  iconName="FileExport"
                  style={{ fontSize: 60, color: '#99999999' }}
                />
                <p>No 48 hour notice reports filed for this campaign.</p>
              </div>
            )}
            {reports.filedReports.length > 0 && (
              <DetailsList
                items={this.mapFiledReports()}
                columns={this.createFiledColumns()}
                checkboxVisibility={CheckboxVisibility.none}
                compact={false}
                selectionMode={SelectionMode.none}
                layoutMode={DetailsListLayoutMode.justified}
              />
            )}
          </div>
        </div>
        <AddFederalReportDialog
          dialogHidden={createReportDialogHidden}
          closeDialog={this.closeCreateReportDialog}
          createReport={this.create48HourNoticeReport}
          campaign={campaign}
          reportType={is48Hour ? '48HourNotice' : '24HourNotice'}
        />
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    campaign: state.currentCampaign.campaign,
    reports: state.reports,
    session: state.user.session,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    reportActions: bindActionCreators(reportActions, dispatch),
    currentReportActions: bindActionCreators(currentReportActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Form6Form24ViewAllReports),
);
