import {
  CheckboxVisibility,
  DefaultButton,
  DetailsList,
  Dropdown,
  Icon,
  PrimaryButton,
  SelectionMode,
  Spinner,
  Stack,
  Sticky,
  StickyPositionType,
} from 'office-ui-fabric-react';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { CaretDown } from '../icons';
import './index.css';

const CustomizedImport = ({
  rows,
  referenceColumns,
  columns,
  requiredColumns,
  isMapping,
  isViewOnly,
  additionalColumns,
  defaultSelectedColumns,
  onMapColumns,
  onBack,
}) => {
  const [selectedColumns, setSelectedColumns] = useState(
    defaultSelectedColumns,
  );

  const columnOptions = columns.map(({ key, label: text }) => ({
    key,
    text,
    required: requiredColumns.includes(key),
    disabled: Boolean(
      selectedColumns.find(selectedColumn => selectedColumn.key === key),
    ),
  }));

  const checkSelectedColumn = index =>
    selectedColumns.find(selectedColumn => selectedColumn.index === index);

  const handleSelectChange = index => (event, option) => {
    const isSelected = checkSelectedColumn(index);
    const updatedSelectedColumn = {
      index,
      key: option.key,
    };
    const updatedSelectedColumns = isSelected
      ? selectedColumns.map(column =>
          column.index === index ? updatedSelectedColumn : column,
        )
      : [...selectedColumns, updatedSelectedColumn];

    setSelectedColumns(updatedSelectedColumns);
  };

  const getCurrentSelectedKey = index =>
    columnOptions.find(
      option => option.key === checkSelectedColumn(index)?.key,
    )?.key;

  const handleClear = index => event => {
    event.stopPropagation();
    const updatedSelectedColumns = selectedColumns.filter(
      ({ index: columnIndex }) => index !== columnIndex,
    );
    setSelectedColumns(updatedSelectedColumns);
  };

  const handleRenderCaretDown = (index, isSelected, isDisabled) => () => (
    <Stack horizontal verticalAlign="center">
      {isSelected && !isDisabled && (
        <Icon
          iconName="Cancel"
          styles={{ root: { paddingRight: '.7em' } }}
          onClick={handleClear(index)}
        />
      )}
      <CaretDown />
    </Stack>
  );

  const handleRenderOption = option => {
    return (
      <div>
        <span>{option?.text}</span>
        {option?.required && (
          <span className="CustomizedImport__DropdownRequiredOption">
            &nbsp;*
          </span>
        )}
      </div>
    );
  };

  const handleRenderColumnHeaderTooltip = ({ column }) => {
    const { key: index } = column;
    const selectedKey = getCurrentSelectedKey(index);

    return typeof index === 'number' ? (
      <div>
        <Dropdown
          selectedKey={selectedKey ?? null}
          onChange={handleSelectChange(index)}
          placeholder="Select column"
          options={columnOptions}
          onRenderCaretDown={handleRenderCaretDown(index, Boolean(selectedKey), isViewOnly)}
          onRenderOption={handleRenderOption}
          disabled={isViewOnly}
        />
      </div>
    ) : null;
  };

  const isBttnDisabled =
    !selectedColumns.length ||
    requiredColumns.some(
      requiredColumn =>
        !selectedColumns.find(({ key }) => key === requiredColumn),
    );

  const handleRenderDetailsHeader = (headerProps, defaultRender) => (
    <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced>
      <div>
        {defaultRender({
          ...headerProps,
          onRenderColumnHeaderTooltip: handleRenderColumnHeaderTooltip,
          styles: { root: { fontSize: '1rem' } },
        })}
        {defaultRender({ ...headerProps, styles: { root: { paddingTop: 0 } } })}
      </div>
    </Sticky>
  );

  const handleMapColumns = () => {
    onMapColumns(selectedColumns);
  };

  const columnsConfig = [
    ...additionalColumns,
    ...referenceColumns.map((name, i) => ({
      key: i,
      name,
      minWidth: 250,
      fieldName: String(i),
    })),
  ];

  return (
    <div className="CustomizedImport">
      <div className="customized-import-table">
        <DetailsList
          items={rows.map(({ values, ...rest }) => ({
            ...rest,
            ...Object.fromEntries(Object.entries(values)),
          }))}
          columns={columnsConfig}
          compact={false}
          selectionMode={SelectionMode.none}
          checkboxVisibility={CheckboxVisibility.none}
          onRenderDetailsHeader={handleRenderDetailsHeader}
        />
      </div>
      <div className="customized-import-footer">
        <div>
          <p className="CustomizedImport__DropdownRequiredOption">
            * - Indicates required fields
          </p>
          {!isViewOnly && (
            <p>
              {`${
                selectedColumns.length
              } columns will be imported, ${referenceColumns.length -
                selectedColumns.length} columns won\`t be imported`}
            </p>
          )}
        </div>
        {!isViewOnly && (
          <div className="customized-import-bttn-container">
            {Boolean(onBack) && (
              <DefaultButton
                text="Back"
                onClick={onBack}
                disabled={isMapping}
              />
            )}
            <PrimaryButton
              text="Map columns"
              onClick={handleMapColumns}
              disabled={isBttnDisabled || isMapping}
            />
            {isMapping && <Spinner />}
          </div>
        )}
      </div>
    </div>
  );
};

CustomizedImport.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  referenceColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  requiredColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
  isMapping: PropTypes.bool,
  isViewOnly: PropTypes.bool,
  onMapColumns: PropTypes.func,
  onBack: PropTypes.func,
  additionalColumns: PropTypes.arrayOf(PropTypes.object),
  defaultSelectedColumns: PropTypes.arrayOf(PropTypes.object),
};

CustomizedImport.defaultProps = {
  isMapping: false,
  isViewOnly: false,
  additionalColumns: [],
  defaultSelectedColumns: [],
  onMapColumns: () => null,
};

export default CustomizedImport;
