import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Dropdown } from 'office-ui-fabric-react/lib/Dropdown';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import { CaretDown } from '../icons';

export const TagPicker = ({
  selectedKey,
  onChange,
  errorMessage,
  label,
  required,
}) => {
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState([true]);

  const getOptions = async () => {
    const { data: tags } = await axios.get('/api/filer/accounting/lists/tagOptions');
    setTags(tags.map(t => t.tag).filter(t => Boolean(t)));
    setLoading(false);
  };

  useEffect(() => {
    getOptions();
  }, []);

  if (loading) {
    return <Spinner size={SpinnerSize.medium} />;
  }

  return (
    <Dropdown
      onRenderCaretDown={() => <CaretDown />}
      label={label}
      required={required}
      selectedKey={selectedKey}
      errorMessage={errorMessage}
      placeholder="Select"
      dropdownWidth={400}
      options={[
        {
          key: 0,
          text: 'Select',
        },
        ...tags.map(tag => ({
          key: tag,
          text: tag,
        }))
          .sort((a, b) => a.text.localeCompare(b.text)),
      ]}
      onChange={onChange}
    />
  );
};

TagPicker.propTypes = {
  selectedKey: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
};

TagPicker.defaultProps = {
  selectedKey: 0,
  errorMessage: '',
  label: 'Source Type',
  required: false,
};

export default TagPicker;
