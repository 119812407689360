import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from 'office-ui-fabric-react/lib/TextField';

const AggregateAmountRangeFilter = ({
  handleChange,
  startAggregateAmount,
  endAggregateAmount,
  errors,
}) => {
  return (
    <div>
      <TextField
        label="Aggregate greater or equal to"
        onChange={handleChange('startAggregateAmount')}
        value={startAggregateAmount}
        errorMessage={errors.startAggregateAmount}
      />
      <TextField
        label="Aggregate less or equal to"
        onChange={handleChange('endAggregateAmount')}
        value={endAggregateAmount}
        errorMessage={errors.endAggregateAmount}
      />
    </div>
  );
};

AggregateAmountRangeFilter.propTypes = {
  startAggregateAmount: PropTypes.number,
  endAggregateAmount: PropTypes.number,
  handleChange: PropTypes.func.isRequired,
  errors: PropTypes.object,
};

export default AggregateAmountRangeFilter;
