import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { CommitteeTypePicker } from '../CommitteeTypePicker';
import { GridRow, Column } from '../../common';

const CommitteeEntity = ({
  committeeFecId = {},
  businessName = {},
  committeeAffiliation = {},
  contactName = {},
  onChange,
  contactType = '',
}) => {
  const isFECEntity = ['PAC'].includes(contactType);
  return (
    <GridRow>
      <Column md={isFECEntity ? 3 : 4}>
        <TextField
          value={businessName.value}
          label={businessName.label}
          required={businessName.required}
          onChange={onChange(businessName.field)}
          errorMessage={businessName.errorMessage}
        />
      </Column>
      <Column md={isFECEntity ? 3 : 4}>
        <CommitteeTypePicker
          selectedKey={committeeAffiliation.value}
          label={committeeAffiliation.label}
          errorMessage={committeeAffiliation.errorMessage}
          required={committeeAffiliation.required}
          onChange={onChange(committeeAffiliation.field)}
        />
      </Column>
      <Column md={isFECEntity ? 3 : 4}>
        <TextField
          label={contactName.label}
          value={contactName.value}
          onChange={onChange(contactName.field)}
          required={contactName.required}
          errorMessage={contactName.errorMessage}
        />
      </Column>
      {isFECEntity && (
        <Column md={3}>
          <TextField
            value={committeeFecId.value}
            label={committeeFecId.label}
            required={committeeFecId.required}
            errorMessage={committeeFecId.errorMessage}
            onChange={onChange(committeeFecId.field)}
          />
        </Column>
      )}
    </GridRow>
  );
};

CommitteeEntity.propTypes = {
  committeeFecId: PropTypes.object.isRequired,
  businessName: PropTypes.object.isRequired,
  committeeAffiliation: PropTypes.object.isRequired,
  contactName: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  contactType: PropTypes.string,
};

export default CommitteeEntity;
