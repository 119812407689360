import { types } from '../actions/depositActions';
import { createReducer } from './createReducer';
import { statuses } from '../helpers/constants';

export const initialState = {
  getListStatus: statuses.NOT_STARTED,
  list: [],
  singleDeposit: null,
  singleDepositForTicket: null,
  undeposited: [],
  uploadDepositFileStatus: statuses.NOT_STARTED,
  uploadResult: null,
  undepositedTotal: 0,
  getUndepositedTotalStatus: statuses.NOT_STARTED,
};

export const actionMap = {
  [types.GET_DEPOSIT_LIST_PROCESSING]: state => ({
    ...state,
    getListStatus: statuses.PROCESSING,
  }),
  [types.GET_DEPOSIT_LIST_SUCCESS]: (
    state,
    { data: { deposits, undeposited } },
  ) => ({
    ...state,
    list: deposits,
    getListStatus: statuses.SUCCESS,
    undeposited,
  }),
  [types.GET_SINGLE_DEPOSIT_SUCCESS]: (
    state,
    { data: { deposit, undeposited } },
  ) => ({
    ...state,
    singleDeposit: deposit,
    undeposited,
  }),
  [types.GET_SINGLE_DEPOSIT_FOR_TICKET_SUCCESS]: (state, action) => {
    return {
      ...state,
      singleDepositForTicket: action.data.deposit,
    };
  },
  [types.CLEAR_SINGLE_DEPOSIT]: state => {
    return {
      ...state,
      singleDeposit: null,
    };
  },
  [types.CLEAR_SINGLE_DEPOSIT_FOR_TICKET]: state => {
    return {
      ...state,
      singleDepositForTicket: null,
    };
  },
  [types.CREATE_DEPOSIT_SUCCESS]: (state, action) => {
    return {
      ...state,
      list: [...state.list, action.data.deposit],
    };
  },
  [types.UPDATE_DEPOSIT_SUCCESS]: (state, action) => {
    return {
      ...state,
      list: state.list.map(d => {
        if (d._id === action.data._id) {
          d = { ...d, ...action.data.payload };
        }
        return d;
      }),
      singleDeposit: null,
    };
  },
  [types.DELETE_DEPOSIT_SUCCESS]: (state, action) => {
    return {
      ...initialState,
      list: state.list.filter(d => d._id !== action.data.id),
      getListStatus: statuses.NOT_STARTED,
    };
  },
  [types.CLEAR_DEPOSITS]: () => {
    return { ...initialState };
  },
  [types.GET_ALL_UNDEPOSITED_SUCCESS]: (state, { data: { transactions } }) => ({
    ...state,
    undeposited: transactions,
  }),
  [types.GET_UNDEPOSITED_TOTAL_SUCCESS]: (state, { data: { undepositedTotal } }) => ({
    ...state,
    undepositedTotal,
    getUndepositedTotalStatus: statuses.SUCCESS,
  }),
  [types.UPLOAD_DEPOSIT_SPREADSHEET_PROCESSING]: state => ({
    ...state,
    uploadDepositFileStatus: statuses.PROCESSING,
  }),
  [types.UPLOAD_DEPOSIT_SPREADSHEET_SUCCESS]: (state, { data: channelResult }) => ({
    ...state,
    uploadDepositFileStatus: statuses.SUCCESS,
    uploadResult: channelResult,
  }),
  [types.UPLOAD_DEPOSIT_SPREADSHEET_FAILURE]: state => ({
    ...state,
    uploadDepositFileStatus: statuses.ERROR,
  }),
  [types.UPLOAD_DEPOSIT_SPREADSHEET_NOT_STARTED]: state => ({
    ...state,
    uploadDepositFileStatus: statuses.NOT_STARTED,
  }),
};

export const depositReducer = createReducer(initialState, actionMap);
