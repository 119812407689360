const federalOffices = {
  id: 1,
  name: 'Federal',
  states: {
    AL: {
      value: 'AL',
      name: 'Alabama',
      offices: {
        1: {
          id: 1,
          name: 'United States Senate',
        },
        2: {
          id: 2,
          name: 'United States House of Representatives',
          suboffices: {
            1: { id: 1, name: 'District 1' },
            2: { id: 2, name: 'District 2' },
            3: { id: 3, name: 'District 3' },
            4: { id: 4, name: 'District 4' },
            5: { id: 5, name: 'District 5' },
            6: { id: 6, name: 'District 6' },
            7: { id: 7, name: 'District 7' },
          },
        },
      },
    },
    AK: {
      value: 'AK',
      name: 'Alaska',
      offices: {
        1: {
          id: 1,
          name: 'United States Senate',
        },
        2: {
          id: 2,
          name: 'United States House of Representatives',
          suboffices: {
            1: { id: 1, name: 'At Large' },
          },
        },
      },
    },
    AZ: {
      value: 'AZ',
      name: 'Arizona',
      offices: {
        1: {
          id: 1,
          name: 'United States Senate',
        },
        2: {
          id: 2,
          name: 'United States House of Representatives',
          suboffices: {
            1: { id: 1, name: 'District 1' },
            2: { id: 2, name: 'District 2' },
            3: { id: 3, name: 'District 3' },
            4: { id: 4, name: 'District 4' },
            5: { id: 5, name: 'District 5' },
            6: { id: 6, name: 'District 6' },
            7: { id: 7, name: 'District 7' },
            8: { id: 8, name: 'District 8' },
            9: { id: 9, name: 'District 9' },
          },
        },
      },
    },
    AR: {
      value: 'AR',
      name: 'Arkansas',
      offices: {
        1: {
          id: 1,
          name: 'United States Senate',
        },
        2: {
          id: 2,
          name: 'United States House of Representatives',
          suboffices: {
            1: { id: 1, name: 'District 1' },
            2: { id: 2, name: 'District 2' },
            3: { id: 3, name: 'District 3' },
            4: { id: 4, name: 'District 4' },
          },
        },
      },
    },
    CA: {
      value: 'CA',
      name: 'California',
      offices: {
        1: {
          id: 1,
          name: 'United States Senate',
        },
        2: {
          id: 2,
          name: 'United States House of Representatives',
          suboffices: {
            1: { id: 1, name: 'District 1' },
            2: { id: 2, name: 'District 2' },
            3: { id: 3, name: 'District 3' },
            4: { id: 4, name: 'District 4' },
            5: { id: 5, name: 'District 5' },
            6: { id: 6, name: 'District 6' },
            7: { id: 7, name: 'District 7' },
            8: { id: 8, name: 'District 8' },
            9: { id: 9, name: 'District 9' },
            10: { id: 10, name: 'District 10' },
            11: { id: 11, name: 'District 11' },
            12: { id: 12, name: 'District 12' },
            13: { id: 13, name: 'District 13' },
            14: { id: 14, name: 'District 14' },
            15: { id: 15, name: 'District 15' },
            16: { id: 16, name: 'District 16' },
            17: { id: 17, name: 'District 17' },
            18: { id: 18, name: 'District 18' },
            19: { id: 19, name: 'District 19' },
            20: { id: 20, name: 'District 20' },
            21: { id: 21, name: 'District 21' },
            22: { id: 22, name: 'District 22' },
            23: { id: 23, name: 'District 23' },
            24: { id: 24, name: 'District 24' },
            25: { id: 25, name: 'District 25' },
            26: { id: 26, name: 'District 26' },
            27: { id: 27, name: 'District 27' },
            28: { id: 28, name: 'District 28' },
            29: { id: 29, name: 'District 29' },
            30: { id: 30, name: 'District 30' },
            31: { id: 31, name: 'District 31' },
            32: { id: 32, name: 'District 32' },
            33: { id: 33, name: 'District 33' },
            34: { id: 34, name: 'District 34' },
            35: { id: 35, name: 'District 35' },
            36: { id: 36, name: 'District 36' },
            37: { id: 37, name: 'District 37' },
            38: { id: 38, name: 'District 38' },
            39: { id: 39, name: 'District 39' },
            40: { id: 40, name: 'District 40' },
            41: { id: 41, name: 'District 41' },
            42: { id: 42, name: 'District 42' },
            43: { id: 43, name: 'District 43' },
            44: { id: 44, name: 'District 44' },
            45: { id: 45, name: 'District 45' },
            46: { id: 46, name: 'District 46' },
            47: { id: 47, name: 'District 47' },
            48: { id: 48, name: 'District 48' },
            49: { id: 49, name: 'District 49' },
            50: { id: 50, name: 'District 50' },
            51: { id: 51, name: 'District 51' },
            52: { id: 52, name: 'District 52' },
            53: { id: 53, name: 'District 53' },
          },
        },
      },
    },
    CO: {
      value: 'CO',
      name: 'Colorado',
      offices: {
        1: {
          id: 1,
          name: 'United States Senate',
        },
        2: {
          id: 2,
          name: 'United States House of Representatives',
          suboffices: {
            1: { id: 1, name: 'District 1' },
            2: { id: 2, name: 'District 2' },
            3: { id: 3, name: 'District 3' },
            4: { id: 4, name: 'District 4' },
            5: { id: 5, name: 'District 5' },
            6: { id: 6, name: 'District 6' },
            7: { id: 7, name: 'District 7' },
          },
        },
      },
    },
    CT: {
      value: 'CT',
      name: 'Connecticut',
      offices: {
        1: {
          id: 1,
          name: 'United States Senate',
        },
        2: {
          id: 2,
          name: 'United States House of Representatives',
          suboffices: {
            1: { id: 1, name: 'District 1' },
            2: { id: 2, name: 'District 2' },
            3: { id: 3, name: 'District 3' },
            4: { id: 4, name: 'District 4' },
            5: { id: 5, name: 'District 5' },
          },
        },
      },
    },
    DE: {
      value: 'DE',
      name: 'Delaware',
      offices: {
        1: {
          id: 1,
          name: 'United States Senate',
        },
        2: {
          id: 2,
          name: 'United States House of Representatives',
          suboffices: {
            1: { id: 1, name: 'At Large' },
          },
        },
      },
    },
    FL: {
      value: 'FL',
      name: 'Florida',
      offices: {
        1: {
          id: 1,
          name: 'United States Senate',
        },
        2: {
          id: 2,
          name: 'United States House of Representatives',
          suboffices: {
            1: { id: 1, name: 'District 1' },
            2: { id: 2, name: 'District 2' },
            3: { id: 3, name: 'District 3' },
            4: { id: 4, name: 'District 4' },
            5: { id: 5, name: 'District 5' },
            6: { id: 6, name: 'District 6' },
            7: { id: 7, name: 'District 7' },
            8: { id: 8, name: 'District 8' },
            9: { id: 9, name: 'District 9' },
            10: { id: 10, name: 'District 10' },
            11: { id: 11, name: 'District 11' },
            12: { id: 12, name: 'District 12' },
            13: { id: 13, name: 'District 13' },
            14: { id: 14, name: 'District 14' },
            15: { id: 15, name: 'District 15' },
            16: { id: 16, name: 'District 16' },
            17: { id: 17, name: 'District 17' },
            18: { id: 18, name: 'District 18' },
            19: { id: 19, name: 'District 19' },
            20: { id: 20, name: 'District 20' },
            21: { id: 21, name: 'District 21' },
            22: { id: 22, name: 'District 22' },
            23: { id: 23, name: 'District 23' },
            24: { id: 24, name: 'District 24' },
            25: { id: 25, name: 'District 25' },
            26: { id: 26, name: 'District 26' },
            27: { id: 27, name: 'District 27' },
          },
        },
      },
    },
    GA: {
      value: 'GA',
      name: 'Georgia',
      offices: {
        1: {
          id: 1,
          name: 'United States Senate',
        },
        2: {
          id: 2,
          name: 'United States House of Representatives',
          suboffices: {
            1: { id: 1, name: '1st District' },
            2: { id: 2, name: '2nd District' },
            3: { id: 3, name: '3rd District' },
            4: { id: 4, name: '4th District' },
            5: { id: 5, name: '5th District' },
            6: { id: 6, name: '6th District' },
            7: { id: 7, name: '7th District' },
            8: { id: 8, name: '8th District' },
            9: { id: 9, name: '9th District' },
            10: { id: 10, name: '10th District' },
            11: { id: 11, name: '11th District' },
            12: { id: 12, name: '12th District' },
            13: { id: 13, name: '13th District' },
            14: { id: 14, name: '14th District' },
          },
        },
      },
    },
    HI: {
      value: 'HI',
      name: 'Hawaii',
      offices: {
        1: {
          id: 1,
          name: 'United States Senate',
        },
        2: {
          id: 2,
          name: 'United States House of Representatives',
          suboffices: {
            1: { id: 1, name: '1st District' },
            2: { id: 2, name: '2nd District' },
          },
        },
      },
    },
    ID: {
      value: 'ID',
      name: 'Idaho',
      offices: {
        1: {
          id: 1,
          name: 'United States Senate',
        },
        2: {
          id: 2,
          name: 'United States House of Representatives',
          suboffices: {
            1: { id: 1, name: '1st District' },
            2: { id: 2, name: '2nd District' },
          },
        },
      },
    },
    IL: {
      value: 'IL',
      name: 'Illinois',
      offices: {
        1: {
          id: 1,
          name: 'United States Senate',
        },
        2: {
          id: 2,
          name: 'United States House of Representatives',
          suboffices: {
            1: { id: 1, name: 'District 1' },
            2: { id: 2, name: 'District 2' },
            3: { id: 3, name: 'District 3' },
            4: { id: 4, name: 'District 4' },
            5: { id: 5, name: 'District 5' },
            6: { id: 6, name: 'District 6' },
            7: { id: 7, name: 'District 7' },
            8: { id: 8, name: 'District 8' },
            9: { id: 9, name: 'District 9' },
            10: { id: 10, name: 'District 10' },
            11: { id: 11, name: 'District 11' },
            12: { id: 12, name: 'District 12' },
            13: { id: 13, name: 'District 13' },
            14: { id: 14, name: 'District 14' },
            15: { id: 15, name: 'District 15' },
            16: { id: 16, name: 'District 16' },
            17: { id: 17, name: 'District 17' },
            18: { id: 18, name: 'District 18' },
          },
        },
      },
    },
    IN: {
      value: 'IN',
      name: 'Indiana',
      offices: {
        1: {
          id: 1,
          name: 'United States Senate',
        },
        2: {
          id: 2,
          name: 'United States House of Representatives',
          suboffices: {
            1: { id: 1, name: 'District 1' },
            2: { id: 2, name: 'District 2' },
            3: { id: 3, name: 'District 3' },
            4: { id: 4, name: 'District 4' },
            5: { id: 5, name: 'District 5' },
            6: { id: 6, name: 'District 6' },
            7: { id: 7, name: 'District 7' },
            8: { id: 8, name: 'District 8' },
            9: { id: 9, name: 'District 9' },
          },
        },
      },
    },
    IA: {
      value: 'IA',
      name: 'Iowa',
      offices: {
        1: {
          id: 1,
          name: 'United States Senate',
        },
        2: {
          id: 2,
          name: 'United States House of Representatives',
          suboffices: {
            1: { id: 1, name: 'District 1' },
            2: { id: 2, name: 'District 2' },
            3: { id: 3, name: 'District 3' },
            4: { id: 4, name: 'District 4' },
          },
        },
      },
    },
    KS: {
      value: 'KS',
      name: 'Kansas',
      offices: {
        1: {
          id: 1,
          name: 'United States Senate',
        },
        2: {
          id: 2,
          name: 'United States House of Representatives',
          suboffices: {
            1: { id: 1, name: 'District 1' },
            2: { id: 2, name: 'District 2' },
            3: { id: 3, name: 'District 3' },
            4: { id: 4, name: 'District 4' },
          },
        },
      },
    },
    KY: {
      value: 'KY',
      name: 'Kentucky',
      offices: {
        1: {
          id: 1,
          name: 'United States Senate',
        },
        2: {
          id: 2,
          name: 'United States House of Representatives',
          suboffices: {
            1: { id: 1, name: 'District 1' },
            2: { id: 2, name: 'District 2' },
            3: { id: 3, name: 'District 3' },
            4: { id: 4, name: 'District 4' },
            5: { id: 5, name: 'District 5' },
            6: { id: 6, name: 'District 6' },
          },
        },
      },
    },
    LA: {
      value: 'LA',
      name: 'Louisiana',
      offices: {
        1: {
          id: 1,
          name: 'United States Senate',
        },
        2: {
          id: 2,
          name: 'United States House of Representatives',
          suboffices: {
            1: { id: 1, name: 'District 1' },
            2: { id: 2, name: 'District 2' },
            3: { id: 3, name: 'District 3' },
            4: { id: 4, name: 'District 4' },
            5: { id: 5, name: 'District 5' },
            6: { id: 6, name: 'District 6' },
          },
        },
      },
    },
    ME: {
      value: 'ME',
      name: 'Maine',
      offices: {
        1: {
          id: 1,
          name: 'United States Senate',
        },
        2: {
          id: 2,
          name: 'United States House of Representatives',
          suboffices: {
            1: { id: 1, name: 'District 1' },
            2: { id: 2, name: 'District 2' },
          },
        },
      },
    },
    MD: {
      value: 'MD',
      name: 'Maryland',
      offices: {
        1: {
          id: 1,
          name: 'United States Senate',
        },
        2: {
          id: 2,
          name: 'United States House of Representatives',
          suboffices: {
            1: { id: 1, name: 'District 1' },
            2: { id: 2, name: 'District 2' },
            3: { id: 3, name: 'District 3' },
            4: { id: 4, name: 'District 4' },
            5: { id: 5, name: 'District 5' },
            6: { id: 6, name: 'District 6' },
            7: { id: 7, name: 'District 7' },
            8: { id: 8, name: 'District 8' },
          },
        },
      },
    },
    MA: {
      value: 'MA',
      name: 'Massachusetts',
      offices: {
        1: {
          id: 1,
          name: 'United States Senate',
        },
        2: {
          id: 2,
          name: 'United States House of Representatives',
          suboffices: {
            1: { id: 1, name: 'District 1' },
            2: { id: 2, name: 'District 2' },
            3: { id: 3, name: 'District 3' },
            4: { id: 4, name: 'District 4' },
            5: { id: 5, name: 'District 5' },
            6: { id: 6, name: 'District 6' },
            7: { id: 7, name: 'District 7' },
            8: { id: 8, name: 'District 8' },
            9: { id: 9, name: 'District 9' },
          },
        },
      },
    },
    MI: {
      value: 'MI',
      name: 'Michigan',
      offices: {
        1: {
          id: 1,
          name: 'United States Senate',
        },
        2: {
          id: 2,
          name: 'United States House of Representatives',
          suboffices: {
            1: { id: 1, name: 'District 1' },
            2: { id: 2, name: 'District 2' },
            3: { id: 3, name: 'District 3' },
            4: { id: 4, name: 'District 4' },
            5: { id: 5, name: 'District 5' },
            6: { id: 6, name: 'District 6' },
            7: { id: 7, name: 'District 7' },
            8: { id: 8, name: 'District 8' },
            9: { id: 9, name: 'District 9' },
            10: { id: 10, name: 'District 10' },
            11: { id: 11, name: 'District 11' },
            12: { id: 12, name: 'District 12' },
            13: { id: 13, name: 'District 13' },
            14: { id: 14, name: 'District 14' },
          },
        },
      },
    },
    MN: {
      value: 'MN',
      name: 'Minnesota',
      offices: {
        1: {
          id: 1,
          name: 'United States Senate',
        },
        2: {
          id: 2,
          name: 'United States House of Representatives',
          suboffices: {
            1: { id: 1, name: 'District 1' },
            2: { id: 2, name: 'District 2' },
            3: { id: 3, name: 'District 3' },
            4: { id: 4, name: 'District 4' },
            5: { id: 5, name: 'District 5' },
            6: { id: 6, name: 'District 6' },
            7: { id: 7, name: 'District 7' },
            8: { id: 8, name: 'District 8' },
          },
        },
      },
    },
    MS: {
      value: 'MS',
      name: 'Mississippi',
      offices: {
        1: {
          id: 1,
          name: 'United States Senate',
        },
        2: {
          id: 2,
          name: 'United States House of Representatives',
          suboffices: {
            1: { id: 1, name: 'District 1' },
            2: { id: 2, name: 'District 2' },
            3: { id: 3, name: 'District 3' },
            4: { id: 4, name: 'District 4' },
          },
        },
      },
    },
    MO: {
      value: 'MO',
      name: 'Missouri',
      offices: {
        1: {
          id: 1,
          name: 'United States Senate',
        },
        2: {
          id: 2,
          name: 'United States House of Representatives',
          suboffices: {
            1: { id: 1, name: 'District 1' },
            2: { id: 2, name: 'District 2' },
            3: { id: 3, name: 'District 3' },
            4: { id: 4, name: 'District 4' },
            5: { id: 5, name: 'District 5' },
            6: { id: 6, name: 'District 6' },
            7: { id: 7, name: 'District 7' },
            8: { id: 8, name: 'District 8' },
          },
        },
      },
    },
    MT: {
      value: 'MT',
      name: 'Montana',
      offices: {
        1: {
          id: 1,
          name: 'United States Senate',
        },
        2: {
          id: 2,
          name: 'United States House of Representatives',
          suboffices: {
            1: { id: 1, name: 'At Large' },
          },
        },
      },
    },
    NE: {
      value: 'NE',
      name: 'Nebraska',
      offices: {
        1: {
          id: 1,
          name: 'United States Senate',
        },
        2: {
          id: 2,
          name: 'United States House of Representatives',
          suboffices: {
            1: { id: 1, name: 'District 1' },
            2: { id: 2, name: 'District 2' },
            3: { id: 3, name: 'District 3' },
          },
        },
      },
    },
    NV: {
      value: 'NV',
      name: 'Nevada',
      offices: {
        1: {
          id: 1,
          name: 'United States Senate',
        },
        2: {
          id: 2,
          name: 'United States House of Representatives',
          suboffices: {
            1: { id: 1, name: 'District 1' },
            2: { id: 2, name: 'District 2' },
            3: { id: 3, name: 'District 3' },
            4: { id: 4, name: 'District 4' },
          },
        },
      },
    },
    NH: {
      value: 'NH',
      name: 'New Hampshire',
      offices: {
        1: {
          id: 1,
          name: 'United States Senate',
        },
        2: {
          id: 2,
          name: 'United States House of Representatives',
          suboffices: {
            1: { id: 1, name: 'District 1' },
            2: { id: 2, name: 'District 2' },
          },
        },
      },
    },
    NJ: {
      value: 'NJ',
      name: 'New Jersey',
      offices: {
        1: {
          id: 1,
          name: 'United States Senate',
        },
        2: {
          id: 2,
          name: 'United States House of Representatives',
          suboffices: {
            1: { id: 1, name: 'District 1' },
            2: { id: 2, name: 'District 2' },
            3: { id: 3, name: 'District 3' },
            4: { id: 4, name: 'District 4' },
            5: { id: 5, name: 'District 5' },
            6: { id: 6, name: 'District 6' },
            7: { id: 7, name: 'District 7' },
            8: { id: 8, name: 'District 8' },
            9: { id: 9, name: 'District 9' },
            10: { id: 10, name: 'District 10' },
            11: { id: 11, name: 'District 11' },
            12: { id: 12, name: 'District 12' },
          },
        },
      },
    },
    NM: {
      value: 'NM',
      name: 'New Mexico',
      offices: {
        1: {
          id: 1,
          name: 'United States Senate',
        },
        2: {
          id: 2,
          name: 'United States House of Representatives',
          suboffices: {
            1: { id: 1, name: 'District 1' },
            2: { id: 2, name: 'District 2' },
            3: { id: 3, name: 'District 3' },
          },
        },
      },
    },
    NY: {
      value: 'NY',
      name: 'New York',
      offices: {
        1: {
          id: 1,
          name: 'United States Senate',
        },
        2: {
          id: 2,
          name: 'United States House of Representatives',
          suboffices: {
            1: { id: 1, name: 'District 1' },
            2: { id: 2, name: 'District 2' },
            3: { id: 3, name: 'District 3' },
            4: { id: 4, name: 'District 4' },
            5: { id: 5, name: 'District 5' },
            6: { id: 6, name: 'District 6' },
            7: { id: 7, name: 'District 7' },
            8: { id: 8, name: 'District 8' },
            9: { id: 9, name: 'District 9' },
            10: { id: 10, name: 'District 10' },
            11: { id: 11, name: 'District 11' },
            12: { id: 12, name: 'District 12' },
            13: { id: 13, name: 'District 13' },
            14: { id: 14, name: 'District 14' },
            15: { id: 15, name: 'District 15' },
            16: { id: 16, name: 'District 16' },
            17: { id: 17, name: 'District 17' },
            18: { id: 18, name: 'District 18' },
            19: { id: 19, name: 'District 19' },
            20: { id: 20, name: 'District 20' },
            21: { id: 21, name: 'District 21' },
            22: { id: 22, name: 'District 22' },
            23: { id: 23, name: 'District 23' },
            24: { id: 24, name: 'District 24' },
            25: { id: 25, name: 'District 25' },
            26: { id: 26, name: 'District 26' },
            27: { id: 27, name: 'District 27' },
          },
        },
      },
    },
    NC: {
      value: 'NC',
      name: 'North Carolina',
      offices: {
        1: {
          id: 1,
          name: 'United States Senate',
        },
        2: {
          id: 2,
          name: 'United States House of Representatives',
          suboffices: {
            1: { id: 1, name: 'District 1' },
            2: { id: 2, name: 'District 2' },
            3: { id: 3, name: 'District 3' },
            4: { id: 4, name: 'District 4' },
            5: { id: 5, name: 'District 5' },
            6: { id: 6, name: 'District 6' },
            7: { id: 7, name: 'District 7' },
            8: { id: 8, name: 'District 8' },
            9: { id: 9, name: 'District 9' },
            10: { id: 10, name: 'District 10' },
            11: { id: 11, name: 'District 11' },
            12: { id: 12, name: 'District 12' },
            13: { id: 13, name: 'District 13' },
          },
        },
      },
    },
    ND: {
      value: 'ND',
      name: 'North Dakota',
      offices: {
        1: {
          id: 1,
          name: 'United States Senate',
        },
        2: {
          id: 2,
          name: 'United States House of Representatives',
          suboffices: {
            1: { id: 1, name: 'At Large' },
          },
        },
      },
    },
    OH: {
      value: 'OH',
      name: 'Ohio',
      offices: {
        1: {
          id: 1,
          name: 'United States Senate',
        },
        2: {
          id: 2,
          name: 'United States House of Representatives',
          suboffices: {
            1: { id: 1, name: 'District 1' },
            2: { id: 2, name: 'District 2' },
            3: { id: 3, name: 'District 3' },
            4: { id: 4, name: 'District 4' },
            5: { id: 5, name: 'District 5' },
            6: { id: 6, name: 'District 6' },
            7: { id: 7, name: 'District 7' },
            8: { id: 8, name: 'District 8' },
            9: { id: 9, name: 'District 9' },
            10: { id: 10, name: 'District 10' },
            11: { id: 11, name: 'District 11' },
            12: { id: 12, name: 'District 12' },
            13: { id: 13, name: 'District 13' },
            14: { id: 14, name: 'District 14' },
            15: { id: 15, name: 'District 15' },
            16: { id: 16, name: 'District 16' },
          },
        },
      },
    },
    OK: {
      value: 'OK',
      name: 'Oklahoma',
      offices: {
        1: {
          id: 1,
          name: 'United States Senate',
        },
        2: {
          id: 2,
          name: 'United States House of Representatives',
          suboffices: {
            1: { id: 1, name: 'District 1' },
            2: { id: 2, name: 'District 2' },
            3: { id: 3, name: 'District 3' },
            4: { id: 4, name: 'District 4' },
            5: { id: 5, name: 'District 5' },
          },
        },
      },
    },
    OR: {
      value: 'OR',
      name: 'Oregon',
      offices: {
        1: {
          id: 1,
          name: 'United States Senate',
        },
        2: {
          id: 2,
          name: 'United States House of Representatives',
          suboffices: {
            1: { id: 1, name: 'District 1' },
            2: { id: 2, name: 'District 2' },
            3: { id: 3, name: 'District 3' },
            4: { id: 4, name: 'District 4' },
            5: { id: 5, name: 'District 5' },
          },
        },
      },
    },
    PA: {
      value: 'PA',
      name: 'Pennsylvania',
      offices: {
        1: {
          id: 1,
          name: 'United States Senate',
        },
        2: {
          id: 2,
          name: 'United States House of Representatives',
          suboffices: {
            1: { id: 1, name: 'District 1' },
            2: { id: 2, name: 'District 2' },
            3: { id: 3, name: 'District 3' },
            4: { id: 4, name: 'District 4' },
            5: { id: 5, name: 'District 5' },
            6: { id: 6, name: 'District 6' },
            7: { id: 7, name: 'District 7' },
            8: { id: 8, name: 'District 8' },
            9: { id: 9, name: 'District 9' },
            10: { id: 10, name: 'District 10' },
            11: { id: 11, name: 'District 11' },
            12: { id: 12, name: 'District 12' },
            13: { id: 13, name: 'District 13' },
            14: { id: 14, name: 'District 14' },
            15: { id: 15, name: 'District 15' },
            16: { id: 16, name: 'District 16' },
            17: { id: 17, name: 'District 17' },
            18: { id: 18, name: 'District 18' },
          },
        },
      },
    },
    RI: {
      value: 'RI',
      name: 'Rhode Island',
      offices: {
        1: {
          id: 1,
          name: 'United States Senate',
        },
        2: {
          id: 2,
          name: 'United States House of Representatives',
          suboffices: {
            1: { id: 1, name: 'District 1' },
            2: { id: 2, name: 'District 2' },
          },
        },
      },
    },
    SC: {
      value: 'SC',
      name: 'South Carolina',
      offices: {
        1: {
          id: 1,
          name: 'United States Senate',
        },
        2: {
          id: 2,
          name: 'United States House of Representatives',
          suboffices: {
            1: { id: 1, name: 'District 1' },
            2: { id: 2, name: 'District 2' },
            3: { id: 3, name: 'District 3' },
            4: { id: 4, name: 'District 4' },
            5: { id: 5, name: 'District 5' },
            6: { id: 6, name: 'District 6' },
            7: { id: 7, name: 'District 7' },
          },
        },
      },
    },
    SD: {
      value: 'SD',
      name: 'South Dakota',
      offices: {
        1: {
          id: 1,
          name: 'United States Senate',
        },
        2: {
          id: 2,
          name: 'United States House of Representatives',
          suboffices: {
            1: { id: 1, name: 'At Large' },
          },
        },
      },
    },
    TN: {
      value: 'TN',
      name: 'Tennessee',
      offices: {
        1: {
          id: 1,
          name: 'United States Senate',
        },
        2: {
          id: 2,
          name: 'United States House of Representatives',
          suboffices: {
            1: { id: 1, name: 'District 1' },
            2: { id: 2, name: 'District 2' },
            3: { id: 3, name: 'District 3' },
            4: { id: 4, name: 'District 4' },
            5: { id: 5, name: 'District 5' },
            6: { id: 6, name: 'District 6' },
            7: { id: 7, name: 'District 7' },
            8: { id: 8, name: 'District 8' },
            9: { id: 9, name: 'District 9' },
          },
        },
      },
    },
    TX: {
      value: 'TX',
      name: 'Texas',
      offices: {
        1: {
          id: 1,
          name: 'United States Senate',
        },
        2: {
          id: 2,
          name: 'United States House of Representatives',
          suboffices: {
            1: { id: 1, name: 'District 1' },
            2: { id: 2, name: 'District 2' },
            3: { id: 3, name: 'District 3' },
            4: { id: 4, name: 'District 4' },
            5: { id: 5, name: 'District 5' },
            6: { id: 6, name: 'District 6' },
            7: { id: 7, name: 'District 7' },
            8: { id: 8, name: 'District 8' },
            9: { id: 9, name: 'District 9' },
            10: { id: 10, name: 'District 10' },
            11: { id: 11, name: 'District 11' },
            12: { id: 12, name: 'District 12' },
            13: { id: 13, name: 'District 13' },
            14: { id: 14, name: 'District 14' },
            15: { id: 15, name: 'District 15' },
            16: { id: 16, name: 'District 16' },
            17: { id: 17, name: 'District 17' },
            18: { id: 18, name: 'District 18' },
            19: { id: 19, name: 'District 19' },
            20: { id: 20, name: 'District 20' },
            21: { id: 21, name: 'District 21' },
            22: { id: 22, name: 'District 22' },
            23: { id: 23, name: 'District 23' },
            24: { id: 24, name: 'District 24' },
            25: { id: 25, name: 'District 25' },
            26: { id: 26, name: 'District 26' },
            27: { id: 27, name: 'District 27' },
            28: { id: 28, name: 'District 28' },
            29: { id: 29, name: 'District 29' },
            30: { id: 30, name: 'District 30' },
            31: { id: 31, name: 'District 31' },
            32: { id: 32, name: 'District 32' },
            33: { id: 33, name: 'District 33' },
            34: { id: 34, name: 'District 34' },
            35: { id: 35, name: 'District 35' },
            36: { id: 36, name: 'District 36' },
          },
        },
      },
    },
    UT: {
      value: 'UT',
      name: 'Utah',
      offices: {
        1: {
          id: 1,
          name: 'United States Senate',
        },
        2: {
          id: 2,
          name: 'United States House of Representatives',
          suboffices: {
            1: { id: 1, name: 'District 1' },
            2: { id: 2, name: 'District 2' },
            3: { id: 3, name: 'District 3' },
            4: { id: 4, name: 'District 4' },
          },
        },
      },
    },
    VT: {
      value: 'VT',
      name: 'Vermont',
      offices: {
        1: {
          id: 1,
          name: 'United States Senate',
        },
        2: {
          id: 2,
          name: 'United States House of Representatives',
          suboffices: {
            1: { id: 1, name: 'At Large' },
          },
        },
      },
    },
    VA: {
      value: 'VA',
      name: 'Virginia',
      offices: {
        1: {
          id: 1,
          name: 'United States Senate',
        },
        2: {
          id: 2,
          name: 'United States House of Representatives',
          suboffices: {
            1: { id: 1, name: 'District 1' },
            2: { id: 2, name: 'District 2' },
            3: { id: 3, name: 'District 3' },
            4: { id: 4, name: 'District 4' },
            5: { id: 5, name: 'District 5' },
            6: { id: 6, name: 'District 6' },
            7: { id: 7, name: 'District 7' },
            8: { id: 8, name: 'District 8' },
            9: { id: 9, name: 'District 9' },
            10: { id: 10, name: 'District 10' },
            11: { id: 11, name: 'District 11' },
          },
        },
      },
    },
    WA: {
      value: 'WA',
      name: 'Washington',
      offices: {
        1: {
          id: 1,
          name: 'United States Senate',
        },
        2: {
          id: 2,
          name: 'United States House of Representatives',
          suboffices: {
            1: { id: 1, name: 'District 1' },
            2: { id: 2, name: 'District 2' },
            3: { id: 3, name: 'District 3' },
            4: { id: 4, name: 'District 4' },
            5: { id: 5, name: 'District 5' },
            6: { id: 6, name: 'District 6' },
            7: { id: 7, name: 'District 7' },
            8: { id: 8, name: 'District 8' },
            9: { id: 9, name: 'District 9' },
            10: { id: 10, name: 'District 10' },
          },
        },
      },
    },
    WV: {
      value: 'WV',
      name: 'West Virginia',
      offices: {
        1: {
          id: 1,
          name: 'United States Senate',
        },
        2: {
          id: 2,
          name: 'United States House of Representatives',
          suboffices: {
            1: { id: 1, name: 'District 1' },
            2: { id: 2, name: 'District 2' },
            3: { id: 3, name: 'District 3' },
          },
        },
      },
    },
    WI: {
      value: 'WI',
      name: 'Wisconsin',
      offices: {
        1: {
          id: 1,
          name: 'United States Senate',
        },
        2: {
          id: 2,
          name: 'United States House of Representatives',
          suboffices: {
            1: { id: 1, name: 'District 1' },
            2: { id: 2, name: 'District 2' },
            3: { id: 3, name: 'District 3' },
            4: { id: 4, name: 'District 4' },
            5: { id: 5, name: 'District 5' },
            6: { id: 6, name: 'District 6' },
            7: { id: 7, name: 'District 7' },
            8: { id: 8, name: 'District 8' },
          },
        },
      },
    },
    WY: {
      value: 'WY',
      name: 'Wyoming',
      offices: {
        1: {
          id: 1,
          name: 'United States Senate',
        },
        2: {
          id: 2,
          name: 'United States House of Representatives',
          suboffices: {
            1: { id: 1, name: 'At Large' },
          },
        },
      },
    },
  },
};

export default federalOffices;
