import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  DetailsList,
  CheckboxVisibility,
  SelectionMode,
} from 'office-ui-fabric-react/lib/DetailsList';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { Grid, GridRow, Column } from '../common';
import Loading from '../Loading';
import { statuses, sortDirections } from '../../helpers/constants';
import { formatDate, formatCurrency, sortByField } from '../../helpers/util';
import { getLabel } from '../../helpers/labelHelper';
import { onColumnClick } from '../../helpers/listsHelper';
import './ContactTransactions.css';

export class ContactTransactions extends Component {
  static propTypes = {
    transactions: PropTypes.object.isRequired,
    session: PropTypes.object.isRequired,
  };

  state = {
    filterText: '',
    sortDirection: sortDirections.NONE,
    sortField: '',
  };

  mapTransactions = () => {
    const { sortField, sortDirection, filterText } = this.state;
    const { transactions, session } = this.props;
    const searchText = filterText.toLowerCase();

    return [
      ...transactions.contributions.map(c => ({
        ...c,
        key: c._id,
        typeRender: (
          <span>
            {getLabel('Contribution', session)}
            <br />
            {c.contributionType}
          </span>
        ),
        type: 'Contribution',
        transactionDate: c.receivedDate,
      })),
      ...transactions.expenditures.map(e => ({
        ...e,
        key: e._id,
        typeRender: (
          <span>
            {getLabel('Expenditure', session)}
            <br />
            {e.expenditureType}
          </span>
        ),
        type: 'Expenditure',
        description: e.purpose,
        transactionDate: e.expenditureDate,
      })),
      ...transactions.loans.map(l => ({ ...l, key: l._id, type: 'Loan' })),
    ]
      .filter(
        t =>
          t.type.toLowerCase().includes(searchText) ||
          t.checkNumber.includes(searchText) ||
          t.paymentReferenceId.toLowerCase().includes(searchText) ||
          t.sourceCode.toLowerCase().includes(searchText),
      )
      .sort(sortByField(sortField, sortDirection));
  };

  createColumns = () => {
    const { sortField, sortDirection } = this.state;
    return [
      {
        key: 'receivedDate',
        name: 'Transaction Date',
        fieldName: 'transactionDate',
        isSorted:
          sortField === 'transactionDate' &&
          sortDirection !== sortDirections.NONE,
        isSortedDescending: sortDirection === sortDirections.DESC,
        onColumnClick: this.onColumnClick,
        onRender: item => formatDate(item.transactionDate),
      },
      {
        key: 'type',
        name: 'Type',
        fieldName: 'type',
        isSorted: sortField === 'type' && sortDirection !== sortDirections.NONE,
        isSortedDescending: sortDirection === sortDirections.DESC,
        onColumnClick: this.onColumnClick,
        onRender: item => item.typeRender,
      },
      {
        key: 'paymentType',
        name: 'Payment Type',
        fieldName: 'paymentType',
        isSorted:
          sortField === 'paymentType' && sortDirection !== sortDirections.NONE,
        isSortedDescending: sortDirection === sortDirections.DESC,
        onColumnClick: this.onColumnClick,
        onRender: item => item.paymentType || '',
      },
      {
        key: 'checkNumber',
        name: 'Check Number',
        fieldName: 'checkNumberSort',
        isSorted:
          sortField === 'checkNumberSort' &&
          sortDirection !== sortDirections.NONE,
        isSortedDescending: sortDirection === sortDirections.DESC,
        onColumnClick: this.onColumnClick,
        onRender: item => item.checkNumber,
      },
      {
        key: 'paymentReferenceId',
        name: 'Reference Id',
        fieldName: 'paymentReferenceId',
        isSorted:
          sortField === 'paymentReferenceId' &&
          sortDirection !== sortDirections.NONE,
        isSortedDescending: sortDirection === sortDirections.DESC,
        onColumnClick: this.onColumnClick,
        onRender: item => item.paymentReferenceId,
      },
      {
        key: 'sourceCode',
        name: 'Source Code',
        fieldName: 'sourceCode',
        isSorted:
          sortField === 'sourceCode' &&
          sortDirection !== sortDirections.NONE,
        isSortedDescending: sortDirection === sortDirections.DESC,
        onColumnClick: this.onColumnClick,
        onRender: item => item.sourceCode,
      },
      {
        key: 'amount',
        name: 'Amount',
        fieldName: 'amount',
        isSorted:
          sortField === 'amount' && sortDirection !== sortDirections.NONE,
        isSortedDescending: sortDirection === sortDirections.DESC,
        onColumnClick: this.onColumnClick,
        onRender: item => formatCurrency(item.amount),
      },
      {
        key: 'electionCycle',
        name: 'Election',
        fieldName: 'electionCycle',
        isSorted:
          sortField === 'electionCycle' &&
          sortDirection !== sortDirections.NONE,
        isSortedDescending: sortDirection === sortDirections.DESC,
        onColumnClick: this.onColumnClick,
        onRender: item => item.electionCycle,
      },
      {
        key: 'electionYear',
        name: 'Election Year',
        fieldName: 'electionYear',
        isSorted:
          sortField === 'electionYear' && sortDirection !== sortDirections.NONE,
        isSortedDescending: sortDirection === sortDirections.DESC,
        onColumnClick: this.onColumnClick,
        onRender: item => item.electionYear,
      },
      {
        key: 'description',
        name: 'Description',
        fieldName: 'description',
        isSorted:
          sortField === 'description' && sortDirection !== sortDirections.NONE,
        isSortedDescending: sortDirection === sortDirections.DESC,
        onColumnClick: this.onColumnClick,
        onRender: item => item.description,
      },
    ];
  };

  handleChange = (e, filterText) => {
    this.setState({
      filterText,
    });
  };

  onColumnClick = (e, column) => {
    this.setState(onColumnClick(column.fieldName));
  };

  render() {
    const { transactions } = this.props;

    if (transactions.status === statuses.PROCESSING) {
      return <Loading />;
    }

    return (
      <Grid className="ContactTransactions">
        <GridRow>
          <Column style={{ marginTop: 24 }}>
            <TextField onChange={this.handleTextChange} placeholder="Search" />
          </Column>
        </GridRow>
        <GridRow>
          <Column>
            <DetailsList
              compact={false}
              items={this.mapTransactions()}
              columns={this.createColumns()}
              selectionMode={SelectionMode.none}
              checkboxVisibility={CheckboxVisibility.none}
            />
          </Column>
        </GridRow>
      </Grid>
    );
  }
}

export default ContactTransactions;
