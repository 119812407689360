import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'office-ui-fabric-react/lib/Dropdown';
import { committeeTypes } from '../../helpers/constants';
import { CaretDown } from '../icons';

const NonCandidateCommitteeTypePicker = ({
  selectedKey,
  onChange,
  required,
  className,
  style,
  errorMessage,
  label,
  placeholder,
}) => (
  <Dropdown
    onRenderCaretDown={() => <CaretDown />}
    selectedKey={selectedKey}
    className={className}
    onChange={onChange}
    required={required}
    placeholder={placeholder}
    style={style}
    errorMessage={errorMessage}
    label={label}
    options={[
      { key: 0, text: 'Select' },
      ...committeeTypes.filter(ct => ct.hidden !== true),
    ]}
  />
);

NonCandidateCommitteeTypePicker.propTypes = {
  selectedKey: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.number,
  ]),
  onChange: PropTypes.func,
  required: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
  errorMessage: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
};

NonCandidateCommitteeTypePicker.defaultProps = {
  selectedKey: { key: 0 },
  onChange: () => {},
  required: false,
  className: '',
  placeholder: 'Select',
  style: {},
  errorMessage: '',
  label: 'Committee Type',
};

export default NonCandidateCommitteeTypePicker;
