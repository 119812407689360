import { createReducer } from '../../reducers/createReducer';

export const initialState = {
  column: 0,
  expression: 0,
  value: null,
};

export const actions = {
  HANDLE_CHANGE: 'HANDLE_CHANGE',
  CLEAR_FILTER: 'CLEAR_FILTER',
};

export const actionMap = {
  [actions.HANDLE_CHANGE]: (state, { data: { fieldName, value } }) => {
    if (fieldName === 'column') {
      return {
        ...state,
        column: value,
        expression: 0,
        value: null,
      };
    }

    if (fieldName === 'expression' && ['exists', 'doesNotExist'].includes(value)) {
      return {
        ...state,
        expression: value,
        value: value === 'exists',
      };
    }

    return {
      ...state,
      [fieldName]: value,
    };
  },
  [actions.CLEAR_FILTER]: () => ({
    ...initialState,
  }),
};

export const listFilterCalloutReducer = createReducer(initialState, actionMap);
