import { takeLatest, call, put } from 'redux-saga/effects';
import axios from 'axios';
import { types as reportingPeriodTypes } from '../actions/reportingPeriodActions';
import { types as messagingTypes } from '../actions/messagingActions';
import { toastTypes } from '../helpers/constants';
import { getServerSideErrorMessage } from '../helpers/util';

// eslint-disable-next-line no-confusing-arrow
const sortByOrder = (a, b) =>
  a.order > b.order ? 1 : a.order < b.order ? -1 : 0;

export function* handleGetReportingPeriods() {
  try {
    const {
      data: { reportingPeriods },
    } = yield call(axios.get, '/api/account/reportingPeriods', {
      withCredentials: true,
    });
    yield put({
      type: reportingPeriodTypes.GET_REPORTING_PERIODS_SUCCESS,
      reportingPeriods: reportingPeriods.sort(sortByOrder),
    });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put({
      type: messagingTypes.SET_TOAST,
      data: { message: error, toastType: toastTypes.ERROR },
    });
  }
}

export function* getReportingPeriods() {
  yield takeLatest(
    reportingPeriodTypes.GET_REPORTING_PERIODS,
    handleGetReportingPeriods,
  );
}
