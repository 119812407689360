import React from 'react';
import PropTypes from 'prop-types';
import { ActionButton } from 'office-ui-fabric-react/lib/Button';
import { GridRow, Column } from '../../common';

const CancelContactPickerActionButton = ({ text, onClick }) => (
  <GridRow>
    <Column>
      <ActionButton
        text={text}
        iconProps={{
          iconName: 'MinusCircle',
          styles: {
            root: {
              color: '#a80000',
            },
          },
        }}
        onClick={onClick}
      />
    </Column>
  </GridRow>
);

CancelContactPickerActionButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default CancelContactPickerActionButton;
