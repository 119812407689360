import axios from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';
import { types } from '../actions/importErrorsActions';
import {
  errorToast,
  getServerSideErrorMessage,
  infoToast,
} from '../helpers/util';
import { importStatusWatchWorker } from './importPollingSaga';

const url = '/api/filer/contributions/import';

export function* handleGetImportErrors(action) {
  try {
    yield put({ type: types.GET_IMPORT_ERRORS_PROCESSING });
    const { data } = yield call(
      axios.get,
      `${url}/${action.data.fileId}/errors`,
      {
        withCredentials: true,
      },
    );

    yield put({
      type: types.GET_IMPORT_ERRORS_SUCCESS,
      data,
    });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put(errorToast(error));
    yield put({ type: types.GET_IMPORT_ERRORS_FAILURE, error });
  }
}

export function* getImportErrors() {
  yield takeLatest(types.GET_IMPORT_ERRORS, handleGetImportErrors);
}

export function* handleSaveImportCorrections(action) {
  try {
    yield put({ type: types.SAVE_IMPORT_CORRECTIONS_PROCESSING });

    const payload = { rows: [action.data.row] };
    yield call(axios.put, `${url}/${action.data.fileId}/corrections`, payload, {
      withCredentials: true,
    });

    yield put(infoToast('File processing started'));
    yield call(importStatusWatchWorker, action.data.fileId);

    yield put({ type: types.SAVE_IMPORT_CORRECTIONS_SUCCESS });
    yield put({ type: types.GET_IMPORT_ERRORS, data: { fileId: action.data.fileId } });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put(errorToast(error));
    yield put({ type: types.SAVE_IMPORT_CORRECTIONS_FAILURE, error });
  }
}

export function* saveImportCorrections() {
  yield takeLatest(types.SAVE_IMPORT_CORRECTIONS, handleSaveImportCorrections);
}
