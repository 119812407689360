import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { ChoiceGroup } from 'office-ui-fabric-react/lib/ChoiceGroup';
import {
  MessageBar,
  MessageBarType,
} from 'office-ui-fabric-react/lib/MessageBar';
import { FECReportingFrequencies } from '../../helpers/constants';

const ReportingFrequencyPicker = ({
  value,
  handleChange,
  required = false,
  errorMessage = '',
}) => (
  <Fragment>
    <ChoiceGroup
      label="Reporting Frequency"
      selectedKey={value}
      onChange={handleChange}
      required={required}
      options={[
        { key: FECReportingFrequencies.Monthly, text: 'Monthly' },
        {
          key: FECReportingFrequencies.Quarterly,
          text: 'Quarterly',
        },
      ]}
    />
    {errorMessage && (
      <MessageBar messageBarType={MessageBarType.error}>
        {errorMessage}
      </MessageBar>
    )}
  </Fragment>
);

ReportingFrequencyPicker.propTypes = {
  value: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  errorMessage: PropTypes.string,
};

export default ReportingFrequencyPicker;
