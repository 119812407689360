export const iterateArray = (arr) => {
  const results = arr.map((item) => {
    if (!item) {
      return null;
    }
    if (typeof item === 'object') {
      // eslint-disable-next-line no-use-before-define
      return cleansObject(item);
    }
    return item;
  });
  return results.filter(Boolean);
};

export const isEmpty = (value) => value === null
  || value === undefined
  || value === ''
  || (typeof value === 'object' && !(value instanceof Date) && Object.keys(value).length === 0)
  || (Array.isArray(value) && value.length === 0)
  || (value instanceof Date && Number.isNaN(value.valueOf()));

export const cleansObject = (obj) => {
  const modified = { ...obj };
  Object.entries(modified).forEach(([key, value]) => {
    if (isEmpty(value)) {
      delete modified[key];
    } else if (typeof value === 'object' && Array.isArray(value)) {
      modified[key] = iterateArray(modified[key]);
      if (isEmpty(modified[key])) {
        delete modified[key];
      }
    } else if (typeof value === 'object' && !(value instanceof Date && !Number.isNaN(value.valueOf()))) {
      const result = cleansObject(modified[key]);
      modified[key] = result;
      if (isEmpty(result)) {
        delete modified[key];
      }
    }
  });
  return modified;
};

export const zeroToUndefined = (input) => {
  return input === 0 ? undefined : input;
};

export const getCandidateNameEntry = (contactType, candidateName, name) => {
  if (contactType === 'CAN') {
    return name || undefined;
  }
  if (contactType === 'CCM') {
    return candidateName || undefined;
  }
  return undefined;
};
