import { createReducer } from '../../../reducers/createReducer';
import { sortDirections } from '../../../helpers/constants';
import { deserializeDate } from '../../../helpers/util';

const getDate = () => {
  const date = new Date();
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
};

export const checkNumberToString = checkNumber =>
  checkNumber
    ? typeof checkNumber === 'number'
      ? checkNumber.toString()
      : checkNumber
    : '';

export const mapContributions = contributions =>
  contributions.map(c => ({
    ...c,
    // displayName: c.displayName,
    checkNumber: checkNumberToString(c?.checkNumber),
  }));

export const mapExpenditures = expenditures =>
  expenditures.map(e => ({
    ...e,
    receivedDate: e.expenditureDate,
    // displayName: e.displayName,
    amount: e.isContributionProcessingFee ? -1 * e.amount : e.amount,
    checkNumber: checkNumberToString(e?.checkNumber),
  }));

export const mapLoans = loans =>
  loans.map(l => ({
    ...l,
    receivedDate: l.loanDate,
    // displayName: l.displayName,
    checkNumber: checkNumberToString(l?.checkNumber),
  }));

export const initialState = {
  _id: '',
  name: '',
  date: getDate(),
  sortField: 'displayName',
  sortDirection: sortDirections.ASC,
  filterText: '',
  selectedDetails: null,
  selections: [],
  contributions: [],
  expenditures: [],
  loans: [],
  undeposited: [],
  allSelected: false,
  toggleShowCCOnly: false,
  toggleShowSelectedOnly: false,
  spreadsheet: null,
  errors: {
    nameError: '',
    dateError: '',
  },
  uploadDialogHidden: true,
  uploadDialogMessageList: [],
};

export const actions = {
  SET_DEPOSIT_FOR_EDIT: 'SET_DEPOSIT_FOR_EDIT',
  SET_UNDEPOSITED: 'SET_UNDEPOSITED',
  SET_SPREADSHEET_FILE: 'SET_SPREADSHEET_FILE',
  HANDLE_CHANGE: 'HANDLE_CHANGE',
  ADD_SELECTION: 'ADD_SELECTION',
  REMOVE_SELECTION: 'REMOVE_SELECTION',
  SET_SORT: 'SET_SORT',
  SET_ERRORS: 'SET_ERRORS',
  TOGGLE_SELECT_ALL: 'TOGGLE_SELECT_ALL',
  TOGGLE_SHOW_CC_ONLY: 'TOGGLE_SHOW_CC_ONLY',
  TOGGLE_SHOW_SELECTED_ONLY: 'TOGGLE_SHOW_SELECTED_ONLY',
  SHOW_UPLOAD_DIALOG: 'SHOW_UPLOAD_DIALOG',
  CLOSE_UPLOAD_DIALOG: 'CLOSE_UPLOAD_DIALOG',
  SET_UPLOAD_DIALOG_MESSAGE: 'SET_UPLOAD_DIALOG_MESSAGE',
};

export const actionMap = {
  [actions.SET_DEPOSIT_FOR_EDIT]: (
    state,
    { data: { deposit, undeposited } },
  ) => {
    if (deposit) {
      const mappedContributions = mapContributions(deposit.contributions);

      const mappedExpenditures = mapExpenditures(deposit.expenditures);

      const mappedLoans = mapLoans(deposit.loans);

      const selections = [
        ...mappedContributions,
        ...mappedExpenditures,
        ...mappedLoans,
      ];

      return {
        ...state,
        _id: deposit._id || '',
        allSelected: undeposited.length === 0 && selections.length !== 0,
        undeposited,
        selections,
        contributions: mappedContributions,
        expenditures: mappedExpenditures,
        loans: mappedLoans,
        name: deposit.name || '',
        date: deserializeDate(deposit.depositDate),
      };
    }
    return state;
  },
  [actions.SET_UNDEPOSITED]: (state, { data: { undeposited } }) => {
    return {
      ...state,
      allSelected: false,
      undeposited,
    };
  },
  [actions.SET_SPREADSHEET_FILE]: (state, { data: { spreadsheet } }) => {
    return {
      ...state,
      spreadsheet,
    };
  },
  [actions.HANDLE_CHANGE]: (state, { data: { fieldName, value } }) => {
    return {
      ...state,
      [fieldName]: value,
    };
  },
  [actions.REMOVE_SELECTION]: (state, { data: { item } }) => ({
    ...state,
    allSelected: false,
    selections: state.selections.filter(
      selection => selection._id !== item._id,
    ),
  }),
  [actions.ADD_SELECTION]: (state, { data: { item } }) => {
    return {
      ...state,
      allSelected: !state._id
        ? state.selections.length + 1 === state.undeposited.length
        : false,
      selections: [...state.selections, item],
    };
  },
  [actions.SET_SORT]: (state, { data: { sortField, sortDirection } }) => ({
    ...state,
    sortField,
    sortDirection,
  }),
  [actions.SET_ERRORS]: (state, { data: { errors } }) => ({
    ...state,
    errors: { ...errors },
  }),
  [actions.TOGGLE_SELECT_ALL]: (state, { data: { currentOptions = [] } }) => {
    const currentToggle = state.allSelected;
    const selections = !currentToggle
      ? Array.from(new Set([...state.selections, ...currentOptions]))
      : [];
    return {
      ...state,
      allSelected:
        !currentToggle && !state.filterText && !state.toggleShowCCOnly,
      selections,
    };
  },
  [actions.TOGGLE_SHOW_CC_ONLY]: state => ({
    ...state,
    toggleShowCCOnly: !state.toggleShowCCOnly,
  }),
  [actions.TOGGLE_SHOW_SELECTED_ONLY]: state => ({
    ...state,
    toggleShowSelectedOnly: !state.toggleShowSelectedOnly,
  }),
  [actions.SHOW_UPLOAD_DIALOG]: state => ({
    ...state,
    uploadDialogHidden: false,
  }),
  [actions.CLOSE_UPLOAD_DIALOG]: state => ({
    ...state,
    uploadDialogHidden: true,
  }),
  [actions.SET_UPLOAD_DIALOG_MESSAGE]: (state, { data: { uploadDialogMessage } }) => ({
    ...state,
    uploadDialogMessage,
  }),
};

export const addEditDepositReducer = createReducer(initialState, actionMap);
