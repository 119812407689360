import React, {
  useReducer,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  DetailsList,
  DetailsRow,
  PrimaryButton,
  SelectionMode,
  CheckboxVisibility,
  Icon,
} from 'office-ui-fabric-react';
import {
  formatCurrency,
  formatInteger,
  formatDate,
} from '../../helpers/util';
import {
  uploadReducer,
  initialState,
  actions as contributionApplyActions,
} from './contributionApplyReducer';

import DataImportDialog from '../../components/Dialogs/DataImportDialog';

import { types as dataImportActions } from '../../actions/dataImportActions';

import { BackButton } from '../../components/common';
import { getLongRunningProcess, getDataImports } from '../../selectors';

import './index.css';
import { types as longRunningTypes } from '../../actions/longRunningProcessActions';

const contributionApply = ({ history }) => {
  const [state, localDispatch] = useReducer(
    uploadReducer,
    initialState,
  );
  const reduxDispatch = useDispatch();
  const longRunningProcess = useSelector(getLongRunningProcess);
  const dataImports = useSelector(getDataImports);

  useEffect(() => {
    reduxDispatch({ type: dataImportActions.GET_DATA_IMPORT_LIST });
  }, []);

  useEffect(() => {
  }, [longRunningProcess.status]);

  useEffect(() => {
  }, [dataImports.getDataImportListStatus]);

  const doContinue = async (dataImportId) => {
    localDispatch({
      type: contributionApplyActions.SHOW_CONTINUE_DIALOG,
      data: { dataImportId },
    });
  };

  const closeDialog = async () => {
    localDispatch({
      type: contributionApplyActions.CLOSE_CONTINUE_DIALOG,
    });
  };

  const onImport = async (dataImportId) => {
    reduxDispatch({
      type: longRunningTypes.LONG_RUNNING_JOB,
      data: {
        jobName: 'process-contribution-data-import',
        prettyJobName: 'Contribution Import',
        payload: {
          dataImportId,
        },
      },
    });
  };

  const onDelete = async (dataImportId) => {
    reduxDispatch({
      type: dataImportActions.DELETE_DATA_IMPORT,
      data: {
        dataImportId,
      },
    });
    closeDialog();
  };

  // const wantedAttributes = 'name importType isOK totalCount totalAmount fileName fileSize processStatus';
  const createColumns = () => {
    return [
      {
        key: 'status',
        name: '',
        fieldName: 'status',
        data: 'string',
        minWidth: 40,
        maxWidth: 40,
        isResizable: true,
        onRender: item => <Icon
          iconName="FileExport"
          style={{ fontSize: 18, color: item.isOK ? 'green' : 'red' }}
        />,
      },
      {
        key: 'import_status',
        name: 'Status',
        fieldName: 'name',
        data: 'string',
        minWidth: 165,
        maxWidth: 200,
        isResizable: true,
        onRender: item => <span>{item.processStatus}</span>,
      },
      {
        key: 'name',
        name: 'Import Name',
        fieldName: 'name',
        data: 'string',
        minWidth: 165,
        maxWidth: 200,
        isResizable: true,
        onRender: item => <span>{item.name}</span>,
      },
      {
        key: 'importType',
        name: 'Import Type',
        fieldName: 'importType',
        data: 'string',
        minWidth: 165,
        maxWidth: 200,
        isResizable: true,
        onRender: item => <span>{item.importType}</span>,
      },
      {
        key: 'fileName',
        name: 'File Name',
        fieldName: 'fileName',
        data: 'string',
        minWidth: 165,
        maxWidth: 200,
        isResizable: true,
        onRender: item => <span>{item.fileName}</span>,
      },
      // {
      //   key: 'fileSize',
      //   name: 'File Size',
      //   fieldName: 'fileSize',
      //   data: 'number',
      //   minWidth: 100,
      //   maxWidth: 100,
      //   isResizable: true,
      //   onRender: item => (
      //     <span style={{ textAlign: 'right', display: 'block' }}>
      //       {item.fileSize}
      //     </span>
      //   ),
      // },
      {
        key: 'totalAmount',
        name: 'Total Amount',
        fieldName: 'totalAmount',
        data: 'number',
        minWidth: 100,
        maxWidth: 100,
        isResizable: true,
        onRender: item => (
          <span style={{ textAlign: 'right', display: 'block' }}>
            {formatCurrency(item.totalAmount)}
          </span>
        ),
      },
      {
        key: 'totalCount',
        name: 'Total Count',
        fieldName: 'totalCount',
        data: 'number',
        minWidth: 80,
        maxWidth: 80,
        isResizable: true,
        onRender: item => (
          <span style={{ textAlign: 'right', display: 'block' }}>
            {formatInteger(item.totalCount)}
          </span>
        ),
      },
      {
        key: 'updatedAt',
        name: 'Updated At',
        fieldName: 'updatedAt',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        onRender: item => <span>{formatDate(item.updatedAt)}</span>,
      },
      {
        key: 'action',
        name: '',
        fieldName: 'action',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        onRender: item => (
          <PrimaryButton
            type="button"
            onClick={() => doContinue(item._id)}
          >
            Continue
          </PrimaryButton>),
      },
    ];
  };

  const onRenderRow = rowProps => (
    <div>
      <DetailsRow {...rowProps} />
    </div>
  );

  return (
    <div>
      <BackButton history={history} message="Cancel" />
      <div className="contributionApply">
        <DetailsList
          items={dataImports.list}
          columns={createColumns()}
          compact={false}
          selectionMode={SelectionMode.none}
          checkboxVisibility={CheckboxVisibility.none}
          onRenderRow={onRenderRow}
        />
      </div>
      <DataImportDialog
        dialogHidden={state.showContinueDialogHidden}
        onCancel={closeDialog}
        onImport={onImport}
        onDelete={onDelete}
        dataImportId={state.dataImportId}
      />
    </div>
  );
};

contributionApply.propTypes = {
  history: PropTypes.object.isRequired,
};

export default contributionApply;
