import React from 'react';
import PropTypes from 'prop-types';
import { DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { Dropdown } from 'office-ui-fabric-react/lib/Dropdown';
import { Link } from 'office-ui-fabric-react/lib/Link';
import { Grid, GridRow, Column, MoneyField } from '../../components/common';
import { getLabel } from '../../helpers/labelHelper';
import { DatePicker } from '../../components/Pickers';
import { CaretDown } from '../../components/icons';
import './EditBalanceDetails.css';

const EditBalanceDetails = ({
  actions,
  campaign,
  balanceDetailsExpanded,
  session,
}) => (
  <div className="EditBalanceDetails depth-1" name="balance-details">
    <h3>
      Beginning Balance Information{' '}
      <small>
        {' '}
        (
        <Link onClick={actions.toggleExpandBalanceDetails}>
          {balanceDetailsExpanded ? 'Collapse' : 'Expand'}
        </Link>
        )
      </small>
    </h3>
    <div
      className={`edit-balance-details-content${
        balanceDetailsExpanded ? ' expanded' : ''
      }`}
    >
      <Grid>
        <GridRow>
          <Column sm={9} lg={6}>
            <MoneyField
              label="Starting Bank Balance"
              value={campaign.openingCashAmount}
              onChange={actions.handleTextChange('openingCashAmount')}
            />
          </Column>
          <Column sm={3} lg={6}>
            <DatePicker
              label="As of Date"
              value={
                campaign.openingCashAmountDate
                  ? campaign.openingCashAmountDate
                  : new Date(2016, 0, 1)
              }
              onChange={actions.handleDateChange('openingCashAmountDate')}
            />
          </Column>
        </GridRow>
        <GridRow>
          <Column sm={9} lg={6}>
            <MoneyField
              label="Prior Period Ending Report Balance"
              value={campaign.endingCashOnHandAmount}
              onChange={actions.handleTextChange('endingCashOnHandAmount')}
            />
          </Column>
        </GridRow>
        <GridRow>
          <Column lg={6}>
            <MoneyField
              value={campaign.endingMonetaryContributionsAmount}
              label={`Ending Monetary ${getLabel('Contributions', session)}`}
              onChange={actions.handleTextChange(
                'endingMonetaryContributionsAmount',
              )}
            />
          </Column>
          <Column lg={6}>
            <MoneyField
              value={campaign.endingMonetaryExpendituresAmount}
              label={`Ending Monetary ${getLabel('Expenditures', session)}`}
              onChange={actions.handleTextChange(
                'endingMonetaryExpendituresAmount',
              )}
            />
          </Column>
        </GridRow>
        <GridRow>
          <Column lg={6}>
            <MoneyField
              value={campaign.endingInKindContributionsAmount}
              label={`Ending In Kind ${getLabel('Contributions', session)}`}
              onChange={actions.handleTextChange(
                'endingInKindContributionsAmount',
              )}
            />
          </Column>
          <Column lg={6}>
            <MoneyField
              value={campaign.endingInKindExpendituresAmount}
              label={`Ending In Kind ${getLabel('Expenditures', session)}`}
              onChange={actions.handleTextChange(
                'endingInKindExpendituresAmount',
              )}
            />
          </Column>
        </GridRow>
        {campaign.officeType === 'Local' && (
          <GridRow>
            <Column lg={6}>
              <Dropdown
                onRenderCaretDown={() => <CaretDown />}
                label="Campaign Affidavit"
                required
                selectedKey={campaign.affidavitLimit}
                onChange={actions.handleAffidavitLimitChange}
                options={[
                  {
                    key: -1,
                    text: 'Select',
                  },
                  {
                    key: 0,
                    text: 'No Affidavit Filed',
                  },
                  {
                    key: 2500,
                    text: 'Under $2,500 Affidavit',
                  },
                  {
                    key: 5000,
                    text: 'Under $5,000 Affidavit',
                  },
                ]}
              />
            </Column>
          </GridRow>
        )}
      </Grid>
    </div>
    {balanceDetailsExpanded && (
      <div className="ms-textAlignRight">
        <DefaultButton
          style={{ marginRight: 8 }}
          text="Back"
          onClick={() =>
            actions.previousStep(
              campaign.campaignCommitteeRegistered ||
                campaign.isNonCandidateCommittee ||
                session.isFederal()
                ? 4
                : 2,
            )
          }
        />
        <DefaultButton
          text="Continue"
          onClick={() => actions.nextStep(session.isFederal() ? 7 : 6)}
        />
      </div>
    )}
  </div>
);

EditBalanceDetails.propTypes = {
  campaign: PropTypes.object,
  actions: PropTypes.object.isRequired,
  balanceDetailsExpanded: PropTypes.bool,
  formSection: PropTypes.number,
  session: PropTypes.object.isRequired,
};

EditBalanceDetails.defaultProps = {
  campaign: {},
  balanceDetailsExpanded: false,
  formSection: null,
};

export default EditBalanceDetails;
