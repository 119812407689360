import { types } from '../actions/currentReportActions';
import { statuses } from '../helpers/constants';
import { createReducer } from './createReducer';

export const initialState = {
  report: {},
  contributions: [],
  expenditures: [],
  loans: [],
  indebtedness: [],
  getCurrentReportStatus: statuses.NOT_STARTED,
  verificationStatus: statuses.NOT_STARTED,
  verificationError: null,
  verificationReport: '',
  verifyFederalWithWebStatus: statuses.NOT_STARTED,
  verifyFederalWithWebError: null,
  verifyFederalWithWebResult: '',
  verifyFederalWithWebReportId: '',
  federalSubmitStatus: statuses.NOT_STARTED,
  federalSubmitError: null,
  federalSubmitJob: '',
  federalReportJobStatus: statuses.NOT_STARTED,
  federalReportJobError: null,
  federalReportJobResult: '',
  requestFECWebServiceDraftReportStatus: statuses.NOT_STARTED,
  requestFECWebServiceDraftReportError: null,
  requestFECWebServiceDraftResult: '',
  requestFECWebServiceDraftReportId: '',
  requestGAWebServiceDraftReportStatus: statuses.NOT_STARTED,
  requestGAWebServiceDraftReportError: null,
  requestGAWebServiceDraftResult: '',
  requestGAWebServiceDraftReportId: '',
  xmlReport: {
    message: null,
    contributionErrors: [],
    expenditureErrors: [],
    loanErrors: [],
    otherErrors: [],
    errorMessage: '',
    hasErrors: false,
    respXml: '',
  },
  xmlReportIdKeyMap: [],
  xmlReportXml: null,
};

const actionMap = {
  [types.GET_CURRENT_REPORT_PROCESSING]: (state) => {
    return {
      ...state,
      getCurrentReportStatus: statuses.PROCESSING,
      report: {},
      contributions: [],
      loans: [],
      expenditures: [],
      indebtedness: [],
    };
  },
  [types.GET_CURRENT_REPORT_SUCCESS]: (state, action) => {
    return {
      ...state,
      report: action.data.report,
      contributions: action.data.contributions,
      loans: action.data.loans,
      expenditures: action.data.expenditures,
      indebtedness: action.data.indebtedness,
      getCurrentReportStatus: statuses.SUCCESS,
    };
  },
  [types.RESET_REPORT]: () => {
    return { ...initialState };
  },
  [types.GET_XML_REPORT_SUCCESS]: (state, action) => {
    return {
      ...state,
      xmlReport: {
        ...state.xmlReport,
        message: action.data.message,
      },
      xmlReportXml: action.data.xml,
      xmlReportIdKeyMap: action.data.idKeyMap,
    };
  },
  [types.SUBMIT_FEDERAL_REPORT_PROCESSING]: state => ({
    ...state,
    federalSubmitStatus: statuses.PROCESSING,
    federalSubmitJob: '',
    federalSubmitError: null,
  }),
  [types.SUBMIT_FEDERAL_REPORT_SUCCESS]: (state, action) => {
    return {
      ...state,
      federalSubmitStatus: statuses.SUCCESS,
      federalSubmitJob: action.data,
      federalSubmitError: null,
    };
  },
  [types.SUBMIT_FEDERAL_REPORT_FAILURE]: (state, action) => {
    return {
      ...state,
      federalSubmitStatus: statuses.ERROR,
      federalSubmitJob: '',
      federalSubmitError: action.error,
    };
  },
  [types.FEDERAL_REPORT_JOB_PROCESSING]: state => ({
    ...state,
    federalReportJobStatus: statuses.PROCESSING,
    federalReportJobResult: '',
    federalReportJobError: null,
  }),
  [types.FEDERAL_REPORT_JOB_SUCCESS]: (state, action) => {
    return {
      ...state,
      federalReportJobStatus: statuses.SUCCESS,
      federalReportJobResult: action,
      federalReportJobError: null,
    };
  },
  [types.FEDERAL_REPORT_JOB_FAILURE]: (state, action) => {
    return {
      ...state,
      federalReportJobStatus: statuses.ERROR,
      federalReportJobResult: '',
      federalReportJobError: action.error,
    };
  },
  [types.REQUEST_FEC_WEB_SERVICE_DRAFT_REPORT_PROCESSING]: (state, action) => ({
    ...state,
    requestFECWebServiceDraftReportStatus: statuses.PROCESSING,
    requestFECWebServiceDraftResult: '',
    requestFECWebServiceDraftReportError: null,
    requestFECWebServiceDraftReportId: action.data,
  }),
  [types.REQUEST_FEC_WEB_SERVICE_DRAFT_REPORT_SUCCESS]: (state, action) => {
    return {
      ...state,
      requestFECWebServiceDraftReportStatus: statuses.SUCCESS,
      requestFECWebServiceDraftResult: action.data,
      requestFECWebServiceDraftReportError: null,
    };
  },
  [types.REQUEST_FEC_WEB_SERVICE_DRAFT_REPORT_FAILURE]: (state, action) => {
    return {
      ...state,
      requestFECWebServiceDraftReportStatus: statuses.ERROR,
      requestFECWebServiceDraftResult: '',
      requestFECWebServiceDraftReportError: action.error,
      requestFECWebServiceDraftReportId: '',
    };
  },
  [types.REQUEST_GA_WEB_SERVICE_DRAFT_REPORT_PROCESSING]: (state, action) => ({
    ...state,
    requestGAWebServiceDraftReportStatus: statuses.PROCESSING,
    requestGAWebServiceDraftResult: '',
    requestGAWebServiceDraftReportError: null,
    requestGAWebServiceDraftReportId: action.data,
  }),
  [types.REQUEST_GA_WEB_SERVICE_DRAFT_REPORT_SUCCESS]: (state, action) => {
    return {
      ...state,
      requestGAWebServiceDraftReportStatus: statuses.SUCCESS,
      requestGAWebServiceDraftResult: action.data,
      requestGAWebServiceDraftReportError: null,
    };
  },
  [types.REQUEST_GA_WEB_SERVICE_DRAFT_REPORT_FAILURE]: (state, action) => {
    return {
      ...state,
      requestGAWebServiceDraftReportStatus: statuses.ERROR,
      requestGAWebServiceDraftResult: '',
      requestGAWebServiceDraftReportError: action.error,
      requestGAWebServiceDraftReportId: '',
    };
  },
  [types.VERIFY_FEDERAL_REPORT_WEB_SERVICE_PROCESSING]: (state, action) => ({
    ...state,
    verifyFederalWithWebStatus: statuses.PROCESSING,
    verifyFederalWithWebResult: '',
    verifyFederalWithWebError: null,
    verifyFederalWithWebReportId: action.data,
  }),
  [types.VERIFY_FEDERAL_REPORT_WEB_SERVICE_SUCCESS]: (state, action) => {
    return {
      ...state,
      verifyFederalWithWebStatus: statuses.SUCCESS,
      verifyFederalWithWebResult: action.data,
      verifyFederalWithWebError: null,
    };
  },
  [types.VERIFY_FEDERAL_REPORT_WEB_SERVICE_FAILURE]: (state, action) => {
    return {
      ...state,
      verifyFederalWithWebStatus: statuses.ERROR,
      verifyFederalWithWebResult: '',
      verifyFederalWithWebError: action.error,
      verifyFederalWithWebReportId: '',
    };
  },
};

export const currentReportReducer = createReducer(initialState, actionMap);
