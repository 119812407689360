import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Label } from 'office-ui-fabric-react';
import { TagPicker } from 'office-ui-fabric-react/lib/Pickers';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';

export const SourceCodePicker = ({
  onChange,
  label,
}) => {
  const [sourceCodes, setSourceCodes] = useState([]);
  const [loading, setLoading] = useState([true]);
  const [foundListSize, setFoundListSize] = useState(0);
  const [displayedListSize, setDisplayedListSize] = useState(0);

  const getOptions = async () => {
    const { data: sourceCodes } = await axios.get('/api/filer/accounting/lists/sourceCodeOptions');
    const orderedSourceCodes = [
      ...sourceCodes
        .map(sourceCode => sourceCode.sourceCode)
        .filter(sourceCode => Boolean(sourceCode))
        .sort((a, b) => a.localeCompare(b))
        .map(sourceCode => ({
          key: sourceCode,
          name: sourceCode,
        })),
    ];
    setSourceCodes(orderedSourceCodes);
    setLoading(false);
  };

  useEffect(() => {
    getOptions();
  }, []);

  if (loading) {
    return <Spinner size={SpinnerSize.medium} />;
  }

  const filterSuggestedTags = (filterText) => {
    const filteredList = sourceCodes.filter(tag => tag.name.toLowerCase().indexOf(filterText.toLowerCase()) !== -1);
    const displayedList = filteredList.slice(0, 100);
    setFoundListSize(filteredList.length);
    setDisplayedListSize(displayedList.length);
    return filteredList;
  };

  const pickerSuggestionsProps = {
    suggestionsHeaderText: `Select (Showing ${displayedListSize} of ${foundListSize})`,
    noResultsFoundText: 'No source codes found',
  };

  const elementClicked = () => {
    return filterSuggestedTags('');
  };

  const doOnChange = (selectedList) => {
    const sourceCode = selectedList.length ? selectedList[0].name : '';
    onChange(null, sourceCode);
  };

  const getTextFromItem = (item) => item.name;
  return (
    <div>
      <Label>{label}</Label>
      <TagPicker
        onResolveSuggestions={filterSuggestedTags}
        getTextFromItem={getTextFromItem}
        pickerSuggestionsProps={pickerSuggestionsProps}
        itemLimit={1}
        onEmptyInputFocus={elementClicked}
        onChange={doOnChange}
      />
    </div>
  );
};

SourceCodePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

export default SourceCodePicker;
