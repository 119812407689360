import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { GridRow, Column, MoneyField } from '../../common';

const FECSection7b = ({ q7bColA, q7bColB }) => {
  return (
    <Fragment>
      <GridRow>
        <Column md={4} classNames="sub-question">
          <p>
            (b) Total Offset to Operating Expenditures <br />
            {'(from Line 14)'}
          </p>
        </Column>
        <Column md={4}>
          <MoneyField
            disabled
            value={q7bColA}
          />
        </Column>
        <Column md={4}>
          <MoneyField disabled value={q7bColB} />
        </Column>
      </GridRow>
    </Fragment>
  );
};

FECSection7b.propTypes = {
  q7bColA: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  q7bColB: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default FECSection7b;
