import React from 'react';
import { sortDirections } from '../../helpers/constants';
import { formatDate, formatCurrency, sortByField } from '../../helpers/util';

export const createColumns = (sortField, sortDirection, onColumnClick) => [
  {
    key: 'selection',
    fieldName: 'selection',
    name: '',
    minWidth: 40,
    maxWidth: 40,
  },
  {
    key: 'date',
    name: 'Date',
    fieldName: 'date',
    isSorted: sortField === 'date' && sortDirection !== sortDirections.NONE,
    isSortedDescending: sortDirection === sortDirections.DESC,
    onColumnClick,
    onRender: item => (
      <span style={{ fontSize: '14px' }}>
        {formatDate(item.date, 'MM/DD/YY')}
      </span>
    ),
    minWidth: 100,
    maxWidth: 150,
  },
  {
    key: 'name',
    name: 'Name',
    fieldName: 'name',
    isSorted: sortField === 'name' && sortDirection !== sortDirections.NONE,
    isSortedDescending: sortDirection === sortDirections.DESC,
    onColumnClick,
    onRender: item => <span style={{ fontSize: '14px' }}>{item.name}</span>,
    minWidth: 150,
  },
  {
    key: 'check-number',
    name: 'Check #',
    fieldName: 'checkNumberSort',
    isSorted:
      sortField === 'checkNumberSort' && sortDirection !== sortDirections.NONE,
    isSortedDescending: sortDirection === sortDirections.DESC,
    onColumnClick,
    onRender: item => {
      return (
        <span>{item.checkNumber !== undefined ? item.checkNumber : ''}</span>
      );
    },
    minWidth: 75,
  },
  {
    key: 'ref-number',
    name: 'Ref. #',
    fieldName: 'paymentReferenceId',
    isSorted:
      sortField === 'paymentReferenceId' &&
      sortDirection !== sortDirections.NONE,
    isSortedDescending: sortDirection === sortDirections.DESC,
    onColumnClick,
    onRender: item => <span>{item.paymentReferenceId || ''}</span>,
    minWidth: 75,
  },
  {
    key: 'amount',
    name: 'Amount',
    fieldName: 'amount',
    isSorted: sortField === 'amount' && sortDirection !== sortDirections.NONE,
    isSortedDescending: sortDirection === sortDirections.DESC,
    onColumnClick,
    minWidth: 150,
    onRender: item => {
      if (item.type === 'Deposit' || item.isInterest) {
        return (
          <span
            style={{ fontSize: '14px', display: 'block', textAlign: 'right' }}
          >
            {formatCurrency(item.amount)}
          </span>
        );
      }

      if (item.type === 'Expenditure') {
        return (
          <span
            style={{
              fontSize: '14px',
              color: 'red',
              display: 'block',
              textAlign: 'right',
            }}
          >
            {`(${formatCurrency(item.amount)})`}
          </span>
        );
      }

      return null;
    },
  },
];

export const searchName = (name, searchTerm = '') => {
  if (searchTerm) {
    return name ? name.toLowerCase().includes(searchTerm) : false;
  }

  return true;
};

export const searchAmount = (amount, searchTerm = '') => {
  if (searchTerm) {
    return amount ? amount.toString().includes(searchTerm) : false;
  }

  return true;
};

export const searchCheckNumber = (checkNumber = '', searchTerm = '') => {
  if (searchTerm) {
    return checkNumber?.toString()?.includes(searchTerm) || false;
  }

  return true;
};

export const searchReferenceNumber = (refNum, searchTerm) => {
  if (searchTerm) {
    return refNum ? refNum.toLowerCase().includes(searchTerm) : false;
  }

  return true;
};

export const mapList = state => {
  const searchTerm = state.filterText.toLowerCase();
  let items;
  if (state.selectedTab === 'all') {
    items = state.transactions.filter(item => {
      if (item.type === 'Deposit') {
        return (
          searchName(item.name, searchTerm) ||
          searchAmount(item.amount, searchTerm)
        );
      }

      if (item.type === 'Expenditure') {
        return (
          searchName(item.displayName, searchTerm) ||
          searchCheckNumber(item.checkNumber || '', searchTerm) ||
          searchReferenceNumber(item.paymentReferenceId, searchTerm) ||
          searchAmount(item.amount, searchTerm)
        );
      }

      return true;
    });
  } else if (state.selectedTab === 'deposits') {
    items = state.transactions.filter(item => {
      if (item.type === 'Deposit' || item.isInterest) {
        return (
          searchName(item.name, searchTerm) ||
          searchAmount(item.amount, searchTerm)
        );
      }

      return false;
    });
  } else if (state.selectedTab === 'expenditures') {
    items = state.transactions.filter(item => {
      if (item.type === 'Expenditure') {
        return (
          searchName(item.displayName, searchTerm) ||
          searchCheckNumber(item.checkNumber || '', searchTerm) ||
          searchReferenceNumber(item.paymentReferenceId, searchTerm) ||
          searchAmount(item.amount, searchTerm)
        );
      }

      return false;
    });
  }

  return items.sort(sortByField(state.sortField, state.sortDirection));
};

export const getSelectionDetails = transactions => {
  const {
    totalDeposits,
    totalExpenditures,
    depositIds,
    expenditureIds,
    contributionIds,
  } = transactions.reduce(
    (acc, item) => {
      if (item.isSelected) {
        if (item.type === 'Deposit') {
          acc.totalDeposits += item.amount;
          acc.depositIds = [...acc.depositIds, item._id];
        } else if (item.type === 'Expenditure') {
          acc.totalExpenditures += item.amount;
          acc.expenditureIds = [...acc.expenditureIds, item._id];
        } else if (item.isInterest) {
          acc.totalDeposits += item.amount;
          acc.contributionIds = [...acc.contributionIds, item._id];
        }
      }
      return acc;
    },
    {
      totalDeposits: 0,
      totalExpenditures: 0,
      depositIds: [],
      expenditureIds: [],
      contributionIds: [],
    },
  );

  return {
    totalDeposits,
    totalExpenditures,
    depositIds,
    expenditureIds,
    contributionIds,
  };
};

export const getOutOfBalance = ({
  endingBalance = 0,
  startingBalance = 0,
  totalDeposits = 0,
  totalExpenditures = 0,
}) => {
  const roughValue =
    parseFloat(endingBalance || 0) -
    parseFloat(startingBalance || 0) -
    (totalDeposits + -totalExpenditures);
  return Math.round(roughValue * 100) / 100;
};

export const updateListSelection = ({
  itemId = '',
  transactions = [],
  isSelected = false,
}) =>
  transactions.map(transactionItem => {
    if (transactionItem._id === itemId) {
      transactionItem.isSelected = isSelected;
    }
    return transactionItem;
  });

export const toggleSelectAll = ({ selectedTab, allSelected, transactions }) => {
  let list = [...transactions];
  if (selectedTab === 'all') {
    list = list.map(item => ({
      ...item,
      isSelected: allSelected,
    }));
  } else if (selectedTab === 'deposits') {
    list = list.map(item => {
      if (item.type === 'Deposit' || item.isInterest) {
        item.isSelected = allSelected;
      }

      return item;
    });
  } else if (selectedTab === 'expenditures') {
    list = list.map(item => {
      if (item.type === 'Expenditure') {
        item.isSelected = allSelected;
      }

      return item;
    });
  }

  return list;
};

export const createPayload = state => {
  const { depositIds, expenditureIds, contributionIds } = getSelectionDetails(
    state.transactions,
  );

  return {
    name: state.name,
    statementBeginningBalance: state.beginningStatementBalance,
    statementEndingBalance: state.endingStatementBalance,
    statementBeginDate: state.beginningStatementDate,
    statementEndDate: state.endingStatementDate,
    depositIds,
    expenditureIds,
    contributionIds,
  };
};

export const convertContributionToDeposit = contribution => {
  const deposit = {
    _id: contribution._id,
    name: `Interest - ${contribution.sortName}`,
    amount: contribution.amount,
    date: contribution.receivedDate,
    checkNumber: contribution.checkNumber || '',
    paymentReferenceId: contribution.paymentReferenceId || '',
    reconciliationId: contribution.reconciliationId || '',
    isInterest: true,
    isSelected: false,
  };
  return deposit;
};
