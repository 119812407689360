import React, { Fragment, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import {
  SearchBox,
  DetailsList,
  SelectionMode,
  CheckboxVisibility,
  DetailsRow,
} from 'office-ui-fabric-react';
import { sortDirections } from '../../helpers/constants';
import { ContributionsPanel } from '../Panels';
import {
  formatCurrency,
  formatDate,
  formatDateTime,
} from '../../helpers/util';
import { getLabel } from '../../helpers/labelHelper';

export const ContributionsList = ({
  contributions,
  session,
  isHidden,
  filterText,
  sortField,
  sortDirection,
  actions,
}) => {
  if (isHidden) {
    return null;
  }

  const [state, setState] = useState({
    showPanel: false,
    panelItem: null,
  });

  const mapContributions = () => {
    return contributions
      .map(c => ({
        ...c,
        key: c._id,
        checkNumber: c.checkNumber || '',
        paymentReferenceId: c.paymentReferenceId || '',
      }));
  };

  const onColumnClick = (e, column) => {
    const field = column.fieldName;
    let direction;
    if (sortField === column.fieldName) {
      if (sortDirection === sortDirections.ASC) {
        direction = sortDirections.DESC;
      } else if (sortDirection === sortDirections.DESC) {
        direction = sortDirections.NONE;
      } else {
        direction = sortDirections.ASC;
      }
    } else {
      direction = sortDirections.ASC;
    }

    actions.setSort({ sortBy: field, sortDir: direction });
  };

  const showPanel = async i => {
    const url = `/api/filer/contributions/${i._id}`;
    const { data: item } = await axios.get(url);

    setState({
      ...state,
      showPanel: true,
      panelItem: item[0],
    });
  };

  const hidePanel = () => {
    setState({
      ...state,
      showPanel: false,
      panelItem: null,
    });
  };

  const createColumns = () => {
    return [
      {
        key: 'contributor-name',
        name: 'Contributor Name',
        fieldName: 'displayName',
        data: 'string',
        minWidth: 165,
        isResizable: true,
        isSorted:
          sortField === 'displayName' &&
          sortDirection !== sortDirections.NONE,
        isSortedDescending: sortDirection === sortDirections.DESC,
        onColumnClick,
        onRender: item => <span>{item.displayName}</span>,
      },
      {
        key: 'amount',
        name: 'Amount',
        fieldName: 'amount',
        data: 'number',
        minWidth: 100,
        isResizable: true,
        isSorted:
          sortField === 'amount' && sortDirection !== sortDirections.NONE,
        isSortedDescending: sortDirection === sortDirections.DESC,
        onColumnClick,
        onRender: item => (
          <span style={{ textAlign: 'right', display: 'block' }}>
            {formatCurrency(item.amount)}
          </span>
        ),
      },
      {
        key: 'checkNumber',
        name: 'Check Number',
        fieldName: 'checkNumberSort',
        data: 'string',
        minWidth: 100,
        isResizable: true,
        isSorted:
          sortField === 'checkNumberSort' &&
          sortDirection !== sortDirections.NONE,
        isSortedDescending: sortDirection === sortDirections.DESC,
        onColumnClick,
        onRender: item => <span>{item.checkNumber}</span>,
      },
      {
        key: 'referenceId',
        name: 'Reference Number',
        fieldName: 'paymentReferenceId',
        isResizable: true,
        minWidth: 150,
        isSorted:
          sortField === 'paymentReferenceId' &&
          sortDirection !== sortDirections.NONE,
        isSortedDescending: sortDirection === sortDirections.DESC,
        onColumnClick,
        onRender: item => <span>{item.paymentReferenceId}</span>,
      },
      {
        key: 'sourceCode',
        name: 'Source Code',
        fieldName: 'sourceCode',
        isResizable: true,
        minWidth: 150,
        isSorted:
          sortField === 'sourceCode' &&
          sortDirection !== sortDirections.NONE,
        isSortedDescending: sortDirection === sortDirections.DESC,
        onColumnClick,
        onRender: item => <span>{item.sourceCode}</span>,
      },
      {
        key: 'date-received',
        name: 'Date Received',
        fieldName: 'receivedDate',
        minWidth: 100,
        isResizable: true,
        isSorted:
          sortField === 'receivedDate' && sortDirection !== sortDirections.NONE,
        isSortedDescending: sortDirection === sortDirections.DESC,
        onColumnClick,
        onRender: item => <span>{formatDate(item.receivedDate)}</span>,
      },
      {
        key: 'date-entered-updated',
        name: 'Date Entered/Updated',
        fieldName: 'updatedAt',
        data: 'string',
        isResizable: true,
        minWidth: 150,
        isSorted:
          sortField === 'updatedAt' && sortDirection !== sortDirections.NONE,
        isSortedDescending: sortDirection === sortDirections.DESC,
        onColumnClick,
        onRender: item => <span>{formatDateTime(item.updatedAt)}</span>,
      },
    ];
  };

  const onRenderRow = rowProps => (
    <div onClick={() => showPanel(rowProps.item)}>
      <DetailsRow {...rowProps} />
    </div>
  );

  return (
    <Fragment>
      <SearchBox
        placeholder={`Search ${getLabel('Contributions', session)}`}
        value={filterText}
        onChange={actions.handleFilterChange}
        clearButtonProps={{
          iconProps: {
            iconName: 'Times',
          },
        }}
      />
      <DetailsList
        items={mapContributions()}
        columns={createColumns()}
        compact={false}
        selectionMode={SelectionMode.none}
        checkboxVisibility={CheckboxVisibility.none}
        onRenderRow={onRenderRow}
      />
      <ContributionsPanel
        item={state.panelItem}
        showPanel={state.showPanel}
        closePanel={hidePanel}
        editItem={actions.editItem}
        session={session}
      />
    </Fragment>
  );
};

ContributionsList.propTypes = {
  contributions: PropTypes.array,
  session: PropTypes.object.isRequired,
  isHidden: PropTypes.bool,
  actions: PropTypes.object,
  sortField: PropTypes.string,
  sortDirection: PropTypes.oneOf(Object.values(sortDirections)),
  filterText: PropTypes.string,
};

ContributionsList.defaultProps = {
  contributions: {
    contributions: [],
  },
  isHidden: false,
};

export default ContributionsList;
