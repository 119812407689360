import { call, put, takeEvery, takeLatest, take } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import axios from 'axios';
import { push } from 'connected-react-router';
import { errorToast, getServerSideErrorMessage } from '../helpers/util';
import { types as currentReportTypes } from '../actions/currentReportActions';
import { types as messagingTypes } from '../actions/messagingActions';
import { types as alertTypes } from '../actions/alertActions';
import { toastTypes } from '../helpers/constants';

export function* handleCreateAmendment(action) {
  try {
    const { reportId } = action.data;
    const { data: newReport } = yield call(
      axios.post,
      `/api/filer/reports/${reportId}/amendments`,
      { withCredentials: true },
    );
    const { _id: newReportId } = newReport;
    yield put(push(`/filer/editReport/${newReportId}`));
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put(errorToast(error));
  }
}

export function* createReportAmendment() {
  yield takeLatest(currentReportTypes.CREATE_AMENDMENT, handleCreateAmendment);
}

export function* handleCreateAmendmentFederal({ data: { reportId } }) {
  try {
    const { data: newReport } = yield call(
      axios.post,
      `/api/filer/fec/reports/${reportId}/amendments`,
      { withCredentials: true },
    );
    const { _id: newReportId } = newReport;
    yield put(push(`/filer/editFECReport/${newReportId}`));
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put(errorToast(error));
  }
}

export function* createReportAmendmentFederal() {
  yield takeLatest(
    currentReportTypes.CREATE_AMENDMENT_FEDERAL,
    handleCreateAmendmentFederal,
  );
}

function getReport(totalSeconds, pause, reportId) {
  return eventChannel(emitter => {
    const iv = setInterval(() => {
      totalSeconds -= pause;
      if (totalSeconds > 0) {
        axios
          .get(`/api/filer/reports/${reportId}`)
          .then(res => {
            if (res.data.isComplete) {
              emitter({ ...res.data });
            }
          })
          .catch(error => {
            emitter({ error });
          });
      } else {
        // this causes the channel to close
        emitter(false);
      }
    }, pause * 1000);
    // The subscriber must return an unsubscribe function
    return () => clearInterval(iv);
  });
}

export function* handleGetCurrentReport(action) {
  try {
    const { reportId } = action.data;
    yield put({
      type: currentReportTypes.GET_CURRENT_REPORT_PROCESSING,
    });
    const { data: { isJobStarted } } = yield call(axios.post, `/api/filer/reports/${reportId}/calculate`, { withCredentials: true });
    if (!isJobStarted) {
      return yield put({
        type: alertTypes.SET_ALERT,
        data: {
          message: 'Unable to create summaries for report',
          toastType: toastTypes.ERROR,
        },
      });
    }

    const channel = yield call(getReport, 60 * 30, 2, reportId);
    const channelResult = yield take(channel);
    channel.close();
    if (channelResult.isFailed) {
      return yield put({
        type: alertTypes.SET_ALERT,
        data: {
          message: 'Unable to retrieve report',
          toastType: toastTypes.ERROR,
        },
      });
    }
    const { data: report } = channelResult;

    // This call retrieves contribution detail needed for reports.
    // State level reports still use the contributions returned from report

    // FEC has contribution size too big to return like this and employs a separate
    // contribution call and pages with infinite scroll
    let contributions = [];
    if (report.officeType !== 'Federal') {
      ({ data: contributions } = yield call(
        axios.get,
        `/api/filer/reports/${reportId}/contributions`,
        { withCredentials: true },
      ));
    }

    const { data: expenditures } = yield call(
      axios.get,
      `/api/filer/reports/${reportId}/expenditures`,
      { withCredentials: true },
    );
    const { data: loans } = yield call(
      axios.get,
      `/api/filer/reports/${reportId}/loans`,
      { withCredentials: true },
    );
    const { data: indebtedness } = yield call(
      axios.get,
      `/api/filer/reports/${reportId}/indebtedness`,
      { withCredentials: true },
    );
    yield put({
      type: messagingTypes.SET_TOAST,
      data: {
        message: 'Report processed successfully!',
        toastType: toastTypes.SUCCESS,
      },
    });
    yield put({
      type: currentReportTypes.GET_CURRENT_REPORT_SUCCESS,
      data: { report, contributions, loans, expenditures, indebtedness },
    });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put(errorToast(error));
  }
}

export function* getCurrentReport() {
  yield takeEvery(
    currentReportTypes.GET_CURRENT_REPORT,
    handleGetCurrentReport,
  );
}

export function* handleUpdateReport(action) {
  try {
    const { payload } = action;
    yield call(axios.put, `/api/filer/reports/${payload._id}`, payload, {
      withCredentials: true,
    });
    yield put({
      type: currentReportTypes.GET_CURRENT_REPORT_PROCESSING,
    });
    yield put({
      type: currentReportTypes.GET_CURRENT_REPORT,
      data: { reportId: payload._id },
    });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put(errorToast(error));
  }
}

export function* updateReport() {
  yield takeEvery(currentReportTypes.UPDATE_REPORT, handleUpdateReport);
}

export function* handleUpdateFederalReport(action) {
  try {
    const { payload } = action;
    yield call(axios.put, `/api/filer/fec/reports/${payload._id}`, payload, {
      withCredentials: true,
    });
    yield put({
      type: currentReportTypes.GET_CURRENT_REPORT_PROCESSING,
    });
    yield put({
      type: currentReportTypes.GET_CURRENT_REPORT,
      data: { reportId: payload._id },
    });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put(errorToast(error));
  }
}

export function* updateFederalReport() {
  yield takeEvery(currentReportTypes.UPDATE_FEDERAL_REPORT, handleUpdateFederalReport);
}

export function* handleGetXmlReport(action) {
  try {
    const { reportId } = action;
    const {
      data: {
        message,
        data: { xml, idKeyMap },
      },
    } = yield call(axios.get, `/api/filer/reports/${reportId}/xml`, {
      withCredentials: true,
    });
    yield put({
      type: currentReportTypes.GET_XML_REPORT_SUCCESS,
      data: { xml, message, idKeyMap },
    });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put(errorToast(error));
  }
}

export function* getXmlReport() {
  yield takeEvery(currentReportTypes.GET_XML_REPORT, handleGetXmlReport);
}

function isAgendaJobComplete(totalSeconds, pause, id) {
  return eventChannel(emitter => {
    const iv = setInterval(() => {
      totalSeconds -= pause;
      if (totalSeconds > 0) {
        axios
          .get(`/api/jobs/getCompletedJob/${id}`)
          .then(res => {
            if (res.data.isComplete) {
              emitter({ ...res.data });
            }
          })
          .catch(error => {
            emitter({ error });
          });
      } else {
        // this causes the channel to close
        emitter(false);
      }
    }, pause * 1000);
    // The subscriber must return an unsubscribe function
    return () => {
      clearInterval(iv);
    };
  });
}

export function* handleSubmitFederalReport(action) {
  try {
    const { reportId, submitFileWithWarnings } = action.data;
    yield put({ type: currentReportTypes.SUBMIT_FEDERAL_REPORT_PROCESSING });
    const { data } = yield call(
      axios.post,
      `/api/filer/reports/${reportId}/fileWithFEC`,
      { submitFileWithWarnings },
    );
    yield put({
      type: currentReportTypes.SUBMIT_FEDERAL_REPORT_SUCCESS,
      data,
    });
    yield put({ type: currentReportTypes.FEDERAL_REPORT_JOB_PROCESSING });
    const channel = yield call(isAgendaJobComplete, 60 * 30, 2, data.taskId);
    const channelResult = yield take(channel);
    channel.close();
    if (channelResult.isFailed) {
      yield put({
        type: currentReportTypes.FEDERAL_REPORT_JOB_FAILURE,
        error: channelResult.failReason,
      });
    } else {
      yield put({
        type: currentReportTypes.FEDERAL_REPORT_JOB_SUCCESS,
        data: channelResult,
      });
    }
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put({
      type: currentReportTypes.SUBMIT_FEDERAL_REPORT_FAILURE,
      error,
    });
    yield put({
      type: currentReportTypes.FEDERAL_REPORT_JOB_FAILURE,
      error,
    });
  }
}

export function* submitFederalReport() {
  yield takeLatest(
    currentReportTypes.SUBMIT_FEDERAL_REPORT,
    handleSubmitFederalReport,
  );
}

export function* handleMarkAsFiled(action) {
  try {
    const {
      data: { reportId },
    } = action;
    yield call(axios.post, `/api/filer/reports/${reportId}/markAsFiled`, null, {
      responseType: 'text',
      withCredentials: true,
    });
    yield put({
      type: messagingTypes.SET_TOAST,
      data: {
        message: 'Report marked as filed successfully!',
        toastType: toastTypes.SUCCESS,
      },
    });
    yield put(push('/filer/reports'));
    yield put({ type: currentReportTypes.RESET_REPORT });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put(errorToast(error));
  }
}

export function* markAsFiled() {
  yield takeLatest(currentReportTypes.MARK_AS_FILED, handleMarkAsFiled);
}

export function* handleMarkTBDAsFiled(action) {
  try {
    const {
      data: { reportId },
    } = action;
    yield call(axios.post, `/api/filer/reports/${reportId}/markAsFiled`, null, {
      responseType: 'text',
      withCredentials: true,
    });
    yield put({
      type: messagingTypes.SET_TOAST,
      data: {
        message: 'Report marked as filed successfully!',
        toastType: toastTypes.SUCCESS,
      },
    });
    yield put(push('/filer/tbdReports'));
    yield put({ type: currentReportTypes.RESET_REPORT });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put(errorToast(error));
  }
}

export function* markTBDAsFiled() {
  yield takeLatest(currentReportTypes.MARK_TBD_AS_FILED, handleMarkTBDAsFiled);
}

export function* handleSubmitTbdReport(action) {
  try {
    const { reportId } = action;
    yield call(axios.put, `/api/filer/reports/${reportId}/tbd`, null, {
      responseType: 'text',
      withCredentials: true,
    });
    yield put({
      type: messagingTypes.SET_TOAST,
      data: {
        message: 'Report submitted successfully!',
        toastType: toastTypes.SUCCESS,
      },
    });
    yield put(push('/filer/tbdReports'));
    yield put({ type: currentReportTypes.RESET_REPORT });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put(errorToast(error));
  }
}

export function* submitTbdReport() {
  yield takeLatest(currentReportTypes.SUBMIT_TBD_REPORT, handleSubmitTbdReport);
}

export function* handleSubmitLocalReport(action) {
  try {
    const { reportId } = action;
    yield call(axios.put, `/api/filer/reports/${reportId}/local`, null, {
      responseType: 'text',
      withCredentials: true,
    });
    yield put({
      type: messagingTypes.SET_TOAST,
      data: {
        message: 'Report submitted successfully!',
        toastType: toastTypes.SUCCESS,
      },
    });
    yield put(push('/filer/reports'));
    yield put({ type: currentReportTypes.RESET_REPORT });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put(errorToast(error));
  }
}

export function* submitLocalReport() {
  yield takeLatest(
    currentReportTypes.SUBMIT_LOCAL_REPORT,
    handleSubmitLocalReport,
  );
}

export function* handleRequestFECWebServiceDraftReport(action) {
  try {
    const { reportId } = action;
    yield put({
      type: currentReportTypes.REQUEST_FEC_WEB_SERVICE_DRAFT_REPORT_PROCESSING,
      data: reportId,
    });
    const { data } = yield call(
      axios.post,
      `/api/filer/reports/${reportId}/fec/fecpdf`,
    );
    const channel = yield call(isAgendaJobComplete, 60 * 5, 2, data.taskId);
    const channelResult = yield take(channel);
    channel.close();
    yield put({
      type: currentReportTypes.REQUEST_FEC_WEB_SERVICE_DRAFT_REPORT_SUCCESS,
      data: channelResult,
    });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put({
      type: currentReportTypes.REQUEST_FEC_WEB_SERVICE_DRAFT_REPORT_FAILURE,
      error,
    });
  }
}

export function* requestFECWebServiceDraftReport() {
  yield takeLatest(
    currentReportTypes.REQUEST_FEC_WEB_SERVICE_DRAFT_REPORT,
    handleRequestFECWebServiceDraftReport,
  );
}

export function* handleRequestGAWebServiceDraftReport(action) {
  try {
    const { reportId } = action;
    yield put({
      type: currentReportTypes.REQUEST_GA_WEB_SERVICE_DRAFT_REPORT_PROCESSING,
      data: reportId,
    });
    const { data } = yield call(
      axios.post,
      `/api/filer/reports/${reportId}/GA/draftpdf`,
    );
    const channel = yield call(isAgendaJobComplete, 60 * 5, 2, data.taskId);
    const channelResult = yield take(channel);
    channel.close();
    yield put({
      type: currentReportTypes.REQUEST_GA_WEB_SERVICE_DRAFT_REPORT_SUCCESS,
      data: channelResult,
    });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put({
      type: currentReportTypes.REQUEST_GA_WEB_SERVICE_DRAFT_REPORT_FAILURE,
      error,
    });
  }
}

export function* requestGAWebServiceDraftReport() {
  yield takeLatest(
    currentReportTypes.REQUEST_GA_WEB_SERVICE_DRAFT_REPORT,
    handleRequestGAWebServiceDraftReport,
  );
}

export function* handleVerifyFederalReportWithWebService(action) {
  try {
    const { reportId } = action;
    // request agenda job create for verify report
    yield put({ type: currentReportTypes.RESET_REPORT });
    yield put({
      type: currentReportTypes.VERIFY_FEDERAL_REPORT_WEB_SERVICE_PROCESSING,
      data: reportId,
    });
    const createJobRequestURL = `/api/filer/reports/${reportId}/webServiceVerification`;
    const { data } = yield call(axios.post, createJobRequestURL);
    // wait for job completion
    const channel = yield call(isAgendaJobComplete, 60 * 5, 2, data.taskId);
    const channelResult = yield take(channel);
    channel.close();
    if (channelResult.isFailed) {
      yield put({
        type: currentReportTypes.VERIFY_FEDERAL_REPORT_WEB_SERVICE_FAILURE,
        error: 'Unable to create verify report.',
      });
    }
    // Successful? Grab content from S3 location.
    const { data: reportData } = yield call(
      axios.get,
      `/api/filer/reports/${reportId}/fec/draftValidateContent`,
    );
    yield put({
      type: currentReportTypes.VERIFY_FEDERAL_REPORT_WEB_SERVICE_SUCCESS,
      data: reportData,
    });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put({
      type: currentReportTypes.VERIFY_FEDERAL_REPORT_WEB_SERVICE_FAILURE,
      error,
    });
  }
}

export function* verifyFederalReportWithWebService() {
  yield takeLatest(
    currentReportTypes.VERIFY_FEDERAL_REPORT_WEB_SERVICE,
    handleVerifyFederalReportWithWebService,
  );
}
