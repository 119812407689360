import { Icon } from 'office-ui-fabric-react';
import PropTypes from 'prop-types';
import React from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { types as messagingActions } from '../../actions/messagingActions';
import { toastTypes } from '../../helpers/constants';
import { mbToBytes } from '../../helpers/util';
import './index.css';

const UploadFile = ({
  mbSize,
  acceptTypes,
  isDropzoneHidden,
  onUploadFile,
}) => {
  const reduxDispatch = useDispatch();

  const handleDrop = ([file], [rejectedFile]) => {
    if (rejectedFile?.errors?.[0]?.code === 'file-too-large') {
      reduxDispatch({
        type: messagingActions.SET_TOAST,
        data: {
          message: `File is larger than ${mbSize} MB`,
          toastType: toastTypes.ERROR,
        },
      });
    } else if (rejectedFile?.errors?.[0]?.code === 'file-invalid-type') {
      const joinedTypes = Object.values(acceptTypes)
        .map(types => types.join(' / '))
        .join(', ');
      reduxDispatch({
        type: messagingActions.SET_TOAST,
        data: {
          message: `File type must be ${joinedTypes}`,
          toastType: toastTypes.ERROR,
        },
      });
    } else {
      onUploadFile(file);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    onDrop: handleDrop,
    accept: acceptTypes,
    maxSize: mbToBytes(mbSize),
  });

  return isDropzoneHidden ? null : (
    <div
      {...getRootProps({
        className: 'UploadFile ms-borderColor-neutralTertiaryAlt',
      })}
    >
      <input {...getInputProps()} />
      <div className="upload-file-caption-container">
        <span className="upload-file-caption">
          Drag &apos;n&apos; drop a file here, or click to select the file
        </span>
        <Icon
          iconName="Upload"
          className="upload-file-icon ms-fontColor-themeDarker"
        />
      </div>
    </div>
  );
};

UploadFile.propTypes = {
  mbSize: PropTypes.number,
  acceptTypes: PropTypes.object,
  isDropzoneHidden: PropTypes.bool,
  onUploadFile: PropTypes.func.isRequired,
};

UploadFile.defaultProps = {
  mbSize: 10,
  isDropzoneHidden: false,
};

export default UploadFile;
