import { PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import React from 'react';
import PropTypes from 'prop-types';

import { formatCurrency } from '../../../helpers/util';

const EarmarkSummaryRow = ({ fileHash, amount, count, formValid, requestUpdate, requestClear }) => {
  return (
    <div style={{ display: 'flex', width: '600px', justifyContent: 'space-around', paddingBottom: 10 }}>
      <div style={{ width: 150 }}>Record count: {count}</div>
      <div style={{ width: 100 }}>{formatCurrency(amount)}</div>
      <div style={{ width: 70 }}>
        <PrimaryButton
          disabled={!formValid}
          type="button"
          onClick={() => requestUpdate(fileHash)}
        >
          Import
        </PrimaryButton>
      </div>
      <div style={{ width: 70 }}>
        <PrimaryButton
          type="button"
          onClick={() => requestClear(fileHash)}
        >
          Clear
        </PrimaryButton>
      </div>
    </div>
  );
};

EarmarkSummaryRow.propTypes = {
  fileHash: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  formValid: PropTypes.bool.isRequired,
  requestUpdate: PropTypes.func.isRequired,
  requestClear: PropTypes.func.isRequired,
};

export default EarmarkSummaryRow;
