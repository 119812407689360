import axios from 'axios';

export const getCampaignForEdit = async () => {
  const { data: campaign } = await axios.get('/api/filer/getCampaignForEdit', {
    withCredentials: true,
  });
  return campaign;
};

export const saveEditCampaign = async campaign => {
  await axios.put(
    '/api/account/setup',
    { campaign },
    { withCredentials: true },
  );
};

export const saveFederalNCCCampaign = async campaign => {
  await axios.put(
    '/api/account/setup/federalNcc',
    { campaign },
    { withCredentials: true },
  );
};

export const saveEditNCCCampaign = async campaign => {
  await axios.put(
    '/api/account/setup/ncc',
    { campaign },
    { withCredentials: true },
  );
};

export const recipientSave = async payload =>
  axios.post('/api/filer/expenditures?ignoreValidation=true', payload, {
    withCredentials: true,
  });

export const recipientUpdate = async payload =>
  axios.put(
    `/api/filer/expenditures/${payload._id}?ignoreValidation=true`,
    payload,
    { withCredentials: true },
  );

export const getContacts = async params => {
  const uri = `/api/filer/contacts${params}`;
  return axios.get(uri, {
    withCredentials: true,
  });
};

export const getAddress = async textString => {
  const uri = `/api/addresses?text=${encodeURIComponent(textString)}`;
  return axios.get(uri, {
    withCredentials: true,
  });
};

export const getContributions = async params => {
  const uri = `/api/filer/contributions${params}`;
  return axios.get(uri, {
    withCredentials: true,
  });
};

export const getContributionsByPromptingContribution = async params => {
  const uri = `/api/filer/contributionsPromptedBy${params}`;
  return axios.get(uri, {
    withCredentials: true,
  });
};

export const getContributionsForContactAndElection = async params => {
  const uri = `/api/filer/contributionsByContact${params}`;
  return axios.get(uri, {
    withCredentials: true,
  });
};

export const getExpenditure = async id =>
  axios.get(`/api/filer/expenditures/${id}`, { withCredentials: true });

export const getContribution = async id =>
  axios.get(`/api/filer/contributions/${id}`, { withCredentials: true });

export const getLoans = async () => axios.get('/api/filer/loans', { withCredentials: true });
