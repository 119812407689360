import { types } from '../actions/alertActions';
import { toastTypes } from '../helpers/constants';
import { createReducer } from './createReducer';

export const initialState = {
  message: null,
  toastType: toastTypes.INFO,
};

export const actionMap = {
  [types.SET_ALERT]: (
    state,
    { data: { message, toastType = toastTypes.INFO } },
  ) => {
    return { ...state, toastType, message };
  },
  [types.CLEAR_ALERT]: () => ({ ...initialState }),
};

export const alertReducer = createReducer(initialState, actionMap);
