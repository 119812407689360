import { formatPhone } from '../../helpers/util';

export const formatAddress = item => {
  if (!item.address) {
    return null;
  }

  if (item.address.addressLine2) {
    return `${item.address.addressLine1 || ''} ${item.address.addressLine2 ||
      ''}, ${item.address.city || ''}, ${item.address.state || ''} ${item
      .address.zipCode || ''}`;
  }

  return `${item.address.addressLine1 || ''}, ${item.address.city || ''}, ${item
    .address.state || ''} ${item.address.zipCode || ''}`;
};

export const getSelectedContactInfo = (state, nextUnprocessed) => {
  const { selectedExistingContact, occupationAndEmployerSwapped } = state;
  return {
    contactType: selectedExistingContact.contactType,
    contactId: selectedExistingContact._id,
    salutation:
      selectedExistingContact.salutation ||
      nextUnprocessed.salutation ||
      undefined,
    firstName:
      selectedExistingContact.firstName ||
      nextUnprocessed.firstName ||
      undefined,
    middleName:
      selectedExistingContact.middleName ||
      nextUnprocessed.middleName ||
      undefined,
    lastName:
      selectedExistingContact.lastName ||
      nextUnprocessed.middleName ||
      undefined,
    employer:
      (occupationAndEmployerSwapped
        ? selectedExistingContact.employer || nextUnprocessed.occupation
        : selectedExistingContact.employer || nextUnprocessed.employer) ||
      undefined,
    occupation:
      (occupationAndEmployerSwapped
        ? selectedExistingContact.occupation || nextUnprocessed.employer
        : selectedExistingContact.occupation || nextUnprocessed.occupation) ||
      undefined,
    address: {
      ...(selectedExistingContact.address ||
        nextUnprocessed.address || {
          addressLine1: '',
          addressLine2: '',
          city: '',
          state: '',
          zipCode: '',
        }),
    },
    email:
      selectedExistingContact.email ||
      nextUnprocessed.emailAddress ||
      undefined,
    phone1:
      selectedExistingContact.phone1 || nextUnprocessed.phone || undefined,
    phone2: selectedExistingContact.phone2 || undefined,
    businessName:
      selectedExistingContact.businessName ||
      nextUnprocessed.businessName ||
      undefined,
  };
};

export const getSearchedContactInfo = (state, nextUnprocessed) => {
  const { searchedContact, occupationAndEmployerSwapped } = state;
  return {
    contactType: searchedContact.contactType,
    contactId: searchedContact._id,
    salutation:
      searchedContact.salutation || nextUnprocessed.salutation || undefined,
    firstName:
      searchedContact.firstName || nextUnprocessed.firstName || undefined,
    middleName:
      searchedContact.middleName || nextUnprocessed.middleName || undefined,
    lastName: searchedContact.lastName || nextUnprocessed.lastName || undefined,
    employer:
      (occupationAndEmployerSwapped
        ? searchedContact.employer || nextUnprocessed.occupation
        : searchedContact.employer || nextUnprocessed.employer) || undefined,
    occupation:
      (occupationAndEmployerSwapped
        ? searchedContact.occupation || nextUnprocessed.employer
        : searchedContact.occupation || nextUnprocessed.occupation) ||
      undefined,
    address: {
      ...(searchedContact.address ||
        nextUnprocessed.address || {
          addressLine1: '',
          addressLine2: '',
          city: '',
          state: '',
          zipCode: '',
        }),
    },
    email: searchedContact.email || nextUnprocessed.emailAddress || undefined,
    phone1: searchedContact.phone1 || nextUnprocessed.phone || undefined,
    phone2: searchedContact.phone2 || undefined,
    businessName:
      searchedContact.businessName || nextUnprocessed.businessName || undefined,
  };
};

export const getNewContactInfo = state => {
  const { newContact } = state;
  return {
    contactType: newContact.contactType,
    contactId: undefined,
    salutation: newContact.salutation || undefined,
    firstName: newContact.firstName || undefined,
    middleName: newContact.middleName || undefined,
    lastName: newContact.lastName || undefined,
    employer:
      (state.occupationAndEmployerSwapped
        ? newContact.occupation
        : newContact.employer) || undefined,
    occupation:
      (state.occupationAndEmployerSwapped
        ? newContact.employer
        : newContact.occupation) || undefined,
    address: {
      ...(newContact.address || {
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        zipCode: '',
      }),
    },
    email: newContact.email || undefined,
    phone1: formatPhone(newContact.phone1) || undefined,
    phone2: formatPhone(newContact.phone2) || undefined,
    businessName: newContact.businessName || undefined,
  };
};

export const formHasErrors = errors => {
  const { dateProcessedError, contributionSplits, splitTotalError } = errors;

  const splitsHaveErrors = Object.values(contributionSplits).reduce(
    (acc, split) => {
      if (Object.values(split).some(e => e.length)) {
        acc = true;
      }
      return acc;
    },
    false,
  );

  return Boolean(
    splitsHaveErrors || dateProcessedError.length || splitTotalError.length,
  );
};

export const formatCheckNumber = checkNumber =>
  checkNumber
    ? typeof checkNumber === 'number'
      ? checkNumber.toString()
      : checkNumber
    : undefined;

export const getContactInfo = (state, nextUnprocessed) => {
  if (state.selectedExistingContact) {
    return getSelectedContactInfo(state, nextUnprocessed);
  }

  if (state.newContact) {
    return getNewContactInfo(state);
  }

  if (state.searchedContact) {
    return getSearchedContactInfo(state, nextUnprocessed);
  }

  return {};
};

export const paymentTypeMap = {
  credit_card: 'Credit Card',
};

export const getPaymentType = contribution =>
  paymentTypeMap[contribution.payment_type] || 'Credit Card';

export const createPayload = (state, nextUnprocessed) => {
  const payload = {
    contributionSplits: state.contributionSplits.map(split => ({
      electionYear: split.electionYear,
      electionCycle: split.electionCycle,
      amount: Math.round(split.amount * 100) / 100,
    })),
    contributionType: 'Monetary',
    paymentType: getPaymentType(nextUnprocessed),
    checkNumber: formatCheckNumber(nextUnprocessed.checkNumber),
    ...getContactInfo(state, nextUnprocessed),
    receivedDate: state.dateProcessed,
    description: nextUnprocessed.description,
    phone1: nextUnprocessed.phone,
    tags: ['Anedot'],
    paymentReferenceId: nextUnprocessed.paymentReferenceId || undefined,
  };

  return payload;
};
