import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions as adminUserActions } from '../../actions/adminUserActions';
import { BackButton } from '../../components/common';
import { AddEditUserForm } from '../../components/ManageUsers';
import './AddUser.css';

class EditUser extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    adminUserActions: PropTypes.object.isRequired,
    user: PropTypes.object,
  };

  static defaultProps = {
    user: {},
  };

  state = {
    user: {
      firstName: '',
      middleName: '',
      lastName: '',
      email: '',
      role: 0,
    },
    firstNameError: '',
    lastNameError: '',
    emailError: '',
    emailIsUnique: true,
    formValid: true,
    roleError: '',
  };

  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.adminUserActions.getUserForEdit(id);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.user === null &&
      this.props.user !== null &&
      this.props.user._id &&
      !this.state.user._id
    ) {
      this.setState({
        user: {
          _id: this.props.user._id,
          firstName: this.props.user.firstName,
          middleName: this.props.user.middleName,
          lastName: this.props.user.lastName,
          email: this.props.user.emailAddress,
          active: this.props.user.active,
          role: this.props.user.role,
        },
      });
    }
  }

  handleChange = fieldName => (e, value) => {
    this.setState(state => ({
      user: {
        ...state.user,
        [fieldName]: value.key || value,
      },
    }));
  };

  toggleActive = () => {
    this.setState(state => ({
      user: {
        ...state.user,
        active: !state.user.active,
      },
    }));
  };

  validateForm = state => {
    state.formValid = state.emailIsUnique && true;
    if (state.user.firstName.length === 0) {
      state.formValid = false;
      state.firstNameError = 'First name is required';
    } else {
      state.firstNameError = '';
    }

    if (state.user.lastName.length === 0) {
      state.formValid = false;
      state.lastNameError = 'Last name is required';
    } else {
      state.lastNameError = '';
    }

    if (state.user.email.length === 0) {
      state.formValid = false;
      state.emailError = 'Email is required';
    } else {
      state.emailError = '';
    }

    if (!state.user.role || state.user.role === 0) {
      state.formValid = false;
      state.roleError = 'User role is required';
    } else {
      state.roleError = '';
    }

    return state;
  };

  saveUser = () => {
    this.setState(this.validateForm, () => {
      if (this.state.formValid) {
        const {
          firstName,
          middleName = '',
          lastName,
          email: emailAddress,
          _id,
          active,
          role,
        } = this.state.user;
        const user = { firstName, middleName, lastName, emailAddress, active, role };
        this.props.adminUserActions.editUser(_id, user);
      }
    });
  };

  cancel = () => {
    this.props.history.push('/filer/manageUsers');
  };

  render() {
    const {
      user,
      firstNameError,
      lastNameError,
      emailError,
      roleError,
      formValid,
    } = this.state;

    const actions = {
      handleChange: this.handleChange,
      toggleActive: this.toggleActive,
      saveUser: this.saveUser,
      cancel: this.cancel,
    };

    return (
      <Fragment>
        <BackButton history={this.props.history} />
        <div className="AddEditUser depth-1">
          <h3>Edit User</h3>
          <AddEditUserForm
            user={user}
            actionType="edit"
            formValid={formValid}
            firstNameError={firstNameError}
            lastNameError={lastNameError}
            emailError={emailError}
            roleError={roleError}
            actions={actions}
          />
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.adminUsers.userForEdit,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    adminUserActions: bindActionCreators(adminUserActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditUser);
