import React from 'react';
import PropTypes from 'prop-types';

const SectionLabel = ({ label, labelLines }) => {
  const style = {
    marginLeft: '14px',
  };

  return (
    <p>
      {label}<span style={style}>{labelLines[0]}</span>
      {
        labelLines.slice(1).map(line => {
          return (<div style={{ marginLeft: '26px' }}>{line}</div>);
        })
      }
    </p>
  );
};

SectionLabel.propTypes = {
  labelLines: PropTypes.array,
  label: PropTypes.string,
};

export default SectionLabel;
