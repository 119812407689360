import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from 'react-stripe-elements';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { Label } from 'office-ui-fabric-react/lib/Label';
import {
  MessageBar,
  MessageBarType,
} from 'office-ui-fabric-react/lib/MessageBar';
import { Grid, GridRow, Column, EmailField, ZipCodeField } from './common';
import { scrollTo } from '../helpers/util';
import './CreditCardForm.css';

const elementStyle = {
  base: {
    color: 'rgb(1, 50, 67)',
    fontWeight: 100,
    '::placeholder': {
      color: '#a6a6a6',
      fontWeight: 100,
      fontSize: '14px',
    },
  },
  invalid: {
    color: '#E25950',

    '::placeholder': {
      color: '#FFCCA5',
    },
  },
};

export const CreditCardForm = ({
  ccInfo: {
    cardholderName,
    cardholderNameError,
    cardholderEmail,
    cardholderEmailError,
    zipCode,
    zipCodeError,
    coupon,
  },
  successMessage,
  formSection,
  planPrice,
  productName,
  stripeError,
  actions,
  showCoupon,
  onBlurCoupon,
  couponError,
  couponAmountOff,
}) => {
  const focusRef = useRef();

  useEffect(() => {
    scrollTo(0, 0);
    focusRef.current.focus();
  }, []);

  const getPlanPrice = (planPrice, amountOff) => {
    let newCost;
    const [, cost] = planPrice.split('$');
    const [amount, interval] = cost.split('/');
    newCost = parseFloat(amount);
    if (typeof amountOff === 'string') {
      const [percent] = amountOff.split('%');
      newCost = `$${newCost - newCost * (percent / 100)}/${interval}`;
    } else {
      newCost = `$${(newCost - amountOff / 100).toFixed(2)}/${interval}`;
    }
    return newCost;
  };

  return (
    <div className="CreditCardForm">
      {productName && planPrice && (
        <div className="pricing">
          <h4>{productName}</h4>
          <h2>{getPlanPrice(planPrice, couponAmountOff)}</h2>
          <p>
            Your credit card will be charged the above amount which will
            automatically renew monthly until cancelled. You will receive an
            emailed invoice prior to your next billing cycle and a receipt each
            time your subscription is renewed. If you wish to cancel your
            service, please contact us using the chat option below or through
            our website at{' '}
            <a href="https://www.tryfrontrunner.com">www.tryfrontrunner.com</a>.
          </p>
        </div>
      )}
      <Grid className="fields">
        {stripeError && (
          <GridRow>
            <Column>
              <MessageBar messageBarType={MessageBarType.error}>
                {stripeError}
              </MessageBar>
            </Column>
          </GridRow>
        )}
        {successMessage && (
          <GridRow>
            <Column>
              <MessageBar messageBarType={MessageBarType.success}>
                {successMessage}
              </MessageBar>
            </Column>
          </GridRow>
        )}
        <GridRow>
          <Column>
            <TextField
              value={cardholderName}
              name="cardholderName"
              errorMessage={cardholderNameError}
              onChange={actions.handleTextChange('cardholderName')}
              label="Cardholder Name"
              placeholder="John R Smith"
              componentRef={focusRef}
            />
          </Column>
        </GridRow>
        <GridRow>
          <Column>
            <label className="stripe-element">
              <Label>Card Number</Label>
              <CardNumberElement value="test" style={elementStyle} />
            </label>
          </Column>
        </GridRow>
        <GridRow>
          <Column lg={4}>
            <label className="stripe-element">
              <Label>Expiration</Label>
              <CardExpiryElement style={elementStyle} />
            </label>
          </Column>
          <Column lg={4}>
            <label className="stripe-element">
              <Label>CVC Number</Label>
              <CardCvcElement style={elementStyle} />
            </label>
          </Column>
          <Column lg={4}>
            <ZipCodeField
              style={{
                subComponentStyles: {
                  label: {
                    marginTop: 8,
                  },
                },
              }}
              value={zipCode}
              name="zipCode"
              errorMessage={zipCodeError}
              onChange={actions.handleTextChange('zipCode')}
              placeholder="12345"
            />
          </Column>
        </GridRow>
        <GridRow>
          <Column>
            <EmailField
              value={cardholderEmail}
              formSection={formSection}
              onChange={actions.handleTextChange('cardholderEmail')}
              placeholder="JohnRSmith@mail.com"
              label="Cardholder Email"
              errorMessage={cardholderEmailError}
            />
          </Column>
        </GridRow>
        {showCoupon && (
          <GridRow>
            <Column md={6}>
              <TextField
                label="Coupon Code"
                errorMessage={couponError}
                value={coupon}
                name="coupon"
                onChange={actions.handleTextChange('coupon')}
                onBlur={onBlurCoupon}
              />
            </Column>
          </GridRow>
        )}
      </Grid>
    </div>
  );
};

CreditCardForm.propTypes = {
  ccInfo: PropTypes.shape({
    cardholderEmail: PropTypes.string,
    cardholderEmailError: PropTypes.string,
    cardholderName: PropTypes.string,
    cardholderNameError: PropTypes.string,
    zipCode: PropTypes.string,
    zipCodeError: PropTypes.string,
    coupon: PropTypes.string,
  }),
  formSection: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  planPrice: PropTypes.string,
  productName: PropTypes.string,
  planPriceStatus: PropTypes.string,
  actions: PropTypes.object.isRequired,
  stripeError: PropTypes.string,
  successMessage: PropTypes.string,
  showCoupon: PropTypes.bool,
  onBlurCoupon: PropTypes.func,
  couponError: PropTypes.string,
  couponAmountOff: PropTypes.number,
};

CreditCardForm.defaultProps = {
  planPrice: '',
  productName: '',
  planPriceStatus: '',
  formSection: '',
  successMessage: '',
  showCoupon: false,
  onBlurCoupon: () => {},
  couponError: '',
  couponAmountOff: 0,
};

export default CreditCardForm;
