import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { DefaultButton } from 'office-ui-fabric-react/lib/Button';
import ToastPortal from '../Toast/ToastPortal';
import { toastTypes } from '../../helpers/constants';
import { actions } from '../../actions/alertActions';
import './Alert.css';

const iconMap = {
  [toastTypes.INFO]: 'InfoCircle',
  [toastTypes.SUCCESS]: 'CheckCircle',
  [toastTypes.ERROR]: 'Times',
  [toastTypes.WARNING]: 'ExclamationTriangle',
};

export const Alert = ({ toastType, message, actions }) => {
  const className = ['Alert', toastType].join(' ');

  return message ? (
    <ToastPortal>
      {message ? (
        <div className={className}>
          <Icon
            iconName={iconMap[toastType] || ''}
            styles={{
              root: {
                fontSize: '28px',
              },
            }}
          />
          <p>{message}</p>
          <DefaultButton text="Dismiss" onClick={actions.clear} />
        </div>
      ) : null}
    </ToastPortal>
  ) : null;
};

Alert.propTypes = {
  toastType: PropTypes.oneOf([
    toastTypes.INFO,
    toastTypes.SUCCESS,
    toastTypes.ERROR,
    toastTypes.WARNING,
  ]),
  message: PropTypes.string.isRequired,
  actions: PropTypes.object.isRequired,
};

Alert.defaultProps = {
  toastType: toastTypes.INFO,
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(
  null,
  mapDispatchToProps,
)(Alert);
