import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Image } from 'office-ui-fabric-react/lib/Image';
import { Link } from 'office-ui-fabric-react/lib/Link';
import {
  PrimaryButton,
  DefaultButton,
} from 'office-ui-fabric-react/lib/Button';
import {
  MessageBar,
  MessageBarType,
} from 'office-ui-fabric-react/lib/MessageBar';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import FRLogo from '../../assets/img/HRFrontRunner.png';
import { userActions } from '../../actions/userActions';
import { statuses } from '../../helpers/constants';
import { Grid, GridRow, Column, PasswordField } from '../../components/common';
import './ResetPassword.css';

export class ResetPassword extends Component {
  static displayName = 'ResetPassword';

  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
  };

  constructor(props, ...rest) {
    super(props, ...rest);

    this.state = {
      newPassword: '',
      newPasswordError: '',
      confirmNewPassword: '',
      confirmNewPasswordError: '',
      passwordResetToken: '',
      formValid: true,
    };

    props.actions.clearPasswordResetStatus();
  }

  componentDidMount() {
    let [, passwordResetToken = ''] = this.props.location.pathname.split(
      'passwordReset',
    );
    passwordResetToken = passwordResetToken && passwordResetToken.split('/')[1];
    if (passwordResetToken) {
      this.setState(() => ({ passwordResetToken }));
    }
  }

  validate = state => {
    state.formValid = true;
    state.newPasswordError = '';
    state.confirmPasswordError = '';

    if (state.newPassword.length === 0) {
      state.formValie = false;
      state.newPasswordError = 'New password is required';
    }

    if (state.confirmNewPassword.length === 0) {
      state.formValid = false;
      state.confirmNewPasswordError = 'Confirm new password is required';
    }

    if (
      state.newPassword &&
      state.confirmNewPassword &&
      state.newPassword !== state.confirmNewPassword
    ) {
      state.newPasswordError = '';
      state.formValid = false;
      state.confirmNewPasswordError = 'Passwords do not match';
    }

    return state;
  };

  resetPassword = () => {
    this.setState(this.validate, () => {
      if (this.state.formValid) {
        this.props.actions.resetPassword(
          this.state.newPassword,
          this.state.confirmNewPassword,
          this.state.passwordResetToken,
        );
      }
    });
  };

  cancel = () => {
    this.props.history.push('/');
  };

  handlePasswordChange = (e, newPassword) => {
    this.setState({ newPassword });
  };

  handleConfirmPasswordChange = (e, confirmNewPassword) => {
    this.setState({ confirmNewPassword });
  };

  render() {
    const {
      props: { user },
      state: {
        newPassword,
        newPasswordError,
        confirmNewPassword,
        confirmNewPasswordError,
      },
    } = this;
    return (
      <div className="ResetPassword depth-1">
        <Grid>
          <GridRow>
            <Column md={6} classNames="ms-mdPush3 ms-lgPush3 ms-xlPush3 logo">
              <Image src={FRLogo} alt="FrontRunner" height={150} />
            </Column>
          </GridRow>
        </Grid>
        <h3 className="ms-textAlignCenter">reset password</h3>
        {user.passwordResetStatus === statuses.ERROR && (
          <MessageBar messageBarType={MessageBarType.error}>
            {user.passwordResetError}
          </MessageBar>
        )}
        {(user.passwordResetStatus === statuses.NOT_STARTED ||
          user.passwordResetStatus === statuses.ERROR) && (
          <Fragment>
            <Grid>
              <GridRow>
                <Column sm={6} classNames="ms-smPush3">
                  <PasswordField
                    label="New Password"
                    value={newPassword}
                    required
                    errorMessage={newPasswordError}
                    onChange={this.handlePasswordChange}
                  />
                </Column>
              </GridRow>
            </Grid>
            <Grid>
              <GridRow>
                <Column sm={6} classNames="ms-smPush3">
                  <PasswordField
                    label="Confirm New Password"
                    value={confirmNewPassword}
                    required
                    errorMessage={confirmNewPasswordError}
                    onChange={this.handleConfirmPasswordChange}
                  />
                </Column>
              </GridRow>
            </Grid>
          </Fragment>
        )}
        {user.passwordResetStatus === statuses.SUCCESS && (
          <MessageBar messageBarType={MessageBarType.success}>
            {'Your password has been reset'}
          </MessageBar>
        )}
        <Grid className="actions">
          <GridRow>
            {(user.passwordResetStatus === statuses.NOT_STARTED ||
              user.passwordResetStatus === statuses.ERROR) && (
              <Column classNames="ms-textAlignCenter">
                <DefaultButton
                  className="cancel-btn"
                  text="Cancel"
                  onClick={this.cancel}
                />
                <PrimaryButton
                  text="Reset Password"
                  onClick={this.resetPassword}
                />
              </Column>
            )}
            {user.passwordResetStatus === statuses.PROCESSING && (
              <Column classNames="ms-textAlignCenter">
                <Spinner size={SpinnerSize.large} />
              </Column>
            )}
            {user.passwordResetStatus === statuses.SUCCESS && (
              <Column classNames="ms-textAlignCenter">
                <Link onClick={this.cancel}>Return to Login Screen</Link>
              </Column>
            )}
          </GridRow>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(userActions, dispatch),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ResetPassword),
);
