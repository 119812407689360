import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { Grid, GridRow, Column } from '../../common';
import WarningBox from './WarningBox';

export default class Section3c extends Component {
  static propTypes = {
    q3c: PropTypes.shape({
      amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }).isRequired,
    handleChange: PropTypes.func.isRequired,
  };

  constructor(...args) {
    super(...args);

    this.amountBox = React.createRef();
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextProps.q3c.amount !== this.props.q3c.amount ||
      nextState.amountCalloutVisible !== this.state.amountCalloutVisible ||
      nextState.canEdit !== this.state.canEdit
    );
  }

  state = {
    amountCalloutVisible: false,
    canEdit: false,
  };

  openCallout = () => {
    this.setState(() => ({
      amountCalloutVisible: true,
    }));
  };

  closeCallout = () => {
    this.setState(() => ({
      amountCalloutVisible: false,
    }));
  };

  acceptEdit = () => {
    this.setState({
      canEdit: true,
      inKindCalloutVisible: false,
      amountCalloutVisible: false,
    });
  };

  render() {
    const { q3c, handleChange } = this.props;
    const { amountCalloutVisible, canEdit } = this.state;

    return (
      <GridRow>
        <Column sm={1} classNames="ms-textAlignRight">
          <h3 className="form-label">{'3c'}</h3>
        </Column>
        <Column sm={11}>
          <Grid>
            <GridRow>
              <Column sm={8}>
                <p>Total amount of investments sold this reporting period.</p>
              </Column>
              <Column sm={2} classNames="ms-smPush2">
                <span ref={this.amountBox}>
                  <TextField
                    borderless
                    inputMode="decimal"
                    styles={{
                      padding: '0 4px',
                      fieldGroup: { borderBottom: '1px solid #999' },
                      field: { textAlign: 'center' },
                    }}
                    value={q3c.amount}
                    onChange={
                      canEdit ? handleChange('q3c')('amount') : () => {}
                    }
                    onClick={this.openCallout}
                  />
                </span>
              </Column>
            </GridRow>
          </Grid>
          {amountCalloutVisible && !canEdit ? (
            <WarningBox
              target={this.amountBox.current}
              onDismiss={this.closeCallout}
              onAccept={this.acceptEdit}
            />
          ) : null}
        </Column>
      </GridRow>
    );
  }
}
