import { put, call, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import { push } from 'connected-react-router';
import { types } from '../actions/createCampaignActions';
import { types as userTypes } from '../actions/userActions';
import { getServerSideErrorMessage } from '../helpers/util';

const url = '/api/account/setup';
export function* handleCreateCampaign(action) {
  try {
    const { payload } = action;
    yield put({ type: types.CREATE_CAMPAIGN_PROCESSING });
    const {
      data: { session, username },
    } = yield call(axios.post, url, payload, { withCredentials: true });
    session.officeType = session.officeType || payload.office.officeType;
    session.officeState = session.officeState || payload.office.officeState;
    yield put({ type: types.CREATE_CAMPAIGN_SUCCESS });
    yield put({
      type: userTypes.USER_LOGIN_SUCCESS,
      session: { ...session, username },
    });
    yield put(push('/filer'));
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put({ type: types.CREATE_CAMPAIGN_FAILURE, error });
  }
}

export function* createCampaign() {
  yield takeLatest(types.CREATE_CAMPAIGN, handleCreateCampaign);
}

export function* handleCreateCommitteeCampaign(action) {
  try {
    const { payload } = action;
    const {
      data: { session, username },
    } = yield call(axios.post, `${url}/ncc`, payload, {
      withCredentials: true,
    });
    yield put({ type: types.CREATE_COMMITTEE_CAMPAIGN_SUCCESS });
    yield put({
      type: userTypes.USER_LOGIN_SUCCESS,
      session: { ...session, username },
    });
    yield put(push('/filer'));
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put({ type: types.CREATE_COMMITTEE_CAMPAIGN_FAILURE, error });
  }
}

export function* createCommitteeCampaign() {
  yield takeLatest(
    types.CREATE_COMMITTEE_CAMPAIGN,
    handleCreateCommitteeCampaign,
  );
}

export function* handleCreateFederalPacCampaign(action) {
  try {
    const { payload } = action;
    const {
      data: { session, username },
    } = yield call(axios.post, `${url}/federalNcc`, payload, {
      withCredentials: true,
    });
    yield put({ type: types.CREATE_FEDERAL_PAC_CAMPAIGN_SUCCESS });
    yield put({
      type: userTypes.USER_LOGIN_SUCCESS,
      session: { ...session, username },
    });
    yield put(push('/filer'));
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put({ type: types.CREATE_FEDERAL_PAC_CAMPAIGN_FAILURE, error });
  }
}

export function* createFederalPacCampaign() {
  yield takeLatest(
    types.CREATE_FEDERAL_PAC_CAMPAIGN,
    handleCreateFederalPacCampaign,
  );
}
