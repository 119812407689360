import axios from 'axios';
import React, {
  Fragment,
  useEffect,
  useReducer,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  PrimaryButton,
  CheckboxVisibility,
  DetailsList,
  DetailsListLayoutMode,
  Icon,
  SelectionMode,
} from 'office-ui-fabric-react';
import { DetailsRow } from 'office-ui-fabric-react/lib/DetailsList';

import PropTypes from 'prop-types';
import { BackButton } from '../../components/common';
import { toastTypes } from '../../helpers/constants';
import {
  getIntegrationSettingsList,
  getUserSession,
} from '../../selectors';

import './index.css';
import { IntegrationSettingDialog } from '../../components/Dialogs/IntegrationSettingDialog';
import ConfirmDeleteDialog from '../../components/Dialogs/ConfirmDeleteDialog';
import { types as integrationSettingActions } from '../../actions/integrationSettingActions';
import {
  formatDate,
} from '../../helpers/util';

import clipBoardHelper from '../../helpers/clipboardHelper';
import { apiIntegrationReducer, initialState, actions } from './apiIntegrationReducer';
import { IntegrationSettingPanel } from '../../components/Panels';
import { types as messagingActions } from '../../actions/messagingActions';
import { types as validationActions } from '../../actions/validationActions';

const ApiIntegration = ({ history }) => {
  const reduxDispatch = useDispatch();
  const [state, localDispatch] = useReducer(apiIntegrationReducer, initialState);
  const integrationSettingsList = useSelector(getIntegrationSettingsList);
  const messages = useSelector(state => state.messages);
  const campaign = useSelector(state => state.currentCampaign.campaign);
  const session = useSelector(getUserSession);

  const getIntegrationSettingList = () => {
    reduxDispatch({
      type: integrationSettingActions.GET_INTEGRATION_SETTINGS,
    });
  };

  useEffect(() => {
    getIntegrationSettingList();
  }, []);

  const mapIntegrationSettingList = () => {
    return integrationSettingsList.map(integration => ({
      ...integration,
      key: integration._id,
    }));
  };

  const getLabelWithTitle = (value) => {
    return <span title={value}>{value}</span>;
  };

  const getActive = (item) => {
    const color = item?.active ? 'lightgreen' : 'red';
    const title = item?.active ? 'Integration is active' : 'Integration is not active';
    return (
      <Icon
        iconName="Circle"
        title={title}
        styles={{
          root: {
            fontSize: '10pt',
            backgroundColor: color,
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 18,
            height: 18,
            color,
            borderRadius: '50%',
          },
        }}
      />
    );
  };

  const getAutoImport = (item) => {
    const color = item?.automaticallyImport ? 'lightgreen' : 'red';
    const title = item?.automaticallyImport ? 'Auto import is active' : 'Auto import is not active';
    return (
      <Icon
        iconName="Circle"
        title={title}
        styles={{
          root: {
            fontSize: '10pt',
            backgroundColor: color,
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 18,
            height: 18,
            color,
            borderRadius: '50%',
          },
        }}
      />
    );
  };

  const copyClick = (e, value) => {
    e.preventDefault();
    e.stopPropagation();
    clipBoardHelper.copyToClipboard(value);
    reduxDispatch({
      type: messagingActions.SET_TOAST,
      data: {
        message: 'Copied successfully to clip board',
        toastType: toastTypes.SUCCESS,
      },
    });
  };

  const getCopyButton = (item) => {
    if (item.integrationPartner === 'WinRed') {
      const toClip = `https://webhooks.frontrunner.app/winred/webhook/${item.accessToken}`;
      return <button type="button" onClick={(e) => copyClick(e, toClip)}>Copy URL</button>;
    }
    if (item.integrationPartner === 'DonateRight') {
      const toClip = `https://webhooks.frontrunner.app/donateRight/transactions?access_token=${item.accessToken}`;
      return <button type="button" onClick={(e) => copyClick(e, toClip)}>Copy URL</button>;
    }

    return <button type="button" onClick={(e) => copyClick(e, item.accessToken)}>Copy Token</button>;
  };

  const createColumns = () => [
    {
      key: 'isActive',
      name: 'Active',
      minWidth: 50,
      maxWidth: 50,
      onRender: item => getActive(item?.integrationSetting),
    },
    {
      key: 'autoImport',
      name: 'Auto Import',
      minWidth: 110,
      maxWidth: 110,
      onRender: item => getAutoImport(item?.integrationSetting),
    },
    {
      key: 'integrationPartner',
      name: 'Integration Partner',
      minWidth: 150,
      maxWidth: 150,
      onRender: item => getLabelWithTitle(item?.integrationSetting?.integrationPartner),
    },
    {
      key: 'memoText',
      name: 'Memo Text',
      minWidth: 300,
      maxWidth: 300,
      onRender: item => getLabelWithTitle(item.integrationSetting.memoText),
    },
    {
      key: 'conduit',
      name: 'Conduit',
      minWidth: 100,
      maxWidth: 100,
      onRender: item => getLabelWithTitle(item.conduitContactUser?.displayName || ''),
    },
    {
      key: 'token',
      name: 'Access Token',
      minWidth: 250,
      maxWidth: 280,
      onRender: item => getLabelWithTitle(item.integrationSetting.accessToken),
    },
    {
      key: 'created',
      name: 'Created',
      minWidth: 130,
      maxWidth: 140,
      onRender: item => getLabelWithTitle(`${formatDate(item?.integrationSetting?.createdAt)} ${item?.createdByUser?.displayName}`),
    },
    {
      key: 'updated',
      name: 'Updated',
      minWidth: 130,
      maxWidth: 140,
      onRender: item => getLabelWithTitle(`${formatDate(item?.integrationSetting?.updatedAt)} ${item?.updatedByUser?.displayName}`),
    },
    {
      key: 'copy',
      name: 'Copy',
      minWidth: 130,
      maxWidth: 140,
      onRender: item => getCopyButton(item?.integrationSetting),
    },
  ];

  const openNewPanel = (selectedRow) => {
    localDispatch({
      type: actions.SET_SELECTED_ROW,
      data: { selectedRow },
    });
  };

  const hidePreviewPanel = () => {
    localDispatch({
      type: actions.HIDE_PREVIEW_PANEL,
    });
  };

  const showAddUpdateDialog = (formAction) => {
    localDispatch({
      type: actions.SHOW_ADD_UPDATE_DIALOG,
      data: { formAction },
    });
  };

  const hideAddUpdateDialog = () => {
    localDispatch({
      type: actions.HIDE_ADD_UPDATE_DIALOG,
    });
  };

  const onRenderRow = (props) => {
    return (
      <div onClick={() => openNewPanel(props.item)}>
        <DetailsRow {...props} />
      </div>
    );
  };

  const onSave = async (payload, formAction) => {
    if (formAction === 'new') {
      await axios.post('/api/filer/integrationSettings', payload);
      hideAddUpdateDialog();
      getIntegrationSettingList();
    } else {
      await axios.put(`/api/filer/integrationSettings/${state.selectedRow?.integrationSetting?._id}`, payload);
      hideAddUpdateDialog();
      getIntegrationSettingList();
    }
  };

  const hideDeleteConfirmDialog = () => {
    localDispatch({
      type: actions.HIDE_DELETE_CONFIRM_DIALOG,
    });
  };

  const doOnDelete = async () => {
      await axios.delete(`/api/filer/integrationSettings/${state.selectedRow?.integrationSetting?._id}`);
      hideAddUpdateDialog();
      hideDeleteConfirmDialog();
      getIntegrationSettingList();
  };

  const onCancelEditDialog = () => {
    hideAddUpdateDialog();
  };

  const onDelete = () => {
    localDispatch({
      type: actions.SHOW_DELETE_CONFIRM_DIALOG,
    });
  };

  return (
    <Fragment>
      <BackButton history={history} message="Cancel" />
      <div className="ApiIntegration">
        <h2>API Integrations</h2>
        <PrimaryButton
          text="Add New Integration"
          onClick={() => showAddUpdateDialog('new')}
          style={{ float: 'right', marginTop: '-48px' }}
        />
        <div className="filed-reports depth-1">
          <h3>Current API Integrations</h3>
          {integrationSettingsList.length === 0 && (
            <div className="no-filed-reports">
              <Icon
                iconName="Upload"
                style={{ fontSize: 40, color: '#99999999' }}
              />
              <p>There are no api integrations for this campaign.</p>
            </div>
          )}
          {integrationSettingsList.length > 0 && (
            <DetailsList
              items={mapIntegrationSettingList()}
              columns={createColumns()}
              checkboxVisibility={CheckboxVisibility.none}
              compact={false}
              selectionMode={SelectionMode.none}
              layoutMode={DetailsListLayoutMode.justified}
              onRenderRow={onRenderRow}
            />
          )}
        </div>
      </div>
      <IntegrationSettingPanel
        item={state.selectedRow}
        showPanel={state.showPreviewPanel}
        closePanel={hidePreviewPanel}
        onEditItem={() => showAddUpdateDialog('update')}
      />
      <IntegrationSettingDialog
        formAction={state.formAction}
        dialogHidden={state.hideAddUpdateDialog}
        integrationSetting={state.selectedRow}
        session={session}
        onSave={onSave}
        onCancel={onCancelEditDialog}
        onDelete={onDelete}
        campaign={campaign}
        messagingActions={messagingActions}
        validationActions={validationActions}
        saveButtonDisabled={state.saveButtonDisabled}
        messages={messages}
      />
      <ConfirmDeleteDialog
        dialogHidden={state.hideDeleteConfirmDialog}
        cancel={hideDeleteConfirmDialog}
        confirm={doOnDelete}
        itemType="Integration Setting"
        message="This will permanently remove this integration setting and any associated integrations will stop functioning until provided a new setting."
      />
    </Fragment>
  );
};

ApiIntegration.propTypes = {
  history: PropTypes.object.isRequired,
};

export default ApiIntegration;
