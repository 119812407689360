export const validate = state => {
  const errors = {
    usernameError: '',
    passwordError: '',
  };

  if (state.username.length === 0) {
    errors.usernameError = 'Username is required';
  }

  if (state.password.length === 0) {
    errors.passwordError = 'Password is required';
  }

  return errors;
};
