export function getQueryParams(search = '') {
  if (search.length) {
    const [, queryParams] = search.split('?');
    const parts = queryParams.split('&');
    const vars = parts.reduce((acc, part) => {
      const [name, value] = part.split('=');
      acc[name] = value;
      return acc;
    }, {});

    return vars;
  }
  return {};
}
