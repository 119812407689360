import { createReducer } from '../../reducers/createReducer';
import { FECReportingFrequencies } from '../../helpers/constants';

export const initialState = {
  reportType: '',
  reportDate: '',
  startDate: '',
  endDate: '',
  reportingFrequency: '',
  previousReportId: '0',
  isAutomaticConduit: true,
  errors: {
    reportTypeError: '',
    reportDateError: '',
    startDateError: '',
    endDateError: '',
    previousReportIdError: '',
  },
};

export const actions = {
  HANDLE_CHANGE: 'HANDLE_CHANGE',
  FORM_ERRORS: 'FORM_ERRORS',
};

const actionMap = {
  [actions.HANDLE_CHANGE]: (state, { data: { fieldName, value } }) => {
    if (
      fieldName === 'reportingFrequency' &&
      value === FECReportingFrequencies.Monthly
    ) {
      return {
        ...state,
        reportingFrequency: value,
        reportType: 'MY',
      };
    }
    if (
      fieldName === 'reportingFrequency' &&
      state.reportingFrequency !== value &&
      value === FECReportingFrequencies.Quarterly
    ) {
      return {
        ...state,
        reportingFrequency: value,
        reportType: '',
      };
    }
    return {
      ...state,
      [fieldName]: value,
    };
  },
  [actions.FORM_ERRORS]: (state, action) => ({
    ...state,
    errors: action.data.errors,
  }),
};

export const addFederalReportReducer = createReducer(initialState, actionMap);
