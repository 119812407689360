import { takeEvery, put, call, debounce } from 'redux-saga/effects';
import axios from 'axios';
import { types as integrationSettingTypes } from '../actions/integrationSettingActions';
import { types as messagingTypes } from '../actions/messagingActions';
import { getServerSideErrorMessage, errorToast } from '../helpers/util';
import { toastTypes } from '../helpers/constants';

const url = '/api/filer/integrationSettings';

export function* handleGetIntegrationSettings() {
  try {
    yield put({ type: integrationSettingTypes.GET_INTEGRATION_SETTINGS_PROCESSING });
    const { data: integrationSettings } = yield call(axios.get, url, {
      withCredentials: true,
    });
    yield put({
      type: integrationSettingTypes.GET_INTEGRATION_SETTINGS_SUCCESS,
      data: { integrationSettings } });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put(errorToast(error));
  }
}

export function* getIntegrationSettings() {
  yield debounce(
    1000,
    integrationSettingTypes.GET_INTEGRATION_SETTINGS,
    handleGetIntegrationSettings,
  );
}

export function* handleCreateIntegrationSetting(action) {
  try {
    const { integrationSetting } = action.data;
    yield call(axios.post, url, integrationSetting, {
      withCredentials: true,
    });
    yield put({
      type: integrationSettingTypes.CREATE_INTEGRATION_SETTING_SUCCESS,
    });
    yield put({
      type: messagingTypes.SET_TOAST,
      data: {
        message: 'IntegrationSetting added successfully!',
        toastType: toastTypes.SUCCESS,
      },
    });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put(errorToast(error));
  }
}

export function* createIntegrationSetting() {
  yield takeEvery(
    integrationSettingTypes.CREATE_INTEGRATION_SETTING,
    handleCreateIntegrationSetting,
  );
}

export function* handleUpdateIntegrationSetting(action) {
  try {
    const { _id, integrationSetting } = action.data;
    yield call(
      axios.put,
      `${url}/${_id}`,
      integrationSetting,
      { withCredentials: true },
    );
    yield put({
      type: integrationSettingTypes.UPDATE_INTEGRATION_SETTING_SUCCESS,
    });
    yield put({
      type: messagingTypes.SET_TOAST,
      data: {
        message: 'IntegrationSetting updated successfully!',
        toastType: toastTypes.SUCCESS,
      },
    });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put(errorToast(error));
  }
}

export function* updateIntegrationSetting() {
  yield takeEvery(
    integrationSettingTypes.UPDATE_INTEGRATION_SETTING,
    handleUpdateIntegrationSetting,
  );
}
