import { sortDirectionMap, formatISODate } from '../../helpers/util';

export const buildParams = (state, filterText, startDate, endDate, checkForNoEmployeeOrOccupation) => {
  const params = [
    `?limit=100&skip=${state.skip * 100}`,
    filterText.length ? `&search=${filterText}` : '',
    startDate ? `&startDate=${formatISODate(startDate)}` : '',
    endDate ? `&endDate=${formatISODate(endDate)}` : '',
    checkForNoEmployeeOrOccupation ? `&checkNoEmpOccup=${checkForNoEmployeeOrOccupation}` : '',
    state.sortBy ? `&sortBy=${state.sortBy}` : '',
    sortDirectionMap[state.sortDir]
      ? `&sortDir=${sortDirectionMap[state.sortDir]}`
      : '',
  ]
    .filter(Boolean)
    .join('');

  return params;
};
