import { isInvalidDate } from '../../helpers/util';
import { frCampaignTypes } from '../../helpers/constants';

export const validate = state => {
  const errors = {};

  if (!state.electionDate && state.frCampaignType !== frCampaignTypes.FederalNonCandidate) {
    errors.electionDateError = 'Election date is required';
  }

  if (!state.reportCode) {
    errors.reportCodeError = 'Report type is required';
  }

  if (!state.startDate) {
    errors.startDateError = 'Start date is required';
  } else if (isInvalidDate(state.startDate)) {
    errors.startDateError = 'Invalid date';
  }

  if (!state.endDate) {
    errors.endDateError = 'End date is required';
  } else if (isInvalidDate(state.endDate)) {
    errors.endDateError = 'Invalid date';
  }

  if (
    state.startDate &&
    !isInvalidDate(state.startDate) &&
    state.endDate &&
    !isInvalidDate(state.endDate) &&
    state.startDate.getTime() > state.endDate.getTime()
  ) {
    errors.startDateError = 'Start date cannot be after end date';
    errors.endDateError = 'End date cannot be before start date';
  }

  if (state.previousReportId === '0') {
    errors.previousReportIdError = 'Previous balance source is required';
  }

  return errors;
};
