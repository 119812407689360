import React from 'react';
import PropTypes from 'prop-types';
import {
  Separator,
  Checkbox,
  TextField,
  ChoiceGroup,
} from 'office-ui-fabric-react';
import { GridRow, Column, MoneyField } from '../common';
import { DatePicker } from '../Pickers';

const FederalIndividualLoan = ({
  cumulativePaymentToDate,
  cumulativePaymentToDateError,
  outstandingBalance,
  outstandingBalanceError,
  isPersonalFundsOfCandidate,
  dueDate,
  dueDateError,
  interestRate,
  interestRateError,
  isSecured,
  isSecuredError,
  actions,
}) => (
  <div className="individual-federal-loan" style={{ padding: '0 8px' }}>
    <Separator />
    <GridRow>
      <Column md={4}>
        <MoneyField
          label="Cumulative Payment To Date"
          value={cumulativePaymentToDate}
          required
          errorMessage={cumulativePaymentToDateError}
          onChange={actions.handleChange('cumulativePaymentToDate')}
        />
      </Column>
      <Column md={4}>
        <MoneyField
          label="Balance Outstanding at the Close of this Period"
          value={outstandingBalance}
          required
          errorMessage={outstandingBalanceError}
          onChange={actions.handleChange('outstandingBalance')}
        />
      </Column>
      <Column md={4} style={{ marginTop: 34 }}>
        <Checkbox
          label="Personal Funds of the Candidate"
          checked={isPersonalFundsOfCandidate}
          onChange={actions.handleChange('isPersonalFundsOfCandidate')}
        />
      </Column>
    </GridRow>
    <GridRow>
      <Column md={4}>
        <DatePicker
          label="Due Date"
          value={dueDate}
          required
          errorMessage={dueDateError}
          onChange={actions.handleDateChange('dueDate')}
        />
      </Column>
      <Column md={4}>
        <TextField
          label="Interest Rate"
          value={interestRate}
          required
          errorMessage={interestRateError}
          onChange={actions.handleChange('interestRate')}
          suffix="% (APR)"
        />
      </Column>
      <Column md={3} classNames="ms-mdPush1">
        <ChoiceGroup
          selectedKey={isSecured}
          onChange={actions.handleChange('isSecured')}
          label="Secured"
          required
          options={[
            {
              key: true,
              text: 'Yes',
              iconProps: {
                iconName: 'Check',
              },
            },
            {
              key: false,
              text: 'No',
              iconProps: {
                iconName: 'Times',
              },
            },
          ]}
        />
        {isSecuredError && <p className="error-message">{isSecuredError}</p>}
      </Column>
    </GridRow>
  </div>
);

FederalIndividualLoan.propTypes = {
  actions: PropTypes.object.isRequired,
  cumulativePaymentToDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  cumulativePaymentToDateError: PropTypes.string,
  outstandingBalance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  outstandingBalanceError: PropTypes.string,
  isPersonalFundsOfCandidate: PropTypes.bool,
  dueDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  dueDateError: PropTypes.string,
  interestRate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  interestRateError: PropTypes.string,
  isSecured: PropTypes.bool,
  isSecuredError: PropTypes.string,
};

FederalIndividualLoan.defaultProps = {
  cumulativePaymentToDate: 0,
  cumulativePaymentToDateError: '',
  outstandingBalance: 0,
  outstandingBalanceError: '',
  isPersonalFundsOfCandidate: false,
  dueDate: new Date(),
  dueDateError: '',
  interestRate: 0,
  interestRateError: '',
  isSecured: false,
  isSecuredError: '',
};

export default FederalIndividualLoan;
