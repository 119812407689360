import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogType,
  DialogFooter,
} from 'office-ui-fabric-react/lib/Dialog';
import {
  DefaultButton,
} from 'office-ui-fabric-react/lib/Button';
import './GenericMessageDialog.css';

const GenericSpinnerMessageDialog = ({
  dialogHidden,
  message,
  onCancel,
  titleText,
}) => {
  return (
    <Dialog
      hidden={dialogHidden}
      onDismiss={onCancel}
      dialogContentProps={{
        showCloseButton: false,
        type: DialogType.largeHeader,
        title: titleText,
        subText: '',
      }}
      modalProps={{
        isBlocking: true,
        containerClassName: 'GenericSpinnerMessageDialog',
      }}
    >
      <div>
        <div className="finished-instruction-div">
          {message}
        </div>
      </div>
      <DialogFooter>
        <DefaultButton text="Close" onClick={onCancel} />
      </DialogFooter>
    </Dialog>
  );
};

GenericSpinnerMessageDialog.propTypes = {
  dialogHidden: PropTypes.bool,
  message: PropTypes.any,
  onCancel: PropTypes.func.isRequired,
  titleText: PropTypes.string.isRequired,
};

GenericSpinnerMessageDialog.defaultProps = {
  dialogHidden: true,
  message: '',
};

export default GenericSpinnerMessageDialog;
