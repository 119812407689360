import { types } from '../actions/contactTransactionActions';
import { statuses } from '../helpers/constants';
import { createReducer } from './createReducer';

export const initialState = {
  contributions: [],
  expenditures: [],
  loans: [],
  status: statuses.NOT_STARTED,
};

const actionMap = {
  [types.GET_TRANSACTIONS_PROCESSING]: state => {
    return {
      ...state,
      status: statuses.PROCESSING,
    };
  },
  [types.GET_TRANSACTIONS_SUCCESS]: (
    state,
    { data: { contributions, expenditures, loans } },
  ) => {
    return {
      ...state,
      contributions,
      expenditures,
      loans,
      status: statuses.SUCCESS,
    };
  },
  [types.GET_TRANSACTIONS_RESET]: () => {
    return { ...initialState };
  },
};

export const contactTransactionsReducer = createReducer(
  initialState,
  actionMap,
);
