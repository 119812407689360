import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from 'office-ui-fabric-react/lib/TextField';

const EmailField = ({
  value,
  label,
  className,
  style,
  onChange,
  required,
  placeholder,
  underlined,
  validateOnBlur,
  errorMessage,
}) => {
  return (
    <TextField
      type="email"
      value={value}
      label={label}
      className={className}
      inputMode="email"
      style={style}
      onChange={onChange}
      required={required}
      placeholder={placeholder}
      underlined={underlined}
      errorMessage={errorMessage}
      validateOnFocusOut={validateOnBlur}
    />
  );
};

EmailField.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  errorMessage: PropTypes.string,
  validateOnBlur: PropTypes.bool,
  setFormInvalid: PropTypes.func,
  formSection: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  underlined: PropTypes.bool,
};

EmailField.defaultProps = {
  value: '',
  label: 'Email Address',
  style: {},
  className: '',
  required: false,
  errorMessage: '',
  validateOnBlur: true,
  setFormInvalid: () => {},
  formSection: '',
  placeholder: '',
  underlined: false,
};

export default EmailField;
