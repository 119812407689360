export function isFrontRunnerDev(session) {
  return (
    session?.username === 'Randy Nichols'
    || session?.username === 'Todd Hickerson'
    )
    && session?.isSysAdmin;
}

export function isSysAdmin(session) {
  return session?.isSysAdmin;
}

export function isBetaUser(session) {
  return session?.isBetaUser;
}
