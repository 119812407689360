import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { Checkbox } from 'office-ui-fabric-react/lib/Checkbox';
import {
  DetailsList,
  SelectionMode,
  CheckboxVisibility,
  DetailsRow,
} from 'office-ui-fabric-react/lib/DetailsList';
import { ExpenditurePanel } from '../Panels';
import { sortDirections } from '../../helpers/constants';
import {
  formatCurrency,
  formatDate,
  formatDateTime,
  sortByField,
} from '../../helpers/util';
import { searchValueForTerm } from '../../helpers/listsHelper';
import { getLabel } from '../../helpers/labelHelper';

export const ExpendituresList = ({
  editItem,
  filterFn,
  expenditures,
  session,
  isHidden,
}) => {
  if (isHidden) {
    return null;
  }

  const [state, setState] = useState({
    filterText: '',
    sortField: 'displayName',
    sortDirection: sortDirections.ASC,
    showPanel: false,
    panelItem: null,
  });

  const showPanel = item => {
    setState({
      ...state,
      showPanel: true,
      panelItem: item,
    });
  };

  const hidePanel = () => {
    setState({
      ...state,
      showPanel: false,
      panelItem: null,
    });
  };

  const onColumnClick = (e, column) => {
    const field = column.fieldName;
    let direction;
    if (state.sortField === column.fieldName) {
      if (state.sortDirection === sortDirections.ASC) {
        direction = sortDirections.DESC;
      } else if (state.sortDirection === sortDirections.DESC) {
        direction = sortDirections.NONE;
      } else {
        direction = sortDirections.ASC;
      }
    } else {
      direction = sortDirections.ASC;
    }

    setState({
      ...state,
      sortField: field,
      sortDirection: direction,
    });
  };

  const createColumns = () => {
    const { sortField, sortDirection } = state;
    return [
      {
        key: 'recipient-name',
        name: 'Recipient Name',
        fieldName: 'displayName',
        data: 'string',
        minWidth: 200,
        maxWidth: 200,
        isSorted:
          sortField === 'displayName' &&
          sortDirection !== sortDirections.NONE,
        isSortedDescending: sortDirection === sortDirections.DESC,
        onColumnClick,
        onRender: item => (
          <span>{item.displayName || item.displayName}</span>
        ),
      },
      {
        key: 'amount',
        name: 'Amount',
        fieldName: 'amount',
        data: 'number',
        minWidth: 100,
        maxWidth: 100,
        isSorted:
          sortField === 'amount' && sortDirection !== sortDirections.NONE,
        isSortedDescending: sortDirection === sortDirections.DESC,
        onColumnClick,
        onRender: item => (
          <span style={{ textAlign: 'right', display: 'block' }}>
            {formatCurrency(item.amount)}
          </span>
        ),
      },
      {
        key: 'checkNumber',
        name: 'Check Number',
        fieldName: 'checkNumberSort',
        data: 'string',
        minWidth: 75,
        maxWidth: 85,
        isSorted:
          sortField === 'checkNumberSort' &&
          sortDirection !== sortDirections.NONE,
        isSortedDescending: sortDirection === sortDirections.DESC,
        onColumnClick,
        onRender: item => <span>{item.checkNumber}</span>,
      },
      {
        key: 'referenceId',
        name: 'Reference Number',
        fieldName: 'paymentReferenceId',
        minWidth: 115,
        maxWidth: 115,
        isSorted:
          sortField === 'paymentReferenceId' &&
          sortDirection !== sortDirections.NONE,
        isSortedDescending: sortDirection === sortDirections.DESC,
        onColumnClick,
        onRender: item => <span>{item.paymentReferenceId}</span>,
      },
      {
        key: 'date-received',
        name: `${getLabel('Expenditure', session)} Date`,
        fieldName: 'expenditureDate',
        isSorted:
          sortField === 'expenditureDate' &&
          sortDirection !== sortDirections.NONE,
        isSortedDescending: sortDirection === sortDirections.DESC,
        data: 'string',
        minWidth: 120,
        maxWidth: 120,
        onColumnClick,
        onRender: item => <span>{formatDate(item.expenditureDate)}</span>,
      },
      {
        key: 'date-entered-updated',
        name: 'Date Entered/Updated',
        fieldName: 'updatedAt',
        data: 'string',
        minWidth: 150,
        isSorted:
          sortField === 'updatedAt' && sortDirection !== sortDirections.NONE,
        isSortedDescending: sortField === sortDirections.DESC,
        onColumnClick,
        onRender: item => <span>{formatDateTime(item.updatedAt)}</span>,
      },
      // {
      //   key: 'exclude-from-itemize',
      //   name: 'Not Itemized',
      //   fieldName: 'excludeFromItemize',
      //   isResizable: true,
      //   isSorted:
      //     sortField === 'excludeFromItemize' && sortDirection !== sortDirections.NONE,
      //   isSortedDescending: sortDirection === sortDirections.DESC,
      //   onColumnClick,
      //   onRender: item => <Checkbox disabled checked={item.excludeFromItemize} />,
      // },
      {
        key: 'reconciled',
        name: 'Reconciled',
        fieldName: 'reconciliationId',
        isResizable: true,
        isSorted:
          sortField === 'reconciliationId' && sortDirection !== sortDirections.NONE,
        isSortedDescending: sortDirection === sortDirections.DESC,
        onColumnClick,
        onRender: item => <Checkbox disabled checked={item.reconciliationId} />,
      },
    ];
  };

  const mapExpenditures = () => {
    const { filterText, sortDirection, sortField } = state;
    const searchTerm = filterText.toLowerCase();
    const results = expenditures.expenditures
      .filter(
        c =>
          searchValueForTerm(c.displayName, searchTerm) ||
          searchValueForTerm(c.checkNumber, searchTerm) ||
          searchValueForTerm(c.amount, searchTerm) ||
          searchValueForTerm(c.paymentReferenceId, searchTerm),
      )
      .filter(filterFn || (() => true))
      .map(c => ({
        ...c,
        key: c._id,
        checkNumber: c.checkNumber || '',
        paymentReferenceId: c.paymentReferenceId || '',
      }))
      .sort(sortByField(sortField, sortDirection));
    return results;
  };

  const filterExpenditures = (e, value) => {
    setState({
      ...state,
      filterText: value,
    });
  };

  const onRenderRow = rowProps => {
    return (
      <div
        onClick={() => {
          showPanel(rowProps.item);
        }}
      >
        <DetailsRow {...rowProps} />
      </div>
    );
  };

  return (
    <Fragment>
      <TextField
        type="text"
        placeholder={`Search ${getLabel('Expenditures', session)}`}
        onChange={filterExpenditures}
      />
      <DetailsList
        items={mapExpenditures()}
        columns={createColumns()}
        compact={false}
        selectionMode={SelectionMode.none}
        checkboxVisibility={CheckboxVisibility.none}
        onRenderRow={onRenderRow}
        useReducedRowRenderer={expenditures.length > 99}
      />
      <ExpenditurePanel
        session={session}
        item={state.panelItem}
        showPanel={state.showPanel}
        closePanel={hidePanel}
        editItem={editItem}
      />
    </Fragment>
  );
};

ExpendituresList.propTypes = {
  editItem: PropTypes.func.isRequired,
  items: PropTypes.object,
  filterFn: PropTypes.func,
  expenditures: PropTypes.object.isRequired,
  session: PropTypes.object.isRequired,
  isHidden: PropTypes.bool,
};

ExpendituresList.defaultProps = {
  items: {
    expenditures: [],
  },
  filterFn: null,
  isHidden: false,
};

export default ExpendituresList;
