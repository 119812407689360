import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { DatePicker } from '../Pickers';
import { GridRow, Column, MoneyField } from '../common';
import { formatCurrency } from '../../helpers/util';

export const CheckPayment = ({
  checkNumber,
  description,
  amount,
  amountErrorMessage,
  federalShareErrorMessage,
  nonFederalShareErrorMessage,
  amountRemaining,
  federalShare,
  nonFederalShare,
  nonFederalShareLabel,
  splitAmounts,
  showAmountRemaining,
  dateReceived,
  dateReceivedErrorMessage,
  referenceId,
  sourceCode,
  actions,
  dateLabel,
  descriptionLabel,
  descriptionFieldName,
  checkNumberError,
  onBlurCheckNumber,
  mailCode,
  envelopeCode,
  externalDonorId,
}) => (
  <Fragment>
    <GridRow>
      <Column lg={6}>
        <TextField
          value={checkNumber}
          label="Check Number"
          onChange={actions.handlePaymentInfoChange('checkNumber')}
          onBlur={onBlurCheckNumber}
          errorMessage={checkNumberError}
        />
      </Column>
      <Column lg={6}>
        <TextField
          value={description}
          label={descriptionLabel}
          onChange={actions.handlePaymentInfoChange(descriptionFieldName)}
        />
      </Column>
    </GridRow>
    {!splitAmounts &&
    <GridRow>
      <Column lg={3}>
        <MoneyField
          label="Amount"
          value={amount}
          required
          errorMessage={amountErrorMessage}
          onChange={actions.handlePaymentInfoChange('amount')}
          description={
            showAmountRemaining
              ? `Remaining to match end recipients total: ${formatCurrency(
                  amountRemaining,
                )}`
              : ''
          }
        />
      </Column>
      <Column lg={3}>
        <DatePicker
          label={dateLabel}
          value={dateReceived}
          onChange={actions.handleChangeDate}
          required
          errorMessage={dateReceivedErrorMessage}
        />
      </Column>
      <Column lg={3}>
        <TextField
          label="Reference ID"
          value={referenceId}
          onChange={actions.handlePaymentInfoChange('referenceId')}
        />
      </Column>
      <Column lg={3}>
        <TextField
          label="Source Code"
          value={sourceCode}
          onChange={actions.handlePaymentInfoChange('sourceCode')}
        />
      </Column>
    </GridRow>
    }
    {!splitAmounts && (
      <GridRow>
        <Column lg={3}>
          <TextField
            label="Mail Code"
            value={mailCode || ''}
            onChange={actions.handlePaymentInfoChange('mailCode')}
          />
        </Column>
        <Column lg={3}>
          <TextField
            label="Envelope Code"
            value={envelopeCode || ''}
            onChange={actions.handlePaymentInfoChange('envelopeCode')}
          />
        </Column>
        <Column lg={3}>
          <TextField
            label="Donor ID"
            value={externalDonorId || ''}
            onChange={actions.handlePaymentInfoChange('externalDonorId')}
          />
        </Column>
      </GridRow>
    )}
    {splitAmounts &&
    <GridRow>
      <Column lg={2}>
        <MoneyField
          label="Federal Share"
          value={federalShare}
          required
          errorMessage={federalShareErrorMessage}
          onChange={actions.handlePaymentInfoChange('federalShare')}
        />
      </Column>
      <Column lg={2}>
        <MoneyField
          label={nonFederalShareLabel}
          value={nonFederalShare}
          required
          errorMessage={nonFederalShareErrorMessage}
          onChange={actions.handlePaymentInfoChange('nonFederalShare')}
        />
      </Column>
      <Column lg={2}>
        <DatePicker
          label={dateLabel}
          value={dateReceived}
          onChange={actions.handleChangeDate}
          required
          errorMessage={dateReceivedErrorMessage}
        />
      </Column>
      <Column lg={3}>
        <TextField
          label="Reference ID"
          value={referenceId}
          onChange={actions.handlePaymentInfoChange('referenceId')}
        />
      </Column>
      <Column lg={3}>
        <TextField
          label="Source Code"
          value={sourceCode}
          onChange={actions.handlePaymentInfoChange('sourceCode')}
        />
      </Column>
    </GridRow>
    }
  </Fragment>
);

CheckPayment.propTypes = {
  actions: PropTypes.object.isRequired,
  checkNumber: PropTypes.string,
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  amountErrorMessage: PropTypes.string,
  federalShareErrorMessage: PropTypes.string,
  nonFederalShareErrorMessage: PropTypes.string,
  amountRemaining: PropTypes.number,
  splitAmounts: PropTypes.bool,
  federalShare: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  nonFederalShare: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  nonFederalShareLabel: PropTypes.string,
  showAmountRemaining: PropTypes.bool,
  dateReceived: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
  ]),
  dateReceivedErrorMessage: PropTypes.string,
  description: PropTypes.string,
  referenceId: PropTypes.string,
  sourceCode: PropTypes.string,
  dateLabel: PropTypes.string,
  descriptionLabel: PropTypes.string,
  descriptionFieldName: PropTypes.string,
  onBlurCheckNumber: PropTypes.func,
  checkNumberError: PropTypes.string,
  mailCode: PropTypes.string,
  envelopeCode: PropTypes.string,
  externalDonorId: PropTypes.string,
};

CheckPayment.defaultProps = {
  checkNumber: '',
  amount: '',
  amountErrorMessage: '',
  federalShareErrorMessage: '',
  nonFederalShareErrorMessage: '',
  amountRemaining: 0,
  splitAmounts: false,
  federalShare: '',
  nonFederalShare: '',
  nonFederalShareLabel: 'Non-Federal Share',
  showAmountRemaining: false,
  dateReceived: new Date(),
  dateReceivedErrorMessage: '',
  description: '',
  referenceId: '',
  dateLabel: 'Date Received',
  descriptionLabel: 'Description',
  descriptionFieldName: 'description',
  onBlurCheckNumber: () => {},
  checkNumberError: '',
  mailCode: '',
  envelopeCode: '',
  externalDonorId: '',
};

export default CheckPayment;
