import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogType } from 'office-ui-fabric-react/lib/Dialog';
import {
  DefaultButton,
  PrimaryButton,
} from 'office-ui-fabric-react/lib/Button';
import { Grid, GridRow, Column, PasswordField } from '../common';
import { validate } from './ChangePasswordDialogValidations';
import './ChangePasswordDialog.css';

const initialState = {
  currentPassword: '',
  newPassword: '',
  confirmNewPassword: '',
  confirmNewPasswordError: '',
};

export const ChangePasswordDialog = ({
  dialogHidden,
  closeDialog,
  changePassword,
}) => {
  const [state, setState] = useState({ ...initialState });
  const [errors, setErrors] = useState({});

  const handleChange = fieldName => (e, value) => {
    setState({
      ...state,
      [fieldName]: value,
    });
  };

  const cancel = () => {
    setState({ ...initialState });
    setErrors({});
    closeDialog();
  };

  const onChangePassword = () => {
    const errors = validate(state);
    if (Object.keys(errors).length) {
      setErrors(errors);
    } else {
      changePassword(state.currentPassword, state.newPassword);
      setState({ ...initialState });
      setErrors({});
    }
  };

  return (
    <Dialog
      hidden={dialogHidden}
      onDismiss={cancel}
      dialogContentProps={{
        type: DialogType.normal,
        title: 'Change Password',
        subText: '',
      }}
      modalProps={{
        isBlocking: false,
        containerClassName: 'ChangePasswordDialog',
      }}
    >
      <div className="change-password">
        <Grid>
          <GridRow>
            <Column>
              <PasswordField
                value={state.currentPassword}
                label="Current Password"
                onChange={handleChange('currentPassword')}
                errorMessage={errors.currentPasswordError}
              />
            </Column>
          </GridRow>
          <GridRow>
            <Column>
              <PasswordField
                value={state.newPassword}
                label="New Password"
                onChange={handleChange('newPassword')}
                errorMessage={errors.newPasswordError}
              />
            </Column>
          </GridRow>
          <GridRow>
            <Column>
              <PasswordField
                value={state.confirmNewPassword}
                label="Confirm New Password"
                onChange={handleChange('confirmNewPassword')}
                errorMessage={errors.confirmNewPasswordError}
              />
            </Column>
          </GridRow>
          <GridRow>
            <Column classNames="actions">
              <DefaultButton
                text="Cancel"
                style={{ marginRight: 16 }}
                onClick={cancel}
                className="cancel-btn"
              />
              <PrimaryButton
                text="Change Password"
                onClick={onChangePassword}
              />
            </Column>
          </GridRow>
        </Grid>
      </div>
    </Dialog>
  );
};

ChangePasswordDialog.propTypes = {
  dialogHidden: PropTypes.bool,
  closeDialog: PropTypes.func.isRequired,
  changePassword: PropTypes.func.isRequired,
};

ChangePasswordDialog.defaultProps = {
  dialogHidden: true,
};

export default ChangePasswordDialog;
