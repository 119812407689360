import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Pivot, PivotItem } from 'office-ui-fabric-react/lib/Pivot';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { ActionButton, DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { StatePicker } from '../Pickers';
import { ZipCodeField } from '../common';

export const AddressForm = ({
  handleChange,
  handleChangeNewAddress,
  handleChangeAdditionalAddress,
  handleDeleteAdditionalAddress,
  addNewAddressStart,
  addNewAddressFinish,
  cancelAddAddress,
  addressLine1,
  addressLine2,
  city,
  state,
  zipCode,
  county,
  additionalAddresses,
  addAddress,
  newAddressLine1,
  newAddressLine2,
  newCity,
  newState,
  newZipCode,
  newCounty,
  session,
}) => (
  <Fragment>
    <Pivot>
      <PivotItem headerText="Primary Address">
        <ContactAddressSection
          addressLine1={{ name: 'addressLine1', val: addressLine1 }}
          addressLine2={{ name: 'addressLine2', val: addressLine2 }}
          city={{ name: 'city', val: city }}
          state={{ name: 'state', val: state }}
          zipCode={{ name: 'zipCode', val: zipCode }}
          county={{ name: 'county', val: county }}
          handleChange={handleChange}
          session={session}
        />
      </PivotItem>
      {(additionalAddresses || []).map((aa, index) => (
        <PivotItem key={aa._id} headerText={`Additional Address ${index + 1}`}>
          <ContactAddressSection
            handleChange={handleChangeAdditionalAddress(aa._id)}
            addressLine1={{ name: 'addressLine1', val: aa.addressLine1 }}
            addressLine2={{ name: 'addressLine2', val: aa.addressLine2 }}
            city={{ name: 'city', val: aa.city }}
            state={{ name: 'state', val: aa.state }}
            zipCode={{ name: 'zipCode', val: aa.zipCode }}
            county={{ name: 'county', val: aa.county }}
            handleDelete={handleDeleteAdditionalAddress(aa._id)}
            session={session}
          />
        </PivotItem>
      ))}
    </Pivot>
    <ActionButton
      disabled={addAddress}
      onClick={addNewAddressStart}
      iconProps={{ iconName: 'PlusCircle' }}
      text="Add Additional Address"
    />
    {addAddress && (
      <div className="additional-field">
        <ContactAddressSection
          handleChange={handleChangeNewAddress}
          addressLine1={{ name: 'newAddressLine1', val: newAddressLine1 }}
          addressLine2={{ name: 'newAddressLine2', val: newAddressLine2 }}
          city={{ name: 'newCity', val: newCity }}
          state={{ name: 'newState', val: newState }}
          zipCode={{ name: 'newZipCode', val: newZipCode }}
          county={{ name: 'newCounty', val: newCounty }}
          session={session}
        />
        <Stack horizontal alignContent="end">
          <DefaultButton
            text="Cancel"
            onClick={cancelAddAddress}
            style={{ marginRight: 16 }}
          />
          <DefaultButton
            text="Save New Address"
            onClick={addNewAddressFinish}
          />
        </Stack>
      </div>
    )}
  </Fragment>
);

AddressForm.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleChangeNewAddress: PropTypes.func.isRequired,
  handleChangeAdditionalAddress: PropTypes.func.isRequired,
  handleDeleteAdditionalAddress: PropTypes.func.isRequired,
  addNewAddressStart: PropTypes.func.isRequired,
  addNewAddressFinish: PropTypes.func.isRequired,
  cancelAddAddress: PropTypes.func.isRequired,
  addressLine1: PropTypes.string,
  addressLine2: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  zipCode: PropTypes.string,
  county: PropTypes.string,
  additionalAddresses: PropTypes.array,
  addAddress: PropTypes.bool,
  newAddressLine1: PropTypes.string,
  newAddressLine2: PropTypes.string,
  newCity: PropTypes.string,
  newState: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  newZipCode: PropTypes.string,
  newCounty: PropTypes.string,
  session: PropTypes.object.isRequired,
};

AddressForm.defaultProps = {
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: 0,
  zipCode: '',
  county: '',
  additionalAddresses: [],
  addAddress: false,
  newAddressLine1: '',
  newAddressLine2: '',
  newCity: '',
  newState: 0,
  newZipCode: '',
  newCounty: '',
};

// eslint-disable-next-line react/no-multi-comp
const ContactAddressSection = ({
  addressLine1,
  addressLine2,
  city,
  state,
  zipCode,
  county,
  handleChange,
  handleDelete,
  session,
}) => (
  <Fragment>
    <TextField
      label="Address 1"
      value={addressLine1.val}
      onChange={handleChange(addressLine1.name)}
    />
    <TextField
      label="Address 2"
      value={addressLine2.val}
      onChange={handleChange(addressLine2.name)}
    />
    <TextField
      label="City"
      value={city.val}
      onChange={handleChange(city.name)}
    />
    <StatePicker
      allowFreeForm
      selectedKey={state.val}
      onChange={handleChange(state.name)}
      session={session}
    />
    <ZipCodeField value={zipCode.val} onChange={handleChange(zipCode.name)} />
    <TextField
      label="County"
      value={county.val}
      onChange={handleChange(county.name)}
    />
    {handleDelete && (
      <ActionButton
        iconProps={{
          iconName: 'MinusCircle',
          styles: {
            root: {
              color: '#a80000',
            },
          },
        }}
        onClick={handleDelete}
        text="Remove Address"
        styles={{
          rootHovered: {
            color: '#640000',
          },
          iconHovered: {
            color: '#640000',
          },
          iconPressed: {
            color: '#640000',
          },
        }}
      />
    )}
  </Fragment>
);

ContactAddressSection.propTypes = {
  addressLine1: PropTypes.object,
  addressLine2: PropTypes.object,
  city: PropTypes.object,
  state: PropTypes.object,
  zipCode: PropTypes.object,
  county: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  handleDelete: PropTypes.func,
  session: PropTypes.object.isRequired,
};

ContactAddressSection.defaultProps = {
  addressLine1: {},
  addressLine2: {},
  city: {},
  state: {},
  zipCode: {},
  county: {},
  handleDelete: null,
};
