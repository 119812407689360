import { types } from '../actions/createCampaignActions';
import { types as paymentTypes } from '../actions/paymentActions';
import { statuses } from '../helpers/constants';
import { createReducer } from './createReducer';

export const initialState = {
  stepIndex: 0,
  submitStatus: statuses.NOT_STARTED,
  submitError: null,
};

/* eslint-disable no-confusing-arrow */
const nextStepMap = {
  0: (state, hasPromoCode) =>
    hasPromoCode ? state.stepIndex + 2 : state.stepIndex + 1,
  1: state => state.stepIndex + 1,
};

const previousStepMap = {
  1: state => state.stepIndex - 1,
  2: (state, hasPromoCode) =>
    hasPromoCode ? state.stepIndex - 2 : state.stepIndex - 1,
};

/* eslint-enable no-confusing-arrow */

const actionMap = {
  [types.NEXT_STEP]: (state, action) => {
    return {
      ...state,
      stepIndex: nextStepMap[state.stepIndex]
        ? nextStepMap[state.stepIndex](state, action.promoCode)
        : state.stepIndex,
    };
  },
  [types.PREVIOUS_STEP]: (state, action) => {
    return {
      ...state,
      stepIndex: previousStepMap[state.stepIndex]
        ? previousStepMap[state.stepIndex](state, action.promoCode)
        : state.stepIndex,
    };
  },
  [types.CREATE_CAMPAIGN_PROCESSING]: state => {
    return {
      ...state,
      submitStatus: statuses.PROCESSING,
    };
  },
  [types.CREATE_CAMPAIGN_SUCCESS]: () => {
    return { ...initialState };
  },
  [types.CREATE_CAMPAIGN_FAILURE]: (state, action) => {
    return {
      ...state,
      submitError: action.error,
    };
  },
  [paymentTypes.GET_STRIPE_TOKEN_SUCCESS]: state => {
    return {
      ...state,
      stepIndex: nextStepMap[state.stepIndex](state),
    };
  },
  [types.CREATE_COMMITTEE_CAMPAIGN_SUCCESS]: () => {
    return { ...initialState };
  },
  [types.CREATE_COMMITTEE_CAMPAIGN_FAILURE]: (state, action) => {
    return {
      ...state,
      submitError: action.error,
    };
  },
  [types.CREATE_FEDERAL_PAC_CAMPAIGN_SUCCESS]: () => {
    return { ...initialState };
  },
  [types.CREATE_FEDERAL_PAC_CAMPAIGN_FAILURE]: (state, action) => {
    return {
      ...state,
      submitError: action.error,
    };
  },
};

export const createCampaignReducer = createReducer(initialState, actionMap);
