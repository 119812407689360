import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'office-ui-fabric-react/lib/Link';
import { Label } from 'office-ui-fabric-react/lib/Label';
import { PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { Grid, GridRow, Column, MoneyField } from '../common';

const FECNonCandidateBeginningBalances = ({
  handleChange,
  toggleExpandSection,
  state,
  sectionName,
}) => {
  return (
    <div className="fecBeginningBalances fec-report-section depth-1" name={sectionName}>
      <h3>
        BEGINNING BALANCES{' '}
        <small>
          {' '}
          (
          <Link onClick={() => toggleExpandSection(sectionName, false)}>
            {state.expandableSections[sectionName] === true
              ? 'Collapse'
              : 'Expand'}
          </Link>
          )
        </small>
      </h3>
      <div
        className={`edit-fec-cover-page${
          state.expandableSections[sectionName] === true
            ? '-expanded'
            : '-collapsed'
        }`}
      >
        <Grid style={{ padding: '0 24px' }}>
          <GridRow style={{ borderTop: '1px solid #919191' }}>
            <Column md={6}>
              <TextField
                value={state?.fecPacSummaryPreviousBalanceAmounts?.colB_Year_ForAbove}
                label="Cash on Hand January 1 of year"
                onChange={handleChange(
                  'colB_Year_ForAbove',
                )}
              />
            </Column>
            <Column md={6}>
              <MoneyField
                label="Cash on Hand Amount for January 1"
                value={state?.fecPacSummaryPreviousBalanceAmounts?.colB_6a_CashOnHandJan1_19}
                onChange={handleChange(
                  'colB_6a_CashOnHandJan1_19',
                )}
              />
            </Column>
          </GridRow>
          <GridRow style={{ borderTop: '1px solid #919191', marginTop: '16px' }}>
            <Column md={6}>
              <MoneyField
                label="Cash On Hand At Beginning"
                value={state?.fecPacSummaryPreviousBalanceAmounts?.colA_6b_CashOnHandBeginning}
                onChange={handleChange(
                  'colA_6b_CashOnHandBeginning',
                )}
              />
            </Column>
          </GridRow>
          <GridRow style={{ borderTop: '1px solid #919191', marginTop: '16px' }}>
            <Column>
              <h2>I. Receipts</h2>
            </Column>
          </GridRow>
          <GridRow>
            <Column>
              <Label>Contributions (other than loans) from:</Label>
            </Column>
          </GridRow>
          <GridRow>
            <Column md={6}>
              <MoneyField
                label="Individuals/Person Itemized"
                value={state?.fecPacSummaryPreviousBalanceAmounts?.colB_11ai_Itemized}
                onChange={handleChange(
                  'colB_11ai_Itemized',
                )}
              />
            </Column>
            <Column md={6}>
              <MoneyField
                label="Individuals/Person Unitemized"
                value={
                  state?.fecPacSummaryPreviousBalanceAmounts?.colB_11aii_Unitemized
                }
                onChange={handleChange(
                  'colB_11aii_Unitemized',
                )}
              />
            </Column>
          </GridRow>
          <GridRow>
            <Column md={6}>
              <MoneyField
                label="Political Party Committees"
                value={state?.fecPacSummaryPreviousBalanceAmounts?.colB_11b_PoliticalPartyCommittees}
                onChange={handleChange(
                  'colB_11b_PoliticalPartyCommittees',
                )}
              />
            </Column>
            <Column md={6}>
              <MoneyField
                label="Other Political Committees (such as PACs)"
                value={state?.fecPacSummaryPreviousBalanceAmounts?.colB_11c_OtherPoliticalCommitteesPacs}
                onChange={handleChange(
                  'colB_11c_OtherPoliticalCommitteesPacs',
                )}
              />
            </Column>
          </GridRow>
          <GridRow style={{ borderTop: '1px solid #919191', marginTop: '16px' }}>
            <Column md={6}>
              <MoneyField
                label="Transfers From Affiliated/Other Party Committees"
                value={
                  state?.fecPacSummaryPreviousBalanceAmounts?.colB_12_TransfersFromAffiliatedOtherPartyCmtes
                }
                onChange={handleChange(
                  'colB_12_TransfersFromAffiliatedOtherPartyCmtes',
                )}
              />
            </Column>
            <Column md={6}>
              <MoneyField
                label="All Loans Received"
                value={
                  state?.fecPacSummaryPreviousBalanceAmounts?.colB_13_AllLoansReceived
                }
                onChange={handleChange(
                  'colB_13_AllLoansReceived',
                )}
              />
            </Column>
          </GridRow>
          <GridRow>
            <Column md={6}>
              <MoneyField
                label="Loan Repayments Received"
                value={
                  state?.fecPacSummaryPreviousBalanceAmounts?.colB_14_LoanRepaymentsReceived
                }
                onChange={handleChange(
                  'colB_14_LoanRepaymentsReceived',
                )}
              />
            </Column>
            <Column md={6}>
              <MoneyField
                label="Offsets To Operating Expenditures (Refunds, Rebates, etc.)"
                value={
                  state?.fecPacSummaryPreviousBalanceAmounts?.colB_15_OffsetsToOperatingExpendituresRefunds
                }
                onChange={handleChange(
                  'colB_15_OffsetsToOperatingExpendituresRefunds',
                )}
              />
            </Column>
          </GridRow>
          <GridRow>
            <Column md={6}>
              <MoneyField
                label="Refunds of Contributions Made to Federal Candidates and Other Political Committees"
                value={
                  state?.fecPacSummaryPreviousBalanceAmounts?.colB_16_RefundsOfFederalContributions
                }
                onChange={handleChange(
                  'colB_16_RefundsOfFederalContributions',
                )}
              />
            </Column>
            <Column md={6}>
              <MoneyField
                label="Other Federal Receipts (Dividends, Interest, etc.)"
                value={
                  state?.fecPacSummaryPreviousBalanceAmounts?.colB_17_OtherFederalReceiptsDividends
                }
                onChange={handleChange(
                  'colB_17_OtherFederalReceiptsDividends',
                )}
              />
            </Column>
          </GridRow>
          <GridRow style={{ borderTop: '1px solid #919191', marginTop: '16px' }}>
            <Column>
              <Label>Transfers from Non-Federal and Levin Funds</Label>
            </Column>
          </GridRow>
          <GridRow>
            <Column md={6}>
              <MoneyField
                label="Non-Federal Account (from Schedule H3)"
                value={
                  state?.fecPacSummaryPreviousBalanceAmounts?.colB_18a_TransfersFromNonfederalAccountH3
                }
                onChange={handleChange(
                  'colB_18a_TransfersFromNonfederalAccountH3',
                )}
              />
            </Column>
            <Column md={6}>
              <MoneyField
                label="Levin Funds (from Schedule H5)"
                value={
                  state?.fecPacSummaryPreviousBalanceAmounts?.colB_18b_TransfersFromNonfederalLevinH5
                }
                onChange={handleChange(
                  'colB_18b_TransfersFromNonfederalLevinH5',
                )}
              />
            </Column>
          </GridRow>
          <GridRow style={{ borderTop: '1px solid #919191', marginTop: '16px' }}>
            <Column>
              <h2>II. Disbursements</h2>
            </Column>
          </GridRow>
          <GridRow>
            <Column>
              <Label>Operating Expenditures</Label>
            </Column>
          </GridRow>
          <GridRow>
            <Column md={4}>
              <MoneyField
                label="Allocated Federal/Non-Federal Activity (from Schedule H4) Federal Share"
                value={
                  state?.fecPacSummaryPreviousBalanceAmounts?.colB_21ai_FederalShare
                }
                onChange={handleChange(
                  'colB_21ai_FederalShare',
                )}
              />
            </Column>
            <Column md={4}>
              <MoneyField
                label="Allocated Federal/Non-Federal Activity (from Schedule H4) Non-Federal Share"
                value={
                  state?.fecPacSummaryPreviousBalanceAmounts?.colB_21aii_NonfederalShare
                }
                onChange={handleChange(
                  'colB_21aii_NonfederalShare',
                )}
              />
            </Column>
            <Column md={4}>
              <MoneyField
                label="Other Federal Operating Expenditures"
                value={
                  state?.fecPacSummaryPreviousBalanceAmounts?.colB_21b_OtherFederalOperatingExpenditures
                }
                onChange={handleChange(
                  'colB_21b_OtherFederalOperatingExpenditures',
                )}
              />
            </Column>
          </GridRow>
          <GridRow style={{ borderTop: '1px solid #919191', marginTop: '16px' }}>
            <Column md={6}>
              <MoneyField
                label="Transfers to Affiliated/Other Party Committees"
                value={
                  state?.fecPacSummaryPreviousBalanceAmounts?.colB_22_TransfersToAffiliatedOtherPartyCmtes
                }
                onChange={handleChange(
                  'colB_22_TransfersToAffiliatedOtherPartyCmtes',
                )}
              />
            </Column>
            <Column md={6}>
              <MoneyField
                label="Contributions to Federal Candidates/Committees and Other Political Committees"
                value={
                  state?.fecPacSummaryPreviousBalanceAmounts?.colB_23_ContributionsToFederalCandidatesCmtes
                }
                onChange={handleChange(
                  'colB_23_ContributionsToFederalCandidatesCmtes',
                )}
              />
            </Column>
          </GridRow>
          <GridRow>
            <Column md={6}>
              <MoneyField
                label="Independent Expenditures (use Schedule E)"
                value={
                  state?.fecPacSummaryPreviousBalanceAmounts?.colB_24_IndependentExpenditures
                }
                onChange={handleChange(
                  'colB_24_IndependentExpenditures',
                )}
              />
            </Column>
            <Column md={6}>
              <MoneyField
                label="Coordinated Party Expenditures (use Schedule F)"
                value={
                  state?.fecPacSummaryPreviousBalanceAmounts?.colB_25_CoordinatedExpendMadeByPartyCmtes
                }
                onChange={handleChange(
                  'colB_25_CoordinatedExpendMadeByPartyCmtes',
                )}
              />
            </Column>
          </GridRow>
          <GridRow>
            <Column md={6}>
              <MoneyField
                label="Loan Repayments Made"
                value={
                  state?.fecPacSummaryPreviousBalanceAmounts?.colB_26_LoanRepaymentsMade
                }
                onChange={handleChange(
                  'colB_26_LoanRepaymentsMade',
                )}
              />
            </Column>
            <Column md={6}>
              <MoneyField
                label="Loans Made"
                value={
                  state?.fecPacSummaryPreviousBalanceAmounts?.colB_27_LoansMade
                }
                onChange={handleChange(
                  'colB_27_LoansMade',
                )}
              />
            </Column>
          </GridRow>
          <GridRow style={{ borderTop: '1px solid #919191', marginTop: '16px' }}>
            <Column>
              <Label>Refunds of Contributions To</Label>
            </Column>
          </GridRow>
          <GridRow>
            <Column md={4}>
              <MoneyField
                label="Individuals/Persons Other Than Political Committees"
                value={
                  state?.fecPacSummaryPreviousBalanceAmounts?.colB_28a_IndividualsPersons
                }
                onChange={handleChange(
                  'colB_28a_IndividualsPersons',
                )}
              />
            </Column>
            <Column md={4}>
              <MoneyField
                label="Political Party Committees"
                value={
                  state?.fecPacSummaryPreviousBalanceAmounts?.colB_28b_PoliticalPartyCommittees
                }
                onChange={handleChange(
                  'colB_28b_PoliticalPartyCommittees',
                )}
              />
            </Column>
            <Column md={4}>
              <MoneyField
                label="Other Political Committees (such as PACs)"
                value={
                  state?.fecPacSummaryPreviousBalanceAmounts?.colB_28c_OtherPoliticalCommittees
                }
                onChange={handleChange(
                  'colB_28c_OtherPoliticalCommittees',
                )}
              />
            </Column>
          </GridRow>
          <GridRow style={{ borderTop: '1px solid #919191', marginTop: '16px' }}>
            <Column md={6}>
              <MoneyField
                label="Other Disbursements (Including Non-Federal Donations)"
                value={
                  state?.fecPacSummaryPreviousBalanceAmounts?.colB_29_OtherDisbursements
                }
                onChange={handleChange(
                  'colB_29_OtherDisbursements',
                )}
              />
            </Column>
          </GridRow>
          <GridRow style={{ borderTop: '1px solid #919191', marginTop: '16px' }}>
            <Column>
              <Label>Federal Election Activity (52 U.S.C.)</Label>
            </Column>
          </GridRow>
          <GridRow>
            <Column md={4}>
              <MoneyField
                label="Allocated Federal Election Activity (from Schedule H6) Federal Share"
                value={
                  state?.fecPacSummaryPreviousBalanceAmounts?.colB_30ai_SharedFederalActivityH6FedShare
                }
                onChange={handleChange(
                  'colB_30ai_SharedFederalActivityH6FedShare',
                )}
              />
            </Column>
            <Column md={4}>
              <MoneyField
                label="Allocated Federal Election Activity (from Schedule H6) Levin Share"
                value={
                  state?.fecPacSummaryPreviousBalanceAmounts?.colB_30aii_SharedFederalActivityH6Nonfed
                }
                onChange={handleChange(
                  'colB_30aii_SharedFederalActivityH6Nonfed',
                )}
              />
            </Column>
            <Column md={4}>
              <MoneyField
                label="Federal Election Activity Paid Entirely With Federal Funds"
                value={
                  state?.fecPacSummaryPreviousBalanceAmounts?.colB_30b_Nonallocable100FedElectionActivity
                }
                onChange={handleChange(
                  'colB_30b_Nonallocable100FedElectionActivity',
                )}
              />
            </Column>
          </GridRow>
        </Grid>
        <div className="section-actions">
          {state.reportType !== '48HourNotice' &&
          <PrimaryButton
            text="Save & Continue"
            onClick={() => toggleExpandSection('summary', true)}
          />
          }
        </div>
      </div>
    </div>
  );
};

FECNonCandidateBeginningBalances.propTypes = {
  currentReport: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleDateChange: PropTypes.func.isRequired,
  toggleExpandSection: PropTypes.func.isRequired,
  state: PropTypes.object.isRequired,
  sectionName: PropTypes.string.isRequired,
};

export default FECNonCandidateBeginningBalances;
