import React from 'react';
import PropTypes from 'prop-types';
import {
  PrimaryButton,
  Panel,
  PanelType,
} from 'office-ui-fabric-react';
import { Grid, GridRow, Column, DataLabel } from '../common';

const IntegrationSettingPanel = ({ item, showPanel, closePanel, onEditItem }) => {
  const onClickEdit = () => {
    onEditItem(item._id);
  };

  if (!item) {
    return null;
  }

  const onRenderFooterContent = () => (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
      }}
    >
      <PrimaryButton
        iconProps={{ iconName: 'PencilAlt' }}
        onClick={onClickEdit}
        text="Edit"
      />
    </div>
  );

  const onRenderNavigationContent = (headerProps, defaultRenderer) => (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        width: '96%',
      }}
    >
      <h3 style={{ flex: 1, marginLeft: '12px' }}>API Integration Details</h3>
      {defaultRenderer(headerProps)}
    </div>
  );

  return (
    <Panel
      isOpen={showPanel}
      onDismiss={closePanel}
      isLightDismiss={false}
      type={PanelType.customNear}
      customWidth={500}
      onRenderFooterContent={onRenderFooterContent}
      onRenderNavigationContent={onRenderNavigationContent}
      isFooterAtBottom
    >
      <Grid>
        <GridRow>
          <Column>
            <DataLabel label="Integration Partner" value={item?.integrationSetting?.integrationPartner} />
            <DataLabel label="accessToken" value={item?.integrationSetting?.accessToken} />
            <DataLabel label="active" value={item?.integrationSetting?.active ? 'Yes' : 'No'} />
            <DataLabel label="automaticallyImport" value={item?.integrationSetting?.automaticallyImport ? 'Yes' : 'No'} />
            <DataLabel label="createdBy" value={item?.createdByUser?.displayName} />
            <DataLabel label="createdAt" value={item?.integrationSetting?.createdAt} />
            <DataLabel label="updatedBy" value={item?.updatedByUser?.displayName} />
            <DataLabel label="updatedAt" value={item?.integrationSetting?.updatedAt} />
            <DataLabel label="conduit" value={item?.conduitContactUser?.displayName || ' '} />
            <DataLabel label="memoText" value={item?.integrationSetting?.memoText || ' '} />
          </Column>
        </GridRow>
      </Grid>
    </Panel>
  );
};

IntegrationSettingPanel.propTypes = {
  showPanel: PropTypes.bool,
  item: PropTypes.object,
  closePanel: PropTypes.func.isRequired,
  onEditItem: PropTypes.func.isRequired,
};

IntegrationSettingPanel.defaultProps = {
  showPanel: false,
  item: {},
};

export default IntegrationSettingPanel;
