import { createReducer } from '../../reducers/createReducer';
import { sortDirections } from '../../helpers/constants';
import { deserializeDate } from '../../helpers/util';
import { convertContributionToDeposit } from './reconciliationsHelper';

export const initialState = {
  transactions: [],
  name: '',
  sortField: 'date',
  sortDirection: sortDirections.ASC,
  beginningStatementDate: null,
  endingStatementDate: null,
  beginningStatementBalance: 0,
  endingStatementBalance: 0,
  isEdit: false,
  filterText: '',
  feePanelOpen: false,
  interestPanelOpen: false,
  selectedTab: 'all',
  errors: {},
};

export const actions = {
  PREP_FORM_FOR_EDIT: 'PREP_FORM_FOR_EDIT',
  RESET_STATE: 'RESET_STATE',
  SET_TRANSACTIONS: 'SET_TRANSACTIONS',
  HANDLE_CHANGE: 'HANDLE_CHANGE',
  SET_FORM_ERRORS: 'SET_FORM_ERRORS',
  SET_SORT: 'SET_SORT',
  ON_SELECTION_CHANGED: 'ON_SELECTION_CHANGED',
  OPEN_PANEL: 'OPEN_PANEL',
  CLOSE_PANEL: 'CLOSE_PANEL',
  SET_SELECTED_TAB: 'SET_SELECTED_TAB',
};

export const convertToTransaction = (
  type,
  existingSelections = [],
) => item => ({
  ...item,
  isSelected:
    Boolean(item.reconciliationId) || existingSelections.includes(item._id),
  key: item._id,
  name: item.name || item.displayName,
  type,
  isInterest: item.isInterest,
  amount: item.amount,
  date: item.depositDate || item.expenditureDate || item.date,
});

const mapListToTransaction = (list, type, existingSelections = []) => {
  return list.map(convertToTransaction(type, existingSelections));
};

// prevents a mid-edit add of fee/interest from resetting current transaction selection
export const mergeCurrentTransactions = (
  current,
  { expenditures, interestContributions },
) => {
  const expenditureResults = expenditures.map(e => {
    const currentExp = current.find(curr => curr._id === e._id);
    return currentExp || convertToTransaction('Expenditure')(e);
  });

  const interestResults = interestContributions.map(i => {
    const currentIntr = current.find(curr => curr._id === i._id);
    return (
      currentIntr || convertToTransaction(null)(convertContributionToDeposit(i))
    );
  });

  return [
    ...current.filter(item => item.type === 'Deposit'),
    ...expenditureResults,
    ...interestResults,
  ];
};

export const actionMap = {
  [actions.PREP_FORM_FOR_EDIT]: (state, { data: { rec } }) => {
    return {
      ...state,
      _id: state._id || rec._id,
      name: state._id ? state.name : rec.name,
      beginningStatementDate: state._id
        ? state.beginningStatementDate
        : deserializeDate(rec.statementBeginDate),
      endingStatementDate: state._id
        ? state.endingStatementDate
        : deserializeDate(rec.statementEndDate),
      beginningStatementBalance: state._id
        ? state.beginningStatementBalance
        : rec.statementBeginningBalance,
      endingStatementBalance: state._id
        ? state.endingStatementBalance
        : rec.statementEndingBalance,
      transactions: state._id
        ? mergeCurrentTransactions(state.transactions, rec)
        : [
            ...mapListToTransaction(rec.deposits, 'Deposit'),
            ...mapListToTransaction(rec.expenditures, 'Expenditure'),
            ...mapListToTransaction(
              rec.interestContributions.map(convertContributionToDeposit),
              null,
            ),
          ],
      isEdit: true,
      errors: state._id ? state.errors : {},
    };
  },
  [actions.RESET_STATE]: () => ({ ...initialState }),
  [actions.SET_TRANSACTIONS]: (
    state,
    { data: { deposits = [], expenditures = [], interestContributions = [] } },
  ) => {
    const {
      selectedDeposits,
      selectedExpenditures,
      selectedInterest,
    } = state.transactions.reduce(
      (acc, item) => {
        if (item.type === 'Deposit' && item.isSelected === true) {
          acc.selectedDeposits = [...acc.selectedDeposits, item._id];
        }

        if (item.type === 'Expenditure' && item.isSelected === true) {
          acc.selectedExpenditures = [...acc.selectedExpenditures, item._id];
        }

        if (
          !item.type &&
          item.isSelected === true &&
          item.isInterest === true
        ) {
          acc.selectedInterest = [...acc.selectedInterest, item._id];
        }
        return acc;
      },
      {
        selectedDeposits: [],
        selectedExpenditures: [],
        selectedInterest: [],
      },
    );

    return {
      ...state,
      transactions: [
        ...mapListToTransaction(deposits, 'Deposit', selectedDeposits),
        ...mapListToTransaction(
          expenditures,
          'Expenditure',
          selectedExpenditures,
        ),
        ...mapListToTransaction(
          interestContributions.map(convertContributionToDeposit),
          null,
          selectedInterest,
        ),
      ],
    };
  },
  [actions.ON_SELECTION_CHANGED]: (state, { data: { list } }) => ({
    ...state,
    transactions: list,
  }),
  [actions.HANDLE_CHANGE]: (state, { data: { fieldName, value } }) => ({
    ...state,
    [fieldName]: value,
  }),
  [actions.SET_FORM_ERRORS]: (state, { data: { errors } }) => ({
    ...state,
    errors,
  }),
  [actions.SET_SORT]: (state, { data: { fieldName } }) => {
    const sort = fieldName;
    let direction = state.sortDirection;

    if (state.sortField === fieldName) {
      if (direction === sortDirections.ASC) {
        direction = sortDirections.DESC;
      } else if (direction === sortDirections.DESC) {
        direction = sortDirections.NONE;
      } else {
        direction = sortDirections.ASC;
      }
    } else {
      direction = sortDirections.ASC;
    }

    return {
      ...state,
      sortField: sort,
      sortDirection: direction,
    };
  },
  [actions.OPEN_PANEL]: (state, { data: { panelName } }) => ({
    ...state,
    [`${panelName}PanelOpen`]: true,
  }),
  [actions.CLOSE_PANEL]: (state, { data: { panelName } }) => ({
    ...state,
    [`${panelName}PanelOpen`]: false,
  }),
  [actions.SET_SELECTED_TAB]: (state, { data: { selectedTab } }) => ({
    ...state,
    selectedTab,
  }),
};

export const addEditReconciliationReducer = createReducer(
  initialState,
  actionMap,
);
