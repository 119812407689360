import { call, put, takeLatest, take } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import axios from 'axios';
import { errorToast, getServerSideErrorMessage } from '../helpers/util';
import { types as longRunningProcessTypes } from '../actions/longRunningProcessActions';

function pollJobUntilComplete(totalSeconds, pause, id) {
  return eventChannel(emitter => {
    const iv = setInterval(() => {
      totalSeconds -= pause;
      if (totalSeconds > 0) {
        axios
          .get(`/api/jobs/getLongRunningProcess/${id}`)
          .then(res => {
            emitter({ ...res.data });
          })
          .catch(error => {
            emitter({ error, isComplete: true });
          });
      } else {
        // this causes the channel to close
        emitter({ isComplete: true, error: 'Request exceeded time allowed. Stopping...' });
      }
    }, pause * 1000);
    // The subscriber must return an unsubscribe function
    return () => {
      clearInterval(iv);
    };
  });
}

export function* handleLongRunningProcess(action) {
  try {
    let inLongRunningProcess = true;
    const { payload, jobName, prettyJobName } = action.data;
    yield put({
      type: longRunningProcessTypes.LONG_RUNNING_JOB_PROCESSING,
      data: { jobName, prettyJobName },
    });
    const { data } = yield call(
      axios.post,
      '/api/jobs/createLongRunningProcess',
      { payload, jobName },
      { withCredentials: true },
      );
    const channel = yield call(pollJobUntilComplete, 60 * 30, 5, data.taskId);
    try {
      while (inLongRunningProcess) {
        const channelResult = yield take(channel);
        if (!channelResult.isComplete) {
          yield put({
            type: longRunningProcessTypes.LONG_RUNNING_JOB_UPDATE_MESSAGING,
            data: channelResult,
          });
        } else {
          inLongRunningProcess = false;
          yield put({
            type: longRunningProcessTypes.LONG_RUNNING_JOB_SUCCESS,
            data: channelResult,
          });
        }
      }
    } catch (err) {
      yield put({
        type: longRunningProcessTypes.LONG_RUNNING_JOB_ERROR,
        error: err,
      });
    } finally {
      channel.close();
    }
  } catch (e) {
    yield put({
      type: longRunningProcessTypes.LONG_RUNNING_JOB_ERROR,
      error: e,
    });
    const error = getServerSideErrorMessage(e);
    yield put(errorToast(error));
  }
}

export function* longRunningProcess() {
  yield takeLatest(longRunningProcessTypes.LONG_RUNNING_JOB, handleLongRunningProcess);
}

export function* handleLongRunningProcessWithFileUpload(action) {
  try {
    let inLongRunningProcess = true;
    const { data: { uploadFile, prettyJobName, onCloseCallback } } = action;
    yield put({
      type: longRunningProcessTypes.LONG_RUNNING_JOB_WITH_FILE_UPLOAD_PROCESSING,
      data: { uploadFile, prettyJobName, onCloseCallback },
    });
    const { data } = yield call(
      axios.post,
      '/api/jobs/createLongRunningProcessWithFileUpload',
      uploadFile,
      { withCredentials: true },
      );
    const channel = yield call(pollJobUntilComplete, 60 * 30, 5, data.taskId);
    try {
      while (inLongRunningProcess) {
        const channelResult = yield take(channel);
        if (!channelResult.isComplete) {
          yield put({
            type: longRunningProcessTypes.LONG_RUNNING_JOB_WITH_FILE_UPLOAD_UPDATE_MESSAGING,
            data: channelResult,
          });
        } else {
          inLongRunningProcess = false;
          yield put({
            type: longRunningProcessTypes.LONG_RUNNING_JOB_WITH_FILE_UPLOAD_SUCCESS,
            data: channelResult,
          });
        }
      }
    } catch (err) {
      yield put({
        type: longRunningProcessTypes.LONG_RUNNING_JOB_WITH_FILE_UPLOAD_ERROR,
        error: err,
      });
    } finally {
      channel.close();
    }
  } catch (e) {
    yield put({
      type: longRunningProcessTypes.LONG_RUNNING_JOB_WITH_FILE_UPLOAD_ERROR,
      error: e,
    });
    const error = getServerSideErrorMessage(e);
    yield put(errorToast(error));
  }
}

export function* longRunningProcessWithFileUpload() {
  yield takeLatest(longRunningProcessTypes.LONG_RUNNING_JOB_WITH_FILE_UPLOAD, handleLongRunningProcessWithFileUpload);
}
