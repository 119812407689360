const types = {
  GET_PLAN_PRICE: 'GET_PLAN_PRICE',
  GET_PLAN_PRICE_FAILURE: 'GET_PLAN_PRICE_FAILURE',
  GET_PLAN_PRICE_SUCCESS: 'GET_PLAN_PRICE_SUCCESS',
  GET_PLAN_PRICE_PROCESSING: 'GET_PLAN_PRICE_PROCESSING',
  RESET_GET_PLAN_PRICE_STATUS: 'RESET_GET_PLAN_PRICE_STATUS',
  GET_STRIPE_TOKEN: 'GET_STRIPE_TOKEN',
  GET_STRIPE_TOKEN_SUCCESS: 'GET_STRIPE_TOKEN_SUCCESS',
  GET_STRIPE_TOKEN_FAILURE: 'GET_STRIPE_TOKEN_FAILURE',
  RESET_GET_STRIPE_TOKEN: 'RESET_GET_STRIPE_TOKEN',
  GET_CC_DETAILS: 'GET_CC_DETAILS',
  GET_CC_DETAILS_SUCCESS: 'GET_CC_DETAILS_SUCCESS',
  GET_CC_DETAILS_FAILURE: 'GET_CC_DETAILS_FAILURE',
  SAVE_CC_DETAILS: 'SAVE_CC_DETAILS',
  SAVE_CC_DETAILS_PROCESSING: 'SAVE_CC_DETAILS_PROCESSING',
  SAVE_CC_DETAILS_SUCCESS: 'SAVE_CC_DETAILS_SUCCESS',
  SAVE_CC_DETAILS_FAILURE: 'SAVE_CC_DETAILS_FAILURE',
  SAVE_CC_DETAILS_STRIPE_TOKEN_SUCCESS: 'SAVE_CC_DETAILS_STRIPE_TOKEN_SUCCESS',
  RESET_PAYMENT_STATE: 'RESET_PAYMENT_STATE',
  CANCEL_ACCOUNT: 'CANCEL_ACCOUNT',
  CANCEL_ACCOUNT_PROCESSING: 'CANCEL_ACCOUNT_PROCESSING',
  CANCEL_ACCOUNT_SUCCESS: 'CANCEL_ACCOUNT_SUCCESS',
  CANCEL_ACCOUNT_FAILURE: 'CANCEL_ACCOUNT_FAILURE',
  GET_PUBLISHABLE_KEY: 'GET_PUBLISHABLE_KEY',
  GET_PUBLISHABLE_KEY_SUCCESS: 'GET_PUBLISHABLE_KEY_SUCCESS',
};

const actions = {
  getPlanPrice(payload) {
    return { type: types.GET_PLAN_PRICE, payload };
  },

  resetGetPlanPriceStatus() {
    return { type: types.RESET_GET_PLAN_PRICE_STATUS };
  },

  getStripeToken({ cardholderName, zipCode, stripe }) {
    return {
      type: types.GET_STRIPE_TOKEN,
      data: { cardholderName, stripe, zipCode },
    };
  },

  resetGetStripeToken() {
    return { type: types.RESET_GET_STRIPE_TOKEN };
  },

  getCCDetails() {
    return { type: types.GET_CC_DETAILS };
  },

  saveCCDetails(payload) {
    return { type: types.SAVE_CC_DETAILS, payload };
  },

  resetStatuses() {
    return { type: types.RESET_PAYMENT_STATE };
  },

  cancelAccount() {
    return { type: types.CANCEL_ACCOUNT };
  },

  getStripePublishableKey() {
    return { type: types.GET_PUBLISHABLE_KEY };
  },
};

export { types, actions };
