import { Icon, PrimaryButton } from 'office-ui-fabric-react';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { BackButton } from '../../components/common';
import { getLabel } from '../../helpers/labelHelper';
import { scrollToTop } from '../../helpers/util';
import { getUserSession } from '../../selectors';
import './ListsIndex.css';

const ListsIndex = ({ history }) => {
  const session = useSelector(getUserSession);
  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <>
      <BackButton
        history={history}
        pageTitle="Ad-hoc Lists and Custom Reports"
        isFixed
      />
      <div className="ListsIndex depth-1">
        <div className="list-options">
          <div className="list-option">
            <div className="list-option-header">
              <Icon iconName="SearchDollar" />
              <h4>Common Report</h4>
              <div className="help-icon">
                <a
                  href="https://intercom.help/frontrunner-llc/en/articles/4332883-fec-using-ad-hoc-lists-and-custom-reports"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon
                    iconName="Help"
                    styles={{
                      root: {
                        fontSize: '10pt',
                        backgroundColor: 'var(--primary-color))',
                        display: 'inline-flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: 24,
                        height: 24,
                        color: '#fff',
                        borderRadius: '50%',
                      },
                    }}
                  />
                </a>
              </div>
            </div>
            <div className="list-option-contents">
              <PrimaryButton
                text={`${getLabel('Contributions', session)} by Date Range`}
                onClick={() =>
                  history.push('/filer/contributionsLists/dateRange')
                }
              />
              <PrimaryButton
                text={`${getLabel('Expenditures', session)} by Date Range`}
                onClick={() =>
                  history.push('/filer/expendituresLists/dateRange')
                }
              />
              <PrimaryButton
                text="Contacts"
                onClick={() => history.push('/filer/contactLists')}
              />
              <PrimaryButton
                onClick={() => history.push('/filer/contributionLimits')}
                text="Contribution Limits"
              />
              {session.isBetaUser && (
                <PrimaryButton
                  onClick={() => window.open('/api/admin/hspMailDailyReport')}
                  text="HSPmail Daily Report (beta)"
                />
              )}
              {false && (
                <PrimaryButton
                  text="Create Custom Report"
                  onClick={() => history.push('/filer/lists')}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

ListsIndex.propTypes = {
  history: PropTypes.object.isRequired,
};

export default ListsIndex;
