import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Pivot, PivotItem } from 'office-ui-fabric-react/lib/Pivot';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { ActionButton, DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { PhoneNumberField } from '../common';

export const PhoneForm = ({
  handleChange,
  handleChangeNewPhone,
  handleChangeAdditionalPhone,
  handleDeleteAdditionalPhone,
  addNewPhoneStart,
  addNewPhoneFinish,
  cancelAddPhone,
  phone1,
  phone2,
  additionalPhoneNumbers,
  addPhone,
  newPhone,
}) => (
  <Fragment>
    <Pivot>
      <PivotItem headerText="Primary Phone">
        <PhoneNumberField
          label="Phone 1"
          value={phone1}
          onChange={handleChange('phone1')}
        />
        <PhoneNumberField
          label="Phone 2"
          value={phone2}
          onChange={handleChange('phone2')}
        />
      </PivotItem>
      {(additionalPhoneNumbers || []).map((ap, index) => (
        <PivotItem headerText={`Additional Phone ${index + 1}`} key={ap._id}>
          <PhoneNumberField
            label={`Additional Phone ${index + 1}`}
            value={ap.value}
            onChange={handleChangeAdditionalPhone(ap._id)}
          />
          <ActionButton
            text="Remove Phone"
            onClick={handleDeleteAdditionalPhone(ap._id)}
            iconProps={{
              iconName: 'MinusCircle',
              styles: {
                root: {
                  color: '#a80000',
                },
              },
            }}
            styles={{
              rootHovered: {
                color: '#640000',
              },
              iconHovered: {
                color: '#640000',
              },
              iconPressed: {
                color: '#640000',
              },
            }}
          />
        </PivotItem>
      ))}
    </Pivot>
    <ActionButton
      disabled={addPhone}
      iconProps={{ iconName: 'PlusCircle' }}
      onClick={addNewPhoneStart}
      text="Add Additional Phone"
    />
    {addPhone && (
      <div className="additional-field">
        <PhoneNumberField
          label="New Phone"
          value={newPhone}
          onChange={handleChangeNewPhone}
        />
        <Stack horizontal alignConent="end">
          <DefaultButton
            text="Cancel"
            onClick={cancelAddPhone}
            style={{ marginRight: 16 }}
          />
          <DefaultButton text="Save New Phone" onClick={addNewPhoneFinish} />
        </Stack>
      </div>
    )}
  </Fragment>
);

PhoneForm.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleChangeNewPhone: PropTypes.func.isRequired,
  handleChangeAdditionalPhone: PropTypes.func.isRequired,
  handleDeleteAdditionalPhone: PropTypes.func.isRequired,
  addNewPhoneStart: PropTypes.func.isRequired,
  addNewPhoneFinish: PropTypes.func.isRequired,
  cancelAddPhone: PropTypes.func.isRequired,
  phone1: PropTypes.string,
  phone2: PropTypes.string,
  additionalPhoneNumbers: PropTypes.array,
  addPhone: PropTypes.bool,
  newPhone: PropTypes.string,
};

PhoneForm.defaultProps = {
  phone1: '',
  phone2: '',
  additionalPhoneNumbers: [],
  addPhone: false,
  newPhone: '',
};
