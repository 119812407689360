/* eslint-disable */
import { FECSourceTypes, GASourceTypes, IowaSourceTypes } from './constants';

export const getOptions = (session) => {
  if (session.officeState === 'Iowa') {
    return [...IowaSourceTypes];
  }
  if (session.officeType === 'Federal') {
    return [...FECSourceTypes];
  }
  return [...GASourceTypes];
};

export const getRecipientTypes = (session) => {
  const options = getOptions(session);
  return options.filter(o => o.key !== 'Common Source');
};
