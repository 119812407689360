export const types = {
  LONG_RUNNING_JOB: 'LONG_RUNNING_JOB',
  LONG_RUNNING_JOB_ERROR: 'LONG_RUNNING_JOB_ERROR',
  LONG_RUNNING_JOB_PROCESSING: 'LONG_RUNNING_JOB_PROCESSING',
  LONG_RUNNING_JOB_UPDATE_MESSAGING: 'LONG_RUNNING_JOB_UPDATE_MESSAGING',
  LONG_RUNNING_JOB_SUCCESS: 'LONG_RUNNING_JOB_SUCCESS',
  LONG_RUNNING_JOB_CLOSE_MESSAGING: 'LONG_RUNNING_JOB_CLOSE_MESSAGING',
  LONG_RUNNING_JOB_WITH_FILE_UPLOAD: 'LONG_RUNNING_JOB_WITH_FILE_UPLOAD',
  LONG_RUNNING_JOB_WITH_FILE_UPLOAD_ERROR: 'LONG_RUNNING_JOB_WITH_FILE_UPLOAD_ERROR',
  LONG_RUNNING_JOB_WITH_FILE_UPLOAD_PROCESSING: 'LONG_RUNNING_JOB_WITH_FILE_UPLOAD_PROCESSING',
  LONG_RUNNING_JOB_WITH_FILE_UPLOAD_UPDATE_MESSAGING: 'LONG_RUNNING_JOB_WITH_FILE_UPLOAD_UPDATE_MESSAGING',
  LONG_RUNNING_JOB_WITH_FILE_UPLOAD_SUCCESS: 'LONG_RUNNING_JOB_WITH_FILE_UPLOAD_SUCCESS',
  LONG_RUNNING_JOB_WITH_FILE_UPLOAD_CLOSE_MESSAGING: 'LONG_RUNNING_JOB_WITH_FILE_UPLOAD_CLOSE_MESSAGING',
};

export const actions = {
  longRunningProcess(jobName, prettyJobName, payload) {
    return {
      type: types.LONG_RUNNING_JOB,
      data: { jobName, prettyJobName, payload },
    };
  },
  longRunningProcessWithFileUpload(uploadFile, prettyJobName, onCloseCallBack) {
    return {
      type: types.LONG_RUNNING_JOB_WITH_FILE_UPLOAD,
      data: { uploadFile, prettyJobName, onCloseCallBack },
    };
  },
  addMessageForlongRunningProcess(messageList) {
    return {
      type: types.LONG_RUNNING_JOB_UPDATE_MESSAGING,
      data: { messageList },
    };
  },
  closeMessaging() {
    return {
      type: types.LONG_RUNNING_JOB_CLOSE_MESSAGING,
    };
  },
};
