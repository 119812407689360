import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from 'office-ui-fabric-react/lib/TextField';

const MoneyField = ({
  value,
  label,
  className,
  style,
  onChange,
  onClick,
  required,
  errorMessage,
  description,
  borderless,
  underlined,
  compRef,
  disabled,
}) => {
  const styles = disabled ? {
    field: { color: '#565656', backgroundColor: 'white' },
    prefix: { color: '#565656', backgroundColor: 'white' },
  } : {};
  return (
    <TextField
      borderless={borderless}
      inputMode="decimal"
      prefix="$"
      value={value}
      className={className}
      style={style}
      styles={styles}
      onChange={onChange}
      onClick={onClick}
      label={label}
      required={required}
      errorMessage={errorMessage}
      description={description}
      underlined={underlined}
      componentRef={compRef}
      disabled={disabled}
    />
  );
};

MoneyField.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string,
  style: PropTypes.object,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  errorMessage: PropTypes.string,
  description: PropTypes.string,
  borderless: PropTypes.bool,
  underlined: PropTypes.bool,
  compRef: PropTypes.object,
  disabled: PropTypes.bool,
};

MoneyField.defaultProps = {
  value: '',
  label: '',
  className: '',
  style: {},
  required: false,
  errorMessage: '',
  description: '',
  borderless: false,
  underlined: false,
  onChange: () => {},
  onClick: () => {},
  compRef: null,
  disabled: false,
};

export default MoneyField;
