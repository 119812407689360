import { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

export default class ToastPortal extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.element,
      PropTypes.node,
    ]),
  };

  constructor(...args) {
    super(...args);

    this.containerEl = document.getElementById('portal');
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.containerEl);
  }
}
