import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, GridRow, Column } from '../../common';

export default class Section3 extends Component {
  static propTypes = {
    q3: PropTypes.shape({
      inKind: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }).isRequired,
  };

  shouldComponentUpdate(nextProps) {
    return (
      nextProps.q3.inKind !== this.props.q3.inKind ||
      nextProps.q3.amount !== this.props.q3.amount
    );
  }

  render() {
    const { q3 } = this.props;
    return (
      <GridRow>
        <Column sm={1} classNames="ms-textAlignRight">
          <h3 className="form-label">3</h3>
        </Column>
        <Column sm={11}>
          <Grid>
            <GridRow>
              <Column sm={8}>
                <p>
                  {
                    'Total amount of all itemized contributions received in this reporting period which are listed in the "Itemized Contributions" section.'
                  }
                </p>
              </Column>
              <Column sm={2} classNames="ms-textAlignCenter money-input">
                <p style={{ padding: '0 6px' }}>{q3.inKind}</p>
              </Column>
              <Column sm={2} classNames="ms-textAlignCenter money-input">
                <p style={{ margin: '16px 12px 0 0' }}>{q3.amount}</p>
              </Column>
            </GridRow>
          </Grid>
        </Column>
      </GridRow>
    );
  }
}
