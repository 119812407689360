import React,
{
  Fragment,
  useState,
  useEffect,
}
  from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Dialog, DialogType } from 'office-ui-fabric-react/lib/Dialog';
import {
  DefaultButton,
  PrimaryButton,
} from 'office-ui-fabric-react/lib/Button';
import { Dropdown } from 'office-ui-fabric-react/lib/Dropdown';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import {
  Grid,
  GridRow,
  Column,
  DataLabel,
} from '../common';
import {
  DatePicker,
} from '../Pickers';
import {
  formatDate,
  formatNonStandardDate,
  sortByField,
} from '../../helpers/util';
import { sortDirections } from '../../helpers/constants';
import { CaretDown } from '../icons';
import './AddReportDialog.css';

const initialState = {
  filingStatus: 0,
  electionDate: 0,
  reportingPeriod: -1,
  reportYear: 0,
  reportDate: null,
  previousReportId: 0,
  report: { key: 0 },
  electionCycle: { key: 0 },
  electionCycleId: 0,
  reportOptions: [],
  electionCycleOptions: [],
  isLoadingElectionCycles: false,
  isLoadingReports: false,
  gACommunicationError: '',
  isGAStateFiling: true,
};

export const AddGAReportDialogTBD = ({
  dialogHidden,
  closeDialog,
  createReport,
  electionDates,
  registrationDate,
  reportType,
  isNonCandidateCommittee,
}) => {
  // There's a issue with TBDs for GA in that GA won't present a TBD report option
  // unless there are transactions on GA that would require a TBD. Since we don't tend to send
  // transactions unless they're report specific, it's chicken/egg issue.
  //
  // To address:
  //
  // Request day for report
  // Submit a long running job that negotiates with GA for the report
  // Use the return to create the report on FR with proper report Id from GA
  // After return, indicate in dialog that the report was created successfully

  const [state, setState] = useState({ ...initialState });
  const [errors, setErrors] = useState({});

  const getOptionsFromGAElectionCyclesReturn = (data) => {
    return data?.electionCycles?.map(r => {
      const { CycleId, CycleName } = r;
      return {
        key: CycleId,
        text: CycleName,
      };
    });
  };

  const getElectionCycleOptions = async () => {
    try {
      setState({
        ...state,
        isLoadingElectionCycles: true,
      });
      const { data: electionCycles } = await axios.get('/api/filer/ga/electionCycles');
      const electionCycleOptions = electionCycles.ok ? getOptionsFromGAElectionCyclesReturn(electionCycles) : [];
      const electionCycleId = electionCycleOptions ? electionCycleOptions[0].key : 0;
      const gACommunicationError = electionCycles.ok ? '' : 'Unable to retrieve the report data from Georgia. Please contact FrontRunner if the issue persists.';
      setState({
        ...state,
        electionCycleOptions,
        electionCycleId,
        gACommunicationError,
        isLoadingElectionCycles: false,
      });
    } catch (err) {
      const gACommunicationError = 'Unable to retrieve the report data from Georgia. Please contact FrontRunner if the issue persists.';
      setState({
        ...state,
        gACommunicationError,
        isLoadingElectionCycles: false,
      });
    }
  };

  useEffect(() => {
    if (!dialogHidden) {
      getElectionCycleOptions();
    }
  }, [dialogHidden]);

  const handleChange = fieldName => (e, value) => {
    setState({
      ...state,
      [fieldName]: value.key !== undefined ? value.key : value,
    });
  };

  const handleElectionCycleSelection = fieldName => (e, value) => {
    setState({
      ...state,
      [fieldName]: value,
    });
  };

  const handleChangeDate = fieldName => date => {
    setState({
      ...state,
      [fieldName]: date,
    });
  };

  const onCloseDialog = () => {
    setState({ ...initialState });
    setErrors({});

    closeDialog();
  };

  const onCreateReport = () => {
    createReport({
      reportDate: state.reportDate,
      electionDateKey: state.electionDate,
      electionCycleId: state.electionCycleId,
    });
  };

  const renderTitle = (v) => {
    return v[0].text;
  };

  if (state.isLoadingElectionCycles) {
    return (
      <Dialog
        hidden={dialogHidden}
        onDismiss={closeDialog}
        dialogContentProps={{
          type: DialogType.normal,
          title: 'Create TBD Report',
          subText: '',
        }}
        modalProps={{
          isBlocking: false,
          containerClassName: 'AddReportDialog',
        }}
      >
        <Spinner
          size={SpinnerSize.medium}
        />
      </Dialog>
    );
  }

  if (state.gACommunicationError) {
    return (
      <Dialog
        hidden={dialogHidden}
        onDismiss={closeDialog}
        dialogContentProps={{
          type: DialogType.close,
          title: 'Create TBD Report',
          subText: '',
        }}
        modalProps={{
          isBlocking: false,
        }}
      >
        <h3>{state.gACommunicationError}</h3>
      </Dialog>
    );
  }

  return (
    <Dialog
      hidden={dialogHidden}
      onDismiss={closeDialog}
      dialogContentProps={{
        type: DialogType.normal,
        title: 'Create TBD Report',
        subText: '',
      }}
      modalProps={{
        isBlocking: false,
        containerClassName: 'AddReportDialog',
      }}
    >
      <div className="add-disclosure-report">
        <Grid>
          {reportType === '' && (
            <Fragment>
              <GridRow>
                {isNonCandidateCommittee && registrationDate && (
                  <Column lg={4}>
                    <DataLabel
                      size="large"
                      label="Registration Year"
                      value={formatDate(registrationDate, 'YYYY')}
                    />
                  </Column>
                )}
              </GridRow>
              <GridRow>
                <Column>
                  {errors.filingStatusError && (
                    <p className="error-message">{errors.filingStatusError}</p>
                  )}
                </Column>
              </GridRow>
            </Fragment>
          )}
          {state.electionCycleOptions && state.electionCycleOptions.length > 1 &&
          <GridRow>
            <Column lg={6}>
              <Dropdown
                onRenderCaretDown={() => <CaretDown />}
                label="Election Cycles"
                required
                onRenderTitle={renderTitle}
                errorMessage={errors.electionCycleError}
                selectedKey={state.electionCycleId}
                onChange={handleElectionCycleSelection('electionCycle')}
                options={[
                  { key: 0, text: 'Select' },
                  ...state.electionCycleOptions,
                ]}
              />
            </Column>
          </GridRow>
          }
          <GridRow>
            <Column lg={6}>
              <DatePicker
                label={'Report Date'}
                value={state.reportDate}
                placeholder="Select Report Date"
                errorMessage={errors.reportDateError}
                onChange={handleChangeDate('reportDate')}
              />
            </Column>
            <Column lg={6}>
              <Dropdown
                onRenderCaretDown={() => <CaretDown />}
                label="Election Date"
                required
                errorMessage={errors.electionDateError}
                selectedKey={state.electionDate}
                onChange={handleChange('electionDate')}
                options={[
                  { key: 0, text: 'Select' },
                  ...electionDates
                    .sort(sortByField('electionDate', sortDirections.ASC))
                    .map(ed => ({
                      key: ed._id,
                      text: `${formatNonStandardDate(
                        ed.electionDate,
                        'YYYY-MM-DD',
                      )} - ${ed.electionCycle}`,
                    })),
                ]}
              />
            </Column>
          </GridRow>
        </Grid>
      </div>
      <div className="add-report-actions">
        <DefaultButton
          text="Cancel"
          onClick={onCloseDialog}
          style={{ marginRight: 16 }}
        />
        <PrimaryButton text="Create Report" onClick={onCreateReport} />
      </div>
    </Dialog>
  );
};

AddGAReportDialogTBD.propTypes = {
  dialogHidden: PropTypes.bool,
  closeDialog: PropTypes.func.isRequired,
  createReport: PropTypes.func.isRequired,
  electionYear: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  electionDates: PropTypes.array.isRequired,
  registrationDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  reportType: PropTypes.string,
  selectedReportingPeriod: PropTypes.string,
  isNonCandidateCommittee: PropTypes.bool,
};

AddGAReportDialogTBD.defaultProps = {
  dialogHidden: true,
  reportType: '',
  selectedReportingPeriod: null,
  isNonCandidateCommittee: false,
  registrationDate: '',
};

export default AddGAReportDialogTBD;
