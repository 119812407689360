import * as R from 'ramda';
import moment from 'moment';
import { sortDirections, toastTypes } from './constants';
import { types as messagingTypes } from '../actions/messagingActions';

export function scrollTo(xPos = 0, yPos = 0) {
  document.querySelector('.content').scrollTo(xPos, yPos);
}

export function scrollToComponent(ref) {
  const element = document.querySelector(ref);
  if (element === null) {
    return null;
  }

  return element.scrollIntoView({
    behavior: 'smooth',
    block: 'start',
    inline: 'start',
  });
}

export function scrollToTop() {
  scrollTo({ top: 0, left: 0, behavior: 'smooth' });
}

export function getServerSideErrorMessage(error) {
  if (typeof error === 'object') {
    let err = R.path(['response', 'data', 'message'], error);
    if (!err) {
      err = R.pathOr(
        'An error occurred, please try again',
        ['response', 'data'],
        error,
      );
    }
    return err;
  }

  if (typeof error === 'string') {
    return error;
  }

  return 'An error occurred, please try again';
}

export function reloadPage() {
  window.location.reload();
}

export function toProperCase(state) {
  const name = state
    .split(' ')
    .map(w => `${w.substring(0, 1).toUpperCase()}${w.substring(1, w.length)}`)
    .join(' ');
  return name;
}

export const filterEmpties = payload => {
  return Object.entries(payload).reduce((acc, item) => {
    const [name, value] = item;
    if (typeof value !== 'object') {
      if (Boolean(value) || value === 0 || value === false) {
        acc[name] = value;
      }
    } else if (value) {
      const subObject = filterEmpties(value);
      if (Object.getOwnPropertyNames(subObject).length > 0) {
        acc[name] = subObject;
      }
    }
    return acc;
  }, {});
};

export function printFullNameFirstNameFirst(user) {
  let displayName;
  if (user.middleName) {
    displayName = `${user.firstName} ${user.middleName} ${user.lastName}`;
  } else {
    displayName = `${user.firstName} ${user.lastName}`;
  }
  return displayName;
}

export function printFullNameLastNameFirst(user) {
  let displayName;
  if (user.middleName) {
    displayName = `${user.lastName}, ${user.firstName} ${user.middleName}`;
  } else {
    displayName = `${user.lastName}, ${user.firstName}`;
  }
  return displayName;
}

export function formatNumber(value) {
  value = parseFloat(value, 10).toFixed(2);
  return `${value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;
}

export function formatInteger(value) {
  value = parseFloat(value, 10).toFixed(0);
  return `${value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;
}

export function formatCurrency(value) {
  return `$${formatNumber(value)}`;
}

export function formatTime(t) {
  return moment(t).format('hh:mm:ss a');
}

export function formatDate(value, format = 'MM/DD/YYYY') {
  return moment.utc(value).format(format);
}

export function formatISODate(value) {
  return moment.utc(value).format('YYYY-MM-DD');
}

export function formatUtcDate(value, format = 'MM/DD/YYYY') {
  if (!value) {
    return value;
  }
  return moment.utc(value).format(format);
}

export function formatNonStandardDate(
  value,
  currentFormat,
  desiredFormat = 'MMM D, YYYY',
) {
  return moment.utc(value, currentFormat).format(desiredFormat);
}

export function formatDateTime(value, format = 'MMM D, YYYY h:mm A') {
  return moment(value).format(format);
}

export function formatDateOnly(value) {
  if (!value) {
    return null;
  }
  if (!(value instanceof Date)) {
    value = new Date(value);
    if (!(value instanceof Date)) {
      throw new Error(
        'value is required to be a date object or able to be cast to a date object',
      );
    }
  }
  const UTCDate = new Date(
    Date.UTC(
      value.getFullYear(),
      value.getMonth(),
      value.getDate(),
      value.getHours(),
    ),
  );
  return UTCDate;
}

export function pad(input, width, z = '0') {
  const myString = input.toString();
  return (myString.length >= width ? myString : new Array((width - myString.length) + 1).join(z) + myString);
}

export function formatEndDate(value) {
  if (!value) {
    return null;
  }
  if (!(value instanceof Date)) {
    value = new Date(value);
    if (!(value instanceof Date)) {
      throw new Error(
        'value is required to be a date object or able to be cast to a date object',
      );
    }
  }
  const entry = `${value.getFullYear()}-${pad(value.getMonth() + 1, 2)}-${pad(value.getDate(), 2)}T23:59:59.999Z`;

  return moment(entry).toDate();
}

export function formatDateUTC(value) {
  if (!value || typeof value !== 'object' || !(value instanceof Date)) {
    return null;
  }
  return new Date(
    Date.UTC(value.getFullYear(), value.getMonth(), value.getDate()),
  );
}

export function sortByField(sortField, sortDirection = sortDirections.ASC) {
  return (a, b) => {
    const sortFieldA =
      a[sortField] !== null && a[sortField] !== undefined ? a[sortField] : '';
    const sortFieldB =
      b[sortField] !== null && b[sortField] !== undefined ? b[sortField] : '';
    if (sortDirection === sortDirections.ASC) {
      return sortFieldA < sortFieldB ? -1 : sortFieldA > sortFieldB ? 1 : 0;
    }

    if (sortDirection === sortDirections.DESC) {
      return sortFieldA > sortFieldB ? -1 : sortFieldA < sortFieldB ? 1 : 0;
    }

    return 0;
  };
}

export function sortByFieldsAsc(fields = []) {
  return (a, b) => {
    for (let i = 0; i < fields.length; i += 1) {
      const predicate = fields[i];
      if (a[predicate] > b[predicate]) {
        return 1;
      }

      if (a[predicate] < b[predicate]) {
        return -1;
      }
    }
  };
}

export function sortByFieldsDesc(fields = []) {
  return (a, b) => {
    for (let i = 0; i < fields.length; i += 1) {
      const predicate = fields[i];
      if (a[predicate] > b[predicate]) {
        return -1;
      }

      if (a[predicate] < b[predicate]) {
        return 1;
      }
    }
  };
}

export const errorToast = error => ({
  type: messagingTypes.SET_TOAST,
  data: {
    message: error,
    toastType: toastTypes.ERROR,
  },
});

export const successToast = message => ({
  type: messagingTypes.SET_TOAST,
  data: {
    message,
    toastType: toastTypes.SUCCESS,
  },
});

export const warningToast = message => ({
  type: messagingTypes.SET_TOAST,
  data: {
    message,
    toastType: toastTypes.WARNING,
  },
});

export const infoToast = message => ({
  type: messagingTypes.SET_TOAST,
  data: {
    message,
    toastType: toastTypes.INFO,
  },
});

export const compareDatesLessThanEqualTo = (date, targetDate) => {
  if (!date) {
    return true;
  }
  return moment(date).isSameOrBefore(targetDate);
};

export const compareDatesGreaterThanEqualTo = (date, targetDate) => {
  if (!date) {
    return true;
  }
  return moment(date).isSameOrAfter(targetDate);
};

export const dateBetweenOrEqualTo = (date, lowEndDate, highEndDate) => {
  // All dates must be represented
  if (!date || !lowEndDate || !highEndDate) {
    return false;
  }
  if (moment(date).isBefore(lowEndDate)) {
    return false;
  }
  if (moment(date).isAfter(highEndDate)) {
    return false;
  }
  return true;
};

export const itemDateBeforeLastFiling = (itemDate, lastFiledEndDate) => {
  if (!lastFiledEndDate) {
    return false;
  }
  return moment(formatDateOnly(itemDate)).isSameOrBefore(moment(formatDateOnly(lastFiledEndDate)));
};

export const formatAddress = item => {
  if (!item.address) {
    return '';
  }

  let address = '';

  if (item.address.addressLine1) {
    address += item.address.addressLine1;
  }

  if (item.address.addressLine2) {
    if (address.length > 0) {
      address += ` ${item.address.addressLine2}`;
    } else {
      address = item.address.addressLine2;
    }
  }

  if (item.address.city) {
    if (address.length > 0) {
      address += `, ${item.address.city}`;
    } else {
      address = item.address.city;
    }
  }

  if (item.address.state) {
    if (address.length > 0) {
      address += `, ${item.address.state}`;
    } else {
      address = item.address.state;
    }
  }

  if (item.address.zipCode) {
    if (address.length > 0) {
      address += ` ${item.address.zipCode}`;
    } else {
      address = item.address.zipCode;
    }
  }

  return address;
};

export const isInvalidDate = date => {
  if (!date) {
    return true;
  }

  if (!(date instanceof Date)) {
    return true;
  }

  if (date.getFullYear && date.getFullYear().toString().length !== 4) {
    return true;
  }

  return false;
};

// eslint-disable-next-line no-restricted-globals
export const isInvalidNumber = val => !(!isNaN(+val) && isFinite(val));

export const setSortFieldAndDirection = (
  currentField,
  currentDirection,
  newField,
) => {
  let field = currentField;
  let direction;
  if (currentField === newField) {
    if (currentDirection === sortDirections.ASC) {
      direction = sortDirections.DESC;
    } else if (currentDirection === sortDirections.DESC) {
      direction = sortDirections.NONE;
    } else {
      direction = sortDirections.ASC;
    }
  } else {
    field = newField;
    direction = sortDirections.ASC;
  }

  return { field, direction };
};

export const calculateEndRecipientTotals = (endRecipients = []) => {
  const recipAmount = endRecipients.reduce((sum, er) => {
    if (!er.isRemoved) {
      sum += parseFloat(er.amount, 10);
    }
    return sum;
  }, 0);
  return recipAmount.toFixed(2);
};

export const formatPhone = (val = '') => {
  if (!val) {
    return '';
  }
  const numsOnly = val.replace(/\D/g, '');

  if (numsOnly.length === 11) {
    return `${numsOnly.substring(0, 1)}-${numsOnly.substring(
      1,
      4,
    )}-${numsOnly.substring(4, 7)}-${numsOnly.substring(7)}`;
  }

  if (numsOnly.length === 10) {
    return `${numsOnly.substring(0, 3)}-${numsOnly.substring(
      3,
      6,
    )}-${numsOnly.substring(6)}`;
  }

  if (numsOnly.length === 7) {
    return `${numsOnly.substring(0, 3)}-${numsOnly.substring(3)}`;
  }

  return val;
};

const filterTags = t =>
  ![
    'contributor',
    'lender',
    'recipient',
    'endRecipient',
    'institution',
  ].includes(t);

export const filterOutSystemTags = (tags = '') =>
  Array.isArray(tags)
    ? tags.filter(filterTags)
    : (tags || '').split(',').filter(filterTags);

export const deserializeDate = value => {
  if (!value) {
    return null;
  }
  let year;
  let month;
  let day;

  if (value instanceof Date) {
    year = value.getFullYear();
    month = value.getMonth();
    day = value.getDate();
  } else if (typeof value === 'string') {
    [year, month, day] = value.split('T')[0].split('-');
  }

  const date = new Date(
    typeof year === 'string' ? parseInt(year, 10) : year,
    typeof month === 'string' ? parseInt(month, 10) - 1 : month,
    typeof day === 'string' ? parseInt(day, 10) : day,
  );

  return date;
};

export const sortDirectionMap = {
  [sortDirections.ASC]: 1,
  [sortDirections.DESC]: -1,
  [sortDirections.NONE]: 0,
};

// From FEC doc
// This indicates the user has typed an illegal character.
// Keyboard characters and punctuation characters are allowed,
// specifically ASCII characters: 32 – 168 and 173 (excluding 127 and 157 - 159).

// Ensure in this range
// (dec) : (hex)
// 32  : 20
// 173 : AD

// Omit these
// 169 : A9
// 172 : AC

// 127 : 7F

// 157 : 9D
// 159 : 9F
export const cleanNonFecChars = (s) => {
  // Alternate single quote caused issues when we pasted into field, replace with single
  // quote from regular ascii set
  return s.replace('‘', "'").replace(/[^\x20-\xad]|[\xa9-\xac]|\x7f|[\x9d-\x9f]/g, '');
};

// Returns {a: 'a', b: 'b', c: null} as [{a: 'a'}, {b: 'b'}] to facilitate easier
// mapping of filter objects further down path with reduce
export const listValidFilterObjects = (obj) => {
  return Object.entries(obj).reduce((acc, o) => {
    const [k, v] = o;
    if (v && v !== '0') {
      acc.push({ [k]: v });
    }
    return acc;
  }, []);
};

export const getLabelFromOptions = (options, key) => {
  const pac = options.find(o => o.key === key);
  return pac ? pac.text : '';
};

export const mbToBytes = mbSize => (mbSize ?? 5) * 2 ** 20;
