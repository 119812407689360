import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { Link } from 'office-ui-fabric-react/lib/Link';
import { Label } from 'office-ui-fabric-react/lib/Label';
import { Toggle } from 'office-ui-fabric-react/lib/Toggle';
import { StatePicker, DatePicker } from '../../components/Pickers';
import {
  Grid,
  GridRow,
  Column,
  EmailField,
  PhoneNumberField,
  ZipCodeField,
} from '../../components/common';
import './EditCommitteeDetails.css';

const EditCommitteeDetails = ({
  campaign: { committee, campaignCommitteeRegistered, isNonCandidateCommittee },
  formSection,
  committeeNameErrorMessage,
  committeeDetailsExpanded,
  actions,
  session,
}) => (
  <div className="EditCommitteeDetails depth-1" name="committee-details">
    <h3>
      Committee Information{' '}
      <small>
        {' '}
        (
        <Link onClick={actions.toggleExpandCommitteeDetails}>
          {committeeDetailsExpanded ? 'Collapse' : 'Expand'}
        </Link>
        )
      </small>
    </h3>
    <div
      className={`edit-committee-details-content${
        committeeDetailsExpanded ? ' expanded' : ''
      }`}
    >
      <Grid>
        {!isNonCandidateCommittee && !session.isFederal() && (
          <GridRow>
            <Column sm={6}>
              <Label>
                {`If a Candidate or Public Official is there a campaign committee (one
                    or more persons) to make campaign transactions keep financial
                    records of the campaign, or file the reports`}
              </Label>
            </Column>
            <Column sm={6}>
              <Toggle
                onText="Yes"
                offText="No"
                checked={campaignCommitteeRegistered}
                onChange={actions.toggleCommitteeRegistered}
              />
            </Column>
          </GridRow>
        )}
        {(campaignCommitteeRegistered ||
          isNonCandidateCommittee ||
          session.isFederal()) && (
          <Fragment>
            <GridRow>
              <Column>
                <TextField
                  label="Campaign Name"
                  required
                  value={committee.name}
                  errorMessage={committeeNameErrorMessage}
                  onChange={actions.handleTextChange('name')}
                />
              </Column>
            </GridRow>
            {isNonCandidateCommittee && (
              <GridRow>
                <Column lg={6}>
                  <DatePicker
                    value={committee.registrationDate}
                    onChange={actions.handleChangeRegistrationDate}
                    label="Committee Registration Date"
                  />
                </Column>
              </GridRow>
            )}
            <GridRow>
              <Column lg={6}>
                <TextField
                  label="Committee Address 1"
                  value={committee.addressLine1}
                  required
                  onChange={actions.handleTextChange('addressLine1')}
                />
              </Column>
              <Column lg={6}>
                <TextField
                  label="Committee Address 2"
                  value={committee.addressLine2}
                  onChange={actions.handleTextChange('addressLine2')}
                />
              </Column>
            </GridRow>
            <GridRow>
              <Column lg={4}>
                <TextField
                  label="Committee City"
                  required
                  value={committee.city}
                  onChange={actions.handleTextChange('city')}
                />
              </Column>
              <Column lg={4}>
                <StatePicker
                  required
                  fieldLabel="Committee State"
                  selectedKey={committee.state}
                  onChange={actions.handleStateChange}
                  session={session}
                />
              </Column>
              <Column lg={4}>
                <ZipCodeField
                  value={committee.zipCode}
                  label="Committee Zip Code"
                  required
                  onChange={actions.handleTextChange('zipCode')}
                />
              </Column>
            </GridRow>
            <GridRow>
              <Column lg={6}>
                <PhoneNumberField
                  label="Committee Phone Number"
                  value={committee.telephoneNumber}
                  onChange={actions.handleTextChange('telephoneNumber')}
                />
              </Column>
              <Column lg={6}>
                <EmailField
                  required
                  label="Committee Email"
                  value={committee.emailAddress}
                  onChange={actions.handleTextChange('emailAddress')}
                  setFormInvalid={actions.setFormInvalid}
                  formSection={formSection}
                />
              </Column>
            </GridRow>
          </Fragment>
        )}
      </Grid>
    </div>
    {committeeDetailsExpanded && (
      <div className="ms-textAlignRight">
        <DefaultButton
          style={{ marginRight: 8 }}
          text="Back"
          onClick={() => actions.previousStep(isNonCandidateCommittee ? 0 : 1)}
        />
        <DefaultButton
          text="Continue"
          onClick={() =>
            actions.nextStep(
              campaignCommitteeRegistered ||
                isNonCandidateCommittee ||
                session.isFederal()
                ? 3
                : 5,
            )
          }
        />
      </div>
    )}
  </div>
);

EditCommitteeDetails.propTypes = {
  campaign: PropTypes.object,
  committeeDetailsExpanded: PropTypes.bool,
  committeeNameErrorMessage: PropTypes.string,
  formSection: PropTypes.number,
  actions: PropTypes.object.isRequired,
  session: PropTypes.object.isRequired,
};

EditCommitteeDetails.defaultProps = {
  campaign: {},
  committeeNameErrorMessage: '',
  committeeDetailsExpanded: false,
  formSection: null,
};

export default EditCommitteeDetails;
