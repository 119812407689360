import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, GridRow, Column } from '../../common';

export default class Section5 extends Component {
  static propTypes = {
    q5Sum: PropTypes.shape({
      inKind: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }).isRequired,
    q5: PropTypes.shape({
      inKind: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  };

  shouldComponentUpdate(nextProps) {
    return (
      nextProps.q5.inKind !== this.props.q5.inKind ||
      nextProps.q5.amount !== this.props.q5.amount ||
      nextProps.q5Sum.inKind !== this.props.q5Sum.inKind ||
      nextProps.q5Sum.amount !== this.props.q5Sum.amount
    );
  }

  render() {
    const { q5, q5Sum } = this.props;

    return (
      <GridRow>
        <Column sm={1} classNames="ms-textAlignRight">
          <h3 className="form-label">5</h3>
        </Column>
        <Column sm={11}>
          <Grid>
            <GridRow>
              <Column sm={8}>
                <p>Total contributions reported this period.</p>
                <p>{'(Line 3 + 3a + 3b + 3c + 3d + 4)'}</p>
              </Column>
              <Column sm={2} classNames="ms-textAlignCenter money-input">
                <p style={{ padding: '16px 12px 0 0' }}>
                  {q5Sum.inKind !== q5.inKind ? q5Sum.inKind : q5.inKind}
                </p>
              </Column>
              <Column sm={2} classNames="ms-textAlignCenter money-input">
                <p style={{ padding: '16px 12px 0 0' }}>
                  {q5Sum.amount !== q5.amount ? q5Sum.amount : q5.amount}
                </p>
              </Column>
            </GridRow>
          </Grid>
        </Column>
      </GridRow>
    );
  }
}
