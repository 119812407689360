import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Label } from 'office-ui-fabric-react/lib/Label';
import { NormalPeoplePicker } from 'office-ui-fabric-react/lib/Pickers';
import { GridRow, Column } from '../../common';

const ExistingContactSelector = ({
  onResolveSuggestions,
  onRenderSuggestionsItem,
  onItemSelected,
  cancelEditSelectedContact,
  contactSearchString,
  label = 'Search Contact by Name ("first last" or "business")',
}) => {
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current && contactSearchString) {
      ref.current.input.current._updateValue(contactSearchString);
    }
  }, [ref, contactSearchString]);

  return (
    <GridRow>
      <Column>
        <Label>{label}</Label>
        <NormalPeoplePicker
          itemLimit={1}
          componentRef={ref}
          onResolveSuggestions={onResolveSuggestions}
          onRenderSuggestionsItem={onRenderSuggestionsItem}
          onItemSelected={contact => {
            cancelEditSelectedContact();
            onItemSelected(contact);
          }}
        />
      </Column>
    </GridRow>
  );
};

ExistingContactSelector.propTypes = {
  onResolveSuggestions: PropTypes.func.isRequired,
  onRenderSuggestionsItem: PropTypes.func.isRequired,
  onItemSelected: PropTypes.func.isRequired,
  cancelEditSelectedContact: PropTypes.func.isRequired,
  contactSearchString: PropTypes.string,
  label: PropTypes.string,
};

export default ExistingContactSelector;
