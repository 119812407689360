import axios from 'axios';
import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  PrimaryButton,
} from 'office-ui-fabric-react/lib/Button';

import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { actions as currentReportActions } from '../../actions/currentReportActions';
import { actions as longRunningProcessActions } from '../../actions/longRunningProcessActions';
import { actions as alertActions } from '../../actions/alertActions';
import { getQueryParams } from '../../helpers/urlHelper';
import Loading from '../../components/Loading';
import { Grid, GridRow, Column, BackButton } from '../../components/common';
import { statuses, ReportTypes } from '../../helpers/constants';
import { SubmitReportDialog } from '../../components/Dialogs';

import './Verify.css';

export class VerifyReport extends Component {
  static propTypes = {
    alertActions: PropTypes.object.isRequired,
    currentReport: PropTypes.object.isRequired,
    currentReportActions: PropTypes.object.isRequired,
    longRunningProcessActions: PropTypes.object.isRequired,
    longRunningProcess: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
  };

  params = getQueryParams(this.props.location.search);

  filingStates = {
    verify: 'Batch Verified',
    apply: 'Batch Apply',
    summary: 'Report Summary Viewed',
    pdf: 'Report PDF Viewed',
    filed: 'Report Filed',
  };

  state = {
    jobsRun: [],
    fileGAReportDialogHidden: true,
    gaSubmitError: '',
    isFilingGA: false,
    reportDetail: {},
    submitError: null,
    verifyInProgress: false,
  };

  getLastJob = () => {
    const jobs = [...this.state.jobsRun];
    if (jobs.length === 0) {
      return { jobId: null, completedState: null, jobResult: null };
    }
    return jobs[jobs.length - 1];
  };

  queueJob = (jobId) => {
    this.setState(state => {
      return {
        ...state,
        jobsRun: [...state.jobsRun, { jobId, completedState: null, jobResult: null }],
      };
    });
  };

  setJobRunResult = (endState, jobResult) => {
    this.setState(state => {
      const jobs = [...state.jobsRun];
      const lastJob = jobs.length && jobs[jobs.length - 1];
      lastJob.completedState = endState;
      lastJob.jobResult = jobResult;
      return {
        ...state,
        jobsRun: jobs,
      };
    });
  };

  getGADraftPDF = async () => {
    const { currentReportActions } = this.props;
    const blankPagePath = `${window.location.origin}/api/filer/message/wait`;
    const newWindow = window.open(blankPagePath, '_blank');
    this.setState({
      pdfWindow: newWindow,
    });
    currentReportActions.requestGAWebServiceDraftReport(this.state.reportDetail.reportingId);
  };

  generatePDF = () => {
    window.open(
      `/api/filer/reports/${this.props.match.params.id}/${
        this.state.reportDetail.reportType === ReportTypes.TBD ? 'tbd' : 'pdf'
      }`,
    );
  };

  componentDidMount() {
    this.getReportInformation();
    if (this.params.refreshVerify) {
      this.verifyReport();
    }
  }

  componentDidUpdate(prevProps) {
    const previousStatus = prevProps?.longRunningProcess?.status;
    const currentStatus = this.props.longRunningProcess?.status;
    const currentResultPayload = this.props.longRunningProcess?.returnedFromServer;

    if (previousStatus === statuses.PROCESSING && currentStatus !== statuses.PROCESSING) {
      this.setJobRunResult(currentStatus, currentResultPayload);
    }

    const prevGAPdfPreviewStatus = prevProps?.currentReport?.requestGAWebServiceDraftReportStatus;
    const getGAPdfPreviewStatus = this.props?.currentReport?.requestGAWebServiceDraftReportStatus;

    const prevFileGAJobStatus = prevProps?.currentReport?.fileGAJobStatus;
    const getFileGAJobStatus = this.props?.currentReport?.fileGAJobStatus;
    const fileGAJobError = this.props?.currentReport?.fileGAJobError;

    if (prevFileGAJobStatus !== statuses.PROCESSING && getFileGAJobStatus === statuses.PROCESSING) {
      this.setState({
        isFilingGA: true,
      });
    }

    if (prevFileGAJobStatus === statuses.PROCESSING && getFileGAJobStatus === statuses.SUCCESS) {
      this.setState({
        isFilingGA: false,
      });
      const location = this.state.reportDetail.reportType === ReportTypes.TBD ? '/filer/tbdReports' : '/filer/reports';
      this.props.history.push(location);
    }

    if (prevFileGAJobStatus === statuses.PROCESSING && getFileGAJobStatus === statuses.ERROR) {
      this.setState({
        isFilingGA: false,
        gaSubmitError: fileGAJobError,
      });
    }

    if (prevGAPdfPreviewStatus === statuses.PROCESSING && getGAPdfPreviewStatus !== statuses.PROCESSING) {
      this.state.pdfWindow.location.href = `${window.location.origin}/api/filer/reports/${this.state.reportDetail.reportingId}/fec/draftPdf`;
    }
  }

  openSubmitReportDialog = () => {
    this.setState({
      fileGAReportDialogHidden: false,
      gaSubmitError: '',
    });
  };

  closeSubmitReportDialog = () => {
    this.setState({
      fileGAReportDialogHidden: true,
    });
  };

  getReportInformation = async () => {
    const { currentReportActions, match } = this.props;
    const { id: reportId } = match.params;
    const { data: reportDetail } = await axios.get(`/api/filer/lookup/Report/${reportId}`);
    this.setState({
      reportDetail,
    });
    currentReportActions.getXmlReport(reportId);
  };

  downloadReport = () => {
    const { match } = this.props;
    window.open(`/api/filer/reports/${match.params.id}/state/downloadValidate`);
  };

  editContribution = id => {
    const { match, alertActions, history } = this.props;
    const { id: reportId } = match.params;
    alertActions.clear();
    history.push(`/filer/editContribution/${id}?verifyReport=${reportId}`);
  };

  editExpenditure = id => {
    const { match, alertActions, history } = this.props;
    const { id: reportId } = match.params;
    alertActions.clear();
    history.push(`/filer/editExpenditure/${id}?verifyReport=${reportId}`);
  };

  editLoan = id => {
    const { match, alertActions, history } = this.props;
    const { id: reportId } = match.params;
    alertActions.clear();
    history.push(`/filer/editLoan/${id}?verifyReport=${reportId}`);
  };

  verifyBatch = () => {
    this.queueJob(this.filingStates.verify);
    const { longRunningProcessActions } = this.props;
    const { reportDetail } = this.state;

    longRunningProcessActions.longRunningProcess(
      'ga-batch-validate',
      'Georgia Verify',
      {
        startDate: reportDetail.startDate,
        endDate: reportDetail.endDate,
        electionDate: reportDetail.electionDate,
        reportKey: reportDetail._id,
        reportType: reportDetail.reportType,
      },
    );
  };

  applyBatch = () => {
    this.queueJob(this.filingStates.apply);
    const { longRunningProcessActions } = this.props;
    const { reportDetail } = this.state;

    longRunningProcessActions.longRunningProcess(
      'ga-batch-apply',
      'Georgia Apply Records',
      {
        startDate: reportDetail.startDate,
        endDate: reportDetail.endDate,
        electionDate: reportDetail.electionDate,
        reportType: reportDetail.reportType,
      },
    );
  };

  compareSummary = () => {
    this.queueJob(this.filingStates.summary);
    const { longRunningProcessActions } = this.props;
    const { reportDetail } = this.state;

    longRunningProcessActions.longRunningProcess(
      'get-ga-report-summary',
      'Get Georgia Report Summary Information',
      {
        reportId: reportDetail._id,
        isSupplemental: reportDetail.reportType === ReportTypes.TBD,
      },
    );

    this.setState({
      messagingText: 'Getting summary...',
    });
  };

  fileGAReport = () => {
    this.closeSubmitReportDialog();
    this.queueJob(this.filingStates.filed);
    const { longRunningProcessActions } = this.props;
    const { reportDetail } = this.state;

    longRunningProcessActions.longRunningProcess(
      'file-with-ga',
      'File Georgia Report',
      {
        reportId: reportDetail._id,
        reportType: reportDetail.reportType,
      },
    );
  };

  render() {
    const {
      currentReport: { xmlReportXml, xmlReport },
      history,
      match,
    } = this.props;

    const {
      fileGAReportDialogHidden,
      isFilingGA,
      gaSubmitError,
    } = this.state;

    if (xmlReportXml === null && xmlReport.message === null) {
      return <Loading />;
    }

    const lastJob = this.getLastJob();
    const lastJobFiledSuccessfully = lastJob.jobId === 'Report Filed' && lastJob.completedState === statuses.SUCCESS && lastJob?.jobResult.isOK;
    const successfulFileReturnLocation = this.state.reportDetail.reportType === ReportTypes.TBD ? '/filer/tbdReports' : '/filer/reports';

    return (
      <Fragment>
        <BackButton
          history={history}
          message={lastJobFiledSuccessfully ? 'Back to Report List' : 'Back to Report'}
          url={lastJobFiledSuccessfully ? successfulFileReturnLocation : `/filer/editReport/${match.params.id}`}
        />
        <div className="VerifyReport">
          <div className="verify-report-content">
            <div className="report depth-1">
              <h3>Report Verification / File</h3>
              <Grid>
                <GridRow className="verify-report-actions">
                  <Column>
                    <PrimaryButton
                      onClick={this.verifyBatch}
                      text="Verify Batch For Report"
                      style={{ marginBottom: 16, width: 240 }}
                      disabled={lastJobFiledSuccessfully}
                    />
                    <br />
                    <PrimaryButton
                      onClick={this.applyBatch}
                      text="Apply Verified Batch"
                      style={{ marginBottom: 16, width: 240 }}
                      disabled={lastJobFiledSuccessfully}
                    />
                    <br />
                    <PrimaryButton
                      onClick={this.compareSummary}
                      text="Compare FR to GA Summary"
                      style={{ marginBottom: 16, width: 240 }}
                      disabled={lastJobFiledSuccessfully}
                    />
                    <br />
                    <PrimaryButton
                      className="action-btn"
                      text="View FR Draft Report"
                      iconProps={{ iconName: 'FilePdf' }}
                      style={{ marginBottom: 16, width: 240 }}
                      onClick={this.generatePDF}
                      disabled={lastJobFiledSuccessfully}
                    />
                    <br />
                    <PrimaryButton
                      onClick={this.getGADraftPDF}
                      text="View GA Draft Report"
                      iconProps={{ iconName: 'FilePdf' }}
                      style={{ marginBottom: 16, width: 240 }}
                      disabled={lastJobFiledSuccessfully}
                    />
                    <br />
                    <PrimaryButton
                      text="Submit Report to State"
                      iconProps={{ iconName: 'Upload' }}
                      onClick={this.openSubmitReportDialog}
                      style={{ marginBottom: 16, width: 240 }}
                      disabled={lastJobFiledSuccessfully}
                    />
                    <br />
                  </Column>
                </GridRow>
              </Grid>
            </div>
            <div className="response depth-1">
              <h3>{this.state.reportDetail.reportingName}</h3>
              {(
                lastJob.jobId === 'Batch Verified'
                && lastJob.completedState === statuses.SUCCESS
                && lastJob.jobResult.isOK
                && lastJob.jobResult.data
              ) &&
                <Fragment>
                  <PrimaryButton
                    onClick={this.downloadReport}
                    text="Download Verify Report"
                    style={{ marginRight: 16, float: 'right' }}
                  />
                  <h3>Please resolve the following errors:</h3>
                  <pre>
                    {lastJob.jobResult.data || ''}
                  </pre>
                </Fragment>
              }
              {(
                lastJob.jobId === 'Batch Verified'
                && lastJob.completedState === statuses.SUCCESS
                && lastJob.jobResult.isOK
                && !lastJob.jobResult.data
              ) &&
              <h3>Report Verified Successfully</h3>
              }
              {(lastJob.jobId === 'Batch Verified' && lastJob.completedState === statuses.SUCCESS && !lastJob.jobResult.isOK) &&
              <h3>Unable to verify batch</h3>
              }

              {(lastJob.jobId === 'Batch Apply' && lastJob.completedState === statuses.SUCCESS) && (
                <Fragment>
                  {lastJob?.jobResult.isOK &&
                  <h3>Successfully Applied Batch</h3>
                  }
                  {!lastJob?.jobResult.isOK &&
                  <h3 className="error">Error Applying Batch<br />Use Verify File To Determine Issue</h3>
                  }
                </Fragment>
              )}

              {(lastJob.jobId === 'Report Summary Viewed' && lastJob.completedState === statuses.SUCCESS && lastJob?.jobResult.isOK) && (
                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
                  <pre>{JSON.stringify(lastJob.jobResult.data.response, null, 2)}</pre>
                  <pre>{JSON.stringify(lastJob.jobResult.data.fRSummary, null, 2)}</pre>
                </div>
              )}
              {lastJobFiledSuccessfully && (
                <h3>Report Filed Successfully
                  <Icon
                    iconName="Check"
                    styles={{
                      root: {
                        display: 'inline-flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: 24,
                        height: 24,
                        fontSize: '18px',
                        color: 'green',
                        borderRadius: '50%',
                        marginTop: '33px',
                        marginLeft: '8px',
                      },
                    }}
                  />
                </h3>
              )}
            </div>
          </div>
        </div>
        <SubmitReportDialog
          dialogHidden={fileGAReportDialogHidden}
          onCancel={this.closeSubmitReportDialog}
          onConfirm={this.fileGAReport}
          isSubmittingFile={isFilingGA}
          submitError={gaSubmitError}
        />
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentReport: state.currentReport,
    longRunningProcess: state.longRunningProcess,
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    currentReportActions: bindActionCreators(currentReportActions, dispatch),
    longRunningProcessActions: bindActionCreators(longRunningProcessActions, dispatch),
    alertActions: bindActionCreators(alertActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VerifyReport);
