import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { GridRow, Column, MoneyField } from '../../common';

const FECSection9 = ({ q9ColA }) => {
  return (
    <Fragment>
      <GridRow style={{ borderTop: '1px solid #919191' }}>
        <Column sm={4}>
          <p>
            9. Debts and Obligations owed <strong>TO</strong> the Committee
            <br />
            {'(Itemize all on Schedule C and/or Schedule D)'}
          </p>
        </Column>
        <Column sm={4} classNames="entry-row">
          <MoneyField
            disabled
            value={q9ColA || 0}
          />
        </Column>
      </GridRow>
    </Fragment>
  );
};

FECSection9.propTypes = {
  q9ColA: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default FECSection9;
