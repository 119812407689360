import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { ChoiceGroup } from 'office-ui-fabric-react/lib/ChoiceGroup';
import { GridRow, Column } from '../../common';

export default class Section7 extends Component {
  static propTypes = {
    hasExpendituresToReport: PropTypes.bool,
    actions: PropTypes.object.isRequired,
  };

  static defaultProps = {
    hasExpendituresToReport: false,
  };

  render() {
    const { hasExpendituresToReport, actions } = this.props;

    return (
      <GridRow>
        <Column sm={1} classNames="ms-textAlignRight">
          <h3 className="form-label">7</h3>
        </Column>
        <Column sm={11}>
          <ChoiceGroup
            selectedKey={hasExpendituresToReport === false ? 'no' : 'yes'}
            onChange={actions.changeExpendituresToReport}
            options={[
              {
                key: 'no',
                text: (
                  <Fragment>
                    <span>{'I have '}</span>
                    <strong>no</strong>
                    <span>{' expenditures to report'}</span>
                  </Fragment>
                ),
              },
              {
                key: 'yes',
                text: 'I have the following expenditures to report:',
              },
            ]}
          />
        </Column>
      </GridRow>
    );
  }
}
