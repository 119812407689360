import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'office-ui-fabric-react/lib/Dropdown';
import { allocatedActivityOrEventOptions } from '../../helpers/constants';
import { CaretDown } from '../icons';

export const AllocationTypePicker = ({
  selectedKey,
  onChange,
  required,
  className,
  style,
  errorMessage,
  label,
  disabled,
}) => (
  <Dropdown
    onRenderCaretDown={() => <CaretDown />}
    selectedKey={selectedKey}
    className={className}
    onChange={onChange}
    required={required}
    style={style}
    errorMessage={errorMessage}
    label={label}
    disabled={disabled}
    options={[
      { key: 0, text: 'Select' },
      ...allocatedActivityOrEventOptions,
    ]}
  />
);

AllocationTypePicker.propTypes = {
  selectedKey: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.number,
  ]),
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
  errorMessage: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
};

AllocationTypePicker.defaultProps = {
  selectedKey: { key: 0 },
  required: false,
  className: '',
  style: {},
  errorMessage: '',
  label: 'Committee Type',
  disabled: false,
};

export default AllocationTypePicker;
