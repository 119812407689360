import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'office-ui-fabric-react/lib/Dropdown';
import { Link } from 'office-ui-fabric-react/lib/Link';
import { PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import {
  fecStateMapByStateName,
  FECReportingFrequencies,
  FecReportTypes,
  FecMonthlyReportTypes,
} from '../../helpers/constants';
import { cleanNonFecChars } from '../../helpers/util';
import { Grid, GridRow, Column, LabelGroup } from '../common';
import { CaretDown } from '../icons';
import { DatePicker, FECElectionCyclePicker } from '../Pickers';

const getStateAbbr = state => {
  return fecStateMapByStateName[state];
};

const FECCoverPage = ({
  campaign,
  currentReport,
  handleChange,
  handleDateChange,
  toggleExpandSection,
  state,
  sectionName,
  nextSectionName,
}) => {
  const [showTextSizeWarning, setShowTextSizeWarning] = useState(false);

  const isQuarterlyOrTerminationReport =
    currentReport.report &&
    ['Q1', 'Q2', 'Q3', 'YE', 'TER'].includes(currentReport.report.reportCode);

  const getReportType = (reportType) => {
    if (reportType === '48HourNotice') {
      return 'New (N)';
    }
    if (reportType === '48HourNoticeAmended') {
      return 'Amended (A)';
    }
    return reportType === 'Original' ? 'New (N)' : 'Amended (A)';
  };

  const textStatementHandler = handleChange('textStatementText');
  const onTextStatement = (e) => {
    const val = cleanNonFecChars(e.target.value);
    setShowTextSizeWarning(val.length > 4000);
    textStatementHandler(e, val.slice(0, 4000));
  };
  const is12TypeReport = state.reportCode?.[0] === '1' && state.reportCode?.[1] === '2';

  const getReportTypes = (campaign, report) => {
    // Earlier reports depended on the campaign setting for report frequency but it's an option in report creation
    // Support campaign level queries but if defined in report, use the definition from the report
    const frequency = report?.report?.reportingFrequency ? report.report.reportingFrequency : campaign.reportingFrequency;
    if (frequency === FECReportingFrequencies.Quarterly) {
      return [
        { key: 0, text: 'Select' },
        ...FecReportTypes.map(rt => ({
          key: rt.text,
          text: rt.fullText,
        })),
      ];
    }
    return [
      { key: 0, text: 'Select' },
      ...FecMonthlyReportTypes.map(rt => ({
        key: rt.text,
        text: rt.fullText,
      })),
    ];
  };

  return (
    <div className="FECCoverPage fec-report-section depth-1" name={sectionName}>
      <h3>
        COVER PAGE{' '}
        <small>
          {' '}
          (
          <Link onClick={() => toggleExpandSection(sectionName, false)}>
            {state.expandableSections[sectionName] === true
              ? 'Collapse'
              : 'Expand'}
          </Link>
          )
        </small>
      </h3>
      <div
        className={`edit-fec-cover-page${
          state.expandableSections[sectionName] === true
            ? '-expanded'
            : '-collapsed'
        }`}
      >
        <Grid style={{ padding: '0 24px' }}>
          <GridRow>
            <Column lg={6}>
              <LabelGroup
                label="1. Name of Committee"
                subText="(in full)"
                value={campaign.candidateOrCommitteeFullName || ''}
              />
            </Column>
            <Column lg={6}>
              <LabelGroup
                label="Address"
                subText="(number and street)"
                nonNumbered
                value={campaign.candidateOrCommitteeFullAddress
                  .replace(/,\sundefined/gi, '')
                  .replace(/\sundefined/gi, '')
                  .replace(/undefined/gi, '')}
              />
            </Column>
          </GridRow>
          {campaign.office === 'S' &&
            <GridRow>
              <Column>
                <LabelGroup
                  label="State"
                  nonNumbered
                  value={getStateAbbr(campaign.officeState)}
                />
              </Column>
            </GridRow>
          }
          {campaign.office === 'R' &&
            <GridRow>
              <Column>
                <LabelGroup
                  label="State - District"
                  nonNumbered
                  value={`${getStateAbbr(
                    campaign.officeState,
                  )} - ${campaign.subOffice.length === 1 ? `0${campaign.subOffice}` : campaign.subOffice}`}
                />
              </Column>
            </GridRow>
          }
          <GridRow>
            <Column>
              <LabelGroup
                label="2. FEC Identification Number"
                value={campaign.filerId || ''}
              />
            </Column>
          </GridRow>
          {Object.keys(currentReport.report).length > 0 && (
            <Fragment>
              <GridRow>
                <Column>
                  <LabelGroup
                    label="3. Report Is"
                    value={getReportType(currentReport.report.reportType)}
                  />
                </Column>
              </GridRow>
              <GridRow>
                <Column md={6}>
                  <Dropdown
                    label="4. Report Type"
                    onChange={handleChange('reportCode')}
                    selectedKey={state.reportCode}
                    required
                    placeholder="Select Report Type"
                    errorMessage={state.errors.reportCodeError}
                    options={getReportTypes(campaign, currentReport)}
                    onRenderCaretDown={() => <CaretDown />}
                  />
                </Column>
              </GridRow>
              {(!isQuarterlyOrTerminationReport && !is12TypeReport) && (
                <GridRow>
                  <Column md={6}>
                    <DatePicker
                      label="Election On"
                      onChange={handleDateChange('electionDate')}
                      value={state.electionDate}
                      required
                      errorMessage={state.errors.electionDateError}
                    />
                  </Column>
                  <Column md={6}>
                    <LabelGroup
                      nonNumbered
                      label="In the state of"
                      value={getStateAbbr(campaign.officeState)}
                    />
                  </Column>
                </GridRow>
              )}
              {(is12TypeReport) && (
                <GridRow>
                  <Column md={4}>
                    <DatePicker
                      label="Election On"
                      onChange={handleDateChange('electionDate')}
                      value={state.electionDate}
                      required
                      errorMessage={state.errors.electionDateError}
                    />
                  </Column>
                  <Column md={4}>
                    <FECElectionCyclePicker
                      value={state.electionCycle}
                      onChange={handleChange('electionCycle')}
                      required
                      errorMessage={state.errors.electionCycle}
                    />
                  </Column>
                  <Column md={4}>
                    <LabelGroup
                      nonNumbered
                      label="In the state of"
                      value={campaign.committee?.state}
                    />
                  </Column>
                </GridRow>
              )}
              <GridRow>
                <Column md={6}>
                  <DatePicker
                    label="5. Covering Period"
                    value={state.startDate}
                    required
                    errorMessage={state.errors.startDateError}
                    onChange={handleDateChange('startDate')}
                  />
                </Column>
                <Column md={6}>
                  <DatePicker
                    label="Through"
                    value={state.endDate}
                    required
                    errorMessage={state.errors.endDateError}
                    onChange={handleDateChange('endDate')}
                  />
                </Column>
              </GridRow>
              <GridRow>
                <Column md={6}>
                  <TextField
                    label="Text Statement (for Report)"
                    multiline={true}
                    rows={3}
                    value={state.textStatementText}
                    onChange={onTextStatement}
                  />
                  {showTextSizeWarning &&
                    <span>
                      Limit of 4000 characters for &apos;Text Statement&apos; field.
                    </span>
                  }
                </Column>
              </GridRow>
            </Fragment>
          )}
        </Grid>
        <div className="section-actions">
          {state.reportType !== '48HourNotice' &&
          <PrimaryButton
            text="Save & Continue"
            onClick={() => toggleExpandSection(nextSectionName, true)}
          />
          }
        </div>
      </div>
    </div>
  );
};

FECCoverPage.propTypes = {
  campaign: PropTypes.object.isRequired,
  currentReport: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleDateChange: PropTypes.func.isRequired,
  toggleExpandSection: PropTypes.func.isRequired,
  state: PropTypes.object.isRequired,
  sectionName: PropTypes.string.isRequired,
  nextSectionName: PropTypes.string.isRequired,
};

export default FECCoverPage;
