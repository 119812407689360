import { createReducer } from '../../reducers/createReducer';

export const initialSplitId = 1;

export const initialState = {
  showDeleted: false,
  contactSelectionOption: null,
  createContactModalHidden: true,
  newContact: null,
  selectedExistingContact: null,
  searchedContact: null,
  dateProcessed: null,
  creatingNew: false,
  occupationAndEmployerSwapped: false,
  startSplitId: initialSplitId,
  contributionSplits: [
    {
      tempId: initialSplitId,
      electionYear: null,
      electionCycle: 0,
      amount: 0,
    },
  ],
  errors: {
    dateProcessedError: '',
    contributionSplits: {},
    splitTotalError: '',
  },
};

export const actions = {
  ON_NEXT_ELECTION_YEAR_CHANGE: 'ON_NEXT_ELECTION_YEAR_CHANGE',
  ON_CHANGE_DATE_PROCESSED: 'ON_CHANGE_DATE_PROCESSED',
  ON_CHANGE_CAMPAIGN_DATA: 'ON_CHANGE_CAMPAIGN_DATA',
  OPEN_CREATE_CONTACT_MODAL: 'OPEN_CREATE_CONTACT_MODAL',
  CLOSE_CREATE_CONTACT_MODAL: 'CLOSE_CREATE_CONTACT_MODAL',
  CANCEL_CREATE_CONTACT_MODAL: 'CANCEL_CREATE_CONTACT_MODAL',
  TOGGLE_SELECT_EXISTING_CONTACT: 'TOGGLE_SELECT_EXISTING_CONTACT',
  ON_SEARCH_CONTACT_SELECTED: 'ON_SEARCH_CONTACT_SELECTED',
  ON_CREATE_NEW_CONTACT: 'ON_CREATE_NEW_CONTACT',
  UPDATE_DEFAULT_CONTACT_SELECTION: 'UPDATE_DEFAULT_CONTACT_SELECTION',
  ON_CHANGE_CONTACT_SELECTION_CHOICE: 'ON_CHANGE_CONTACT_SELECTION_CHOICE',
  FORM_ERRORS: 'FORM_ERRORS',
  SWAP_OCCUPATION_AND_EMPLOYER: 'SWAP_OCCUPATION_AND_EMPLOYER',
  PROCESS_NEXT: 'PROCESS_NEXT',
  CLEAR_STATE: 'CLEAR_STATE',
  ADD_SPLIT: 'ADD_SPLIT',
  REMOVE_SPLIT: 'REMOVE_SPLIT',
  TOGGLE_SHOW_DELETED: 'TOGGLE_SHOW_DELETED',
};

export const actionMap = {
  [actions.ON_NEXT_ELECTION_YEAR_CHANGE]: (
    state,
    { data: { electionYear } },
  ) => ({
    ...state,
    contributionSplits: state.contributionSplits.map(split => ({
      ...split,
      electionYear,
    })),
  }),
  [actions.ON_CHANGE_DATE_PROCESSED]: (state, action) => ({
    ...state,
    dateProcessed: action.data.date,
  }),
  [actions.ON_CHANGE_CAMPAIGN_DATA]: (
    state,
    { data: { fieldName, value, tempId } },
  ) => ({
    ...state,
    contributionSplits: state.contributionSplits.map(split => {
      if (split.tempId === tempId) {
        split[fieldName] = value;
      }
      return split;
    }),
  }),
  [actions.OPEN_CREATE_CONTACT_MODAL]: state => ({
    ...state,
    createContactModalHidden: false,
    creatingNew: true,
  }),
  [actions.CLOSE_CREATE_CONTACT_MODAL]: state => ({
    ...state,
    createContactModalHidden: true,
    creatingNew: false,
  }),
  [actions.CANCEL_CREATE_CONTACT_MODAL]: state => ({
    ...state,
    createContactModalHidden: true,
    creatingNew: true,
  }),
  [actions.TOGGLE_SELECT_EXISTING_CONTACT]: (state, action) => ({
    ...state,
    selectedExistingContact: action.data.selectedExistingContact,
    newContact: null,
    searchedContact: null,
  }),
  [actions.ON_SEARCH_CONTACT_SELECTED]: (state, action) => ({
    ...state,
    searchedContact: action.data.contact,
    newContact: null,
    selectedExistingContact: null,
  }),
  [actions.ON_CREATE_NEW_CONTACT]: (state, action) => ({
    ...state,
    newContact: action.data.contact,
    searchedContact: null,
    selectedExistingContact: null,
  }),
  [actions.UPDATE_DEFAULT_CONTACT_SELECTION]: (
    state,
    { data: { selectionName, amount } },
  ) => ({
    ...state,
    selectedExistingContact: null,
    searchedContact: null,
    newContact: null,
    contactSelectionOption: selectionName,
    contributionSplits: [
      {
        ...state.contributionSplits[0],
        amount: (
          Math.round((amount || state.contributionSplits[0].amount) * 100) / 100
        ).toFixed(2),
      },
    ],
  }),
  [actions.FORM_ERRORS]: (state, { data: { errors } }) => ({
    ...state,
    errors,
  }),
  [actions.ON_CHANGE_CONTACT_SELECTION_CHOICE]: (state, action) => ({
    ...state,
    contactSelectionOption: action.data.contactSelectionOption,
    selectedExistingContact: action.data.selectedExistingContact,
    searchedContact: action.data.searchedContact,
    newContact: action.data.newContact,
  }),
  [actions.SWAP_OCCUPATION_AND_EMPLOYER]: state => ({
    ...state,
    occupationAndEmployerSwapped: !state.occupationAndEmployerSwapped,
  }),
  [actions.PROCESS_NEXT]: state => ({
    ...initialState,
    showDeleted: state.showDeleted,
    contributionSplits: [
      {
        tempId: 1,
        electionYear: state.contributionSplits[0]?.electionYear || 0,
        electionCycle: state.contributionSplits[0]?.electionCycle || 0,
        amount: 0,
      },
    ],
    startSplitId: 1,
    selectedExistingContact: null,
    searchedContact: null,
    newContact: null,
  }),
  [actions.CLEAR_STATE]: state => ({
    ...initialState,
    startSplitId: 1,
    contributionSplits: [
      {
        tempId: 1,
        electionYear: state.contributionSplits[0]?.electionYear || 0,
        electionCycle: 0,
        amount: 0,
      },
    ],
  }),
  [actions.ADD_SPLIT]: state => {
    const newSplitId = state.startSplitId + 1;
    return {
      ...state,
      startSplitId: newSplitId,
      contributionSplits: [
        ...state.contributionSplits,
        {
          tempId: newSplitId,
          electionYear: 0,
          electionCycle: 0,
          amount: 0,
        },
      ],
    };
  },
  [actions.REMOVE_SPLIT]: (state, { data: { tempId } }) => ({
    ...state,
    contributionSplits: state.contributionSplits.filter(
      split => split.tempId !== tempId,
    ),
  }),
  [actions.TOGGLE_SHOW_DELETED]: state => ({
    ...state,
    showDeleted: !state.showDeleted,
  }),
};

export const anedotReducer = createReducer(initialState, actionMap);
