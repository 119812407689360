import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { ActionButton } from 'office-ui-fabric-react/lib/Button';
import { Separator } from 'office-ui-fabric-react/lib/Separator';
import { GridRow, Column, MoneyField } from '../../components/common';
import { types as anedotTypes } from '../../actions/anedotActions';
import AggregatesGraph from '../../components/AggregatesGraph';
import {
  YearPicker,
  ElectionCyclePicker,
  FECElectionCyclePicker,
} from '../../components/Pickers';
import { getAggregateAmount } from '../../selectors';

const ContributionSplitRow = ({
  split,
  onChange,
  onRemove,
  onAdd,
  session,
  selectedContact,
  errors = {},
}) => {
  const reduxDispatch = useDispatch();
  const aggregateAmount = useSelector(getAggregateAmount);

  useEffect(() => {
    if (
      split.electionYear !== 0 &&
      split.electionCycle !== 0 &&
      selectedContact !== null
    ) {
      reduxDispatch({
        type: anedotTypes.GET_AGGREGATE_AMOUNT,
        data: {
          electionYear: split.electionYear,
          electionCycle: split.electionCycle,
          _id: selectedContact?._id,
        },
      });
    }
  }, [split.electionYear, split.electionCycle, selectedContact]);

  const existingAggregate =
    selectedContact &&
    aggregateAmount.find(
      a =>
        a._id === selectedContact?._id &&
        a.electionYear === split.electionYear &&
        a.electionCycle === split.electionCycle,
    );

  return (
    <div>
      <GridRow classNames="campaign-details">
        <Column md={3}>
          <YearPicker
            label="Election Year"
            value={split.electionYear}
            onChange={onChange('electionYear', split.tempId)}
            required
            errorMessage={errors?.electionYearError}
          />
        </Column>
        <Column md={4}>
          {session.isFederal() ? (
            <FECElectionCyclePicker
              label="Election"
              value={split.electionCycle}
              onChange={onChange('electionCycle', split.tempId)}
              required
              errorMessage={errors?.electionCycleError}
            />
          ) : (
            <ElectionCyclePicker
              label="Election"
              value={split.electionCycle}
              onChange={onChange('electionCycle', split.tempId)}
              required
              errorMessage={errors?.electionCycleError}
            />
          )}
        </Column>
        <Column md={3}>
          <MoneyField
            label="Amount"
            value={split.amount}
            required
            errorMessage={errors?.amountError}
            onChange={onChange('amount', split.tempId)}
          />
        </Column>
        <Column md={2}>
          <div className="split-actions">
            {split.tempId === 1 && (
              <ActionButton
                iconProps={{ iconName: 'Plus' }}
                text="Split Amount"
                onClick={onAdd}
              />
            )}
            {split.tempId > 1 && (
              <ActionButton
                disabled={split.tempId === 1}
                iconProps={{
                  iconName: 'Times',
                  styles: {
                    root: { color: split.tempId === 1 ? '' : '#a80000' },
                  },
                }}
                text="Remove"
                onClick={() => onRemove(split.tempId)}
              />
            )}
          </div>
        </Column>
      </GridRow>
      <div style={{ height: 10 }} />
      {selectedContact !== null &&
        split.electionYear !== 0 &&
        split.electionCycle !== 0 &&
        existingAggregate && (
          <AggregatesGraph
            aggregates={[existingAggregate]}
            electionYear={split.electionYear}
            electionCycle={split.electionCycle}
            amount={split.amount || 0}
          />
        )}
      <Separator />
    </div>
  );
};

ContributionSplitRow.propTypes = {
  split: PropTypes.shape({
    tempId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    electionYear: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    electionCycle: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    errors: PropTypes.shape({
      electionYearError: PropTypes.string,
      electionCycleError: PropTypes.string,
      amountError: PropTypes.string,
    }),
  }),
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  onAdd: PropTypes.func,
  session: PropTypes.object.isRequired,
  errors: PropTypes.object,
  selectedContact: PropTypes.object,
};

export default ContributionSplitRow;
