import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, GridRow, Column } from '../../common';

export default class Section9 extends Component {
  static propTypes = {
    q9: PropTypes.shape({
      inKind: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  };

  render() {
    const { q9 } = this.props;
    return (
      <GridRow>
        <Column sm={1} classNames="ms-textAlignRight">
          <h3 className="form-label">9</h3>
        </Column>
        <Column sm={11}>
          <Grid>
            <GridRow>
              <Column sm={8}>
                <p>
                  {
                    'Total amount of all itemized expenditures made in this reporting period which are listed in the "Itemized Expenditures" section.'
                  }
                </p>
              </Column>
              <Column sm={2} classNames="ms-textAlignCenter money-input">
                <p style={{ margin: '16px 12px 0 0' }}>{q9.inKind}</p>
              </Column>
              <Column sm={2} classNames="ms-textAlignCenter money-input">
                <p style={{ margin: '16px 12px 0 0' }}>{q9.amount}</p>
              </Column>
            </GridRow>
          </Grid>
        </Column>
      </GridRow>
    );
  }
}
