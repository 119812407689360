import { takeLatest, call, put } from 'redux-saga/effects';
import axios from 'axios';
import { push } from 'connected-react-router';
import {
  getServerSideErrorMessage,
  errorToast,
  successToast,
} from '../helpers/util';
import { types as reconciliationTypes } from '../actions/reconciliationActions';

const url = '/api/filer/reconciliations';

const creds = { withCredentials: true };

export function* handleGetUnreconciledActivity() {
  try {
    const {
      data: { deposits, expenditures, interestContributions },
    } = yield call(axios.get, '/api/filer/activity/unreconciled', creds);

    yield put({
      type: reconciliationTypes.GET_UNRECONCILED_ACTIVITY_SUCCESS,
      data: {
        deposits,
        expenditures,
        interestContributions,
      },
    });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put(errorToast(error));
  }
}

export function* getUnreconciledActivity() {
  yield takeLatest(
    reconciliationTypes.GET_UNRECONCILED_ACTIVITY,
    handleGetUnreconciledActivity,
  );
}

export function* handleCreateReconciliation(action) {
  try {
    const { payload } = action.data;
    yield call(axios.post, url, payload, creds);
    yield put({ type: reconciliationTypes.GET_UNRECONCILED_ACTIVITY });
    yield put(successToast('Reconciliation created!'));
    yield put(push('/filer/reconciliations'));
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put(errorToast(error));
  }
}

export function* createReconciliation() {
  yield takeLatest(
    reconciliationTypes.CREATE_RECONCILIATION,
    handleCreateReconciliation,
  );
}

export function* handleGetAllReconciliations() {
  try {
    yield put({ type: reconciliationTypes.GET_RECONCILIATIONS_PROCESSING });
    const {
      data: { deposits, expenditures, interestContributions },
    } = yield call(axios.get, '/api/filer/activity/unreconciled', creds);
    const { data: reconciliations } = yield call(axios.get, url, creds);
    yield put({
      type: reconciliationTypes.GET_RECONCILIATIONS_SUCCESS,
      data: { reconciliations, deposits, expenditures, interestContributions },
    });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put(errorToast(error));
  }
}

export function* getAllReconciliations() {
  yield takeLatest(
    reconciliationTypes.GET_RECONCILIATIONS,
    handleGetAllReconciliations,
  );
}

export function* handleGetSingleReconciliation(action) {
  try {
    const { id } = action.data;
    const { data: reconciliation } = yield call(
      axios.get,
      `${url}/${id}`,
      creds,
    );

    yield put({
      type: reconciliationTypes.GET_SINGLE_RECONCILIATION_SUCCESS,
      data: { reconciliation },
    });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put(errorToast(error));
  }
}

export function* getSingleReconciliation() {
  yield takeLatest(
    reconciliationTypes.GET_SINGLE_RECONCILIATION,
    handleGetSingleReconciliation,
  );
}

export function* handleUpdateReconciliation(action) {
  try {
    const { id, payload } = action.data;
    yield call(axios.put, `${url}/${id}`, payload, creds);
    yield put({
      type: reconciliationTypes.UPDATE_RECONCILIATION_SUCCESS,
      data: { id, reconciliation: payload },
    });
    yield put({ type: reconciliationTypes.GET_UNRECONCILED_ACTIVITY });
    yield put(successToast('Reconciliation updated!'));
    yield put(push('/filer/reconciliations'));
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put(errorToast(error));
  }
}

export function* updateReconciliation() {
  yield takeLatest(
    reconciliationTypes.UPDATE_RECONCILIATION,
    handleUpdateReconciliation,
  );
}

export function* handleDeleteReconciliation(action) {
  try {
    const { id } = action.data;
    yield call(axios.delete, `${url}/${id}`, { withCredentials: true });
    yield put({
      type: reconciliationTypes.DELETE_RECONCILIATION_SUCCESS,
      data: { id },
    });
    yield put(successToast('Reconciliation deleted!'));
    yield put({ type: reconciliationTypes.GET_UNRECONCILED_ACTIVITY });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put(errorToast(error));
  }
}

export function* deleteReconciliation() {
  yield takeLatest(
    reconciliationTypes.DELETE_RECONCILIATION,
    handleDeleteReconciliation,
  );
}
