export const types = {
  GET_CONTRIBUTIONS_SUMMARY: 'GET_CONTRIBUTIONS_SUMMARY',
  GET_CONTRIBUTIONS_SUMMARY_PROCESSING: 'GET_CONTRIBUTIONS_SUMMARY_PROCESSING',
  GET_CONTRIBUTIONS_SUMMARY_SUCCESS: 'GET_CONTRIBUTIONS_SUMMARY_SUCCESS',
  GET_CONTRIBUTIONS_SUMMARY_FAILURE: 'GET_CONTRIBUTIONS_SUMMARY_FAILURE',
  GET_CONTRIBUTIONS_SUMMARY_NEXT_PAGE: 'GET_CONTRIBUTIONS_SUMMARY_NEXT_PAGE',
  GET_CONTRIBUTIONS_SUMMARY_NEXT_PAGE_PROCESSING: 'GET_CONTRIBUTIONS_SUMMARY_NEXT_PAGE_PROCESSING',
  GET_CONTRIBUTIONS_SUMMARY_NEXT_PAGE_SUCCESS: 'GET_CONTRIBUTIONS_SUMMARY_NEXT_PAGE_SUCCESS',
  GET_CONTRIBUTIONS_SUMMARY_NEXT_PAGE_FAILURE: 'GET_CONTRIBUTIONS_SUMMARY_NEXT_PAGE_FAILURE',

};

export const actions = {
  getContributionsSummary() {
    return { type: types.GET_CONTRIBUTIONS_SUMMARY };
  },
  getContributionsSummaryNextPage() {
    return { type: types.GET_CONTRIBUTIONS_SUMMARY_NEXT_PAGE };
  },
};
