import { createReducer } from '../../reducers/createReducer';

const populateContact = party => {
  return {
    contactId: party._id || '',
    businessName: party.businessName || '',
    businessType: party.businessType || 0,
    contactName: party.contactName || '',
    commonSource: party.commonSource || '',
    specific: party.specific || '',
    committeeAffiliation: party.committeeAffiliation || 0,
    salutation: party.salutation || 'none',
    firstName: party.firstName,
    middleName: party.middleName || '',
    lastName: party.lastName,
    employer: party.employer || '',
    occupation: party.occupation || '',
    isCandidate: party.isCandidate || false,
    addressLine1: party.address?.addressLine1 || party.addressLine1 || '',
    addressLine2: party.address?.addressLine2 || party.addressLine2 || '',
    city: party.address?.city || party.city || '',
    state: party.address?.state || party.state || 0,
    zipCode: party.address?.zipCode || party.zipCode || '',
    county: party.address?.county || party.county || '',
    phone1: party.phone1 || '',
    phone2: party.phone2 || '',
    email: party.email || '',
    fiduciaryRelationship: party.fiduciaryRelationship || '',
    amountGuaranteedOutstanding: party.amountGuaranteedOutstanding || 0,
    responsiblePartyType: party.responsiblePartyType,
    committeeFecId: party.committeeFecId || '',
  };
};

export const initialState = {
  responsiblePartyType: 0,
  contactId: '',

  // IND, Individual, or CAN
  salutation: 'none',
  firstName: '',
  middleName: '',
  lastName: '',
  employer: '',
  occupation: '',
  isCandidate: false,
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: 0,
  zipCode: '',
  county: '',
  phone1: '',
  phone2: '',
  email: '',
  fiduciaryRelationship: '',
  amountGuaranteedOutstanding: '',

  committeeFecId: '',

  // ORG, Business
  businessName: '',
  businessType: 0,
  contactName: '',

  // Committees
  committeeAffiliation: 0,

  // Common Source
  commonSource: '',
  specific: '',

  selectedContact: null,
  resetForm: false,
  errors: {
    firstName: '',
    lastName: '',
    fiduciaryRelationship: '',
    amountGuaranteedOutstanding: '',
    fecIdError: '',
    selectedContactError: '',
    email: '',
  },
};

export const actions = {
  SET_SELECTED_CONTACT: 'SET_SELECTED_CONTACT',
  HANDLE_CHANGE: 'HANDLE_CHANGE',
  SET_FORM_ERRORS: 'SET_FORM_ERRORS',
  CLEAR_FORM: 'CLEAR_FORM',
  CLEAR_CONTACT_INFO: 'CLEAR_CONTACT_INFO',
  RESET_CONTACT_INFO: 'RESET_CONTACT_INFO',
};

export const actionMap = {
  [actions.SET_SELECTED_CONTACT]: (state, { data: { party } }) => {
    const mappedContact = populateContact(party);
    const returnState = {
      ...state,
      ...mappedContact,
      selectedContact: { ...party },
      errors: {
        ...initialState.errors,
      },
      responsiblePartyType:
        mappedContact.responsiblePartyType || party.contactType,
    };

    return returnState;
  },
  [actions.HANDLE_CHANGE]: (state, { data: { fieldName, value } }) => ({
    ...state,
    [fieldName]: value,
  }),
  [actions.SET_FORM_ERRORS]: (state, { data: { errors } }) => ({
    ...state,
    errors: {
      ...state.errors,
      ...errors,
    },
  }),
  [actions.CLEAR_FORM]: () => {
    return {
      ...initialState,
      resetForm: true,
    };
  },
  [actions.CLEAR_CONTACT_INFO]: state => {
    return {
      ...state,
      resetForm: true,
      selectedContact: null,
      salutation: 'none',
      firstName: '',
      middleName: '',
      lastName: '',
      occupation: '',
      employer: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: 0,
      zipCode: '',
      county: '',
      phone1: '',
      phone2: '',
      email: '',
      committeeFecId: '',
    };
  },
  [actions.RESET_CONTACT_INFO]: state => {
    if (state.selectedContact) {
      return {
        ...state,
        resetForm: true,
        contactId: state.contactId || '',
        salutation: state.selectedContact.salutation || 'none',
        firstName: state.selectedContact.firstName || '',
        middleName: state.selectedContact.middleName || '',
        lastName: state.selectedContact.lastName || '',
        occupation: state.selectedContact.occupation || '',
        employer: state.selectedContact.employer || '',
        addressLine1:
          state.selectedContact.address?.addressLine1 ||
          state.selectedContact.addressLine1 ||
          '',
        addressLine2:
          state.selectedContact.address?.addressLine2 ||
          state.selectedContact.addressLine2 ||
          '',
        city:
          state.selectedContact.address?.city ||
          state.selectedContact.city ||
          '',
        state:
          state.selectedContact.address?.state ||
          state.selectedContact.state ||
          0,
        zipCode:
          state.selectedContact.address?.zipCode ||
          state.selectedContact.zipCode ||
          '',
        county:
          state.selectedContact.address?.county ||
          state.selectedContact.county ||
          '',
        phone1: state.selectedContact.phone1 || '',
        phone2: state.selectedContact.phone2 || '',
        email: state.selectedContact.email || '',
        committeeFecId: state.selectedContact.committeeFecId || '',
      };
    }
    return {
      ...state,
      resetForm: true,
      contactId: '',
      salutation: 'none',
      firstName: '',
      middleName: '',
      lastName: '',
      occupation: '',
      employer: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: 0,
      zipCode: '',
      county: '',
      phone1: '',
      phone2: '',
      email: '',
      committeeFecId: '',
    };
  },
};

export const ResponsiblePartyReducer = createReducer(initialState, actionMap);
