const types = {
  GET_LOANS: 'GET_LOANS',
  GET_LOANS_PROCESSING: 'GET_LOANS_PROCESSING',
  GET_LOANS_SUCCESS: 'GET_LOANS_SUCCESS',
  GET_LOANS_FAILURE: 'GET_LOANS_FAILURE',
  SAVE_LOAN: 'SAVE_LOAN',
  SAVE_FEC_LOAN: 'SAVE_FEC_LOAN',
  SAVE_TBD_LOAN: 'SAVE_TBD_LOAN',
  DELETE_LOAN: 'DELETE_LOAN',
  DELETE_FEC_LOAN: 'DELETE_FEC_LOAN',
  UPDATE_LOAN: 'UPDATE_LOAN',
  UPDATE_FEC_LOAN: 'UPDATE_FEC_LOAN',
  SAVE_LOAN_PROCESSING: 'SAVE_LOAN_PROCESSING',
  SAVE_LOAN_RESET: 'SAVE_LOAN_RESET',
  SAVE_LOAN_FAILURE: 'SAVE_LOAN_FAILURE',
  SAVE_LOAN_SUCCESS: 'SAVE_LOAN_SUCCESS',
};

const actions = {
  getLoans() {
    return { type: types.GET_LOANS };
  },

  save(payload, addNew, reportId) {
    return { type: types.SAVE_LOAN, data: { payload, addNew, reportId } };
  },

  saveFec(payload, addNew, reportId = null) {
    return {
      type: types.SAVE_FEC_LOAN,
      data: {
        payload,
        addNew,
        reportId,
      },
    };
  },

  saveTbd(payload, addNew, reportId) {
    return { type: types.SAVE_TBD_LOAN, data: { payload, addNew, reportId } };
  },

  update(payload, addNew, reportId, redirect, verifyReport = null) {
    return {
      type: types.UPDATE_LOAN,
      data: { payload, addNew, reportId, redirect, verifyReport },
    };
  },

  updateFec(payload, addNew, reportId, redirect, verifyReport = null) {
    return {
      type: types.UPDATE_FEC_LOAN,
      data: { payload, addNew, reportId, redirect, verifyReport },
    };
  },

  resetSave() {
    return { type: types.SAVE_LOAN_RESET };
  },

  delete(id, reportId, redirect) {
    return { type: types.DELETE_LOAN, data: { id, reportId, redirect } };
  },

  deleteFec(id, reportId = null, redirect = null) {
    return { type: types.DELETE_FEC_LOAN, data: { id, reportId, redirect } };
  },
};

export { types, actions };
