import Candidate from './Candidate';
import DashboardMoneyInfo from './DashboardMoneyInfo';

export default class Campaign {
  constructor(dto = {}) {
    this.reportingFrequency = dto.reportingFrequency || '';
    this.accountId = dto.accountId;
    this.affidavitLimit = dto.affidavitLimit;
    this.campaignName = dto.campaignName;
    this.candidate = new Candidate(dto.candidate);
    this.filerId = dto.filerId || '';
    this.description = dto.description;
    this.managedBy = dto.managedBy;
    this.committee = { ...dto.committee };
    this.candidateAddressLinesCombined = dto.candidateAddressLinesCombined;
    this.candidateFullAddress = dto.candidateFullAddress;
    this.candidateOrCommitteeEmailAddress =
      dto.candidateOrCommitteeEmailAddress;
    this.candidateOrCommitteeFullName = dto.candidateOrCommitteeFullName;
    this.candidateOrCommitteeFullAddress = dto.candidateOrCommitteeFullAddress;
    this.candidateOrCommitteeTelephoneNumber =
      dto.candidateOrCommitteeTelephoneNumber;
    this.candidateSortName = dto.candidateSortName;
    this.chairPersonAddressLinesCombined = dto.chairPersonAddressLinesCombined;
    this.chairPersonFullAddress = dto.chairPersonFullAddress;
    this.chairPersonFullName = dto.chairPersonFullName;
    this.committeeAddressLinesCombined = dto.committeeAddressLinesCombined;
    this.committeeFullAddress = dto.committeeFullAddress;
    this.committeeType = dto.committeeType;
    this.dashboardMoneyInfo = new DashboardMoneyInfo(dto.dashboard);
    this.partyAffiliation = dto.partyAffiliation;
    this.anedotEvents = dto.anedotEvents || [];
    this.electionDates = (dto.electionDates || []).reduce((dates, ed) => {
      // filter out duplicates client side until we figure out what's going on server side
      if (dates.find(d => d._id === ed._id) === undefined) {
        dates.push(ed);
      }
      return dates;
    }, []);
    this.endingCashOnHandAmount = dto.endingCashOnHandAmount;
    this.endingInKindContributionsAmount = dto.endingInKindContributionsAmount;
    this.endingInKindExpendituresAmount = dto.endingInKindExpendituresAmount;
    this.endingMonetaryContributionsAmount =
      dto.endingMonetaryContributionsAmount;
    this.endingMonetaryExpenditureAmount = dto.endingMonetaryExpenditureAmount;
    this.id = dto.id;
    this.isIncumbent = dto.isIncumbent;
    this.isNonCandidateCommittee = dto.isNonCandidateCommittee;
    this.isSetupComplete = dto.isSetupComplete;
    this.nextElectionYear = dto.nextElectionYear;
    this.officeType = dto.officeType;
    this.officeState = dto.officeState;
    this.officeCategory = dto.officeCategory;
    this.office = dto.office;
    this.subOffice = dto.subOffice;
    this.officeName = dto.officeName;
    this.partisanOffice = dto.partisanOffice;
    this.doesReportNEDec31 = dto.doesReportNEDec31;
    this.openingCashAmount = dto.openingCashAmount;
    this.openingCashAmountDate = dto.openingCashAmountDate;
    this.referralCode = dto.referralCode;
    this.status = dto.status;
    this.stripe = dto.stripe
      ? {
          cardholderEmail: dto.stripe.cardholderEmail,
          cardholderName: dto.stripe.cardholderName,
          customerId: dto.stripe.customerId,
          lastFour: dto.stripe.lastFour,
          plan: dto.stripe.plan,
          subscriptionId: dto.stripe.subscriptionId,
        }
      : {};
    this.treasurerAddressLinesCombined = dto.treasurerAddressLinesCombined;
    this.treasurerFullAddress = dto.treasurerFullAddress;
    this.treasurerFullName = dto.treasurerFullName;
    this.lastFiledReportEndDate = dto.lastFiledReportEndDate;
    this.canEditTransactionIdNumber = dto.canEditTransactionIdNumber;
  }
}
