export const getFullAddressLine = (obj) => {
  if (!obj) {
    return '';
  }
  const addL2 = obj?.addressLine2 ? ` ${obj.addressLine2}` : ' ';

  if (obj?.city && obj?.state) { // comma works here
    return `${obj?.addressLine1 || ''}${addL2}, ${obj?.city || ''}, ${obj?.state || ''}  ${obj?.zipCode || ''}`;
  }
  // Show what we can, but don't assume commas
  return `${obj.addressLine1 || ''}${addL2} ${obj.city || ''} ${obj.state || ''}  ${obj.zipCode || ''}`;
};
