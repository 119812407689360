import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, GridRow, Column } from '../../common';

export default class Section6 extends Component {
  static propTypes = {
    q6: PropTypes.shape({
      inKind: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }).isRequired,
    q6Sum: PropTypes.shape({
      inKind: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }).isRequired,
  };

  shouldComponentUpdate(nextProps) {
    return (
      nextProps.q6.inKind !== this.props.q6.inKind ||
      nextProps.q6.amount !== this.props.q6.amount ||
      nextProps.q6Sum.inKind !== this.props.q6Sum.inKind ||
      nextProps.q6Sum.amount !== this.props.q6Sum.amount
    );
  }

  render() {
    const { q6, q6Sum } = this.props;
    return (
      <GridRow>
        <Column sm={1} classNames="ms-textAlignRight">
          <h3 className="form-label">6</h3>
        </Column>
        <Column sm={11}>
          <Grid>
            <GridRow>
              <Column sm={8}>
                <p>
                  Total contributions to date. Total to be carried forward to
                  next report of this election cycle*.{' '}
                </p>
                <p>{'(Line 2 + 5)'}</p>
              </Column>
              <Column sm={2} classNames="ms-textAlignCenter money-input">
                <p style={{ margin: '16px 12px 0 0' }}>
                  {q6Sum.inKind !== q6.inKind ? q6Sum.inKind : q6.inKind}
                </p>
              </Column>
              <Column sm={2} classNames="ms-textAlignCenter money-input">
                <p style={{ margin: '16px 12px 0 0' }}>
                  {q6Sum.amount !== q6.amount ? q6Sum.amount : q6.amount}
                </p>
              </Column>
            </GridRow>
          </Grid>
        </Column>
      </GridRow>
    );
  }
}
