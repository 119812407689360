import { types } from '../actions/integrationSettingActions';
import { createReducer } from './createReducer';
import { statuses } from '../helpers/constants';

export const initialState = {
  getIntegrationSettingsStatus: statuses.NOT_STARTED,
  getIntegrationSettingsError: '',
  integrationSettings: [],
  createIntegrationStatus: statuses.NOT_STARTED,
  createIntegrationError: '',
  updateIntegrationStatus: statuses.NOT_STARTED,
  updateIntegrationError: '',
};

export const actionMap = {
  [types.GET_INTEGRATION_SETTINGS_PROCESSING]: (state) => ({
    ...state,
    getIntegrationSettingsStatus: statuses.PROCESSING,
    getIntegrationSettingsError: '',
  }),
  [types.GET_INTEGRATION_SETTINGS_SUCCESS]: (
    state,
    { data: { integrationSettings } },
  ) => ({
    ...state,
    getIntegrationSettingsStatus: statuses.SUCCESS,
    getIntegrationSettingsError: '',
    integrationSettings,
  }),
  [types.GET_INTEGRATION_SETTINGS_FAILURE]: (state, action) => {
    return {
      ...state,
      getIntegrationSettingsStatus: statuses.ERROR,
      getIntegrationSettingsError: action.error,
      integrationSettings: [],
    };
  },
  [types.CREATE_INTEGRATION_SETTING_PROCESSING]: (state) => {
    return {
      ...state,
      createIntegrationStatus: statuses.PROCESSING,
      createIntegrationError: '',
    };
  },
  [types.CREATE_INTEGRATION_SETTING_SUCCESS]: (state) => {
    return {
      ...state,
      createIntegrationStatus: statuses.SUCCESS,
      createIntegrationError: '',
    };
  },
  [types.CREATE_INTEGRATION_SETTING_FAILURE]: (state, action) => {
    return {
      ...state,
      createIntegrationStatus: statuses.ERROR,
      createIntegrationError: action.error,
    };
  },
  [types.UPDATE_INTEGRATION_SETTING_PROCESSING]: (state) => {
    return {
      ...state,
      updateIntegrationStatus: statuses.PROCESSING,
      updateIntegrationError: '',
    };
  },
  [types.UPDATE_INTEGRATION_SETTING_SUCCESS]: (state) => {
    return {
      ...state,
      updateIntegrationStatus: statuses.SUCCESS,
      updateIntegrationError: '',
    };
  },
  [types.UPDATE_INTEGRATION_SETTING_FAILURE]: (state, action) => {
    return {
      ...state,
      updateIntegrationStatus: statuses.ERROR,
      updateIntegrationError: action.error,
    };
  },
};

export const integrationSettingReducer = createReducer(initialState, actionMap);
