import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from '../../actions/reconciliationActions';
import { formatDate, formatCurrency } from '../../helpers/util';
import { BackButton } from '../../components/common';
import './ReconciliationReport.css';

const ReconciliationReport = ({
  match,
  reconciliationActions,
  history,
  currentCampaign,
  reconciliations,
}) => {
  useEffect(() => {
    reconciliationActions.getSingleReconciliation(match.params.id);
  }, [match.params.id]);

  useEffect(() => {
    return () => {
      reconciliationActions.clearSingleReconciliation();
    };
  }, []);

  const {
    singleReconciliation,
    unreconciledActivity: { deposits, expenditures },
  } = reconciliations;

  if (!singleReconciliation) {
    return null;
  }

  return (
    <Fragment>
      <BackButton
        url="/filer/reconciliations"
        history={history}
        message="Back"
      />
      <div className="ReconciliationReport depth-1">
        <label className="print-link" onClick={() => window.print()}>
          Print
        </label>
        <div className="heading">
          <p>{currentCampaign.campaignName}</p>
          <p>{currentCampaign.candidateOrCommitteeFullAddress}</p>
          <h3>{`Reconciliation Report - ${singleReconciliation.name}`}</h3>
        </div>
        {singleReconciliation && (
          <div className="content">
            <div className="reconciled-deposits">
              <table>
                <thead>
                  <tr>
                    <th colSpan={3}>
                      <h4>Reconciled Deposits</h4>
                    </th>
                  </tr>
                  <tr>
                    <th>Date</th>
                    <th>Deposit Name</th>
                    <th className="amount">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {singleReconciliation.deposits
                    .filter(e => Boolean(e.reconciliationId))
                    .map(item => {
                      return (
                        <tr key={item._id}>
                          <td>{formatDate(item.depositDate)}</td>
                          <td>{item.name}</td>
                          <td className="amount">
                            {formatCurrency(item.amount)}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan={3} className="amount">
                      <span>Total:</span>{' '}
                      {formatCurrency(
                        singleReconciliation.deposits.reduce((sum, item) => {
                          if (item.reconciliationId) {
                            sum += item.amount;
                          }
                          return sum;
                        }, 0),
                      )}
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
            <div className="page-break" />
            <div className="reconciled-checks">
              <table>
                <thead>
                  <tr>
                    <th colSpan={5}>
                      <h4>Reconciled Checks</h4>
                    </th>
                  </tr>
                  <tr>
                    <th>Check Number</th>
                    <th>Reference Number</th>
                    <th>Date</th>
                    <th>Memo/Payee</th>
                    <th className="amount">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {singleReconciliation.expenditures
                    .filter(e => Boolean(e.reconciliationId))
                    .map(e => (
                      <tr key={e._id}>
                        <td>{e.checkNumber || '-'}</td>
                        <td>{e.paymentReferenceId || '-'}</td>
                        <td>{formatDate(e.expenditureDate)}</td>
                        <td>{e.displayName}</td>
                        <td className="amount">{formatCurrency(e.amount)}</td>
                      </tr>
                    ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan={5} className="amount">
                      <span>Total:</span>{' '}
                      {formatCurrency(
                        singleReconciliation.expenditures.reduce(
                          (sum, item) => {
                            if (item.reconciliationId) {
                              sum += item.amount;
                            }
                            return sum;
                          },
                          0,
                        ),
                      )}
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
            <div className="outstanding-recs">
              <table>
                <thead>
                  <tr>
                    <th colSpan={6}>
                      <h4>Outstanding Reconciliations</h4>
                    </th>
                  </tr>
                  <tr>
                    <th>Check Number</th>
                    <th>Reference Number</th>
                    <th>Date</th>
                    <th>Name/Memo/Payee</th>
                    <th className="amount">Deposit</th>
                    <th className="amount">Withdrawal</th>
                  </tr>
                </thead>
                <tbody>
                  {[...deposits, ...expenditures].map(item => (
                    <tr key={item._id}>
                      <td>{item.checkNumber || '-'}</td>
                      <td>{item.paymentReferenceId || '-'}</td>
                      <td>
                        {item.depositDate
                          ? formatDate(item.depositDate)
                          : formatDate(item.expenditureDate)}
                      </td>
                      <td>{item.name || item.displayName}</td>
                      <td className="amount">
                        {item.depositDate ? formatCurrency(item.amount) : '-'}
                      </td>
                      <td className="amount">
                        {item.expenditureDate
                          ? formatCurrency(item.amount)
                          : '-'}
                      </td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan={4}>Totals:</td>
                    <td className="amount">
                      {formatCurrency(
                        deposits.reduce((sum, item) => {
                          sum += item.amount;
                          return sum;
                        }, 0),
                      )}
                    </td>
                    <td className="amount">
                      {formatCurrency(
                        expenditures.reduce((sum, item) => {
                          sum += item.amount;
                          return sum;
                        }, 0),
                      )}
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
};

ReconciliationReport.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  currentCampaign: PropTypes.object.isRequired,
  reconciliations: PropTypes.object.isRequired,
  reconciliationActions: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    currentCampaign: state.currentCampaign.campaign,
    reconciliations: state.reconciliations,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    reconciliationActions: bindActionCreators(actions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReconciliationReport);
