import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { GridRow, Column, MoneyField } from '../../common';

const FECSection8 = ({ q8ColA }) => {
  return (
    <Fragment>
      <GridRow style={{ borderTop: '1px solid #919191' }}>
        <Column sm={4}>
          <p>
            8. Cash on Hand at Close of Reporting Period
            <br />
            {'(from Line 27)'}
          </p>
        </Column>
        <Column sm={4} classNames="entry-row">
          <MoneyField
            disabled
            value={q8ColA || 0}
          />
        </Column>
      </GridRow>
    </Fragment>
  );
};

FECSection8.propTypes = {
  q8ColA: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default FECSection8;
