import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from 'office-ui-fabric-react/lib/TextField';

const PhoneNumberField = ({
  label,
  value,
  className,
  style,
  onChange,
  required,
  errorMessage,
}) => {
  // TODO: mask this field with (___) ___ - ____
  // keeping this as normal text field until we can get a fix for the Safari bug

  return (
    <TextField
      type="tel"
      value={value}
      label={label}
      className={className}
      style={style}
      onChange={onChange}
      required={required}
      errorMessage={errorMessage}
    />
  );
};

PhoneNumberField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  errorMessage: PropTypes.string,
};

PhoneNumberField.defaultProps = {
  label: 'Phone Number',
  value: '',
  className: '',
  style: {},
  required: false,
  errorMessage: '',
};

export default PhoneNumberField;
