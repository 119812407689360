import React from 'react';
import PropTypes from 'prop-types';
import { GridRow, Column, MoneyField } from '../../common';
import { getReceiptTotals } from '../../../pages/Reports/fecReportHelper';

const FECSection16 = ({ state }) => (
  <GridRow style={{ borderTop: '1px solid #919191', paddingBottom: 6 }}>
    <Column sm={4}>
      <p>
        16. <strong>TOTAL RECEIPTS</strong> <br />
        {'(add Lines 11(e), 12, 13(c), 14, and 15)'}
        <br />
        {'(Carry Total to Line 24, page 4)'}
      </p>
    </Column>
    <Column sm={4} classNames="entry-row">
      <MoneyField value={getReceiptTotals('A', state) || 0} disabled />
    </Column>
    <Column sm={4} classNames="entry-row">
      <MoneyField value={getReceiptTotals('B', state) || 0} disabled />
    </Column>
  </GridRow>
);

FECSection16.propTypes = {
  state: PropTypes.object.isRequired,
};

export default FECSection16;
