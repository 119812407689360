export const types = {
  GET_BUDGET_CATEGORY: 'GET_BUDGET_CATEGORY',
  SET_BUDGET_CATEGORY: 'SET_BUDGET_CATEGORY',
  SET_BUDGET_CATEGORY_SUCCESS: 'SET_BUDGET_CATEGORY_SUCCESS',
  SET_BUDGET_CATEGORY_FAILURE: 'SET_BUDGET_CATEGORY_FAILURE',
};

export const actions = {
  getBudgetCategory() {
    return { type: types.GET_BUDGET_CATEGORY };
  },

  setBudgetCategory() {
    return { type: types.SET_BUDGET_CATEGORY };
  },
};
