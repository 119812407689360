import React from 'react';
import PropTypes from 'prop-types';
import {
  Separator,
  PrimaryButton,
  Panel,
  PanelType,
} from 'office-ui-fabric-react';
import { Grid, GridRow, Column, DataLabel } from '../common';
import { formatCurrency, formatDate, formatAddress } from '../../helpers/util';
import { getLabel } from '../../helpers/labelHelper';

const ContributionsPanel = ({
  item,
  showPanel,
  closePanel,
  session,
  editItem,
}) => {
  const onEditClick = () => {
    editItem(item._id);
  };

  if (!item) {
    return null;
  }

  const onRenderFooterContent = () => (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
      }}
    >
      <PrimaryButton
        iconProps={{ iconName: 'PencilAlt' }}
        onClick={onEditClick}
        text="Edit"
      />
    </div>
  );

  const onRenderNavigationContent = (headerProps, defaultRenderer) => (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        width: '96%',
      }}
    >
      <h3 style={{ flex: 1 }}>{`${getLabel(
        'Contribution',
        session,
      )} Details`}</h3>
      {defaultRenderer(headerProps)}
    </div>
  );

  return (
    <Panel
      isOpen={showPanel}
      onDismiss={closePanel}
      type={PanelType.smallFixedNear}
      isLightDismiss
      isFooterAtBottom
      onRenderFooterContent={onRenderFooterContent}
      onRenderNavigationContent={onRenderNavigationContent}
    >
      <Grid>
        <GridRow>
          <Column>
            <DataLabel label="Contributor" value={item.displayName} />
            <DataLabel
              label={`${getLabel('Contribution', session)} Type`}
              value={item.contributionType}
            />
            {!!item.contributionCategory && (
              <DataLabel
                label={`${getLabel('Contribution', session)} Category`}
                value={item.contributionCategory}
              />
            )}
            <DataLabel label="Amount" value={formatCurrency(item.amount)} />
            <DataLabel
              label="Date Received"
              value={formatDate(item.receivedDate)}
            />
            {item.paymentType !== '0' && item.paymentType && (
              <DataLabel label="Payment Type" value={item.paymentType} />
            )}
            {item.checkNumber && (
              <DataLabel label="Check Number" value={item.checkNumber} />
            )}
            {item.paymentReferenceId && (
              <DataLabel label="Reference Id" value={item.paymentReferenceId} />
            )}
            {item.sourceCode && (
              <DataLabel label="Source Code" value={item.sourceCode} />
            )}
          </Column>
        </GridRow>
        <GridRow>
          <Column>
            {!!item.creditCardType && !!item.creditCardLast4 && (
              <DataLabel
                label="Credit Card"
                value={`${item.creditCardType} - **${item.creditCardLast4}`}
              />
            )}
            {item.contactName && (
              <DataLabel label="Contact Name" value={item.contactName} />
            )}
            {(item.contributorFullAddress ||
              (item.address && formatAddress(item))) && (
                <DataLabel
                  label="Address"
                  value={`${item.contributorFullAddress || formatAddress(item)}`}
                  valueStyle={{ textAlign: 'right' }}
                />
              )}
            {item.phone1 && <DataLabel label="Phone" value={item.phone1} />}
            {item.email && <DataLabel label="Email" value={item.email} />}
            <Separator />
            {item.electionYear &&
              <>
                <DataLabel label="Election Year" value={item.electionYear} />
                {session.isFederal() ? (
                  <DataLabel
                    label="Receipt For"
                    value={`${item.electionCycle}${item.electionCycleOtherDescription ? ' - ' + item.electionCycleOtherDescription : ''}`}
                  />
                ) : (
                  <DataLabel
                    label="Election"
                    value={`${item.electionCycle}`}
                  />
                  )}
              </>
            }
            {Array.isArray(item.tags) &&
              item.tags?.filter(Boolean).length > 0 && (
                <DataLabel label="Tags" value={item.tags.join(',')} />
              )}
          </Column>
        </GridRow>
        {session.isFederal() && (
          <>
            {item?.memoText?.length > 0 && (
              <GridRow>
                <Column>
                  <DataLabel label="Memo" value={item.memoText} />
                </Column>
              </GridRow>
            )}
            {item?.conduit &&
              (item.conduit?.firstName || item.conduit?.businessName) && (
                <GridRow>
                  <Column>
                    <DataLabel
                      label="Conduit"
                      value={
                        <>
                          <label>
                            {item.conduit.businessName ||
                              `${item.conduit.lastName}, 
                              ${item.conduit.firstName} ${item.conduit.middleName || ''}`}
                          </label>
                        </>
                      }
                    />
                    {item.conduit?.committeeFecId && (
                      <DataLabel
                        label="Conduit FEC ID"
                        value={item.conduit.committeeFecId}
                      />
                    )}
                  </Column>
                </GridRow>
              )}
          </>
        )}
      </Grid>
    </Panel>
  );
};

ContributionsPanel.propTypes = {
  showPanel: PropTypes.bool,
  item: PropTypes.object,
  closePanel: PropTypes.func.isRequired,
  editItem: PropTypes.func.isRequired,
  session: PropTypes.object.isRequired,
};

ContributionsPanel.defaultProps = {
  showPanel: false,
  item: {},
};

export default ContributionsPanel;
