import { createReducer } from '../../../reducers/createReducer';
import { calcContributionByDateRangeFilters } from '../../../helpers/listsHelper';

export const defaultFilters = [
  {
    column: 'transactionType',
    expression: 'equals',
    value: 'contribution',
  },
  {
    column: 'transactionType',
    expression: 'exists',
    value: 'contribution',
  },
];

export const initialState = {
  timePeriod: 0,
  startDate: '',
  endDate: '',
  filters: [...defaultFilters],
  columns: [],
  columnDefinitions: [],
  sourceCodes: [],
  transactionIdNumber: '',
  tags: '',
  errors: {
    startDate: '',
    endDate: '',
  },
};

export const actions = {
  HANDLE_CHANGE: 'HANDLE_CHANGE',
  SET_FORM_ERRORS: 'SET_FORM_ERRORS',
  SET_COLUMN_DEFINITIONS: 'SET_COLUMN_DEFINITIONS',
  HANDLE_NO_EMPLOYEE_CHECK: 'HANDLE_NO_EMPLOYEE_CHECK',
};

const calcFiltersWithDefaultFilters = calcContributionByDateRangeFilters(defaultFilters);

export const actionMap = {
  [actions.HANDLE_CHANGE]: (state, { data: { fieldName, value } }) => {
    if (fieldName === 'timePeriod' && value !== 'custom') {
      return {
        ...state,
        [fieldName]: value,
        startDate: '',
        endDate: '',
        filters: calcFiltersWithDefaultFilters(fieldName, value, state),
      };
    }
    return {
      ...state,
      [fieldName]: value,
      filters: calcFiltersWithDefaultFilters(fieldName, value, state),
    };
  },
  [actions.HANDLE_NO_EMPLOYEE_CHECK]: (state, { data: { fieldName, value } }) => {
    return {
      ...state,
      [fieldName]: value,
      filters: calcFiltersWithDefaultFilters('noEmployeeOrOccupation', value, state),
    };
  },
  [actions.SET_FORM_ERRORS]: (state, { data: { errors } }) => ({
    ...state,
    errors,
  }),
  [actions.SET_COLUMN_DEFINITIONS]: (state, { data: columnDefinitions }) => ({
    ...state,
    columns: (columnDefinitions || []).map(cd => cd.key),
    columnDefinitions,
  }),
};

export const contributionsReducer = createReducer(
  initialState,
  actionMap,
);
