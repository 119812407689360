import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import { ConnectedRouter } from 'connected-react-router';
import { Switch, Route } from 'react-router-dom';
import { Fabric } from 'office-ui-fabric-react/lib/Fabric';
import { loadTheme } from 'office-ui-fabric-react';
import { setIconOptions } from 'office-ui-fabric-react/lib/Styling';
import { Provider } from 'react-redux';
import configureStore, { history } from './store';
import rootReducer from './reducers';
import App from './App';
import initializeIcons from './registerIcons';
import './index.css';

(async function getIntercomId() {
  const { data } = await axios.get('/api/intercom');
  window.Intercom('boot', { ...data });
})();

// Suppress Fabric icon warnings.
setIconOptions({
  disableWarnings: true,
});

initializeIcons();

loadTheme({
  palette: {
    themePrimary: '#244b8d',
    themeLighterAlt: '#f3f6fa',
    themeLighter: '#d1dbed',
    themeLight: '#acbedd',
    themeTertiary: '#6786ba',
    themeSecondary: '#365a9a',
    themeDarkAlt: '#21437e',
    themeDark: '#1c396b',
    themeDarker: '#142a4f',
    neutralLighterAlt: '#f8f8f8',
    neutralLighter: '#f4f4f4',
    neutralLight: '#eaeaea',
    neutralQuaternaryAlt: '#dadada',
    neutralQuaternary: '#d0d0d0',
    neutralTertiaryAlt: '#c8c8c8',
    neutralTertiary: '#bab8b7',
    neutralSecondary: '#666',
    neutralPrimaryAlt: '#8d8b8a',
    neutralPrimary: '#323130',
    neutralDark: '#605e5d',
    black: '#494847',
    white: '#ffffff',
  },
});

const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Fabric>
        <Switch>
          <Route path="/" component={App} />
        </Switch>
      </Fabric>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root'),
);

if (module.hot) {
  module.hot.accept('./reducers', () => {
    store.replaceReducer(rootReducer(history));
  });
}
