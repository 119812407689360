import React from 'react';
import PropTypes from 'prop-types';
import {
  ActionButton,
  DefaultButton,
  IconButton,
} from 'office-ui-fabric-react/lib/Button';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { Link } from 'office-ui-fabric-react/lib/Link';
import { StatePicker } from '../../components/Pickers';
import {
  Grid,
  GridRow,
  Column,
  PhoneNumberField,
  EmailField,
  ZipCodeField,
} from '../../components/common';
import './EditTreasurerDetails.css';

const EditTreasurerDetails = ({
  campaign: { treasurer, designatedAgent },
  formSection,
  treasurerDetailsExpanded,
  actions,
  session,
}) => (
  <div className="EditTreasurerDetails depth-1" name="treasurer-details">
    <h3>
      Treasurer Information{' '}
      <small>
        {' '}
        (
        <Link onClick={actions.toggleExpandTreasurerDetails}>
          {treasurerDetailsExpanded ? 'Collapse' : 'Expand'}
        </Link>
        )
      </small>
    </h3>
    <div
      className={`edit-treasurer-details-content${
        treasurerDetailsExpanded ? ' expanded' : ''
      }`}
    >
      <Grid>
        <GridRow>
          <Column lg={4}>
            <TextField
              label="Treasurer First Name"
              value={treasurer.firstName}
              onChange={actions.handleTextChange('firstName')}
            />
          </Column>
          <Column lg={4}>
            <TextField
              label="Treasurer Middle Name"
              value={treasurer.middleName}
              onChange={actions.handleTextChange('middleName')}
            />
          </Column>
          <Column lg={4}>
            <TextField
              label="Treasurer Last Name"
              value={treasurer.lastName}
              onChange={actions.handleTextChange('lastName')}
            />
          </Column>
        </GridRow>
        <GridRow>
          <Column lg={6}>
            <TextField
              label="Treasurer Mailing Address 1"
              value={treasurer.addressLine1}
              onChange={actions.handleTextChange('addressLine1')}
            />
          </Column>
          <Column lg={6}>
            <TextField
              label="Treasurer Mailing Address 2"
              value={treasurer.addressLine2}
              onChange={actions.handleTextChange('addressLine2')}
            />
          </Column>
        </GridRow>
        <GridRow>
          <Column lg={4}>
            <TextField
              label="Treasurer City"
              value={treasurer.city}
              onChange={actions.handleTextChange('city')}
            />
          </Column>
          <Column lg={4}>
            <StatePicker
              fieldLabel="Treasurer State"
              selectedKey={treasurer.state}
              onChange={actions.handleStateChange}
              session={session}
            />
          </Column>
          <Column lg={4}>
            <ZipCodeField
              label="Treasurer Zip Code"
              value={treasurer.zipCode}
              onChange={actions.handleTextChange('zipCode')}
            />
          </Column>
        </GridRow>
        <GridRow>
          <Column lg={session.isFederal() ? 4 : 6}>
            <PhoneNumberField
              label="Treasurer Telephone Number"
              value={treasurer.telephoneNumber}
              onChange={actions.handleTextChange('telephoneNumber')}
            />
          </Column>
          <Column lg={session.isFederal() ? 4 : 6}>
            <EmailField
              label="Treasurer Email"
              value={treasurer.emailAddress}
              onChange={actions.handleTextChange('emailAddress')}
              setFormInvalid={actions.setFormInvalid}
              formSection={formSection}
            />
          </Column>
          {session.isFederal() && (
            <Column lg={4}>
              <TextField
                label="Treasurer Title or Position"
                value={treasurer.titleOrPosition}
                onChange={actions.handleTextChange('titleOrPosition')}
              />
            </Column>
          )}
        </GridRow>
        {session.isFederal() && designatedAgent === null && (
          <GridRow>
            <Column>
              <ActionButton
                iconProps={{
                  iconName: 'PlusCircle',
                }}
                onClick={actions.addDesignatedAgent}
              >
                Add Designated Agent/Assistant Treasurer
              </ActionButton>
            </Column>
          </GridRow>
        )}
        {session.isFederal() &&
          designatedAgent !== null &&
          Object.keys(designatedAgent).length > 0 && (
            <GridRow>
              <Column md={6}>
                <h4>
                  Designated Agent/Assistant Treasurer
                  <IconButton
                    iconProps={{ iconName: 'PencilAlt' }}
                    styles={{ root: { color: 'rgb(0, 120, 212)' } }}
                    onClick={actions.addDesignatedAgent}
                  />
                </h4>
                <p>{`${designatedAgent.firstName} ${
                  designatedAgent.middleName
                    ? designatedAgent.middleName + ' '
                    : ''
                }${designatedAgent.lastName}${
                  designatedAgent.titleOrPosition
                    ? ', ' + designatedAgent.titleOrPosition
                    : ''
                }`}</p>
                <address>
                  {Boolean(designatedAgent.addressLine1) &&
                    designatedAgent.addressLine1.length > 0 && (
                      <p>{`${designatedAgent.addressLine1}`}</p>
                    )}
                  {Boolean(designatedAgent.addressLine2) &&
                    designatedAgent.addressLine2.length > 0 && (
                      <p>{`${designatedAgent.addressLine2}`}</p>
                    )}
                  <p>{`${
                    designatedAgent.city ? designatedAgent.city + ', ' : ''
                  }${
                    designatedAgent.state ? designatedAgent.state + ' ' : ''
                  }${designatedAgent.zipCode || ''}`}</p>
                </address>
                {Boolean(designatedAgent.telephoneNumber) &&
                  designatedAgent.telephoneNumber.length > 0 && (
                    <p>{designatedAgent.telephoneNumber}</p>
                  )}
                {Boolean(designatedAgent.emailAddress) &&
                  designatedAgent.emailAddress.length > 0 && (
                    <p>{designatedAgent.emailAddress}</p>
                  )}
                <ActionButton
                  text="Remove Designated Agent/Assistant Treasurer"
                  onClick={actions.removeDesignatedAgent}
                  iconProps={{
                    iconName: 'MinusCircle',
                    styles: {
                      root: {
                        color: '#a80000',
                      },
                    },
                  }}
                />
              </Column>
            </GridRow>
          )}
      </Grid>
    </div>
    {treasurerDetailsExpanded && (
      <div className="ms-textAlignRight">
        <DefaultButton
          style={{ marginRight: 8 }}
          text="Back"
          onClick={() => actions.previousStep(3)}
        />
        <DefaultButton text="Continue" onClick={() => actions.nextStep(5)} />
      </div>
    )}
  </div>
);

EditTreasurerDetails.propTypes = {
  campaign: PropTypes.object,
  treasurerDetailsExpanded: PropTypes.bool,
  actions: PropTypes.object.isRequired,
  formSection: PropTypes.number,
  session: PropTypes.object.isRequired,
};

EditTreasurerDetails.defaultProps = {
  campaign: {},
  treasurerDetailsExpanded: false,
  formSection: null,
};

export default EditTreasurerDetails;
