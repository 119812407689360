import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { SalutationPicker } from '../SalutationPicker';
import { StatePicker } from '..';
import { FederalOfficePicker } from '../FederalOfficePicker';
import { GridRow, Column } from '../../common';

const CandidateEntity = ({
  candidateFecId,
  salutation,
  firstName,
  middleName,
  lastName,
  suffix,
  occupation,
  employer,
  onChange,
  candidateOffice,
  candidateState,
  candidateDistrict,
}) => (
  <Fragment>
    {salutation.show && (
      <GridRow>
        <Column md={4}>
          <SalutationPicker
            selectedKey={salutation.value}
            label={salutation.label}
            onChange={onChange(salutation.field)}
            errorMessage={salutation.errorMessage}
            required={salutation.required}
          />
        </Column>
      </GridRow>
    )}
    <GridRow>
      <Column md={suffix.show ? 3 : 4}>
        <TextField
          value={firstName.value}
          label={firstName.label}
          onChange={onChange(firstName.field)}
          required={firstName.required}
          errorMessage={firstName.errorMessage}
        />
      </Column>
      <Column md={suffix.show ? 3 : 4}>
        <TextField
          value={middleName.value}
          label={middleName.label}
          onChange={onChange(middleName.field)}
          required={middleName.required}
          errorMessage={middleName.errorMessage}
        />
      </Column>
      <Column md={suffix.show ? 3 : 4}>
        <TextField
          value={lastName.value}
          label={lastName.label}
          onChange={onChange(lastName.field)}
          required={lastName.required}
          errorMessage={lastName.errorMessage}
        />
      </Column>
      {suffix.show && (
        <Column md={3}>
          <TextField
            value={suffix.value}
            label={suffix.label}
            onChange={onChange(suffix.field)}
            required={suffix.required}
            errorMessage={suffix.errorMessage}
          />
        </Column>
      )}
    </GridRow>
    <GridRow>
      <Column md={4}>
        <TextField
          label={employer.label}
          value={employer.value}
          onChange={onChange(employer.field)}
          errorMessage={employer.errorMessage}
          required={employer.required}
        />
      </Column>
      <Column md={4}>
        <TextField
          label={occupation.label}
          value={occupation.value}
          onChange={onChange(occupation.field)}
          errorMessage={employer.errorMessage}
          required={employer.required}
        />
      </Column>
      <Column md={4}>
        <TextField
          label={candidateFecId.label}
          value={candidateFecId.value}
          onChange={onChange(candidateFecId.field)}
          errorMessage={candidateFecId.errorMessage}
          required={candidateFecId.required}
        />
      </Column>
    </GridRow>
    {candidateOffice.show && // if office is needed, so are the others
    <GridRow>
      <Column md={4}>
        <FederalOfficePicker
          selectedKey={candidateOffice.value}
          label={candidateOffice.label}
          onChange={onChange(candidateOffice.field)}
          errorMessage={candidateOffice.errorMessage}
          required={candidateOffice.required}
        />
      </Column>
      <Column md={4}>
        <StatePicker
          selectedKey={candidateState.value}
          fieldLabel={candidateState.label}
          onChange={onChange(candidateState.field)}
          errorMessage={candidateState.errorMessage}
          required={candidateState.required}
        />
      </Column>
      <Column md={4}>
        <TextField
          label={candidateDistrict.label}
          value={candidateDistrict.value}
          onChange={onChange(candidateDistrict.field)}
          errorMessage={candidateDistrict.errorMessage}
          required={candidateDistrict.required}
        />
      </Column>
    </GridRow>
    }
  </Fragment>
);

CandidateEntity.propTypes = {
  candidateFecId: PropTypes.object.isRequired,
  salutation: PropTypes.object.isRequired,
  firstName: PropTypes.object.isRequired,
  middleName: PropTypes.object.isRequired,
  lastName: PropTypes.object.isRequired,
  suffix: PropTypes.object.isRequired,
  employer: PropTypes.object.isRequired,
  occupation: PropTypes.object.isRequired,
  candidateOffice: PropTypes.object.isRequired,
  candidateState: PropTypes.object.isRequired,
  candidateDistrict: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CandidateEntity;
