import { initialState } from './expendituresReducer';

export const validate = state => {
  const errors = {
    ...initialState.errors,
  };
  if (
    state.startDate &&
    state.endDate &&
    (state.startDate > state.endDate || state.endDate < state.startDate) &&
    state.timePeriod === 'custom'
  ) {
    errors.startDate = 'Start date cannot be after end date';
    errors.endDate = 'End date cannot be before start date';
  }

  return errors;
};
