import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { Image } from 'office-ui-fabric-react/lib/Image';
import { injectStripe } from 'react-stripe-elements';
import { ProgressIndicator } from 'office-ui-fabric-react/lib/ProgressIndicator';
import { userActions } from '../../actions/userActions';
import { actions as createCampaignActions } from '../../actions/createCampaignActions';
import { actions as paymentActions } from '../../actions/paymentActions';
import {
  statuses,
  committeeTypes,
  officeTypes,
  fecCommitteeTypes,
  signupOfficeTypes,
  fecStateMapByAbbreviation,
} from '../../helpers/constants';
import { isFederalPac, isFederalJfc } from '../../helpers/committeeHelper';
import FRLogo from '../../assets/img/HRFrontRunner.png';
import { filterEmpties } from '../../helpers/util';
import { getQueryParams } from '../../helpers/urlHelper';
import {
  offices,
  getOfficeTypeName,
  getOfficeName,
  getOfficeCategoryName,
  getOfficeForCreate,
  getSubOfficeForCreate,
  getSubOfficeName,
} from '../../helpers/offices';
import OfficeDetails from './OfficeDetails';
import NonCandidateCommitteeDetails from './NonCandidateCommitteeDetails';
import PaymentStep from './PaymentStep';
import { Grid, GridRow, Column } from '../../components/common';
import { validateOfficeDetails } from './officeDetailValidations';
import { validateNonCandidateCommittee } from './nonCandidateCommitteeValidations';
import { validateCCInfo } from './CCFormValidations';
import Summary from './Summary';
import './CreateCampaign.css';

export class CreateCampaign extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    stripe: PropTypes.object.isRequired,
    createCampaign: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    payment: PropTypes.object.isRequired,
    userActions: PropTypes.object.isRequired,
    paymentActions: PropTypes.object.isRequired,
    createCampaignActions: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
  };

  params = getQueryParams(this.props.location.search);

  constructor(props, ...args) {
    super(props, ...args);
    this.state = {
      nonCandidateType: null,
      nonCandidateTypeError: '',
      office: {
        candidateFirstName: '',
        candidateFirstNameError: '',
        candidateMiddleName: '',
        candidateLastName: '',
        candidateLastNameError: '',
        candidateEmailAddress: '',
        candidateEmailAddressError: '',
        officeType: 0,
        officeTypeError: '',
        officeState: 0,
        officeStateError: '',
        officeCategory: 0,
        officeCategoryError: '',
        office: 0,
        officeError: '',
        suboffice: 0,
        subofficeError: '',
        nextElectionYear: 0,
        nextElectionYearError: '',
        formValid: false,
        reportingFrequency: '',
        reportingFrequencyError: '',
      },
      committee: {
        type: 0,
        typeError: '',
        // electionDate: { key: 0, text: 'Select' },
        // electionDateError: '',
        registrationDate: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate(),
        ),
        registrationDateError: '',
        // electionDates: [{ key: 0, text: 'Select' }],
        name: '',
        nameError: '',
        state: 0,
        stateError: '',
        // countyOrMunicipality: '',
        // countyOrMunicipalityError: '',
        formValid: false,
        federalPac: {
          individualLastName: '',
          individualFirstName: '',
          individualMiddleName: '',
          individualPrefix: '',
          individualSuffix: '',
          individualOccupation: '',
          individualEmployer: '',
          organizationType: '',
          // Used for both committee types separate segregated fund 'E' or if committee type is not separate ('F')
          isLobbyistRegistrantPac: false,
          // Option if committee type is 'F'
          isLeadershipPac: false,
        },
      },
      ccInfo: {
        cardholderEmail: '',
        cardholderEmailError: '',
        cardholderName: '',
        cardholderNameError: '',
        zipCode: '',
        zipCodeError: '',
        formValid: true,
        coupon: '',
      },
      stripeToken: '',
      billingZipCode: '',
      last4: '',
      stripeError: '',
      status: statuses.NOT_STARTED,
      couponError: '',
      couponAmountOff: 0,
    };
    props.userActions.getPromoCode();
    props.userActions.checkIsNonCandidateCommittee();
    props.userActions.getCurrentUser();
  }

  componentDidMount() {
    this.checkRedirects();
  }

  componentDidUpdate() {
    this.checkRedirects();
  }

  checkRedirects = () => {
    const { history, user } = this.props;
    if (user.session && user.session.isSysAdminRole()) {
      history.push('/filer');
    } else if (user.isAuthenticated === true && user.session.accountId) {
      history.push('/filer');
    }
  };

  renderSectionLabel = (isNonCandidateCommittee, stepIndex) => {
    let text = '';
    const stepMap = {
      0: isNonCandidateCommittee
        ? 'Committee Information'
        : 'Office Information',
      1: 'Credit Card Information',
      2: 'Summary',
    };
    text = stepMap[stepIndex];
    return text;
  };

  renderInitialStep = () => {
    const { office, committee } = this.state;
    const { user, payment } = this.props;

    if (user.isNonCandidateCommittee) {
      const committeeActions = {
        handleCommitteeTextChange: this.handleCommitteeTextChange,
        handleCommitteeDateChange: this.handleCommitteeDateChange,
        handleNonCandidateTypeChange: this.handleNonCandidateTypeChange,
        handleNextStep: this.handleNextStep,
        handleCommitteeFederalPacCheckboxOption: this.handleCommitteeFederalPacCheckboxOption,
        handleCommitteeFederalPacOption: this.handleCommitteeFederalPacOption,
      };

      return (
        <NonCandidateCommitteeDetails
          committee={committee}
          offices={offices}
          officeType={this.state.nonCandidateType}
          officeTypeError={this.state.nonCandidateTypeError}
          showFederalPacOptions={this.params.showFederalPacOptions === 'true'}
          actions={committeeActions}
          stripeError={payment.stripeError}
          status={payment.stripeStatus}
        />
      );
    }

    const officeActions = {
      handleOfficeTextChange: this.handleOfficeTextChange,
      handleOfficeTypeChange: this.handleOfficeTypeChange,
      handleOfficeStateChange: this.handleOfficeStateChange,
      handleOfficeCategoryChange: this.handleOfficeCategoryChange,
      handleOfficeChange: this.handleOfficeChange,
      handleStateSubOfficeChange: this.handleStateSubOfficeChange,
      handleLocalSubOfficeChange: this.handleTextChange,
      handleNextElectionYearChange: this.handleNextElectionYearChange,
      handleNextStep: this.handleNextStep,
      handleChangeReportingFrequency: this.handleChangeReportingFrequency,
    };

    return (
      <OfficeDetails
        isNonCandidateCommittee={user.isNonCandidateCommittee}
        office={office}
        actions={officeActions}
        offices={offices}
        stripeError={payment.stripeError}
        status={payment.stripeStatus}
      />
    );
  };

  validateCoupon = async () => {
    try {
      if (this.state.ccInfo.coupon) {
        const {
          data,
        } = await axios.get(
          `/payment/coupon/validate/${this.state.ccInfo.coupon}`,
          { withCredentials: true },
        );
        this.setState({
          couponError: '',
          couponAmountOff: data.amount_off
            ? data.amount_off
            : data.percent_off
            ? `${data.percent_off}%`
            : 0,
        });
      }
    } catch (e) {
      this.setState({
        couponError: 'Invalid coupon',
        couponAmountOff: 0,
      });
    }
  };

  renderCreditCardForm = () => {
    const {
      state: { ccInfo, couponError, couponAmountOff },
      props: {
        payment: {
          planPrice,
          planDescription,
          planPriceStatus,
          stripeError,
          stripeStatus,
        },
      },
    } = this;

    const ccFormActions = {
      handleTextChange: this.handleCCTextChange,
      handleNextStep: this.handleNextStep,
      handlePreviousStep: this.handlePreviousStep,
    };

    return (
      <PaymentStep
        ccInfo={ccInfo}
        planPrice={planPrice}
        productName={planDescription}
        getPlanPriceStatus={planPriceStatus}
        stripeError={stripeError}
        status={stripeStatus}
        actions={ccFormActions}
        showCoupon
        onBlurCoupon={this.validateCoupon}
        couponError={couponError}
        couponAmountOff={couponAmountOff}
      />
    );
  };

  renderSummary = () => {
    const {
      props: {
        user: {
          isNonCandidateCommittee,
          registeredPromoCode,
          promoCodeDescription,
        },
        createCampaign: { submitStatus },
        payment: { planPrice, planDescription },
      },
    } = this;

    const {
      committee,
      office,
      ccInfo: { coupon },
    } = this.state;

    const summaryActions = {
      handleNextStep: this.createCampaign,
      handlePreviousStep: this.handlePreviousStep,
    };

    return (
      <Summary
        isNonCandidateCommittee={isNonCandidateCommittee}
        committee={committee}
        committeeTypes={committeeTypes}
        officeType={getOfficeTypeName(office.officeType)}
        officeState={office.officeState}
        officeCategory={getOfficeCategoryName(
          office.officeType,
          office.officeState,
          office.officeCategory,
          office.isNonCandidateCommittee,
        )}
        office={getOfficeName(
          office.officeType,
          office.officeState,
          office.officeCategory,
          office.office,
          office.isNonCandidateCommittee,
        )}
        suboffice={getSubOfficeName(office)}
        nextElectionYear={office.nextElectionYear}
        candidateFirstName={office.candidateFirstName || ''}
        candidateMiddleName={office.candidateMiddleName || ''}
        candidateLastName={office.candidateLastName || ''}
        candidateEmailAddress={office.candidateEmailAddress || ''}
        coupon={coupon}
        planPrice={planPrice}
        productName={planDescription}
        promoDescription={promoCodeDescription}
        promoCode={registeredPromoCode}
        actions={summaryActions}
        status={submitStatus}
      />
    );
  };

  logout = () => {
    this.props.userActions.logout();
  };

  handleCommitteeTextChange = fieldName => (e, value) => {
    this.setState(state => ({
      committee: {
        ...state.committee,
        [fieldName]: value.key !== undefined ? value.key : value,
      },
    }));
  };

  handleCommitteeFederalPacOption = fieldName => (e, value) => {
    this.setState(state => ({
      committee: {
        ...state.committee,
        federalPac: {
          ...state.committee.federalPac,
          [fieldName]: value.key !== undefined ? value.key : value,
        },
      },
    }));
  };

  handleCommitteeDateChange = registrationDate => {
    this.setState(state => ({
      committee: {
        ...state.committee,
        registrationDate,
      },
    }));
  };

  handleOfficeTextChange = fieldName => (e, value) => {
    this.setState(state => ({
      office: {
        ...state.office,
        [fieldName]: value.key !== undefined ? value.key : value,
      },
    }));
  };

  handleOfficeTypeChange = (e, option) => {
    const value = option.key !== undefined ? option.key : option;
    const typeChanged = this.state.office.officeType !== value;
    this.setState(state => ({
      office: {
        ...state.office,
        officeType: value,
        officeState: typeChanged ? 0 : state.office.officeState,
        officeCategory: typeChanged ? 0 : state.office.officeCategory,
        office: typeChanged ? 0 : state.office.office,
        suboffice: typeChanged
          ? value === signupOfficeTypes.STATE
            ? 0
            : ''
          : state.office.suboffice,
      },
    }));
  };

  handleNonCandidateTypeChange = (e, option) => {
    const value = option.key !== undefined ? option.key : option;
    this.setState({ nonCandidateType: value });
  };

  handleOfficeStateChange = (e, option) => {
    const value = option.key !== undefined ? option.key : option;
    const stateChanged = this.state.office.officeState !== value;
    this.setState(state => ({
      office: {
        ...state.office,
        officeState: value,
        officeCategory: stateChanged ? 0 : state.office.officeCategory,
        office: stateChanged ? 0 : state.office.office,
        suboffice: stateChanged
          ? state.office.officeType === signupOfficeTypes.STATE
            ? 0
            : ''
          : state.office.suboffice,
      },
    }));
  };

  handleOfficeCategoryChange = (e, option) => {
    const value = option.key !== undefined ? option.key : option;
    const categoryChanged = this.state.office.officeCategory !== value;
    this.setState(state => ({
      office: {
        ...state.office,
        officeCategory: value,
        office: categoryChanged ? 0 : state.office.office,
        suboffice: categoryChanged
          ? state.office.officeType === signupOfficeTypes.STATE
            ? 0
            : ''
          : state.office.suboffice,
      },
    }));
  };

  handleOfficeChange = (e, option) => {
    const value = option.key !== undefined ? option.key : option;
    const officeChanged = this.state.office.office !== value;
    this.setState(state => ({
      office: {
        ...state.office,
        office: value,
        suboffice: officeChanged
          ? state.office.officeType === signupOfficeTypes.STATE
            ? 0
            : ''
          : state.office.suboffice,
      },
    }));
  };

  handleStateSubOfficeChange = (e, option) => {
    this.setState(state => ({
      office: {
        ...state.office,
        suboffice: option.key !== undefined ? option.key : option,
      },
    }));
  };

  handleNextElectionYearChange = (e, option) => {
    this.setState(state => ({
      office: {
        ...state.office,
        nextElectionYear: option.key !== undefined ? option.key : option,
      },
    }));
  };

  handleChangeReportingFrequency = (e, option) => {
    this.setState(state => ({
      office: {
        ...state.office,
        reportingFrequency: option.key !== undefined ? option.key : option,
      },
    }));
  };

  handleCCTextChange = fieldName => (e, value) => {
    this.setState(state => ({
      ccInfo: {
        ...state.ccInfo,
        [fieldName]: value.key !== undefined ? value.key : value,
      },
    }));
  };

  handleCommitteeFederalPacCheckboxOption = (e, value, fieldName) => {
    this.setState(state => ({
      committee: {
        ...state.committee,
        federalPac: {
          ...state.committee.federalPac,
          [fieldName]: value,
        },
      },
    }));
  };

  handleNextStep = () => {
    const stepMapNonCandidateCommittee = {
      0: () => {
        this.setState((state, props) => {
          state = validateNonCandidateCommittee(state);
          if (state.committee.formValid) {
            if (!props.user.registeredPromoCode) {
              const planPayload = {
                isNonCandidateCommittee:
                  props.user.isNonCandidateCommittee || false,
                officeState: state.committee.state,
              };
              props.paymentActions.getPlanPrice(planPayload);
              props.createCampaignActions.nextStep();
            } else {
              props.createCampaignActions.nextStep(
                props.user.registeredPromoCode,
              );
            }
          }
          return state;
        });
      },
      1: () => {
        this.setState(state => {
          state = validateCCInfo(state);
          if (state.ccInfo.formValid) {
            this.props.paymentActions.getStripeToken({
              cardholderName: state.ccInfo.cardholderName,
              zipCode: state.ccInfo.zipCode,
              stripe: this.props.stripe,
            });
          }
          return state;
        });
      },
    };

    const stepMapNormal = {
      0: () => {
        this.setState((state, props) => {
          state = validateOfficeDetails(state, props);
          if (state.office.formValid) {
            if (!props.user.registeredPromoCode) {
              const planPayload = {
                officeState:
                  fecStateMapByAbbreviation[state.office.officeState],
                officeType: getOfficeTypeName(state.office.officeType),
                office: getOfficeName(
                  state.office.officeType,
                  state.office.officeState,
                  state.office.officeCategory,
                  state.office.office,
                  state.office.isNonCandidateCommittee,
                ),
                isNonCandidateCommittee: false,
              };

              props.paymentActions.getPlanPrice(planPayload);
              props.createCampaignActions.nextStep();
            } else {
              props.createCampaignActions.nextStep(
                props.user.registeredPromoCode,
              );
            }
          }
          return state;
        });
      },
      1: () => {
        this.setState(state => {
          state = validateCCInfo(state);
          if (state.ccInfo.formValid) {
            this.props.paymentActions.getStripeToken({
              cardholderName: state.ccInfo.cardholderName,
              zipCode: state.ccInfo.zipCode,
              stripe: this.props.stripe,
            });
          }
          return state;
        });
      },
    };

    const stepMap = this.props.user.isNonCandidateCommittee
      ? stepMapNonCandidateCommittee
      : stepMapNormal;
    if (stepMap[this.props.createCampaign.stepIndex]) {
      stepMap[this.props.createCampaign.stepIndex]();
    }
  };

  handlePreviousStep = () => {
    this.props.createCampaignActions.previousStep(
      this.props.user.registeredPromoCode,
    );
  };

  createCampaign = e => {
    e.preventDefault();
    const {
      props: { payment, user },
      state: { ccInfo, office, committee },
    } = this;

    const payload = {
      payment: {
        planId: payment.planId,
        cardholderName: ccInfo.cardholderName,
        cardholderEmail: ccInfo.cardholderEmail,
        billingZipCode: payment.billingZipCode,
        stripeToken: payment.stripeToken,
        lastFour: payment.last4,
        coupon: ccInfo.coupon,
      },
    };

    if (!user.isNonCandidateCommittee) {
      payload.office = {
        officeType: getOfficeTypeName(office.officeType),
        officeState: fecStateMapByAbbreviation[office.officeState],
        officeCategory: getOfficeCategoryName(
          office.officeType,
          office.officeState,
          office.officeCategory,
          office.isNonCandidateCommittee,
        ),
        office: getOfficeForCreate(
          office.officeType,
          office.officeState,
          office.officeCategory,
          office.office,
        ),
        subOffice:
          getSubOfficeForCreate(
            office.officeType,
            office.officeState,
            office.officeCategory,
            office.office,
            office.suboffice,
          ) || null,
      };

      const officeType = getOfficeTypeName(office.officeType);
      const committee = officeType === officeTypes.FEDERAL ? { type: fecCommitteeTypes.CandidatePrincipal } : {};

      payload.campaign = {
        committee,
        nextElectionYear: office.nextElectionYear,
        officeType,
        promoCode: user.registeredPromoCode,
        reportingFrequency: office.reportingFrequency || undefined,
        candidate: {
          firstName: office.candidateFirstName,
          middleName: office.candidateMiddleName,
          lastName: office.candidateLastName,
          emailAddress: office.candidateEmailAddress,
        },
      };

      this.props.createCampaignActions.createCampaign(filterEmpties(payload));
    } else {
      if (isFederalPac(committee) || isFederalJfc(committee)) {
        payload.isNonCandidateCommittee = true;
        payload.campaign = {
          promoCode: user.registeredPromoCode,
        };
        payload.committee = {
          name: committee.name,
          type: committee.type,
          registrationDate: moment(committee.registrationDate).format(),
          federalPac: { ...committee.federalPac },
        };
        return this.props.createCampaignActions.createFederalPacCampaign(
          filterEmpties(payload),
        );
      }

      payload.isNonCandidateCommittee = true;

      payload.campaign = {
        promoCode: user.registeredPromoCode,
      };

      payload.committee = {
        accountType: 'State',
        name: committee.name,
        type: committee.type,
        state: committee.state,
        registrationDate: committee.registrationDate,
      };

      this.props.createCampaignActions.createCommitteeCampaign(
        filterEmpties(payload),
      );
    }
  };

  render() {
    const {
      props: {
        createCampaign: { stepIndex },
        user,
      },
    } = this;

    return (
      <Fragment>
        <DefaultButton
          onClick={this.logout}
          text="Logout"
          className="logout-btn"
        />
        <div className="CreateCampaign depth-1">
          <Grid>
            <GridRow>
              <Column>
                <Image src={FRLogo} alt="FrontRunner" height={75} />
              </Column>
            </GridRow>
            <GridRow>
              <Column>
                <ProgressIndicator
                  label={this.renderSectionLabel(
                    user.isNonCandidateCommittee,
                    stepIndex,
                  )}
                  percentComplete={(stepIndex + 1) / 3}
                />
              </Column>
            </GridRow>
          </Grid>
          <div className="content">
            {stepIndex === 0 && this.renderInitialStep()}
            {stepIndex === 1 && this.renderCreditCardForm()}
            {stepIndex === 2 && this.renderSummary()}
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    createCampaign: state.createCampaign,
    user: state.user,
    payment: state.payment,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    userActions: bindActionCreators(userActions, dispatch),
    paymentActions: bindActionCreators(paymentActions, dispatch),
    createCampaignActions: bindActionCreators(createCampaignActions, dispatch),
  };
};

export default injectStripe(
  withRouter(
    connect(mapStateToProps, mapDispatchToProps, null, { pure: false })(
      CreateCampaign,
    ),
  ),
);
