import { takeEvery, takeLatest, put, call, debounce } from 'redux-saga/effects';
import axios from 'axios';
import { types as contactTypes } from '../actions/contactActions';
import { types as messagingTypes } from '../actions/messagingActions';
import { getServerSideErrorMessage, errorToast } from '../helpers/util';
import { toastTypes } from '../helpers/constants';

const url = '/api/filer/contacts';

export function* handleGetAllContacts() {
  try {
    yield put({ type: contactTypes.CONTACT_ACTION_PROCESSING });
    const { data: contacts } = yield call(axios.get, url, {
      withCredentials: true,
    });
    yield put({ type: contactTypes.GET_ALL_CONTACTS_SUCCESS, contacts });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put(errorToast(error));
  }
}

export function* getAllContacts() {
  yield debounce(
    1000,
    contactTypes.GET_ALL_CONTACTS,
    handleGetAllContacts,
  );
}

export function* handleGetContactsNextPage(action) {
  const { params = '' } = action.data;
  try {
    yield put({
      type: contactTypes.GET_CONTACTS_NEXT_PAGE_PROCESSING,
    });
    const { data: contacts } = yield call(
      axios.get,
      `/api/filer/contacts${params}`,
      {
        withCredentials: true,
      },
    );
    yield put({
      type: contactTypes.GET_CONTACTS_NEXT_PAGE_SUCCESS,
      contacts,
    });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put(errorToast(error));
  }
}

export function* getContactsNextPage() {
  yield takeEvery(
    contactTypes.GET_CONTACTS_NEXT_PAGE,
    handleGetContactsNextPage,
  );
}

export function* handleGetContacts(action) {
  const { params = '' } = action.data;
  try {
    yield put({
      type: contactTypes.GET_CONTACTS_PROCESSING,
    });
    const { data: contacts } = yield call(
      axios.get,
      `/api/filer/contacts${params}`,
      {
        withCredentials: true,
      },
    );
    yield put({
      type: contactTypes.GET_CONTACTS_SUCCESS,
      contacts,
    });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put(errorToast(error));
  }
}

export function* getContacts() {
  yield debounce(
    1000,
    contactTypes.GET_CONTACTS,
    handleGetContacts,
  );
}

export function* handleDeleteContact(action) {
  try {
    const { id } = action;
    yield call(axios.delete, `${url}/${id}`, { withCredentials: true });
    yield put({ type: contactTypes.DELETE_CONTACT_SUCCESS, _id: id });
    yield put({
      type: messagingTypes.SET_TOAST,
      data: {
        message: 'Contact deleted successfully',
        toastType: toastTypes.SUCCESS,
      },
    });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put(errorToast(error));
  }
}

export function* deleteContact() {
  yield takeLatest(contactTypes.DELETE_CONTACT, handleDeleteContact);
}

export function* handleSaveContact(action) {
  try {
    const { contact } = action.data;
    const { data: addedContact } = yield call(axios.post, url, contact, {
      withCredentials: true,
    });
    yield put({
      type: contactTypes.ADD_CONTACT_SUCCESS,
      contact: addedContact,
    });
    yield put({
      type: messagingTypes.SET_TOAST,
      data: {
        message: 'Contact added successfully!',
        toastType: toastTypes.SUCCESS,
      },
    });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put(errorToast(error));
  }
}

export function* saveContact() {
  yield takeEvery(contactTypes.ADD_CONTACT, handleSaveContact);
}

export function* handleUpdateContact(action) {
  try {
    const { _id, contact } = action.data;
    const { data: updatedContact } = yield call(
      axios.put,
      `${url}/${_id}`,
      contact,
      { withCredentials: true },
    );
    yield put({
      type: contactTypes.UPDATE_CONTACT_SUCCESS,
      data: { contact: updatedContact, _id },
    });
    yield put({
      type: messagingTypes.SET_TOAST,
      data: {
        message: 'Contact updated successfully!',
        toastType: toastTypes.SUCCESS,
      },
    });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put(errorToast(error));
  }
}

export function* updateContact() {
  yield takeEvery(contactTypes.UPDATE_CONTACT, handleUpdateContact);
}

export function* handleMergeContacts(action) {
  try {
    const { targetContactId, contactIds, params } = action.data;
    yield call(axios.post, `${url}/${targetContactId}/merge`, contactIds, {
      withCredentials: true,
    });
    yield put({
      type: contactTypes.GET_CONTACTS,
      data: { params },
    });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put(errorToast(error));
  }
}

export function* mergeContacts() {
  yield takeLatest(contactTypes.MERGE_CONTACTS, handleMergeContacts);
}

export function* handleConsolidateContacts(action) {
  try {
    const { targetContact, contactIds, params } = action.data;
    yield call(
      axios.post,
      `${url}/${targetContact._id}/consolidate`,
      {
        primaryContact: targetContact,
        contactIds,
      },
      { withCredentials: true },
    );
    yield put({
      type: contactTypes.GET_CONTACTS,
      data: { params },
    });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put(errorToast(error));
  }
}

export function* consolidateContacts() {
  yield takeLatest(
    contactTypes.CONSOLIDATE_CONTACTS,
    handleConsolidateContacts,
  );
}
