import React from 'react';
import PropTypes from 'prop-types';
import { Label } from 'office-ui-fabric-react/lib/Label';
import {
  PrimaryButton,
  DefaultButton,
} from 'office-ui-fabric-react/lib/Button';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import {
  statuses,
  officeTypes,
  federalOffices,
  fecNonCandidateCommitteeOptions,
  fecPacOrganizationOptions,
} from '../../helpers/constants';
import { formatDate, getLabelFromOptions } from '../../helpers/util';
import { isFederalPac, isFederalJfc } from '../../helpers/committeeHelper';
import { Grid, GridRow, Column } from '../../components/common';
import './Summary.css';

export const Summary = ({
  isNonCandidateCommittee,
  committee,
  candidateFirstName,
  candidateMiddleName,
  candidateLastName,
  candidateEmailAddress,
  officeType,
  officeState,
  officeCategory,
  office,
  suboffice,
  nextElectionYear,
  planPrice,
  productName,
  promoCode,
  actions,
  status,
  coupon,
}) => {
  const isFederalNonCandidate = isFederalJfc(committee) || isFederalPac(committee);
  return (
    <div className="Summary">
      <Grid>
        <GridRow>
          <Column md={3} classNames="lead-column">
            <Icon
              iconName="Receipt"
              styles={{ root: { fontSize: '72px', color: '#71afe5' } }}
            />
          </Column>
          <Column md={9}>
            {(isNonCandidateCommittee && !isFederalNonCandidate) && (
              <Grid>
                <GridRow>
                  <Column md={6}>
                    <Label className="summary-label ms-fontColor-themeDarker">
                      Committee Name:
                    </Label>
                    <p>{committee.name}</p>
                  </Column>
                  <Column md={6}>
                    <Label className="summary-label ms-fontColor-themeDarker">
                      Committee Type:
                    </Label>
                    <p>{committee.type}</p>
                  </Column>
                  <Column md={6}>
                    <Label className="summary-label ms-fontColor-themeDarker">
                      Committee State:
                    </Label>
                    <p>{committee.state}</p>
                  </Column>
                  <Column md={6}>
                    <Label className="summary-label ms-fontColor-themeDarker">
                      Registration Date:
                    </Label>
                    <p>
                      {formatDate(committee.registrationDate, 'MMM D, YYYY')}
                    </p>
                  </Column>
                </GridRow>
                {coupon && (
                  <GridRow>
                    <Column md={6}>
                      <Label className="summary-label ms-fontColor-themeDarker">
                        Coupon:
                      </Label>
                      <p>{coupon}</p>
                    </Column>
                  </GridRow>
                )}
                {!promoCode && (
                  <GridRow>
                    <Column>
                      <Label className="summary-label ms-fontColor-themeDarker">
                        Subscription:
                      </Label>
                      <p>
                        {productName} - {planPrice}
                      </p>
                    </Column>
                  </GridRow>
                )}
                {promoCode && (
                  <GridRow>
                    <Column>
                      <Label className="summary-label ms-fontColor-themeDarker">
                        Subscription:
                      </Label>
                      <p>{'Free Trial'}</p>
                    </Column>
                  </GridRow>
                )}
              </Grid>
            )}
            {(isNonCandidateCommittee && isFederalNonCandidate) && (
              <Grid>
                <GridRow>
                  <Column md={6}>
                    <Label className="summary-label ms-fontColor-themeDarker">
                      Committee Name:
                    </Label>
                    <p>{committee.name}</p>
                  </Column>
                  <Column md={6}>
                    <Label className="summary-label ms-fontColor-themeDarker">
                      Committee Type:
                    </Label>
                    <p>{getLabelFromOptions(fecNonCandidateCommitteeOptions, committee.type)}</p>
                  </Column>

                </GridRow>
                { committee.type === 'pac-separate-segregated-fund' &&
                <GridRow>
                  <Column md={6}>
                    <Label className="summary-label ms-fontColor-themeDarker">
                      Committee Name:
                    </Label>
                    <p>{getLabelFromOptions(fecPacOrganizationOptions, committee.federalPac.organizationType)}</p>
                  </Column>
                  <Column md={6}>
                    <Label className="summary-label ms-fontColor-themeDarker">
                      Lobbyist/Registrant PAC:
                    </Label>
                    <p>{committee.federalPac.isLobbyistRegistrantPac ? 'Yes' : 'No'}</p>
                  </Column>
                </GridRow>
                }
                { committee.type === 'pac-support-oppose-more-than-one' &&
                <GridRow>
                  <Column md={6}>
                    <Label className="summary-label ms-fontColor-themeDarker">
                      Lobbyist/Registrant PAC:
                    </Label>
                    <p>{committee.federalPac.isLobbyistRegistrantPac ? 'Yes' : 'No'}</p>
                  </Column>
                  <Column md={6}>
                    <Label className="summary-label ms-fontColor-themeDarker">
                      Leadership PAC:
                    </Label>
                    <p>{committee.federalPac.isLeadershipPac ? 'Yes' : 'No'}</p>
                  </Column>
                </GridRow>
                }
                {coupon && (
                  <GridRow>
                    <Column md={6}>
                      <Label className="summary-label ms-fontColor-themeDarker">
                        Registration Date:
                      </Label>
                      <p>
                        {formatDate(committee.registrationDate, 'MMM D, YYYY')}
                      </p>
                    </Column>
                    <Column md={6}>
                      <Label className="summary-label ms-fontColor-themeDarker">
                        Coupon:
                      </Label>
                      <p>{coupon}</p>
                    </Column>
                  </GridRow>
                )}
                {!promoCode && (
                  <GridRow>
                    <Column>
                      <Label className="summary-label ms-fontColor-themeDarker">
                        Subscription:
                      </Label>
                      <p>
                        {productName} - {planPrice}
                      </p>
                    </Column>
                  </GridRow>
                )}
                {promoCode && (
                  <GridRow>
                    <Column>
                      <Label className="summary-label ms-fontColor-themeDarker">
                        Subscription:
                      </Label>
                      <p>{'Free Trial'}</p>
                    </Column>
                  </GridRow>
                )}
              </Grid>
            )}
            {!isNonCandidateCommittee && (
              <Grid>
                <GridRow>
                  {candidateFirstName.length > 0 &&
                    candidateLastName.length > 0 && (
                      <Column md={6}>
                        <Label className="summary-label ms-fontColor-themeDarker">
                          Candidate:
                        </Label>
                        <p>{`${candidateFirstName} ${
                          candidateMiddleName ? candidateMiddleName + ' ' : ''
                        }${candidateLastName}`}</p>
                      </Column>
                    )}
                  {candidateEmailAddress.length > 0 && (
                    <Column md={6}>
                      <Label className="summary-label ms-fontColor-themeDarker">
                        Email:
                      </Label>
                      <p>{candidateEmailAddress}</p>
                    </Column>
                  )}
                  {officeType !== 0 && (
                    <Column md={6}>
                      <Label className="summary-label ms-fontColor-themeDarker">
                        Office Type:
                      </Label>
                      <p>{officeType}</p>
                    </Column>
                  )}
                  {officeState !== 0 && (
                    <Column md={6}>
                      <Label className="summary-label ms-fontColor-themeDarker">
                        Office State:
                      </Label>
                      <p>{officeState}</p>
                    </Column>
                  )}
                  {officeType === officeTypes.LOCAL && (
                    <Column md={6}>
                      <Label className="summary-label ms-fontColor-themeDarker">
                        Jurisdiction:
                      </Label>
                      <p>{officeCategory}</p>
                    </Column>
                  )}
                  {officeType !== officeTypes.FEDERAL &&
                    (suboffice !== 0 || suboffice.length) &&
                    office !== 0 && (
                      <Column md={6}>
                        <Label className="summary-label ms-fontColor-themeDarker">
                          Office:
                        </Label>
                        <p>
                          {office}, {suboffice}
                        </p>
                      </Column>
                    )}
                  {officeType === officeTypes.FEDERAL &&
                    office === federalOffices.H &&
                    (suboffice !== 0 || suboffice.length) && (
                      <Column md={6}>
                        <Label className="summary-label ms-fontColor-themeDarker">
                          Office:
                        </Label>
                        <p>
                          {office}, {suboffice}
                        </p>
                      </Column>
                    )}
                  {officeType === officeTypes.FEDERAL &&
                    office === federalOffices.S && (
                      <Column md={6}>
                        <Label className="summary-label ms-fontColor-themeDarker">
                          Office:
                        </Label>
                        <p>{office}</p>
                      </Column>
                    )}
                  {nextElectionYear !== 0 && (
                    <Column md={6}>
                      <Label className="summary-label ms-fontColor-themeDarker">
                        Election Year:
                      </Label>
                      <p>{nextElectionYear}</p>
                    </Column>
                  )}
                </GridRow>
                {coupon && (
                  <GridRow>
                    <Column md={6}>
                      <Label className="summary-label ms-fontColor-themeDarker">
                        Coupon:
                      </Label>
                      <p>{coupon}</p>
                    </Column>
                  </GridRow>
                )}
                {!promoCode && (
                  <GridRow>
                    <Column>
                      <Label className="summary-label ms-fontColor-themeDarker">
                        Subscription:
                      </Label>
                      <p>
                        {productName} - {planPrice}
                      </p>
                    </Column>
                  </GridRow>
                )}
                {promoCode && (
                  <GridRow>
                    <Column>
                      <Label className="summary-label ms-fontColor-themeDarker">
                        Subscription:
                      </Label>
                      <p>{'Free Trial'}</p>
                    </Column>
                  </GridRow>
                )}
              </Grid>
            )}
          </Column>
        </GridRow>
      </Grid>
      <div className="actions">
        <Grid>
          <GridRow>
            <Column classNames="ms-textAlignRight">
              {status !== statuses.PROCESSING && (
                <DefaultButton
                  text="Back"
                  onClick={actions.handlePreviousStep}
                  className="back-btn"
                />
              )}
              {status === statuses.NOT_STARTED && (
                <PrimaryButton
                  text="Create"
                  onClick={actions.handleNextStep}
                  primary
                />
              )}

              {status === statuses.PROCESSING && (
                <Spinner size={SpinnerSize.large} />
              )}
            </Column>
          </GridRow>
        </Grid>
      </div>
    </div>
  );
};

Summary.propTypes = {
  isNonCandidateCommittee: PropTypes.bool,
  committee: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    registrationDate: PropTypes.object,
    state: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    federalPac: PropTypes.object,
  }),
  candidateFirstName: PropTypes.string,
  candidateMiddleName: PropTypes.string,
  candidateLastName: PropTypes.string,
  candidateEmailAddress: PropTypes.string,
  officeType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  officeState: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  officeCategory: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  office: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  suboffice: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
  ]),
  nextElectionYear: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  planPrice: PropTypes.string,
  productName: PropTypes.string,
  promoDescription: PropTypes.string,
  promoCode: PropTypes.string,
  coupon: PropTypes.string,
  actions: PropTypes.object,
  status: PropTypes.string.isRequired,
};

Summary.defaultProps = {
  isNonCandidateCommittee: false,
  committee: {
    name: '',
    type: '',
    registrationDate: null,
    state: null,
    electionDate: null,
  },
  candidateFirstName: '',
  candidateMiddleName: '',
  candidateLastName: '',
  candidateEmailAddress: '',
  officeType: 0,
  officeState: 0,
  officeCategory: 0,
  office: 0,
  suboffice: '',
  nextElectionYear: 0,
  planPrice: '',
  productName: '',
  coupon: '',
  promoDescription: '',
  promoCode: '',
};

export default Summary;
