import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Image } from 'office-ui-fabric-react/lib/Image';
import {
  MessageBar,
  MessageBarType,
} from 'office-ui-fabric-react/lib/MessageBar';
import { ActionButton } from 'office-ui-fabric-react/lib/Button';
import DefaultCampaignLogo from '../../assets/img/icon-sq-sm-wht.png';
import { getLabel } from '../../helpers/labelHelper';
import { federalOffices } from '../../helpers/constants';
import { DataLabel } from '../../components/common';

const DashboardCampaignInfo = ({ currentCampaign, user, history }) => {
  let { office, subOffice } = (currentCampaign.campaign || '');
  office = getLabel(office, user.session);
  if (subOffice && subOffice !== 'undefined') {
    if (office === federalOffices.H) {
      subOffice = `District ${subOffice}`;
    }
  } else {
    subOffice = '';
  }
  office = federalOffices[office] || office;

  return (
    <div className="depth-1 campaign-info">
      <div className="ms-textAlignCenter campaign-logo">
        <Image src={DefaultCampaignLogo} height={120} />
      </div>
      <div className="campaign-details">
        <h2>{currentCampaign.campaign.campaignName}</h2>

        <div className="ms-textAlignCenter">
          <Fragment>
            <div className="divider" />
            <DataLabel
              value={currentCampaign.campaign.managedBy}
              label={'Managed By'}
              style={{ justifyContent: 'center' }}
              valueStyle={{ fontWeight: 300 }}
            />
            <DataLabel
              label={currentCampaign.campaign.description}
              style={{ justifyContent: 'center' }}
              valueStyle={{ fontWeight: 300 }}
            />
          </Fragment>
        </div>

        {currentCampaign.campaign &&
          !currentCampaign.campaign.isNonCandidateCommittee && (
            <Fragment>
              <div className="divider" />
              {user.session.isFederal() && (
                <DataLabel
                  label={`${office}${subOffice ? ', ' + subOffice : ''}`}
                />
              )}
              {!user.session.isFederal() && (
                <DataLabel
                  label={`${currentCampaign.campaign.officeName}`}
                />
              )}
              {user.session.isFederal() && office !== 'President' && (
                <Fragment>
                  <div className="divider" />
                  <DataLabel
                    label={currentCampaign.campaign.officeState}
                  />
                </Fragment>
              )}
              <div className="divider" />
            </Fragment>
          )
        }

        {currentCampaign.campaign &&
          !currentCampaign.campaign.isNonCandidateCommittee && (
            <DataLabel
              value={currentCampaign.campaign.nextElectionYear}
              label="Next Election"
              style={{ justifyContent: 'center' }}
              valueStyle={{ fontWeight: 300 }}
            />
          )
        }
        {currentCampaign.campaign.chairPersonFullName &&
        currentCampaign.campaign.chairPersonFullName.trim() ? (
          <Fragment>
            <div className="divider" />
            <DataLabel
              value={currentCampaign.campaign.chairPersonFullName}
              label={`${getLabel('Chairperson', user.session)}`}
              style={{ justifyContent: 'center' }}
              valueStyle={{ fontWeight: 300 }}
            />
          </Fragment>
        ) : null}
        {currentCampaign.campaign.treasurerFullName &&
        currentCampaign.campaign.treasurerFullName.trim() ? (
          <Fragment>
            <div className="divider" />
            <DataLabel
              value={currentCampaign.campaign.treasurerFullName}
              label={getLabel('Treasurer', user.session)}
              style={{ justifyContent: 'center' }}
              valueStyle={{ fontWeight: 300 }}
            />
            <div className="divider" />
          </Fragment>
        ) : null}
        <div className="ms-textAlignCenter">
          {currentCampaign.campaign &&
            currentCampaign.campaign.isSetupComplete === false && (
              <Fragment>
                <MessageBar messageBarType={MessageBarType.error}>
                  {
                    "You have not finished setting up your campaign, click 'Edit Campaign' to complete setup."
                  }
                </MessageBar>
                <ActionButton
                  className="ms-textAlignCenter"
                  onClick={() => {
                    history.push('/filer/editCampaign');
                  }}
                  text="Edit Campaign"
                  iconProps={{
                    iconName: 'PencilAlt',
                  }}
                />
              </Fragment>
            )}
        </div>
      </div>
    </div>
  );
};

DashboardCampaignInfo.propTypes = {
  currentCampaign: PropTypes.object,
  user: PropTypes.object,
  history: PropTypes.object.isRequired,
};

DashboardCampaignInfo.defaultProps = {
  currentCampaign: {},
  user: {},
};

export default DashboardCampaignInfo;
