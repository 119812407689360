import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { TrustTypePicker } from '../TrustTypePicker';
import { GridRow, Column } from '../../common';

const TrustEntity = ({
  contactName,
  businessName,
  trustee,
  trustor,
  isLivingOrRevokableTrust,
  onChange,
}) => {
  return (
    <Fragment>
      <GridRow>
        <Column md={6}>
          <TextField
            value={businessName.value}
            label="Trust Name"
            onChange={onChange(businessName.field)}
            required={businessName.required}
            errorMessage={businessName.errorMessage}
          />
        </Column>
        <Column md={6}>
          <TextField
            value={contactName.value}
            label={contactName.label}
            onChange={onChange(contactName.field)}
            required={contactName.required}
            errorMessage={contactName.errorMessage}
          />
        </Column>
      </GridRow>
      <GridRow>
        <Column md={4}>
          <TrustTypePicker
            selectedKey={isLivingOrRevokableTrust.value}
            onChange={onChange(isLivingOrRevokableTrust.field)}
          />
        </Column>
        <Column md={4}>
          <TextField
            value={trustee.value}
            label={trustee.label}
            onChange={onChange(trustee.field)}
            required={trustee.required}
            errorMessage={trustee.errorMessage}
          />
        </Column>
        <Column md={4}>
          <TextField
            value={trustor.value}
            label={trustor.label}
            onChange={onChange(trustor.field)}
            required={trustor.required}
            errorMessage={trustor.errorMessage}
          />
        </Column>
      </GridRow>
    </Fragment>
  );
};

TrustEntity.propTypes = {
  contactName: PropTypes.object.isRequired,
  businessName: PropTypes.object.isRequired,
  trustee: PropTypes.object.isRequired,
  trustor: PropTypes.object.isRequired,
  isLivingOrRevokableTrust: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default TrustEntity;
