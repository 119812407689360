import { put, call, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import { push } from 'connected-react-router';
import { types as adminUserTypes } from '../actions/adminUserActions';
import {
  getServerSideErrorMessage,
  errorToast,
  successToast,
} from '../helpers/util';

const url = '/api/admin/users';

export function* handleGetUsersForCampaign() {
  try {
    yield put({ type: adminUserTypes.GET_USERS_FOR_CAMPAIGN_PROCESSING });
    const { data: users } = yield call(axios.get, url, {
      withCredentials: true,
    });
    yield put({
      type: adminUserTypes.GET_USERS_FOR_CAMPAIGN_SUCCESS,
      data: { users },
    });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put(errorToast(error));
  }
}

export function* getUsersForCampaign() {
  yield takeLatest(
    adminUserTypes.GET_USERS_FOR_CAMPAIGN,
    handleGetUsersForCampaign,
  );
}

export function* handleAddUser(action) {
  try {
    const { user } = action.data;
    const { data: newUser } = yield call(axios.post, url, user, {
      withCredentials: true,
    });
    yield put(successToast('User created successfully!'));
    yield put({ type: adminUserTypes.ADD_USER_SUCCESS, data: { newUser } });
    yield put(push('/filer/manageUsers'));
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put(errorToast(error));
  }
}

export function* addUser() {
  yield takeLatest(adminUserTypes.ADD_USER, handleAddUser);
}

export function* handleGetUserForEdit(action) {
  try {
    const { _id } = action.data;
    const { data: user } = yield call(axios.get, `${url}/${_id}`, {
      withCredentials: true,
    });
    yield put({
      type: adminUserTypes.GET_USER_FOR_EDIT_SUCCESS,
      data: { user },
    });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put(errorToast(error));
  }
}

export function* getUserForEdit() {
  yield takeLatest(adminUserTypes.GET_USER_FOR_EDIT, handleGetUserForEdit);
}

export function* handleEditUser(action) {
  try {
    const { _id, user } = action.data;
    yield call(axios.put, `${url}/${_id}`, user, { withCredentials: true });
    yield put(push('/filer/manageUsers'));
    yield put({ type: adminUserTypes.GET_USERS_FOR_CAMPAIGN });
    yield put(successToast('User updated successfully!'));
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put(errorToast(error));
  }
}

export function* editUser() {
  yield takeLatest(adminUserTypes.EDIT_USER, handleEditUser);
}

export function* handleResendInvite(action) {
  try {
    const { _id } = action.data;
    yield call(axios.post, `${url}/${_id}/resendInvitation`, {
      withCredentials: true,
    });
    yield put(successToast('Invite resent to user'));
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put(errorToast(error));
  }
}

export function* resendInvite() {
  yield takeLatest(adminUserTypes.RESEND_INVITE, handleResendInvite);
}

export function* handleToggleUserActive(action) {
  try {
    const { _id, isActive } = action.data;
    yield call(
      axios.patch,
      `/api/admin/users/${_id}`,
      { active: isActive },
      { withCredentials: true },
    );
    yield put({ type: adminUserTypes.GET_USERS_FOR_CAMPAIGN });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put(errorToast(error));
  }
}

export function* toggleUserActive() {
  yield takeLatest(adminUserTypes.TOGGLE_USER_ACTIVE, handleToggleUserActive);
}
