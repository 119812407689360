import { takeLatest, put, call } from 'redux-saga/effects';
import axios from 'axios';
import { push } from 'connected-react-router';
import { types } from '../actions/federalReportActions';
import { types as messagingTypes } from '../actions/messagingActions';
import { getServerSideErrorMessage } from '../helpers/util';
import { toastTypes } from '../helpers/constants';

export function* handleCreateFederalReport({ data: { reportDetails } }) {
  try {
    yield put({ type: types.CREATE_FEDERAL_REPORT_PROCESSING });
    const { data: newReport } = yield call(
      axios.post,
      '/api/filer/fec/reports',
      reportDetails,
      { withCredentials: true },
    );
    yield put({
      type: types.CREATE_FEDERAL_REPORT_SUCCESS,
      data: { newReport },
    });
    yield put(push(`/filer/editFECReport/${newReport._id}`));
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put({ type: types.CREATE_FEDERAL_REPORT_FAILURE, error });
    yield put({
      type: messagingTypes.SET_TOAST,
      data: {
        message: error,
        toastType: toastTypes.ERROR,
      },
    });
  }
}

export function* createFederalReport() {
  yield takeLatest(types.CREATE_FEDERAL_REPORT, handleCreateFederalReport);
}
