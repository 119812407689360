import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogType,
  DialogFooter,
  DefaultButton,
  PrimaryButton,
  TextField,
} from 'office-ui-fabric-react';
import { validate } from './F3ZDialogValidations';
import { Grid, GridRow, Column, MoneyField } from '../common';

const initialState = {
  committeeName: '',
  committeeId: '',
  col6c_NetContributions: '',
  col7c_NetOperatingExpenditures: '',
  col9_DebtsAndObligationsOwedToTheCommittee: '',
  col10_DebtsAndObligationsOwedByTheCommittee: '',
  col11a_ContributionsFromIndividuals_PersonsOtherThanPoliticalCommittees: '',
  col11b_ContributionsFromPoliticalPartyCommittees: '',
  col11c_ContributionsFromOtherPoliticalCommittees: '',
  col11d_ContributionsFromTheCandidate: '',
  col11e_TotalContributions: '',
  col12_TransfersFromOtherAuthorizedCommittees: '',
  col13a_LoansMadeOrGuaranteedByTheCandidate: '',
  col13b_AllOtherLoans: '',
  col13c_TotalLoans: '',
  col14_OffsetsToOperatingExpenditures: '',
  col15_OtherReceipts: '',
  col16_TotalReceipts: '',
  col17_OperatingExpenditures: '',
  col18_TransfersToOtherAuthorizedCommittees: '',
  col19a_RepaymentsOfLoansMadeOrGuaranteedByCandidate: '',
  col19b_OtherLoanRepayments: '',
  col19c_TotalLoanRepayments: '',
  col20a_RefundsOfContributionsToIndividuals_Persons: '',
  col20b_RefundsOfContributionsToPoliticalPartyCommittees: '',
  col20c_RefundsOfContributionsToOtherPoliticalCommittees: '',
  col20d_TotalContributionsRefunds: '',
  col21_OtherDisbursements: '',
  col22_TotalDisbursements: '',
  col23_CashOnHandAtBeginningOfReportingPeriod: '',
  col27_CashOnHandAtCloseOfReportingPeriod: '',
};

const F3ZDialog = ({
  dialogHidden,
  closeDialog,
  f3ZToEdit,
  updateF3Z,
  addF3Z,
}) => {
  const [state, setState] = useState({ ...initialState });
  const [errors, setErrors] = useState({});
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const onResize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  useEffect(() => {
    if (f3ZToEdit !== null) {
      setState({
        ...f3ZToEdit,
      });
    }
  }, [f3ZToEdit]);

  const handleChange = fieldName => (e, value) => {
    setState({
      ...state,
      [fieldName]: value.key !== undefined ? value.key : value,
    });
  };

  const onCloseDialog = () => {
    closeDialog();
    setState({ ...initialState });
    setErrors({});
  };

  const onSave = () => {
    const validationErrors = validate(state);
    if (Object.keys(validationErrors).length) {
      setErrors(validationErrors);
    } else {
      setErrors({});

      if (f3ZToEdit) {
        updateF3Z(state);
      } else {
        addF3Z(state);
      }

      onCloseDialog();
    }
  };

  return (
    <Dialog
      minWidth={screenWidth < 910 ? '95%' : '50%'}
      maxMidth={screenWidth < 910 ? '95%' : 775}
      hidden={dialogHidden}
      onDismiss={onCloseDialog}
      dialogContentProps={{
        type: DialogType.normal,
        title: `${f3ZToEdit ? 'Edit' : 'Add'} F3Z`,
        subText: '',
      }}
    >
      <Grid>
        <GridRow style={{ paddingBottom: 16 }}>
          <Column md={6}>
            <TextField
              label="Committee Name"
              value={state.committeeName}
              onChange={handleChange('committeeName')}
              required
              errorMessage={errors.committeeNameError}
            />
          </Column>
          <Column md={6}>
            <TextField
              label="Committee ID"
              value={state.committeeId}
              onChange={handleChange('committeeId')}
              required
              errorMessage={errors.committeeIdError}
            />
          </Column>
        </GridRow>
        <GridRow style={{ borderTop: '1px solid #919191', paddingTop: 16 }}>
          <Column md={6}>
            <MoneyField
              label="6(c) Net Contributions"
              value={state.col6c_NetContributions}
              onChange={handleChange('col6c_NetContributions')}
              errorMessage={errors.col6c_NetContributionsError}
            />
          </Column>
          <Column md={6}>
            <MoneyField
              label="15 Other Receipts"
              value={state.col15_OtherReceipts}
              onChange={handleChange('col15_OtherReceipts')}
              errorMessage={errors.col15_OtherReceiptsError}
            />
          </Column>
        </GridRow>
        <GridRow>
          <Column md={6}>
            <MoneyField
              label="7(c) Net Operating Expenditures"
              value={state.col7c_NetOperatingExpenditures}
              onChange={handleChange('col7c_NetOperatingExpenditures')}
              errorMessage={errors.col7c_NetOperatingExpendituresError}
            />
          </Column>
          <Column md={6}>
            <MoneyField
              label="16 Total Receipts"
              value={state.col16_TotalReceipts}
              onChange={handleChange('col16_TotalReceipts')}
              errorMessage={errors.col16_TotalReceiptsError}
            />
          </Column>
        </GridRow>
        <GridRow>
          <Column md={6}>
            <MoneyField
              label="9 Debts and Obligations Owed TO the Committee"
              value={state.col9_DebtsAndObligationsOwedToTheCommittee}
              onChange={handleChange('col9_DebtsAndObligationsOwedToTheCommittee')}
              errorMessage={errors.col9_DebtsAndObligationsOwedToTheCommitteeError}
            />
          </Column>
          <Column md={6}>
            <MoneyField
              label="17 Operating Expenditures"
              value={state.col17_OperatingExpenditures}
              onChange={handleChange('col17_OperatingExpenditures')}
              errorMessage={errors.col17_OperatingExpendituresError}
            />
          </Column>
        </GridRow>
        <GridRow>
          <Column md={6}>
            <MoneyField
              label="10 Debts and Obligations Owed BY the Committee"
              value={state.col10_DebtsAndObligationsOwedByTheCommittee}
              onChange={handleChange('col10_DebtsAndObligationsOwedByTheCommittee')}
              errorMessage={errors.col10_DebtsAndObligationsOwedByTheCommitteeError}
            />
          </Column>
          <Column md={6}>
            <MoneyField
              label="18 Transfers to Other Authorized Committees"
              value={state.col18_TransfersToOtherAuthorizedCommittees}
              onChange={handleChange('col18_TransfersToOtherAuthorizedCommittees')}
              errorMessage={errors.col18_TransfersToOtherAuthorizedCommitteesError}
            />
          </Column>
        </GridRow>
        <GridRow>
          <Column md={6}>
            <MoneyField
              label="11(a) Contributions from Individuals/Persons Other Than Political Committees"
              value={state.col11a_ContributionsFromIndividuals_PersonsOtherThanPoliticalCommittees}
              onChange={handleChange('col11a_ContributionsFromIndividuals_PersonsOtherThanPoliticalCommittees')}
              errorMessage={errors.col11a_ContributionsFromIndividuals_PersonsOtherThanPoliticalCommitteesError}
            />
          </Column>
          <Column md={6}>
            <MoneyField
              label="19(a) Repayments of Loans Made or Guaranteed by Candidate"
              value={state.col19a_RepaymentsOfLoansMadeOrGuaranteedByCandidate}
              onChange={handleChange('col19a_RepaymentsOfLoansMadeOrGuaranteedByCandidate')}
              errorMessage={errors.col19a_RepaymentsOfLoansMadeOrGuaranteedByCandidateError}
            />
          </Column>
        </GridRow>
        <GridRow>
          <Column md={6}>
            <MoneyField
              label="11(b) Contributions from Political Party Committees"
              value={state.col11b_ContributionsFromPoliticalPartyCommittees}
              onChange={handleChange('col11b_ContributionsFromPoliticalPartyCommittees')}
              errorMessage={errors.col11b_ContributionsFromPoliticalPartyCommitteesError}
            />
          </Column>
          <Column md={6}>
            <MoneyField
              label="19(b) Other Loan Repayments"
              value={state.col19b_OtherLoanRepayments}
              onChange={handleChange('col19b_OtherLoanRepayments')}
              errorMessage={errors.col19b_OtherLoanRepaymentsError}
            />
          </Column>
        </GridRow>
        <GridRow>
          <Column md={6}>
            <MoneyField
              label="11(c) Contributions from Other Political Committees"
              value={state.col11c_ContributionsFromOtherPoliticalCommittees}
              onChange={handleChange('col11c_ContributionsFromOtherPoliticalCommittees')}
              errorMessage={errors.col11c_ContributionsFromOtherPoliticalCommitteesError}
            />
          </Column>
          <Column md={6}>
            <MoneyField
              label="19(c) Total Loan Repayments"
              value={state.col19c_TotalLoanRepayments}
              onChange={handleChange('col19c_TotalLoanRepayments')}
              errorMessage={errors.col19c_TotalLoanRepaymentsError}
            />
          </Column>
        </GridRow>
        <GridRow>
          <Column md={6}>
            <MoneyField
              label="11(d) Contributions from the Candidate"
              value={state.col11d_ContributionsFromTheCandidate}
              onChange={handleChange('col11d_ContributionsFromTheCandidate')}
              errorMessage={errors.col11d_ContributionsFromTheCandidateError}
            />
          </Column>
          <Column md={6}>
            <MoneyField
              label="20(a) Refunds of Contributions to Individuals/Persons"
              value={state.col20a_RefundsOfContributionsToIndividuals_Persons}
              onChange={handleChange('col20a_RefundsOfContributionsToIndividuals_Persons')}
              errorMessage={errors.col20a_RefundsOfContributionsToIndividuals_PersonsError}
            />
          </Column>
        </GridRow>
        <GridRow>
          <Column md={6}>
            <MoneyField
              label="11(e) Total Contributions"
              value={state.col11e_TotalContributions}
              onChange={handleChange('col11e_TotalContributions')}
              errorMessage={errors.col11e_TotalContributionsError}
            />
          </Column>
          <Column md={6}>
            <MoneyField
              label="20(b) Refunds of Contributions to Political Party Committees"
              value={state.col20b_RefundsOfContributionsToPoliticalPartyCommittees}
              onChange={handleChange('col20b_RefundsOfContributionsToPoliticalPartyCommittees')}
              errorMessage={errors.col20b_RefundsOfContributionsToPoliticalPartyCommitteesError}
            />
          </Column>
        </GridRow>
        <GridRow>
          <Column md={6}>
            <MoneyField
              label="12 Transfers from Other Authorized Committees"
              value={state.col12_TransfersFromOtherAuthorizedCommittees}
              onChange={handleChange('col12_TransfersFromOtherAuthorizedCommittees')}
              errorMessage={errors.col12_TransfersFromOtherAuthorizedCommitteesError}
            />
          </Column>
          <Column md={6}>
            <MoneyField
              label="20(c) Refunds of Contributions to Other Political Committees"
              value={state.col20c_RefundsOfContributionsToOtherPoliticalCommittees}
              onChange={handleChange('col20c_RefundsOfContributionsToOtherPoliticalCommittees')}
              errorMessage={errors.col20c_RefundsOfContributionsToOtherPoliticalCommitteesError}
            />
          </Column>
        </GridRow>
        <GridRow>
          <Column md={6}>
            <MoneyField
              label="13(a) Loans Made or Guaranteed by the Candidate"
              value={state.col13a_LoansMadeOrGuaranteedByTheCandidate}
              onChange={handleChange('col13a_LoansMadeOrGuaranteedByTheCandidate')}
              errorMessage={errors.col13a_LoansMadeOrGuaranteedByTheCandidateError}
            />
          </Column>
          <Column md={6}>
            <MoneyField
              label="20(d) Total Contributions Refunds"
              value={state.col20d_TotalContributionsRefunds}
              onChange={handleChange('col20d_TotalContributionsRefunds')}
              errorMessage={errors.col20d_TotalContributionsRefundsError}
            />
          </Column>
        </GridRow>
        <GridRow>
          <Column md={6}>
            <MoneyField
              label="13(b) All Other Loans"
              value={state.col13b_AllOtherLoans}
              onChange={handleChange('col13b_AllOtherLoans')}
              errorMessage={errors.col13b_AllOtherLoansError}
            />
          </Column>
          <Column md={6}>
            <MoneyField
              label="21 Other Disbursements"
              value={state.col21_OtherDisbursements}
              onChange={handleChange('col21_OtherDisbursements')}
              errorMessage={errors.col21_OtherDisbursementsError}
            />
          </Column>
        </GridRow>
        <GridRow>
          <Column md={6}>
            <MoneyField
              label="13(c) Total Loans"
              value={state.col13c_TotalLoans}
              onChange={handleChange('col13c_TotalLoans')}
              errorMessage={errors.col13c_TotalLoansError}
            />
          </Column>
          <Column md={6}>
            <MoneyField
              label="22 Total Disbursements"
              value={state.col22_TotalDisbursements}
              onChange={handleChange('col22_TotalDisbursements')}
              errorMessage={errors.col22_TotalDisbursementsError}
            />
          </Column>
        </GridRow>
        <GridRow>
          <Column md={6}>
            <MoneyField
              label="14 Offsets to Operating Expenditures"
              value={state.col14_OffsetsToOperatingExpenditures}
              onChange={handleChange('col14_OffsetsToOperatingExpenditures')}
              errorMessage={errors.col14_OffsetsToOperatingExpendituresError}
            />
          </Column>
          <Column md={6}>
            <MoneyField
              label="23 Cash on Hand at Beginning of Reporting Period"
              value={state.col23_CashOnHandAtBeginningOfReportingPeriod}
              onChange={handleChange('col23_CashOnHandAtBeginningOfReportingPeriod')}
              errorMessage={errors.col23_CashOnHandAtBeginningOfReportingPeriodError}
            />
          </Column>
        </GridRow>
        <GridRow>
          <Column md={6}>
            &nbsp;
          </Column>
          <Column md={6}>
            <MoneyField
              label="27 Cash on Hand at Close of Reporting Period"
              value={state.col27_CashOnHandAtCloseOfReportingPeriod}
              onChange={handleChange('col27_CashOnHandAtCloseOfReportingPeriod')}
              errorMessage={errors.col27_CashOnHandAtCloseOfReportingPeriodError}
            />
          </Column>
        </GridRow>
      </Grid>
      <DialogFooter>
        <DefaultButton
          text="Cancel"
          onClick={onCloseDialog}
          styles={{ root: { marginRight: 16 } }}
        />
        <PrimaryButton text="Save" onClick={onSave} />
      </DialogFooter>
    </Dialog>
  );
};

F3ZDialog.propTypes = {
  dialogHidden: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  f3ZToEdit: PropTypes.object,
  updateF3Z: PropTypes.func.isRequired,
  addF3Z: PropTypes.func.isRequired,
};

F3ZDialog.defaultProps = {
  f3ZToEdit: null,
};

export default F3ZDialog;
