import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StripeProvider, Elements } from 'react-stripe-elements';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions as paymentActions } from '../../actions/paymentActions';
import CreateCampaign from './CreateCampaign';

export class CreateCampaignContainer extends Component {
  static propTypes = {
    payment: PropTypes.object.isRequired,
    paymentActions: PropTypes.object.isRequired,
  };

  componentDidMount() {
    if (!this.props.payment.publishableKey) {
      this.props.paymentActions.getStripePublishableKey();
    }
  }

  render() {
    const { publishableKey } = this.props.payment;

    if (!publishableKey) {
      return null;
    }

    return (
      <StripeProvider apiKey={publishableKey}>
        <Elements>
          <CreateCampaign />
        </Elements>
      </StripeProvider>
    );
  }
}

function mapStateToProps(state) {
  return {
    payment: state.payment,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    paymentActions: bindActionCreators(paymentActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateCampaignContainer);
