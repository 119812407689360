// campaign
export const getCurrentCampaign = state => state.currentCampaign;

export const getCampaign = state => getCurrentCampaign(state)?.campaign;

export const getCampaignFinances = state => getCurrentCampaign(state)?.campaignFinances;

export const getCampaignFinancesStatus = state => getCurrentCampaign(state)?.getCurrentCampaignFinanceStatus;

export const getNextElectionYear = state =>
  getCampaign(state)?.nextElectionYear;

export const getIsNonCandidateCommittee = state =>
  getCampaign(state)?.isNonCandidateCommittee;

export const getDashboardMoneyInfo = state =>
  getCampaign(state)?.dashboardMoneyInfo;

// user
export const getUser = state => state.user;

export const getUserSession = state => getUser(state)?.session;

// anedot
export const getAnedot = state => state.anedot;

export const getAnedotUpdateStatus = state => getAnedot(state)?.updateStatus;

export const getNextUnprocessedEvent = state =>
  getAnedot(state)?.nextUnprocessed;

export const getUnprocessedAnedotRecord = state =>
  getAnedot(state)?.unprocessedRecord;

export const getAllUnprocessedEvents = state => getAnedot(state)?.unprocessed;

export const getUnprocessedStatus = state =>
  getAnedot(state)?.getUnprocessedStatus;

export const getCurrentAnedotIndex = state => getAnedot(state)?.currentIndex;

export const getAggregateAmount = state => getAnedot(state)?.aggregateAmount;

// deposits
export const getDeposits = state => state.deposits;

export const getDepositsList = state => getDeposits(state)?.list;

export const getDepositListStatus = state => getDeposits(state)?.getListStatus;

export const getUndepositedItems = state => getDeposits(state)?.undeposited;

export const getUndepositedTotal = state => getDeposits(state)?.undepositedTotal;

export const getUndepositedTotalStatus = state => getDeposits(state)?.getUndepositedTotalStatus;

export const getSingleDeposit = state => getDeposits(state)?.singleDeposit;

export const getDepositUploadStatus = state => getDeposits(state)?.uploadDepositFileStatus;

export const getUploadResult = state => getDeposits(state)?.uploadResult;

export const getSingleDepositForTicket = state =>
  getDeposits(state)?.singleDepositForTicket;

// aggregates
export const getAggregates = state => state.aggregates;

export const getAggregatesContributions = state =>
  getAggregates(state)?.contributions;

export const getAggregatesExpenditures = state =>
  getAggregates(state)?.expenditures;

// budget categories
export const getBudgetCategory = state => state.budgetCategory;

export const getBudgetCategoryStatus = state =>
  getBudgetCategory(state)?.getBudgetCategoryStatus;

export const getBudgetCategoryOptions = state =>
  getBudgetCategory(state)?.budgetCategoryOptions;

// contributions
export const getContributions = state => state.contributions;

export const getContributionsListStatus = state =>
  getContributions(state)?.getContributionsStatus;

export const getContributionsList = state =>
  getContributions(state)?.contributions;

export const getContributionsForReport = state =>
  getContributions(state)?.contributionsForReport;

export const getContributionsError = state =>
  getContributions(state)?.getContributionsError;

export const getContributionsSaveStatus = state =>
  getContributions(state)?.saveStatus;

export const getNextPageStatusContributions = state =>
  getContributions(state)?.getNextPageStatus;

export const getContributionsByContact = state =>
  getContributions(state)?.contributionsByContact;

export const getContributionsByContactStatus = state =>
  getContributions(state)?.getContributionsByContactStatus;

// constributionSummary
export const getContributionsSummary = state => state.contributionsSummary;

export const getContributionsSummaryList = state => getContributionsSummary(state).contributionsSummary;

export const getContributionsSummaryListStatus = state =>
  getContributionsSummary(state)?.getContributionsSummaryStatus;

export const getContributionsSummaryListNextPageStatus = state =>
  getContributionsSummary(state)?.getContributionsSummaryNextPageStatus;

// expenditures
export const getExpenditures = state => state.expenditures;

export const getExpendituresListStatus = state =>
  getExpenditures(state)?.getExpendituresStatus;

export const getExpendituresList = state =>
  getExpenditures(state)?.expenditures;

export const getNextPageStatusForExpenditures = state =>
  getExpenditures(state)?.getNextPageStatus;

export const getNextPageStatusExpenditures = state =>
  getExpenditures(state)?.getNextPageStatus;

export const getExpendituresByContact = state =>
  getExpenditures(state)?.expendituresByContact;

export const getExpendituresByContactStatus = state =>
  getExpenditures(state)?.getExpendituresByContactStatus;

// export const getExpendituresForReport = state =>
//   getExpenditures(state)?.expendituresForReport;
//
// export const getExpendituresError = state =>
//   getExpenditures(state)?.getExpendituresError;
//
// export const getExpendituresSaveStatus = state =>
//   getExpenditures(state)?.saveStatus;
//

// validations
export const getValidations = state => state.validations;

// ad-hoc
export const getLists = state => state.lists;

export const getListsForAdHoc = state => getLists(state)?.items || [];

export const getListFetchStatus = state => getLists(state)?.status;

export const getListCSVStatus = state => getLists(state)?.csvStatus;

export const getContributionLimitListCSVStatus = state => getLists(state)?.csvContributionLimitStatus;

export const getListRecordCount = state => getLists(state)?.totalRecordCount;

// election cycles
export const getElectionCycles = state => state.electionCycles;

export const getElectionCycleList = state => getElectionCycles(state)?.cycles;

// reconciliations
export const getReconciliations = state => state.reconciliations;

export const getReconciliationListGetStatus = state =>
  getReconciliations(state)?.getListStatus;

export const getReconciliationsList = state => getReconciliations(state)?.list;

export const getUnreconciledActivity = state =>
  getReconciliations(state)?.unreconciledActivity;

export const getUnreconciledDeposits = state =>
  getUnreconciledActivity(state)?.deposits;

export const getUnreconciledExpenditures = state =>
  getUnreconciledActivity(state)?.expenditures;

export const getUnreconciledInterest = state =>
  getUnreconciledActivity(state)?.interestContributions;

export const getSingleReconciliation = state =>
  getReconciliations(state)?.singleReconciliation;

export const getReconciliationSorts = state => state.reconciliationSorts;

// reports
export const getReports = state => state.reports;

export const getDraftReports = state => getReports(state)?.draftReports;

export const getFiledReports = state => getReports(state)?.filedReports;

// reportingPeriods
export const getReportingPeriods = state => state.reportingPeriods;

export const getReportingPeriodsList = state =>
  getReportingPeriods(state).periods;

// contact transactions
export const getContactTransactions = state => state.contactTransactions;

export const getContactContributions = state =>
  getContactTransactions(state)?.contributions;

export const getContactExpenditures = state =>
  getContactTransactions(state)?.expenditures;

export const getContactLoans = state => getContactTransactions(state)?.loans;

export const getContacts = state => state.contacts;

export const getNextPageStatusContacts = state =>
  getContacts(state)?.getNextPageStatus;

export const getSearchValues = state => state.searchValues;

export const getNameSearchValue = state => getSearchValues(state)?.nameSearchValue;

export const getStartDate = state => getSearchValues(state)?.startDate;

export const getEndDate = state => getSearchValues(state)?.endDate;

export const getElectionYear = state => getSearchValues(state)?.electionYear;

export const getContactType = state => getSearchValues(state)?.contactType;

export const getElectionCycle = state => getSearchValues(state)?.electionCycle;

export const getByYear = state => getSearchValues(state)?.byYear;

export const getCheckForNoOccupationOrEmployer = state => getSearchValues(state)?.checkForNoEmployeeOrOccupation;

// consolidation contacts
export const getContactsForMerge = state => state.contactsMerge;

export const getPossibleContactsForMerge = state => getContactsForMerge(state)?.contactToMerge;

export const getPossibleContactsForMergeStatus = state => getContactsForMerge(state)?.contactToMergeStatus;

export const getCurrentReport = state => state.currentReport;

export const getLongRunningProcess = state => state.longRunningProcess;

export const getDataImports = state => state.dataImports;

export const getImportList = state => state.importList;

export const getImportWizard = state => state.importWizard;

export const getImportErrors = state => state.importErrors;

export const getIntegrationSettings = state => state.integrationSettings;

export const getIntegrationSettingsList = state => getIntegrationSettings(state)?.integrationSettings;

export const getIntegrationSettingsListStatus = state => getIntegrationSettings(state)?.getIntegrationSettingsStatus;
