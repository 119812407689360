import React from 'react';
import { FECElectionCyclePicker } from '../../../../components/Pickers';
import {
  ContributionFileFormat,
  ImportDataType,
} from '../../../../helpers/constants';

export const ConfigValueType = {
  Select: 'select',
  Number: 'number',
  Text: 'text',
};

const booleanOptions = [
  { key: 'true', text: 'True', value: true },
  { key: 'false', text: 'False', value: false },
];

const defaultFileFormatConfig = {
  isItemFiled: {
    questionText: 'Have these contributions been filed already?',
    type: ConfigValueType.Select,
    options: booleanOptions,
  },
};

const electionAndYearFileFormatConfig = {
  ...defaultFileFormatConfig,
  electionYear: {
    questionText: 'Election year',
    type: ConfigValueType.Text,
  },
  electionCycle: {
    questionText: 'Election cycle',
    Component: ({ label, value, onChange }) => (
      <FECElectionCyclePicker
        label={label}
        value={value}
        onChange={(event, option) => onChange(option.key || undefined)}
      />
    ),
  },
};

// NOTE: save for future use
// const mailingAndCagingFileFormatConfig = {
//   electionYear: {
//     questionText: 'Election year',
//     type: ConfigValueType.Text,
//   },
// };

const contributionsConfigByFileFormat = {
  [ContributionFileFormat.Anedot]: electionAndYearFileFormatConfig,
  [ContributionFileFormat.DemocracyEngine]: electionAndYearFileFormatConfig,
  [ContributionFileFormat.HSPMail]: electionAndYearFileFormatConfig,
  [ContributionFileFormat.MailingAndCaging]: electionAndYearFileFormatConfig,
};

export const configByDataType = {
  [ImportDataType.Contribution]: contributionsConfigByFileFormat,
};
