import React from 'react';
import PropTypes from 'prop-types';
import {
  ActionButton,
  DefaultButton,
  PrimaryButton,
} from 'office-ui-fabric-react/lib/Button';
import { Grid, GridRow, Column } from '../common';
import { EmptyView } from '../EmptyView';
import LoansList from '../LoansList';
import { formatCurrency } from '../../helpers/util';

export const LoansSection = ({
  items,
  actions,
  isExpanded,
  totalAmount,
  isTBDReport,
  session,
}) => (
  <div className="loans depth-1" name="loans">
    <header onClick={actions.toggleExpand}>
      <h3>Loans</h3>
      <label>{`Amount: ${formatCurrency(totalAmount)}`}</label>
    </header>
    <div className={`content${isExpanded ? '-expanded' : '-collapsed'}`}>
      <Grid>
        <GridRow>
          <Column classNames="ms-textAlignRight">
            <ActionButton
              iconProps={{
                iconName: 'PlusCircle',
                styles: {
                  root: {
                    fontSize: '1.3em',
                  },
                },
              }}
              onClick={actions.addNew}
            >
              Add New Loan
            </ActionButton>
          </Column>
        </GridRow>
        {items.length > 0 && (
          <LoansList
            editItem={actions.editItem}
            loans={{
              loans: [...items],
            }}
            session={session}
          />
        )}
        {items.length === 0 && (
          <EmptyView message="There are no loans for this reporting period." />
        )}
      </Grid>
      <div className="section-actions">
        <DefaultButton
          text="Back"
          style={{ marginRight: 16 }}
          onClick={actions.previousSection}
        />
        <PrimaryButton
          text={isTBDReport ? 'Save' : 'Continue'}
          onClick={actions.nextSection}
        />
      </div>
    </div>
  </div>
);

LoansSection.propTypes = {
  actions: PropTypes.object.isRequired,
  items: PropTypes.array,
  isExpanded: PropTypes.bool,
  totalAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isTBDReport: PropTypes.bool,
  session: PropTypes.object.isRequired,
};

LoansSection.defaultProps = {
  items: [],
  isExpanded: false,
  totalAmount: 0,
  isTBDReport: false,
};

export default LoansSection;
