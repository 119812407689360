import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import { Dropdown } from 'office-ui-fabric-react/lib/Dropdown';
import { Checkbox } from 'office-ui-fabric-react/lib/Checkbox';
import { Grid, GridRow, Column } from '../../components/common';
import {
  DatePicker,
  NonCandidateCommitteeTypePicker,
} from '../../components/Pickers';
import { CaretDown } from '../../components/icons';
import { fecNonCandidateCommitteeOptions, fecCommitteeTypes, fecPacOrganizationOptions } from '../../helpers/constants';

export const NonCandidateCommitteeDetails = ({
  committee,
  actions,
  offices,
  officeType,
  officeTypeError,
  showFederalPacOptions,
}) => {
  const renderStateFormElements = () => {
    return (
      <>
        <GridRow>
          <Column>
            <TextField
              label="Committee Full Name"
              value={committee.name}
              required
              errorMessage={committee.nameError}
              onChange={actions.handleCommitteeTextChange('name')}
            />
          </Column>
        </GridRow>
        <GridRow style={{ marginBottom: 32 }}>
          <Column lg={4}>
            <NonCandidateCommitteeTypePicker
              label="Committee Type"
              required
              selectedKey={committee.type}
              errorMessage={committee.typeError}
              onChange={actions.handleCommitteeTextChange('type')}
            />
          </Column>
          <Column lg={4}>
            <DatePicker
              label="Registration Date"
              required
              value={committee.registrationDate}
              errorMessage={committee.registrationDateError}
              onChange={actions.handleCommitteeDateChange}
            />
          </Column>
          <Column lg={4}>
            <Dropdown
              onRenderCaretDown={() => <CaretDown />}
              placeholder="Select"
              required
              label="Committee State"
              selectedKey={committee.state}
              errorMessage={committee.stateError}
              options={[
                { key: 0, text: 'Select' },
                { key: 'GA', text: 'Georgia' },
              ]}
              onChange={actions.handleCommitteeTextChange('state')}
            />
          </Column>
        </GridRow>
        <GridRow className="actions">
          <Column classNames="ms-textAlignRight">
            <PrimaryButton text="Next" onClick={actions.handleNextStep} />
          </Column>
        </GridRow>
      </>
    );
  };

  const renderFederalFormElements = () => {
    return (
      <>
        <GridRow>
          <Column>
            <TextField
              label="Committee Full Name"
              value={committee.name}
              required
              errorMessage={committee.nameError}
              onChange={actions.handleCommitteeTextChange('name')}
            />
          </Column>
        </GridRow>
        <GridRow>
          <Column lg={6}>
            <Dropdown
              onRenderCaretDown={() => <CaretDown />}
              label="Committee Type"
              options={[
                { key: 0, text: 'Not Selected' },
                ...fecNonCandidateCommitteeOptions,
              ]}
              placeholder="Select"
              selectedKey={committee.type}
              errorMessage={committee.typeError}
              onChange={actions.handleCommitteeTextChange('type')}
              required
            />
          </Column>
          <Column lg={6}>
            <DatePicker
              label="Registration Date"
              required
              value={committee.registrationDate}
              errorMessage={committee.registrationDateError}
              onChange={actions.handleCommitteeDateChange}
            />
          </Column>
        </GridRow>
        {committee.type === fecCommitteeTypes.PacSeparateSegregatedFund && (
          <GridRow style={{ marginTop: '8px' }}>
            <Column lg={6}>
              {committee?.federalPac?.organizationTypeError}
              <Dropdown
                onRenderCaretDown={() => <CaretDown />}
                label="Connected Organization"
                options={[
                  { key: 0, text: 'Not Selected' },
                  ...fecPacOrganizationOptions,
                ]}
                placeholder="Select"
                selectedKey={committee?.federalPac?.organizationType}
                errorMessage={committee?.federalPac?.organizationTypeError}
                onChange={actions.handleCommitteeFederalPacOption('organizationType')}
                required
              />
            </Column>
            <Column lg={6}>
              <Checkbox
                styles={{ root: { marginTop: '32px' } }}
                label="Is Lobbyist/Registrant PAC"
                checked={committee?.federalPac?.isLobbyistRegistrantPac}
                onChange={(e, checked) => actions.handleCommitteeFederalPacCheckboxOption(e, checked, 'isLobbyistRegistrantPac')}
              />
            </Column>
          </GridRow>
        )}
        {committee.type === fecCommitteeTypes.PacSupportOpposeMoreThanOne && (
          <GridRow style={{ marginTop: '16px' }}>
            <Column lg={6}>
              <Checkbox
                label="Is Lobbyist/Registrant PAC"
                checked={committee?.federalPac?.isLobbyistRegistrantPac}
                onChange={(e, checked) => actions.handleCommitteeFederalPacCheckboxOption(e, checked, 'isLobbyistRegistrantPac')}
              />
            </Column>
            <Column lg={6}>
              <Checkbox
                label="Is Leadership PAC"
                checked={committee?.federalPac?.isLeadershipPac}
                onChange={(e, checked) => actions.handleCommitteeFederalPacCheckboxOption(e, checked, 'isLeadershipPac')}
              />
            </Column>
          </GridRow>
        )}
        {committee.type === fecCommitteeTypes.PacIndependentExpenditureOnly && (
          <GridRow style={{ marginTop: '16px' }}>
            <Column lg={6}>
              <Checkbox
                label="Is Lobbyist/Registrant PAC"
                checked={committee?.federalPac?.isLobbyistRegistrantPac}
                onChange={(e, checked) => actions.handleCommitteeFederalPacCheckboxOption(e, checked, 'isLobbyistRegistrantPac')}
              />
            </Column>
          </GridRow>
        )}
        {committee.type === fecCommitteeTypes.PacContributionAndNonContribution && (
          <GridRow style={{ marginTop: '16px' }}>
            <Column lg={6}>
              <Checkbox
                label="Is Lobbyist/Registrant PAC"
                checked={committee?.federalPac?.isLobbyistRegistrantPac}
                onChange={(e, checked) => actions.handleCommitteeFederalPacCheckboxOption(e, checked, 'isLobbyistRegistrantPac')}
              />
            </Column>
          </GridRow>
        )}
        <GridRow className="actions">
          <Column classNames="ms-textAlignRight">
            <PrimaryButton text="Next" onClick={actions.handleNextStep} />
          </Column>
        </GridRow>
      </>
    );
  };

  return (
    <Grid className="NonCandidateCommitteeDetails">
      {showFederalPacOptions &&
      <GridRow>
        <Column>
          <Dropdown
            onRenderCaretDown={() => <CaretDown />}
            label="Non Candidate Committee Type"
            onChange={actions.handleNonCandidateTypeChange}
            selectedKey={officeType}
            dropdownWidth={250}
            errorMessage={officeTypeError}
            placeholder="Select"
            options={[
              ...Object.values(offices)
                .filter(o => o.id !== 3)
                .map(office => ({
                  key: office.id,
                  text: office.name,
                })),
            ]}
            required
          />
        </Column>
      </GridRow>
      }
      {officeType === 1 &&
      <>
        {renderFederalFormElements()}
      </>
      }
      {(officeType === 2 || !showFederalPacOptions) &&
      <>
        {renderStateFormElements()}
      </>
      }
    </Grid>
  );
};

NonCandidateCommitteeDetails.propTypes = {
  committee: PropTypes.object,
  actions: PropTypes.object.isRequired,
  offices: PropTypes.object.isRequired,
  officeType: PropTypes.number,
  officeTypeError: PropTypes.string,
  showFederalPacOptions: PropTypes.bool,
};

NonCandidateCommitteeDetails.defaultProps = {
  officeType: '',
  officeTypeError: '',
  committee: {
    name: '',
    nameError: '',
    type: 0,
    typeError: '',
    registrationDate: new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate(),
    ),
    registrationDateError: '',
    formValid: true,
    state: 0,
    stateError: '',
    showFederalPacOptions: false,
  },
};

export default NonCandidateCommitteeDetails;
