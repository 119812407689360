import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { GridRow, Column } from '../../common';
import { BusinessTypePicker } from '../BusinessTypePicker';

const BusinessEntity = ({
  businessType,
  businessName,
  contactName,
  onChange,
}) => (
  <GridRow>
    <Column
      md={
        businessType.show && contactName.show
          ? 4
          : businessType.show || contactName.show
          ? 6
          : 12
      }
    >
      <TextField
        label={businessName.label}
        value={businessName.value}
        onChange={onChange(businessName.field)}
        required={businessName.required}
        errorMessage={businessName.errorMessage}
      />
    </Column>
    {businessType.show && (
      <Column
        md={
          businessType.show && contactName.show
            ? 4
            : businessType.show || contactName.show
            ? 6
            : 12
        }
      >
        <BusinessTypePicker
          label={businessType.label}
          required={businessType.required}
          errorMessage={businessType.errorMessage}
          options={businessType.options}
          selectedKey={businessType.value}
          onChange={onChange(businessType.field)}
        />
      </Column>
    )}
    {contactName.show && (
      <Column
        md={
          businessType.show && contactName.show
            ? 4
            : businessType.show || contactName.show
            ? 6
            : 12
        }
      >
        <TextField
          label={contactName.label}
          value={contactName.value}
          required={contactName.required}
          errorMessage={contactName.errorMessage}
          onChange={onChange(contactName.field)}
        />
      </Column>
    )}
  </GridRow>
);

BusinessEntity.propTypes = {
  businessName: PropTypes.object.isRequired,
  businessType: PropTypes.object.isRequired,
  contactName: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default BusinessEntity;
