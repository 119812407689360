import React from 'react';
import PropTypes from 'prop-types';
import { Panel, PanelType } from 'office-ui-fabric-react/lib/Panel';
import { PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import { Grid, GridRow, Column, DataLabel } from '../common';
import { formatCurrency, formatDate, sortByField } from '../../helpers/util';
import { getLabel } from '../../helpers/labelHelper';
import { sortDirections } from '../../helpers/constants';

const valueStyle = {
  textAlign: 'right',
};

const labelStyle = {
  fontWeight: 600,
};

const DepositDetailsPanel = ({
  isOpen,
  closePanel,
  item,
  createDepositTicket,
  session,
}) => {
  if (!item) {
    return null;
  }

  const onRenderFooterContent = () => (
    <div>
      <PrimaryButton
        text="Print Deposit Ticket"
        onClick={createDepositTicket}
      />
    </div>
  );

  return (
    <Panel
      isOpen={isOpen}
      onDismiss={closePanel}
      type={PanelType.smallFixedNear}
      isLightDismiss
      isFooterAtBottom
      className="deposit-details-panel"
      onRenderFooterContent={onRenderFooterContent}
    >
      <h3>Deposit Details</h3>
      <Grid>
        <GridRow>
          <Column>
            <DataLabel
              label="Deposit Name"
              value={item.name}
              valueStyle={valueStyle}
              labelStyle={labelStyle}
            />
          </Column>
        </GridRow>
        <GridRow>
          <Column>
            <DataLabel
              label="Deposit Date"
              value={formatDate(item.depositDate, 'MMM D, YYYY')}
              valueStyle={valueStyle}
              labelStyle={labelStyle}
            />
          </Column>
        </GridRow>
        <GridRow>
          <Column>
            <DataLabel
              label="Total Amount"
              value={formatCurrency(item.amount)}
              valueStyle={valueStyle}
              labelStyle={labelStyle}
            />
          </Column>
        </GridRow>
        {item.contributions.length > 0 && (
          <GridRow>
            <h3>{getLabel('Contributions', session)}</h3>
            <div className="details-wrapper">
              <div
                style={{
                  position: 'absolute',
                  paddingRight: 16,
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                }}
              >
                {item.contributions
                  .sort(sortByField('displayName', sortDirections.ASC))
                  .map(c => (
                    <Column key={c._id}>
                      <div
                        style={{
                          borderBottom: '1px solid #919191',
                          paddingBottom: 8,
                        }}
                      >
                        <DataLabel
                          label="Contributor"
                          value={c.displayName}
                          valueStyle={valueStyle}
                          labelStyle={labelStyle}
                        />
                        <DataLabel
                          label="Amount"
                          value={formatCurrency(c.amount)}
                          valueStyle={valueStyle}
                          labelStyle={labelStyle}
                        />
                        <DataLabel
                          label="Payment Type"
                          value={`${c.paymentType}${c.checkNumber ? ' - ' + c.checkNumber : ''}`}
                          valueStyle={valueStyle}
                          labelStyle={labelStyle}
                        />
                        <DataLabel
                          label="Date"
                          value={formatDate(c.receivedDate)}
                          valueStyle={valueStyle}
                          labelStyle={labelStyle}
                        />
                      </div>
                    </Column>
                  ))}
              </div>
            </div>
          </GridRow>
        )}
        {item.expenditures.length > 0 && (
          <GridRow>
            <h3>{getLabel('Expenditures', session)}</h3>
            <div className="details-wrapper">
              <div
                style={{
                  position: 'absolute',
                  paddingRight: 16,
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                }}
              >
                {item.expenditures
                  .sort(sortByField('displayName', sortDirections.ASC))
                  .map(c => (
                    <Column key={c._id}>
                      <div
                        style={{
                          borderBottom: '1px solid #919191',
                          paddingBottom: 8,
                        }}
                      >
                        <DataLabel
                          label="Recipient"
                          value={c.displayName}
                          valueStyle={valueStyle}
                          labelStyle={labelStyle}
                        />
                        <DataLabel
                          label="Amount"
                          value={formatCurrency(c.amount)}
                          valueStyle={valueStyle}
                          labelStyle={labelStyle}
                        />
                        <DataLabel
                          label="Payment Type"
                          value={`${c.paymentType}${c.checkNumber ? ' - ' + c.checkNumber : ''
                            }`}
                          valueStyle={valueStyle}
                          labelStyle={labelStyle}
                        />
                        <DataLabel
                          label="Date"
                          value={formatDate(c.expenditureDate)}
                          valueStyle={valueStyle}
                          labelStyle={labelStyle}
                        />
                      </div>
                    </Column>
                  ))}
              </div>
            </div>
          </GridRow>
        )}
        {item.loans.length > 0 && (
          <GridRow>
            <h3>Loans</h3>
            <div className="details-wrapper">
              <div
                style={{
                  position: 'absolute',
                  paddingRight: 16,
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                }}
              >
                {item.loans
                  .sort(sortByField('displayName', sortDirections.ASC))
                  .map(c => (
                    <Column key={c._id}>
                      <div
                        style={{
                          borderBottom: '1px solid #919191',
                          paddingBottom: 8,
                        }}
                      >
                        <DataLabel
                          label="Lender"
                          value={c.displayName}
                          valueStyle={valueStyle}
                          labelStyle={labelStyle}
                        />
                        <DataLabel
                          label="Amount"
                          value={formatCurrency(c.amount)}
                          valueStyle={valueStyle}
                          labelStyle={labelStyle}
                        />
                        <DataLabel
                          label="Payment Type"
                          value={`${c.paymentType}${c.checkNumber ? ' - ' + c.checkNumber : ''}`}
                          valueStyle={valueStyle}
                          labelStyle={labelStyle}
                        />
                        <DataLabel
                          label="Date"
                          value={formatDate(c.receivedDate)}
                          valueStyle={valueStyle}
                          labelStyle={labelStyle}
                        />
                      </div>
                    </Column>
                  ))}
              </div>
            </div>
          </GridRow>
        )}
      </Grid>
    </Panel>
  );
};

DepositDetailsPanel.propTypes = {
  isOpen: PropTypes.bool,
  closePanel: PropTypes.func.isRequired,
  item: PropTypes.object,
  createDepositTicket: PropTypes.func.isRequired,
  session: PropTypes.object,
};

DepositDetailsPanel.defaultProps = {
  isOpen: false,
  item: {},
};

export default DepositDetailsPanel;
