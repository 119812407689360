import { types } from '../actions/messagingActions';
import { toastTypes, toastPositions } from '../helpers/constants';
import { createReducer } from './createReducer';

export const initialState = {
  toastType: toastTypes.INFO,
  message: null,
  position: toastPositions.TOP_RIGHT,
};

const actionMap = {
  [types.SET_TOAST]: (state, action) => {
    return {
      ...state,
      toastType: action.data.toastType || toastTypes.INFO,
      message: action.data.message,
      position: action.data.position || toastPositions.TOP_RIGHT,
    };
  },
  [types.CLEAR_TOAST]: () => initialState,
};

export const messagingReducer = createReducer(initialState, actionMap);
