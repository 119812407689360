import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { DefaultButton, IconButton } from 'office-ui-fabric-react/lib/Button';
import { Link } from 'office-ui-fabric-react/lib/Link';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { Toggle } from 'office-ui-fabric-react/lib/Toggle';
import { Grid, GridRow, Column, MoneyField } from '../../components/common';
import { YearPicker, ElectionCyclePicker } from '../../components/Pickers';
import { formatCurrency } from '../../helpers/util';
import { cleanUpMoneyEntry } from '../../helpers/currencyHelper';
import './EditInitialIndebtednessDetails.css';

export default class EditInitialIndebtednessDetails extends Component {
  static propTypes = {
    campaign: PropTypes.object,
    actions: PropTypes.object.isRequired,
    indebtednessDetailsExpanded: PropTypes.bool,
    formSection: PropTypes.number,
  };

  static defaultProps = {
    campaign: {},
    indebtednessDetailsExpanded: false,
    formSection: null,
  };

  state = {
    electionYear: 0,
    electionYearError: '',
    electionCycle: 0,
    electionCycleError: '',
    periodOpeningDebtAmount: '',
  };

  clearFields = () => {
    this.setState(() => ({
      electionYear: 0,
      electionCycle: 0,
      electionYearError: '',
      electionCycleError: '',
      periodOpeningDebtAmount: '',
      periodOpeningDebtAmountError: '',
    }));
  };

  handleChange = fieldName => (e, value) => {
    this.setState({
      [fieldName]: value.key !== undefined ? value.key : value,
    });
  };

  addDebt = () => {
    const { state } = this;
    const openingDebtAmount = cleanUpMoneyEntry(state.periodOpeningDebtAmount);
    if (
      state.electionYear !== 0 &&
      state.electionCycle !== 0 &&
      openingDebtAmount > 0
    ) {
      const payload = {
        electionYear: state.electionYear,
        electionCycle: state.electionCycle,
        periodOpeningDebtAmount: openingDebtAmount,
      };
      this.props.actions.addDebt(payload);
      this.clearFields();
    } else {
      this.setState(state => ({
        electionYearError:
          state.electionYear === 0 ? 'Election year is required' : '',
        electionCycleError:
          state.electionCycle === 0 ? 'Election type is required' : '',
        periodOpeningDebtAmountError:
          state.periodOpeningDebtAmount <= 0
            ? 'Please enter a value greater than 0'
            : '',
      }));
    }
  };

  render() {
    const {
      props: { actions, campaign, indebtednessDetailsExpanded },
      state: {
        electionYear,
        electionYearError,
        electionCycle,
        electionCycleError,
        periodOpeningDebtAmount,
        periodOpeningDebtAmountError,
      },
    } = this;

    return (
      <div
        className="EditInitialIndebtednessDetails depth-1"
        name="indebtedness-details"
      >
        <h3>
          Initial Indebtedness{' '}
          <small>
            {' '}
            (
            <Link onClick={actions.toggleExpandIndebtednessDetails}>
              {indebtednessDetailsExpanded ? 'Collapse' : 'Expand'}
            </Link>
            )
          </small>
        </h3>
        <div
          className={`edit-indebtedness-details-content${
            indebtednessDetailsExpanded ? ' expanded' : ''
          }`}
        >
          <Grid>
            <GridRow>
              <Column>
                <Toggle
                  label="This campaign has prior indebtedness"
                  onText="Yes"
                  offText="No"
                  checked={campaign.hasIndebtedness}
                  onChange={actions.toggleHasIndebtedness}
                />
              </Column>
            </GridRow>
            {(campaign.hasIndebtedness || campaign.debts.length > 0) && (
              <Fragment>
                <GridRow>
                  <Column lg={5} classNames="add-debt">
                    <Grid>
                      <GridRow>
                        <Column>
                          <YearPicker
                            label="Election Year"
                            value={electionYear}
                            errorMessage={electionYearError}
                            onChange={this.handleChange('electionYear')}
                          />
                        </Column>
                      </GridRow>
                      <GridRow>
                        <Column>
                          <ElectionCyclePicker
                            label="Election"
                            value={electionCycle}
                            errorMessage={electionCycleError}
                            onChange={this.handleChange('electionCycle')}
                          />
                        </Column>
                      </GridRow>
                      <GridRow>
                        <Column>
                          <MoneyField
                            value={periodOpeningDebtAmount}
                            label="Opening Debt Amount"
                            onChange={this.handleChange(
                              'periodOpeningDebtAmount',
                            )}
                            errorMessage={periodOpeningDebtAmountError}
                          />
                        </Column>
                      </GridRow>
                      <GridRow>
                        <Column classNames="ms-textAlignCenter debt-actions">
                          <DefaultButton
                            onClick={this.clearFields}
                            text="Cancel"
                            className="cancel-btn"
                          />
                          <DefaultButton
                            primary
                            text="Add"
                            onClick={this.addDebt}
                          />
                        </Column>
                      </GridRow>
                    </Grid>
                  </Column>
                  <Column lg={7} classNames="current-debts">
                    <h3>Current Debts</h3>
                    <Grid>
                      {campaign.debts
                        .filter(d => !d.removed)
                        .map((d, index) => (
                          <GridRow key={index}>
                            <Column classNames="debt-item">
                              <Icon
                                iconName="FileInvoiceDollar"
                                style={{ fontSize: 18 }}
                              />
                              <div>
                                <h4>{`${d.electionCycle.text ||
                                  d.electionCycle} - ${d.electionYear.text ||
                                  d.electionYear}`}</h4>
                                <p>
                                  {formatCurrency(d.periodOpeningDebtAmount)}
                                </p>
                              </div>
                              <div>
                                <IconButton
                                  onClick={() =>
                                    this.props.actions.removeDebt(d._id)
                                  }
                                  iconProps={{
                                    iconName: 'Times',
                                    styles: {
                                      root: {
                                        fontSize: 18,
                                      },
                                    },
                                  }}
                                />
                              </div>
                            </Column>
                          </GridRow>
                        ))}
                    </Grid>
                  </Column>
                </GridRow>
              </Fragment>
            )}
          </Grid>
        </div>
        {indebtednessDetailsExpanded && (
          <div className="ms-textAlignRight">
            <DefaultButton
              text="Back"
              onClick={() => actions.previousStep(5)}
            />
          </div>
        )}
      </div>
    );
  }
}
