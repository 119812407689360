export const types = {
  GET_UNPROCESSED: 'GET_UNPROCESSED',
  GET_UNPROCESSED_PROCESSING: 'GET_UNPROCESSED_PROCESSING',
  GET_UNPROCESSED_SUCCESS: 'GET_UNPROCESSED_SUCCESS',
  GET_UNPROCESSED_FAILURE: 'GET_UNPROCESSED_FAILURE',
  GET_RECORD: 'GET_RECORD',
  GET_RECORD_PROCESSING: 'GET_RECORD_PROCESSING',
  GET_RECORD_SUCCESS: 'GET_RECORD_SUCCESS',
  GET_RECORD_FAILURE: 'GET_RECORD_FAILURE',
  UPDATE_ANEDOT_CONTRIBUTION: 'UPDATE_ANEDOT_CONTRIBUTION',
  UPDATE_ANEDOT_CONTRIBUTION_PROCESSING:
    'UPDATE_ANEDOT_CONTRIBUTION_PROCESSING',
  UPDATE_ANEDOT_CONTRIBUTION_SUCCESS: 'UPDATE_ANEDOT_CONTRIBUTION_SUCCESS',
  UPDATE_ANEDOT_CONTRIBUTION_FAILURE: 'UPDATE_ANEDOT_CONTRIBUTION_FAILURE',
  CLEAR_UPDATE_STATUS: 'CLEAR_UPDATE_STATUS',
  CLEAR_UNPROCESSED: 'CLEAR_UNPROCESSED',
  DO_NOT_IMPORT: 'DO_NOT_IMPORT',
  GET_NEXT_UNPROCESSED: 'GET_NEXT_UNPROCESSED',
  GET_PREVIOUS_UNPROCESSED: 'GET_PREVIOUS_UNPROCESSED',
  GET_AGGREGATE_AMOUNT: 'GET_AGGREGATE_AMOUNT',
  GET_AGGREGATE_AMOUNT_SUCCESS: 'GET_AGGREGATE_AMOUNT_SUCCESS',
};
