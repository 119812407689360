import React, { useReducer, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  FocusTrapCallout,
  DefaultButton,
  PrimaryButton,
  FocusZone,
  Stack,
  Dropdown,
  getTheme,
  mergeStyleSets,
  FontWeights,
  TextField,
} from 'office-ui-fabric-react';
import { useSelector } from 'react-redux';
import { CaretDown } from '../icons';
import { getElectionCycleList } from '../../selectors';
import {
  FecElectionCycles,
} from '../../helpers/constants';
import { getOptions as getContactOptions } from '../../helpers/contactHelper';
import { listOptions, filterExpressions } from '../../helpers/listsHelper';
import {
  initialState,
  actions,
  listFilterCalloutReducer,
} from './listFilterCalloutReducer';
import { DatePicker } from '../Pickers';
import { getLabel } from '../../helpers/labelHelper';

const theme = getTheme();
const styles = mergeStyleSets({
  callout: {
    minWidth: 350,
  },
  header: {
    padding: '18px 24px 12px',
  },
  title: [
    theme.fonts.xLarge,
    {
      margin: 0,
      fontWeight: FontWeights.semilight,
    },
  ],
  inner: {
    height: '100%',
    padding: '0 24px 20px',
    width: '100%',
  },
  field: {
    width: 300,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '0 24px 24px',
  },
});

const ListFilterCallout = ({ onDismiss, onAddFilter, session }) => {
  const [state, localDispatch] = useReducer(
    listFilterCalloutReducer,
    initialState,
  );

  const electionCycles = useSelector(getElectionCycleList);

  const onSave = () => {
    const { column, expression, value } = state;
    if (
      column !== 0 &&
      expression !== 0 &&
      (value.length > 0 || value !== undefined)
    ) {
      onAddFilter({ column, expression, value });
      localDispatch({
        type: actions.CLEAR_FILTER,
      });
    }
  };

  const columnOptions = useMemo(() => listOptions);

  const expressionOptions = useMemo(() => filterExpressions);

  const getValidExpressions = () =>
    expressionOptions.reduce((acc, option) => {
      if (option.data?.validFor?.includes(state.column)) {
        acc = [...acc, option];
      }
      return acc;
    }, []);

  const handleChange = fieldName => (e, option) => {
    const value = option.key !== undefined ? option.key : option;
    localDispatch({
      type: actions.HANDLE_CHANGE,
      data: {
        fieldName,
        value,
      },
    });
  };

  const listMap = {
    electionCycle: () =>
      session.isFederal()
        ? FecElectionCycles.map(ec => ({ key: ec, text: ec }))
        : electionCycles.map(ec => ({
            key: ec.text || ec,
            text: ec.text || ec,
          })),
    entityType: () => getContactOptions(session),
    transactionType: () =>
      ['Contribution', 'Expenditure', 'Loan'].map(tt => ({
        key: tt.toLowerCase(),
        text: getLabel(tt, session),
      })),
    paymentType: () =>
      [
        'Cash',
        'Check',
        'Credit Card',
        'Debit Card',
        'Wire Transfer',
        'ACH',
        'Other',
      ].map(pt => ({
        key: pt,
        text: pt,
      })),
    transactionKind: () => [
      {
        key: 'In-Kind',
        text: 'In Kind',
      },
      ...[
        'Common Source',
        'Credit Card',
        'Credit Received on Loan',
        'Deferred Payment',
        'Loan Repayment',
        'Monetary',
        'Payment on Deferred Expense',
        'Refund',
        'Reimbursement',
      ].map(tk => ({
        key: tk,
        text: tk,
      })),
    ],
  };

  const getMultiSelectOptions = () => {
    if (listMap[state.column]) {
      return listMap[state.column]();
    }

    return [{ key: 0, text: 'Select' }];
  };

  const handleChangeMutliSelectValues = (e, item) => {
    const { value } = state;
    let newValues = [];
    if (item.selected) {
      newValues = [...(Array.isArray(value) ? value : []), item.key];
    } else {
      newValues = Array.isArray(value)
        ? value.filter(val => val !== item.key)
        : [];
    }

    localDispatch({
      type: actions.HANDLE_CHANGE,
      data: {
        fieldName: 'value',
        value: newValues,
      },
    });
  };

  const handleChangeDate = date => {
    localDispatch({
      type: actions.HANDLE_CHANGE,
      data: {
        fieldName: 'value',
        value: date,
      },
    });
  };

  const renderValueField = () => {
    if (
      ['isOneOf', 'isNotOneOf'].includes(state.expression) &&
      [
        'electionCycle',
        'entityType',
        'transactionType',
        'transactionKind',
        'paymentType',
        'tags',
      ].includes(state.column)
    ) {
      if (state.column === 'tags') {
        return (
          <TextField
            label="Value"
            className={styles.field}
            value={state.value}
            onChange={handleChange('value')}
            placeholder="Separate Tags with a comma"
          />
        );
      }

      const options = getMultiSelectOptions();
      return (
        <Dropdown
          className={styles.field}
          label="Values"
          multiSelect
          selectedKeys={state.value}
          options={options}
          onChange={handleChangeMutliSelectValues}
          onRenderCaretDown={() => <CaretDown />}
          dropdownWidth={300}
        />
      );
    }

    if (
      [
        'equals',
        'notEquals',
        'isGreaterThan',
        'isGreaterThanOrEqual',
        'isLessThan',
        'isLessThanOrEqual',
        'contains',
      ].includes(state.expression)
    ) {
      if (state.column === 'transactionDate') {
        return (
          <DatePicker
            className={styles.field}
            label="Value"
            value={state.value}
            onChange={handleChangeDate}
          />
        );
      }

      if (state.column === 'tags') {
        return (
          <TextField
            label="Value"
            className={styles.field}
            value={state.value}
            onChange={handleChange('value')}
            placeholder="Separate Tags with a comma"
          />
        );
      }

      if (listMap[state.column]) {
        return (
          <Dropdown
            label="Value"
            selectedKey={state.value}
            onChange={handleChange('value')}
            className={styles.field}
            options={listMap[state.column]()}
            onRenderCaretDown={() => <CaretDown />}
          />
        );
      }

      return (
        <TextField
          className={styles.field}
          label="Value"
          value={state.value}
          onChange={handleChange('value')}
        />
      );
    }
    return null;
  };

  return (
    <FocusTrapCallout
      target={document.querySelectorAll('.AddFilterButton')[0]}
      onDismiss={onDismiss}
      className={styles.callout}
    >
      <FocusZone>
        <div className={styles.header}>
          <p className={styles.title}>Add Filter</p>
        </div>
        <div className={styles.inner}>
          <Stack vertical childrenGap={8}>
            <Dropdown
              className={styles.field}
              label="Column"
              onChange={handleChange('column')}
              options={columnOptions}
              selectedKey={state.column}
              onRenderCaretDown={() => <CaretDown />}
            />
            <Dropdown
              className={styles.field}
              label="Operation"
              onChange={handleChange('expression')}
              options={getValidExpressions()}
              selectedKey={state.expression}
              onRenderCaretDown={() => <CaretDown />}
              disabled={state.column === 0}
            />
            {renderValueField()}
          </Stack>
        </div>
        <Stack className={styles.buttons} horizontal childrenGap={8}>
          <DefaultButton text="Cancel" onClick={onDismiss} />
          <PrimaryButton text="Save" onClick={onSave} />
        </Stack>
      </FocusZone>
    </FocusTrapCallout>
  );
};

ListFilterCallout.propTypes = {
  onDismiss: PropTypes.func.isRequired,
  onAddFilter: PropTypes.func.isRequired,
  session: PropTypes.object.isRequired,
};

export default ListFilterCallout;
