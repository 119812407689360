import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import AppRoutes from './AppRoutes';
import './App.css';

const isUnauthorized = status => status === 401;
const isSessionCheck = config =>
  config.method === 'get' && config.url.includes('/session');
const isFailedLogin = config =>
  config.method === 'post' && config.url.toLowerCase().includes('/signin');
const userIsOnLoginScreen = () =>
  window.location.pathname === '/' || window.location.pathname === '/login';

axios.interceptors.response.use(
  response => response,
  error => {
    const {
      response: { status, config },
    } = error;
    const unauthorized = isUnauthorized(status);
    const checkingSession = isSessionCheck(config);
    const failedLoginAttempt = isFailedLogin(config);
    const isLoginScreen = userIsOnLoginScreen();
    if (
      unauthorized &&
      ((!checkingSession && !failedLoginAttempt) ||
        (checkingSession && !isLoginScreen))
    ) {
      // eslint-disable-next-line
      console.log(`
      user missing authentication:
      checkingSession: ${checkingSession.toString()},
      failedLoginAttempt: ${failedLoginAttempt.toString()},
      isOnLoginScreen: ${isLoginScreen.toString()},
    `);
      const currentPath = window.location.pathname;
      window.location = `/?returnUrl=${currentPath}`;
    } else {
      return Promise.reject(error);
    }
  },
);

class App extends Component {
  static propTypes = {
    buildNumber: PropTypes.string,
  };

  static defaultProps = {
    buildNumber: '1.0.6792',
  };

  render() {
    const { props } = this;
    return (
      <div className="App">
        <AppRoutes />
        <div className="footer">
          <span className="info">
            <div className="build-number">{props.buildNumber}</div>
            <div>
              &copy;{' '}
              {`${new Date().getFullYear()} FrontRunner LLC - Roswell, Georgia`}
            </div>
          </span>
        </div>
      </div>
    );
  }
}

export default App;
