export const types = {
  GET_CURRENT_REPORT: 'GET_CURRENT_REPORT',
  GET_CURRENT_REPORT_PROCESSING: 'GET_CURRENT_REPORT_PROCESSING',
  GET_CURRENT_REPORT_SUCCESS: 'GET_CURRENT_REPORT_SUCCESS',
  RESET_REPORT: 'RESET_REPORT',
  GET_CONTRIBUTIONS_FOR_REPORT_SUCCESS: 'GET_CONTRIBUTIONS_FOR_REPORT_SUCCESS',
  GET_EXPENDITURES_FOR_REPORT_SUCCESS: 'GET_EXPENDITURES_FOR_REPORT_SUCCESS',
  GET_LOANS_FOR_REPORT_SUCCESS: 'GET_LOANS_FOR_REPORT_SUCCESS',
  UPDATE_REPORT: 'UPDATE_REPORT',
  UPDATE_FEDERAL_REPORT: 'UPDATE_FEDERAL_REPORT',
  GET_XML_REPORT: 'GET_XML_REPORT',
  GET_XML_REPORT_SUCCESS: 'GET_XML_REPORT_SUCCESS',
  MARK_AS_FILED: 'MARK_AS_FILED',
  MARK_TBD_AS_FILED: 'MARK_TBD_AS_FILED',
  SUBMIT_TBD_REPORT: 'SUBMIT_TBD_REPORT',
  SUBMIT_LOCAL_REPORT: 'SUBMIT_LOCAL_REPORT',
  SUBMIT_FEDERAL_REPORT: 'SUBMIT_FEDERAL_REPORT',
  SUBMIT_FEDERAL_REPORT_PROCESSING: 'SUBMIT_FEDERAL_REPORT_PROCESSING',
  SUBMIT_FEDERAL_REPORT_SUCCESS: 'SUBMIT_FEDERAL_REPORT_SUCCESS',
  SUBMIT_FEDERAL_REPORT_FAILURE: 'SUBMIT_FEDERAL_REPORT_FAILURE',
  FEDERAL_REPORT_JOB_PROCESSING: 'FEDERAL_REPORT_JOB_PROCESSING',
  FEDERAL_REPORT_JOB_SUCCESS: 'FEDERAL_REPORT_JOB_SUCCESS',
  FEDERAL_REPORT_JOB_FAILURE: 'FEDERAL_REPORT_JOB_FAILURE',
  CREATE_AMENDMENT: 'CREATE_AMENDMENT',
  CREATE_AMENDMENT_FEDERAL: 'CREATE_AMENDMENT_FEDERAL',
  REQUEST_FEC_WEB_SERVICE_DRAFT_REPORT: 'REQUEST_FEC_WEB_SERVICE_DRAFT_REPORT',
  REQUEST_FEC_WEB_SERVICE_DRAFT_REPORT_PROCESSING: 'REQUEST_FEC_WEB_SERVICE_DRAFT_REPORT_PROCESSING',
  REQUEST_FEC_WEB_SERVICE_DRAFT_REPORT_SUCCESS: 'REQUEST_FEC_WEB_SERVICE_DRAFT_REPORT_SUCCESS',
  REQUEST_FEC_WEB_SERVICE_DRAFT_REPORT_FAILURE: 'REQUEST_FEC_WEB_SERVICE_DRAFT_REPORT_FAILURE',
  REQUEST_GA_WEB_SERVICE_DRAFT_REPORT: 'REQUEST_GA_WEB_SERVICE_DRAFT_REPORT',
  REQUEST_GA_WEB_SERVICE_DRAFT_REPORT_PROCESSING: 'REQUEST_GA_WEB_SERVICE_DRAFT_REPORT_PROCESSING',
  REQUEST_GA_WEB_SERVICE_DRAFT_REPORT_SUCCESS: 'REQUEST_GA_WEB_SERVICE_DRAFT_REPORT_SUCCESS',
  REQUEST_GA_WEB_SERVICE_DRAFT_REPORT_FAILURE: 'REQUEST_GA_WEB_SERVICE_DRAFT_REPORT_FAILURE',
  VERIFY_FEDERAL_REPORT_WEB_SERVICE: 'VERIFY_FEDERAL_REPORT_WEB_SERVICE',
  VERIFY_FEDERAL_REPORT_WEB_SERVICE_PROCESSING: 'VERIFY_FEDERAL_REPORT_WEB_SERVICE_PROCESSING',
  VERIFY_FEDERAL_REPORT_WEB_SERVICE_SUCCESS: 'VERIFY_FEDERAL_REPORT_WEB_SERVICE_SUCCESS',
  VERIFY_FEDERAL_REPORT_WEB_SERVICE_FAILURE: 'VERIFY_FEDERAL_REPORT_WEB_SERVICE_FAILURE',
};

export const actions = {
  getReport(reportId) {
    return { type: types.GET_CURRENT_REPORT, data: { reportId } };
  },

  resetReport() {
    return { type: types.RESET_REPORT };
  },

  updateReport(payload) {
    return { type: types.UPDATE_REPORT, payload };
  },

  updateFederalReport(payload) {
    return { type: types.UPDATE_FEDERAL_REPORT, payload };
  },

  getXmlReport(reportId) {
    return { type: types.GET_XML_REPORT, reportId };
  },

  verifyFederalReportWithWebService(reportId) {
    return { type: types.VERIFY_FEDERAL_REPORT_WEB_SERVICE, reportId };
  },

  submitFederalReport(reportId, submitFileWithWarnings) {
    return {
      type: types.SUBMIT_FEDERAL_REPORT,
      data: {
        reportId,
        submitFileWithWarnings,
      },
    };
  },

  requestFECWebServiceDraftReport(reportId) {
    return { type: types.REQUEST_FEC_WEB_SERVICE_DRAFT_REPORT, reportId };
  },

  requestGAWebServiceDraftReport(reportId) {
    return { type: types.REQUEST_GA_WEB_SERVICE_DRAFT_REPORT, reportId };
  },

  submitTbdReport(reportId) {
    return { type: types.SUBMIT_TBD_REPORT, reportId };
  },

  submitLocalReport(reportId) {
    return { type: types.SUBMIT_LOCAL_REPORT, reportId };
  },

  markAsFiled(reportId) {
    return { type: types.MARK_AS_FILED, data: { reportId } };
  },

  markTBDAsFiled(reportId) {
    return { type: types.MARK_TBD_AS_FILED, data: { reportId } };
  },

  amendReport(reportId) {
    return { type: types.CREATE_AMENDMENT, data: { reportId } };
  },

  amendReportFederal(reportId) {
    return { type: types.CREATE_AMENDMENT_FEDERAL, data: { reportId } };
  },
};
