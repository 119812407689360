import { Spinner, SpinnerSize } from 'office-ui-fabric-react';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { actions as importWizardActions } from '../../../actions/importWizardActions';
import { BackButton } from '../../../components/common';
import Steps from '../../../components/Steps';
import {
  ImportDataType,
  ImportProgressStep,
  ImportStatus,
  statuses,
} from '../../../helpers/constants';
import { getImportWizard } from '../../../selectors';
import ColumnMappingStep from './ColumnMappingStep';
import DataTypeStep from './DataTypeStep';
import QuestionsStep from './QuestionsStep';
import UploadStep from './UploadStep';
import { steps } from './utils';

import FileFormatStep from './FileFormatStep';
import './index.css';

const ImportWizard = ({ history, match }) => {
  const importWizard = useSelector(getImportWizard);
  const reduxDispatch = useDispatch();
  const { id } = match.params;
  const isLoading =
    id && importWizard.getEditedImportDataStatus !== statuses.SUCCESS;

  useEffect(() => {
    if (id) {
      reduxDispatch(
        importWizardActions.getEditedImportData(
          id,
          ImportDataType.Contribution,
        ),
      );
    }
    return () => {
      reduxDispatch(importWizardActions.clearImportWizardData());
    };
  }, []);

  const handleSaveDataType = dataType => {
    reduxDispatch(importWizardActions.saveImportDataType(dataType));
  };

  const handleUploadFile = file => {
    reduxDispatch(importWizardActions.uploadImportFile(file));
  };

  const handleSaveFileFormat = fileFormat => {
    reduxDispatch(importWizardActions.saveImportFileFormat(fileFormat));
  };

  const handleSaveConfig = config => {
    reduxDispatch(importWizardActions.saveImportConfig(importWizard.fileId, config));
  };

  const handleMapImportColumns = columns => {
    reduxDispatch(
      importWizardActions.mapImportColumns(importWizard.fileId, columns),
    );
  };

  const handleCancel = () => {
    history.goBack();
  };

  const handleChangeStep = step => () => {
    reduxDispatch(importWizardActions.changeImportWizardStep(step));
  };

  const getStepComponent = step => {
    switch (step) {
      case ImportProgressStep.DataType:
        return (
          <DataTypeStep
            dataType={importWizard.dataType}
            onSaveDataType={handleSaveDataType}
            onCancel={handleCancel}
          />
        );
      case ImportProgressStep.Upload:
        return (
          <UploadStep
            fileName={importWizard.fileName}
            fileId={importWizard.fileId}
            uploadImportFileStatus={importWizard.uploadImportFileStatus}
            onUploadFile={handleUploadFile}
            onBack={handleChangeStep(ImportProgressStep.DataType)}
            onNext={handleChangeStep(ImportProgressStep.FileFormat)}
          />
        );
      case ImportProgressStep.FileFormat:
        return (
          <FileFormatStep
            fileFormat={importWizard.config.fileFormat}
            dataType={importWizard.dataType}
            onNext={handleSaveFileFormat}
          />
        );
      case ImportProgressStep.Questions:
        return (
          <QuestionsStep
            dataType={importWizard.dataType}
            initialValues={importWizard.config}
            saveConfigStatus={importWizard.saveImportConfigStatus}
            getUploadResultStatus={importWizard.getUploadResultStatus}
            onSaveConfig={handleSaveConfig}
            onBack={handleChangeStep(ImportProgressStep.FileFormat)}
          />
        );
      case ImportProgressStep.ColumnMapping:
        return (
          <ColumnMappingStep
            uploadResult={importWizard.uploadResult}
            mapImportColumnsStatus={importWizard.mapImportColumnsStatus}
            onMapImportColumns={handleMapImportColumns}
            onBack={
              importWizard.status === ImportStatus.Created
                ? handleChangeStep(ImportProgressStep.Questions)
                : undefined
            }
          />
        );
      default:
        return null;
    }
  };

  const currentStepIndex = steps.find(
    ({ label }) => label === importWizard.step,
  ).index;

  return (
    <>
      <BackButton
        url="/filer/dev/importList"
        message="Cancel"
        history={history}
        pageTitle="Import File"
      />
      <div className="ImportWizard depth-1">
        {isLoading ? (
          <Spinner className="import-wizard-spinner" size={SpinnerSize.large} />
        ) : (
          <>
            <Steps steps={steps} currentStepIndex={currentStepIndex} />
            <div className="import-wizard-content">
              {getStepComponent(importWizard.step)}
            </div>
          </>
        )}
      </div>
    </>
  );
};

ImportWizard.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(ImportWizard);
