import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'office-ui-fabric-react/lib/Link';
import {
  DefaultButton,
  PrimaryButton,
} from 'office-ui-fabric-react/lib/Button';
import { Grid, GridRow, Column, MoneyField } from '../common';
import {
  getTotalDisbursements,
  getReceiptTotals,
  getSubTotals,
  getCashOnHandAtClosing,
} from '../../pages/Reports/fecReportHelper';

import {
  FECSection17,
  FECSection18,
  FECSection19a,
  FECSection19b,
  FECSection19c,
  FECSection20a,
  FECSection20b,
  FECSection20c,
  FECSection20d,
  FECSection21,
  FECSection23,
} from './Sections';

const styles = {
  sectionHeader: {
    borderBottom: '1px solid #919191',
    height: 50,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  sectionSeparator: {
    marginTop: 16,
    borderTop: '3px solid #919191',
  },
};

const FECDetailedSummaryDisbursements = ({
  toggleExpandSection,
  state,
  sectionName,
}) => {
  return (
    <div
      className="FECDetailedSummaryDisbursements fec-report-section depth-1"
      name={sectionName}
    >
      <h3>
        DETAILED SUMMARY OF DISBURSEMENTS{' '}
        <small>
          {' '}
          (
          <Link onClick={() => toggleExpandSection(sectionName, false)}>
            {state.expandableSections[sectionName] ? 'Collapse' : 'Expand'}
          </Link>
          )
        </small>
      </h3>
      <div
        className={`fec-summary-detailed-disbursements${
          state.expandableSections[sectionName] ? '-expanded' : '-collapsed'
        }`}
      >
        <Grid>
          <GridRow>
            <Column sm={4}>
              <p style={styles.sectionHeader}>
                <strong>II. DISBURSEMENTS</strong>
              </p>
            </Column>
            <Column sm={4}>
              <p style={styles.sectionHeader}>
                <strong>COLUMN A</strong>
                <strong>Total This Period</strong>
              </p>
            </Column>
            <Column sm={4}>
              <p style={styles.sectionHeader}>
                <strong>COLUMN B</strong>
                <strong>Election Cycle-to-Date</strong>
              </p>
            </Column>
          </GridRow>
          <FECSection17
            q17ColA={state.q17ColA}
            q17ColB={state.q17ColB}
          />
          <FECSection18
            q18ColA={state.q18ColA}
            q18ColB={state.q18ColB}
          />
          <GridRow style={{ borderTop: '1px solid #919191', paddingBottom: 6 }}>
            <Column sm={4}>
              <p>19. LOAN REPAYMENTS</p>
            </Column>
          </GridRow>
          <FECSection19a
            q19aColA={state.q19aColA}
            q19aColB={state.q19aColB}
          />
          <FECSection19b
            q19bColA={state.q19bColA}
            q19bColB={state.q19bColB}
          />

          <FECSection19c state={state} />
          <GridRow style={{ borderTop: '1px solid #919191', paddingBottom: 6 }}>
            <Column sm={4}>
              <p>20. REFUNDS OF CONTRIBUTIONS TO</p>
            </Column>
          </GridRow>
          <FECSection20a
            q20aColA={state.q20aColA}
            q20aColB={state.q20aColB}
          />
          <FECSection20b
            q20bColA={state.q20bColA}
            q20bColB={state.q20bColB}
          />
          <FECSection20c
            q20cColA={state.q20cColA}
            q20cColB={state.q20cColB}
          />
          <FECSection20d state={state} />
          <FECSection21
            q21ColA={state.q21ColA}
            q21ColB={state.q21ColB}
          />
          <GridRow style={{ borderTop: '1px solid #919191', paddingBottom: 6 }}>
            <Column sm={4}>
              <p>
                <strong>22. TOTAL DISBURSEMENTS</strong>
                <br />
                {'(add Lines 17, 18, 19(c), 20(d), and 21)'}
              </p>
            </Column>
            <Column sm={4} classNames="entry-row">
              <MoneyField
                disabled
                value={getTotalDisbursements('A', state) || 0}
              />
            </Column>
            <Column sm={4} classNames="entry-row">
              <MoneyField
                disabled
                value={getTotalDisbursements('B', state) || 0}
              />
            </Column>
          </GridRow>
          <GridRow style={styles.sectionSeparator}>
            <Column sm={8}>
              <p style={styles.sectionHeader}>
                <strong>III. CASH SUMMARY</strong>
              </p>
            </Column>
          </GridRow>
          <FECSection23 q23={state.q23} />
          <GridRow>
            <Column sm={8}>
              <p>24. TOTAL RECEIPTS THIS PERIOD{' (from Line 16, page 3)'}</p>
            </Column>
            <Column sm={4}>
              <MoneyField disabled value={getReceiptTotals('A', state) || 0} />
            </Column>
          </GridRow>
          <GridRow>
            <Column sm={8}>
              <p>25. SUBTOTAL{' (add Line 23 and Line 24)'}</p>
            </Column>
            <Column sm={4}>
              <MoneyField disabled value={getSubTotals('A', state) || 0} />
            </Column>
          </GridRow>
          <GridRow>
            <Column sm={8}>
              <p>26. TOTAL DISBURSEMENTS THIS PERIOD{' (from Line 22)'}</p>
            </Column>
            <Column sm={4}>
              <MoneyField
                disabled
                value={getTotalDisbursements('A', state) || 0}
              />
            </Column>
          </GridRow>
          <GridRow>
            <Column sm={8}>
              <p>
                27. CASH ON HAND AT CLOSE OF REPORTING PERIOD
                {' (subtract Line 26 from Line 25)'}
              </p>
            </Column>
            <Column sm={4}>
              <MoneyField
                disabled
                value={getCashOnHandAtClosing('A', state) || 0}
              />
            </Column>
          </GridRow>
        </Grid>
        <div className="section-actions">
          <DefaultButton
            text="Previous Section"
            style={{ marginRight: 16 }}
            onClick={() =>
              toggleExpandSection('summaryDetailedReceipts', false)
            }
          />
          <PrimaryButton
            text="Next Section"
            onClick={() => toggleExpandSection('itemizedReceipts', false)}
          />
        </div>
      </div>
    </div>
  );
};

FECDetailedSummaryDisbursements.propTypes = {
  toggleExpandSection: PropTypes.func.isRequired,
  state: PropTypes.object.isRequired,
  sectionName: PropTypes.string.isRequired,
};

export default FECDetailedSummaryDisbursements;
