import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Link } from 'office-ui-fabric-react/lib/Link';
import {
  ActionButton,
  DefaultButton,
  PrimaryButton,
} from 'office-ui-fabric-react/lib/Button';
import { sortDirections } from '../../helpers/constants';
import { useIntersect } from '../../hooks/useIntersect';
import { ContributionsList } from '../FecContributionsList';
import { isForm6Form24Report as determineIfForm6_24 } from '../../helpers/reportHelper';
import { Grid, GridRow, Column } from '../common';
import { getNextPageStatusContributions } from '../../selectors';
import ScrollObserver from '../ScrollObserver';

const ItemizedReceipts = ({
  sectionName,
  state,
  toggleExpandSection,
  addNewReceipt,
  actions,
  sortField,
  sortDirection,
  filterText,
  session,
  items,
}) => {
  const getNextPageStatus = useSelector(getNextPageStatusContributions);

  const [scrollRef, entry] = useIntersect({
    rootMargin: '80px',
    threshold: 0.3,
  });

  useEffect(() => {
    if (entry.isIntersecting) {
      actions.nextPage();
    }
  }, [entry]);

  const isForm6Form24Report = determineIfForm6_24(state.reportType);
  // Query already constrains date range to report, no need to check for that
  const filterFn = isForm6Form24Report ? e => {
    if (e.amount < 1000.0) {
      return false;
    }
    return e.isItemFiled !== true;
  } : null;

  const renderList = () => (
    <>
      <ContributionsList
        filterFn={filterFn}
        actions={actions}
        contributions={items}
        session={session}
        isHidden={state.expandableSections[sectionName] === false}
        sortDirection={sortDirection}
        sortField={sortField}
        filterText={filterText}
      />
      {/*
        (Randy) My intent was to put the scroll observer in one level down in
        Contribution list so all events originated from index, but it kept throwing
        errors around the intersect states in ContributionsList directly. Moved up
        to squelch errors.
        */}
      {!isForm6Form24Report &&
      <ScrollObserver
        getNextPageStatus={getNextPageStatus}
        scrollRef={scrollRef}
      />
      }
    </>
  );

  return (
    <div
      className="ItemizedReceipts fec-report-section depth-1"
      name={sectionName}
    >
      <h3>
        RECEIPTS{' '}
        <small>
          {' '}
          (
          <Link onClick={() => toggleExpandSection(sectionName, false)}>
            {state.expandableSections[sectionName] === true
              ? 'Collapse'
              : 'Expand'}
          </Link>
          )
        </small>
      </h3>
      <div
        className={`fec-itemizedReceipts${
          state.expandableSections[sectionName] === true
            ? '-expanded'
            : '-collapsed'
        }`}
      >
        <Grid>
          <GridRow>
            <Column classNames="ms-textAlignRight">
              <ActionButton
                text="Add New Receipt"
                iconProps={{
                  iconName: 'PlusCircle',
                  styles: {
                    root: {
                      fontSize: '1.3em',
                    },
                  },
                }}
                onClick={addNewReceipt}
              />
            </Column>
          </GridRow>
          {renderList()}
        </Grid>
        <div className="section-actions">
          {!isForm6Form24Report &&
          <>
            <DefaultButton
              text="Previous Section"
              style={{ marginRight: 16 }}
              onClick={() =>
                toggleExpandSection('summaryDetailedDisbursements', false)
              }
            />
            <PrimaryButton
              text="Save & Continue"
              onClick={() => toggleExpandSection('itemizedDisbursements', true)}
            />
          </>
          }
        </div>
      </div>
    </div>
  );
};

ItemizedReceipts.propTypes = {
  sectionName: PropTypes.string.isRequired,
  state: PropTypes.object.isRequired,
  toggleExpandSection: PropTypes.func.isRequired,
  addNewReceipt: PropTypes.func.isRequired,
  actions: PropTypes.object.isRequired,
  session: PropTypes.object.isRequired,
  filterText: PropTypes.string,
  sortField: PropTypes.string,
  sortDirection: PropTypes.oneOf(Object.values(sortDirections)),
  items: PropTypes.array,
};

ItemizedReceipts.defaultProps = {
  items: [],
};

export default ItemizedReceipts;
