import { types } from '../actions/electionCycleActions';
import { createReducer } from './createReducer';

export const initialState = {
  electionCyclesError: null,
  cycles: [],
};

const actionMap = {
  [types.GET_ELECTION_CYCLES_SUCCESS]: (state, action) => {
    return {
      ...state,
      cycles: action.cycles,
    };
  },
  [types.GET_ELECTION_CYCLES_FAILURE]: (state, action) => {
    return {
      ...state,
      electionCyclesError: action.error,
    };
  },
};

export const electionCycleReducer = createReducer(initialState, actionMap);
