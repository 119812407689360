export const validate = (state, campaignRecordMaintainer, reportType) => {
  const errors = {};

  if (reportType === '') {
    if (state.filingStatus === 0) {
      errors.filingStatusError = 'Please choose a filing status';
    }
  }

  if (reportType === 'Termination') {
    if (campaignRecordMaintainer.firstName.length === 0) {
      errors.firstNameError = 'First name is required';
    }

    if (campaignRecordMaintainer.lastName.length === 0) {
      errors.lastNameError = 'Last name is required';
    }

    if (campaignRecordMaintainer.addressLine1.length === 0) {
      errors.addressLine1Error = 'Address 1 is required';
    }

    if (campaignRecordMaintainer.city.length === 0) {
      errors.cityError = 'City is required';
    }

    if (campaignRecordMaintainer.state === 0) {
      errors.stateError = 'State is required';
    }

    if (campaignRecordMaintainer.zipCode.length === 0) {
      errors.zipCodeError = 'Zip code is required';
    }

    if (campaignRecordMaintainer.phone1.length === 0) {
      errors.phone1Error = 'Phone is required';
    }

    if (campaignRecordMaintainer.email.length === 0) {
      errors.emailError = 'Email is required';
    }
  }

  if (state.electionDate === 0) {
    errors.electionDateError = 'Please choose an election date';
  }

  if (state.isGAStateFiling && !state?.report?.data?.EndDate) {
    errors.reportError = 'Please choose a report';
  }

  if (state.filingStatus !== 1 && state.previousReportId === 0 && reportType !== 'TBD') {
    errors.previousReportIdError = 'A previous report is necessary for this report type';
  }

  return errors;
};
