import { createReducer } from '../../reducers/createReducer';

export const initialState = {
  committeeFecId: '',
  spouseFirstName: '',
  spouseMiddleName: '',
  spouseLastName: '',
  children: [],
  _id: null,
  additionalAddresses: [],
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: 0,
  zipCode: '',
  county: '',
  phone1: '',
  phone2: '',
  email: '',
  additionalTelephoneNumbers: [],
  additionalEmailAddresses: [],
  businessName: '',
  businessType: 0,
  contactName: '',
  taxEin: '',
  committeeAffiliation: 0,
  commonSource: '',
  specific: '',
  type: 0,
  notes: [],
  originalHouseholdMembers: [],
  householdMembers: [],
  addressSearchSelection: [],
  showHouseholdPicker: false,
  salutation: 'none',
  firstName: '',
  middleName: '',
  lastName: '',
  suffix: '',
  nickName: '',
  occupation: '',
  employer: '',
  tags: [],
  selectedKey: 'details',
  newChild: {
    addChild: false,
    newChild: '',
  },
  newAddress: {
    addAddress: false,
    newAddressLine1: '',
    newAddressLine2: '',
    newCity: '',
    newState: 0,
    newZipCode: '',
    newCounty: '',
  },
  newPhone: {
    addPhone: false,
    newPhone: '',
  },
  newEmail: {
    addEmail: false,
    newEmail: '',
  },
  newNote: {
    addNote: false,
    newNote: '',
  },
  candidateOffice: '',
  candidateState: 0,
  candidateDistrict: '',
  candidateFecId: '',
  candidateFirstName: '',
  candidateMiddleName: '',
  candidateLastName: '',
  taxId: '',
  externalDonorId: '',
  trackFor1099: false,
  isDeceased: null,
  isRemoved: null,
  isCandidate: null,
};

export const actions = {
  SET_CONTACT_DETAILS: 'SET_CONTACT_DETAILS',
  ON_LINK_CLICK: 'ON_LINK_CLICK',
  HANDLE_CHANGE: 'HANDLE_CHANGE',
  HANDLE_CHANGE_NEW_ADDRESS: 'HANDLE_CHANGE_NEW_ADDRESS',
  HANDLE_CHANGE_ADDITIONAL_ADDRESS: 'HANDLE_CHANGE_ADDITIONAL_ADDRESS',
  DELETE_ADDITIONAL_ADDRESS: 'DELETE_ADDITIONAL_ADDRESS',
  HANDLE_CHANGE_NEW_EMAIL: 'HANDLE_CHANGE_NEW_EMAIL',
  HANDLE_CHANGE_ADDITIONAL_EMAIL: 'HANDLE_CHANGE_ADDITIONAL_EMAIL',
  DELETE_ADDITIONAL_EMAIL: 'DELETE_ADDITIONAL_EMAIL',
  HANDLE_CHANGE_NEW_PHONE: 'HANDLE_CHANGE_NEW_PHONE',
  HANDLE_CHANGE_ADDITIONAL_PHONE: 'HANDLE_CHANGE_ADDITIONAL_PHONE',
  DELETE_ADDITIONAL_PHONE: 'DELETE_ADDITIONAL_PHONE',
  HANDLE_CHANGE_CHILD: 'HANDLE_CHANGE_CHILD',
  HANDLE_CHANGE_NEW_CHILD: 'HANDLE_CHANGE_NEW_CHILD',
  DELETE_CHILD: 'DELETE_CHILD',
  ADD_NEW_CHILD_START: 'ADD_NEW_CHILD_START',
  CANCEL_ADD_CHILD: 'CANCEL_ADD_CHILD',
  ADD_NEW_CHILD_FINISH: 'ADD_NEW_CHILD_FINISH',
  ADD_NEW_ADDRESS_START: 'ADD_NEW_ADDRESS_START',
  CANCEL_ADD_ADDRESS: 'CANCEL_ADD_ADDRESS',
  ADD_NEW_ADDRESS_FINISH: 'ADD_NEW_ADDRESS_FINISH',
  ADD_NEW_PHONE_START: 'ADD_NEW_PHONE_START',
  CANCEL_ADD_PHONE: 'CANCEL_ADD_PHONE',
  ADD_NEW_PHONE_FINISH: 'ADD_NEW_PHONE_FINISH',
  ADD_NEW_EMAIL_START: 'ADD_NEW_EMAIL_START',
  CANCEL_ADD_EMAIL: 'CANCEL_ADD_EMAIL',
  ADD_NEW_EMAIL_FINISH: 'ADD_NEW_EMAIL_FINISH',
  ADD_NEW_NOTE_START: 'ADD_NEW_NOTE_START',
  CANCEL_ADD_NOTE: 'CANCEL_ADD_NOTE',
  ADD_NEW_NOTE_FINISH: 'ADD_NEW_NOTE_FINISH',
  HANDLE_CHANGE_NEW_NOTE: 'HANDLE_CHANGE_NEW_NOTE',
  HANDLE_CHANGE_NOTE: 'HANDLE_CHANGE_NOTE',
  DELETE_NOTE: 'DELETE_NOTE',
  SHOW_HOUSEHOLD_PICKER: 'SHOW_HOUSEHOLD_PICKER',
  HIDE_HOUSEHOLD_PICKER: 'HIDE_HOUSEHOLD_PICKER',
  ON_HOUSEHOLD_MEMBER_SELECTED: 'ON_HOUSEHOLD_MEMBER_SELECTED',
  ON_HOUSEHOLD_MEMBER_REMOVED: 'ON_HOUSEHOLD_MEMBER_REMOVED',
  ADD_TO_HOUSEHOLD_ORIGINAL: 'ADD_TO_HOUSEHOLD_ORIGINAL',
  CLEAR_STATE: 'CLEAR_STATE',
};

export const actionMap = {
  [actions.SET_CONTACT_DETAILS]: (state, { data: { contact } }) => {
    return {
      ...state,
      spouseFirstName: contact?.family?.spouse?.firstName || '',
      spouseMiddleName: contact?.family?.spouse?.middleName || '',
      spouseLastName: contact?.family?.spouse?.lastName || '',
      children: contact.family?.children || [],
      _id: contact._id || undefined,
      committeeFecId: contact.committeeFecId || '',
      additionalAddresses: contact?.additionalAddresses || [],
      addressLine1: contact?.address?.addressLine1 || '',
      addressLine2: contact?.address?.addressLine2 || '',
      city: contact?.address?.city || '',
      state: contact?.address?.state || 0,
      zipCode: contact?.address?.zipCode || '',
      county: contact?.address?.county || '',
      phone1: contact.phone1 || '',
      phone2: contact.phone2 || '',
      email: contact.email || '',
      additionalTelephoneNumbers: contact.additionalTelephoneNumbers || [],
      additionalEmailAddresses: contact.additionalEmailAddresses || [],
      businessName: contact.businessName || '',
      businessType: contact.businessType || 0,
      contactName: contact.contactName || '',
      isLivingOrRevokableTrust: contact.isLivingOrRevokableTrust || '',
      trustee: contact.trustee || '',
      trustor: contact.trustor || '',
      taxEin: contact.taxEin || '',
      committeeAffiliation: contact.committeeAffiliation || 0,
      commonSource: contact.commonSource || '',
      specific: contact.specific || '',
      type: contact.contactType !== 0 ? contact.contactType : 'Other',
      notes: contact.notes || [],
      salutation: contact.salutation || 'none',
      relationshipToCandidate: contact.relationshipToCandidate || '',
      firstName: contact.firstName || '',
      middleName: contact.middleName || '',
      lastName: contact.lastName || '',
      suffix: contact.suffix || '',
      nickName: contact.nickName || '',
      occupation: contact.occupation || '',
      employer: contact.employer || '',
      candidateOffice: contact.candidateOffice || '',
      candidateState: contact.candidateState || 0,
      candidateDistrict: contact.candidateDistrict || '',
      candidateFecId: contact.candidateFecId || '',
      candidateFirstName: contact.candidateFirstName || '',
      candidateMiddleName: contact.candidateMiddleName || '',
      candidateLastName: contact.candidateLastName || '',
      tags: [...(contact.tags || [])],
      taxId: contact.taxId || '',
      externalDonorId: contact.externalDonorId || '',
      trackFor1099: contact.trackFor1099 || false,
      isDeceased: contact.isDeceased || null,
      isRemoved: contact.isRemoved || null,
      isCandidate: contact.isCandidate || null,
    };
  },
  [actions.ON_LINK_CLICK]: (state, { data: { id } }) => ({
    ...state,
    selectedKey: id,
  }),
  [actions.HANDLE_CHANGE]: (state, { data: { fieldName, value } }) => ({
    ...state,
    [fieldName]: value,
  }),
  [actions.HANDLE_CHANGE_NEW_ADDRESS]: (
    state,
    { data: { fieldName, value } },
  ) => ({
    ...state,
    newAddress: {
      ...state.newAddress,
      [fieldName]: value,
    },
  }),
  [actions.HANDLE_CHANGE_ADDITIONAL_ADDRESS]: (
    state,
    { data: { _id, fieldName, value } },
  ) => ({
    ...state,
    additionalAddresses: state.additionalAddresses.map(aa => {
      if (aa._id === _id) {
        aa[fieldName] = value;
      }
      return aa;
    }),
  }),
  [actions.DELETE_ADDITIONAL_ADDRESS]: (state, { data: { _id } }) => ({
    ...state,
    additionalAddresses: state.additionalAddresses.filter(aa => aa._id !== _id),
  }),
  [actions.HANDLE_CHANGE_NEW_EMAIL]: (state, { data: { value } }) => ({
    ...state,
    newEmail: {
      ...state.newEmail,
      newEmail: value,
    },
  }),
  [actions.HANDLE_CHANGE_ADDITIONAL_EMAIL]: (
    state,
    { data: { _id, value } },
  ) => ({
    ...state,
    additionalEmailAddresses: state.additionalEmailAddresses.map(ae => {
      if (ae._id === _id) {
        ae.value = value;
      }
      return ae;
    }),
  }),
  [actions.DELETE_ADDITIONAL_EMAIL]: (state, { data: { _id } }) => ({
    ...state,
    additionalEmailAddresses: state.additionalEmailAddresses.filter(
      ae => ae._id !== _id,
    ),
  }),
  [actions.HANDLE_CHANGE_NEW_PHONE]: (state, { data: { value } }) => ({
    ...state,
    newPhone: {
      ...state.newPhone,
      newPhone: value,
    },
  }),
  [actions.HANDLE_CHANGE_ADDITIONAL_PHONE]: (
    state,
    { data: { _id, value } },
  ) => ({
    ...state,
    additionalTelephoneNumbers: state.additionalTelephoneNumbers.map(at => {
      if (at._id === _id) {
        at.value = value;
      }
      return at;
    }),
  }),
  [actions.DELETE_ADDITIONAL_PHONE]: (state, { data: { _id } }) => ({
    ...state,
    additionalTelephoneNumbers: state.additionalTelephoneNumbers.filter(
      at => at._id !== _id,
    ),
  }),
  [actions.HANDLE_CHANGE_CHILD]: (state, { data: { _id, value } }) => ({
    ...state,
    children: state.children.map(child => {
      if (child._id === _id) {
        child.name = value;
      }

      return child;
    }),
  }),
  [actions.HANDLE_CHANGE_NEW_CHILD]: (state, { data: { value } }) => ({
    ...state,
    newChild: {
      ...state.newChild,
      newChild: value,
    },
  }),
  [actions.DELETE_CHILD]: (state, { data: { _id } }) => ({
    ...state,
    children: state.children.filter(c => c._id !== _id),
  }),
  [actions.ADD_NEW_CHILD_START]: state => ({
    ...state,
    newChild: {
      ...state.newChild,
      addChild: true,
    },
  }),
  [actions.CANCEL_ADD_CHILD]: state => ({
    ...state,
    newChild: { addChild: false, newChild: '' },
  }),
  [actions.ADD_NEW_CHILD_FINISH]: (state, { data: { _id } }) => ({
    ...state,
    children: [...state.children, { _id, name: state.newChild.newChild }],
    newChild: { addChild: false, newChild: '' },
  }),
  [actions.ADD_NEW_ADDRESS_START]: state => ({
    ...state,
    newAddress: {
      addAddress: true,
      newAddressLine1: '',
      newAddressLine2: '',
      newCity: '',
      newState: 0,
      newZipCode: '',
      newCounty: '',
    },
  }),
  [actions.CANCEL_ADD_ADDRESS]: state => ({
    ...state,
    newAddress: {
      addAddress: false,
      newAddressLine1: '',
      newAddressLine2: '',
      newCity: '',
      newState: 0,
      newZipCode: '',
      newCounty: '',
    },
  }),
  [actions.ADD_NEW_ADDRESS_FINISH]: (state, { data: { _id } }) => ({
    ...state,
    additionalAddresses: [
      ...state.additionalAddresses,
      {
        _id,
        addressLine1: state.newAddress.newAddressLine1,
        addressLine2: state.newAddress.newAddressLine2,
        city: state.newAddress.newCity,
        state: state.newAddress.newState,
        zipCode: state.newAddress.newZipCode,
        county: state.newAddress.newCounty,
      },
    ],
    newAddress: {
      addAddress: false,
      newAddressLine1: '',
      newAddressLine2: '',
      newCity: '',
      newState: 0,
      newZipCode: '',
      newCounty: '',
    },
  }),
  [actions.ADD_NEW_PHONE_START]: state => ({
    ...state,
    newPhone: {
      addPhone: true,
      newPhone: '',
    },
  }),
  [actions.CANCEL_ADD_PHONE]: state => ({
    ...state,
    newPhone: { addPhone: false, newPhone: '' },
  }),
  [actions.ADD_NEW_PHONE_FINISH]: (state, { data: { _id } }) => ({
    ...state,
    additionalTelephoneNumbers: [
      ...state.additionalTelephoneNumbers,
      {
        _id,
        value: state.newPhone.newPhone,
      },
    ],
    newPhone: {
      addPhone: false,
      newPhone: '',
    },
  }),
  [actions.ADD_NEW_EMAIL_START]: state => ({
    ...state,
    newEmail: {
      addEmail: true,
      newEmail: '',
    },
  }),
  [actions.CANCEL_ADD_EMAIL]: state => ({
    ...state,
    newEmail: { addEmail: false, newEmail: '' },
  }),
  [actions.ADD_NEW_EMAIL_FINISH]: (state, { data: { _id } }) => ({
    ...state,
    additionalEmailAddresses: [
      ...state.additionalEmailAddresses,
      {
        _id,
        value: state.newEmail.newEmail,
      },
    ],
    newEmail: {
      addEmail: false,
      newEmail: '',
    },
  }),
  [actions.ADD_NEW_NOTE_START]: state => ({
    ...state,
    newNote: {
      addNote: true,
      newNote: '',
    },
  }),
  [actions.CANCEL_ADD_NOTE]: state => ({
    ...state,
    newNote: {
      addNote: false,
      newNote: '',
    },
  }),
  [actions.ADD_NEW_NOTE_FINISH]: (state, { data: { _id } }) => ({
    ...state,
    notes: [...state.notes, { _id, value: state.newNote.newNote }],
    newNote: {
      addNote: false,
      newNote: '',
    },
  }),
  [actions.HANDLE_CHANGE_NEW_NOTE]: (state, { data: { value } }) => ({
    ...state,
    newNote: {
      ...state.newNote,
      newNote: value,
    },
  }),
  [actions.HANDLE_CHANGE_NOTE]: (state, { data: { _id, value } }) => ({
    ...state,
    notes: state.notes.map(note => {
      if (note._id === _id) {
        note.value = value;
      }

      return note;
    }),
  }),
  [actions.DELETE_NOTE]: (state, { data: { _id } }) => ({
    ...state,
    notes: state.notes.filter(n => n._id !== _id),
  }),
  [actions.SHOW_HOUSEHOLD_PICKER]: state => ({
    ...state,
    showHouseholdPicker: true,
  }),
  [actions.HIDE_HOUSEHOLD_PICKER]: state => ({
    ...state,
    showHouseholdPicker: false,
  }),
  [actions.ON_HOUSEHOLD_MEMBER_SELECTED]: (state, action) => {
    const { contact } = action.data;
    return {
      ...state,
      householdMembers: [
        ...state.householdMembers,
        contact,
      ],
    };
  },
  [actions.ADD_TO_HOUSEHOLD_ORIGINAL]: (state, action) => {
    const { contact } = action.data;
    return {
      ...state,
      originalHouseholdMembers: [
        ...state.originalHouseholdMembers,
        contact,
      ],
    };
  },
  [actions.ON_HOUSEHOLD_MEMBER_REMOVED]: (state, action) => {
    const { contactId } = action.data;
    return {
      ...state,
      householdMembers: state.householdMembers.filter(e => e._id !== contactId),
    };
  },
  [actions.CLEAR_STATE]: () => ({
    ...initialState,
  }),
};

export const ContactDetailsReducer = createReducer(initialState, actionMap);
