import {
  DefaultButton,
  PrimaryButton,
} from 'office-ui-fabric-react/lib/Button';
import {
  Dialog,
  DialogFooter,
  DialogType,
} from 'office-ui-fabric-react/lib/Dialog';
import { Dropdown } from 'office-ui-fabric-react/lib/Dropdown';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { TooltipHost } from 'office-ui-fabric-react/lib/Tooltip';
import PropTypes from 'prop-types';
import React, { useEffect, useReducer } from 'react';

import {
  FecMonthlyReportTypes,
  FECReportingFrequencies,
  FecReportTypes,
} from '../../helpers/constants';
import { Column, Grid, GridRow } from '../common';
import { CaretDown } from '../icons';
import {
  DatePicker,
  FiledReportPicker,
  ReportingFrequencyPicker,
} from '../Pickers';
import { validateForm6 } from './Add24HourNoticeReportDialogValidations';
import { validate } from './AddFederalNonCandidateReportDialogValidations';
import {
  actions,
  addFederalReportReducer,
  initialState,
} from './addFederalNonCandidateReportReducer';

export const AddFederalNonCandidateReportDialog = ({
  campaign,
  dialogHidden,
  closeDialog,
  createReport,
  reportType,
}) => {
  const [state, dispatch] = useReducer(addFederalReportReducer, initialState);

  useEffect(() => {
    dispatch({ type: 'RESET_STATE' });
    return () => {
      dispatch({ type: 'RESET_STATE' });
    };
  }, []);

  useEffect(() => {
    if (!state.reportingFrequency && campaign && campaign.reportingFrequency) {
      dispatch({
        type: actions.HANDLE_CHANGE,
        data: {
          fieldName: 'reportingFrequency',
          value: campaign.reportingFrequency,
        },
      });
    }
  }, [campaign, campaign?.reportingFrequency, state.reportingFrequency]);

  const handleChange = fieldName => (e, value) => {
    dispatch({
      type: actions.HANDLE_CHANGE,
      data: {
        fieldName,
        value: value.key !== undefined ? value.key : value,
      },
    });
  };

  const handleChangeDate = fieldName => date => {
    dispatch({
      type: actions.HANDLE_CHANGE,
      data: {
        fieldName,
        value: date,
      },
    });
  };

  const onCloseDialog = () => {
    dispatch({ type: 'RESET_STATE' });
    closeDialog();
  };

  const onCreateReport = () => {
    const errors =
      reportType === '48HourNotice' ? validateForm6(state) : validate(state);
    dispatch({ type: actions.FORM_ERRORS, data: { errors } });
    if (Object.keys(errors).length === 0) {
      createReport(state);
    }
  };

  return (
    <Dialog
      hidden={dialogHidden}
      onDismiss={onCloseDialog}
      dialogContentProps={{
        type: DialogType.normal,
        title:
          reportType === '' ? 'Create Report' : 'Create 48 Hour Notice Report',
        subText: '',
      }}
      minWidth={'45%'}
      maxWidth={'100%'}
      modalProps={{
        isBlocking: false,
        containerClassName: 'AddFederalReportDialog',
      }}
    >
      <div>
        <Grid>
          {reportType !== '48HourNotice' && (
            <>
              <GridRow>
                <Column>
                  <ReportingFrequencyPicker
                    value={state.reportingFrequency}
                    handleChange={handleChange('reportingFrequency')}
                  />
                </Column>
              </GridRow>
              <GridRow>
                <Column md={6}>
                  {state.reportingFrequency ===
                    FECReportingFrequencies.Quarterly && (
                    <Dropdown
                      label="Report Type"
                      onChange={handleChange('reportType')}
                      selectedKey={state.reportType}
                      required
                      placeholder="Select Report Type"
                      errorMessage={state.errors.reportTypeError}
                      options={[
                        { key: 0, text: 'Select' },
                        ...FecReportTypes.map(rt => ({
                          key: rt.text,
                          text: rt.fullText,
                        })),
                      ]}
                      onRenderCaretDown={() => <CaretDown />}
                    />
                  )}
                  {state.reportingFrequency ===
                    FECReportingFrequencies.Monthly && (
                    <Dropdown
                      label="Report Type"
                      onChange={handleChange('reportType')}
                      selectedKey={state.reportType}
                      required
                      placeholder="Select Report Type"
                      errorMessage={state.errors.reportTypeError}
                      options={[
                        { key: 0, text: 'Select' },
                        ...FecMonthlyReportTypes.map(rt => ({
                          key: rt.text,
                          text: rt.fullText,
                        })),
                      ]}
                      onRenderCaretDown={() => <CaretDown />}
                    />
                  )}
                </Column>
                <Column md={6}>
                  <DatePicker
                    onChange={handleChangeDate('reportDate')}
                    value={state.reportDate}
                    required
                    errorMessage={state.errors.reportDateError}
                    label="Report Date"
                  />
                </Column>
              </GridRow>
            </>
          )}
          {reportType === '48HourNotice' && (
            <GridRow>
              <Column md={6}>
                <DatePicker
                  onChange={handleChangeDate('reportDate')}
                  value={state.reportDate}
                  required
                  errorMessage={state.errors.reportDateError}
                  label="Report Date"
                />
              </Column>
            </GridRow>
          )}
          <GridRow>
            <Column md={6}>
              <DatePicker
                label="Report Start Date"
                value={state.startDate}
                onChange={handleChangeDate('startDate')}
                errorMessage={state.errors.startDateError}
                required
              />
            </Column>
            <Column md={6}>
              <DatePicker
                label="Report End Date"
                value={state.endDate}
                onChange={handleChangeDate('endDate')}
                errorMessage={state.errors.endDateError}
                required
              />
            </Column>
          </GridRow>
          {reportType !== '48HourNotice' && (
            <GridRow>
              <Column md={11}>
                <FiledReportPicker
                  label="Previous Report Balance Source"
                  selectedKey={state.previousReportId}
                  onChange={handleChange('previousReportId')}
                  errorMessage={state.errors.previousReportIdError}
                  required
                />
              </Column>
              <Column md={1}>
                <TooltipHost
                  content="All reports must contain a reference to obtain current totals to date. This can either be the campaign default settings or a reference to a previously filed report."
                  closeDelay={750}
                >
                  <Icon
                    iconName="Help"
                    styles={{
                      root: {
                        fontSize: '10pt',
                        backgroundColor: 'var(--primary-color))',
                        display: 'inline-flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: 24,
                        height: 24,
                        color: '#fff',
                        borderRadius: '50%',
                        marginTop: '33px',
                      },
                    }}
                  />
                </TooltipHost>
              </Column>
              <Column md={6} />
            </GridRow>
          )}
        </Grid>
      </div>
      <DialogFooter>
        <DefaultButton
          text="Cancel"
          onClick={onCloseDialog}
          style={{
            marginRight: 16,
          }}
        />
        <PrimaryButton text="Create Report" onClick={onCreateReport} />
      </DialogFooter>
    </Dialog>
  );
};

AddFederalNonCandidateReportDialog.propTypes = {
  dialogHidden: PropTypes.bool,
  closeDialog: PropTypes.func.isRequired,
  createReport: PropTypes.func.isRequired,
  campaign: PropTypes.object.isRequired,
  reportType: PropTypes.string,
};

AddFederalNonCandidateReportDialog.defaultProps = {
  dialogHidden: true,
  reportType: '',
};

export default AddFederalNonCandidateReportDialog;
