import { PrimaryButton } from 'office-ui-fabric-react';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { actions as importListActions } from '../../../actions/importListActions';
import { BackButton } from '../../../components/common';
import PaginatedList from '../../../components/common/PaginatedList';
import { getImportList } from '../../../selectors';
import { getColumns } from './utils';
import './index.css';

const ImportList = ({ history }) => {
  const reduxDispatch = useDispatch();
  const importList = useSelector(getImportList);

  const handleChangePage = (page, count) => {
    reduxDispatch(importListActions.getImportList({ page: page + 1, count }));
  };

  const handleAddImport = () => {
    history.push('/filer/dev/uploadImportFile');
  };

  const finalizeImport = fileId => {
    reduxDispatch(importListActions.finalizeImport(fileId));
  };

  const reviewErrors = fileId => {
    history.push(`/filer/dev/importErrors/${fileId}`);
  };

  const editMapping = fileId => {
    history.push(`/filer/dev/uploadImportFile/${fileId}`);
  };

  return (
    <>
      <BackButton history={history} pageTitle="Import List" />
      <div className="ImportList depth-1">
        <div className="import-list-header">
          <h2>Import List</h2>
          <PrimaryButton
            iconProps={{
              iconName: 'Plus',
            }}
            text="Import file"
            style={{ marginLeft: 'auto' }}
            onClick={handleAddImport}
          />
        </div>
        <PaginatedList
          items={importList.list}
          total={importList.total}
          columns={getColumns({
            finalizeImport,
            finalizeImportFileId: importList.finalizeImportFileId,
            reviewErrors,
            editMapping,
          })}
          onChangePage={handleChangePage}
        />
      </div>
    </>
  );
};

ImportList.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(ImportList);
