import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import {
  IconButton,
  ActionButton,
  DefaultButton,
} from 'office-ui-fabric-react/lib/Button';
import { Link } from 'office-ui-fabric-react/lib/Link';
import {
  DetailsList,
  SelectionMode,
  CheckboxVisibility,
} from 'office-ui-fabric-react/lib/DetailsList';
import { Grid, GridRow, Column } from '../common';
import { formatCurrency } from '../../helpers/util';
import { EmptyView } from '../EmptyView';
import { F3ZDialog, ConfirmDeleteFromReportDialog } from '../Dialogs';

const F3ZSection = ({
  toggleExpandSection,
  sectionName,
  addF3ZData,
  updateF3ZData,
  deleteF3ZData,
  state,
}) => {
  const [f3ZDialogHidden, setF3ZDialogHidden] = useState(true);
  const [f3ZToEdit, setF3ZToEdit] = useState(null);
  const [f3ZToDelete, setF3ZToDelete] = useState(null);
  const [confirmDeleteHidden, setConfirmDeleteHidden] = useState(true);

  const openF3ZDialog = () => {
    setF3ZDialogHidden(false);
  };

  const closeF3ZDialog = () => {
    setF3ZToEdit(null);
    setF3ZDialogHidden(true);
  };

  const addF3Z = async f3Z => {
    addF3ZData(f3Z);
  };

  const updateF3Z = f3Z => {
    updateF3ZData(f3Z);
  };

  // eslint-disable-next-line
  const setF3ZForEdit = f3Z => {
    setF3ZToEdit(f3Z);
    setF3ZDialogHidden(false);
  };

  const deleteF3Z = f3Z => {
    setF3ZToDelete(f3Z);
    setConfirmDeleteHidden(false);
  };

  const cancelDelete = () => {
    setF3ZToDelete({});
    setConfirmDeleteHidden(true);
  };

  const confirmDelete = () => {
    deleteF3ZData(f3ZToDelete);
  };

  const createColumns = () => [
    {
      key: 'committeeName',
      fieldName: 'committeeName',
      name: 'Name',
      minWidth: 150,
      maxWidth: 180,
      isResizable: true,
      onRender: item => item.committeeName,
    },
    {
      key: 'committeeId',
      fieldName: 'committeeId',
      name: 'Committee Id',
      minWidth: 150,
      maxWidth: 180,
      isResizable: true,
      onRender: item => item.committeeId,
    },
    {
      key: 'col11e_TotalContributions',
      fieldName: 'col11e_TotalContributions',
      name: 'Total Contributions',
      minWidth: 100,
      maxWidth: 115,
      isResizable: true,
      onRender: item => formatCurrency(item.col11e_TotalContributions),
    },
    {
      key: 'col13c_TotalLoans',
      fieldName: 'col13c_TotalLoans',
      name: 'Total Loans',
      minWidth: 75,
      maxWidth: 115,
      isResizable: true,
      onRender: item => formatCurrency(item.col13c_TotalLoans),
    },
    {
      key: 'col16_TotalReceipts',
      fieldName: 'col16_TotalReceipts',
      name: 'Total Receipts',
      minWidth: 75,
      maxWidth: 115,
      isResizable: true,
      onRender: item => formatCurrency(item.col16_TotalReceipts),
    },
    {
      key: 'col23_CashOnHandAtBeginningOfReportingPeriod',
      fieldName: 'col23_CashOnHandAtBeginningOfReportingPeriod',
      name: 'Beginning COH',
      minWidth: 100,
      maxWidth: 115,
      isResizable: true,
      onRender: item => formatCurrency(item.col23_CashOnHandAtBeginningOfReportingPeriod),
    },
    {
      key: 'col27_CashOnHandAtCloseOfReportingPeriod',
      fieldName: 'col27_CashOnHandAtCloseOfReportingPeriod',
      name: 'Closing COH',
      minWidth: 100,
      maxWidth: 115,
      isResizable: true,
      onRender: item => formatCurrency(item.col27_CashOnHandAtCloseOfReportingPeriod),
    },
    {
      key: 'actions',
      fieldName: '',
      name: '',
      minWidth: 150,
      maxWidth: 150,
      isResizable: false,
      onRender: item => (
        <span
          className="ms-textAlignRight"
          style={{ display: 'inline-block', width: '100%' }}
        >
          <IconButton
            iconProps={{
              iconName: 'PencilAlt',
            }}
            styles={{
              root: {
                marginRight: 32,
              },
            }}
            onClick={() => setF3ZForEdit(item)}
          />
          <IconButton
            styles={{
              root: {
                marginRight: 16,
              },
            }}
            iconProps={{
              iconName: 'TrashAlt',
            }}
            onClick={() => deleteF3Z(item)}
          />
        </span>
      ),
    },
  ];

  return (
    <Fragment>
      <div className="FECF3Z fec-report-section depth-1" name={sectionName}>
        <h3>
          F3Z{' '}
          <small>
            {' '}
            (
            <Link onClick={() => toggleExpandSection(sectionName, false)}>
              {state.expandableSections[sectionName] === true
                ? 'Collapse'
                : 'Expand'}
            </Link>
            )
          </small>
        </h3>
        <div
          className={`content${
            state.expandableSections[sectionName] === true
              ? '-expanded'
              : '-collapsed'
          }`}
        >
          <Grid>
            <GridRow>
              <Column classNames="ms-textAlignRight">
                <ActionButton
                  iconProps={{
                    iconName: 'PlusCircle',
                    styles: {
                      root: {
                        fontSize: '1.3em',
                      },
                    },
                  }}
                  onClick={openF3ZDialog}
                >
                  Add New F3Z
                </ActionButton>
              </Column>
            </GridRow>
            {state && state.f3ZData && state.f3ZData.length === 0 && (
              <EmptyView message="There are no F3Z entries for this reporting period." />
            )}
            {state && state.f3ZData && state.f3ZData.length !== 0 && (
              <DetailsList
                checkboxVisibility={CheckboxVisibility.hidden}
                selectionMode={SelectionMode.none}
                columns={createColumns()}
                items={state.f3ZData}
              />
            )}
          </Grid>
          <div className="section-actions">
            <DefaultButton
              text="Previous Section"
              style={{ marginRight: 16 }}
              onClick={() => toggleExpandSection('debts', false)}
            />
          </div>
        </div>
      </div>
      <F3ZDialog
        dialogHidden={f3ZDialogHidden}
        closeDialog={closeF3ZDialog}
        f3ZToEdit={f3ZToEdit}
        addF3Z={addF3Z}
        updateF3Z={updateF3Z}
      />
      <ConfirmDeleteFromReportDialog
        dialogHidden={confirmDeleteHidden}
        cancel={cancelDelete}
        confirm={confirmDelete}
        itemType="F3Z"
      />
    </Fragment>
  );
};

F3ZSection.propTypes = {
  toggleExpandSection: PropTypes.func.isRequired,
  updateReport: PropTypes.func.isRequired,
  sectionName: PropTypes.string.isRequired,
  state: PropTypes.object.isRequired,
  addF3ZData: PropTypes.func.isRequired,
  updateF3ZData: PropTypes.func.isRequired,
  deleteF3ZData: PropTypes.func.isRequired,
};

export default F3ZSection;
