import { types } from '../actions/paymentActions';
import { statuses } from '../helpers/constants';
import { createReducer } from './createReducer';

export const initialState = {
  accountStatus: '',
  planId: '',
  planPrice: '',
  planPriceStatus: statuses.NOT_STARTED,
  planDescription: '',
  planPriceError: null,
  stripeToken: null,
  billingZipCode: null,
  last4: null,
  stripeError: null,
  stripeStatus: statuses.NOT_STARTED,
  submitStatus: statuses.NOT_STARTED,
  successMessage: '',
  cancelAccountError: null,
  cancelAccountStatus: statuses.NOT_STARTED,
  publishableKey: '',
  coupon: '',
};

const actionMap = {
  [types.GET_PLAN_PRICE_SUCCESS]: (state, action) => {
    return {
      ...state,
      planPriceStatus: statuses.SUCCESS,
      planPriceError: null,
      planDescription: action.pricingPlan.productName,
      planPrice: action.pricingPlan.planPrice,
      planId: action.pricingPlan.planId,
    };
  },
  [types.GET_PLAN_PRICE_FAILURE]: (state, action) => {
    return {
      ...state,
      planPriceStatus: statuses.ERROR,
      planPriceError: action.error,
    };
  },
  [types.GET_PLAN_PRICE_PROCESSING]: state => {
    return {
      ...state,
      planPriceStatus: statuses.PROCESSING,
    };
  },
  [types.RESET_GET_PLAN_PRICE_STATUS]: state => {
    return {
      ...state,
      planPriceStatus: statuses.NOT_STARTED,
    };
  },
  [types.GET_STRIPE_TOKEN_PROCESSING]: state => {
    return {
      ...state,
      stripeStatus: statuses.PROCESSING,
    };
  },
  [types.GET_STRIPE_TOKEN_SUCCESS]: (state, action) => {
    return {
      ...state,
      stripeStatus: statuses.SUCCESS,
      stripeError: null,
      stripeToken: action.data.token,
      billingZipCode: action.data.billingZipCode,
      last4: action.data.last4,
    };
  },
  [types.GET_STRIPE_TOKEN_FAILURE]: (state, action) => {
    return {
      ...state,
      stripeStatus: statuses.ERROR,
      stripeError: action.error,
    };
  },
  [types.RESET_GET_STRIPE_TOKEN]: state => {
    return {
      ...state,
      stripeStatus: statuses.NOT_STARTED,
      stripeError: null,
    };
  },
  [types.GET_CC_DETAILS_SUCCESS]: (state, action) => {
    return {
      ...state,
      planPriceStatus: statuses.SUCCESS,
      planPriceError: null,
      planDescription: action.data.productName,
      planPrice: action.data.price,
      planId: action.data.planId,
      billingZipCode: action.data.billingZipCode,
      last4: action.data.last4,
      cardholderName: action.data.cardholderName,
      cardholderEmail: action.data.cardholderEmail,
      accountStatus: action.data.status,
      coupon: action.data.coupon,
    };
  },
  [types.GET_CC_DETAILS_FAILURE]: (state, action) => {
    return {
      ...state,
      planPriceError: action.error,
      planPriceStatus: statuses.ERROR,
    };
  },
  [types.SAVE_CC_DETAILS_FAILURE]: (state, action) => {
    return {
      ...state,
      stripeError: action.stripeError,
      submitError: action.submitError,
      stripeStatus: statuses.ERROR,
      submitStatus: statuses.NOT_STARTED,
    };
  },
  [types.SAVE_CC_DETAILS_PROCESSING]: state => {
    return {
      ...state,
      submitStatus: statuses.PROCESSING,
    };
  },
  [types.SAVE_CC_DETAILS_SUCCESS]: (state, action) => {
    return {
      ...state,
      successMessage: action.message,
      submitStatus: statuses.SUCCESS,
      stripeStatus: statuses.SUCCESS,
      stripeError: null,
      submitError: null,
    };
  },
  [types.RESET_PAYMENT_STATE]: () => {
    return { ...initialState };
  },
  [types.CANCEL_ACCOUNT_PROCESSING]: state => {
    return {
      ...state,
      cancelAccountStatus: statuses.PROCESSING,
    };
  },
  [types.CANCEL_ACCOUNT_FAILURE]: (state, action) => {
    return {
      ...state,
      cancelAccountError: action.error,
      cancelAccountStatus: statuses.ERROR,
    };
  },
  [types.CANCEL_ACCOUNT_SUCCESS]: state => {
    return {
      ...state,
      cancelAccountError: null,
      cancelAccountStatus: statuses.SUCCESS,
    };
  },
  [types.GET_PUBLISHABLE_KEY_SUCCESS]: (state, action) => {
    return {
      ...state,
      publishableKey: action.publishableKey,
    };
  },
};

export const paymentReducer = createReducer(initialState, actionMap);
