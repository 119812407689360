import { sortDirectionMap } from '../../../helpers/util';

export const buildParams = (state, filterText, electionCycle, electionYear, byYear, contactType) => {
  const params = [
    `?limit=100&skip=${state.skip * 100}`,
    filterText.length ? `&search=${filterText}` : '',
    electionCycle ? `&electionCycle=${electionCycle}` : '',
    electionYear ? `&electionYear=${electionYear}` : '',
    contactType ? `&contactType=${contactType}` : '',
    state.sortBy ? `&sortBy=${state.sortBy}` : '',
    byYear ? '&byYear=true' : '',
    sortDirectionMap[state.sortDir]
      ? `&sortDir=${sortDirectionMap[state.sortDir]}`
      : '',
  ]
    .filter(Boolean)
    .join('');

  return params;
};
