import { types } from '../actions/importActions';
import { createReducer } from './createReducer';
import { statuses } from '../helpers/constants';

export const initialState = {
  getUnprocessedEarmarksStatus: statuses.NOT_STARTED,
  unprocessedEarmarks: {},
  applyEarmarkImportRecordsStatus: statuses.NOT_STARTED,
  removeEarmarkImportRecordsStatus: statuses.NOT_STARTED,
  uploadEarmarkSpreadsheetStatus: statuses.NOT_STARTED,
  backgroundEarmarkImportProcess: statuses.NOT_STARTED,
};

export const actionMap = {
  [types.GET_UNPROCESSED_EARMARKS_SUMMARY_PROCESSING]: state => ({
    ...state,
    getUnprocessedEarmarksStatus: statuses.PROCESSING,
  }),
  [types.GET_UNPROCESSED_EARMARKS_SUMMARY_SUCCESS]: (state, action) => {
    return {
      ...state,
      unprocessedEarmarks: action.data,
      getUnprocessedEarmarksStatus: statuses.SUCCESS,
    };
  },
  [types.APPLY_EARMARK_IMPORT_RECORDS_PROCESSING]: state => ({
    ...state,
    applyEarmarkImportRecordsStatus: statuses.PROCESSING,
  }),
  [types.APPLY_EARMARK_IMPORT_RECORDS_SUCCESS]: state => ({
    ...state,
    applyEarmarkImportRecordsStatus: statuses.SUCCESS,
  }),
  [types.APPLY_EARMARK_IMPORT_RECORDS_FAILURE]: state => ({
    ...state,
    applyEarmarkImportRecordsStatus: statuses.ERROR,
  }),
  [types.REMOVE_EARMARK_IMPORT_RECORDS_PROCESSING]: state => ({
    ...state,
    removeEarmarkImportRecordsStatus: statuses.PROCESSING,
  }),
  [types.REMOVE_EARMARK_IMPORT_RECORDS_SUCCESS]: state => ({
    ...state,
    removeEarmarkImportRecordsStatus: statuses.SUCCESS,
  }),
  [types.UPLOAD_EARMARK_SPREADSHEET_PROCESSING]: state => ({
    ...state,
    uploadEarmarkSpreadsheetStatus: statuses.PROCESSING,
  }),
  [types.UPLOAD_EARMARK_SPREADSHEET_SUCCESS]: state => ({
    ...state,
    uploadEarmarkSpreadsheetStatus: statuses.SUCCESS,
  }),
  [types.UPLOAD_EARMARK_SPREADSHEET_FAILURE]: state => ({
    ...state,
    uploadEarmarkSpreadsheetStatus: statuses.ERROR,
  }),
  [types.BACKGROUND_EARMARK_IMPORT_RECORDS_PROCESSING]: state => ({
    ...state,
    backgroundEarmarkImportProcess: statuses.PROCESSING,
  }),
  [types.BACKGROUND_EARMARK_IMPORT_RECORDS_SUCCESS]: state => ({
    ...state,
    backgroundEarmarkImportProcess: statuses.SUCCESS,
  }),
  [types.BACKGROUND_EARMARK_IMPORT_RECORDS_FAILURE]: state => ({
    ...state,
    backgroundEarmarkImportProcess: statuses.ERROR,
  }),
};

export const importReducer = createReducer(initialState, actionMap);
