import { createReducer } from '../../reducers/createReducer';

export const initialState = {
  calculatedTotals: {},
};

export const actions = {
  SET_CALCULATED_TOTALS: 'SET_CALCULATED_TOTALS',
};

export const actionMap = {
  [actions.SET_CALCULATED_TOTALS]: (
    state,
    { data: { reportId, reportData } },
  ) => ({
    ...state,
    calculatedTotals: {
      ...state.calculatedTotals,
      [reportId]: reportData,
    },
  }),
};

export const auditReducer = createReducer(initialState, actionMap);
