export const types = {
  GET_LEDGER: 'GET_LEDGER',
  GET_LEDGER_SUCCESS: 'GET_LEDGER_SUCCESS',
  RECONCILE_ITEM: 'RECONCILE_ITEM',
  RECONCILE_ITEM_SUCCESS: 'RECONCILE_ITEM_SUCCESS',
  CLEAR_LEDGER: 'CLEAR_LEDGER',
};

export const actions = {
  getLedger() {
    return { type: types.GET_LEDGER };
  },

  reconcile(id, type, reconciled) {
    return { type: types.RECONCILE_ITEM, data: { id, type, reconciled } };
  },

  clearLedger() {
    return { type: types.CLEAR_LEDGER };
  },
};
