import React, { useEffect, useState } from 'react';
import { getContacts } from '../services/apiCalls';
import { FECSourceTypeCodes } from '../helpers/constants';
import {
  IndividualPersona,
  BusinessPersona,
} from '../components/Pickers/ContactPickerPersonas';

export function useContactSearch(
  electionCycle,
  electionYear,
  type,
  collection,
) {
  const [state, setState] = useState({
    electionCycle,
    electionYear,
    type,
    collection,
  });

  useEffect(() => {
    setState({
      electionCycle,
      electionYear,
      type,
      collection,
    });
  }, [electionYear, electionCycle, type, collection]);

  const searchContacts = async filterText => {
    try {
      const params = [
        `?name=${encodeURIComponent(filterText)}`,
        state.electionCycle ? `&elec=${state.electionCycle}` : '',
        state.electionYear ? `&elecYear=${state.electionYear}` : '',
        state.type ? `&type=${state.type}` : '',
        state.collection ? `&coll=${state.collection}` : '',
      ]
        .filter(Boolean)
        .join('');

      const { data } = await getContacts(params);
      return data;
    } catch (e) {
      return [];
    }
  };

  const onResolveSuggestions = async filterText => {
    if (filterText && filterText.length > 1) {
      return searchContacts(filterText);
    }
    return [];
  };

  const onRenderSuggestionsItem = persona => {
    if (
      ['Individual', FECSourceTypeCodes.IND, FECSourceTypeCodes.CAN].includes(
        persona.contactType,
      )
    ) {
      return <IndividualPersona persona={persona} />;
    }

    if (
      [
        'Business',
        'Committee',
        'Common Source',
        'TST', // Iowa trust
        FECSourceTypeCodes.ORG,
        FECSourceTypeCodes.COM,
        FECSourceTypeCodes.PAC,
        FECSourceTypeCodes.PTY,
        FECSourceTypeCodes.CCM,
      ].includes(persona.contactType)
    ) {
      return <BusinessPersona persona={persona} />;
    }

    return null;
  };

  return [onResolveSuggestions, onRenderSuggestionsItem];
}
