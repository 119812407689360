import React from 'react';

import { Icon, Stack } from 'office-ui-fabric-react';
import { formatDateOnly, formatUtcDate } from './util';
import { FecReportTypes, FecMonthlyReportTypes, ReportBalanceSource } from './constants';
import { reportingPeriods } from './reportingPeriods';

export const setSourceAndPrevReportIdFromPrevReportId = (previousReportId) => {
  if (previousReportId === '0' || !previousReportId) {
    return { previousBalanceSource: null, adjustedPreviousReportId: null };
  }

  // If previous report id indicates campaign default, no adjusted report id, source is campaign default
  if (previousReportId === ReportBalanceSource.CampaignDefault) { // not report
    return { previousBalanceSource: ReportBalanceSource.CampaignDefault, adjustedPreviousReportId: null };
  }

  // if not campaign default, references a previous report
  return {
    previousBalanceSource: ReportBalanceSource.PreviousFiling,
    adjustedPreviousReportId: previousReportId,
  };
};

export const createFederalReportPayload = ({
  startDate,
  endDate,
  electionDate,
  reportType: reportCode,
  reportDate,
  electionCycle,
  reportingFrequency,
  previousReportId, // Possible that this is a default constant and not a previous report id
  isAutomaticConduit,
}) => {
  const { adjustedPreviousReportId, previousBalanceSource } = setSourceAndPrevReportIdFromPrevReportId(previousReportId);

  const payload = {
    startDate: formatDateOnly(startDate),
    endDate: formatDateOnly(endDate),
    electionDate: formatDateOnly(electionDate),
    reportType: 'Original',
    reportCode,
    reportDate: formatDateOnly(reportDate),
    electionCycle,
    reportingFrequency,
    previousBalanceSource,
    previousReportId: adjustedPreviousReportId,
    isAutomaticConduit,
  };

  return payload;
};

export const createDisclosureReportPayload = (payload) => {
  const { adjustedPreviousReportId, previousBalanceSource } = setSourceAndPrevReportIdFromPrevReportId(payload.previousReportId);
  return {
    ...payload,
    previousBalanceSource,
    previousReportId: adjustedPreviousReportId,
  };
};

export const isForm6Form24Report = (reportType) => /\d\dHourNotice/.test(reportType);

export const getReportTypeByReportCode = (reportCode) => {
  let rec = FecReportTypes.find(e => e.text === reportCode);
  if (rec) {
    return rec.listingText;
  }
  rec = FecMonthlyReportTypes.find(e => e.text === reportCode);
  if (rec) {
    return rec.listingText;
  }
  return '';
};

export const isAmendment = (item) => (/amend/i.test(item.reportType));
export const isFec48Hour = (item) => (/48hour/i.test(item.reportType));
export const getAmendmentOriginalLabel = (item) => isAmendment(item) ? 'Amendment' : 'Original';
export const getAmendmentNumber = (item) => isAmendment(item) ? item.amendmentNumber : '';
export const getReportTypeLabel = (item) => `${getAmendmentOriginalLabel(item)} ${getAmendmentNumber(item)}`;
export const getYear = (item) => formatUtcDate(item.endDate, 'YYYY');

export const getReportTypeByItem = (item) => {
  const code = getReportTypeByReportCode(item.reportCode);
  const reportTypeLabel = getReportTypeLabel(item);
  if (code) {
    return `${code} ${getYear(item)} | ${reportTypeLabel}`;
  }
  return '';
};

export const getReportTypeByItemForm6 = (item) => {
  const reportTypeLabel = getReportTypeLabel(item);
  return `48-hour notice of contribution/loans received ${getYear(item)} | ${reportTypeLabel}`;
};

export const getReportTypeByItemForm24 = (item) => {
  const reportTypeLabel = getReportTypeLabel(item);
  const form24Type = isFec48Hour(item) ? '48-hour' : '24-hour';
  return `${form24Type} notice of independent expenditures ${getYear(item)} | ${reportTypeLabel}`;
};

export const getReportTypeByItemForState = (item) => {
  const rp = reportingPeriods.find(rp => rp.id === item.reportingPeriod);
  const nameFromReportPeriod = rp ? `${rp.group} - ${rp.desc}` : '';
  let name = nameFromReportPeriod || item.reportingName;
  // shorten for new longer names from GA
  name = name.replace('Campaign Contribution Disclosure Report', 'CCDR');
  const reportType = item.reportType === 'Amended' ? 'Amendment' : 'Original';
  if (name) {
    return `${name} ${formatUtcDate(item.endDate, 'YYYY')} | ${reportType}`;
  }
  return '';
};

export const getVersion = (item) => {
  const currentOrPastMsg = item.isCurrentVersion ? 'Current Version' : 'Past Version';
  const currentOrPastIcon = item.isCurrentVersion ? <Icon styles={{ root: { color: '#36bdbb' } }} iconName="CheckCircle" /> : <Icon styles={{ root: { color: '#112e51' } }} iconName="history" />;
  return (
    <Stack horizontal childrenGap={8}>
      <div style={{ paddingRight: 4 }}>{currentOrPastIcon}</div>
      <Stack childrenGap={4}>
        <div>{currentOrPastMsg}</div>
        <div>{item.filingId}</div>
      </Stack>
    </Stack>
  );
};

export const getVersionNonFec = (item, isTbd = false) => {
  const currentOrPastMsg = item.isCurrentVersion || isTbd ? 'Current Version' : 'Past Version';
  const currentOrPastIcon = item.isCurrentVersion || isTbd ? <Icon styles={{ root: { color: '#36bdbb' } }} iconName="CheckCircle" /> : <Icon styles={{ root: { color: '#112e51' } }} iconName="history" />;
  return (
    <Stack horizontal childrenGap={8}>
      <div style={{ paddingRight: 4 }}>{currentOrPastIcon}</div>
      <Stack childrenGap={4}>
        <div>{currentOrPastMsg}</div>
        <div>{item.filedConfirmationNumber}</div>
      </Stack>
    </Stack>
  );
};
