import { takeLatest, takeEvery, put, call } from 'redux-saga/effects';
import axios from 'axios';
import { types } from '../actions/debtActions';
import { getServerSideErrorMessage } from '../helpers/util';

export function* handleGetDebts(action) {
  try {
    const { reportId } = action.data;
    yield put({ type: types.GET_DEBTS_PROCESSING });
    const { data: debts } = yield call(
      axios.get,
      `/api/filer/reports/${reportId}/debt`,
      { withCredentials: true },
    );
    yield put({ type: types.GET_DEBTS_SUCCESS, data: { debts } });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put({ type: types.GET_DEBTS_FAILURE, error });
  }
}

export function* getDebts() {
  yield takeLatest(types.GET_DEBTS, handleGetDebts);
}

export function* handleSaveDebt(action) {
  try {
    const { payload, reportId } = action.data;
    yield put({ type: types.SAVE_DEBT_PROCESSING });
    const { data: debt } = yield call(
      axios.post,
      `/api/filer/reports/${reportId}/debt`,
      payload,
      { withCredentials: true },
    );
    yield put({ type: types.SAVE_DEBT_SUCCESS, data: { debt } });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put({ type: types.SAVE_DEBT_FAILURE, error });
  }
}

export function* saveDebt() {
  yield takeEvery(types.SAVE_DEBT, handleSaveDebt);
}

export function* handleUpdateDebt(action) {
  try {
    const { reportId, debtId, payload } = action.data;
    yield put({ type: types.UPDATE_DEBT_PROCESSING });
    yield call(
      axios.put,
      `/api/filer/reports/${reportId}/debt/${debtId}`,
      payload,
      { withCredentials: true },
    );
    yield put({ type: types.UPDATE_DEBT_SUCCESS, data: { debtId, payload } });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put({ type: types.UPDATE_DEBT_FAILURE, error });
  }
}

export function* updateDebt() {
  yield takeEvery(types.UPDATE_DEBT, handleUpdateDebt);
}

export function* handleDeleteDebt(action) {
  try {
    const { reportId, debtId } = action.data;
    yield put({ type: types.DELETE_DEBT_PROCESSING });
    yield call(axios.delete, `/api/filer/reports/${reportId}/debt/${debtId}`, {
      withCredentials: true,
    });
    yield put({ type: types.DELETE_DEBT_SUCCESS, data: { debtId } });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put({ type: types.DELETE_DEBT_FAILURE, error });
  }
}

export function* deleteDebt() {
  yield takeLatest(types.DELETE_DEBT, handleDeleteDebt);
}
