import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { TextField } from 'office-ui-fabric-react/lib/TextField';

export const CommonSourceForm = ({
  handleChange,
  businessName,
  commonSource,
  specific,
}) => (
  <Fragment>
    <TextField
      label="Name"
      value={businessName}
      onChange={handleChange('businessName')}
    />
    <TextField
      label="Common Source"
      value={commonSource}
      onChange={handleChange('commonSource')}
    />
    <TextField
      label="Specific"
      value={specific}
      onChange={handleChange('specific')}
    />
  </Fragment>
);

CommonSourceForm.propTypes = {
  handleChange: PropTypes.func.isRequired,
  businessName: PropTypes.string,
  commonSource: PropTypes.string,
  specific: PropTypes.string,
};

CommonSourceForm.defaultProps = {
  businessName: '',
  commonSource: '',
  specific: '',
};
