import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  DefaultButton,
  PrimaryButton,
} from 'office-ui-fabric-react/lib/Button';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { Grid, GridRow, Column } from '../common';

export class InvestmentsSection extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    items: PropTypes.array,
    isExpanded: PropTypes.bool,
  };

  static defaultProps = {
    items: [],
    isExpanded: false,
  };

  shouldComponentUpdate(nextProps) {
    return nextProps.isExpanded !== this.props.isExpanded;
  }

  render() {
    const {
      props: { actions, isExpanded },
    } = this;

    return (
      <div className="investments depth-1" name="investments">
        <header onClick={actions.toggleExpand}>
          <h3>Investments</h3>
        </header>
        <div className={`content${isExpanded ? '-expanded' : '-collapsed'}`}>
          <Grid>
            <GridRow>
              <Column>
                <TextField label="Search by Investment Name" />
              </Column>
            </GridRow>
          </Grid>
          <div className="section-actions">
            <DefaultButton
              text="Back"
              style={{ marginRight: 16 }}
              onClick={actions.previousSection}
            />
            <PrimaryButton text="Continue" onClick={actions.nextSection} />
          </div>
        </div>
      </div>
    );
  }
}

export default InvestmentsSection;
