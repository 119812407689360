import { select, takeLatest, takeEvery, call, put } from 'redux-saga/effects';
import axios from 'axios';
import uuid from 'uuid/v4';
import { types } from '../actions/anedotActions';
import {
  errorToast,
  getServerSideErrorMessage,
  successToast,
} from '../helpers/util';
import { getUserSession } from '../selectors';
import { getLabel } from '../helpers/labelHelper';
import { AnedotProcessedStates } from '../helpers/constants';

export function transformData(data) {
  return data.map(item => ({
    ...item,
    possibleContacts: item?.possibleContacts?.map(pc => ({
      ...pc,
      uuid: uuid(),
    })),
  }));
}

export function transformItemData(item) {
  return {
    ...item,
    possibleContacts: item?.possibleContacts?.map(pc => ({
      ...pc,
      uuid: uuid(),
    })),
  };
}

export function* handleGetUnprocessed({ data: { processedStatus } }) {
  try {
    if (Object.values(AnedotProcessedStates).includes(processedStatus)) {
      yield put({ type: types.GET_UNPROCESSED_PROCESSING });
      const { data = [] } = yield call(
        axios.get,
        `/api/anedotEvents?processedStatus=${processedStatus}`,
        {
          withCredentials: true,
        },
      );
      yield put({
        type: types.GET_UNPROCESSED_SUCCESS,
        data: { unprocessed: data },
      });
    }
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put(errorToast(error));
  }
}

export function* getUnprocessed() {
  yield takeLatest(types.GET_UNPROCESSED, handleGetUnprocessed);
}

export function* handleGetRecord({ data: { id } }) {
  try {
    yield put({ type: types.GET_RECORD_PROCESSING });
    const { data = [] } = yield call(
      axios.get,
      `/api/anedotEvents/${id}`,
      {
        withCredentials: true,
      },
    );
    let unprocessedRecord = {};
    if (data) {
      unprocessedRecord = yield call(transformItemData, data);
    }
    yield put({
      type: types.GET_RECORD_SUCCESS,
      data: { unprocessedRecord },
    });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put(errorToast(error));
  }
}

export function* getRecord() {
  yield takeLatest(types.GET_RECORD, handleGetRecord);
}

export function* handleUpdateContribution(action) {
  try {
    const { id: anedotId, payload, isProcessed } = action.data;
    const { data: contributions } = yield call(
      axios.post,
      '/api/filer/contributions/batch',
      payload,
      { withCredentials: true },
    );
    yield put({ type: types.UPDATE_ANEDOT_CONTRIBUTION_PROCESSING });
    yield call(
      axios.patch,
      `/api/anedotEvents/${anedotId}`,
      {
        isProcessed: !isProcessed,
        contributionIds: contributions.map(c => c._id),
      },
      { withCredentials: true },
    );
    yield put({
      type: types.UPDATE_ANEDOT_CONTRIBUTION_SUCCESS,
      data: { anedotId },
    });
    const session = yield select(getUserSession);
    yield put(
      successToast(
        `${getLabel('Contribution', session)} processed successfully!`,
      ),
    );
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put(errorToast(error));
  }
}

export function* updateContribution() {
  yield takeLatest(types.UPDATE_ANEDOT_CONTRIBUTION, handleUpdateContribution);
}

export function* handleDoNotImport(action) {
  try {
    const { id } = action.data;
    yield put({ type: types.UPDATE_ANEDOT_CONTRIBUTION_PROCESSING });
    yield call(
      axios.patch,
      `/api/anedotEvents/${id}`,
      {
        isProcessed: true,
        isDeleted: true,
      },
      { withCredentials: true },
    );
    yield put({
      type: types.UPDATE_ANEDOT_CONTRIBUTION_SUCCESS,
      data: { anedotId: id },
    });
    const session = yield select(getUserSession);
    yield put(successToast(`${getLabel('Contribution', session)} dismissed`));
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put(errorToast(error));
  }
}

export function* doNotImport() {
  yield takeLatest(types.DO_NOT_IMPORT, handleDoNotImport);
}

export function* handleGetAggregateAmount({
  data: { electionCycle, electionYear, _id },
}) {
  try {
    const { data } = yield call(
      axios.get,
      `/api/filer/contacts/${_id}/contributions/aggregate?electionYear=${electionYear}&electionCycle=${electionCycle}`,
      { withCredentials: true },
    );

    yield put({
      type: types.GET_AGGREGATE_AMOUNT_SUCCESS,
      data: {
        amount: data,
        _id,
        electionCycle,
        electionYear,
      },
    });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put(errorToast(error));
  }
}

export function* getAggregateAmount() {
  yield takeEvery(types.GET_AGGREGATE_AMOUNT, handleGetAggregateAmount);
}
