import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, withRouter } from 'react-router-dom';
import AddContribution from '../Contributions/Add';
import AddEditDeposit from '../Deposits/AddEdit';
import AddExpenditure from '../Expenditures/Add';
import AddLoan from '../Loans/Add';
import AddReconciliation from '../Reconciliations/AddReconciliation';
import AddUser from '../ManageUsers/AddUser';
import AnedotContributions from '../Anedot';
import CashOnHand from '../Audit/CashOnHand';
import Contacts from '../Contacts';
import ContactsAdhoc from '../Lists/Contacts';
import ContactMerge from '../ContactMerge';
import ContributionLimits from '../Lists/ContributionLimits';
import ContributionApply from '../ContributionApply';
import ContributionUpload from '../ContributionUpload';
import ContributionsByDateRange from '../Lists/ContributionsByDateRange';
import Deposits from '../Deposits';
import DeveloperCivixAPI from '../DeveloperGACivixAPI';
import DonorMailingList from '../Lists/DonorMailingList';
import EditBillingInfoContainer from '../EditBillingInfo';
import EditCampaign from '../EditCampaign';
import EditContribution from '../Contributions/Edit';
import EditExpenditure from '../Expenditures/Edit';
import EditFECReport from '../Reports/FecEdit';
import EditLoan from '../Loans/Edit';
import EditReport from '../Reports/Edit';
import EditUser from '../ManageUsers/EditUser';
import ExpendituresByDateRange from '../Lists/ExpendituresByDateRange';
import Form6Form24ViewAllReports from '../Reports/Form6Form24ViewAll';
import ImportEarmarks from '../Import/Earmarks';
import InKindTransactions from '../Lists/InKindTransactions';
import ItemizedExpenditures from '../Lists/ItemizedExpenditures';
import Ledger from '../Ledger';
import Lists from '../Lists';
import ListsIndex from '../Lists/ListsIndex';
import MainDashboard from './MainDashboard';
import ManageUsers from '../ManageUsers/ManageUsers';
import ReconciliationReport from '../Reconciliations/ReconciliationReport';
import Reconciliations from '../Reconciliations';
import UnclearedTransactions from '../Lists/UnclearedTransactions';
import VerifyFECReport from '../Reports/VerifyFECReport';
import VerifyReport from '../Reports/Verify';
import ViewContributions from '../Contributions/ViewAll';
import ViewExpenditures from '../Expenditures/ViewAll';
import ViewLoans from '../Loans/ViewAll';
import ViewReports from '../Reports/ViewAll';
import ViewTBDReports from '../Reports/TBDViewAll';
import ImportList from '../FileImport/ImportList';
import ImportWizard from '../FileImport/ImportWizard';
import ImportErrors from '../FileImport/ImportErrors';
import ApiIntegration from '../ApiIntegration';

export const DashboardRoutes = ({ match }) => {
  const { path } = match;
  return (
    <Switch>
      <Route path={`${path}/listsIndex`} component={ListsIndex} />
      <Route path={`${path}/lists`} component={Lists} />
      <Route
        path={`${path}/contributionsLists/dateRange`}
        component={ContributionsByDateRange}
      />
      <Route
        path={`${path}/expendituresLists/itemized`}
        component={ItemizedExpenditures}
      />
      <Route
        path={`${path}/expendituresLists/dateRange`}
        component={ExpendituresByDateRange}
      />
      <Route
        path={`${path}/contactLists`}
        component={ContactsAdhoc}
      />
      <Route
        path={`${path}/unclearedTransactions`}
        component={UnclearedTransactions}
      />
      <Route
        path={`${path}/inKindTransactions`}
        component={InKindTransactions}
      />
      <Route path={`${path}/donorMailingList`} component={DonorMailingList} />
      <Route path={`${path}/ledger`} component={Ledger} />
      <Route path={`${path}/contributionLimits`} component={ContributionLimits} />
      <Route path={`${path}/contactMerge`} component={ContactMerge} />
      <Route path={`${path}/contacts`} component={Contacts} />
      <Route path={`${path}/editCampaign`} component={EditCampaign} />
      <Route
        path={`${path}/editBillingInfo`}
        component={EditBillingInfoContainer}
      />
      <Route path={`${path}/addContribution`} component={AddContribution} />
      <Route
        path={`${path}/editContribution/:id`}
        component={EditContribution}
      />
      <Route path={`${path}/addExpenditure`} component={AddExpenditure} />
      <Route path={`${path}/editExpenditure/:id`} component={EditExpenditure} />
      <Route path={`${path}/addLoan`} component={AddLoan} />
      <Route path={`${path}/editLoan/:id`} component={EditLoan} />
      <Route path={`${path}/editLoan/:id`} component={AddLoan} />
      <Route path={`${path}/contributions`} component={ViewContributions} />
      <Route path={`${path}/expenditures`} component={ViewExpenditures} />
      <Route path={`${path}/loans`} component={ViewLoans} />
      <Route path={`${path}/reports/verify/:id`} component={VerifyReport} />
      <Route path={`${path}/reports`} component={ViewReports} />
      <Route path={`${path}/tbdReports`} component={ViewTBDReports} />
      <Route path={`${path}/48HourReports`} component={Form6Form24ViewAllReports} />
      <Route
        path={`${path}/editFECReport/verify/:id`}
        component={VerifyFECReport}
      />
      <Route path={`${path}/editFECReport/:id`} component={EditFECReport} />
      <Route path={`${path}/editReport/:id`} component={EditReport} />
      <Route path={`${path}/editUser/:id`} component={EditUser} />
      <Route path={`${path}/manageUsers`} component={ManageUsers} />
      <Route path={`${path}/addUser`} component={AddUser} />
      <Route path={`${path}/deposits`} component={Deposits} />
      <Route path={`${path}/addDeposit`} component={AddEditDeposit} />
      <Route path={`${path}/editDeposit/:id`} component={AddEditDeposit} />
      <Route path={`${path}/addReconciliation`} component={AddReconciliation} />
      <Route
        path={`${path}/editReconciliation/:id`}
        component={AddReconciliation}
      />
      <Route
        path={`${path}/reconciliationReport/:id`}
        component={ReconciliationReport}
      />
      <Route path={`${path}/reconciliations`} component={Reconciliations} />
      <Route
        path={`${path}/creditCardProcessing`}
        component={AnedotContributions}
      />
      <Route
        path={`${path}/import/aristotle/earmarks`}
        component={ImportEarmarks}
      />
      <Route
        path={`${path}/audit/cashonhand`}
        component={CashOnHand}
      />
      <Route
        path={`${path}/apiIntegration`}
        component={ApiIntegration}
      />
      <Route
        path={`${path}/dev/gaCivixAPI`}
        component={DeveloperCivixAPI}
      />
      <Route
        path={`${path}/dev/contributionUpload`}
        component={ContributionUpload}
      />
      <Route
        path={`${path}/dev/contributionApply`}
        component={ContributionApply}
      />
      <Route
        path={`${path}/dev/importList`}
        component={ImportList}
      />
      <Route
        path={`${path}/dev/uploadImportFile/:id?`}
        component={ImportWizard}
      />
      <Route path={`${path}/dev/importErrors/:id`} component={ImportErrors} />
      <Route path="" component={MainDashboard} />
    </Switch>
  );
};

DashboardRoutes.propTypes = {
  match: PropTypes.object.isRequired,
};

export default withRouter(DashboardRoutes);
