export const types = {
  GET_IMPORT_ERRORS: 'GET_IMPORT_ERRORS',
  GET_IMPORT_ERRORS_PROCESSING: 'GET_IMPORT_ERRORS_PROCESSING',
  GET_IMPORT_ERRORS_SUCCESS: 'GET_IMPORT_ERRORS_SUCCESS',
  GET_IMPORT_ERRORS_FAILURE: 'GET_IMPORT_ERRORS_FAILURE',
  SAVE_IMPORT_CORRECTIONS: 'SAVE_IMPORT_CORRECTIONS',
  SAVE_IMPORT_CORRECTIONS_PROCESSING: 'SAVE_IMPORT_CORRECTIONS_PROCESSING',
  SAVE_IMPORT_CORRECTIONS_SUCCESS: 'SAVE_IMPORT_CORRECTIONS_SUCCESS',
  SAVE_IMPORT_CORRECTIONS_FAILURE: 'SAVE_IMPORT_CORRECTIONS_FAILURE',
};

export const actions = {
  getImportErrors(fileId) {
    return { type: types.GET_IMPORT_ERRORS, data: { fileId } };
  },
  saveImportCorrections(fileId, row) {
    return { type: types.SAVE_IMPORT_CORRECTIONS, data: { fileId, row } };
  },
};
