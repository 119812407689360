import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { Grid, GridRow, Column } from '../../common';

export default class Section10 extends Component {
  static propTypes = {
    q10: PropTypes.shape({
      inKind: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    handleChange: PropTypes.func.isRequired,
  };

  render() {
    const { q10, handleChange } = this.props;
    return (
      <GridRow>
        <Column sm={1} classNames="ms-textAlignRight">
          <h3 className="form-label">10</h3>
        </Column>
        <Column sm={11}>
          <Grid>
            <GridRow>
              <Column sm={8}>
                <p>
                  {
                    'Total amount of all separate expenditures of $100.00 or less that were made in this reporting period and not listed in the "Itemized Expenditures" section'
                  }
                </p>
              </Column>
              <Column sm={2} classNames="ms-textAlignCenter money-input">
                <TextField
                  borderless
                  inputMode="decimal"
                  styles={{
                    padding: '0 4px',
                    fieldGroup: { borderBottom: '1px solid #999' },
                    field: { textAlign: 'center' },
                  }}
                  value={q10.inKind}
                  onChange={handleChange('q10')('inKind')}
                />
              </Column>
              <Column sm={2} classNames="ms-textAlignCenter money-input">
                <TextField
                  borderless
                  inputMode="decimal"
                  styles={{
                    padding: '0 4px',
                    fieldGroup: { borderBottom: '1px solid #999' },
                    field: { textAlign: 'center' },
                  }}
                  value={q10.amount}
                  onChange={handleChange('q10')('amount')}
                />
              </Column>
            </GridRow>
          </Grid>
        </Column>
      </GridRow>
    );
  }
}
