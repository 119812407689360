import { takeLatest, take, put, call } from 'redux-saga/effects';
import axios from 'axios';
import qs from 'qs';
import { eventChannel } from 'redux-saga';
import { types as listTypes } from '../actions/listActions';
import { types as messagingTypes } from '../actions/messagingActions';
import { toastTypes } from '../helpers/constants';
import { getServerSideErrorMessage } from '../helpers/util';

export function* handleGetList({ data }) {
  try {
    yield put({ type: listTypes.GET_LIST_PROCESSING });

    const params = qs.stringify(data, {
      addQueryPrefix: true,
      arrayFormat: 'brackets',
    });

    const {
      data: { records, count },
    } = yield call(axios.get, `/api/filer/accounting/lists${params}`, {
      withCredentials: true,
    });
    yield put({
      type: listTypes.GET_LIST_SUCCESS,
      data: {
        items: records,
        count,
      },
    });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put({
      type: messagingTypes.SET_TOAST,
      data: {
        message: error,
        toastType: toastTypes.ERROR,
      },
    });
  }
}

export function* getList() {
  yield takeLatest(listTypes.GET_LIST, handleGetList);
}

function isAgendaJobComplete(totalSeconds, pause, id) {
  return eventChannel(emitter => {
    const iv = setInterval(() => {
      totalSeconds -= pause;
      if (totalSeconds > 0) {
        axios
          .get(`/api/jobs/getCompletedJob/${id}`)
          .then(res => {
            if (res.data.isComplete) {
              emitter({ ...res.data });
            }
          })
          .catch(error => {
            emitter({ error });
          });
      } else {
        // this causes the channel to close
        emitter(false);
      }
    }, pause * 1000);
    // The subscriber must return an unsubscribe function
    return () => {
      clearInterval(iv);
    };
  });
}

export function* handleGetCsv({ data }) {
  try {
    yield put({ type: listTypes.GET_CSV_PROCESSING });
    const nameMap = {
      'expenditures-by-date-range-fields': 'expenditures.csv',
      'contributions-by-date-range-fields': 'receipts.csv',
      'contacts-fields': 'contacts.csv',
      'deposit-entry-fields': 'deposits.csv',
    };

    const { data: { taskId } } = yield call(axios.post, '/api/filer/accounting/lists/csv', data, {
      withCredentials: true,
    });
    const channel = yield call(isAgendaJobComplete, 60 * 30, 2, taskId);
    const channelResult = yield take(channel);
    channel.close();
    if (channelResult.isFailed) {
      yield put({ type: listTypes.GET_CSV_FAILURE });
      yield put({
        type: messagingTypes.SET_TOAST,
        data: {
          message: channelResult.failReason,
          toastType: toastTypes.ERROR,
        },
      });
    } else {
      const result = yield call(axios.get, `/api/filer/accounting/lists/csv/${taskId}`, {
        withCredentials: true,
      });
      const link = document.createElement('a');
      const blob = new Blob([result.data], { type: 'text/csv' });
      link.setAttribute('href', window.URL.createObjectURL(blob));
      link.setAttribute(
        'download',
        nameMap[data.fields] || 'adhoc_custom.csv',
      );
      link.click();
      yield put({ type: listTypes.GET_CSV_SUCCESS });
    }
  } catch (e) {
    yield put({ type: listTypes.GET_CSV_FAILURE });
    const error = getServerSideErrorMessage(e);
    yield put({
      type: messagingTypes.SET_TOAST,
      data: {
        message: error,
        toastType: toastTypes.ERROR,
      },
    });
  }
}

export function* getCsv() {
  yield takeLatest(listTypes.GET_CSV, handleGetCsv);
}

export function* handleGetContributionLimitCsv({ data }) {
  try {
    yield put({ type: listTypes.GET_CONTRIBUTION_LIMIT_CSV_PROCESSING });

    const params = qs.stringify(data, {
      addQueryPrefix: true,
      arrayFormat: 'brackets',
    });

    const { data: { taskId } } = yield call(axios.get, `/api/filer/accounting/lists/limitsCsv${params}`, {
      withCredentials: true,
    });
    const channel = yield call(isAgendaJobComplete, 60 * 30, 2, taskId);
    const channelResult = yield take(channel);
    channel.close();
    if (channelResult.isFailed) {
      yield put({ type: listTypes.GET_CONTRIBUTION_LIMIT_CSV_FAILURE });
      yield put({
        type: messagingTypes.SET_TOAST,
        data: {
          message: channelResult.failReason,
          toastType: toastTypes.ERROR,
        },
      });
    } else {
      const result = yield call(axios.get, `/api/filer/accounting/lists/csv/${taskId}`, {
        withCredentials: true,
      });
      const link = document.createElement('a');
      const blob = new Blob([result.data], { type: 'text/csv' });
      link.setAttribute('href', window.URL.createObjectURL(blob));
      link.setAttribute(
        'download',
        'contribution_limits.csv',
      );
      link.click();
      yield put({ type: listTypes.GET_CONTRIBUTION_LIMIT_CSV_SUCCESS });
    }
  } catch (e) {
    yield put({ type: listTypes.GET_CONTRIBUTION_LIMIT_CSV_FAILURE });
    const error = getServerSideErrorMessage(e);
    yield put({
      type: messagingTypes.SET_TOAST,
      data: {
        message: error,
        toastType: toastTypes.ERROR,
      },
    });
  }
}

export function* getContributionLimitCsv() {
  yield takeLatest(listTypes.GET_CONTRIBUTION_LIMIT_CSV, handleGetContributionLimitCsv);
}

export function* handleGetUnclearedTransactions({ data }) {
  try {
    yield put({ type: listTypes.GET_LIST_PROCESSING });

    const params = qs.stringify(data, {
      addQueryPrefix: true,
      arrayFormat: 'brackets',
    });
    const {
      data: { records, count },
    } = yield call(axios.get, `/api/filer/accounting/listsUncleared${params}`, {
      withCredentials: true,
    });

    yield put({
      type: listTypes.GET_LIST_SUCCESS,
      data: { items: records, count },
    });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put({
      type: messagingTypes.SET_TOAST,
      data: {
        message: error,
        toastType: toastTypes.ERROR,
      },
    });
  }
}

export function* getUnclearedTransactions() {
  yield takeLatest(
    listTypes.GET_LIST_UNRECONCILED,
    handleGetUnclearedTransactions,
  );
}
