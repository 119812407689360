import Papa from 'papaparse';
import { cleanUpMoneyEntryToFloat } from './currencyHelper';

export const parseAmex = (content) => {
  const { data: lines } = Papa.parse(content, {
    header: false,
  });
  const retList = [];
  for (let i = 0; i < lines.length; i += 1) {
    const line = lines[i];
    const expenditureDate = line[0];
    const cardHolder = line[3];
    const searchString = (line[2] || '').replace(/\s+/g, ' ');
    const amount = cleanUpMoneyEntryToFloat(line[5]);
    const address1 = line[8];
    const cityState = line[9];
    const zip = line[10];
    const reference = (line[12] || '').replace(/'/g, '');
    const purpose = line[13];
    retList.push({
      id: i,
      expenditureDate,
      cardHolder,
      searchString,
      amount,
      address1,
      cityState,
      zip,
      reference,
      purpose,
    });
  }
  return retList;
};

const formatDate = (dt) => {
  return (dt || '').replace(/-/g, '/');
};

export const parseServisFirst = (content) => {
  const { data: lines } = Papa.parse(content, {
    header: false,
  });
  const retList = [];
  for (let i = 0; i < lines.length; i += 1) {
    const line = lines[i];
    // Convert to MM/DD/YYYY because the date picker handles that better than YYYY-MM-DD
    const expenditureDate = formatDate(line[1]) || formatDate(line[0]);
    const cardHolder = line[8];
    const searchString = (line[3] || '').replace(/\s+/g, ' ');
    const amount = cleanUpMoneyEntryToFloat(line[4]);
    const address1 = '';
    const cityState = line[6];
    const zip = line[10];
    const reference = line[11];
    const purpose = line[5];
    retList.push({
      id: i,
      expenditureDate,
      cardHolder,
      searchString,
      amount,
      address1,
      cityState,
      zip,
      reference,
      purpose,
    });
  }
  return retList;
};
