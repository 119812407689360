import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { ChoiceGroup } from 'office-ui-fabric-react/lib/ChoiceGroup';
import {
  MessageBar,
  MessageBarType,
} from 'office-ui-fabric-react/lib/MessageBar';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { Dropdown } from 'office-ui-fabric-react/lib/Dropdown';
import { Label } from 'office-ui-fabric-react/lib/Label';
import { CaretDown } from '../icons';

export const PaymentTypes = ({
  required,
  selectedKey,
  onChange,
  errorMessage,
  label,
}) => {
  const onRenderOption = option => (
    <div className="drop-down-payment-type-option">
      <Icon
        style={{ marginRight: 16 }}
        aria-hidden={true}
        className={option.iconProps.className}
        title={option.text}
      />
      <span>{option.text}</span>
    </div>
  );

  const options = [
    {
      key: 'Cash',
      text: 'Cash',
      iconProps: { iconName: 'MoneyBillAlt' },
    },
    {
      key: 'Check',
      text: 'Check',
      iconProps: { iconName: 'MoneyCheckAlt' },
    },
    {
      key: 'Credit Card',
      text: 'Credit Card',
      iconProps: { iconName: 'CreditCard' },
    },
    {
      key: 'Debit Card',
      text: 'Debit Card',
      iconProps: { iconName: 'CreditCardAlt' },
    },
    {
      key: 'Wire Transfer',
      text: 'Wire Transfer',
      iconProps: { iconName: 'ExchangeAlt' },
    },
    {
      key: 'ACH',
      text: 'ACH',
      iconProps: { iconName: 'University' },
    },
    {
      key: 'Other',
      text: 'Other',
      iconProps: { iconName: 'ExclamationCircle' },
    },
  ];

  return (
    <Fragment>
      <Label required={required}>{label}</Label>
      <Dropdown
        onRenderCaretDown={() => <CaretDown />}
        onRenderOption={onRenderOption}
        className="ms-hiddenLgUp"
        errorMessage={errorMessage}
        options={[{ key: 0, text: 'Select' }, ...options]}
        onChange={onChange}
        selectedKey={selectedKey}
      />
      {errorMessage && (
        <MessageBar
          messageBarType={MessageBarType.error}
          className="ms-hiddenMdDown"
        >
          {errorMessage}
        </MessageBar>
      )}
      <ChoiceGroup
        className="ms-hiddenMdDown"
        selectedKey={selectedKey}
        onChange={onChange}
        options={options}
      />
    </Fragment>
  );
};

PaymentTypes.propTypes = {
  required: PropTypes.bool,
  selectedKey: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  label: PropTypes.string,
};

PaymentTypes.defaultProps = {
  required: false,
  selectedKey: null,
  errorMessage: '',
  label: 'Payment Type',
};

export default PaymentTypes;
