import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, GridRow, Column } from '../../common';

export default class Section11 extends Component {
  static propTypes = {
    q11: PropTypes.shape({
      inKind: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    q11Sum: PropTypes.shape({
      inKind: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  };

  static defaultProps = {
    q11: {
      inKind: 0,
      amount: 0,
    },
    q11Sum: {
      inKind: 0,
      amount: 0,
    },
  };

  render() {
    const { q11Sum, q11 } = this.props;
    return (
      <GridRow>
        <Column sm={1} classNames="ms-textAlignRight">
          <h3 className="form-label">11</h3>
        </Column>
        <Column sm={11}>
          <Grid>
            <GridRow>
              <Column sm={8}>
                <p>{'Total expenditures reported this period. '}</p>
                <p>{'(Line 9 + 10)'}</p>
              </Column>
              <Column sm={2} classNames="ms-textAlignCenter money-input">
                <p style={{ margin: '16px 12px 0 0' }}>
                  {q11Sum.inKind !== q11.inKind ? q11Sum.inKind : q11.inKind}
                </p>
              </Column>
              <Column sm={2} classNames="ms-textAlignCenter money-input">
                <p style={{ margin: '16px 12px 0 0' }}>
                  {q11Sum.amount !== q11.amount ? q11Sum.amount : q11.amount}
                </p>
              </Column>
            </GridRow>
          </Grid>
        </Column>
      </GridRow>
    );
  }
}
