import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import {
  MessageBar,
  MessageBarType,
} from 'office-ui-fabric-react/lib/MessageBar';
import { Grid, GridRow, Column, PasswordField } from '../../components/common';

const SignupPasswordForm = ({
  password,
  passwordError,
  confirmPassword,
  confirmPasswordError,
  actions,
  completionError,
}) => {
  const focusRef = useRef(null);
  useEffect(() => {
    focusRef.current.focus();
  }, []);

  return (
    <form noValidate onSubmit={actions.submitAndLogin}>
      <Grid>
        {completionError && (
          <GridRow>
            <Column sm={8} classNames="ms-smPush2">
              <MessageBar messageBarType={MessageBarType.error}>
                {completionError}
              </MessageBar>
            </Column>
          </GridRow>
        )}
        <GridRow>
          <Column sm={8} classNames="ms-smPush2">
            <PasswordField
              onChange={actions.handleTextChange('password')}
              required
              value={password}
              label="Create Password"
              errorMessage={passwordError}
              componentRef={focusRef}
            />
          </Column>
        </GridRow>
        <GridRow>
          <Column sm={8} classNames="ms-smPush2">
            <PasswordField
              onChange={actions.handleTextChange('confirmPassword')}
              required
              value={confirmPassword}
              label="Confirm New Password"
              errorMessage={confirmPasswordError}
            />
          </Column>
        </GridRow>
        <GridRow>
          <Column classNames="ms-textAlignCenter">
            <PrimaryButton
              text="Submit and Login"
              type="submit"
              onClick={actions.submitAndLogin}
            />
          </Column>
        </GridRow>
      </Grid>
    </form>
  );
};

SignupPasswordForm.propTypes = {
  password: PropTypes.string,
  passwordError: PropTypes.string,
  confirmPassword: PropTypes.string,
  confirmPasswordError: PropTypes.string,
  actions: PropTypes.object.isRequired,
  completionError: PropTypes.string,
};

SignupPasswordForm.defaultProps = {
  password: '',
  passwordError: '',
  confirmPassword: '',
  confirmPasswordError: '',
  completionError: '',
};

export default SignupPasswordForm;
