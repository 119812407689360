import { isInvalidDate } from '../../helpers/util';

export const validate = (state, campaignRecordMaintainer, reportType) => {
  const errors = {};

  if (reportType === '') {
    if (state.filingStatus === 0) {
      errors.filingStatusError = 'Please choose a filing status';
    }

    if (state.reportingPeriod === -1) {
      errors.reportingPeriodError = 'Please choose a reporting period';
    }
  }

  if (reportType === '' || reportType === 'Termination') {
    if (state.reportYear === 0) {
      errors.reportYearError = 'Please choose a report year';
    }
  }

  if (reportType === 'Termination') {
    if (campaignRecordMaintainer.firstName.length === 0) {
      errors.firstNameError = 'First name is required';
    }

    if (campaignRecordMaintainer.lastName.length === 0) {
      errors.lastNameError = 'Last name is required';
    }

    if (campaignRecordMaintainer.addressLine1.length === 0) {
      errors.addressLine1Error = 'Address 1 is required';
    }

    if (campaignRecordMaintainer.city.length === 0) {
      errors.cityError = 'City is required';
    }

    if (campaignRecordMaintainer.state === 0) {
      errors.stateError = 'State is required';
    }

    if (campaignRecordMaintainer.zipCode.length === 0) {
      errors.zipCodeError = 'Zip code is required';
    }

    if (campaignRecordMaintainer.phone1.length === 0) {
      errors.phone1Error = 'Phone is required';
    }

    if (campaignRecordMaintainer.email.length === 0) {
      errors.emailError = 'Email is required';
    }
  }

  if (state.electionDate === 0) {
    errors.electionDateError = 'Please choose an election date';
  }

  if (!state.startDate) {
    errors.startDateError = 'Start date is required';
  } else if (state.startDate && isInvalidDate(state.startDate)) {
    errors.startDateError = 'Invalid Date';
  }

  if (!state.endDate) {
    errors.endDateError = 'End date is required';
  } else if (state.endDate && isInvalidDate(state.endDate)) {
    errors.endDateError = 'Invalid Date';
  }

  if (state.filingStatus !== 1 && state.previousReportId === 0 && reportType !== 'TBD') {
    errors.previousReportIdError = 'A previous report is necessary for this report type';
  }

  if (
    state.startDate &&
    state.endDate &&
    !isInvalidDate(state.startDate) &&
    !isInvalidDate(state.endDate) &&
    new Date(state.startDate).getTime() > new Date(state.endDate).getTime()
  ) {
    errors.startDateError = 'Start Date cannot be after End Date';
    errors.endDateError = 'End Date cannot be before Start Date';
  }

  return errors;
};
