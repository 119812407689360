import React, { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
  MessageBar,
  MessageBarType,
  ActionButton,
  CommandBar,
  Icon,
  List,
  Separator,
  Spinner,
  SpinnerType,
} from 'office-ui-fabric-react';
import { types as depositActions } from '../../actions/depositActions';
import { BackButton, Grid, GridRow, Column } from '../../components/common';
import ConfirmDeleteDialog from '../../components/Dialogs/ConfirmDeleteDialog';
import { formatCurrency, formatDate, sortByField } from '../../helpers/util';
import { sortDirections, statuses } from '../../helpers/constants';
import { DepositDetailsPanel } from '../../components/Panels';
import { getLabel } from '../../helpers/labelHelper';
import {
  getUserSession,
  // getUndepositedItems,
  getSingleDeposit,
  getDepositsList,
  getDepositListStatus,
  getUndepositedTotal,
  getUndepositedTotalStatus,
  getListCSVStatus,
} from '../../selectors';
import { actions, initialState, DepositsReducer } from './depositsReducer';
import './Deposits.css';
import { types as listActions } from '../../actions/listActions';

const sortMap = {
  name: 'Name',
  amount: 'Amount',
  depositDate: 'Deposit Date',
};

const sortDirectionMap = {
  [sortDirections.ASC]: '(ascending)',
  [sortDirections.DESC]: '(descending)',
};

const Deposits = ({ history }) => {
  const [state, localDispatch] = useReducer(DepositsReducer, initialState);
  const reduxDispatch = useDispatch();
  const list = useSelector(getDepositsList);
  // const undeposited = useSelector(getUndepositedItems);
  const singleDeposit = useSelector(getSingleDeposit);
  const session = useSelector(getUserSession);
  const getAllDepositsStatus = useSelector(getDepositListStatus);
  const depositsTotalStatus = useSelector(getUndepositedTotalStatus);
  const undepositedTotal = useSelector(getUndepositedTotal);
  const listCSVStatus = useSelector(getListCSVStatus);

  useEffect(() => {
    localDispatch({
      type: actions.SET_UNDEPOSITED,
      data: undepositedTotal,
    });
  }, [undepositedTotal]);

  useEffect(() => {
    reduxDispatch({
      type: depositActions.GET_UNDEPOSITED_TOTAL,
    });
    reduxDispatch({
      type: depositActions.GET_DEPOSIT_LIST,
    });
    return () => {
      reduxDispatch({
        type: depositActions.CLEAR_DEPOSITS,
      });
    };
  }, []);

  useEffect(() => {
    if (singleDeposit && !state.detailsPanelOpen) {
      localDispatch({
        type: actions.SHOW_DEPOSIT_PANEL,
      });
    } else if (!singleDeposit && state.detailsPanelOpen) {
      localDispatch({
        type: actions.HIDE_DEPOSIT_PANEL,
      });
    }
  }, [singleDeposit]);

  const setLoadingCSV = (isLoadingCSV, downloadId) => {
    localDispatch({
      type: actions.SET_IS_LOADING_CSV,
      data: { isLoadingCSV, downloadId },
    });
  };

  useEffect(() => {
    if (listCSVStatus === statuses.SUCCESS || listCSVStatus === statuses.ERROR) {
      setLoadingCSV(false, null);
    }
  }, [listCSVStatus]);

  const createNewDeposit = () => {
    history.push('/filer/addDeposit');
  };

  const mapItems = () => {
    const { sortField, sortDirection, filterText } = state;
    return list
      .filter(c => c.name.toLowerCase().includes(filterText.toLowerCase()))
      .map(d => ({ ...d, key: d._id }))
      .sort(sortByField(sortField, sortDirection));
  };

  const deleteDeposit = depositToDelete => {
    localDispatch({
      type: actions.OPEN_CONFIRM_DEPOSIT_PANEL,
      data: { depositToDelete },
    });
  };

  const showDetailsPanel = _id => {
    reduxDispatch({
      type: depositActions.GET_SINGLE_DEPOSIT,
      data: {
        id: _id,
      },
    });
  };

  const hideDetailsPanel = () => {
    reduxDispatch({
      type: depositActions.CLEAR_SINGLE_DEPOSIT,
    });
  };

  const hideDeleteDepositPanel = () => {
    localDispatch({ type: actions.CLOSE_CONFIRM_DEPOSIT_PANEL });
  };

  const doDeleteDeposit = () => {
    reduxDispatch({
      type: depositActions.DELETE_DEPOSIT,
      data: {
        id: state.depositToDelete._id,
      },
    });
    hideDeleteDepositPanel();
  };

  const onSort = (field, direction) => () => {
    localDispatch({
      type: actions.SET_SORT,
      data: {
        sortField: field,
        sortDirection: direction,
      },
    });
  };

  const getCommandBarFarItems = () => [
    {
      key: 'new-deposit',
      name: 'Add New',
      iconProps: {
        iconName: 'PlusCircle',
      },
      onClick: createNewDeposit,
    },
  ];

  const getCommandBarItems = () => {
    const { sortField, sortDirection } = state;
    if (list.length === 0) {
      return [];
    }
    const items = [
      {
        key: 'sort',
        name: 'Sort',
        iconProps: {
          iconName: 'Sort',
        },
        subMenuProps: {
          items: [
            {
              key: 'sort-by-name-asc',
              name: 'Name (ascending)',
              iconProps: {
                iconName: 'SortUp',
              },
              onClick: onSort('name', sortDirections.ASC),
            },
            {
              key: 'sort-by-name-desc',
              name: 'Name (descending)',
              iconProps: {
                iconName: 'SortDown',
              },
              onClick: onSort('name', sortDirections.DESC),
            },
            {
              key: 'sort-by-amount-asc',
              name: 'Amount (ascending)',
              iconProps: {
                iconName: 'SortUp',
              },
              onClick: onSort('amount', sortDirections.ASC),
            },
            {
              key: 'sort-by-amount-desc',
              name: 'Amount (descending)',
              iconProps: {
                iconName: 'SortDown',
              },
              onClick: onSort('amount', sortDirections.DESC),
            },
            {
              key: 'sort-by-date-asc',
              name: 'Date (ascending)',
              iconProps: {
                iconName: 'SortUp',
              },
              onClick: onSort('depositDate', sortDirections.ASC),
            },
            {
              key: 'sort-by-date-desc',
              name: 'Date (descending)',
              iconProps: {
                iconName: 'SortDown',
              },
              onClick: onSort('depositDate', sortDirections.DESC),
            },
          ],
        },
      },
    ];

    if (sortField && sortDirection !== sortDirections.NONE) {
      items[0].subMenuProps.items = items[0].subMenuProps.items.concat({
        key: 'remove-sort',
        name: 'Remove Sort',
        iconProps: {
          iconName: 'Times',
        },
        onClick: onSort('depositDate', sortDirections.DESC),
      });
    }

    return items;
  };

  const createDepositTicket = _id => {
    const { origin } = window.location;
    const url = `${origin}/depositTicket/${_id}`;
    const win = window.open(url, '_blank');
    win.focus();
  };

  const exportToCSV = async _id => {
    setLoadingCSV(true, _id);
    const filter = [
      {
        column: 'transactionType',
        expression: 'equals',
        value: 'contribution',
      },
      {
        column: 'transactionType',
        expression: 'exists',
        value: 'contribution',
      },
      {
        column: 'transactionType',
        expression: 'equals',
        value: 'expenditure',
      },
      {
        column: 'transactionType',
        expression: 'exists',
        value: 'expenditure',
      },
      {
        column: 'depositId',
        expression: 'equals',
        value: _id,
      },
    ];
    reduxDispatch({
      type: listActions.GET_CSV,
      data: {
        fields: 'deposit-entry-fields',
        filters: JSON.stringify(
          filter.reduce((list, filter) => {
            const { column, expression, value } = filter;
            list = [
              ...list,
              {
                column,
                expression,
                value: Array.isArray(value)
                  ? value.join(',')
                  : column === 'amount' || column === 'aggregateAmount'
                    ? +value
                    : value + '',
              },
            ];
            return list;
          }, []),
        ),
      },
    });
  };

  const onRenderCell = item => (
    <div
      className="fr-deposit-row-item"
      data-is-focusable={true}
      key={item._id}
    >
      <div className="deposit-details">
        <h3>{item.name}</h3>
        <p className="amount">{formatCurrency(item.amount)}</p>
        <div className="deposit-info-actions">
          <ActionButton
            onClick={() => showDetailsPanel(item._id)}
            text="Details"
            iconProps={{
              iconName: 'Info',
            }}
          />
          <ActionButton
            iconProps={{
              iconName: 'PencilAlt',
            }}
            text="Edit"
            onClick={() => history.push(`/filer/editDeposit/${item._id}`)}
            className="deposit-action-btn"
          />
          <ActionButton
            text="Print Deposit Ticket"
            iconProps={{
              iconName: 'Print',
            }}
            onClick={() => createDepositTicket(item._id)}
          />
          <ActionButton
            text="Reconciled"
            iconProps={{
              iconName: item.reconciliationId ? 'CheckSquare' : 'UnCheckSquare',
            }}
            disabled
            style={{ color: 'rgb(50, 49, 48)' }}
          />
          <ActionButton
            text={(state.isLoadingCSV && item._id === state.downloadId) ? 'Getting CSV' : 'Download CSV'}
            iconProps={{
              iconName: 'FileCSV',
            }}
            disabled={state.isLoadingCSV}
            onClick={() => exportToCSV(item._id)}
            style={{ color: 'rgb(50, 49, 48)' }}
          />
        </div>
      </div>
      <div className="deposit-row-actions">
        <ActionButton
          iconProps={{
            iconName: 'TrashAlt',
          }}
          onClick={() => deleteDeposit(item)}
          text="Delete"
        />
        <span>Deposited {formatDate(item.depositDate)}</span>
      </div>
    </div>
  );

  const rowsNotLoaded = [statuses.NOT_STARTED, statuses.PROCESSING].includes(getAllDepositsStatus);
  const totalNotReturned = [statuses.NOT_STARTED, statuses.PROCESSING].includes(depositsTotalStatus);

  if (rowsNotLoaded || totalNotReturned) {
    return (
      <div className="Deposits-wrapper">
        <BackButton history={history} />
        <div className="Deposits depth-1">
          <h3>Deposits</h3>
          <div>
            <Spinner type={SpinnerType.large} label="Loading" />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="Deposits-wrapper">
      <BackButton history={history} />
      <div className="Deposits depth-1">
        <h3>Deposits</h3>
        <MessageBar messageBarType={MessageBarType.warning}>
          <span>
            {'You have '}
            <strong>{`${formatCurrency(state.undepositedTotal)}`}</strong>
            {` in undeposited ${getLabel('transactions', session)}!`}
          </span>
        </MessageBar>
        <Grid>
          <GridRow>
            <Column>
              <Separator />
              <CommandBar
                items={getCommandBarItems()}
                farItems={getCommandBarFarItems()}
              />
              <Separator />
            </Column>
          </GridRow>
          {list.length > 0 && (
            <GridRow>
              <Column>
                <div className="deposits-list" data-is-scrollable={false}>
                  <p>{`Sorted By ${sortMap[state.sortField]} ${
                    sortDirectionMap[state.sortDirection]
                  }`}</p>
                  <List items={mapItems()} onRenderCell={onRenderCell} />
                </div>
              </Column>
            </GridRow>
          )}
        </Grid>
        {getAllDepositsStatus === statuses.SUCCESS && list.length === 0 && (
          <>
            <Grid>
              <GridRow>
                <Column>
                  <div className="no-deposits">
                    <Icon
                      iconName="ListAlt"
                      styles={{ root: { fontSize: 72, color: '#71afe5' } }}
                    />
                    <h2>{'There are no deposits for this campaign'}</h2>
                  </div>
                </Column>
              </GridRow>
            </Grid>
          </>
        )}
      </div>
      <DepositDetailsPanel
        session={session}
        item={singleDeposit}
        isOpen={state.detailsPanelOpen}
        closePanel={hideDetailsPanel}
        createDepositTicket={() => createDepositTicket(singleDeposit._id)}
      />
      <ConfirmDeleteDialog
        dialogHidden={!state.deleteDepositPanelOpen}
        cancel={hideDeleteDepositPanel}
        confirm={doDeleteDeposit}
        itemType="Deposit"
        message={state.depositToDelete.name ? `Remove ${state.depositToDelete.name} for ${formatCurrency(state.depositToDelete.amount)}?` : ''}
      />
    </div>
  );
};

Deposits.propTypes = {
  history: PropTypes.object.isRequired,
};

export default Deposits;
