import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'office-ui-fabric-react/lib/Dropdown';
import { fecContributionCategories } from '../../helpers/constants';
import { getLabel } from '../../helpers/labelHelper';
import { CaretDown } from '../icons';

const ContributionCategoryPicker = ({
  selectedKey,
  onChange,
  errorMessage,
  required,
  className,
  session,
}) => (
  <Dropdown
    onRenderCaretDown={() => <CaretDown />}
    className={className}
    errorMessage={errorMessage}
    label={`${getLabel('Contribution', session)} Category`}
    required={required}
    placeholder="Select"
    selectedKey={selectedKey}
    onChange={onChange}
    options={[{ key: 0, text: 'Select' }, ...fecContributionCategories]}
  />
);

ContributionCategoryPicker.propTypes = {
  selectedKey: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  required: PropTypes.bool,
  className: PropTypes.string,
  session: PropTypes.object.isRequired,
};

ContributionCategoryPicker.defaultProps = {
  errorMessage: '',
  required: false,
  className: '',
};

export default ContributionCategoryPicker;
