import React from 'react';
import PropTypes from 'prop-types';
import { GridRow, Column, MoneyField } from '../../common';
import { getNetOperatingExpenditures } from '../../../pages/Reports/fecReportHelper';

const FECSection7c = ({ state }) => (
  <GridRow>
    <Column sm={4} classNames="sub-question">
      <p>
        (c) Net Operating Expenditures
        <br />
        {'(subtract Line 7(b) from Line 7(a))'}
      </p>
    </Column>
    <Column sm={4} classNames="entry-row">
      <MoneyField value={getNetOperatingExpenditures('A', state)} disabled />
    </Column>
    <Column sm={4} classNames="entry-row">
      <MoneyField value={getNetOperatingExpenditures('B', state)} disabled />
    </Column>
  </GridRow>
);

FECSection7c.propTypes = {
  state: PropTypes.object.isRequired,
};

export default FECSection7c;
