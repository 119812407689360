import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { GridRow, Column, MoneyField } from '../../common';

const FECSection23 = ({ q23 }) => {
  return (
    <Fragment>
      <GridRow>
        <Column sm={8}>
          <p>23. CASH ON HAND AT BEGINNING OF REPORTING PERIOD</p>
        </Column>
        <Column sm={4}>
          <MoneyField
            disabled
            value={q23 || 0}
          />
        </Column>
      </GridRow>
    </Fragment>
  );
};

FECSection23.propTypes = {
  q23: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default FECSection23;
