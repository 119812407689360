import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { Image } from 'office-ui-fabric-react/lib/Image';
import { Link } from 'office-ui-fabric-react/lib/Link';
import { Dropdown } from 'office-ui-fabric-react/lib/Dropdown';
import SignupForm from './SignupForm';
import { userActions } from '../../actions/userActions';
import SignupVerification from './SignupVerification';
import SignupPasswordForm from './SignupPasswordForm';
import { Toast } from '../../components/Toast';
import { emailRegex } from '../../helpers/constants';
import {
  validateSignupForm,
  validateVerificationCode,
  validatePasswordForm,
} from './SignupFormValidations';
import FRLogo from '../../assets/img/HRFrontRunner.png';
import { CaretDown } from '../../components/icons';
import { Grid, GridRow, Column } from '../../components/common';
import './Signup.css';

const campaignCreationOptions = [
  { key: 1, text: 'I am a Candidate and want to create a new Campaign' },
  {
    key: 2,
    text:
      'I am a Campaign Staffer or Volunteer and am authorized to create a new Campaign',
  },
  { key: 4, text: 'I am setting up a non-candidate Committee' },
];

export const Signup = ({ match, user, actions, history, messages }) => {
  const [errors, setErrors] = useState({});
  const [signupState, setSignupState] = useState({
    selectedOption: 0,
    firstName: '',
    middleName: '',
    lastName: '',
    emailAddress: '',
    termsAccepted: false,
  });
  const [verificationState, setVerificationState] = useState({
    verificationCode: '',
  });
  const [passwordState, setPasswordState] = useState({
    password: '',
    confirmPassword: '',
  });
  const [
    termsAndConditionsDialogHidden,
    setTermsAndConditionsDialogHidden,
  ] = useState(true);

  useEffect(() => {
    const { registrationToken = null } = match.params;
    if (registrationToken && !user.registrationToken) {
      actions.getInfoFromRegistrationToken(registrationToken);
      history.push(`/signup/${registrationToken}`);
    }
  }, []);

  useEffect(() => {
    if (user.registrationToken && !match.params.registrationToken) {
      history.push(`/signup/${user.registrationToken}`);
    }
  }, [user]);

  const handleSignupChange = fieldName => (e, value) => {
    setSignupState({
      ...signupState,
      [fieldName]: value.key !== undefined ? value.key : value,
    });

    if (fieldName === 'emailAddress' && user.signupEmailIsUnique !== null) {
      actions.resetEmailCheck();
    }
  };

  const handleVerificationChange = (e, value) => {
    setVerificationState({ ...verificationState, verificationCode: value });
  };

  const handlePasswordChange = fieldName => (e, value) => {
    setPasswordState({
      ...passwordState,
      [fieldName]: value,
    });
  };

  const checkEmailIsUnique = e => {
    const { value } = e.target;
    if (emailRegex.test(value)) {
      actions.checkEmailIsUnique(value);
    }
  };

  const acceptTermsFromDialog = () => {
    setSignupState({
      ...signupState,
      termsAccepted: true,
    });

    setTermsAndConditionsDialogHidden(true);
  };

  const toggleAcceptTandC = (e, termsAccepted) => {
    setSignupState({
      ...signupState,
      termsAccepted,
    });
  };

  const openTandCDialog = () => {
    setTermsAndConditionsDialogHidden(false);
  };

  const closeTandCDialog = () => {
    setTermsAndConditionsDialogHidden(true);
  };

  const cancel = () => {
    history.push('/');
  };

  const register = () => {
    const errors = validateSignupForm(signupState, user);
    if (Object.keys(errors).length) {
      setErrors(errors);
    } else {
      const payload = {
        selectedOption: signupState.selectedOption,
        firstName: signupState.firstName,
        middle: signupState.middleName,
        lastName: signupState.lastName,
        emailAddress: signupState.emailAddress,
        termsAccepted: signupState.termsAccepted,
      };
      actions.signupNewAccount(payload);
    }
  };

  const resendEmail = () => {
    actions.resendVerificationEmail(user.registrationToken);
  };

  const verifyCode = e => {
    if (e.preventDefault) {
      e.preventDefault();
    }
    const errors = validateVerificationCode(verificationState);
    if (Object.keys(errors).length) {
      setErrors(errors);
    } else {
      actions.verifyCode(
        verificationState.verificationCode,
        user.registrationToken,
        signupState.emailAddress || user.registeredEmailAddress,
      );
    }
  };

  const submitAndLogin = e => {
    if (e.preventDefault) {
      e.preventDefault();
    }

    const errors = validatePasswordForm(passwordState);
    if (Object.keys(errors).length) {
      setErrors(errors);
    } else {
      const payload = {
        emailAddress: signupState.emailAddress || user.registeredEmailAddress,
        password: passwordState.password,
        confirmPassword: passwordState.confirmPassword,
        emailToken: user.registrationToken,
      };
      actions.completeSignup(payload);
    }
  };

  const renderSignupForm = () => {
    const signupActions = {
      handleChange: handleSignupChange,
      toggleAcceptTandC,
      closeTandCDialog,
      openTandCDialog,
      acceptTermsFromDialog,
      register,
      checkEmailIsUnique,
      cancel,
    };

    return (
      <Fragment>
        <Grid>
          <GridRow>
            <Column>
              <Dropdown
                onRenderCaretDown={() => <CaretDown />}
                label="Campaign Creation Option"
                required
                selectedKey={signupState.selectedOption}
                onChange={handleSignupChange('selectedOption')}
                options={[
                  { key: 0, text: 'Select' },
                  ...campaignCreationOptions,
                ]}
              />
            </Column>
          </GridRow>
        </Grid>
        {signupState.selectedOption < 5 && signupState.selectedOption > 0 && (
          <SignupForm
            actions={signupActions}
            firstName={signupState.firstName}
            firstNameError={errors.firstNameError}
            middleName={signupState.middleName}
            lastName={signupState.lastName}
            lastNameError={errors.lastNameError}
            signupEmailUnique={user.signupEmailIsUnique}
            emailAddress={
              signupState.emailAddress || user.registeredEmailAddress
            }
            emailAddressError={errors.emailAddressError}
            termsAccepted={signupState.termsAccepted}
            termsAndConditionsDialogHidden={termsAndConditionsDialogHidden}
          />
        )}
      </Fragment>
    );
  };

  const renderVerificationForm = () => {
    const verifyActions = {
      handleVerificationChange,
      resendEmail,
      verifyCode,
    };

    return (
      <SignupVerification
        verificationCode={verificationState.verificationCode}
        verificationCodeError={
          errors.verificationCodeError || user.verificationError
        }
        actions={verifyActions}
      />
    );
  };

  const renderPasswordForm = () => {
    const passwordActions = {
      handleTextChange: handlePasswordChange,
      submitAndLogin,
    };

    return (
      <SignupPasswordForm
        password={passwordState.password}
        passwordError={errors.passwordError}
        confirmPassword={passwordState.confirmPassword}
        confirmPasswordError={errors.confirmPasswordError}
        actions={passwordActions}
        completionError={user.passwordError}
      />
    );
  };

  return (
    <div className="Signup-wrapper depth-1">
      <div className="Signup">
        <Grid>
          <GridRow>
            <Column md={6} classNames="ms-mdPush3 ms-lgPush3 ms-xlPush3 logo">
              <Image src={FRLogo} alt="FrontRunner" height={150} />
            </Column>
          </GridRow>
        </Grid>
        <h3 className="ms-textAlignCenter">get me started!</h3>
        {user.registered === false &&
          user.verified === false &&
          signupState.selectedOption >= 0 &&
          signupState.selectedOption < 5 &&
          renderSignupForm()}
        {user.registered === true &&
          user.verified === false &&
          renderVerificationForm()}
        {user.registered === true &&
          user.verified === true &&
          renderPasswordForm()}
        {signupState.selectedOption === 0 && (
          <div className="ms-textAlignCenter">
            <Link
              onClick={() => {
                history.push('/');
              }}
            >
              Return to Login Screen
            </Link>
          </div>
        )}
      </div>
      {messages.message && (
        <Toast
          message={messages.message}
          toastType={messages.toastType}
          position={messages.position}
        />
      )}
    </div>
  );
};

Signup.displayName = 'Signup';

Signup.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  messages: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    user: state.user,
    messages: state.messages,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(userActions, dispatch),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Signup),
);
