import { types } from '../actions/verifyUserActions';
import { createReducer } from './createReducer';

export const initialState = {
  verified: false,
  token: null,
};

const actionMap = {
  [types.VERIFY_TOKEN_SUCCESS]: (state, action) => {
    return {
      ...state,
      verified: action.data.verified,
      token: action.data.token,
    };
  },
  [types.VERIFY_USER_SUCCESS]: () => {
    return { ...initialState };
  },
};

export const verifyUserReducer = createReducer(initialState, actionMap);
