import { createReducer } from './createReducer';
import { types } from '../actions/aggregateActions';

export const initialState = {
  contributions: [],
  expenditures: [],
};

export const actionMap = {
  [types.GET_CONTRIBUTION_AGGREGATES_SUCCESS]: (
    state,
    { data: { contributions } },
  ) => ({
    ...state,
    contributions,
  }),
  [types.CLEAR_AGGREGATES]: () => {
    return initialState;
  },
};

export const aggregatesReducer = createReducer(initialState, actionMap);
