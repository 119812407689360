import { takeLatest, call, put } from 'redux-saga/effects';
import axios from 'axios';
import { push } from 'connected-react-router';
import { types as reportTypes } from '../actions/reportActions';
import { types as messagingTypes } from '../actions/messagingActions';
import { getServerSideErrorMessage } from '../helpers/util';
import { toastTypes } from '../helpers/constants';

const form6form24Types = [
  '48hournotice',
  '48hournoticeamended',
  '24hournotice',
  '24hournoticeamended',
];

export function* handleGetDraftReports({ data }) {
  try {
    const { reportType = '' } = data;
    yield put({ type: reportTypes.GET_DRAFT_REPORTS_PROCESSING });
    let {
      data: { data: reports },
    } = yield call(axios.get, '/api/filer/reports?reportStatus=Draft', {
      withCredentials: true,
    });
    if (reportType && reportType === '48HourNotice') {
      reports = reports.filter(r =>
        form6form24Types.includes(r.reportType.toLowerCase()),
      );
    } else if (reportType) {
      reports = reports.filter(
        r => r.reportType.toLowerCase() === reportType.toLowerCase(),
      );
    } else {
      reports = reports.filter(r =>
        ['original', 'amended'].includes(r.reportType.toLowerCase()),
      );
    }
    yield put({ type: reportTypes.GET_DRAFT_REPORTS_SUCCESS, reports });
    yield put({ type: reportTypes.GET_DRAFT_REPORTS_RESET });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put({
      type: messagingTypes.SET_TOAST,
      data: { message: error, toastType: toastTypes.ERROR },
    });
    yield put({ type: reportTypes.GET_DRAFT_REPORTS_RESET });
  }
}

export function* getDraftReports() {
  yield takeLatest(reportTypes.GET_DRAFT_REPORTS, handleGetDraftReports);
}

export function* handleGetFiledReports({ data }) {
  try {
    const { reportType = '' } = data;
    yield put({ type: reportTypes.GET_FILED_REPORTS_PROCESSING });
    let {
      data: { data: reports },
    } = yield call(axios.get, '/api/filer/reports?reportStatus=Filed', {
      withCredentials: true,
    });

    if (reportType && reportType === '48HourNotice') {
      reports = reports.filter(r =>
        form6form24Types.includes(r.reportType.toLowerCase()),
      );
    } else if (reportType && reportType !== 'Federal') {
      reports = reports.filter(
        r => r.reportType.toLowerCase() === reportType.toLowerCase(),
      );
    } else {
      reports = reports.filter(r =>
        ['original', 'amended'].includes(r.reportType.toLowerCase()),
      );
    }
    yield put({ type: reportTypes.GET_FILED_REPORTS_SUCCESS, reports });
    yield put({ type: reportTypes.GET_FILED_REPORTS_RESET });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put({
      type: messagingTypes.SET_TOAST,
      data: { message: error, toastType: toastTypes.ERROR },
    });
    yield put({ type: reportTypes.GET_FILED_REPORTS_RESET });
  }
}

export function* getFiledReports() {
  yield takeLatest(reportTypes.GET_FILED_REPORTS, handleGetFiledReports);
}

export function* handleCreateDisclosureReport(action) {
  try {
    yield put({ type: reportTypes.CREATE_DISCLOSURE_REPORT_PROCESSING });
    const { payload } = action;
    const { data: newReport } = yield call(
      axios.post,
      '/api/filer/reports',
      payload,
      { withCredentials: true },
    );
    yield put({
      type: reportTypes.CREATE_DISCLOSURE_REPORT_SUCCESS,
      newReport,
    });
    yield put(push(`/filer/editReport/${newReport._id}`));
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put({
      type: messagingTypes.SET_TOAST,
      data: {
        message: error,
        toastType: toastTypes.ERROR,
      },
    });
    yield put({
      type: reportTypes.CREATE_DISCLOSURE_REPORT_RESET,
    });
  }
}

export function* createDisclosureReport() {
  yield takeLatest(
    reportTypes.CREATE_DISCLOSURE_REPORT,
    handleCreateDisclosureReport,
  );
}

export function* handleCreateTbdReport(action) {
  try {
    yield put({ type: reportTypes.CREATE_TBD_REPORT_PROCESSING });
    const { payload } = action;
    const { data: newReport } = yield call(
      axios.post,
      '/api/filer/reports',
      payload,
      { withCredentials: true },
    );
    yield put({ type: reportTypes.CREATE_TBD_REPORT_SUCCESS, newReport });
    yield put(push(`/filer/editReport/${newReport._id}?reportType=TBD`));
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put({
      type: messagingTypes.SET_TOAST,
      data: {
        message: error,
        toastType: toastTypes.ERROR,
      },
    });
    yield put({
      type: reportTypes.CREATE_TBD_REPORT_RESET,
    });
  }
}

export function* createTbdReport() {
  yield takeLatest(reportTypes.CREATE_TBD_REPORT, handleCreateTbdReport);
}

export function* handleCreate48HourNoticeReport(action) {
  try {
    yield put({ type: reportTypes.CREATE_48HOUR_NOTICE_REPORT_PROCESSING });
    const { payload } = action;
    const { data: newReport } = yield call(
      axios.post,
      '/api/filer/fec/reports',
      payload,
      { withCredentials: true },
    );
    yield put({ type: reportTypes.CREATE_48HOUR_NOTICE_REPORT_SUCCESS, newReport });
    yield put(push(`/filer/editFECReport/${newReport._id}?reportType=48HourNotice`));
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put({
      type: messagingTypes.SET_TOAST,
      data: {
        message: error,
        toastType: toastTypes.ERROR,
      },
    });
    yield put({
      type: reportTypes.CREATE_48HOUR_NOTICE_REPORT_RESET,
    });
  }
}

export function* create48HourNoticeReport() {
  yield takeLatest(reportTypes.CREATE_48HOUR_NOTICE_REPORT, handleCreate48HourNoticeReport);
}

export function* handleDeleteDisclosureReport(action) {
  try {
    const {
      data: { id, isTBDReport },
    } = action;
    yield call(axios.delete, `/api/filer/reports/${id}`, {
      withCredentials: true,
    });
    yield put({
      type: messagingTypes.SET_TOAST,
      data: {
        message: 'Report successfully deleted',
        toastType: toastTypes.SUCCESS,
      },
    });
    if (isTBDReport) {
      yield put(push('/filer/tbdReports'));
    } else {
      yield put(push('/filer/reports'));
    }
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put({
      type: messagingTypes.SET_TOAST,
      data: {
        message: error,
        toastType: toastTypes.ERROR,
      },
    });
  }
}

export function* deleteDisclosureReport() {
  yield takeLatest(
    reportTypes.DELETE_DISCLOSURE_REPORT,
    handleDeleteDisclosureReport,
  );
}

export function* handleDelete48HourNoticeReport(action) {
  try {
    const {
      data: { id },
    } = action;
    yield call(axios.delete, `/api/filer/reports/${id}`, {
      withCredentials: true,
    });
    yield put({
      type: messagingTypes.SET_TOAST,
      data: {
        message: 'Report successfully deleted',
        toastType: toastTypes.SUCCESS,
      },
    });
    yield put(push('/filer/48HourReports'));
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put({
      type: messagingTypes.SET_TOAST,
      data: {
        message: error,
        toastType: toastTypes.ERROR,
      },
    });
  }
}

export function* delete48HourNoticeReport() {
  yield takeLatest(
    reportTypes.DELETE_48HOUR_NOTICE_REPORT,
    handleDelete48HourNoticeReport,
  );
}
