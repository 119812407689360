import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { Label } from 'office-ui-fabric-react/lib/Label';
import { Grid, GridRow, Column } from '../../common';

export default class Section8 extends Component {
  static propTypes = {
    q8: PropTypes.shape({
      inKind: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
    handleChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    q8: {
      inKind: 0,
      amount: 0,
    },
  };

  render() {
    const { q8, handleChange } = this.props;
    return (
      <GridRow>
        <Column sm={1} classNames="ms-textAlignRight">
          <h3 className="form-label">8</h3>
        </Column>
        <Column sm={11}>
          <Grid>
            <GridRow>
              <Column sm={8}>
                <p>
                  {
                    'Total expenditures made and reported prior to this reporting period. If this is the'
                  }
                </p>
                <p>{'A. First report of this Election Cycle*, ENTER 0.'}</p>
                <p>
                  {
                    'B. Second or subsequent filing ENTER Line 12 of previous report.'
                  }
                </p>
              </Column>
              <Column
                sm={2}
                classNames="ms-textAlignCenter money-input with-label"
              >
                <Label className="money-label">In-Kind Estimated Value</Label>
                <TextField
                  borderless
                  inputMode="decimal"
                  styles={{
                    padding: '0 4px',
                    fieldGroup: { borderBottom: '1px solid #999' },
                    field: { textAlign: 'center' },
                  }}
                  value={q8.inKind}
                  onChange={handleChange('q8')('inKind')}
                />
              </Column>
              <Column
                sm={2}
                classNames="ms-textAlignCenter money-input with-label"
              >
                <Label className="money-label">Cash Amount</Label>
                <TextField
                  borderless
                  inputMode="decimal"
                  styles={{
                    padding: '0 4px',
                    fieldGroup: { borderBottom: '1px solid #999' },
                    field: { textAlign: 'center' },
                  }}
                  value={q8.amount}
                  onChange={handleChange('q8')('amount')}
                />
              </Column>
            </GridRow>
          </Grid>
        </Column>
      </GridRow>
    );
  }
}
