import {
  ChoiceGroup,
  DefaultButton,
  PrimaryButton,
} from 'office-ui-fabric-react';
import PropTypes from 'prop-types';
import React from 'react';
import { ImportDataType } from '../../../../helpers/constants';
import { dataTypeOptions } from './utils';

const DataTypeStep = ({ dataType, onSaveDataType, onCancel }) => {
  const [selectedDataType, setSelectedDataType] = React.useState(dataType);

  const handleChange = (event, option) => {
    setSelectedDataType(option.key);
  };

  const handleSaveBttnClick = () => {
    onSaveDataType(selectedDataType);
  };

  return (
    <div>
      <ChoiceGroup
        selectedKey={selectedDataType}
        options={dataTypeOptions}
        onChange={handleChange}
        label="What type of data would you like to import?"
      />
      <div className="import-wizard-step-bttns">
        <DefaultButton text="Cancel" onClick={onCancel} />
        <PrimaryButton
          disabled={!selectedDataType}
          onClick={handleSaveBttnClick}
          text="Next"
        />
      </div>
    </div>
  );
};

DataTypeStep.propTypes = {
  dataType: PropTypes.oneOf(Object.values(ImportDataType)),
  onSaveDataType: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default DataTypeStep;
