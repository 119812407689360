import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  PrimaryButton,
  DefaultButton,
} from 'office-ui-fabric-react/lib/Button';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { Grid, GridRow, Column } from '../common';

export class AddendumSection extends Component {
  static propTypes = {
    addendum: PropTypes.string,
    actions: PropTypes.object.isRequired,
    isExpanded: PropTypes.bool,
  };

  static defaultProps = {
    addendum: '',
    isExpanded: false,
  };

  shouldComponentUpdate(nextProps) {
    return (
      nextProps.addendum !== this.props.addendum ||
      nextProps.isExpanded !== this.props.isExpanded
    );
  }

  render() {
    const { isExpanded, actions, addendum } = this.props;

    return (
      <div className="addendum depth-1" name="addendum">
        <header onClick={actions.toggleExpand}>
          <h3>Addendum</h3>
        </header>
        <div className={`content${isExpanded ? '-expanded' : '-collapsed'}`}>
          <Grid>
            <GridRow>
              <Column>
                <TextField
                  label="Add Addendum Here"
                  value={addendum}
                  onChange={actions.handleChange('addendum')}
                />
              </Column>
            </GridRow>
          </Grid>
          <div className="section-actions">
            <DefaultButton
              text="Back"
              style={{ marginRight: 16 }}
              onClick={actions.previousSection}
            />
            <PrimaryButton text="Save Changes" onClick={actions.updateReport} />
          </div>
        </div>
      </div>
    );
  }
}

export default AddendumSection;
