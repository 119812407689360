export const validateOfficeDetails = (state, props) => {
  const {
    officeType = 0,
    officeState = 0,
    office = 0,
    officeCategory = 0,
    suboffice = '',
    nextElectionYear = 0,
    candidateFirstName = '',
    candidateLastName = '',
    candidateEmailAddress = '',
    reportingFrequency = '',
  } = state.office;

  state.office.formValid = true;

  if (officeType === 0) {
    state.office.officeTypeError = 'Office type is required';
    state.office.formValid = false;
  } else {
    state.office.officeTypeError = '';

    if (!props.user.isNonCandidateCommittee) {
      state.office.candidateFirstNameError =
        candidateFirstName.length > 0 ? '' : 'Candidate first name is required';
      state.office.candidateLastNameError =
        candidateLastName.length > 0 ? '' : 'Candidate last name is required';
      state.office.candidateEmailAddressError =
        candidateEmailAddress.length > 0
          ? ''
          : 'Candidate email address is required';
      state.office.formValid = !(
        state.office.candidateFirstNameError &&
        state.office.candidateLastNameError &&
        state.office.candidateEmailAddressError
      );
    }

    if (office === 0) {
      state.office.officeError = 'Office is required';
      state.office.formValid = false;
    } else {
      state.office.officeError = '';
    }

    if (officeState === 0) {
      state.office.officeStateError = 'Office state is required';
      state.office.formValid = false;
    } else {
      state.office.officeStateError = '';
    }

    if (reportingFrequency === '' && officeType === 1) {
      state.office.reportingFrequencyError = 'Reporting frequency is required';
      state.office.formValid = false;
    } else {
      state.office.reportingFrequencyError = '';
    }

    if (officeType < 3) {
      if ((suboffice === 0 || suboffice === '') && office !== 1) {
        state.office.subofficeError = 'Sub Office is required';
        state.office.formValid = false;
      } else {
        state.office.subofficeError = '';
      }
    }

    if (officeType === 3) {
      if (officeCategory === 0) {
        state.office.officeCategoryError = 'Office category is required';
        state.office.formValid = false;
      } else {
        state.office.officeCategoryError = '';
      }

      if (suboffice.length === 0) {
        state.office.subofficeError = 'Sub Office is required';
        state.office.formValid = false;
      } else {
        state.office.subofficeError = '';
      }
    }
  }

  if (nextElectionYear === 0) {
    state.office.nextElectionYearError = 'Next Election Year is required';
    state.office.formValid = false;
  } else {
    state.office.nextElectionYearError = '';
  }

  return state;
};
