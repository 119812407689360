import { emailRegex } from '../../helpers/constants';

export const validateSignupForm = state => {
  const errors = {};

  if (state.firstName.length === 0) {
    errors.firstNameError = 'First name is required';
  }

  if (state.lastName.length === 0) {
    errors.lastNameError = 'Last name is required';
  }

  if (state.emailAddress.length === 0) {
    errors.emailAddressError = 'Email address is required';
  } else if (!emailRegex.test(state.emailAddress)) {
    errors.emailAddressError = 'Email address is invalid';
  }

  return errors;
};

export const validateVerificationCode = state => {
  const errors = {};

  if (state.verificationCode.length === 0) {
    errors.verificationCodeError = 'Verification code is required';
  }

  return errors;
};

export const validatePasswordForm = state => {
  const errors = {};

  if (state.password.length === 0) {
    errors.passwordError = 'Password is required';
  }

  if (state.confirmPassword.length === 0) {
    errors.confirmPasswordError = 'Confirm password is required';
  } else if (state.password !== state.confirmPassword) {
    errors.confirmPasswordError = 'Passwords do not match';
  }

  return errors;
};
