import { ActionButton } from 'office-ui-fabric-react/lib/Button';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { contactIconMap, FECSourceTypeCodes } from '../../../helpers/constants';
import { Column, GridRow } from '../../common';

const lineStyle = { marginTop: 0, marginBottom: 2 };
const addressStyleLastLine = { marginTop: 0, marginBottom: 6 };

const formatDetail = selectedContact => {
  const type =
    selectedContact.contactType ||
    selectedContact.contactType ||
    selectedContact.responsiblePartyType;

  const addressLine1 =
    selectedContact.address?.addressLine1 || selectedContact.addressLine1 || '';
  const addressLine2 =
    selectedContact.address?.addressLine2 || selectedContact.addressLine2 || '';
  const city = selectedContact.address?.city || selectedContact.city || '';
  const state = selectedContact.address?.state || selectedContact.state || '';
  const zipCode =
    selectedContact.address?.zipCode || selectedContact.zipCode || '';
  const { email, phone1, occupation, employer } = selectedContact;

  const getOccupation = occupation => {
    return occupation ? (
      <p style={lineStyle}>Occupation: {occupation}</p>
    ) : (
      <p style={lineStyle}>No occupation (edit contact to add)</p>
    );
  };

  const getEmployer = employer => {
    return employer ? (
      <p style={lineStyle}>Employer: {employer}</p>
    ) : (
      <p style={lineStyle}>No employer (edit contact to add)</p>
    );
  };

  return (
    <div>
      <p style={lineStyle}>{addressLine1}</p>
      {addressLine2 && <p style={lineStyle}>{addressLine2}</p>}
      <p style={addressStyleLastLine}>{`${city ? city + ', ' : ''}${
        state ? state + ' ' : ''
      }${zipCode || ''}`}</p>
      {email && <p style={lineStyle}>{email}</p>}
      {!email && (
        <>
          <p style={lineStyle}>No email address (edit contact to add)</p>
        </>
      )}
      {phone1 && <p style={lineStyle}>{phone1}</p>}
      {['Individual', FECSourceTypeCodes.IND, FECSourceTypeCodes.CAN].includes(
        type,
      ) && getOccupation(occupation)}
      {['Individual', FECSourceTypeCodes.IND, FECSourceTypeCodes.CAN].includes(
        type,
      ) && getEmployer(employer)}
    </div>
  );
};

const SelectedContactCard = ({
  selectedContact,
  clearSelectedContact,
  editSelectedContact,
  disallowEdit,
}) => {
  const type =
    selectedContact.contactType ||
    selectedContact.contactType ||
    selectedContact.responsiblePartyType;

  return (
    <Fragment>
      <GridRow>
        <Column classNames="selected-contact-container">
          <Icon
            iconName={contactIconMap[type]}
            styles={{
              root: {
                fontSize: '16pt',
                backgroundColor: 'var(--primary-color))',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: 36,
                height: 36,
                color: '#fff',
                borderRadius: '50%',
              },
            }}
          />
          <h4>
            {![
              'Individual',
              FECSourceTypeCodes.IND,
              FECSourceTypeCodes.CAN,
            ].includes(type) &&
            ![
              'Individual',
              FECSourceTypeCodes.IND,
              FECSourceTypeCodes.CAN,
            ].includes(type)
              ? selectedContact.businessName
              : `${selectedContact.prefix ? selectedContact.prefix + ' ' : ''}${
                  selectedContact.firstName
                } ${
                  selectedContact.middleName
                    ? ' ' + selectedContact.middleName
                    : ''
                } ${selectedContact.lastName}${
                  selectedContact.suffix ? ' ' + selectedContact.suffix : ''
                }`}
          </h4>
          <div>{formatDetail(selectedContact)}</div>
          <div>
            <ActionButton
              text="Clear Selected Contact"
              onClick={clearSelectedContact}
              styles={{
                root: {
                  marginRight: 16,
                },
              }}
              iconProps={{
                iconName: 'MinusCircle',
                styles: {
                  root: {
                    color: '#a80000',
                  },
                },
              }}
            />
            {!disallowEdit && (
              <ActionButton
                text="Edit Selected Contact"
                onClick={editSelectedContact}
                iconProps={{
                  iconName: 'PencilAlt',
                }}
              />
            )}
          </div>
        </Column>
      </GridRow>
    </Fragment>
  );
};

SelectedContactCard.propTypes = {
  selectedContact: PropTypes.object.isRequired,
  clearSelectedContact: PropTypes.func.isRequired,
  editSelectedContact: PropTypes.func.isRequired,
  disallowEdit: PropTypes.bool.isRequired,
};

export default SelectedContactCard;
