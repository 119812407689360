export const types = {
  VERIFY_TOKEN: 'VERIFY_TOKEN',
  VERIFY_TOKEN_SUCCESS: 'VERIFY_TOKEN_SUCCESS',
  VERIFY_USER: 'VERIFY_USER',
  VERIFY_USER_SUCCESS: 'VERIFY_USER_SUCCESS',
};

export const actions = {
  verifyToken(token) {
    return { type: types.VERIFY_TOKEN, data: { token } };
  },

  verifyUser(token, payload) {
    return { type: types.VERIFY_USER, data: { token, payload } };
  },
};
