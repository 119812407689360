import React from 'react';
import PropTypes from 'prop-types';
import {
  PrimaryButton,
  Panel,
  PanelType,
  Separator,
} from 'office-ui-fabric-react';
import { Grid, GridRow, Column, DataLabel } from '../common';
import { formatCurrency, formatDate } from '../../helpers/util';
import { getLabel } from '../../helpers/labelHelper';

const address = item => {
  if (!item.address) {
    return null;
  }

  item.address = {
    addressLine1: item.address.addressLine1
      ? item.address.addressLine1 + ', '
      : '',
    addressLine2: item.address.addressLine2
      ? item.address.addressLine2 + ', '
      : '',
    city: item.address.city ? item.address.city + ', ' : '',
    state: item.address.state ? item.address.state : '',
    zipCode: item.address.zipCode ? item.address.zipCode : '',
  };

  if (item.address.addressLine2) {
    return `${item.address.addressLine1}${item.address.addressLine2}${item.address.city}${item.address.state} ${item.address.zipCode}`.trim();
  }

  return `${item.address.addressLine1}${item.address.city}${item.address.state} ${item.address.zipCode}`.trim();
};

const LoanPanel = ({ item, showPanel, closePanel, editItem, session }) => {
  const onClickEdit = () => {
    editItem(item._id);
  };

  if (!item) {
    return null;
  }

  const lenderAddress = address(item);

  const onRenderFooterContent = () => (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
      }}
    >
      <PrimaryButton
        iconProps={{ iconName: 'PencilAlt' }}
        onClick={onClickEdit}
        text="Edit"
      />
    </div>
  );

  const onRenderNavigationContent = (headerProps, defaultRenderer) => (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        width: '96%',
      }}
    >
      <h3 style={{ flex: 1 }}>Loan Details</h3>
      {defaultRenderer(headerProps)}
    </div>
  );

  return (
    <Panel
      isOpen={showPanel}
      onDismiss={closePanel}
      isLightDismiss
      type={PanelType.smallFixedNear}
      onRenderFooterContent={onRenderFooterContent}
      onRenderNavigationContent={onRenderNavigationContent}
      isFooterAtBottom
    >
      <Grid>
        <GridRow>
          <Column>
            <DataLabel label="Lender" value={item.displayName} />
            <DataLabel label="Amount" value={formatCurrency(item.amount)} />
            {item.interestRate !== undefined && (
              <DataLabel
                label="Interest Rate (%APR)"
                value={item.interestRate}
              />
            )}
            <DataLabel label="Loan Date" value={formatDate(item.loanDate)} />
            {!!item.paymentType && (
              <DataLabel label="Loan Type" value={item.paymentType} />
            )}

            {item.checkNumber && (
              <DataLabel label="Check Number" value={item.checkNumber} />
            )}
            {item.paymentReferenceId && (
              <DataLabel label="Reference Id" value={item.paymentReferenceId} />
            )}
          </Column>
        </GridRow>
        <GridRow>
          <Column>
            {item.contactName && (
              <DataLabel label="Contact Name" value={item.contactName} />
            )}
            <DataLabel
              label="Address"
              value={`${lenderAddress}`}
              valueStyle={{ textAlign: 'right' }}
            />
            {item.description && (
              <DataLabel label="Description" value={item.description} />
            )}
            {!!item.phone1 && <DataLabel label="Phone" value={item.phone1} />}
            {!!item.email && <DataLabel label="Email" value={item.email} />}
            <Separator />
            <DataLabel label="Election Year" value={item.electionYear} />
            <DataLabel
              label="Election"
              value={`${item.electionCycle}${item.electionCycleOtherDescription
                ? ' - ' + item.electionCycleOtherDescription
                : ''
                }`}
            />
            {item.responsibleParties && item.responsibleParties.length > 0 && (
              <DataLabel
                label={`${getLabel('Responsible Parties', session)}`}
                valueStyle={{ textAlign: 'right' }}
                value={item.responsibleParties
                  .map(rp => {
                    if (rp.displayName) {
                      return rp.displayName;
                    }

                    return `${rp.firstName} ${rp.lastName}`;
                  })
                  .join('; ')}
              />
            )}
          </Column>
        </GridRow>
      </Grid>
    </Panel>
  );
};

LoanPanel.propTypes = {
  showPanel: PropTypes.bool,
  item: PropTypes.object,
  closePanel: PropTypes.func.isRequired,
  editItem: PropTypes.func.isRequired,
  session: PropTypes.object.isRequired,
};

LoanPanel.defaultProps = {
  showPanel: false,
  item: {},
};

export default LoanPanel;
