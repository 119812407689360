import { types } from '../actions/importWizardActions';
import {
  ImportProgressStep,
  ImportStatus,
  statuses,
} from '../helpers/constants';
import { createReducer } from './createReducer';

export const initialState = {
  step: ImportProgressStep.DataType,
  dataType: null,
  config: {},
  fileName: null,
  fileId: null,
  status: null,
  uploadResult: null,
  uploadImportFileStatus: statuses.NOT_STARTED,
  uploadImportFileError: null,
  saveImportConfigStatus: statuses.NOT_STARTED,
  saveImportConfigError: null,
  getUploadResultStatus: statuses.NOT_STARTED,
  getUploadResultError: null,
  mapImportColumnsStatus: statuses.NOT_STARTED,
  mapImportColumnsError: null,
  getEditedImportDataStatus: statuses.NOT_STARTED,
  getEditedImportDataError: null,
};

const actionMap = {
  [types.CHANGE_IMPORT_WIZARD_STEP]: (state, action) => ({
    ...state,
    step: action.data.step,
  }),

  [types.CLEAR_IMPORT_WIZARD_DATA]: () => ({
    ...initialState,
  }),

  [types.SAVE_IMPORT_DATA_TYPE]: (state, action) => ({
    ...state,
    step: ImportProgressStep.Upload,
    dataType: action.data.dataType,
  }),

  [types.UPLOAD_IMPORT_FILE_PROCESSING]: (state, action) => ({
    ...state,
    uploadImportFileStatus: statuses.PROCESSING,
    uploadImportFileError: null,
    fileName: action.data.fileName,
  }),

  [types.UPLOAD_IMPORT_FILE_SUCCESS]: (state, action) => ({
    ...state,
    uploadImportFileStatus: statuses.SUCCESS,
    uploadImportFileError: null,
    fileId: action.data.fileId,
    status: ImportStatus.Created,
  }),

  [types.UPLOAD_IMPORT_FILE_FAILURE]: (state, action) => ({
    ...state,
    uploadImportFileStatus: statuses.ERROR,
    uploadImportFileError: action.error,
  }),

  [types.SAVE_IMPORT_FILE_FORMAT]: (state, action) => ({
    ...state,
    config: { ...state.config, fileFormat: action.data.fileFormat },
    step: ImportProgressStep.Questions,
  }),

  [types.SAVE_IMPORT_CONFIG_PROCESSING]: state => ({
    ...state,
    saveImportConfigStatus: statuses.PROCESSING,
    saveImportConfigError: null,
  }),

  [types.SAVE_IMPORT_CONFIG_SUCCESS]: (state, action) => ({
    ...state,
    saveImportConfigStatus: statuses.SUCCESS,
    saveImportConfigError: null,
    config: action.data.config,
  }),

  [types.SAVE_IMPORT_CONFIG_FAILURE]: (state, action) => ({
    ...state,
    saveImportConfigStatus: statuses.ERROR,
    saveImportConfigError: action.error,
  }),

  [types.GET_UPLOAD_RESULT_PROCESSING]: state => ({
    ...state,
    getUploadResultStatus: statuses.PROCESSING,
    getUploadResultError: null,
  }),

  [types.GET_UPLOAD_RESULT_SUCCESS]: (state, action) => ({
    ...state,
    getUploadResultStatus: statuses.SUCCESS,
    getUploadResultError: null,
    uploadResult: action.data,
    step: ImportProgressStep.ColumnMapping,
  }),

  [types.GET_UPLOAD_RESULT_FAILURE]: (state, action) => ({
    ...state,
    getUploadResultStatus: statuses.ERROR,
    getUploadResultError: action.error,
  }),

  [types.MAP_IMPORT_COLUMNS_PROCESSING]: state => ({
    ...state,
    mapImportColumnsStatus: statuses.PROCESSING,
    mapImportColumnsError: null,
  }),

  [types.MAP_IMPORT_COLUMNS_SUCCESS]: state => ({
    ...state,
    mapImportColumnsStatus: statuses.SUCCESS,
    mapImportColumnsError: null,
  }),

  [types.MAP_IMPORT_COLUMNS_FAILURE]: (state, action) => ({
    ...state,
    mapImportColumnsStatus: statuses.ERROR,
    mapImportColumnsError: action.error,
  }),
  [types.GET_EDITED_IMPORT_DATA_PROCESSING]: state => ({
    ...state,
    getEditedImportDataStatus: statuses.PROCESSING,
    getEditedImportDataError: null,
  }),

  [types.GET_EDITED_IMPORT_DATA_SUCCESS]: (state, action) => ({
    ...state,
    getEditedImportDataStatus: statuses.SUCCESS,
    getEditedImportDataError: null,
    ...action.data,
  }),

  [types.GET_EDITED_IMPORT_DATA_FAILURE]: (state, action) => ({
    ...state,
    getEditedImportDataStatus: statuses.ERROR,
    getEditedImportDataError: action.error,
  }),
};

export const importWizardReducer = createReducer(initialState, actionMap);
