import React from 'react';
import PropTypes from 'prop-types';
import { Panel, PanelType } from 'office-ui-fabric-react/lib/Panel';
import { Pivot, PivotItem } from 'office-ui-fabric-react/lib/Pivot';
import { ActionButton } from 'office-ui-fabric-react/lib/Button';
import { ChoiceGroup } from 'office-ui-fabric-react/lib/ChoiceGroup';
import { Dropdown } from 'office-ui-fabric-react/lib/Dropdown';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import {
  standardColumns,
  endRecipientColumns,
} from '../../helpers/listsHelper';
import { Grid, GridRow, Column, MoneyField } from '../common';
import { DatePicker } from '../Pickers';
import { getLabel } from '../../helpers/labelHelper';
import { CaretDown } from '../icons';
import './FiltersPanel.css';

const FiltersPanel = ({
  electionCycles,
  showPanel,
  closePanel,
  fieldType,
  selectedStandardColumns,
  selectedEndRecipientColumns,
  electionCycleFilters,
  transactionTypeFilters,
  entityTypeFilters,
  paymentTypeFilters,
  startDate,
  endDate,
  startAmount,
  endAmount,
  endRecipientTypeFilters,
  erStartDate,
  erEndDate,
  erStartAmount,
  erEndAmount,
  actions,
  session,
  tags,
}) => {
  return (
    <Panel
      isOpen={showPanel}
      onDismiss={closePanel}
      type={PanelType.medium}
      isLightDismiss
    >
      <ActionButton
        onClick={actions.resetFilters}
        iconProps={{ iconName: 'Filter' }}
      >
        Reset
      </ActionButton>
      <Pivot>
        <PivotItem
          headerText="Columns"
          headerButtonProps={{
            'data-order': 2,
            'data-title': 'Columns',
          }}
        >
          <div className="columns-filter">
            <ChoiceGroup
              onChange={actions.onChangeFieldType}
              selectedKey={fieldType}
              options={[
                {
                  key: 'standard',
                  text: 'Standard',
                  iconProps: { iconName: 'ListUl' },
                },
                {
                  key: 'end-recipient',
                  text: 'End Recipient',
                  iconProps: { iconName: 'AddressCard' },
                },
              ]}
            />
            {fieldType === 'standard' && (
              <Dropdown
                onRenderCaretDown={() => <CaretDown />}
                placeholder="Select Columns"
                label="Available Columns"
                multiSelect
                selectedKeys={selectedStandardColumns}
                onChange={actions.onChangeMultiSelect(
                  'selectedStandardColumns',
                )}
                options={[
                  ...standardColumns()
                    .map(col => {
                      const labels = col.text
                        .split(' ')
                        .map(val => getLabel(val, session))
                        .join(' ');
                      return {
                        key: col.key,
                        text: labels,
                      };
                    })
                    .sort((a, b) => a.text - b.text),
                ]}
              />
            )}
            {fieldType === 'end-recipient' && (
              <Dropdown
                onRenderCaretDown={() => <CaretDown />}
                placeholder="Select Columns"
                label="Available Columns"
                multiSelect
                selectedKeys={selectedEndRecipientColumns}
                onChange={actions.onChangeMultiSelect(
                  'selectedEndRecipientColumns',
                )}
                options={[
                  ...endRecipientColumns()
                    .map(col => {
                      const labels = col.text
                        .split(' ')
                        .map(val => getLabel(val, session))
                        .join(' ');
                      return {
                        key: col.key,
                        text: labels,
                      };
                    })
                    .sort((a, b) => a.text - b.text),
                ]}
              />
            )}
          </div>
        </PivotItem>
        <PivotItem
          headerText="Filters"
          headerButtonProps={{
            'data-order': 3,
            'data-title': 'Filters',
          }}
        >
          <div className="filters-filter">
            {fieldType === 'standard' && (
              <div className="filter-subset">
                <h4>Standard Filters:</h4>
                <Grid>
                  <GridRow>
                    <Column>
                      <Dropdown
                        onRenderCaretDown={() => <CaretDown />}
                        label="Election"
                        selectedKeys={electionCycleFilters}
                        options={[
                          ...electionCycles.map(ec => ({
                            key: ec.text || ec,
                            text: ec.text || ec,
                          })),
                        ]}
                        multiSelect
                        onChange={actions.onChangeMultiSelect(
                          'electionCycleFilters',
                        )}
                      />
                    </Column>
                  </GridRow>
                  <GridRow>
                    <Column>
                      <Dropdown
                        onRenderCaretDown={() => <CaretDown />}
                        label="Transaction Type"
                        selectedKeys={transactionTypeFilters}
                        options={[
                          {
                            key: 'Contribution',
                            text: getLabel('Contribution', session),
                          },
                          {
                            key: 'Loan',
                            text: 'Loan',
                          },
                          {
                            key: 'Expenditure',
                            text: getLabel('Expenditure', session),
                          },
                        ]}
                        multiSelect
                        onChange={actions.onChangeMultiSelect(
                          'transactionTypeFilters',
                        )}
                      />
                    </Column>
                  </GridRow>
                  <GridRow>
                    <Column>
                      <Dropdown
                        onRenderCaretDown={() => <CaretDown />}
                        label="Entity Type"
                        selectedKeys={entityTypeFilters}
                        options={[
                          {
                            key: 'Individual',
                            text: 'Individual',
                          },
                          {
                            key: 'Business',
                            text: 'Business',
                          },
                          {
                            key: 'Committee',
                            text: 'Committee',
                          },
                          {
                            key: 'Common Source',
                            text: 'Common Source',
                          },
                        ]}
                        multiSelect
                        onChange={actions.onChangeMultiSelect(
                          'entityTypeFilters',
                        )}
                      />
                    </Column>
                  </GridRow>
                  <GridRow>
                    <Column>
                      <Dropdown
                        onRenderCaretDown={() => <CaretDown />}
                        label="Payment Type"
                        selectedKeys={paymentTypeFilters}
                        options={[
                          {
                            key: 'Cash',
                            text: 'Cash',
                          },
                          {
                            key: 'Check',
                            text: 'Check',
                          },
                          {
                            key: 'Credit Card',
                            text: 'Credit Card',
                          },
                          {
                            key: 'Debit Card',
                            text: 'Debit Card',
                          },
                          {
                            key: 'Wire Transfer',
                            text: 'Wire Transfer',
                          },
                          {
                            key: 'ACH',
                            text: 'ACH',
                          },
                          {
                            key: 'Other',
                            text: 'Other',
                          },
                        ]}
                        multiSelect
                        onChange={actions.onChangeMultiSelect(
                          'paymentTypeFilters',
                        )}
                      />
                    </Column>
                  </GridRow>
                  <GridRow>
                    <Column sm={6}>
                      <DatePicker
                        label="Transaction Date Minimum"
                        value={startDate}
                        onChange={actions.onChangeDate('startDate')}
                      />
                    </Column>
                    <Column sm={6}>
                      <DatePicker
                        label="Transaction Date Maximum"
                        value={endDate}
                        onChange={actions.onChangeDate('endDate')}
                      />
                    </Column>
                  </GridRow>
                  <GridRow>
                    <Column sm={6}>
                      <MoneyField
                        value={startAmount}
                        onChange={actions.onChange('startAmount')}
                        label="Minimum Amount"
                      />
                    </Column>
                    <Column sm={6}>
                      <MoneyField
                        value={endAmount}
                        onChange={actions.onChange('endAmount')}
                        label="Maximum Amount"
                      />
                    </Column>
                  </GridRow>
                  <GridRow>
                    <Column>
                      <TextField
                        value={tags}
                        label="Tags"
                        placeholder="Separate Tags with a Comma"
                        onChange={actions.onChange('tags')}
                      />
                    </Column>
                  </GridRow>
                </Grid>
              </div>
            )}
            {fieldType === 'end-recipient' && (
              <div className="filter-subset">
                <h4>End Recipient Filters:</h4>
                <Grid>
                  <GridRow>
                    <Column>
                      <Dropdown
                        onRenderCaretDown={() => <CaretDown />}
                        label="End Recipient Entity Type"
                        multiSelect
                        onChange={actions.onChangeMultiSelect(
                          'endRecipientTypeFilters',
                        )}
                        selectedKeys={endRecipientTypeFilters}
                        options={[
                          {
                            key: 'Individual',
                            text: 'Individual',
                          },
                          {
                            key: 'Business',
                            text: 'Business/Other Entity',
                          },
                        ]}
                      />
                    </Column>
                  </GridRow>
                  <GridRow>
                    <Column sm={6}>
                      <DatePicker
                        label="Start Date"
                        value={erStartDate}
                        onChange={actions.onChangeDate('erStartDate')}
                      />
                    </Column>
                    <Column sm={6}>
                      <DatePicker
                        label="End Date"
                        value={erEndDate}
                        onChange={actions.onChangeDate('erEndDate')}
                      />
                    </Column>
                  </GridRow>
                  <GridRow>
                    <Column sm={6}>
                      <MoneyField
                        value={erStartAmount}
                        onChange={actions.onChange('erStartAmount')}
                        label="Minimum Amount"
                      />
                    </Column>
                    <Column sm={6}>
                      <MoneyField
                        value={erEndAmount}
                        onChange={actions.onChange('erEndAmount')}
                        label="Maximum Amount"
                      />
                    </Column>
                  </GridRow>
                  <GridRow>
                    <Column>
                      <TextField
                        value={tags}
                        label="Tags"
                        placeholder="Separate Tags with a Comma"
                        onChange={actions.onChange('tags')}
                      />
                    </Column>
                  </GridRow>
                </Grid>
              </div>
            )}
          </div>
        </PivotItem>
      </Pivot>
    </Panel>
  );
};

FiltersPanel.propTypes = {
  electionCycles: PropTypes.array.isRequired,
  showPanel: PropTypes.bool,
  closePanel: PropTypes.func.isRequired,
  fieldType: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  selectedStandardColumns: PropTypes.array.isRequired,
  selectedEndRecipientColumns: PropTypes.array.isRequired,
  electionCycleFilters: PropTypes.array.isRequired,
  transactionTypeFilters: PropTypes.array.isRequired,
  entityTypeFilters: PropTypes.array.isRequired,
  paymentTypeFilters: PropTypes.array.isRequired,
  startDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  endDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  startAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  endAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  endRecipientTypeFilters: PropTypes.array.isRequired,
  erStartDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  erEndDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  erStartAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  erEndAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  actions: PropTypes.object.isRequired,
  session: PropTypes.object.isRequired,
  tags: PropTypes.array,
};

FiltersPanel.defaultProps = {
  showPanel: true,
  startDate: null,
  endDate: null,
  startAmount: 0,
  endAmount: 0,
  erStartDate: null,
  erEndDate: null,
  erStartAmount: 0,
  erEndAmount: 0,
  tags: [],
};

export default FiltersPanel;
