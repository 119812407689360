import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { GridRow, Column, MoneyField } from '../../common';
import SectionLabel from './SectionLabel';

const FECSection8 = ({ q8ColA, q8ColB }) => {
  return (
    <Fragment>
      <GridRow style={{ borderTop: '1px solid #919191' }}>
        <Column sm={4}>
          <SectionLabel
            label="8."
            labelLines={[
              'Cash on Hand at Close of',
              'Reporting Period',
              '(subtract Line 7 from Line 6(d))',
            ]}
          />
        </Column>
        <Column sm={4} classNames="entry-row">
          <MoneyField
            disabled
            value={q8ColA || 0}
          />
        </Column>
        <Column sm={4} classNames="entry-row">
          <MoneyField
            disabled
            value={q8ColB || 0}
          />
        </Column>
      </GridRow>
    </Fragment>
  );
};

FECSection8.propTypes = {
  q8ColA: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  q8ColB: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default FECSection8;
