import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { Label } from 'office-ui-fabric-react/lib/Label';
import { Grid, GridRow, Column } from '../../common';
import WarningBox from './WarningBox';

export default class Section2 extends Component {
  static propTypes = {
    q2: PropTypes.shape({
      inKind: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
    handleChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    q2: {
      inKind: 0,
      amount: 0,
    },
  };

  constructor(...args) {
    super(...args);

    this.inKindBox = React.createRef();
    this.amountBox = React.createRef();
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextProps.q2.inKind !== this.props.q2.inKind ||
      nextProps.q2.amount !== this.props.q2.amount ||
      nextState.inKindCalloutVisible !== this.state.inKindCalloutVisible ||
      nextState.amountCalloutVisible !== this.state.amountCalloutVisible ||
      nextState.canEdit !== this.state.canEdit
    );
  }

  state = {
    inKindCalloutVisible: false,
    amountCalloutVisible: false,
    canEdit: false,
  };

  openCallout = name => {
    this.setState(() => ({
      [`${name}CalloutVisible`]: true,
    }));
  };

  closeCallout = name => () => {
    this.setState(() => ({
      [`${name}CalloutVisible`]: false,
    }));
  };

  acceptEdit = () => {
    this.setState({
      canEdit: true,
      inKindCalloutVisible: false,
      amountCalloutVisible: false,
    });
  };

  render() {
    const { q2, handleChange } = this.props;
    const { inKindCalloutVisible, amountCalloutVisible, canEdit } = this.state;

    return (
      <GridRow>
        <Column sm={1} classNames="ms-textAlignRight">
          <h3 className="form-label">2</h3>
        </Column>
        <Column sm={11}>
          <Grid>
            <GridRow>
              <Column sm={8}>
                <p>
                  A.&nbsp; If this is the first time to file a disclosure report
                  for the current office sought, ENTER 0 in both columns (one
                  time only); or
                </p>
                <p>
                  B.&nbsp; If this is the first report of this Election Cycle*,
                  ENTER 0 in the in-kind column and list any net balance on hand
                  brought forward from the previous election cycle in the amount
                  amount column (Line 15 of previous report, or total funds left
                  over at year end of previous cycle); or
                </p>
                <p>
                  C.&nbsp; If this filing is the second or subsequent filing of
                  this Election Cycle, list totals from Line 6 of previous
                  report in both the in-kind and amount amount columns.
                </p>
              </Column>
              <Column
                sm={2}
                classNames="ms-textAlignCenter money-input with-label"
              >
                <Label className="money-label">In-Kind Estimated Value</Label>
                <span ref={this.inKindBox}>
                  <TextField
                    borderless
                    inputMode="decimal"
                    value={q2.inKind}
                    onChange={canEdit ? handleChange('q2')('inKind') : () => {}}
                    styles={{
                      padding: '0 4px',
                      fieldGroup: { borderBottom: '1px solid #999' },
                      field: { textAlign: 'center' },
                    }}
                    onClick={() => this.openCallout('inKind')}
                  />
                </span>
              </Column>
              <Column
                sm={2}
                classNames="ms-textAlignCenter money-input with-label"
              >
                <Label className="money-label">Cash Amount</Label>
                <span ref={this.amountBox}>
                  <TextField
                    borderless
                    inputMode="decimal"
                    value={q2.amount}
                    onChange={canEdit ? handleChange('q2')('amount') : () => {}}
                    styles={{
                      padding: '0 4px',
                      fieldGroup: { borderBottom: '1px solid #999' },
                      field: { textAlign: 'center' },
                    }}
                    onClick={() => this.openCallout('amount')}
                  />
                </span>
              </Column>
            </GridRow>
          </Grid>
          {inKindCalloutVisible && !canEdit ? (
            <WarningBox
              target={this.inKindBox.current}
              onDismiss={this.closeCallout('inKind')}
              onAccept={this.acceptEdit}
            />
          ) : null}
          {amountCalloutVisible && !canEdit ? (
            <WarningBox
              target={this.amountBox.current}
              onDismiss={this.closeCallout('amount')}
              onAccept={this.acceptEdit}
            />
          ) : null}
        </Column>
      </GridRow>
    );
  }
}
