import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'office-ui-fabric-react/lib/Dropdown';
import { getContributionsForContactAndElection } from '../../services/apiCalls';
import { formatDate, formatCurrency } from '../../helpers/util';
import { CaretDown } from '../icons';

const ContributionRefundPicker = ({
  label,
  errorMessage,
  electionCycle,
  electionYear,
  contactId,
  value = '0',
  className = '',
  required = false,
  style = {},
  onChange,
}) => {
  const [state, setState] = useState({
    contributions: [],
    queriedContributions: false,
    noContributionsMessage: '',
  });

  const search = async (electionCycle, electionYear, contactId) => {
    try {
      const params = [
        contactId ? `?contactId=${contactId}` : '',
        electionCycle ? `&electionCycle=${electionCycle}` : '',
        electionYear ? `&electionYear=${electionYear}` : '',
      ]
        .filter(Boolean)
        .join('');

      const { data } = await getContributionsForContactAndElection(params);
      return data;
    } catch (e) {
      return [];
    }
  };

  const formatForSelection = (contribution, paddingTop = 6) => {
    const colStyle = { flex: '1 1 0', padding: `${paddingTop} 8 0 0` };
    const rowStyle = {
      height: '32px',
      width: '600px',
      overflow: 'hidden',
      display: 'flex',
      justifyContent: 'left',
      verticalAlign: 'middle',
      paddingLeft: '6px',
    };
    const l = (
      <div key={contribution._id} style={rowStyle}>
        <div style={colStyle}>Amount: {formatCurrency(contribution.amount)}</div>
        <div style={colStyle}>Received: {formatDate(contribution.receivedDate)}</div>
        <div style={colStyle}>Entered/Updated: {formatDate(contribution.updatedAt)}</div>
      </div>
    );
    return l;
  };

  const noContributions = () => {
    return state.queriedContributions && state.contributions.length === 0;
  };

  const noContributionLabel = 'No contributions for this contact in this year and cycle';

  const setContributions = async (electionCycle, electionYear, contactId) => {
    const contributions = await search(electionCycle, electionYear, contactId);
    const noContributionsMessage = !contributions.length ? noContributionLabel : '';
    setState({
      contributions,
      queriedContributions: true,
      noContributionsMessage,
    });
  };

  const hasAll = (electionCycle, electionYear, contactId) => {
    return electionCycle !== '0' && electionYear !== '0' && contactId;
  };

  const onRenderTitle = (e) => {
    if (e[0].key === '0') {
      return 'Select Refunded Contribution';
    }
    return formatForSelection(e[0].contribution, 0);
  };

  useEffect(() => {
    if (hasAll(electionCycle, electionYear, contactId)) {
      setContributions(electionCycle, electionYear, contactId);
    }
  }, [electionCycle, electionYear, contactId]);

  const getSelectLabel = () => {
    if (noContributions()) {
      return { key: '0', text: noContributionLabel };
    }
    return { key: '0', text: 'Select Refunded Contribution' };
  };

  return (
    <div>
      <span className="error-message">{state.noContributionsMessage}</span>
      {!state.noContributionsMessage && (
        <Dropdown
          onRenderCaretDown={() => <CaretDown />}
          selectedKey={value}
          onChange={onChange}
          className={className}
          placeholder="Select Refund Contribution"
          onRenderTitle={onRenderTitle}
          style={style}
          errorMessage={errorMessage}
          required={required}
          label={label}
          options={[
            getSelectLabel(),
            ...state.contributions.map(c => ({
              key: c._id,
              text: formatForSelection(c),
              contribution: c,
            })),
          ]}
        />)
      }
    </div>
  );
};

ContributionRefundPicker.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  errorMessage: PropTypes.string,
  required: PropTypes.bool,
  style: PropTypes.object,
  label: PropTypes.string,
  electionCycle: PropTypes.string,
  electionYear: PropTypes.string,
  contactId: PropTypes.string,
};

export default ContributionRefundPicker;
