import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Dropdown } from 'office-ui-fabric-react/lib/Dropdown';
import { CaretDown } from '../icons';
import { types as reportActions } from '../../actions/reportActions';
import {
  getReportTypeByItem,
  getReportTypeByItemForState,
} from '../../helpers/reportHelper';
import { ReportBalanceSource } from '../../helpers/constants';
import { formatUtcDate } from '../../helpers/util';

import {
  getFiledReports,
  getDraftReports,
} from '../../selectors';

const FiledReportPicker = ({
  selectedKey,
  onChange,
  errorMessage,
  label,
  required,
  reportId,
  disabled = false,
}) => {
  const reduxDispatch = useDispatch();
  const filedReports = useSelector(getFiledReports);
  const draftReports = useSelector(getDraftReports);
  const {
    CampaignDefault,
  } = ReportBalanceSource;
  const [filesForDropdown, setFilesForDropdown] = useState([]);

  useEffect(() => {
    reduxDispatch({
      type: reportActions.GET_FILED_REPORTS,
      data: { reportType: '' },
    });
    reduxDispatch({
      type: reportActions.GET_DRAFT_REPORTS,
      data: { reportType: '' },
    });
  }, []);

  useEffect(() => {
    const draftReportsWithoutSelf = (draftReports || []).filter(e => e._id !== reportId);
    setFilesForDropdown([...draftReportsWithoutSelf].concat([...(filedReports || [])]));
  }, [filedReports, draftReports]);

  const getFederalReportOption = (filedReport) => {
    return (
      <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
        <div style={{ width: 300, overflow: 'hidden', textOverflow: 'ellipsis' }}>{getReportTypeByItem(filedReport)}</div>
        <div style={{ width: 140 }}>{formatUtcDate(filedReport.startDate, 'MM/DD/YY')} - {formatUtcDate(filedReport.endDate, 'MM/DD/YY')}</div>
        <div style={{ width: 100 }}>{filedReport.filingId}</div>
      </div>
    );
  };

  const getNonFederalReportOption = (filedReport) => {
    return (
      <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
        <div style={{ width: 390, overflow: 'hidden', textOverflow: 'ellipsis' }}>{getReportTypeByItemForState(filedReport)}</div>
        <div style={{ width: 140 }}>{formatUtcDate(filedReport.startDate, 'MM/DD/YY')} - {formatUtcDate(filedReport.endDate, 'MM/DD/YY')}</div>
        <div style={{ width: 100 }}>{filedReport.filedConfirmationNumber}</div>
      </div>
    );
  };

  const getOption = (filedReport) => {
    return filedReport.officeType === 'Federal'
      ? getFederalReportOption(filedReport)
      : getNonFederalReportOption(filedReport);
  };

  const getOptions = () => {
    const selectOption = { key: '0', text: 'Select' };
    const campaignDefaultOption = { key: CampaignDefault, text: 'Defined In Campaign Defaults' };
    const options = [
      selectOption,
      campaignDefaultOption,
      ...filesForDropdown.map(filedReport => ({
      key: filedReport._id,
      text: getOption(filedReport),
    }))];
    return options;
  };

  const onRenderTitle = (o) => {
    return o[0].text;
  };

  return (
    <>
      <Dropdown
        onRenderCaretDown={() => <CaretDown />}
        label={label}
        required={required}
        selectedKey={selectedKey}
        errorMessage={errorMessage}
        onRenderTitle={onRenderTitle}
        disabled={disabled}
        options={getOptions()}
        onChange={onChange}
      />
    </>
  );
};

FiledReportPicker.propTypes = {
  selectedKey: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onChange: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  reportId: PropTypes.string,
};

FiledReportPicker.defaultProps = {
  errorMessage: '',
  required: true,
  reportId: '',
};

export default FiledReportPicker;
