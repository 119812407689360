import { initialState } from './BankInterestPanelReducer';
import {
  isInvalidNumber,
  isInvalidDate,
  formatDateOnly,
} from '../../../helpers/util';

import { zeroToUndefined } from '../../../helpers/payloadHelper';

import { emailRegex, FECSourceTypeCodes } from '../../../helpers/constants';

export const initialErrors = {
  ...initialState.errors,
};

export const validate = (state, session) => {
  const errors = { ...initialErrors };

  if (!state.amount || state.amount === '0') {
    errors.amount = 'Amount is required';
  } else if (isInvalidNumber(+state.amount)) {
    errors.amount = 'Invalid amount value';
  }

  if (!state.electionYear || state.electionYear === 0) {
    errors.electionYear = 'Election year is required';
  }

  if (!state.electionCycle || state.electionCycle === 0) {
    errors.electionCycle = 'Election cycle is required';
  }

  if (!state.dateReceived) {
    errors.dateReceived = 'Date received is required';
  } else if (isInvalidDate(state.dateReceived)) {
    errors.dateReceived = 'Invalid date';
  }

  if (!state.selectedContact && !state.usingNewContact) {
    errors.selectedContact = 'Please select a contact or create a new one';
  }

  if (state.email && !emailRegex.test(state.email)) {
    errors.email = 'Please use a valid email address';
  }

  if (state.usingNewContact) {
    if (!state.contactType || state.contactType === 0) {
      errors.contactType = 'Please select a contact type';
    }

    if (
      ['Individual', FECSourceTypeCodes.IND, FECSourceTypeCodes.CAN].includes(
        state.contactType,
      )
    ) {
      if (!state.firstName) {
        errors.firstName = 'First name is required';
      }

      if (!state.lastName) {
        errors.lastName = 'Last name is required';
      }
    } else if (
      [
        'Business',
        'Committee',
        'Common Source',
        FECSourceTypeCodes.ORG,
        FECSourceTypeCodes.COM,
        FECSourceTypeCodes.PTY,
        FECSourceTypeCodes.PAC,
        FECSourceTypeCodes.CCM,
      ].includes(state.contactType)
    ) {
      if (!state.businessName) {
        errors.businessName = 'Business name is required';
      }
    }
  }

  if (session.isFederal()) {
    if (
      ['Other', 'Special'].includes(state.electionCycle) &&
      !state.otherElectionType
    ) {
      errors.otherElectionType = 'Other election type is required';
    }
  }

  return errors;
};

export const createPayload = state => {
  const payload = {
    electionCycle: state.electionCycle,
    electionYear: +state.electionYear,
    electionCycleOtherDescription: state.otherElectionType || undefined,
    contributionType: 'Monetary',
    contributionCategory: 'Other Receipt',
    contactType: state.contactType,
    paymentType: 'Other',
    paymentReferenceId: state.paymentReferenceId || undefined,
    amount: +state.amount,
    description: state.description || '',
    contactId: state.contactId || undefined,
    receivedDate: formatDateOnly(state.dateReceived),
    isInterest: true,
    ...(['Individual', FECSourceTypeCodes.IND, FECSourceTypeCodes.CAN].includes(
      state.contactType,
    )
      ? {
          salutation:
            state.salutation !== 'none' ? zeroToUndefined(state.salutation) : undefined,
          firstName: state.firstName || undefined,
          middleName: state.middleName || undefined,
          lastName: state.lastName || undefined,
          employer: state.employer || undefined,
          occupation: state.occupation || undefined,
        }
      : {}),
    ...([
      'Business',
      'Committee',
      FECSourceTypeCodes.ORG,
      FECSourceTypeCodes.COM,
      FECSourceTypeCodes.CCM,
      FECSourceTypeCodes.PTY,
      FECSourceTypeCodes.PAC,
    ].includes(state.contactType)
      ? {
          businessName: state.businessName || undefined,
          businessType:
            (['Business', FECSourceTypeCodes.ORG].includes(state.contactType) &&
              state.businessType) ||
            undefined,
          contactName:
            ([
              'Business',
              'Committee',
              FECSourceTypeCodes.ORG,
              FECSourceTypeCodes.COM,
              FECSourceTypeCodes.CCM,
              FECSourceTypeCodes.PAC,
              FECSourceTypeCodes.PTY,
            ].includes(state.contactType) &&
              state.contactName) ||
            undefined,
          committeeAffiliation:
            ([
              'Business',
              'Committee',
              FECSourceTypeCodes.COM,
              FECSourceTypeCodes.PAC,
            ].includes(state.contactType) &&
              state.committeeAffiliation) ||
            undefined,
          commonSource: state.commonSource || undefined,
          specific: state.specific || undefined,
        }
      : {}),
    ...(['Common Source'].includes(state.contactType)
      ? {
          businessName: state.businessName || undefined,
          commonSource: state.commonSource || undefined,
          specific: state.specific || undefined,
        }
      : {}),
    committeeFecId:
      ([
        FECSourceTypeCodes.CAN,
        FECSourceTypeCodes.CCM,
        FECSourceTypeCodes.PAC,
        FECSourceTypeCodes.PTY,
      ].includes(state.contactType) &&
        state.committeeFecId) ||
      undefined,
    address: {
      addressLine1: state.addressLine1 || undefined,
      addressLine2: state.addressLine2 || undefined,
      city: state.city || undefined,
      state: state.state || undefined,
      zipCode: state.zipCode || undefined,
      county: state.county || undefined,
    },
    email: state.email || undefined,
    phone1: state.phone1 || undefined,
    phone2: state.phone2 || undefined,
  };

  return payload;
};
