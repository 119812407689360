import React, {
  useRef,
  useReducer,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown } from 'office-ui-fabric-react/lib/Dropdown';
import { Checkbox } from 'office-ui-fabric-react/lib/Checkbox';
import {
  PrimaryButton,
  Stack,
  TooltipHost,
} from 'office-ui-fabric-react';
import {
  uploadReducer,
  initialState,
  actions,
} from './contributionUploadReducer';

import { CaretDown } from '../../components/icons';
import { types as longRunningProcessTypes } from '../../actions/longRunningProcessActions';
import { types as dataImportActionTypes } from '../../actions/dataImportActions';
import { BackButton, HelpIcon, TextField } from '../../components/common';
import DataImportDialog from '../../components/Dialogs/DataImportDialog';
import { getLongRunningProcess } from '../../selectors';
import { DataImportTypes } from '../../helpers/constants';

import './index.css';

const ContributionUpload = ({ history }) => {
  const [state, localDispatch] = useReducer(
    uploadReducer,
    initialState,
  );

  const reduxDispatch = useDispatch();
  const longRunningProcess = useSelector(getLongRunningProcess);

  const handleChange = fieldName => (e, option) => {
    const value = option.key !== undefined ? option.key : option;
    localDispatch({
      type: actions.HANDLE_CHANGE,
      data: { fieldName, value },
    });
  };

  const filePicker = useRef(null);

  const onChangeHandler = (event) => {
    const chosenFile = event.target.files[0];
    const formData = new FormData();
    formData.append('file', chosenFile);
    formData.set('fileName', chosenFile.name);
    formData.set('size', chosenFile.size);
    formData.set('lastModified', chosenFile.lastModified);
    formData.set('type', chosenFile.type);
    localDispatch({
      type: actions.SET_UPLOAD_FILE,
      data: {
        uploadFile: formData,
      },
    });
  };

  const validate = (state) => {
    let hasErrors = false;
    const errors = {
      importTypeError: '',
      nameError: '',
    };

    if (!state.importType) {
      hasErrors = true;
      errors.importTypeError = 'Import Type is required';
    }

    if (!state.name) {
      hasErrors = true;
      errors.nameError = 'Please specify a name for import';
    }

    localDispatch({ type: actions.SET_ERRORS, data: { errors } });
    return hasErrors;
  };

  const showDialog = async (dataImportId) => {
    localDispatch({
      type: actions.SHOW_UPLOAD_DIALOG,
      data: { dataImportId },
    });
  };

  const closeDialog = async () => {
    localDispatch({
      type: actions.CLOSE_UPLOAD_DIALOG,
    });
  };

  const onLongRunningClose = async (longRunningProcessState) => {
    showDialog(longRunningProcessState?.returnedFromServer?.data?.dataImportId);
  };

  const onClickHandler = async () => {
    const hasErrors = validate(state);
    if (hasErrors) {
      return;
    }
    const payload = {
      createDeposit: state.createDeposit,
    };

    state.uploadFile.set('jobName', 'process-contribution-upload');
    state.uploadFile.set('name', state.name);
    state.uploadFile.set('importType', state.importType);
    state.uploadFile.set('payload', JSON.stringify(payload));

    reduxDispatch({
      type: longRunningProcessTypes.LONG_RUNNING_JOB_WITH_FILE_UPLOAD,
      data: {
        uploadFile: state.uploadFile,
        prettyJobName: 'Process Contribution Upload',
        onCloseCallback: onLongRunningClose,
      },
    });
  };

  const onImport = async (dataImportId) => {
    reduxDispatch({
      type: longRunningProcessTypes.LONG_RUNNING_JOB,
      data: {
        jobName: 'process-contribution-data-import',
        prettyJobName: 'Contribution Import',
        payload: {
          dataImportId,
        },
      },
    });
  };

  const onDelete = async (dataImportId) => {
    reduxDispatch({
      type: dataImportActionTypes.DELETE_DATA_IMPORT,
      data: {
        dataImportId,
      },
    });
  };

  return (
    <div>
      <BackButton history={history} message="Cancel" />
      <div className="ContributionUpload">
        <Stack
          horizontal
        >
          <Stack
            vertical
            tokens={{ childrenGap: '16px' }}
            styles={{ root: { width: '300px', padding: '16px' } }}
          >
            <div>
              <Dropdown
                label="Contribution Import Type"
                onChange={handleChange('importType')}
                selectedKey={state.importType}
                required
                placeholder="Select Contribution Import"
                errorMessage={state.errors.importTypeError}
                options={[
                  ...DataImportTypes,
                ]
                }
                onRenderCaretDown={() => <CaretDown />}
              />
              <TextField
                label="Import Name"
                value={state.name}
                onChange={handleChange('name')}
                errorMessage={state.errors.nameError}
                required={true}
              />
            </div>
            <div>
              <Checkbox
                label="Add deposit"
                checked={state.createDeposit}
                onChange={handleChange('createDeposit')}
                styles={{ root: { marginTop: '24px' } }}
              />
            </div>
            <div className="deposit-actions">
              <h3>Upload File
                <TooltipHost
                  content="Use the expected csv file layout to upload transactions.)"
                  closeDelay={750}
                >
                  <HelpIcon />
                </TooltipHost>
              </h3>
              <input
                className="file-input"
                ref={filePicker}
                type="file"
                onChange={onChangeHandler}
                accept=".csv"
                label="Select CSV Upload File"
              />
              <PrimaryButton
                style={{ marginTop: '12px' }}
                type="button"
                onClick={onClickHandler}
                disabled={!filePicker?.current?.value || state.isLoading}
              >
                Upload Contributions
              </PrimaryButton>
            </div>
          </Stack>
          <Stack>
            <h3>State</h3>
            <pre>
              {JSON.stringify(state, null, 2)}
            </pre>
          </Stack>
          <Stack>
            <h3>Long Running Process</h3>
            <pre>
              {JSON.stringify(longRunningProcess, null, 2)}
            </pre>
          </Stack>
        </Stack>
      </div>
      <DataImportDialog
        dialogHidden={state.uploadDialogHidden}
        onCancel={closeDialog}
        onImport={onImport}
        onDelete={onDelete}
        dataImportId={state.dataImportId}
      />
    </div>
  );
};

ContributionUpload.propTypes = {
  history: PropTypes.object.isRequired,
};

export default ContributionUpload;
