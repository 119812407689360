import { types } from '../actions/importListActions';
import { types as importPollingActionTypes } from '../actions/importPollingActions';
import { ImportStatus, statuses } from '../helpers/constants';
import { createReducer } from './createReducer';

export const initialState = {
  list: [],
  total: 0,
  getImportListStatus: statuses.NOT_STARTED,
  getImportListError: null,
  finalizeImportStatus: statuses.NOT_STARTED,
  finalizeImportFileId: null,
  finalizeImportError: null,
};

const actionMap = {
  [types.GET_IMPORT_LIST_PROCESSING]: state => ({
    ...state,
    getImportListStatus: statuses.PROCESSING,
    getImportListError: null,
  }),

  [types.GET_IMPORT_LIST_SUCCESS]: (state, action) => ({
    ...state,
    getImportListStatus: statuses.SUCCESS,
    getImportListError: null,
    list: action.data.items,
    total: action.data.total,
  }),

  [types.GET_IMPORT_LIST_FAILURE]: (state, action) => ({
    ...state,
    getImportListStatus: statuses.ERROR,
    getImportListError: action.error,
  }),

  [importPollingActionTypes.GET_IMPORT_STATUS_SUCCESS]: (state, action) =>
    action.data.status !== ImportStatus.Pending
      ? {
          ...state,
          list: state.list.map(item =>
            item.fileId === action.data.fileId
              ? { ...item, status: action.data.status }
              : item,
          ),
        }
      : state,

  [types.FINALIZE_IMPORT_PROCESSING]: (state, action) => ({
    ...state,
    finalizeImportStatus: statuses.PROCESSING,
    finalizeImportError: null,
    finalizeImportFileId: action.data.fileId,
  }),

  [types.FINALIZE_IMPORT_SUCCESS]: (state, action) => ({
    ...state,
    list: state.list.map(item =>
      item.fileId === action.data.fileId
        ? { ...item, status: ImportStatus.Pending }
        : item,
    ),
    finalizeImportStatus: statuses.SUCCESS,
    finalizeImportError: null,
    finalizeImportFileId: null,
  }),

  [types.FINALIZE_IMPORT_FAILURE]: (state, action) => ({
    ...state,
    finalizeImportStatus: statuses.ERROR,
    finalizeImportError: action.error,
    finalizeImportFileId: null,
  }),
};

export const importListReducer = createReducer(initialState, actionMap);
