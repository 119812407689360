import React, {
  useEffect,
  // useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogType,
  DialogFooter,
} from 'office-ui-fabric-react/lib/Dialog';
import {
  DefaultButton,
} from 'office-ui-fabric-react/lib/Button';

import { types as dataImportActions } from '../../actions/dataImportActions';

import './GenericMessageDialog.css';

import {
  // getLongRunningProcess,
  getDataImports,
} from '../../selectors';

const DataImportDialog = ({
  dataImportId,
  dialogHidden,
  onCancel,
  onImport,
  onDelete,
}) => {
  const reduxDispatch = useDispatch();
  const dataImports = useSelector(getDataImports);

  useEffect(() => {
    if (dataImportId) {
      reduxDispatch({
        type: dataImportActions.GET_DATA_IMPORT_DETAIL,
        data: { dataImportId },
      });
    }
  }, [dataImportId]);

  const { isOK, totalCount } = dataImports.detail;
  const showImport = isOK && totalCount > 0;

  const doOnDelete = () => {
    onDelete(dataImportId);
  };

  const doOnImport = () => {
    onImport(dataImportId);
  };

  return (
    <Dialog
      hidden={dialogHidden}
      onDismiss={onCancel}
      minWidth={600}
      dialogContentProps={{
        showCloseButton: false,
        type: DialogType.largeHeader,
        title: `'${dataImports.detail.name}' Import`,
        subText: '',
      }}
      modalProps={{
        isBlocking: true,
        containerClassName: 'DataImportDialog',
      }}
    >
      <div>
        <pre>
          {JSON.stringify(dataImports.detail, null, 2)}
        </pre>
      </div>
      <DialogFooter>
        {showImport &&
        <DefaultButton text="Apply Import" onClick={doOnImport} />
        }
        <DefaultButton text="Delete Import" onClick={doOnDelete} />
        <DefaultButton text="Close" onClick={onCancel} />
      </DialogFooter>
    </Dialog>
  );
};

DataImportDialog.propTypes = {
  dialogHidden: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onImport: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  dataImportId: PropTypes.string,
};

DataImportDialog.defaultProps = {
  dialogHidden: true,
  dataImportId: null,
};

export default DataImportDialog;
