import { takeLatest, put, call } from 'redux-saga/effects';
import axios from 'axios';
import { push } from 'connected-react-router';
import { types as verifyUserTypes } from '../actions/verifyUserActions';
import {
  getServerSideErrorMessage,
  errorToast,
  successToast,
} from '../helpers/util';

const url = '/api/userInvitations';

export function* handleVerifyToken(action) {
  try {
    const { token } = action.data;
    yield call(axios.get, `${url}/${token}`);
    yield put({
      type: verifyUserTypes.VERIFY_TOKEN_SUCCESS,
      data: { verified: true, token },
    });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put(errorToast(error));
  }
}

export function* verifyToken() {
  yield takeLatest(verifyUserTypes.VERIFY_TOKEN, handleVerifyToken);
}

export function* handleVerifyUser(action) {
  try {
    const { token, payload } = action.data;
    yield call(axios.put, `${url}/${token}`, payload);
    yield put({ type: verifyUserTypes.VERIFY_USER_SUCCESS });
    yield put(push('/'));
    yield put(
      successToast(
        'Signup successful! Login with your email and the password you just created',
      ),
    );
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put(errorToast(error));
  }
}

export function* verifyUser() {
  yield takeLatest(verifyUserTypes.VERIFY_USER, handleVerifyUser);
}
