import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import {
  PrimaryButton,
  DefaultButton,
} from 'office-ui-fabric-react/lib/Button';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import CreditCardForm from '../../components/CreditCardForm';
import { statuses } from '../../helpers/constants';
import { scrollTo } from '../../helpers/util';
import { GridRow, Column } from '../../components/common';
import './PaymentStep.css';

export class PaymentStep extends Component {
  static propTypes = {
    ccInfo: PropTypes.shape({
      cardholderEmail: PropTypes.string,
      cardholderEmailError: PropTypes.string,
      cardholderName: PropTypes.string,
      cardholderNameError: PropTypes.string,
    }),
    planPrice: PropTypes.string,
    productName: PropTypes.string,
    planPriceStatus: PropTypes.string,
    actions: PropTypes.object.isRequired,
    stripeError: PropTypes.string,
    stripeStatus: PropTypes.string,
    showCoupon: PropTypes.bool,
    onBlurCoupon: PropTypes.func,
    couponError: PropTypes.string,
    couponAmountOff: PropTypes.number,
  };

  static defaultProps = {
    planPrice: '',
    productName: '',
    planPriceStatus: '',
    showCoupon: false,
    onBlurCoupon: () => {},
    couponError: '',
    couponAmountOff: 0,
  };

  componentDidMount() {
    scrollTo(0, 0);
  }

  render() {
    const {
      ccInfo,
      planPrice,
      productName,
      planPriceStatus,
      stripeStatus,
      stripeError,
      actions,
      showCoupon,
      onBlurCoupon,
      couponError,
      couponAmountOff,
    } = this.props;

    return (
      <div className="PaymentStep">
        <CreditCardForm
          ccInfo={ccInfo}
          planPrice={planPrice}
          productName={productName}
          getPlanPriceStatus={planPriceStatus}
          stripeError={stripeError}
          status={stripeStatus}
          actions={actions}
          showCoupon={showCoupon}
          onBlurCoupon={onBlurCoupon}
          couponError={couponError}
          couponAmountOff={couponAmountOff}
        />
        <GridRow className="actions">
          <Column style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {stripeStatus !== statuses.PROCESSING && (
              <Fragment>
                <DefaultButton
                  text="Back"
                  onClick={actions.handlePreviousStep}
                  className="back-btn"
                />
                <PrimaryButton text="Next" onClick={actions.handleNextStep} />
              </Fragment>
            )}
            {stripeStatus === statuses.PROCESSING && (
              <Spinner size={SpinnerSize.large} />
            )}
          </Column>
        </GridRow>
      </div>
    );
  }
}

export default PaymentStep;
