import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  TextField,
  Toggle,
  Separator,
  ChoiceGroup,
  MessageBar,
  MessageBarType,
} from 'office-ui-fabric-react';
import { GridRow, Column, MoneyField } from '../common';
import { DatePicker, SalutationPicker } from '../Pickers';
import DepositoryAccountPicker from '../DepositoryAccountPicker';

const FederalBusinessLoan = ({
  campaign,
  actions,
  interestRate,
  dueDate,
  isLoanRestructured,
  originalLoanDate,
  isLineOfCredit,
  drawAmount,
  outstandingBalance,
  otherPartiesSecondarilyLiable,
  isCollateralPledged,
  collateralPledgedDescription,
  collateralPledgedEstimatedValue,
  lenderPerfectedSecurity,
  areFutureContributionsOrInterestIncomePledged,
  futureContributionsOrInterestDescription,
  futureContributionsOrInterestEstimatedValue,
  depositoryAccount,
  loanBasisDescription,
  setSaveButtonDisabled,
  treasurerDateSigned,
  authorizedRepresentative,
  errors,
}) => {
  return (
    <div className="business-federal-loan" style={{ padding: '0 8px' }}>
      <Separator />
      <GridRow classNames="loan-section">
        <Column md={3}>
          <TextField
            value={interestRate}
            onChange={actions.handleChange('interestRate')}
            errorMessage={errors.interestRateError}
            required
            label="Interest Rate"
            suffix="%APR"
          />
        </Column>
        <Column md={3}>
          <DatePicker
            label="Due Date"
            value={dueDate}
            onChange={actions.handleDateChange('dueDate')}
            required
            errorMessage={errors.dueDateError}
          />
        </Column>
        <Column md={3} classNames="restructured">
          <Toggle
            label="Has Loan Been Restructured"
            onText="Yes"
            offText="No"
            checked={isLoanRestructured}
            onChange={actions.handleChange('isLoanRestructured')}
          />
        </Column>
        {isLoanRestructured && (
          <Column md={3} classNames="restructured">
            <DatePicker
              label="Date Originally Incurred"
              value={originalLoanDate}
              onChange={actions.handleDateChange('originalLoanDate')}
              required
              errorMessage={errors.originalLoanDateError}
            />
          </Column>
        )}
      </GridRow>
      <Separator />
      <GridRow>
        <Column md={4}>
          <Toggle
            label="Is Line of Credit"
            onText="Yes"
            offText="No"
            checked={isLineOfCredit}
            onChange={actions.handleChange('isLineOfCredit')}
          />
        </Column>
        {isLineOfCredit && (
          <Fragment>
            <Column md={4}>
              <MoneyField
                label="Amount of this Draw"
                value={drawAmount}
                required
                errorMessage={errors.drawAmountError}
                onChange={actions.handleChange('drawAmount')}
              />
            </Column>
            <Column md={4}>
              <MoneyField
                label="Total Outstanding Balance"
                value={outstandingBalance}
                required
                errorMessage={errors.outstandingBalanceError}
                onChange={actions.handleChange('outstandingBalance')}
              />
            </Column>
          </Fragment>
        )}
      </GridRow>
      <Separator />
      <GridRow>
        <Column>
          <ChoiceGroup
            label="Are other parties secondarily liable for the debt incurred?"
            selectedKey={otherPartiesSecondarilyLiable}
            required
            onChange={actions.handleChange('otherPartiesSecondarilyLiable')}
            options={[
              {
                key: true,
                text: 'Yes',
                iconProps: {
                  iconName: 'Check',
                },
              },
              {
                key: false,
                text: 'No',
                iconProps: {
                  iconName: 'Times',
                },
              },
            ]}
          />
          {errors.otherPartiesSecondarilyLiableError && (
            <MessageBar messageBarType={MessageBarType.error}>
              {errors.otherPartiesSecondarilyLiableError}
            </MessageBar>
          )}
        </Column>
      </GridRow>
      <Separator />
      <GridRow>
        <Column md={7}>
          <Toggle
            label="Are any of the following pledged as collateral for the loan: real estate, personal
              property, goods, negotiable instruments, certificates of deposit, chattel papers,
              stocks, accounts receivable, cash on deposit, or other similar traditional collateral?"
            onText="Yes"
            offText="No"
            checked={isCollateralPledged}
            onChange={actions.handleChange('isCollateralPledged')}
          />
          {isCollateralPledged && (
            <TextField
              label="Please Specify"
              required
              value={collateralPledgedDescription}
              errorMessage={errors.collateralPledgedDescriptionError}
              onChange={actions.handleChange('collateralPledgedDescription')}
            />
          )}
        </Column>
        {isCollateralPledged && (
          <Column md={5}>
            <MoneyField
              label="What is the value of this collateral"
              required
              value={collateralPledgedEstimatedValue}
              errorMessage={errors.collateralPledgedEstimatedValueError}
              onChange={actions.handleChange('collateralPledgedEstimatedValue')}
            />
            <ChoiceGroup
              label="Does the lender have perfected security interest in it?"
              selectedKey={lenderPerfectedSecurity}
              required
              onChange={actions.handleChange('lenderPerfectedSecurity')}
              options={[
                {
                  key: true,
                  text: 'Yes',
                },
                {
                  key: false,
                  text: 'No',
                },
              ]}
            />
            {errors.lenderPerfectedSecurityError && (
              <p className="error-message">
                {errors.lenderPerfectedSecurityError}
              </p>
            )}
          </Column>
        )}
      </GridRow>
      <Separator />
      <GridRow>
        <Column md={6}>
          <Toggle
            label="Are any future contributions or future receipts of interest income, pledged as collateral for the loan?"
            onText="Yes"
            offText="No"
            checked={areFutureContributionsOrInterestIncomePledged}
            onChange={actions.handleChange(
              'areFutureContributionsOrInterestIncomePledged',
            )}
          />
        </Column>
        {areFutureContributionsOrInterestIncomePledged && (
          <Column md={6}>
            <TextField
              label="Please Specify"
              required
              value={futureContributionsOrInterestDescription}
              onChange={actions.handleChange(
                'futureContributionsOrInterestDescription',
              )}
              errorMessage={
                errors.futureContributionsOrInterestDescriptionError
              }
            />
            <MoneyField
              label="What is the estimated value?"
              required
              value={futureContributionsOrInterestEstimatedValue}
              onChange={actions.handleChange(
                'futureContributionsOrInterestEstimatedValue',
              )}
              errorMessage={
                errors.futureContributionsOrInterestEstimatedValueError
              }
            />
          </Column>
        )}
      </GridRow>
      {areFutureContributionsOrInterestIncomePledged && (
        <GridRow>
          <Column md={5}>
            <p>
              {
                'A depository account must be established pursuant to 11 CFR 100.82(e)(2) and 100.142(e)(2).'
              }
            </p>
            <DatePicker
              label="Date account established"
              onChange={actions.handleDepositoryAccountDateChange}
              value={depositoryAccount.establishedDate}
              required
              errorMessage={errors.establishedDateError}
            />
          </Column>
          <Column md={7} classNames="location-of-account">
            {errors.missingDepositoryAccountError && (
              <MessageBar messageBarType={MessageBarType.error}>
                {errors.missingDepositoryAccountError}
              </MessageBar>
            )}
            <DepositoryAccountPicker
              resetDepositoryAccount={actions.resetDepositoryAccount}
              setSaveButtonDisabled={setSaveButtonDisabled}
              banks={campaign.committee && (campaign.committee.banks || [])}
              handleSelectExistingDepositoryAccount={
                actions.handleSelectExistingDepositoryAccount
              }
              establishedDateError={errors.establishedDateError}
              selectedDepositoryAccount={depositoryAccount}
              clearSelectedDepositoryAccount={
                actions.clearSelectedDepositoryAccount
              }
              handleDepositoryAccountChange={
                actions.handleDepositoryAccountChange
              }
              handleSaveDepositoryAccount={actions.handleSaveDepositoryAccount}
              errors={errors}
            />
          </Column>
        </GridRow>
      )}
      <Separator />
      <GridRow>
        <Column>
          <TextField
            label={`If neither of the types of collateral described above was pledged for this loan, or if the amount pledged does not equal or
   exceed the loan amount, state the basis upon which this loan was made and the basis on which it assures repayment.`}
            multiline
            rows={3}
            value={loanBasisDescription}
            onChange={actions.handleChange('loanBasisDescription')}
          />
        </Column>
      </GridRow>
      <GridRow classNames="loan-section">
        <h4>Committee Treasurer</h4>
        <Column md={5}>
          <DatePicker
            label="Date Treasurer Signed"
            value={treasurerDateSigned}
            onChange={actions.handleChangeTreasurerSignedDate}
            errorMessage={errors.treasurerDateSignedError}
          />
        </Column>
      </GridRow>
      <Separator />
      <GridRow>
        <h4>Authorized Representative</h4>
        <Column md={2}>
          <SalutationPicker
            selectedKey={authorizedRepresentative.prefix}
            label="Prefix"
            onChange={actions.handleChangeAuthorizedRepText('prefix')}
          />
        </Column>
        <Column md={4}>
          <TextField
            label="First Name"
            value={authorizedRepresentative.firstName}
            onChange={actions.handleChangeAuthorizedRepText('firstName')}
          />
        </Column>
        <Column md={2}>
          <TextField
            label="Middle Name"
            value={authorizedRepresentative.middleName}
            onChange={actions.handleChangeAuthorizedRepText('middleName')}
          />
        </Column>
        <Column md={4}>
          <TextField
            label="Last Name"
            value={authorizedRepresentative.lastName}
            onChange={actions.handleChangeAuthorizedRepText('lastName')}
          />
        </Column>
      </GridRow>
      <GridRow>
        <Column md={2}>
          <TextField
            label="Suffix"
            value={authorizedRepresentative.suffix}
            onChange={actions.handleChangeAuthorizedRepText('suffix')}
          />
        </Column>
        <Column md={4}>
          <TextField
            label="Title"
            value={authorizedRepresentative.title}
            onChange={actions.handleChangeAuthorizedRepText('title')}
          />
        </Column>
        <Column md={4}>
          <DatePicker
            onChange={actions.handleChangeAuthorizedRepDateSigned}
            value={authorizedRepresentative.dateSigned}
            label="Date Representative Signed"
            errorMessage={errors.authorizedRepDateSignedError}
          />
        </Column>
      </GridRow>
    </div>
  );
};

FederalBusinessLoan.propTypes = {
  campaign: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  interestRate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  interestRateError: PropTypes.string,
  dueDate: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.number,
  ]),
  dueDateError: PropTypes.string,
  isLoanRestructured: PropTypes.bool,
  originalLoanDate: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.number,
  ]),
  originalLoanDateError: PropTypes.string,
  isLineOfCredit: PropTypes.bool,
  drawAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  drawAmountError: PropTypes.string,
  outstandingBalance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  outstandingBalanceError: PropTypes.string,
  otherPartiesSecondarilyLiable: PropTypes.bool,
  otherPartiesSecondarilyLiableError: PropTypes.string,
  isCollateralPledged: PropTypes.bool,
  collateralPledgedDescription: PropTypes.string,
  collateralPledgedDescriptionError: PropTypes.string,
  collateralPledgedEstimatedValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  collateralPledgedEstimatedValueError: PropTypes.string,
  lenderPerfectedSecurity: PropTypes.bool,
  lenderPerfectedSecurityError: PropTypes.string,
  areFutureContributionsOrInterestIncomePledged: PropTypes.bool,
  futureContributionsOrInterestDescription: PropTypes.string,
  futureContributionsOrInterestDescriptionError: PropTypes.string,
  futureContributionsOrInterestEstimatedValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  futureContributionsOrInterestEstimatedValueError: PropTypes.string,
  depositoryAccount: PropTypes.object,
  depositoryAccountError: PropTypes.string,
  loanBasisDescription: PropTypes.string,
  selectedDepositoryAccount: PropTypes.object,
  setSaveButtonDisabled: PropTypes.func.isRequired,
  missingDepositoryAccountError: PropTypes.string,
  establishedDateError: PropTypes.string,
  treasurerDateSigned: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  treasurerDateSignedError: PropTypes.string,
  authorizedRepresentative: PropTypes.object.isRequired,
  authorizedRepDateSignedError: PropTypes.string,
  errors: PropTypes.object,
};

FederalBusinessLoan.defaultProps = {
  interestRate: '',
  interestRateError: '',
  dueDate: '',
  dueDateError: '',
  isLoanRestructured: false,
  originalLoanDate: '',
  originalLoanDateError: '',
  isLineOfCredit: false,
  drawAmount: '',
  drawAmountError: '',
  outstandingBalance: '',
  outstandingBalanceError: '',
  otherPartiesSecondarilyLiable: null,
  otherPartiesSecondarilyLiableError: '',
  isCollateralPledged: null,
  collateralPledgedDescription: '',
  collateralPledgedDescriptionError: '',
  collateralPledgedEstimatedValue: '',
  collateralPledgedEstimatedValueError: '',
  lenderPerfectedSecurity: null,
  lenderPerfectedSecurityError: '',
  areFutureContributionsOrInterestIncomePledged: null,
  futureContributionsOrInterestDescription: '',
  futureContributionsOrInterestDescriptionError: '',
  futureContributionsOrInterestEstimatedValue: '',
  futureContributionsOrInterestEstimatedValueError: '',
  depositoryAccount: {},
  depositoryAccountError: '',
  loanBasisDescription: '',
  selectedDepositoryAccount: {
    establishedDate: '',
    establishedDateError: '',
    addressLine1: '',
    addressLine1Error: '',
    addressLine2: '',
    city: '',
    cityError: '',
    state: 0,
    stateError: '',
    zipCode: '',
    zipCodeError: '',
  },
  missingDepositoryAccountError: '',
  establishedDateError: '',
  treasurerDateSignedError: '',
  authorizedRepDateSignedError: '',
  errors: {},
};

export default FederalBusinessLoan;
