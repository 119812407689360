import React from 'react';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import './Loading.css';

const Loading = () => (
  <div className="Loading">
    <div className="wrapper">
      <Spinner size={SpinnerSize.large} />
    </div>
  </div>
);

export default Loading;
