export function createReducer(initialState, actionMap, withReset = true) {
  let map = { ...actionMap };
  if (withReset) {
    map = { ...map, RESET_STATE: () => initialState };
  }
  return (state = { ...initialState }, action) => {
    if (map[action.type]) {
      return map[action.type](state, action);
    }

    return state;
  };
}

export function createDebugReducer(initialState, actionMap, withReset = true) {
  let map = { ...actionMap };
  if (withReset) {
    map = { ...map, RESET_STATE: () => initialState };
  }
  return (state = { ...initialState }, action) => {
    // eslint-disable-next-line
    console.log('Reducer: before', action, state);
    if (map[action.type]) {
      const ret = map[action.type](state, action);
      // eslint-disable-next-line
      console.log('Reducer: after', action, ret);
      return ret;
    }
    // eslint-disable-next-line
    console.log('Reducer: after, no action taken', action);
    return state;
  };
}
