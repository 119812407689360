import { initialState } from './createContactReducer';
import { nonIndividualErrorTypeMap } from '../../helpers/constants';

export const validate = state => {
  const errors = {
    ...initialState.errors,
  };

  if (state.type === 0) {
    errors.typeError = 'Contact type is required';
  } else if (['IND', 'Individual', 'CAN'].includes(state.type)) {
    if (state.firstName.length === 0) {
      errors.firstNameError = 'First name is required';
    }

    if (state.lastName.length === 0) {
      errors.lastNameError = 'Last name is required';
    }
  } else if (!['IND', 'Individual', 'Candidate'].includes(state.type)) {
    if (state.businessName.length === 0) {
      errors.businessNameError = `${
        nonIndividualErrorTypeMap[state.type]
      } is required`;
    }
  }

  return errors;
};
