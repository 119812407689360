import React from 'react';
import PropTypes from 'prop-types';
import { GridRow, Column, MoneyField } from '../../common';
import { getTotalLoanRepayments } from '../../../pages/Reports/fecReportHelper';

const FECSection19 = ({ state }) => (
  <GridRow>
    <Column sm={4} classNames="sub-question">
      <p>
        (c) TOTAL LOAN REPAYMENTS
        <br />
        {'(add Lines 19(a) and (b))'}
      </p>
    </Column>
    <Column sm={4} classNames="entry-row">
      <MoneyField value={getTotalLoanRepayments('A', state) || 0} disabled />
    </Column>
    <Column sm={4} classNames="entry-row">
      <MoneyField value={getTotalLoanRepayments('B', state) || 0} disabled />
    </Column>
  </GridRow>
);

FECSection19.propTypes = {
  state: PropTypes.object.isRequired,
};

export default FECSection19;
