export const types = {
  GET_UNPROCESSED_EARMARKS_SUMMARY: 'GET_UNPROCESSED_EARMARKS_SUMMARY',
  GET_UNPROCESSED_EARMARKS_SUMMARY_PROCESSING: 'GET_UNPROCESSED_EARMARKS_SUMMARY_PROCESSING',
  GET_UNPROCESSED_EARMARKS_SUMMARY_SUCCESS: 'GET_UNPROCESSED_EARMARKS_SUMMARY_SUCCESS',
  APPLY_EARMARK_IMPORT_RECORDS: 'APPLY_EARMARK_IMPORT_RECORDS',
  APPLY_EARMARK_IMPORT_RECORDS_PROCESSING: 'APPLY_EARMARK_IMPORT_RECORDS_PROCESSING',
  APPLY_EARMARK_IMPORT_RECORDS_SUCCESS: 'APPLY_EARMARK_IMPORT_RECORDS_SUCCESS',
  APPLY_EARMARK_IMPORT_RECORDS_FAILURE: 'APPLY_EARMARK_IMPORT_RECORDS_FAILURE',
  REMOVE_EARMARK_IMPORT_RECORDS: 'REMOVE_EARMARK_IMPORT_RECORDS',
  REMOVE_EARMARK_IMPORT_RECORDS_PROCESSING: 'REMOVE_EARMARK_IMPORT_RECORDS_PROCESSING',
  REMOVE_EARMARK_IMPORT_RECORDS_SUCCESS: 'REMOVE_EARMARK_IMPORT_RECORDS_SUCCESS',
  UPLOAD_EARMARK_SPREADSHEET: 'UPLOAD_EARMARK_SPREADSHEET',
  UPLOAD_EARMARK_SPREADSHEET_PROCESSING: 'UPLOAD_EARMARK_SPREADSHEET_PROCESSING',
  UPLOAD_EARMARK_SPREADSHEET_SUCCESS: 'UPLOAD_EARMARK_SPREADSHEET_SUCCESS',
  UPLOAD_EARMARK_SPREADSHEET_FAILURE: 'UPLOAD_EARMARK_SPREADSHEET_FAILURE',
  BACKGROUND_EARMARK_IMPORT_RECORDS_PROCESSING: 'BACKGROUND_EARMARK_IMPORT_RECORDS_PROCESSING',
  BACKGROUND_EARMARK_IMPORT_RECORDS_SUCCESS: 'BACKGROUND_EARMARK_IMPORT_RECORDS_SUCCESS',
  BACKGROUND_EARMARK_IMPORT_RECORDS_FAILURE: 'BACKGROUND_EARMARK_IMPORT_RECORDS_FAILURE',
};

export const actions = {
  getUnprocessedEarmarksSummary() {
    return { type: types.GET_UNPROCESSED_EARMARKS_SUMMARY };
  },
  applyEarmarkImportRecords(fileHash, payload) {
    return { type: types.APPLY_EARMARK_IMPORT_RECORDS, data: { fileHash, payload } };
  },
  removeEarmarkImportRecords(fileHash) {
    return { type: types.REMOVE_EARMARK_IMPORT_RECORDS, data: { fileHash } };
  },
  uploadEarmarkSpreadsheet(spreadsheet) {
    return { type: types.UPLOAD_EARMARK_SPREADSHEET, data: { spreadsheet } };
  },
};
