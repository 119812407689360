import {
  CheckboxVisibility,
  DetailsList,
  Dialog,
  DialogType,
  IconButton,
  PrimaryButton,
  SelectionMode,
  Spinner,
  Text,
  TooltipHost,
} from 'office-ui-fabric-react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { actions as importErrorsActions } from '../../../actions/importErrorsActions';
import { actions as importListActions } from '../../../actions/importListActions';
import { BackButton } from '../../../components/common';
import Loading from '../../../components/Loading';
import { ImportColumnType, statuses } from '../../../helpers/constants';
import { formatDate } from '../../../helpers/util';
import { getImportErrors, getImportList } from '../../../selectors';
import EditImportRowDialogBody from './EditImportRowDialogBody';
import './index.css';

const ImportErrors = ({ history, match }) => {
  const reduxDispatch = useDispatch();
  const {
    errorsData,
    getImportErrorsStatus,
    saveImportCorrectionsStatus,
  } = useSelector(getImportErrors);
  const { finalizeImportStatus, finalizeImportFileId } = useSelector(
    getImportList,
  );
  const { id: fileId } = match.params;
  const isFinalizing =
    finalizeImportStatus === statuses.PROCESSING &&
    finalizeImportFileId === fileId;
  const [editingRowIndex, setEditingRowIndex] = useState();

  useEffect(() => {
    reduxDispatch(importErrorsActions.getImportErrors(fileId));
  }, [fileId]);

  useEffect(() => {
    if (
      saveImportCorrectionsStatus === statuses.SUCCESS &&
      editingRowIndex !== undefined
    ) {
      setEditingRowIndex(undefined);
    }
  }, [saveImportCorrectionsStatus]);

  const handleActionCellClick = rowIndex => {
    setEditingRowIndex(rowIndex);
  };

  const handleDismissDialog = () => {
    setEditingRowIndex(undefined);
  };

  const handleSaveCorrections = values => {
    reduxDispatch(
      importErrorsActions.saveImportCorrections(fileId, {
        index: editingRowIndex,
        values,
      }),
    );
  };

  const handleFinalize = () => {
    reduxDispatch(importListActions.finalizeImport(fileId, true));
  };

  const additionalColumns = [
    {
      key: 'action',
      name: '',
      fieldName: 'action',
      minWidth: 50,
      maxWidth: 200,
      isResizable: true,
      onRender: item => (
        <TooltipHost content="Edit values">
          <IconButton
            style={{ height: 16, width: 16 }}
            iconProps={{ iconName: 'PencilAlt' }}
            onClick={() => handleActionCellClick(item.index)}
          />
        </TooltipHost>
      ),
    },
    {
      key: 'errors',
      name: 'Errors',
      fieldName: 'errors',
      minWidth: 500,
      isResizable: true,
      onRender: item => (
        <div className="import-errors-error-cell ">
          {Object.entries(item.errors)
            // eslint-disable-next-line no-unused-vars
            .map(([key, value]) => value)
            .join(', ')}
        </div>
      ),
    },
  ];

  const editingRow = errorsData?.rows.find(
    ({ index }) => index === editingRowIndex,
  );

  const columnsConfig = errorsData && [
    ...additionalColumns,
    ...errorsData.columns.map(({ label, type }, i) => ({
      key: i,
      name: label,
      fieldName: String(i),
      minWidth: 250,
      onRender: item => (
        <span>
          {type === ImportColumnType.Date && item[i]
            ? formatDate(item[i])
            : item[i]}
        </span>
      ),
    })),
  ];

  const tableItems =
    errorsData &&
    errorsData.rows.map(({ values, ...rest }) => ({
      ...rest,
      ...Object.fromEntries(Object.entries(values)),
    }));

  return !errorsData || getImportErrorsStatus === statuses.PROCESSING ? (
    <Loading />
  ) : (
    <div className="ImportErrors">
      <BackButton
        url="/filer/dev/importList"
        history={history}
        pageTitle="Import Errors"
      />
      <div className="import-errors-content depth-1">
        <h2>Import Errors</h2>
        {tableItems.length ? (
          <div className="import-errors-table">
            <DetailsList
              items={tableItems}
              columns={columnsConfig}
              compact={false}
              selectionMode={SelectionMode.none}
              checkboxVisibility={CheckboxVisibility.none}
            />
          </div>
        ) : (
          <div className="import-errors-finalize-container">
            <Text>All errors have been fixed. Please finalize the import.</Text>
            <div className="import-errors-finalize-bttn-container">
              <PrimaryButton
                text="Finalize import"
                onClick={handleFinalize}
                disabled={isFinalizing}
              />
              {isFinalizing && <Spinner />}
            </div>
          </div>
        )}
        <Dialog
          hidden={editingRowIndex === undefined}
          onDismiss={handleDismissDialog}
          dialogContentProps={{
            type: DialogType.normal,
            title: 'Edit row values in order to fix errors:',
            subText: '',
          }}
          modalProps={{
            isBlocking: false,
            containerClassName: 'EditRowValuesDialog',
          }}
          minWidth={600}
          maxWidth={750}
        >
          <EditImportRowDialogBody
            row={editingRow}
            columns={errorsData.columns}
            onSaveCorrections={handleSaveCorrections}
            onCloseDialog={handleDismissDialog}
            isSavingCorrections={
              saveImportCorrectionsStatus === statuses.PROCESSING
            }
          />
        </Dialog>
      </div>
    </div>
  );
};

export default withRouter(ImportErrors);
