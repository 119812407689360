import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Dropdown } from 'office-ui-fabric-react/lib/Dropdown';
import { CaretDown } from '../icons';
import { getElectionCycles } from '../../selectors';

export const ElectionCyclePicker = ({
  value = { key: 0 },
  className = '',
  errorMessage = '',
  required = false,
  style = {},
  label = '',
  placeholder = 'Select',
  disabled = false,
  onChange,
}) => {
  const electionCycles = useSelector(getElectionCycles);
  return (
    <Dropdown
      onRenderCaretDown={() => <CaretDown />}
      selectedKey={value}
      onChange={onChange}
      className={className}
      placeholder={placeholder}
      style={style}
      errorMessage={errorMessage || electionCycles.electionCyclesError || ''}
      required={required}
      label={label}
      disabled={disabled}
      options={[
        { key: 0, text: 'Select' },
        ...electionCycles.cycles.map(ec => ({ key: ec.text, text: ec.text })),
      ]}
    />
  );
};

ElectionCyclePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
  errorMessage: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.object,
  ]),
  required: PropTypes.bool,
  style: PropTypes.object,
  electionCycles: PropTypes.object,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
};

export default ElectionCyclePicker;
