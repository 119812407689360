import React from 'react';
import PropTypes from 'prop-types';
import {
  ActionButton,
  DefaultButton,
  PrimaryButton,
} from 'office-ui-fabric-react/lib/Button';
import { Grid, GridRow, Column } from '../common';
import { EmptyView } from '../EmptyView';
import { formatCurrency } from '../../helpers/util';
import { ContributionsList } from '../ContributionsList';
import { getLabel } from '../../helpers/labelHelper';

export const ContributionsSection = ({
  items,
  actions,
  isExpanded,
  totalAmount,
  totalInKind,
  session,
}) => {
  return (
    <div className="contributions depth-1" name="contributions">
      <header onClick={actions.toggleExpand}>
        <h3>{getLabel('Contributions', session)}</h3>
        <label>
          {`Amount: ${formatCurrency(totalAmount)} | In-Kind: ${formatCurrency(
            totalInKind,
          )}`}
        </label>
      </header>
      <div className={`content${isExpanded ? '-expanded' : '-collapsed'}`}>
        <Grid>
          <GridRow>
            <Column classNames="ms-textAlignRight">
              <ActionButton
                iconProps={{
                  iconName: 'PlusCircle',
                  styles: {
                    root: {
                      fontSize: '1.3em',
                    },
                  },
                }}
                onClick={actions.addNew}
              >
                {`Add New ${getLabel('Contribution', session)}`}
              </ActionButton>
            </Column>
          </GridRow>
          {items.length > 0 && (
            <ContributionsList
              filterFn={null}
              editItem={actions.editItem}
              contributions={{
                contributions: [...items],
              }}
              session={session}
            />
          )}
          {items.length === 0 && (
            <EmptyView
              message={`There are no ${getLabel(
                'contributions',
                session,
              )} this reporting period.`}
            />
          )}
        </Grid>
        <div className="section-actions">
          <DefaultButton
            text="Back"
            style={{ marginRight: 16 }}
            onClick={actions.previousSection}
          />
          <PrimaryButton text="Continue" onClick={actions.nextSection} />
        </div>
      </div>
    </div>
  );
};

ContributionsSection.propTypes = {
  actions: PropTypes.object.isRequired,
  items: PropTypes.array,
  isExpanded: PropTypes.bool,
  totalAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  totalInKind: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  session: PropTypes.object.isRequired,
};

ContributionsSection.defaultProps = {
  items: [],
  isExpanded: false,
  totalAmount: 0,
  totalInKind: 0,
};

export default ContributionsSection;
