import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'office-ui-fabric-react/lib/Dropdown';
import { CaretDown } from '../icons';
import { FecElectionCycles } from '../../helpers/constants';

const FECElectionCyclePicker = ({
  value = 0,
  className = '',
  errorMessage = '',
  required = false,
  style = {},
  label = 'Election',
  placeholder = 'Select',
  onChange,
  disabled = false,
}) => (
  <Dropdown
    onRenderCaretDown={() => <CaretDown />}
    selectedKey={value}
    onChange={onChange}
    className={className}
    placeholder={placeholder}
    style={style}
    errorMessage={errorMessage}
    required={required}
    label={label}
    disabled={disabled}
    options={[
      { key: 0, text: 'Select' },
      ...FecElectionCycles.map(ec => ({
        key: ec,
        text: ec,
      })),
    ]}
  />
);

FECElectionCyclePicker.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.object,
  ]),
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  errorMessage: PropTypes.string,
  required: PropTypes.bool,
  style: PropTypes.object,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
};

export default FECElectionCyclePicker;
