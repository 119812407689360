import { takeLatest, call, put } from 'redux-saga/effects';
import axios from 'axios';
import { types } from '../actions/validationActions';
import { getServerSideErrorMessage, errorToast } from '../helpers/util';

export function* handleValidateCheckNumber(action) {
  try {
    const { contactId, checkNumber, type } = action.data;
    if (contactId && checkNumber && type) {
      const { data: exists } = yield call(
        axios.get,
        `/api/filer/contacts/${contactId}/checks/${checkNumber}/exists?activityType=${type}`,
        { withCredentials: true },
      );
      if (!exists) {
        yield put({ type: types.CLEAR_CHECK_NUMBER_VALIDATION });
      } else {
        yield put({
          type: types.VALIDATE_CHECK_NUMBER_ERROR,
          error: 'Warning: Possible Duplicate Check Number',
        });
      }
    }
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put(errorToast(error));
  }
}

export function* validateCheckNumber() {
  yield takeLatest(types.VALIDATE_CHECK_NUMBER, handleValidateCheckNumber);
}
