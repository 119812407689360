import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'office-ui-fabric-react/lib/Link';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import {
  DetailsList,
  CheckboxVisibility,
  SelectionMode,
} from 'office-ui-fabric-react/lib/DetailsList';
import { ActionButton, DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { StatePicker } from '../../components/Pickers';
import { Grid, GridRow, Column, ZipCodeField } from '../../components/common';
import { frCampaignTypes, sortDirections } from '../../helpers/constants';
import { sortByField } from '../../helpers/util';
import './EditBanksSection.css';

const startState = {
  location: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: '',
  zipCode: '',
  isEdit: false,
};

const EditBanksSection = ({
  banksDetailsExpanded,
  campaign,
  campaignType,
  actions,
  session,
}) => {
  const [state, setState] = useState({ ...startState });
  const [sorts, setSorts] = useState({
    field: 'location',
    sortDirection: sortDirections.NONE,
  });

  const handleChange = fieldName => (e, value) => {
    setState({
      ...state,
      [fieldName]: value.key !== undefined ? value.key : value,
    });
  };

  const onCancel = () => {
    setState({ ...startState });
  };

  const onSave = () => {
    const {
      _id,
      location,
      addressLine1,
      addressLine2,
      city,
      state: bankState,
      zipCode,
    } = state;
    if (state.isEdit) {
      actions.editBank({
        _id,
        location,
        addressLine1,
        addressLine2,
        city,
        state: bankState,
        zipCode,
      });
    } else {
      actions.onSave({
        location,
        addressLine1,
        addressLine2,
        city,
        state: bankState,
        zipCode,
      });
    }
    setState({ ...startState });
  };

  const mapBanks = () =>
    campaign.banks.sort(sortByField(sorts.field, sorts.sortDirection));

  const onColumnClick = (e, column) => {
    if (sorts.field === column.fieldName) {
      if (sorts.sortDirection === sortDirections.ASC) {
        setSorts({ field: sorts.field, sortDirection: sortDirections.DESC });
      } else if (sorts.sortDirection === sortDirections.DESC) {
        setSorts({ field: sorts.field, sortDirection: sortDirections.NONE });
      } else {
        setSorts({ field: sorts.field, sortDirection: sortDirections.ASC });
      }
    } else {
      setSorts({ field: column.fieldName, sortDirection: sortDirections.ASC });
    }
  };

  const editBank = bank => {
    setState({ ...bank, isEdit: true });
  };

  const createColumns = () => [
    {
      key: 'name',
      name: 'Name',
      fieldName: 'location',
      minWidth: 100,
      maxWidth: 175,
      isSorted:
        sorts.field === 'location' &&
        sorts.sortDirection !== sortDirections.NONE,
      isSortedDescending: sorts.sortDirection === sortDirections.DESC,
      onColumnClick,
    },
    {
      key: 'address',
      name: 'Address',
      fieldName: 'addressLine1',
      minWidth: 200,
      maxWidth: 200,
      onRender: item =>
        `${item.addressLine1 || ''}${
          item.addressLine2 ? ', ' + item.addressLine2 : ''
        } ${item.city || ''} ${item.state || ''} ${item.zipCode}`,
      isSorted:
        sorts.field === 'addressLine1' &&
        sorts.sortDirection !== sortDirections.NONE,
      isSortedDescending: sorts.sortDirection === sortDirections.DESC,
      onColumnClick,
    },
    {
      key: 'actions',
      name: '',
      minWidth: 200,
      maxWidth: 200,
      onRender: item => (
        <div className="bank-action-items">
          <ActionButton
            text="Edit"
            onClick={() => editBank(item)}
            iconProps={{
              iconName: 'PencilAlt',
              styles: {
                marginRight: 16,
              },
            }}
          />
          <ActionButton
            text="Delete"
            // eslint-disable-next-line react/prop-types
            onClick={() => actions.removeBank(item._id)}
            iconProps={{
              iconName: 'TrashAlt',
            }}
          />
        </div>
      ),
    },
  ];

  return (
    <div className="EditBanksSection depth-1" name="banks-details">
      <h3>
        Banks or Other Depositories{' '}
        <small>
          {' '}
          (
          <Link onClick={actions.toggleExpandBankDetails}>
            {banksDetailsExpanded ? 'Collapse' : 'Expand'}
          </Link>
          )
        </small>
      </h3>
      <div
        className={`edit-banks-details-content${
          banksDetailsExpanded ? ' expanded' : ''
        }`}
      >
        <Grid>
          <GridRow>
            <Column md={4}>
              <h4>{`${state.isEdit ? 'Edit' : 'Add New'} Bank/Depository`}</h4>
              <TextField
                label="Bank/Depository Name"
                value={state.location}
                onChange={handleChange('location')}
              />
              <TextField
                label="Mailing Address 1"
                value={state.addressLine1}
                onChange={handleChange('addressLine1')}
              />
              <TextField
                label="Mailing Address 2"
                value={state.addressLine2}
                onChange={handleChange('addressLine2')}
              />
              <TextField
                label="City"
                value={state.city}
                onChange={handleChange('city')}
              />
              <StatePicker
                selectedKey={state.state}
                onChange={handleChange('state')}
                session={session}
              />
              <ZipCodeField
                value={state.zipCode}
                onChange={handleChange('zipCode')}
              />
              <div className="add-bank-actions">
                <DefaultButton
                  text="Cancel"
                  onClick={onCancel}
                  disabled={
                    !state.location &&
                    !state.addressLine1 &&
                    !state.addressLine2 &&
                    !state.city &&
                    !state.state &&
                    !state.zipCode
                  }
                  styles={{
                    root: {
                      marginRight: 16,
                    },
                  }}
                />
                <DefaultButton
                  text={state.isEdit ? 'Save' : 'Add'}
                  onClick={onSave}
                />
              </div>
            </Column>
            <Column md={8}>
              {campaign.banks.length > 0 && (
                <div className="bank-list">
                  <DetailsList
                    items={mapBanks()}
                    columns={createColumns()}
                    checkboxVisibility={CheckboxVisibility.hidden}
                    selectionMode={SelectionMode.none}
                  />
                </div>
              )}
              {campaign.banks.length === 0 && (
                <div className="no-banks-listed">
                  <Icon
                    iconName="University"
                    styles={{ root: { fontSize: 24 } }}
                  />
                  <p>No banks/depositories listed</p>
                </div>
              )}
            </Column>
          </GridRow>
        </Grid>
      </div>
      {banksDetailsExpanded && (
        <div className="ms-textAlignRight">
          <DefaultButton
            style={{ marginRight: 8 }}
            text="Back"
            onClick={() => actions.previousStep(5)}
          />
          {campaignType === frCampaignTypes.FederalNonCandidate &&
          <DefaultButton text="Continue" onClick={() => actions.nextStep(8)} />
          }
        </div>
      )}
    </div>
  );
};

EditBanksSection.propTypes = {
  campaign: PropTypes.object,
  campaignType: PropTypes.string,
  actions: PropTypes.object.isRequired,
  banksDetailsExpanded: PropTypes.bool,
  session: PropTypes.object.isRequired,
};

EditBanksSection.defaultProps = {
  campaign: {},
  campaignType: '',
  banksDetailsExpanded: false,
};

export default EditBanksSection;
