import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'office-ui-fabric-react/lib/Dropdown';
import { iowaRelationshipsToCandidateMap } from '../../helpers/constants';
import { CaretDown } from '../icons';

export const RelationshipToCandidatePicker = ({
  onChange,
  selectedKey,
  required,
  label,
  errorMessage,
}) => (
  <>
    <Dropdown
      onRenderCaretDown={() => <CaretDown />}
      label={label}
      onChange={onChange}
      required={required}
      selectedKey={selectedKey}
      errorMessage={errorMessage}
      options={[
        ...iowaRelationshipsToCandidateMap.map(s => ({
          key: s.key,
          text: s.text,
        })),
      ]}
    />
  </>
);

RelationshipToCandidatePicker.propTypes = {
  selectedKey: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  label: PropTypes.string,
  errorMessage: PropTypes.string,
};

RelationshipToCandidatePicker.defaultProps = {
  selectedKey: 1,
  required: false,
  label: 'Relationship to Candidate',
  errorMessage: '',
};

export default RelationshipToCandidatePicker;
