// import Moment from 'moment';
import { createReducer } from './createReducer';

export const types = {
  CLEAR_DATES: 'CLEAR_DATES',
  CLEAR_SEARCH: 'CLEAR_SEARCH',
  HANDLE_CHANGE: 'HANDLE_CHANGE',
  SET_NAME_SEARCH: 'SET_NAME_SEARCH',
  SET_NO_EMPLOYEE_OR_OCCUPUPATION_VALIDATION: 'SET_NO_EMPLOYEE_OR_OCCUPUPATION_VALIDATION',
};

export const initialState = {
  nameSearchValue: '',
  startDate: '',
  endDate: '',
  checkForNoEmployeeOrOccupation: false,
  electionYear: '',
  electionCycle: '',
  contactType: '',
  byYear: false,
};

export const actionMap = {
  [types.SET_NAME_SEARCH]: (
    state,
    { data: { nameSearchValue } },
  ) => {
    return {
      ...state,
      nameSearchValue,
    };
  },
  [types.SET_NO_EMPLOYEE_OR_OCCUPUPATION_VALIDATION]: (
    state,
    { data: { checkForNoEmployeeOrOccupation } },
  ) => {
    return {
      ...state,
      checkForNoEmployeeOrOccupation,
    };
  },
  [types.CLEAR_SEARCH]: (
    state,
  ) => {
    return {
      ...state,
      nameSearchValue: '',
      startDate: '',
      endDate: '',
      contactType: '',
      checkForNoEmployeeOrOccupation: false,
    };
  },
  [types.CLEAR_DATES]: (
    state,
  ) => {
    return {
      ...state,
      startDate: '',
      endDate: '',
    };
  },
  [types.HANDLE_CHANGE]: (state, { data: { fieldName, value } }) => {
    return {
      ...state,
      [fieldName]: value, // Moment.utc(value).format(),
    };
  },
};

export const searchValueReducer = createReducer(initialState, actionMap);
