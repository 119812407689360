export const types = {
  GET_EXPENDITURES: 'GET_EXPENDITURES',
  GET_EXPENDITURES_PROCESSING: 'GET_EXPENDITURES_PROCESSING',
  GET_EXPENDITURES_SUCCESS: 'GET_EXPENDITURES_SUCCESS',
  GET_EXPENDITURES_FAILURE: 'GET_EXPENDITURES_FAILURE',
  GET_EXPENDITURES_BY_CONTACT: 'GET_EXPENDITURES_BY_CONTACT',
  GET_EXPENDITURES_BY_CONTACT_PROCESSING: 'GET_EXPENDITURES_BY_CONTACT_PROCESSING',
  GET_EXPENDITURES_BY_CONTACT_SUCCESS: 'GET_EXPENDITURES_BY_CONTACT_SUCCESS',
  GET_EXPENDITURES_BY_CONTACT_FAILURE: 'GET_EXPENDITURES_BY_CONTACT_FAILURE',
  SAVE_EXPENDITURE: 'SAVE_EXPENDITURE',
  SAVE_FEC_EXPENDITURE: 'SAVE_FEC_EXPENDITURE',
  DELETE_EXPENDITURE: 'DELETE_EXPENDITURE',
  DELETE_FEC_EXPENDITURE: 'DELETE_FEC_EXPENDITURE',
  UPDATE_FEC_EXPENDITURE: 'UPDATE_FEC_EXPENDITURE',
  UPDATE_EXPENDITURE: 'UPDATE_EXPENDITURE',
  SAVE_EXPENDITURE_PROCESSING: 'SAVE_EXPENDITURE_PROCESSING',
  SAVE_EXPENDITURE_RESET: 'SAVE_EXPENDITURE_RESET',
  SAVE_EXPENDITURE_FAILURE: 'SAVE_EXPENDITURE_FAILURE',
  SAVE_EXPENDITURE_SUCCESS: 'SAVE_EXPENDITURE_SUCCESS',
  SAVE_FEE_TRANSACTION: 'SAVE_FEE_TRANSACTION',
  SAVE_FEE_TRANSACTION_SUCCESS: 'SAVE_FEE_TRANSACTION_SUCCESS',
  SAVE_FEE_TRANSACTION_FAILURE: 'SAVE_FEE_TRANSACTION_FAILURE',
  GET_EXPENDITURES_NEXT_PAGE: 'GET_EXPENDITURES_NEXT_PAGE',
  GET_EXPENDITURES_NEXT_PAGE_SUCCESS: 'GET_EXPENDITURES_NEXT_PAGE_SUCCESS',
  GET_EXPENDITURES_NEXT_PAGE_FAILURE: 'GET_EXPENDITURES_NEXT_PAGE_FAILURE',
  GET_EXPENDITURES_NEXT_PAGE_PROCESSING:
    'GET_EXPENDITURES_NEXT_PAGE_PROCESSING',
};

export const actions = {
  getExpenditures() {
    return { type: types.GET_EXPENDITURES };
  },

  save(payload, addNew, reportId) {
    return {
      type: types.SAVE_EXPENDITURE,
      data: { payload, addNew, reportId },
    };
  },

  saveFec(payload, addNew, reportId) {
    return {
      type: types.SAVE_FEC_EXPENDITURE,
      data: {
        payload,
        addNew,
        reportId,
      },
    };
  },

  recipientSave(payload) {
    return { type: types.SAVE_EXPENDITURE_END_RECIPIENT, data: { payload } };
  },

  update(payload, addNew, reportId, redirect, verifyReport = null) {
    return {
      type: types.UPDATE_EXPENDITURE,
      data: { payload, addNew, reportId, redirect, verifyReport },
    };
  },

  updateFec(payload, addNew, reportId, redirect, verifyReport = null) {
    return {
      type: types.UPDATE_FEC_EXPENDITURE,
      data: { payload, addNew, reportId, redirect, verifyReport },
    };
  },

  resetSave() {
    return { type: types.SAVE_EXPENDITURE_RESET };
  },

  delete(id, reportId, redirect) {
    return { type: types.DELETE_EXPENDITURE, data: { id, reportId, redirect } };
  },

  deleteFec(id, reportId, redirect) {
    return {
      type: types.DELETE_FEC_EXPENDITURE,
      data: { id, reportId, redirect },
    };
  },
};
