import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'office-ui-fabric-react/lib/Dropdown';
import { salutations } from '../../helpers/constants';
import { CaretDown } from '../icons';

export const SalutationPicker = ({
  onChange,
  selectedKey,
  required,
  label,
  errorMessage,
}) => (
  <Dropdown
    onRenderCaretDown={() => <CaretDown />}
    label={label}
    onChange={onChange}
    required={required}
    selectedKey={selectedKey}
    errorMessage={errorMessage}
    options={[
      { key: 0, text: 'Select' },
      ...salutations.map(s => ({
        key: s,
        text: s,
      })),
    ]}
  />
);

SalutationPicker.propTypes = {
  selectedKey: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  label: PropTypes.string,
  errorMessage: PropTypes.string,
};

SalutationPicker.defaultProps = {
  selectedKey: { key: 0 },
  required: false,
  label: 'Salutation',
  errorMessage: '',
};

export default SalutationPicker;
