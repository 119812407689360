import { types } from '../actions/importErrorsActions';
import { statuses } from '../helpers/constants';
import { createReducer } from './createReducer';

export const initialState = {
  errorsData: null,
  getImportErrorsStatus: statuses.NOT_STARTED,
  getImportErrorsError: null,
  saveImportCorrectionsStatus: statuses.NOT_STARTED,
  saveImportCorrectionsError: null,
};

const actionMap = {
  [types.GET_IMPORT_ERRORS_PROCESSING]: state => ({
    ...state,
    getImportErrorsStatus: statuses.PROCESSING,
    getImportErrorsError: null,
  }),

  [types.GET_IMPORT_ERRORS_SUCCESS]: (state, action) => ({
    ...state,
    getImportErrorsStatus: statuses.SUCCESS,
    getImportErrorsError: null,
    errorsData: action.data,
  }),

  [types.GET_IMPORT_ERRORS_FAILURE]: (state, action) => ({
    ...state,
    getImportErrorsStatus: statuses.ERROR,
    getImportErrorsError: action.error,
  }),

  [types.SAVE_IMPORT_CORRECTIONS_PROCESSING]: state => ({
    ...state,
    saveImportCorrectionsStatus: statuses.PROCESSING,
    saveImportCorrectionsError: null,
  }),

  [types.SAVE_IMPORT_CORRECTIONS_SUCCESS]: state => ({
    ...state,
    saveImportCorrectionsStatus: statuses.SUCCESS,
    saveImportCorrectionsError: null,
  }),

  [types.SAVE_IMPORT_CORRECTIONS_FAILURE]: (state, action) => ({
    ...state,
    saveImportCorrectionsStatus: statuses.ERROR,
    saveImportCorrectionsError: action.error,
  }),
};

export const importErrorsReducer = createReducer(initialState, actionMap);
