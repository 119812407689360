import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions as adminUserActions } from '../../actions/adminUserActions';
import { BackButton } from '../../components/common';
import { AddEditUserForm } from '../../components/ManageUsers';
import './AddUser.css';

class AddUser extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    adminUserActions: PropTypes.object.isRequired,
  };

  state = {
    user: {
      firstName: '',
      middleName: '',
      lastName: '',
      email: '',
      role: 0,
    },
    firstNameError: '',
    lastNameError: '',
    emailError: '',
    roleError: '',
    emailIsUnique: true,
    formValid: true,
  };

  handleChange = fieldName => (e, value) => {
    this.setState(state => ({
      user: {
        ...state.user,
        [fieldName]: value.key || value,
      },
    }));
  };

  validateForm = state => {
    state.formValid = state.emailIsUnique && true;
    if (state.user.firstName.length === 0) {
      state.formValid = false;
      state.firstNameError = 'First name is required';
    } else {
      state.firstNameError = '';
    }

    if (state.user.lastName.length === 0) {
      state.formValid = false;
      state.lastNameError = 'Last name is required';
    } else {
      state.lastNameError = '';
    }

    if (state.user.email.length === 0) {
      state.formValid = false;
      state.emailError = 'Email is required';
    } else {
      state.emailError = '';
    }

    if (state.user.role === 0) {
      state.formValid = false;
      state.roleError = 'User role is required';
    } else {
      state.roleError = '';
    }

    return state;
  };

  saveUser = () => {
    this.setState(
      state => ({
        state: this.validateForm(state),
      }),
      () => {
        if (this.state.formValid) {
          const {
            firstName,
            middleName = '',
            lastName,
            email: emailAddress,
            role,
          } = this.state.user;
          const user = { firstName, middleName, lastName, emailAddress, role };
          this.props.adminUserActions.addUser(user);
        }
      },
    );
  };

  cancel = () => {
    this.props.history.push('/filer/manageUsers');
  };

  render() {
    const {
      user,
      firstNameError,
      lastNameError,
      emailError,
      formValid,
      roleError,
    } = this.state;

    const actions = {
      handleChange: this.handleChange,
      saveUser: this.saveUser,
      cancel: this.cancel,
    };

    return (
      <Fragment>
        <BackButton history={this.props.history} />
        <div className="AddEditUser depth-1">
          <h3>Add New User</h3>
          <AddEditUserForm
            user={user}
            formValid={formValid}
            firstNameError={firstNameError}
            lastNameError={lastNameError}
            emailError={emailError}
            roleError={roleError}
            actions={actions}
          />
        </div>
      </Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    adminUserActions: bindActionCreators(adminUserActions, dispatch),
  };
}

export default connect(
  null,
  mapDispatchToProps,
)(AddUser);
