import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import * as R from 'ramda';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  DefaultButton,
  PrimaryButton,
} from 'office-ui-fabric-react/lib/Button';
import {
  formatDateOnly,
  formatUtcDate,
  scrollToTop,
  scrollToComponent,
  deserializeDate,
  itemDateBeforeLastFiling,
} from '../../helpers/util';
import {
  isBetaUser,
} from '../../helpers/sessionHelper';
import {
  convertItemUpdateErrorsToMessageList,
  itemUpdateWarning,
} from '../../helpers/labelHelper';
import { actions as reportActions } from '../../actions/reportActions';
import { actions as currentReportActions } from '../../actions/currentReportActions';
import {
  CoverPage,
  ContributionsSection,
  LoansSection,
  ExpendituresSection,
  InvestmentsSection,
  SummarySection,
  IndebtednessSection,
  AddendumSection,
} from '../../components/ReportSections';
import { getQueryParams } from '../../helpers/urlHelper';
import { setSourceAndPrevReportIdFromPrevReportId } from '../../helpers/reportHelper';
import Loading from '../../components/Loading';
import { GenericMessageDialog, SubmitReportDialog, ConfirmContinueUpdateDialog } from '../../components/Dialogs';
import BeginningBalanceSourceDialog from '../../components/Dialogs/BeginningBalanceSourceDialog';

import './Edit.css';

let reportPlaceholder = {};

export class EditReport extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    campaign: PropTypes.object.isRequired,
    reportingPeriods: PropTypes.array.isRequired,
    reportActions: PropTypes.object.isRequired,
    currentReport: PropTypes.object.isRequired,
    currentReportActions: PropTypes.object.isRequired,
    session: PropTypes.object.isRequired,
  };

  state = {
    q2: { inKind: 0, amount: 0 },
    q3: { inKind: 0, amount: 0 },
    q3a: { amount: 0 },
    q3b: { amount: 0 },
    q3c: { amount: 0 },
    q3d: { amount: 0 },
    q4: { inKind: 0, amount: 0 },
    q5: { inKind: 0, amount: 0 },
    q6: { inKind: 0, amount: 0 },
    q8: { inKind: 0, amount: 0 },
    q9: { inKind: 0, amount: 0 },
    q10: { inKind: 0, amount: 0 },
    q11: { inKind: 0, amount: 0 },
    q12: { inKind: 0, amount: 0 },
    q13: { amount: 0 },
    q14: { amount: 0 },
    q15: { inKind: 0, amount: 0 },
    reportId: '',
    reportType: '',
    reportDate: '',
    electionYear: '',
    electionDate: { key: 0 },
    electionDateError: '',
    reportingName: '',
    reportingPeriod: { key: -1 },
    reportingPeriodError: '',
    reportYear: { key: 0 },
    reportYearError: '',
    startDate: null,
    startDateError: '',
    endDate: null,
    endDateError: null,
    sectionErrors: [],
    coverpageExpanded: true,
    contributionsExpanded: false,
    loansExpanded: false,
    expendituresExpanded: false,
    investmentsExpanded: false,
    summaryExpanded: false,
    indebtednessExpanded: false,
    addendumExpanded: false,
    formValid: true,
    hasContributionsToReport: { key: 'no' },
    addendum: '',
    addendumComplete: false,
    contributionsComplete: false,
    expendituresComplete: false,
    indebtednessComplete: false,
    investmentsComplete: false,
    loansComplete: false,
    summaryComplete: false,
    isTBDReport: false,
    submitReportDialogHidden: true,
    markAsFiledDialogHidden: true,
    gaMessageHidden: true,
    previousBalanceSourceDialogHidden: true,
    previousReportId: '0',
    previousBalanceSource: '0',
    previousReportIdError: '',
    continueEditMessageList: [],
    confirmUpdateCallback: () => {},
    confirmContinueUpdateHidden: true,
  };

  componentDidMount() {
    if (this.props.session && this.props.session.isFederal()) {
      this.props.history.push(
        `/filer/editFECReport/${this.props.match.params.id}`,
      );
    }
    scrollToTop();
    this.getReport();
  }

  componentWillUnmount() {
    this.props.currentReportActions.resetReport();
  }

  openSubmitReportDialog = () => {
    this.setState({
      submitReportDialogHidden: false,
    });
  };

  closeSubmitReportDialog = () => {
    this.setState({
      // Randy Put this back to remove message dialog
      submitReportDialogHidden: true,
      gaMessageHidden: true,
    });
  };

  populateDisclosureReport = report => {
    reportPlaceholder = { ...report };
    this.setState(
      state => {
        state.reportId = report._id;
        state.electionDate = { key: formatUtcDate(report.electionDate, 'YYYY-MM-DD') };
        state.reportingPeriod = { key: report.reportingPeriod };
        state.reportingName = report.reportingName;
        state.reportYear = { key: +report.reportingPeriodYear };
        state.reportType = report.reportType;
        state.reportDate = formatUtcDate(report.reportDate, 'MMMM D, YYYY');
        state.electionYear = report.electionYear;
        state.startDate = deserializeDate(report.startDate);
        state.endDate = deserializeDate(report.endDate);
        state.previousBalanceSource = report.isFirstFilingForCurrentOffice ? null : report.previousBalanceSource;
        state.isFirstFilingForCurrentOffice = report.isFirstFilingForCurrentOffice;
        state.previousReportId = report.previousReportId;
        state.q1 = { key: report.summary.contributions.q1 ? 'yes' : 'no' };
        state.q2 = {
          inKind: report.summary.contributions.q2.inKind.toFixed(2),
          amount: report.summary.contributions.q2.amount.toFixed(2),
        };
        state.q3 = {
          inKind: report.summary.contributions.q3.inKind.toFixed(2),
          amount: report.summary.contributions.q3.amount.toFixed(2),
        };
        state.q3a = {
          amount: report.summary.contributions.q3a.amount.toFixed(2),
        };
        state.q3b = {
          amount: report.summary.contributions.q3b.amount.toFixed(2),
        };
        state.q3c = {
          amount: report.summary.contributions.q3c.amount.toFixed(2),
        };
        state.q3d = {
          amount: report.summary.contributions.q3d.amount.toFixed(2),
        };
        state.q4 = {
          inKind: report.summary.contributions.q4.inKind.toFixed(2),
          amount: report.summary.contributions.q4.amount.toFixed(2),
        };
        state.q5 = {
          inKind: report.summary.contributions.q5.inKind.toFixed(2),
          amount: report.summary.contributions.q5.amount.toFixed(2),
        };
        state.q6 = {
          inKind: report.summary.contributions.q6.inKind.toFixed(2),
          amount: report.summary.contributions.q6.amount.toFixed(2),
        };
        state.q7 = { key: report.summary.expenditures.q7 ? 'yes' : 'no' };
        state.q8 = {
          inKind: report.summary.expenditures.q8.inKind.toFixed(2),
          amount: report.summary.expenditures.q8.amount.toFixed(2),
        };
        state.q9 = {
          inKind: report.summary.expenditures.q9.inKind.toFixed(2),
          amount: report.summary.expenditures.q9.amount.toFixed(2),
        };
        state.q10 = {
          inKind: report.summary.expenditures.q10.inKind.toFixed(2),
          amount: report.summary.expenditures.q10.amount.toFixed(2),
        };
        state.q11 = {
          inKind: report.summary.expenditures.q11.inKind.toFixed(2),
          amount: report.summary.expenditures.q11.amount.toFixed(2),
        };
        state.q12 = {
          inKind: report.summary.expenditures.q12.inKind.toFixed(2),
          amount: report.summary.expenditures.q12.amount.toFixed(2),
        };
        state.q13 = {
          amount: report.summary.investments.q13.amount.toFixed(2),
        };
        state.q14 = {
          amount: report.summary.investments.q14.amount.toFixed(2),
        };
        state.q15 = {
          inKind: report.summary.total.q15.inKind.toFixed(2),
          amount: report.summary.total.q15.amount.toFixed(2),
        };
        state.addendum = report.addendum;
        return state;
      },
      () => {
        if (!report.previousBalanceSource && !report.isFirstFilingForCurrentOffice) {
          return this.setState({
            previousBalanceSourceDialogHidden: false,
          });
        }
        const params = getQueryParams(this.props.location.search);
        if (Object.keys(params).length) {
          if (params.section) {
            this.setState(
              {
                [`${params.section}Expanded`]: true,
                coverpageExpanded: false,
              },
              () => {
                scrollToComponent(`[name="${params.section}"]`);
              },
            );
          }
        }
      },
    );
  };

  populateTBDReport(report) {
    reportPlaceholder = { ...report };
    this.setState(
      state => {
        state.reportId = report._id;
        state.electionDate = {
          key: moment(report.electionDate)
            .add(1, 'd')
            .format('YYYY-MM-DD'),
        };
        state.reportingPeriod = { key: report.reportingPeriod };
        state.reportYear = { key: +report.reportingPeriodYear };
        state.reportType = report.reportType;
        state.reportDate = formatUtcDate(report.reportDate, 'MMMM D, YYYY');
        state.electionYear = report.electionYear;
        state.startDate = deserializeDate(report.startDate);
        state.endDate = deserializeDate(report.endDate);
        state.isTBDReport = true;
        return state;
      },
      () => {
        const params = getQueryParams(this.props.location.search);
        if (Object.keys(params).length) {
          if (params.section) {
            this.setState(
              {
                [`${params.section}Expanded`]: true,
                coverpageExpanded: false,
              },
              () => {
                scrollToComponent(`[name="${params.section}"]`);
              },
            );
          }
        }
      },
    );
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.currentReport.report.summary &&
      !R.equals(
        prevProps.currentReport.report.summary,
        this.props.currentReport.report.summary,
      )
    ) {
      const report = { ...this.props.currentReport.report };
      if (report.reportType !== 'TBD Contribution Report') {
        this.populateDisclosureReport(report);
      } else if (report.reportType === 'TBD Contribution Report') {
        this.populateTBDReport(report);
      }
    }
  }

  getReport = () => {
    this.props.currentReportActions.getReport(this.props.match.params.id);
  };

  handleChange = fieldName => (e, value) => {
    const val = value.key !== undefined ? value.key : value;
    this.setState({
      [fieldName]: val,
    });
  };

  handleChangeElectionDate = fieldName => (e, value) => {
    this.setState({
      [fieldName]: value,
    });
  };

  handleChangeDate = fieldName => date => {
    this.setState({
      [fieldName]: date,
    });
  };

  handleChangeAmounts = question => type => (e, value) => {
    const [whole, decimal = null] = value.toString().split('.');
    this.setState(state => ({
      [question]: {
        ...state[question],
        [type]: decimal !== null
          ? decimal.length > 2
            ? `${whole}.${decimal.substr(0, 2)}`
            : `${whole}.${decimal}`
          : `${whole}`,
      },
    }));
  };

  toggleExpandSection = sectionName => () => {
    this.setState(state => ({
      [`${sectionName}Expanded`]: !state[`${sectionName}Expanded`],
    }));
  };

  previousSection = (currentSection, prevSection) => () => {
    this.setState(
      () => ({
        [`${currentSection}Expanded`]: false,
        [`${prevSection}Expanded`]: true,
      }),
      () => {
        scrollToComponent(`[name="${prevSection}"]`);
      },
    );
  };

  nextSection = (currentSection, nextSection) => () => {
    this.setState(
      () => ({
        [`${currentSection}Expanded`]: false,
        [`${nextSection}Expanded`]: true,
        [`${currentSection}Complete`]: true,
      }),
      () => {
        this.updateReport();
        scrollToComponent(`[name="${nextSection}"]`);
      },
    );
  };

  generatePDF = () => {
    window.open(
      `/api/filer/reports/${this.props.match.params.id}/${
        this.state.isTBDReport ? 'tbd' : 'pdf'
      }`,
    );
  };

  updateReport = () => {
    if (!this.state.isTBDReport) {
      this.updateDisclosureReport();
    }
  };

  validate = state => {
    state.formValid = true;
    if (state.startDate && state.endDate) {
      const { startDate, endDate } = this.state;
      if (startDate.getTime() > endDate.getTime()) {
        state.formValid = false;
        state.startDateError =
          'Report Start Date cannot be after Report End Date';
        state.endDateError =
          'Report End Date cannot be before Report Start Date';
      } else {
        state.startDateError = '';
        state.endDateError = '';
      }
    }
    if (!state.isFirstFilingForCurrentOffice && state.previousReportId === '0') {
      state.formValid = false;
      state.previousReportIdError = 'Previous report source is required';
    } else {
      state.previousReportIdError = '';
    }
    state.previousBalanceSourceDialogHidden = true;
    return state;
  };

  updateDisclosureReport = () => {
    this.setState(this.validate, () => {
      if (this.state.formValid) {
        const { adjustedPreviousReportId, previousBalanceSource } = setSourceAndPrevReportIdFromPrevReportId(this.state.previousReportId || this.state.previousBalanceSource);
        const payload = {
          _id: this.state.reportId,
          reportType: reportPlaceholder.reportType,
          reportingPeriodYear: this.state.reportYear.key || this.state.reportYear,
          reportDate: reportPlaceholder.reportDate,
          electionYear: reportPlaceholder.electionYear,
          startDate: formatDateOnly(this.state.startDate),
          endDate: formatDateOnly(this.state.endDate),
          addendum: this.state.addendum,
          campaignId: reportPlaceholder.campaignId,
          electionDate: this.state.electionDate.key,
          reportingPeriod: this.state.reportingPeriod?.key || this.state.reportingPeriod,
          previousBalanceSource: this.state.isFirstFilingForCurrentOffice ? '' : previousBalanceSource,
          previousReportId: adjustedPreviousReportId,
          sectionsCompleted: {
            addendum: this.state.addendumComplete,
            contributions: this.state.contributionsComplete,
            expenditures: this.state.expendituresComplete,
            indebtedness: this.state.indebtednessComplete,
            investments: this.state.investmentsComplete,
            loans: this.state.loansComplete,
            summary: this.state.summaryComplete,
          },
          summary: {
            contributions: {
              q1: this.state.q1.key === 'yes',
              q2: this.state.q2,
              q3: this.state.q3,
              q3a: this.state.q3a,
              q3b: this.state.q3b,
              q3c: this.state.q3c,
              q3d: this.state.q3d,
              q4: this.state.q4,
              q5: this.state.q5,
              q6: this.state.q6,
            },
            expenditures: {
              q7: this.state.q7.key === 'yes',
              q8: this.state.q8,
              q9: this.state.q9,
              q10: this.state.q10,
              q11: this.state.q11,
              q12: this.state.q12,
            },
            investments: {
              q13: this.state.q13,
              q14: this.state.q14,
            },
            total: {
              q15: this.state.q15,
            },
          },
        };
        this.props.currentReportActions.updateReport(payload);
      }
    });
  };

  verifyReport = () => {
    this.props.history.push(
      `/filer/reports/verify/${this.props.match.params.id}`,
    );
  };

  downloadReportXML = async () => {
    const reportDownloadPath = `/api/filer/reports/${this.state.reportId}/gaFilingXml`;
    window.open(reportDownloadPath, '_blank');
  };

  downloadLocalReportData = async () => {
    const reportDownloadPath = `/api/filer/reports/${this.state.reportId}/local`;
    window.open(reportDownloadPath, '_blank');
  };

  openMarkAsFiledDialog = () => {
    this.setState({
      markAsFiledDialogHidden: false,
    });
  };

  closeMarkAsFiledDialog = () => {
    return this.setState({
      markAsFiledDialogHidden: true,
    });
  };

  markAsFiledCallback = () => {
    const { id: reportId } = this.props.match.params;
    const report = { ...this.props.currentReport.report };
    if (report.reportType !== 'TBD Contribution Report') {
      this.props.currentReportActions.markAsFiled(reportId);
    } else {
      // Different call for redirect after call to controller
      this.props.currentReportActions.markTBDAsFiled(reportId);
    }

    this.closeMarkAsFiledDialog();
  };

  fileReport = () => {
    this.setState(
      {
        submitReportDialogHidden: true,
      },
      () => {
        const { id: reportId } = this.props.match.params;
        if (this.props.campaign.officeType === 'Local') {
          this.props.currentReportActions.submitLocalReport(reportId);
        }
      },
    );
  };

  deleteReport = () => {
    this.props.reportActions.deleteDisclosureReport(
      this.props.match.params.id,
      this.state.isTBDReport,
    );
  };

  addNewContribution = () => {
    const tbdAddon = this.state.isTBDReport ? '&tbd=true' : '';
    this.props.history.push(
      `/filer/addContribution?reportId=${this.state.reportId}${tbdAddon}`,
    );
  };

  editContribution = id => {
    const tbdAddon = this.state.isTBDReport ? '&tbd=true' : '';
    this.props.history.push(
      `/filer/editContribution/${id}?reportId=${this.state.reportId}${tbdAddon}`,
    );
  };

  onEditContributionClick = id => {
    const { currentReport, campaign } = this.props;
    const item = currentReport.contributions.find(c => c._id === id);
    const { isItemFiled, reconciliationId, depositId, receivedDate } = (item || {});
    const isBadItemDate = itemDateBeforeLastFiling(receivedDate, campaign.lastFiledReportEndDate);
    const continueEditMessageList = convertItemUpdateErrorsToMessageList({
      itemName: 'receipt',
      isItemFiled,
      reconciliationId,
      depositId,
      isBadItemDate,
    });

    if (continueEditMessageList.length > 0) {
      return this.setState({
        confirmUpdateCallback: () => this.editContribution(id),
        continueEditMessageList,
        confirmContinueUpdateHidden: false,
      });
    }

    this.editContribution(id);
  };

  addNewLoan = () => {
    const tbdAddon = this.state.isTBDReport ? '&tbd=true' : '';
    this.props.history.push(
      `/filer/addLoan?reportId=${this.state.reportId}${tbdAddon}`,
    );
  };

  editLoan = id => {
    const tbdAddon = this.state.isTBDReport ? '&tbd=true' : '';
    this.props.history.push(
      `/filer/editLoan/${id}?reportId=${this.state.reportId}${tbdAddon}`,
    );
  };

  addNewExpenditure = () => {
    this.props.history.push(
      `/filer/addExpenditure?reportId=${this.state.reportId}`,
    );
  };

  editExpenditure = id => {
    this.props.history.push(
      `/filer/editExpenditure/${id}?reportId=${this.state.reportId}`,
    );
  };

  onEditExpenditureClick = id => {
    const { currentReport, campaign } = this.props;
    const item = currentReport.expenditures.find(c => c._id === id);
    const { isItemFiled, reconciliationId, depositId, expenditureDate } = (item || {});
    const isBadItemDate = itemDateBeforeLastFiling(expenditureDate, campaign.lastFiledReportEndDate);
    const continueEditMessageList = convertItemUpdateErrorsToMessageList({
      itemName: 'expenditure',
      isItemFiled,
      reconciliationId,
      depositId,
      isBadItemDate,
    });

    if (continueEditMessageList.length > 0) {
      return this.setState({
        confirmUpdateCallback: () => this.editExpenditure(id),
        continueEditMessageList,
        confirmContinueUpdateHidden: false,
      });
    }

    this.editExpenditure(id);
  };

  cancelContinueUpdate = () => {
    return this.setState({
      confirmUpdateCallback: null,
      confirmContinueUpdateHidden: true,
    });
  };

  render() {
    const {
      state: {
        reportType,
        reportDate,
        electionYear,
        electionDate,
        electionDateError,
        reportingName,
        reportingPeriod,
        reportingPeriodError,
        reportYear,
        reportYearError,
        startDate,
        startDateError,
        endDate,
        endDateError,
        coverpageExpanded,
        contributionsExpanded,
        loansExpanded,
        expendituresExpanded,
        investmentsExpanded,
        summaryExpanded,
        indebtednessExpanded,
        addendumExpanded,
        q1,
        q2,
        q3,
        q3a,
        q3b,
        q3c,
        q3d,
        q4,
        q5,
        q6,
        q7,
        q8,
        q9,
        q10,
        q11,
        q12,
        q13,
        q14,
        q15,
        addendum,
        isTBDReport,
        submitReportDialogHidden,
        markAsFiledDialogHidden,
        previousBalanceSourceDialogHidden,
        previousReportId,
        previousBalanceSource,
        previousReportIdError,
        isFirstFilingForCurrentOffice,
        reportId,
      },
      props: { campaign, reportingPeriods, currentReport, session },
    } = this;

    const {
      contrTotalAmount,
      contrTotalInKind,
    } = currentReport.contributions.reduce(
      (sum, contr) => {
        if (contr.contributionType === 'In-Kind') {
          sum.contrTotalInKind += contr.amount;
        } else {
          sum.contrTotalAmount += contr.amount;
        }
        return sum;
      },
      { contrTotalAmount: 0, contrTotalInKind: 0 },
    );

    const {
      expTotalAmount,
      expTotalInKind,
    } = currentReport.expenditures.reduce(
      (sum, exp) => {
        if (exp.expenditureType === 'In-Kind') {
          sum.expTotalInKind += exp.amount;
        } else {
          sum.expTotalAmount += exp.amount;
        }
        return sum;
      },
      { expTotalAmount: 0, expTotalInKind: 0 },
    );

    const totalLoanAmount = currentReport.loans.reduce((sum, loan) => {
      sum += loan.amount;
      return sum;
    }, 0);

    if (Object.values(currentReport.report).length === 0) {
      return <Loading />;
    }

    const gaReportMessage = (
      <div>
        Georgia is reporting to a new system beginning with the 4/30/22 report. This functionality will be enabled in FrontRunner once the state of Georgia has enabled new report creation with their new system for the the 4/30/22 report.
      </div>
    );

    const markAsFiledMessage = 'This allows a report submitted directly to GA (not through FrontRunner) to be marked as filed in FrontRunner.';
    const isBetaUserEnabled = isBetaUser(this.props.session);

    return (
      <Fragment>
        <div className="EditReport">
          <CoverPage
            actions={{
              toggleExpand: this.toggleExpandSection('coverpage'),
              handleChange: this.handleChange,
              handleChangeElectionDate: this.handleChangeElectionDate,
              updateReport: this.updateReport,
              nextSection: this.nextSection('coverpage', 'contributions'),
              handleChangeDate: this.handleChangeDate,
            }}
            isTBDReport={isTBDReport}
            isExpanded={coverpageExpanded}
            reportType={reportType}
            reportDate={reportDate}
            electionYear={electionYear}
            electionDate={electionDate}
            electionDateError={electionDateError}
            reportingName={reportingName}
            reportingPeriod={reportingPeriod}
            reportingPeriodError={reportingPeriodError}
            reportingPeriods={reportingPeriods}
            reportYear={reportYear}
            reportYearError={reportYearError}
            campaign={campaign}
            startDate={startDate}
            startDateError={startDateError}
            endDate={endDate}
            endDateError={endDateError}
            previousBalanceSource={previousBalanceSource}
            previousReportId={previousReportId}
            previousReportIdError={previousReportIdError}
            isFirstFilingForCurrentOffice={isFirstFilingForCurrentOffice}
            reportId={reportId}
            session={session}
          />
          <ContributionsSection
            items={currentReport.contributions}
            totalInKind={contrTotalInKind}
            totalAmount={contrTotalAmount}
            actions={{
              toggleExpand: this.toggleExpandSection('contributions'),
              addNew: this.addNewContribution,
              editItem: this.onEditContributionClick,
              previousSection: this.previousSection(
                'contributions',
                'coverpage',
              ),
              nextSection: this.nextSection('contributions', 'loans'),
            }}
            isExpanded={contributionsExpanded}
            session={session}
          />
          <LoansSection
            items={currentReport.loans}
            totalAmount={totalLoanAmount}
            isTBDReport={isTBDReport}
            actions={{
              toggleExpand: this.toggleExpandSection('loans'),
              addNew: this.addNewLoan,
              editItem: this.editLoan,
              previousSection: this.previousSection('loans', 'contributions'),
              nextSection: isTBDReport
                ? this.updateReport
                : this.nextSection('loans', 'expenditures'),
            }}
            isExpanded={loansExpanded}
            session={session}
          />
          {!isTBDReport && (
            <Fragment>
              <ExpendituresSection
                items={currentReport.expenditures}
                totalInKind={expTotalInKind}
                totalAmount={expTotalAmount}
                actions={{
                  toggleExpand: this.toggleExpandSection('expenditures'),
                  addNew: this.addNewExpenditure,
                  editItem: this.onEditExpenditureClick,
                  previousSection: this.previousSection(
                    'expenditures',
                    'loans',
                  ),
                  nextSection: this.nextSection('expenditures', 'investments'),
                }}
                isExpanded={expendituresExpanded}
                session={session}
              />
              <InvestmentsSection
                actions={{
                  toggleExpand: this.toggleExpandSection('investments'),
                  previousSection: this.previousSection(
                    'investments',
                    'expenditures',
                  ),
                  nextSection: this.nextSection('investments', 'summary'),
                }}
                isExpanded={investmentsExpanded}
              />
              <SummarySection
                actions={{
                  handleChange: this.handleChangeAmounts,
                  toggleExpand: this.toggleExpandSection('summary'),
                  previousSection: this.previousSection(
                    'summary',
                    'investments',
                  ),
                  nextSection: this.nextSection('summary', 'indebtedness'),
                  changeContributionsToReport: this.handleChange('q1'),
                  changeExpendituresToReport: this.handleChange('q7'),
                }}
                q1={q1}
                q2={q2}
                q3={q3}
                q3a={q3a}
                q3b={q3b}
                q3c={q3c}
                q3d={q3d}
                q4={q4}
                q5={q5}
                q6={q6}
                q7={q7}
                q8={q8}
                q9={q9}
                q10={q10}
                q11={q11}
                q12={q12}
                q13={q13}
                q14={q14}
                q15={q15}
                isExpanded={summaryExpanded}
              />
              <IndebtednessSection
                items={currentReport.indebtedness}
                actions={{
                  toggleExpand: this.toggleExpandSection('indebtedness'),
                  previousSection: this.previousSection(
                    'indebtedness',
                    'summary',
                  ),
                  nextSection: this.nextSection('indebtedness', 'addendum'),
                }}
                isExpanded={indebtednessExpanded}
              />
              <AddendumSection
                actions={{
                  updateReport: this.updateReport,
                  handleChange: this.handleChange,
                  toggleExpand: this.toggleExpandSection('addendum'),
                  previousSection: this.previousSection(
                    'addendum',
                    'indebtedness',
                  ),
                }}
                addendum={addendum}
                isExpanded={addendumExpanded}
              />
            </Fragment>
          )}
        </div>
        <div className="edit-report-actions">
          <div className="actions">
            <DefaultButton
              className="action-btn"
              text="Back to Reports"
              iconProps={{ iconName: 'ArrowAltCircleLeft' }}
              onClick={() =>
                this.props.history.push(
                  `/filer/${isTBDReport ? 'tbdReports' : 'reports'}`,
                )
              }
            />
            <DefaultButton
              className="action-btn"
              text="Generate Working PDF"
              iconProps={{ iconName: 'FilePdf' }}
              onClick={this.generatePDF}
            />
            {campaign.officeType === 'Local' && (
              <PrimaryButton
                className="action-btn"
                text="Download EasyFile Report Data"
                iconProps={{ iconName: 'CheckDouble' }}
                onClick={this.downloadLocalReportData}
              />
            )}
            {campaign.officeType !== 'Local' && (
              <PrimaryButton
                className="action-btn"
                text="Download Report XML"
                iconProps={{ iconName: 'CheckDouble' }}
                onClick={this.downloadReportXML}
              />
            )}
            {campaign.officeType !== 'Local' && (
              <PrimaryButton
                className="action-btn"
                text="Verify and File"
                iconProps={{ iconName: 'CheckDouble' }}
                onClick={this.verifyReport}
                disabled={!isBetaUserEnabled}
              />
            )}
            {campaign.officeType !== 'Local' && (
              <PrimaryButton
                className="action-btn"
                text="Mark as Filed"
                iconProps={{ iconName: 'Upload' }}
                onClick={this.openMarkAsFiledDialog}
                disabled={!isBetaUserEnabled}
              />
            )}
            <PrimaryButton
              className="action-btn"
              text={campaign.officeType !== 'Local' ? 'Submit Report to State' : 'Mark as Filed'}
              iconProps={{ iconName: 'Upload' }}
              onClick={this.openSubmitReportDialog}
              disabled={campaign.officeType !== 'Local'}
            />
            <PrimaryButton
              className="action-btn delete-btn"
              text="Delete Report"
              iconProps={{ iconName: 'TrashAlt' }}
              onClick={this.deleteReport}
            />
          </div>
        </div>
        <SubmitReportDialog
          dialogHidden={submitReportDialogHidden}
          onCancel={this.closeSubmitReportDialog}
          onConfirm={this.fileReport}
          session={session}
        />
        <BeginningBalanceSourceDialog
          dialogHidden={previousBalanceSourceDialogHidden}
          onConfirm={this.updateDisclosureReport}
          reportId={this.props.match.params.id}
          handleChange={this.handleChange}
          selectedKey={previousReportId}
        />
        <ConfirmContinueUpdateDialog
          dialogHidden={this.state.confirmContinueUpdateHidden}
          cancel={this.cancelContinueUpdate}
          confirm={this.state.confirmUpdateCallback}
          messageList={this.state.continueEditMessageList}
          instruction={itemUpdateWarning}
        />
        <ConfirmContinueUpdateDialog
          dialogHidden={markAsFiledDialogHidden}
          cancel={this.closeMarkAsFiledDialog}
          confirm={this.markAsFiledCallback}
          messageList={[]}
          instruction={markAsFiledMessage}
        />
        <GenericMessageDialog
          onCancel={this.closeSubmitReportDialog}
          titleText="Report Verification and Submission"
          message={gaReportMessage}
          dialogHidden={this.state.gaMessageHidden}
        />
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    campaign: state.currentCampaign.campaign,
    reportingPeriods: state.reportingPeriods.periods,
    currentReport: state.currentReport,
    session: state.user.session,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    reportActions: bindActionCreators(reportActions, dispatch),
    currentReportActions: bindActionCreators(currentReportActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditReport),
);
