import { createReducer } from '../../reducers/createReducer';
import { statuses } from '../../helpers/constants';

export const initialState = {
  electionYear: 0,
  status: statuses.PROCESSING,
  tbd: false,
  fec: false,
  reportIdRedirect: null,
  redirect: null,
  verifyReport: null,
  loan: null,
  confirmDeleteHidden: true,
};

export const actions = {
  GET_LOAN_FOR_EDIT_SUCCESS: 'GET_LOAN_FOR_EDIT_SUCCESS',
  GET_LOAN_FOR_EDIT_FAILURE: 'GET_LOAN_FOR_EDIT_FAILURE',
  SHOW_CONFIRM_DELETE_MODAL: 'SHOW_CONFIRM_DELETE_MODAL',
  HIDE_CONFIRM_DELETE_MODAL: 'HIDE_CONFIRM_DELETE_MODAL',
};

export const actionMap = {
  [actions.GET_LOAN_FOR_EDIT_SUCCESS]: (state, action) => {
    const { loan, params } = action.data;
    const {
      reportId = null,
      redirect = null,
      verifyReport = null,
      tbd = false,
      fec = false,
    } = params;

    const { contactId: selectedContact } = loan;

    const {
      _id: contactId,
      salutation,
      businessType,
      address,
      contactAddress,
      ...rest
    } = selectedContact;

    return {
      ...state,
      loan: {
        ...loan,
        selectedContact: {
          _id: contactId,
          ...rest,
          ...contactAddress,
        },
        contactId,
        ...contactAddress,
        salutation: salutation || 'none',
        businessType: businessType || 0,
        electionYear: parseInt(loan.electionYear, 10),
        responsibleParties: loan.responsibleParties.map(rp => {
          return {
            ...rp,
            addressLine1: rp.address?.addressLine1 || '',
            addressLine2: rp.address?.addressLine2 || '',
            city: rp.address?.city || '',
            state: rp.address?.state || 0,
            zipCode: rp.address?.zipCode || '',
            county: rp.address?.county || '',
            email: rp.email || '',
            phone1: rp.phone1 || '',
            phone2: rp.phone2 || '',
            salutation: rp.salutation || 'none',
          };
        }),
      },
      reportIdRedirect: reportId,
      fec,
      tbd,
      verifyReport,
      redirect,
      status: statuses.SUCCESS,
    };
  },
  [actions.GET_LOAN_FOR_EDIT_FAILURE]: state => ({
    ...state,
    status: statuses.ERROR,
  }),
  [actions.SHOW_CONFIRM_DELETE_MODAL]: state => ({
    ...state,
    confirmDeleteHidden: false,
  }),
  [actions.HIDE_CONFIRM_DELETE_MODAL]: state => ({
    ...state,
    confirmDeleteHidden: true,
  }),
  [actions.SHOW_CONFIRM_DELETE_MODAL]: state => ({
    ...state,
    confirmDeleteHidden: false,
  }),
};

export const editLoanReducer = createReducer(initialState, actionMap);
