import axios from 'axios';
import { call, delay, put, race, take } from 'redux-saga/effects';
import { types } from '../actions/importPollingActions';
import { types as messagingTypes } from '../actions/messagingActions';
import { ImportStatus, toastTypes } from '../helpers/constants';
import { errorToast, getServerSideErrorMessage } from '../helpers/util';

const POLLING_DELAY = 2500;
const url = '/api/filer/contributions/import';

const statusToastData = {
  [ImportStatus.Processed]: {
    message: 'File processed successfully',
    toastType: toastTypes.SUCCESS,
  },
  [ImportStatus.Error]: {
    message: 'File processing failed. Please review and fix errors',
    toastType: toastTypes.ERROR,
  },
  [ImportStatus.Completed]: {
    message: 'File import finalized successfully',
    toastType: toastTypes.SUCCESS,
  },
  [ImportStatus.Failed]: {
    message: 'File import failed to finalize',
    toastType: toastTypes.ERROR,
  },
};

export function* importStatusPollingWorker(fileId) {
  yield put({ type: types.START_IMPORT_STATUS_POLLING });

  while (true) {
    try {
      yield put({ type: types.GET_IMPORT_STATUS_PROCESSING });

      const {
        data: { status },
      } = yield call(axios.get, `${url}/${fileId}/status`, {
        withCredentials: true,
      });

      yield put({
        type: types.GET_IMPORT_STATUS_SUCCESS,
        data: { status, fileId },
      });

      if (status !== ImportStatus.Pending) {
        yield put({
          type: messagingTypes.SET_TOAST,
          data: statusToastData[status],
        });
        yield put({ type: types.CANCEL_IMPORT_STATUS_POLLING });
      } else {
        yield delay(POLLING_DELAY);
      }
    } catch (e) {
      const error = getServerSideErrorMessage(e);
      yield put(errorToast(error));
      yield put({ type: types.GET_IMPORT_STATUS_FAILURE, error });
      yield put({ type: types.CANCEL_IMPORT_STATUS_POLLING });
    }
  }
}

export function* importStatusWatchWorker(fileId) {
  yield race({
    task: call(importStatusPollingWorker, fileId),
    cancel: take(types.CANCEL_IMPORT_STATUS_POLLING),
  });
}
