import { ContributionFileFormat, ImportDataType } from '../../../../helpers/constants';

export const contributionFileFormatOptions = Object.entries(
  ContributionFileFormat,
).map(([, text]) => ({
  key: text,
  text,
  disabled:
    text !== ContributionFileFormat.Anedot &&
    text !== ContributionFileFormat.DemocracyEngine &&
    text !== ContributionFileFormat.HSPMail &&
    text !== ContributionFileFormat.MailingAndCaging,
}));

export const fileFormatOptionsByDataType = {
  [ImportDataType.Contribution]: contributionFileFormatOptions,
};
