import { all, takeEvery, takeLatest, put, call } from 'redux-saga/effects';
import axios from 'axios';
import { types as contactMergeTypes } from '../actions/contactMergeActions';
import { getServerSideErrorMessage, errorToast } from '../helpers/util';

const url = '/api/filer/duplicateContacts';

export function* handleGetContactToMerge() {
  try {
    yield put({ type: contactMergeTypes.GET_CONTACT_TO_MERGE_PROCESSING });
    const { data: contact } = yield call(axios.get, url, {
      withCredentials: true,
    });
    yield put({ type: contactMergeTypes.GET_CONTACT_TO_MERGE_SUCCESS, contact });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put(errorToast(error));
  }
}

export function* getContactToMerge() {
  yield takeLatest(contactMergeTypes.GET_CONTACT_TO_MERGE, handleGetContactToMerge);
}

const getContacts = (contacts) => {
  return contacts.reduce((a, c) => {
    const { data: contactList } = c;
    if (contactList.length) {
      a.push(contactList[0]);
    }
    return a;
  }, []);
};

export function* handleGetPossibleContactsForMerge(action) {
  const { contactIds = [] } = action.data;
  try {
    yield put({
      type: contactMergeTypes.GET_POSSIBLE_CONTACTS_FOR_MERGE_PROCESSING,
    });
    const allContacts = yield all(contactIds.map(contactId => call(axios.get,
      `/api/filer/contacts/${contactId}`,
      {
        withCredentials: true,
      })));
    yield put({
      type: contactMergeTypes.GET_POSSIBLE_CONTACTS_FOR_MERGE_SUCCESS,
      allContacts: getContacts(allContacts),
    });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put(errorToast(error));
  }
}

export function* getPossibleContactsForMerge() {
  yield takeEvery(
    contactMergeTypes.GET_POSSIBLE_CONTACTS_FOR_MERGE,
    handleGetPossibleContactsForMerge,
  );
}
