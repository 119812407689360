import { types } from '../actions/validationActions';
import { createReducer } from './createReducer';

export const initialState = {
  checkNumberError: '',
};

const actionMap = {
  [types.VALIDATE_CHECK_NUMBER_ERROR]: (state, action) => {
    return {
      ...state,
      checkNumberError: action.error,
    };
  },

  [types.CLEAR_CHECK_NUMBER_VALIDATION]: state => {
    return {
      ...state,
      checkNumberError: '',
    };
  },
};

export const validationReducer = createReducer(initialState, actionMap);
