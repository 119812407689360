import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { ActionButton, PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import {
  DetailsList,
  CheckboxVisibility,
  SelectionMode,
} from 'office-ui-fabric-react/lib/DetailsList';
import { Grid, GridRow, Column, BackButton } from '../../components/common';
import { actions as adminUserActions } from '../../actions/adminUserActions';
import { scrollToTop, sortByField } from '../../helpers/util';
import { statuses, sortDirections } from '../../helpers/constants';
import { onColumnClick } from '../../helpers/listsHelper';
import './ManageUsers.css';

class ManageUsers extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    adminUsers: PropTypes.object.isRequired,
    adminUserActions: PropTypes.object.isRequired,
  };

  constructor(props, ...args) {
    super(props, ...args);

    this.state = {
      filterText: '',
      sortDirection: sortDirections.NONE,
      sortField: '',
    };
  }

  componentDidMount() {
    scrollToTop();
    this.props.adminUserActions.getUsersForCampaign();
  }

  mapUsers = () => {
    const { sortField, sortDirection, filterText } = this.state;
    const { users = [] } = this.props.adminUsers;
    const searchText = filterText.toLowerCase();

    return users
      .filter(
        u =>
          ((u.displayName && u.displayName.toLowerCase().includes(searchText)) ||
            (u.emailAddress &&
              u.emailAddress.toLowerCase().includes(searchText))) &&
          !u.isSysAdmin,
      )
      .map(u => ({ ...u, key: u._id }))
      .sort(sortByField(sortField, sortDirection));
  };

  edit = id => {
    this.props.history.push(`/filer/editUser/${id}`);
  };

  toggleSuspendUser = id => {
    const userObj = this.props.adminUsers.users.find(u => u._id === id);
    if (userObj) {
      const { active } = userObj;
      this.props.adminUserActions.toggleUserActive(id, !active);
    }
  };

  resendInvite = id => {
    this.props.adminUserActions.resendInvite(id);
  };

  createColumns = () => {
    const { sortField, sortDirection } = this.state;
    return [
      {
        key: 'active',
        name: 'Active',
        fieldName: 'active',
        isSorted:
          sortField === 'active' && sortDirection !== sortDirections.NONE,
        isSortedDescending: sortDirection === sortDirections.DESC,
        onColumnClick: this.onColumnClick,
        minWidth: 50,
        maxWidth: 75,
        onRender: item =>
          item.active ? (
            <Icon
              iconName="UserCheck"
              styles={{ root: { color: '#107c10', fontSize: '1.2rem' } }}
            />
          ) : (
            <Icon
              iconName="UserMinus"
              styles={{ root: { color: '#a80000', fontSize: '1.2rem' } }}
            />
          ),
      },
      {
        key: 'displayName',
        name: 'Name',
        fieldName: 'displayName',
        isSorted:
          sortField === 'displayName' && sortDirection !== sortDirections.NONE,
        isSortedDescending: sortDirection === sortDirections.DESC,
        onColumnClick: this.onColumnClick,
        maxWidth: 150,
        onRender: item => item.displayName,
      },
      {
        key: 'email',
        name: 'Email',
        fieldName: 'emailAddress',
        isSorted:
          sortField === 'emailAddress' && sortDirection !== sortDirections.NONE,
        isSortedDescending: sortDirection === sortDirections.DESC,
        onColumnClick: this.onColumnClick,
        minWidth: 200,
        maxWidth: 250,
        onRender: item => item.emailAddress,
      },
      {
        key: 'role',
        name: 'User Role',
        fieldName: 'role',
        isSorted:
          sortField === 'role' && sortDirection !== sortDirections.NONE,
        isSortedDescending: sortDirection === sortDirections.DESC,
        onColumnClick: this.onColumnClick,
        minWidth: 200,
        maxWidth: 250,
        onRender: item => item.role,
      },
      {
        key: 'actions',
        name: 'Actions',
        onRender: item => (
          <span className="manage-user-actions">
            <ActionButton
              text="Edit"
              iconProps={{
                iconName: 'PencilAlt',
              }}
              className="manage-user-btn"
              onClick={() => this.edit(item._id)}
            />
            {item.active && (
              <ActionButton
                className="manage-user-btn"
                text="Suspend User"
                iconProps={{
                  iconName: 'UserSlash',
                }}
                onClick={() => this.toggleSuspendUser(item._id)}
              />
            )}
            {!item.active && (
              <ActionButton
                className="manage-user-btn"
                text="Set Active"
                iconProps={{
                  iconName: 'UserCheck',
                }}
                onClick={() => this.toggleSuspendUser(item._id)}
              />
            )}
            {!item.emailVerifiedAt && (
              <ActionButton
                text="Resend Invite"
                iconProps={{
                  iconName: 'Envelope',
                }}
                className="manage-user-btn"
                onClick={() => this.resendInvite(item._id)}
              />
            )}
          </span>
        ),
      },
    ];
  };

  onColumnClick = (e, column) => {
    this.setState(onColumnClick(column.fieldName));
  };

  handleChange = (e, filterText) => {
    this.setState({ filterText });
  };

  render() {
    const { adminUsers } = this.props;

    if (
      adminUsers.status === statuses.PROCESSING ||
      adminUsers.status === statuses.NOT_STARTED
    ) {
      return <Spinner size={SpinnerSize.large} />;
    }

    return (
      <Fragment>
        <BackButton history={this.props.history} />
        <div className="ManageUsers depth-1">
          <h3>Manage Users</h3>
          <Grid>
            <GridRow style={{ marginBottom: 16 }}>
              <Column classNames="ms-textAlignRight">
                <PrimaryButton
                  iconProps={{
                    iconName: 'PlusCircle',
                  }}
                  onClick={() => this.props.history.push('/filer/addUser')}
                  text="Add New User"
                />
              </Column>
            </GridRow>
            <GridRow style={{ marginBottom: 16 }}>
              <Column>
                <TextField
                  onChange={this.handleTextChange}
                  placeholder="Search"
                />
              </Column>
            </GridRow>
            <GridRow>
              <Column>
                <DetailsList
                  compact={false}
                  items={this.mapUsers()}
                  columns={this.createColumns()}
                  selectionMode={SelectionMode.none}
                  checkboxVisibility={CheckboxVisibility.none}
                />
              </Column>
            </GridRow>
          </Grid>
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    adminUsers: state.adminUsers,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    adminUserActions: bindActionCreators(adminUserActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ManageUsers);
