import React from 'react';
import PropTypes from 'prop-types';
import {
  DetailsList,
  CheckboxVisibility,
  SelectionMode,
  DetailsListLayoutMode,
  Sticky,
  StickyPositionType,
  ScrollablePane,
} from 'office-ui-fabric-react';
import { getLabel } from '../../helpers/labelHelper';
import { columns } from '../../helpers/listsHelper';

function renderColumn(cols) {
  return ({ key, name, renderFunc, maxWidth = 200, renderFuncArgs }) => {
    return cols.includes(key)
      ? {
          key: key === 'amount' ? 'key-Amount' : key, // deter right-aligned format
          name,
          isSorted: false,
          isResizable: true,
          onRender: item =>
            renderFunc ? renderFunc(item[key], renderFuncArgs) : item[key],
          maxWidth,
        }
      : null;
  };
}

const AdHocResultsList = ({ session, items, selectedColumns, customColumnList = [] }) => {
  const mapList = () => items.map(i => ({ ...i, key: i._id }));
  const isCustomColumnList = customColumnList.length > 0;

  const getColumns = () => {
    const columnMasterList = isCustomColumnList ? customColumnList : columns({ session });
    const hasCol = renderColumn(selectedColumns);
    const cols = selectedColumns.reduce((list, col) => {
      const columnTemplate = columnMasterList.find(c => c.key === col);
      if (columnTemplate) {
        const name = columnTemplate.text
          .split(' ')
          .map(val => isCustomColumnList ? val : getLabel(val, session))
          .join(' ');

        const args = {
          key: columnTemplate.key,
          name,
          renderFunc: columnTemplate.renderFunc,
          maxWidth: columnTemplate.maxWidth,
          renderFuncArgs: columnTemplate.renderFuncArgs,
        };

        list = [...list, hasCol(args)];
      }

      return list;
    }, []);

    return cols;
  };

  const onScroll = (event) => {
    const element = document.querySelector(
      "[class*='sticky-header']",
    );
    if (element != null) {
      element.scrollLeft = event.target.scrollLeft;
    }
  };

  const onRenderHeader = (headerProps, defaultRender) => (
    <Sticky
      stickyPosition={StickyPositionType.Header}
      isScrollSynced={true}
      stickyClassName="sticky-header"
    >
      {defaultRender(headerProps)}
    </Sticky>
  );

  return (
    <ScrollablePane
      onScroll={onScroll}
      scrollbarVisibility={true}
      className="spreadsheet-data"
    >
      <DetailsList
        items={mapList()}
        columns={getColumns()}
        checkboxVisibility={CheckboxVisibility.none}
        selectionMode={SelectionMode.none}
        compact={false}
        layout={DetailsListLayoutMode.fixed}
        onRenderDetailsHeader={onRenderHeader}
      />
    </ScrollablePane>
  );
};

AdHocResultsList.propTypes = {
  session: PropTypes.object,
  items: PropTypes.array,
  selectedColumns: PropTypes.array,
  customColumnList: PropTypes.array,
};

export default AdHocResultsList;
