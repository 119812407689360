import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'office-ui-fabric-react/lib/Dropdown';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { CaretDown } from '../icons';
import { contactIconMap } from '../../helpers/constants';

export const SourceTypePicker = ({
  selectedKey,
  onChange,
  errorMessage,
  label,
  sourceTypes,
  required,
  disabled,
}) => {
  const onRenderOption = option => (
    <div>
      {option && option.data && option.data.iconName && (
        <Icon
          iconName={option.data.iconName}
          styles={{
            root: {
              color: '#244b8d',
              marginRight: 16,
            },
          }}
        />
      )}
      <span>{option.text}</span>
    </div>
  );

  const onRenderTitle = options => {
    const option = options.find(opt => opt.key === selectedKey);
    if (option) {
      return (
        <div>
          {option.data && option.data.iconName && (
            <Icon
              iconName={option.data.iconName}
              styles={{
                root: {
                  color: '#244b8d',
                  marginRight: 16,
                },
              }}
            />
          )}
          <span>{option.text || ''}</span>
        </div>
      );
    }
    return null;
  };

  return (
    <Dropdown
      onRenderCaretDown={() => <CaretDown />}
      label={label}
      required={required}
      selectedKey={selectedKey}
      errorMessage={errorMessage}
      placeholder="Select"
      disabled={disabled}
      onRenderOption={onRenderOption}
      onRenderTitle={onRenderTitle}
      options={[
        {
          key: 0,
          text: 'Select',
        },
        ...sourceTypes.map(contactType => ({
          key: contactType.key !== undefined ? contactType.key : contactType,
          text: contactType.text !== undefined ? contactType.text : contactType,
          data: {
            iconName:
              contactIconMap[
              contactType.key !== undefined ? contactType.key : contactType
              ],
          },
        })),
      ]}
      onChange={onChange}
    />
  );
};

SourceTypePicker.propTypes = {
  selectedKey: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  label: PropTypes.string,
  sourceTypes: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.object),
  ]).isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
};

SourceTypePicker.defaultProps = {
  selectedKey: 0,
  errorMessage: '',
  label: 'Source Type',
  required: true,
};

export default SourceTypePicker;
