import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, GridRow, Column } from '../../common';

export default class Section12 extends Component {
  static propTypes = {
    q12: PropTypes.shape({
      inKind: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    q12Sum: PropTypes.shape({
      inKind: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  };

  render() {
    const { q12, q12Sum } = this.props;

    return (
      <GridRow>
        <Column sm={1} classNames="ms-textAlignRight">
          <h3 className="form-label">12</h3>
        </Column>
        <Column sm={11}>
          <Grid>
            <GridRow>
              <Column sm={8}>
                <p>
                  {
                    'Total expenditures to date. Total to be carried forward to next report of this election cycle*.'
                  }
                </p>
                <p>{'(Line 8 + 11)'}</p>
              </Column>
              <Column sm={2} classNames="ms-textAlignCenter money-input">
                <p style={{ margin: '16px 12px 0 0' }}>
                  {q12Sum.inKind !== q12.inKind ? q12Sum.inKind : q12.inKind}
                </p>
              </Column>
              <Column sm={2} classNames="ms-textAlignCenter money-input">
                <p style={{ margin: '16px 12px 0 0' }}>
                  {q12Sum.amount !== q12.amount ? q12Sum.amount : q12.amount}
                </p>
              </Column>
            </GridRow>
          </Grid>
        </Column>
      </GridRow>
    );
  }
}
