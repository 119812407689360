import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { GridRow, Column } from '../../common';

const CreditCardEntity = ({
  creditCardCompany,
  cardholderName,
  cardholderEmployer,
  cardholderOccupation,
  onChange,
}) => (
  <Fragment>
    <GridRow>
      <Column md={6}>
        <TextField
          label="Credit Card Company"
          value={creditCardCompany.value}
          required
          errorMessage={creditCardCompany.errorMessage}
          onChange={onChange(creditCardCompany.field)}
        />
      </Column>
      <Column md={6}>
        <TextField
          label={cardholderName.label}
          value={cardholderName.value}
          onChange={onChange(cardholderName.field)}
          required={cardholderName.required}
          errorMessage={cardholderName.errorMessage}
        />
      </Column>
    </GridRow>
    <GridRow>
      <Column md={6}>
        <TextField
          label={cardholderEmployer.label}
          value={cardholderEmployer.value}
          onChange={onChange(cardholderEmployer.field)}
          required={cardholderEmployer.required}
          errorMessage={cardholderEmployer.errorMessage}
        />
      </Column>
      <Column md={6}>
        <TextField
          label={cardholderOccupation.label}
          value={cardholderOccupation.value}
          onChange={onChange(cardholderOccupation.field)}
          required={cardholderOccupation.required}
          errorMessage={cardholderOccupation.errorMessage}
        />
      </Column>
    </GridRow>
  </Fragment>
);

CreditCardEntity.propTypes = {
  creditCardCompany: PropTypes.object.isRequired,
  cardholderName: PropTypes.object.isRequired,
  cardholderEmployer: PropTypes.object.isRequired,
  cardholderOccupation: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CreditCardEntity;
