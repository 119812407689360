import Moment from 'moment-timezone';
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'office-ui-fabric-react/lib/Link';
import {
  fecStateMapByStateName,
} from '../../helpers/constants';
import { Grid, GridRow, Column, LabelGroup } from '../common';
import { DatePicker } from '../Pickers';

const getStateAbbr = state => {
  return fecStateMapByStateName[state];
};

const FECCoverPage = ({
  campaign,
  currentReport,
  toggleExpandSection,
  handleDateChange,
  state,
  sectionName,
}) => {
  const getName = () => {
    const last = campaign?.candidate?.lastName || '';
    const first = campaign?.candidate?.firstName || '';
    const middle = campaign?.candidate?.middleName || '';
    return `${first} ${middle} ${last}`;
  };

  const getAmendDate = () => Moment(currentReport.report.amendedReportFiledAt).tz(campaign.timeZoneName || 'US/Eastern').format('MM/DD/YYYY');

  return (
    <div className="FECCoverPage fec-report-section depth-1" name={sectionName}>
      <h3>
        COVER PAGE{' '}
        <small>
          {' '}
          (
          <Link onClick={() => toggleExpandSection(sectionName, false)}>
            {state.expandableSections[sectionName] === true
              ? 'Collapse'
              : 'Expand'}
          </Link>
          )
        </small>
      </h3>
      <div
        className={`edit-fec-cover-page${
          state.expandableSections[sectionName] === true
            ? '-expanded'
            : '-collapsed'
        }`}
      >
        <Grid style={{ padding: '0 24px' }}>
          <GridRow>
            <Column lg={6}>
              <LabelGroup
                label="1. Name of Committee"
                subText="(in full)"
                value={campaign.candidateOrCommitteeFullName || ''}
              />
            </Column>
            <Column lg={6}>
              <LabelGroup
                label="Address"
                subText="(number and street)"
                nonNumbered
                value={campaign.candidateOrCommitteeFullAddress
                  .replace(/,\sundefined/gi, '')
                  .replace(/\sundefined/gi, '')
                  .replace(/undefined/gi, '')}
              />
            </Column>
          </GridRow>
          <GridRow>
            <Column>
              <LabelGroup
                label="2. Name Of Candidate"
                value={getName()}
              />
            </Column>
          </GridRow>
          {campaign.subOffice &&
          <GridRow>
            <Column>
              <LabelGroup
                label="3. Office Sought (State and District)"
                value={`${getStateAbbr(
                  campaign.officeState,
                )} - ${campaign.subOffice.length === 1 ? `0${campaign.subOffice}` : campaign.subOffice}`}
              />
            </Column>
          </GridRow>
          }
          <GridRow>
            <Column>
              <LabelGroup
                label="4. FEC Identification Number"
                value={campaign.filerId || ''}
              />
            </Column>
          </GridRow>
          <GridRow>
            <Column>
              <LabelGroup
                label="5. Is This An Amendment"
                value={currentReport.report.amendmentNumber ? `Yes, it amends the notice filed on ${getAmendDate()}.` : 'No, This is a new filing.'}
              />
            </Column>
          </GridRow>
          <GridRow>
            <Column md={6}>
              <DatePicker
                label="5. Covering Period"
                value={state.startDate}
                required
                errorMessage={state.errors.startDateError}
                onChange={handleDateChange('startDate')}
                disabled
              />
            </Column>
            <Column md={6}>
              <DatePicker
                label="Through"
                value={state.endDate}
                required
                errorMessage={state.errors.endDateError}
                onChange={handleDateChange('endDate')}
                disabled
              />
            </Column>
          </GridRow>
        </Grid>
      </div>
    </div>
  );
};

FECCoverPage.propTypes = {
  campaign: PropTypes.object.isRequired,
  currentReport: PropTypes.object.isRequired,
  toggleExpandSection: PropTypes.func.isRequired,
  handleDateChange: PropTypes.func.isRequired,
  state: PropTypes.object.isRequired,
  sectionName: PropTypes.string.isRequired,
};

export default FECCoverPage;
