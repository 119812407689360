import { put, call, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import { types as contactTransactionTypes } from '../actions/contactTransactionActions';
import { types as messagingTypes } from '../actions/messagingActions';
import { getServerSideErrorMessage } from '../helpers/util';
import { toastTypes } from '../helpers/constants';

export function* handleGetContactTransactions(action) {
  try {
    const {
      data: { id },
    } = action;
    yield put({ type: contactTransactionTypes.GET_TRANSACTIONS_PROCESSING });
    const {
      data: { contributions, expenditures, loans },
    } = yield call(axios.get, `/api/filer/contacts/${id}/transactions`, {
      withCredentials: true,
    });
    yield put({
      type: contactTransactionTypes.GET_TRANSACTIONS_SUCCESS,
      data: { contributions, expenditures, loans },
    });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put({
      type: messagingTypes.SET_TOAST,
      data: {
        message: error,
        toastType: toastTypes.ERROR,
      },
    });
  }
}

export function* getContactTransactions() {
  yield takeLatest(
    contactTransactionTypes.GET_TRANSACTIONS,
    handleGetContactTransactions,
  );
}
