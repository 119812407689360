import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { GridRow, Column, MoneyField } from '../../common';
import { getTotalIndividualContributions } from '../../../pages/Reports/fecReportHelper';

const FECSection11aiii = ({ state }) => {
  return (
    <Fragment>
      <GridRow>
        <Column sm={4} classNames="sub-question">
          <p className="sub-question">
            (iii) TOTAL of contributions from individuals
          </p>
        </Column>
        <Column sm={4} classNames="entry-row">
          <MoneyField
            value={getTotalIndividualContributions('A', state) || 0}
            disabled
          />
        </Column>
        <Column sm={4} classNames="entry-row">
          <MoneyField
            value={getTotalIndividualContributions('B', state) || 0}
            disabled
          />
        </Column>
      </GridRow>
    </Fragment>
  );
};

FECSection11aiii.propTypes = {
  state: PropTypes.object.isRequired,
};

export default FECSection11aiii;
