export const validate = ({ state }) => {
  const errors = {
    integrationPartnerError: '',
    confirmPasswordError: '',
  };

  if (!state.integrationPartner) {
    errors.integrationPartnerError = 'Integration Partner is required';
  }

  if (state.password !== state.confirmPassword) {
    errors.confirmPasswordError = 'Passwords do not match';
  }
  return errors;
};

export const validateConfirmPassword = ({ state }) => {
  const errors = {
    integrationPartnerError: '',
    confirmPasswordError: '',
  };

  if (state.password !== state.confirmPassword) {
    errors.confirmPasswordError = 'Passwords do not match';
  }
  return errors;
};
