import React from 'react';
import PropTypes from 'prop-types';
import { Label } from 'office-ui-fabric-react';
import './LabelGroup.css';

const LabelGroup = ({ label, nonNumbered, value, subText }) => (
  <div className={`LabelGroup${nonNumbered ? ' non-numbered' : ''}`}>
    <Label>
      {label}
      {subText !== '' && <span className="subText">{subText}</span>}
    </Label>
    <p>{value}</p>
  </div>
);

LabelGroup.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  subText: PropTypes.string,
  nonNumbered: PropTypes.bool,
  value: PropTypes.string.isRequired,
};

LabelGroup.defaultProps = {
  nonNumbered: false,
  subText: '',
};

export default LabelGroup;
