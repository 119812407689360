import { isInvalidDate } from '../../helpers/util';
import { isFederalPac, isFederalJfc } from '../../helpers/committeeHelper';

const validateStateNonCandidate = state => {
  state.committee.formValid = true;
  if (state.committee.name.length === 0) {
    state.committee.nameError = 'Committee name is required';
    state.committee.formValid = false;
  } else {
    state.committee.nameError = '';
  }

  if (!state.committee.registrationDate) {
    state.committee.formValid = false;
    state.committee.registrationDateError = 'Registration date is required';
  } else if (isInvalidDate(state.committee.registrationDate)) {
    state.committee.formValid = false;
    state.committee.registrationDateError = 'Registration date is invalid';
  } else {
    state.committee.registrationDateError = '';
  }

  if (state.committee.state === 0) {
    state.committee.formValid = false;
    state.committee.stateError = 'Committee state is required';
  } else {
    state.committee.stateError = '';
  }

  return state;
};

const validateFederalNonCandidate = state => {
  state.committee.formValid = true;
  if (state.committee.name.length === 0) {
    state.committee.nameError = 'Committee name is required';
    state.committee.formValid = false;
  } else {
    state.committee.nameError = '';
  }

  if (!state.committee.registrationDate) {
    state.committee.formValid = false;
    state.committee.registrationDateError = 'Registration date is required';
  } else if (isInvalidDate(state.committee.registrationDate)) {
    state.committee.formValid = false;
    state.committee.registrationDateError = 'Registration date is invalid';
  } else {
    state.committee.registrationDateError = '';
  }

  if (state.committee.type === 'pac-separate-segregated-fund') {
    if (!state.committee.federalPac.organizationType) {
      state.committee.formValid = false;
      state.committee.federalPac.organizationTypeError = 'Organization type is required';
    } else {
      state.committee.federalPac.organizationTypeError = '';
    }
  }
  return state;
};

export const validateNonCandidateCommittee = state => {
  if (state.committee.type === 0) {
    state.committee.formValid = false;
    state.committee.typeError = 'Committee type is required';
    return state;
  }

  state.committee.typeError = '';
  if (isFederalPac(state.committee) || isFederalJfc(state.committee)) {
    return validateFederalNonCandidate(state);
  }
  return validateStateNonCandidate(state);
};
