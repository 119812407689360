import React from 'react';
import PropTypes from 'prop-types';
import {
  DefaultButton,
  PrimaryButton,
} from 'office-ui-fabric-react/lib/Button';
import { Grid, GridRow, Column } from '../common';
import {
  Section1,
  Section2,
  Section3,
  Section3a,
  Section3b,
  Section3c,
  Section3d,
  Section4,
  Section5,
  Section6,
  Section7,
  Section8,
  Section9,
  Section10,
  Section11,
  Section12,
  Section13,
  Section14,
  Section15,
} from './SummarySections';

const calcSummary = questions =>
  questions.reduce(
    (totals, q) => {
      totals.amount = (
        parseFloat(totals.amount, 10) + parseFloat(q.amount || 0, 10)
      ).toFixed(2);
      totals.inKind = (
        parseFloat(totals.inKind, 10) + parseFloat(q.inKind || 0, 10)
      ).toFixed(2);
      return totals;
    },
    { inKind: 0, amount: 0 },
  );

export const SummarySection = ({
  isExpanded,
  actions,
  q1,
  q2,
  q3,
  q3a,
  q3b,
  q3c,
  q3d,
  q4,
  q5,
  q6,
  q7,
  q8,
  q9,
  q10,
  q11,
  q12,
  q13,
  q14,
  q15,
}) => {
  const q5Sum = calcSummary([q3, q3a, q3b, q3c, q3d, q4]);
  const q6Sum = calcSummary([
    q2,
    parseFloat(q5Sum.inKind + q5Sum.amount, 10) !==
    parseFloat(q5.inKind + q5.amount, 10)
      ? q5Sum
      : q5,
  ]);
  const q11Sum = calcSummary([q9, q10]);
  const q12Sum = calcSummary([
    q8,
    parseFloat(q11Sum.inKind + q11Sum.amount, 10) !==
    parseFloat(q11.inKind + q11.amount, 10)
      ? q11Sum
      : q11,
  ]);
  const q6InKind =
    parseFloat(q6Sum.inKind + q6Sum.amount, 10) !==
    parseFloat(q6.inKind + q6.amount, 10)
      ? q6Sum.inKind
      : q6.inKind;
  const q6Amount =
    parseFloat(q6Sum.inKind + q6Sum.amount, 10) !==
    parseFloat(q6.inKind + q6.amount, 10)
      ? q6Sum.amount
      : q6.amount;
  const q12InKind =
    parseFloat(q12Sum.inKind + q12Sum.amount, 10) !==
    parseFloat(q12.inKind + q12.amount, 10)
      ? q12Sum.inKind
      : q12.inKind;
  const q12Amount =
    parseFloat(q12Sum.inKind + q12Sum.amount, 10) !==
    parseFloat(q12.inKind + q12.amount, 10)
      ? q12Sum.amount
      : q12.amount;
  const q15Sum = {
    inKind: parseFloat(q6InKind - q12InKind, 10).toFixed(2),
    amount: parseFloat(q6Amount - q12Amount + q14.amount, 10).toFixed(2),
  };
  return (
    <div className="summary depth-1" name="summary">
      <header onClick={actions.toggleExpand}>
        <h3>Summary</h3>
      </header>
      <div className={`content${isExpanded ? '-expanded' : '-collapsed'}`}>
        <Grid>
          <GridRow>
            <Column>
              <h4>Contributions Received</h4>
            </Column>
          </GridRow>
          <Section1
            hasContributionsToReport={q1.key === 'yes'}
            actions={actions}
          />
          <Section2 q2={q2} handleChange={actions.handleChange} />
          <Section3 q3={q3} />
          <Section3a q3a={q3a} handleChange={actions.handleChange} />
          <Section3b q3b={q3b} handleChange={actions.handleChange} />
          <Section3c q3c={q3c} handleChange={actions.handleChange} />
          <Section3d q3d={q3d} handleChange={actions.handleChange} />
          <Section4 q4={q4} handleChange={actions.handleChange} />
          <Section5 q5={q5} q5Sum={q5Sum} />
          <Section6 q6={q6} q6Sum={q6Sum} />
        </Grid>

        <Grid>
          <GridRow>
            <Column>
              <h4>Expenditures Made</h4>
            </Column>
          </GridRow>
          <Section7
            hasExpendituresToReport={q7.key === 'yes'}
            actions={actions}
          />
          <Section8 q8={q8} handleChange={actions.handleChange} />
          <Section9 q9={q9} />
          <Section10 q10={q10} handleChange={actions.handleChange} />
          <Section11 q11={q11} q11Sum={q11Sum} />
          <Section12 q12={q12} q12Sum={q12Sum} />
        </Grid>

        <Grid>
          <GridRow>
            <Column>
              <h4>Investments</h4>
              <Section13 q13={q13} />
              <Section14 q14={q14} />
            </Column>
          </GridRow>
        </Grid>

        <Grid>
          <GridRow>
            <Column>
              <h4>Total Net Balance on Hand</h4>
              <Section15
                q15={
                  parseFloat(q15Sum.inKind + q15Sum.amount, 10) !==
                  parseFloat(q15.inKind + q15.amount, 10)
                    ? q15Sum
                    : q15
                }
              />
            </Column>
          </GridRow>
        </Grid>
        <div className="section-actions">
          <DefaultButton
            text="Back"
            style={{ marginRight: 16 }}
            onClick={actions.previousSection}
          />
          <PrimaryButton text="Continue" onClick={actions.nextSection} />
        </div>
      </div>
    </div>
  );
};

SummarySection.propTypes = {
  actions: PropTypes.object.isRequired,
  isExpanded: PropTypes.bool,
  hasContributionsToReport: PropTypes.object,
  hasExpendituresToReport: PropTypes.object,
  q1: PropTypes.object,
  q2: PropTypes.object,
  q3: PropTypes.object,
  q3a: PropTypes.object,
  q3b: PropTypes.object,
  q3c: PropTypes.object,
  q3d: PropTypes.object,
  q4: PropTypes.object,
  q5: PropTypes.object,
  q6: PropTypes.object,
  q7: PropTypes.object,
  q8: PropTypes.object,
  q9: PropTypes.object,
  q10: PropTypes.object,
  q11: PropTypes.object,
  q12: PropTypes.object,
  q13: PropTypes.object,
  q14: PropTypes.object,
  q15: PropTypes.object,
  contributions: PropTypes.object,
  expenditures: PropTypes.object,
  loans: PropTypes.object,
};

SummarySection.defaultProps = {
  q1: { key: 'no' },
  q2: { inKind: 0, amount: 0 },
  q3: { inKind: 0, amount: 0 },
  q3a: { amount: 0 },
  q3b: { amount: 0 },
  q3c: { amount: 0 },
  q3d: { amount: 0 },
  q4: { inKind: 0, amount: 0 },
  q5: { inKind: 0, amount: 0 },
  q6: { inKind: 0, amount: 0 },
  q7: { key: 'no' },
  q8: { inKind: 0, amount: 0 },
  q9: { inKind: 0, amount: 0 },
  q10: { inKind: 0, amount: 0 },
  q11: { inKind: 0, amount: 0 },
  q12: { inKind: 0, amount: 0 },
  q13: { amount: 0 },
  q14: { amount: 0 },
  q15: { inKind: 0, amount: 0 },
  isExpanded: false,
  hasContributionsToReport: { key: 'no' },
  hasExpendituresToReport: { key: 'no' },
  contributions: {
    contributionsForReport: [],
  },
  expenditures: {
    getExpendituresForReport: [],
  },
  loans: {
    loansForReport: [],
  },
};

export default SummarySection;
