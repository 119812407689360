import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Pivot, PivotItem } from 'office-ui-fabric-react/lib/Pivot';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { ActionButton, DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { EmailField } from '../common';

export const EmailForm = ({
  handleChange,
  handleChangeNewEmail,
  handleChangeAdditionalEmail,
  handleDeleteAdditionalEmail,
  addNewEmailStart,
  cancelAddEmail,
  addNewEmailFinish,
  email,
  additionalEmailAddresses,
  addEmail,
  newEmail,
}) => (
  <Fragment>
    <Pivot>
      <PivotItem headerText="Primary Email">
        <EmailField value={email} onChange={handleChange('email')} />
      </PivotItem>
      {(additionalEmailAddresses || []).map((ae, index) => (
        <PivotItem key={ae._id} headerText={`Additional Email ${index + 1}`}>
          <EmailField
            label={`Additional Email ${index + 1}`}
            value={ae.value}
            onChange={handleChangeAdditionalEmail(ae._id)}
          />
          <ActionButton
            text="Remove Email"
            onClick={handleDeleteAdditionalEmail(ae._id)}
            iconProps={{
              iconName: 'MinusCircle',
              styles: {
                root: {
                  color: '#a80000',
                },
              },
            }}
            styles={{
              rootHovered: {
                color: '#640000',
              },
              iconHovered: {
                color: '#640000',
              },
              iconPressed: {
                color: '#640000',
              },
            }}
          />
        </PivotItem>
      ))}
    </Pivot>
    <ActionButton
      disabled={addEmail}
      iconProps={{ icon: 'PlusCircle' }}
      onClick={addNewEmailStart}
      text="Add Additional Email"
    />
    {addEmail && (
      <div className="additional-field">
        <EmailField
          value={newEmail}
          label="New Email"
          onChange={handleChangeNewEmail}
        />
        <Stack horizontal alignContent="end">
          <DefaultButton
            text="Cancel"
            onClick={cancelAddEmail}
            style={{ marginRight: 16 }}
          />
          <DefaultButton text="Save New Email" onClick={addNewEmailFinish} />
        </Stack>
      </div>
    )}
  </Fragment>
);

EmailForm.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleChangeNewEmail: PropTypes.func.isRequired,
  handleChangeAdditionalEmail: PropTypes.func.isRequired,
  handleDeleteAdditionalEmail: PropTypes.func.isRequired,
  addNewEmailStart: PropTypes.func.isRequired,
  cancelAddEmail: PropTypes.func.isRequired,
  addNewEmailFinish: PropTypes.func.isRequired,
  email: PropTypes.string,
  additionalEmailAddresses: PropTypes.array,
  addEmail: PropTypes.bool,
  newEmail: PropTypes.string,
};

EmailForm.defaultProps = {
  email: '',
  additionalEmailAddresses: [],
  addEmail: false,
  newEmail: '',
};
