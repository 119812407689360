import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import SectionLabel from './SectionLabel';
import { GridRow, Column, MoneyField } from '../../common';

const FECSection7 = ({ q7aColA, q7aColB }) => {
  return (
    <Fragment>
      <GridRow style={{ borderTop: '1px solid #919191' }}>
        <Column md={4}>
          <SectionLabel
            label="7."
            labelLines={[
              'Total Disbursements (from Line 31)',
            ]}
          />
        </Column>
        <Column md={4} classNames="entry-row">
          <MoneyField
            disabled
            value={q7aColA}
          />
        </Column>
        <Column md={4} classNames="entry-row">
          <MoneyField disabled value={q7aColB} />
        </Column>
      </GridRow>
    </Fragment>
  );
};

FECSection7.propTypes = {
  q7aColA: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  q7aColB: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default FECSection7;
