import React from 'react';
import PropTypes from 'prop-types';
import { Separator } from 'office-ui-fabric-react/lib/Separator';
import { createTheme } from 'office-ui-fabric-react/lib/Styling';

const theme = createTheme({
  fonts: {
    medium: {
      fontSize: '18px',
    },
  },
});

export const SectionSeparator = ({ content, alignContent }) => (
  <Separator
    theme={theme}
    styles={{
      root: {
        marginTop: 24,
      },
    }}
    alignContent={alignContent}
  >
    {content}
  </Separator>
);

SectionSeparator.propTypes = {
  content: PropTypes.string.isRequired,
  alignContent: PropTypes.oneOf(['start', 'center', 'end']),
};

SectionSeparator.defaultProps = {
  alignContent: 'center',
};
