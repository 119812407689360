import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'office-ui-fabric-react/lib/Link';
import {
  DefaultButton,
  PrimaryButton,
} from 'office-ui-fabric-react/lib/Button';
import { Grid, GridRow, Column } from '../common';
import {
  FECSection11ai,
  FECSection11aii,
  FECSection11aiii,
  FECSection11b,
  FECSection11c,
  FECSection11d,
  FECSection11e,
  FECSection12,
  FECSection13a,
  FECSection13b,
  FECSection13c,
  FECSection14,
  FECSection15,
  FECSection16,
} from './Sections';

const styles = {
  sectionHeader: {
    borderBottom: '1px solid #919191',
    height: 50,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  sectionSeparator: {
    marginTop: 16,
    borderTop: '3px solid #919191',
  },
};

const FECDetailedSummaryReceipts = ({
  toggleExpandSection,
  state,
  sectionName,
}) => {
  return (
    <div
      className="FECDetailedSummaryReceipts fec-report-section depth-1"
      name={sectionName}
    >
      <h3>
        DETAILED SUMMARY OF RECEIPTS{' '}
        <small>
          {' '}
          (
          <Link onClick={() => toggleExpandSection(sectionName)}>
            {state.expandableSections[sectionName] === true
              ? 'Collapse'
              : 'Expand'}
          </Link>
          )
        </small>
      </h3>
      <div
        className={`fec-summary-detailed-receipts${
          state.expandableSections[sectionName] === true
            ? '-expanded'
            : '-collapsed'
        }`}
      >
        <Grid>
          <GridRow style={styles.sectionSeparator}>
            <Column sm={4}>
              <p style={styles.sectionHeader}>
                <strong>I. RECEIPTS</strong>
              </p>
            </Column>
            <Column sm={4}>
              <p style={styles.sectionHeader}>
                <strong>COLUMN A</strong>
                <strong>Total This Period</strong>
              </p>
            </Column>
            <Column sm={4}>
              <p style={styles.sectionHeader}>
                <strong>COLUMN B</strong>
                <strong>Election Cycle-to-Date</strong>
              </p>
            </Column>
          </GridRow>
          <GridRow>
            <Column sm={4}>
              <p>11. CONTRIBUTIONS (other than loans) FROM</p>
            </Column>
          </GridRow>
          <FECSection11ai
            q11aiColA={state.q11aiColA}
            q11aiColB={state.q11aiColB}
          />
          <FECSection11aii
            q11aiiColA={state.q11aiiColA}
            q11aiiColB={state.q11aiiColB}
          />
          <FECSection11aiii state={state} />
          <FECSection11b
            q11bColA={state.q11bColA}
            q11bColB={state.q11bColB}
          />
          <FECSection11c
            q11cColA={state.q11cColA}
            q11cColB={state.q11cColB}
          />
          <FECSection11d
            q11dColA={state.q11dColA}
            q11dColB={state.q11dColB}
          />

          <FECSection11e state={state} />
          <FECSection12
            q12ColA={state.q12ColA}
            q12ColB={state.q12ColB}
          />
          <FECSection13a
            q13aColA={state.q13aColA}
            q13aColB={state.q13aColB}
          />
          <FECSection13b
            q13bColA={state.q13bColA}
            q13bColB={state.q13bColB}
          />
          <FECSection13c state={state} />
          <FECSection14
            q14ColA={state.q14ColA}
            q14ColB={state.q14ColB}
          />
          <FECSection15
            q15ColA={state.q15ColA}
            q15ColB={state.q15ColB}
          />
          <FECSection16 state={state} />
        </Grid>
        <div className="section-actions">
          <DefaultButton
            text="Previous Section"
            style={{ marginRight: 16 }}
            onClick={() => toggleExpandSection('summary', false)}
          />
          <PrimaryButton
            text="Next Section"
            onClick={() =>
              toggleExpandSection('summaryDetailedDisbursements', false)
            }
          />
        </div>
      </div>
    </div>
  );
};

FECDetailedSummaryReceipts.propTypes = {
  toggleExpandSection: PropTypes.func.isRequired,
  state: PropTypes.object.isRequired,
  sectionName: PropTypes.string.isRequired,
};

export default FECDetailedSummaryReceipts;
