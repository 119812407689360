import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'office-ui-fabric-react/lib/Dropdown';
import { CaretDown } from '../icons';

const ReportViewPicker = ({
  filedNonFecLink,
  filedPdfLocation,
  pdfDetailLocation,
  fecResponseLocation,
  fecFileLocation,
  tbdFiledLocation,
  tbdReceiptLocation,
}) => {
  const doView = (item, v) => {
    if (v.location) {
      window.open(v.location, '_blank');
    }
  };

  return (
    <Dropdown
      onRenderCaretDown={() => <CaretDown />}
      placeholder="View"
      onChange={doView}
      selectedKeys={0}
      styles={{ root: { width: 110 } }}
      options={
        [
          { key: 0, text: 'View', location: '' },
          ...(filedPdfLocation ? [{ key: 1, text: 'Filed PDF', location: filedPdfLocation }] : []),
          ...(pdfDetailLocation ? [{ key: 2, text: 'PDF Detail', location: pdfDetailLocation }] : []),
          ...(fecResponseLocation ? [{ key: 3, text: 'FEC Response', location: fecResponseLocation }] : []),
          ...(fecFileLocation ? [{ key: 4, text: 'FEC File', location: fecFileLocation }] : []),
          ...(filedNonFecLink ? [{ key: 5, text: 'XML File', location: filedNonFecLink }] : []),
          ...(tbdFiledLocation ? [{ key: 6, text: 'TBD File', location: tbdFiledLocation }] : []),
          ...(tbdReceiptLocation ? [{ key: 7, text: 'TBD Receipt', location: tbdReceiptLocation }] : []),
        ]
      }
    />);
};

ReportViewPicker.propTypes = {
  filedPdfLocation: PropTypes.string,
  pdfDetailLocation: PropTypes.string,
  fecResponseLocation: PropTypes.string,
  fecFileLocation: PropTypes.string,
  filedNonFecLink: PropTypes.string,
  tbdFiledLocation: PropTypes.string,
  tbdReceiptLocation: PropTypes.string,
};

ReportViewPicker.defaultProps = {
  filedPdfLocation: '',
  pdfDetailLocation: '',
  fecResponseLocation: '',
  fecFileLocation: '',
  filedNonFecLink: '',
  tbdFiledLocation: '',
  tbdReceiptLocation: '',
};

export default ReportViewPicker;
