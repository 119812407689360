import { types } from '../actions/expenditureActions';
import { statuses } from '../helpers/constants';
import { createReducer } from './createReducer';

export const initialState = {
  expenditures: [],
  // These track rows by rowId and need to be objects holding lists of expenditures, status or error
  // expendituresByContact[<rowId>] = [<expenditure>,<expenditure>];
  // getExpendituresByContactStatus[<rowId]> = status
  // getExpendituresByContactError[<rowId]> = error
  expendituresByContact: {},
  getExpendituresByContactError: {},
  getExpendituresByContactStatus: {},
  getExpendituresError: null,
  getExpendituresStatus: statuses.NOT_STARTED,
  saveStatus: statuses.NOT_STARTED,
  getNextPageStatus: statuses.PROCESSING,
};

const actionMap = {
  [types.GET_EXPENDITURES_PROCESSING]: state => {
    return {
      ...state,
      getExpendituresStatus: statuses.PROCESSING,
    };
  },
  [types.GET_EXPENDITURES_SUCCESS]: (state, action) => {
    return {
      ...state,
      expenditures: action.expenditures,
      getExpendituresStatus: statuses.SUCCESS,
    };
  },
  [types.GET_EXPENDITURES_FAILURE]: (state, action) => {
    return {
      ...state,
      getExpendituresError: action.error,
      getExpendituresStatus: statuses.ERROR,
    };
  },
  [types.GET_EXPENDITURES_BY_CONTACT_PROCESSING]: (state, action) => {
    const { rowId } = action;
    return {
      ...state,
      getExpendituresByContactError: {
        ...state.getExpendituresByContactError,
        [rowId]: null,
      },
      getExpendituresByContactStatus: {
        ...state.getExpendituresByContactStatus,
        [rowId]: statuses.PROCESSING,
      },
    };
  },
  [types.GET_EXPENDITURES_BY_CONTACT_SUCCESS]: (state, action) => {
    const { expenditures, rowId } = action.data;
    return {
      ...state,
      getExpendituresByContactError: {
        ...state.getExpendituresByContactError,
        [rowId]: null,
      },
      getExpendituresByContactStatus: {
        ...state.getExpendituresByContactStatus,
        [rowId]: statuses.SUCCESS,
      },
      expendituresByContact: {
        ...state.expendituresByContact,
        [rowId]: expenditures,
      },
    };
  },
  [types.GET_EXPENDITURES_BY_CONTACT_FAILURE]: (state, action) => {
    const { error, rowId } = action.data;
    return {
      ...state,
      getExpendituresByContactError: {
        ...state.getExpendituresByContactError,
        [rowId]: error,
      },
      getExpendituresByContactStatus: {
        ...state.getExpendituresByContactStatus,
        [rowId]: statuses.ERROR,
      },
    };
  },
  [types.SAVE_EXPENDITURE_PROCESSING]: state => {
    return {
      ...state,
      saveStatus: statuses.PROCESSING,
    };
  },
  [types.SAVE_EXPENDITURE_RESET]: state => {
    return {
      ...state,
      saveStatus: statuses.NOT_STARTED,
    };
  },
  [types.SAVE_EXPENDITURE_FAILURE]: state => {
    return {
      ...state,
      saveStatus: statuses.ERROR,
    };
  },
  [types.SAVE_EXPENDITURE_SUCCESS]: state => {
    return {
      ...state,
      saveStatus: statuses.SUCCESS,
    };
  },
  [types.GET_EXPENDITURES_NEXT_PAGE_PROCESSING]: state => ({
    ...state,
    getNextPageStatus: statuses.PROCESSING,
  }),
  [types.GET_EXPENDITURES_NEXT_PAGE_SUCCESS]: (
    state,
    { data: { expenditures } },
  ) => ({
    ...state,
    expenditures: [...state.expenditures, ...expenditures],
    getNextPageStatus: statuses.NOT_STARTED,
  }),
};

export const expendituresReducer = createReducer(initialState, actionMap);
