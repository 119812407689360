import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import { types } from '../../actions/depositActions';
import { formatCurrency, formatDate } from '../../helpers/util';
import { getSingleDepositForTicket, getCampaign } from '../../selectors';
import './DepositTicket.css';

const DepositTicket = ({ match }) => {
  const reduxDispatch = useDispatch();
  const deposit = useSelector(getSingleDepositForTicket);
  const campaign = useSelector(getCampaign);

  const getDeposit = () => {
    const { id } = match.params;
    reduxDispatch({
      type: types.GET_DEPOSIT_TICKET_INFO,
      data: {
        id,
      },
    });
  };

  const clearDeposit = () => {
    reduxDispatch({
      type: types.CLEAR_SINGLE_DEPOSIT_FOR_TICKET,
    });
  };

  useEffect(() => {
    getDeposit();
    return () => {
      clearDeposit();
    };
  }, []);

  const print = () => {
    window.print();
  };

  if (!deposit || !campaign) {
    return null;
  }

  const {
    contributionCashDeposits,
    contributionCheckDeposits,
  } = deposit?.contributions.reduce(
    (acc, contribution) => {
      if (contribution.paymentType === 'Check') {
        acc.contributionCheckDeposits = [
          ...acc.contributionCheckDeposits,
          contribution,
        ];
      } else {
        acc.contributionCashDeposits = [
          ...acc.contributionCashDeposits,
          contribution,
        ];
      }
      return acc;
    },
    {
      contributionCashDeposits: [],
      contributionCheckDeposits: [],
    },
  );

  const {
    expenditureCashDeposits,
    expenditureCheckDeposits,
  } = deposit?.expenditures.reduce(
    (acc, expenditure) => {
      if (expenditure.paymentType === 'Check') {
        acc.expenditureCheckDeposits = [
          ...acc.expenditureCheckDeposits,
          expenditure,
        ];
      } else {
        acc.expenditureCashDeposits = [
          ...acc.expenditureCashDeposits,
          expenditure,
        ];
      }
      return acc;
    },
    {
      expenditureCashDeposits: [],
      expenditureCheckDeposits: [],
    },
  );

  const { loanCashDeposits, loanCheckDeposits } = deposit?.loans.reduce(
    (acc, expenditure) => {
      if (expenditure.paymentType === 'Check') {
        acc.loanCheckDeposits = [...acc.loanCheckDeposits, expenditure];
      } else {
        acc.loanCashDeposits = [...acc.loanCashDeposits, expenditure];
      }
      return acc;
    },
    {
      loanCashDeposits: [],
      loanCheckDeposits: [],
    },
  );

  const getTotal = (sum, deposit) => {
    sum += deposit.amount;
    return sum;
  };

  const cashTotal = [
    ...contributionCashDeposits,
    ...expenditureCashDeposits,
    ...loanCashDeposits,
  ].reduce(getTotal, 0);

  const checkTotal = [
    ...contributionCheckDeposits,
    ...expenditureCheckDeposits,
    ...loanCheckDeposits,
  ].reduce(getTotal, 0);

  return (
    <Fragment>
      <div className="DepositTicket">
        <div className="wrapper">
          <div className="content">
            <h3 className="header">Deposit Ticket</h3>
            <div className="campaign-details">
              <h3>{campaign.committee.name}</h3>
              <address>
                <p>{campaign.candidate.addressLine1 || ''}</p>
                {campaign.candidate.addressLine2 && (
                  <p>{campaign.candidate.addressLine2}</p>
                )}
                <p>
                  {`${campaign.candidate.city || ''}, ${campaign.candidate
                    .state || ''} ${campaign.candidate.zipCode || ''}`.replace(
                      /^,\s*$/g,
                      '',
                    )}
                </p>
              </address>
              <p className="date">
                <span>Date:</span>
                {formatDate(deposit.depositDate, 'MMMM DD, YYYY')}
              </p>
              <div className="bank-info">
                <h3>Bank Name</h3>
                <address>
                  <p>5000 Commerce Dr.</p>
                  <p>Anytown, USA 12345</p>
                </address>
              </div>
            </div>
          </div>
          <div className="deposits-list">
            <table>
              <tbody>
                <tr>
                  <td className="ms-fontWeight-semibold label-cell">Cash</td>
                  <td className="ms-textAlignRight">
                    {formatCurrency(cashTotal)}
                  </td>
                </tr>
                <tr>
                  <td className="ms-fontWeight-semibold label-cell">Checks</td>
                  <td className="ms-textAlignCenter"> - </td>
                </tr>
                {[
                  ...contributionCheckDeposits,
                  ...expenditureCheckDeposits,
                  ...loanCheckDeposits,
                ].map(deposit => (
                  <tr key={deposit._id}>
                    <td className="ms-textAlignRight">
                      <p>
                        {deposit.businessName ||
                          deposit.displayName ||
                          deposit.displayName}
                      </p>
                      <p>{`${deposit.checkNumber ? '#' + deposit.checkNumber : ''
                        }`}</p>
                    </td>
                    <td className="ms-textAlignRight">
                      {formatCurrency(deposit.amount)}
                    </td>
                  </tr>
                ))}
                <tr>
                  <td className="ms-fontWeight-semibold label-cell">Total:</td>
                  <td className="ms-textAlignRight">
                    {formatCurrency(cashTotal + checkTotal)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="DepositTicket-actions">
        <PrimaryButton
          text="Print"
          iconProps={{
            iconName: 'Print',
          }}
          onClick={print}
        />
      </div>
    </Fragment>
  );
};

DepositTicket.propTypes = {
  match: PropTypes.object.isRequired,
};

export default DepositTicket;
