import React from 'react';
import PropTypes from 'prop-types';
import './DataLabel.css';

const DataLabel = ({
  label,
  value,
  className,
  size,
  labelStyle,
  valueStyle,
  style,
}) => {
  const hasValue = value !== null && value !== undefined && value !== '';

  const compoundedStyle = {
    justifyContent: hasValue ? 'space-between' : 'center',
    ...style,
  };

  const compoundedLabelStyle = {
    marginRight: 8,
    ...labelStyle,
  };

  if (size === 'large') {
    return (
      <h2 className={`DataLabel ${className}`} style={compoundedStyle}>
        <span className="label" style={compoundedLabelStyle}>
          {label}
          {hasValue ? ': ' : ''}
        </span>
        <span style={valueStyle}>{value}</span>
      </h2>
    );
  }

  if (size === 'medium') {
    return (
      <h3 className={`DataLabel ${className}`} style={compoundedStyle}>
        <span className="label" style={compoundedLabelStyle}>
          {label}
          {hasValue ? ': ' : ''}
        </span>
        <span style={valueStyle}>{value}</span>
      </h3>
    );
  }

  return (
    <h4 className={`DataLabel ${className}`} style={compoundedStyle}>
      <span className="label" style={compoundedLabelStyle}>
        {label}
        {hasValue ? ': ' : ''}
      </span>
      <span style={valueStyle}>{value}</span>
    </h4>
  );
};

DataLabel.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
    PropTypes.element,
    PropTypes.func,
  ]),
  className: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  labelStyle: PropTypes.object,
  valueStyle: PropTypes.object,
  style: PropTypes.object,
};

DataLabel.defaultProps = {
  className: '',
  value: '',
  size: 'medium',
  labelStyle: {},
  valueStyle: {},
  style: {},
};

export default DataLabel;
