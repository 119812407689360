import createSagaMiddleware from 'redux-saga';
import { createBrowserHistory } from 'history';
import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import rootReducer from '../reducers';
import { initSagas } from '../initSagas';

export const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();

// // Really handy to debug issues with saga
// // Logs pre state, action and post action state
// const addLoggingToDispatch = (store) => {
//   const rawDispatch = store.dispatch;
//   return (action) => {
//     console.group(action.type);
//     console.log('prev state', store.getState());
//     console.log('action', action);
//     const returnValue = rawDispatch(action);
//     console.log('next state', store.getState());
//     console.groupEnd(action.type);
//     return returnValue;
//   };
// };

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore(preloadedState) {
  const store = createStore(
    rootReducer(history),
    preloadedState,
    composeEnhancers(applyMiddleware(sagaMiddleware, routerMiddleware(history))),
  );

  // store.dispatch = addLoggingToDispatch(store);
  initSagas(sagaMiddleware);

  return store;
}
