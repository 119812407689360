import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { SalutationPicker, StatePicker } from '../../Pickers';
import { FederalOfficePicker } from '../../Pickers/FederalOfficePicker';

export const CandidateForm = ({
  salutation = 'none',
  firstName = '',
  middleName = '',
  lastName = '',
  suffix = '',
  candidateFecId = '',
  employer = '',
  occupation = '',
  candidateOffice = '',
  candidateState = 0,
  candidateDistrict = '',
  handleChange,
}) => (
  <Fragment>
    <SalutationPicker
      onChange={handleChange('salutation')}
      selectedKey={salutation}
    />
    <TextField
      label="First Name"
      value={firstName}
      onChange={handleChange('firstName')}
      required
    />
    <TextField
      label="Middle Name"
      value={middleName}
      onChange={handleChange('middleName')}
    />
    <TextField
      label="Last Name"
      value={lastName}
      onChange={handleChange('lastName')}
      required
    />
    <TextField
      label="Suffix"
      value={suffix}
      onChange={handleChange('suffix')}
    />
    <TextField
      label="Employer"
      value={employer}
      onChange={handleChange('employer')}
    />
    <TextField
      label="Occupation"
      value={occupation}
      onChange={handleChange('occupation')}
    />
    <FederalOfficePicker
      selectedKey={candidateOffice}
      label="Candidate Office"
      onChange={handleChange('candidateOffice')}
    />
    <StatePicker
      selectedKey={candidateState}
      fieldLabel="Candidate State"
      onChange={handleChange('candidateState')}
    />
    <TextField
      label="Candidate District"
      value={candidateDistrict}
      onChange={handleChange('candidateDistrict')}
    />

    <TextField label="Candidate FEC ID" value={candidateFecId} onChange={handleChange('candidateFecId')} />
  </Fragment>
  );

CandidateForm.propTypes = {
  salutation: PropTypes.string,
  firstName: PropTypes.string,
  middleName: PropTypes.string,
  lastName: PropTypes.string,
  suffix: PropTypes.string,
  candidateFecId: PropTypes.string,
  employer: PropTypes.string,
  occupation: PropTypes.string,
  candidateOffice: PropTypes.string,
  candidateState: PropTypes.number,
  candidateDistrict: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
};
