import React from 'react';
import PropTypes from 'prop-types';
import {
  DefaultButton,
  PrimaryButton,
} from 'office-ui-fabric-react/lib/Button';
import {
  DetailsList,
  SelectionMode,
  CheckboxVisibility,
  DetailsListLayoutMode,
} from 'office-ui-fabric-react/lib/DetailsList';
import { Grid, GridRow, Column } from '../common';
import { formatCurrency } from '../../helpers/util';

export const IndebtednessSection = ({ isExpanded, actions, items }) => {
  const createColumns = () => {
    return [
      {
        key: 'election-cycle',
        name: 'Election',
        minWidth: 175,
        maxWidth: 200,
        onRender: item => (
          <span>{`${item.electionCycle} - ${item.electionYear}`}</span>
        ),
      },
      {
        key: 'beginning-indebtedness',
        name: 'Beginning Indebtedness',
        maxWidth: 150,
        onRender: item => (
          <span className="ms-textAlignRight">
            {formatCurrency(item.periodOpeningDebtAmount)}
          </span>
        ),
      },
      {
        key: 'loans-received',
        name: 'Loans Received',
        maxWidth: 150,
        onRender: item => (
          <span className="ms-textAlignRight">
            {formatCurrency(item.loansReceivedAmount)}
          </span>
        ),
      },
      {
        key: 'deferred-payment-of-expense',
        name: 'Deferred Payment of Expenses',
        minWidth: 175,
        maxWidth: 175,
        onRender: item => (
          <span className="ms-textAlignRight">
            {formatCurrency(item.deferredPaymentOfExpensesAmount)}
          </span>
        ),
      },
      {
        key: 'loan-payments',
        name: 'Loan Payments',
        maxWidth: 150,
        onRender: item => (
          <span className="ms-textAlignRight">
            {formatCurrency(item.loanPaymentsAmount)}
          </span>
        ),
      },
      {
        key: 'credits-received',
        name: 'Credit Received',
        maxWidth: 150,
        onRender: item => (
          <span className="ms-textAlignRight">
            {formatCurrency(item.creditReceivedAmount)}
          </span>
        ),
      },
      {
        key: 'payments-on-deferred-expenses',
        name: 'Payments on Deferred Expenses',
        minWidth: 175,
        maxWidth: 175,
        onRender: item => (
          <span className="ms-textAlignRight">
            {formatCurrency(item.paymentsOnPreviouslyDeferredExpensesAmount)}
          </span>
        ),
      },
      {
        key: 'closing-indebtedness',
        name: 'Closing Indebtedness',
        fieldName: '',
        maxWidth: 150,
        onRender: item => (
          <span className="ms-textAlignRight">
            {formatCurrency(item.periodClosingDebtAmount)}
          </span>
        ),
      },
    ];
  };

  const mapDebts = () =>
    items.map(i => ({
      ...i,
      key: i._id,
    }));

  return (
    <div className="indebtedness depth-1" name="indebtedness">
      <header onClick={actions.toggleExpand}>
        <h3>Indebtedness</h3>
      </header>
      <div className={`content${isExpanded ? '-expanded' : '-collapsed'}`}>
        <Grid>
          <GridRow>
            <Column>
              <DetailsList
                items={mapDebts()}
                columns={createColumns()}
                compact={false}
                selectionMode={SelectionMode.none}
                checkboxVisibility={CheckboxVisibility.none}
                layoutMode={DetailsListLayoutMode.fixed}
              />
            </Column>
          </GridRow>
        </Grid>
        <div className="section-actions">
          <DefaultButton
            text="Back"
            style={{ marginRight: 16 }}
            onClick={actions.previousSection}
          />
          <PrimaryButton text="Continue" onClick={actions.nextSection} />
        </div>
      </div>
    </div>
  );
};

IndebtednessSection.propTypes = {
  items: PropTypes.array,
  actions: PropTypes.object.isRequired,
  isExpanded: PropTypes.bool,
};

IndebtednessSection.defaultProps = {
  items: [],
  isExpanded: false,
};

export default IndebtednessSection;
