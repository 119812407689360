import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { Link } from 'office-ui-fabric-react/lib/Link';
import { types as actions } from '../../actions/userActions';

const AccountInactive = ({ history }) => {
  const reduxDispatch = useDispatch();

  return (
    <div className="inactive-account">
      <h3 style={{ color: '#fff' }}>This account is currently inactive</h3>
      <p>
        Bring the account back to active status by{' '}
        <Link onClick={() => history.push('/filer/editBillingInfo')}>
          updating payment information.
        </Link>
      </p>
      <DefaultButton
        text="Logout"
        onClick={() => {
          reduxDispatch({ type: actions.LOGOUT });
        }}
      />
    </div>
  );
};

AccountInactive.propTypes = {
  history: PropTypes.object.isRequired,
};

export default AccountInactive;
