import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import {
  DetailsList,
  SelectionMode,
  CheckboxVisibility,
  DetailsRow,
} from 'office-ui-fabric-react/lib/DetailsList';
import { LoanPanel } from '../Panels';
import { sortDirections } from '../../helpers/constants';
import {
  formatCurrency,
  formatDate,
  formatDateTime,
  sortByField,
} from '../../helpers/util';
import { onColumnClick, searchValueForTerm } from '../../helpers/listsHelper';

export class LoansList extends Component {
  static propTypes = {
    editItem: PropTypes.func.isRequired,
    loans: PropTypes.object,
    filterFn: PropTypes.func,
    session: PropTypes.object.isRequired,
    isHidden: PropTypes.bool,
  };

  static defaultProps = {
    loans: {
      loans: [],
    },
    filterFn: null,
    isHidden: false,
  };

  state = {
    filterText: '',
    sortField: 'displayName',
    sortDirection: sortDirections.ASC,
    showPanel: false,
    panelItem: null,
  };

  showPanel = item => {
    this.setState({
      showPanel: true,
      panelItem: item,
    });
  };

  hidePanel = () => {
    this.setState({
      showPanel: false,
      panelItem: null,
    });
  };

  createColumns = () => {
    const { sortField, sortDirection } = this.state;
    return [
      {
        key: 'type-of-lender',
        name: 'Type of Lender',
        fieldName: 'contactType',
        minWidth: 125,
        maxWidth: 200,
        isSorted:
          sortField === 'contactType' && sortDirection !== sortDirections.NONE,
        isSortedDescending: sortDirection === sortDirections.DESC,
        isResizable: true,
        onColumnClick: this.onColumnClick,
        onRender: item => <span>{item.contactType}</span>,
      },
      {
        key: 'lender-name',
        name: 'Lender Name',
        fieldName: 'displayName',
        minWidth: 200,
        maxWidth: 200,
        isSorted:
          sortField === 'displayName' &&
          sortDirection !== sortDirections.NONE,
        isSortedDescending: sortDirection === sortDirections.DESC,
        isResizable: true,
        onColumnClick: this.onColumnClick,
        onRender: item => <span>{item.displayName}</span>,
      },
      {
        key: 'amount',
        name: 'Amount',
        fieldName: 'amount',
        minWidth: 85,
        maxWidth: 175,
        isSorted:
          sortField === 'amount' && sortDirection !== sortDirections.NONE,
        isSortedDescending: sortDirection === sortDirections.DESC,
        isResizable: true,
        onColumnClick: this.onColumnClick,
        onRender: item => (
          <span style={{ textAlign: 'right', display: 'block' }}>
            {formatCurrency(item.amount)}
          </span>
        ),
      },
      {
        key: 'amount-of-payments',
        name: 'Payments',
        fieldName: 'cumulativePayment',
        minWidth: 85,
        maxWidth: 175,
        isSorted:
          sortField === 'cumulativePayment' && sortDirection !== sortDirections.NONE,
        isSortedDescending: sortDirection === sortDirections.DESC,
        isResizable: true,
        onColumnClick: this.onColumnClick,
        onRender: item => (
          <span style={{ textAlign: 'right', display: 'block' }}>
            {formatCurrency(item.cumulativePayment || 0)}
          </span>
        ),
      },
      {
        key: 'checkNumber',
        name: 'Check Number',
        fieldName: 'checkNumberSort',
        data: 'string',
        minWidth: 100,
        maxWidth: 110,
        isSorted:
          sortField === 'checkNumberSort' &&
          sortDirection !== sortDirections.NONE,
        isSortedDescending: sortDirection === sortDirections.DESC,
        isResizable: true,
        onColumnClick: this.onColumnClick,
        onRender: item => <span>{item.checkNumber}</span>,
      },
      {
        key: 'referenceId',
        name: 'Reference Number',
        fieldName: 'paymentReferenceId',
        minWidth: 100,
        maxWidth: 150,
        isSorted:
          sortField === 'paymentReferenceId' &&
          sortDirection !== sortDirections.NONE,
        isSortedDescending: sortDirection === sortDirections.DESC,
        isResizable: true,
        onColumnClick: this.onColumnClick,
        onRender: item => <span>{item.paymentReferenceId}</span>,
      },
      {
        key: 'date-received',
        name: 'Date Received',
        fieldName: 'loanDate',
        minWidth: 100,
        maxWidth: 125,
        isSorted:
          sortField === 'loanDate' && sortDirection !== sortDirections.NONE,
        isSortedDescending: sortDirection === sortDirections.DESC,
        isResizable: true,
        onColumnClick: this.onColumnClick,
        onRender: item => <span>{formatDate(item.loanDate)}</span>,
      },
      {
        key: 'date-entered-updated',
        name: 'Date Entered/Updated',
        fieldName: 'updatedAt',
        minWidth: 200,
        isSorted:
          sortField === 'updatedAt' && sortDirection !== sortDirections.NONE,
        isSortedDescending: sortDirection === sortDirections.DESC,
        isResizable: true,
        onColumnClick: this.onColumnClick,
        onRender: item => <span>{formatDateTime(item.updatedAt)}</span>,
      },
    ];
  };

  onColumnClick = (e, column) => {
    this.setState(onColumnClick(column.fieldName));
  };

  mapLoans = () => {
    const { filterText, sortDirection, sortField } = this.state;
    const {
      loans: { loans },
      filterFn,
    } = this.props;

    const searchTerm = filterText.toLowerCase();

    const results = loans
      .filter(
        c =>
          searchValueForTerm(c.displayName, searchTerm) ||
          searchValueForTerm(c.amount, searchTerm) ||
          searchValueForTerm(c.paymentReferenceId, searchTerm),
      )
      .filter(filterFn || (() => true))
      .map(c => ({
        ...c,
        key: c._id,
        checkNumber: c.checkNumber || '',
        paymentReferenceId: c.paymentReferenceId || '',
      }))
      .sort(sortByField(sortField, sortDirection));
    return results;
  };

  filterLoans = (e, value) => {
    this.setState({
      filterText: value,
    });
  };

  onRenderRow = props => (
    <div onClick={() => this.showPanel(props.item)}>
      <DetailsRow {...props} />
    </div>
  );

  render() {
    if (this.props.isHidden) {
      return null;
    }

    const { showPanel, panelItem } = this.state;
    const { session } = this.props;

    return (
      <Fragment>
        <TextField
          placeHolder="Filter Loans by Lender Name"
          onChange={this.filterLoans}
        />
        <DetailsList
          items={this.mapLoans()}
          columns={this.createColumns()}
          checkboxVisibility={CheckboxVisibility.none}
          compact={false}
          selectionMode={SelectionMode.none}
          onRenderRow={this.onRenderRow}
        />
        <LoanPanel
          item={panelItem}
          showPanel={showPanel}
          closePanel={this.hidePanel}
          editItem={this.props.editItem}
          session={session}
        />
      </Fragment>
    );
  }
}

export default LoansList;
