import { call, takeEvery, put } from 'redux-saga/effects';
import axios from 'axios';
import { types } from '../actions/electionCycleActions';
import { getServerSideErrorMessage, sortByField } from '../helpers/util';

export function* handleGetElectionCycles() {
  try {
    const {
      data: { electionCycles: cycles },
    } = yield call(axios.get, '/api/account/electionCycles', {
      withCredentials: true,
    });
    yield put({
      type: types.GET_ELECTION_CYCLES_SUCCESS,
      cycles: cycles.sort(sortByField('order')),
    });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put({ type: types.GET_ELECTION_CYCLES_FAILURE, error });
  }
}

export function* getElectionCycles() {
  yield takeEvery(types.GET_ELECTION_CYCLES, handleGetElectionCycles);
}
