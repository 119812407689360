import { createReducer } from '../../reducers/createReducer';
import { filterOutSystemTags, deserializeDate } from '../../helpers/util';

export const initialState = {
  errors: {
    electionYearError: '',
    electionError: '',
    otherElectionTypeError: '',
    paymentTypeError: '',
    loanDateError: '',
    firstNameError: '',
    lastNameError: '',
    businessNameError: '',
    interestRateError: '',
    dueDateError: '',
    outstandingBalanceError: '',
    cumulativePaymentToDateError: '',
    isPersonalFundsOfCandidateError: '',
    isSecuredError: '',
    originalLoanDateError: '',
    drawAmountError: '',
    emailError: '',
    otherPartiesSecondarilyLiableError: '',
    collateralPledgedDescriptionError: '',
    collateralPledgedEstimatedValueError: '',
    lenderPerfectedSecurityError: '',
    areFutureContributionsOrInterestIncomePledgedError: '',
    futureContributionsOrInterestEstimatedValueError: '',
    depositoryAccountLocationError: '',
    depositoryAccountAddressLine1Error: '',
    depositoryAccountCityError: '',
    depositoryAccountStateError: '',
    depositoryAccountZipCodeError: '',
    selectedContactError: '',
    missingDepositoryAccountError: '',
    fecIdError: '',
  },
  candidateOffice: '',
  candidateState: 0,
  candidateDistrict: '',
  candidateFecId: '',
  candidateFirstName: '',
  candidateMiddleName: '',
  candidateLastName: '',
  committeeFecId: '',
  selectedContact: null,
  partyDialogHidden: true,
  loanId: '',
  contactId: '',
  salutation: 'none',
  businessName: '',
  businessType: 0,
  contactName: '',
  firstName: '',
  middleName: '',
  lastName: '',
  suffix: '',
  employer: '',
  occupation: '',
  committeeAffiliation: 0,
  transactionIdNumber: '',
  isCandidate: false,
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: 0,
  zipCode: '',
  county: '',
  email: '',
  phone1: '',
  phone2: '',

  selectedParty: null,
  election: 0,
  electionYear: 0,
  otherElectionType: '',
  contactType: 0,
  paymentType: 0,
  forceItemization: false,
  tags: '',
  isReportable: false,

  amount: '',
  loanDate: '',
  description: '',
  referenceId: '',
  checkNumber: '',
  cardholderName: '',
  cardholderEmployer: '',
  cardholderOccupation: '',
  cardLast4: '',
  cardType: 0,

  interestRate: '',
  dueDate: '',
  outstandingBalance: '',
  cumulativePaymentToDate: '',
  isPersonalFundsOfCandidate: false,
  isSecured: null,

  isLoanRestructured: false,
  originalLoanDate: '',
  isLineOfCredit: false,
  drawAmount: '',
  otherPartiesSecondarilyLiable: null,
  isCollateralPledged: null,
  collateralPledgedDescription: '',
  collateralPledgedEstimatedValue: '',
  lenderPerfectedSecurity: null,
  areFutureContributionsOrInterestIncomePledged: null,
  futureContributionsOrInterestDescription: '',
  futureContributionsOrInterestEstimatedValue: '',
  loanBasisDescription: '',
  treasurerDateSigned: '',

  depositoryAccount: {
    _id: '',
    location: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: 0,
    zipCode: '',
    county: '',
    establishedDate: '',
  },
  authorizedRepresentative: {
    prefix: 'none',
    firstName: '',
    middleName: '',
    lastName: '',
    suffix: '',
    title: '',
    dateSigned: '',
  },

  responsibleParties: [],
};

export const actions = {
  GET_LOAN_SUCCESS: 'GET_LOAN_SUCCESS',
  CLEAR_FIELDS_FOR_NEW: 'CLEAR_FIELDS_FOR_NEW',
  HANDLE_CHANGE: 'HANDLE_CHANGE',
  HANDLE_CHANGE_AUTHORIZED_REP: 'HANDLE_CHANGE_AUTHORIZED_REP',
  CLEAR_DEPOSITORY_ACCOUNT: 'CLEAR_DEPOSITORY_ACCOUNT',
  HANDLE_CHANGE_NO_FUTURE_CONTRIBUTIONS:
    'HANDLE_CHANGE_NO_FUTURE_CONTRIBUTIONS,',
  HANDLE_CHANGE_DEPOSITORY_ACCOUNT: 'HANDLE_CHANGE_DEPOSITORY_ACCOUNT',
  SET_ERRORS: 'SET_ERRORS',
  OPEN_PARTY_DIALOG: 'OPEN_PARTY_DIALOG',
  CLOSE_PARTY_DIALOG: 'CLOSE_PARTY_DIALOG',
  CLEAR_PARTY_ADD_NEW: 'CLEAR_PARTY_ADD_NEW',
  SELECT_PARTY: 'SELECT_PARTY',
  ADD_PARTY: 'ADD_PARTY',
  EDIT_PARTY: 'EDIT_PARTY',
  DELETE_PARTY: 'DELETE_PARTY',
  CLEAR_SELECTED_CONTACT: 'CLEAR_SELECTED_CONTACT',
  ON_CONTACT_SELECTED: 'ON_CONTACT_SELECTED',
  RESET_SELECTED_CONTACT_INFO: 'RESET_SELECTED_CONTACT_INFO',
  SET_EXISTING_DEPOSITORY_ACCOUNT: 'SET_EXISTING_DEPOSITORY_ACCOUNT',
  RESET_DEPOSITORY_ACCOUNT: 'RESET_DEPOSITORY_ACCOUNT',
};

export const actionMap = {
  [actions.GET_LOAN_SUCCESS]: (
    state,
    { data: { loan, electionYear, isReportable = false } },
  ) => {
    return {
      ...state,
      // form state
      selectedContact: {
        ...loan.selectedContact,
        _id: loan.contactId,
        address: {
          ...loan.address,
        },
      },
      electionYear:
        typeof electionYear === 'string'
          ? parseInt(electionYear, 10)
          : electionYear,
      election: loan.electionCycle,
      transactionIdNumber: loan.transactionIdNumber,
      otherElectionType: loan.electionCycleOtherDescription || '',
      isReportable,
      amount: loan.amount || '',
      loanDate: deserializeDate(loan.loanDate),
      description: loan.description || '',
      referenceId: loan.paymentReferenceId || '',
      paymentType: loan.paymentType || 0,
      checkNumber: loan.checkNumber || '',
      contactType: loan.contactType || 0,
      forceItemization: loan.forceItemize || false,
      tags: filterOutSystemTags(loan.tags),
      cardholderName: loan.cardholderName || '',
      cardholderEmployer: loan.cardholderEmployer || '',
      cardholderOccupation: loan.cardholderOccupation || '',
      cardLast4: loan.cardLast4 || '',
      cardType: loan.cardType || 0,
      // individual contact
      loanId: loan._id,
      contactId: loan.contactId,
      committeeFecId: loan.selectedContact.committeeFecId || loan.committeeFecId || '',
      candidateOffice: loan.selectedContact.candidateOffice || loan.candidateOffice || '',
      candidateState: loan.selectedContact.candidateState || loan.candidateState || 0,
      candidateDistrict: loan.selectedContact.candidateDistrict || loan.candidateDistrict || '',
      candidateFecId: loan.selectedContact.candidateFecId || loan.candidateFecId || '',
      candidateFirstName: loan.selectedContact.candidateFirstName || loan.candidateFirstName || '',
      candidateMiddleName: loan.selectedContact.candidateMiddleName || loan.candidateMiddleName || '',
      candidateLastName: loan.selectedContact.candidateLastName || loan.candidateLastName || '',
      salutation: loan.selectedContact.salutation || 'none',
      firstName: loan.selectedContact.firstName || '',
      middleName: loan.selectedContact.middleName || '',
      lastName: loan.selectedContact.lastName || '',
      suffix: loan.selectedContact.suffix || '',
      employer: loan.selectedContact.employer || '',
      occupation: loan.selectedContact.occupation || '',
      // businessContact
      businessName: loan.selectedContact.businessName || '',
      businessType: loan.selectedContact.businessType || 0,
      contactName: loan.selectedContact.contactName || '',
      // contact address, email, and phones
      addressLine1: loan.address?.addressLine1 || '',
      addressLine2: loan.address?.addressLine2 || '',
      city: loan.address?.city || '',
      state: loan.address?.state || 0,
      zipCode: loan.address?.zipCode || '',
      county: loan.address?.county || '',
      email: loan.selectedContact.email || '',
      phone1: loan.selectedContact.phone1 || '',
      phone2: loan.selectedContact.phone2 || '',
      // federal loan common fields
      interestRate: loan.interestRate || 0,
      dueDate: loan.dueDate ? deserializeDate(loan.dueDate) : null,
      outstandingBalance: loan.outstandingBalance || 0,
      // federal loan individual
      cumulativePaymentToDate: loan.cumulativePayment || 0,
      isPersonalFundsOfCandidate: loan.isPersonalFundsOfCandidate || false,
      isSecured: loan.isSecured !== undefined ? loan.isSecured : null,
      // federal business loan
      isLoanRestructured:
        loan.isLoanRestructured !== undefined ? loan.isLoanRestructured : false,
      originalLoanDate: loan.originalLoanDate
        ? deserializeDate(loan.originalLoanDate)
        : null,
      isLineOfCredit:
        loan.isLineOfCredit !== undefined ? loan.isLineOfCredit : false,
      drawAmount: loan.drawAmount || '',
      otherPartiesSecondarilyLiable:
        loan.otherPartiesSecondarilyLiable !== undefined
          ? loan.otherPartiesSecondarilyLiable
          : null,
      isCollateralPledged:
        loan.isCollateralPledged !== undefined
          ? loan.isCollateralPledged
          : null,
      collateralPledgedDescription: loan.collateralPledgedDescription || '',
      collateralPledgedEstimatedValue:
        loan.collateralPledgedEstimatedValue || '',
      lenderPerfectedSecurity:
        loan.lenderPerfectedSecurity !== undefined
          ? loan.lenderPerfectedSecurity
          : null,
      areFutureContributionsOrInterestIncomePledged:
        loan.areFutureContributionsOrInterestIncomePledged !== undefined
          ? loan.areFutureContributionsOrInterestIncomePledged
          : null,
      futureContributionsOrInterestDescription:
        loan.futureContributionsOrInterestDescription || '',
      futureContributionsOrInterestEstimatedValue:
        loan.futureContributionsOrInterestEstimatedValue || '',
      loanBasisDescription: loan.loanBasisDescription || '',
      treasurerDateSigned: deserializeDate(loan.treasurerDateSigned),
      // depositoryAccount
      depositoryAccount: {
        ...state.depositoryAccount,
        _id: loan.depositoryAccount?._id || '',
        location: loan.depositoryAccount?.location || '',
        addressLine1: loan.depositoryAccount?.address?.addressLine1 || '',
        addressLine2: loan.depositoryAccount?.address?.addressLine2 || '',
        city: loan.depositoryAccount?.address?.city || '',
        state: loan.depositoryAccount?.address?.state || 0,
        zipCode: loan.depositoryAccount?.address?.zipCode || '',
        county: loan.depositoryAccount?.address?.county || '',
        establishedDate: loan.depositoryAccount?.establishedDate
          ? deserializeDate(loan.depositoryAccount?.establishedDate)
          : null,
      },
      // authorized representative
      authorizedRepresentative: {
        ...state.authorizedRepresentative,
        prefix: loan.authorizedRepresentative?.prefix || 'none',
        firstName: loan.authorizedRepresentative?.firstName || '',
        middleName: loan.authorizedRepresentative?.middleName || '',
        lastName: loan.authorizedRepresentative?.lastName || '',
        suffix: loan.authorizedRepresentative?.suffix || '',
        title: loan.authorizedRepresentative?.title || '',
        dateSigned: loan.authorizedRepresentative?.dateSigned
          ? deserializeDate(loan.authorizedRepresentative.dateSigned)
          : null,
      },
      responsibleParties: (loan.responsibleParties || []).map(rp => {
        const { address = {}, ...rpDetails } = rp;
        return {
          ...rpDetails,
          contactType: rp.contactType || 0,
          addressLine1: address.addressLine1 || '',
          addressLine2: address.addressLine2 || '',
          city: address.city || '',
          state: address.state || 0,
          zipCode: address.zipCode || '',
          county: address.county || '',
          email: rp.email || '',
          phone1: rp.phone1 || '',
          phone2: rp.phone2 || '',
          salutation: rp.salutation || 'none',
        };
      }),
    };
  },
  [actions.CLEAR_FIELDS_FOR_NEW]: state => ({
    ...initialState,
    election: state.election,
    electionYear: state.electionYear,
  }),
  [actions.HANDLE_CHANGE]: (state, { data: { fieldName, value } }) => {
    if (fieldName === 'election') {
      return {
        ...state,
        [fieldName]: value,
        otherElectionType: ['Other', 'Special'].includes(value)
          ? state.otherElectionType
          : '',
      };
    }

    if (fieldName === 'contactType') {
      const currentLenderType = state.contactType;
      return {
        ...state,
        [fieldName]: value,
        errors: {
          ...(currentLenderType === value
            ? state.errors
            : {
                ...state.errors,
                firstNameError: '',
                lastNameError: '',
                businessNameError: '',
              }),
        },
      };
    }

    return {
      ...state,
      [fieldName]: value,
    };
  },
  [actions.HANDLE_CHANGE_AUTHORIZED_REP]: (
    state,
    { data: { fieldName, value } },
  ) => ({
    ...state,
    authorizedRepresentative: {
      ...state.authorizedRepresentative,
      [fieldName]: value,
    },
  }),
  [actions.CLEAR_DEPOSITORY_ACCOUNT]: state => ({
    ...state,
    depositoryAccount: {
      ...initialState.depositoryAccount,
    },
  }),
  [actions.HANDLE_CHANGE_NO_FUTURE_CONTRIBUTIONS]: (
    state,
    { data: { fieldName, value } },
  ) => ({
    ...state,
    [fieldName]: value,
    futureContributionsOrInterestDescription: '',
    futureContributionsOrInterestEstimatedValue: '',
  }),
  [actions.HANDLE_CHANGE_DEPOSITORY_ACCOUNT]: (
    state,
    { data: { fieldName, value } },
  ) => ({
    ...state,
    depositoryAccount: {
      ...state.depositoryAccount,
      [fieldName]: value,
    },
  }),
  [actions.SET_ERRORS]: (state, { data: { errors } }) => ({
    ...state,
    errors: {
      ...state.errors,
      ...errors,
    },
  }),
  [actions.OPEN_PARTY_DIALOG]: state => ({
    ...state,
    partyDialogHidden: false,
  }),
  [actions.CLOSE_PARTY_DIALOG]: state => ({
    ...state,
    partyDialogHidden: true,
    selectedParty: null,
  }),
  [actions.CLEAR_PARTY_ADD_NEW]: state => ({
    ...state,
    selectedParty: null,
  }),
  [actions.SELECT_PARTY]: (state, { data: { id } }) => {
    const selectedParty = state.responsibleParties.find(rp => rp._id === id);
    return {
      ...state,
      selectedParty,
      partyDialogHidden: false,
    };
  },
  [actions.ADD_PARTY]: (state, { data: { newParty, _id } }) => {
    return {
      ...state,
      responsibleParties: [
        ...state.responsibleParties,
        {
          ...newParty,
          _id,
          isNew: true,
          removed: false,
        },
      ],
    };
  },
  [actions.EDIT_PARTY]: (state, { data: { updatedParty } }) => ({
    ...state,
    responsibleParties: state.responsibleParties.map(rp => {
      if (rp._id === updatedParty._id) {
        rp = {
          ...rp,
          ...updatedParty,
        };
      }
      return rp;
    }),
    selectedParty: null,
  }),
  [actions.DELETE_PARTY]: (state, { data: { id } }) => ({
    ...state,
    responsibleParties: state.responsibleParties.map(rp => {
      if (rp._id === id) {
        rp.removed = true;
      }
      return rp;
    }),
  }),
  [actions.CLEAR_SELECTED_CONTACT]: state => ({
    ...state,
    contactId: '',
    salutation: 'none',
    firstName: '',
    middleName: '',
    lastName: '',
    suffix: '',
    employer: '',
    occupation: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: 0,
    zipCode: '',
    county: '',
    businessName: '',
    businessType: 0,
    contactName: '',
    email: '',
    phone1: '',
    phone2: '',
    selectedContact: null,
    contactType: 0,
  }),
  [actions.ON_CONTACT_SELECTED]: (state, { data: { contact } }) => {
    const {
      address: contactAddress,
      _id,
      contactId,
      salutation,
      businessType,
      tags,
      ...rest
    } = contact;

    return {
      ...state,
      selectedContact: {
        ...contact,
        salutation: salutation || 'none',
        businessType: businessType || 0,
        address: {
          ...contact.address,
          state: (contact.address || {}).state || 0,
        },
      },
      contactId: _id,
      contactType: contact.contactType,
      ...contactAddress,
      ...rest,
      salutation: salutation || 'none',
      businessType: businessType || 0,
      description: state.description || contact.purpose || '',
      tags: filterOutSystemTags(tags),
      transactionIdNumber: state.transactionIdNumber,
    };
  },
  [actions.RESET_SELECTED_CONTACT_INFO]: state => {
    if (state.selectedContact) {
      return {
        ...state,
        salutation: state.selectedContact.salutation || 'none',
        firstName: state.selectedContact.firstName || '',
        middleName: state.selectedContact.middleName || '',
        lastName: state.selectedContact.lastName || '',
        suffix: state.selectedContact.suffix || '',
        employer: state.selectedContact.employer || '',
        occupation: state.selectedContact.occupation || '',
        addressLine1: state.selectedContact.address?.addressLine1 || '',
        addressLine2: state.selectedContact.address?.addressLine2 || '',
        city: state.selectedContact.address?.city || '',
        state: state.selectedContact.address?.state || 0,
        zipCode: state.selectedContact.address?.zipCode || '',
        county: state.selectedContact.address?.county || '',
        phone1: state.selectedContact.phone1 || '',
        phone2: state.selectedContact.phone2 || '',
        email: state.selectedContact.email || '',
        businessName: state.selectedContact.businessName || '',
        businessType: state.selectedContact.businessType || 0,
        contactName: state.selectedContact.contactName || '',
      };
    }

    return {
      ...state,
      contactType: 0,
      salutation: 'none',
      firstName: '',
      middleName: '',
      lastName: '',
      suffix: '',
      employer: '',
      occupation: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: 0,
      zipCode: '',
      county: '',
      phone1: '',
      phone2: '',
      email: '',
      businessName: '',
      businessType: 0,
      contactName: '',
    };
  },
  [actions.SET_EXISTING_DEPOSITORY_ACCOUNT]: (
    state,
    { data: { account } },
  ) => ({
    ...state,
    depositoryAccount: {
      ...state.depositoryAccount,
      ...account,
    },
  }),
  [actions.RESET_DEPOSITORY_ACCOUNT]: (state, { data: { originalData } }) => ({
    ...state,
    depositoryAccount: {
      ...state.depositoryAccount,
      ...originalData,
    },
  }),
};

export const loanFormReducer = createReducer(initialState, actionMap);
