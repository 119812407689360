import { takeLatest, put, call, take } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import axios from 'axios';
import {
  getServerSideErrorMessage,
  errorToast,
  successToast,
} from '../helpers/util';

import { types as importTypes } from '../actions/importActions';

function isAgendaJobComplete(totalSeconds, pause, id) {
  return eventChannel(emitter => {
    const iv = setInterval(() => {
      totalSeconds -= pause;
      if (totalSeconds > 0) {
        axios
          .get(`/api/jobs/getCompletedJob/${id}`)
          .then(res => {
            if (res.data.isComplete) {
              emitter({ ...res.data });
            }
          })
          .catch(error => {
            emitter({ error });
          });
      } else {
        // this causes the channel to close
        emitter(false);
      }
    }, pause * 1000);
    // The subscriber must return an unsubscribe function
    return () => {
      clearInterval(iv);
    };
  });
}

export function* handleGetUnprocessedEarmarkImports() {
  try {
    yield put({ type: importTypes.GET_UNPROCESSED_EARMARKS_SUMMARY_PROCESSING });
    const data = yield call(axios.get, '/api/import/aristotle/earmarks', {
      withCredentials: true,
    });

    yield put({
      type: importTypes.GET_UNPROCESSED_EARMARKS_SUMMARY_SUCCESS,
      data,
    });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put(errorToast(error));
  }
}

export function* getUnprocessedEarmarkImports() {
  yield takeLatest(importTypes.GET_UNPROCESSED_EARMARKS_SUMMARY, handleGetUnprocessedEarmarkImports);
}

export function* handleApplyEarmarkImportRecords(action) {
  try {
    const { data: { fileHash, payload } } = action;
    yield put({ type: importTypes.APPLY_EARMARK_IMPORT_RECORDS_PROCESSING });
    const { data } = yield call(axios.post, `/api/import/aristotle/earmarks/applyUnprocessed/${fileHash}`, payload, {
      withCredentials: true,
    });

    yield put({
      type: importTypes.APPLY_EARMARK_IMPORT_RECORDS_SUCCESS,
    });
    yield put({ type: importTypes.BACKGROUND_EARMARK_IMPORT_RECORDS_PROCESSING });
    const channel = yield call(isAgendaJobComplete, 60 * 30, 2, data.taskId);
    const channelResult = yield take(channel);
    channel.close();
    if (channelResult.isFailed) {
      yield put({
        type: importTypes.BACKGROUND_EARMARK_IMPORT_RECORDS_FAILURE,
        error: channelResult.failReason,
      });
    } else {
      yield put({
        type: importTypes.BACKGROUND_EARMARK_IMPORT_RECORDS_SUCCESS,
        data: channelResult,
      });
    }
    yield put(successToast('Successfully imported spreadsheet records.'));
    yield put({ type: importTypes.GET_UNPROCESSED_EARMARKS_SUMMARY });
  } catch (e) {
    yield put({ type: importTypes.APPLY_EARMARK_IMPORT_RECORDS_FAILURE });
    const error = getServerSideErrorMessage(e);
    yield put(errorToast(error));
  }
}

export function* applyEarmarkImportRecords() {
  yield takeLatest(importTypes.APPLY_EARMARK_IMPORT_RECORDS, handleApplyEarmarkImportRecords);
}

export function* handleRemoveEarmarkImportRecords(action) {
  try {
    const { data: { fileHash } } = action;
    yield put({ type: importTypes.REMOVE_EARMARK_IMPORT_RECORDS_PROCESSING });
    yield call(axios.delete, `/api/import/aristotle/earmarks/${fileHash}`, {
      withCredentials: true,
    });

    yield put({
      type: importTypes.REMOVE_EARMARK_IMPORT_RECORDS_SUCCESS,
      data: fileHash,
    });
    yield put({ type: importTypes.GET_UNPROCESSED_EARMARKS_SUMMARY });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put(errorToast(error));
  }
}

export function* removeEarmarkImportRecords() {
  yield takeLatest(importTypes.REMOVE_EARMARK_IMPORT_RECORDS, handleRemoveEarmarkImportRecords);
}

export function* handleUploadEarmarkSpreadsheet(action) {
  try {
    const { data: spreadsheet } = action;

    yield put({ type: importTypes.UPLOAD_EARMARK_SPREADSHEET_PROCESSING });
    yield call(axios.post, '/api/import/aristotle/earmarks/upload', spreadsheet, {
      withCredentials: true,
    });

    yield put({ type: importTypes.UPLOAD_EARMARK_SPREADSHEET_SUCCESS });
    yield put({ type: importTypes.GET_UNPROCESSED_EARMARKS_SUMMARY });
  } catch (e) {
    yield put({ type: importTypes.UPLOAD_EARMARK_SPREADSHEET_FAILURE });
    const error = getServerSideErrorMessage(e);
    yield put(errorToast(error));
  }
}

export function* uploadEarmarkSpreadsheet() {
  yield takeLatest(importTypes.UPLOAD_EARMARK_SPREADSHEET, handleUploadEarmarkSpreadsheet);
}
