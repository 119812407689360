import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Panel, PanelType } from 'office-ui-fabric-react/lib/Panel';
import { Dropdown } from 'office-ui-fabric-react/lib/Dropdown';
import { ActionButton } from 'office-ui-fabric-react/lib/Button';
import { Grid, GridRow, Column, MoneyField } from '../common';
import { DatePicker } from '../Pickers';
import { getLabel } from '../../helpers/labelHelper';
import { CaretDown } from '../icons';

export default class FilterLedgerPanel extends Component {
  static propTypes = {
    showPanel: PropTypes.bool,
    closePanel: PropTypes.func.isRequired,
    electionCycles: PropTypes.array.isRequired,
    electionCycleFilters: PropTypes.array.isRequired,
    transactionTypeFilters: PropTypes.array.isRequired,
    entityTypeFilters: PropTypes.array.isRequired,
    paymentTypeFilters: PropTypes.array.isRequired,
    startDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    endDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    startAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    endAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    actions: PropTypes.object.isRequired,
    session: PropTypes.object.isRequired,
  };

  static defaultProps = {
    showPanel: false,
    startDate: null,
    endDate: null,
    startAmount: 0,
    endAmount: 0,
  };

  render() {
    const {
      showPanel,
      closePanel,
      electionCycles,
      electionCycleFilters,
      transactionTypeFilters,
      entityTypeFilters,
      paymentTypeFilters,
      startDate,
      endDate,
      startAmount,
      endAmount,
      actions,
      session,
    } = this.props;

    return (
      <Panel
        isOpen={showPanel}
        onDismiss={closePanel}
        type={PanelType.medium}
        isLightDismiss
      >
        <ActionButton
          onClick={actions.resetFilters}
          iconProps={{ iconName: 'Filter' }}
        >
          Reset
        </ActionButton>
        <Grid>
          <GridRow>
            <Column>
              <Dropdown
                onRenderCaretDown={() => <CaretDown />}
                label="Election"
                selectedKeys={electionCycleFilters}
                options={[
                  { key: 0, text: 'Select' },
                  ...electionCycles.map(ec => ({
                    key: ec.text,
                    text: ec.text,
                  })),
                ]}
                multiSelect
                onChange={actions.onChangeMultiSelect('electionCycleFilters')}
              />
            </Column>
          </GridRow>
          <GridRow>
            <Column>
              <Dropdown
                onRenderCaretDown={() => <CaretDown />}
                label="Transaction Type"
                selectedKeys={transactionTypeFilters}
                options={[
                  {
                    key: 0,
                    text: 'Select',
                  },
                  {
                    key: 'Adjustment',
                    text: 'Adjustment',
                  },
                  {
                    key: 'Contribution',
                    text: 'Contribution',
                  },
                  {
                    key: 'Expenditure',
                    text: getLabel('Expenditure', session),
                  },
                  {
                    key: 'Loan',
                    text: 'Loan',
                  },
                ]}
                multiSelect
                onChange={actions.onChangeMultiSelect('transactionTypeFilters')}
              />
            </Column>
          </GridRow>
          <GridRow>
            <Column>
              <Dropdown
                onRenderCaretDown={() => <CaretDown />}
                label="Entity Type"
                selectedKeys={entityTypeFilters}
                options={[
                  {
                    key: 0,
                    text: 'Select',
                  },
                  {
                    key: 'Individual',
                    text: 'Individual',
                  },
                  {
                    key: 'Business',
                    text: 'Business',
                  },
                  {
                    key: 'Committee',
                    text: 'Committee',
                  },
                  {
                    key: 'Common Source',
                    text: 'Common Source',
                  },
                ]}
                multiSelect
                onChange={actions.onChangeMultiSelect('entityTypeFilters')}
              />
            </Column>
          </GridRow>
          <GridRow>
            <Column>
              <Dropdown
                onRenderCaretDown={() => <CaretDown />}
                label="Payment Type"
                selectedKeys={paymentTypeFilters}
                options={[
                  { key: 0, text: 'Select' },
                  {
                    key: 'Cash',
                    text: 'Cash',
                  },
                  {
                    key: 'Check',
                    text: 'Check',
                  },
                  {
                    key: 'Credit Card',
                    text: 'Credit Card',
                  },
                  {
                    key: 'Debit Card',
                    text: 'Debit Card',
                  },
                  {
                    key: 'Wire Transfer',
                    text: 'Wire Transfer',
                  },
                  {
                    key: 'ACH',
                    text: 'ACH',
                  },
                  {
                    key: 'Other',
                    text: 'Other',
                  },
                ]}
                multiSelect
                onChange={actions.onChangeMultiSelect('paymentTypeFilters')}
              />
            </Column>
          </GridRow>
          <GridRow>
            <Column sm={6}>
              <DatePicker
                label="Transaction Date Minimum"
                value={
                  startDate ||
                  new Date(
                    new Date().getFullYear(),
                    new Date().getMonth(),
                    new Date().getDate(),
                  )
                }
                onChange={actions.onChangeDate('startDate')}
              />
            </Column>
            <Column sm={6}>
              <DatePicker
                label="Transaction Date Maximum"
                value={
                  endDate ||
                  new Date(
                    new Date().getFullYear(),
                    new Date().getMonth(),
                    new Date().getDate(),
                    23,
                    59,
                    59,
                    999,
                  )
                }
                onChange={actions.onChangeDate('endDate')}
              />
            </Column>
          </GridRow>
          <GridRow>
            <Column sm={6}>
              <MoneyField
                value={startAmount}
                onChange={actions.onChange('startAmount')}
                label="Minimum Amount"
              />
            </Column>
            <Column sm={6}>
              <MoneyField
                value={endAmount}
                onChange={actions.onChange('endAmount')}
                label="Maximum Amount"
              />
            </Column>
          </GridRow>
        </Grid>
      </Panel>
    );
  }
}
