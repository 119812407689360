const types = {
  NEXT_STEP: 'NEXT_STEP',
  PREVIOUS_STEP: 'PREVIOUS_STEP',
  CREATE_CAMPAIGN: 'CREATE_CAMPAIGN',
  CREATE_CAMPAIGN_SUCCESS: 'CREATE_CAMPAIGN_SUCCESS',
  CREATE_CAMPAIGN_FAILURE: 'CREATE_CAMPAIGN_FAILURE',
  CREATE_CAMPAIGN_PROCESSING: 'CREATE_CAMPAIGN_PROCESING',
  CREATE_COMMITTEE_CAMPAIGN: 'CREATE_COMMITTEE_CAMPAIGN',
  CREATE_COMMITTEE_CAMPAIGN_SUCCESS: 'CREATE_COMMITTEE_CAMPAIGN_SUCCESS',
  CREATE_COMMITTEE_CAMPAIGN_FAILURE: 'CREATE_COMMITTEE_CAMPAIGN_FAILURE',
  CREATE_FEDERAL_PAC_CAMPAIGN: 'CREATE_FEDERAL_PAC_CAMPAIGN',
  CREATE_FEDERAL_PAC_CAMPAIGN_SUCCESS: 'CREATE_FEDERAL_PAC_CAMPAIGN_SUCCESS',
  CREATE_FEDERAL_PAC_CAMPAIGN_FAILURE: 'CREATE_FEDERAL_PAC_CAMPAIGN_FAILURE',
};

const actions = {
  nextStep(promoCode = null) {
    return { type: types.NEXT_STEP, promoCode };
  },

  previousStep(promoCode = null) {
    return { type: types.PREVIOUS_STEP, promoCode };
  },

  createCampaign(payload) {
    return { type: types.CREATE_CAMPAIGN, payload };
  },

  createCommitteeCampaign(payload) {
    return { type: types.CREATE_COMMITTEE_CAMPAIGN, payload };
  },

  createFederalPacCampaign(payload) {
    return { type: types.CREATE_FEDERAL_PAC_CAMPAIGN, payload };
  },
};

export { types, actions };
