import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'office-ui-fabric-react/lib/Dropdown';
import { getOptions } from '../../helpers/contactHelper';
import { CaretDown } from '../icons';

const ContactTypePicker = ({
  selectedKey,
  onChange,
  errorMessage,
  required,
  className,
  session,
}) => {
  const options = getOptions(session);
  return (
    <Dropdown
      onRenderCaretDown={() => <CaretDown />}
      className={className}
      errorMessage={errorMessage}
      label="Contact Type"
      required={required}
      placeholder="Select"
      selectedKey={selectedKey}
      onChange={onChange}
      options={[{ key: '0', text: 'Select' }, ...options]}
      dropdownWidth="auto"
    />);
};

ContactTypePicker.propTypes = {
  selectedKey: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  required: PropTypes.bool,
  className: PropTypes.string,
  session: PropTypes.object.isRequired,
};

ContactTypePicker.defaultProps = {
  errorMessage: '',
  required: false,
  className: '',
};

export default ContactTypePicker;
