import React from 'react';
import PropTypes from 'prop-types';
import { ActionButton } from 'office-ui-fabric-react/lib/Button';
import { Callout, DirectionalHint } from 'office-ui-fabric-react/lib/Callout';
import './WarningBox.css';

const WarningBox = ({ onDismiss, target, onAccept }) => (
  <Callout
    onDismiss={onDismiss}
    target={target}
    coverTarget
    directionHint={DirectionalHint.leftCenter}
    isBeakVisible={false}
    gapSpace={0}
  >
    <div className="warning-box-outer">
      <p>
        Editing these values can affect the outcome of future reports. Edit at
        your own risk!
      </p>
      <div className="warning-actions">
        <ActionButton
          text="Cancel"
          onClick={onDismiss}
          iconProps={{ iconName: 'Times' }}
        />
        <ActionButton
          text="Proceed"
          onClick={onAccept}
          iconProps={{ iconName: 'Check' }}
        />
      </div>
    </div>
  </Callout>
);

WarningBox.propTypes = {
  target: PropTypes.object.isRequired,
  onDismiss: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
};

export default WarningBox;
