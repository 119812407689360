import React from 'react';
import PropTypes from 'prop-types';
import {
  Panel,
  PanelType,
  Separator,
  PrimaryButton,
} from 'office-ui-fabric-react';
import { Grid, GridRow, Column, DataLabel } from '../common';
import { formatCurrency, formatDate } from '../../helpers/util';
import { disbursementCategories } from '../../helpers/constants';
import { getLabel } from '../../helpers/labelHelper';

const address = item => {
  if (!item.address) {
    return null;
  }

  item.address = {
    addressLine1: item.address.addressLine1
      ? item.address.addressLine1 + ', '
      : '',
    addressLine2: item.address.addressLine2
      ? item.address.addressLine2 + ', '
      : '',
    city: item.address.city ? item.address.city + ', ' : '',
    state: item.address.state ? item.address.state : '',
    zipCode: item.address.zipCode ? item.address.zipCode : '',
  };

  if (item.address.addressLine2) {
    return `${item.address.addressLine1}${item.address.addressLine2}${item.address.city}${item.address.state} ${item.address.zipCode}`.trim();
  }

  return `${item.address.addressLine1}${item.address.city}${item.address.state} ${item.address.zipCode}`.trim();
};

const ExpenditurePanel = ({
  item,
  showPanel,
  closePanel,
  session,
  editItem,
}) => {
  const onClickEdit = () => {
    editItem(item._id);
  };

  if (!item) {
    return null;
  }

  const recipAddress = item.recipientFullAddress || address(item);
  const disbursementCategory = disbursementCategories.find(
    dc => dc.key === item.disbursementCategory,
  );

  const onRenderFooterContent = () => (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
      }}
    >
      <PrimaryButton
        iconProps={{ iconName: 'PencilAlt' }}
        onClick={onClickEdit}
        text="Edit"
      />
    </div>
  );

  const onRenderNavigationContent = (headerProps, defaultRenderer) => (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        width: '96%',
      }}
    >
      <h3 style={{ flex: 1 }}>{`${getLabel(
        'Expenditure',
        session,
      )} Details`}</h3>
      {defaultRenderer(headerProps)}
    </div>
  );

  return (
    <Panel
      isOpen={showPanel}
      onDismiss={closePanel}
      type={PanelType.smallFixedNear}
      isLightDismiss
      onRenderFooterContent={onRenderFooterContent}
      onRenderNavigationContent={onRenderNavigationContent}
      isFooterAtBottom
    >
      <Grid>
        <GridRow>
          <Column>
            <DataLabel label="Recipient" value={item.displayName} />
            <DataLabel
              label={`${getLabel('Expenditure', session)} Type`}
              value={item.expenditureType}
            />
            {disbursementCategory && (
              <DataLabel
                label="Disbursement Category"
                value={disbursementCategory.text}
                valueStyle={{ textAlign: 'right' }}
              />
            )}
            <DataLabel label="Amount" value={formatCurrency(item.amount)} />
            <DataLabel
              label="Date Paid"
              value={formatDate(item.expenditureDate)}
            />
            {item.paymentType !== '0' && item.paymentType && (
              <DataLabel label="Payment Type" value={item.paymentType} />
            )}
            {item.checkNumber && (
              <DataLabel label="Check Number" value={item.checkNumber} />
            )}
            {item.paymentReferenceId && (
              <DataLabel label="Reference Id" value={item.paymentReferenceId} />
            )}
          </Column>
        </GridRow>
        <GridRow>
          <Column>
            {item.purpose && <DataLabel label="Purpose" value={item.purpose} />}
            {!!item.creditCardType && (
              <DataLabel label="Credit Card" value={item.creditCardType} />
            )}
            {!!item.creditCardLast4 && (
              <DataLabel
                label="Credit Card Last 4"
                value={`**${item.creditCardLast4}`}
              />
            )}
            {item.contactName && (
              <DataLabel label="Contact Name" value={item.contactName} />
            )}
            {recipAddress && (
              <DataLabel
                label="Address"
                value={recipAddress}
                valueStyle={{ textAlign: 'right' }}
              />
            )}
            {item.phone1 && <DataLabel label="Phone" value={item.phone1} />}
            {item.email && <DataLabel label="Email" value={item.email} />}
            <Separator />
            <DataLabel label="Election Year" value={item.electionYear} />
            {session.isFederal() ? (
              <>
                <DataLabel
                  label="Disbursement For"
                  value={`${item.electionCycle}${item.electionCycleOtherDescription ? ' - ' + item.electionCycleOtherDescription : ''}`}
                />
                {item?.memoText?.length > 0 && (
                  <DataLabel label="Memo" value={item.memoText} />
                )}
              </>
            ) : (
              <DataLabel label="Election" value={item.electionCycle} />
              )}
            {item.endRecipients && item.endRecipients.length > 0 && (
              <DataLabel
                label="End Recipients"
                value={item.endRecipients
                  .map(er => {
                    if (er.displayName) {
                      return er.displayName;
                    }

                    if (er.firstName && er.lastName) {
                      return `${er.firstName} ${er.lastName}`;
                    }

                    if (er.businessName) {
                      return er.businessName;
                    }

                    return 'name not found';
                  })
                  .join('; ')}
                valueStyle={{ textAlign: 'right' }}
              />
            )}
          </Column>
        </GridRow>
      </Grid>
    </Panel>
  );
};

ExpenditurePanel.propTypes = {
  showPanel: PropTypes.bool,
  item: PropTypes.object,
  closePanel: PropTypes.func.isRequired,
  editItem: PropTypes.func.isRequired,
  session: PropTypes.object.isRequired,
};

ExpenditurePanel.defaultProps = {
  showPanel: false,
  item: {},
};

export default ExpenditurePanel;
