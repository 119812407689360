import React, { useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  ActionButton,
  Checkbox,
  CheckboxVisibility,
  DetailsList,
  DetailsRow,
  SearchBox,
  SelectionMode,
  Spinner,
  SpinnerSize,
} from 'office-ui-fabric-react';
// import {
//   TooltipHost,
// } from 'office-ui-fabric-react/lib/Tooltip';
// import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { types as messagingTypes } from '../../actions/messagingActions';
import { sortDirections, statuses, toastTypes } from '../../helpers/constants';
import { getFullAddressLine } from '../../helpers/addressHelper';
import { useIntersect } from '../../hooks/useIntersect';
import { ContributionsPanel } from '../Panels';
import { ElectionCyclePicker, FECElectionCyclePicker, YearPicker, ContactTypePicker } from '../Pickers';
import { EmptyView } from '../EmptyView';
import { formatCurrency, getServerSideErrorMessage } from '../../helpers/util';
import { getLabel } from '../../helpers/labelHelper';
import ScrollObserver from '../ScrollObserver';
import { getContributionsSummaryListNextPageStatus } from '../../selectors';
import { ContributionDetailRows } from './contributionDetailRows';
import { ExpenditureDetailRows } from './expenditureDetailRows';
import './index.scss';

export const ObservableContributionSummary = ({
  editItem,
  contributionsSummary,
  session,
  isHidden,
  contactType,
  filterText = '',
  electionCycle,
  electionYear,
  byYear,
  actions = { handleChange: () => {}, setSort: () => {}, nextPage: () => {} },
  getContributionsSummaryStatus = statuses.NOT_STARTED,
  contributionCsvStatus = statuses.NOT_STARTED,
  sortField = 'updatedAt',
  sortDirection = sortDirections.DESC,
}) => {
  const reduxDispatch = useDispatch();
  const getNextPageStatus = useSelector(getContributionsSummaryListNextPageStatus);

  if (isHidden) {
    return null;
  }

  const [scrollRef, entry] = useIntersect({
    rootMargin: '60px',
    threshold: 0.3,
  });

  useEffect(() => {
    if (entry.isIntersecting) {
      actions.nextPage();
    }
  }, [entry]);

  const [state, setState] = useState({
    showPanel: false,
    panelItem: null,
    errors: [],
    selectedKey: 0,
    detailContributions: {},
    contactDetailOpen: {},
  });

  const onColumnClick = (e, column) => {
    const field = column.fieldName;
    let direction;
    if (sortField === column.fieldName) {
      if (sortDirection === sortDirections.ASC) {
        direction = sortDirections.DESC;
      } else if (sortDirection === sortDirections.DESC) {
        direction = sortDirections.NONE;
      } else {
        direction = sortDirections.ASC;
      }
    } else {
      direction = sortDirections.ASC;
    }
    actions.setSort({ sortField: field, sortDirection: direction });
  };

  useEffect(() => {
    setState({
      ...state,
      showPanel: state.panelItem !== null,
    });
  }, [state.panelItem]);

  // eslint-disable-next-line no-unused-vars
  const showPanel = async item => {
    try {
      const { data } = await axios.get(`/api/filer/contributions/${item._id}`, {
        withCredentials: true,
      });

      if (data.length) {
        const [contribution] = data;
        setState({
          ...state,
          panelItem: contribution,
        });
      }
    } catch (e) {
      const error = getServerSideErrorMessage(e);
      reduxDispatch({
        type: messagingTypes.SET_TOAST,
        data: {
          message: error,
          toastType: toastTypes.ERROR,
        },
      });
    }
  };

  const hidePanel = () => {
    setState({
      ...state,
      panelItem: null,
    });
  };

  const getCheckbox = (item) => {
    return (
      <Checkbox
        checked={item.isSelected}
        onChange={actions.getOnCheck(item)}
        checkmarkIconProps={{
          iconName: 'Check',
        }}
      />
    );
  };

  const onContactSummaryRowClick = (item) => {
    const rowId = item._id;
    const rowIsOpen = state.contactDetailOpen[rowId] === 'block';
    setState({
      ...state,
      contactDetailOpen: {
        ...state.contactDetailOpen,
        [rowId]: rowIsOpen ? 'none' : 'block',
      },
    });
  };

  const columns = useMemo(() => [
    {
      key: 'checkbox',
      name: 'Export Contributions',
      fieldName: 'checkbox',
      isResizable: false,
      minWidth: 150,
      maxWidth: 150,
      onColumnClick: () => {},
      onRender: item => getCheckbox(item),
    },
    {
      key: 'amount',
      name: 'Amount',
      fieldName: 'amount',
      data: 'number',
      minWidth: 100,
      isResizable: true,
      isSorted: sortField === 'amount' && sortDirection !== sortDirections.NONE,
      isSortedDescending: sortDirection === sortDirections.DESC,
      onColumnClick,
      onRender: item => (
        <span style={{ textAlign: 'right', display: 'block' }} onClick={() => onContactSummaryRowClick(item)}>
          {formatCurrency(item.amount)}
        </span>
      ),
    },
    {
      key: 'contact-type',
      name: 'Type',
      fieldName: 'contactType',
      data: 'string',
      minWidth: 50,
      isResizable: true,
      isSorted:
        sortField === 'contactType' && sortDirection !== sortDirections.NONE,
      isSortedDescending: sortDirection === sortDirections.DESC,
      onColumnClick,
      onRender: item => (
        <span style={{ textAlign: 'right', display: 'block' }} onClick={() => onContactSummaryRowClick(item)}>
          {item.contactType}
        </span>
      ),
    },
    {
      key: 'election-year',
      name: 'Election Year',
      fieldName: 'electionYear',
      data: 'string',
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      isSorted: sortField === 'electionYear' && sortDirection !== sortDirections.NONE,
      isSortedDescending: sortDirection === sortDirections.DESC,
      onColumnClick,
      onRender: item => (
        <span style={{ textAlign: 'right', display: 'block' }} onClick={() => onContactSummaryRowClick(item)}>
          {item.electionYear}
        </span>
      ),
    },
    {
      key: 'election-cycle',
      name: 'Election',
      fieldName: 'electionCycle',
      data: 'string',
      minWidth: 100,
      isResizable: true,
      isSorted: sortField === 'electionCycle' && sortDirection !== sortDirections.NONE,
      isSortedDescending: sortDirection === sortDirections.DESC,
      onColumnClick,
      onRender: item => (
        <span style={{ textAlign: 'right', display: 'block' }} onClick={() => onContactSummaryRowClick(item)}>
          {item.electionCycle}
        </span>
      ),
    },
    {
      key: 'display-name',
      name: 'Name',
      fieldName: 'displayName',
      data: 'string',
      minWidth: 150,
      isResizable: true,
      isSorted: sortField === 'displayName' && sortDirection !== sortDirections.NONE,
      isSortedDescending: sortDirection === sortDirections.DESC,
      onColumnClick,
      onRender: item => (
        <span style={{ textAlign: 'right', display: 'block' }} onClick={() => onContactSummaryRowClick(item)}>
          {item.displayName}
        </span>
      ),
    },
    {
      key: 'address',
      name: 'Address',
      fieldName: 'address',
      isResizable: true,
      minWidth: 325,
      maxWidth: 425,
      isSorted:
        sortField === 'addressLine1' &&
        sortDirection !== sortDirections.NONE,
      isSortedDescending: sortDirection === sortDirections.DESC,
      onColumnClick,
      onRender: item => <span onClick={() => onContactSummaryRowClick(item)}>{getFullAddressLine(item.address)}</span>,
    },
    {
      key: 'occupation',
      name: 'Occupation',
      fieldName: 'occupation',
      minWidth: 200,
      isResizable: true,
      isSorted:
        sortField === 'occupation' && sortDirection !== sortDirections.NONE,
      isSortedDescending: sortDirection === sortDirections.DESC,
      onColumnClick,
      onRender: item => <span onClick={() => onContactSummaryRowClick(item)}>{item.occupation}</span>,
    },
    {
      key: 'employer',
      name: 'Employer',
      fieldName: 'employer',
      minWidth: 250,
      isResizable: true,
      isSorted:
        sortField === 'employer' && sortDirection !== sortDirections.NONE,
      isSortedDescending: sortDirection === sortDirections.DESC,
      onColumnClick,
      onRender: item => <span onClick={() => onContactSummaryRowClick(item)}>{item.employer}</span>,
    },
  ]);

  const onRenderRow = rowProps => {
    const { item } = rowProps;
    const { _id: rowId, contactId, electionYear, electionCycle } = item;
    const params = `?contactId=${contactId}&electionCycle=${electionCycle}&electionYear=${electionYear}`;
    const isOpen = state.contactDetailOpen[rowId] === 'block';
    return (
      <div>
        <div className="summary-contribution-row">
          <DetailsRow {...rowProps} />
        </div>
        <div style={{ display: state.contactDetailOpen[rowId] || 'none' }}>
          <ContributionDetailRows
            isOpen={isOpen}
            rowId={rowId}
            params={params}
          />
          <ExpenditureDetailRows
            isOpen={isOpen}
            rowId={rowId}
            params={params}
          />
        </div>
      </div>
    );
  };

  const spinnerForContributionCSV = <Spinner size={SpinnerSize.small} />;
  const contributionButtonText = contributionCsvStatus === statuses.PROCESSING ? spinnerForContributionCSV : 'Export Selected Rows to Contribution CSV';
  const byYearOnChange = actions.changeSearchValue('byYear');
  const rowIsSelected = (contributionsSummary || []).find(c => c.isSelected);
  return (
    <div className="ObservableContributionSummary">
      <div className="search-line">
        <div style={{ paddingTop: '29px' }}>
          <SearchBox
            placeholder={`Search ${getLabel('Contributions', session)}`}
            value={filterText}
            onChange={actions.handleFilterChange}
            clearButtonProps={{
              iconProps: {
                iconName: 'Times',
              },
            }}
          />
        </div>
        <div style={{ marginLeft: '50px', display: 'flex', marginRight: '12px' }}>
          <ContactTypePicker
            onChange={actions.changeSearchValue('contactType')}
            session={session}
            value={contactType}
          />
          <YearPicker
            onChange={actions.changeSearchValue('electionYear')}
            required
            errorMessage={state.errors.electionYear}
            label="Election Year"
            value={electionYear}
            className="year-picker"
          />
          {session.isFederal() ? (
            <FECElectionCyclePicker
              value={electionCycle}
              onChange={actions.changeSearchValue('electionCycle')}
              required
              errorMessage={state.errors.electionCycle}
              className="election-cycle"
              label="Election"
              disabled={byYear}
            />
          ) : (
            <ElectionCyclePicker
              value={electionCycle}
              label="Election"
              onChange={actions.changeSearchValue('electionCycle')}
              required
              className="election-cycle"
              errorMessage={state.errors.electionCycle}
              disabled={byYear}
            />
          )}
          <div style={{ marginLeft: '24px', marginTop: '33px' }}>
            <Checkbox
              checked={byYear}
              onChange={byYearOnChange}
              label="By Year:"
              checkmarkIconProps={{
                iconName: 'Check',
              }}
            />
          </div>
          <div style={{ marginLeft: '24px', marginTop: '22px' }}>
            <ActionButton
              text="Export to CSV"
              onClick={actions.exportToCSV}
              iconProps={{
                iconName: 'FileCSV',
              }}
            />
          </div>
          <div style={{ marginLeft: '24px', marginTop: '22px', width: '200px' }}>
            <ActionButton
              text={contributionButtonText}
              disabled={!rowIsSelected || contributionCsvStatus === statuses.PROCESSING}
              onClick={actions.exportToContributionCSV}
              iconProps={{
                iconName: 'FileCSV',
              }}
            />
          </div>
        </div>
      </div>
      {getContributionsSummaryStatus === statuses.PROCESSING && (
        <div style={{ padding: '60px 0' }}>
          <Spinner size={SpinnerSize.large} />
        </div>
      )}
      {getContributionsSummaryStatus === statuses.SUCCESS && contributionsSummary.length > 0 && (
        <>
          <DetailsList
            items={contributionsSummary}
            columns={columns}
            compact={false}
            selectionMode={SelectionMode.none}
            checkboxVisibility={CheckboxVisibility.none}
            onRenderRow={onRenderRow}
          />
          <ScrollObserver
            getNextPageStatus={getNextPageStatus}
            scrollRef={scrollRef}
          />
        </>
      )}
      {getContributionsSummaryStatus === statuses.SUCCESS &&
        contributionsSummary.length === 0 && (
          <EmptyView
            message={'No contribution summaries found'}
          />
        )}
      <ContributionsPanel
        item={state.panelItem}
        showPanel={state.showPanel}
        closePanel={hidePanel}
        editItem={editItem}
        session={session}
      />
    </div>
  );
};

ObservableContributionSummary.propTypes = {
  editItem: PropTypes.func.isRequired,
  contributionsSummary: PropTypes.array,
  filterFn: PropTypes.func,
  session: PropTypes.object.isRequired,
  isHidden: PropTypes.bool,
  filterText: PropTypes.string,
  electionCycle: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  electionYear: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  byYear: PropTypes.bool,
  actions: PropTypes.object,
  getContributionsSummaryStatus: PropTypes.string,
  contributionCsvStatus: PropTypes.string,
  sortField: PropTypes.string,
  sortDirection: PropTypes.oneOf(Object.values(sortDirections)),
};

ObservableContributionSummary.defaultProps = {
  contributionsSummary: {
    contributions: [],
  },
  filterFn: null,
  isHidden: false,
};

export default ObservableContributionSummary;
