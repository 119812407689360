import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { GridRow, Column, MoneyField } from '../../common';

const FECSection17 = ({ q17ColA, q17ColB }) => {
  return (
    <Fragment>
      <GridRow style={{ paddingBottom: 6 }}>
        <Column sm={4}>
          <p>17. OPERATING EXPENDITURES</p>
        </Column>
        <Column sm={4} classNames="entry-row">
          <MoneyField
            disabled
            value={q17ColA || 0}
          />
        </Column>
        <Column sm={4} classNames="entry-row">
          <MoneyField value={q17ColB || 0} disabled />
        </Column>
      </GridRow>
    </Fragment>
  );
};

FECSection17.propTypes = {
  q17ColA: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  q17ColB: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default FECSection17;
