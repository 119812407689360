import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { GridRow, Column, MoneyField } from '../../common';

const FECSection20b = ({ q20bColA, q20bColB }) => {
  return (
    <Fragment>
      <GridRow>
        <Column sm={4} classNames="sub-question">
          <p>(b) Political Party Committees</p>
        </Column>
        <Column sm={4} classNames="entry-row">
          <MoneyField
            disabled
            value={q20bColA || 0}
          />
        </Column>
        <Column sm={4} classNames="entry-row">
          <MoneyField
            disabled
            value={q20bColB || 0}
          />
        </Column>
      </GridRow>
    </Fragment>
  );
};

FECSection20b.propTypes = {
  q20bColA: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  q20bColB: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default FECSection20b;
