import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { GridRow, Column, MoneyField } from '../../common';

const FECSection11d = ({ q11dColA, q11dColB }) => {
  return (
    <Fragment>
      <GridRow>
        <Column sm={4}>
          <p className="sub-question">(d) The Candidate</p>
        </Column>
        <Column sm={4} classNames="entry-row">
          <MoneyField
            disabled
            value={q11dColA || 0}
          />
        </Column>
        <Column sm={4} classNames="entry-row">
          <MoneyField
            disabled
            value={q11dColB || 0}
          />
        </Column>
      </GridRow>
    </Fragment>
  );
};

FECSection11d.propTypes = {
  q11dColA: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  q11dColB: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default FECSection11d;
