export const types = {
  VALIDATE_CHECK_NUMBER: 'VALIDATE_CHECK_NUMBER',
  VALIDATE_CHECK_NUMBER_ERROR: 'VALIDATE_CHECK_NUMBER_ERROR',
  CLEAR_CHECK_NUMBER_VALIDATION: 'CLEAR_CHECK_NUMBER_VALIDATION',
};

export const actions = {
  validateContributionCheckNumber(contactId, checkNumber) {
    return {
      type: types.VALIDATE_CHECK_NUMBER,
      data: { contactId, checkNumber, type: 'contribution' },
    };
  },

  validateExpenditureCheckNumber(contactId, checkNumber) {
    return {
      type: types.VALIDATE_CHECK_NUMBER,
      data: { contactId, checkNumber, type: 'expenditure' },
    };
  },

  validateLoanCheckNumber(contactId, checkNumber) {
    return {
      type: types.VALIDATE_CHECK_NUMBER,
      data: { contactId, checkNumber, type: 'loan' },
    };
  },

  clearCheckNumberValidations() {
    return { type: types.CLEAR_CHECK_NUMBER_VALIDATION };
  },
};
