import { types } from '../actions/contributionActions';
import { statuses } from '../helpers/constants';
import { createReducer } from './createReducer';

export const initialState = {
  contributions: [],
  // These track rows by rowId and need to be objects holding lists of contributions, status or error
  // getContributionsByContactError[<rowId>] = [<contribution>,<contribution>];
  // contributionsByContactStatus[<rowId]> = status
  // getContributionsByContactError[<rowId]> = error
  contributionsByContact: {},
  getContributionsByContactError: {},
  getContributionsByContactStatus: {},
  contributionsForReport: [],
  getContributionsError: null,
  getContributionsStatus: statuses.NOT_STARTED,
  saveStatus: statuses.NOT_STARTED,
  getNextPageStatus: statuses.PROCESSING,
};

const actionMap = {
  [types.GET_CONTRIBUTIONS_PROCESSING]: state => {
    return {
      ...state,
      getContributionsStatus: statuses.PROCESSING,
    };
  },
  [types.GET_CONTRIBUTIONS_SUCCESS]: (state, action) => {
    return {
      ...state,
      contributions: action.contributions,
      getContributionsStatus: statuses.SUCCESS,
    };
  },
  [types.GET_CONTRIBUTIONS_FAILURE]: (state, action) => {
    return {
      ...state,
      getContributionsError: action.error,
      getContributionsStatus: statuses.ERROR,
    };
  },
  [types.GET_CONTRIBUTIONS_BY_CONTACT_PROCESSING]: (state, action) => {
    const { rowId } = action;
    return {
      ...state,
      getContributionsByContactError: {
        ...state.getContributionsByContactError,
        [rowId]: null,
      },
      getContributionsByContactStatus: {
        ...state.getContributionsByContactStatus,
        [rowId]: statuses.PROCESSING,
      },
    };
  },
  [types.GET_CONTRIBUTIONS_BY_CONTACT_SUCCESS]: (state, action) => {
    const { contributions, rowId } = action.data;
    return {
      ...state,
      getContributionsByContactError: {
        ...state.getContributionsByContactError,
        [rowId]: null,
      },
      getContributionsByContactStatus: {
        ...state.getContributionsByContactStatus,
        [rowId]: statuses.SUCCESS,
      },
      contributionsByContact: {
        ...state.contributionsByContact,
        [rowId]: contributions,
      },
    };
  },
  [types.GET_CONTRIBUTIONS_BY_CONTACT_FAILURE]: (state, action) => {
    const { error, rowId } = action.data;
    return {
      ...state,
      getContributionsByContactError: {
        ...state.getContributionsByContactError,
        [rowId]: error,
      },
      getContributionsByContactStatus: {
        ...state.getContributionsByContactStatus,
        [rowId]: statuses.ERROR,
      },
    };
  },
  [types.SAVE_CONTRIBUTION_PROCESSING]: state => {
    return {
      ...state,
      saveStatus: statuses.PROCESSING,
    };
  },
  [types.SAVE_CONTRIBUTION_RESET]: state => {
    return {
      ...state,
      saveStatus: statuses.NOT_STARTED,
    };
  },
  [types.SAVE_CONTRIBUTION_FAILURE]: state => {
    return {
      ...state,
      saveStatus: statuses.ERROR,
    };
  },
  [types.SAVE_CONTRIBUTION_SUCCESS]: state => {
    return {
      ...state,
      saveStatus: statuses.SUCCESS,
    };
  },
  [types.GET_CONTRIBUTIONS_FOR_REPORT_SUCCESS]: (state, action) => {
    return {
      ...state,
      contributionsForReport: action.contributions,
    };
  },
  [types.CLEAR_CONTRIBUTIONS]: state => ({
    ...state,
    contributions: [],
    getContributionsStatus: statuses.NOT_STARTED,
    getContributionsError: null,
  }),
  [types.GET_CONTRIBUTIONS_NEXT_PAGE_PROCESSING]: state => ({
    ...state,
    getNextPageStatus: statuses.PROCESSING,
  }),
  [types.GET_CONTRIBUTIONS_NEXT_PAGE_SUCCESS]: (
    state,
    { data: { contributions } },
  ) => ({
    ...state,
    contributions: [...state.contributions, ...contributions],
    getNextPageStatus: statuses.NOT_STARTED,
  }),
};

export const contributionReducer = createReducer(initialState, actionMap);
