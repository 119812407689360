export const types = {
  GET_IMPORT_LIST: 'GET_IMPORT_LIST',
  GET_IMPORT_LIST_PROCESSING: 'GET_IMPORT_LIST_PROCESSING',
  GET_IMPORT_LIST_SUCCESS: 'GET_IMPORT_LIST_SUCCESS',
  GET_IMPORT_LIST_FAILURE: 'GET_IMPORT_LIST_FAILURE',
  GET_IMPORT_STATUS: 'GET_IMPORT_STATUS',
  FINALIZE_IMPORT: 'FINALIZE_IMPORT',
  FINALIZE_IMPORT_PROCESSING: 'FINALIZE_IMPORT_PROCESSING',
  FINALIZE_IMPORT_SUCCESS: 'FINALIZE_IMPORT_SUCCESS',
  FINALIZE_IMPORT_FAILURE: 'FINALIZE_IMPORT_FAILURE',
};

export const actions = {
  getImportList(paginationConfig) {
    return { type: types.GET_IMPORT_LIST, data: paginationConfig };
  },
  finalizeImport(fileId, redirectToImportList = false) {
    return { type: types.FINALIZE_IMPORT, data: { fileId, redirectToImportList } };
  },
};
