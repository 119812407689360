import { createReducer } from '../../reducers/createReducer';

export const initialState = {
  uploadFile: null,
  importType: 0,
  createDeposit: false,
  errors: {
    importTypeError: '',
    nameError: '',
  },
  dataImportId: null,
  uploadDialogHidden: true,
  uploadDialogMessageList: [],
};

export const actions = {
  SET_UPLOAD_FILE: 'SET_UPLOAD_FILE',
  HANDLE_CHANGE: 'HANDLE_CHANGE',
  SET_ERRORS: 'SET_ERRORS',
  SHOW_UPLOAD_DIALOG: 'SHOW_UPLOAD_DIALOG',
  CLOSE_UPLOAD_DIALOG: 'CLOSE_UPLOAD_DIALOG',
  SET_UPLOAD_DIALOG_MESSAGE: 'SET_UPLOAD_DIALOG_MESSAGE',
};

export const actionMap = {
  [actions.SET_UPLOAD_FILE]: (state, { data: { uploadFile } }) => {
    return {
      ...state,
      uploadFile,
    };
  },
  [actions.HANDLE_CHANGE]: (state, { data: { fieldName, value } }) => {
    return {
      ...state,
      [fieldName]: value,
    };
  },
  [actions.SET_ERRORS]: (state, { data: { errors } }) => ({
    ...state,
    errors: { ...errors },
  }),
  [actions.SHOW_UPLOAD_DIALOG]: (state, { data: { dataImportId } }) => ({
    ...state,
    dataImportId,
    uploadDialogHidden: false,
  }),
  [actions.CLOSE_UPLOAD_DIALOG]: state => ({
    ...state,
    uploadDialogHidden: true,
  }),
  [actions.SET_UPLOAD_DIALOG_MESSAGE]: (state, { data: { uploadDialogMessage } }) => ({
    ...state,
    uploadDialogMessage,
  }),
};

export const uploadReducer = createReducer(initialState, actionMap);
