import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { GridRow, Column, MoneyField } from '../../common';

const FECSection11c = ({ q11cColA, q11cColB }) => {
  return (
    <Fragment>
      <GridRow>
        <Column sm={4}>
          <p className="sub-question">
            (c) Other Political Committees (such as PACs)
          </p>
        </Column>
        <Column sm={4} classNames="entry-row">
          <MoneyField
            disabled
            value={q11cColA || 0}
          />
        </Column>
        <Column sm={4} classNames="entry-row">
          <MoneyField
            disabled
            value={q11cColB || 0}
          />
        </Column>
      </GridRow>
    </Fragment>
  );
};

FECSection11c.propTypes = {
  q11cColA: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  q11cColB: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default FECSection11c;
