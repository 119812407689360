import React from 'react';
import PropTypes from 'prop-types';
import { ActionButton } from 'office-ui-fabric-react/lib/Button';
import { GridRow, Column } from '../../common';

const UseNewContactButton = ({ useNewContact }) => (
  <GridRow>
    <Column>
      <ActionButton
        text="Use new contact"
        iconProps={{
          iconName: 'PlusCircle',
        }}
        onClick={useNewContact}
      />
    </Column>
  </GridRow>
);

UseNewContactButton.propTypes = {
  useNewContact: PropTypes.func.isRequired,
};

export default UseNewContactButton;
