import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'office-ui-fabric-react/lib/Dropdown';
import { expenditureRecipientTypes } from '../../helpers/constants';
import { CaretDown } from '../icons';

export const RecipientTypePicker = ({
  selectedKey,
  onChange,
  errorMessage,
  disabled,
}) => (
  <Dropdown
    onRenderCaretDown={() => <CaretDown />}
    label="Recipient Type"
    required
    disabled={disabled}
    selectedKey={selectedKey}
    errorMessage={errorMessage}
    options={[
      {
        key: 0,
        text: 'Select',
      },
      ...expenditureRecipientTypes.map(rt => ({
        key: rt,
        text: rt,
      })),
    ]}
    onChange={onChange}
  />
);

RecipientTypePicker.propTypes = {
  selectedKey: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  onChange: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  disabled: PropTypes.bool,
};

RecipientTypePicker.defaultProps = {
  errorMessage: '',
  disabled: false,
};

export default RecipientTypePicker;
