import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import StatePicker from '../StatePicker';
import { GridRow, Column, EmailField, PhoneNumberField } from '../../common';

const EntityAddress = ({
  address1,
  address2,
  city,
  state,
  zipCode,
  county,
  phone1,
  phone2,
  email,
  onChange,
}) => (
  <Fragment>
    <GridRow>
      <Column md={6}>
        <TextField
          value={address1.value}
          label={address1.label}
          onChange={onChange(address1.field)}
          required={address1.required}
          errorMessage={address1.errorMessage}
        />
      </Column>
      <Column md={6}>
        <TextField
          value={address2.value}
          label={address2.label}
          onChange={onChange(address2.field)}
          required={address2.required}
          errorMessage={address2.errorMessage}
        />
      </Column>
    </GridRow>
    <GridRow>
      <Column md={county.show ? 3 : 4}>
        <TextField
          value={city.value}
          label={city.label}
          onChange={onChange(city.field)}
          required={city.required}
          errorMessage={city.errorMessage}
        />
      </Column>
      <Column md={county.show ? 3 : 4}>
        <StatePicker
          fieldLabel={state.label}
          selectedKey={state.value}
          onChange={onChange(state.field)}
          required={state.required}
          errorMessage={state.errorMessage}
        />
      </Column>
      <Column md={county.show ? 3 : 4}>
        <TextField
          value={zipCode.value}
          label={zipCode.label}
          onChange={onChange(zipCode.field)}
          required={zipCode.required}
          errorMessage={zipCode.errorMessage}
        />
      </Column>
      {county.show && (
        <Column md={3}>
          <TextField
            label={county.label}
            value={county.value}
            required={county.required}
            errorMessage={county.errorMessage}
            onChange={onChange(county.field)}
          />
        </Column>
      )}
    </GridRow>
    <GridRow>
      {phone1.show && (
        <Column md={4}>
          <PhoneNumberField
            label={phone1.label}
            value={phone1.value}
            onChange={onChange(phone1.field)}
            require={phone1.required}
            errorMessage={phone1.errorMessage}
          />
        </Column>
      )}
      {phone2.show && (
        <Column md={4}>
          <PhoneNumberField
            label={phone2.label}
            value={phone2.value}
            onChange={onChange(phone2.field)}
            require={phone2.required}
            errorMessage={phone2.errorMessage}
          />
        </Column>
      )}
      {email.show && (
        <Column md={4}>
          <EmailField
            label={email.label}
            value={email.value}
            required={email.required}
            errorMessage={email.errorMessage}
            onChange={onChange(email.field)}
          />
        </Column>
      )}
    </GridRow>
  </Fragment>
);

EntityAddress.propTypes = {
  address1: PropTypes.object.isRequired,
  address2: PropTypes.object.isRequired,
  city: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  zipCode: PropTypes.object.isRequired,
  county: PropTypes.object.isRequired,
  phone1: PropTypes.object.isRequired,
  phone2: PropTypes.object.isRequired,
  email: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default EntityAddress;
