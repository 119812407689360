import { createReducer } from '../../reducers/createReducer';
import { deserializeDate } from '../../helpers/util';

export const initialState = {
  campaign: null,
  incumbentNameError: '',
  filerEmailError: '',
  filerDetailsExpanded: true,
  candidateDetailsExpanded: false,
  committeeDetailsExpanded: false,
  chairpersonDetailsExpanded: false,
  treasurerDetailsExpanded: false,
  balanceDetailsExpanded: false,
  indebtednessDetailsExpanded: false,
  banksDetailsExpanded: false,
  organizationsDetailsExpanded: false,
  committeeNameErrorMessage: '',
  currentStep: 0,
  formValid: true,
  sectionErrors: [],
  designatedAgentModalHidden: true,
  timeZoneName: '',
};

export const actions = {
  SET_CAMPAIGN_DETAILS: 'SET_CAMPAIGN_DETAILS',
  SET_FORM_INVALID: 'SET_FORM_INVALID',
  HANDLE_CHANGE: 'HANDLE_CHANGE',
  HANDLE_FEDERAL_PAC_CHANGE: 'HANDLE_FEDERAL_PAC_CHANGE',
  HANDLE_DEFAULT_CHANGE: 'HANDLE_DEFAULT_CHANGE',
  TOGGLE_EXPAND_FILER_DETAILS: 'TOGGLE_EXPAND_FILER_DETAILS',
  TOGGLE_EXPAND_CANDIDATE_DETAILS: 'TOGGLE_EXPAND_CANDIDATE_DETAILS',
  TOGGLE_COMMITTEE_REGISTERED: 'TOGGLE_COMMITTEE_REGISTERED',
  TOGGLE_EXPAND_COMMITTEE_DETAILS: 'TOGGLE_EXPAND_COMMITTEE_DETAILS',
  SET_FORM_VALID: 'SET_FORM_VALID',
  TOGGLE_EXPAND_CHAIRPERSON_DETAILS: 'TOGGLE_EXPAND_CHAIRPERSON_DETAILS',
  TOGGLE_EXPAND_TREASURER_DETAILS: 'TOGGLE_EXPAND_TREASURER_DETAILS',
  SET_DESIGNATED_AGENT_MODAL_VISIBILITY:
    'SET_DESIGNATED_AGENT_MODAL_VISIBILITY',
  SET_DESIGNATED_AGENT: 'SET_DESIGNATED_AGENT',
  TOGGLE_EXPAND_BALANCE_DETAILS: 'TOGGLE_EXPAND_BALANCE_DETAILS',
  TOGGLE_EXPAND_INDEBTEDNESS: 'TOGGLE_EXPAND_INDEBTEDNESS',
  ADD_DEBT: 'ADD_DEBT',
  REMOVE_DEBT: 'REMOVE_DEBT',
  TOGGLE_EXPAND_BANK_DETAILS: 'TOGGLE_EXPAND_BANK_DETAILS,',
  ADD_BANK: 'ADD_BANK',
  EDIT_BANK: 'EDIT_BANK',
  REMOVE_BANK: 'REMOVE_BANK',
  TOGGLE_EXPAND_ORGANIZATION_DETAILS: 'TOGGLE_EXPAND_ORGANIZATION_DETAILS,',
  ADD_ORGANIZATION: 'ADD_ORGANIZATION',
  EDIT_ORGANIZATION: 'EDIT_ORGANIZATION',
  REMOVE_ORG: 'REMOVE_ORG',
  UPDATE_STEP: 'UPDATE_STEP',
  SET_FORM_ERRORS: 'SET_FORM_ERRORS',
  TOGGLE_EXPAND_SECTION: 'TOGGLE_EXPAND_SECTION',
};

export const actionMap = {
  [actions.SET_CAMPAIGN_DETAILS]: (state, action) => {
    const { campaign } = action.data;
    return {
      ...state,
      campaign: {
        ...campaign,
        openingCashAmountDate: deserializeDate(campaign.openingCashAmountDate),
        nextElectionDate: deserializeDate(campaign.nextElectionDate),
        campaignCommitteeRegistered: Boolean(
          campaign.campaignCommitteeRegistered,
        ),
        openingCashAmount: campaign.openingCashAmount,
        endingCashOnHandAmount: campaign.endingCashOnHandAmount,
        previousElectionCycleCarryForwardAmount: campaign.previousElectionCycleCarryForwardAmount,
        endingMonetaryContributionsAmount:
          campaign.endingMonetaryContributionsAmount,
        endingMonetaryExpendituresAmount:
          campaign.endingMonetaryExpendituresAmount,
        endingInKindExpendituresAmount: campaign.endingInKindExpendituresAmount,
        endingInKindContributionsAmount:
          campaign.endingInKindContributionsAmount,
        hasIndebtedness: campaign.debts.length > 0,
        debts: campaign.debts.map(d => ({
          ...d,
          electionCycle: d.electionCycle,
          electionYear: d.electionYear,
          periodOpeningDebtAmount: d.periodOpeningDebtAmount,
          periodClosingDebtAmount: d.periodClosingDebtAmount,
        })),
        reportingFrequency: campaign.reportingFrequency || '',
        affidavitLimit:
          campaign.affidavitLimit !== undefined ? campaign.affidavitLimit : -1,
        candidate: {
          ...campaign.candidate,
          state: (campaign.candidate || {}).state,
        },
        committee: {
          ...campaign.committee,
          registrationDate: campaign.committee.registrationDate
            ? deserializeDate(campaign.committee.registrationDate)
            : null,
          electionDate: campaign.committee.electionDate
            ? deserializeDate(campaign.committee.electionDate)
            : null,
          state: (campaign.committee || {}).state || 0,
        },
        chairPerson: {
          ...campaign.chairPerson,
          state: (campaign.chairPerson || {}).state || 0,
        },
        settings: {
          importDefaults: {
            electionCycle: campaign?.settings?.importDefaults?.electionCycle,
            electionYear: campaign?.settings?.importDefaults?.electionYear,
          },
        },
        treasurer: {
          ...campaign.treasurer,
          state: (campaign.treasurer || {}).state || 0,
        },
        designatedAgent:
          campaign.designatedAgent &&
          Object.keys(campaign.designatedAgent).length > 0
            ? {
                ...campaign.designatedAgent,
              }
            : {},
        banks: campaign.banks || [],
        organizations: campaign.organizations || [],
        fecSummary: {
          colB_6a_TotalContributionsNoLoans:
            campaign.fecSummary.colB_6a_TotalContributionsNoLoans || 0,
          colB_6b_TotalContributionRefunds:
            campaign.fecSummary.colB_6b_TotalContributionRefunds || 0,
          colB_7a_TotalOperatingExpenditures:
            campaign.fecSummary.colB_7a_TotalOperatingExpenditures || 0,
          colB_7b_TotalOffsetsToOperatingExpenditures:
            campaign.fecSummary.colB_7b_TotalOffsetsToOperatingExpenditures ||
            0,
          colB_11ai_IndividualsItemized:
            campaign.fecSummary.colB_11ai_IndividualsItemized || 0,
          colB_11aii_IndividualsUnitemized:
            campaign.fecSummary.colB_11aii_IndividualsUnitemized || 0,
          colB_11b_PoliticalPartyCommittees:
            campaign.fecSummary.colB_11b_PoliticalPartyCommittees || 0,
          colB_11c_OtherPoliticalCommittees:
            campaign.fecSummary.colB_11c_OtherPoliticalCommittees || 0,
          colB_11d_TheCandidate: campaign.fecSummary.colB_11d_TheCandidate || 0,
          colB_12_TransfersFromOtherAuthorizedCommittees:
            campaign.fecSummary
              .colB_12_TransfersFromOtherAuthorizedCommittees || 0,
          colB_13a_LoansMadeOrGuarnByTheCandidate:
            campaign.fecSummary.colB_13a_LoansMadeOrGuarnByTheCandidate || 0,
          colB_13b_AllOtherLoans:
            campaign.fecSummary.colB_13b_AllOtherLoans || 0,
          colB_15_OtherReceipts: campaign.fecSummary.colB_15_OtherReceipts || 0,
          colB_18_TransfersToOtherAuthorizedCommittees:
            campaign.fecSummary.colB_18_TransfersToOtherAuthorizedCommittees ||
            0,
          colB_19a_LoanRepaymentByCandidate:
            campaign.fecSummary.colB_19a_LoanRepaymentByCandidate || 0,
          colB_19b_LoanRepayments_AllOtherLoans:
            campaign.fecSummary.colB_19b_LoanRepayments_AllOtherLoans || 0,
          colB_20a_Refund_IndividualsOtherThanPolCmtes:
            campaign.fecSummary.colB_20a_Refund_IndividualsOtherThanPolCmtes ||
            0,
          colB_20b_Refund_PoliticalPartyCommittees:
            campaign.fecSummary.colB_20b_Refund_PoliticalPartyCommittees || 0,
          colB_20c_Refund_OtherPoliticalCommittees:
            campaign.fecSummary.colB_20c_Refund_OtherPoliticalCommittees || 0,
          colB_21_OtherDisbursements:
            campaign.fecSummary.colB_21_OtherDisbursements || 0,
        },
      },
    };
  },
  [actions.SET_FORM_INVALID]: (state, action) => ({
    ...state,
    formValid: !action.data.isInvalid,
    sectionErrors: action.data.isInvalid
      ? [...state.sectionErrors, action.data.formSection].filter(
          fs => fs !== null && fs !== undefined,
        )
      : state.sectionErrors.filter(fs => fs !== action.data.formSection),
  }),
  [actions.HANDLE_CHANGE]: (state, action) => {
    if (!action.data.section) {
      return {
        ...state,
        campaign: {
          ...state.campaign,
          [action.data.fieldName]: action.data.value,
        },
      };
    }
    return {
      ...state,
      campaign: {
        ...state.campaign,
        [action.data.section]: {
          ...state.campaign[action.data.section],
          [action.data.fieldName]: action.data.value,
        },
      },
    };
  },
  [actions.HANDLE_FEDERAL_PAC_CHANGE]: (state, action) => {
    return {
      ...state,
      campaign: {
        ...state.campaign,
        committee: {
          ...state.campaign.committee,
          federalPac: {
            ...state.campaign.committee.federalPac,
            [action.data.fieldName]: action.data.value,
          },
        },
      },
    };
  },
  [actions.HANDLE_DEFAULT_CHANGE]: (state, action) => {
    return {
      ...state,
      campaign: {
        ...state.campaign,
        settings: {
          ...state.campaign.settings,
          importDefaults: {
            ...state.campaign.settings.importDefaults,
            [action.data.fieldName]: action.data.value,
          },
        },
      },
    };
  },
  [actions.TOGGLE_EXPAND_FILER_DETAILS]: state => ({
    ...state,
    filerDetailsExpanded: !state.filerDetailsExpanded,
  }),
  [actions.TOGGLE_EXPAND_CANDIDATE_DETAILS]: state => ({
    ...state,
    candidateDetailsExpanded: !state.candidateDetailsExpanded,
  }),
  [actions.TOGGLE_COMMITTEE_REGISTERED]: (state, action) => {
    return {
      ...state,
      campaign: {
        ...state.campaign,
        campaignCommitteeRegistered: action.data.campaignCommitteeRegistered,
      },
      committeeNameErrorMessage: '',
      sectionErrors: state.sectionErrors.filter(fs => fs !== 2),
      formValid: action.data.campaignCommitteeRegistered
        ? state.sectionErrors.filter(fs => fs !== 2).length === 0
        : true,
    };
  },
  [actions.TOGGLE_EXPAND_COMMITTEE_DETAILS]: state => ({
    ...state,
    committeeDetailsExpanded: !state.committeeDetailsExpanded,
  }),
  [actions.SET_FORM_VALID]: state => ({
    ...state,
    formValid: true,
  }),
  [actions.TOGGLE_EXPAND_CHAIRPERSON_DETAILS]: state => ({
    ...state,
    chairpersonDetailsExpanded: !state.chairpersonDetailsExpanded,
  }),
  [actions.TOGGLE_EXPAND_TREASURER_DETAILS]: state => ({
    ...state,
    treasurerDetailsExpanded: !state.treasurerDetailsExpanded,
  }),
  [actions.SET_DESIGNATED_AGENT_MODAL_VISIBILITY]: (state, action) => ({
    ...state,
    designatedAgentModalHidden: action.data.isHidden,
  }),
  [actions.SET_DESIGNATED_AGENT]: (state, action) => ({
    ...state,
    campaign: {
      ...state.campaign,
      designatedAgent: action.data.designatedAgent,
    },
  }),
  [actions.TOGGLE_EXPAND_BALANCE_DETAILS]: state => ({
    ...state,
    balanceDetailsExpanded: !state.balanceDetailsExpanded,
  }),
  [actions.TOGGLE_EXPAND_INDEBTEDNESS]: state => ({
    ...state,
    indebtednessDetailsExpanded: !state.indebtednessDetailsExpanded,
  }),
  [actions.ADD_DEBT]: (state, action) => {
    return {
      ...state,
      campaign: {
        ...state.campaign,
        debts: [
          ...(state.campaign.debts || []),
          {
            ...action.data.newDebt,
            isNew: true,
            _id: action.data._id,
          },
        ],
      },
    };
  },
  [actions.REMOVE_DEBT]: (state, action) => {
    return {
      ...state,
      campaign: {
        ...state.campaign,
        debts: state.campaign.debts.map(d => {
          if (d._id === action.data.id) {
            d.removed = true;
          }

          return d;
        }),
      },
    };
  },
  [actions.TOGGLE_EXPAND_BANK_DETAILS]: state => ({
    ...state,
    banksDetailsExpanded: !state.banksDetailsExpanded,
  }),
  [actions.ADD_BANK]: (state, action) => {
    return {
      ...state,
      campaign: {
        ...state.campaign,
        banks: [
          ...(state.campaign.banks || []),
          {
            ...action.data.newBank,
            _id: action.data._id,
            isNew: true,
          },
        ],
      },
    };
  },
  [actions.EDIT_BANK]: (state, action) => ({
    ...state,
    campaign: {
      ...state.campaign,
      banks: state.campaign.banks.map(b => {
        if (b._id === action.data.updatedBank._id) {
          b = action.data.updatedBank;
        }
        return b;
      }),
    },
  }),
  [actions.REMOVE_BANK]: (state, action) => ({
    ...state,
    campaign: {
      ...state.campaign,
      banks: state.campaign.banks.filter(b => b._id !== action.data.id),
    },
  }),
  [actions.TOGGLE_EXPAND_ORGANIZATION_DETAILS]: state => ({
    ...state,
    organizationsDetailsExpanded: !state.organizationsDetailsExpanded,
  }),
  [actions.ADD_ORGANIZATION]: (state, action) => ({
    ...state,
    campaign: {
      ...state.campaign,
      organizations: [
        ...state.campaign.organizations,
        {
          ...action.data.newOrg,
          _id: action.data.id,
          isNew: true,
        },
      ],
    },
  }),
  [actions.EDIT_ORGANIZATION]: (state, action) => ({
    ...state,
    campaign: {
      ...state.campaign,
      organizations: state.campaign.organizations.map(org => {
        if (org._id === action.data.updatedOrg._id) {
          org = action.data.updatedOrg;
        }
        return org;
      }),
    },
  }),
  [actions.REMOVE_ORG]: (state, action) => ({
    ...state,
    campaign: {
      ...state.campaign,
      organizations: state.campaign.organizations.filter(
        org => org._id !== action.data.id,
      ),
    },
  }),
  [actions.UPDATE_STEP]: (state, action) => {
    return {
      ...state,
      [action.data.currentSectionExpanded]: false,
      [action.data.newSectionExpanded]: true,
      currentStep: action.data.step,
    };
  },
  [actions.SET_FORM_ERRORS]: (state, action) => ({
    ...state,
    formValid: action.data.formValid,
    committeeNameErrorMessage: action.data.committeeNameErrorMessage,
    sectionErrors: action.data.sectionErrors,
  }),
  [actions.TOGGLE_EXPAND_SECTION]: (state, action) => ({
    ...state,
    [action.data.section]: action.data.isExpanded,
  }),
};

export const EditCampaignReducer = createReducer(initialState, actionMap);
