import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
  DefaultButton,
  PrimaryButton,
  IconButton,
  ActionButton,
} from 'office-ui-fabric-react/lib/Button';
import { Text } from 'office-ui-fabric-react/lib/Text';
import { Separator } from 'office-ui-fabric-react/lib/Separator';
import { DataLabel } from '../../components/common';
import { types as anedotTypes } from '../../actions/anedotActions';
import { getCurrentAnedotIndex } from '../../selectors';
import { formatDate, formatCurrency, formatPhone } from '../../helpers/util';

const ContributionDetails = ({
  toggleShowDeleted,
  showDeleted = false,
  contribution,
  totalAnedotEvents,
  selectedExistingContact,
  newContact,
  searchedContact,
  processContribution,
  doNotImport,
  swapOccupationAndEmployer,
  occupationAndEmployerSwapped,
}) => {
  const currentIndex = useSelector(getCurrentAnedotIndex);
  const reduxDispatch = useDispatch();

  const getNext = () => {
    reduxDispatch({
      type: anedotTypes.GET_NEXT_UNPROCESSED,
    });
  };

  const getPrevious = () => {
    reduxDispatch({
      type: anedotTypes.GET_PREVIOUS_UNPROCESSED,
    });
  };

  let address = '';
  if (contribution && contribution.address) {
    address = (
      <address>
        <p>{contribution.address.addressLine1}</p>
        {contribution.address.addressLine2 && (
          <p>{contribution.address.addressLine2}</p>
        )}
        <p>{`${contribution.address.city}, ${contribution.address.state} ${contribution.address.zipCode}`}</p>
      </address>
    );
  }

  const totalEvents = totalAnedotEvents.length;

  return (
    <div
      className={`contribution-wrapper${showDeleted ? ' show-deleted' : ''}`}
    >
      <h3>{`${showDeleted ? 'Deleted ' : ''}Contribution Details`}</h3>
      <div className="navigation">
        <IconButton
          iconProps={{ iconName: 'CaretLeft' }}
          onClick={getPrevious}
          disabled={currentIndex === 0}
        />
        <Text>{`${currentIndex + 1} of ${totalEvents}`}</Text>
        <IconButton
          iconProps={{ iconName: 'CaretRight' }}
          onClick={getNext}
          disabled={currentIndex >= totalEvents - 1}
        />
      </div>
      {contribution.status &&
      <DataLabel label="Status" value={contribution.status} />
      }
      {contribution.eventType &&
      <DataLabel label="Event Type" value={contribution.eventType} />
      }
      <DataLabel
        label="Date Processed"
        value={formatDate(contribution.processedDate)}
      />
      {contribution.contactType &&
        <DataLabel label="Source" value={contribution.contactType} />
      }
      {contribution.amount && (
        <DataLabel label="Amount" value={formatCurrency(contribution.amount)} />
      )}
      {contribution.checkNumber && (
        <DataLabel label="Check Number" value={contribution.checkNumber} />
      )}
      {contribution.paymentType && (
        <DataLabel label="Payment Type" value={contribution.paymentType} />
      )}
      <DataLabel label="Contributor" value={contribution.displayName} />
      <DataLabel label="Address" value={address} />
      {contribution.emailAddress && (
        <DataLabel label="Email" value={contribution.emailAddress} />
      )}
      {contribution.phone && (
        <DataLabel label="Phone" value={formatPhone(contribution.phone)} />
      )}
      {contribution.employer && (
        <DataLabel
          label="Employer"
          value={
            occupationAndEmployerSwapped
              ? contribution.occupation
              : contribution.employer
          }
        />
      )}
      {contribution.occupation && (
        <DataLabel
          label="Occupation"
          value={
            occupationAndEmployerSwapped
              ? contribution.employer
              : contribution.occupation
          }
        />
      )}
      {Boolean(contribution.occupation && contribution.employer) && (
        <DefaultButton
          text={
            occupationAndEmployerSwapped
              ? 'Unswap Occupation & Employer'
              : 'Swap Occupation & Employer'
          }
          onClick={swapOccupationAndEmployer}
        />
      )}
      {contribution.description && (
        <DataLabel label="Description" value={contribution.description} />
      )}
      <Separator />
      <div className="anedot-contribution-actions">
        <DefaultButton
          text="Do Not Import"
          onClick={doNotImport}
          style={{ marginRight: 16 }}
        />
        <PrimaryButton
          text="Process"
          onClick={processContribution}
          disabled={
            selectedExistingContact === null &&
            newContact === null &&
            searchedContact === null
          }
        />
      </div>
      <Separator />
      <ActionButton
        text={showDeleted ? 'Show Unprocessed' : 'Show Deleted'}
        onClick={toggleShowDeleted}
      />
    </div>
  );
};

ContributionDetails.propTypes = {
  contribution: PropTypes.object.isRequired,
  selectedExistingContact: PropTypes.object,
  newContact: PropTypes.object,
  searchedContact: PropTypes.object,
  processContribution: PropTypes.func.isRequired,
  doNotImport: PropTypes.func.isRequired,
  swapOccupationAndEmployer: PropTypes.func.isRequired,
  occupationAndEmployerSwapped: PropTypes.bool,
  totalAnedotEvents: PropTypes.array,
  getNext: PropTypes.func,
  getPrevious: PropTypes.func,
  toggleShowDeleted: PropTypes.func,
  showDeleted: PropTypes.bool,
};

ContributionDetails.defaultProps = {
  selectedExistingContact: null,
  newContact: null,
  searchedContact: null,
  occupationAndEmployerSwapped: false,
  totalAnedotEvents: 0,
};

export default ContributionDetails;
