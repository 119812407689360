export const types = {
  GET_CURRENT_CAMPAIGN: 'GET_CURRENT_CAMPAIGN',
  GET_CURRENT_CAMPAIGN_PROCESSING: 'GET_CURRENT_CAMPAIGN_PROCESSING',
  GET_CURRENT_CAMPAIGN_SUCCESS: 'GET_CURRENT_CAMPAIGN_SUCCESS',
  GET_CURRENT_CAMPAIGN_FAILURE: 'GET_CURRENT_CAMPAIGN_FAILURE',
  GET_CURRENT_CAMPAIGN_FINANCE: 'GET_CURRENT_CAMPAIGN_FINANCE',
  REFRESH_CURRENT_CAMPAIGN_FINANCE: 'REFRESH_CURRENT_CAMPAIGN_FINANCE',
  RESET_CURRENT_CAMPAIGN_FINANCE: 'GET_CURRENT_CAMPAIGN_FINANCE',
  GET_CURRENT_CAMPAIGN_FINANCE_PROCESSING: 'GET_CURRENT_CAMPAIGN_FINANCE_PROCESSING',
  GET_CURRENT_CAMPAIGN_FINANCE_SUCCESS: 'GET_CURRENT_CAMPAIGN_FINANCE_SUCCESS',
  GET_CURRENT_CAMPAIGN_FINANCE_FAILURE: 'GET_CURRENT_CAMPAIGN_FINANCE_FAILURE',
  GET_ALL_CAMPAIGNS: 'GET_ALL_CAMPAIGNS',
  GET_ALL_CAMPAIGNS_SUCCESS: 'GET_ALL_CAMPAIGNS_SUCCESS',
  ADMIN_SELECT_CAMPAIGN: 'ADMIN_SELECT_CAMPAIGN',
};

export const actions = {
  getCurrentCampaign() {
    return { type: types.GET_CURRENT_CAMPAIGN };
  },

  getCurrentCampaignFinances() {
    return { type: types.GET_CURRENT_CAMPAIGN_FINANCE };
  },

  refreshCurrentCampaignFinances() {
    return { type: types.REFRESH_CURRENT_CAMPAIGN_FINANCE };
  },

  getAllCampaigns() {
    return { type: types.GET_ALL_CAMPAIGNS };
  },

  adminSelectCampaign(id) {
    return { type: types.ADMIN_SELECT_CAMPAIGN, id };
  },

  setCurrentCampaignAsProcessing() {
    return { type: types.GET_CURRENT_CAMPAIGN_PROCESSING };
  },
};
