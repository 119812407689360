import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { GridRow, Column, MoneyField } from '../../common';

const FECSection15 = ({ q15ColA, q15ColB }) => {
  return (
    <Fragment>
      <GridRow style={{ borderTop: '1px solid #919191', paddingBottom: 6 }}>
        <Column sm={4}>
          <p>
            15. OTHER RECEIPTS <br />
            (Dividends, Interest, etc.)
          </p>
        </Column>
        <Column sm={4} classNames="entry-row">
          <MoneyField
            disabled
            value={q15ColA || 0}
          />
        </Column>
        <Column sm={4} classNames="entry-row">
          <MoneyField value={q15ColB || 0} disabled />
        </Column>
      </GridRow>
    </Fragment>
  );
};

FECSection15.propTypes = {
  q15ColA: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  q15ColB: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default FECSection15;
