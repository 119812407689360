import { isInvalidNumber } from '../../helpers/util';

export const validate = (state) => {
  const errors = {};

  if (state.committeeName === '') {
    errors.committeeNameError = 'Committee name is required';
  }
  if (state.committeeId === '') {
    errors.committeeIdError = 'Committee id is required';
  }
  if (isInvalidNumber(state.col6c_NetContributions)) {
    errors.col6c_NetContributionsError = 'Invalid amount';
  }
  if (isInvalidNumber(state.col7c_NetOperatingExpenditures)) {
    errors.col7c_NetOperatingExpendituresError = 'Invalid amount';
  }
  if (isInvalidNumber(state.col9_DebtsAndObligationsOwedToTheCommittee)) {
    errors.col9_DebtsAndObligationsOwedToTheCommitteeError = 'Invalid amount';
  }
  if (isInvalidNumber(state.col10_DebtsAndObligationsOwedByTheCommittee)) {
    errors.col10_DebtsAndObligationsOwedByTheCommitteeError = 'Invalid amount';
  }
  if (isInvalidNumber(state.col11a_ContributionsFromIndividuals_PersonsOtherThanPoliticalCommittees)) {
    errors.col11a_ContributionsFromIndividuals_PersonsOtherThanPoliticalCommitteesError = 'Invalid amount';
  }
  if (isInvalidNumber(state.col11b_ContributionsFromPoliticalPartyCommittees)) {
    errors.col11b_ContributionsFromPoliticalPartyCommitteesError = 'Invalid amount';
  }
  if (isInvalidNumber(state.col11c_ContributionsFromOtherPoliticalCommittees)) {
    errors.col11c_ContributionsFromOtherPoliticalCommitteesError = 'Invalid amount';
  }
  if (isInvalidNumber(state.col11d_ContributionsFromTheCandidate)) {
    errors.col11d_ContributionsFromTheCandidateError = 'Invalid amount';
  }
  if (isInvalidNumber(state.col11e_TotalContributions)) {
    errors.col11e_TotalContributionsError = 'Invalid amount';
  }
  if (isInvalidNumber(state.col12_TransfersFromOtherAuthorizedCommittees)) {
    errors.col12_TransfersFromOtherAuthorizedCommitteesError = 'Invalid amount';
  }
  if (isInvalidNumber(state.col13a_LoansMadeOrGuaranteedByTheCandidate)) {
    errors.col13a_LoansMadeOrGuaranteedByTheCandidateError = 'Invalid amount';
  }
  if (isInvalidNumber(state.col13b_AllOtherLoans)) {
    errors.col13b_AllOtherLoansError = 'Invalid amount';
  }
  if (isInvalidNumber(state.col13c_TotalLoans)) {
    errors.col13c_TotalLoansError = 'Invalid amount';
  }
  if (isInvalidNumber(state.col14_OffsetsToOperatingExpenditures)) {
    errors.col14_OffsetsToOperatingExpendituresError = 'Invalid amount';
  }
  if (isInvalidNumber(state.col15_OtherReceipts)) {
    errors.col15_OtherReceiptsError = 'Invalid amount';
  }
  if (isInvalidNumber(state.col16_TotalReceipts)) {
    errors.col16_TotalReceiptsError = 'Invalid amount';
  }
  if (isInvalidNumber(state.col17_OperatingExpenditures)) {
    errors.col17_OperatingExpendituresError = 'Invalid amount';
  }
  if (isInvalidNumber(state.col18_TransfersToOtherAuthorizedCommittees)) {
    errors.col18_TransfersToOtherAuthorizedCommitteesError = 'Invalid amount';
  }
  if (isInvalidNumber(state.col19a_RepaymentsOfLoansMadeOrGuaranteedByCandidate)) {
    errors.col19a_RepaymentsOfLoansMadeOrGuaranteedByCandidateError = 'Invalid amount';
  }
  if (isInvalidNumber(state.col19b_OtherLoanRepayments)) {
    errors.col19b_OtherLoanRepaymentsError = 'Invalid amount';
  }
  if (isInvalidNumber(state.col19c_TotalLoanRepayments)) {
    errors.col19c_TotalLoanRepaymentsError = 'Invalid amount';
  }
  if (isInvalidNumber(state.col20a_RefundsOfContributionsToIndividuals_Persons)) {
    errors.col20a_RefundsOfContributionsToIndividuals_PersonsError = 'Invalid amount';
  }
  if (isInvalidNumber(state.col20b_RefundsOfContributionsToPoliticalPartyCommittees)) {
    errors.col20b_RefundsOfContributionsToPoliticalPartyCommitteesError = 'Invalid amount';
  }
  if (isInvalidNumber(state.col20c_RefundsOfContributionsToOtherPoliticalCommittees)) {
    errors.col20c_RefundsOfContributionsToOtherPoliticalCommitteesError = 'Invalid amount';
  }
  if (isInvalidNumber(state.col20d_TotalContributionsRefunds)) {
    errors.col20d_TotalContributionsRefundsError = 'Invalid amount';
  }
  if (isInvalidNumber(state.col21_OtherDisbursements)) {
    errors.col21_OtherDisbursementsError = 'Invalid amount';
  }
  if (isInvalidNumber(state.col22_TotalDisbursements)) {
    errors.col22_TotalDisbursementsError = 'Invalid amount';
  }
  if (isInvalidNumber(state.col23_CashOnHandAtBeginningOfReportingPeriod)) {
    errors.col23_CashOnHandAtBeginningOfReportingPeriodError = 'Invalid amount';
  }
  if (isInvalidNumber(state.col27_CashOnHandAtCloseOfReportingPeriod)) {
    errors.col27_CashOnHandAtCloseOfReportingPeriodError = 'Invalid amount';
  }
  return errors;
};
