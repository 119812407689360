import React, { useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
  ActionButton,
  Checkbox,
  CheckboxVisibility,
  DetailsList,
  PrimaryButton,
  ScrollablePane,
  ScrollbarVisibility,
  SearchBox,
  Separator,
  Sticky,
  StickyPositionType,
  TextField,
  Pivot,
  PivotItem,
} from 'office-ui-fabric-react';
import { BackButton, MoneyField } from '../../components/common';
import { formatCurrency, scrollToTop } from '../../helpers/util';
import { getLabel } from '../../helpers/labelHelper';
import { types as reconciliationActions } from '../../actions/reconciliationActions';
import { types as expenditureActions } from '../../actions/expenditureActions';
import { types as contributionActions } from '../../actions/contributionActions';
import { DatePicker } from '../../components/Pickers';
import BankFeePanel from '../../components/Panels/BankFeePanel';
import BankInterestPanel from '../../components/Panels/BankInterestPanel';
import { validate } from './ReconciliationFormValidations';
import {
  createColumns,
  mapList,
  getSelectionDetails,
  getOutOfBalance,
  updateListSelection,
  createPayload,
  toggleSelectAll,
} from './reconciliationsHelper';
import {
  initialState,
  actions,
  addEditReconciliationReducer,
} from './addEditReconciliationReducer';
import {
  getUserSession,
  getUnreconciledDeposits,
  getUnreconciledExpenditures,
  getUnreconciledInterest,
  getSingleReconciliation,
} from '../../selectors';
import './AddReconciliation.css';

const AddReconciliation = ({ history, match }) => {
  const [state, localDispatch] = useReducer(
    addEditReconciliationReducer,
    initialState,
  );

  const session = useSelector(getUserSession);
  const unreconciledDeposits = useSelector(getUnreconciledDeposits);
  const unreconciledExpenditures = useSelector(getUnreconciledExpenditures);
  const unreconciledInterest = useSelector(getUnreconciledInterest);
  const singleReconciliation = useSelector(getSingleReconciliation);
  const reduxDispatch = useDispatch();

  let items = mapList(state);

  useEffect(() => {
    items = mapList(state);
  }, [state.selectedTab]);

  useEffect(() => {
    scrollToTop();
    if (match.params.id) {
      reduxDispatch({
        type: reconciliationActions.GET_SINGLE_RECONCILIATION,
        data: {
          id: match.params.id,
        },
      });
    } else {
      reduxDispatch({
        type: reconciliationActions.GET_UNRECONCILED_ACTIVITY,
      });
    }

    return () => {
      if (match.params.id) {
        reduxDispatch({
          type: reconciliationActions.CLEAR_SINGLE_RECONCILIATION,
        });
      }

      localDispatch({
        type: actions.RESET_STATE,
      });

      reduxDispatch({
        type: reconciliationActions.CLEAR_UNRECONCILED_ACTIVITY,
      });
    };
  }, []);

  useEffect(() => {
    if (singleReconciliation !== null) {
      localDispatch({
        type: actions.PREP_FORM_FOR_EDIT,
        data: {
          rec: singleReconciliation,
        },
      });
    } else {
      localDispatch({
        type: actions.SET_TRANSACTIONS,
        data: {
          deposits: unreconciledDeposits,
          expenditures: unreconciledExpenditures,
          interestContributions: unreconciledInterest,
        },
      });
    }
  }, [
    singleReconciliation,
    unreconciledDeposits,
    unreconciledExpenditures,
    unreconciledInterest,
  ]);

  const changeBankStatementDate = fieldName => bankStatementDate => {
    localDispatch({
      type: actions.HANDLE_CHANGE,
      data: {
        fieldName,
        value: bankStatementDate,
      },
    });
  };

  const onChange = fieldName => (e, value) => {
    localDispatch({
      type: actions.HANDLE_CHANGE,
      data: {
        fieldName,
        value: value.key !== undefined ? value.key : value,
      },
    });
  };

  const handleItemSelectionChanged = itemId => (e, isSelected) => {
    const result = updateListSelection({
      itemId,
      transactions: state.transactions,
      isSelected,
    });

    localDispatch({
      type: actions.ON_SELECTION_CHANGED,
      data: {
        list: result,
      },
    });
  };

  const save = () => {
    const errors = validate(state);
    localDispatch({
      type: actions.SET_FORM_ERRORS,
      data: {
        errors,
      },
    });
    if (!Object.keys(errors).length) {
      const payload = createPayload(state);
      if (state.isEdit) {
        reduxDispatch({
          type: reconciliationActions.UPDATE_RECONCILIATION,
          data: {
            id: state._id,
            payload,
          },
        });

        reduxDispatch({
          type: reconciliationActions.CLEAR_SINGLE_RECONCILIATION,
        });
      } else {
        reduxDispatch({
          type: reconciliationActions.CREATE_RECONCILIATION,
          data: {
            payload,
          },
        });
      }
    }
  };

  const onColumnClick = (e, { fieldName }) => {
    localDispatch({
      type: actions.SET_SORT,
      data: {
        fieldName,
      },
    });
  };

  const openPanel = panelName => () => {
    localDispatch({
      type: actions.OPEN_PANEL,
      data: { panelName },
    });
  };

  const closePanel = panelName => () => {
    localDispatch({
      type: actions.CLOSE_PANEL,
      data: { panelName },
    });
  };

  const saveInterest = payload => {
    reduxDispatch({
      type: contributionActions.SAVE_INTEREST_TRANSACTION,
      data: { payload },
    });
  };

  const saveFee = payload => {
    reduxDispatch({
      type: expenditureActions.SAVE_FEE_TRANSACTION,
      data: { payload },
    });
  };

  const onLinkClick = ({ props: { itemKey } }) => {
    localDispatch({
      type: actions.SET_SELECTED_TAB,
      data: { selectedTab: itemKey },
    });
  };

  const getSelectAllChecked = () => {
    const { selectedTab, transactions } = state;
    let allSelected = false;
    const itemIsSelected = item => item.isSelected;
    if (selectedTab === 'all') {
      allSelected = transactions.every(itemIsSelected);
    } else if (selectedTab === 'deposits') {
      allSelected = transactions
        .filter(item => item.type === 'Deposit')
        .every(itemIsSelected);
    } else if (selectedTab === 'expenditures') {
      allSelected = transactions
        .filter(item => item.type === 'Expenditure')
        .every(itemIsSelected);
    }

    return allSelected;
  };

  const onToggleSelectAll = (e, isChecked) => {
    const list = toggleSelectAll({
      allSelected: isChecked,
      transactions: state.transactions,
      selectedTab: state.selectedTab,
    });

    localDispatch({
      type: actions.ON_SELECTION_CHANGED,
      data: { list },
    });
  };

  const onRenderDetailsHeader = (headerProps, defaultRender) => {
    return (
      <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced>
        <div>
          <div className="search-actions">
            <SearchBox
              className="search-box"
              value={state.filterText}
              onChange={onChange('filterText')}
              clearButtonProps={{
                iconProps: {
                  iconName: 'Times',
                },
              }}
              placeholder="Search name, amount, or check number"
            />
            <ActionButton text="Add Interest" onClick={openPanel('interest')} />
            <ActionButton text="Add Fee" onClick={openPanel('fee')} />
          </div>
          <Pivot
            onLinkClick={onLinkClick}
            headersOnly
            selectedKey={state.selectedTab}
          >
            <PivotItem headerText="All" itemKey="all" />
            <PivotItem headerText="Deposits" itemKey="deposits" />
            <PivotItem
              headerText={getLabel('Expenditures', session)}
              itemKey="expenditures"
            />
          </Pivot>
          <Checkbox
            checked={getSelectAllChecked()}
            onChange={onToggleSelectAll}
            className="select-all-checkbox"
          />
          {defaultRender(headerProps)}
        </div>
      </Sticky>
    );
  };

  const onRenderItemColumn = (item, index, column) => {
    if (column.key === 'selection') {
      return (
        <Checkbox
          checked={item.isSelected}
          onChange={handleItemSelectionChanged(item._id)}
        />
      );
    }

    return null;
  };

  const { totalDeposits, totalExpenditures } = getSelectionDetails(
    state.transactions,
  );

  const outOfBalance = getOutOfBalance({
    endingBalance: state.endingStatementBalance,
    startingBalance: state.beginningStatementBalance,
    totalDeposits,
    totalExpenditures,
  });

  const renderFields = () => (
    <>
      <TextField
        className="rec-field"
        label="Reconciliation Name"
        value={state.name}
        onChange={onChange('name')}
        errorMessage={state.errors.nameError}
        required
      />
      <DatePicker
        className="rec-field"
        label="Statement Date Start"
        onChange={changeBankStatementDate('beginningStatementDate')}
        value={state.beginningStatementDate}
        errorMessage={state.errors.beginningStatementDateError}
      />
      <DatePicker
        className="rec-field"
        label="Statement Date End"
        onChange={changeBankStatementDate('endingStatementDate')}
        value={state.endingStatementDate}
        errorMessage={state.errors.endingStatementDateError}
      />
      <MoneyField
        className="rec-field"
        label="Statement Balance Start"
        onChange={onChange('beginningStatementBalance')}
        value={state.beginningStatementBalance}
        errorMessage={state.errors.beginningStatementBalanceError}
      />
      <MoneyField
        className="rec-field"
        label="Statement Balance End"
        onChange={onChange('endingStatementBalance')}
        value={state.endingStatementBalance}
        errorMessage={state.errors.endingStatementBalanceError}
      />
    </>
  );

  const renderTotals = () => (
    <div className="reconciliation-actions">
      <label className="selected-deposits">
        Deposits: <span>{`${formatCurrency(totalDeposits)}`}</span>
      </label>
      <label className="selected-expenditures">
        {`${getLabel('Expenditures', session)}:`}
        <span>{`${formatCurrency(totalExpenditures)}`}</span>
      </label>
      <label>
        Total:
        <span>{formatCurrency(totalDeposits - totalExpenditures)}</span>
      </label>
      <PrimaryButton text="Save" onClick={save} />
    </div>
  );

  const renderList = () => (
    <div className="reconciliations-list">
      <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
        <div className="list-wrapper">
          <DetailsList
            checkboxVisibility={CheckboxVisibility.hidden}
            items={items}
            columns={createColumns(
              state.sortField,
              state.sortDirection,
              onColumnClick,
            )}
            onRenderItemColumn={onRenderItemColumn}
            onRenderDetailsHeader={onRenderDetailsHeader}
          />
        </div>
      </ScrollablePane>
    </div>
  );

  return (
    <>
      <BackButton
        history={history}
        url="/filer/reconciliations"
        message="Cancel"
        isFixed
        pageTitle={`${state.isEdit ? 'Edit' : 'Add'} Reconciliation`}
      />
      <div className="AddReconciliations-wrapper ms-hiddenMdUp">
        <div className="out-of-balance-container">
          <MoneyField label="Out of Balance" value={outOfBalance} disabled />
        </div>
        <Separator />
        <Pivot>
          <PivotItem headerText="Fields">
            {renderFields()}
            {renderTotals()}
          </PivotItem>
          <PivotItem headerText="Transactions">{renderList()}</PivotItem>
        </Pivot>
      </div>
      <div className="AddReconciliations-wrapper ms-hiddenSm">
        <div className="options-panel">
          {renderFields()}
          <MoneyField label="Out of Balance" value={outOfBalance} disabled />
          <Separator />
          {renderTotals()}
        </div>
        {renderList()}
      </div>
      <BankInterestPanel
        isOpen={state.interestPanelOpen}
        onDismiss={closePanel('interest')}
        onSave={saveInterest}
        session={session}
        periodEndDate={state.endingStatementDate}
      />
      <BankFeePanel
        isOpen={state.feePanelOpen}
        onDismiss={closePanel('fee')}
        onSave={saveFee}
        session={session}
        periodEndDate={state.endingStatementDate}
      />
    </>
  );
};

AddReconciliation.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default AddReconciliation;
