import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import {
  DefaultButton,
  PrimaryButton,
} from 'office-ui-fabric-react/lib/Button';
import { Toggle } from 'office-ui-fabric-react/lib/Toggle';
import { TermsAndConditionsDialog } from '../../components/Dialogs';
import { Grid, GridRow, Column } from '../../components/common';

const SignupForm = ({
  actions,
  firstName,
  firstNameError,
  middleName,
  lastName,
  lastNameError,
  emailAddress,
  emailAddressError,
  termsAccepted,
  signupEmailUnique,
  termsAndConditionsDialogHidden,
}) => {
  return (
    <Fragment>
      <Grid>
        <GridRow>
          <Column md={4}>
            <TextField
              value={firstName}
              onChange={actions.handleChange('firstName')}
              label="First Name"
              required
              errorMessage={firstNameError}
            />
          </Column>
          <Column md={4}>
            <TextField
              value={middleName}
              onChange={actions.handleChange('middleName')}
              label="Middle Name"
            />
          </Column>
          <Column md={4}>
            <TextField
              value={lastName}
              required
              label="Last Name"
              errorMessage={lastNameError}
              onChange={actions.handleChange('lastName')}
            />
          </Column>
        </GridRow>
        <GridRow>
          <Column>
            <TextField
              label="Email Address"
              value={emailAddress}
              errorMessage={
                emailAddressError ||
                (emailAddress &&
                  signupEmailUnique === false &&
                  'Email address already in use')
              }
              onChange={actions.handleChange('emailAddress')}
              required
              onBlur={actions.checkEmailIsUnique}
            />
          </Column>
        </GridRow>
        <GridRow>
          <Column md={6}>
            <Toggle
              checked={termsAccepted}
              label="I Accept the Terms and Conditions"
              onText="Yes"
              offText="No"
              onChange={actions.toggleAcceptTandC}
            />
          </Column>
          <Column md={6} classNames="ms-textAlignRight">
            <DefaultButton
              text="View Terms"
              onClick={actions.openTandCDialog}
            />
          </Column>
        </GridRow>
        <GridRow>
          <Column classNames="ms-textAlignCenter">
            <DefaultButton
              onClick={actions.cancel}
              text="Cancel"
              className="cancel-btn"
            />
            <PrimaryButton
              text="Register"
              className="register-btn"
              onClick={actions.register}
              disabled={!termsAccepted}
            />
          </Column>
        </GridRow>
      </Grid>
      <TermsAndConditionsDialog
        acceptTermsFromDialog={actions.acceptTermsFromDialog}
        termsAndConditionsDialogHidden={termsAndConditionsDialogHidden}
        closeDialog={actions.closeTandCDialog}
      />
    </Fragment>
  );
};

SignupForm.propTypes = {
  actions: PropTypes.object.isRequired,
  firstName: PropTypes.string,
  firstNameError: PropTypes.string,
  middleName: PropTypes.string,
  lastName: PropTypes.string,
  lastNameError: PropTypes.string,
  emailAddress: PropTypes.string,
  emailAddressError: PropTypes.string,
  termsAccepted: PropTypes.bool,
  signupEmailUnique: PropTypes.bool,
  termsAndConditionsDialogHidden: PropTypes.bool.isRequired,
};

SignupForm.defaultProps = {
  firstName: '',
  firstNameError: '',
  middleName: '',
  lastName: '',
  lastNameError: '',
  emailAddress: '',
  emailAddressError: '',
  termsAccepted: false,
  signupEmailUnique: true,
};

export default SignupForm;
