import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogType,
  DialogFooter,
} from 'office-ui-fabric-react/lib/Dialog';
import {
  PrimaryButton,
} from 'office-ui-fabric-react/lib/Button';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import { formatTime } from '../../helpers/util';

import './LongRunningProcessDialog.css';

const LongRunningProcessDialog = ({
  dialogHidden,
  onClose,
  prettyJobName,
  isProcessing,
  processResultStatus,
  messageList,
  error,
}) => {
  const callResult = processResultStatus === 'error'
    ? <span className="error">- Error Making Request</span>
    : ''; // <span className="success">Successful Request</span>;
  const title = isProcessing ? (
    <div style={{ display: 'flex' }}>{prettyJobName} Request <Spinner className="file-submit-spinner" size={SpinnerSize.medium} /></div>
  ) : <span>{prettyJobName} {callResult}</span>;

  return (
    <Dialog
      hidden={dialogHidden}
      onDismiss={onClose}
      dialogContentProps={{
        showCloseButton: false,
        type: DialogType.normal,
        title,
        subText: '',
      }}
      minWidth={600}
      maxWidth={750}
      modalProps={{
        isBlocking: true,
        containerClassName: 'LongRunningServiceDialog',
      }}
    >
      <div>
        <ul style={{ paddingBottom: 16 }}>
          {messageList.length > 0 &&
          messageList.map(m => <li className={m.messageType}>{m.message} - {formatTime(m.at)}</li>)
          }
        </ul>
        {error &&
        <div className="error" style={{ marginTop: '16px' }}>
          <h3>Unable to make request</h3>
          <pre style={{ whiteSpace: 'pre-wrap' }}>
            {JSON.stringify(error, null, 2)}
          </pre>
        </div>
        }
      </div>
      <DialogFooter>
        <PrimaryButton disabled={isProcessing} text="Close" onClick={onClose} />
      </DialogFooter>
    </Dialog>
  );
};

LongRunningProcessDialog.propTypes = {
  prettyJobName: PropTypes.string,
  dialogHidden: PropTypes.bool,
  onClose: PropTypes.func,
  isProcessing: PropTypes.bool,
  processResultStatus: PropTypes.string,
  messageList: PropTypes.array,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

LongRunningProcessDialog.defaultProps = {
  prettyJobName: 'Long Running Request',
  dialogHidden: true,
  isProcessing: false,
  processResultStatus: '',
  onClose: () => {},
  messageList: [],
  error: '',
};

export default LongRunningProcessDialog;
