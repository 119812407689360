import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { IconButton } from 'office-ui-fabric-react/lib/Button';
import './PasswordField.css';

const PasswordField = ({
  value,
  onChange,
  onBlur,
  classNames,
  style,
  label,
  required,
  placeholder,
  errorMessage,
  name,
  autofocus,
  underlined,
  componentRef,
}) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <span className={`PasswordField${underlined ? ' underlined' : ''}`}>
      <TextField
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        className={classNames}
        style={style}
        label={label}
        required={required}
        placeholder={placeholder}
        type={isVisible ? 'text' : 'password'}
        errorMessage={errorMessage}
        name={name}
        underlined={underlined}
        autofocus={autofocus}
        componentRef={componentRef}
      />
      <IconButton
        tabIndex={-1}
        iconProps={{
          iconName: isVisible ? 'EyeSlash' : 'Eye',
        }}
        onClick={() => setIsVisible(!isVisible)}
        className="visibility-button"
      />
    </span>
  );
};

PasswordField.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  classNames: PropTypes.string,
  style: PropTypes.object,
  label: PropTypes.string,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  errorMessage: PropTypes.string,
  name: PropTypes.string,
  underlined: PropTypes.bool,
  autofocus: PropTypes.bool,
  componentRef: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
    PropTypes.func,
  ]),
};

PasswordField.defaultProps = {
  value: '',
  onChange: () => {},
  classNames: '',
  style: {},
  label: '',
  required: false,
  placeholder: '',
  errorMessage: '',
  name: '',
  underlined: false,
  autofocus: false,
};

export default PasswordField;
