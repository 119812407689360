/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { DefaultButton, PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import { Checkbox } from 'office-ui-fabric-react/lib/Checkbox';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { types as contactMergeActions } from '../../actions/contactMergeActions';
import { BackButton } from '../../components/common';
import { formatDateTime } from '../../helpers/util';
import { statuses } from '../../helpers/constants';

import {
  getPossibleContactsForMerge,
  getPossibleContactsForMergeStatus,
} from '../../selectors';
import {
  getFlatDecoratedListFromContactToMerge,
  fillInEmptyAttributes,
  recordsInMerge,
  recordsNotInMerge,
  getContactFromList,
  createUpdateData,
  getListExcludingContact,
} from './helper';
import './index.css';

export const ContactMerge = ({ history }) => {
  const reduxDispatch = useDispatch();
  const contactsForMerge = useSelector(getPossibleContactsForMerge);
  const contactsForMergeStatus = useSelector(getPossibleContactsForMergeStatus);

  const [state, setState] = useState({
    contactsForMerge: [],
    contactMetaRecord: {},
    recordToIgnoreIfNoMerge: {},
    processing: false,
    noContactsToMerge: false,
  });

  const getNextContactToMerge = () => {
    reduxDispatch({ type: contactMergeActions.GET_CONTACT_TO_MERGE });
  };

  useEffect(() => {
    getNextContactToMerge();
  }, []);

  const getContactMetaRecord = (contacts) => {
    const mergeRecords = recordsInMerge(contacts);
    if (!mergeRecords.length) {
      return {};
    }
    return fillInEmptyAttributes(mergeRecords[0], mergeRecords);
  };

  useEffect(() => {
    if (contactsForMergeStatus === statuses.SUCCESS) {
      if (!contactsForMerge) {
        setState({
          ...state,
          noContactsToMerge: true,
        });
      } else {
        const contacts = getFlatDecoratedListFromContactToMerge(contactsForMerge);
        const contactMetaRecord = getContactMetaRecord(contacts);
        setState({
          ...state,
          contactsForMerge: contacts,
          contactMetaRecord,
          recordToIgnoreIfNoMerge: contactsForMerge._id,
        });
      }
    }
  }, [contactsForMerge, contactsForMergeStatus]);

  const toggleInMerge = (id) => {
    const contacts = [...state.contactsForMerge];
    const contact = getContactFromList(contacts, id);
    contact.isPartOfMerge = !contact.isPartOfMerge;
    const contactMetaRecord = getContactMetaRecord(contacts);
    setState(state => {
      return {
        ...state,
        contactsForMerge: contacts,
        contactMetaRecord,
      };
    });
  };

  const mergeContact = async () => {
    const { url, payload, primaryContactId } = createUpdateData(state.contactMetaRecord, state.contactsForMerge);
    try {
      setState({
        ...state,
        processing: true,
      });
      await axios.post(url, payload, {
        withCredentials: true,
      });
      setState({
        ...state,
        processing: false,
      });
      getNextContactToMerge();
    } catch (e) {
      setState({
        ...state,
        processing: false,
      });
    }
  };

  const noMerge = async () => {
    try {
      const res = await axios.patch(`/api/filer/duplicateContacts/${state.recordToIgnoreIfNoMerge}`, {
        withCredentials: true,
      });
      getNextContactToMerge();
    } catch (e) {
      setState({
        ...state,
        processing: false,
      });
    }
  };

  const togglePinItem = (c, attr) => {
    const contacts = JSON.parse(JSON.stringify(state.contactsForMerge));
    const con = getContactFromList(contacts, c._id);
    // Clean up any pinned in other rows
    let other = getListExcludingContact(contacts, c._id);
    other = other.map(c => {
      c[attr].isPinned = false;
      return c;
    });
    // Toggle this one
    con[attr].isPinned = !con[attr].isPinned;
    const contactMetaRecord = getContactMetaRecord(contacts);
    setState({
      ...state,
      contactsForMerge: contacts,
      contactMetaRecord,
    });
  };

  // get class
  const gc = (c, attr, defaultClasses) => {
    return c[attr]?.isPinned ? `${defaultClasses} is-pinned` : `${defaultClasses}`;
  };

  if (state.noContactsToMerge) {
    return (
      <>
        <BackButton
          history={history}
          pageTitle="Merge Contacts"
        />
        <div className="ContactMerge">
          <header className="contact-merge-header" />
          <h2>Merge Contacts</h2>
          <h3>No contacts found that require a merge.</h3>
        </div>
      </>
    );
  }

  return (
    <>
      <BackButton
        history={history}
        pageTitle="Merge Contacts"
      />
      <div className="ContactMerge">
        <header className="contact-merge-header" />
        <h2>Merge Contacts</h2>
        <h3>Contacts that will be merged into one contact</h3>
        <div style={{ fontSize: '12px', marginTop: '-12px', marginBottom: '8px' }}>Click on an entry to include it in the final record</div>
        <div className="view-all-contributions-content">
          <div className="row merge-header-row" key="merge-header-row">
            <div className="row-check">
              &nbsp;
            </div>
            <div className="row-item-other">
              Name
            </div>
            <div className="row-item-large-other">
              Address
            </div>
            <div className="row-item-other">
              Email
            </div>
            <div className="row-item-other">
              Phone
            </div>
            <div className="row-item-other">
              Occupation
            </div>
            <div className="row-item-other">
              Employer
            </div>
            <div className="row-item-other">
              Created/Updated
            </div>
          </div>
          {recordsInMerge(state.contactsForMerge).map(c => {
              return (
                <div className="row" key={c._id}>
                  <div className="row-check">
                    <Checkbox
                      checked={c.isPartOfMerge}
                      onChange={() => toggleInMerge(c._id)}
                    />
                  </div>
                  <div
                    className={gc(c, 'displayName', 'row-item')}
                    onClick={() => togglePinItem(c, 'displayName')}
                  >
                    {c.displayName.value}
                  </div>

                  <div
                    className={gc(c, 'formattedAddress', 'row-item-large')}
                    onClick={() => togglePinItem(c, 'formattedAddress')}
                  >
                    {c?.formattedAddress.value}
                  </div>

                  <div
                    className={gc(c, 'email', 'row-item')}
                    onClick={() => togglePinItem(c, 'email')}
                  >
                    {c.email.value}
                  </div>

                  <div
                    className={gc(c, 'phone1', 'row-item')}
                    onClick={() => togglePinItem(c, 'phone1')}
                  >
                    {c.phone1.value}
                  </div>

                  <div
                    className={gc(c, 'occupation', 'row-item')}
                    onClick={() => togglePinItem(c, 'occupation')}
                  >
                    {c.occupation.value}
                  </div>

                  <div
                    className={gc(c, 'employer', 'row-item')}
                    onClick={() => togglePinItem(c, 'employer')}
                  >
                    {c.employer.value}
                  </div>

                  <div className="row-item">
                    {formatDateTime(c.updatedAt.value, 'MM/DD/YYYY')}
                  </div>
                </div>
              );
            },
          )}
          <div className="row" style={{ width: '100%', height: '36px' }}>
            &nbsp;
          </div>
          <div className="row merge-header-row" key={state.contactMetaRecord._id}>
            <div className="row-check">
              <Icon
                iconName="ArrowRight"
                styles={{
                  root: {
                    fontSize: '14px',
                    backgroundColor: 'rgb(36, 177, 141)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 36,
                    height: 24,
                    color: '#fff',
                    borderRadius: '50%',
                  },
                }}
              />
            </div>
            <div className="row-item-other">
              {state.contactMetaRecord?.displayName?.value}
            </div>
            <div className="row-item-large-other">
              {state.contactMetaRecord?.formattedAddress?.value}
            </div>
            <div className="row-item-other">
              {state.contactMetaRecord?.email?.value}
            </div>
            <div className="row-item-other">
              {state.contactMetaRecord?.phone1?.value}
            </div>
            <div className="row-item-other">
              {state.contactMetaRecord?.occupation?.value}
            </div>
            <div className="row-item-other">
              {state.contactMetaRecord?.employer?.value}
            </div>
            <div className="row-item-other">
              {state.contactMetaRecord?.updatedAt?.value ? formatDateTime(state.contactMetaRecord?.updatedAt?.value, 'MM/DD/YYYY') : ''}
            </div>
          </div>
          <div className="button-div">
            <DefaultButton
              text="Ignore This Merge"
              onClick={noMerge}
              disabled={recordsInMerge(state.contactsForMerge).length < 1 || state.processing}
            />
            <PrimaryButton
              style={{ marginLeft: '12px' }}
              text={state.processing ? 'Merging...' : 'Merge Contacts'}
              onClick={mergeContact}
              disabled={recordsInMerge(state.contactsForMerge).length < 2 || state.processing}
            />
          </div>
        </div>
        {recordsNotInMerge(state.contactsForMerge).length > 0 &&
        <div>
          <h3 style={{ paddingTop: '36px' }}>Not included in merge</h3>
          <div className="view-all-contributions-content">
            {recordsNotInMerge(state.contactsForMerge).map(c => {
                return (
                  <div className="row" key={c._id}>
                    <div className="row-check">
                      <Checkbox
                        checked={c.isPartOfMerge}
                        onChange={() => toggleInMerge(c._id)}
                      />
                    </div>
                    <div className="row-item">
                      {c.displayName.value}
                    </div>
                    <div className="row-item-large">
                      {c?.formattedAddress.value}
                    </div>
                    <div className="row-item">
                      {c.email.value}
                    </div>
                    <div className="row-item">
                      {c.phone1.value}
                    </div>
                    <div className="row-item">
                      {c.occupation.value}
                    </div>
                    <div className="row-item">
                      {c.employer.value}
                    </div>
                    <div className="row-item">
                      {formatDateTime(c.updatedAt.value)}
                    </div>
                  </div>
                );
              },
            )}
          </div>
        </div>
        }
      </div>
    </>
  );
};

ContactMerge.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(ContactMerge);
