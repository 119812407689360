import {
  all,
  select,
  takeEvery,
  takeLatest,
  call,
  put,
} from 'redux-saga/effects';
import axios from 'axios';
import { push } from 'connected-react-router';
import { types as userTypes } from '../actions/userActions';
import { types as currentCampaignTypes } from '../actions/currentCampaignActions';
import { types as electionCycleTypes } from '../actions/electionCycleActions';
import { types as anedotTypes } from '../actions/anedotActions';
import { getServerSideErrorMessage, errorToast } from '../helpers/util';
import { getUserSession } from '../selectors';
import Campaign from '../models/Campaign';

export function* handleGetCurrentCampaign() {
  try {
    yield put({
      type: currentCampaignTypes.GET_CURRENT_CAMPAIGN_PROCESSING,
    });
    const { data: campaign } = yield call(
      axios.get,
      '/api/filer/currentCampaign',
      {
        withCredentials: true,
      },
    );
    yield put({
      type: currentCampaignTypes.GET_CURRENT_CAMPAIGN_FINANCE,
    });
    yield put({ type: electionCycleTypes.GET_ELECTION_CYCLES });
    if (campaign) {
      const { data } = yield call(axios.get, '/api/intercom');
      window.Intercom('boot', { ...data });
      yield put({
        type: currentCampaignTypes.GET_CURRENT_CAMPAIGN_SUCCESS,
        campaign: new Campaign(campaign),
      });
    }
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put({
      type: currentCampaignTypes.GET_CURRENT_CAMPAIGN_FAILURE,
      error,
    });
  }
}

export function* getCurrentCampaign() {
  yield all([
    takeLatest(
      currentCampaignTypes.GET_CURRENT_CAMPAIGN,
      handleGetCurrentCampaign,
    ),
    takeEvery(
      anedotTypes.UPDATE_ANEDOT_CONTRIBUTION_SUCCESS,
      handleGetCurrentCampaign,
    ),
  ]);
}

export function* handleGetCurrentCampaignFinances() {
  try {
    const { data: campaignFinances } = yield call(
      axios.get,
      '/api/filer/currentCampaignFinanceStats',
      {
        withCredentials: true,
      },
    );
    yield put({
      type: currentCampaignTypes.GET_CURRENT_CAMPAIGN_FINANCE_SUCCESS,
      campaignFinances,
    });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put({
      type: currentCampaignTypes.GET_CURRENT_CAMPAIGN_FINANCE_FAILURE,
      error,
    });
  }
}

export function* getCurrentCampaignFinances() {
  yield takeLatest(
    currentCampaignTypes.GET_CURRENT_CAMPAIGN_FINANCE,
    handleGetCurrentCampaignFinances,
  );
}

export function* handleRefreshCurrentCampaignFinances() {
  try {
    yield put({
      type: currentCampaignTypes.GET_CURRENT_CAMPAIGN_FINANCE_PROCESSING,
    });
    const { data: campaignFinances } = yield call(
      axios.get,
      '/api/filer/currentCampaignFinanceStats?refresh=true',
      {
        withCredentials: true,
      },
    );
    yield put({
      type: currentCampaignTypes.GET_CURRENT_CAMPAIGN_FINANCE_SUCCESS,
      campaignFinances,
    });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put({
      type: currentCampaignTypes.GET_CURRENT_CAMPAIGN_FINANCE_FAILURE,
      error,
    });
  }
}

export function* refreshCurrentCampaignFinances() {
  yield takeLatest(
    currentCampaignTypes.REFRESH_CURRENT_CAMPAIGN_FINANCE,
    handleRefreshCurrentCampaignFinances,
  );
}

export function* handleGetAllCampaigns() {
  try {
    const { data: campaigns } = yield call(axios.get, '/api/filer/campaigns', {
      withCredentials: true,
    });
    yield put({
      type: currentCampaignTypes.GET_ALL_CAMPAIGNS_SUCCESS,
      data: { campaigns },
    });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put(errorToast(error));
  }
}

export function* getAllCampaigns() {
  yield takeLatest(
    currentCampaignTypes.GET_ALL_CAMPAIGNS,
    handleGetAllCampaigns,
  );
}

export function* handleAdminSelectCampaign(action) {
  try {
    const { id } = action;
    if (id) {
      const { data } = yield call(
        axios.get,
        `/api/filer/campaignContext/${id}`,
        { withCredentials: true },
      );
      const { data: intercomData } = yield call(axios.get, '/api/intercom');
      window.Intercom('boot', { ...intercomData });
      const currentSession = yield select(getUserSession);
      const session = currentSession.update(data);
      yield put({ type: 'RESET_STATE' });
      yield put({
        type: userTypes.USER_SELECT_CAMPAIGN_SUCCESS,
        data: { session },
      });
      yield put({ type: currentCampaignTypes.GET_CURRENT_CAMPAIGN });
      yield put(push('/filer'));
    }
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put(errorToast(error));
  }
}

export function* adminSelectCampaign() {
  yield takeLatest(
    currentCampaignTypes.ADMIN_SELECT_CAMPAIGN,
    handleAdminSelectCampaign,
  );
}
