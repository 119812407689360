import { put, take, call, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import { push } from 'connected-react-router';
import { types as currentCampaignTypes } from '../actions/currentCampaignActions';
import { types as messageTypes } from '../actions/messagingActions';
import { types as paymentTypes } from '../actions/paymentActions';
import { getServerSideErrorMessage, errorToast } from '../helpers/util';
import { toastTypes } from '../helpers/constants';

export function* handleGetPlanPrice(action) {
  try {
    yield put({ type: paymentTypes.GET_PLAN_PRICE_PROCESSING });
    const { payload } = action;
    const {
      data: { productName, planPrice, planId },
    } = yield call(axios.post, '/payment/plan', payload, {
      withCredentials: true,
    });
    yield put({
      type: paymentTypes.GET_PLAN_PRICE_SUCCESS,
      pricingPlan: { productName, planPrice, planId },
    });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put({ type: paymentTypes.GET_PLAN_PRICE_FAILURE, error });
  }
}

export function* getPlanPrice() {
  yield takeLatest(paymentTypes.GET_PLAN_PRICE, handleGetPlanPrice);
}

export function* handleGetStripeToken(action) {
  try {
    const {
      data: { stripe = {}, cardholderName = '', zipCode = '' },
    } = action;

    const { token, error } = yield call(stripe.createToken, {
      name: cardholderName,
      address_zip: zipCode,
    });
    if (token) {
      yield put({
        type: paymentTypes.GET_STRIPE_TOKEN_SUCCESS,
        data: {
          token: token.id,
          billingZipCode: zipCode,
          last4: token.card.last4,
        },
      });
    } else if (error && error.message) {
      yield put({
        type: paymentTypes.GET_STRIPE_TOKEN_FAILURE,
        error: error.message,
      });
    }
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put({ type: paymentTypes.GET_STRIPE_TOKEN_FAILURE, error });
  }
}

export function* getStripeToken() {
  yield takeLatest(paymentTypes.GET_STRIPE_TOKEN, handleGetStripeToken);
}

export function* handleGetCCDetails() {
  try {
    const { data } = yield call(axios.get, '/api/filer/getCampaignCCDetails', {
      withCredentials: true,
    });
    yield put({ type: paymentTypes.GET_CC_DETAILS_SUCCESS, data });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put({ type: paymentTypes.GET_CC_DETAILS_FAILURE, error });
  }
}

export function* getCCDetails() {
  yield takeLatest(paymentTypes.GET_CC_DETAILS, handleGetCCDetails);
}

export function* handleSaveCCDetails(action) {
  try {
    yield put({ type: paymentTypes.SAVE_CC_DETAILS_PROCESSING });
    const { payload } = action;
    const { token: stripeToken, error: stripeError } = yield call(
      payload.stripe.createToken,
      { name: payload.cardholderName },
    );
    if (stripeError) {
      yield put({
        type: paymentTypes.SAVE_CC_DETAILS_FAILURE,
        stripeError: stripeError.message,
      });
    } else {
      const {
        data: { message },
      } = yield call(
        axios.post,
        '/payment/customer',
        {
          plan: payload.plan,
          cardholderName: payload.cardholderName,
          cardholderEmail: payload.cardholderEmail,
          stripeToken: stripeToken.id,
          lastFour: stripeToken.card.last4,
          coupon: payload.coupon,
        },
        { withCredentials: true },
      );
      yield put({ type: paymentTypes.SAVE_CC_DETAILS_SUCCESS, message });
      yield put({ type: currentCampaignTypes.GET_CURRENT_CAMPAIGN_FINANCE });
      yield put(push('/filer'));
      yield put({
        type: messageTypes.SET_TOAST,
        data: { toastType: toastTypes.SUCCESS, message },
      });
    }
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put({
      type: paymentTypes.SAVE_CC_DETAILS_FAILURE,
      submitError: error,
    });
  }
}

export function* saveCCDetails() {
  yield takeLatest(paymentTypes.SAVE_CC_DETAILS, handleSaveCCDetails);
}

export function* cancelAccount() {
  try {
    yield take(paymentTypes.CANCEL_ACCOUNT);
    yield put({ type: paymentTypes.CANCEL_ACCOUNT_PROCESSING });
    yield call(axios.delete, '/payment/customer/delete', {
      withCredentials: true,
    });
    yield put({ type: currentCampaignTypes.GET_CURRENT_CAMPAIGN_FINANCE });
    yield put(push('/filer'));
    yield put({
      type: messageTypes.SET_TOAST,
      data: {
        toastType: toastTypes.SUCCESS,
        message: "You've successfully cancelled your account",
      },
    });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put({ type: paymentTypes.CANCEL_ACCOUNT_FAILURE, error });
  }
}

export function* handleGetPublishableKey() {
  try {
    const { data: publishableKey } = yield call(
      axios.get,
      '/payment/publishableKey',
      { withCredentials: true },
    );
    yield put({
      type: paymentTypes.GET_PUBLISHABLE_KEY_SUCCESS,
      publishableKey,
    });
  } catch (e) {
    if (e.response && e.response.status && e.response.status === 401) {
      yield put(push('/'));
    } else {
      const error = getServerSideErrorMessage(e);
      yield put(errorToast(error));
    }
  }
}

export function* getPublishableKey() {
  yield takeLatest(paymentTypes.GET_PUBLISHABLE_KEY, handleGetPublishableKey);
}
