import { ChoiceGroup, PrimaryButton } from 'office-ui-fabric-react';
import PropTypes from 'prop-types';
import React from 'react';
import { ImportDataType } from '../../../../helpers/constants';
import { fileFormatOptionsByDataType } from './utils';

const FileFormatStep = ({ fileFormat, dataType, onNext }) => {
  const [selectedFileFormat, setSelectedFileFormat] = React.useState(
    fileFormat,
  );

  const handleChange = (event, option) => {
    setSelectedFileFormat(option.key);
  };

  const handleNextBttnClick = () => {
    onNext(selectedFileFormat);
  };

  return (
    <div>
      <ChoiceGroup
        selectedKey={selectedFileFormat}
        options={fileFormatOptionsByDataType[dataType]}
        onChange={handleChange}
        label="1. Where did you get the import file from?"
      />
      <div className="import-wizard-step-bttns">
        <PrimaryButton
          disabled={!selectedFileFormat}
          onClick={handleNextBttnClick}
          text="Next"
        />
      </div>
    </div>
  );
};

FileFormatStep.propTypes = {
  fileFormat: PropTypes.string,
  dataType: PropTypes.oneOf(Object.values(ImportDataType)).isRequired,
  onNext: PropTypes.func.isRequired,
};

export default FileFormatStep;
