import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { GridRow, Column, MoneyField } from '../../common';

const FECSection11aii = ({ q11aiiColA, q11aiiColB }) => {
  return (
    <Fragment>
      <GridRow>
        <Column sm={4} classNames="sub-question">
          <p className="sub-question">(ii) Unitemized</p>
        </Column>
        <Column sm={4} classNames="entry-row">
          <MoneyField
            disabled
            value={q11aiiColA || 0}
          />
        </Column>
        <Column sm={4} classNames="entry-row">
          <MoneyField
            disabled
            value={q11aiiColB || 0}
          />
        </Column>
      </GridRow>
    </Fragment>
  );
};

FECSection11aii.propTypes = {
  q11aiiColA: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  q11aiiColB: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default FECSection11aii;
