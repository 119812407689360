import React from 'react';
import PropTypes from 'prop-types';
import { GridRow, Column, MoneyField } from '../../common';
import { getTotalRefunds } from '../../../pages/Reports/fecReportHelper';

const FECSection20d = ({ state }) => (
  <GridRow>
    <Column sm={4} classNames="sub-question">
      <p>
        (d) TOTAL CONTRIBUTION REFUNDS
        <br />
        {'(add Lines 20(a), (b), and (c))'}
      </p>
    </Column>
    <Column sm={4} classNames="entry-row">
      <MoneyField disabled value={getTotalRefunds('A', state) || 0} />
    </Column>
    <Column sm={4} classNames="entry-row">
      <MoneyField disabled value={getTotalRefunds('B', state) || 0} />
    </Column>
  </GridRow>
);

FECSection20d.propTypes = {
  state: PropTypes.object.isRequired,
};

export default FECSection20d;
