import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { TrustTypePicker } from '../../Pickers';

export const TrustForm = ({
  handleChange,
  contactName = '',
  businessName = '',
  trustee = '',
  trustor = '',
  isLivingOrRevokableTrust = 'Y',
}) => (
  <Fragment>
    <TextField
      label="Trust Name"
      value={businessName}
      onChange={handleChange('businessName')}
    />
    <TextField
      label="Contact Name"
      value={contactName}
      onChange={handleChange('contactName')}
    />
    <TextField
      label="Trustee"
      value={trustee}
      onChange={handleChange('trustee')}
    />
    <TextField
      label="Trustor"
      value={trustor}
      onChange={handleChange('trustor')}
    />

    <TrustTypePicker
      selectedKey={isLivingOrRevokableTrust}
      onChange={handleChange('isLivingOrRevokableTrust')}
    />
  </Fragment>
  );

TrustForm.propTypes = {
  handleChange: PropTypes.func.isRequired,
  contactName: PropTypes.string,
  businessName: PropTypes.string,
  trustee: PropTypes.string,
  trustor: PropTypes.string,
  isLivingOrRevokableTrust: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};
