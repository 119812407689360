import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { Checkbox } from 'office-ui-fabric-react/lib/Checkbox';
import {
  DefaultButton,
  PrimaryButton,
} from 'office-ui-fabric-react/lib/Button';
import UserRolePicker from '../Pickers/UserRolePicker';
import { Grid, GridRow, Column, EmailField } from '../common';

const AddEditUserForm = ({
  user,
  actions,
  firstNameError,
  lastNameError,
  emailError,
  roleError,
  actionType,
}) => (
  <Grid>
    <GridRow>
      <Column lg={7}>
        <Grid>
          <GridRow>
            <Column lg={6}>
              <TextField
                label="First Name"
                value={user.firstName}
                required
                errorMessage={firstNameError}
                onChange={actions.handleChange('firstName')}
              />
            </Column>
            <Column lg={6}>
              <TextField
                label="Middle Name"
                value={user.middleName}
                onChange={actions.handleChange('middleName')}
              />
            </Column>
          </GridRow>
          <GridRow>
            <Column lg={6}>
              <TextField
                label="Last Name"
                value={user.lastName}
                required
                errorMessage={lastNameError}
                onChange={actions.handleChange('lastName')}
              />
            </Column>
            <Column lg={6}>
              <EmailField
                value={user.email}
                required
                errorMessage={emailError}
                onChange={actions.handleChange('email')}
              />
            </Column>
          </GridRow>
          <GridRow>
            <Column lg={6}>
              <UserRolePicker
                errorMessage={roleError}
                required={true}
                selectedKey={user.role}
                onChange={actions.handleChange('role')}
              />
            </Column>
            {actionType === 'edit' && (
              <Column lg={6} style={{ paddingTop: '30px' }}>
                <Checkbox
                  checked={user.active}
                  onChange={actions.toggleActive}
                  label="Active"
                />
              </Column>
            )}
          </GridRow>
        </Grid>
      </Column>
      <Column lg={5}>
        <div className="add-edit-user-actions">
          <DefaultButton text="Cancel" onClick={actions.cancel} />
          <PrimaryButton
            text="Save"
            iconProps={{
              iconName: actionType === 'add' ? 'Plus' : 'PencilAlt',
            }}
            onClick={actions.saveUser}
            className="save-btn"
          />
        </div>
      </Column>
    </GridRow>
  </Grid>
);

AddEditUserForm.propTypes = {
  actionType: PropTypes.oneOf(['add', 'edit']),
  user: PropTypes.object,
  firstNameError: PropTypes.string,
  lastNameError: PropTypes.string,
  roleError: PropTypes.string,
  emailError: PropTypes.string,
  actions: PropTypes.object.isRequired,
};

AddEditUserForm.defaultProps = {
  actionType: 'add',
  user: {
    firstName: '',
    middleName: '',
    lastName: '',
    email: '',
  },
  firstNameError: '',
  lastNameError: '',
  roleError: '',
  emailError: '',
};

export default AddEditUserForm;
