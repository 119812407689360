import { emailRegex } from '../../helpers/constants';

export const validate = (state, session) => {
  const errors = {
    firstName: '',
    lastName: '',
    businessName: '',
    fiduciaryRelationship: '',
    amountGuaranteedOutstanding: '',
    selectedContactError: '',
  };

  if (['IND', 'Individual', 'CAN'].includes(state.responsiblePartyType)) {
    if (state.firstName.length === 0) {
      errors.firstName = 'First name is required';
    }

    if (state.lastName.length === 0) {
      errors.lastName = 'Last name is required';
    }
  }

  if (state.selectedContact === null) {
    if (state.responsiblePartyType === 0) {
      errors.selectedContactError =
        'Please choose a contact or create a new one';
    } else {
      errors.selectedContactError = '';
    }
  } else {
    errors.selectedContactError = '';
  }

  if (state.email && !emailRegex.test(state.email)) {
    errors.email = 'Please use a valid email address';
  } else {
    errors.email = '';
  }

  if (
    ['Business', 'ORG', 'PTY', 'Common Source'].includes(
      state.responsiblePartyType,
    )
  ) {
    if (session.isFederal()) {
      if (state.businessName.length === 0) {
        errors.businessName = 'Organization name is required';
      }
    } else if (state.businessName.length === 0) {
      errors.businessName = 'Business name is required';
    }
  }

  if (
    ['CCM', 'COM', 'PAC', 'Committee', 'Candidate Committee'].includes(
      state.responsiblePartyType,
    )
  ) {
    if (state.businessName.length === 0) {
      errors.businessName = 'Business name is required';
    }
  }

  if (session.isFederal()) {
    if (state.amountGuaranteedOutstanding.length === 0) {
      errors.amountGuaranteedOutstanding =
        'Amount guaranteed outstanding is required';
      // eslint-disable-next-line no-restricted-globals
    } else if (isNaN(state.amountGuaranteedOutstanding)) {
      errors.amountGuaranteedOutstanding = 'Not a valid number or amount';
    }
  } else if (state.fiduciaryRelationship.length === 0) {
    errors.fiduciaryRelationship = 'Fiduciary relationship is required';
  }

  return errors;
};
