import React, { Fragment, Component } from 'react';

import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ActionButton } from 'office-ui-fabric-react/lib/Button';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import {
  DetailsList,
  DetailsRow,
  CheckboxVisibility,
  SelectionMode,
  DetailsListLayoutMode,
} from 'office-ui-fabric-react/lib/DetailsList';
import { actions as reportActions } from '../../actions/reportActions';
import { actions as longRunningProcessActions } from '../../actions/longRunningProcessActions';
import { formatDate, formatUtcDate } from '../../helpers/util';
import {
  AddGAReportDialogTBD,
  AddReportDialog,
  GenericMessageDialog,
} from '../../components/Dialogs';
import { BackButton } from '../../components/common';
import { statuses } from '../../helpers/constants';
import ReportViewPicker from '../../components/Pickers/ReportViewPicker';
import './ViewAll.css';
import { getReportTypeByItemForState, getVersionNonFec } from '../../helpers/reportHelper';

export class TBDViewAllReports extends Component {
  static propTypes = {
    campaign: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    longRunningProcess: PropTypes.object.isRequired,
    longRunningProcessActions: PropTypes.object.isRequired,
    reportActions: PropTypes.object.isRequired,
    reports: PropTypes.object.isRequired,
    session: PropTypes.object.isRequired,
  };

  state = {
    createReportDialogHidden: true,
    gaMessageHidden: true,
  };

  componentDidMount() {
    this.props.reportActions.getDraftReports('TBD Contribution Report');
    this.props.reportActions.getFiledReports('TBD Contribution Report');
  }

  componentWillUnmount() {
    this.props.reportActions.clearReports();
  }

  openCreateReportDialog = () => {
    this.setState({
      gaMessageHidden: false,
      // createReportDialogHidden: false,
    });
  };

  openCreateReportDialog = () => {
    this.setState({
      createReportDialogHidden: false,
    });
  };

  closeCreateReportDialog = () => {
    this.setState({
      createReportDialogHidden: true,
    });
  };

  setJobRunResult = () => {
    this.props.reportActions.getDraftReports('TBD Contribution Report');
    this.props.reportActions.getFiledReports('TBD Contribution Report');
    this.closeCreateReportDialog();
  };

  componentDidUpdate(prevProps) {
    const previousStatus = prevProps?.longRunningProcess?.status;
    const currentStatus = this.props.longRunningProcess?.status;
    // const currentResultPayload = this.props.longRunningProcess?.returnedFromServer;

    if (previousStatus === statuses.PROCESSING && currentStatus !== statuses.PROCESSING) {
      this.setJobRunResult();
    }
  }

  createTBDReport = reportDetails => {
    const { reportDate, electionDateKey, electionCycleId } = reportDetails;
    const electionDateOption = [...this.props.campaign.electionDates].filter(d => d._id === electionDateKey);

    const { longRunningProcessActions } = this.props;
    longRunningProcessActions.longRunningProcess(
      'create-ga-tbd',
      'Create Georgia TBD Report',
      {
        reportDate,
        electionDate: electionDateOption[0].electionDate,
        electionCycleId,
      },
    );
  };

  createColumnsDraft = () => {
    return [
      {
        key: 'report',
        name: 'Report',
        minWidth: 400,
        maxWidth: 420,
        onRender: item => getReportTypeByItemForState(item),
      },
      {
        key: 'report-start-date',
        name: 'Start Date',
        minWidth: 100,
        maxWidth: 100,
        onRender: item => <span>{formatUtcDate(item.startDate)}</span>,
      },
      {
        key: 'report-end-date',
        name: 'End Date',
        minWidth: 100,
        maxWidth: 100,
        onRender: item => <span>{formatUtcDate(item.endDate)}</span>,
      },
    ];
  };

  createFiledColumns = () => [
    {
      key: 'report',
      name: 'Report',
      minWidth: 400,
      maxWidth: 420,
      onRender: item => getReportTypeByItemForState(item),
    },
    {
      key: 'view',
      name: '',
      minWidth: 125,
      maxWidth: 140,
      onRender: item => {
        const tbdFiledLocation = `/api/filer/reports/${item._id}/tbdFiled`;
        const tbdReceiptLocation = `/api/filer/reports/${item._id}/tbdFiled?receipt=true`;
        return (
          <div className="filed-report-actions">
            <ReportViewPicker
              tbdFiledLocation={tbdFiledLocation}
              tbdReceiptLocation={tbdReceiptLocation}
            />
          </div>
        );
      },
    },
    {
      key: 'version',
      name: 'Version',
      minWidth: 220,
      maxWidth: 220,
      onRender: item => getVersionNonFec(item, true),
    },
    {
      key: 'report-start-date',
      name: 'Start Date',
      minWidth: 100,
      maxWidth: 100,
      onRender: item => <span>{formatUtcDate(item.startDate)}</span>,
    },
    {
      key: 'report-end-date',
      name: 'End Date',
      minWidth: 100,
      maxWidth: 100,
      onRender: item => <span>{formatUtcDate(item.endDate)}</span>,
    },
    {
      key: 'report-date',
      name: 'Date Filed',
      minWidth: 100,
      maxWidth: 100,
      onRender: item => <span>{formatDate(item.reportFiledAt)}</span>,
    },
  ];

  mapDraftReports = () => {
    const {
      reports: { draftReports },
    } = this.props;
    return draftReports.map(dr => ({
      ...dr,
      key: dr._id,
    }));
  };

  mapFiledReports = () => {
    const {
      reports: { filedReports },
    } = this.props;
    return filedReports.map(fr => ({
      ...fr,
      key: fr._id,
    }));
  };

  onRenderRow = props => {
    return (
      <div onClick={() => this.onHandleRowSelection(props.item._id)}>
        <DetailsRow {...props} />
      </div>
    );
  };

  onHandleRowSelection = id => {
    this.props.history.push(`/filer/editReport/${id}?type=TBD`);
  };

  openGAReportDialog = () => {
    this.setState({
      gaMessageHidden: false,
    });
  };

  closeGAReportDialog = () => {
    this.setState({
      gaMessageHidden: true,
    });
  };

  gaReportMessage = (
    <div>
      Report creation for Georgia is reporting to a new system beginning with the 4/30/22 report. This functionality will be enabled in FrontRunner once the state of Georgia has enabled new report creation with their new system for the the 4/30/22 report.
    </div>
  );

  render() {
    const {
      props: { reports, campaign, session },
      state: { createReportDialogHidden },
    } = this;

    const isStateGeorgiaCampaign = (campaign && campaign.officeType === 'State' && campaign.officeState === 'Georgia')
      || (campaign && campaign.isNonCandidateCommittee === true && campaign.officeType !== 'Federal');

    return (
      <Fragment>
        <BackButton history={this.props.history} />
        <div className="ViewAllReports">
          <div className="working-reports depth-1">
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <h3>Working Reports</h3>
              <ActionButton
                iconProps={{ iconName: 'Plus' }}
                onClick={this.openCreateReportDialog}
                text="Create New TBD Report"
              />
            </div>
            {reports.draftReports.length === 0 && (
              <div className="no-filed-reports">
                <Icon
                  iconName="FileImport"
                  style={{ fontSize: 60, color: '#99999999' }}
                />
                <p>
                  No TBD contribution reports created or in progress for this
                  campaign.
                </p>
              </div>
            )}
            {reports.draftReports.length > 0 && (
              <DetailsList
                items={this.mapDraftReports()}
                columns={this.createColumnsDraft()}
                checkboxVisibility={CheckboxVisibility.none}
                compact={false}
                selectionMode={SelectionMode.none}
                layoutMode={DetailsListLayoutMode.justified}
                onRenderRow={this.onRenderRow}
              />
            )}
          </div>
          <div className="filed-reports depth-1">
            <h3 style={{ marginBottom: 16 }}>Filed Reports</h3>
            {reports.filedReports.length === 0 && (
              <div className="no-filed-reports">
                <Icon
                  iconName="FileExport"
                  style={{ fontSize: 60, color: '#99999999' }}
                />
                <p>No TBD contribution reports filed for this campaign.</p>
              </div>
            )}
            {reports.filedReports.length > 0 && (
              <DetailsList
                items={this.mapFiledReports()}
                columns={this.createFiledColumns()}
                checkboxVisibility={CheckboxVisibility.none}
                compact={false}
                selectionMode={SelectionMode.none}
                layoutMode={DetailsListLayoutMode.justified}
              />
            )}
          </div>
        </div>
        {!isStateGeorgiaCampaign &&
        <AddReportDialog
          dialogHidden={createReportDialogHidden}
          closeDialog={this.closeCreateReportDialog}
          createReport={this.createTBDReport}
          electionYear={campaign.nextElectionYear}
          isNonCandidateCommittee={campaign.isNonCandidateCommittee}
          registrationDate={
            campaign.isNonCandidateCommittee
              ? campaign.committee.registrationDate
              : ''
          }
          electionDates={campaign.electionDates}
          reportingPeriods={[]}
          reportType="TBD"
          session={session}
        />
        }
        {isStateGeorgiaCampaign &&
        <AddGAReportDialogTBD
          dialogHidden={createReportDialogHidden}
          closeDialog={this.closeCreateReportDialog}
          createReport={this.createTBDReport}
          electionYear={campaign.nextElectionYear}
          electionDates={campaign.electionDates}
          isNonCandidateCommittee={campaign.isNonCandidateCommittee}
          registrationDate={
            campaign.isNonCandidateCommittee
              ? campaign.committee.registrationDate
              : ''
          }
          reportingPeriods={[]}
          reportType="TBD"
          session={session}
        />
        }
        <GenericMessageDialog
          onCancel={this.closeGAReportDialog}
          titleText="TBD Report Creation"
          message={this.gaReportMessage}
          dialogHidden={this.state.gaMessageHidden}
        />
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    campaign: state.currentCampaign.campaign,
    reports: state.reports,
    longRunningProcess: state.longRunningProcess,
    session: state.user.session,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    reportActions: bindActionCreators(reportActions, dispatch),
    longRunningProcessActions: bindActionCreators(longRunningProcessActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TBDViewAllReports),
);
