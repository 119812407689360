import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'office-ui-fabric-react/lib/Checkbox';
import { ActionButton } from 'office-ui-fabric-react/lib/Button';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { DataLabel } from '../../components/common';
import { formatPhone } from '../../helpers/util';
import { contactIconMap } from '../../helpers/constants';
import './ContactRow.scss';

const ContactRow = ({
  classNames,
  item,
  toggleSelectContact,
  selectedContacts,
  openDetailsPanel,
  showDetails,
  selectable,
  itemIdField,
}) => {
  const classes = [
    'contact-row',
    selectedContacts.includes(item[itemIdField]) ? 'selected' : '',
    classNames,
  ]
    .filter(Boolean)
    .join(' ');

  const getDisplayName = () => {
    if (item.displayName) {
      return item.displayName;
    }

    if (item.businessName) {
      return item.businessName;
    }

    return `${item.lastName +
      ', ' +
      item.firstName +
      (item.middleName ? ' ' + item.middleName : '')}`;
  };

  return (
    <div className={classes}>
      {selectable && (
        <Checkbox
          checked={selectedContacts.includes(item[itemIdField])}
          onChange={toggleSelectContact(item[itemIdField])}
          checkmarkIconProps={{
            iconName: 'Check',
          }}
          className="checkbox-row"
        />
      )}
      <Icon
        iconName={contactIconMap[item.contactType] || ''}
        styles={{
          root: { fontSize: 32, marginLeft: 16, color: '#244b8d' },
        }}
      />
      <div className="contact-details" onClick={openDetailsPanel}>
        <div className="name-address">
          <h4>{getDisplayName()}</h4>
          {item.address && (
            <address>
              <p>{item.address.addressLine1 || ''}</p>
              {item.address.addressLine2 && (
                <p>{item.address.addressLine2 || ''}</p>
              )}
              <p>{`${item.address.city ? item.address.city + ', ' : ''}${item.address.state ? item.address.state : ''
                } ${item.address.zipCode ? item.address.zipCode : ''}`}</p>
            </address>
          )}
        </div>
        <div className="phone-email">
          {item.phone && item.phone && (
            <DataLabel
              label="Phone 1"
              value={formatPhone(item.phone)}
              size="large"
            />
          )}
          {item.phone1 && item.phone1 && (
            <DataLabel
              label="Phone 1"
              value={formatPhone(item.phone1)}
              size="large"
            />
          )}
          {item.phone2 && (
            <DataLabel
              label="Phone 2"
              value={formatPhone(item.phone2)}
              size="large"
            />
          )}
          {item.email && (
            <DataLabel label="Email" value={item.email} size="large" />
          )}
        </div>
      </div>
      {showDetails && (
        <ActionButton
          iconProps={{ iconName: 'Info' }}
          onClick={openDetailsPanel(item[itemIdField])}
          text="Details"
        />
      )}
    </div>
  );
};

ContactRow.propTypes = {
  classNames: PropTypes.string,
  item: PropTypes.object,
  showDetailsPanel: PropTypes.func,
  toggleSelectContact: PropTypes.func,
  openDetailsPanel: PropTypes.func,
  selectedContacts: PropTypes.array,
  showDetails: PropTypes.bool,
  selectable: PropTypes.bool,
  removeContact: PropTypes.func,
  removable: PropTypes.bool,
  itemIdField: PropTypes.string,
};

ContactRow.defaultProps = {
  item: {},
  showDetailsPanel: () => { },
  toggleSelectContact: () => { },
  openDetailsPanel: () => { },
  selectedContacts: [],
  showDetails: true,
  selectable: true,
  removable: false,
  removeContact: () => { },
  itemIdField: '_id',
};

export default ContactRow;
