import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'office-ui-fabric-react/lib/Link';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { Toggle } from 'office-ui-fabric-react/lib/Toggle';
import { Dropdown } from 'office-ui-fabric-react/lib/Dropdown';
import {
  DetailsList,
  CheckboxVisibility,
  SelectionMode,
} from 'office-ui-fabric-react/lib/DetailsList';
import { ActionButton, DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { StatePicker } from '../../components/Pickers';
import { Grid, GridRow, Column, ZipCodeField } from '../../components/common';
import { sortDirections } from '../../helpers/constants';
import { sortByField } from '../../helpers/util';
import { CaretDown } from '../../components/icons';
import './EditOrganizationsSection.css';

const startState = {
  name: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: '',
  zipCode: '',
  relationship: '',
  isEdit: false,
};

const EditOrganizationsSection = ({
  organizationsDetailsExpanded,
  campaign,
  actions,
  session,
}) => {
  const [state, setState] = useState({ ...startState });
  const [sorts, setSorts] = useState({
    field: 'name',
    sortDirection: sortDirections.NONE,
  });
  const [toggleSection, setToggleSection] = useState(
    campaign.organizations.length > 0,
  );

  const handleChange = fieldName => (e, value) => {
    setState({
      ...state,
      [fieldName]: value.key !== undefined ? value.key : value,
    });
  };

  const onCancel = () => {
    setState({ ...startState });
  };

  const onSave = () => {
    const {
      _id,
      name,
      addressLine1,
      addressLine2,
      city,
      state: orgState,
      zipCode,
      relationship,
    } = state;
    if (state.isEdit) {
      actions.editOrg({
        _id,
        name,
        addressLine1,
        addressLine2,
        city,
        state: orgState,
        zipCode,
        relationship,
      });
    } else {
      actions.onSave({
        name,
        addressLine1,
        addressLine2,
        city,
        state: orgState,
        zipCode,
        relationship,
      });
    }

    setState({ ...startState });
  };

  const mapOrgs = () =>
    campaign.organizations.sort(sortByField(sorts.field, sorts.sortDirection));

  const onColumnClick = (e, column) => {
    if (sorts.field === column.fieldName) {
      if (sorts.sortDirection === sortDirections.ASC) {
        setSorts({ field: sorts.field, sortDirection: sortDirections.DESC });
      } else if (sorts.sortDirection === sortDirections.DESC) {
        setSorts({ field: sorts.field, sortDirection: sortDirections.NONE });
      } else {
        setSorts({ field: sorts.field, sortDirection: sortDirections.ASC });
      }
    } else {
      setSorts({ field: column.fieldName, sortDirection: sortDirections.ASC });
    }
  };

  const editOrg = org => {
    setState({ ...org, isEdit: true });
  };

  const createColumns = () => [
    {
      key: 'name',
      name: 'Name',
      fieldName: 'name',
      minWidth: 100,
      maxWidth: 175,
      isSorted:
        sorts.field === 'name' && sorts.sortDirection !== sortDirections.NONE,
      isSortedDescending: sorts.sortDirection === sortDirections.DESC,
      onColumnClick,
    },
    {
      key: 'address',
      name: 'Address',
      fieldName: 'addressLine1',
      minWidth: 200,
      maxWidth: 200,
      onRender: item =>
        `${item.addressLine1 || ''}${
          item.addressLine2 ? ', ' + item.addressLine2 : ''
        } ${item.city || ''} ${item.state || ''} ${item.zipCode}`,
      isSorted:
        sorts.field === 'addressLine1' &&
        sorts.sortDirection !== sortDirections.NONE,
      isSortedDescending: sorts.sortDirection === sortDirections.DESC,
      onColumnClick,
    },
    {
      key: 'relationship',
      name: 'Relationship',
      fieldName: 'relationship',
      minWidth: 200,
      maxWidth: 200,
      onRender: item => `${item.relationship || ''}`,
      isSorted:
        sorts.field === 'addressLine1' &&
        sorts.sortDirection !== sortDirections.NONE,
      isSortedDescending: sorts.sortDirection === sortDirections.DESC,
      onColumnClick,
    },
    {
      key: 'actions',
      name: '',
      minWidth: 200,
      maxWidth: 200,
      onRender: item => (
        <div className="bank-action-items">
          <ActionButton
            text="Edit"
            onClick={() => editOrg(item)}
            iconProps={{
              iconName: 'PencilAlt',
              styles: {
                marginRight: 16,
              },
            }}
          />
          <ActionButton
            text="Delete"
            // eslint-disable-next-line react/prop-types
            onClick={() => actions.removeOrg(item._id)}
            iconProps={{
              iconName: 'TrashAlt',
            }}
          />
        </div>
      ),
    },
  ];

  return (
    <div className="EditOrganizationsSection depth-1" name="orgs-details">
      <h3>
        Organizations{' '}
        <small>
          {' '}
          (
          <Link onClick={actions.toggleExpandOrganizationsDetails}>
            {organizationsDetailsExpanded ? 'Collapse' : 'Expand'}
          </Link>
          )
        </small>
      </h3>
      <div
        className={`edit-orgs-details-content${
          organizationsDetailsExpanded ? ' expanded' : ''
        }`}
      >
        <Toggle
          label="Is there Any Connected Organization, Affiliated Committee, Joint Fundraising Initiative, or Leadership PAC Sponsor?"
          onText="Yes"
          offText="No"
          onChange={() => setToggleSection(!toggleSection)}
          checked={toggleSection}
          disabled={campaign.organizations.length > 0}
        />
        {(toggleSection || campaign.organizations.length > 0) && (
          <Fragment>
            <Grid>
              <GridRow>
                <Column md={4}>
                  <h4>{`${state.isEdit ? 'Edit' : 'Add New'} Organization`}</h4>
                  <TextField
                    label="Complete Name"
                    value={state.name}
                    onChange={handleChange('name')}
                  />
                  <TextField
                    label="Mailing Address 1"
                    value={state.addressLine1}
                    onChange={handleChange('addressLine1')}
                  />
                  <TextField
                    label="Mailing Address 2"
                    value={state.addressLine2}
                    onChange={handleChange('addressLine2')}
                  />
                  <TextField
                    label="City"
                    value={state.city}
                    onChange={handleChange('city')}
                  />
                  <StatePicker
                    selectedKey={state.state}
                    onChange={handleChange('state')}
                    session={session}
                  />
                  <ZipCodeField
                    value={state.zipCode}
                    onChange={handleChange('zipCode')}
                  />
                  <Dropdown
                    onRenderCaretDown={() => <CaretDown />}
                    selectedKey={state.relationship}
                    onChange={handleChange('relationship')}
                    label="Relationship"
                    options={[
                      '',
                      'Connected Organization',
                      'Affiliated Committee',
                      'Joint Fundraising Initiative',
                      'Leadership PAC Sponsor',
                    ].map(c => ({
                      key: c,
                      text: c,
                    }))}
                  />
                  <div className="add-org-actions">
                    <DefaultButton
                      text="Cancel"
                      onClick={onCancel}
                      disabled={
                        !state.name &&
                        !state.addressLine1 &&
                        !state.addressLine2 &&
                        !state.city &&
                        !state.state &&
                        !state.zipCode &&
                        !state.relationship
                      }
                      styles={{
                        root: {
                          marginRight: 16,
                        },
                      }}
                    />
                    <DefaultButton
                      text={state.isEdit ? 'Save' : 'Add'}
                      onClick={onSave}
                    />
                  </div>
                </Column>
                <Column md={8}>
                  {campaign.organizations.length > 0 && (
                    <div className="orgs-list">
                      <DetailsList
                        items={mapOrgs()}
                        columns={createColumns()}
                        checkboxVisibility={CheckboxVisibility.hidden}
                        selectionMode={SelectionMode.none}
                      />
                    </div>
                  )}
                  {campaign.organizations.length === 0 && (
                    <div className="no-orgs-listed">
                      <Icon
                        iconName="Users"
                        styles={{ root: { fontSize: 24 } }}
                      />
                      <p>No organizations listed</p>
                    </div>
                  )}
                </Column>
              </GridRow>
            </Grid>
            {organizationsDetailsExpanded && (
              <div className="ms-textAlignRight">
                <DefaultButton
                  style={{ marginRight: 8 }}
                  text="Back"
                  onClick={() => actions.previousStep(7)}
                />
              </div>
            )}
          </Fragment>
        )}
      </div>
    </div>
  );
};

EditOrganizationsSection.propTypes = {
  campaign: PropTypes.object,
  actions: PropTypes.object.isRequired,
  organizationsDetailsExpanded: PropTypes.bool,
  session: PropTypes.object.isRequired,
};

EditOrganizationsSection.defaultProps = {
  campaign: {},
  organizationsDetailsExpanded: false,
};

export default EditOrganizationsSection;
