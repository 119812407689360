import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import ToastPortal from './ToastPortal';
import { toastTypes, toastPositions } from '../../helpers/constants';
import { actions } from '../../actions/messagingActions';
import './Toast.css';

const iconMap = {
  [toastTypes.INFO]: 'InfoCircle',
  [toastTypes.SUCCESS]: 'CheckCircle',
  [toastTypes.ERROR]: 'Time',
  [toastTypes.WARNING]: 'ExclamationTriangle',
};

export class Toast extends Component {
  _timer = null;

  static propTypes = {
    pathname: PropTypes.string.isRequired,
    toastType: PropTypes.oneOf([
      toastTypes.INFO,
      toastTypes.SUCCESS,
      toastTypes.ERROR,
      toastTypes.WARNING,
    ]),
    message: PropTypes.string.isRequired,
    duration: PropTypes.number,
    position: PropTypes.oneOf([
      toastPositions.TOP_LEFT,
      toastPositions.TOP_CENTER,
      toastPositions.TOP_RIGHT,
      toastPositions.BOTTOM_LEFT,
      toastPositions.BOTTOM_CENTER,
      toastPositions.BOTTOM_RIGHT,
    ]),
    actions: PropTypes.object,
  };

  static defaultProps = {
    toastType: toastTypes.INFO,
    duration: 4500,
    position: toastPositions.TOP_RIGHT,
    actions: {},
  };

  componentDidMount() {
    this.setTimer();
  }

  componentWillUnmount() {
    clearTimeout(this._timer);
  }

  clearToast = () => {
    this.props.actions.clearToast();
  };

  setTimer = () => {
    if (this._timer !== null) {
      clearTimeout(this._timer);
    }

    this._timer = setTimeout(() => {
      this.props.actions.clearToast();
      this._timer = null;
    }, this.props.duration);
  };

  render() {
    const { toastType, message, position } = this.props;

    const className = ['Toast', toastType, position].join(' ');

    return message ? (
      <ToastPortal>
        {message ? (
          <div className={className} onClick={this.clearToast}>
            <Icon
              iconName={iconMap[toastType] || ''}
              styles={{
                root: {
                  fontSize: '28px',
                },
              }}
            />
            <p>{message}</p>
          </div>
        ) : null}
      </ToastPortal>
    ) : null;
  }
}

function mapStateToProps(state) {
  return {
    pathname: state.router.location.pathname,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Toast);
