export const types = {
  GET_CONTRIBUTIONS: 'GET_CONTRIBUTIONS',
  GET_CONTRIBUTIONS_PROCESSING: 'GET_CONTRIBUTIONS_PROCESSING',
  GET_CONTRIBUTIONS_SUCCESS: 'GET_CONTRIBUTIONS_SUCCESS',
  GET_CONTRIBUTIONS_FAILURE: 'GET_CONTRIBUTIONS_FAILURE',
  GET_CONTRIBUTIONS_BY_CONTACT: 'GET_CONTRIBUTIONS_BY_CONTACT',
  GET_CONTRIBUTIONS_BY_CONTACT_PROCESSING: 'GET_CONTRIBUTIONS_BY_CONTACT_PROCESSING',
  GET_CONTRIBUTIONS_BY_CONTACT_SUCCESS: 'GET_CONTRIBUTIONS_BY_CONTACT_SUCCESS',
  GET_CONTRIBUTIONS_BY_CONTACT_FAILURE: 'GET_CONTRIBUTIONS_BY_CONTACT_FAILURE',
  SAVE_CONTRIBUTION: 'SAVE_CONTRIBUTION',
  SAVE_FEC_CONTRIBUTION: 'SAVE_FEC_CONTRIBUTION',
  SAVE_TBD_CONTRIBUTION: 'SAVE_TBD_CONTRIBUTION',
  DELETE_CONTRIBUTION: 'DELETE_CONTRIBUTION',
  DELETE_FEC_CONTRIBUTION: 'DELETE_FEC_CONTRIBUTION',
  UPDATE_CONTRIBUTION: 'UPDATE_CONTRIBUTION',
  UPDATE_FEC_CONTRIBUTION: 'UPDATE_FEC_CONTRIBUTION',
  UPDATE_CONTRIBUTION_TBD: 'UPDATE_CONTRIBUTION_TBD',
  SAVE_CONTRIBUTION_PROCESSING: 'SAVE_CONTRIBUTION_PROCESSING',
  SAVE_CONTRIBUTION_RESET: 'SAVE_CONTRIBUTION_RESET',
  SAVE_CONTRIBUTION_FAILURE: 'SAVE_CONTRIBUTION_FAILURE',
  SAVE_CONTRIBUTION_SUCCESS: 'SAVE_CONTRIBUTION_SUCCESS',
  CLEAR_CONTRIBUTIONS: 'CLEAR_CONTRIBUTIONS',
  GET_CONTRIBUTIONS_NEXT_PAGE: 'GET_CONTRIBUTIONS_NEXT_PAGE',
  GET_CONTRIBUTIONS_NEXT_PAGE_SUCCESS: 'GET_CONTRIBUTIONS_NEXT_PAGE_SUCCESS',
  GET_CONTRIBUTIONS_NEXT_PAGE_FAILURE: 'GET_CONTRIBUTIONS_NEXT_PAGE_FAILURE',
  GET_CONTRIBUTIONS_NEXT_PAGE_PROCESSING:
    'GET_CONTRIBUTIONS_NEXT_PAGE_PROCESSING',
  SAVE_INTEREST_TRANSACTION: 'SAVE_INTEREST_TRANSACTION',
  SAVE_INTEREST_TRANSACTION_SUCCESS: 'SAVE_INTEREST_TRANSACTION_SUCCESS',
  SAVE_INTEREST_TRANSACTION_FAILURE: 'SAVE_INTEREST_TRANSACTION_FAILURE',
};

export const actions = {
  getContributions() {
    return { type: types.GET_CONTRIBUTIONS };
  },

  save(payload, addNew, reportId) {
    return {
      type: types.SAVE_CONTRIBUTION,
      data: { payload, addNew, reportId },
    };
  },

  saveFec(payload, addNew, reportId) {
    return {
      type: types.SAVE_FEC_CONTRIBUTION,
      data: {
        payload,
        addNew,
        reportId,
      },
    };
  },

  saveTbd(payload, addNew, reportId) {
    return {
      type: types.SAVE_TBD_CONTRIBUTION,
      data: { payload, addNew, reportId },
    };
  },

  update(payload, addNew, reportId, redirect = null, verifyReport = null) {
    return {
      type: types.UPDATE_CONTRIBUTION,
      data: { payload, addNew, reportId, redirect, verifyReport },
    };
  },

  updateFec(payload, addNew, reportId, redirect = null, verifyReport = null) {
    return {
      type: types.UPDATE_FEC_CONTRIBUTION,
      data: {
        payload,
        addNew,
        reportId,
        redirect,
        verifyReport,
      },
    };
  },

  updateTbd(payload, addNew, reportId = null) {
    return {
      type: types.UPDATE_CONTRIBUTION_TBD,
      data: { payload, addNew, reportId },
    };
  },

  resetSave() {
    return { type: types.SAVE_CONTRIBUTION_RESET };
  },

  delete(id, reportId = null, redirect = null) {
    return {
      type: types.DELETE_CONTRIBUTION,
      data: { id, reportId, redirect },
    };
  },

  deleteFec(id, reportId = null, redirect = null) {
    return {
      type: types.DELETE_FEC_CONTRIBUTION,
      data: { id, reportId, redirect },
    };
  },
};
