export const validate = state => {
  const errors = {};

  if (state.currentPassword.length === 0) {
    errors.currentPasswordError = 'Current password is required';
  }

  if (state.newPassword.length === 0) {
    errors.newPasswordError = 'New password is required';
  }

  if (state.confirmNewPassword.length === 0) {
    errors.confirmNewPasswordError = 'Confirm password is required';
  }

  if (state.newPassword.length && state.confirmNewPassword.length) {
    if (state.newPassword !== state.confirmNewPassword) {
      errors.confirmNewPasswordError = 'Passwords do not match';
    }
  }

  return errors;
};
