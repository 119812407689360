import { toastTypes, toastPositions } from '../helpers/constants';

export const types = {
  SET_TOAST: 'SET_TOAST',
  CLEAR_TOAST: 'CLEAR_TOAST',
};

export const actions = {
  setInfoToast(message, position = toastPositions.TOP_RIGHT) {
    return {
      type: types.SET_TOAST,
      data: { toastType: toastTypes.INFO, message, position },
    };
  },

  setSuccessToast(message, position = toastPositions.TOP_RIGHT) {
    return {
      type: types.SET_TOAST,
      data: { toastType: toastTypes.SUCCESS, message, position },
    };
  },

  setErrorToast(message, position = toastPositions.TOP_RIGHT) {
    return {
      type: types.SET_TOAST,
      data: { toastType: toastTypes.ERROR, message, position },
    };
  },

  setWarningToast(message, position = toastPositions.TOP_RIGHT) {
    return {
      type: types.SET_TOAST,
      data: { toastType: toastTypes.WARNING, message, position },
    };
  },

  clearToast() {
    return { type: types.CLEAR_TOAST };
  },
};
