import React from 'react';
import PropTypes from 'prop-types';
import { Persona } from 'office-ui-fabric-react/lib/Persona';

const BusinessPersona = ({ persona }) => {
  const addy = {
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    zipCode: '',
    ...persona.address,
  };

  const address = `${addy.addressLine1 || ''}${
    addy.addressLine2 ? ' ' + addy.addressLine2 : ''
  } ${addy.city}, ${addy.state} ${addy.zipCode}`;

  return (
    <Persona
      text={persona.typeAheadName || persona.businessName}
      secondaryText={persona.contactName}
      tertiaryText={address}
      coinSize={48}
    />
  );
};

BusinessPersona.propTypes = {
  persona: PropTypes.object.isRequired,
};

export default BusinessPersona;
