import { createReducer } from '../../reducers/createReducer';
import { sortDirections } from '../../helpers/constants';

export const initialState = {
  detailsPanelOpen: false,
  deleteDepositPanelOpen: false,
  depositToDelete: {},
  sortField: 'depositDate',
  sortDirection: sortDirections.DESC,
  filterText: '',
  undepositedTotal: 0,
  isLoadingCSV: false,
  downloadId: null,
};

export const actions = {
  CLOSE_CONFIRM_DEPOSIT_PANEL: 'CLOSE_CONFIRM_DEPOSIT_PANEL',
  OPEN_CONFIRM_DEPOSIT_PANEL: 'OPEN_CONFIRM_DEPOSIT_PANEL',
  SET_UNDEPOSITED: 'SET_UNDEPOSITED',
  SHOW_DEPOSIT_PANEL: 'SHOW_DEPOSIT_PANEL',
  HIDE_DEPOSIT_PANEL: 'HIDE_DEPOSIT_PANEL',
  SET_SORT: 'SET_SORT',
  SET_IS_LOADING_CSV: 'SET_IS_LOADING_CSV',
};

export const actionMap = {
  [actions.CLOSE_CONFIRM_DEPOSIT_PANEL]: (state) => ({
    ...state,
    deleteDepositPanelOpen: false,
    depositToDelete: {},
  }),
  [actions.OPEN_CONFIRM_DEPOSIT_PANEL]: (state, { data: { depositToDelete } }) => {
    return {
      ...state,
      deleteDepositPanelOpen: true,
      depositToDelete,
    };
  },
  [actions.SET_UNDEPOSITED]: (state, { data: undepositedTotal }) => ({
    ...state,
    undepositedTotal,
  }),
  [actions.SHOW_DEPOSIT_PANEL]: state => ({
    ...state,
    detailsPanelOpen: true,
  }),
  [actions.HIDE_DEPOSIT_PANEL]: state => ({
    ...state,
    detailsPanelOpen: false,
  }),
  [actions.SET_IS_LOADING_CSV]: (state, { data: { isLoadingCSV, downloadId } }) => ({
    ...state,
    isLoadingCSV,
    downloadId: isLoadingCSV ? downloadId : null,
  }),
  [actions.SET_SORT]: (state, { data: { sortField, sortDirection } }) => ({
    ...state,
    sortField,
    sortDirection,
  }),
};

export const DepositsReducer = createReducer(initialState, actionMap);
