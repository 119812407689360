import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from 'office-ui-fabric-react/lib/TextField';

const ZipCodeField = ({
  label = 'Zip Code',
  value = '',
  errorMessage = '',
  required = false,
  onChange = () => {},
  placeholder = '',
  style = {},
}) => {
  return (
    <TextField
      label={label}
      value={value}
      onChange={onChange}
      errorMessage={errorMessage}
      required={required}
      inputMode="numeric"
      placeholder={placeholder}
      styles={style}
    />
  );
};

ZipCodeField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  errorMessage: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  style: PropTypes.object,
};

export default ZipCodeField;
