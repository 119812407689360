import React, { useReducer, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import {
  Stack,
  mergeStyleSets,
  ActionButton,
  Spinner,
  SpinnerSize,
  Text, Checkbox,
} from 'office-ui-fabric-react';
import { BackButton } from '../../../components/common';
import {
  getUserSession,
  getListsForAdHoc,
  getListFetchStatus,
  getListRecordCount,
  getListCSVStatus,
} from '../../../selectors';
import {
  expendituresReducer,
  initialState,
  actions,
} from './expendituresReducer';
import { validate } from './form-validations';
import { types as listActions } from '../../../actions/listActions';
import AdHocResultsList from '../AdHocResultsList';
import { getLabel } from '../../../helpers/labelHelper';
import { formatISODate } from '../../../helpers/util';
import { statuses } from '../../../helpers/constants';
import TimePeriodFilter from '../TimePeriodFilter';
import '../SpecificList.css';

const styles = mergeStyleSets({
  list: {
    height: 'calc(100vh - 245px)',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    boxSizing: 'border-box',
  },
  listBlurb: {
    fontSize: '20px',
    marginLeft: 80,
  },
});

const Expenditure = ({ history }) => {
  const [state, localDispatch] = useReducer(
    expendituresReducer,
    initialState,
  );

  const reduxDispatch = useDispatch();
  const session = useSelector(getUserSession);
  const lists = useSelector(getListsForAdHoc);
  const listFetchStatus = useSelector(getListFetchStatus);
  const totalRecords = useSelector(getListRecordCount);
  const listCSVStatus = useSelector(getListCSVStatus);
  const [items, setItems] = useState([]);

  useEffect(() => {
    const errors = validate(state);
    localDispatch({
      type: actions.SET_FORM_ERRORS,
      data: { errors },
    });
    if (Object.values(errors).every(e => e.length === 0) && state.columns.length > 0) {
      reduxDispatch({
        type: listActions.GET_LIST,
        data: {
          fields: 'expenditures-by-date-range-fields',
          filters: JSON.stringify(
            state.filters.reduce((list, filter) => {
              const { column, expression } = filter;
              let { value } = filter;
              if (column === 'transactionDate') {
                value = formatISODate(value);
              }
              list = [
                ...list,
                {
                  column,
                  expression,
                  value: Array.isArray(value)
                    ? value.join(',')
                    : column === 'amount' || column === 'aggregateAmount'
                    ? +value
                    : value + '',
                },
              ];
              return list;
            }, []),
          ),
        },
      });
    }
  }, [state.filters, state.columns, state.handleMissingPurposeCheck]);

  const getColumns = async () => {
    const { data: columnDefinitions } = await axios.get(
      '/api/filer/accounting/lists/expenditureByDateColumns',
      {
        withCredentials: true,
      },
    );

    localDispatch({
      type: actions.SET_COLUMN_DEFINITIONS,
      data: columnDefinitions,
    });
  };

  useEffect(() => {
    getColumns();
    return () => {
      reduxDispatch({
        type: listActions.CLEAR_LISTS,
      });
    };
  }, []);

  useEffect(() => {
    if (lists.length && state.columns) {
      setItems(lists);
    }
  }, [lists, state.columns]);

  const handleChange = fieldName => (e, option) => {
    const value = option.key !== undefined ? option.key : option;
    localDispatch({
      type: actions.HANDLE_CHANGE,
      data: { fieldName, value },
    });
  };

  const changeDate = fieldName => value => {
    localDispatch({
      type: actions.HANDLE_CHANGE,
      data: { fieldName, value },
    });
  };

  const exportToCSV = async () => {
    reduxDispatch({
      type: listActions.GET_CSV,
      data: {
        fields: 'expenditures-by-date-range-fields',
        filters: JSON.stringify(
          state.filters.reduce((list, filter) => {
            const { column, expression } = filter;
            let { value } = filter;
            if (column === 'transactionDate') {
              value = formatISODate(value);
            }
            list = [
              ...list,
              {
                column,
                expression,
                value: Array.isArray(value)
                  ? value.join(',')
                  : column === 'amount' || column === 'aggregateAmount'
                  ? +value
                  : value + '',
              },
            ];
            return list;
          }, []),
        ),
      },
    });
  };

  const handleMissingPurposeCheck = (e, option) => {
    localDispatch({
      type: actions.HANDLE_MISSING_PURPOSE_CHECK,
      data: { fieldName: 'handleMissingPurposeCheck', value: option },
    });
  };

  return (
    <>
      <BackButton
        history={history}
        isFixed
        url="/filer/listsIndex"
        pageTitle={`${getLabel('Expenditures', session)}`}
      />
      <div className="Expenditure SpecificList">
        <div className="options-panel">
          <Stack>
            <Checkbox
              label="Purpose is missing"
              checked={state.handleMissingPurposeCheck}
              onChange={handleMissingPurposeCheck}
              styles={{ root: { marginBottom: '16px' } }}
            />

            <TimePeriodFilter
              handleChange={handleChange}
              changeDate={changeDate}
              timePeriod="custom"
              startDate={state.startDate}
              endDate={state.endDate}
              errors={state.errors}
              onlyCustom={true}
            />
          </Stack>
        </div>
        {listCSVStatus === statuses.PROCESSING ? (
          <div className="list loading-csv">
            <Spinner size={SpinnerSize.large} label="Generating CSV" />
          </div>
        ) : (
          <div className="list">
            <Stack childrenGap={8}>
              <Stack
                horizontal
                verticalAlign="center"
                childrenGap={8}
                className={styles.topRow}
              >
                <ActionButton
                  text={`Export to CSV (${totalRecords} records)`}
                  onClick={exportToCSV}
                  disabled={lists.length === 0 || state.columns.length === 0}
                  iconProps={{
                    iconName: 'FileCSV',
                  }}
                />
                {totalRecords > 10 && (
                  <Text variant="small">Previewing first 10 records</Text>
                )}
              </Stack>
              <Stack childrenGap={8} className={styles.list}>
                {listFetchStatus === statuses.PROCESSING && (
                  <div className="loading-list">
                    <Spinner size={SpinnerSize.large} label="Loading..." />
                  </div>
                )}
                {lists.length > 0 && listFetchStatus === statuses.SUCCESS && (
                  <AdHocResultsList
                    session={session}
                    items={items}
                    selectedColumns={state.columns}
                    customColumnList={state.columnDefinitions}
                  />
                )}
                {lists.length === 0 && listFetchStatus === statuses.SUCCESS && (
                  <p className={styles.listBlurb}>No results found</p>
                )}
              </Stack>
            </Stack>
          </div>
        )}
      </div>
    </>
  );
};

Expenditure.propTypes = {
  history: PropTypes.object.isRequired,
};

export default Expenditure;
