import { isInvalidDate } from '../../helpers/util';
import { emailRegex } from '../../helpers/constants';

export const validate = state => {
  const errors = {};

  if (state.name.length === 0) {
    errors.nameError = 'Reconciliation name is required';
  }

  if (
    state.endingStatementBalance === '' ||
    state.endingStatementBalance === '' ||
    state.endingStatementBalance === null ||
    state.endingStatementBalance === undefined
  ) {
    errors.endingStatementBalanceError = 'Bank statement balance is required';
  }

  if (state.email && !emailRegex.test(state.email)) {
    errors.email = 'Please use a valid email address';
  }

  if (
    state.beginningStatementDate &&
    isInvalidDate(state.beginningStatementDate)
  ) {
    errors.beginningStatementDateError = 'Invalid date';
  }

  if (state.endingStatementDate && isInvalidDate(state.endingStatementDate)) {
    errors.endingStatementDateError = 'Invalid date';
  }

  return errors;
};
