export default class DashboardMoneyInfo {
  constructor(dto = {}) {
    this.cashOnHand = dto.cashOnHand;
    this.indebtedness = dto.indebtedness
      ? {
          creditReceivedAmount: dto.indebtedness.creditReceivedAmount,
          deferredPaymentOfExpensesAmount:
            dto.indebtedness.deferredPaymentOfExpensesAmount,
          id: dto.indebtedness.id,
          isPriorDebt: dto.indebtedness.isPriorDebt,
          loanPaymentsAmount: dto.indebtedness.loanPaymentsAmount,
          loansReceivedAmount: dto.indebtedness.loansReceivedAmount,
          paymentsOnPreviouslyDeferredExpensesAmount:
            dto.indebtedness.paymentsOnPreviouslyDeferredExpensesAmount,
          periodClosingDebtAmount: dto.indebtedness.periodClosingDebtAmount,
          periodOpeningDebtAmount: dto.indebtedness.periodOpeningDebtAmount,
        }
      : {};
    this.moneyRaised = dto.moneyRaised
      ? {
          electionCycle: dto.moneyRaised.electionCycles,
          electionYear: dto.moneyRaised.electionYear,
          totalRaised: dto.moneyRaised.totalRaised,
        }
      : {};
  }
}
