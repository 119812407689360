import { types } from '../actions/longRunningProcessActions';
import { statuses } from '../helpers/constants';
import { createReducer } from './createReducer';

export const initialState = {
  status: statuses.NOT_STARTED,
  presentMessaging: false,
  jobName: '',
  prettyJobName: '',
  messaging: [],
  error: null,
  returnedFromServer: {
    data: {},
  },
  uploadFile: statuses.NOT_STARTED,
  onCloseCallback: null,
};

const actionMap = {
  [types.LONG_RUNNING_JOB_PROCESSING]: (state, action) => {
    return {
      ...state,
      status: statuses.PROCESSING,
      presentMessaging: true,
      jobName: action?.data?.jobName,
      prettyJobName: action?.data?.prettyJobName,
      messaging: [],
      returnedFromServer: { data: {} },
      error: null,
    };
  },
  [types.LONG_RUNNING_JOB_UPDATE_MESSAGING]: (state, action) => {
    return {
      ...state,
      messaging: action?.data?.messageList,
    };
  },
  [types.LONG_RUNNING_JOB_ERROR]: (state, action) => {
    return {
      ...state,
      status: statuses.ERROR,
      error: action.error,
    };
  },
  [types.LONG_RUNNING_JOB_SUCCESS]: (state, action) => {
    return {
      ...state,
      status: statuses.SUCCESS,
      returnedFromServer: action?.data?.jobResult,
      messaging: action?.data?.messageList,
      error: null,
    };
  },
  [types.LONG_RUNNING_JOB_CLOSE_MESSAGING]: (state) => {
    return {
      ...state,
      presentMessaging: false,
    };
  },
  [types.LONG_RUNNING_JOB_WITH_FILE_UPLOAD_PROCESSING]: (state, action) => {
    const { uploadFile, prettyJobName, onCloseCallback } = action.data;
    return {
      ...state,
      uploadFile,
      status: statuses.PROCESSING,
      presentMessaging: true,
      jobName: uploadFile.jobName,
      prettyJobName,
      onCloseCallback,
      messaging: [],
      returnedFromServer: null,
      error: null,
    };
  },
  [types.LONG_RUNNING_JOB_WITH_FILE_UPLOAD_UPDATE_MESSAGING]: (state, action) => {
    return {
      ...state,
      messaging: action?.data?.messageList,
    };
  },
  [types.LONG_RUNNING_JOB_WITH_FILE_UPLOAD_ERROR]: (state, action) => {
    return {
      ...state,
      status: statuses.ERROR,
      error: action.error,
    };
  },
  [types.LONG_RUNNING_JOB_WITH_FILE_UPLOAD_SUCCESS]: (state, action) => {
    return {
      ...state,
      status: statuses.SUCCESS,
      returnedFromServer: action?.data?.jobResult,
      messaging: action?.data?.messageList,
      error: null,
    };
  },
  [types.LONG_RUNNING_JOB_WITH_FILE_UPLOAD_CLOSE_MESSAGING]: (state) => {
    return {
      ...state,
      presentMessaging: false,
    };
  },
};

export const longRunningProcessReducer = createReducer(initialState, actionMap);
