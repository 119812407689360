import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { GridRow, Column, MoneyField } from '../../common';

const FECSection11ai = ({ q11aiColA, q11aiColB }) => {
  return (
    <Fragment>
      <GridRow>
        <Column sm={4}>
          <p className="sub-question">
            (a) Individuals/Persons Other Than Political Committees
          </p>
        </Column>
      </GridRow>
      <GridRow>
        <Column sm={4} classNames="sub-question">
          <p className="sub-question">(i) Itemized (use Schedule A)</p>
        </Column>
        <Column sm={4} classNames="entry-row">
          <MoneyField
            disabled
            value={q11aiColA || 0}
          />
        </Column>
        <Column sm={4} classNames="entry-row">
          <MoneyField
            disabled
            value={q11aiColB || 0}
          />
        </Column>
      </GridRow>
    </Fragment>
  );
};

FECSection11ai.propTypes = {
  q11aiColA: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  q11aiColB: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default FECSection11ai;
