import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogType,
  DialogFooter,
} from 'office-ui-fabric-react/lib/Dialog';
import {
  DefaultButton,
  PrimaryButton,
} from 'office-ui-fabric-react/lib/Button';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import ConfirmDeleteFromReportDialog from './ConfirmDeleteFromReportDialog';

const ConfirmContinueUpdateDialog = ({
  dialogHidden,
  cancel,
  confirm,
  instruction,
  messageList,
}) => {
  const getMessageList = () => {
    return messageList.map((m, i) => {
      return (<li key={i}>{m}</li>);
    });
  };

  return (
    <Dialog
      hidden={dialogHidden}
      onDismiss={cancel}
      dialogContentProps={{
        type: DialogType.normal,
        title: (
          <span style={{ display: 'flex', alignItems: 'center' }}>
            <Icon
              iconName="ExclamationTriangle"
              styles={{
                root: {
                  backgroundColor: '#a80000',
                  color: '#fff',
                  borderRadius: '50%',
                  height: 36,
                  width: 36,
                  marginRight: 16,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                },
              }}
            />
            <span
              style={{
                fontWeight: 'bold',
                fontSize: '18pt',
                padding: 0,
                margin: 0,
              }}
            >Continue?</span>
          </span>
        ),
        subText: instruction,
      }}
    >
      <div className="message-list">
        <ul>
          {getMessageList()}
        </ul>
      </div>
      <DialogFooter>
        <DefaultButton
          text="Cancel"
          onClick={cancel}
          styles={{
            root: {
              marginRight: 16,
            },
          }}
        />
        <PrimaryButton
          text="Continue Update"
          onClick={confirm}
        />
      </DialogFooter>
    </Dialog>
  );
};

ConfirmContinueUpdateDialog.propTypes = {
  dialogHidden: PropTypes.bool.isRequired,
  cancel: PropTypes.func.isRequired,
  confirm: PropTypes.func.isRequired,
  messageList: PropTypes.array,
  instruction: PropTypes.string.isRequired,
};

ConfirmDeleteFromReportDialog.defaultProps = {
  messageList: [],
};

export default ConfirmContinueUpdateDialog;
