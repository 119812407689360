import { isInvalidDate } from '../../helpers/util';

export const validate = (state, totalAmount) => {
  const errors = {
    dateProcessedError: '',
    contributionSplits: {},
    splitTotalError: '',
  };

  let splitTotal = 0;

  state.contributionSplits.map(split => {
    splitTotal += Math.round(split.amount * 100) / 100;
    errors.contributionSplits[split.tempId] = {};
    if (split.electionYear === 0) {
      errors.contributionSplits[split.tempId].electionYearError =
        'Election year is required';
    } else {
      errors.contributionSplits[split.tempId].electionYearError = '';
    }

    if (split.electionCycle === 0) {
      errors.contributionSplits[split.tempId].electionCycleError =
        'Election cycle is required';
    } else {
      errors.contributionSplits[split.tempId].electionCycleError = '';
    }

    if (
      !split ||
      split.amount === 0 ||
      (typeof split.amount === 'number' ? false : !split.amount.length)
    ) {
      errors.contributionSplits[split.tempId].amountError = 'Invalid amount';
    } else {
      errors.contributionSplits[split.tempId].amountError = '';
    }

    return split;
  });

  if (splitTotal !== Math.round(totalAmount * 100) / 100) {
    errors.splitTotalError =
      'Totals of splits do not match total processed amount';
  } else {
    errors.splitTotalError = '';
  }

  if (!state.dateProcessed) {
    errors.dateProcessedError = 'Date processed is required';
  } else if (isInvalidDate(state.dateProcessed)) {
    errors.dateProcessedError = 'Invalid date';
  } else {
    errors.dateProcessedError = '';
  }

  return errors;
};
