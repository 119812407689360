import {
  DefaultButton,
  Dropdown,
  PrimaryButton,
  Spinner,
  Text,
} from 'office-ui-fabric-react';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { TextField } from '../../../../components/common';
import { DatePicker } from '../../../../components/Pickers';
import { ImportColumnType } from '../../../../helpers/constants';
import './index.scss';
import { booleanOptions } from './utils';

const EditImportRowDialogBody = ({
  row,
  columns,
  isSavingCorrections,
  onSaveCorrections,
  onCloseDialog,
}) => {
  const [values, setValues] = useState(row.values);

  const mappedErrors = Object.entries(row.errors).map(([key, value]) => [
    key,
    { value, isFixed: false },
  ]);
  const [errors, setErrors] = useState(Object.fromEntries(mappedErrors));

  const handleChange = (value, editingColumnIndex) => {
    const newValues = [...values];
    newValues[editingColumnIndex] = value;
    setValues(newValues);
  };

  const handleTextFieldChange = editingColumnIndex => ({ target }) => {
    handleChange(target.value, editingColumnIndex);
  };

  const handleDatePickerChange = editingColumnIndex => value => {
    handleChange(value, editingColumnIndex);
  };

  const handleDropdownChange = editingColumnIndex => (event, option) => {
    handleChange(option.value, editingColumnIndex);
  };

  const handleBlur = (key, index) => ({ target }) => {
    if (errors[key] && target.value !== row.values[index]) {
      const newErrors = {
        ...errors,
        [key]: { ...errors[key], isFixed: true },
      };
      setErrors(newErrors);
    }
  };

  const validateValue = (value, index) => {
    const { type } = columns[index];

    if (type === ImportColumnType.Number) {
      const parsedValue = parseFloat(value);
      return Number.isNaN(parsedValue) ? value : parsedValue;
    }
    return value;
  };

  const handleSaveCorrections = () => {
    const validatedValues = values.map(validateValue);
    onSaveCorrections(validatedValues);
  };

  const handleRenderDescription = ({ description }) => (
    <Text
      variant="small"
      block
      className="edit-import-row-input-success-message"
    >
      {description}
    </Text>
  );

  const renderInput = (value, index) => {
    const column = columns[index];
    const error = errors[column.key];
    const commonProps = {
      key: index,
      name: column.label,
      label: column.label,
      placeholder: column.label,
      className: `edit-import-row-input ${
        error?.isFixed ? 'edit-import-row-input-success' : ''
      }`,
      errorMessage: error?.isFixed ? '' : error?.value,
      description: error?.isFixed ? 'Tentatively fixed' : undefined,
      onBlur: handleBlur(column.key, index),
      onRenderDescription: error?.isFixed ? handleRenderDescription : undefined,
    };

    if (column.type === ImportColumnType.Date) {
      const dateValue = new Date(value);
      return (
        <DatePicker
          {...commonProps}
          value={Number.isNaN(dateValue.getTime()) ? null : dateValue}
          onChange={handleDatePickerChange(index)}
        />
      );
    }
    if (column.type === ImportColumnType.Boolean) {
      return (
        <Dropdown
          {...commonProps}
          selectedKey={String(value)}
          onChange={handleDropdownChange(index)}
          options={booleanOptions}
        />
      );
    }
    return (
      <TextField
        {...commonProps}
        value={value || ''}
        onChange={handleTextFieldChange(index)}
      />
    );
  };

  return (
    <>
      <div className="EditImportRowDialogBody">
        <ul className="edit-import-row-errors">
          {Object.entries(errors).map(([key, { value, isFixed }]) => (
            <li key={key}>
              <span
                className={`edit-import-row-error-message ${
                  isFixed ? 'edit-import-row-error-message-fixed' : ''
                }`}
              >
                {value}
              </span>
              {isFixed && (
                <span className="edit-import-row-success-message">
                  &nbsp;&#10003;&nbsp;Tentatively fixed
                </span>
              )}
            </li>
          ))}
        </ul>
        <div className="edit-import-row-input-container">
          {values.map(renderInput)}
        </div>
      </div>
      <div className="EditImportRowDialogBody__Footer">
        <DefaultButton text="Cancel" onClick={onCloseDialog} />
        <PrimaryButton
          text="Save corrections"
          disabled={isSavingCorrections}
          onClick={handleSaveCorrections}
        />
        {isSavingCorrections && <Spinner />}
      </div>
    </>
  );
};

EditImportRowDialogBody.propTypes = {
  row: PropTypes.object,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSaveCorrections: PropTypes.func.isRequired,
  onCloseDialog: PropTypes.func.isRequired,
  isSavingCorrections: PropTypes.bool.isRequired,
};

EditImportRowDialogBody.defaultProps = {
  row: { values: [], errors: {} },
};

export default EditImportRowDialogBody;
