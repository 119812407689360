import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from 'office-ui-fabric-react/lib/TextField';

const AmountRangeFilter = ({
  handleChange,
  startAmount,
  endAmount,
  errors,
}) => {
  return (
    <div>
      <TextField
        label="Amount greater or equal to"
        onChange={handleChange('startAmount')}
        value={startAmount}
        errorMessage={errors.startAmount}
      />
      <TextField
        label="Amount less or equal to"
        onChange={handleChange('endAmount')}
        value={endAmount}
        errorMessage={errors.endAmount}
      />
    </div>
  );
};

AmountRangeFilter.propTypes = {
  startAmount: PropTypes.number,
  endAmount: PropTypes.number,
  handleChange: PropTypes.func.isRequired,
  errors: PropTypes.object,
};

export default AmountRangeFilter;
