export const types = {
  GET_DEBTS: 'GET_DEBTS',
  GET_DEBTS_PROCESSING: 'GET_DEBTS_PROCESSING',
  GET_DEBTS_SUCCESS: 'GET_DEBTS_SUCCESS',
  GET_DEBTS_FAILURE: 'GET_DEBTS_FAILURE',
  CLEAR_DEBTS: 'CLEAR_DEBTS',
  SAVE_DEBT: 'SAVE_DEBT',
  SAVE_DEBT_PROCESSING: 'SAVE_DEBT_PROCESSING',
  SAVE_DEBT_SUCCESS: 'SAVE_DEBT_SUCCESS',
  SAVE_DEBT_FAILURE: 'SAVE_DEBT_FAILURE',
  UPDATE_DEBT: 'UPDATE_DEBT',
  UPDATE_DEBT_PROCESSING: 'UPDATE_DEBT_PROCESSING',
  UPDATE_DEBT_SUCCESS: 'UPDATE_DEBT_SUCCESS',
  UPDATE_DEBT_FAILURE: 'UPDATE_DEBT_FAILURE',
  DELETE_DEBT: 'DELETE_DEBT',
  DELETE_DEBT_PROCESSING: 'DELETE_DEBT_PROCESSING',
  DELETE_DEBT_SUCCESS: 'DELETE_DEBT_SUCCESS',
  DELETE_DEBT_FAILURE: 'DELETE_DEBT_FAILURE',
};

export const actions = {
  getDebts(reportId) {
    return { type: types.GET_DEBTS, data: { reportId } };
  },

  saveDebt(reportId, payload) {
    return { type: types.SAVE_DEBT, data: { reportId, payload } };
  },

  updateDebt(reportId, debtId, payload) {
    return { type: types.UPDATE_DEBT, data: { reportId, debtId, payload } };
  },

  deleteDebt(reportId, debtId) {
    return { type: types.DELETE_DEBT, data: { reportId, debtId } };
  },

  clearDebts() {
    return { type: types.CLEAR_DEBTS };
  },
};
