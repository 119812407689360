import { ImportDataType } from '../../../../helpers/constants';

export const dataTypeOptions = [{
  key: ImportDataType.Contribution,
  text: 'Contribution (Receipt)',
}, {
  key: ImportDataType.Expenditure,
  text: 'Expenditure (Disbursement)',
  disabled: true,
}, {
  key: ImportDataType.Loan,
  text: 'Loan',
  disabled: true,
}, {
  key: ImportDataType.Contact,
  text: 'Contact',
  disabled: true,
}];
