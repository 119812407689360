import getCountiesForState from './countiesByState';
import getCitiesForState from './citiesByState';
import { sortByField } from './util';
import { sortDirections, signupOfficeTypes } from './constants';
import federalOffices from './federalOffices';

const offices = {
  1: { ...federalOffices },
  2: {
    id: 2,
    name: 'State',
    states: {
      GA: {
        value: 'GA',
        name: 'Georgia',
        offices: {
          1: {
            id: 1,
            name: 'Statewide',
            suboffices: {
              1: { id: 1, name: 'Governor' },
              2: { id: 2, name: 'Lieutenant Governor' },
              3: { id: 3, name: 'Attorney General' },
              4: { id: 4, name: 'Secretary of State' },
              5: { id: 5, name: 'Agriculture Commissioner' },
              6: { id: 6, name: 'Insurance Commissioner' },
              7: { id: 7, name: 'Labor Commissioner' },
              8: { id: 8, name: 'Superintendent of Public Instruction' },
              9: { id: 9, name: 'Public Service Commissioner - District 1' },
              10: { id: 10, name: 'Public Service Commissioner - District 2' },
              11: { id: 11, name: 'Public Service Commissioner - District 3' },
              12: { id: 12, name: 'Public Service Commissioner - District 4' },
              13: { id: 13, name: 'Public Service Commissioner - District 5' },
              14: { id: 14, name: 'Supreme Court Justice' },
            },
          },
          2: {
            id: 2,
            name: 'House of Representatives',
            suboffices: {
              1: { id: 1, name: 'House District 1' },
              2: { id: 2, name: 'House District 2' },
              3: { id: 3, name: 'House District 3' },
              4: { id: 4, name: 'House District 4' },
              5: { id: 5, name: 'House District 5' },
              6: { id: 6, name: 'House District 6' },
              7: { id: 7, name: 'House District 7' },
              8: { id: 8, name: 'House District 8' },
              9: { id: 9, name: 'House District 9' },
              10: { id: 10, name: 'House District 10' },
              11: { id: 11, name: 'House District 11' },
              12: { id: 12, name: 'House District 12' },
              13: { id: 13, name: 'House District 13' },
              14: { id: 14, name: 'House District 14' },
              15: { id: 15, name: 'House District 15' },
              16: { id: 16, name: 'House District 16' },
              17: { id: 17, name: 'House District 17' },
              18: { id: 18, name: 'House District 18' },
              19: { id: 19, name: 'House District 19' },
              20: { id: 20, name: 'House District 20' },
              21: { id: 21, name: 'House District 21' },
              22: { id: 22, name: 'House District 22' },
              23: { id: 23, name: 'House District 23' },
              24: { id: 24, name: 'House District 24' },
              25: { id: 25, name: 'House District 25' },
              26: { id: 26, name: 'House District 26' },
              27: { id: 27, name: 'House District 27' },
              28: { id: 28, name: 'House District 28' },
              29: { id: 29, name: 'House District 29' },
              30: { id: 30, name: 'House District 30' },
              31: { id: 31, name: 'House District 31' },
              32: { id: 32, name: 'House District 32' },
              33: { id: 33, name: 'House District 33' },
              34: { id: 34, name: 'House District 34' },
              35: { id: 35, name: 'House District 35' },
              36: { id: 36, name: 'House District 36' },
              37: { id: 37, name: 'House District 37' },
              38: { id: 38, name: 'House District 38' },
              39: { id: 39, name: 'House District 39' },
              40: { id: 40, name: 'House District 40' },
              41: { id: 41, name: 'House District 41' },
              42: { id: 42, name: 'House District 42' },
              43: { id: 43, name: 'House District 43' },
              44: { id: 44, name: 'House District 44' },
              45: { id: 45, name: 'House District 45' },
              46: { id: 46, name: 'House District 46' },
              47: { id: 47, name: 'House District 47' },
              48: { id: 48, name: 'House District 48' },
              49: { id: 49, name: 'House District 49' },
              50: { id: 50, name: 'House District 50' },
              51: { id: 51, name: 'House District 51' },
              52: { id: 52, name: 'House District 52' },
              53: { id: 53, name: 'House District 53' },
              54: { id: 54, name: 'House District 54' },
              55: { id: 55, name: 'House District 55' },
              56: { id: 56, name: 'House District 56' },
              57: { id: 57, name: 'House District 57' },
              58: { id: 58, name: 'House District 58' },
              59: { id: 59, name: 'House District 59' },
              60: { id: 60, name: 'House District 60' },
              61: { id: 61, name: 'House District 61' },
              62: { id: 62, name: 'House District 62' },
              63: { id: 63, name: 'House District 63' },
              64: { id: 64, name: 'House District 64' },
              65: { id: 65, name: 'House District 65' },
              66: { id: 66, name: 'House District 66' },
              67: { id: 67, name: 'House District 67' },
              68: { id: 68, name: 'House District 68' },
              69: { id: 69, name: 'House District 69' },
              70: { id: 70, name: 'House District 70' },
              71: { id: 71, name: 'House District 71' },
              72: { id: 72, name: 'House District 72' },
              73: { id: 73, name: 'House District 73' },
              74: { id: 74, name: 'House District 74' },
              75: { id: 75, name: 'House District 75' },
              76: { id: 76, name: 'House District 76' },
              77: { id: 77, name: 'House District 77' },
              78: { id: 78, name: 'House District 78' },
              79: { id: 79, name: 'House District 79' },
              80: { id: 80, name: 'House District 80' },
              81: { id: 81, name: 'House District 81' },
              82: { id: 82, name: 'House District 82' },
              83: { id: 83, name: 'House District 83' },
              84: { id: 84, name: 'House District 84' },
              85: { id: 85, name: 'House District 85' },
              86: { id: 86, name: 'House District 86' },
              87: { id: 87, name: 'House District 87' },
              88: { id: 88, name: 'House District 88' },
              89: { id: 89, name: 'House District 89' },
              90: { id: 90, name: 'House District 90' },
              91: { id: 91, name: 'House District 91' },
              92: { id: 92, name: 'House District 92' },
              93: { id: 93, name: 'House District 93' },
              94: { id: 94, name: 'House District 94' },
              95: { id: 95, name: 'House District 95' },
              96: { id: 96, name: 'House District 96' },
              97: { id: 97, name: 'House District 97' },
              98: { id: 98, name: 'House District 98' },
              99: { id: 99, name: 'House District 99' },
              100: { id: 100, name: 'House District 100' },
              101: { id: 101, name: 'House District 101' },
              102: { id: 102, name: 'House District 102' },
              103: { id: 103, name: 'House District 103' },
              104: { id: 104, name: 'House District 104' },
              105: { id: 105, name: 'House District 105' },
              106: { id: 106, name: 'House District 106' },
              107: { id: 107, name: 'House District 107' },
              108: { id: 108, name: 'House District 108' },
              109: { id: 109, name: 'House District 109' },
              110: { id: 110, name: 'House District 110' },
              111: { id: 111, name: 'House District 111' },
              112: { id: 112, name: 'House District 112' },
              113: { id: 113, name: 'House District 113' },
              114: { id: 114, name: 'House District 114' },
              115: { id: 115, name: 'House District 115' },
              116: { id: 116, name: 'House District 116' },
              117: { id: 117, name: 'House District 117' },
              118: { id: 118, name: 'House District 118' },
              119: { id: 119, name: 'House District 119' },
              120: { id: 120, name: 'House District 120' },
              121: { id: 121, name: 'House District 121' },
              122: { id: 122, name: 'House District 122' },
              123: { id: 123, name: 'House District 123' },
              124: { id: 124, name: 'House District 124' },
              125: { id: 125, name: 'House District 125' },
              126: { id: 126, name: 'House District 126' },
              127: { id: 127, name: 'House District 127' },
              128: { id: 128, name: 'House District 128' },
              129: { id: 129, name: 'House District 129' },
              130: { id: 130, name: 'House District 130' },
              131: { id: 131, name: 'House District 131' },
              132: { id: 132, name: 'House District 132' },
              133: { id: 133, name: 'House District 133' },
              134: { id: 134, name: 'House District 134' },
              135: { id: 135, name: 'House District 135' },
              136: { id: 136, name: 'House District 136' },
              137: { id: 137, name: 'House District 137' },
              138: { id: 138, name: 'House District 138' },
              139: { id: 139, name: 'House District 139' },
              140: { id: 140, name: 'House District 140' },
              141: { id: 141, name: 'House District 141' },
              142: { id: 142, name: 'House District 142' },
              143: { id: 143, name: 'House District 143' },
              144: { id: 144, name: 'House District 144' },
              145: { id: 145, name: 'House District 145' },
              146: { id: 146, name: 'House District 146' },
              147: { id: 147, name: 'House District 147' },
              148: { id: 148, name: 'House District 148' },
              149: { id: 149, name: 'House District 149' },
              150: { id: 150, name: 'House District 150' },
              151: { id: 151, name: 'House District 151' },
              152: { id: 152, name: 'House District 152' },
              153: { id: 153, name: 'House District 153' },
              154: { id: 154, name: 'House District 154' },
              155: { id: 155, name: 'House District 155' },
              156: { id: 156, name: 'House District 156' },
              157: { id: 157, name: 'House District 157' },
              158: { id: 158, name: 'House District 158' },
              159: { id: 159, name: 'House District 159' },
              160: { id: 160, name: 'House District 160' },
              161: { id: 161, name: 'House District 161' },
              162: { id: 162, name: 'House District 162' },
              163: { id: 163, name: 'House District 163' },
              164: { id: 164, name: 'House District 164' },
              165: { id: 165, name: 'House District 165' },
              166: { id: 166, name: 'House District 166' },
              167: { id: 167, name: 'House District 167' },
              168: { id: 168, name: 'House District 168' },
              169: { id: 169, name: 'House District 169' },
              170: { id: 170, name: 'House District 170' },
              171: { id: 171, name: 'House District 171' },
              172: { id: 172, name: 'House District 172' },
              173: { id: 173, name: 'House District 173' },
              174: { id: 174, name: 'House District 174' },
              175: { id: 175, name: 'House District 175' },
              176: { id: 176, name: 'House District 176' },
              177: { id: 177, name: 'House District 177' },
              178: { id: 178, name: 'House District 178' },
              179: { id: 179, name: 'House District 179' },
              180: { id: 180, name: 'House District 180' },
            },
          },
          3: {
            id: 3,
            name: 'State Senate',
            suboffices: {
              1: { id: 1, name: 'State Senate District 1' },
              2: { id: 2, name: 'State Senate District 2' },
              3: { id: 3, name: 'State Senate District 3' },
              4: { id: 4, name: 'State Senate District 4' },
              6: { id: 6, name: 'State Senate District 6' },
              7: { id: 7, name: 'State Senate District 7' },
              8: { id: 8, name: 'State Senate District 8' },
              9: { id: 9, name: 'State Senate District 9' },
              10: { id: 10, name: 'State Senate District 10' },
              11: { id: 11, name: 'State Senate District 11' },
              12: { id: 12, name: 'State Senate District 12' },
              13: { id: 13, name: 'State Senate District 13' },
              14: { id: 14, name: 'State Senate District 14' },
              15: { id: 15, name: 'State Senate District 15' },
              16: { id: 16, name: 'State Senate District 16' },
              17: { id: 17, name: 'State Senate District 17' },
              18: { id: 18, name: 'State Senate District 18' },
              19: { id: 19, name: 'State Senate District 19' },
              20: { id: 20, name: 'State Senate District 20' },
              21: { id: 21, name: 'State Senate District 21' },
              22: { id: 22, name: 'State Senate District 22' },
              23: { id: 23, name: 'State Senate District 23' },
              24: { id: 24, name: 'State Senate District 24' },
              25: { id: 25, name: 'State Senate District 25' },
              26: { id: 26, name: 'State Senate District 26' },
              27: { id: 27, name: 'State Senate District 27' },
              28: { id: 28, name: 'State Senate District 28' },
              29: { id: 29, name: 'State Senate District 29' },
              30: { id: 30, name: 'State Senate District 30' },
              31: { id: 31, name: 'State Senate District 31' },
              32: { id: 32, name: 'State Senate District 32' },
              33: { id: 33, name: 'State Senate District 33' },
              34: { id: 34, name: 'State Senate District 34' },
              35: { id: 35, name: 'State Senate District 35' },
              36: { id: 36, name: 'State Senate District 36' },
              37: { id: 37, name: 'State Senate District 37' },
              38: { id: 38, name: 'State Senate District 38' },
              39: { id: 39, name: 'State Senate District 39' },
              40: { id: 40, name: 'State Senate District 40' },
              41: { id: 41, name: 'State Senate District 41' },
              42: { id: 42, name: 'State Senate District 42' },
              43: { id: 43, name: 'State Senate District 43' },
              44: { id: 44, name: 'State Senate District 44' },
              45: { id: 45, name: 'State Senate District 45' },
              46: { id: 46, name: 'State Senate District 46' },
              47: { id: 47, name: 'State Senate District 47' },
              48: { id: 48, name: 'State Senate District 48' },
              49: { id: 49, name: 'State Senate District 49' },
              50: { id: 50, name: 'State Senate District 50' },
              51: { id: 51, name: 'State Senate District 51' },
              52: { id: 52, name: 'State Senate District 52' },
              53: { id: 53, name: 'State Senate District 53' },
              54: { id: 54, name: 'State Senate District 54' },
              55: { id: 55, name: 'State Senate District 55' },
              56: { id: 56, name: 'State Senate District 56' },
            },
          },
          4: {
            id: 4,
            name: 'Court of Appeals Judge',
            suboffices: {
              1: { id: 1, name: 'Division 1' },
              2: { id: 2, name: 'Division 2' },
              3: { id: 3, name: 'Division 3' },
              4: { id: 4, name: 'Division 4' },
              5: { id: 5, name: 'Division 5' },
            },
          },
          5: {
            id: 5,
            name: 'Superior Court Judge',
            suboffices: {
              1: { id: 1, name: '1st Circuit' },
              2: { id: 2, name: '2nd Circuit' },
              3: { id: 3, name: '3rd Circuit' },
              4: { id: 4, name: '4th Circuit' },
              5: { id: 5, name: '5th Circuit' },
              6: { id: 6, name: '6th Circuit' },
              7: { id: 7, name: '7th Circuit' },
              8: { id: 8, name: '8th Circuit' },
              9: { id: 9, name: '9th Circuit' },
              10: { id: 10, name: '10th Circuit' },
              11: { id: 11, name: '11th Circuit' },
              12: { id: 12, name: '12th Circuit' },
              13: { id: 13, name: '13th Circuit' },
              14: { id: 14, name: '14th Circuit' },
              15: { id: 15, name: '15th Circuit' },
              16: { id: 16, name: '16th Circuit' },
              17: { id: 17, name: '17th Circuit' },
              18: { id: 18, name: '18th Circuit' },
              19: { id: 19, name: '19th Circuit' },
              20: { id: 20, name: '20th Circuit' },
              21: { id: 21, name: '21st Circuit' },
              22: { id: 22, name: '22nd Circuit' },
              23: { id: 23, name: '23rd Circuit' },
              24: { id: 24, name: '24th Circuit' },
              25: { id: 25, name: '25th Circuit' },
              26: { id: 26, name: '26th Circuit' },
              27: { id: 27, name: '27th Circuit' },
              28: { id: 28, name: '28th Circuit' },
              29: { id: 29, name: '29th Circuit' },
              30: { id: 30, name: '30th Circuit' },
              31: { id: 31, name: '31st Circuit' },
              32: { id: 32, name: '32nd Circuit' },
              33: { id: 33, name: '33rd Circuit' },
              34: { id: 34, name: '34th Circuit' },
              35: { id: 35, name: '35th Circuit' },
              36: { id: 36, name: '36th Circuit' },
              37: { id: 37, name: '37th Circuit' },
              38: { id: 38, name: '38th Circuit' },
              39: { id: 39, name: '39th Circuit' },
              40: { id: 40, name: '40th Circuit' },
              41: { id: 41, name: '41st Circuit' },
              42: { id: 42, name: '42nd Circuit' },
              43: { id: 43, name: '43rd Circuit' },
              44: { id: 44, name: '44th Circuit' },
              45: { id: 45, name: '45th Circuit' },
              46: { id: 46, name: '46th Circuit' },
              47: { id: 47, name: '47th Circuit' },
              48: { id: 48, name: '48th Circuit' },
              49: { id: 49, name: '49th Circuit' },
            },
          },
          6: {
            id: 6,
            name: 'State Court Judge',
            suboffices: {
              1: { id: 1, name: 'Appling County' },
              2: { id: 2, name: 'Atkinson County' },
              3: { id: 3, name: 'Bacon County' },
              4: { id: 4, name: 'Baldwin County' },
              5: { id: 5, name: 'Bibb County' },
              6: { id: 6, name: 'Brooks County' },
              7: { id: 7, name: 'Bryan County' },
              8: { id: 8, name: 'Bulloch County' },
              9: { id: 9, name: 'Burke County' },
              10: { id: 10, name: 'Candler County' },
              11: { id: 11, name: 'Carroll County' },
              12: { id: 12, name: 'Catoosa County' },
              13: { id: 13, name: 'Charlton County' },
              14: { id: 14, name: 'Chatham County' },
              15: { id: 15, name: 'Chattooga County' },
              16: { id: 16, name: 'Cherokee County' },
              17: { id: 17, name: 'Clarke County' },
              18: { id: 18, name: 'Clayton County' },
              19: { id: 19, name: 'Cobb County' },
              20: { id: 20, name: 'Coffee County' },
              21: { id: 21, name: 'Colquitt County' },
              22: { id: 22, name: 'Coweta County' },
              23: { id: 23, name: 'Decatur County' },
              24: { id: 24, name: 'DeKalb County' },
              25: { id: 25, name: 'Dougherty County' },
              26: { id: 26, name: 'Douglas County' },
              27: { id: 27, name: 'Early County' },
              28: { id: 28, name: 'Effingham County' },
              29: { id: 29, name: 'Elbert County' },
              30: { id: 30, name: 'Emanuel County' },
              31: { id: 31, name: 'Evans County' },
              32: { id: 32, name: 'Fayette County' },
              33: { id: 33, name: 'Forsyth County' },
              34: { id: 34, name: 'Fulton County' },
              35: { id: 35, name: 'Glynn County' },
              36: { id: 36, name: 'Grady County' },
              37: { id: 37, name: 'Gwinnett County' },
              38: { id: 38, name: 'Habersham County' },
              39: { id: 39, name: 'Hall County' },
              40: { id: 40, name: 'Henry County' },
              41: { id: 41, name: 'Houston County' },
              42: { id: 42, name: 'Jackson County' },
              43: { id: 43, name: 'Jeff Davis County' },
              44: { id: 44, name: 'Jefferson County' },
              45: { id: 45, name: 'Jenkins County' },
              46: { id: 46, name: 'Liberty County' },
              47: { id: 47, name: 'Lowndes County' },
              48: { id: 48, name: 'McIntosh County' },
              49: { id: 49, name: 'Miller County' },
              50: { id: 50, name: 'Mitchell County' },
              51: { id: 51, name: 'Muscogee County' },
              52: { id: 52, name: 'Pierce County' },
              53: { id: 53, name: 'Putnam County' },
              54: { id: 54, name: 'Richmond County' },
              55: { id: 55, name: 'Rockdale County' },
              56: { id: 56, name: 'Screven County' },
              57: { id: 57, name: 'Spalding County' },
              58: { id: 58, name: 'Stephens County' },
              59: { id: 59, name: 'Sumter County' },
              60: { id: 60, name: 'Tattnall County' },
              61: { id: 61, name: 'Thomas County' },
              62: { id: 62, name: 'Tift County' },
              63: { id: 63, name: 'Toombs County' },
              64: { id: 64, name: 'Treutlen County' },
              65: { id: 65, name: 'Troup County' },
              66: { id: 66, name: 'Turner County' },
              67: { id: 67, name: 'Walker County' },
              68: { id: 68, name: 'Ware County' },
              69: { id: 69, name: 'Washington County' },
              70: { id: 70, name: 'Worth County' },
            },
          },
          7: {
            id: 7,
            name: 'Probate Court Judge',
            suboffices: getCountiesForState('GA')
              .sort(sortByField('name', sortDirections.ASC))
              .reduce((list, county, index) => {
                list[index + 1] = {
                  id: index + 1,
                  name: county,
                };
                return list;
              }, {}),
          },
          8: {
            id: 8,
            name: 'Juvenile Court Judge',
            suboffices: getCountiesForState('GA')
              .sort(sortByField('name', sortDirections.ASC))
              .reduce((list, county, index) => {
                list[index + 1] = {
                  id: index + 1,
                  name: county,
                };
                return list;
              }, {}),
          },
          9: {
            id: 9,
            name: 'Magistrate Court Judge',
            suboffices: getCountiesForState('GA')
              .sort(sortByField('name', sortDirections.ASC))
              .reduce((list, county, index) => {
                list[index + 1] = {
                  id: index + 1,
                  name: county,
                };
                return list;
              }, {}),
          },
          10: {
            id: 10,
            name: 'District Attorney',
            suboffices: {
              1: { id: 1, name: 'Alapaha Judicial Circuit' },
              2: { id: 2, name: 'Alcovy Judicial Circuit' },
              3: { id: 3, name: 'Appalachian Judicial Circuit' },
              4: { id: 4, name: 'Atlanta Judicial Circuit' },
              5: { id: 5, name: 'Atlantic Judicial Circuit' },
              6: { id: 6, name: 'Augusta Judicial Circuit' },
              7: { id: 7, name: 'Bell-Forsyth Judicial Circuit' },
              8: { id: 8, name: 'Blue Ridge Judicial Circuit' },
              9: { id: 9, name: 'Brunswick Judicial Circuit' },
              10: { id: 10, name: 'Chattahoochee Judicial Circuit' },
              11: { id: 11, name: 'Cherokee Judicial Circuit' },
              12: { id: 12, name: 'Clayton Judicial Circuit' },
              13: { id: 13, name: 'Cobb Judicial Circuit' },
              14: { id: 14, name: 'Conasauga Judicial Circuit' },
              15: { id: 15, name: 'Cordele Judicial Circuit' },
              16: { id: 16, name: 'Coweta Judicial Circuit' },
              17: { id: 17, name: 'Dougherty Judicial Circuit' },
              18: { id: 18, name: 'Douglas Judicial Circuit' },
              19: { id: 19, name: 'Dublin Judicial Circuit' },
              20: { id: 20, name: 'Eastern Judicial Circuit' },
              21: { id: 21, name: 'Enotah Judicial Circuit' },
              22: { id: 22, name: 'Flint Judicial Circuit' },
              23: { id: 23, name: 'Griffin Judicial Circuit' },
              24: { id: 24, name: 'Gwinnett Judicial Circuit' },
              25: { id: 25, name: 'Houston Judicial Circuit' },
              26: { id: 26, name: 'Lookout Mountain Judicial Circuit' },
              27: { id: 27, name: 'Macon Judicial Circuit' },
              28: { id: 28, name: 'Middle Judicial Circuit' },
              29: { id: 29, name: 'Mountain Judicial Circuit' },
              30: { id: 30, name: 'Northeastern Judicial Circuit' },
              31: { id: 31, name: 'Northern Judicial Circuit' },
              32: { id: 32, name: 'Ocmulgee Judicial Circuit' },
              33: { id: 33, name: 'Oconee Judicial Circuit' },
              34: { id: 34, name: 'Ogeechee Judicial Circuit' },
              35: { id: 35, name: 'Pataula Judicial Circuit' },
              36: { id: 36, name: 'Paulding Judicial Circuit' },
              37: { id: 37, name: 'Piedmont Judicial Circuit' },
              38: { id: 38, name: 'Rockdale Judicial Circuit' },
              39: { id: 39, name: 'Rome Judicial Circuit' },
              40: { id: 40, name: 'South Georgia Judicial Circuit' },
              41: { id: 41, name: 'Southern Judicial Circuit' },
              42: { id: 42, name: 'Southwestern Judicial Circuit' },
              43: { id: 43, name: 'Stone Mountain Judicial Circuit' },
              44: { id: 44, name: 'Tallapoosa Judicial Circuit' },
              45: { id: 45, name: 'Tifton Judicial Circuit' },
              46: { id: 46, name: 'Toombs Judicial Circuit' },
              47: { id: 47, name: 'Towaliga Judicial Circuit' },
              48: { id: 48, name: 'Waycross Judicial Circuit' },
              49: { id: 49, name: 'Western Judicial Circuit' },
            },
          },
        },
      },
    },
  },
  3: {
    id: 3,
    name: 'Local',
    states: {
      GA: {
        value: 'GA',
        name: 'Georgia',
        offices: {
          1: {
            id: 1,
            name: 'City/Town/Other',
            suboffices: getCitiesForState('GA')
              .sort(sortByField('name', sortDirections.ASC))
              .reduce((list, city, index) => {
                list[index + 1] = {
                  id: index + 1,
                  name: city,
                };
                return list;
              }, {}),
          },
          2: {
            id: 2,
            name: 'County',
            suboffices: getCountiesForState('GA')
              .sort(sortByField('name', sortDirections.ASC))
              .reduce((list, county, index) => {
                list[index + 1] = {
                  id: index + 1,
                  name: county,
                };
                return list;
              }, {}),
          },
          3: {
            id: 3,
            name: 'Schoolboard',
            suboffices: {},
          },
        },
      },
    },
  },
};

const federalOfficesMap = {
  1: 'S',
  2: 'H',
  3: 'P',
};

const getOfficeTypeName = officeType => offices[officeType]?.name;

const getOfficeName = (
  officeType = 0,
  officeState = 0,
  officeCategory = 0,
  office = 0,
  isNonCandidateCommittee = false,
) => {
  if (isNonCandidateCommittee) {
    return null;
  }

  return officeType < 3
    ? offices[officeType]?.states[officeState]?.offices[office]?.name
    : offices[officeType]?.states[officeState]?.offices[officeCategory]
        ?.suboffices[office]?.name;
};

const getOfficeCategoryName = (
  officeType = 0,
  officeState = 0,
  officeCategory = 0,
  isNonCandidateCommittee = false,
) =>
  isNonCandidateCommittee || officeCategory === 0
    ? undefined
    : offices[officeType]?.states[officeState]?.offices[officeCategory]?.name;

const getOfficeForCreate = (
  officeType = 0,
  officeState = 0,
  officeCategory = 0,
  office = 0,
) => {
  const isFederal = officeType === signupOfficeTypes.FEDERAL;
  const isState = officeType === signupOfficeTypes.STATE;
  const isLocal = officeType === signupOfficeTypes.LOCAL;

  if (isFederal) {
    return federalOfficesMap[office];
  }

  if (isState) {
    return offices[officeType]?.states[officeState]?.offices[office]?.name;
  }

  if (isLocal) {
    return offices[officeType]?.states[officeState]?.offices[officeCategory]
      ?.name;
  }

  return undefined;
};

const getSubOfficeForCreate = (
  officeType,
  officeState,
  officeCategory,
  office,
  suboffice,
) => {
  const isFederal = officeType === signupOfficeTypes.FEDERAL;
  const isState = officeType === signupOfficeTypes.STATE;
  const isLocal = officeType === signupOfficeTypes.LOCAL;

  if (isFederal) {
    return federalOfficesMap[office] === 'H'
      ? offices[officeType]?.states[officeState]?.offices[office]?.suboffices[
          suboffice
        ]?.id?.toString()
      : undefined;
  }

  if (isState) {
    return offices[officeType]?.states[officeState]?.offices[office]
      ?.suboffices[suboffice]?.name;
  }

  if (isLocal) {
    return suboffice;
  }

  return undefined;
};

const getSubOfficeName = ({ officeType, officeState, office, suboffice }) => {
  const isFederal = officeType === signupOfficeTypes.FEDERAL;
  const isState = officeType === signupOfficeTypes.STATE;
  const isLocal = officeType === signupOfficeTypes.LOCAL;

  if (isFederal) {
    return federalOfficesMap[office] === 'H' ?
      offices[officeType]?.states[officeState]?.offices[office]?.suboffices[suboffice]?.name : undefined;
  }

  if (isState) {
    return offices[officeType]?.states[officeState]?.offices[office]?.suboffices[suboffice]?.name;
  }

  if (isLocal) {
    return office.suboffice;
  }

  return undefined;
};

export {
  offices,
  getOfficeTypeName,
  getOfficeCategoryName,
  getOfficeName,
  getOfficeForCreate,
  getSubOfficeForCreate,
  getSubOfficeName,
};
