import React, { useState, useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import Uuid from 'uuid/v4';
import { useDispatch, useSelector } from 'react-redux';
import {
  ActionButton,
  DefaultButton,
  IconButton,
  Separator,
  ScrollablePane,
  Stack,
  Text,
  mergeStyleSets,
  SearchBox,
  Icon,
  Spinner,
  SpinnerSize,
} from 'office-ui-fabric-react';
import { types as listActions } from '../../actions/listActions';
import { BackButton } from '../../components/common';
import ListFilterCallout from '../../components/ListFilterCallout';
import SelectedFilter from '../../components/ListFilterCallout/SelectedFilter';
import AdHocResultsList from './AdHocResultsList';
import { initialState, listsReducer, actions } from './listsReducer';
import {
  getUserSession,
  getListsForAdHoc,
  getListRecordCount,
  getListCSVStatus,
} from '../../selectors';
import { columns } from '../../helpers/listsHelper';
import { statuses } from '../../helpers/constants';
import { formatISODate } from '../../helpers/util';
import './Lists.css';

const styles = mergeStyleSets({
  columns: {
    height: 'calc(88vh - 242px)',
    width: '100%',
    boxSizing: 'border-box',
    position: 'relative',
  },
  columnsHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  clearColumnsButton: {
    marginLeft: 16,
    cursor: 'pointer',
    fontSize: '0.8rem',
    color: 'blue',
    textTransform: 'none',
  },
  topRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  list: {
    height: 'calc(100vh - 245px)',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    boxSizing: 'border-box',
  },
  listBlurb: {
    fontSize: '1.3rem',
    padding: 60,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
});

export const Lists = ({ history }) => {
  const reduxDispatch = useDispatch();
  const lists = useSelector(getListsForAdHoc);
  const session = useSelector(getUserSession);

  const [state, localDispatch] = useReducer(listsReducer, initialState);
  const [items, setItems] = useState([]);
  const totalRecords = useSelector(getListRecordCount);
  const listCSVStatus = useSelector(getListCSVStatus);

  useEffect(() => {
    if (state.columns.length > 0) {
      reduxDispatch({
        type: listActions.GET_LIST,
        data: {
          fields: state.columns.join(','),
          filters: JSON.stringify(
            state.filters.reduce((list, filter) => {
              const { column, expression } = filter;
              let { value } = filter;
              if (column === 'transactionDate') {
                value = formatISODate(value);
              }
              list = [
                ...list,
                {
                  column,
                  expression,
                  value: Array.isArray(value)
                    ? value.join(',')
                    : column === 'amount' || column === 'aggregateAmount'
                    ? +value
                    : value + '',
                },
              ];
              return list;
            }, []),
          ),
        },
      });
    }
  }, [state.columns, state.filters]);

  useEffect(() => {
    return () => {
      reduxDispatch({
        type: listActions.CLEAR_LISTS,
      });
    };
  }, []);

  useEffect(() => {
    if (lists.length) {
      setItems(lists);
    }
  }, [lists]);

  const openFilterCallout = () => {
    localDispatch({
      type: actions.OPEN_FILTER_CALLOUT,
    });
  };

  const closeFilterCallout = () => {
    localDispatch({
      type: actions.CLOSE_FILTER_CALLOUT,
    });
  };

  const addFilter = filter => {
    localDispatch({ type: actions.ADD_FILTER, data: { filter, id: Uuid() } });
  };

  const removeFilter = id => {
    localDispatch({ type: actions.REMOVE_FILTER, data: { id } });
  };

  const toggleColumn = key => () => {
    if (state.columns.includes(key)) {
      localDispatch({
        type: actions.REMOVE_COLUMN,
        data: { key },
      });
    } else {
      localDispatch({
        type: actions.ADD_COLUMN,
        data: { key },
      });
    }
  };

  const exportToCSV = async () => {
    reduxDispatch({
      type: listActions.GET_CSV,
      data: {
        fields: state.columns.join(','),
        filters: JSON.stringify(
          state.filters.reduce((list, filter) => {
            const { column, expression } = filter;
            let { value } = filter;
            if (column === 'transactionDate') {
              value = formatISODate(value);
            }
            list = [
              ...list,
              {
                column,
                expression,
                value: Array.isArray(value)
                  ? value.join(',')
                  : column === 'amount' || column === 'aggregateAmount'
                  ? +value
                  : value + '',
              },
            ];
            return list;
          }, []),
        ),
      },
    });
  };

  const handleChange = fieldName => (e, option) => {
    const value = option.key !== undefined ? option.key : option;
    localDispatch({
      type: actions.HANDLE_CHANGE,
      data: { fieldName, value },
    });
  };

  const clearColumns = () => {
    localDispatch({
      type: actions.CLEAR_COLUMNS,
    });
  };

  return (
    <>
      <BackButton
        history={history}
        isFixed
        pageTitle="Ad-hoc Lists and Custom Reports"
        url="/filer/listsIndex"
      />
      <div className="Lists depth-1">
        <div className="options-panel ms-hiddenMdDown">
          <Stack>
            <DefaultButton
              text="Add Filter"
              onClick={openFilterCallout}
              iconProps={{ iconName: 'Filter' }}
              className="AddFilterButton"
            />
            {state.filterCalloutVisible && (
              <ListFilterCallout
                onDismiss={closeFilterCallout}
                onAddFilter={addFilter}
                session={session}
              />
            )}
            <Separator />
            <div className={styles.columns}>
              <h3 className={styles.columnsHeader}>
                Columns
                {state.columns.length > 0 && (
                  <Text
                    className={styles.clearColumnsButton}
                    onClick={clearColumns}
                    variant="xSmall"
                  >
                    clear
                  </Text>
                )}
              </h3>
              <ScrollablePane
                className={styles.columns}
                initialScrollPosition={0}
              >
                {columns({ session }).map(({ key, text }) => {
                  const isSelected = state.columns.includes(key);
                  return (
                    <div key={key} className="column">
                      <Text variant="small">{text}</Text>
                      <IconButton
                        onClick={toggleColumn(key)}
                        iconProps={{
                          iconName: isSelected ? 'MinusCircle' : 'PlusCircle',
                          styles: {
                            root: {
                              color: isSelected ? 'red' : 'inherit',
                            },
                          },
                        }}
                      />
                    </div>
                  );
                })}
              </ScrollablePane>
            </div>
          </Stack>
        </div>
        {listCSVStatus === statuses.PROCESSING ? (
          <div className="list loading-csv">
            <Spinner size={SpinnerSize.large} label="Generating CSV" />
          </div>
        ) : (
          <div className="list">
            <Stack childrenGap={8}>
              <Stack horizontal childrenGap={8} className={styles.topRow}>
                <ActionButton
                  text={`Export to CSV (${totalRecords} records)`}
                  onClick={exportToCSV}
                  disabled={lists.length === 0 || state.columns.length === 0}
                  iconProps={{
                    iconName: 'FileCSV',
                  }}
                />
                <SearchBox
                  placeholder="Search"
                  value={state.filterText}
                  onChange={handleChange('filterText')}
                  clearButtonProps={{
                    iconProps: {
                      iconName: 'Times',
                    },
                  }}
                  styles={{
                    root: {
                      visibility: 'hidden',
                      marginTop: 15,
                      marginLeft: 19,
                    },
                  }}
                />
                <div className="filters-lists">
                  {state.filters.map(filter => (
                    <SelectedFilter
                      key={filter.id}
                      filter={filter}
                      removeFilter={removeFilter}
                      session={session}
                    />
                  ))}
                </div>
              </Stack>
              <Stack childrenGap={8} className={styles.list}>
                {lists.length > 0 && state.columns.length > 0 ? (
                  <AdHocResultsList
                    session={session}
                    items={items}
                    selectedColumns={state.columns}
                  />
                ) : (
                  <p className={styles.listBlurb}>
                    <Icon
                      iconName="ArrowLeft"
                      styles={{ root: { marginRight: 16 } }}
                    />
                    Choose columns to preview first 500 results
                  </p>
                )}
              </Stack>
            </Stack>
          </div>
        )}
      </div>
    </>
  );
};

Lists.propTypes = {
  history: PropTypes.object.isRequired,
};

export default Lists;
