import axios from 'axios';
import moment from 'moment';
import {
  DefaultButton,
  PrimaryButton,
} from 'office-ui-fabric-react/lib/Button';
import { ChoiceGroup } from 'office-ui-fabric-react/lib/ChoiceGroup';
import { Dialog, DialogType } from 'office-ui-fabric-react/lib/Dialog';
import { Dropdown } from 'office-ui-fabric-react/lib/Dropdown';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { TooltipHost } from 'office-ui-fabric-react/lib/Tooltip';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import { sortDirections } from '../../helpers/constants';
import {
  formatDate,
  formatDateOnly,
  formatNonStandardDate,
  sortByField,
} from '../../helpers/util';
import {
  Column,
  DataLabel,
  EmailField,
  Grid,
  GridRow,
  PhoneNumberField,
  ZipCodeField,
} from '../common';
import { CaretDown } from '../icons';
import { DatePicker, FiledReportPicker, StatePicker } from '../Pickers';
import { validate } from './AddGAReportDialogValidations';
import './AddReportDialog.css';

const initialState = {
  filingStatus: 0,
  electionDate: 0,
  reportingPeriod: -1,
  reportYear: 0,
  startDate: null,
  endDate: null,
  previousReportId: 0,
  report: { key: 0 },
  electionCycle: { key: 0 },
  electionCycleId: 0,
  reportOptions: [],
  electionCycleOptions: [],
  isLoadingElectionCycles: false,
  isLoadingReports: false,
  gACommunicationError: '',
  isGAStateFiling: true,
};

const initialCampaignRecordMaintainerState = {
  firstName: '',
  middleName: '',
  lastName: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: 0,
  zipCode: '',
  phone1: '',
  email: '',
};

export const AddGAReportDialog = ({
  dialogHidden,
  closeDialog,
  createReport,
  electionYear,
  electionDates,
  registrationDate,
  reportType,
  selectedReportingPeriod,
  isNonCandidateCommittee,
}) => {
  const [state, setState] = useState({ ...initialState });
  const [campaignRecordMaintainer, setCampaignRecordMaintainer] = useState({
    ...initialCampaignRecordMaintainerState,
  });
  const [errors, setErrors] = useState({});

  const getOptionsFromGAReportsReturn = data => {
    const ret = data?.reports?.map(r => {
      const { ReportId, ReportName, StartDate, EndDate } = r;
      return {
        key: ReportId,
        text: (
          <span>
            {ReportName}&nbsp;&nbsp;&nbsp;({formatDate(StartDate)} -{' '}
            {formatDate(EndDate)})
          </span>
        ),
        data: r,
      };
    });
    return ret;
  };

  const getOptionsFromGAElectionCyclesReturn = data => {
    return data?.electionCycles?.map(r => {
      const { CycleId, CycleName } = r;
      return {
        key: CycleId,
        text: CycleName,
      };
    });
  };

  const getElectionCycleOptions = async () => {
    try {
      setState({
        ...state,
        isLoadingElectionCycles: true,
      });
      const { data: electionCycles } = await axios.get(
        '/api/filer/ga/electionCycles',
      );
      const electionCycleOptions = electionCycles.ok
        ? getOptionsFromGAElectionCyclesReturn(electionCycles)
        : [];
      const electionCycleId = electionCycleOptions
        ? electionCycleOptions[0].key
        : 0;
      const gACommunicationError = electionCycles.ok
        ? ''
        : 'Unable to retrieve the report data from Georgia. Please contact FrontRunner if the issue persists.';
      setState({
        ...state,
        electionCycleOptions,
        electionCycleId,
        gACommunicationError,
        isLoadingElectionCycles: false,
      });
    } catch (err) {
      const gACommunicationError =
        'Unable to retrieve the report data from Georgia. Please contact FrontRunner if the issue persists.';
      setState({
        ...state,
        gACommunicationError,
        isLoadingElectionCycles: false,
      });
    }
  };

  const getReportOptions = async () => {
    try {
      setState({
        ...state,
        isLoadingReports: true,
      });
      const { data: reportData } = await axios.get(
        `/api/filer/ga/reports/${state.electionCycleId}`,
      );
      const reportOptions = reportData.ok
        ? getOptionsFromGAReportsReturn(reportData)
        : [];
      const gACommunicationError = reportData.ok
        ? ''
        : 'Unable to retrieve the report data from Georgia. Please contact FrontRunner if the issue persists.';
      setState({
        ...state,
        reportOptions,
        gACommunicationError,
      });
    } catch (err) {
      setState({
        ...state,
        gACommunicationError:
          'Unable to retrieve the report data from Georgia. Please contact FrontRunner if the issue persists.',
        isLoadingReports: false,
      });
    }
  };

  useEffect(() => {
    if (!dialogHidden) {
      getElectionCycleOptions();
    }
  }, [dialogHidden]);

  useEffect(() => {
    if (state.electionCycleId !== 0) {
      getReportOptions();
    }
  }, [state.electionCycleId]);

  useEffect(() => {
    if (selectedReportingPeriod && state.reportingPeriod === -1) {
      setState({
        ...state,
        reportingPeriod: selectedReportingPeriod,
      });
    }
  }, [selectedReportingPeriod]);

  const handleChange = fieldName => (e, value) => {
    setState({
      ...state,
      [fieldName]: value.key !== undefined ? value.key : value,
    });
  };

  const handleReportSelection = fieldName => (e, value) => {
    setState({
      ...state,
      [fieldName]: value,
      startDate: value.data.StartDate && moment(value.data.StartDate).toDate(),
      endDate: value.data.EndDate && moment(value.data.EndDate).toDate(),
    });
  };

  const handleElectionCycleSelection = fieldName => (e, value) => {
    setState({
      ...state,
      [fieldName]: value.key,
    });
  };

  const handleMaintainerChange = fieldName => (e, value) => {
    setCampaignRecordMaintainer({
      ...campaignRecordMaintainer,
      [fieldName]: value.key !== undefined ? value.key : value,
    });
  };

  const handleChangeDate = fieldName => date => {
    setState({
      ...state,
      [fieldName]: date,
    });
  };

  const onCloseDialog = () => {
    setState({ ...initialState });
    setCampaignRecordMaintainer({ ...initialCampaignRecordMaintainerState });
    setErrors({});

    closeDialog();
  };

  const getReportType = () => {
    if (reportType === 'TBD') {
      return '17';
    }

    if (reportType === 'Termination') {
      return '18';
    }

    // We don't use dropdown any longer for GA state
    // and model requires it for most other non federal reports
    return -1;
  };

  const onCreateReport = () => {
    const errors = validate(state, campaignRecordMaintainer, reportType);
    const isFirstReport = state.filingStatus === 1;
    if (Object.keys(errors).length) {
      setErrors(errors);
    } else {
      const payload = {
        electionDate: electionDates.find(ed => ed._id === state.electionDate)
          .electionDate,
        electionYear,
        isFirstFilingForCurrentOffice:
          reportType === '' && state.filingStatus === 1,
        isFirstFilingForElectionCycle:
          reportType === '' && state.filingStatus === 2,
        isSecondOrSubsequentFilingForElectionCycle:
          reportType === '' && state.filingStatus === 3,
        isTerminationReport: reportType === 'Termination',
        reportDate: formatDateOnly(new Date()),
        reportType:
          reportType === 'TBD' ? 'TBD Contribution Report' : 'Original',
        reportingPeriod: getReportType(),
        reportingPeriodYear: state.reportYear,
        startDate: state.startDate ? formatDateOnly(state.startDate) : null,
        endDate: state.endDate ? formatDateOnly(state.endDate) : null,
        previousReportId: isFirstReport ? undefined : state.previousReportId,
        reportingName: state?.report?.data?.ReportName,
        reportingId: state?.report?.key,
      };

      if (reportType === 'Termination') {
        payload.campaignRecords = {
          firstName: campaignRecordMaintainer.firstName,
          middleName: campaignRecordMaintainer.middleName,
          lastName: campaignRecordMaintainer.lastName,
          addressLine1: campaignRecordMaintainer.addressLine1,
          addressLine2: campaignRecordMaintainer.addressLine2,
          city: campaignRecordMaintainer.city,
          state: campaignRecordMaintainer.state,
          zipCode: campaignRecordMaintainer.zipCode,
          telephoneNumber: campaignRecordMaintainer.phone1,
          emailAddress: campaignRecordMaintainer.email,
        };
      }

      createReport(payload);
      setState({ ...initialState });
      setCampaignRecordMaintainer({ ...initialCampaignRecordMaintainerState });
      setErrors({});
    }
  };

  const getDialogTitle = () => {
    switch (reportType) {
      case 'TBD':
        return 'Create TBD Report';
      case 'Termination':
        return 'Create Final Report';
      default:
        return 'Create Disclosure Report';
    }
  };

  const renderTitle = v => {
    return v[0].text;
  };

  if (state.isLoadingElectionCycles) {
    return (
      <Dialog
        hidden={dialogHidden}
        onDismiss={closeDialog}
        dialogContentProps={{
          type: DialogType.normal,
          title: getDialogTitle(),
          subText: '',
        }}
        modalProps={{
          isBlocking: false,
          containerClassName: 'AddReportDialog',
        }}
      >
        <Spinner size={SpinnerSize.medium} />
      </Dialog>
    );
  }

  if (state.gACommunicationError) {
    return (
      <Dialog
        hidden={dialogHidden}
        onDismiss={closeDialog}
        dialogContentProps={{
          type: DialogType.close,
          title: getDialogTitle(),
          subText: '',
        }}
        modalProps={{
          isBlocking: false,
        }}
      >
        <h3>{state.gACommunicationError}</h3>
      </Dialog>
    );
  }

  return (
    <Dialog
      hidden={dialogHidden}
      onDismiss={closeDialog}
      dialogContentProps={{
        type: DialogType.normal,
        title: getDialogTitle(),
        subText: '',
      }}
      modalProps={{
        isBlocking: false,
        containerClassName: 'AddReportDialog',
      }}
    >
      <div className="add-disclosure-report">
        <Grid>
          {reportType === '' && (
            <Fragment>
              <GridRow>
                {isNonCandidateCommittee && registrationDate && (
                  <Column lg={4}>
                    <DataLabel
                      size="large"
                      label="Registration Year"
                      value={formatDate(registrationDate, 'YYYY')}
                    />
                  </Column>
                )}
              </GridRow>
              <GridRow>
                <Column>
                  {errors.filingStatusError && (
                    <p className="error-message">{errors.filingStatusError}</p>
                  )}
                </Column>
              </GridRow>
            </Fragment>
          )}
          {reportType === 'Termination' && (
            <div className="campaign-maintainer">
              <GridRow>
                <Column>
                  <h3>Person Responsible for Maintaining Campaign Records</h3>
                </Column>
              </GridRow>
              <GridRow>
                <Column lg={4}>
                  <TextField
                    value={campaignRecordMaintainer.firstName}
                    label="First Name"
                    errorMessage={errors.firstNameError}
                    onChange={handleMaintainerChange('firstName')}
                    required
                  />
                </Column>
                <Column lg={4}>
                  <TextField
                    value={campaignRecordMaintainer.middleName}
                    label="Middle Name"
                    onChange={handleMaintainerChange('middleName')}
                  />
                </Column>
                <Column lg={4}>
                  <TextField
                    value={campaignRecordMaintainer.lastName}
                    label="Last Name"
                    errorMessage={errors.lastNameError}
                    onChange={handleMaintainerChange('lastName')}
                    required
                  />
                </Column>
              </GridRow>
              <GridRow>
                <Column lg={6}>
                  <TextField
                    value={campaignRecordMaintainer.addressLine1}
                    label="Address 1"
                    errorMessage={errors.addressLine1Error}
                    required
                    onChange={handleMaintainerChange('addressLine1')}
                  />
                </Column>
                <Column lg={6}>
                  <TextField
                    value={campaignRecordMaintainer.addressLine2}
                    label="Address 2"
                    onChange={handleMaintainerChange('addressLine2')}
                  />
                </Column>
              </GridRow>
              <GridRow>
                <Column lg={4}>
                  <TextField
                    value={campaignRecordMaintainer.city}
                    label="City"
                    errorMessage={errors.cityError}
                    onChange={handleMaintainerChange('city')}
                    required
                  />
                </Column>
                <Column lg={4}>
                  <StatePicker
                    selectedKey={campaignRecordMaintainer.state}
                    onChange={handleMaintainerChange('state')}
                    required
                    errorMessage={errors.stateError}
                  />
                </Column>
                <Column lg={4}>
                  <ZipCodeField
                    value={campaignRecordMaintainer.zipCode}
                    errorMessage={errors.zipCodeError}
                    onChange={handleMaintainerChange('zipCode')}
                    required
                  />
                </Column>
              </GridRow>
              <GridRow>
                <Column lg={6}>
                  <PhoneNumberField
                    label="Phone"
                    value={campaignRecordMaintainer.phone1}
                    errorMessage={errors.phone1Error}
                    required
                    onChange={handleMaintainerChange('phone1')}
                  />
                </Column>
                <Column lg={6}>
                  <EmailField
                    formSection="campaign-maintainer"
                    value={campaignRecordMaintainer.email}
                    errorMessage={errors.emailError}
                    required
                    onChange={handleMaintainerChange('email')}
                  />
                </Column>
              </GridRow>
            </div>
          )}
          {state.electionCycleOptions && state.electionCycleOptions.length > 1 && (
            <GridRow>
              <Column>
                <Dropdown
                  onRenderCaretDown={() => <CaretDown />}
                  label="Election Cycles"
                  required
                  onRenderTitle={renderTitle}
                  errorMessage={errors.electionCycleError}
                  selectedKey={state.electionCycleId}
                  onChange={handleElectionCycleSelection('electionCycleId')}
                  options={[
                    { key: 0, text: 'Select' },
                    ...state.electionCycleOptions,
                  ]}
                />
              </Column>
            </GridRow>
          )}
          <GridRow>
            <Column>
              {state.isLoadingReports && (
                <div style={{ paddingTop: '24px' }}>
                  <Spinner
                    size={SpinnerSize.medium}
                    label="Loading reports for election cycle"
                  />
                </div>
              )}
              {!state.isLoadingReports && (
                <Dropdown
                  onRenderCaretDown={() => <CaretDown />}
                  label="Report"
                  required
                  onRenderTitle={renderTitle}
                  errorMessage={errors.reportError}
                  selectedKey={state.report.key}
                  onChange={handleReportSelection('report')}
                  options={[
                    {
                      key: 0,
                      text: 'Select',
                      data: { StartDate: null, EndDate: null },
                    },
                    ...state.reportOptions,
                  ]}
                />
              )}
            </Column>
          </GridRow>
          <GridRow>
            <Column lg={6}>
              <DatePicker
                label={'Report Start Date'}
                value={state.startDate}
                placeholder="Select Date"
                errorMessage={errors.startDateError}
                onChange={handleChangeDate('startDate')}
                disabled={true}
              />
            </Column>
            <Column lg={6}>
              <DatePicker
                label={'Report End Date'}
                value={state.endDate}
                placeholder="Select Date"
                errorMessage={errors.endDateError}
                onChange={handleChangeDate('endDate')}
                disabled={true}
              />
            </Column>
          </GridRow>
          <GridRow>
            <Column>
              <Dropdown
                onRenderCaretDown={() => <CaretDown />}
                label="Election Date"
                required
                errorMessage={errors.electionDateError}
                selectedKey={state.electionDate}
                onChange={handleChange('electionDate')}
                options={[
                  { key: 0, text: 'Select' },
                  ...electionDates
                    .sort(sortByField('electionDate', sortDirections.ASC))
                    .map(ed => ({
                      key: ed._id,
                      text: `${formatNonStandardDate(
                        ed.electionDate,
                        'YYYY-MM-DD',
                      )} - ${ed.electionCycle}`,
                    })),
                ]}
              />
            </Column>
          </GridRow>
          <GridRow>
            <Column>
              <ChoiceGroup
                required
                label="Filing Status"
                selectedKey={state.filingStatus}
                onChange={handleChange('filingStatus')}
                options={[
                  {
                    key: 1,
                    text:
                      'This is the first filing for the Office for which I am running',
                  },
                  {
                    key: 2,
                    text: 'This is the first filing for this Election Cycle',
                  },
                  {
                    key: 3,
                    text:
                      'This is the second or subsequent filing for this Election Cycle',
                  },
                ]}
              />
              {errors.filingStatusError && (
                <p className="error-message">{errors.filingStatusError}</p>
              )}
            </Column>
          </GridRow>
          {state.filingStatus !== 1 && reportType !== 'TBD' && (
            <>
              <GridRow>
                <Column md={11}>
                  <FiledReportPicker
                    label="Previous Report Balance Source"
                    selectedKey={state.previousReportId}
                    onChange={handleChange('previousReportId')}
                    errorMessage={errors.previousReportIdError}
                    required
                  />
                </Column>
                <Column md={1}>
                  <TooltipHost
                    content="All reports but first filing for office must contain a reference to obtain the current totals to date. This can either be the campaign default settings or a reference to a previously filed report."
                    closeDelay={750}
                  >
                    <Icon
                      iconName="Help"
                      styles={{
                        root: {
                          fontSize: '10pt',
                          backgroundColor: 'var(--primary-color))',
                          display: 'inline-flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          width: 24,
                          height: 24,
                          color: '#fff',
                          borderRadius: '50%',
                          marginTop: '33px',
                        },
                      }}
                    />
                  </TooltipHost>
                </Column>
                <Column md={6} />
              </GridRow>
            </>
          )}
        </Grid>
      </div>
      <div className="add-report-actions">
        <DefaultButton
          text="Cancel"
          onClick={onCloseDialog}
          style={{ marginRight: 16 }}
        />
        <PrimaryButton text="Create Report" onClick={onCreateReport} />
      </div>
    </Dialog>
  );
};

AddGAReportDialog.propTypes = {
  dialogHidden: PropTypes.bool,
  closeDialog: PropTypes.func.isRequired,
  createReport: PropTypes.func.isRequired,
  electionYear: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  electionDates: PropTypes.array.isRequired,
  registrationDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  reportType: PropTypes.string,
  selectedReportingPeriod: PropTypes.string,
  isNonCandidateCommittee: PropTypes.bool,
};

AddGAReportDialog.defaultProps = {
  dialogHidden: true,
  reportType: '',
  selectedReportingPeriod: null,
  isNonCandidateCommittee: false,
  registrationDate: '',
};

export default AddGAReportDialog;
