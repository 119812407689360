import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

class PrivateRoute extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    component: PropTypes.func.isRequired,
  };

  render() {
    const { component: Component, user, ...rest } = this.props;

    return (
      <Route
        {...rest}
        render={routeProps => {
          if (user.isAuthenticated) {
            return <Component {...routeProps} />;
          }
          return (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: routeProps.location },
              }}
            />
          );
        }}
      />
    );
  }
}

function mapStateToProps(state) {
  return { user: state.user };
}

export default connect(
  mapStateToProps,
  null,
)(PrivateRoute);
