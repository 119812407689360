import React, { useEffect, useReducer } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import uuid from 'uuid/v4';
import { useSelector, useDispatch } from 'react-redux';
import {
  DefaultButton,
  PrimaryButton,
  Stack,
  Icon,
  Panel,
  PanelType,
  Pivot,
  PivotItem,
} from 'office-ui-fabric-react';
import { types as contactTransactionActions } from '../../actions/contactTransactionActions';
import ContactForm from '../ContactForm';
import ContactTransactions from '../ContactTransactions';
import {
  initialState,
  actions,
  ContactDetailsReducer,
} from './ContactDetailsReducer';
import {
  getCampaign,
  getContactTransactions,
  getUserSession,
} from '../../selectors';

export const ContactDetailsPanel = ({
  showPanel = false,
  contact = {},
  closePanel,
  saveContact,
  addContribution,
  updateContact,
  deleteContact,
}) => {
  const reduxDispatch = useDispatch();
  const [state, localDispatch] = useReducer(
    ContactDetailsReducer,
    initialState,
  );
  const session = useSelector(getUserSession);
  const contactTransactions = useSelector(getContactTransactions);
  const campaign = useSelector(getCampaign);

  const getHouseholdData = async (id) => {
    const { data } = await axios.get(`/api/filer/contacts/${id}`);
    for (let i = 0; i < data.contactLinks.length; i += 1) {
      const contactLink = data.contactLinks[i];
      const { contactType } = contactLink;
      const isPerson = contactType === 'IND' || contactType === 'CAN' || contactType === 'Individual';
      const displayName = !isPerson ? contactLink.businessName : `${contactLink.firstName} ${contactLink.lastName}`;
      const contact = {
        _id: contactLink.id,
        displayName,
      };
      localDispatch({
        type: actions.ON_HOUSEHOLD_MEMBER_SELECTED,
        data: { contact },
      });
      localDispatch({
        type: actions.ADD_TO_HOUSEHOLD_ORIGINAL,
        data: { contact },
      });
    }
  };

  useEffect(() => {
    if (contact && contact._id) {
      getHouseholdData(contact._id);
      reduxDispatch({
        type: contactTransactionActions.GET_TRANSACTIONS,
        data: {
          id: contact._id,
        },
      });
      localDispatch({
        type: actions.SET_CONTACT_DETAILS,
        data: {
          contact: {
            ...contact,
            taxId: contact.taxId || '',
            family: {
              spouse: {
                firstName: contact?.family?.spouse?.firstName || '',
                middleName: contact?.family?.spouse?.middleName || '',
                lastName: contact?.family?.spouse?.lastName || '',
              },
              children: (contact?.family?.children || []).map(child => ({
                ...child,
                _id: uuid(),
              })),
            },
            additionalAddresses: (contact?.additionalAddresses || []).map(
              item => ({
                addressLine1: item.addressLine1 || '',
                addressLine2: item.addressLine2 || '',
                city: item.city || '',
                state: item.state || 0,
                zipCode: item.zipCode || '',
                county: item.county || '',
                _id: item._id || uuid(),
              }),
            ),
            additionalTelephoneNumbers: (
              contact?.additionalTelephoneNumbers || []
            ).reduce((acc, item) => {
              if (item && item.number && item._id) {
                acc = [...acc, { value: item.number, _id: item._id }];
              }
              return acc;
            }, []),
            additionalEmailAddresses: (
              contact?.additionalEmailAddresses || []
              ).map(
              item => ({
                _id: uuid(),
                value: item,
              }),
            ),
            notes: (contact.notes || []).map(note => ({
              value: note.text,
              _id: uuid(),
            })),
          },
        },
      });
    }
  }, [contact]);

  useEffect(() => {
    return () => {
      reduxDispatch({
        type: contactTransactionActions.GET_TRANSACTIONS_RESET,
      });
    };
  }, []);

  const onLinkClick = item => {
    localDispatch({
      type: actions.ON_LINK_CLICK,
      data: {
        id: item.props.id,
      },
    });
  };

  const handleChange = fieldName => (e, value) => {
    localDispatch({
      type: actions.HANDLE_CHANGE,
      data: {
        fieldName,
        value: value.key !== undefined ? value.key : value,
      },
    });
  };

  const handleChangeNewAddress = fieldName => (e, value) => {
    localDispatch({
      type: actions.HANDLE_CHANGE_NEW_ADDRESS,
      data: {
        fieldName,
        value: value.key !== undefined ? value.key : value,
      },
    });
  };

  const handleChangeAdditionalAddress = _id => fieldName => (e, value) => {
    localDispatch({
      type: actions.HANDLE_CHANGE_ADDITIONAL_ADDRESS,
      data: {
        _id,
        fieldName,
        value: value.key !== undefined ? value.key : value,
      },
    });
  };

  const handleDeleteAdditionalAddress = _id => () => {
    localDispatch({
      type: actions.DELETE_ADDITIONAL_ADDRESS,
      data: {
        _id,
      },
    });
  };

  const handleChangeNewEmail = (e, value) => {
    localDispatch({
      type: actions.HANDLE_CHANGE_NEW_EMAIL,
      data: {
        value,
      },
    });
  };

  const handleChangeAdditionalEmail = _id => (e, value) => {
    localDispatch({
      type: actions.HANDLE_CHANGE_ADDITIONAL_EMAIL,
      data: {
        _id,
        value,
      },
    });
  };

  const handleDeleteAdditionalEmail = _id => () => {
    localDispatch({
      type: actions.DELETE_ADDITIONAL_EMAIL,
      data: {
        _id,
      },
    });
  };

  const handleChangeNewPhone = (e, value) => {
    localDispatch({
      type: actions.HANDLE_CHANGE_NEW_PHONE,
      data: {
        value,
      },
    });
  };

  const handleChangeAdditionalPhone = _id => (e, value) => {
    localDispatch({
      type: actions.HANDLE_CHANGE_ADDITIONAL_PHONE,
      data: {
        _id,
        value,
      },
    });
  };

  const handleDeleteAdditionalPhone = _id => () => {
    localDispatch({
      type: actions.DELETE_ADDITIONAL_PHONE,
      data: { _id },
    });
  };

  const handleChangeChild = _id => (e, value) => {
    localDispatch({
      type: actions.HANDLE_CHANGE_CHILD,
      data: { _id, value },
    });
  };

  const handleChangeNewChild = (e, value) => {
    localDispatch({
      type: actions.HANDLE_CHANGE_NEW_CHILD,
      data: { value },
    });
  };

  const handleDeleteChild = _id => () => {
    localDispatch({ type: actions.DELETE_CHILD, data: { _id } });
  };

  const addNewChildStart = () => {
    localDispatch({ type: actions.ADD_NEW_CHILD_START });
  };

  const cancelAddChild = () => {
    localDispatch({ type: actions.CANCEL_ADD_CHILD });
  };

  const addNewChildFinish = () => {
    localDispatch({
      type: actions.ADD_NEW_CHILD_FINISH,
      data: { _id: uuid() },
    });
  };

  const addNewAddressStart = () => {
    localDispatch({ type: actions.ADD_NEW_ADDRESS_START });
  };

  const cancelAddAddress = () => {
    localDispatch({ type: actions.CANCEL_ADD_ADDRESS });
  };

  const addNewAddressFinish = () => {
    localDispatch({
      type: actions.ADD_NEW_ADDRESS_FINISH,
      data: { _id: uuid() },
    });
  };

  const addNewPhoneStart = () => {
    localDispatch({ type: actions.ADD_NEW_PHONE_START });
  };

  const cancelAddPhone = () => {
    localDispatch({ type: actions.CANCEL_ADD_PHONE });
  };

  const addNewPhoneFinish = () => {
    localDispatch({
      type: actions.ADD_NEW_PHONE_FINISH,
      data: { _id: uuid() },
    });
  };

  const addNewEmailStart = () => {
    localDispatch({ type: actions.ADD_NEW_EMAIL_START });
  };

  const cancelAddEmail = () => {
    localDispatch({ type: actions.CANCEL_ADD_EMAIL });
  };

  const addNewEmailFinish = () => {
    localDispatch({
      type: actions.ADD_NEW_EMAIL_FINISH,
      data: { _id: uuid() },
    });
  };

  const addNewNoteStart = () => {
    localDispatch({ type: actions.ADD_NEW_NOTE_START });
  };

  const cancelAddNote = () => {
    localDispatch({ type: actions.CANCEL_ADD_NOTE });
  };

  const addNewNoteFinish = () => {
    localDispatch({ type: actions.ADD_NEW_NOTE_FINISH, data: { _id: uuid() } });
  };

  const handleChangeNewNote = (e, value) => {
    localDispatch({
      type: actions.HANDLE_CHANGE_NEW_NOTE,
      data: {
        value,
      },
    });
  };

  const handleChangeNote = _id => (e, value) => {
    localDispatch({
      type: actions.HANDLE_CHANGE_NOTE,
      data: {
        _id,
        value,
      },
    });
  };

  const handleDeleteNote = _id => () => {
    localDispatch({ type: actions.DELETE_NOTE, data: { _id } });
  };

  const showHouseholdPicker = () => {
    localDispatch({ type: actions.SHOW_HOUSEHOLD_PICKER });
  };

  const hideHouseholdPicker = () => {
    localDispatch({ type: actions.HIDE_HOUSEHOLD_PICKER });
  };

  const onHouseholdMemberSelected = (contact) => {
    localDispatch({ type: actions.ON_HOUSEHOLD_MEMBER_SELECTED, data: { contact } });
  };

  const onHouseholdMemberRemoved = (onRemove, contactId) => {
    onRemove();
    localDispatch({
      type: actions.ON_HOUSEHOLD_MEMBER_REMOVED,
      data: {
        contactId,
      },
    });
  };

  const formActions = {
    handleChange,
    children: {
      addNewChildStart,
      cancelAddChild,
      addNewChildFinish,
      handleDeleteChild,
      handleChangeChild,
      handleChangeNewChild,
    },
    notes: {
      addNewNoteStart,
      cancelAddNote,
      addNewNoteFinish,
      handleChangeNote,
      handleDeleteNote,
      handleChangeNewNote,
    },
    householdMembers: {
      showHouseholdPicker,
      hideHouseholdPicker,
      onHouseholdMemberSelected,
      onHouseholdMemberRemoved,
    },
    emails: {
      addNewEmailStart,
      cancelAddEmail,
      addNewEmailFinish,
      handleChangeAdditionalEmail,
      handleDeleteAdditionalEmail,
      handleChangeNewEmail,
    },
    phoneNumbers: {
      addNewPhoneStart,
      cancelAddPhone,
      addNewPhoneFinish,
      handleDeleteAdditionalPhone,
      handleChangeAdditionalPhone,
      handleChangeNewPhone,
    },
    addresses: {
      addNewAddressStart,
      addNewAddressFinish,
      cancelAddAddress,
      handleDeleteAdditionalAddress,
      handleChangeAdditionalAddress,
      handleChangeNewAddress,
    },
  };

  const headerText =
    contact && !contact._id ? 'Add Contact' : 'Contact Details';

  const onClosePanel = () => {
    localDispatch({ type: actions.CLEAR_STATE });
    closePanel();
  };

  const onSaveContact = () => {
    saveContact(state);
    localDispatch({ type: actions.CLEAR_STATE });
  };

  const onUpdateContact = () => {
    updateContact(state);
    localDispatch({ type: actions.CLEAR_STATE });
  };

  const onDeleteContact = _id => {
    deleteContact(_id);
    localDispatch({ type: actions.CLEAR_STATE });
  };

  const onRenderFooterContent = () => (
    <Stack horizontal horizontalAlign="center">
      <DefaultButton
        text="Close"
        onClick={onClosePanel}
        style={{ marginRight: 16 }}
      />
      {state.selectedKey === 'details' && (
        <PrimaryButton
          text={!contact._id ? 'Add' : 'Update'}
          onClick={() => (!contact._id ? onSaveContact() : onUpdateContact())}
          style={{ marginRight: 16 }}
        />
      )}
    </Stack>
  );

  const pivotItems = [
    <PivotItem headerText={headerText} key="details" id="details">
      <ContactForm
        campaign={campaign}
        contactState={state}
        session={session}
        actions={formActions}
      />
    </PivotItem>,
  ];

  if (contact._id) {
    pivotItems.push([
      <PivotItem headerText="Transactions" key="transaction" id="transaction">
        <ContactTransactions
          transactions={contactTransactions}
          session={session}
        />
      </PivotItem>,
      <PivotItem headerText="Delete Contact" key="delete" id="delete">
        <Stack
          horizontalAlign="center"
          verticalAlign="center"
          styles={{ root: { marginTop: 32, marginBottom: 16 } }}
        >
          <Icon
            iconName="TrashAlt"
            styles={{
              root: {
                backgroundColor: '#fff',
                color: '#a80000',
                borderRadius: '50%',
                border: '1px solid #6a6a6a',
                height: 36,
                width: 36,
                marginBottom: 16,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              },
            }}
          />
          <p>Deleting a contact can affect:</p>
          <ul>
            <li>Reports</li>
            <li>Ledgers</li>
            <li>Lists</li>
          </ul>
          <PrimaryButton
            className="delete-btn"
            text="Delete Contact"
            onClick={() => onDeleteContact(contact._id)}
            styles={{
              root: {
                backgroundColor: '#a80000',
              },
              rootHovered: {
                backgroundColor: '#640000',
              },
              rootPressed: {
                backgroundColor: '#640000',
              },
            }}
          />
        </Stack>
      </PivotItem>,
    ]);
  }

  return (
    <Panel
      isOpen={showPanel}
      type={
        state.selectedKey === 'transaction'
          ? PanelType.extraLarge
          : PanelType.medium
      }
      className="ContactDetailsPanel"
      isFooterAtBottom={true}
      onDismiss={onClosePanel}
      onRenderFooterContent={onRenderFooterContent}
    >
      {(contact?._id) &&
      <div style={{ width: '100%' }}>
        <DefaultButton
          text="Add Contribution"
          onClick={() => addContribution(contact._id)}
          style={{ float: 'right' }}
        />
      </div>
      }
      <div style={{ clear: 'both' }}>&nbsp;</div>
      <Pivot selectedKey={state.selectedKey} onLinkClick={onLinkClick}>
        {pivotItems}
      </Pivot>
    </Panel>
  );
};

ContactDetailsPanel.propTypes = {
  showPanel: PropTypes.bool,
  closePanel: PropTypes.func.isRequired,
  contact: PropTypes.object,
  saveContact: PropTypes.func.isRequired,
  updateContact: PropTypes.func.isRequired,
  deleteContact: PropTypes.func.isRequired,
  addContribution: PropTypes.func.isRequired,
};

export default ContactDetailsPanel;
