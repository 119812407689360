import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Label } from 'office-ui-fabric-react/lib/Label';
import { Grid, GridRow, Column } from '../../common';

export default class Section15 extends Component {
  static propTypes = {
    q15: PropTypes.shape({
      inKind: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  };

  static defaultProps = {
    q15: {
      inKind: 0,
      amount: 0,
    },
  };

  render() {
    const { q15 } = this.props;

    return (
      <GridRow>
        <Column sm={1} classNames="ms-textAlignRight">
          <h3 className="form-label">15</h3>
        </Column>
        <Column sm={11}>
          <Grid>
            <GridRow>
              <Column sm={8}>
                <p>{'Net balance on hand'}</p>
                <p>{'(Line 6 - Line 12 + Line 14)'}</p>
              </Column>
              <Column
                sm={2}
                classNames="ms-textAlignCenter money-input with-label"
              >
                <Label className="money-label">In-Kind Estimated Value</Label>
                <p style={{ margin: '0 6px' }}>{q15.inKind}</p>
              </Column>
              <Column
                sm={2}
                classNames="ms-textAlignCenter money-input with-label"
              >
                <Label className="money-label">Cash Amount</Label>
                <p style={{ margin: '0 6px' }}>{q15.amount}</p>
              </Column>
            </GridRow>
          </Grid>
        </Column>
      </GridRow>
    );
  }
}
