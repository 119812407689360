import { createReducer } from '../../reducers/createReducer';

export const initialState = {
  saveButtonDisabled: false,
  fec: false,
  reportIdRedirect: null,
  tbd: false,
  isReportable: false,
  electionYear: 0,
};

export const actions = {
  SET_INITIAL_CAMPAIGN: 'SET_INITIAL_CAMPAIGN',
  SET_QUERY_PARAMS: 'SET_QUERY_PARAMS',
  TOGGLE_SAVE_BUTTON_DISABLED: 'TOGGLE_SAVE_BUTTON_DISABLED',
};

export const actionMap = {
  [actions.SET_INITIAL_CAMPAIGN]: (
    state,
    { data: { electionYear, isReportable = false } },
  ) => ({
    ...state,
    electionYear,
    isReportable,
  }),
  [actions.SET_QUERY_PARAMS]: (
    state,
    { data: { reportIdRedirect, fec, tbd } },
  ) => ({
    ...state,
    reportIdRedirect,
    fec,
    tbd,
  }),
  [actions.TOGGLE_SAVE_BUTTON_DISABLED]: (state, { data: { isDisabled } }) => ({
    ...state,
    saveButtonDisabled: isDisabled,
  }),
};

export const addLoanReducer = createReducer(initialState, actionMap);
