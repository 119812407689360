import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'office-ui-fabric-react/lib/Dropdown';
import { politicalCommitteeTypes } from '../../helpers/constants';
import { CaretDown } from '../icons';

export const CommitteeTypePicker = ({
  selectedKey,
  onChange,
  required,
  className,
  style,
  errorMessage,
  label,
}) => (
  <Dropdown
    onRenderCaretDown={() => <CaretDown />}
    selectedKey={selectedKey}
    className={className}
    onChange={onChange}
    required={required}
    style={style}
    errorMessage={errorMessage}
    label={label}
    options={[
      { key: 0, text: 'Select' },
      ...politicalCommitteeTypes.map(pct => ({
        key: pct,
        text: pct,
      })),
    ]}
  />
);

CommitteeTypePicker.propTypes = {
  selectedKey: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.number,
  ]),
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
  errorMessage: PropTypes.string,
  label: PropTypes.string,
};

CommitteeTypePicker.defaultProps = {
  selectedKey: { key: 0 },
  required: false,
  className: '',
  style: {},
  errorMessage: '',
  label: 'Committee Type',
};

export default CommitteeTypePicker;
