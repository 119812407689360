export const types = {
  GET_UNRECONCILED_ACTIVITY: 'GET_UNRECONCILED_ACTIVITY',
  GET_UNRECONCILED_ACTIVITY_SUCCESS: 'GET_UNRECONCILED_ACTIVITY_SUCCESS',
  CLEAR_UNRECONCILED_ACTIVITY: 'CLEAR_UNRECONCILED_ACTIVITY',
  GET_RECONCILIATIONS: 'GET_RECONCILIATIONS',
  GET_RECONCILIATIONS_PROCESSING: 'GET_RECONCILIATIONS_PROCESSING',
  GET_RECONCILIATIONS_SUCCESS: 'GET_RECONCILIATIONS_SUCCESS',
  GET_SINGLE_RECONCILIATION: 'GET_SINGLE_RECONCILIATION',
  GET_SINGLE_RECONCILIATION_SUCCESS: 'GET_SINGLE_RECONCILIATION_SUCCESS',
  CREATE_RECONCILIATION: 'CREATE_RECONCILIATION',
  UPDATE_RECONCILIATION: 'UPDATE_RECONCILIATION',
  UPDATE_RECONCILIATION_SUCCESS: 'UPDATE_RECONCILIATION_SUCCESS',
  DELETE_RECONCILIATION: 'DELETE_RECONCILIATION',
  DELETE_RECONCILIATION_SUCCESS: 'DELETE_RECONCILIATION_SUCCESS',
  ADD_INTEREST_EARNED: 'ADD_INTEREST_EARNED',
  ADD_INTEREST_EARNED_SUCCESS: 'ADD_INTEREST_EARNED_SUCCESS',
  ADD_FEE: 'ADD_FEE',
  ADD_FEE_SUCCESS: 'ADD_FEE_SUCCESS',
  CLEAR_SINGLE_RECONCILIATION: 'CLEAR_SINGLE_RECONCILIATION',
  CLEAR_RECONCILIATIONS: 'CLEAR_RECONCILIATIONS',
};

export const actions = {
  getUnreconciledActivity() {
    return { type: types.GET_UNRECONCILED_ACTIVITY };
  },

  clearUnreconciledActivity() {
    return { type: types.CLEAR_UNRECONCILED_ACTIVITY };
  },

  createReconciliation(payload) {
    return { type: types.CREATE_RECONCILIATION, data: { payload } };
  },

  getSingleReconciliation(id) {
    return { type: types.GET_SINGLE_RECONCILIATION, data: { id } };
  },

  updateReconciliation(id, payload) {
    return { type: types.UPDATE_RECONCILIATION, data: { id, payload } };
  },

  addInterestEarned(payload) {
    return { type: types.ADD_INTEREST_EARNED, data: { payload } };
  },

  addFee(payload) {
    return { type: types.ADD_FEE, data: { payload } };
  },

  clearSingleReconciliation() {
    return { type: types.CLEAR_SINGLE_RECONCILIATION };
  },
};
