import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { DatePicker } from '../Pickers';
import { GridRow, Column, MoneyField } from '../common';
import { formatCurrency } from '../../helpers/util';

export const CashPayment = ({
  amount,
  amountErrorMessage,
  federalShareErrorMessage,
  nonFederalShareErrorMessage,
  amountRemaining,
  federalShare,
  nonFederalShare,
  nonFederalShareLabel,
  splitAmounts,
  showAmountRemaining,
  dateReceived,
  dateReceivedErrorMessage,
  referenceId,
  sourceCode,
  actions,
  dateLabel,
  description,
  showPurpose,
  descriptionLabel,
  descriptionFieldName,
  mailCode,
  envelopeCode,
  externalDonorId,
}) => (
  <Fragment>
    <GridRow>
      {!splitAmounts &&
      <Column lg={showPurpose ? 6 : 3}>
        <MoneyField
          label="Amount"
          value={amount}
          required
          errorMessage={amountErrorMessage}
          onChange={actions.handlePaymentInfoChange('amount')}
          description={
            showAmountRemaining
              ? `Remaining to match end recipients total: ${formatCurrency(
              amountRemaining,
              )}`
              : ''
          }
        />
      </Column>
      }
      {splitAmounts &&
      <>
        <Column lg={3}>
          <MoneyField
            label="Federal Share"
            value={federalShare}
            required
            errorMessage={federalShareErrorMessage}
            onChange={actions.handlePaymentInfoChange('federalShare')}
          />
        </Column>
        <Column lg={3}>
          <MoneyField
            label={nonFederalShareLabel}
            value={nonFederalShare}
            required
            errorMessage={nonFederalShareErrorMessage}
            onChange={actions.handlePaymentInfoChange('nonFederalShare')}
          />
        </Column>
      </>
      }
      <Column lg={showPurpose ? 6 : 3}>
        <DatePicker
          label={dateLabel}
          value={dateReceived}
          onChange={actions.handleChangeDate}
          required
          errorMessage={dateReceivedErrorMessage}
        />
      </Column>
      {!showPurpose && (
        <Column lg={3}>
          <TextField
            label="Reference ID"
            value={referenceId}
            onChange={actions.handlePaymentInfoChange('referenceId')}
          />
        </Column>
      )}
      {!showPurpose && (
        <Column lg={3}>
          <TextField
            label="Source Code"
            value={sourceCode}
            onChange={actions.handlePaymentInfoChange('sourceCode')}
          />
        </Column>
      )}
    </GridRow>
    {!showPurpose && (
      <GridRow>
        <Column lg={3}>
          <TextField
            label="Mail Code"
            value={mailCode || ''}
            onChange={actions.handlePaymentInfoChange('mailCode')}
          />
        </Column>
        <Column lg={3}>
          <TextField
            label="Envelope Code"
            value={envelopeCode || ''}
            onChange={actions.handlePaymentInfoChange('envelopeCode')}
          />
        </Column>
        <Column lg={3}>
          <TextField
            label="Donor ID"
            value={externalDonorId || ''}
            onChange={actions.handlePaymentInfoChange('externalDonorId')}
          />
        </Column>
      </GridRow>
    )}
    {showPurpose && (
      <GridRow>
        <Column lg={6}>
          <TextField
            label={descriptionLabel}
            value={description || ''}
            onChange={actions.handlePaymentInfoChange(descriptionFieldName)}
          />
        </Column>
        <Column lg={6}>
          <TextField
            label="Reference ID"
            value={referenceId || ''}
            onChange={actions.handlePaymentInfoChange('referenceId')}
          />
        </Column>
      </GridRow>
    )}
  </Fragment>
);

CashPayment.propTypes = {
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  amountRemaining: PropTypes.number,
  showAmountRemaining: PropTypes.bool,
  amountErrorMessage: PropTypes.string,
  federalShareErrorMessage: PropTypes.string,
  nonFederalShareErrorMessage: PropTypes.string,
  splitAmounts: PropTypes.bool,
  federalShare: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  nonFederalShare: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  nonFederalShareLabel: PropTypes.string,
  dateReceived: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
  ]),
  dateReceivedErrorMessage: PropTypes.string,
  referenceId: PropTypes.string,
  sourceCode: PropTypes.string,
  actions: PropTypes.object.isRequired,
  dateLabel: PropTypes.string,
  description: PropTypes.string,
  showPurpose: PropTypes.bool,
  descriptionLabel: PropTypes.string,
  descriptionFieldName: PropTypes.string,
  mailCode: PropTypes.string,
  envelopeCode: PropTypes.string,
  externalDonorId: PropTypes.string,
};

CashPayment.defaultProps = {
  amount: '',
  amountErrorMessage: '',
  amountRemaining: 0,
  splitAmounts: false,
  federalShare: '',
  nonFederalShare: '',
  federalShareErrorMessage: '',
  nonFederalShareErrorMessage: '',
  nonFederalShareLabel: 'Non-Federal Share',
  showAmountRemaining: false,
  dateReceived: new Date(),
  dateReceivedErrorMessage: '',
  referenceId: '',
  dateLabel: 'Date Received',
  description: '',
  showPurpose: false,
  descriptionLabel: 'Description',
  descriptionFieldName: 'description',
  mailCode: '',
  envelopeCode: '',
  externalDonorId: '',
};

export default CashPayment;
