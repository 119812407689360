import { takeEvery, put, call } from 'redux-saga/effects';
import axios from 'axios';
import { types } from '../actions/aggregateActions';
import { getServerSideErrorMessage, errorToast } from '../helpers/util';

export function* handleGetAggregatesContributions({
  data: { contactId, excludedContributionId },
}) {
  try {
    const excludedContributionIdQuery = excludedContributionId
      ? `?excludedContributionId=${excludedContributionId}`
      : '';
    const { data: contributions } = yield call(
      axios.get,
      `/api/filer/contacts/${contactId}/contributions/aggregates${excludedContributionIdQuery}`,
      { withCredentials: true },
    );
    yield put({
      type: types.GET_CONTRIBUTION_AGGREGATES_SUCCESS,
      data: {
        contributions,
      },
    });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put(errorToast(error));
  }
}

export function* getAggregatesContributions() {
  yield takeEvery(
    types.GET_CONTRIBUTION_AGGREGATES,
    handleGetAggregatesContributions,
  );
}
