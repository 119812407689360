import React from 'react';
import PropTypes from 'prop-types';
import { ComboBox } from 'office-ui-fabric-react/lib/ComboBox';

const GenericPicker = ({
  fieldLabel,
  options,
  selectedKey,
  className,
  style,
  required,
  onChange,
  errorMessage,
  placeholder,
  disabled,
}) => {
  return (
    <ComboBox
      buttonIconProps={{
        iconName: 'CaretDown',
      }}
      className={className}
      style={style}
      label={fieldLabel}
      placeholder={placeholder}
      allowFreeForm={false}
      autoComplete={'on'}
      disabled={disabled}
      options={[
        { key: 0, text: 'Select' },
        ...options,
      ]}
      required={required}
      onChange={onChange}
      selectedKey={selectedKey}
      errorMessage={errorMessage}
    />
  );
};

GenericPicker.propTypes = {
  options: PropTypes.array,
  fieldLabel: PropTypes.string,
  selectedKey: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.object,
  ]),
  disabled: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  errorMessage: PropTypes.string,
  placeholder: PropTypes.string,
};

GenericPicker.defaultProps = {
  fieldLabel: '',
  selectedKey: 0,
  className: '',
  style: {},
  required: false,
  errorMessage: '',
  placeholder: '',
  disabled: false,
};

export default GenericPicker;
