import { createReducer } from '../../reducers/createReducer';

export const initialState = {
  calculatedTotals: {},
  selectedRow: null,
  hideAddUpdateDialog: true,
  hideDeleteConfirmDialog: true,
  showPreviewPanel: false,
  formAction: '',
};

export const actions = {
  REMOVE_SELECTED_ROW: 'REMOVE_SELECTED_ROW',
  SET_SELECTED_ROW: 'SET_SELECTED_ROW',
  SHOW_ADD_UPDATE_DIALOG: 'SHOW_ADD_UPDATE_DIALOG',
  HIDE_ADD_UPDATE_DIALOG: 'HIDE_ADD_UPDATE_DIALOG',
  HIDE_DELETE_CONFIRM_DIALOG: 'HIDE_DELETE_CONFIRM_DIALOG',
  SHOW_DELETE_CONFIRM_DIALOG: 'SHOW_DELETE_CONFIRM_DIALOG',
  HIDE_PREVIEW_PANEL: 'HIDE_PREVIEW_PANEL',
};

export const actionMap = {
  [actions.SET_SELECTED_ROW]: (
    state,
    { data: { selectedRow } },
  ) => ({
    ...state,
    selectedRow,
    showPreviewPanel: true,
  }),
  [actions.REMOVE_SELECTED_ROW]: (
    state,
  ) => ({
    ...state,
    selectedRow: null,
  }),
  [actions.SHOW_ADD_UPDATE_DIALOG]: (
    state,
    { data: { formAction } },
  ) => ({
    ...state,
    hideAddUpdateDialog: false,
    showPreviewPanel: false,
    formAction,
  }),
  [actions.HIDE_ADD_UPDATE_DIALOG]: (
    state,
  ) => ({
    ...state,
    hideAddUpdateDialog: true,
    formAction: '',
    selectedRow: null,
  }),
  [actions.SHOW_DELETE_CONFIRM_DIALOG]: (
    state,
  ) => ({
    ...state,
    hideDeleteConfirmDialog: false,
  }),
  [actions.HIDE_DELETE_CONFIRM_DIALOG]: (
    state,
  ) => ({
    ...state,
    hideDeleteConfirmDialog: true,
  }),
  [actions.HIDE_PREVIEW_PANEL]: (
    state,
  ) => ({
    ...state,
    showPreviewPanel: false,
  }),
};

export const apiIntegrationReducer = createReducer(initialState, actionMap);
