import { takeLatest, put, call } from 'redux-saga/effects';
import axios from 'axios';
import { types as ledgerTypes } from '../actions/ledgerActions';
import { getServerSideErrorMessage, errorToast } from '../helpers/util';

const url = '/api/filer/accounting/ledger';

export function* handleGetLedger() {
  try {
    const { data } = yield call(axios.get, url, { withCredentials: true });
    const ledger = {
      ...data,
      entries: data.entries.filter(Boolean),
    };
    yield put({ type: ledgerTypes.GET_LEDGER_SUCCESS, ledger });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put(errorToast(error));
  }
}

export function* getLedger() {
  yield takeLatest(ledgerTypes.GET_LEDGER, handleGetLedger);
}

export function* handleReconciliation(action) {
  try {
    const { id, type, reconciled } = action.data;
    const payload = {
      transactionId: id,
      transactionType: type,
      reconciled,
    };
    const { data: item } = yield call(axios.put, `${url}/${id}`, payload, {
      withCredentials: true,
    });
    yield put({ type: ledgerTypes.RECONCILE_ITEM_SUCCESS, data: { item } });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put(errorToast(error));
  }
}

export function* reconcileItem() {
  yield takeLatest(ledgerTypes.RECONCILE_ITEM, handleReconciliation);
}
