import { createReducer } from '../../../reducers/createReducer';
import { calcFilters } from '../../../helpers/listsHelper';

export const defaultColumns = [
  'transactionType',
  'transactionDate',
  'amount',
  'displayName',
];

export const initialState = {
  startDate: '',
  endDate: '',
  timePeriod: 0,
  filters: [],
  columns: [...defaultColumns],
  errors: {
    startDate: '',
    endDate: '',
  },
};

export const actions = {
  HANDLE_CHANGE: 'HANDLE_CHANGE',
  SET_FORM_ERRORS: 'SET_FORM_ERRORS',
};

const calcFiltersWithDefaultFilters = calcFilters([]);

export const actionMap = {
  [actions.HANDLE_CHANGE]: (state, { data: { fieldName, value } }) => {
    if (fieldName === 'timePeriod' && value !== 'custom') {
      return {
        ...state,
        [fieldName]: value,
        startDate: '',
        endDate: '',
        filters: calcFiltersWithDefaultFilters(fieldName, value, state),
      };
    }

    return {
      ...state,
      [fieldName]: value,
      filters: calcFiltersWithDefaultFilters(fieldName, value, state),
    };
  },
  [actions.SET_FORM_ERRORS]: (state, { data: { errors } }) => ({
    ...state,
    errors,
  }),
};

export const unclearedTransactionsReducer = createReducer(
  initialState,
  actionMap,
);
