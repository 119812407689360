import { officeTypes } from '../helpers/constants';

export default class Session {
  constructor(dto) {
    if (dto) {
      this.role = dto.role || 'unknown';
      this.campaignId = dto.campaignId;
      this.username = dto.username;
      this.officeType = dto.officeType;
      this.isBetaUser = dto.isBetaUser;
      this.isSysAdmin = dto.isSysAdmin;
    }
  }

  update(data) {
    this.officeType = data.officeType;
    this.officeState = data.officeState;
    this.role = data.role || 'unknown';
    return this;
  }

  isAdminRole() {
    return this.role === 'Admin';
  }

  isSysAdminRole() {
    return this.role === 'SysAdmin';
  }

  isAdminType() {
    return this.isSysAdminRole() || this.isAdminRole();
  }

  isFederal() {
    return this.officeType === officeTypes.FEDERAL;
  }
}
