import { createReducer } from '../../reducers/createReducer';
import { statuses } from '../../helpers/constants';
import { deserializeRecipient } from '../../helpers/endRecipientHelper';
import { expenditureTypeMap } from '../../helpers/expenditureHelpers';
import { filterOutSystemTags, deserializeDate } from '../../helpers/util';
import {
  expenditureBaseInitialState,
  errors,
} from './expenditureBaseInitialState';

export const initialState = {
  ...expenditureBaseInitialState,
  bulkRecipientDialogHidden: true,
  errors: { ...errors },
};

export const actions = {
  SET_INITIAL_CAMPAIGN: 'SET_INITIAL_CAMPAIGN',
  SET_QUERY_PARAMS: 'SET_QUERY_PARAMS',
  CLEAR_RECIPIENT_INFO: 'CLEAR_RECIPIENT_INFO',
  ON_CONTACT_SELECTED: 'ON_CONTACT_SELECTED',
  ON_CONDUIT_SELECTED: 'ON_CONDUIT_SELECTED',
  ON_SUPPORT_OPPOSE_SELECTED: 'ON_SUPPORT_OPPOSE_SELECTED',
  HANDLE_REFUND_CONTRIBUTION_CHANGE: 'HANDLE_REFUND_CONTRIBUTION_CHANGE',
  HANDLE_LOAN_CHANGE: 'HANDLE_LOAN_CHANGE',
  HANDLE_CHANGE: 'HANDLE_CHANGE',
  HANDLE_COUPLED_CHANGE: 'HANDLE_COUPLED_CHANGE',
  HANDLE_FEDERAL_ELECTION_CHANGE: 'HANDLE_FEDERAL_ELECTION_CHANGE',
  HANDLE_CONTRIBUTION_FEDERAL_ELECTION_CHANGE: 'HANDLE_CONTRIBUTION_FEDERAL_ELECTION_CHANGE',
  HANDLE_CHANGE_DATE_PAID: 'HANDLE_CHANGE_DATE_PAID',
  CLOSE_END_RECIPIENT_DIALOG: 'CLOSE_END_RECIPIENT_DIALOG',
  CLOSE_BULK_END_RECIPIENT_DIALOG: 'CLOSE_BULK_END_RECIPIENT_DIALOG',
  SELECT_RECIPIENT: 'SELECT_RECIPIENT',
  RECIPIENT_ADDED: 'RECIPIENT_ADDED',
  EDIT_RECIPIENT: 'EDIT_RECIPIENT',
  DELETE_RECIPIENT: 'DELETE_RECIPIENT',
  ON_RECIPIENT_SAVE: 'ON_RECIPIENT_SAVE',
  ON_BULK_RECIPIENT_SAVE: 'ON_BULK_RECIPIENT_SAVE',
  FORM_ERRORS: 'FORM_ERRORS',
  FORCE_BUSINESS_RECIPIENT_TYPE: 'FORCE_BUSINESS_RECIPIENT_TYPE',
  CLEAR_FIELDS_FOR_NEW: 'CLEAR_FIELDS_FOR_NEW',
  RESET_SELECTED_CONTACT_INFO: 'RESET_SELECTED_CONTACT_INFO',
  RESET_SELECTED_CONDUIT_INFO: 'RESET_SELECTED_CONDUIT_INFO',
  RESET_SELECTED_SUPPORT_OPPOSE_INFO: 'RESET_SELECTED_SUPPORT_OPPOSE_INFO',
  RESET_REFUND_CONTRIBUTION_INFO: 'RESET_REFUND_CONTRIBUTION_INFO',
  RESET_LOAN_INFO: 'RESET_LOAN_INFO',
  SHOW_CONTINUE_UPDATE: 'SHOW_CONTINUE_UPDATE',
  HIDE_CONTINUE_UPDATE: 'HIDE_CONTINUE_UPDATE',
};

const actionMap = {
  [actions.SET_INITIAL_CAMPAIGN]: (
    state,
    {
      data: {
        electionYear,
        isReportable,
        isRecipientSave,
        recipientSaveExpenditureId,
      },
    },
  ) => ({
    ...state,
    electionYear,
    isReportable,
    isRecipientSave,
    recipientSaveExpenditureId,
  }),
  [actions.SET_QUERY_PARAMS]: (state, action) => ({
    ...state,
    expenditureType: action.data.expenditureType || 0,
    reportIdRedirect: action.data.reportIdRedirect,
    election: action.data.election || 0,
    fec: action.data.fec || false,
    frCampaignType: action.data.frCampaignType,
  }),
  [actions.FORCE_BUSINESS_RECIPIENT_TYPE]: state => ({
    ...state,
    contactType: 'Business',
  }),
  [actions.CLEAR_RECIPIENT_INFO]: state => {
    return {
      ...state,
      selectedContact: null,
      contactType: ['Credit Card'].includes(state.expenditureType)
        ? 'Business'
        : 0,
      salutation: 'none',
      firstName: '',
      middleName: '',
      lastName: '',
      suffix: '',
      employer: '',
      occupation: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: 0,
      zipCode: '',
      county: '',
      phone1: '',
      phone2: '',
      email: '',
      businessName: '',
      businessType: 0,
      contactName: '',
      cardholderName: '',
      cardholderEmployer: '',
      cardholderOccupation: '',
      committeeAffiliation: 0,
    };
  },
  [actions.RESET_SELECTED_CONTACT_INFO]: state => {
    if (state.selectedContact) {
      return {
        ...state,
        salutation: state.selectedContact.salutation || 'none',
        firstName: state.selectedContact.firstName || '',
        middleName: state.selectedContact.middleName || '',
        lastName: state.selectedContact.lastName || '',
        suffix: state.selectedContact.suffix || '',
        employer: state.selectedContact.employer || '',
        occupation: state.selectedContact.occupation || '',
        addressLine1: state.selectedContact.address?.addressLine1 || '',
        addressLine2: state.selectedContact.address?.addressLine2 || '',
        city: state.selectedContact.address?.city || '',
        state: state.selectedContact.address?.state || 0,
        zipCode: state.selectedContact.address?.zipCode || '',
        county: state.selectedContact.address?.county || '',
        phone1: state.selectedContact.phone1 || '',
        phone2: state.selectedContact.phone2 || '',
        email: state.selectedContact.email || '',
        businessName: state.selectedContact.businessName || '',
        businessType: state.selectedContact.businessType || 0,
        contactName: state.selectedContact.contactName || '',
        committeeAffiliation: state.selectedContact.committeeAffiliation || 0,
        cardholderName: state.selectedContact.cardholderName || '',
        cardholderOccupation: state.selectedContact.cardholderOccupation || '',
        cardholderEmployer: state.selectedContact.cardholderEmployer || '',
      };
    }

    return {
      ...state,
      salutation: 'none',
      firstName: '',
      middleName: '',
      lastName: '',
      suffix: '',
      employer: '',
      occupation: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: 0,
      zipCode: '',
      county: '',
      phone1: '',
      phone2: '',
      email: '',
      businessName: '',
      businessType: 0,
      contactName: '',
      committeeAffiliation: 0,
      cardholderName: '',
      cardholderOccupation: '',
      cardholderEmployer: '',
    };
  },
  [actions.RESET_SELECTED_CONDUIT_INFO]: state => {
    return {
      ...state,
      conduit: null,
    };
  },
  [actions.RESET_SELECTED_SUPPORT_OPPOSE_INFO]: state => {
    return {
      ...state,
      supportOppose: null,
      contributionOfficeSought: 0,
      contributionOfficeState: 0,
      contributionDistrict: '',
    };
  },
  [actions.RESET_REFUND_CONTRIBUTION_INFO]: state => {
    return {
      ...state,
      refundContributionId: '0',
    };
  },
  [actions.RESET_LOAN_INFO]: state => {
    return {
      ...state,
      refundContributionId: '0',
    };
  },
  [actions.ON_CONTACT_SELECTED]: (state, action) => {
    const { contact } = action.data;
    const {
      address: contactAddress,
      _id,
      salutation,
      businessType,
      committeeAffiliation,
      tags,
      ...rest
    } = contact;

    const returnPayload = {
      ...state,
      selectedContact: {
        ...contact,
        tags: filterOutSystemTags(tags),
        salutation: salutation || 'none',
        businessType: businessType || 0,
        committeeAffiliation: committeeAffiliation || 0,
        phone1: contact.phone1 || '',
        phone2: contact.phone2 || '',
        address: {
          ...contact.address,
          state: (contact.address || {}).state || 0,
        },
      },
      contactType: contact.contactType,
      ...contactAddress,
      tags: filterOutSystemTags(tags),
      ...rest,
      salutation: salutation || 'none',
      businessType: businessType || 0,
      committeeAffiliation: committeeAffiliation || 0,
      phone1: contact.phone1 || '',
      phone2: contact.phone2 || '',
      purpose: state.purpose || contact.purpose || '',
      refundContributionId: '0',
      transactionIdNumber: '', // Don't write contact id number into state of contribution
    };

    return returnPayload;
  },
  [actions.ON_CONDUIT_SELECTED]: (state, action) => {
    const { selectedConduitContact } = action.data;
    return {
      ...state,
      conduit: { ...selectedConduitContact },
    };
  },
  [actions.ON_SUPPORT_OPPOSE_SELECTED]: (state, action) => {
    const { selectedSupportOpposeContact } = action.data;
    return {
      ...state,
      supportOppose: { ...selectedSupportOpposeContact },
      contributionOfficeSought: selectedSupportOpposeContact.candidateOffice,
      contributionOfficeState: selectedSupportOpposeContact.candidateState,
      contributionDistrict: selectedSupportOpposeContact.candidateDistrict,
    };
  },
  [actions.HANDLE_REFUND_CONTRIBUTION_CHANGE]: (state, action) => {
    return {
      ...state,
      refundContributionId: action.data.refundContributionId,
    };
  },
  [actions.HANDLE_LOAN_CHANGE]: (state, action) => {
    return {
      ...state,
      loanId: action.data.loanId,
    };
  },
  [actions.HANDLE_COUPLED_CHANGE]: (state, { data: { fieldName1, fieldName2, value } }) => {
    return {
      ...state,
      [fieldName1]: value,
      [fieldName2]: value,
    };
  },
  [actions.HANDLE_CHANGE]: (state, { data: { fieldName, value } }) => {
    if (fieldName === 'contactType') {
      const currentRecipientType = state.contactType;
      return {
        ...state,
        [fieldName]: value,
        errors: {
          ...(currentRecipientType === value
            ? state.errors
            : {
                ...state.errors,
                firstNameErrorMessage: '',
                lastNameErrorMessage: '',
                businessNameError: '',
                creditCardCompanyError: '',
              }),
        },
      };
    }
    if (fieldName === 'paymentType') {
      return {
        ...state,
        [fieldName]: value,
        cardType: 0,
        cardLast4: '',
        checkNumber: '',
      };
    }
    if (fieldName === 'election' || fieldName === 'electionYear') {
      return {
        ...state,
        refundContributionId: '0',
        [fieldName]: value,
      };
    }
    // We need a better way to work through these, the more we go the more
    // other fields need to change if others are selected, a switch statement if here?
    if (fieldName === 'disbursementCategory') {
      return {
        ...state,
        refundContributionId: '0',
        contributionOfficeSought: 0,
        contributionOfficeState: 0,
        contributionDistrict: '',
        contributionElectionYear: 0,
        contributionElection: 0,
        contributionOtherElectionType: '',
        [fieldName]: value,
      };
    }
    if (fieldName === 'expenditureType') {
      return {
        ...state,
        // if true but other selection is chosen, this is hidden and should be false
        // if in view, user can set
        isAllocated: false,
        [fieldName]: value,
      };
    }
    return {
      ...state,
      [fieldName]: value,
    };
  },
  [actions.HANDLE_CHANGE_DATE_PAID]: (state, action) => {
    const { datePaid } = action.data;
    return {
      ...state,
      datePaid,
      errors: {
        ...state.errors,
        datePaidErrorMessage:
          datePaid &&
          datePaid.getFullYear &&
          datePaid.getFullYear().toString().length !== 4
            ? 'Invalid date'
            : '',
      },
    };
  },
  [actions.HANDLE_FEDERAL_ELECTION_CHANGE]: (state, action) => ({
    ...state,
    election: action.data.election,
    otherElectionType: action.data.otherElectionType,
    refundContributionId: '0',
  }),
  [actions.HANDLE_CONTRIBUTION_FEDERAL_ELECTION_CHANGE]: (state, action) => ({
    ...state,
    contributionElection: action.data.contributionElection || 0,
    contributionOtherElectionType: action.data.contributionOtherElectionType,
    election: action.data.contributionElection || 0,
    otherElectionType: action.data.contributionOtherElectionType,
  }),
  [actions.CLOSE_END_RECIPIENT_DIALOG]: state => ({
    ...state,
    addRecipientDialogHidden: true,
    selectedRecipient: null,
  }),
  [actions.CLOSE_BULK_END_RECIPIENT_DIALOG]: state => ({
    ...state,
    bulkRecipientDialogHidden: true,
    selectedRecipient: null,
  }),
  [actions.SELECT_RECIPIENT]: (state, action) => {
    return {
      ...state,
      selectedRecipient: state.endRecipients.find(
        er => er._id === action.data.recipientId,
      ),
      addRecipientDialogHidden: false,
    };
  },
  [actions.RECIPIENT_ADDED]: (state, action) => {
    const { expenditure } = action.data;
    let { endRecipients } = expenditure;
    endRecipients = (endRecipients || []).map(deserializeRecipient).map(e => ({ ...e, isDirty: false }));
    return {
      ...state,
      ...expenditure,
      electionYear: parseInt(expenditure.electionYear, 10),
      election: expenditure.electionCycle,
      expenditureType: expenditureTypeMap[expenditure.expenditureType],
      paymentType: expenditure.paymentType,
      contactType: expenditure.contactType,
      datePaid: deserializeDate(expenditure.expenditureDate),
      status: statuses.SUCCESS,
      referenceId: expenditure.paymentReferenceId || '',
      tags: filterOutSystemTags(expenditure.tags),
      forceItemization: expenditure.forceItemize || false,
      cardLast4: expenditure.creditCardLast4 || '',
      cardType: expenditure.creditCardType || 0,
      purpose: expenditure.purpose || '',
      endRecipients,
    };
  },
  [actions.EDIT_RECIPIENT]: (state, action) => {
    const { updatedRecipient } = action.data;
    const isIndividualType = ['Individual', 'IND', 'CAN'].includes(
      updatedRecipient.contactType,
    );
    return {
      ...state,
      endRecipients: state.endRecipients.map(er => {
        if (er._id === updatedRecipient._id) {
          er = {
            ...er,
            ...updatedRecipient,
            isDirty: true,
            firstName: isIndividualType
              ? updatedRecipient.firstName
              : undefined,
            middleName: isIndividualType
              ? updatedRecipient.middleName
              : undefined,
            lastName: isIndividualType ? updatedRecipient.lastName : undefined,
            suffix: isIndividualType ? updatedRecipient.suffix : undefined,
            salutation: isIndividualType ? updatedRecipient.salutation : 'none',
            occupation: isIndividualType
              ? updatedRecipient.occupation
              : undefined,
            employer: isIndividualType ? updatedRecipient.employer : undefined,
            displayName: isIndividualType
              ? `${updatedRecipient.lastName}, ${updatedRecipient.firstName}`
              : updatedRecipient.businessName,
            businessName: isIndividualType
              ? undefined
              : updatedRecipient.businessName,
            contactName: isIndividualType
              ? undefined
              : updatedRecipient.contactName,
            businessType: isIndividualType ? 0 : updatedRecipient.businessType,
          };
        }
        return er;
      }),
      selectedRecipient: null,
    };
  },
  [actions.DELETE_RECIPIENT]: (state, action) => {
    return {
      ...state,
      endRecipients: state.endRecipients.map(er => {
        if (er._id === action.data.recipientId) {
          er.isRemoved = true;
        }
        return er;
      }),
    };
  },
  [actions.FORM_ERRORS]: (state, action) => {
    return {
      ...state,
      errors: action.data.errors,
    };
  },
  [actions.ON_RECIPIENT_SAVE]: (state, action) => {
    return {
      ...state,
      addRecipientDialogHidden: false,
      isRecipientSave: true,
      recipientSaveExpenditureId: action.data._id,
    };
  },
  [actions.ON_BULK_RECIPIENT_SAVE]: (state, action) => {
    return {
      ...state,
      bulkRecipientDialogHidden: false,
      isRecipientSave: true,
      recipientSaveExpenditureId: action.data._id,
    };
  },
  [actions.CLEAR_FIELDS_FOR_NEW]: state => {
    const {
      electionYear,
      election,
      otherElectionType,
      expenditureType,
      fec,
      reportIdRedirect,
      frCampaignType,
    } = state;
    return {
      ...initialState,
      electionYear,
      election,
      otherElectionType,
      expenditureType,
      fec,
      reportIdRedirect,
      frCampaignType,
    };
  },
  [actions.SHOW_CONTINUE_UPDATE]: (
    state,
    { data: { addNew, continueEditMessageList } },
  ) => ({
    ...state,
    confirmContinueUpdateHidden: false,
    addNew,
    continueEditMessageList,
  }),
  [actions.HIDE_CONTINUE_UPDATE]: (
    state,
  ) => ({
    ...state,
    confirmContinueUpdateHidden: true,
    addNew: false,
  }),
};

export const addExpenditureReducer = createReducer(initialState, actionMap);
