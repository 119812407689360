import React from 'react';
import PropTypes from 'prop-types';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import { statuses } from '../../helpers/constants';

const ScrollObserver = ({ scrollRef, getNextPageStatus }) => (
  <div className="scrollObserver" ref={scrollRef} style={{ paddingTop: 24 }}>
    {getNextPageStatus === statuses.PROCESSING && (
      <Spinner size={SpinnerSize.small} label="Loading more" />
    )}
  </div>
);

ScrollObserver.propTypes = {
  scrollRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  getNextPageStatus: PropTypes.string,
};

export default ScrollObserver;
