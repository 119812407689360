import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import { types as expenditureTypes } from '../../actions/expenditureActions';
import { formatCurrency, formatDate } from '../../helpers/util';
import {
  getExpendituresByContact,
  getExpendituresByContactStatus,
} from '../../selectors';
import { statuses } from '../../helpers/constants';

export const ExpenditureDetailRows = ({
  rowId,
  params,
  isOpen,
}) => {
  const reduxDispatch = useDispatch();
  const expendituresByContact = useSelector(getExpendituresByContact);
  const contributionsByContactStatus = useSelector(getExpendituresByContactStatus);
  const status = contributionsByContactStatus[rowId];

  useEffect(() => {
    if (isOpen) {
      reduxDispatch({
        type: expenditureTypes.GET_EXPENDITURES_BY_CONTACT,
        data: {
          rowId,
          params,
        },
      });
    }
  }, [isOpen]);

  if (status !== statuses.SUCCESS) {
    return (
      <div className="item-detail-header">
        <Spinner size={SpinnerSize.small} labelPosition="right" label="Loading expenditures" />
      </div>
    );
  }

  if (status === statuses.SUCCESS && expendituresByContact[rowId].length === 0) {
    return (
      <div className="item-detail-header">
        <div style={{ marginLeft: 8 }}>No expenditures</div>
      </div>
    );
  }

  return (
    <div>
      <div className="item-detail-header">
        <div className="medium">Expenditures</div>
        <div className="medium">Date</div>
        <div className="money">Amount</div>
        <div className="medium">FormType</div>
        <div className="medium">Filed</div>
        <div className="large">Payment Reference Id</div>
        <div className="medium">Type</div>
        <div className="medium">Category</div>
        <div className="medium">Created</div>
        <div className="medium">Updated</div>
      </div>
      {(expendituresByContact[rowId] || [])
        .filter(r => r.disbursementCategory === '010')
        .map(r => {
        return (<div className="item-detail-row" key={r._id}>
          {/* <div className="large">{r.displayName}</div> */}
          <div className="medium">&nbsp;</div>
          <div className="medium">{formatDate(r.expenditureDate, 'MM/DD/YYYY')}</div>
          <div className="medium">{formatCurrency(r.amount)}</div>
          <div className="medium">{r.fecFormType}</div>
          <div className="medium">{r.isItemFiled ? 'Filed' : 'Not Filed'}</div>
          <div className="large">{r.paymentReferenceId}</div>
          <div className="medium">{r.expenditureType}</div>
          <div className="medium">{r.disbursementCategory}</div>
          <div className="medium">{formatDate(r.createdAt, 'MM/DD/YYYY')}</div>
          <div className="medium">{formatDate(r.updatedAt, 'MM/DD/YYYY')}</div>
        </div>);
      })}
    </div>
  );
};

ExpenditureDetailRows.propTypes = {
  isOpen: PropTypes.bool,
  rowId: PropTypes.string,
  params: PropTypes.string,
};
