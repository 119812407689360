import { types } from '../actions/dataImportActions';
import { createReducer } from './createReducer';
import { statuses } from '../helpers/constants';

export const initialState = {
  list: [],
  detail: {},
  getDataImportListStatus: statuses.NOT_STARTED,
  getDataImportListError: null,
  getDataImportDetailStatus: statuses.NOT_STARTED,
  getDataImportDetailError: null,
  saveDataImportDetailtatus: statuses.NOT_STARTED,
  deleteDataImportStatus: statuses.NOT_STARTED,
  deleteDataImportError: null,
};

const actionMap = {
  [types.GET_DATA_IMPORT_LIST_PROCESSING]: state => ({
    ...state,
    getDataImportListStatus: statuses.PROCESSING,
    getDataImportListError: null,
  }),

  [types.GET_DATA_IMPORT_LIST_SUCCESS]: (state, action) => ({
    ...state,
    getDataImportListStatus: statuses.SUCCESS,
    getDataImportListError: null,
    list: action.data.dataImportList,
  }),

  [types.GET_DATA_IMPORT_LIST_FAILURE]: (state, action) => ({
    ...state,
    getDataImportListStatus: statuses.ERROR,
    getDataImportListError: action.error,
  }),

  [types.GET_DATA_IMPORT_DETAIL_PROCESSING]: state => ({
    ...state,
    getDataImportDetailStatus: statuses.PROCESSING,
    getDataImportDetailError: null,
  }),

  [types.GET_DATA_IMPORT_DETAIL_SUCCESS]: (state, action) => ({
    ...state,
    getDataImportDetailStatus: statuses.SUCCESS,
    getDataImportDetailError: null,
    detail: action.data.dataImport,
  }),

  [types.GET_DATA_IMPORT_DETAIL_FAILURE]: (state, action) => ({
    ...state,
    getDataImportDetailStatus: statuses.ERROR,
    getDataImportDetailError: action.error,
  }),

  [types.DELETE_DATA_IMPORT_PROCESSING]: state => ({
    ...state,
    deleteDataImportStatus: statuses.PROCESSING,
    deleteDataImportError: null,
  }),

  [types.DELETE_DATA_IMPORT_SUCCESS]: (state, action) => ({
    ...state,
    list: state.list.filter(d => d._id !== action.data.dataImportId),
    deleteDataImportStatus: statuses.SUCCESS,
    deleteDataImportError: null,
  }),

  [types.DELETE_DATA_IMPORT_FAILURE]: (state, action) => ({
    ...state,
    deleteDataImportStatus: statuses.ERROR,
    deleteDataImportError: action.error,
  }),
};

export const dataImportReducer = createReducer(initialState, actionMap);
