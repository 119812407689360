import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import { ActionButton } from 'office-ui-fabric-react/lib/Button';
import { Stack, StackItem } from 'office-ui-fabric-react/lib/Stack';
import { types as currentCampaignAction } from '../../actions/currentCampaignActions';
import { formatCurrency, formatDateTime } from '../../helpers/util';
import { getLabel } from '../../helpers/labelHelper';
import { CampaignUserRoles, statuses } from '../../helpers/constants';
import {
  getCampaignFinances,
  getCampaignFinancesStatus,
} from '../../selectors';

const DashboardCampaignFinances = ({ history, currentCampaign, user }) => {
  if (!currentCampaign.campaign) {
    return null;
  }
  const reduxDispatch = useDispatch();
  const stats = useSelector(getCampaignFinances);
  const statsStatus = useSelector(getCampaignFinancesStatus);
  const headerWidthStyle = { root: { width: '20%', alignItems: 'flex-end' } };
  const headerAmountStyle = { root: { fontSize: '.9rem', fontWeight: 'bold' } };
  const amountWidthStyle = { root: { width: '20%', alignItems: 'flex-end' } };
  const amountStyle = { root: { fontSize: '.9rem' } };
  const getDashboardLabel = (val) => {
    return (
      <Stack key={val} styles={headerWidthStyle}>
        <StackItem styles={headerAmountStyle}><div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{val}</div></StackItem>
      </Stack>
    );
  };

  const getDetailLabel = (val) => {
    return (
      <Stack styles={amountWidthStyle}>
        <StackItem styles={headerAmountStyle}><div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{val}</div></StackItem>
      </Stack>
    );
  };

  const getDashboardValue = (val) => {
    return (
      <Stack styles={amountWidthStyle}>
        <StackItem styles={amountStyle}>{val}</StackItem>
      </Stack>
    );
  };

  const getEntries = (entries) => {
    return entries.map(e => {
      return getDashboardLabel(e);
    });
  };

  const formatCurrencyWithDashForZero = (val) => {
    return val === 0 ? '-' : formatCurrency(val);
  };

  const getDetailMoneyRows = (detailEntries) => {
    return detailEntries.map(e => {
      return (
        <Stack key={e.electionCycle} horizontal horizontalAlign="space-between">
          {getDetailLabel(`${e.electionCycle} ${e.electionYear || ''}`)}
          {getDashboardValue(formatCurrencyWithDashForZero(e.cashOnHand))}
          {getDashboardValue(formatCurrencyWithDashForZero(e.contributions))}
          {getDashboardValue(formatCurrencyWithDashForZero(e.expenditures))}
        </Stack>
      );
    });
  };

  const refreshStats = () => {
    reduxDispatch({
      type: currentCampaignAction.REFRESH_CURRENT_CAMPAIGN_FINANCE,
    });
  };

  const getDashboard = () => {
    return (
      <div className="finance-content depth-1">
        <div className="finances" style={{ minHeight: '275px' }}>
          <div className="refresh-info">
            <span>
              Last Update: {formatDateTime(stats.updatedAt)}
            </span>
            <ActionButton
              className="refresh-button"
              onClick={refreshStats}
              text="Refresh"
            />
          </div>
          <Stack horizontal horizontalAlign="space-between">
            {getDetailLabel('Cash On Hand')}
            {getDashboardValue(formatCurrencyWithDashForZero(stats.monetaryStats.cashOnHand))}
            {getDashboardValue('')}
            {getDashboardValue('')}
            {getDashboardValue('')}
          </Stack>
          <Stack horizontal horizontalAlign="space-between">
            {getDetailLabel('Undeposited Funds')}
            {getDashboardValue(formatCurrencyWithDashForZero(stats.monetaryStats.undepositedFunds))}
            {getDashboardValue('')}
            {getDashboardValue('')}
            {getDashboardValue('')}
          </Stack>
          <div style={{ height: '16px' }} />
          <div>
            <Stack horizontal horizontalAlign="space-between" style={{ fontWeight: 'bold', alignItems: 'flex-end' }}>
              {getEntries(['', 'Cash On Hand', 'Receipts', 'Disbursements'])}
            </Stack>
            <div style={{ height: '8px' }} />
            {getDetailMoneyRows(stats.monetaryStats.details)}
          </div>
        </div>
        <div className="secondary-actions">
          <ActionButton
            className="action-btn"
            onClick={() => history.push('/filer/contributions')}
            disabled={
              ![
                CampaignUserRoles.SysAdmin,
                CampaignUserRoles.Admin,
                CampaignUserRoles.Accounting,
                CampaignUserRoles.DataEntry,
                CampaignUserRoles.ContributionEntry,
                CampaignUserRoles.Fundraiser,
              ].includes(user.session.role)
            }
            text={`View All ${getLabel('Contributions', user.session)}`}
            iconProps={{
              iconName: 'WavyBill',
              styles: {
                root: {
                  color: '#107c10',
                },
              },
            }}
          />
          <ActionButton
            className="action-btn"
            onClick={() => history.push('/filer/expenditures')}
            disabled={
              ![
                CampaignUserRoles.SysAdmin,
                CampaignUserRoles.Admin,
                CampaignUserRoles.Accounting,
                CampaignUserRoles.DataEntry,
                CampaignUserRoles.ExpenditureEntry,
                CampaignUserRoles.Fundraiser,
              ].includes(user.session.role)
            }
            text={`View All ${getLabel('Expenditures', user.session)}`}
            iconProps={{
              iconName: 'WavyBill',
              styles: {
                root: {
                  color: '#e81123',
                },
              },
            }}
          />
          <ActionButton
            className="action-btn"
            onClick={() => history.push('/filer/loans')}
            disabled={
              ![
                CampaignUserRoles.SysAdmin,
                CampaignUserRoles.Admin,
                CampaignUserRoles.Accounting,
              ].includes(user.session.role)
            }
            text="View All Loans"
            iconProps={{
              iconName: 'WavyBill',
              styles: {
                root: {
                  color: '#ff8c00',
                },
              },
            }}
          />
        </div>
      </div>
    );
  };
  if (statsStatus === statuses.PROCESSING || statsStatus === statuses.NOT_STARTED) {
    return (
      <div className="finance-content depth-1">
        <div className="finances" style={{ minHeight: '275px' }}>
          <Spinner size={SpinnerSize.small} label="Loading financial data for campaign" />
        </div>
        <div className="secondary-actions">
          <ActionButton
            className="action-btn"
            onClick={() => history.push('/filer/contributions')}
            disabled={
              ![
                CampaignUserRoles.SysAdmin,
                CampaignUserRoles.Admin,
                CampaignUserRoles.Accounting,
                CampaignUserRoles.DataEntry,
                CampaignUserRoles.ContributionEntry,
                CampaignUserRoles.Fundraiser,
              ].includes(user.session.role)
            }
            text={`View All ${getLabel('Contributions', user.session)}`}
            iconProps={{
              iconName: 'WavyBill',
              styles: {
                root: {
                  color: '#107c10',
                },
              },
            }}
          />
          <ActionButton
            className="action-btn"
            onClick={() => history.push('/filer/expenditures')}
            disabled={
              ![
                CampaignUserRoles.SysAdmin,
                CampaignUserRoles.Admin,
                CampaignUserRoles.Accounting,
                CampaignUserRoles.DataEntry,
                CampaignUserRoles.ExpenditureEntry,
                CampaignUserRoles.Fundraiser,
              ].includes(user.session.role)
            }
            text={`View All ${getLabel('Expenditures', user.session)}`}
            iconProps={{
              iconName: 'WavyBill',
              styles: {
                root: {
                  color: '#e81123',
                },
              },
            }}
          />
          <ActionButton
            className="action-btn"
            onClick={() => history.push('/filer/loans')}
            disabled={
              ![
                CampaignUserRoles.SysAdmin,
                CampaignUserRoles.Admin,
                CampaignUserRoles.Accounting,
                CampaignUserRoles.DataEntry,
                CampaignUserRoles.Fundraiser,
              ].includes(user.session.role)
            }
            text="View All Loans"
            iconProps={{
              iconName: 'WavyBill',
              styles: {
                root: {
                  color: '#ff8c00',
                },
              },
            }}
          />
        </div>
      </div>
    );
  }
  if (stats && stats.monetaryStats) {
    return getDashboard();
  }
  return (
    <div className="finance-content depth-1">
      <div className="finances" style={{ minHeight: '275px' }}>
        <h3>Stats are calculating. Refresh page in a moment to see finance stats.</h3>
      </div>
    </div>
  );
};

DashboardCampaignFinances.propTypes = {
  history: PropTypes.object.isRequired,
  user: PropTypes.object,
  currentCampaign: PropTypes.object,
};

DashboardCampaignFinances.defaultProps = {
  user: {},
  currentCampaign: {},
};

export default DashboardCampaignFinances;
