import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from 'office-ui-fabric-react/lib/Button';

const style = {
  root: {
    color: '#fff',
  },
};

function formatAddress(party) {
  if (!party.addressLine1 && !party.city && !party.state && !party.zipCode) {
    return '';
  }
  if (party?.addressLine2) {
    return `(${party.addressLine1}${party.addressLine1 ? ',' : ''} ${
      party.addressLine2
    }, ${party.city}${party.city ? ',' : ''} ${party.state} ${party.zipCode})`;
  }

  return `(${party.addressLine1}${party.addressLine1 ? ',' : ''} ${party.city}${
    party.city ? ',' : ''
  } ${party.state || ''} ${party.zipCode})`;
}

const ResponsibleParty = ({ party, editParty, deleteParty }) => (
  <div className="responsible-party">
    {party.businessName ? (
      <h4>{`${party.businessName} ${formatAddress(party)}`}</h4>
    ) : (
      <h4>{`${party.firstName} ${party.lastName} ${formatAddress(party)}`}</h4>
    )}
    <span>
      <IconButton
        iconProps={{ iconName: 'PencilAlt', styles: style }}
        onClick={() => {
          editParty(party._id);
        }}
      />
      <IconButton
        iconProps={{ iconName: 'TrashAlt', styles: style }}
        onClick={() => {
          deleteParty(party._id);
        }}
      />
    </span>
  </div>
);

ResponsibleParty.propTypes = {
  party: PropTypes.object.isRequired,
  editParty: PropTypes.func.isRequired,
  deleteParty: PropTypes.func.isRequired,
};

export default ResponsibleParty;
