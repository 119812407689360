import { emailRegex } from '../../helpers/constants';

export const validate = (state, selectedContact) => {
  const errors = {};

  if (!selectedContact && state.contactType === 0) {
    errors.contactTypeError = 'Contact type is required';
  }

  if (state.periodBeginningBalance.length === 0) {
    errors.periodBeginningBalanceError =
      'Period beginning balance is required (if none, enter 0)';
  }

  if (parseFloat(state.periodBeginningBalance) < 0) {
    errors.periodBeginningBalanceError = 'Invalid amount';
  }

  if (state.incurredAmount.length === 0) {
    errors.incurredAmountError =
      'Incurred amount is required (if none, enter 0)';
  }

  if (state.email && !emailRegex.test(state.email)) {
    errors.emailErrorMessage = 'Please use a valid email address';
  }

  if (parseFloat(state.incurredAmount) < 0) {
    errors.incurredAmountError = 'Invalid amount';
  }

  if (state.paymentAmount.length === 0) {
    errors.paymentAmountError = 'Payment amount is required (if none, enter 0)';
  }

  if (parseFloat(state.paymentAmount) < 0) {
    errors.paymentAmountError = 'Invalid amount';
  }

  if (state.periodEndingBalance.length === 0) {
    errors.periodEndingBalanceError =
      'Period ending balance is required (if none, enter 0)';
  }

  if (parseFloat(state.periodEndingBalance) < 0) {
    errors.periodEndingBalanceError = 'Invalid amount';
  }

  return errors;
};
