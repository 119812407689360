import React from 'react';
import PropTypes from 'prop-types';
import { GridRow, Column, MoneyField } from '../../common';
import { getNetContributions } from '../../../pages/Reports/fecReportHelper';

const FECSection6c = ({ state }) => (
  <GridRow>
    <Column sm={4} classNames="sub-question">
      <p>
        (c) Net Contributions (other than loans)
        <br />
        {'(subtract Line 6(b) from Line 6(a))'}
      </p>
    </Column>
    <Column sm={4} classNames="entry-row">
      <MoneyField value={getNetContributions('A', state)} disabled borderless />
    </Column>
    <Column sm={4} classNames="entry-row">
      <MoneyField value={getNetContributions('B', state)} disabled borderless />
    </Column>
  </GridRow>
);

FECSection6c.propTypes = {
  state: PropTypes.object.isRequired,
};

export default FECSection6c;
