const types = {
  GET_REPORTING_PERIODS: 'GET_REPORTING_PERIODS',
  GET_REPORTING_PERIODS_SUCCESS: 'GET_REPORTING_PERIODS_SUCCESS',
  GET_REPORTING_PERIODS_FAILURE: 'GET_REPORTING_PERIODS_FAILURE',
};

const actions = {
  getReportingPeriods() {
    return { type: types.GET_REPORTING_PERIODS };
  },
};

export { types, actions };
