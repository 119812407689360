import { types } from '../actions/listActions';
import { createReducer } from './createReducer';
import { formatDate, formatCurrency } from '../helpers/util';
import { statuses } from '../helpers/constants';

export const initialState = {
  items: [],
  totalRecordCount: 0,
  status: statuses.NOT_STARTED,
  csvStatus: statuses.NOT_STARTED,
  csvContributionLimitStatus: statuses.NOT_STARTED,
};

const addSystemTag = item => {
  if (item.transactionType === 'Contribution') {
    return 'contributor';
  }

  if (item.transactionType === 'Expenditure') {
    return 'recipient';
  }

  if (item.transactionType === 'Loan') {
    return 'loan';
  }

  return '';
};

const formatTags = (tags, additionalTags) => {
  if (!tags) {
    return [additionalTags];
  }

  if (typeof tags === 'string') {
    return [...tags.split(',').filter(Boolean), additionalTags];
  }

  if (Array.isArray(tags)) {
    return [...tags.filter(Boolean), additionalTags];
  }

  return [tags, additionalTags];
};

export function fullAddress(address = {}) {
  return `
    ${address.addressLine1 || ''}
    ${address.addressLine2 || ''}
    ${address.city || ''}, ${address.state || ''} ${address.zipCode || ''}
    ${address.county || ''}
  `;
}

const flatMap = item => {
  return {
    ...item,
    businessName: item.businessName || '',
    addressLine1: item.address?.addressLine1 || '',
    addressLine2: item.address?.addressLine2 || '',
    city: item.address?.city || '',
    state: item.address?.state || '',
    zipCode: item.address?.zipCode || item.zipCode || '',
    county: item.address?.county || '',
    address: fullAddress(item.address),
    transactionDate: formatDate(item.transactionDate, 'YYYY-MM-DD'),
    receivedDate: formatDate(item.transactionDate, 'YYYY-MM-DD'),
    expenditureDate: formatDate(item.expenditureDate, 'YYYY-MM-DD'),
    createdAt: formatDate(item.createdAt, 'YYYY-MM-DD'),
    updatedAt: formatDate(item.updatedAt, 'YYYY-MM-DD'),
    amount: formatCurrency(item.amount || 0),
    aggregateAmount: formatCurrency(item.aggregateAmount || 0),
    tags: formatTags(item.tags, addSystemTag(item)),
    endRecipients: (item.endRecipients || []).map(er => ({
      ...er,
      addressLine1: er.address?.addressLine1 || '',
      addressLine2: er.address?.addressLine2 || '',
      city: er.address?.city || '',
      state: er.address?.state || '',
      zipCode: er.address?.zipCode || '',
      county: er.address?.county || '',
      address: fullAddress(er.address),
      expenditureDate: formatDate(er.expenditureDate),
      amount: formatCurrency(er.amount || 0),
      tags: formatTags(er.tags, 'end-recipient'),
    })),
  };
};

const actionMap = {
  [types.GET_LIST_PROCESSING]: state => ({
    ...state,
    status: statuses.PROCESSING,
    items: [],
    totalRecordCount: 0,
  }),
  [types.GET_LIST_SUCCESS]: (state, { data: { items, count } }) => {
    return {
      ...state,
      items: items.map(flatMap),
      status: statuses.SUCCESS,
      totalRecordCount: count,
    };
  },
  [types.CLEAR_LISTS]: () => initialState,
  [types.GET_CSV_PROCESSING]: state => ({
    ...state,
    csvStatus: statuses.PROCESSING,
  }),
  [types.GET_CSV_SUCCESS]: state => ({
    ...state,
    csvStatus: statuses.SUCCESS,
  }),
  [types.GET_CSV_FAILURE]: state => ({
    ...state,
    csvStatus: statuses.ERROR,
  }),
  [types.GET_CONTRIBUTION_LIMIT_CSV_PROCESSING]: state => ({
    ...state,
    csvContributionLimitStatus: statuses.PROCESSING,
  }),
  [types.GET_CONTRIBUTION_LIMIT_CSV_SUCCESS]: state => ({
    ...state,
    csvContributionLimitStatus: statuses.SUCCESS,
  }),
  [types.GET_CONTRIBUTION_LIMIT_CSV_FAILURE]: state => ({
    ...state,
    csvContributionLimitStatus: statuses.ERROR,
  }),
};

export const listReducer = createReducer(initialState, actionMap);
