import { createReducer } from '../../reducers/createReducer';

export const initialState = {
  type: 0,
  firstName: '',
  middleName: '',
  lastName: '',
  salutation: 'none',
  businessName: '',
  employer: '',
  occupation: '',
  committeeFecId: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: 0,
  zipCode: '',
  county: '',
  phone: '',
  emailAddress: '',
  errors: {
    firstNameError: '',
    lastNameError: '',
    businessNameError: '',
    typeError: '',
  },
};

export const actions = {
  SET_CONTRIBUTION_INFO: 'SET_CONTRIBUTION_INFO',
  CLEAR_STATE: 'CLEAR_STATE',
  HANDLE_CHANGE: 'HANDLE_CHANGE',
  SET_FORM_ERRORS: 'SET_FORM_ERRORS',
};

export const actionMap = {
  [actions.CLEAR_STATE]: () => ({
    ...initialState,
  }),
  [actions.SET_CONTRIBUTION_INFO]: (
    state,
    { data: { contributionInfo, session } },
  ) => {
    const isLikelyIndividual =
      contributionInfo.firstName && contributionInfo.lastName;
    const { address = {}, ...rest } = contributionInfo;
    return {
      ...state,
      ...rest,
      ...address,
      type: isLikelyIndividual
        ? session.isFederal()
          ? 'IND'
          : 'Individual'
        : state.type,
    };
  },
  [actions.HANDLE_CHANGE]: (state, { data: { fieldName, value } }) => {
    if (fieldName === 'type') {
      const currentType = state.type;
      return {
        ...state,
        [fieldName]: value,
        errors: {
          ...(currentType === value
            ? state.errors
            : {
                ...state.errors,
                firstNameError: '',
                lastNameError: '',
                businessNameError: '',
                typeError: '',
              }),
        },
      };
    }
    return {
      ...state,
      [fieldName]: value,
    };
  },
  [actions.SET_FORM_ERRORS]: (state, { data: { errors } }) => ({
    ...state,
    errors: {
      ...state.errors,
      ...errors,
    },
  }),
};

export const createContactReducer = createReducer(initialState, actionMap);
