import React from 'react';
import PropTypes from 'prop-types';
import { mergeStyleSets, ActionButton, Text } from 'office-ui-fabric-react';
import { getLabel } from '../../helpers/labelHelper';
import { formatDate } from '../../helpers/util';

const styles = mergeStyleSets({
  container: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#e5e5e5',
    borderRadius: 26,
    boxSizing: 'border-box',
    padding: '0 4px 0 12px',
    margin: 8,
  },
  label: {
    textTransform: 'uppercase',
  },
});

const expressionMap = {
  exists: 'Exists',
  doesNotExist: 'Does not exist',
  equals: 'Equals',
  notEquals: 'Does not equal',
  isOneOf: 'Is one of:',
  isNotOneOf: 'Is not one of:',
  isGreaterThan: '>',
  isGreaterThanOrEqual: '>=',
  isLessThan: '<',
  isLessThanOrEqual: '<=',
  contains: 'Contains:',
};

const SelectedFilter = ({ filter: fltr, removeFilter, session }) => {
  const filter = { ...fltr };
  if (filter.column === 'transactionDate') {
    filter.value = formatDate(filter.value);
  }

  if (['exists', 'doesNotExist'].includes(filter.expression)) {
    return (
      <div className={styles.container}>
        <Text className={styles.label} variant="xSmall">
          {`${filter.column} ${expressionMap[filter.expression] || ''}`}
        </Text>
        <ActionButton
          onClick={() => removeFilter(filter.id)}
          iconProps={{ iconName: 'Times' }}
        />
      </div>
    );
  }
  return (
    <div className={styles.container}>
      <Text className={styles.label} variant="xSmall">
        {`${filter.column} ${expressionMap[filter.expression] || ''} ${
          Array.isArray(filter.value)
            ? filter.value.map(val => getLabel(val, session)).join(', ')
            : getLabel(filter.value, session)
        }`}
      </Text>
      <ActionButton
        onClick={() => removeFilter(filter.id)}
        iconProps={{ iconName: 'Times' }}
      />
    </div>
  );
};

SelectedFilter.propTypes = {
  filter: PropTypes.object.isRequired,
  removeFilter: PropTypes.func.isRequired,
  session: PropTypes.object.isRequired,
};

export default SelectedFilter;
