import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'office-ui-fabric-react/lib/Dropdown';
import { creditCardTypes } from '../../helpers/constants';
import { CaretDown } from '../icons';

export const CreditCardTypePicker = ({
  onChange,
  selectedKey,
  style,
  required,
  className,
  label,
  errorMessage,
}) => (
  <Dropdown
    onRenderCaretDown={() => <CaretDown />}
    onChange={onChange}
    selectedKey={selectedKey}
    style={style}
    className={className}
    required={required}
    label={label}
    errorMessage={errorMessage}
    options={[
      {
        key: 0,
        text: 'Select',
      },
      ...creditCardTypes.map(cct => ({
        key: cct,
        text: cct,
      })),
    ]}
  />
);

CreditCardTypePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  selectedKey: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.number,
  ]),
  className: PropTypes.string,
  style: PropTypes.object,
  required: PropTypes.bool,
  label: PropTypes.string,
  errorMessage: PropTypes.string,
};

CreditCardTypePicker.defaultProps = {
  selectedKey: { key: 0 },
  className: '',
  style: {},
  required: false,
  label: 'Credit Card Type',
  errorMessage: '',
};

export default CreditCardTypePicker;
