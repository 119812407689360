import PropTypes from 'prop-types';
import React from 'react';
import './index.css';

const Steps = ({ steps, currentStepIndex }) => {
  const isCompleteStep = index => currentStepIndex > index;

  const isCurrentStep = index => currentStepIndex === index;

  const isLastStep = index => index === steps.length - 1;

  const getIndexContainerClass = index =>
    isCurrentStep(index)
      ? 'ms-borderColor-themePrimary'
      : isCompleteStep(index)
      ? 'ms-borderColor-themeDarker'
      : 'ms-borderColor-neutralTertiary';

  const getIndexClass = index =>
    isCurrentStep(index)
      ? 'ms-fontColor-themePrimary'
      : isCompleteStep(index)
      ? 'ms-fontColor-themeDarker'
      : 'ms-fontColor-neutralTertiary';

  const getDividerClasses = index =>
    `step-divider ${
      isCompleteStep(index)
        ? 'ms-bgColor-themeDarker'
        : 'ms-bgColor-neutralTertiary'
    }`;

  const getLabelClass = index =>
    isCurrentStep(index)
      ? 'ms-fontColor-neutralPrimary'
      : isCompleteStep(index)
      ? 'ms-fontColor-neutralSecondary'
      : 'ms-fontColor-neutralTertiary';

  return (
    <div className="Steps">
      {steps.map(({ index, label }) => (
        <div key={String(index)} className="step-container">
          <div className="step-progress-container">
            <div
              style={index === 0 ? { visibility: 'hidden' } : undefined}
              className={getDividerClasses(index - 1)}
            />
            <div
              className={`step-index-container ${getIndexContainerClass(
                index,
              )}`}
            >
              <span
                className={`step-index-container-value ${getIndexClass(index)}`}
              >
                {index < 9 ? `0${index + 1}` : index + 1}
              </span>
            </div>
            <div
              style={isLastStep(index) ? { visibility: 'hidden' } : undefined}
              className={getDividerClasses(index)}
            />
          </div>
          <div mt={0.625} className="step-info">
            <span className={`step-title ${getIndexClass(index)}`}>
              Step&nbsp;
              {index < 9 ? `0${index + 1}` : index + 1}
            </span>
            <span className={`step-label ${getLabelClass(index)}`}>
              {label}
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};

Steps.propTypes = {
  steps: PropTypes.array.isRequired,
  currentStepIndex: PropTypes.number.isRequired,
};

export default Steps;
