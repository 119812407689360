import {
  DefaultButton,
  PrimaryButton,
} from 'office-ui-fabric-react/lib/Button';
import { ChoiceGroup } from 'office-ui-fabric-react/lib/ChoiceGroup';
import { Dialog, DialogType } from 'office-ui-fabric-react/lib/Dialog';
import { Dropdown } from 'office-ui-fabric-react/lib/Dropdown';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { TooltipHost } from 'office-ui-fabric-react/lib/Tooltip';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import { sortDirections } from '../../helpers/constants';
import {
  formatDate,
  formatDateOnly,
  formatNonStandardDate,
  sortByField,
} from '../../helpers/util';
import {
  Column,
  DataLabel,
  EmailField,
  Grid,
  GridRow,
  PhoneNumberField,
  ZipCodeField,
} from '../common';
import { CaretDown } from '../icons';
import {
  DatePicker,
  FiledReportPicker,
  StatePicker,
  YearPicker,
} from '../Pickers';
import './AddReportDialog.css';
import { validate } from './AddReportDialogValidations';

const initialState = {
  filingStatus: 0,
  electionDate: 0,
  reportingPeriod: -1,
  reportYear: 0,
  startDate: null,
  endDate: null,
  previousReportId: 0,
};

const initialCampaignRecordMaintainerState = {
  firstName: '',
  middleName: '',
  lastName: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: 0,
  zipCode: '',
  phone1: '',
  email: '',
};

export const AddReportDialog = ({
  dialogHidden,
  closeDialog,
  createReport,
  electionYear,
  electionDates,
  registrationDate,
  reportType,
  selectedReportingPeriod,
  isNonCandidateCommittee,
  reportingPeriods,
}) => {
  const [state, setState] = useState({ ...initialState });
  const [campaignRecordMaintainer, setCampaignRecordMaintainer] = useState({
    ...initialCampaignRecordMaintainerState,
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (selectedReportingPeriod && state.reportingPeriod === -1) {
      setState({
        ...state,
        reportingPeriod: selectedReportingPeriod,
      });
    }
  }, [selectedReportingPeriod]);

  const handleChange = fieldName => (e, value) => {
    setState({
      ...state,
      [fieldName]: value.key !== undefined ? value.key : value,
    });
  };

  const handleMaintainerChange = fieldName => (e, value) => {
    setCampaignRecordMaintainer({
      ...campaignRecordMaintainer,
      [fieldName]: value.key !== undefined ? value.key : value,
    });
  };

  const handleChangeDate = fieldName => date => {
    setState({
      ...state,
      [fieldName]: date,
    });
  };

  const onCloseDialog = () => {
    setState({ ...initialState });
    setCampaignRecordMaintainer({ ...initialCampaignRecordMaintainerState });
    setErrors({});

    closeDialog();
  };

  const getReportType = () => {
    if (reportType === 'TBD') {
      return '17';
    }

    if (reportType === 'Termination') {
      return '18';
    }

    return state.reportingPeriod;
  };

  const onCreateReport = () => {
    const errors = validate(state, campaignRecordMaintainer, reportType);
    const isFirstReport = state.filingStatus === 1;
    if (Object.keys(errors).length) {
      setErrors(errors);
    } else {
      const payload = {
        electionDate: electionDates.find(ed => ed._id === state.electionDate)
          .electionDate,
        electionYear,
        isFirstFilingForCurrentOffice:
          reportType === '' && state.filingStatus === 1,
        isFirstFilingForElectionCycle:
          reportType === '' && state.filingStatus === 2,
        isSecondOrSubsequentFilingForElectionCycle:
          reportType === '' && state.filingStatus === 3,
        isTerminationReport: reportType === 'Termination',
        reportDate: formatDateOnly(new Date()),
        reportType:
          reportType === 'TBD' ? 'TBD Contribution Report' : 'Original',
        reportingPeriod: getReportType(),
        reportingPeriodYear: state.reportYear,
        startDate: state.startDate ? formatDateOnly(state.startDate) : null,
        endDate: state.endDate ? formatDateOnly(state.endDate) : null,
        previousReportId: isFirstReport ? undefined : state.previousReportId,
      };

      if (reportType === 'Termination') {
        payload.campaignRecords = {
          firstName: campaignRecordMaintainer.firstName,
          middleName: campaignRecordMaintainer.middleName,
          lastName: campaignRecordMaintainer.lastName,
          addressLine1: campaignRecordMaintainer.addressLine1,
          addressLine2: campaignRecordMaintainer.addressLine2,
          city: campaignRecordMaintainer.city,
          state: campaignRecordMaintainer.state,
          zipCode: campaignRecordMaintainer.zipCode,
          telephoneNumber: campaignRecordMaintainer.phone1,
          emailAddress: campaignRecordMaintainer.email,
        };
      }

      createReport(payload);
      setState({ ...initialState });
      setCampaignRecordMaintainer({ ...initialCampaignRecordMaintainerState });
      setErrors({});
    }
  };

  const getDialogTitle = () => {
    switch (reportType) {
      case 'TBD':
        return 'Create TBD Report';
      case 'Termination':
        return 'Create Final Report';
      default:
        return 'Create Disclosure Report';
    }
  };

  return (
    <Dialog
      hidden={dialogHidden}
      onDismiss={closeDialog}
      dialogContentProps={{
        type: DialogType.normal,
        title: getDialogTitle(),
        subText: '',
      }}
      modalProps={{
        isBlocking: false,
        containerClassName: 'AddReportDialog',
      }}
    >
      <div className="add-disclosure-report">
        <Grid>
          <GridRow>
            <Column lg={4}>
              <DataLabel
                size="large"
                label="Report Date"
                value={formatDate(new Date())}
              />
            </Column>
          </GridRow>
          {reportType === '' && (
            <Fragment>
              <GridRow>
                {!isNonCandidateCommittee && (
                  <Column lg={4}>
                    <DataLabel
                      size="large"
                      label="Election Year"
                      value={electionYear}
                    />
                  </Column>
                )}
                {isNonCandidateCommittee && registrationDate && (
                  <Column lg={4}>
                    <DataLabel
                      size="large"
                      label="Registration Year"
                      value={formatDate(registrationDate, 'YYYY')}
                    />
                  </Column>
                )}
              </GridRow>
              <GridRow>
                <Column>
                  <ChoiceGroup
                    required
                    label="Filing Status"
                    selectedKey={state.filingStatus}
                    onChange={handleChange('filingStatus')}
                    options={[
                      {
                        key: 1,
                        text:
                          'This is the first filing for the Office for which I am running',
                      },
                      {
                        key: 2,
                        text:
                          'This is the first filing for this Election Cycle',
                      },
                      {
                        key: 3,
                        text:
                          'This is the second or subsequent filing for this Election Cycle',
                      },
                    ]}
                  />
                  {errors.filingStatusError && (
                    <p className="error-message">{errors.filingStatusError}</p>
                  )}
                </Column>
              </GridRow>
            </Fragment>
          )}
          {reportType === 'Termination' && (
            <div className="campaign-maintainer">
              <GridRow>
                <Column>
                  <h3>Person Responsible for Maintaining Campaign Records</h3>
                </Column>
              </GridRow>
              <GridRow>
                <Column lg={4}>
                  <TextField
                    value={campaignRecordMaintainer.firstName}
                    label="First Name"
                    errorMessage={errors.firstNameError}
                    onChange={handleMaintainerChange('firstName')}
                    required
                  />
                </Column>
                <Column lg={4}>
                  <TextField
                    value={campaignRecordMaintainer.middleName}
                    label="Middle Name"
                    onChange={handleMaintainerChange('middleName')}
                  />
                </Column>
                <Column lg={4}>
                  <TextField
                    value={campaignRecordMaintainer.lastName}
                    label="Last Name"
                    errorMessage={errors.lastNameError}
                    onChange={handleMaintainerChange('lastName')}
                    required
                  />
                </Column>
              </GridRow>
              <GridRow>
                <Column lg={6}>
                  <TextField
                    value={campaignRecordMaintainer.addressLine1}
                    label="Address 1"
                    errorMessage={errors.addressLine1Error}
                    required
                    onChange={handleMaintainerChange('addressLine1')}
                  />
                </Column>
                <Column lg={6}>
                  <TextField
                    value={campaignRecordMaintainer.addressLine2}
                    label="Address 2"
                    onChange={handleMaintainerChange('addressLine2')}
                  />
                </Column>
              </GridRow>
              <GridRow>
                <Column lg={4}>
                  <TextField
                    value={campaignRecordMaintainer.city}
                    label="City"
                    errorMessage={errors.cityError}
                    onChange={handleMaintainerChange('city')}
                    required
                  />
                </Column>
                <Column lg={4}>
                  <StatePicker
                    selectedKey={campaignRecordMaintainer.state}
                    onChange={handleMaintainerChange('state')}
                    required
                    errorMessage={errors.stateError}
                  />
                </Column>
                <Column lg={4}>
                  <ZipCodeField
                    value={campaignRecordMaintainer.zipCode}
                    errorMessage={errors.zipCodeError}
                    onChange={handleMaintainerChange('zipCode')}
                    required
                  />
                </Column>
              </GridRow>
              <GridRow>
                <Column lg={6}>
                  <PhoneNumberField
                    label="Phone"
                    value={campaignRecordMaintainer.phone1}
                    errorMessage={errors.phone1Error}
                    required
                    onChange={handleMaintainerChange('phone1')}
                  />
                </Column>
                <Column lg={6}>
                  <EmailField
                    formSection="campaign-maintainer"
                    value={campaignRecordMaintainer.email}
                    errorMessage={errors.emailError}
                    required
                    onChange={handleMaintainerChange('email')}
                  />
                </Column>
              </GridRow>
            </div>
          )}
          <GridRow>
            <Column>
              <Dropdown
                onRenderCaretDown={() => <CaretDown />}
                label="Election Date"
                required
                errorMessage={errors.electionDateError}
                selectedKey={state.electionDate}
                onChange={handleChange('electionDate')}
                options={[
                  { key: 0, text: 'Select' },
                  ...electionDates
                    .sort(sortByField('electionDate', sortDirections.ASC))
                    .map(ed => ({
                      key: ed._id,
                      text: `${formatNonStandardDate(
                        ed.electionDate,
                        'YYYY-MM-DD',
                      )} - ${ed.electionCycle}`,
                    })),
                ]}
              />
            </Column>
          </GridRow>
          {reportType === '' && (
            <GridRow>
              <Column>
                <Dropdown
                  label="Reporting Period"
                  onRenderCaretDown={() => <CaretDown />}
                  selectedKey={state.reportingPeriod}
                  errorMessage={errors.reportingPeriodError}
                  required
                  onChange={handleChange('reportingPeriod')}
                  options={[
                    { key: 0, text: 'Select' },
                    ...reportingPeriods.map(rp => ({
                      key: rp.id,
                      text: `${rp.group} - ${rp.desc}`,
                    })),
                  ].filter(e => e.key !== '17')}
                />
              </Column>
            </GridRow>
          )}
          {reportType !== 'TBD' && (
            <GridRow>
              <Column>
                <YearPicker
                  onChange={handleChange('reportYear')}
                  required
                  errorMessage={errors.reportYearError}
                  label="Report Covering Year"
                  value={state.reportYear}
                />
              </Column>
            </GridRow>
          )}
          <GridRow>
            <Column lg={6}>
              <DatePicker
                label={'Report Start Date'}
                value={state.startDate}
                placeholder="Select Date"
                errorMessage={errors.startDateError}
                onChange={handleChangeDate('startDate')}
                required
              />
            </Column>
            <Column lg={6}>
              <DatePicker
                label={'Report End Date'}
                value={state.endDate}
                placeholder="Select Date"
                errorMessage={errors.endDateError}
                onChange={handleChangeDate('endDate')}
                required
              />
            </Column>
          </GridRow>
          {state.filingStatus !== 1 && reportType !== 'TBD' && (
            <GridRow>
              <Column md={11}>
                <FiledReportPicker
                  label="Previous Report Balance Source"
                  selectedKey={state.previousReportId}
                  onChange={handleChange('previousReportId')}
                  errorMessage={errors.previousReportIdError}
                  required
                />
              </Column>
              <Column md={1}>
                <TooltipHost
                  content="All reports but first filing for office must contain a reference to obtain the current totals to date. This can either be the campaign default settings or a reference to a previously filed report."
                  closeDelay={750}
                >
                  <Icon
                    iconName="Help"
                    styles={{
                      root: {
                        fontSize: '10pt',
                        backgroundColor: 'var(--primary-color))',
                        display: 'inline-flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: 24,
                        height: 24,
                        color: '#fff',
                        borderRadius: '50%',
                        marginTop: '33px',
                      },
                    }}
                  />
                </TooltipHost>
              </Column>
              <Column md={6} />
            </GridRow>
          )}
        </Grid>
      </div>
      <div className="add-report-actions">
        <DefaultButton
          text="Cancel"
          onClick={onCloseDialog}
          style={{ marginRight: 16 }}
        />
        <PrimaryButton text="Create Report" onClick={onCreateReport} />
      </div>
    </Dialog>
  );
};

AddReportDialog.propTypes = {
  dialogHidden: PropTypes.bool,
  closeDialog: PropTypes.func.isRequired,
  createReport: PropTypes.func.isRequired,
  electionYear: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  electionDates: PropTypes.array.isRequired,
  registrationDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  reportType: PropTypes.string,
  selectedReportingPeriod: PropTypes.string,
  isNonCandidateCommittee: PropTypes.bool,
  reportingPeriods: PropTypes.array,
};

AddReportDialog.defaultProps = {
  dialogHidden: true,
  reportType: '',
  selectedReportingPeriod: null,
  isNonCandidateCommittee: false,
  registrationDate: '',
  reportingPeriods: [],
};

export default AddReportDialog;
