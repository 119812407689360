import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { GridRow, Column, MoneyField } from '../../common';

const FECSection18 = ({ q18ColA, q18ColB }) => {
  return (
    <Fragment>
      <GridRow style={{ borderTop: '1px solid #919191', paddingBottom: 6 }}>
        <Column sm={4}>
          <p>18. TRANSFERS TO OTHER AUTHORIZED COMMITTEES</p>
        </Column>
        <Column sm={4} classNames="entry-row">
          <MoneyField
            disabled
            value={q18ColA || 0}
          />
        </Column>
        <Column sm={4} classNames="entry-row">
          <MoneyField value={q18ColB || 0} disabled />
        </Column>
      </GridRow>
    </Fragment>
  );
};

FECSection18.propTypes = {
  q18ColA: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  q18ColB: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default FECSection18;
