import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import {
  MessageBar,
  MessageBarType,
} from 'office-ui-fabric-react/lib/MessageBar';
import { BackButton } from '../../components/common';
import { actions as currentReportActions } from '../../actions/currentReportActions';
import { actions as messagingActions } from '../../actions/messagingActions';
import { statuses } from '../../helpers/constants';

import './VerifyFECReport.css';

const VerifyFECReport = ({
  match,
  history,
  currentReport,
  currentReportActions,
  messagingActions,
}) => {
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    return () => {
      currentReportActions.resetReport();
    };
  }, []);

  useEffect(() => {
    if (currentReport) {
      if (currentReport.verificationStatus === statuses.PROCESSING) {
        setIsProcessing(true);
      } else if (currentReport.verificationStatus === statuses.SUCCESS) {
        // messagingActions.setSuccessToast('Report Verified Successfully'); (randy)
        setIsProcessing(false);
      } else if (currentReport.verificationStatus === statuses.ERROR) {
        messagingActions.setErrorToast(currentReport.verificationError);
        setIsProcessing(false);
      }
    }
  }, [
    currentReport,
    currentReport.verificationStatus,
    currentReport.verificationError,
    currentReport.verificationReport,
  ]);

  useEffect(() => {
    // open up new window while report is requested
    if (currentReport.verifyFederalWithWebStatus === statuses.PROCESSING) {
      setIsProcessing(true);
    }
    // Either have the report, or we don't
    if (currentReport.verifyFederalWithWebStatus === statuses.SUCCESS) {
      setIsProcessing(false);
    }
    if (currentReport.verifyFederalWithWebStatus === statuses.ERROR) {
      messagingActions.setErrorToast(currentReport.verifyFederalWithWebError);
      setIsProcessing(false);
    }
  }, [
    currentReport.verifyFederalWithWebStatus,
    currentReport.verifyFederalWithWebResult,
    currentReport.verifyFederalWithWebReportId,
  ]);

  const verifyReportWithWebService = () => {
    const { id } = match.params;
    currentReportActions.verifyFederalReportWithWebService(id);
  };

  const downloadWSReport = () => {
    window.open(`/api/filer/reports/${match.params.id}/fec/draftValidate`);
  };

  const downloadFecFile = () => {
    window.open(`/api/filer/reports/${match.params.id}/fec/draftFecFile`);
  };

  return (
    <Fragment>
      <BackButton
        history={history}
        url={`/filer/editFECReport/${match.params.id}`}
        message="Back to Report"
      />
      <div className="VerifyFECReport depth-1">
        <h3>Report Verification</h3>
        {isProcessing && (
          <Spinner
            size={SpinnerSize.large}
            label="Verifying Report"
            ariaLive="assertive"
            labelPosition="right"
          />
        )}
        {currentReport &&
        currentReport.verificationStatus === statuses.ERROR && (
          <MessageBar messageBarType={MessageBarType.error}>
            {currentReport.verificationError}
          </MessageBar>
        )}
        {currentReport &&
        currentReport.verifyFederalWithWebStatus === statuses.SUCCESS && (
          <Fragment>
            <div className="verify-fec-report-actions">
              <PrimaryButton text="Download Report" onClick={downloadWSReport} />
              <PrimaryButton style={{ marginLeft: '5px' }} text="Download FEC File" onClick={downloadFecFile} />
            </div>
            <pre className="override-base-font-for-pre">
              {currentReport.verifyFederalWithWebResult}
            </pre>
          </Fragment>
        )
        }
        <div className="verify-fec-report-actions">
          <PrimaryButton className="verify-button" text="Verify Report" onClick={verifyReportWithWebService} />
        </div>
      </div>
    </Fragment>
  );
};

VerifyFECReport.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  currentReport: PropTypes.object.isRequired,
  currentReportActions: PropTypes.object.isRequired,
  messagingActions: PropTypes.object.isRequired,
};

const mapStateToProps = ({ currentReport }) => ({
  currentReport,
});

const mapDispatchToProps = dispatch => ({
  currentReportActions: bindActionCreators(currentReportActions, dispatch),
  messagingActions: bindActionCreators(messagingActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VerifyFECReport);
