import { takeLatest, take, put, call } from 'redux-saga/effects';
import axios from 'axios';
import { push } from 'connected-react-router';
import { eventChannel } from 'redux-saga';
import {
  getServerSideErrorMessage,
  errorToast,
  successToast,
} from '../helpers/util';
import { types as depositTypes } from '../actions/depositActions';
import { types as currentCampaignTypes } from '../actions/currentCampaignActions';
import Campaign from '../models/Campaign';

const url = '/api/filer/deposits';

const transformUndeposited = data =>
  data.map(item => {
    if (!item.receivedDate) {
      item.receivedDate = item?.expenditureDate || item?.loanDate;
    }

    item.displayName = item?.displayName || '';

    item.checkNumber = item.checkNumber
      ? typeof item.checkNumber === 'number'
        ? item.checkNumber.toString()
        : item.checkNumber
      : undefined;

    if (item.isContributionProcessingFee) {
      item.amount *= -1;
    }
    return item;
  });

function isAgendaJobComplete(totalSeconds, pause, id) {
  return eventChannel(emitter => {
    const iv = setInterval(() => {
      totalSeconds -= pause;
      if (totalSeconds > 0) {
        axios
          .get(`/api/jobs/getCompletedJob/${id}`)
          .then(res => {
            if (res.data.isComplete) {
              emitter({ ...res.data });
            }
          })
          .catch(error => {
            emitter({ error });
          });
      } else {
        // this causes the channel to close
        emitter(false);
      }
    }, pause * 1000);
    // The subscriber must return an unsubscribe function
    return () => {
      clearInterval(iv);
    };
  });
}

export function* handleGetAllUndeposited() {
  try {
    const { data } = yield call(axios.get, '/api/filer/deposits/undeposited', {
      withCredentials: true,
    });
    const transactions = transformUndeposited(data);
    yield put({
      type: depositTypes.GET_ALL_UNDEPOSITED_SUCCESS,
      data: {
        transactions,
      },
    });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put(errorToast(error));
  }
}

export function* getAllUndeposited() {
  yield takeLatest(depositTypes.GET_ALL_UNDEPOSITED, handleGetAllUndeposited);
}

export function* handleGetUndepositedTotal() {
  try {
    const { data } = yield call(axios.get, '/api/filer/deposits/undepositedTotal', {
      withCredentials: true,
    });
    const undepositedTotal = data;
    yield put({
      type: depositTypes.GET_UNDEPOSITED_TOTAL_SUCCESS,
      data: {
        undepositedTotal,
      },
    });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put(errorToast(error));
  }
}

export function* getUndepositedTotal() {
  yield takeLatest(depositTypes.GET_UNDEPOSITED_TOTAL, handleGetUndepositedTotal);
}

export function* handleGetDepositList() {
  try {
    yield put({ type: depositTypes.GET_DEPOSIT_LIST_PROCESSING });
    const { data: deposits } = yield call(axios.get, url, {
      withCredentials: true,
    });
    yield put({
      type: depositTypes.GET_DEPOSIT_LIST_SUCCESS,
      data: { deposits, undeposited: [] },
    });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put(errorToast(error));
  }
}

export function* getDepositList() {
  yield takeLatest(depositTypes.GET_DEPOSIT_LIST, handleGetDepositList);
}

export function* handleGetSingleDeposit(action) {
  try {
    const { id } = action.data;
    const { data: deposit } = yield call(axios.get, `${url}/${id}`, {
      withCredentials: true,
    });
    const { data } = yield call(axios.get, '/api/filer/deposits/undeposited', {
      withCredentials: true,
    });
    const undeposited = transformUndeposited(data);
    yield put({
      type: depositTypes.GET_SINGLE_DEPOSIT_SUCCESS,
      data: { deposit, undeposited },
    });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put(errorToast(error));
  }
}

export function* getSingleDeposit() {
  yield takeLatest(depositTypes.GET_SINGLE_DEPOSIT, handleGetSingleDeposit);
}

export function* handleCreateDeposit(action) {
  try {
    const { payload } = action.data;
    const { data: deposit } = yield call(axios.post, url, payload, {
      withCredentials: true,
    });
    yield put({ type: depositTypes.CREATE_DEPOSIT_SUCCESS, data: { deposit } });
    yield put({ type: depositTypes.GET_ALL_UNDEPOSITED });
    yield put(push('/filer/deposits'));
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put(errorToast(error));
  }
}

export function* createDeposit() {
  yield takeLatest(depositTypes.CREATE_DEPOSIT, handleCreateDeposit);
}

export function* handleUpdateDeposit(action) {
  try {
    const { payload, id } = action.data;
    yield call(axios.put, `${url}/${id}`, payload, { withCredentials: true });
    yield put({
      type: depositTypes.UPDATE_DEPOSIT_SUCCESS,
      data: { payload, _id: id },
    });
    yield put(successToast('Deposit updated!'));
    yield put({ type: depositTypes.GET_DEPOSIT_LIST });
    yield put(push('/filer/deposits'));
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put(errorToast(error));
  }
}

export function* updateDeposit() {
  yield takeLatest(depositTypes.UPDATE_DEPOSIT, handleUpdateDeposit);
}

export function* handleDeleteDeposit(action) {
  try {
    const { id } = action.data;
    yield call(axios.delete, `${url}/${id}`, { withCredentials: true });
    yield put({ type: depositTypes.DELETE_DEPOSIT_SUCCESS, data: { id } });
    yield put(successToast('Deposit deleted!'));
    yield put({ type: depositTypes.GET_DEPOSIT_LIST });
    yield put({ type: depositTypes.GET_UNDEPOSITED_TOTAL });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put(errorToast(error));
  }
}

export function* deleteDeposit() {
  yield takeLatest(depositTypes.DELETE_DEPOSIT, handleDeleteDeposit);
}

export function* handleGetDepositTicketInfo(action) {
  try {
    const { id } = action.data;
    const { data: deposit } = yield call(axios.get, `${url}/${id}`, {
      withCredentials: true,
    });
    yield put({
      type: depositTypes.GET_SINGLE_DEPOSIT_FOR_TICKET_SUCCESS,
      data: { deposit },
    });
    const { data: campaign } = yield call(
      axios.get,
      '/api/filer/currentCampaign',
      { withCredentials: true },
    );
    yield put({
      type: currentCampaignTypes.GET_CURRENT_CAMPAIGN_SUCCESS,
      campaign: new Campaign(campaign),
    });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put({
      type: depositTypes.GET_DEPOSIT_TICKET_INFO_ERROR,
      data: { error },
    });
  }
}

export function* getDepositTicketInfo() {
  yield takeLatest(
    depositTypes.GET_DEPOSIT_TICKET_INFO,
    handleGetDepositTicketInfo,
  );
}

export function* handleUploadDepositSpreadsheet(action) {
  try {
    const { data: spreadsheet } = action;

    yield put({ type: depositTypes.UPLOAD_DEPOSIT_SPREADSHEET_PROCESSING });
    const { data } = yield call(axios.post, '/api/filer/deposits/upload', spreadsheet, {
      withCredentials: true,
    });

    const channel = yield call(isAgendaJobComplete, 60 * 30, 2, data.taskId);
    const channelResult = yield take(channel);
    channel.close();
    if (channelResult.isFailed) {
      yield put({
        type: depositTypes.UPLOAD_DEPOSIT_SPREADSHEET_FAILURE,
        error: channelResult.failReason,
      });
    } else {
      yield put({
        type: depositTypes.UPLOAD_DEPOSIT_SPREADSHEET_SUCCESS,
        data: channelResult,
      });
    }
    yield put({ type: depositTypes.UPLOAD_DEPOSIT_SPREADSHEET_NOT_STARTED });
  } catch (e) {
    yield put({ type: depositTypes.UPLOAD_DEPOSIT_SPREADSHEET_FAILURE });
    const error = getServerSideErrorMessage(e);
    yield put(errorToast(error));
  }
}

export function* uploadDepositSpreadsheet() {
  yield takeLatest(depositTypes.UPLOAD_DEPOSIT_SPREADSHEET, handleUploadDepositSpreadsheet);
}
