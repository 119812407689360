import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { GridRow, Column, MoneyField } from '../../common';

const FECSection10 = ({ q10ColA }) => {
  return (
    <Fragment>
      <GridRow
        style={{
          borderTop: '1px solid #919191',
          borderBottom: '1px solid #919191',
        }}
      >
        <Column sm={4}>
          <p>
            10. Debts and Obligations owed <strong>BY</strong> the Committee
            <br />
            {'(Itemize all on Schedule C and/or Schedule D)'}
          </p>
        </Column>
        <Column sm={4} classNames="entry-row">
          <MoneyField
            disabled
            value={q10ColA || 0}
          />
        </Column>
      </GridRow>
    </Fragment>
  );
};

FECSection10.propTypes = {
  q10ColA: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default FECSection10;
