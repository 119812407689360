const emptyAddress = {
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: '',
  zipCode: '',
  county: '',
};

export function getContactInformation(state, expenditure) {
  expenditure.contactId = expenditure?.contactId || {};
  expenditure.contactId.address = expenditure?.contactId?.address || emptyAddress;
  const selectedContact = {
    ...state.selectedContact,
    ...expenditure.contactId,
    salutation: expenditure.contactId.salutation
      ? expenditure.contactId.salutation
      : 'none',
    businessName: !['IND', 'Individual', 'CAN'].includes(expenditure.contactType) ? expenditure?.contactId?.businessName : '',
    businessType: expenditure.businessType || 0,
    committeeAffiliation: expenditure.committeeAffiliation || 0,
    phone1: expenditure?.contactId?.phone1 ? expenditure.contactId.phone1 : '',
    phone2: expenditure?.contactId?.phone2 ? expenditure.contactId.phone2 : '',
    address: {
      ...expenditure?.contactId?.address,
      state: expenditure?.contactId?.address?.state || 0,
    },
    cardholderName: expenditure.cardholderName,
    cardholderEmployer: expenditure.cardholderEmployer,
    cardholderOccupation: expenditure.cardholderOccupation,
  };

  return selectedContact;
}

export const expenditureTypeMap = {
  'In-Kind': 'In Kind',
  'In Kind': 'In Kind',
  Monetary: 'Monetary',
  'Loan Repayment': 'Loan Repayment',
  'Credit Card': 'Credit Card',
  'Deferred Payment': 'Deferred Payment',
  'Payment on Deferred Expense': 'Payment on Deferred Expense',
  Refund: 'Refund',
  Reimbursement: 'Reimbursement',
  '3rd Party': '3rd Party',
  Allocated: 'Allocated',
  Contributions: 'Contributions',
  'Independent Expenditure (Schedule E)': 'Independent Expenditure (Schedule E)',
  'Coordinated Party Expenditure (Schedule F)': 'Coordinated Party Expenditure (Schedule F)',
  'Loan Made': 'Loan Made',
  Other: 'Other',
  'Federal Election Activity': 'Federal Election Activity',
};
