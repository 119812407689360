import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import {
  Dialog,
  DialogType,
  DialogFooter,
} from 'office-ui-fabric-react/lib/Dialog';
import {
  DefaultButton,
  PrimaryButton,
} from 'office-ui-fabric-react/lib/Button';

const ConfirmDeleteFromReportDialog = ({
  dialogHidden,
  cancel,
  confirm,
  itemType,
  message,
}) => (
  <Dialog
    hidden={dialogHidden}
    onDismiss={cancel}
    dialogContentProps={{
      type: DialogType.normal,
      title: (
        <span style={{ display: 'flex', alignItems: 'center' }}>
          <Icon
            iconName="TrashAlt"
            styles={{
              root: {
                backgroundColor: '#a80000',
                color: '#fff',
                borderRadius: '50%',
                height: 36,
                width: 36,
                marginRight: 16,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              },
            }}
          />
          <span
            style={{
              fontWeight: 'bold',
              fontSize: '18pt',
              padding: 0,
              margin: 0,
            }}
          >{`Delete ${itemType}?`}</span>
        </span>
      ),
      subText: message,
    }}
  >
    <DialogFooter>
      <DefaultButton
        text="No, Keep It"
        onClick={cancel}
        styles={{
          root: {
            marginRight: 16,
          },
        }}
      />
      <PrimaryButton
        text="Yes, Delete It"
        onClick={confirm}
        styles={{
          root: {
            backgroundColor: '#a80000',
            color: 'white',
          },
          rootHovered: {
            backgroundColor: '#fff',
            color: '#a80000',
            border: '1px solid #a80000',
          },
        }}
      />
    </DialogFooter>
  </Dialog>
);

ConfirmDeleteFromReportDialog.propTypes = {
  dialogHidden: PropTypes.bool.isRequired,
  cancel: PropTypes.func.isRequired,
  confirm: PropTypes.func.isRequired,
  itemType: PropTypes.string.isRequired,
  message: PropTypes.string,
};

ConfirmDeleteFromReportDialog.defaultProps = {
  message: 'Deleting an item associated to a report can make accurate reporting difficult or impossible.',
};

export default ConfirmDeleteFromReportDialog;
