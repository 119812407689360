import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Image } from 'office-ui-fabric-react/lib/Image';
import { Link } from 'office-ui-fabric-react/lib/Link';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import {
  MessageBar,
  MessageBarType,
} from 'office-ui-fabric-react/lib/MessageBar';
import { useSelector, useDispatch } from 'react-redux';
import { types as userActions } from '../../actions/userActions';
import { Grid, GridRow, Column, PasswordField } from '../../components/common';
import { Toast } from '../../components/Toast';
import { validate } from './LoginValidations';
import FRLogo from '../../assets/img/HRFrontRunner.png';
import './Login.css';

export const Login = ({ history }) => {
  const [state, setState] = useState({
    username: '',
    password: '',
    usernameError: '',
    passwordError: '',
  });

  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const messages = useSelector(state => state.messages);

  const checkRedirects = () => {
    if (user.isAuthenticated === true && !user.session.campaignId) {
      history.push('/createCampaign');
    } else if (user.isAuthenticated === true && user.session.campaignId) {
      history.push('/filer');
    }
  };

  useEffect(() => {
    dispatch({
      type: userActions.GET_CURRENT_USER,
    });
    checkRedirects();
  }, []);

  useEffect(() => {
    checkRedirects();
  }, [user.isAuthenticated, user.session]);

  const handleTextChange = fieldName => (e, value) => {
    setState({
      ...state,
      [fieldName]: value,
    });
  };

  const signIn = e => {
    e.preventDefault();
    const errors = validate(state);
    setState({
      ...state,
      ...errors,
    });

    if (Object.values(errors).every(e => e.length === 0)) {
      dispatch({
        type: userActions.USER_LOGIN,
        data: {
          username: state.username,
          password: state.password,
        },
      });
    }
  };

  const forgotPassword = () => {
    history.push('/forgotPassword');
  };

  const signUp = () => {
    history.push('/signup');
  };

  const { username, usernameError, password, passwordError } = state;

  return (
    <form className="Login depth-1" onSubmit={signIn} noValidate>
      <Grid>
        <GridRow>
          <Column md={6} classNames="ms-mdPush3 ms-lgPush3 ms-xlPush3 logo">
            <Image src={FRLogo} alt="FrontRunner" height={150} />
          </Column>
        </GridRow>
        <GridRow>
          {user.error && (
            <Column lg={8} classNames="ms-lgPush2 ms-xlPush2">
              <MessageBar messageBarType={MessageBarType.error}>
                {user.error}
              </MessageBar>
            </Column>
          )}
          <Column lg={8} classNames="ms-lgPush2 ms-xlPush2">
            <TextField
              label="Username:"
              underlined
              autoFocus
              required
              name="username"
              value={username}
              errorMessage={usernameError}
              onChange={handleTextChange('username')}
            />
          </Column>
          <Column lg={8} classNames="ms-lgPush2 ms-xlPush2">
            <PasswordField
              label="Password:"
              underlined
              autofocus
              required
              name="password"
              value={password}
              errorMessage={passwordError}
              onChange={handleTextChange('password')}
            />
          </Column>
        </GridRow>
        <GridRow>
          <Column classNames="ms-textAlignCenter">
            <PrimaryButton text="Login" type="submit" />
          </Column>
        </GridRow>
        <GridRow>
          <Column classNames="links ms-textAlignCenter">
            <Link onClick={forgotPassword}>Forgot Password</Link>
            <span>|</span>
            <Link className="signup" onClick={signUp}>
              Sign Up
            </Link>
            <span>|</span>
            <Link href="//tryfrontrunner.com" target="_blank">
              More Info
            </Link>
          </Column>
        </GridRow>
      </Grid>
      {messages.message && (
        <Toast
          message={messages.message}
          toastType={messages.toastType}
          position={messages.position}
        />
      )}
    </form>
  );
};

Login.displayName = 'Login';

Login.propTypes = {
  history: PropTypes.object.isRequired,
};

export default Login;
