import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { NormalPeoplePicker } from 'office-ui-fabric-react/lib/Pickers';
import { Label } from 'office-ui-fabric-react/lib/Label';
import { Persona } from 'office-ui-fabric-react/lib/Persona';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import {
  ActionButton,
  DefaultButton,
  PrimaryButton,
} from 'office-ui-fabric-react/lib/Button';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { Grid, GridRow, Column, ZipCodeField } from '../common';
import { StatePicker } from '../Pickers';
import './DepositoryAccountPicker.css';

const addressStyle = { marginTop: 0, marginBottom: 2 };

const DepositoryAccountPicker = ({
  handleSelectExistingDepositoryAccount,
  selectedDepositoryAccount,
  clearSelectedDepositoryAccount,
  handleDepositoryAccountChange,
  handleSaveDepositoryAccount,
  resetDepositoryAccount,
  setSaveButtonDisabled,
  errors,
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const [accountCopy, setAccountCopy] = useState(null);

  useEffect(() => {
    if (!isEdit) {
      setAccountCopy({ ...selectedDepositoryAccount });
    }
  }, [isEdit]);

  const onRenderSuggestionsItem = persona => {
    const address = `${persona.addressLine1 || ''}${
      persona.addressLine2 ? ' ' + persona.addressLine2 : ''
    } ${persona.city}, ${persona.state} ${persona.zipCode}`;

    return (
      <Persona text={persona.location} secondaryText={address} coinSize={48} />
    );
  };

  const searchDepositoryAccounts = async filterText => {
    const { data: banks } = await axios.get(
      `/api/filer/depositoryAccounts?name=${filterText}`,
      {
        withCredentials: true,
      },
    );
    return banks;
  };

  const onResolveSuggestions = filterText => {
    if (filterText && filterText.length > 1) {
      return searchDepositoryAccounts(filterText);
    }
    return [];
  };

  const onItemSelected = contact => {
    handleSelectExistingDepositoryAccount(contact);
    return null;
  };

  const enableEdit = () => {
    setSaveButtonDisabled(true);
    setIsEdit(true);
  };

  const cancelEdit = () => {
    if (!selectedDepositoryAccount._id) {
      clearSelectedDepositoryAccount();
    } else {
      resetDepositoryAccount(accountCopy);
    }
    setIsEdit(false);
    setSaveButtonDisabled(false);
  };

  const onSave = () => {
    const callback = () => {
      setIsEdit(false);
      setSaveButtonDisabled(false);
    };

    handleSaveDepositoryAccount(callback);
  };

  return (
    <GridRow>
      <Column>
        {!selectedDepositoryAccount._id && !isEdit && (
          <Fragment>
            <Label>Search Existing Depository Accounts By Name</Label>
            <NormalPeoplePicker
              itemLimit={1}
              onResolveSuggestions={onResolveSuggestions}
              onRenderSuggestionsItem={onRenderSuggestionsItem}
              onItemSelected={onItemSelected}
            />
            <div className="create-new-depository-account-wrapper">
              <ActionButton
                text="Add New Depository Account"
                onClick={enableEdit}
                iconProps={{
                  iconName: 'PlusCircle',
                }}
              />
            </div>
          </Fragment>
        )}
        {selectedDepositoryAccount._id && !isEdit && (
          <div className="selected-depository-account">
            <Icon
              iconName="Building"
              styles={{
                root: {
                  fontSize: '16pt',
                  backgroundColor: 'rgb(0, 120, 212)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: 36,
                  height: 36,
                  color: '#fff',
                  borderRadius: '50%',
                },
              }}
            />
            <h4>{selectedDepositoryAccount.location}</h4>
            <p style={addressStyle}>{selectedDepositoryAccount.addressLine1}</p>
            {selectedDepositoryAccount.addressLine2 && (
              <p style={addressStyle}>
                {selectedDepositoryAccount.addressLine2}
              </p>
            )}
            <p style={addressStyle}>
              {`${
                selectedDepositoryAccount.city
                  ? selectedDepositoryAccount.city + ', '
                  : ''
              }${
                selectedDepositoryAccount.state
                  ? selectedDepositoryAccount.state + ' '
                  : ''
              }${selectedDepositoryAccount.zipCode || ''}`}
            </p>
            <div className="selected-depository-account-actions">
              <ActionButton
                text="Clear Selected Depository Account"
                onClick={clearSelectedDepositoryAccount}
                iconProps={{
                  iconName: 'MinusCircle',
                  styles: {
                    root: {
                      color: '#a80000',
                    },
                  },
                }}
              />
              <ActionButton
                text="Edit Selected Depository Account"
                onClick={enableEdit}
                iconProps={{
                  iconName: 'PencilAlt',
                }}
              />
            </div>
          </div>
        )}
        {isEdit && (
          <Fragment>
            <div className="edit-selected-depository-account-form">
              <Grid>
                <GridRow>
                  <Column>
                    <TextField
                      value={selectedDepositoryAccount.location}
                      label="Location of Account"
                      onChange={handleDepositoryAccountChange('location')}
                      required
                      errorMessage={errors.depositoryAccountLocationError}
                    />
                  </Column>
                </GridRow>
                <GridRow>
                  <Column>
                    <TextField
                      label="Address 1"
                      value={selectedDepositoryAccount.addressLine1}
                      onChange={handleDepositoryAccountChange('addressLine1')}
                      required
                      errorMessage={errors.depositoryAccountAddressLine1Error}
                    />
                    <TextField
                      label="Address 2"
                      value={selectedDepositoryAccount.addressLine2}
                      onChange={handleDepositoryAccountChange('addressLine2')}
                    />
                    <Grid>
                      <GridRow>
                        <Column md={5}>
                          <TextField
                            label="City"
                            required
                            value={selectedDepositoryAccount.city}
                            onChange={handleDepositoryAccountChange('city')}
                            errorMessage={errors.depositoryAccountCityError}
                          />
                        </Column>
                        <Column md={4}>
                          <StatePicker
                            selectedKey={selectedDepositoryAccount.state}
                            onChange={handleDepositoryAccountChange('state')}
                            errorMessage={errors.depositoryAccountStateError}
                            required
                          />
                        </Column>
                        <Column md={3}>
                          <ZipCodeField
                            value={selectedDepositoryAccount.zipCode}
                            required
                            onChange={handleDepositoryAccountChange('zipCode')}
                            errorMessage={errors.depositoryAccountZipCodeError}
                          />
                        </Column>
                      </GridRow>
                    </Grid>
                  </Column>
                </GridRow>
              </Grid>
            </div>
            <div className="edit-selected-depository-account-actions">
              <DefaultButton
                text="Cancel"
                onClick={cancelEdit}
                style={{ marginRight: 16 }}
              />
              <PrimaryButton text="Save" onClick={onSave} />
            </div>
          </Fragment>
        )}
      </Column>
    </GridRow>
  );
};

DepositoryAccountPicker.propTypes = {
  banks: PropTypes.array.isRequired,
  handleSelectExistingDepositoryAccount: PropTypes.func.isRequired,
  clearSelectedDepositoryAccount: PropTypes.func.isRequired,
  handleDepositoryAccountChange: PropTypes.func.isRequired,
  handleSaveDepositoryAccount: PropTypes.func.isRequired,
  resetDepositoryAccount: PropTypes.func.isRequired,
  setSaveButtonDisabled: PropTypes.func.isRequired,
  selectedDepositoryAccount: PropTypes.shape({
    _id: PropTypes.string,
    location: PropTypes.string,
    addressLine1: PropTypes.string,
    addressLine2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    zipCode: PropTypes.string,
    establishedDate: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string,
      PropTypes.number,
    ]),
  }).isRequired,
  errors: PropTypes.object,
};

export default DepositoryAccountPicker;
