import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'office-ui-fabric-react/lib/Dropdown';
import { businessTypes } from '../../helpers/constants';
import { CaretDown } from '../icons';

export const BusinessTypePicker = ({
  selectedKey,
  onChange,
  required,
  errorMessage,
  className,
  style,
  label,
  options,
}) => (
  <Dropdown
    onRenderCaretDown={() => <CaretDown />}
    label={label}
    errorMessage={errorMessage}
    required={required}
    onChange={onChange}
    className={className}
    style={style}
    selectedKey={selectedKey}
    options={[
      {
        key: 0,
        text: 'Select',
      },
      ...(options && options.length ? options : businessTypes).map(bt => ({
        key: bt.key !== undefined ? bt.key : bt,
        text: bt.text !== undefined ? bt.text : bt,
      })),
    ]}
  />
);

BusinessTypePicker.propTypes = {
  selectedKey: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.number,
  ]),
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  errorMessage: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  label: PropTypes.string,
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.object),
  ]),
};

BusinessTypePicker.defaultProps = {
  selectedKey: { key: 0 },
  required: false,
  errorMessage: '',
  className: '',
  style: {},
  label: 'Business Type',
};

export default BusinessTypePicker;
