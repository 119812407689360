import React from 'react';
import PropTypes from 'prop-types';
import { ComboBox } from 'office-ui-fabric-react/lib/ComboBox';
import { states, fecStates } from '../../helpers/constants';

const StatePicker = ({
  showIndexOption,
  indexKey,
  indexLabel,
  fieldLabel,
  allowFreeForm,
  allowAutoComplete,
  selectedKey,
  className,
  style,
  required,
  onChange,
  errorMessage,
  placeholder,
  session,
  disabled,
}) => {
  let stateValues;
  if (session && session.isFederal()) {
    stateValues = fecStates.map(s => ({
      key: s.abbr,
      text: `${s.name} (${s.abbr})`,
    }));
  } else {
    stateValues = states.map(s => ({
      key: s.abbr,
      text: s.name,
    }));
  }

  const stateOptions = showIndexOption
    ? [{ key: indexKey, text: indexLabel }, ...stateValues]
    : stateValues;

  return (
    <ComboBox
      buttonIconProps={{
        iconName: 'CaretDown',
      }}
      className={className}
      style={style}
      label={fieldLabel}
      placeholder={placeholder}
      allowFreeForm={allowFreeForm}
      autoComplete={allowAutoComplete ? 'on' : 'off'}
      options={stateOptions}
      required={required}
      onChange={onChange}
      selectedKey={selectedKey}
      errorMessage={errorMessage}
      disabled={disabled}
    />
  );
};

StatePicker.propTypes = {
  showIndexOption: PropTypes.bool,
  indexLabel: PropTypes.string,
  indexKey: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  fieldLabel: PropTypes.string,
  allowFreeForm: PropTypes.bool,
  disabled: PropTypes.bool,
  allowAutoComplete: PropTypes.bool,
  selectedKey: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.object,
  ]),
  className: PropTypes.string,
  style: PropTypes.object,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  errorMessage: PropTypes.string,
  placeholder: PropTypes.string,
  session: PropTypes.object,
};

StatePicker.defaultProps = {
  indexLabel: 'Select',
  indexKey: 0,
  fieldLabel: 'State',
  allowFreeForm: false,
  allowAutoComplete: true,
  selectedKey: 0,
  className: '',
  disabled: false,
  style: {},
  required: false,
  showIndexOption: true,
  errorMessage: '',
  placeholder: '',
  session: null,
};

export default StatePicker;
