import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Dropdown } from 'office-ui-fabric-react/lib/Dropdown';
import {
  TooltipHost,
  TooltipDelay,
  DirectionalHint,
} from 'office-ui-fabric-react/lib/Tooltip';
import { CaretDown } from '../icons';
import {
  disbursementCategories as nonPresidentialDisbursementCategories,
  presidentialDisbursementCategories,
  iowaDisbursementCategories,
} from '../../helpers/constants';

import { getCampaign } from '../../selectors';
import { isPresidentialCampaign } from '../../helpers/campaignHelper';

const getDisbursementCategories = (campaign) => {
  if (campaign.officeState === 'Iowa') {
    return iowaDisbursementCategories;
  }
  return isPresidentialCampaign(campaign) ? presidentialDisbursementCategories : nonPresidentialDisbursementCategories;
};

const DisbursementCategoryPicker = ({
  handleChange,
  disbursementCategory = 0,
  errorMessage = '',
  isIndependentExpenditure = false,
}) => {
  const campaign = useSelector(getCampaign);
  const disbursementCategories = getDisbursementCategories(campaign);

  const onRenderOption = option => (
    <TooltipHost
      delay={TooltipDelay.zero}
      id={option.key}
      calloutProps={{ gapSpace: 20 }}
      directionalHint={DirectionalHint.rightCenter}
      styles={{
        root: {
          width: '100%',
        },
      }}
      tooltipProps={{
        onRenderContent: () => {
          if (option.key === 0) {
            return null;
          }
          const items = disbursementCategories.find(
            dc => dc.key === option.key,
          );
          return (
            <div
              style={{
                padding: '0 8px',
                width: '100%',
              }}
            >
              <p
                style={{ margin: 0 }}
              >{`Valid Expenses for ${option.text}:`}</p>
              <ul>
                {items.included.map(type => (
                  <li key={type} style={{ fontWeight: 400 }}>
                    {type}
                  </li>
                ))}
              </ul>
            </div>
          );
        },
      }}
    >
      <div title="" style={{ width: '100%' }}>
        {option.text}
      </div>
    </TooltipHost>
  );

  const getOptions = () => {
    const categories = [...disbursementCategories].filter(dc => {
      return isIndependentExpenditure ? dc.key <= 10 : true;
    });

    return [
      { key: 0, text: 'Select' },
      ...categories.map(dc => ({
        key: dc.key,
        text: dc.text,
      })),
    ];
  };

  return (
    <Dropdown
      onRenderCaretDown={() => <CaretDown />}
      label="Disbursement Category"
      selectedKey={disbursementCategory}
      required
      placeholder="Select"
      errorMessage={errorMessage}
      onRenderOption={onRenderOption}
      options={getOptions()}
      onChange={handleChange}
    />
  );
};

DisbursementCategoryPicker.propTypes = {
  handleChange: PropTypes.func.isRequired,
  isIndependentExpenditure: PropTypes.bool,
  disbursementCategory: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  errorMessage: PropTypes.string,
  options: PropTypes.array,
};

export default DisbursementCategoryPicker;
