import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { GridRow, Column, MoneyField } from '../../common';
import { getLoanTotals } from '../../../pages/Reports/fecReportHelper';

const FECSection13c = ({ state }) => {
  return (
    <Fragment>
      <GridRow>
        <Column sm={4} classNames="sub-question">
          <p>
            (c) TOTAL LOANS
            <br />
            {'(add Lines 13(a) and (b))'}
          </p>
        </Column>
        <Column sm={4} classNames="entry-row">
          <MoneyField value={getLoanTotals('A', state) || 0} disabled />
        </Column>
        <Column sm={4} classNames="entry-row">
          <MoneyField value={getLoanTotals('B', state) || 0} disabled />
        </Column>
      </GridRow>
    </Fragment>
  );
};

FECSection13c.propTypes = {
  state: PropTypes.object.isRequired,
};

export default FECSection13c;
