import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'office-ui-fabric-react/lib/Link';
import {
  ActionButton,
  DefaultButton,
  PrimaryButton,
} from 'office-ui-fabric-react/lib/Button';
import { EmptyView } from '../EmptyView';
import LoansList from '../LoansList';
import { Grid, GridRow, Column } from '../common';
import { getLabel } from '../../helpers/labelHelper';
import { isForm6Form24Report as determineIfForm6_24 } from '../../helpers/reportHelper';
import { dateBetweenOrEqualTo, deserializeDate } from '../../helpers/util';

const ItemizedLoans = ({
  state,
  toggleExpandSection,
  sectionName,
  addNewLoan,
  editLoan,
  items,
  session,
}) => {
  const isForm6Form24Report = determineIfForm6_24(state.reportType);

  const filterFn = isForm6Form24Report ? e => {
    if (e.amount < 1000.0) {
      return false;
    }
    if (e.isItemFiled === true) {
      return false;
    }
    if (e.businessType === 'Bank/ Lender') {
      return false;
    }
    const loanDate = deserializeDate(e.loanDate);
    return dateBetweenOrEqualTo(loanDate, state.startDate, state.endDate);
  } : null;
  const renderList = () =>
    items.length === 0 ? (
      <EmptyView
        message={`There are no ${getLabel(
          'loans',
          session,
        )} this reporting period.`}
      />
    ) : (
      <LoansList
        filterFn={filterFn}
        editItem={editLoan}
        loans={{
          loans: [...items],
        }}
        session={session}
        isHidden={state.expandableSections[sectionName] === false}
      />
    );

  return (
    <div
      className="ItemizedLoans fec-report-section depth-1"
      name={sectionName}
    >
      <h3>
        LOANS{' '}
        <small>
          {' '}
          (
          <Link onClick={() => toggleExpandSection(sectionName, false)}>
            {state.expandableSections[sectionName] === true
              ? 'Collapse'
              : 'Expand'}
          </Link>
          )
        </small>
      </h3>
      <div
        className={`fec-itemized-loans${
          state.expandableSections[sectionName] === true
            ? '-expanded'
            : '-collapsed'
        }`}
      >
        <Grid>
          <GridRow>
            <Column classNames="ms-textAlignRight">
              <ActionButton
                text="Add New Loan"
                iconProps={{
                  iconName: 'PlusCircle',
                  styles: {
                    root: {
                      fontSize: '1.3em',
                    },
                  },
                }}
                onClick={addNewLoan}
              />
            </Column>
          </GridRow>
          {renderList()}
        </Grid>
        <div className="section-actions">
          {!isForm6Form24Report &&
          <>
            <DefaultButton
              text="Previous Section"
              style={{ marginRight: 16 }}
              onClick={() => toggleExpandSection('itemizedDisbursements', false)}
            />
            <PrimaryButton
              text="Save & Continue"
              onClick={() => toggleExpandSection('debts', true)}
            />
          </>
          }
        </div>
      </div>
    </div>
  );
};

ItemizedLoans.propTypes = {
  state: PropTypes.object.isRequired,
  toggleExpandSection: PropTypes.func.isRequired,
  sectionName: PropTypes.string,
  addNewLoan: PropTypes.func.isRequired,
  editLoan: PropTypes.func.isRequired,
  items: PropTypes.array,
  session: PropTypes.object.isRequired,
};

ItemizedLoans.defaultProps = {
  items: [],
};

export default ItemizedLoans;
