export const expenditureBaseInitialState = {
  transactionIdNumber: '',
  allocatedActivityOrEventType: 0,
  frCampaignType: null,
  activityOrEventIdentifier: '',
  isAllocated: false,
  federalShare: '',
  nonFederalShare: '',
  selectedRecipient: null,
  reportIdRedirect: null,
  fec: false,
  committeeFecId: '',
  electionYear: 0,
  election: 0,
  otherElectionType: '',
  expenditureType: 0,
  disbursementCategory: '000',
  contactType: 0,
  isCandidate: false,
  isRedesignation: false,
  paymentType: 0,
  paymentCode: '',
  paymentCodeOther: '',
  selectedContact: null,
  amount: '',
  datePaid: '',
  referenceId: '',
  checkNumber: '',
  purpose: '',
  cardLast4: '',
  cardType: 0,
  forceItemization: false,
  isReportable: false,
  tags: '',
  endRecipients: [],
  addRecipientDialogHidden: true,
  salutation: 'none',
  firstName: '',
  middleName: '',
  lastName: '',
  suffix: '',
  supportOpposeCode: 'S',
  disseminationDate: '',
  completingFirstName: '',
  completingLastName: '',
  completingDateSigned: '',
  employer: '',
  occupation: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: 0,
  zipCode: '',
  county: '',
  phone1: '',
  phone2: '',
  email: '',
  businessName: '',
  businessType: 0,
  committeeAffiliation: 0,
  contactName: '',
  cardholderName: '',
  cardholderEmployer: '',
  cardholderOccupation: '',
  isContributionProcessingFee: false,
  conduit: null,
  supportOppose: null,
  memoCode: false,
  memoText: '',
  candidateOffice: '',
  candidateState: 0,
  candidateDistrict: '',
  candidateFecId: '',
  candidateFirstName: '',
  candidateMiddleName: '',
  candidateLastName: '',
  reconciliationId: null,
  isItemFiled: false,
  depositId: null,
  confirmContinueUpdateHidden: true,
  addNew: false,
  continueEditMessageList: [],
  refundContributionId: '0',
  loanId: '0',
  contributionOfficeSought: 0,
  contributionOfficeState: 0,
  contributionDistrict: '',
  contributionElectionYear: 0,
  contributionElection: 0,
  contributionOtherElectionType: '',
  budgetCategoryId: '',
  budgetCategories: [],
  trustee: '',
  trustor: '',
  isLivingOrRevokableTrust: 'Y',
  relationshipToCandidate: '',
};

export const errors = {
  allocatedActivityOrEventTypeError: '',
  electionYearError: '',
  electionError: '',
  emailErrorMessage: '',
  otherElectionTypeError: '',
  expenditureTypeError: '',
  disbursementCategoryError: '',
  refundContributionErrorMessage: '',
  loanErrorMessage: '',
  recipientTypeError: '',
  paymentTypeError: '',
  amountErrorMessage: '',
  datePaidErrorMessage: '',
  firstNameErrorMessage: '',
  lastNameErrorMessage: '',
  businessNameError: '',
  creditCardCompanyError: '',
  selectedContactError: '',
  fecIdError: '',
  activityOrEventIdentifierError: '',
  federalShareError: '',
  nonFederalShareError: '',
  contributionOfficeSoughtError: '',
  contributionOfficeStateError: '',
  contributionDistrictError: '',
  contributionElectionYearError: '',
  contributionElectionError: '',
  contributionOtherElectionTypeError: '',
};
