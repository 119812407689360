import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { GridRow, Column, MoneyField } from '../../common';

const FECSection21 = ({ q21ColA, q21ColB }) => {
  return (
    <Fragment>
      <GridRow style={{ borderTop: '1px solid #919191', paddingBottom: 6 }}>
        <Column sm={4}>
          <p>21. OTHER DISBURSEMENTS</p>
        </Column>
        <Column sm={4} classNames="entry-row">
          <MoneyField
            disabled
            value={q21ColA || 0}
          />
        </Column>
        <Column sm={4} classNames="entry-row">
          <MoneyField value={q21ColB || 0} disabled />
        </Column>
      </GridRow>
    </Fragment>
  );
};

FECSection21.propTypes = {
  q21ColA: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  q21ColB: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default FECSection21;
