import React, { useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import { EmptyView } from '../../components/EmptyView';
import LoansList from '../../components/LoansList';
import { types as loanActions } from '../../actions/loanActions';
import { statuses } from '../../helpers/constants';
import Loading from '../../components/Loading';
import { BackButton } from '../../components/common';
import { scrollToTop } from '../../helpers/util';
import './ViewAll.css';

export const ViewAllLoans = ({ history }) => {
  const loans = useSelector(state => state.loans);
  const session = useSelector(state => state.user.session);
  const reduxDispatch = useDispatch();

  useEffect(() => {
    scrollToTop();
    reduxDispatch({
      type: loanActions.GET_LOANS,
    });
  }, []);

  const edit = id => {
    history.push(`/filer/editLoan/${id}`);
  };

  if (loans.getLoansStatus === statuses.PROCESSING) {
    return <Loading />;
  }

  return (
    <Fragment>
      <BackButton history={history} />
      <div className="ViewAllLoans depth-1">
        <header className="loans-header">
          <h3>Loans</h3>
          <PrimaryButton
            text="Add"
            iconProps={{
              iconName: 'PlusCircle',
            }}
            onClick={() => history.push('/filer/addLoan')}
          />
        </header>
        {loans.getLoansStatus === statuses.SUCCESS &&
          loans.loans.length === 0 && (
            <EmptyView message="There are no loans" />
          )}
        {loans.getLoansStatus === statuses.SUCCESS && loans.loans.length > 0 && (
          <div className="view-all-loans-content">
            <LoansList editItem={edit} loans={loans} session={session} />
          </div>
        )}
      </div>
    </Fragment>
  );
};

ViewAllLoans.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(ViewAllLoans);
