import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogType,
  DialogFooter,
} from 'office-ui-fabric-react/lib/Dialog';
import {
  DefaultButton,
  PrimaryButton,
} from 'office-ui-fabric-react/lib/Button';
import { Checkbox } from 'office-ui-fabric-react';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import './SubmitReportDialog.css';

const SubmitReportDialog = ({
  dialogHidden,
  onCancel,
  onConfirm,
  onConfirmButtonText,
  isSubmittingFile,
  fecFilingWarning,
  isFecSubmit,
  submitWithWarnings,
  submitError,
  handleSubmitWithWarningsClick,
}) => (
  <Dialog
    hidden={dialogHidden}
    onDismiss={onCancel}
    dialogContentProps={{
      type: DialogType.normal,
      title: fecFilingWarning ? '' : 'Are You Sure?',
      subText: '',
    }}
    modalProps={{
      isBlocking: true,
      containerClassName: 'SubmitReportDialog',
    }}
  >
    {!fecFilingWarning &&
    <div>
      <div style={{ paddingBottom: 16 }}>
        <i>
          I hearby swear and affirm that I have examined this report and attest
          that the information herein is complete, true and correct.
        </i>
        {isFecSubmit &&
        <Checkbox
          className="fec-with-warnings-checkbox"
          checked={submitWithWarnings}
          label="Allow submit with warnings"
          onChange={handleSubmitWithWarningsClick}
          checkmarkIconProps={{
            iconName: 'Check',
          }}
        />
        }
      </div>
      <div className="error-message">
        {submitWithWarnings === true &&
        <span>It is recommended to correct any warnings using [Verify with FEC] before filing with FEC. </span>
        }
        This operation cannot be undone. Once filed, reports may only be amended.
      </div>
      {isSubmittingFile &&
        <div className="submit-spinner">
          <Spinner className="file-submit-spinner" size={SpinnerSize.medium} />
        </div>
      }

    </div>
    }
    {fecFilingWarning &&
    <div className="error-message">
      {fecFilingWarning}
    </div>
    }
    {submitError &&
    <div className="error-message" style={{ marginTop: '16px' }}>
      {submitError}
    </div>
    }
    <DialogFooter>
      <DefaultButton disabled={isSubmittingFile} text="Cancel" onClick={onCancel} />
      <PrimaryButton disabled={isSubmittingFile || fecFilingWarning || submitError} text={onConfirmButtonText} onClick={onConfirm} />
    </DialogFooter>
  </Dialog>
);

SubmitReportDialog.propTypes = {
  dialogHidden: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onConfirmButtonText: PropTypes.string,
  isSubmittingFile: PropTypes.bool,
  fecFilingWarning: PropTypes.string,
  isFecSubmit: PropTypes.bool,
  submitWithWarnings: PropTypes.bool,
  submitError: PropTypes.string,
  handleSubmitWithWarningsClick: PropTypes.func,
};

SubmitReportDialog.defaultProps = {
  dialogHidden: true,
  onConfirmButtonText: 'Yes, File With State',
  isSubmittingFile: false,
  fecFilingWarning: '',
  isFecSubmit: false,
  submitWithWarnings: false,
  submitError: '',
  handleSubmitWithWarningsClick: () => {},
};

export default SubmitReportDialog;
