const labelMap = {
  Federal: {
    expenditure: 'disbursement',
    expenditures: 'disbursements',
    Expenditure: 'Disbursement',
    Expenditures: 'Disbursements',
    EXPENDITURE: 'DISBURSEMENT',
    EXPENDITURES: 'DISBURSEMENTS',
    contribution: 'receipt',
    contributions: 'receipts',
    Contribution: 'Receipt',
    Contributions: 'Receipts',
    CONTRIBUTION: 'RECEIPT',
    CONTRIBUTIONS: 'RECEIPTS',
    'Filer ID': 'FEC Campaign Committee Id',
    'Filer PIN': 'Filing Password',
    A: 'Attorney General',
    H: 'United States House of Representatives',
    S: 'United States Senate',
    P: 'President',
    Chairperson: 'Custodian of Records',
    'Address 1': 'Mailing Address 1',
    'Address 2': 'Mailing Address 2',
    'Address Line 1': 'Mailing Address 1',
    'Address Line 2': 'Mailing Address 2',
    'Add Responsible Party': 'Add Endorsers or Guarantors to Loan Source',
    'Edit Responsible Party': 'Edit Endorser or Guarantor to Loan Source',
    'Responsible Parties': 'Endorsers / Guarantors',
    'Loan Date': 'Date Incurred or Established',
    'Loan date': 'Date incurred or established',
    'File With State': 'File With FEC',
  },
  State: {
    Alabama: {},
    Alaska: {},
    Arizona: {},
    Arkansas: {},
    California: {},
    Colorado: {},
    Connecticut: {},
    Delaware: {},
    'District of Columbia': {},
    Florida: {},
    Georgia: {},
    Hawaii: {},
    Idaho: {},
    Illinois: {},
    Indiana: {},
    Iowa: {},
    Kansas: {},
    Kentucky: {},
    Lousiana: {},
    Maine: {},
    Maryland: {},
    Massachusetts: {},
    Michigan: {},
    Minnesota: {},
    Mississippi: {},
    Missouri: {},
    Montana: {},
    Nebraska: {},
    Nevada: {},
    'New Hampshire': {},
    'New Jersey': {},
    'New Mexico': {},
    'New York': {},
    'North Carolina': {},
    'North Dakota': {},
    Ohio: {},
    Oklahoma: {},
    Oregon: {},
    Pennsylvania: {},
    'Rhode Island': {},
    'South Carolina': {},
    'South Dakota': {},
    Tennessee: {},
    Texas: {},
    Utah: {},
    Vermont: {},
    Virginia: {},
    Washington: {},
    'West Virginia': {},
    Wisconsin: {},
    Wyoming: {},
  },
  Local: {
    Alabama: {},
    Alaska: {},
    Arizona: {},
    Arkansas: {},
    California: {},
    Colorado: {},
    Connecticut: {},
    Delaware: {},
    'District of Columbia': {},
    Florida: {},
    Georgia: {},
    Hawaii: {},
    Idaho: {},
    Illinois: {},
    Indiana: {},
    Iowa: {},
    Kansas: {},
    Kentucky: {},
    Lousiana: {},
    Maine: {},
    Maryland: {},
    Massachusetts: {},
    Michigan: {},
    Minnesota: {},
    Mississippi: {},
    Missouri: {},
    Montana: {},
    Nebraska: {},
    Nevada: {},
    'New Hampshire': {},
    'New Jersey': {},
    'New Mexico': {},
    'New York': {},
    'North Carolina': {},
    'North Dakota': {},
    Ohio: {},
    Oklahoma: {},
    Oregon: {},
    Pennsylvania: {},
    'Rhode Island': {},
    'South Carolina': {},
    'South Dakota': {},
    Tennessee: {},
    Texas: {},
    Utah: {},
    Vermont: {},
    Virginia: {},
    Washington: {},
    'West Virginia': {},
    Wisconsin: {},
    Wyoming: {},
  },
};

export const getLabel = (label, session) => {
  if (
    session &&
    session.officeType &&
    labelMap[session.officeType] &&
    labelMap[session.officeType][label]
  ) {
    return labelMap[session.officeType][label];
  }

  if (
    session &&
    session.officeType &&
    session.officeState &&
    labelMap[session.officeType] &&
    labelMap[session.officeType][session.officeState] &&
    labelMap[session.officeType][session.officeState][label]
  ) {
    return labelMap[session.officeType][session.officeState][label];
  }

  return label;
};

export const businessNameLabel = type => {
  if (
    ['CCM', 'COM', 'PAC', 'Committee', 'Candidate Committee'].includes(type)
  ) {
    return 'Committee Name';
  }

  if (['PTY', 'ORG'].includes(type)) {
    return 'Organization Name';
  }

  return 'Business Name';
};

export const convertItemUpdateErrorsToMessageList = ({
  itemName,
  isBadItemDate = false,
  isItemFiled = false,
  reconciliationId = false,
  depositId = false,
}) => {
  const badDateMsg = isBadItemDate ? `This ${itemName} date is earlier than the last filing.` : '';
  const filedMsg = isItemFiled ? `This ${itemName} has been filed.` : '';
  const reconciledMsg = reconciliationId ? `This ${itemName} has been reconciled.` : '';
  const depositedMsg = depositId ? `This ${itemName} has been deposited.` : '';
  return [badDateMsg, filedMsg, reconciledMsg, depositedMsg].filter(Boolean);
};

export const itemUpdateWarning = 'Changing this data affects the filed regulatory reports and balance totals. ' +
  'This is usually done when amending previously filed reports and/or manually addressing any balance issues.';
