import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
  SearchBox,
  Checkbox,
  DetailsList,
  SelectionMode,
  CheckboxVisibility,
  DetailsRow,
  Spinner,
  SpinnerSize,
  DefaultButton,
} from 'office-ui-fabric-react';
import {
  TooltipHost,
} from 'office-ui-fabric-react/lib/Tooltip';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { types as messagingTypes } from '../../actions/messagingActions';
import { sortDirections, statuses, toastTypes } from '../../helpers/constants';
import { useIntersect } from '../../hooks/useIntersect';
import { ExpenditurePanel } from '../Panels';
import { EmptyView } from '../EmptyView';
import {
  formatCurrency,
  formatDate,
  formatDateTime,
  getServerSideErrorMessage,
} from '../../helpers/util';
import { getLabel } from '../../helpers/labelHelper';
import ScrollObserver from '../ScrollObserver';
import './index.scss';
import { DatePicker } from '../Pickers';

import { getNextPageStatusExpenditures } from '../../selectors';

export const ObservableExpendituresList = ({
  editItem,
  expenditures,
  session,
  isHidden,
  filterText = '',
  startDate,
  endDate,
  checkForNoOccupationOrEmployer,
  actions = { handleChange: () => {}, setSort: () => {}, nextPage: () => {} },
  getExpendituresStatus = statuses.NOT_STARTED,
  sortField = 'updatedAt',
  sortDirection = sortDirections.DESC,
}) => {
  const reduxDispatch = useDispatch();
  const getNextPageStatus = useSelector(getNextPageStatusExpenditures);

  if (isHidden) {
    return null;
  }

  const [scrollRef, entry] = useIntersect({
    rootMargin: '80px',
    threshold: 0.3,
  });

  useEffect(() => {
    if (entry.isIntersecting) {
      actions.nextPage();
    }
  }, [entry]);

  const [state, setState] = useState({
    showPanel: false,
    panelItem: null,
  });

  const onColumnClick = (e, column) => {
    const field = column.fieldName;
    let direction;
    if (sortField === column.fieldName) {
      if (sortDirection === sortDirections.ASC) {
        direction = sortDirections.DESC;
      } else if (sortDirection === sortDirections.DESC) {
        direction = sortDirections.NONE;
      } else {
        direction = sortDirections.ASC;
      }
    } else {
      direction = sortDirections.ASC;
    }
    actions.setSort({ sortField: field, sortDirection: direction });
  };

  useEffect(() => {
    setState({
      ...state,
      showPanel: state.panelItem !== null,
    });
  }, [state.panelItem]);

  const showPanel = async item => {
    try {
      const { data } = await axios.get(`/api/filer/expenditures/${item._id}`, {
        withCredentials: true,
      });

      if (data) {
        setState({
          ...state,
          panelItem: data,
        });
      }
    } catch (e) {
      const error = getServerSideErrorMessage(e);
      reduxDispatch({
        type: messagingTypes.SET_TOAST,
        data: {
          message: error,
          toastType: toastTypes.ERROR,
        },
      });
    }
  };

  const hidePanel = () => {
    setState({
      ...state,
      panelItem: null,
    });
  };

  const rowIconStyle = {
    root: {
      color: 'rgb(168, 168, 168)',
      fontSize: 16,
      marginRight: 8,
    },
  };

  const getIsFiled = () => {
    return (
      <TooltipHost
        content="Filed"
        closeDelay={750}
      >
        <Icon
          iconName="ListAlt"
          styles={rowIconStyle}
        />
      </TooltipHost>);
  };

  const getIsDeposited = () => {
    return (
      <TooltipHost
        content="Deposited"
        closeDelay={750}
      >
        <Icon
          iconName="FileInvoiceDollar"
          styles={rowIconStyle}
        />
      </TooltipHost>);
  };

  const getIsReconciled = () => {
    return (
      <TooltipHost
        content="Reconciled"
        closeDelay={750}
      >
        <Icon
          iconName="BalanceScale"
          styles={rowIconStyle}
        />
      </TooltipHost>);
  };

  const columns = useMemo(() => [
      {
        key: 'recipient-name',
        name: 'Recipient Name',
        fieldName: 'sortName',
        data: 'string',
        minWidth: 200,
        maxWidth: 200,
        isSorted:
          sortField === 'sortName' &&
          sortDirection !== sortDirections.NONE,
        isSortedDescending: sortDirection === sortDirections.DESC,
        onColumnClick,
        onRender: item => (
          <span>{item.displayName}</span>
        ),
      },
      {
        key: 'amount',
        name: 'Amount',
        fieldName: 'amount',
        data: 'number',
        minWidth: 100,
        maxWidth: 100,
        isSorted:
          sortField === 'amount' && sortDirection !== sortDirections.NONE,
        isSortedDescending: sortDirection === sortDirections.DESC,
        onColumnClick,
        onRender: item => (
          <span style={{ textAlign: 'right', display: 'block' }}>
            {formatCurrency(item.amount)}
          </span>
        ),
      },
      {
        key: 'checkNumber',
        name: 'Check Number',
        fieldName: 'checkNumberSort',
        data: 'string',
        minWidth: 75,
        maxWidth: 85,
        isSorted:
          sortField === 'checkNumberSort' &&
          sortDirection !== sortDirections.NONE,
        isSortedDescending: sortDirection === sortDirections.DESC,
        onColumnClick,
        onRender: item => <span>{item.checkNumber}</span>,
      },
      {
        key: 'referenceId',
        name: 'Reference Number',
        fieldName: 'paymentReferenceId',
        minWidth: 115,
        maxWidth: 115,
        isSorted:
          sortField === 'paymentReferenceId' &&
          sortDirection !== sortDirections.NONE,
        isSortedDescending: sortDirection === sortDirections.DESC,
        onColumnClick,
        onRender: item => <span>{item.paymentReferenceId}</span>,
      },
      {
        key: 'date-received',
        name: `${getLabel('Expenditure', session)} Date`,
        fieldName: 'expenditureDate',
        isSorted:
          sortField === 'expenditureDate' &&
          sortDirection !== sortDirections.NONE,
        isSortedDescending: sortDirection === sortDirections.DESC,
        data: 'string',
        minWidth: 120,
        maxWidth: 120,
        onColumnClick,
        onRender: item => <span>{formatDate(item.expenditureDate)}</span>,
      },
      {
        key: 'date-entered-updated',
        name: 'Date Entered/Updated',
        fieldName: 'updatedAt',
        data: 'string',
        minWidth: 150,
        isSorted:
          sortField === 'updatedAt' && sortDirection !== sortDirections.NONE,
        isSortedDescending: sortField === sortDirections.DESC,
        onColumnClick,
        onRender: item => <span>{formatDateTime(item.updatedAt)}</span>,
      },
    {
      key: 'isItemFiled',
      // iconName: 'ListAlt',
      // iconClassName: 'expenditure-header-icon',
      fieldName: 'isItemFiled',
      name: 'FIL',
      isResizable: true,
      isSorted:
        sortField === 'isItemFiled' && sortDirection !== sortDirections.NONE,
      isSortedDescending: sortDirection === sortDirections.DESC,
      onColumnClick,
      onRender: item => item.isItemFiled ? getIsFiled() : null,
      minWidth: 30,
      maxWidth: 30,
    },
    {
      key: 'deposited',
      name: 'DEP',
      // iconName: 'FileInvoiceDollar',
      // iconClassName: 'expenditure-header-icon',
      fieldName: 'depositId',
      isResizable: true,
      isSorted:
        sortField === 'depositId' && sortDirection !== sortDirections.NONE,
      isSortedDescending: sortDirection === sortDirections.DESC,
      onColumnClick,
      onRender: item => item.depositId ? getIsDeposited() : null,
      minWidth: 30,
      maxWidth: 30,
    },
    {
      key: 'reconciled',
      name: 'REC',
      // iconName: 'BalanceScale',
      // iconClassName: 'expenditure-header-icon',
      fieldName: 'reconciliationId',
      isResizable: true,
      isSorted:
        sortField === 'reconciliationId' && sortDirection !== sortDirections.NONE,
      isSortedDescending: sortDirection === sortDirections.DESC,
      onColumnClick,
      onRender: item => item.reconciliationId ? getIsReconciled() : null,
      minWidth: 30,
      maxWidth: 30,
    },
    ]);

  const onRenderRow = rowProps => (
    <div onClick={() => showPanel(rowProps.item)}>
      <DetailsRow {...rowProps} />
    </div>
  );

  return (
    <div className="ObservableExpendituresList">
      <div className="search-line">
        <div>
          <SearchBox
            placeholder={`Search ${getLabel('Expenditures', session)}`}
            value={filterText}
            onChange={actions.handleFilterChange}
            clearButtonProps={{
              iconProps: {
                iconName: 'Times',
              },
            }}
          />
        </div>
        <div style={{ marginLeft: '50px', display: 'flex', marginRight: '12px' }}>
          <div className="date-label">Start Date</div>
          <DatePicker
            onChange={actions.changeDate('startDate')}
            value={startDate}
          />
          <div className="date-label">End Date</div>
          <DatePicker
            onChange={actions.changeDate('endDate')}
            value={endDate}
          />
        </div>
        <TooltipHost
          content="Clear start and end dates."
        >
          <DefaultButton
            text="Clear Dates"
            onClick={actions.clearDates}
          />
        </TooltipHost>
        <div className="no-employee-or-occupation">
          <Checkbox
            label="No Employee/Occupation"
            checked={checkForNoOccupationOrEmployer}
            onChange={actions.setCheckForNoOccupationOrEmployer}
          />
        </div>
      </div>
      {getExpendituresStatus === statuses.PROCESSING && (
        <div style={{ padding: '60px 0' }}>
          <Spinner size={SpinnerSize.large} />
        </div>
      )}
      {getExpendituresStatus === statuses.SUCCESS && expenditures.length > 0 && (
        <>
          <DetailsList
            items={expenditures}
            columns={columns}
            compact={false}
            selectionMode={SelectionMode.none}
            checkboxVisibility={CheckboxVisibility.none}
            onRenderRow={onRenderRow}
          />
          <ScrollObserver
            getNextPageStatus={getNextPageStatus}
            scrollRef={scrollRef}
          />
        </>
      )}
      {getExpendituresStatus === statuses.SUCCESS &&
        expenditures.length === 0 && (
          <EmptyView
            message={`No ${getLabel('expenditures', session)} found`}
          />
        )}
      <ExpenditurePanel
        item={state.panelItem}
        showPanel={state.showPanel}
        closePanel={hidePanel}
        editItem={editItem}
        session={session}
      />
    </div>
  );
};

ObservableExpendituresList.propTypes = {
  editItem: PropTypes.func.isRequired,
  expenditures: PropTypes.array,
  filterFn: PropTypes.func,
  session: PropTypes.object.isRequired,
  isHidden: PropTypes.bool,
  filterText: PropTypes.string,
  startDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  endDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  checkForNoOccupationOrEmployer: PropTypes.bool,
  actions: PropTypes.object,
  getExpendituresStatus: PropTypes.string,
  sortField: PropTypes.string,
  sortDirection: PropTypes.oneOf(Object.values(sortDirections)),
};

ObservableExpendituresList.defaultProps = {
  expenditures: {
    expenditures: [],
  },
  filterFn: null,
  isHidden: false,
};

export default ObservableExpendituresList;
