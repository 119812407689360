import { createReducer } from '../../reducers/createReducer';

export const initialState = {
  showContinueDialogHidden: true,
  uploadDialogMessage: '',
  dataImportId: null,
};

export const actions = {
  SHOW_CONTINUE_DIALOG: 'SHOW_CONTINUE_DIALOG',
  CLOSE_CONTINUE_DIALOG: 'CLOSE_CONTINUE_DIALOG',
  SET_CONTINUE_DIALOG_MESSAGE: 'SET_CONTINUE_DIALOG_MESSAGE',
};

export const actionMap = {
  [actions.SHOW_CONTINUE_DIALOG]: (state, { data: { dataImportId } }) => ({
    ...state,
    dataImportId,
    showContinueDialogHidden: false,
  }),
  [actions.CLOSE_CONTINUE_DIALOG]: state => ({
    ...state,
    dataImportId: null,
    showContinueDialogHidden: true,
  }),
  [actions.SET_CONTINUE_DIALOG_MESSAGE]: (state, { data: { uploadDialogMessage } }) => ({
    ...state,
    uploadDialogMessage,
  }),
};

export const uploadReducer = createReducer(initialState, actionMap);
