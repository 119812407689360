import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import './EmptyView.css';

export const EmptyView = ({ message }) => (
  <div className="EmptyView">
    <Icon
      iconName="ExclamationTriangle"
      styles={{
        root: {
          fontSize: 28,
        },
      }}
    />
    <h2>{message}</h2>
  </div>
);

EmptyView.propTypes = {
  message: PropTypes.string,
};

EmptyView.defaultProps = {
  message: 'There are no items',
};

export default EmptyView;
