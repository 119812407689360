import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from 'office-ui-fabric-react/lib/Button';
import { formatDate, formatCurrency } from '../../helpers/util';

const style = {
  root: {
    color: '#fff',
  },
};

const EndRecipient = ({ recipient, editRecipient, deleteRecipient }) => (
  <div className="recipient">
    <h4>
      {recipient.displayName ||
        recipient.businessName ||
        `${recipient.lastName}, ${recipient.firstName}`}
      {` - ${formatCurrency(recipient.amount)} - `}
      {`${formatDate(recipient.datePaid)}`}
      {`${recipient.purpose ? ' - ' + recipient.purpose : ''}`}
      {`${recipient.memoText ? ' - ' + recipient.memoText : ''}`}
    </h4>
    <span>
      <IconButton
        iconProps={{ iconName: 'PencilAlt', styles: style }}
        onClick={() => {
          editRecipient(recipient._id);
        }}
      />
      <IconButton
        iconProps={{ iconName: 'TrashAlt', styles: style }}
        onClick={() => {
          deleteRecipient(recipient._id);
        }}
      />
    </span>
  </div>
);

EndRecipient.propTypes = {
  recipient: PropTypes.object.isRequired,
  editRecipient: PropTypes.func.isRequired,
  deleteRecipient: PropTypes.func.isRequired,
};

export default EndRecipient;
