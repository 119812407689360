import React from 'react';
import PropTypes from 'prop-types';

export const Grid = ({ children, className, style, name }) => {
  const classNames = ['ms-Grid', className].join(' ');

  return (
    <div className={classNames} style={style} name={name}>
      {children}
    </div>
  );
};

export const GridRow = ({ children, classNames, style }) => {
  const classes = ['ms-Grid-row', classNames].join(' ');

  return (
    <div className={classes} style={style}>
      {children}
    </div>
  );
};

Grid.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.element,
    PropTypes.node,
  ]),
  className: PropTypes.string,
  style: PropTypes.object,
  name: PropTypes.string,
};

Grid.defaultProps = {
  children: null,
  className: '',
  style: {},
  name: '',
};

GridRow.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.element,
    PropTypes.node,
  ]),
  classNames: PropTypes.string,
  style: PropTypes.object,
};

GridRow.defaultProps = {
  children: null,
  classNames: '',
  style: {},
};

export const Column = ({ classNames, sm, md, lg, xl, children, style }) => {
  const classes = [
    'ms-Grid-col',
    classNames,
    sm ? `ms-sm${sm}` : '',
    md ? `ms-md${md}` : '',
    lg ? `ms-lg${lg}` : '',
    xl ? `ms-xl${xl}` : '',
  ]
    .filter(Boolean)
    .join(' ');

  return (
    <div className={classes} style={style}>
      {children}
    </div>
  );
};

Column.propTypes = {
  style: PropTypes.object,
  classNames: PropTypes.string,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
  xl: PropTypes.number,
  children: PropTypes.any,
};

Column.defaultProps = {
  style: {},
  classNames: '',
  sm: 12,
  md: null,
  lg: null,
  xl: null,
};
