export const types = {
  GET_CONTACT_TO_MERGE: 'GET_CONTACT_TO_MERGE',
  GET_CONTACT_TO_MERGE_PROCESSING: 'GET_CONTACT_TO_MERGE_PROCESSING',
  GET_CONTACT_TO_MERGE_SUCCESS: 'GET_CONTACT_TO_MERGE_SUCCESS',
  GET_CONTACT_TO_MERGE_FAILURE: 'GET_CONTACT_TO_MERGE_FAILURE',
  GET_POSSIBLE_CONTACTS_FOR_MERGE: 'GET_POSSIBLE_CONTACTS_FOR_MERGE',
  GET_POSSIBLE_CONTACTS_FOR_MERGE_PROCESSING: 'GET_POSSIBLE_CONTACTS_FOR_MERGE_PROCESSING',
  GET_POSSIBLE_CONTACTS_FOR_MERGE_SUCCESS: 'GET_POSSIBLE_CONTACTS_FOR_MERGE_SUCCESS',
  GET_POSSIBLE_CONTACTS_FOR_MERGE_FAILURE: 'GET_POSSIBLE_CONTACTS_FOR_MERGE_FAILURE',
};

export const actions = {
  getContactToConsolidate() {
    return { type: types.GET_CONTACT_TO_MERGE };
  },

  getPossibleContactsForConsolidation() {
    return { type: types.GET_POSSIBLE_CONTACTS_FOR_MERGE };
  },
};
