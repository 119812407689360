import { types } from '../actions/contactMergeActions';
import { statuses } from '../helpers/constants';
import { createReducer } from './createReducer';

export const initialState = {
  contactToMerge: {},
  contactToMergeStatus: statuses.NOT_STARTED,
  possibleContactsForMerge: [],
  possibleContactsForMergeStatus: statuses.NOT_STARTED,
};

const actionMap = {
  [types.GET_CONTACT_TO_MERGE_PROCESSING]: state => {
    return {
      ...state,
      contactToMergeStatus: statuses.PROCESSING,
    };
  },
  [types.GET_CONTACT_TO_MERGE_SUCCESS]: (state, action) => {
    return {
      ...state,
      contactToMerge: action.contact,
      contactToMergeStatus: statuses.SUCCESS,
    };
  },
  [types.GET_POSSIBLE_CONTACTS_FOR_MERGE_PROCESSING]: state => {
    return {
      ...state,
      possibleContactsForMergeStatus: statuses.PROCESSING,
    };
  },
  [types.GET_POSSIBLE_CONTACTS_FOR_MERGE_SUCCESS]: (state, action) => {
    return {
      ...state,
      possibleContactsForMerge: action.allContacts,
      possibleContactsForMergeStatus: statuses.SUCCESS,
    };
  },
};

export const contactsMergeReducer = createReducer(initialState, actionMap);
