import { createReducer } from '../../../reducers/createReducer';
import { calcFilters } from '../../../helpers/listsHelper';

export const defaultFilters = [
  {
    column: 'transactionType',
    expression: 'equals',
    value: 'expenditure',
  },
  {
    column: 'transactionType',
    expression: 'exists',
    value: 'expenditure',
  },
];

export const defaultColumns = [
  'transactionDate',
  'displayName',
  'description',
  'amount',
];

export const initialState = {
  timePeriod: 0,
  startDate: '',
  endDate: '',
  filters: [...defaultFilters],
  columns: [...defaultColumns],
  errors: {
    startDate: '',
    endDate: '',
  },
};

export const actions = {
  HANDLE_CHANGE: 'HANDLE_CHANGE',
  SET_FORM_ERRORS: 'SET_FORM_ERRORS',
};

const calcFiltersWithDefaultFilters = calcFilters(defaultFilters);

export const actionMap = {
  [actions.HANDLE_CHANGE]: (state, { data: { fieldName, value } }) => {
    if (fieldName === 'timePeriod' && value !== 'custom') {
      return {
        ...state,
        [fieldName]: value,
        startDate: '',
        endDate: '',
        filters: calcFiltersWithDefaultFilters(fieldName, value, state),
      };
    }
    return {
      ...state,
      [fieldName]: value,
      filters: calcFiltersWithDefaultFilters(fieldName, value, state),
    };
  },
  [actions.SET_FORM_ERRORS]: (state, { data: { errors } }) => ({
    ...state,
    errors,
  }),
};

export const itemizedExpendituresReducer = createReducer(
  initialState,
  actionMap,
);
