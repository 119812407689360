import {
  ChoiceGroup,
  DefaultButton,
  PrimaryButton,
  Spinner,
} from 'office-ui-fabric-react';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { TextField } from '../../../../components/common';
import { ImportDataType, statuses } from '../../../../helpers/constants';
import './index.css';
import { configByDataType, ConfigValueType } from './utils';

const QuestionsStep = ({
  dataType,
  initialValues,
  saveConfigStatus,
  getUploadResultStatus,
  onSaveConfig,
  onBack,
}) => {
  const questionsConfig = configByDataType[dataType][initialValues.fileFormat];
  const questionsConfigEntries = Object.entries(questionsConfig);
  const correspondingInitialValues = questionsConfigEntries.reduce(
    (acc, [key]) => ({ ...acc, [key]: initialValues[key] }),
    { fileFormat: initialValues.fileFormat },
  );
  const [values, setValues] = useState(correspondingInitialValues);

  const handleSelectChange = key => (event, option) => {
    setValues(oldValues => ({ ...oldValues, [key]: option.value }));
  };

  const handleTextFieldChange = key => event => {
    setValues(oldValues => ({
      ...oldValues,
      [key]: event.target.value.trim() ? event.target.value : undefined,
    }));
  };

  const handleComponentChange = key => value => {
    setValues(oldValues => ({ ...oldValues, [key]: value }));
  };

  const handleNextClick = () => {
    onSaveConfig({ ...values, isItemFiled: values.isItemFiled === 'true' });
  };

  const renderQuestion = (
    [key, { type, options, questionText, Component }],
    index,
  ) => {
    const label = `${index + 1}. ${questionText}`;
    if (Component) {
      return (
        <Component
          key={key}
          label={label}
          value={values[key]}
          onChange={handleComponentChange(key)}
        />
      );
    }
    if (type === ConfigValueType.Select) {
      return (
        <ChoiceGroup
          key={key}
          selectedKey={String(values[key])}
          options={options}
          onChange={handleSelectChange(key)}
          label={label}
        />
      );
    }
    return (
      <TextField
        key={key}
        label={label}
        value={values[key]}
        onChange={handleTextFieldChange(key)}
      />
    );
  };

  const isLoading =
    saveConfigStatus === statuses.PROCESSING ||
    getUploadResultStatus === statuses.PROCESSING;

  const isNextDisabled =
    questionsConfigEntries.some(([key]) => values[key] === undefined) ||
    isLoading;

  return (
    <div className="QuestionsStep">
      <div className="questions-step-questions-container">
        {questionsConfigEntries.map(renderQuestion)}
      </div>
      <div className="import-wizard-step-bttns">
        <DefaultButton text="Back" onClick={onBack} disabled={isLoading} />
        <PrimaryButton
          onClick={handleNextClick}
          text="Next"
          disabled={isNextDisabled}
        />
        {(saveConfigStatus === statuses.PROCESSING ||
          getUploadResultStatus === statuses.PROCESSING) && <Spinner />}
      </div>
    </div>
  );
};

QuestionsStep.propTypes = {
  dataType: PropTypes.oneOf(Object.values(ImportDataType)).isRequired,
  initialValues: PropTypes.object.isRequired,
  saveConfigStatus: PropTypes.oneOf(Object.values(statuses)).isRequired,
  getUploadResultStatus: PropTypes.oneOf(Object.values(statuses)).isRequired,
  onSaveConfig: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
};

export default QuestionsStep;
