import React from 'react';
import PropTypes from 'prop-types';
import { ActionButton } from 'office-ui-fabric-react/lib/Button';

const BackButton = ({
  history,
  url = '',
  message = 'Back',
  isFixed = false,
  pageTitle = '',
}) => (
  <div
    className="go-back"
    style={
      isFixed
        ? { position: 'fixed', top: 65, zIndex: 121, display: 'flex', marginRight: '10px' }
        : { display: 'flex' }
    }
  >
    <ActionButton
      iconProps={{
        iconName: 'ArrowAltCircleLeft',
        styles: {
          root: {
            color: '#fff',
          },
        },
      }}
      style={{ color: '#fff', marginRight: 32 }}
      onClick={() => (url ? history.push(url) : history.push('/filer'))}
    >
      {message}
    </ActionButton>
    {pageTitle && (
      <h1
        style={{
          flexGrow: 1,
          fontSize: '14px',
          fontWeight: 100,
          color: '#fff',
        }}
      >
        {pageTitle}
      </h1>
    )}
  </div>
);

BackButton.propTypes = {
  history: PropTypes.object.isRequired,
  url: PropTypes.string,
  message: PropTypes.string,
  isFixed: PropTypes.bool,
  pageTitle: PropTypes.string,
};

export default BackButton;
