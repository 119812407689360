import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  ActionButton,
  DefaultButton,
  Icon,
  DetailsList,
  DetailsRow,
  CheckboxVisibility,
  SelectionMode,
  DetailsListLayoutMode,
} from 'office-ui-fabric-react';

import { types as reportActions } from '../../actions/reportActions';
import { types as federalReportActions } from '../../actions/federalReportActions';
import { types as currentReportActions } from '../../actions/currentReportActions';
import { types as reportingPeriodActions } from '../../actions/reportingPeriodActions';
import { formatDate, formatUtcDate, scrollToTop } from '../../helpers/util';
import { frCampaignTypes } from '../../helpers/constants';
import { getFRCampaignType } from '../../helpers/campaignHelper';
import { reportingPeriods } from '../../helpers/reportingPeriods';

import {
  createDisclosureReportPayload,
  createFederalReportPayload,
  getReportTypeByItem,
  getReportTypeByItemForState,
  getVersion,
  getVersionNonFec,
} from '../../helpers/reportHelper';
import {
  AddReportDialog,
  AddGAReportDialog,
  AddFederalReportDialog,
  AddFederalNonCandidateReportDialog,
} from '../../components/Dialogs';
import { BackButton } from '../../components/common';
import ReportViewPicker from '../../components/Pickers/ReportViewPicker';
import {
  getUserSession,
  getCampaign,
  getDraftReports,
  getFiledReports,
} from '../../selectors';
import './ViewAll.css';

const initialState = {
  createReportDialogHidden: true,
  gaMessageHidden: true,
  reportType: '',
};

export const ViewAllReports = ({ history }) => {
  const [state, setState] = useState({ ...initialState });
  const reduxDispatch = useDispatch();
  const session = useSelector(getUserSession);
  const campaign = useSelector(getCampaign);
  const draftReports = useSelector(getDraftReports);
  const filedReports = useSelector(getFiledReports);
  const frCampaignType = getFRCampaignType(campaign);

  useEffect(() => {
    scrollToTop();
    reduxDispatch({
      type: reportActions.GET_DRAFT_REPORTS,
      data: {
        reportType: '',
      },
    });
    reduxDispatch({
      type: reportActions.GET_FILED_REPORTS,
      data: { reportType: '' },
    });
    reduxDispatch({
      type: reportingPeriodActions.GET_REPORTING_PERIODS,
    });
    return () => {
      reduxDispatch({
        type: reportActions.CLEAR_REPORTS,
      });
    };
  }, []);

  const openCreateReportDialog = (type = '') => {
    setState({
      ...state,
      createReportDialogHidden: false,
      reportType: type,
    });
  };

  const closeCreateReportDialog = () => {
    setState({
      ...state,
      createReportDialogHidden: true,
    });
  };

  const createDisclosureReport = reportDetails => {
    const payload = createDisclosureReportPayload(reportDetails);
    reduxDispatch({
      type: reportActions.CREATE_DISCLOSURE_REPORT,
      payload,
    });
    closeCreateReportDialog();
  };

  const createFederalReport = reportDetails => {
    const payload = createFederalReportPayload(reportDetails);
    reduxDispatch({
      type: federalReportActions.CREATE_FEDERAL_REPORT,
      data: {
        reportDetails: payload,
      },
    });
    closeCreateReportDialog();
  };

  const getAmendmentClick = (item) => {
    if (item.isAmendable) {
      return () => {
        reduxDispatch({
          type: currentReportActions.CREATE_AMENDMENT,
          data: {
            reportId: item._id,
          },
        });
      };
    }
    return () => {};
  };

  const createColumnsDraft = () => [
    {
      key: 'report',
      name: 'Report',
      minWidth: 300,
      maxWidth: 320,
      onRender: item => getReportTypeByItemForState(item),
    },
    {
      key: 'report-start-date',
      name: 'Start Date',
      minWidth: 100,
      maxWidth: 100,
      onRender: item => <span>{formatUtcDate(item.startDate)}</span>,
    },
    {
      key: 'report-end-date',
      name: 'End Date',
      minWidth: 100,
      maxWidth: 100,
      onRender: item => <span>{formatUtcDate(item.endDate)}</span>,
    },
  ];

  const createFiledColumns = () => [
    {
      key: 'report',
      name: 'Report',
      minWidth: 300,
      maxWidth: 320,
      onRender: item => getReportTypeByItemForState(item),
    },
    {
      key: 'view',
      name: '',
      minWidth: 125,
      maxWidth: 140,
      onRender: item => {
        const filedNonFecLink = `/api/filer/reports/${item._id}/xmlFiled?receipt=true`;
        const pdfDetailLocation = `/api/filer/reports/${item._id}/pdfFiled`;
        return (
          <div className="filed-report-actions">
            <ReportViewPicker
              filedNonFecLink={filedNonFecLink}
              pdfDetailLocation={pdfDetailLocation}
            />
          </div>
        );
      },
    },
    {
      key: 'version',
      name: 'Version',
      minWidth: 220,
      maxWidth: 220,
      onRender: item => getVersionNonFec(item),
    },
    {
      key: 'report-start-date',
      name: 'Start Date',
      minWidth: 100,
      maxWidth: 100,
      onRender: item => <span>{formatUtcDate(item.startDate)}</span>,
    },
    {
      key: 'report-end-date',
      name: 'End Date',
      minWidth: 100,
      maxWidth: 100,
      onRender: item => <span>{formatUtcDate(item.endDate)}</span>,
    },
    {
      key: 'report-date',
      name: 'Date Filed',
      minWidth: 100,
      maxWidth: 100,
      onRender: item => <span>{formatDate(item.reportFiledAt)}</span>,
    },
    // {
    //   key: 'election-date',
    //   name: 'Election Date',
    //   maxWidth: 120,
    //   onRender: item => <span>{formatUtcDate(item.electionDate)}</span>,
    // },
    {
      key: 'actions',
      name: '',
      minWidth: 125,
      maxWidth: 140,
      onRender: item => {
        return (
          <div className="filed-report-actions">
            { item.isAmendable &&
            <DefaultButton
              onClick={getAmendmentClick(item)}
              iconProps={{
                iconName: 'PencilAlt',
                styles: {
                  root: {
                    color: '#107c10',
                    marginRight: 8,
                  },
                },
              }}
              text="Amend"
              disabled={!item.isAmendable}
            />
            }
          </div>
        );
      },
    },
  ];

  const createColumnsDraftFec = () => [
      {
        key: 'report',
        name: 'Report',
        minWidth: 300,
        maxWidth: 320,
        onRender: item => <span>{getReportTypeByItem(item)}</span>,
      },
      {
        key: 'report-start-date',
        name: 'Start Date',
        minWidth: 100,
        maxWidth: 100,
        onRender: item => <span>{formatUtcDate(item.startDate)}</span>,
      },
      {
        key: 'report-end-date',
        name: 'End Date',
        minWidth: 100,
        maxWidth: 100,
        onRender: item => <span>{formatUtcDate(item.endDate)}</span>,
      },
    ];

  const createFiledColumnsFec = () => [
    {
      key: 'report',
      name: 'Report',
      minWidth: 300,
      maxWidth: 320,
      onRender: item => <span>{getReportTypeByItem(item)}</span>,
    },
    {
      key: 'view',
      name: '',
      minWidth: 125,
      maxWidth: 140,
      onRender: item => {
        const filedPdfLocation = `/api/filer/reports/${item._id}/pdfFiled`;
        const pdfDetailLocation = `/api/filer/reports/${item._id}/pdfFiled?detail=true`;
        const fecResponseLocation = `/api/filer/reports/${item._id}/filed`;
        const fecFileLocation = `/api/filer/reports/${item._id}/fec/fecFile`;
        return (
          <div className="filed-report-actions">
            <ReportViewPicker
              filedPdfLocation={filedPdfLocation}
              pdfDetailLocation={pdfDetailLocation}
              fecResponseLocation={fecResponseLocation}
              fecFileLocation={fecFileLocation}
            />
          </div>
        );
      },
    },
    {
      key: 'version',
      name: 'Version',
      minWidth: 220,
      maxWidth: 220,
      onRender: item => getVersion(item),
    },
    {
      key: 'report-start-date',
      name: 'Start Date',
      minWidth: 100,
      maxWidth: 100,
      onRender: item => <span>{formatUtcDate(item.startDate)}</span>,
    },
    {
      key: 'report-end-date',
      name: 'End Date',
      minWidth: 100,
      maxWidth: 100,
      onRender: item => <span>{formatUtcDate(item.endDate)}</span>,
    },
    {
      key: 'report-date-filed',
      name: 'Date Filed',
      minWidth: 100,
      maxWidth: 100,
      onRender: item => <span>{formatUtcDate(item.reportFiledAt)}</span>,
    },
    {
      key: 'actions',
      name: '',
      minWidth: 125,
      maxWidth: 140,
      onRender: item => {
        return (
          <div className="filed-report-actions">
            { item.isAmendable &&
            <DefaultButton
              onClick={!item.isAmendable ? () => {} : () => {
                reduxDispatch({
                  type: currentReportActions.CREATE_AMENDMENT_FEDERAL,
                  data: {
                    reportId: item._id,
                  },
                });
              }}
              iconProps={{
                iconName: 'PencilAlt',
                styles: {
                  root: {
                    color: '#107c10',
                    marginRight: 8,
                  },
                },
              }}
              text="Amend"
              disabled={!item.isAmendable}
            />
            }
          </div>
        );
      },
    },
  ];

  const mapDraftReports = () => {
    return draftReports.map(dr => ({
      ...dr,
      key: dr._id,
    }));
  };

  const mapFiledReports = () => {
    return filedReports.map(fr => ({
      ...fr,
      key: fr._id,
    }));
  };

  const onHandleRowSelection = id => {
    if (session.isFederal()) {
      history.push(`/filer/editFECReport/${id}`);
    } else {
      history.push(`/filer/editReport/${id}`);
    }
  };

  const onRenderRow = rowProps => {
    return (
      <div style={{ cursor: 'pointer' }} onClick={() => onHandleRowSelection(rowProps.item.id)}>
        <DetailsRow {...rowProps} />
      </div>
    );
  };

  const getNonFederalReportCreate = () => {
    return (
      <ActionButton
        iconProps={{ iconName: 'PlusCircle' }}
        menuIconProps={{
          iconName: 'CaretDown',
        }}
        menuProps={{
          items: [
            {
              key: 'disclosure-report',
              text: 'Disclosure Report',
              onClick: () => {
                openCreateReportDialog();
              },
              iconProps: {
                iconName: 'ChartBar',
              },
            },
            {
              key: 'termination-report',
              text: 'Termination Report',
              onClick: () => {
                openCreateReportDialog('Termination');
              },
              iconProps: {
                iconName: 'Stop',
              },
            },
          ],
        }}
        text="Create New Report"
      />
    );
  };

  let isStateGeorgiaCampaign = (campaign && campaign.officeType === 'State' && campaign.officeState === 'Georgia')
    || (campaign && campaign.isNonCandidateCommittee === true && campaign.officeType !== 'Federal');

  isStateGeorgiaCampaign = isStateGeorgiaCampaign
    && campaign && !['39728', '63460'].includes(campaign.filerId); // Rupal, Cobb (Add Paulding's filer id to prompt local report options)

  return (
    <>
      <BackButton history={history} />
      <div className="ViewAllReports">
        <div className="working-reports depth-1">
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <h3>Working Reports</h3>
            {session.isFederal() ? (
              <ActionButton
                iconProps={{
                  iconName: 'PlusCircle',
                }}
                text="Create New Report"
                onClick={() => openCreateReportDialog()}
              />
            ) :
              getNonFederalReportCreate()
            }
          </div>
          {draftReports.length === 0 && (
            <div className="no-filed-reports">
              <Icon
                iconProps="FileImport"
                style={{ fontSize: 60, color: '#99999999' }}
              />
              <p>No reports created or in progress for this campaign.</p>
            </div>
          )}
          {draftReports.length > 0 && (
            <DetailsList
              items={mapDraftReports()}
              columns={session.isFederal() ? createColumnsDraftFec() : createColumnsDraft()}
              checkboxVisibility={CheckboxVisibility.none}
              compact={false}
              selectionMode={SelectionMode.none}
              layoutMode={DetailsListLayoutMode.justified}
              onRenderRow={onRenderRow}
            />
          )}
        </div>
        <div className="filed-reports depth-1">
          <h3>Filed Reports</h3>
          {filedReports.length === 0 && (
            <div className="no-filed-reports">
              <Icon
                iconName="FileExport"
                style={{ fontSize: 60, color: '#99999999' }}
              />
              <p>No reports filed for this campaign.</p>
            </div>
          )}
          {filedReports.length > 0 && (
            <DetailsList
              items={mapFiledReports()}
              columns={session.isFederal() ? createFiledColumnsFec() : createFiledColumns()}
              checkboxVisibility={CheckboxVisibility.none}
              compact={false}
              selectionMode={SelectionMode.none}
              layoutMode={DetailsListLayoutMode.justified}
            />
          )}
        </div>
      </div>
      {!isStateGeorgiaCampaign && !session.isFederal() && (
        <AddReportDialog
          dialogHidden={state.createReportDialogHidden}
          closeDialog={closeCreateReportDialog}
          createReport={createDisclosureReport}
          electionYear={campaign.nextElectionYear}
          electionDates={campaign.electionDates}
          isNonCandidateCommittee={campaign.isNonCandidateCommittee}
          registrationDate={
            campaign.isNonCandidateCommittee
              ? campaign.committee.registrationDate
              : ''
          }
          reportingPeriods={reportingPeriods}
          reportType={state.reportType}
          session={session}
        />
      )}
      {isStateGeorgiaCampaign && (
        <AddGAReportDialog
          dialogHidden={state.createReportDialogHidden}
          closeDialog={closeCreateReportDialog}
          createReport={createDisclosureReport}
          electionYear={campaign.nextElectionYear}
          electionDates={campaign.electionDates}
          isNonCandidateCommittee={campaign.isNonCandidateCommittee}
          registrationDate={
            campaign.isNonCandidateCommittee
              ? campaign.committee.registrationDate
              : ''
          }
          reportingPeriods={reportingPeriods}
          reportType={state.reportType}
          session={session}
        />
      )}
      {campaign && frCampaignType === frCampaignTypes.FederalCandidate && (
        <AddFederalReportDialog
          campaign={campaign}
          dialogHidden={state.createReportDialogHidden}
          closeDialog={closeCreateReportDialog}
          createReport={createFederalReport}
        />
      )}
      {campaign && frCampaignType === frCampaignTypes.FederalNonCandidate && (
        <AddFederalNonCandidateReportDialog
          campaign={campaign}
          dialogHidden={state.createReportDialogHidden}
          closeDialog={closeCreateReportDialog}
          createReport={createFederalReport}
        />
      )}
    </>
  );
};

ViewAllReports.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(ViewAllReports);
