import React, { useEffect, useReducer, useState } from 'react';
import axios from 'axios';
import qs from 'qs';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
  ActionButton,
  Stack,
  mergeStyleSets,
  Spinner,
  SpinnerSize,
  Text,
} from 'office-ui-fabric-react';
import { BackButton } from '../../../components/common';
import {
  unclearedTransactionsReducer,
  actions,
  initialState,
} from './unclearedTransactionsReducer';
import {
  getUserSession,
  getListsForAdHoc,
  getListFetchStatus,
  getListRecordCount,
} from '../../../selectors';
import { types as listActions } from '../../../actions/listActions';
import { types as messagingActions } from '../../../actions/messagingActions';
import { toastTypes, statuses } from '../../../helpers/constants';
import { getServerSideErrorMessage } from '../../../helpers/util';
import AdHocResultsList from '../AdHocResultsList';
import TimePeriodFilter from '../TimePeriodFilter';
import { validate } from './form-validations';
import '../SpecificList.css';

const styles = mergeStyleSets({
  list: {
    height: 'calc(100vh - 245px)',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    boxSizing: 'border-box',
  },
  listBlurb: {
    fontSize: '20px',
    marginLeft: 80,
  },
});

const UnclearedTransactions = ({ history }) => {
  const [state, localDispatch] = useReducer(
    unclearedTransactionsReducer,
    initialState,
  );
  const reduxDispatch = useDispatch();
  const session = useSelector(getUserSession);
  const lists = useSelector(getListsForAdHoc);
  const listFetchStatus = useSelector(getListFetchStatus);
  const [items, setItems] = useState([]);
  const [loadingCSV, setLoadingCSV] = useState(false);
  const totalRecords = useSelector(getListRecordCount);

  useEffect(() => {
    const errors = validate(state);
    localDispatch({
      type: actions.SET_FORM_ERRORS,
      data: { errors },
    });
    if (Object.values(state.errors).every(e => e.length === 0)) {
      reduxDispatch({
        type: listActions.GET_LIST_UNRECONCILED,
        data: {
          fields: state.columns.join(','),
          filters: JSON.stringify(
            state.filters.reduce((list, filter) => {
              const { column, expression, value } = filter;
              list = [
                ...list,
                {
                  column,
                  expression,
                  value: Array.isArray(value)
                    ? value.join(',')
                    : column === 'amount' || column === 'aggregateAmount'
                    ? +value
                    : value + '',
                },
              ];
              return list;
            }, []),
          ),
        },
      });
    }
  }, [state.filters]);

  useEffect(() => {
    return () => {
      reduxDispatch({
        type: listActions.CLEAR_LISTS,
      });
    };
  }, []);

  useEffect(() => {
    if (lists.length) {
      setItems(lists);
    }
  }, [lists]);

  const handleChange = fieldName => (e, option) => {
    const value = option.key !== undefined ? option.key : option;
    localDispatch({
      type: actions.HANDLE_CHANGE,
      data: { fieldName, value },
    });
  };

  const changeDate = fieldName => value => {
    localDispatch({
      type: actions.HANDLE_CHANGE,
      data: { fieldName, value },
    });
  };

  const exportToCSV = async () => {
    try {
      setLoadingCSV(true);
      const queryParams = {
        fields: state.columns.join(','),
        filters: JSON.stringify(
          state.filters.reduce((list, filter) => {
            const { column, expression, value } = filter;
            list = [
              ...list,
              {
                column,
                expression,
                value: Array.isArray(value)
                  ? value.join(',')
                  : column === 'amount' || column === 'aggregateAmount'
                  ? +value
                  : value + '',
              },
            ];
            return list;
          }, []),
        ),
        download: true,
      };
      const params = qs.stringify(queryParams, {
        addQueryPrefix: true,
        arrayFormat: 'brakcets',
      });

      const { data: result } = await axios.get(
        `/api/filer/accounting/listsUncleared${params}`,
        {
          withCredentials: true,
        },
      );

      const link = document.createElement('a');
      const blob = new Blob([result], { type: 'text/csv' });
      link.setAttribute('href', window.URL.createObjectURL(blob));
      link.setAttribute('download', 'uncleared_transactions.csv');
      link.click();
    } catch (e) {
      const error = getServerSideErrorMessage(e);
      reduxDispatch({
        type: messagingActions.SET_TOAST,
        data: {
          message: error,
          toastType: toastTypes.ERROR,
        },
      });
    } finally {
      setLoadingCSV(false);
    }
  };

  return (
    <>
      <BackButton
        history={history}
        isFixed
        url="/filer/listsIndex"
        pageTitle="Uncleared Transactions"
      />
      <div className="UnclearedTransactions SpecificList">
        <div className="options-panel">
          <Stack childrenGap={8}>
            <TimePeriodFilter
              handleChange={handleChange}
              changeDate={changeDate}
              timePeriod={state.timePeriod}
              startDate={state.startDate}
              endDate={state.endDate}
              errors={state.errors}
            />
          </Stack>
        </div>
        {loadingCSV ? (
          <div className="list loading-csv">
            <Spinner size={SpinnerSize.large} label="Generating CSV" />
          </div>
        ) : (
          <div className="list">
            <Stack childrenGap={8}>
              <Stack
                horizontal
                verticalAlign="center"
                childrenGap={8}
                className={styles.topRow}
              >
                <ActionButton
                  text={`Export to CSV (${totalRecords} records)`}
                  onClick={exportToCSV}
                  disabled={lists.length === 0 || state.columns.length === 0}
                  iconProps={{
                    iconName: 'FileCSV',
                  }}
                />
                {totalRecords > 499 && (
                  <Text variant="small">Previewing first 500 records</Text>
                )}
              </Stack>
              <Stack childrenGap={8} className={styles.list}>
                {listFetchStatus === statuses.PROCESSING && (
                  <div className="loading-list">
                    <Spinner size={SpinnerSize.large} label="Loading..." />
                  </div>
                )}
                {lists.length > 0 && listFetchStatus === statuses.SUCCESS && (
                  <AdHocResultsList
                    session={session}
                    items={items}
                    selectedColumns={state.columns}
                  />
                )}
                {lists.length === 0 && listFetchStatus === statuses.SUCCESS && (
                  <p className={styles.listBlurb}>No results found</p>
                )}
              </Stack>
            </Stack>
          </div>
        )}
      </div>
    </>
  );
};

UnclearedTransactions.propTypes = {
  history: PropTypes.object.isRequired,
};

export default UnclearedTransactions;
