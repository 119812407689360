import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import PrivateRoute from './components/PrivateRoute';
import DepositTicket from './pages/Deposits/DepositTicket';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import Signup from './pages/Signup';
import CreateCampaignContainer from './pages/CreateCampaign';
import VerifyInvite from './pages/VerifyInvite';

export default class AppRoutes extends Component {
  render() {
    return (
      <Switch>
        <PrivateRoute path="/filer" component={Dashboard} />
        <Route path="/passwordReset/:resetToken" component={ResetPassword} />
        <Route path="/forgotPassword" component={ForgotPassword} />
        <Route path="/signup/:registrationToken" component={Signup} />
        <Route path="/verify/:verificationToken" component={VerifyInvite} />
        <Route path="/signup" component={Signup} />
        <Route path="/createCampaign" component={CreateCampaignContainer} />
        <Route path="/depositTicket/:id" component={DepositTicket} />
        <Route path="/" component={Login} />
      </Switch>
    );
  }
}
