import { types } from '../actions/reconciliationSortActions';
import { createReducer } from './createReducer';
import { sortDirections } from '../helpers/constants';

export const initialState = {
  sortField: 'statementEndDate',
  sortDirection: sortDirections.DESC,
};

const actionMap = {
  [types.SORT_RECONCILIATIONS]: (state, action) => {
    return {
      ...state,
      sortField: action.data.field,
      sortDirection: action.data.direction,
    };
  },

  [types.CLEAR_RECONCILIATION_SORT]: state => ({
    ...state,
    sortField: 'statementEndDate',
    sortDirection: sortDirections.DESC,
  }),
};

export const reconciliationSortsReducer = createReducer(
  initialState,
  actionMap,
);
