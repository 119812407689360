import axios from 'axios';
import React, {
  // Fragment,
  // useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Stack,
  StackItem, TextField,
} from 'office-ui-fabric-react';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import { DefaultButton } from 'office-ui-fabric-react/lib/Button';

import { BackButton } from '../../components/common';

import './index.css';

const DeveloperCivixAPI = ({ history }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const [url, setUrl] = useState('');
  const [payload, setPayload] = useState({});
  const [name, setName] = useState('');
  const [params, setParams] = useState({});

  const doRequest = async (name, action, url, payload) => {
    setIsLoading(true);
    setUrl(url);
    setPayload(payload);
    setName(name);
    try {
      if (action === 'get') {
        const { data } = await axios.get(url);
        setResponse(JSON.stringify(data, null, 2));
      }
      setIsLoading(false);
    } catch (err) {
      setResponse(JSON.stringify(err, null, 2));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <BackButton history={history} message="Cancel" />
      <div className="DeveloperCivixAPI">
        <Stack
          horizontal
        >
          <Stack
            vertical
            tokens={{ childrenGap: '16px' }}
            styles={{ root: { width: '300px', padding: '16px' } }}
          >
            <StackItem>
              <TextField
                label="Election Cycle"
                value={params.electionCycle}
                onChange={(e) => setParams({ ...params, electionCycle: e.target.value })}
              />
            </StackItem>
            <StackItem>
              <DefaultButton
                className="action-btn"
                text="Get GA Election Cycles"
                iconProps={{
                  iconName: 'ListAlt',
                  styles: {
                    root: {
                      color: '#107c10',
                    },
                  },
                }}
                onClick={() =>
                  doRequest('Election Cycle Request', 'get', '/api/filer/ga/electionCycles', {})
                }
              />
            </StackItem>
            <StackItem>
              <DefaultButton
                className="action-btn"
                text="Get Unfiled GA Reports"
                iconProps={{
                  iconName: 'ListAlt',
                  styles: {
                    root: {
                      color: '#107c10',
                    },
                  },
                }}
                onClick={() =>
                  doRequest('Report List Request', 'get', `/api/filer/ga/reports/${params.electionCycle}`, {})
                }
              />
            </StackItem>
            <StackItem>
              <DefaultButton
                className="action-btn"
                text="Get Filed GA Reports"
                iconProps={{
                  iconName: 'ListAlt',
                  styles: {
                    root: {
                      color: '#107c10',
                    },
                  },
                }}
                onClick={() =>
                  doRequest('Report List Request', 'get', `/api/filer/ga/reports/${params.electionCycle}?filed=true`, {})
                }
              />
            </StackItem>
          </Stack>
          <Stack
            vertical
            tokens={{ childrenGap: '8px' }}
            styles={{ root: { padding: '16px' } }}
          >
            <h2>{name}</h2>
            <StackItem>
              <h3>Call</h3>
              {url}
            </StackItem>
            {Object.keys(payload).length &&
            <StackItem>
              <h3>Payload</h3>
              <pre>
                {JSON.stringify(payload, null, 2)}
              </pre>
            </StackItem>
            }
            <StackItem>
              <h3>Response</h3>
              <div>
                {isLoading &&
                <>
                  <Spinner size={SpinnerSize.medium} />
                </>
                }
                {!isLoading &&
                <>
                  <pre style={{ whiteSpace: 'pre-wrap', maxWidth: '1000px' }}>
                    {response}
                  </pre>
                </>
                }
              </div>
            </StackItem>
          </Stack>
        </Stack>
      </div>
    </div>
  );
};

DeveloperCivixAPI.propTypes = {
  history: PropTypes.object.isRequired,
};

export default DeveloperCivixAPI;
