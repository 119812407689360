import { types } from '../actions/adminUserActions';
import { statuses } from '../helpers/constants';
import { createReducer } from './createReducer';

export const initialState = {
  users: [],
  status: statuses.NOT_STARTED,
  userForEdit: null,
};

const actionMap = {
  [types.GET_USERS_FOR_CAMPAIGN_SUCCESS]: (state, action) => {
    return {
      ...state,
      users: action.data.users,
      status: statuses.SUCCESS,
      userForEdit: null,
    };
  },
  [types.GET_USERS_FOR_CAMPAIGN_PROCESSING]: state => {
    return {
      ...state,
      status: statuses.PROCESSING,
    };
  },
  [types.CLEAR_CURRENT_USER_LIST]: () => {
    return { ...initialState };
  },
  [types.GET_USER_FOR_EDIT_SUCCESS]: (state, action) => {
    return {
      ...state,
      userForEdit: action.data.user,
    };
  },
  [types.ADD_USER_SUCCESS]: (state, action) => {
    return {
      ...state,
      users: [...state.users, action.data.newUser],
    };
  },
};

export const adminUserReducer = createReducer(initialState, actionMap);
