import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'office-ui-fabric-react/lib/Dropdown';
import { CaretDown } from '../icons';

const YearPicker = ({
  label = 'Year',
  onChange = () => {},
  placeholder = 'Select',
  value = 0,
  errorMessage = '',
  required = false,
  startYear = 2014,
  addedYears = 14,
  className = '',
}) => {
  const calculateYears = () => {
    const years = [];
    for (let start = 0; start <= addedYears; start += 1) {
      const year = startYear + start;
      years.push({ key: year, text: year });
    }
    return years;
  };

  return (
    <Dropdown
      onRenderCaretDown={() => <CaretDown />}
      label={label}
      onChange={onChange}
      placeholder={placeholder}
      selectedKey={value}
      options={[{ key: 0, text: 'Select' }, ...calculateYears()]}
      errorMessage={errorMessage}
      required={required}
      className={className}
    />
  );
};

YearPicker.propTypes = {
  startYear: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  addedYears: PropTypes.number,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  errorMessage: PropTypes.string,
  required: PropTypes.bool,
  className: PropTypes.string,
};

export default YearPicker;
