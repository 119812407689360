import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { GridRow, Column, MoneyField } from '../../common';

const FECSection12 = ({ q12ColA, q12ColB }) => {
  return (
    <Fragment>
      <GridRow style={{ borderTop: '1px solid #919191', paddingBottom: 6 }}>
        <Column sm={4}>
          <p>12. TRANSFERS FROM OTHER AUTHORIZED COMMITTEES</p>
        </Column>
        <Column sm={4} classNames="entry-row">
          <MoneyField
            disabled
            value={q12ColA || 0}
          />
        </Column>
        <Column sm={4} classNames="entry-row">
          <MoneyField disabled value={q12ColB || 0} />
        </Column>
      </GridRow>
    </Fragment>
  );
};

FECSection12.propTypes = {
  q12ColA: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  q12ColB: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default FECSection12;
