import { TextField as MSTextField } from 'office-ui-fabric-react/lib/TextField';
import PropTypes from 'prop-types';
import React from 'react';

const TextField = ({
  value,
  label,
  className,
  style,
  onChange,
  onClick,
  onBlur,
  onRenderDescription,
  required,
  errorMessage,
  description,
  borderless,
  underlined,
  compRef,
  disabled,
}) => {
  const styles = disabled
    ? {
        field: { color: '#565656', backgroundColor: 'white' },
        prefix: { color: '#565656', backgroundColor: 'white' },
      }
    : {};
  return (
    <MSTextField
      borderless={borderless}
      inputMode="text"
      value={value}
      className={className}
      style={style}
      styles={styles}
      onChange={onChange}
      onClick={onClick}
      onBlur={onBlur}
      onRenderDescription={onRenderDescription}
      label={label}
      required={required}
      errorMessage={errorMessage}
      description={description}
      underlined={underlined}
      componentRef={compRef}
      disabled={disabled}
    />
  );
};

TextField.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string,
  style: PropTypes.object,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  onBlur: PropTypes.func,
  onRenderDescription: PropTypes.func,
  errorMessage: PropTypes.string,
  description: PropTypes.string,
  borderless: PropTypes.bool,
  underlined: PropTypes.bool,
  compRef: PropTypes.object,
  disabled: PropTypes.bool,
};

TextField.defaultProps = {
  value: '',
  label: '',
  className: '',
  style: {},
  required: false,
  errorMessage: '',
  description: '',
  borderless: false,
  underlined: false,
  onChange: () => {},
  onClick: () => {},
  onBlur: () => {},
  onRenderDescription: undefined,
  compRef: null,
  disabled: false,
};

export default TextField;
