const isFederalJfc = (committeeObj) => {
  return [
    'jfc-disburses-to-candidate-auth-committee',
    'jfc-disburses-to-no-candidate-auth-committee',
  ].includes(committeeObj.type);
};

const isFederalPac = (committeeObj) => {
  return [
    'pac-separate-segregated-fund',
    'pac-support-oppose-more-than-one',
    'independent-expenditure-only-super-pac',
    'contribution-and-non-contribution-hybrid-pac',
  ].includes(committeeObj.type);
};

module.exports = {
  isFederalJfc,
  isFederalPac,
};
