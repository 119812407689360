import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'office-ui-fabric-react/lib/Dropdown';
import { CaretDown } from '../../icons';

export const BudgetCategoryPicker = ({
  onChange,
  selectedKey,
  required,
  label,
  errorMessage,
  options,
}) => {
  return (
    <Dropdown
      onRenderCaretDown={() => <CaretDown />}
      label={label}
      onChange={onChange}
      required={required}
      selectedKey={selectedKey}
      errorMessage={errorMessage}
      options={[{ key: '', text: 'Select' }, ...options]}
    />
  );
};

BudgetCategoryPicker.propTypes = {
  selectedKey: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  label: PropTypes.string,
  options: PropTypes.array,
  errorMessage: PropTypes.string,
};

BudgetCategoryPicker.defaultProps = {
  selectedKey: { key: 0 },
  required: false,
  label: 'Salutation',
  errorMessage: '',
  options: [],
};

export default BudgetCategoryPicker;
