import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { Grid, GridRow, Column } from '../../common';

export default class Section14 extends Component {
  static propTypes = {
    q14: PropTypes.shape({
      amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  };

  render() {
    const { q14 } = this.props;

    return (
      <GridRow>
        <Column sm={1} classNames="ms-textAlignRight">
          <h3 className="form-label">14</h3>
        </Column>
        <Column sm={11}>
          <Grid>
            <GridRow>
              <Column sm={8}>
                <p>
                  {
                    'Total value of investments held at the end of this reporting period.'
                  }
                </p>
              </Column>
              <Column sm={2} classNames="ms-textAlignCenter money-input">
                <TextField
                  borderless
                  inputMode="decimal"
                  styles={{ padding: '0 4px', field: { textAlign: 'center' } }}
                />
              </Column>
              <Column sm={2} classNames="ms-textAlignCenter money-input">
                <TextField
                  borderless
                  inputMode="decimal"
                  value={q14.amount}
                  styles={{
                    padding: '0 4px',
                    fieldGroup: { borderBottom: '1px solid #999' },
                    field: { textAlign: 'center' },
                  }}
                />
              </Column>
            </GridRow>
          </Grid>
        </Column>
      </GridRow>
    );
  }
}
