import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import {
  TextField,
  DetailsList,
  SelectionMode,
  CheckboxVisibility,
  DetailsRow,
} from 'office-ui-fabric-react';
import { sortDirections } from '../../helpers/constants';
import { ContributionsPanel } from '../Panels';
import {
  formatCurrency,
  formatDate,
  formatDateTime,
  sortByField,
} from '../../helpers/util';
import { searchValueForTerm } from '../../helpers/listsHelper';
import { getLabel } from '../../helpers/labelHelper';

export const ContributionsList = ({
  editItem,
  contributions,
  filterFn,
  session,
  isHidden,
}) => {
  if (isHidden) {
    return null;
  }

  const [state, setState] = useState({
    filterText: '',
    sortField: 'sortName',
    sortDirection: sortDirections.ASC,
    showPanel: false,
    panelItem: null,
  });

  const mapContributions = () => {
    const { filterText, sortDirection, sortField } = state;
    const searchTerm = filterText.toLowerCase();

    return contributions.contributions
      .filter(
        c =>
          searchValueForTerm(c.displayName, searchTerm) ||
          searchValueForTerm(c.checkNumber, searchTerm) ||
          searchValueForTerm(c.amount, searchTerm) ||
          searchValueForTerm(c.paymentReferenceId, searchTerm) ||
          searchValueForTerm(c.sourceCode, searchTerm),
      )
      .filter(filterFn || (() => true))
      .map(c => ({
        ...c,
        key: c._id,
        checkNumber: c.checkNumber || '',
        paymentReferenceId: c.paymentReferenceId || '',
      }))
      .sort(sortByField(sortField, sortDirection));
  };

  const onColumnClick = (e, column) => {
    const field = column.fieldName;
    let direction;
    if (state.sortField === column.fieldName) {
      if (state.sortDirection === sortDirections.ASC) {
        direction = sortDirections.DESC;
      } else if (state.sortDirection === sortDirections.DESC) {
        direction = sortDirections.NONE;
      } else {
        direction = sortDirections.ASC;
      }
    } else {
      direction = sortDirections.ASC;
    }

    setState({
      ...state,
      sortField: field,
      sortDirection: direction,
    });
  };

  const filterContributions = (e, value) => {
    setState({
      ...state,
      filterText: value,
    });
  };

  const showPanel = item => {
    setState({
      ...state,
      showPanel: true,
      panelItem: item,
    });
  };

  const hidePanel = () => {
    setState({
      ...state,
      showPanel: false,
      panelItem: null,
    });
  };

  const createColumns = () => {
    const { sortField, sortDirection } = state;
    return [
      {
        key: 'contributor-name',
        name: 'Contributor Name',
        fieldName: 'displayName',
        data: 'string',
        minWidth: 165,
        isResizable: true,
        isSorted:
          sortField === 'displayName' &&
          sortDirection !== sortDirections.NONE,
        isSortedDescending: sortDirection === sortDirections.DESC,
        onColumnClick,
        onRender: item => <span>{item.displayName}</span>,
      },
      {
        key: 'amount',
        name: 'Amount',
        fieldName: 'amount',
        data: 'number',
        minWidth: 100,
        isResizable: true,
        isSorted:
          sortField === 'amount' && sortDirection !== sortDirections.NONE,
        isSortedDescending: sortDirection === sortDirections.DESC,
        onColumnClick,
        onRender: item => (
          <span style={{ textAlign: 'right', display: 'block' }}>
            {formatCurrency(item.amount)}
          </span>
        ),
      },
      {
        key: 'checkNumber',
        name: 'Check Number',
        fieldName: 'checkNumberSort',
        data: 'string',
        minWidth: 100,
        isResizable: true,
        isSorted:
          sortField === 'checkNumberSort' &&
          sortDirection !== sortDirections.NONE,
        isSortedDescending: sortDirection === sortDirections.DESC,
        onColumnClick,
        onRender: item => <span>{item.checkNumber}</span>,
      },
      {
        key: 'referenceId',
        name: 'Reference Number',
        fieldName: 'paymentReferenceId',
        isResizable: true,
        minWidth: 150,
        isSorted:
          sortField === 'paymentReferenceId' &&
          sortDirection !== sortDirections.NONE,
        isSortedDescending: sortDirection === sortDirections.DESC,
        onColumnClick,
        onRender: item => <span>{item.paymentReferenceId}</span>,
      },
      {
        key: 'sourceCode',
        name: 'Source Code',
        fieldName: 'sourceCode',
        isResizable: true,
        minWidth: 150,
        isSorted:
          sortField === 'sourceCode' &&
          sortDirection !== sortDirections.NONE,
        isSortedDescending: sortDirection === sortDirections.DESC,
        onColumnClick,
        onRender: item => <span>{item.sourceCode}</span>,
      },
      {
        key: 'date-received',
        name: 'Date Received',
        fieldName: 'receivedDate',
        minWidth: 100,
        isResizable: true,
        isSorted:
          sortField === 'receivedDate' && sortDirection !== sortDirections.NONE,
        isSortedDescending: sortDirection === sortDirections.DESC,
        onColumnClick,
        onRender: item => <span>{formatDate(item.receivedDate)}</span>,
      },
      {
        key: 'date-entered-updated',
        name: 'Date Entered/Updated',
        fieldName: 'updatedAt',
        data: 'string',
        isResizable: true,
        minWidth: 150,
        isSorted:
          sortField === 'updatedAt' && sortDirection !== sortDirections.NONE,
        isSortedDescending: sortDirection === sortDirections.DESC,
        onColumnClick,
        onRender: item => <span>{formatDateTime(item.updatedAt)}</span>,
      },
    ];
  };

  const onRenderRow = rowProps => (
    <div onClick={() => showPanel(rowProps.item)}>
      <DetailsRow {...rowProps} />
    </div>
  );

  return (
    <Fragment>
      <TextField
        placeholder={`Search ${getLabel('Contributions', session)}`}
        onChange={filterContributions}
      />
      <DetailsList
        items={mapContributions()}
        columns={createColumns()}
        compact={false}
        selectionMode={SelectionMode.none}
        checkboxVisibility={CheckboxVisibility.none}
        onRenderRow={onRenderRow}
      />
      <ContributionsPanel
        item={state.panelItem}
        showPanel={state.showPanel}
        closePanel={hidePanel}
        editItem={editItem}
        session={session}
      />
    </Fragment>
  );
};

ContributionsList.propTypes = {
  editItem: PropTypes.func.isRequired,
  contributions: PropTypes.object,
  filterFn: PropTypes.func,
  session: PropTypes.object.isRequired,
  isHidden: PropTypes.bool,
};

ContributionsList.defaultProps = {
  contributions: {
    contributions: [],
  },
  filterFn: null,
  isHidden: false,
};

export default ContributionsList;
