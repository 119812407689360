export const reportingPeriods = [
  {
    id: '0',
    group: 'Non Election Year',
    desc: 'January 31st',
    cutoffMonth: 1,
    cutoffDay: 31,
    gaStateFilingCode: 'NEJan31',
    order: 0,
  },
  {
    id: '1',
    group: 'Non Election Year',
    desc: 'June 30th',
    cutoffMonth: 6,
    cutoffDay: 30,
    gaStateFilingCode: 'NEJun30',
    order: 1,
  },
  {
    id: '19',
    group: 'Non Election Year',
    desc: 'December 31st',
    cutoffMonth: 12,
    cutoffDay: 31,
    gaStateFilingCode: 'NEDec31',
    order: 2,
  },
  {
    id: '2',
    group: 'Election Year',
    desc: 'January 31st',
    cutoffMonth: 1,
    cutoffDay: 31,
    gaStateFilingCode: 'EYJan31',
    order: 3,
  },
  {
    id: '3',
    group: 'Election Year',
    desc: 'April 30th',
    cutoffMonth: 4,
    cutoffDay: 30,
    gaStateFilingCode: 'EYMar31',
    order: 4,
  },
  {
    id: '4',
    group: 'Election Year',
    desc: 'June 30th',
    cutoffMonth: 6,
    cutoffDay: 30,
    gaStateFilingCode: 'EYJun30',
    order: 5,
  },
  {
    id: '5',
    group: 'Election Year',
    desc: 'September 30th',
    cutoffMonth: 9,
    cutoffDay: 30,
    gaStateFilingCode: 'EYSep30',
    order: 6,
  },
  {
    id: '6',
    group: 'Election Year',
    desc: 'October 25th',
    cutoffMonth: 10,
    cutoffDay: 25,
    gaStateFilingCode: 'EYOct25',
    order: 7,
  },
  {
    id: '7',
    group: 'Election Year',
    desc: 'December 31st',
    cutoffMonth: 12,
    cutoffDay: 31,
    gaStateFilingCode: 'EYDec31',
    order: 8,
  },
  {
    id: '6309',
    group: 'Supplemental (if applicable)',
    desc: 'June 30th',
    cutoffMonth: 6,
    cutoffDay: 30,
    gaStateFilingCode: 'IndepJun30',
    order: 4,
  },
  {
    id: '8',
    group: 'Run-Offs',
    desc: '6 days before Primary Run-Off',
    gaStateFilingCode: 'RO6DBP',
    order: 9,
  },
  {
    id: '9',
    group: 'Run-Offs',
    desc: '6 days before General Run-Off',
    gaStateFilingCode: 'RO6DBG',
    order: 10,
  },
  {
    id: '10',
    group: 'Run-Offs',
    desc: '6 days before Special Primary Run-Off',
    gaStateFilingCode: 'RO6DBSP',
    order: 11,
  },
  {
    id: '11',
    group: 'Run-Offs',
    desc: '6 days before Special Run-Off',
    gaStateFilingCode: 'RO6DBS',
    order: 12,
  },
  {
    id: '12',
    group: 'Special Election',
    desc: '15 days before Special Primary',
    gaStateFilingCode: 'SE15DBSP',
    order: 13,
  },
  {
    id: '13',
    group: 'Special Election',
    desc: '15 days before Special',
    gaStateFilingCode: 'SE15DBS',
    order: 14,
  },
  {
    id: '14',
    group: 'Special Election',
    desc: 'December 31st',
    gaStateFilingCode: 'SEDEC31',
    order: 15,
  },
  {
    id: '15',
    group: 'Supplemental (if applicable)',
    desc: 'June 30th',
    cutoffMonth: 6,
    cutoffDay: 30,
    gaStateFilingCode: 'IndepJun30',
    order: 16,
  },
  {
    id: '16',
    group: 'Supplemental (if applicable)',
    desc: 'December 31st',
    cutoffMonth: 12,
    cutoffDay: 31,
    gaStateFilingCode: 'IndepDec31',
    order: 17,
  },
  {
    id: '17',
    group: 'Two Business Day Reporting',
    desc: 'Contributions',
    cutoffMonth: 0,
    cutoffDay: 0,
    gaStateFilingCode: '',
    order: 18,
  },
  {
    id: '18',
    group: 'Termination Report',
    desc: 'Final',
    gaStateFilingCode: 'FINAL',
    order: 19,
  },
];
