import axios from 'axios';
import { goBack, push } from 'connected-react-router';
import { call, put, takeLatest } from 'redux-saga/effects';
import { types } from '../actions/importWizardActions';
import { ImportProgressStep, ImportStatus } from '../helpers/constants';
import {
  errorToast,
  getServerSideErrorMessage,
  infoToast,
} from '../helpers/util';
import { importStatusWatchWorker } from './importPollingSaga';

const url = '/api/filer/contributions/import';

export function* handleUploadImportFile(action) {
  try {
    yield put({
      type: types.UPLOAD_IMPORT_FILE_PROCESSING,
      data: { fileName: action.data.file.name },
    });

    const formData = new FormData();
    formData.append('file', action.data.file);
    formData.append('fileName', action.data.file.name);
    formData.append('fileSize', action.data.file.size);

    const { data } = yield call(axios.post, url, formData, {
      withCredentials: true,
    });
    yield put({ type: types.UPLOAD_IMPORT_FILE_SUCCESS, data });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put(errorToast(error));
    yield put({ type: types.UPLOAD_IMPORT_FILE_FAILURE, error });
  }
}

export function* uploadImportFile() {
  yield takeLatest(types.UPLOAD_IMPORT_FILE, handleUploadImportFile);
}

export function* handleSaveImportConfig(action) {
  try {
    yield put({ type: types.SAVE_IMPORT_CONFIG_PROCESSING });
    yield call(
      axios.put,
      `${url}/${action.data.fileId}/config`,
      action.data.config,
      {
        withCredentials: true,
      },
    );
    yield put({ type: types.SAVE_IMPORT_CONFIG_SUCCESS, data: action.data });
    yield put({
      type: types.GET_UPLOAD_RESULT,
      data: { fileId: action.data.fileId },
    });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put(errorToast(error));
    yield put({ type: types.SAVE_IMPORT_CONFIG_FAILURE, error });
  }
}

export function* saveImportConfig() {
  yield takeLatest(types.SAVE_IMPORT_CONFIG, handleSaveImportConfig);
}

export function* handleGetUploadResult(action) {
  try {
    yield put({ type: types.GET_UPLOAD_RESULT_PROCESSING });
    const { data } = yield call(
      axios.get,
      `${url}/${action.data.fileId}/preview`,
      {
        withCredentials: true,
      },
    );
    yield put({ type: types.GET_UPLOAD_RESULT_SUCCESS, data });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put(errorToast(error));
    yield put({ type: types.GET_UPLOAD_RESULT_FAILURE, error });
  }
}

export function* getUploadResult() {
  yield takeLatest(types.GET_UPLOAD_RESULT, handleGetUploadResult);
}

export function* handleMapImportColumns(action) {
  try {
    yield put({ type: types.MAP_IMPORT_COLUMNS_PROCESSING });
    const payload = { columns: action.data.columns };
    yield call(axios.put, `${url}/${action.data.fileId}/mapping`, payload, {
      withCredentials: true,
    });
    yield put({ type: types.MAP_IMPORT_COLUMNS_SUCCESS });
    yield put(push('/filer/dev/importList'));

    yield put(infoToast('File processing started'));
    yield call(importStatusWatchWorker, action.data.fileId);
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put(errorToast(error));
    yield put({ type: types.MAP_IMPORT_COLUMNS_FAILURE, error });
  }
}

export function* mapImportColumns() {
  yield takeLatest(types.MAP_IMPORT_COLUMNS, handleMapImportColumns);
}

export function* handleGetEditedImportData(action) {
  try {
    yield put({ type: types.GET_EDITED_IMPORT_DATA_PROCESSING });
    const { fileId, dataType } = action.data;
    const {
      data: { status },
    } = yield call(axios.get, `${url}/${fileId}/status`, {
      withCredentials: true,
    });

    let data = {};

    if (status === ImportStatus.Completed || status === ImportStatus.Failed || status === ImportStatus.Pending) {
      yield put(errorToast('Insufficient status for editing import file'));
      yield put(goBack());
    } else if (status === ImportStatus.Created) {
      const { data: config } = yield call(
        axios.get,
        `${url}/${fileId}/config`,
        { withCredentials: true },
      );

      data = { step: ImportProgressStep.FileFormat, config: config || {}, fileId, status, dataType };
    } else {
      yield call(handleGetUploadResult, action);
      data = { fileId, status, dataType };
    }

    yield put({ type: types.GET_EDITED_IMPORT_DATA_SUCCESS, data });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put(errorToast(error));
    yield put({ type: types.GET_EDITED_IMPORT_DATA_FAILURE, error });
  }
}

export function* getEditedImportData() {
  yield takeLatest(
    types.GET_EDITED_IMPORT_DATA,
    handleGetEditedImportData,
  );
}
