import React, { Fragment, useEffect, useState, useRef } from 'react';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import { IconButton, NormalPeoplePicker, Persona } from 'office-ui-fabric-react';
import { Label } from 'office-ui-fabric-react/lib/Label';
import { useSelector, useDispatch } from 'react-redux';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import PropTypes from 'prop-types';
import EarmarkSummaryRow from './EarmarkSummaryRow';
import Tip from '../../../components/common/Tip';

import { statuses } from '../../../helpers/constants';
import { Grid, GridRow, Column, BackButton } from '../../../components/common';
import { YearPicker, FECElectionCyclePicker, DatePicker } from '../../../components/Pickers';
import { CaretDown } from '../../../components/icons';

import { types as importActions } from '../../../actions/importActions';
import { useContactSearch } from '../../../hooks/useContactSearch';

import './earmarks.css';

const Earmarks = ({ history }) => {
  const dispatch = useDispatch();
  const importData = useSelector(state => state.importdata);
  const filePicker = useRef(null);

  const [state, setState] = useState({
    spreadsheetFile: null,
    loaded: false,
    unprocessedEarmarkRecordsSummary: {},
    election: null,
    electionYear: null,
    depositDate: null,
    conduitContact: null,
    sourceReference: '',
    isUploadingFile: false,
    isApplyingUpdates: false,
    tag: '',
  });

  useEffect(() => {
    dispatch({
      type: importActions.GET_UNPROCESSED_EARMARKS_SUMMARY,
    });
  }, []);

  const formIsValid = () => {
    return (
      state.electionCycle &&
      state.electionYear &&
      state.depositDate &&
      state.conduitContact &&
      state.sourceReference &&
      state.tag);
  };

  const requestUpdate = (fileHash) => {
    const {
      electionCycle,
      electionYear,
      depositDate,
      conduitContact,
      sourceReference,
      tag,
    } = state;

    dispatch({
      type: importActions.APPLY_EARMARK_IMPORT_RECORDS,
      data: {
        fileHash,
        payload: {
          electionCycle,
          electionYear,
          depositDate,
          conduitContactId: conduitContact._id,
          sourceReference,
          tag,
        },
      },
    });
  };

  const requestClear = (fileHash) => {
    dispatch({
      type: importActions.REMOVE_EARMARK_IMPORT_RECORDS,
      data: { fileHash },
    });
  };

  const getCurrentUnprocessedEarmarks = (recs) => {
    const keys = Object.keys(recs);
    const rows = keys.map(k => {
      return <EarmarkSummaryRow
        key={k}
        fileHash={k}
        amount={recs[k].totalAmount}
        count={recs[k].totalCount}
        formValid={formIsValid()}
        requestUpdate={requestUpdate}
        requestClear={requestClear}
      />;
    });
    return rows;
  };

  useEffect(() => {
    const {
      getUnprocessedEarmarksStatus,
      unprocessedEarmarks,
    } = importData;
    if (getUnprocessedEarmarksStatus === statuses.SUCCESS) {
      setState(state => {
        return {
          ...state,
          unprocessedEarmarkRecordsSummary: unprocessedEarmarks.data,
        };
      });
    }
  }, [importData.getUnprocessedEarmarksStatus, importData.unprocessedEarmarks]);

  useEffect(() => {
    const { uploadEarmarkSpreadsheetStatus } = importData;
    if (uploadEarmarkSpreadsheetStatus === statuses.PROCESSING) {
      setState(state => {
        return {
          ...state,
          isUploadingFile: true,
        };
      });
    }

    if (uploadEarmarkSpreadsheetStatus === statuses.ERROR) {
      setState(state => {
        return {
          ...state,
          isUploadingFile: false,
        };
      });
    }

    if (uploadEarmarkSpreadsheetStatus === statuses.SUCCESS) {
      filePicker.current.value = '';
      setState(state => {
        return {
          ...state,
          spreadsheetFile: null,
          isUploadingFile: false,
        };
      });
    }
  }, [importData.uploadEarmarkSpreadsheetStatus]);

  useEffect(() => {
    const { backgroundEarmarkImportProcess } = importData;
    if (backgroundEarmarkImportProcess === statuses.PROCESSING) {
      setState(state => {
        return {
          ...state,
          isApplyingUpdates: true,
        };
      });
    } else if (backgroundEarmarkImportProcess === statuses.SUCCESS) {
      setState(state => {
        return {
          ...state,
          unprocessedEarmarkRecordsSummary: {},
          isApplyingUpdates: false,
        };
      });
    } else {
      setState(state => {
        return {
          ...state,
          isApplyingUpdates: false,
        };
      });
    }
  }, [importData.backgroundEarmarkImportProcess]);

  const onChangeHandler = (event) => {
    const chosenFile = event.target.files[0];
    const formData = new FormData();
    formData.append('file', chosenFile);
    formData.set('name', chosenFile.name);
    formData.set('size', chosenFile.size);
    formData.set('lastModified', chosenFile.lastModified);
    formData.set('type', chosenFile.type);
    setState({ ...state, spreadsheetFile: formData });
  };

  const onClickHandler = async () => {
    dispatch({
      type: importActions.UPLOAD_EARMARK_SPREADSHEET,
      data: state.spreadsheetFile,
    });
  };

  const [onResolveSuggestions, onRenderSuggestionsItem] = useContactSearch(
    null,
    null,
    null,
    'Contribution',
  );

  const handleChange = fieldName => (e, value) => {
    setState({
      ...state,
      [fieldName]: value.key !== undefined ? value.key : value,
    });
  };

  const handleChangeDate = fieldName => date => {
    setState({
      ...state,
      [fieldName]: date,
    });
  };

  const onItemSelected = contact => {
    setState({
      ...state,
      conduitContact: contact,
    });
    return contact;
  };

  const onRemoveSearchedContact = removeFn => {
    setState({
      ...state,
      conduitContact: null,
    });
    removeFn();
  };

  const onRenderItem = itemProps => {
    const { displayName, _id } = itemProps.item;

    const name = `${displayName} (Id: ...${_id.substring(_id.length - 4, _id.length + 1)})`;
    return (
      <div className="chosen-contact" key={itemProps.item._id}>
        <Persona text={name} size={10} />
        <IconButton
          iconProps={{ iconName: 'Times' }}
          onClick={() => onRemoveSearchedContact(itemProps.onRemoveItem)}
        />
      </div>
    );
  };

  const fileTip = 'Upload file must be of type xlsx with headers that match the template';
  const templateLinkStyle = {
    display: 'inline-block',
    marginLeft: '11px',
    fontSize: '14px',
  };

  return (
    <Fragment>
      <BackButton history={history} message="Cancel" />
      <div className="Earmarks">
        <h2>Import Earmark Records From Spreadsheet</h2>
        <Grid>
          <GridRow>
            <Column>
              <h3>Upload spreadsheet <Tip style={{ primaryColor: 'darkgrey' }} content={fileTip} /><a style={templateLinkStyle} target="_blank" rel="noopener noreferrer" href="/api/filer/earmarkTemplate">Template XLSX</a></h3>

              <input
                className="file-input"
                ref={filePicker}
                type="file"
                onChange={onChangeHandler}
                label="Select Spreadsheet File"
              />
              <PrimaryButton
                type="button"
                onClick={onClickHandler}
                disabled={!filePicker?.current?.value || state.isUploadingFile}
              >
                Upload Spreadsheet
              </PrimaryButton>
            </Column>
          </GridRow>
        </Grid>
        <Grid className="section">
          <GridRow>
            <h3>Apply to all imported records</h3>
            <Column md={4}>
              <FECElectionCyclePicker
                label="Election"
                required
                value={state.electionCycle}
                onChange={handleChange('electionCycle')}
                placeHolder="Select"
                onRenderCaretDown={() => <CaretDown />}
              />
            </Column>
            <Column md={4}>
              <YearPicker
                onChange={handleChange('electionYear')}
                required
                label="Election Year"
                value={state.electionYear}
              />
            </Column>
            <Column md={4}>
              <DatePicker
                label="Deposit Date"
                value={state.depositDate}
                placeholder="Select Date"
                onChange={handleChangeDate('depositDate')}
                required
              />
            </Column>
          </GridRow>
          <GridRow>
            <Column md={4}>
              <Label required>Conduit Contact</Label>
              <NormalPeoplePicker
                itemLimit={1}
                onResolveSuggestions={onResolveSuggestions}
                onRenderSuggestionsItem={onRenderSuggestionsItem}
                onItemSelected={onItemSelected}
                onRenderItem={onRenderItem}
              />
            </Column>
            <Column md={4}>
              <TextField
                label="Source Reference"
                value={state.sourceReference}
                required
                onChange={handleChange('sourceReference')}
              />
            </Column>
            <Column lg={4}>
              <TextField
                label="Tag"
                value={state.tag}
                required
                onChange={handleChange('tag')}
              />
            </Column>
          </GridRow>
        </Grid>
        <Grid className="section">
          <GridRow>
            {!state.isApplyingUpdates &&
            <Column>
              <h3>Uploaded files ready to import</h3>
              <div>{getCurrentUnprocessedEarmarks(state.unprocessedEarmarkRecordsSummary)}</div>
            </Column>
            }
            {state.isApplyingUpdates &&
            <Column>
              <span>
                Applying records may take several minutes depending upon the size of the uploaded spreadsheet.
                <Spinner size={SpinnerSize.medium} />
              </span>
            </Column>
            }
          </GridRow>
        </Grid>
      </div>

    </Fragment>
  );
};

Earmarks.propTypes = {
  history: PropTypes.object.isRequired,
};

EarmarkSummaryRow.propTypes = {
  fileHash: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  requestUpdate: PropTypes.func.isRequired,
  requestClear: PropTypes.func.isRequired,
};

export default Earmarks;
