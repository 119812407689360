import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { Grid, GridRow, Column } from '../../common';
import WarningBox from './WarningBox';

export default class Section4 extends Component {
  static propTypes = {
    q4: PropTypes.shape({
      inKind: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }).isRequired,
    handleChange: PropTypes.func.isRequired,
  };

  constructor(...args) {
    super(...args);

    this.inKindBox = React.createRef();
    this.amountBox = React.createRef();
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextProps.q4.amount !== this.props.q4.amount ||
      nextProps.q4.amount !== this.props.q4.amount ||
      nextState.inKindCalloutVisible !== this.state.inKindCalloutVisible ||
      nextState.amountCalloutVisible !== this.state.amountCalloutVisible ||
      nextState.canEdit !== this.state.canEdit
    );
  }

  state = {
    inKindCalloutVisible: false,
    amountCalloutVisible: false,
    canEdit: false,
  };

  openCallout = name => {
    this.setState(() => ({
      [`${name}CalloutVisible`]: true,
    }));
  };

  closeCallout = name => () => {
    this.setState(() => ({
      [`${name}CalloutVisible`]: false,
    }));
  };

  acceptEdit = () => {
    this.setState({
      canEdit: true,
      inKindCalloutVisible: false,
      amountCalloutVisible: false,
    });
  };

  render() {
    const { q4, handleChange } = this.props;
    const { inKindCalloutVisible, amountCalloutVisible, canEdit } = this.state;

    return (
      <GridRow>
        <Column sm={1} classNames="ms-textAlignRight">
          <h3 className="form-label">4</h3>
        </Column>
        <Column sm={11}>
          <Grid>
            <GridRow>
              <Column sm={8}>
                <p>
                  {`Total amount of all separate contributions of $100 or less received in this reporting period and
                  not listed in the "Itemized Contributions" section. "Common Source" contributions must be aggregated on
                  the "Itemized Contributions" section.`}
                </p>
              </Column>
              <Column sm={2} classNames="ms-textAlignCenter money-input">
                <span ref={this.inKindBox}>
                  <TextField
                    borderless
                    inputMode="decimal"
                    value={q4.inKind}
                    styles={{
                      padding: '0 4px',
                      marginTop: 8,
                      fieldGroup: { borderBottom: '1px solid #999' },
                      field: { textAlign: 'center' },
                    }}
                    onChange={canEdit ? handleChange('q4')('inKind') : () => {}}
                    onClick={() => this.openCallout('inKind')}
                  />
                </span>
              </Column>
              <Column sm={2} classNames="ms-textAlignCenter money-input">
                <span ref={this.amountBox}>
                  <TextField
                    borderless
                    inputMode="decimal"
                    value={q4.amount}
                    styles={{
                      padding: '0 4px',
                      marginTop: 8,
                      fieldGroup: { borderBottom: '1px solid #999' },
                      field: { textAlign: 'center' },
                    }}
                    onChange={canEdit ? handleChange('q4')('amount') : () => {}}
                    onClick={() => this.openCallout('amount')}
                  />
                </span>
              </Column>
            </GridRow>
          </Grid>
          {inKindCalloutVisible && !canEdit ? (
            <WarningBox
              target={this.inKindBox.current}
              onDismiss={this.closeCallout('inKind')}
              onAccept={this.acceptEdit}
            />
          ) : null}
          {amountCalloutVisible && !canEdit ? (
            <WarningBox
              target={this.amountBox.current}
              onDismiss={this.closeCallout('amount')}
              onAccept={this.acceptEdit}
            />
          ) : null}
        </Column>
      </GridRow>
    );
  }
}
