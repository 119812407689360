import { toastTypes } from '../helpers/constants';

export const types = {
  SET_ALERT: 'SET_ALERT',
  CLEAR_ALERT: 'CLEAR_ALERT',
};

export const actions = {
  setSuccessAlert(message) {
    return {
      type: types.SET_ALERT,
      data: { message, alertType: toastTypes.SUCCESS },
    };
  },

  setInfoAlert(message) {
    return {
      type: types.SET_ALERT,
      data: { message, alertType: toastTypes.INFO },
    };
  },

  setErrorAlert(message) {
    return {
      type: types.SET_ALERT,
      data: { message, alertType: toastTypes.ERROR },
    };
  },

  setWarningAlert(message) {
    return {
      type: types.SET_ALERT,
      data: { message, alertType: toastTypes.WARNING },
    };
  },

  clear() {
    return { type: types.CLEAR_ALERT };
  },
};
