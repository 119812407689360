import React, { Fragment, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { IconButton, CommandButton } from 'office-ui-fabric-react/lib/Button';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { ContextualMenuItemType } from 'office-ui-fabric-react/lib/ContextualMenu';
import { Panel, PanelType } from 'office-ui-fabric-react/lib/Panel';
import { SearchBox } from 'office-ui-fabric-react/lib/SearchBox';
import { Image } from 'office-ui-fabric-react/lib/Image';
import { Link } from 'office-ui-fabric-react/lib/Link';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions as currentCampaignActions } from '../../actions/currentCampaignActions';
import { actions as longRunningProcessActions } from '../../actions/longRunningProcessActions';
import { actions as electionCycleActions } from '../../actions/electionCycleActions';
import { actions as reportingPeriodActions } from '../../actions/reportingPeriodActions';
import { userActions } from '../../actions/userActions';
import DashboardRoutes from './DashboardRoutes';
import { Toast } from '../../components/Toast';
import Alert from '../../components/Alert';
import {
  ChangePasswordDialog,
  LongRunningProcessDialog,
} from '../../components/Dialogs';
import FRLogo from '../../assets/img/HRFrontRunner.png';
import { sortByField } from '../../helpers/util';
import { statuses } from '../../helpers/constants';
import { CaretDown } from '../../components/icons';
import { transformCampaignsForSelection } from './dashboardHelpers';
import {
  initialState,
  actions,
  selectedCampaignReducer,
} from './selectedCampaignReducer';
import './Dashboard.css';

export const Dashboard = ({
  history,
  currentCampaignActions,
  currentCampaign,
  getCurrentCampaignStatus,
  userActions,
  electionCycleActions,
  reportingPeriodActions,
  user,
  longRunningProcess,
  longRunningProcessActions,
  messages,
  alerts,
}) => {
  const [state, localDispatch] = useReducer(
    selectedCampaignReducer,
    initialState,
  );

  useEffect(() => {
    window.Intercom('update');
    if (!user.isAuthenticated) {
      history.replace('/');
    }
  }, []);

  const closeDialog = async () => {
    if (longRunningProcess.onCloseCallback) {
      await longRunningProcess.onCloseCallback(longRunningProcess);
    }

    longRunningProcessActions.closeMessaging();
  };

  const handleCampaignSelection = campaign => {
    history.push('/filer');
    currentCampaignActions.setCurrentCampaignAsProcessing();
    currentCampaignActions.adminSelectCampaign(campaign.key);
    localDispatch({
      type: actions.SET_SELECTED_CAMPAIGN,
      data: {
        campaign,
        allCampaigns: currentCampaign.allCampaigns.map(
          transformCampaignsForSelection,
        ),
      },
    });
  };

  useEffect(() => {
    if (user.session) {
      if (currentCampaign.allCampaigns.length === 0) {
        currentCampaignActions.getAllCampaigns();
      } else if (
        !user.session.isSysAdminRole() &&
        currentCampaign.allCampaigns.length === 1 &&
        !currentCampaign.campaign &&
        getCurrentCampaignStatus === statuses.NOT_STARTED
      ) {
        currentCampaignActions.getCurrentCampaign();
      } else if (
        user.session.campaignId &&
        !currentCampaign.campaign &&
        currentCampaign.allCampaigns.length > 1) {
        const cc = state.allCampaigns.find(c => c.key === user.session.campaignId);
        if (state.selectedCampaign.key === 0 && cc?.key) {
          handleCampaignSelection(cc);
        }
        if (cc?.key) {
          electionCycleActions.getElectionCycles();
          reportingPeriodActions.getReportingPeriods();
        }
      }
    }
  }, [user.session, currentCampaign, getCurrentCampaignStatus]);

  useEffect(() => {
    localDispatch({
      type: actions.SET_ALL_CAMPAIGNS,
      data: {
        allCampaigns: currentCampaign.allCampaigns.map(
          transformCampaignsForSelection,
        ),
      },
    });
  }, [currentCampaign.allCampaigns]);

  const openChangePasswordDialog = () => {
    localDispatch({
      type: actions.TOGGLE_HIDE_CHANGE_PASSWORD_DIALOG,
      data: {
        changePasswordDialogHidden: false,
      },
    });
  };

  const closeChangePasswordDialog = () => {
    localDispatch({
      type: actions.TOGGLE_HIDE_CHANGE_PASSWORD_DIALOG,
      data: {
        changePasswordDialogHidden: true,
      },
    });
  };

  const onChangePassword = (oldPass, newPass) => {
    localDispatch({
      type: actions.TOGGLE_HIDE_CHANGE_PASSWORD_DIALOG,
      data: {
        changePasswordDialogHidden: true,
      },
    });
    userActions.changePassword(oldPass, newPass);
  };

  const logout = () => {
    userActions.logout();
  };

  const openUserPanel = () => {
    localDispatch({
      type: actions.TOGGLE_SHOW_USER_PANEL,
      data: {
        showUserPanel: true,
      },
    });
  };

  const closeUserPanel = () => {
    localDispatch({
      type: actions.TOGGLE_SHOW_USER_PANEL,
      data: {
        showUserPanel: false,
      },
    });
  };

  const handleTextChange = fieldName => (e, value) => {
    localDispatch({
      type: actions.HANDLE_CHANGE,
      data: {
        fieldName,
        value,
      },
    });
    // TODO; search action here
  };

  const onChangeCampaignSearch = (e, name) => {
    const filteredCampaigns = currentCampaign.allCampaigns
      .map(transformCampaignsForSelection)
      .filter(item => item.text.toLowerCase().includes(name.toLowerCase()));

    if (!filteredCampaigns || !filteredCampaigns.length) {
      filteredCampaigns.push({
        key: 'noResults',
        onRender: () => (
          <div
            key="no results"
            style={{
              width: '100%',
              height: 100,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <span>No Campaigns found</span>
          </div>
        ),
      });
    }

    localDispatch({
      type: actions.SET_ALL_CAMPAIGNS,
      data: {
        allCampaigns: filteredCampaigns,
      },
    });
  };

  const onAbortCampaignSearch = () => {
    localDispatch({
      type: actions.SET_ALL_CAMPAIGNS,
      data: {
        allCampaigns: currentCampaign.allCampaigns.map(
          transformCampaignsForSelection,
        ),
      },
    });
  };

  const onRenderMenuList = (menuListProps, defaultRender) => (
    <div>
      <div>
        <SearchBox
          placeholder="Search Campaigns"
          styles={{ root: { margin: 8 } }}
          onChange={onChangeCampaignSearch}
          onAbort={onAbortCampaignSearch}
          clearButtonProps={{
            iconProps: {
              iconName: 'Times',
            },
          }}
        />
      </div>
      {defaultRender(menuListProps)}
    </div>
  );

  const showSession = false;
  return (
    <Fragment>
      <div className="Dashboard">
        <div className="header">
          <div className="logo">
            <Link
              onClick={() => {
                history.push('/filer');
              }}
            >
              <Image src={FRLogo} height={55} />
            </Link>
          </div>
          {showSession &&
          <div>
            {JSON.stringify(user.session)}
          </div>
          }
          {user.session &&
            (user.session.isSysAdminRole() ||
              state.allCampaigns.length > 1) && (
              <div className="admin-campaign-select">
                <CommandButton
                  text={state.selectedCampaign.text || 'Select Campaign'}
                  onRenderMenuIcon={() => <CaretDown />}
                  menuProps={{
                    onRenderMenuList,
                    items: state.allCampaigns
                      .map(item => ({
                        ...item,
                        onClick: () => handleCampaignSelection(item),
                      }))
                      .sort(sortByField('text')),
                    onMenuDismissed: onAbortCampaignSearch,
                  }}
                  styles={{
                    root: {
                      border: '1px solid #919191',
                    },
                  }}
                />
              </div>
            )}
          {(currentCampaign.campaign ||
            (user.session && user.session.isSysAdminRole()) ||
            state.allCampaigns.length > 1) && (
            <div className="user-menu">
              {false && (
                <div className="search ms-hiddenSm ms-hiddenMd">
                  <TextField
                    onChange={handleTextChange('searchString')}
                    value={state.searchString}
                    placeholder="Search"
                  />
                </div>
              )}
              {false && (
                <IconButton
                  className="ms-hiddenLgUp"
                  onClick={openUserPanel}
                  iconProps={{
                    iconName: 'Search',
                  }}
                />
              )}
              <IconButton
                menuIconProps={{
                  iconName: 'CaretDown',
                }}
                menuProps={{
                  items: [
                    {
                      key: 'username',
                      text: user.session.username,
                    },
                    {
                      key: 'divider_1',
                      itemType: ContextualMenuItemType.Divider,
                    },
                    {
                      key: 'change-password',
                      text: 'Change Password',
                      iconProps: {
                        iconName: 'Lock',
                        className: 'ms-fontColor-themePrimary',
                      },
                      onClick: openChangePasswordDialog,
                    },
                    {
                      key: 'divider_2',
                      itemType: ContextualMenuItemType.Divider,
                    },
                    {
                      key: 'logout',
                      text: 'Logout',
                      iconProps: {
                        iconName: 'SignOutAlt',
                        className: 'ms-fontColor-themePrimary',
                      },
                      onClick: logout,
                    },
                  ].filter(Boolean),
                }}
                iconProps={{
                  iconName: 'UserAlt',
                }}
              />
            </div>
          )}
        </div>
        <div className="content">
          {currentCampaign.campaign === null &&
            state.allCampaigns.length > 1 &&
            user.session &&
            !user.session.isSysAdminRole() && (
              <div className="choose-a-campaign">
                <Icon
                  iconName="ChevronUp"
                  styles={{
                    root: {
                      fontSize: '32px',
                      marginRight: 16,
                      marginTop: 8,
                    },
                  }}
                />
                <h2>Choose a campaign to start</h2>
              </div>
            )}
          <DashboardRoutes />
        </div>
        {false && (
          <Panel
            isOpen={state.showUserPanel}
            type={PanelType.smallFluid}
            onDismiss={closeUserPanel}
          >
            <TextField
              onChanged={handleTextChange('searchString')}
              value={state.searchString}
            />
          </Panel>
        )}
        <LongRunningProcessDialog
          dialogHidden={!longRunningProcess.presentMessaging}
          onClose={closeDialog}
          isProcessing={longRunningProcess.status === statuses.PROCESSING}
          processResultStatus={longRunningProcess.status}
          messageList={longRunningProcess.messaging}
          prettyJobName={longRunningProcess.prettyJobName}
          error={longRunningProcess.error}
        />
        {messages.message && (
          <Toast
            message={messages.message}
            toastType={messages.toastType}
            position={messages.position}
          />
        )}
        {alerts.message && (
          <Alert message={alerts.message} toastType={alerts.toastType} />
        )}
      </div>
      <ChangePasswordDialog
        dialogHidden={state.changePasswordDialogHidden}
        closeDialog={closeChangePasswordDialog}
        changePassword={onChangePassword}
      />
    </Fragment>
  );
};

Dashboard.propTypes = {
  history: PropTypes.object.isRequired,
  currentCampaignActions: PropTypes.object.isRequired,
  currentCampaign: PropTypes.object.isRequired,
  getCurrentCampaignStatus: PropTypes.string.isRequired,
  userActions: PropTypes.object.isRequired,
  electionCycleActions: PropTypes.object.isRequired,
  reportingPeriodActions: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  messages: PropTypes.object.isRequired,
  alerts: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    currentCampaign: state.currentCampaign,
    getCurrentCampaignStatus: state.currentCampaign.getCurrentCampaignStatus,
    user: state.user,
    longRunningProcess: state.longRunningProcess,
    messages: state.messages,
    alerts: state.alerts,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    currentCampaignActions: bindActionCreators(
      currentCampaignActions,
      dispatch,
    ),
    longRunningProcessActions: bindActionCreators(
      longRunningProcessActions,
      dispatch,
    ),
    userActions: bindActionCreators(userActions, dispatch),
    electionCycleActions: bindActionCreators(electionCycleActions, dispatch),
    reportingPeriodActions: bindActionCreators(
      reportingPeriodActions,
      dispatch,
    ),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Dashboard),
);
