import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { BasePicker } from 'office-ui-fabric-react/lib/Pickers';
import { Checkbox } from 'office-ui-fabric-react/lib/Checkbox';
import { IconButton } from 'office-ui-fabric-react/lib/Button';
import { Label } from 'office-ui-fabric-react/lib/Label';

import { useContributionSearch } from '../../hooks/useContributionSearch';
import { useRefundContributionSearch } from '../../hooks/useRefundContributionSearch';

class ContributionBasePicker extends BasePicker { }

const ContributionPicker = ({
  checkboxLabel,
  pickerLabel,
  onItemSelected,
  selectedItems,
  removeSelectedItem,
  promptingRecordId,
}) => {
  const [checked, setChecked] = useState(false);
  const [hasItem, setHasItem] = useState(false);
  let onResolveContributionSuggestions;
  let onRenderSuggestionsContributionItem;
  let renderItem;
  if (promptingRecordId) {
    ([onResolveContributionSuggestions, onRenderSuggestionsContributionItem, renderItem] = useRefundContributionSearch(promptingRecordId));
  } else {
    ([onResolveContributionSuggestions, onRenderSuggestionsContributionItem, renderItem] = useContributionSearch());
  }

  useEffect(() => {
    if (selectedItems) {
      setChecked(true);
      setHasItem(true);
    }
  }, selectedItems);

  useEffect(() => {
    if (hasItem && !checked) {
      removeSelectedItem();
    }
  }, [checked]);

  const onRenderContribution = itemProps => {
    const { item: contribution } = itemProps;
    const deleteIcon = (<IconButton
      iconProps={{ iconName: 'Times' }}
      onClick={removeSelectedItem}
    />);
    return renderItem(contribution, deleteIcon);
  };

  return (
    <Fragment>
      <Checkbox
        checked={checked}
        label={checkboxLabel}
        onChange={() => setChecked(!checked)}
        checkmarkIconProps={{
          iconName: 'Check',
        }}
      />
      {(checked && !selectedItems) &&
      <>
        <Label>{pickerLabel}</Label>
        <ContributionBasePicker
          itemLimit={1}
          onRenderSuggestionsItem={onRenderSuggestionsContributionItem}
          onResolveSuggestions={onResolveContributionSuggestions}
          onItemSelected={onItemSelected}
          resolveDelay={500}
        />
      </>
      }
      {selectedItems &&
      onRenderContribution({ item: selectedItems })
      }
    </Fragment>
  );
};

ContributionPicker.propTypes = {
  checkboxLabel: PropTypes.string,
  pickerLabel: PropTypes.string,
  selectedItems: PropTypes.object,
  onItemSelected: PropTypes.func,
  removeSelectedItem: PropTypes.func,
  promptingRecordId: PropTypes.string,
};

export default ContributionPicker;
