import React, { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Loading from '../../components/Loading';
import { types as messagingActions } from '../../actions/messagingActions';
import { types as loanActions } from '../../actions/loanActions';
import { types as validationActions } from '../../actions/validationActions';
import { LoanForm } from '../../components/LoanForm/LoanForm';
import { getQueryParams } from '../../helpers/urlHelper';
import { scrollToTop } from '../../helpers/util';
import { statuses } from '../../helpers/constants';
import { initialState, actions, addLoanReducer } from './addLoanReducer';

export const AddLoan = ({ location, history }) => {
  const campaign = useSelector(state => state.currentCampaign.campaign);
  const loans = useSelector(state => state.loans);
  const session = useSelector(state => state.user.session);
  const validations = useSelector(state => state.validations);
  const messages = useSelector(state => state.messages);
  const [state, localDispatch] = useReducer(addLoanReducer, initialState);
  const reduxDispatch = useDispatch();

  useEffect(() => {
    if (state.electionYear === 0 && campaign) {
      const electionYear = campaign.isNonCandidateCommittee
        ? new Date().getFullYear()
        : campaign.nextElectionYear * 1 || 0;
      localDispatch({
        type: actions.SET_INITIAL_CAMPAIGN,
        data: {
          electionYear,
          isReportable: campaign.isNonCandidateCommittee,
        },
      });
    }
  }, [
    campaign.isNonCandidateCommittee,
    campaign.nextElectionYear,
    state.electionYear,
  ]);

  useEffect(() => {
    scrollToTop();
    const params = getQueryParams(location.search);
    if (Object.keys(params).length) {
      localDispatch({
        type: actions.SET_QUERY_PARAMS,
        data: {
          reportIdRedirect: params.reportId,
          tbd: Boolean(params.tbd),
          fec: Boolean(params.fec),
        },
      });
    }

    return () => {
      reduxDispatch({
        type: validationActions.CLEAR_CHECK_NUMBER_VALIDATION,
      });
    };
  }, []);

  const onSave = (payload, addNew = false, callbacks) => {
    if (state.tbd) {
      payload.reportId = state.reportIdRedirect;
      reduxDispatch({
        type: loanActions.SAVE_TBD_LOAN,
        data: { payload, addNew, reportId: state.reportIdRedirect },
      });
    } else if (state.fec) {
      reduxDispatch({
        type: loanActions.SAVE_FEC_LOAN,
        data: {
          payload,
          addNew,
          reportId: state.reportIdRedirect,
        },
      });
    } else {
      reduxDispatch({
        type: loanActions.SAVE_LOAN,
        data: {
          payload,
          addNew,
          reportId: state.reportIdRedirect,
        },
      });
    }

    if (addNew) {
      callbacks();
    }
  };

  const setSaveButtonDisabled = isDisabled => {
    localDispatch({
      type: actions.TOGGLE_SAVE_BUTTON_DISABLED,
      data: {
        isDisabled,
      },
    });
  };

  const onCancel = () => {
    if (state.reportIdRedirect) {
      if (state.fec) {
        history.push(
          `/filer/editFECReport/${state.reportIdRedirect}?section=itemizedLoans`,
        );
      } else {
        history.push(
          `/filer/editReport/${state.reportIdRedirect}?section=loans`,
        );
      }
    } else {
      history.push('/filer/loans');
    }
  };

  if (loans.saveStatus === statuses.PROCESSING) {
    return <Loading />;
  }

  if (loans.saveStatus === statuses.NOT_STARTED) {
    return (
      <div className="LoanForm depth-1">
        <h3>Add Loan</h3>
        <LoanForm
          campaign={campaign}
          session={session}
          onSave={onSave}
          onCancel={onCancel}
          electionYear={state.electionYear}
          messagingActions={messagingActions}
          validations={validations}
          validationActions={validationActions}
          saveButtonDisabled={state.saveButtonDisabled}
          setSaveButtonDisabled={setSaveButtonDisabled}
          messages={messages}
        />
      </div>
    );
  }

  return null;
};

AddLoan.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(AddLoan);
