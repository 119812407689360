import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { GridRow, Column, MoneyField } from '../../common';

const FECSection19b = ({ q19bColA, q19bColB }) => {
  return (
    <Fragment>
      <GridRow>
        <Column sm={4} classNames="sub-question">
          <p>(b) Of All Other Loans</p>
        </Column>
        <Column sm={4} classNames="entry-row">
          <MoneyField
            disabled
            value={q19bColA || 0}
          />
        </Column>
        <Column sm={4} classNames="entry-row">
          <MoneyField
            disabled
            value={q19bColB || 0}
          />
        </Column>
      </GridRow>
    </Fragment>
  );
};

FECSection19b.propTypes = {
  q19bColA: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  q19bColB: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default FECSection19b;
