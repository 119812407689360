import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'office-ui-fabric-react/lib/Dropdown';
import { getLoans } from '../../services/apiCalls';
import { formatDate, formatCurrency } from '../../helpers/util';
import { CaretDown } from '../icons';

const LoanPicker = ({
  label,
  errorMessage,
  value = '0',
  className = '',
  required = false,
  style = {},
  onChange,
}) => {
  const [state, setState] = useState({
    loans: [],
    queriedLoans: false,
    noLoansMessage: '',
  });

  const formatForSelection = (loan, paddingTop = 6) => {
    const colStyle = { flex: '1 1 0', padding: `${paddingTop} 8 0 0` };
    const rowStyle = {
      height: '32px',
      width: '600px',
      overflow: 'hidden',
      display: 'flex',
      justifyContent: 'left',
      verticalAlign: 'middle',
      paddingLeft: '6px',
    };
    const l = (
      <div key={loan._id} style={rowStyle}>
        <div style={colStyle}>Amount: {formatCurrency(loan.amount)}</div>
        <div style={colStyle}>Received: {formatDate(loan.loanDate)}</div>
        <div style={colStyle}>Entered/Updated: {formatDate(loan.updatedAt)}</div>
      </div>
    );
    return l;
  };

  const noLoans = () => {
    return state.queriedLoans && state.loans.length === 0;
  };

  const noLoanLabel = 'No loans for this campaign';

  const setLoans = async () => {
    const { data: loans } = await getLoans();
    loans.sort((a, b) => {
      return new Date(b.loanDate) - new Date(a.loanDate);
    });
    const noLoansMessage = !loans.length ? noLoanLabel : '';
    setState({
      loans,
      queriedLoans: true,
      noLoansMessage,
    });
  };

  const onRenderTitle = (e) => {
    if (e[0].key === '0') {
      return 'Select Loan';
    }
    return formatForSelection(e[0].loan, 0);
  };

  useEffect(() => {
      setLoans();
  }, []);

  const getSelectLabel = () => {
    if (noLoans()) {
      return { key: '0', text: noLoanLabel };
    }
    return { key: '0', text: 'Select Loan' };
  };

  return (
    <div>
      <span className="error-message">{state.noLoansMessage}</span>
      {!state.noLoansMessage && (
        <Dropdown
          onRenderCaretDown={() => <CaretDown />}
          selectedKey={value}
          onChange={onChange}
          className={className}
          placeholder="Select Loan"
          onRenderTitle={onRenderTitle}
          style={style}
          errorMessage={errorMessage}
          required={required}
          label={label}
          options={[
            getSelectLabel(),
            ...state.loans.map(l => ({
              key: l._id,
              text: formatForSelection(l),
              loan: l,
            })),
          ]}
        />)
      }
    </div>
  );
};

LoanPicker.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  errorMessage: PropTypes.string,
  required: PropTypes.bool,
  style: PropTypes.object,
  label: PropTypes.string,
  electionCycle: PropTypes.string,
  electionYear: PropTypes.string,
  contactId: PropTypes.string,
};

export default LoanPicker;
