import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { DatePicker } from '../Pickers';
import { GridRow, Column, MoneyField } from '../common';
import { formatCurrency } from '../../helpers/util';

export const DebitCardPayment = ({
  actions,
  amount,
  amountErrorMessage,
  federalShareErrorMessage,
  nonFederalShareErrorMessage,
  amountRemaining,
  showAmountRemaining,
  federalShare,
  nonFederalShare,
  nonFederalShareLabel,
  splitAmounts,
  dateReceived,
  dateReceivedErrorMessage,
  description,
  referenceId,
  sourceCode,
  dateLabel,
  descriptionLabel,
  descriptionFieldName,
  mailCode,
  envelopeCode,
  externalDonorId,
}) => (
  <Fragment>
    {!splitAmounts &&
    <GridRow>
      <Column lg={6}>
        <MoneyField
          label="Amount"
          value={amount}
          required
          errorMessage={amountErrorMessage}
          onChange={actions.handlePaymentInfoChange('amount')}
          description={
            showAmountRemaining
              ? `Remaining to match end recipients total: ${formatCurrency(
                  amountRemaining,
                )}`
              : ''
          }
        />
      </Column>
      <Column lg={6}>
        <DatePicker
          label={dateLabel}
          value={dateReceived}
          onChange={actions.handleChangeDate}
          required
          errorMessage={dateReceivedErrorMessage}
        />
      </Column>
    </GridRow>
    }
    {splitAmounts &&
    <GridRow>
      <Column lg={4}>
        <MoneyField
          label="Federal Share"
          value={federalShare}
          required
          errorMessage={federalShareErrorMessage}
          onChange={actions.handlePaymentInfoChange('federalShare')}
        />
      </Column>
      <Column lg={4}>
        <MoneyField
          label={nonFederalShareLabel}
          value={nonFederalShare}
          required
          errorMessage={nonFederalShareErrorMessage}
          onChange={actions.handlePaymentInfoChange('nonFederalShare')}
        />
      </Column>
      <Column lg={4}>
        <DatePicker
          label={dateLabel}
          value={dateReceived}
          onChange={actions.handleChangeDate}
          required
          errorMessage={dateReceivedErrorMessage}
        />
      </Column>
    </GridRow>
    }
    <GridRow>
      <Column lg={4}>
        <TextField
          value={description}
          label={descriptionLabel}
          onChange={actions.handlePaymentInfoChange(descriptionFieldName)}
        />
      </Column>
      <Column lg={4}>
        <TextField
          label="Reference ID"
          value={referenceId}
          onChange={actions.handlePaymentInfoChange('referenceId')}
        />
      </Column>
      <Column lg={4}>
        <TextField
          label="SourceCode"
          value={sourceCode}
          onChange={actions.handlePaymentInfoChange('sourceCode')}
        />
      </Column>

    </GridRow>
    <GridRow>
      <Column lg={3}>
        <TextField
          label="Mail Code"
          value={mailCode || ''}
          onChange={actions.handlePaymentInfoChange('mailCode')}
        />
      </Column>
      <Column lg={3}>
        <TextField
          label="Envelope Code"
          value={envelopeCode || ''}
          onChange={actions.handlePaymentInfoChange('envelopeCode')}
        />
      </Column>
      <Column lg={3}>
        <TextField
          label="Donor ID"
          value={externalDonorId || ''}
          onChange={actions.handlePaymentInfoChange('externalDonorId')}
        />
      </Column>
    </GridRow>
  </Fragment>
);

DebitCardPayment.propTypes = {
  actions: PropTypes.object.isRequired,
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  amountErrorMessage: PropTypes.string,
  federalShareErrorMessage: PropTypes.string,
  nonFederalShareErrorMessage: PropTypes.string,
  amountRemaining: PropTypes.number,
  showAmountRemaining: PropTypes.bool,
  splitAmounts: PropTypes.bool,
  federalShare: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  nonFederalShare: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  nonFederalShareLabel: PropTypes.string,
  dateReceived: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
  ]),
  dateReceivedErrorMessage: PropTypes.string,
  referenceId: PropTypes.string,
  sourceCode: PropTypes.string,
  description: PropTypes.string,
  dateLabel: PropTypes.string,
  descriptionLabel: PropTypes.string,
  descriptionFieldName: PropTypes.string,
  mailCode: PropTypes.string,
  envelopeCode: PropTypes.string,
  externalDonorId: PropTypes.string,
};

DebitCardPayment.defaultProps = {
  amount: '',
  amountErrorMessage: '',
  federalShareErrorMessage: '',
  nonFederalShareErrorMessage: '',
  amountRemaining: 0,
  showAmountRemaining: false,
  splitAmounts: false,
  federalShare: '',
  nonFederalShare: '',
  nonFederalShareLabel: 'Non-Federal Share',
  dateReceived: new Date(),
  dateReceivedErrorMessage: '',
  referenceId: '',
  description: '',
  dateLabel: 'Date Received',
  descriptionLabel: 'Description',
  descriptionFieldName: 'description',
  mailCode: '',
  envelopeCode: '',
  externalDonorId: '',
};

export default DebitCardPayment;
