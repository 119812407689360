import React from 'react';
import PropTypes from 'prop-types';
import { DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { Link } from 'office-ui-fabric-react/lib/Link';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { StatePicker } from '../../components/Pickers';
import {
  EmailField,
  PhoneNumberField,
  Grid,
  GridRow,
  Column,
  ZipCodeField,
} from '../../components/common';
import './EditCandidateDetails.css';

const EditCandidateDetails = ({
  campaign: { candidate = {} },
  formSection,
  candidateDetailsExpanded,
  actions,
  session,
}) => (
  <div className="EditCandidateDetails depth-1" name="candidate-details">
    <h3>
      Candidate Information{' '}
      <small>
        {' '}
        (
        <Link onClick={actions.toggleExpandCandidateDetails}>
          {candidateDetailsExpanded ? 'Collapse' : 'Expand'}
        </Link>
        )
      </small>
    </h3>
    <div
      className={`edit-candidate-details-content${
        candidateDetailsExpanded ? ' expanded' : ''
      }`}
    >
      <Grid>
        {session.isFederal() && (
          <GridRow>
            <Column lg={6}>
              <TextField
                label="Candidate FEC Id"
                value={candidate.fecIdNumber}
                onChange={actions.handleTextChange('fecIdNumber')}
                required
              />
            </Column>
          </GridRow>
        )}
        <GridRow>
          <Column lg={4}>
            <TextField
              value={candidate.firstName}
              label="Candidate First Name"
              required
              onChange={actions.handleTextChange('firstName')}
            />
          </Column>
          <Column lg={4}>
            <TextField
              value={candidate.middleName}
              label="Candidate Middle Name"
              onChange={actions.handleTextChange('middleName')}
            />
          </Column>
          <Column lg={4}>
            <TextField
              value={candidate.lastName}
              label="Candidate Last Name"
              onChange={actions.handleTextChange('lastName')}
              required
            />
          </Column>
        </GridRow>
        <GridRow>
          <Column lg={6}>
            <TextField
              value={candidate.addressLine1}
              label="Candidate Address 1"
              onChange={actions.handleTextChange('addressLine1')}
              required
            />
          </Column>
          <Column lg={6}>
            <TextField
              value={candidate.addressLine2}
              label="Candidate Address 2"
              onChange={actions.handleTextChange('addressLine2')}
            />
          </Column>
        </GridRow>
        <GridRow>
          <Column lg={4}>
            <TextField
              label="Candidate City"
              value={candidate.city}
              required
              onChange={actions.handleTextChange('city')}
            />
          </Column>
          <Column lg={4}>
            <StatePicker
              selectedKey={candidate.state}
              onChange={actions.handleStateChange}
              fieldLabel="Candidate State"
              required
              session={session}
            />
          </Column>
          <Column lg={4}>
            <ZipCodeField
              label="Candidate Zip Code"
              value={candidate.zipCode}
              required
              onChange={actions.handleTextChange('zipCode')}
            />
          </Column>
        </GridRow>
        <GridRow>
          <Column lg={6}>
            <PhoneNumberField
              label="Candidate Phone Number"
              value={candidate.telephoneNumber}
              onChange={actions.handleTextChange('telephoneNumber')}
            />
          </Column>
          <Column lg={6}>
            <EmailField
              label="Candidate Email Address"
              value={candidate.emailAddress}
              required
              onChange={actions.handleTextChange('emailAddress')}
              setFormInvalid={actions.setFormInvalid}
              formSection={formSection}
            />
          </Column>
        </GridRow>
      </Grid>
    </div>
    {candidateDetailsExpanded && (
      <div className="ms-textAlignRight">
        <DefaultButton
          style={{ marginRight: 8 }}
          text="Back"
          onClick={() => actions.previousStep(0)}
        />
        <DefaultButton text="Continue" onClick={() => actions.nextStep(2)} />
      </div>
    )}
  </div>
);

EditCandidateDetails.propTypes = {
  campaign: PropTypes.object,
  candidateDetailsExpanded: PropTypes.bool,
  actions: PropTypes.object.isRequired,
  formSection: PropTypes.number,
  session: PropTypes.object.isRequired,
};

EditCandidateDetails.defaultProps = {
  campaign: {},
  candidateDetailsExpanded: false,
  formSection: null,
};

export default EditCandidateDetails;
