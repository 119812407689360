import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'office-ui-fabric-react/lib/Dropdown';
import { federalOffices } from '../../helpers/constants';
import { CaretDown } from '../icons';

export const FederalOfficePicker = ({
  onChange,
  selectedKey,
  required,
  label,
  errorMessage,
}) => (
  <Dropdown
    onRenderCaretDown={() => <CaretDown />}
    label={label}
    onChange={onChange}
    required={required}
    selectedKey={selectedKey}
    errorMessage={errorMessage}
    options={[
      { key: '', text: 'Select' },
      ...Object.keys({ ...federalOffices }).map(k => ({
        key: k,
        text: federalOffices[k],
      })),
    ]}
  />
);

FederalOfficePicker.propTypes = {
  selectedKey: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  label: PropTypes.string,
  errorMessage: PropTypes.string,
};

FederalOfficePicker.defaultProps = {
  selectedKey: { key: 0 },
  required: false,
  label: 'Salutation',
  errorMessage: '',
};

export default FederalOfficePicker;
