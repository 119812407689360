import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Panel, PanelType } from 'office-ui-fabric-react/lib/Panel';
import { CompoundButton } from 'office-ui-fabric-react/lib/Button';
import { Overlay } from 'office-ui-fabric-react/lib/Overlay';
import ContactDetails from '../ContactForm/ContactDetails';
import { Grid, GridRow, Column } from '../common';
import { mergeFields } from '../../helpers/mergeHelper';
import { sortByUpdatedAtNewestFirst } from '../../helpers/listsHelper';
import './MergeContactsPanel.css';

const initialState = {
  targetContact: null,
  contacts: [],
  proceedWithMerge: false,
};

const MergeContactsPanel = ({
  contactsToMerge,
  consolidateContacts,
  showPanel,
  closePanel,
}) => {
  const [state, setState] = useState({ ...initialState });

  useEffect(() => {
    if (
      state.targetContact === null &&
      state.contacts.length === 0 &&
      contactsToMerge.length > 1
    ) {
      sortByUpdatedAtNewestFirst(contactsToMerge);
      const targetContact = contactsToMerge[0];
      const contacts = contactsToMerge.filter(c => c._id !== targetContact._id);
      setState({
        ...state,
        targetContact,
        contacts,
      });
    }
  }, [state.targetContact, state.contacts, contactsToMerge]);

  const setAsTargetContact = id => {
    setState({
      ...state,
      targetContact: contactsToMerge.find(c => c._id === id),
      contacts: contactsToMerge.filter(c => c._id !== id),
    });
  };

  const warnBeforeProceeding = () => {
    setState({
      ...state,
      proceedWithMerge: true,
    });
  };

  const onSaveMerge = () => {
    const { targetContact, contacts } = state;
    const contactIds = contacts.map(c => c._id);
    const targetContactObject = mergeFields(targetContact, contacts);
    setState({
      ...state,
      proceedWithMerge: false,
    });

    consolidateContacts(targetContactObject, contactIds);
  };

  if (!state.targetContact || state.contacts.length === 0) {
    return null;
  }

  return (
    <Panel
      isOpen={showPanel}
      onDismiss={closePanel}
      type={PanelType.smallFluid}
    >
      <div className="MergeContactsPanel">
        <h3>Consolidate Contacts</h3>
        <Grid className="contact-details-grid">
          <GridRow>
            <Column>
              <p>
                {`Note: This action is not a merging of contacts and their details, rather a consolidation of separate contacts for record keeping puposes.
                  The contacts you have selected, including the "Primary Contact", will be consolidated and replaced with a new contact. The new contact will retain
                  the information presented in the "Primary Contact."`}
              </p>
              <p>
                <strong>
                  {
                    'All transactions recorded for any of the contacts here will be replaced with the new contact.'
                  }
                </strong>
              </p>
            </Column>
          </GridRow>
          <GridRow>
            <Column lg={5} className="target-contact-wrapper">
              <ContactDetails
                isTarget
                contact={state.targetContact}
                cancelMerge={closePanel}
                warnBeforeProceeding={warnBeforeProceeding}
              />
            </Column>
            <Column lg={7} className="source-contacts">
              <Grid>
                <GridRow>
                  {state.contacts.map(c => (
                    <Column lg={6} key={c._id}>
                      <ContactDetails
                        contact={c}
                        setAsTargetContact={setAsTargetContact}
                      />
                    </Column>
                  ))}
                </GridRow>
              </Grid>
            </Column>
          </GridRow>
        </Grid>
      </div>
      {state.proceedWithMerge && (
        <Overlay className="MergeOverlay">
          <div className="merge-warning">
            <p>Are you sure? This action cannot be undone.</p>
            <div className="merge-warning-actions">
              <CompoundButton
                onClick={() => setState({ ...state, proceedWithMerge: false })}
                className="cancel-btn"
                secondaryText="Let me double check"
              >
                No
              </CompoundButton>
              <CompoundButton
                secondaryText="I understand the impact and wish to proceed"
                primary
                onClick={onSaveMerge}
              >
                Yes
              </CompoundButton>
            </div>
          </div>
        </Overlay>
      )}
    </Panel>
  );
};

MergeContactsPanel.propTypes = {
  showPanel: PropTypes.bool,
  closePanel: PropTypes.func.isRequired,
  saveMerge: PropTypes.func.isRequired,
  consolidateContacts: PropTypes.func.isRequired,
  contactsToMerge: PropTypes.array,
  session: PropTypes.object.isRequired,
};

MergeContactsPanel.defaultProps = {
  showPanel: false,
  contactsToMerge: [],
};

export default MergeContactsPanel;
