import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { GridRow, Column, MoneyField } from '../../common';
import { getTotalContributions } from '../../../pages/Reports/fecReportHelper';

const FECSection11e = ({ state }) => {
  return (
    <Fragment>
      <GridRow>
        <Column sm={4}>
          <p className="sub-question">
            (e) TOTAL CONTRIBUTIONS (other than loans)
            <br />
            {'(add Lines 11(a)(iii), (b), (c), and (d))'}
          </p>
        </Column>
        <Column sm={4} classNames="entry-row">
          <MoneyField value={getTotalContributions('A', state) || 0} disabled />
        </Column>
        <Column sm={4} classNames="entry-row">
          <MoneyField value={getTotalContributions('B', state) || 0} disabled />
        </Column>
      </GridRow>
    </Fragment>
  );
};

FECSection11e.propTypes = {
  state: PropTypes.object.isRequired,
};

export default FECSection11e;
