import {
  DefaultButton,
  PrimaryButton,
  ProgressIndicator,
} from 'office-ui-fabric-react';
import PropTypes from 'prop-types';
import React from 'react';
import UploadFile from '../../../../components/UploadFile';
import { importFileAcceptTypes, statuses } from '../../../../helpers/constants';
import './index.css';

const UploadStep = ({
  fileId,
  fileName,
  uploadImportFileStatus,
  onUploadFile,
  onBack,
  onNext,
}) => {
  const isUploadLoading = uploadImportFileStatus === statuses.PROCESSING;

  return (
    <div className="UploadStep">
      <UploadFile
        acceptTypes={importFileAcceptTypes}
        onUploadFile={onUploadFile}
        isDropzoneHidden={Boolean(fileId || isUploadLoading)}
      />
      {(isUploadLoading || fileId) && (
        <ProgressIndicator
          className="upload-progress-indicator"
          label={fileName}
          barHeight={6}
          percentComplete={fileId ? 100 : undefined}
        />
      )}
      <div className="import-wizard-step-bttns upload-back-bttn">
        <DefaultButton
          text="Back"
          onClick={onBack}
          disabled={Boolean(fileId) || isUploadLoading}
        />
        <PrimaryButton
          text="Next"
          onClick={onNext}
          disabled={!fileId || isUploadLoading}
        />
      </div>
    </div>
  );
};

UploadStep.propTypes = {
  fileName: PropTypes.string,
  fileId: PropTypes.string,
  uploadImportFileStatus: PropTypes.oneOf(Object.values(statuses)).isRequired,
  onUploadFile: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
};

export default UploadStep;
