import React from 'react';
import PropTypes from 'prop-types';
import './index.css';

const Badge = ({ text, color }) => {
  return (
    <div className="Badge" style={{ backgroundColor: color }}>
      {text}
    </div>
  );
};

Badge.propTypes = {
  text: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};

export default Badge;
