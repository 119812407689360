import React, { Fragment, useReducer, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
  IconButton,
  ActionButton,
  Spinner,
  SpinnerSize,
  MessageBar,
  MessageBarType,
  NormalPeoplePicker,
  Persona,
  Icon,
  ChoiceGroup,
  Separator,
} from 'office-ui-fabric-react';
import { types as anedotTypes } from '../../actions/anedotActions';
import { Grid, GridRow, Column, BackButton } from '../../components/common';
import ContactRow from '../Contacts/ContactRow';
import CreateContactModal from './CreateContactModal';
import ContributionDetails from './ContributionDetails';
import ExistingContactsList from './ExistingContactsList';
import { useContactSearch } from '../../hooks/useContactSearch';
import { statuses, AnedotProcessedStates } from '../../helpers/constants';
import { DatePicker } from '../../components/Pickers';
import { initialState, anedotReducer, actions } from './anedotReducer';
import ContributionSplit from './ContributionSplit';
import { validate } from './anedotValidations';
import { formHasErrors, createPayload } from './anedotHelpers';
import {
  getCampaign,
  getNextElectionYear,
  getIsNonCandidateCommittee,
  getUserSession,
  getNextUnprocessedEvent,
  getAllUnprocessedEvents,
  getUnprocessedStatus,
  getUnprocessedAnedotRecord,
  getCurrentAnedotIndex,
} from '../../selectors';
import './AnedotContributions.css';

const AnedotContributions = ({ history }) => {
  const searchBoxRef = useRef(null);
  const reduxDispatch = useDispatch();
  const [state, localDispatch] = useReducer(anedotReducer, initialState);
  const campaign = useSelector(getCampaign);
  const nextElectionYear = useSelector(getNextElectionYear);
  const isNonCandidateCommittee = useSelector(getIsNonCandidateCommittee);
  const session = useSelector(getUserSession);
  const allUnprocessed = useSelector(getAllUnprocessedEvents);
  const nextUnprocessedId = useSelector(getNextUnprocessedEvent);
  const nextUnprocessed = useSelector(getUnprocessedAnedotRecord);
  const currentIndex = useSelector(getCurrentAnedotIndex);
  const anedotGetUnprocessedStatus = useSelector(getUnprocessedStatus);

  const getUnprocessedRecords = () => {
    reduxDispatch({
      type: anedotTypes.GET_UNPROCESSED,
      data: {
        processedStatus: state.showDeleted
          ? AnedotProcessedStates.DELETED
          : AnedotProcessedStates.NOT_PROCESSED,
      },
    });
  };

  const getUnprocessedRecord = (recordId) => {
    if (recordId) {
      reduxDispatch({
        type: anedotTypes.GET_RECORD,
        data: { id: recordId },
      });
    }
  };

  useEffect(() => {
    if (
      allUnprocessed.length === 0 &&
      anedotGetUnprocessedStatus === statuses.NOT_STARTED
    ) {
      getUnprocessedRecords();
    }

    return () => {
      reduxDispatch({
        type: anedotTypes.CLEAR_UNPROCESSED,
      });
    };
  }, []);

  useEffect(() => {
    getUnprocessedRecords();
  }, [state.showDeleted]);

  useEffect(() => {
    if (
      anedotGetUnprocessedStatus === statuses.SUCCESS &&
      nextUnprocessed !== null &&
      nextUnprocessed?.possibleContacts?.length
    ) {
      localDispatch({
        type: actions.UPDATE_DEFAULT_CONTACT_SELECTION,
        data: {
          selectionName: 'use-existing',
          amount: nextUnprocessed.amount,
        },
      });
    } else {
      localDispatch({
        type: actions.UPDATE_DEFAULT_CONTACT_SELECTION,
        data: {
          selectionName: 'search',
          amount: nextUnprocessed?.amount || undefined,
        },
      });
    }
  }, [anedotGetUnprocessedStatus, nextUnprocessed]);

  useEffect(() => {
    if (campaign) {
      localDispatch({
        type: actions.ON_NEXT_ELECTION_YEAR_CHANGE,
        data: {
          electionYear: isNonCandidateCommittee
            ? new Date().getFullYear()
            : +nextElectionYear,
        },
      });
    }
  }, [isNonCandidateCommittee, nextElectionYear, state.electionYear]);

  useEffect(() => {
    if (state.contactSelectionOption === 'search' && searchBoxRef.current) {
      searchBoxRef.current.focus();
    }
  }, [state.contactSelectionOption]);

  useEffect(() => {
    if (anedotGetUnprocessedStatus === statuses.SUCCESS) {
      getUnprocessedRecord(nextUnprocessedId);
    }
  }, [nextUnprocessedId, currentIndex]);

  const onChangeDateProcessed = date => {
    localDispatch({
      type: actions.ON_CHANGE_DATE_PROCESSED,
      data: {
        date: new Date(date),
      },
    });
  };

  useEffect(() => {
    if (nextUnprocessed !== null && nextUnprocessed !== undefined) {
      onChangeDateProcessed(nextUnprocessed.processedDate);
    }
  }, [nextUnprocessed]);

  const processContribution = () => {
    const errors = validate(state, nextUnprocessed.amount);
    localDispatch({ type: actions.FORM_ERRORS, data: { errors } });
    if (!formHasErrors(errors)) {
      const payload = createPayload(state, nextUnprocessed);

      const { isProcessed } = nextUnprocessed;

      reduxDispatch({
        type: anedotTypes.UPDATE_ANEDOT_CONTRIBUTION,
        data: {
          id: nextUnprocessed._id,
          payload,
          isProcessed: state.showDeleted ? false : isProcessed,
        },
      });

      localDispatch({
        type: actions.PROCESS_NEXT,
      });
    }
  };

  const doNotImport = () => {
    reduxDispatch({
      type: anedotTypes.DO_NOT_IMPORT,
      data: {
        id: nextUnprocessed._id,
      },
    });

    localDispatch({
      type: actions.PROCESS_NEXT,
    });
  };

  const onChangeCampaignData = (fieldName, tempId) => (e, value) => {
    localDispatch({
      type: actions.ON_CHANGE_CAMPAIGN_DATA,
      data: {
        fieldName,
        value: value.key !== undefined ? value.key : value,
        tempId,
      },
    });
  };

  const openCreateContactModal = () => {
    localDispatch({ type: actions.OPEN_CREATE_CONTACT_MODAL });
  };

  const closeCreateContactModal = () => {
    localDispatch({ type: actions.CLOSE_CREATE_CONTACT_MODAL });
  };

  const cancelCreateContactModal = () => {
    localDispatch({ type: actions.CANCEL_CREATE_CONTACT_MODAL });
  };

  const toggleSelectExistingContact = uuid => () => {
    const { possibleContacts } = nextUnprocessed;

    let selectedExistingContact;

    if (
      state.selectedExistingContact &&
      state.selectedExistingContact.uuid === uuid
    ) {
      selectedExistingContact = null;
    } else {
      selectedExistingContact =
        possibleContacts.find(pc => pc.uuid === uuid) || null;
    }

    localDispatch({
      type: actions.TOGGLE_SELECT_EXISTING_CONTACT,
      data: { selectedExistingContact },
    });
  };

  const [onResolveSuggestions, onRenderSuggestionsItem] = useContactSearch(
    null,
    state.electionYear,
    null,
    'Contribution',
  );

  const onSearchContactSelected = contact => {
    localDispatch({
      type: actions.ON_SEARCH_CONTACT_SELECTED,
      data: { contact: { ...contact, key: contact._id } },
    });
    return contact;
  };

  const onRemoveSearchedContact = removeFn => {
    localDispatch({
      type: actions.ON_SEARCH_CONTACT_SELECTED,
      data: { contact: null },
    });
    removeFn();
  };

  const onRenderItem = itemProps => (
    <div className="chosen-contact" key={itemProps.item._id}>
      <Persona text={itemProps.item.displayName} size={10} />
      <IconButton
        iconProps={{ iconName: 'Times' }}
        onClick={() => onRemoveSearchedContact(itemProps.onRemoveItem)}
      />
    </div>
  );

  const swapOccupationAndEmployer = () => {
    localDispatch({ type: actions.SWAP_OCCUPATION_AND_EMPLOYER });
  };

  const onChangeContactSelectionChoice = (e, option) => {
    localDispatch({
      type: actions.ON_CHANGE_CONTACT_SELECTION_CHOICE,
      data: {
        contactSelectionOption: option.key,
        selectedExistingContact:
          option.key === 'use-existing' ? state.selectedExistingContact : null,
        searchedContact: option.key === 'search' ? state.searchedContact : null,
        newContact: option.key === 'create-new' ? state.newContact : null,
      },
    });
  };

  const onCreateContact = contact => {
    localDispatch({
      type: actions.ON_CREATE_NEW_CONTACT,
      data: {
        contact,
      },
    });
  };

  const removeCreatedContact = () => () => {
    localDispatch({
      type: actions.ON_CREATE_NEW_CONTACT,
      data: {
        contact: null,
      },
    });
  };

  const addSplit = () => {
    localDispatch({
      type: actions.ADD_SPLIT,
    });
  };

  const removeSplit = tempId => {
    localDispatch({
      type: actions.REMOVE_SPLIT,
      data: {
        tempId,
      },
    });
  };

  const toggleShowDeleted = () => {
    localDispatch({
      type: actions.TOGGLE_SHOW_DELETED,
    });
  };

  const {
    contactSelectionOption,
    createContactModalHidden,
    selectedExistingContact,
    searchedContact,
    newContact,
    contributionSplits,
    dateProcessed,
    creatingNew,
    occupationAndEmployerSwapped,
    showDeleted,
    errors,
  } = state;

  return (
    <Fragment>
      <BackButton history={history} message="Cancel" />
      {![statuses.SUCCESS, statuses.ERROR].includes(
        anedotGetUnprocessedStatus,
      ) && (
        <div
          className="no-anedot-contributions depth-1"
          style={{
            backgroundColor: '#fff',
            padding: 40,
            margin: '45px auto',
            width: '80%',
          }}
        >
          <Spinner
            size={SpinnerSize.large}
            label="Checking for new transactions"
          />
        </div>
      )}

      {(nextUnprocessed && nextUnprocessedId && anedotGetUnprocessedStatus === statuses.SUCCESS) && (
        <div className="AnedotContributions">
          <ContributionDetails
            showDeleted={showDeleted}
            toggleShowDeleted={toggleShowDeleted}
            totalAnedotEvents={allUnprocessed}
            contribution={nextUnprocessed}
            selectedExistingContact={selectedExistingContact}
            newContact={newContact}
            searchedContact={searchedContact}
            processContribution={processContribution}
            doNotImport={doNotImport}
            swapOccupationAndEmployer={swapOccupationAndEmployer}
            occupationAndEmployerSwapped={occupationAndEmployerSwapped}
          />
          <div className="contact-matching-wrapper">
            <h3>Campaign Details</h3>
            <Grid>
              <GridRow>
                <Column md={6}>
                  <DatePicker
                    label="Date Processed"
                    value={dateProcessed}
                    onChange={onChangeDateProcessed}
                    required
                    errorMessage={errors.dateProcessedError}
                  />
                </Column>
              </GridRow>
              <Separator />
              {errors.splitTotalError.length > 0 && (
                <MessageBar messageBarType={MessageBarType.error}>
                  {errors.splitTotalError}
                </MessageBar>
              )}
              {contributionSplits.map(split => (
                <ContributionSplit
                  key={split.tempId}
                  split={split}
                  onChange={onChangeCampaignData}
                  onAdd={addSplit}
                  onRemove={removeSplit}
                  session={session}
                  selectedContact={
                    state.selectedExistingContact || state.searchedContact
                  }
                  errors={state.errors.contributionSplits?.[split.tempId] || {}}
                />
              ))}
            </Grid>
            <h3>Associate Contact</h3>
            <Grid classNames="possible-contact-actions">
              <GridRow>
                <Column>
                  <ChoiceGroup
                    defaultSelectedKey={
                      nextUnprocessed?.possibleContacts?.length > 0
                        ? 'use-existing'
                        : 'search'
                    }
                    selectedKey={state.contactSelectionOption}
                    onChange={onChangeContactSelectionChoice}
                    options={[
                      {
                        key: 'use-existing',
                        text: 'Use Existing',
                        contactSelectionOption,
                        styles: {
                          className: 'use-existing-choice-group',
                        },
                        disabled:
                          nextUnprocessed?.possibleContacts?.length === 0,
                        onRenderField: (itemProps, rndr) => {
                          if (contactSelectionOption !== 'use-existing') {
                            return rndr(itemProps);
                          }
                          return (
                            <div className="use-existing">
                              {rndr(itemProps)}
                              <ExistingContactsList
                                toggleSelectExistingContact={
                                  toggleSelectExistingContact
                                }
                                contribution={nextUnprocessed}
                                selectedExistingContact={
                                  selectedExistingContact
                                }
                              />
                            </div>
                          );
                        },
                      },
                      {
                        key: 'search',
                        text: 'Search',
                        onRenderField: (itemProps, rndr) => {
                          if (contactSelectionOption !== 'search') {
                            return rndr(itemProps);
                          }
                          return (
                            <div className="search-contacts">
                              {rndr(itemProps)}
                              <NormalPeoplePicker
                                componentRef={searchBoxRef}
                                itemLimit={1}
                                disabled={contactSelectionOption !== 'search'}
                                onResolveSuggestions={onResolveSuggestions}
                                onRenderSuggestionsItem={
                                  onRenderSuggestionsItem
                                }
                                onItemSelected={onSearchContactSelected}
                                onRenderItem={onRenderItem}
                                styles={{
                                  root: {
                                    marginLeft: 16,
                                  },
                                }}
                              />
                            </div>
                          );
                        },
                      },
                      {
                        key: 'create-new',
                        text: 'Create New',
                        onClick: openCreateContactModal,
                      },
                    ]}
                  />
                </Column>
              </GridRow>
            </Grid>
            {newContact && (
              <div className="newContact">
                <label>Created Contact:</label>
                <ContactRow
                  item={newContact}
                  showDetails={false}
                  selectable={false}
                  removable
                  removeContact={removeCreatedContact}
                />
              </div>
            )}
          </div>
        </div>
      )}

      {!nextUnprocessedId && anedotGetUnprocessedStatus === statuses.SUCCESS && (
        <div className="no-anedot-transactions depth-1">
          <div className="message-container">
            <Icon
              iconName="List"
              styles={{
                root: {
                  fontSize: 36,
                },
              }}
            />
            <p>{'There are no new credit card transactions to process.'}</p>
          </div>
          <ActionButton
            text={showDeleted ? 'Show Unprocessed' : 'Show Deleted'}
            onClick={toggleShowDeleted}
          />
        </div>
      )}
      <CreateContactModal
        isHidden={createContactModalHidden}
        onDismiss={cancelCreateContactModal}
        close={closeCreateContactModal}
        contributionInfo={nextUnprocessed}
        onCreateContact={onCreateContact}
        session={session}
        creatingNew={creatingNew}
        occupationAndEmployerSwapped={occupationAndEmployerSwapped}
      />
    </Fragment>
  );
};

AnedotContributions.propTypes = {
  history: PropTypes.object.isRequired,
};

export default AnedotContributions;
