import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { TextField } from 'office-ui-fabric-react/lib/TextField';

export const PacNonMulticandidateForm = ({
  name,
  contactName,
  handleChange,
}) => (
  <Fragment>
    <TextField label="Name" value={name} onChange={handleChange('name')} />
    <TextField
      label="Contact Name"
      value={contactName}
      onChange={handleChange('contactName')}
    />
  </Fragment>
);

PacNonMulticandidateForm.propTypes = {
  handleChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  contactName: PropTypes.string,
};

PacNonMulticandidateForm.defaultProps = {
  name: '',
  contactName: '',
};

export default PacNonMulticandidateForm;
