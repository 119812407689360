import PropTypes from 'prop-types';
import React from 'react';
import CustomizedImport from '../../../../components/CustomizedImport';
import { statuses } from '../../../../helpers/constants';
import './index.css';

const ColumnMappingStep = ({
  uploadResult,
  mapImportColumnsStatus,
  onMapImportColumns,
  onBack,
}) => {
  const isMapping = mapImportColumnsStatus === statuses.PROCESSING;

  const rows = uploadResult.exampleRows.map((values, index) => ({ index, values }));

  return (
    <div className="ColumnMappingStep">
      <CustomizedImport
        rows={rows}
        referenceColumns={uploadResult.referenceColumns}
        columns={uploadResult.columns}
        requiredColumns={uploadResult.requiredColumns}
        defaultSelectedColumns={uploadResult.mapping?.columns ?? undefined}
        isMapping={isMapping}
        onMapColumns={onMapImportColumns}
        onBack={onBack}
      />
    </div>
  );
};

ColumnMappingStep.propTypes = {
  uploadResult: PropTypes.object,
  mapImportColumnsStatus: PropTypes.oneOf(Object.values(statuses)),
  onMapImportColumns: PropTypes.func.isRequired,
  onBack: PropTypes.func,
};

export default ColumnMappingStep;
