import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { Image } from 'office-ui-fabric-react/lib/Image';
import { Link } from 'office-ui-fabric-react/lib/Link';
import {
  PrimaryButton,
  DefaultButton,
} from 'office-ui-fabric-react/lib/Button';
import {
  MessageBar,
  MessageBarType,
} from 'office-ui-fabric-react/lib/MessageBar';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import FRLogo from '../../assets/img/HRFrontRunner.png';
import { userActions } from '../../actions/userActions';
import { statuses } from '../../helpers/constants';
import { EmailField } from '../../components/common';
import './ForgotPassword.css';

export class ForgotPassword extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };

  constructor(props, ...rest) {
    super(props, ...rest);
    this.state = {
      emailAddress: '',
      emailAddressError: '',
      formValid: true,
      sectionErrors: [],
    };

    props.actions.clearForgotPasswordRequestStatus();
  }

  setFormInvalid = (isInvalid, formSection) => {
    this.setState(state => {
      state.formValid = !isInvalid;
      state.sectionErrors = isInvalid
        ? [...state.sectionErrors, formSection].filter(
            fs => fs !== null && fs !== undefined,
          )
        : state.sectionErrors.filter(fs => fs !== formSection);
      return state;
    });
  };

  handleEmailChange = (e, emailAddress) => {
    this.setState({ emailAddress });
  };

  cancel = () => {
    this.props.history.push('/');
  };

  validate = state => {
    state.formValid = true;

    if (state.emailAddress.length === 0) {
      state.emailAddressError = 'Email address is required';
      state.formValid = false;
    }

    if (state.sectionErrors.length) {
      state.formValid = false;
    }
    return state;
  };

  sendEmail = () => {
    this.setState(this.validate, () => {
      if (this.state.formValid) {
        this.props.actions.sendForgotPasswordEmail(this.state.emailAddress);
      }
    });
  };

  render() {
    const { emailAddress, emailAddressError } = this.state;

    const { user } = this.props;

    return (
      <div className="ForgotPassword depth-1">
        <div className="ms-Grid">
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6 ms-xl6 ms-mdPush3 ms-lgPush3 ms-xlPush3 logo">
              <Image src={FRLogo} alt="FrontRunner" height={150} />
            </div>
          </div>
        </div>
        <h3 className="ms-textAlignCenter">forgot password</h3>
        {user.forgotPasswordStatus === statuses.ERROR && (
          <MessageBar messageBarType={MessageBarType.error}>
            {user.forgotPasswordError}
          </MessageBar>
        )}
        {(user.forgotPasswordStatus === statuses.NOT_STARTED ||
          user.forgotPasswordStatus === statuses.ERROR) && (
          <div className="ms-Grid">
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg8 ms-lgPush2">
                <EmailField
                  label="Email Address"
                  value={emailAddress}
                  formSection="emailAddress"
                  required
                  onChange={this.handleEmailChange}
                  underlined
                  errorMessage={emailAddressError}
                />
              </div>
            </div>
          </div>
        )}
        {user.forgotPasswordStatus === statuses.SUCCESS && (
          <MessageBar messageBarType={MessageBarType.success}>
            {
              'If your email address is found, an email will be sent to you with a link for resetting your password.'
            }
          </MessageBar>
        )}
        <div className="ms-Grid actions">
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm12 ms-textAlignCenter">
              {(user.forgotPasswordStatus === statuses.NOT_STARTED ||
                user.forgotPasswordStatus === statuses.ERROR) && (
                <Fragment>
                  <DefaultButton
                    text="Cancel"
                    onClick={this.cancel}
                    className="cancel-btn"
                  />
                  <PrimaryButton text="Send Email" onClick={this.sendEmail} />
                </Fragment>
              )}
              {user.forgotPasswordStatus === statuses.PROCESSING && (
                <Spinner size={SpinnerSize.large} />
              )}
            </div>
          </div>
          {user.forgotPasswordStatus === statuses.SUCCESS && (
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm12 ms-textAlignCenter">
                <Link onClick={this.cancel}>Return to Login Screen</Link>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { user: state.user };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(userActions, dispatch),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ForgotPassword),
);
