import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { GridRow, Column, MoneyField } from '../../common';

const FECSection6a = ({ q6aColA, q6aColB }) => {
  return (
    <Fragment>
      <GridRow>
        <Column md={4} classNames="sub-question">
          <p>
            (a) Total Contributions <br />
            {'(other than loans)(from Line 11(e))'}
          </p>
        </Column>
        <Column md={4} classNames="entry-row">
          <MoneyField
            disabled
            value={q6aColA}
          />
        </Column>
        <Column md={4} classNames="entry-row">
          <MoneyField
            disabled
            value={q6aColB}
          />
        </Column>
      </GridRow>
    </Fragment>
  );
};

FECSection6a.propTypes = {
  q6aColA: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  q6aColB: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default FECSection6a;
