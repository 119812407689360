export const types = {
  // login actions
  USER_LOGIN: 'USER_LOGIN',
  USER_LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
  USER_LOGIN_FAILURE: 'USER_LOGIN_FAILURE',
  USER_LOGOUT: 'USER_LOGOUT',
  USER_LOGIN_CREATE_CAMPAIGN: 'USER_LOGIN_CREATE_CAMPAIGN',
  USER_LOGIN_CLEAR_MESSAGE: 'USER_LOGIN_CLEAR_MESSAGE',
  GET_PROMO_CODE: 'GET_PROMO_CODE',
  GET_PROMO_CODE_SUCCESS: 'GET_PROMO_CODE_SUCCESS',
  GET_PROMO_CODE_FAILURE: 'GET_PROMO_CODE_FAILURE',
  CLEAR_PROMO_CODE: 'CLEAR_PROMO_CODE',
  CHECK_IS_NON_CANDIDATE_COMMITTEE: 'CHECK_IS_NON_CANDIDATE_COMMITTEE',
  CHECK_IS_NON_CANDIDATE_COMMITTEE_SUCCESS:
    'CHECK_IS_NON_CANDIDATE_COMMITTEE_SUCCESS',
  CHECK_IS_NON_CANDIDATE_COMMITTEE_FAILURE:
    'CHECK_IS_NON_CANDIDATE_COMMITTEE_FAILURE',
  LOGOUT: 'LOGOUT',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',

  // get current user actions
  GET_CURRENT_USER: 'GET_CURRENT_USER',

  // forgot password actions
  CLEAR_FORGOT_PASSWORD_REQUEST_STATUS: 'CLEAR_FORGOT_PASSWORD_REQUEST_STATUS',
  FORGOT_PASSWORD_REQUEST: 'FORGOT_PASSWORD_REQUEST',
  FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_FAILURE: 'FORGOT_PASSWORD_FAILURE',
  FORGOT_PASSWORD_REQUEST_PROCESSING: 'FORGOT_PASSWORD_REQUEST_PROCESSING',

  // reset password actions
  RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',
  RESET_PASSWORD_PROCESSING: 'RESET_PASSWORD_PROCESSING',
  CLEAR_RESET_PASSWORD_STATUS: 'CLEAR_RESET_PASSWORD_STATUS',

  // change password actions
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',

  // signup actions
  VALIDATE_PROMO_CODE: 'VALIDATE_PROMO_CODE',
  PROMO_CODE_VALID: 'PROMO_CODE_VALID',
  PROMO_CODE_INVALID: 'PROMO_CODE_INVALID',
  CHECK_EMAIL_IS_UNIQUE: 'CHECK_EMAIL_IS_UNIQUE',
  SIGNUP_EMAIL_IS_UNIQUE: 'SIGNUP_EMAIL_IS_UNIQUE',
  SIGNUP_EMAIL_NOT_UNIQUE: 'SIGNUP_EMAIL_NOT_UNIQUE',
  CHECK_EMAIL_IS_UNIQUE_ERROR: 'CHECK_EMAIL_IS_UNIQUE_ERROR',
  RESET_EMAIL_CHECK: 'RESET_EMAIL_CHECK',
  REGISTER_NEW_ACCOUNT: 'REGISTER_NEW_ACCOUNT',
  REGISTER_NEW_ACCOUNT_SUCCESS: 'REGISTER_NEW_ACCOUNT_SUCCESS',
  REGISTER_NEW_ACCOUNT_FAILURE: 'REGISTER_NEW_ACCOUNT_FAILURE',
  REGISTER_COMMITTEE: 'REGISTER_COMMITTEE',
  REGISTER_COMMITTEE_SUCCESS: 'REGISTER_COMMITTEE_SUCCESS',
  GET_INFO_FROM_TOKEN: 'GET_INFO_FROM_TOKEN',
  USER_REGISTERED: 'USER_REGISTERED',
  USER_NOT_REGISTERED: 'USER_NOT_REGISTERED',
  USER_NOT_VERIFIED: 'USER_NOT_VERIFIED',
  USER_VERIFIED: 'USER_VERIFIED',
  RESEND_VERIFICATION_EMAIL: 'RESEND_VERIFICATION_EMAIL',
  VERIFICATION_EMAIL_RESENT: 'VERIFICATION_EMAIL_RESENT',
  VERIFICATION_EMAIL_NOT_SENT: 'VERIFICATION_EMAIL_NOT_SENT',
  VERIFY_NEW_ACCOUNT: 'VERIFY_NEW_ACCOUNT',
  USER_VERIFICATION_ERROR: 'USER_VERIFICATION_ERROR',
  COMPLETE_SIGNUP: 'COMPLETE_SIGNUP',
  CLEAR_SIGNUP_ERRORS: 'CLEAR_SIGNUP_ERRORS',
  COMPLETE_SIGNUP_FAILURE: 'COMPLETE_SIGNUP_FAILURE',
  USER_SELECT_CAMPAIGN_SUCCESS: 'USER_SELECT_CAMPAIGN_SUCCESS',
};

export const userActions = {
  getCurrentUser() {
    return { type: types.GET_CURRENT_USER };
  },

  userLogin(username, password) {
    return { type: types.USER_LOGIN, data: { username, password } };
  },

  sendForgotPasswordEmail(emailAddress) {
    return { type: types.FORGOT_PASSWORD_REQUEST, emailAddress };
  },

  clearForgotPasswordRequestStatus() {
    return { type: types.CLEAR_FORGOT_PASSWORD_REQUEST_STATUS };
  },

  resetPassword(newPassword, confirmPassword, passwordResetToken) {
    return {
      type: types.RESET_PASSWORD_REQUEST,
      data: { newPassword, confirmPassword, passwordResetToken },
    };
  },

  clearPasswordResetStatus() {
    return { type: types.CLEAR_RESET_PASSWORD_STATUS };
  },

  checkEmailIsUnique(emailAddress) {
    return { type: types.CHECK_EMAIL_IS_UNIQUE, emailAddress };
  },

  resetEmailCheck() {
    return { type: types.RESET_EMAIL_CHECK };
  },

  signupNewAccount(payload) {
    return { type: types.REGISTER_NEW_ACCOUNT, payload };
  },

  getInfoFromRegistrationToken(registrationToken) {
    return { type: types.GET_INFO_FROM_TOKEN, registrationToken };
  },

  resendVerificationEmail(registrationToken) {
    return { type: types.RESEND_VERIFICATION_EMAIL, registrationToken };
  },

  verifyCode(verificationCode, registrationToken, emailAddress) {
    return {
      type: types.VERIFY_NEW_ACCOUNT,
      data: { verificationCode, registrationToken, emailAddress },
    };
  },

  completeSignup(payload) {
    return { type: types.COMPLETE_SIGNUP, payload };
  },

  validatePromoCode(promoCode) {
    return { type: types.VALIDATE_PROMO_CODE, promoCode };
  },

  getPromoCode() {
    return { type: types.GET_PROMO_CODE };
  },

  clearPromoCode() {
    return { type: types.CLEAR_PROMO_CODE };
  },

  checkIsNonCandidateCommittee() {
    return { type: types.CHECK_IS_NON_CANDIDATE_COMMITTEE };
  },

  logout() {
    return { type: types.LOGOUT };
  },

  changePassword(oldPass, newPass) {
    return { type: types.CHANGE_PASSWORD, data: { oldPass, newPass } };
  },

  registerCommittee(payload) {
    return { type: types.REGISTER_COMMITTEE, data: { payload } };
  },
};
