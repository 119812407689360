import { createReducer } from '../../reducers/createReducer';

export const initialState = {
  searchString: '',
  showUserPanel: false,
  changePasswordDialogHidden: true,
  selectedCampaign: { key: 0 },
  allCampaigns: [],
};

export const actions = {
  SET_SELECTED_CAMPAIGN: 'SET_SELECTED_CAMPAIGN',
  SET_ALL_CAMPAIGNS: 'SET_ALL_CAMPAIGNS',
  TOGGLE_HIDE_CHANGE_PASSWORD_DIALOG: 'TOGGLE_HIDE_CHANGE_PASSWORD_DIALOG',
  TOGGLE_SHOW_USER_PANEL: 'TOGGLE_SHOW_USER_PANEL',
  HANDLE_CHANGE: 'HANDLE_CHANGE',
};

export const actionMap = {
  [actions.SET_SELECTED_CAMPAIGN]: (
    state,
    { data: { campaign, allCampaigns } },
  ) => {
    return {
      ...state,
      selectedCampaign: campaign,
      allCampaigns,
    };
  },
  [actions.SET_ALL_CAMPAIGNS]: (state, { data: { allCampaigns } }) => {
    return {
      ...state,
      allCampaigns,
    };
  },
  [actions.TOGGLE_HIDE_CHANGE_PASSWORD_DIALOG]: (
    state,
    { data: { changePasswordDialogHidden } },
  ) => {
    return {
      ...state,
      changePasswordDialogHidden,
    };
  },
  [actions.TOGGLE_SHOW_USER_PANEL]: (state, { data: { showUserPanel } }) => {
    return {
      ...state,
      showUserPanel,
    };
  },
  [actions.HANDLE_CHANGE]: (state, { data: { fieldName, value } }) => {
    return {
      ...state,
      [fieldName]: value,
    };
  },
};

export const selectedCampaignReducer = createReducer(initialState, actionMap);
