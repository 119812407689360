import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import { types as contributionTypes } from '../../actions/contributionActions';
import { formatCurrency, formatDate } from '../../helpers/util';
import { statuses } from '../../helpers/constants';

import {
  getContributionsByContact,
  getContributionsByContactStatus,
} from '../../selectors';

export const ContributionDetailRows = ({
  rowId,
  params,
  isOpen,
}) => {
  const reduxDispatch = useDispatch();
  const contributionsByContact = useSelector(getContributionsByContact);
  const contributionsByContactStatus = useSelector(getContributionsByContactStatus);
  const status = contributionsByContactStatus[rowId];

  useEffect(() => {
    if (isOpen) {
      reduxDispatch({
        type: contributionTypes.GET_CONTRIBUTIONS_BY_CONTACT,
        data: {
          rowId,
          params,
        },
      });
    }
  }, [isOpen]);

  if (status !== statuses.SUCCESS) {
    return (
      <div className="item-detail-header">
        <Spinner size={SpinnerSize.small} labelPosition="right" label="Loading receipts" />
      </div>
    );
  }

  if (status === statuses.SUCCESS && contributionsByContact[rowId].length === 0) {
    return (
      <div className="item-detail-header">
        <div style={{ marginLeft: 8 }}>No receipts</div>
      </div>
    );
  }

  return (
    <div>
      <div className="item-detail-header">
        <div className="medium">Receipts</div>
        <div className="medium">Date</div>
        <div className="money">Amount</div>
        <div className="medium">FormType</div>
        <div className="medium">Filed</div>
        <div className="large">Payment Reference Id</div>
        <div className="large">Source Code</div>
        <div className="medium">Created</div>
        <div className="medium">Updated</div>
      </div>
      {(contributionsByContact[rowId] || []).map(r => {
        return (<div className="item-detail-row" key={r._id}>
          <div className="medium">&nbsp;</div>
          <div className="medium">{formatDate(r.receivedDate, 'MM/DD/YYYY')}</div>
          <div className="money">{formatCurrency(r.amount)}</div>
          <div className="medium">{r.fecFormType}</div>
          <div className="medium">{r.isItemFiled ? 'Filed' : 'Not Filed'}</div>
          <div className="large">{r.paymentReferenceId}</div>
          <div className="large">{r.sourceCode}</div>
          <div className="medium">{formatDate(r.createdAt, 'MM/DD/YYYY')}</div>
          <div className="medium">{formatDate(r.updatedAt, 'MM/DD/YYYY')}</div>
        </div>);
      })}
    </div>
  );
};

ContributionDetailRows.propTypes = {
  isOpen: PropTypes.bool,
  rowId: PropTypes.string,
  params: PropTypes.string,
};
