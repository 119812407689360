import { types } from '../actions/ledgerActions';
import { createReducer } from './createReducer';

export const initialState = {};

const actionMap = {
  [types.GET_LEDGER_SUCCESS]: (state, action) => {
    return {
      ...state,
      ...action.ledger,
    };
  },
  [types.RECONCILE_ITEM_SUCCESS]: (state, action) => {
    return {
      ...state,
      entries: state.entries.map(item => {
        if (item.transactionId && item.transactionId === action.data.item._id) {
          item.reconciled = !item.reconciled;
        }
        return item;
      }),
    };
  },
  [types.CLEAR_LEDGER]: () => {
    return { ...initialState };
  },
};

export const ledgerReducer = createReducer(initialState, actionMap);
