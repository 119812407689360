import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogType,
  DialogFooter,
} from 'office-ui-fabric-react/lib/Dialog';
import {
  DefaultButton,
} from 'office-ui-fabric-react/lib/Button';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import './GenericSpinnerMessageDialog.css';

const GenericSpinnerMessageDialog = ({
  dialogHidden,
  finishedProcessingMessageList,
  finishedProcessingInstruction,
  isProcessing,
  onCancel,
  processingMessage,
  titleText,
}) => {
  const getFinishedListMessages = () => {
    return finishedProcessingMessageList.map((m, i) => {
      return (<li key={i}>{m}</li>);
    });
  };

  return (
    <Dialog
      hidden={dialogHidden}
      onDismiss={onCancel}
      dialogContentProps={{
        showCloseButton: false,
        type: DialogType.largeHeader,
        title: titleText,
        subText: '',
      }}
      modalProps={{
        isBlocking: true,
        containerClassName: 'GenericSpinnerMessageDialog',
      }}
    >
      {isProcessing &&
      <div>
        <div className="submit-spinner">
          <Spinner className="file-submit-spinner" size={SpinnerSize.medium} />
        </div>
        {processingMessage}
      </div>
      }
      {!isProcessing &&
      <div>
        <div className="finished-list-messages">
          <ul>
            {getFinishedListMessages()}
          </ul>
        </div>
        <div className="finished-instruction-div">
          {finishedProcessingInstruction}
        </div>
      </div>
      }
      <DialogFooter>
        <DefaultButton text="Close" onClick={onCancel} disabled={isProcessing} />
      </DialogFooter>
    </Dialog>
  );
};

GenericSpinnerMessageDialog.propTypes = {
  dialogHidden: PropTypes.bool,
  finishedProcessingMessageList: PropTypes.array,
  finishedProcessingInstruction: PropTypes.string,
  isProcessing: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  processingMessage: PropTypes.string,
  titleText: PropTypes.string.isRequired,
};

GenericSpinnerMessageDialog.defaultProps = {
  dialogHidden: true,
  finishedProcessingMessageList: ['Processing is complete'],
  finishedProcessingInstruction: '',
  processingMessage: 'Is processing...',
};

export default GenericSpinnerMessageDialog;
