import { types } from '../actions/contributionSummaryActions';
import { statuses } from '../helpers/constants';
import { createReducer } from './createReducer';

export const initialState = {
  contributionsSummary: [],
  getContributionsSummaryError: null,
  getContributionsSummaryStatus: statuses.NOT_STARTED,
  getContributionsSummaryNextPageStatus: statuses.NOT_STARTED,
};

const actionMap = {
  [types.GET_CONTRIBUTIONS_SUMMARY_PROCESSING]: state => {
    return {
      ...state,
      getContributionsSummaryStatus: statuses.PROCESSING,
    };
  },
  [types.GET_CONTRIBUTIONS_SUMMARY_SUCCESS]: (state, action) => {
    return {
      ...state,
      contributionsSummary: action.contributionsSummary,
      getContributionsSummaryStatus: statuses.SUCCESS,
    };
  },
  [types.GET_CONTRIBUTIONS_SUMMARY_FAILURE]: (state, action) => {
    return {
      ...state,
      getContributionsSummaryError: action.error,
      getContributionsSummaryStatus: statuses.ERROR,
    };
  },
  [types.GET_CONTRIBUTIONS_SUMMARY_NEXT_PAGE_PROCESSING]: state => {
    return {
      ...state,
      getContributionsSummaryNextPageStatus: statuses.PROCESSING,
    };
  },
  [types.GET_CONTRIBUTIONS_SUMMARY_NEXT_PAGE_SUCCESS]: (state, action) => {
    return {
      ...state,
      contributionsSummary: [...state.contributionsSummary, ...action.contributionsSummary],
      getContributionsSummaryNextPageStatus: statuses.SUCCESS,
    };
  },
  [types.GET_CONTRIBUTIONS_SUMMARY_NEXT_PAGE_FAILURE]: (state, action) => {
    return {
      ...state,
      getContributionsSummaryNextPageStatus: action.error,
    };
  },
};

export const contributionSummaryReducer = createReducer(initialState, actionMap);
