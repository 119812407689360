import { types } from '../actions/reconciliationActions';
import { types as expenditureTypes } from '../actions/expenditureActions';
import { types as contributionTypes } from '../actions/contributionActions';
import { createReducer } from './createReducer';
import { statuses } from '../helpers/constants';

export const initialState = {
  unreconciledActivity: {
    deposits: [],
    expenditures: [],
    interestContributions: [],
  },
  getListStatus: statuses.NOT_STARTED,
  list: [],
  singleReconciliation: null,
};

export const actionMap = {
  [types.GET_UNRECONCILED_ACTIVITY_SUCCESS]: (
    state,
    { data: { deposits = [], expenditures = [], interestContributions = [] } },
  ) => ({
    ...state,
    unreconciledActivity: {
      deposits,
      expenditures,
      interestContributions,
    },
  }),

  [types.CLEAR_UNRECONCILED_ACTIVITY]: state => ({
    ...state,
    unreconciledActivity: {
      deposits: [],
      expenditures: [],
      interestContributions: [],
    },
  }),

  [types.GET_RECONCILIATIONS_PROCESSING]: state => ({
    ...state,
    getListStatus: statuses.PROCESSING,
  }),

  [types.GET_RECONCILIATIONS_SUCCESS]: (
    state,
    {
      data: {
        reconciliations,
        deposits = [],
        expenditures = [],
        interestContributions = [],
      },
    },
  ) => ({
    ...state,
    list: [...reconciliations],
    unreconciledActivity: {
      deposits,
      expenditures,
      interestContributions,
    },
    getListStatus: statuses.SUCCESS,
  }),
  [types.GET_SINGLE_RECONCILIATION_SUCCESS]: (
    state,
    { data: { reconciliation } },
  ) => ({
    ...state,
    singleReconciliation: reconciliation,
  }),
  [types.UPDATE_RECONCILIATION_SUCCESS]: (
    state,
    { data: { id, reconciliation } },
  ) => ({
    ...state,
    list: state.list.map(r => {
      if (r._id === id) {
        const updated = {
          ...r,
          ...reconciliation,
        };
        return updated;
      }
      return r;
    }),
  }),
  [types.CLEAR_SINGLE_RECONCILIATION]: state => ({
    ...state,
    singleReconciliation: null,
  }),
  [types.DELETE_RECONCILIATION_SUCCESS]: (state, { data: { id } }) => ({
    ...state,
    list: state.list.filter(r => r._id !== id),
  }),
  [types.CLEAR_RECONCILIATIONS]: () => ({
    ...initialState,
  }),
  [expenditureTypes.SAVE_FEE_TRANSACTION_SUCCESS]: (
    state,
    { data: { expenditure } },
  ) => {
    if (state.singleReconciliation !== null) {
      return {
        ...state,
        singleReconciliation: {
          ...state.singleReconciliation,
          deposits: [...state.singleReconciliation.deposits],
          expenditures: [
            ...state.singleReconciliation.expenditures,
            expenditure,
          ],
          interestContributions: [
            ...state.singleReconciliation.interestContributions,
          ],
        },
      };
    }

    return {
      ...state,
      unreconciledActivity: {
        ...state.unreconciliedActivity,
        deposits: [...state.unreconciledActivity.deposits],
        expenditures: [...state.unreconciledActivity.expenditures, expenditure],
        interestContributions: [
          ...state.unreconciledActivity.interestContributions,
        ],
      },
    };
  },
  [contributionTypes.SAVE_INTEREST_TRANSACTION_SUCCESS]: (
    state,
    { data: { contribution } },
  ) => {
    if (state.singleReconciliation !== null) {
      return {
        ...state,
        singleReconciliation: {
          ...state.singleReconciliation,
          deposits: [...state.singleReconciliation.deposits],
          expenditures: [...state.singleReconciliation.expenditures],
          interestContributions: [
            ...state.singleReconciliation.interestContributions,
            contribution,
          ],
        },
      };
    }

    return {
      ...state,
      unreconciledActivity: {
        ...state.unreconciledActivity,
        deposits: [...state.unreconciledActivity.deposits],
        expenditures: [...state.unreconciledActivity.expenditures],
        interestContributions: [
          ...state.unreconciledActivity.interestContributions,
          contribution,
        ],
      },
    };
  },
};

export const reconciliationReducer = createReducer(initialState, actionMap);
