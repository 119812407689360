import { takeEvery, takeLatest, call, put } from 'redux-saga/effects';
import axios from 'axios';
import { push } from 'connected-react-router';
import { types as loanTypes } from '../actions/loanActions';
import { types as messagingTypes } from '../actions/messagingActions';
import { types as currentCampaignTypes } from '../actions/currentCampaignActions';
import { getServerSideErrorMessage } from '../helpers/util';
import { toastTypes } from '../helpers/constants';

export function* handleGetLoans() {
  try {
    yield put({ type: loanTypes.GET_LOANS_PROCESSING });
    const { data: loans } = yield call(axios.get, '/api/filer/loans', {
      withCredentials: true,
    });
    yield put({ type: loanTypes.GET_LOANS_SUCCESS, loans });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put({ type: loanTypes.GET_LOANS_FAILURE, error });
  }
}

export function* getLoans() {
  yield takeEvery(loanTypes.GET_LOANS, handleGetLoans);
}

export function* isAddNewAndHasReportId(reportId) {
  yield put(push(`/filer/addLoan?reportId=${reportId}`));
}

export function* isAddNewAndHasReportIdFec(reportId) {
  yield put(push(`/filer/addLoan?reportId=${reportId}&fec=true`));
}

export function* isAddNewAndHasReportIdTbd(reportId) {
  yield put(push(`/filer/addLoan?reportId=${reportId}&tbd=true`));
}

export function* isNotAddNewAndHasReportId(reportId) {
  yield put(push(`/filer/editReport/${reportId}?section=loans`));
}

export function* isNotAddNewAndHasReportIdFec(reportId) {
  yield put(push(`/filer/editFECReport/${reportId}?section=itemizedLoans`));
}

export function* addNewLoan() {
  yield put(push('/filer/addLoan'));
}

export function* addNewLoanFec() {
  yield put(push('/filer/addLoan?fec=true'));
}

export function* addNewLoanTbd() {
  yield put(push('/filer/addLoan?tbd=true'));
}

export function* returnToLoansIndex() {
  yield put(push('/filer/loans'));
}

export function* returnToLedger() {
  yield put(push('/filer/ledger'));
}

export function* returnToReportVerify(verifyReport) {
  yield put(push(`/filer/reports/verify/${verifyReport}`));
}

export function* handleSave(action) {
  try {
    yield put({ type: loanTypes.SAVE_LOAN_PROCESSING });
    const {
      data: { payload, addNew = false, reportId = null },
    } = action;
    let url = '/api/filer';
    if (payload.reportId) {
      url += `/reports/${payload.reportId}/loans?ignoreValidation=true`;
    } else {
      url += '/loans?ignoreValidation=true';
    }
    yield call(axios.post, url, payload, { withCredentials: true });
    yield put({
      type: messagingTypes.SET_TOAST,
      data: {
        message: 'Loan added successfully!',
        toastType: toastTypes.SUCCESS,
      },
    });

    if (addNew && reportId) {
      yield call(isAddNewAndHasReportId, reportId);
    } else if (!addNew && reportId) {
      yield call(isNotAddNewAndHasReportId, reportId);
    } else if (addNew && !reportId) {
      yield call(addNewLoan);
    } else {
      yield call(returnToLoansIndex);
    }

    yield put({ type: loanTypes.SAVE_LOAN_SUCCESS });
    yield put({ type: loanTypes.SAVE_LOAN_RESET });
    yield put({ type: currentCampaignTypes.GET_CURRENT_CAMPAIGN_FINANCE });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put({
      type: messagingTypes.SET_TOAST,
      data: { message: error, toastType: toastTypes.ERROR },
    });
    yield put({ type: loanTypes.SAVE_LOAN_RESET });
  }
}

export function* saveLoan() {
  yield takeEvery(loanTypes.SAVE_LOAN, handleSave);
}

export function* handleSaveFec(action) {
  try {
    yield put({ type: loanTypes.SAVE_LOAN_PROCESSING });
    const {
      data: { payload, addNew = false, reportId = null },
    } = action;
    let url = '/api/filer';
    if (payload.reportId) {
      url += `/reports/${payload.reportId}/loans?ignoreValidation=true`;
    } else {
      url += '/loans?ignoreValidation=true';
    }
    yield call(axios.post, url, payload, { withCredentials: true });
    yield put({
      type: messagingTypes.SET_TOAST,
      data: {
        message: 'Loan added successfully!',
        toastType: toastTypes.SUCCESS,
      },
    });

    if (addNew && reportId) {
      yield call(isAddNewAndHasReportIdFec, reportId);
    } else if (!addNew && reportId) {
      yield call(isNotAddNewAndHasReportIdFec, reportId);
    } else if (addNew && !reportId) {
      yield call(addNewLoanFec);
    } else {
      yield call(returnToLoansIndex);
    }
    yield put({ type: loanTypes.SAVE_LOAN_SUCCESS });
    yield put({ type: loanTypes.SAVE_LOAN_RESET });
    yield put({ type: currentCampaignTypes.GET_CURRENT_CAMPAIGN_FINANCE });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put({
      type: messagingTypes.SET_TOAST,
      data: { message: error, toastType: toastTypes.ERROR },
    });
    yield put({ type: loanTypes.SAVE_LOAN_RESET });
  }
}

export function* saveFecLoan() {
  yield takeEvery(loanTypes.SAVE_FEC_LOAN, handleSaveFec);
}

export function* handleSaveTbd(action) {
  try {
    yield put({ type: loanTypes.SAVE_LOAN_PROCESSING });
    const {
      data: { payload, addNew = false, reportId = null },
    } = action;
    yield call(axios.post, '/api/filer/loans?ignoreValidation=true', payload, {
      withCredentials: true,
    });
    yield put({
      type: messagingTypes.SET_TOAST,
      data: {
        message: 'Loan added successfully!',
        toastType: toastTypes.SUCCESS,
      },
    });
    if (addNew && reportId) {
      yield call(isAddNewAndHasReportIdTbd, reportId);
    } else if (!addNew && reportId) {
      yield call(isNotAddNewAndHasReportId, reportId);
    } else if (addNew && !reportId) {
      yield call(addNewLoanTbd);
    } else {
      yield call(returnToLoansIndex);
    }

    yield put({ type: loanTypes.SAVE_LOAN_SUCCESS });
    yield put({ type: loanTypes.SAVE_LOAN_RESET });
    yield put({ type: currentCampaignTypes.GET_CURRENT_CAMPAIGN_FINANCE });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put({
      type: messagingTypes.SET_TOAST,
      data: { message: error, toastType: toastTypes.ERROR },
    });
    yield put({ type: loanTypes.SAVE_LOAN_RESET });
  }
}

export function* saveTbdLoan() {
  yield takeEvery(loanTypes.SAVE_TBD_LOAN, handleSaveTbd);
}

export function* handleDelete(action) {
  try {
    yield put({ type: loanTypes.SAVE_LOAN_PROCESSING });
    const {
      data: { id = null, reportId = null, redirect = null },
    } = action;
    if (id) {
      yield call(axios.delete, `/api/filer/loans/${id}`, {
        withCredentials: true,
      });
      yield put({
        type: messagingTypes.SET_TOAST,
        data: {
          message: 'Loan deleted successfully!',
          toastType: toastTypes.SUCCESS,
        },
      });
      if (reportId) {
        yield call(isNotAddNewAndHasReportId, reportId);
      } else if (redirect) {
        yield call(returnToLedger);
      } else {
        yield call(returnToLoansIndex);
      }
      yield put({ type: loanTypes.SAVE_LOAN_RESET });
      yield put({ type: currentCampaignTypes.GET_CURRENT_CAMPAIGN_FINANCE });
    }
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put({
      type: messagingTypes.SET_TOAST,
      data: { message: error, toastType: toastTypes.ERROR },
    });
    yield put({ type: loanTypes.SAVE_LOAN_RESET });
  }
}

export function* deleteLoan() {
  yield takeLatest(loanTypes.DELETE_LOAN, handleDelete);
}

export function* handleUpdate(action) {
  try {
    yield put({ type: loanTypes.SAVE_LOAN_PROCESSING });
    const {
      data: {
        payload,
        addNew = false,
        reportId = null,
        redirect = null,
        verifyReport = null,
      },
    } = action;
    yield call(
      axios.put,
      `/api/filer/loans/${payload._id}?ignoreValidation=true`,
      payload,
      { withCredentials: true },
    );
    yield put({
      type: messagingTypes.SET_TOAST,
      data: {
        message: 'Loan updated successfully!',
        toastType: toastTypes.SUCCESS,
      },
    });
    yield put({ type: loanTypes.SAVE_LOAN_RESET });
    if (verifyReport) {
      yield call(returnToReportVerify, verifyReport);
    } else if (addNew && reportId) {
      yield call(isAddNewAndHasReportId, reportId);
    } else if (!addNew && reportId) {
      yield call(isNotAddNewAndHasReportId, reportId);
    } else if (addNew && !reportId) {
      yield call(addNewLoan);
    } else if (redirect) {
      yield call(returnToLedger);
    } else {
      yield call(returnToLoansIndex);
    }
    yield put({ type: currentCampaignTypes.GET_CURRENT_CAMPAIGN_FINANCE });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put({
      type: messagingTypes.SET_TOAST,
      data: { message: error, toastType: toastTypes.ERROR },
    });
    yield put({ type: loanTypes.SAVE_LOAN_FAILURE });
  }
}

export function* updateLoan() {
  yield takeEvery(loanTypes.UPDATE_LOAN, handleUpdate);
}

export function* handleUpdateFec(action) {
  try {
    yield put({ type: loanTypes.SAVE_LOAN_PROCESSING });
    const {
      data: {
        payload,
        addNew = false,
        reportId = null,
        redirect = null,
        verifyReport = null,
      },
    } = action;
    yield call(
      axios.put,
      `/api/filer/loans/${payload._id}?ignoreValidation=true`,
      payload,
      { withCredentials: true },
    );
    yield put({
      type: messagingTypes.SET_TOAST,
      data: {
        message: 'Loan updated successfully!',
        toastType: toastTypes.SUCCESS,
      },
    });
    yield put({ type: loanTypes.SAVE_LOAN_RESET });
    if (verifyReport) {
      yield call(returnToReportVerify, verifyReport);
    } else if (addNew && reportId) {
      yield call(isAddNewAndHasReportIdFec, reportId);
    } else if (!addNew && reportId) {
      yield call(isNotAddNewAndHasReportIdFec, reportId);
    } else if (addNew && !reportId) {
      yield call(addNewLoanFec);
    } else if (redirect) {
      yield call(returnToLedger);
    } else {
      yield call(returnToLoansIndex);
    }

    yield put({ type: currentCampaignTypes.GET_CURRENT_CAMPAIGN_FINANCE });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put({
      type: messagingTypes.SET_TOAST,
      data: { message: error, toastType: toastTypes.ERROR },
    });
    yield put({ type: loanTypes.SAVE_LOAN_FAILURE });
  }
}

export function* updateFecLoan() {
  yield takeEvery(loanTypes.UPDATE_FEC_LOAN, handleUpdateFec);
}

export function* handleDeleteFec(action) {
  try {
    yield put({ type: loanTypes.SAVE_LOAN_PROCESSING });
    const {
      data: { id = null, reportId = null, redirect = null },
    } = action;
    if (id) {
      yield call(axios.delete, `/api/filer/loans/${id}`, {
        withCredentials: true,
      });
      yield put({
        type: messagingTypes.SET_TOAST,
        data: {
          message: 'Loan deleted successfully!',
          toastType: toastTypes.SUCCESS,
        },
      });
      if (reportId) {
        yield call(isNotAddNewAndHasReportIdFec, reportId);
      } else if (redirect) {
        yield call(returnToLedger);
      } else {
        yield call(returnToLoansIndex);
      }
      yield put({ type: loanTypes.SAVE_LOAN_RESET });
      yield put({ type: currentCampaignTypes.GET_CURRENT_CAMPAIGN_FINANCE });
    }
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put({
      type: messagingTypes.SET_TOAST,
      data: { message: error, toastType: toastTypes.ERROR },
    });
    yield put({ type: loanTypes.SAVE_LOAN_RESET });
  }
}

export function* deleteFecLoan() {
  yield takeLatest(loanTypes.DELETE_FEC_LOAN, handleDeleteFec);
}
