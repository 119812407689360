import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
  MessageBar,
  MessageBarType,
  List,
  ActionButton,
  CommandBar,
  Icon,
  Spinner,
  SpinnerType,
} from 'office-ui-fabric-react';
import { Grid, GridRow, Column, BackButton } from '../../components/common';
import { types as reconciliationActions } from '../../actions/reconciliationActions';
import { types as reconciliationSortActions } from '../../actions/reconciliationSortActions';
import { formatCurrency, formatDate, sortByField } from '../../helpers/util';
import { statuses, sortDirections } from '../../helpers/constants';
import { getLabel } from '../../helpers/labelHelper';
import {
  getUserSession,
  getReconciliationSorts,
  getReconciliationsList,
  getUnreconciledDeposits,
  getUnreconciledExpenditures,
  getUnreconciledInterest,
  getReconciliationListGetStatus,
} from '../../selectors';
import './Reconciliations.css';

const sortMap = {
  name: 'Name',
  statementBeginDate: 'Beginning Statement Date',
  statementEndDate: 'Ending Statement Date',
  statementBeginningBalance: 'Beginning Statement Balance',
  statementEndingBalance: 'Ending Statement Balance',
};

const sortDirectionMap = {
  [sortDirections.ASC]: '(ascending)',
  [sortDirections.DESC]: '(descending)',
};

const Reconciliations = ({ history }) => {
  const reduxDispatch = useDispatch();
  const session = useSelector(getUserSession);
  const reconciliationSorts = useSelector(getReconciliationSorts);
  const reconciliationsList = useSelector(getReconciliationsList);
  const unreconciledDeposits = useSelector(getUnreconciledDeposits);
  const unreconciledExpenditures = useSelector(getUnreconciledExpenditures);
  const unreconciledInterest = useSelector(getUnreconciledInterest);
  const getListStatus = useSelector(getReconciliationListGetStatus);

  useEffect(() => {
    reduxDispatch({
      type: reconciliationActions.GET_RECONCILIATIONS,
    });
    return () => {
      reduxDispatch({
        type: reconciliationActions.CLEAR_RECONCILIATIONS,
      });
    };
  }, []);

  const mapItems = () => {
    const { sortField, sortDirection } = reconciliationSorts;
    return reconciliationsList
      .map(r => ({ ...r, key: r._id }))
      .sort(sortByField(sortField, sortDirection));
  };

  const editReconciliation = id => {
    history.push(`/filer/editReconciliation/${id}`);
  };

  const deleteReconciliation = id => {
    reduxDispatch({
      type: reconciliationActions.DELETE_RECONCILIATION,
      data: {
        id,
      },
    });
  };

  const onSort = (field, direction) => () => {
    reduxDispatch({
      type: reconciliationSortActions.SORT_RECONCILIATIONS,
      data: {
        field,
        direction,
      },
    });
  };

  const getCommandBarFarItems = () => [
    {
      key: 'new-reconciliation',
      name: 'Add New',
      iconProps: {
        iconName: 'PlusCircle',
      },
      onClick: () => history.push('/filer/addReconciliation'),
    },
  ];

  const getCommandBarItems = () => {
    const { sortField, sortDirection } = reconciliationSorts;
    const items = [
      {
        key: 'sort',
        name: 'Sort',
        iconProps: {
          iconName: 'Sort',
        },
        subMenuProps: {
          items: [
            {
              key: 'sort-by-name-asc',
              name: 'Name (ascending)',
              iconProps: {
                iconName: 'SortDown',
              },
              onClick: onSort('name', sortDirections.ASC),
            },
            {
              key: 'sort-by-name-desc',
              name: 'Name (descending)',
              iconProps: {
                iconName: 'SortUp',
              },
              onClick: onSort('name', sortDirections.DESC),
            },
            {
              key: 'sort-by-beginning-statement-date-asc',
              name: 'Beginning Statement Date (ascending)',
              iconProps: {
                iconName: 'SortDown',
              },
              onClick: onSort('statementBeginDate', sortDirections.ASC),
            },
            {
              key: 'sort-by-beginning-statement-date-desc',
              name: 'Beginning Statement Date (descending)',
              iconProps: {
                iconName: 'SortUp',
              },
              onClick: onSort('statementBeginDate', sortDirections.DESC),
            },
            {
              key: 'sort-by-ending-statement-date-asc',
              name: 'Ending Statement Date (ascending)',
              iconProps: {
                iconName: 'SortDown',
              },
              onClick: onSort('statementEndDate', sortDirections.ASC),
            },
            {
              key: 'sort-by-ending-statement-date-desc',
              name: 'Ending Statement Date (descending)',
              iconProps: {
                iconName: 'SortUp',
              },
              onClick: onSort('statementEndDate', sortDirections.DESC),
            },
            {
              key: 'sort-by-beginning-statement-balance-asc',
              name: 'Beginning Statement Balance (ascending)',
              iconProps: {
                iconName: 'SortDown',
              },
              onClick: onSort('statementBeginningBalance', sortDirections.ASC),
            },
            {
              key: 'sort-by-beginning-statement-balance-desc',
              name: 'Beginning Statement Balance (descending)',
              iconProps: {
                iconName: 'SortUp',
              },
              onClick: onSort('statementBeginningBalance', sortDirections.DESC),
            },
            {
              key: 'sort-by-ending-statement-balance-asc',
              name: 'Ending Statement Balance (ascending)',
              iconProps: {
                iconName: 'SortDown',
              },
              onClick: onSort('statementEndingBalance', sortDirections.ASC),
            },
            {
              key: 'sort-by-ending-statement-balance-desc',
              name: 'Ending Statement Balance (descending)',
              iconProps: {
                iconName: 'SortUp',
              },
              onClick: onSort('statementEndingBalance', sortDirections.DESC),
            },
          ],
        },
      },
    ];

    if (sortField && sortDirection !== sortDirections.NONE) {
      items[0].subMenuProps.items = items[0].subMenuProps.items.concat({
        key: 'remove-sort',
        name: 'Remove Sort',
        iconProps: {
          iconName: 'Times',
        },
        onClick: () => {
          reduxDispatch({
            type: reconciliationSortActions.CLEAR_RECONCILIATION_SORT,
          });
        },
      });
    }

    return items;
  };

  const onRenderCell = item => (
    <div
      className="fr-reconciliation-row-item"
      data-is-focusable={true}
      key={item._id}
    >
      <div className="rec-details">
        <h3>{item.name}</h3>
        <div className="rec-info">
          <div className="starting-info">
            <label>Statement Begin</label>
            <p className="balance">
              {formatCurrency(item.statementBeginningBalance)}
            </p>
            <p className="date">{formatDate(item.statementBeginDate)}</p>
          </div>
          <div className="ending-info">
            <label>Statement End</label>
            <p className="balance">
              {formatCurrency(item.statementEndingBalance)}
            </p>
            <p className="date">{formatDate(item.statementEndDate)}</p>
          </div>
        </div>
        <div className="rec-info-actions">
          <ActionButton
            iconProps={{
              iconName: 'PencilAlt',
            }}
            text="Edit"
            onClick={() => editReconciliation(item._id)}
            className="rec-action-btn"
          />
          <ActionButton
            iconProps={{
              iconName: 'FileAlt',
            }}
            text="Reconciliation Report"
            className="rec-action-btn"
            onClick={() =>
              history.push(`/filer/reconciliationReport/${item._id}`)
            }
          />
          <span className="ms-hiddenMdUp">
            <ActionButton
              text="Delete"
              iconProps={{
                iconName: 'TrashAlt',
              }}
              onClick={() => deleteReconciliation(item._id)}
            />
          </span>
        </div>
      </div>
      <div className="rec-row-actions ms-hiddenSm">
        <ActionButton
          text="Delete"
          iconProps={{
            iconName: 'TrashAlt',
          }}
          onClick={() => deleteReconciliation(item._id)}
        />
      </div>
    </div>
  );

  let totalDepositsAmount = unreconciledDeposits.reduce((acc, d) => {
    acc += d.amount;
    return acc;
  }, 0);

  const totalInterestAmount = unreconciledInterest.reduce((acc, i) => {
    acc += i.amount;
    return acc;
  }, 0);

  totalDepositsAmount += totalInterestAmount;

  const totalExpendituresAmount = unreconciledExpenditures.reduce((acc, e) => {
    acc += e.amount;
    return acc;
  }, 0);

  return (
    <div className="Reconciliations-wrapper">
      <BackButton history={history} />
      <div className="Reconciliations depth-1">
        <h3>Reconciliations</h3>
        {(unreconciledDeposits.length > 0 || unreconciledExpenditures.length > 0) && (
          <MessageBar messageBarType={MessageBarType.warning}>
            <span>
              {'You have '}
              {(unreconciledDeposits.length > 0 || unreconciledInterest.length > 0) && (
                <>
                  <strong>{formatCurrency(totalDepositsAmount)}</strong>
                  {' in deposits'}
                </>
              )}
              {unreconciledExpenditures.length > 0 && (unreconciledDeposits.length > 0 || unreconciledInterest.length > 0) && (
                <span>{' and '}</span>
              )}
              {unreconciledExpenditures.length > 0 && (
                <>
                  <strong>{formatCurrency(totalExpendituresAmount)}</strong>
                  {` in ${getLabel('expenditures', session)} `}
                </>
              )}
              <span>{' to be reconciled.'}</span>
            </span>
          </MessageBar>
        )}
        <Grid>
          <GridRow>
            <Column>
              <CommandBar
                items={getCommandBarItems()}
                farItems={getCommandBarFarItems()}
              />
            </Column>
          </GridRow>
          {[statuses.NOT_STARTED, statuses.PROCESSING].includes(
            getListStatus,
          ) && (
            <div>
              <Spinner type={SpinnerType.large} label="Loading" />
            </div>
          )}
          {getListStatus === statuses.SUCCESS &&
            reconciliationsList.length > 0 && (
              <GridRow>
                <Column classNames="reconciliations-list">
                  <p>{`Sorted By ${sortMap[reconciliationSorts.sortField]} ${
                    sortDirectionMap[reconciliationSorts.sortDirection]
                  }`}</p>
                  <List items={mapItems()} onRenderCell={onRenderCell} />
                </Column>
              </GridRow>
            )}
          {getListStatus === statuses.SUCCESS &&
            reconciliationsList.length === 0 && (
              <GridRow>
                <Column>
                  <div className="no-reconciliations">
                    <Icon
                      iconName="ListAlt"
                      styles={{ root: { fontSize: 72, color: '#71afe5' } }}
                    />
                    <h2>{'There are no reconciliations for this campaign'}</h2>
                  </div>
                </Column>
              </GridRow>
            )}
        </Grid>
      </div>
    </div>
  );
};

Reconciliations.propTypes = {
  history: PropTypes.object.isRequired,
};

export default Reconciliations;
