import { activityTypes } from '../../../helpers/constants';
import { isInvalidDate, formatDateOnly } from '../../../helpers/util';

export const validate = state => {
  const errors = {
    nameError: '',
    dateError: '',
  };

  if (state.name.length === 0) {
    errors.nameError = 'Deposit name is required';
  }

  if (!state.date) {
    errors.dateError = 'Please specify a deposit date';
  } else if (isInvalidDate(state.date)) {
    errors.dateError = 'Invalid date';
  }

  return errors;
};

export const createPayload = state => {
  const {
    contributionIds,
    expenditureIds,
    loanIds,
    amount,
  } = state.selections.reduce(
    (acc, selection) => {
      acc.amount = Math.round((acc.amount + selection.amount || 0) * 100) / 100;
      switch (selection.activityType) {
        case activityTypes.Contribution:
            acc.contributionIds = [...acc.contributionIds, selection._id];
          break;
        case activityTypes.Expenditure:
          acc.expenditureIds = [...acc.expenditureIds, selection._id];
          break;
        case activityTypes.Loan:
          acc.loanIds = [...acc.loanIds, selection._id];
          break;
        default:
          throw new Error(`activityType not implemented: ${acc.activityType}`);
      }
      return acc;
    },
    {
      contributionIds: [],
      expenditureIds: [],
      loanIds: [],
      amount: 0,
    },
  );
  return {
    name: state.name,
    depositDate: formatDateOnly(state.date),
    amount,
    contributionIds: contributionIds.length ? contributionIds : undefined,
    expenditureIds: expenditureIds.length ? expenditureIds : undefined,
    loanIds: loanIds.length ? loanIds : undefined,
  };
};
