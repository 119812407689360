import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'office-ui-fabric-react/lib/Dropdown';
import { DatePicker } from '../../components/Pickers';
import { timePeriodFilters, timePeriodFilterOnlyCustom } from '../../helpers/listsHelper';

const TimePeriodFilter = ({
  handleChange,
  changeDate,
  timePeriod,
  startDate,
  endDate,
  onlyCustom,
  errors,
}) => {
  const timePeriodOptions = useMemo(() => onlyCustom ? timePeriodFilterOnlyCustom : timePeriodFilters);
  return (
    <>
      {timePeriodOptions.length > 1 &&
      <Dropdown
        label="Time Period"
        onChange={handleChange('timePeriod')}
        selectedKey={timePeriod}
        options={timePeriodOptions}
      />
      }
      {timePeriod === 'custom' && (
        <>
          <DatePicker
            label="Start Date"
            onChange={changeDate('startDate')}
            value={startDate}
            errorMessage={errors.startDate}
          />
          <DatePicker
            label="End Date"
            onChange={changeDate('endDate')}
            value={endDate}
            errorMessage={errors.endDate}
          />
        </>
      )}
    </>
  );
};

TimePeriodFilter.propTypes = {
  handleChange: PropTypes.func.isRequired,
  changeDate: PropTypes.func.isRequired,
  timePeriod: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  startDate: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.object,
  ]),
  endDate: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.object,
  ]),
  errors: PropTypes.object,
  onlyCustom: PropTypes.bool,
};

export default TimePeriodFilter;
