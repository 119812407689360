import { types } from '../actions/budgetCategoryActions';
import { statuses } from '../helpers/constants';
import { createReducer } from './createReducer';

export const initialState = {
  getBudgetCategoryStatus: statuses.NOT_STARTED,
  budgetCategoryOptions: [],
};

const actionMap = {
  [types.SET_BUDGET_CATEGORY_SUCCESS]: (state, action) => {
    return {
      ...state,
      budgetCategoryOptions: action.data,
      getBudgetCategoryStatus: statuses.SUCCESS,
    };
  },
  [types.SET_BUDGET_CATEGORY_FAILURE]: (state) => {
    return {
      ...state,
      budgetCategoryOptions: [],
      getBudgetCategoryStatus: statuses.ERROR,
    };
  },

  [types.GET_BUDGET_CATEGORY]: (state) => {
    return state.budgetCategoryOptions;
  },
};

export const budgetCategoryReducer = createReducer(initialState, actionMap);
