export const types = {
  GET_DATA_IMPORT_LIST: 'GET_DATA_IMPORT_LIST',
  GET_DATA_IMPORT_LIST_PROCESSING: 'GET_DATA_IMPORT_LIST_PROCESSING',
  GET_DATA_IMPORT_LIST_SUCCESS: 'GET_DATA_IMPORT_LIST_SUCCESS',
  GET_DATA_IMPORT_LIST_FAILURE: 'GET_DATA_IMPORT_LIST_FAILURE',
  GET_DATA_IMPORT_DETAIL: 'GET_DATA_IMPORT_DETAIL',
  GET_DATA_IMPORT_DETAIL_PROCESSING: 'GET_DATA_IMPORT_DETAIL_PROCESSING',
  GET_DATA_IMPORT_DETAIL_SUCCESS: 'GET_DATA_IMPORT_DETAIL_SUCCESS',
  GET_DATA_IMPORT_DETAIL_FAILURE: 'GET_DATA_IMPORT_DETAIL_FAILURE',
  DELETE_DATA_IMPORT: 'DELETE_DATA_IMPORT',
  DELETE_DATA_IMPORT_PROCESSING: 'DELETE_DATA_IMPORT_PROCESSING',
  DELETE_DATA_IMPORT_SUCCESS: 'DELETE_DATA_IMPORT_SUCCESS',
  DELETE_DATA_IMPORT_FAILURE: 'DELETE_DATA_IMPORT_FAILURE',
};

export const actions = {
  getDataImportList() {
    return { type: types.GET_DATA_IMPORT_LIST };
  },

  getDataImportDetail(dataImportId) {
    return { type: types.GET_DATA_IMPORT_LIST, data: { dataImportId } };
  },

  deleteDataImport(dataImportId) {
    return { type: types.DELETE_DATA_IMPORT, data: { dataImportId } };
  },
};
