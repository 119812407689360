import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogType,
  DialogFooter,
} from 'office-ui-fabric-react/lib/Dialog';
import {
  PrimaryButton,
} from 'office-ui-fabric-react/lib/Button';
import { FiledReportPicker } from '../Pickers';
import './BeginningBalanceSourceDialog.css';

const BeginningBalanceSourceDialog = ({
  dialogHidden,
  onConfirm,
  selectedKey,
  handleChange,
  reportId,
}) => (
  <Dialog
    hidden={dialogHidden}
    dialogContentProps={{
      showCloseButton: false,
      type: DialogType.normal,
      title: 'Define the Report Beginning Balance Source',
      subText: '',
    }}
    modalProps={{
      isBlocking: true,
      containerClassName: 'BeginningBalanceSourceDialog',
    }}
  >
    <p>
      All reports must contain a reference to obtain current totals to date.
      This can either be the campaign default settings or a reference to a previously filed report.
    </p>
    <FiledReportPicker
      label="Previous Report Balance Source"
      selectedKey={selectedKey}
      onChange={handleChange('previousReportId')}
      reportId={reportId}
      required
    />
    <DialogFooter>
      <PrimaryButton disabled={selectedKey === '0'} text="OK" onClick={onConfirm} />
    </DialogFooter>
  </Dialog>
);

BeginningBalanceSourceDialog.propTypes = {
  dialogHidden: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  selectedKey: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  reportId: PropTypes.string.isRequired,
};

BeginningBalanceSourceDialog.defaultProps = {
  dialogHidden: true,
  selectedKey: '0',
};

export default BeginningBalanceSourceDialog;
